import Label from 'components/inputs/Label';
import { useLayoutEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import {
  Autofill,
  IBasePicker,
  IBasePickerProps,
  IBasePickerSuggestionsProps,
  IPersonaProps,
  IPickerItemProps,
  IRefObject,
  Icon,
  NormalPeoplePicker,
  Persona,
  PersonaSize,
  Shimmer,
  ShimmerElementType,
  TooltipHost,
  concatStyleSets
} from '@fluentui/react';

export interface ICombinedPickerProps {
  id?: string;
  /**
   * Defaultvalue for the picker
   * @defaultvalue []
   */
  defaultValue?: IPersonaProps[];
  /**
   * Defaultvalue for the picker
   * @defaultvalue []
   */
  combinedPickerRef?: IRefObject<IBasePicker<IPersonaProps>>;
  /**
   * Optional flag to mark picker as readOnly
   * @defaultvalue false
   */
  disabled?: boolean;
  /**
   * Description of the combined picker field.
   */
  description?: string;
  /**
   * Display the consent warning icon
   * @defaultvalue false
   */
  displayConsentWarning?: boolean;
  /**
   * Restrict the amount of selectable items.
   */
  itemLimit?: number;
  /**
   * A label for the picker
   */
  label?: string;
  /**
   * The icon that will be displayed to the left of the label
   */
  labelIconName?: string;
  /**
   * A callback for when the user moves the focus away from the picker
   */
  onBlur?: React.FocusEventHandler<HTMLInputElement | Autofill>;
  /**
   * Callback issued when an item is selected or removed
   */
  onChange?: (items?: IPersonaProps[]) => void;
  /**
   * Function that specifies how the selected item will appear.
   */
  onRenderItem?: (props: IPickerItemProps<IPersonaProps>) => JSX.Element;
  /**
   * A callback for what should happen when suggestions are shown without input provided.
   * Returns the already selected items so the resolver can filter them out.
   * If used in conjunction with resolveDelay this will only kick off after the delay throttle.
   */
  onEmptyInputFocus?: (items?: IPersonaProps[]) => void;
  /**
   * A callback for what should happen when a person types text into the input.
   */
  onSearch?: (filterText?: string, selectedItems?: IPersonaProps[]) => Promise<IPersonaProps[]>;
  /**
   * A short hint that describes the expected value.
   */
  placeholder?: string;
  /**
   * Whether the associated form field is required or not
   * @defaultvalue false
   */
  required?: boolean;
  /**
   * Whether to render persona details or not
   * @defaultvalue false
   */
  showSecondaryText?: IPersonaProps['showSecondaryText'];
  /**
   * Call to provide customized styling that will layer on top of the variant rules.
   */
  styles?: IBasePickerProps<IPersonaProps>['styles'];
  /**
   * The selected items
   */
  value?: IPersonaProps[];
  /**
   * Whether the picker is loading
   * @defaultvalue false
   */
  isLoading?: boolean;
}

const CombinedPickerWrapper = styled.div`
  border: 1px solid #a19f9d;
  border-radius: 3px;
  background: #f3f2f1;

  display: flex;
  flex-wrap: wrap;

  .c-picker-persona--disabled {
    background: #e3e3e3;
    border: 1px solid #a19f9d;
    margin: 3px;
    padding: 3px;
    border-radius: 12px;
  }
`;

function CombinedPicker({
  isLoading = false,
  combinedPickerRef,
  defaultValue = [],
  description,
  disabled = false,
  displayConsentWarning = false,
  id,
  itemLimit,
  label,
  labelIconName,
  onBlur,
  onChange,
  onEmptyInputFocus,
  onRenderItem,
  onSearch,
  placeholder,
  required = false,
  showSecondaryText = true,
  styles,
  value
}: ICombinedPickerProps): JSX.Element {
  const [inputIdRef] = useState<string>(uuidv4());

  const [selectedItems, setSelectedItems] = useState<IPersonaProps[] | undefined>(defaultValue);

  const { t } = useTranslation();

  useLayoutEffect(() => {
    // useEffect to set wrapper div + ids for the consent warning icon
    if (displayConsentWarning) {
      const input = document.getElementById(`input-${id || inputIdRef}`);
      const warningIconPlaceholder = document.createElement('div');

      warningIconPlaceholder.id = `${inputIdRef}-warningIconPlaceholder-id`;
      warningIconPlaceholder.className = `${inputIdRef}-warningIconPlaceholder-id`;

      if (input) {
        input.before(warningIconPlaceholder);
      }
    }
  }, [inputIdRef, displayConsentWarning, id]);

  function onRenderToolTipContent() {
    return (
      <>
        <div>{t('combinedPicker.warning.text1')}</div>
        <div>{t('combinedPicker.warning.text2')}</div>
      </>
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function setConsentWarning() {
    const targetContainer = document.getElementById(`${inputIdRef}-warningIconPlaceholder-id`);

    if (targetContainer) {
      const warningIconStyles = {
        root: {
          fontSize: '20px',
          margin: '5px',
          color: '#a80000',
          selectors: { ':hover': { cursor: 'help' } }
        }
      };

      const warningIcon = (
        <TooltipHost
          tooltipProps={{ onRenderContent: onRenderToolTipContent }}
          calloutProps={{ gapSpace: 15 }}
        >
          <Icon iconName="warning" styles={warningIconStyles} />
        </TooltipHost>
      );

      const root = createRoot(targetContainer);
      root.render(warningIcon);
    }
  }

  async function onResolveSuggestions(
    filterText: string,
    selectedItems?: IPersonaProps[]
  ): Promise<IPersonaProps[]> {
    if (filterText && onSearch) {
      return onSearch(filterText, selectedItems);
    }

    return [];
  }

  function handleOnEmptyInputFocus(selectedItems?: IPersonaProps[]): Promise<IPersonaProps[]> | [] {
    if (!onEmptyInputFocus && onSearch) {
      return onSearch('***', selectedItems);
    }

    if (onEmptyInputFocus) {
      onEmptyInputFocus();

      return [];
    }

    return [];
  }

  function removeFocusVisibleClass() {
    // get body element
    const body = document.getElementsByTagName('body')[0];
    // remove class from body element caused by fluent ui bug
    body.classList.remove('ms-Fabric--isFocusVisible');
  }

  function onPeopePickerChange(items?: IPersonaProps[]): void {
    if (!value) {
      setSelectedItems(items);
    }

    removeFocusVisibleClass();

    if (onChange) {
      onChange(items);
    }
  }

  function getNormalPeoplePickerStyles(): IBasePickerProps<IPersonaProps>['styles'] {
    const rootStyles = {
      border: '1px solid #a19f9d',
      borderRadius: 3,
      height: 48,
      ':hover': {
        border: '1px solid black',
        borderRadius: 3
      }
    };

    let normalPeoplePickerStyles: IBasePickerProps<IPersonaProps>['styles'] = {
      root: disabled ? rootStyles : undefined
    };

    if (styles) {
      normalPeoplePickerStyles = concatStyleSets(normalPeoplePickerStyles, styles);
    }

    return normalPeoplePickerStyles;
  }

  function renderPersona(persona: IPersonaProps): JSX.Element {
    return (
      <div className="c-picker-persona--disabled">
        <Persona
          hidePersonaDetails={false}
          imageUrl={persona.imageUrl}
          size={PersonaSize.size32}
          showSecondaryText={showSecondaryText}
          secondaryText={persona.secondaryText}
          text={persona.text}
        />
      </div>
    );
  }

  if (disabled && selectedItems && selectedItems.length > 0) {
    return (
      <div>
        <Label
          required={required}
          iconName={labelIconName}
          label={label}
          description={description}
        />
        <CombinedPickerWrapper>{selectedItems.map(renderPersona)}</CombinedPickerWrapper>
      </div>
    );
  }

  const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
    loadingText: t('peoplepicker.loading'),
    mostRecentlyUsedHeaderText: t('peoplepicker.recent'),
    noResultsFoundText: t('peoplepicker.noresult'),
    showRemoveButtons: false
  };

  const normalPeoplePickerStyles = getNormalPeoplePickerStyles();

  return (
    <div id={id} onMouseLeave={removeFocusVisibleClass}>
      <Label required={required} iconName={labelIconName} label={label} description={description} />
      {isLoading ? (
        <Shimmer
          styles={{ root: { borderRadius: 8, overflow: 'hidden' } }}
          shimmerElements={[{ height: 50, type: ShimmerElementType.line }]}
        />
      ) : (
        <NormalPeoplePicker
          defaultSelectedItems={defaultValue}
          disabled={disabled}
          componentRef={combinedPickerRef}
          inputProps={{
            id: `input-${id}`,
            placeholder,
            autoComplete: 'off'
          }}
          itemLimit={itemLimit}
          onBlur={onBlur}
          selectedItems={value}
          onRenderItem={onRenderItem}
          onChange={onPeopePickerChange}
          onEmptyResolveSuggestions={handleOnEmptyInputFocus}
          onResolveSuggestions={onResolveSuggestions}
          pickerSuggestionsProps={pickerSuggestionsProps}
          resolveDelay={100}
          styles={normalPeoplePickerStyles}
        />
      )}
    </div>
  );
}

export default CombinedPicker;
