import { EmptyListPlaceHolder, LoadingSpinner, MainCommandBar } from 'components';
import CombinedPicker from 'components/inputs/CombinedPickerJS/CombinedPickerJS';
import ListCustom from 'components/lists/ListCustom/ListCustom';
import DialogCustom from 'components/surfaces/Dialog/DialogCustom';
import { AppContext } from 'features/App';
import NoAccessContainer from 'pages/NoAccess';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import fetchRequest, { apiErrorHandler } from 'services/api';
import styled from 'styled-components';
import breakpoints from 'utils/breakpoints';
import { checkScreenWidth } from 'utils/helpers';
import {
  ColumnActionsMode,
  DialogType,
  IconButton,
  Selection,
  SelectionMode
} from '@fluentui/react';
import ReplaceMemberDialog from '../components/ReplaceMemberDialog';

const TitleColumnStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

const EmployeesInTeamsAdminStyled = styled.div`
  @media (max-width: ${breakpoints.extraSmallMax}px) {
    padding-bottom: 50px;
  }
`;

const TeamsContainer = styled.div`
  margin-right: 16px;
  width: 1140px;

  .ms-DetailsRow-check {
    height: 100%;
  }

  .actionIcon {
    margin: auto;
    margin: -10px 0 0 0;
    height: 200%;
    width: 40px;
    vertical-align: middle;
  }

  .moreButton {
    visibility: ${checkScreenWidth(['extraSmall']) ? 'visible' : 'hidden'};
    background-color: transparent;
  }

  padding-bottom: 0.5rem;
`;

const EmployeePickerStyled = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div:first-of-type {
    margin: auto 10px !important;
  }
`;

const MemberRowStyled = styled.div`
  &:hover {
    .moreButton {
      visibility: visible;
    }
  }
`;

function EmployeesInTeamsAdministration({ updateTeams }) {
  const { t } = useTranslation();

  const { globalAppState } = useContext(AppContext);
  const { currentUser, searchTerm } = globalAppState;

  const [isLoading, setIsLoading] = useState(false);

  const [forMultipleTeams, setForMultipleTeams] = useState(false);
  const [selection, setSelection] = useState(null);

  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedMember, setSelectedMember] = useState(null);

  const [members, setMembers] = useState(null);
  const [filteredMembers, setFilteredMembers] = useState([]);

  const [showReplaceDialog, setShowReplaceDialog] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  const [deleteConfTitle, setDeleteConfTitle] = useState('');
  const [deleteConfSubText, setDeleteConfSubText] = useState('');

  const [notificationSubText, setNotificationSubText] = useState('');
  const [notificationTitle, setNotificationTitle] = useState('');

  const type = 0;
  const status = null;

  const cols = [
    {
      key: 'teamTitle',
      name: t('teamsAdministration.column.title'),
      fieldName: 'teamTitle',
      className: 'textColumn',
      minWidth: checkScreenWidth(['extraSmall']) ? window.innerWidth - 75 : 400,
      maxWidth: checkScreenWidth(['extraSmall']) ? window.innerWidth - 75 : 600,
      isMultiline: true,
      isFiltered: false,
      isResizable: true,
      isSorted: false,
      isSortedDescending: null,
      columnActionsMode: ColumnActionsMode.hasDropdown,
      onRender: (row) => (
        <TitleColumnStyled>
          <div>{row.teamTitle}</div>
          <IconButton
            className="moreButton"
            menuIconProps={{ iconName: 'More' }}
            menuProps={{
              items: [
                {
                  key: 'replaceMember',
                  text: t('employeesInTeamsAdministration.column.replaceMember'),
                  iconProps: { iconName: 'Transition' },
                  onClick: () => onReplaceMember(row)
                },
                {
                  key: 'removeMember',
                  disabled: !row.canBeRemoved,
                  text: t('employeesInTeamsAdministration.column.removeMember'),
                  iconProps: { iconName: 'UserRemove' },
                  onClick: () => onRemoveMember(row)
                }
              ]
            }}
            styles={{
              rootHovered: { backgroundColor: '#E1E1E1' },
              root: {
                height: '20px',
                marginLeft: '30px'
              },
              menuIcon: {
                fontSize: '14px',
                fontWeight: '600'
              }
            }}
          />
        </TitleColumnStyled>
      )
    },
    {
      key: 'teamCountry',
      name: t('teamsAdministration.column.country'),
      fieldName: 'teamCountry',
      className: 'textColumn',
      minWidth: 240,
      maxWidth: 240,
      isFiltered: false,
      isResizable: true,
      isSorted: false,
      isSortedDescending: null,
      columnActionsMode: ColumnActionsMode.hasDropdown
    },
    {
      key: 'teamLocation',
      name: t('teamsAdministration.column.location'),
      fieldName: 'teamLocation',
      className: 'textColumn',
      minWidth: 150,
      maxWidth: 150,
      isFiltered: false,
      isResizable: true,
      isSorted: false,
      isSortedDescending: null,
      columnActionsMode: ColumnActionsMode.hasDropdown
    }
  ];

  const [columns, setColumns] = useState(cols);

  const getFilterOptions = (_, column) => {
    if (members && column) {
      const itemsToFilter = [...members];

      // Set filter options for selected column
      const newFilterOptions = [];

      const setCountryFilterOption = (member) => {
        if (!newFilterOptions.find((filter) => filter.key === member.teamCountry)) {
          newFilterOptions.push({
            key: member.teamCountry,
            text: member.teamCountry,
            checked: false
          });
        }

        return null;
      };

      const setLocationFilterOption = (member) => {
        if (!newFilterOptions.find((filter) => filter.key === member.teamLocation)) {
          newFilterOptions.push({
            key: member.teamLocation,
            text: member.teamLocation,
            checked: false
          });
        }

        return null;
      };

      if (column?.key === 'teamCountry') {
        itemsToFilter.map(setCountryFilterOption);
      } else if (column?.key === 'teamLocation') {
        itemsToFilter.map(setLocationFilterOption);
      }

      return newFilterOptions;
    }

    return null;
  };

  function searchItems(items) {
    if (searchTerm && items?.length) {
      const foundItems = items.filter((member) =>
        member.teamTitle
          .trim()
          .toLowerCase()
          .includes(encodeURIComponent(searchTerm.trim().toLowerCase()))
      );

      return foundItems;
    }

    return items;
  }

  function sortItems(itemsToSort, sortBy, sortDesc, ignoreSearch = false) {
    const copyAndSort = (items, columnKey, isSortedDescending) => {
      const sortedItems = items.slice(0).sort((a, b) => {
        if (columnKey === 'country' || columnKey === 'location') {
          const typeA = a[columnKey].name;
          const typeB = b[columnKey].name;

          return (
            isSortedDescending
              ? typeA.toLowerCase() < typeB.toLowerCase()
              : typeA.toLowerCase() > typeB.toLowerCase()
          )
            ? 1
            : -1;
        }

        let valueA = a[columnKey];
        let valueB = b[columnKey];

        if (typeof a[columnKey] === 'string') {
          valueA = a[columnKey].toLowerCase();
        }

        if (typeof b[columnKey] === 'string') {
          valueB = b[columnKey].toLowerCase();
        }

        if (valueA && valueB) {
          return (isSortedDescending ? valueA < valueB : valueA > valueB) ? 1 : -1;
        }

        if (valueA && !valueB) {
          return isSortedDescending ? -1 : 1;
        }

        return isSortedDescending ? 1 : -1;
      });

      return sortedItems;
    };

    let foundItems = [...itemsToSort];

    if (!ignoreSearch) {
      foundItems = searchItems(itemsToSort);
    }

    const sortedItems = copyAndSort(foundItems, sortBy, sortDesc);

    return sortedItems;
  }

  function filterItems(column, items) {
    let foundItems = searchItems(items);

    const selectedFilters = column?.filterOptionsState;

    if (selectedFilters) {
      const filters = [];

      Object.keys(selectedFilters).map((key) => {
        if (selectedFilters[key] === true) {
          filters.push(key);
        }

        return null;
      });

      if (filters?.length) {
        let filteredTeams = [];

        filters.map((filterValue) => {
          const partOfFilteredTeams = foundItems.filter((member) => {
            if (column.fieldName === 'teamCountry') {
              return member.teamCountry === filterValue;
            }

            if (column.fieldName === 'teamLocation') {
              return member.teamLocation === filterValue;
            }

            return null;
          });

          filteredTeams = filteredTeams.concat(partOfFilteredTeams);

          return null;
        });

        foundItems = filteredTeams;
      }
    }

    const sortedColumn = columns.find((col) => col.isSorted);

    if (sortedColumn) {
      const sortedItems = sortItems(
        foundItems,
        sortedColumn.fieldName,
        sortedColumn.isSortedDescending,
        true
      );

      return sortedItems;
    }

    return foundItems;
  }

  function onFilterItems(column, columns) {
    setColumns(columns);

    const filteredAndSortedItems = filterItems(column, members);
    setFilteredMembers(filteredAndSortedItems);
  }

  function onSortItems(column, columns) {
    const sortedItems = sortItems(filteredMembers, column.fieldName, column.isSortedDescending);

    setColumns(columns);
    setFilteredMembers(sortedItems);
  }

  const getMember = useCallback(
    async (team) => {
      let teamMember = null;
      let canBeRemoved = false;
      let message = null;
      let teamItem = team;

      if (teamItem) {
        if (!teamItem.members || teamItem.members.length === 0) {
          const url = `Team/${team.id}`;
          teamItem = await fetchRequest({ url }).catch(apiErrorHandler);
        }
      }

      if (teamItem && teamItem.id) {
        teamMember = teamItem.members.find((x) => x.userId === selectedEmployee.userId);

        if (teamItem.members.length > 1) {
          if (teamMember && teamMember.roleId === 1) {
            const teamLeaders = teamItem.members.filter((x) => x.roleId === 1).length;

            if (teamLeaders > 1) {
              canBeRemoved = true;
            } else {
              message = t(
                'employeesInTeamsAdministration.notificationDialog.removeOnlyTeamLeader.subText'
              );
            }
          } else {
            canBeRemoved = true;
          }
        } else {
          message = t('employeesInTeamsAdministration.notificationDialog.removeLastMember.subText');
        }
      }
      let teamMemberModel = null;

      if (teamItem && teamMember) {
        teamMemberModel = {
          teamId: teamItem.id,
          teamTypeId: teamItem.typeId,
          teamTitle: teamItem.title,
          teamDisplayTitle: teamItem.displayTitle,
          teamCountry: teamItem.country ? teamItem.country.name : null,
          teamLocation: teamItem.location ? teamItem.location.name : null,
          roleId: teamMember.roleId,
          userId: teamMember.userId,
          login: teamMember.userId,
          name: teamMember.name,
          pictureUrl: teamMember.pictureUrl,
          tenantId: teamMember.tenantId,
          canBeRemoved,
          notificationMessage: message,
          teamMembers: teamItem.members
        };
      }

      return teamMemberModel;
    },
    [selectedEmployee, t]
  );

  const loadItems = useCallback(async () => {
    setIsLoading(true);

    let url = `Teams?type=${type}`;

    if (selectedEmployee && selectedEmployee.userId && selectedEmployee.userId.trim() !== '') {
      url += `&userId=${selectedEmployee.userId}&detailed=true`;
    }

    if (status && status.trim().length > 0) {
      url += `&statusId=${status}`;
    }

    // if (searchTerm) {
    //   url += `&searchTerms=${encodeURIComponent(searchTerm)}`;
    // }

    const teams = await fetchRequest({ url }).catch(apiErrorHandler);

    if (teams?.length) {
      const members = [];

      await Promise.all(teams.map(async (team) => members.push(await getMember(team))));

      const sortedMembers = members
        .slice(0)
        .sort((a, b) => (a && b && a.teamTitle > b.teamTitle ? 1 : -1));

      setMembers(sortedMembers);
    } else {
      setMembers(null);
    }

    setIsLoading(false);
  }, [selectedEmployee, getMember]);

  const onShowRemoveFromSelectedConfDialog = useCallback(async () => {
    const title = t('employeesInTeamsAdministration.deleteFromAllDialog.title');

    const subText = t('employeesInTeamsAdministration.deleteFromAllDialog.subText', {
      user: selectedEmployee ? selectedEmployee.name : null
    });

    setForMultipleTeams(true);
    setDeleteConfSubText(subText);
    setDeleteConfTitle(title);
    setShowDeleteConfirmation(true);
  }, [selectedEmployee, t]);

  function handleSelectedEmployee(value) {
    setSelectedEmployee(value[0]);
    setMembers(null);
  }

  useEffect(() => {
    if (selectedEmployee?.userId) {
      loadItems();
    }
  }, [loadItems, selectedEmployee]);

  const onShowNotification = useCallback(
    (title, message) => {
      const defaultTitle = t('employeesInTeamsAdministration.notificationDialog.title.forbidden');

      setNotificationTitle(title || notificationTitle || defaultTitle);

      if (message && message !== '') {
        setNotificationSubText(message);
      }

      setShowNotification(true);
    },
    [notificationTitle, t]
  );

  async function replaceUserInTeams(teamIds, oldUserId, newUserId) {
    const url = `Teams/ChangeUser`;

    const body = JSON.stringify({ teamIds, oldUserId, newUserId });

    await fetchRequest({ url, method: 'PUT', body, ignoreAlert: true }).catch(
      async (errResponse) => {
        const err = await errResponse;

        if (
          !(err instanceof SyntaxError) &&
          (err.indexOf('400') !== -1 || err.indexOf('401') !== -1 || err.indexOf('403') !== -1)
        ) {
          const error = JSON.parse(err);

          onShowNotification(null, error.message);
        }
      }
    );

    if (currentUser.userId === oldUserId && currentUser.userId !== newUserId) {
      selection.map((selTeam) => {
        const team = {
          id: selTeam.teamId,
          title: selTeam.teamTitle,
          displayTitle: selTeam.teamDisplayTitle
        };
        updateTeams(team, 'DELETE');
        return null;
      });
    } else if (currentUser.userId === newUserId && currentUser.userId !== oldUserId) {
      selection.map((selTeam) => {
        const team = {
          id: selTeam.teamId,
          title: selTeam.teamTitle,
          displayTitle: selTeam.teamDisplayTitle
        };
        updateTeams(team, 'POST');
        return null;
      });
    }
  }

  async function removeUserFromTeams(teamIds, userId) {
    const url = `Teams/RemoveUser`;

    const body = JSON.stringify({ teamIds, userId });

    await fetchRequest({ url, method: 'DELETE', body, ignoreAlert: true }).catch(
      async (errResponse) => {
        const err = await errResponse;

        if (
          !(err instanceof SyntaxError) &&
          (err.indexOf('400') !== -1 || err.indexOf('401') !== -1 || err.indexOf('403') !== -1)
        ) {
          const error = JSON.parse(err);

          onShowNotification(null, error.message);
        }
      }
    );

    if (currentUser.userId === userId) {
      selection.map((selTeam) => {
        const team = {
          id: selTeam.teamId,
          title: selTeam.teamTitle,
          displayTitle: selTeam.teamDisplayTitle
        };

        updateTeams(team, 'DELETE');

        return null;
      });
    }
  }

  function onNotificationDialogCancel() {
    setShowNotification(false);
  }

  function onReplaceMember(member) {
    setSelectedMember(member);
    setForMultipleTeams(false);
    setShowReplaceDialog(true);
  }

  function onRemoveMember(member) {
    setSelectedMember(member);
    setForMultipleTeams(false);

    if (member.canBeRemoved) {
      const title = t('employeesInTeamsAdministration.deleteDialog.title');

      const subText = t('employeesInTeamsAdministration.deleteDialog.subText', {
        user: member ? member.name : null,
        team: member ? member.teamTitle : null
      });

      setDeleteConfTitle(title);
      setDeleteConfSubText(subText);
      setShowDeleteConfirmation(true);
    } else {
      setNotificationSubText(member ? member.notificationMessage : null);
      onShowNotification();
    }
  }

  function onReplaceMemberInSelected() {
    setForMultipleTeams(true);
    setShowReplaceDialog(true);
  }

  const changedSelection = new Selection({
    onSelectionChanged: () => {
      let selMember = null;
      const selectedTeams = [];

      changedSelection.getSelection().map((sel, index) => {
        if (index === 0) {
          selMember = sel;
        }

        const selectedMember = {
          teamId: sel.teamId,
          teamTitle: sel.teamTitle,
          teamDisplayTitle: sel.teamDisplayTitle,
          canBeRemoved: sel.canBeRemoved
        };

        return selectedTeams.push(selectedMember);
      });

      setSelectedMember(selMember);
      setSelection(selectedTeams);
    }
  });

  function onReplaceCancel() {
    setShowReplaceDialog(false);
  }

  function onRemoveCancel() {
    setShowDeleteConfirmation(false);
  }

  const onReplaceConfirm = async (newUser) => {
    setShowReplaceDialog(false);

    if (selectedEmployee && newUser) {
      setIsLoading(true);

      if (forMultipleTeams && selection && selection.length > 1) {
        const teamIds = selection.map((team) => team.teamId);

        await replaceUserInTeams(teamIds, selectedEmployee.userId, newUser.userId);
      } else {
        await replaceUserInTeams([selectedMember.teamId], selectedEmployee.userId, newUser.userId);
      }

      loadItems();
    }
  };

  const onRemoveConfirm = async () => {
    setShowDeleteConfirmation(false);

    if (selectedEmployee) {
      setIsLoading(true);

      if (forMultipleTeams && selection && selection.length > 1) {
        const teamIds = selection.map((team) => team.teamId);

        await removeUserFromTeams(teamIds, selectedEmployee.userId);
      } else {
        await removeUserFromTeams([selectedMember.teamId], selectedEmployee.userId);
      }

      setSelectedMember(null);

      loadItems();
    }
  };

  if (!currentUser?.isAdmin) {
    return <NoAccessContainer />;
  }

  function getPlaceholderText() {
    if (searchTerm) {
      if (selectedEmployee) {
        return t('employeesInTeamsAdministration.emptySearchResults');
      }

      return t('employeesInTeamsAdministration.searchNoEmployee');
    }

    if (selectedEmployee) {
      t('employeesInTeamsAdministration.placeHolder');
    }

    return t('employeesInTeamsAdministration.placeHolderNoEmployee');
  }

  function getMembers() {
    const selColumn = columns.find((column) => column.isFiltered);

    const newMembers = filterItems(selColumn, members);

    return newMembers;
  }

  function getMemberList() {
    const filteredAndSortedTeams = getMembers();

    if (filteredAndSortedTeams?.length) {
      return (
        <TeamsContainer className="details-list-wrapper">
          <ListCustom
            items={filteredAndSortedTeams}
            getFilterOptions={getFilterOptions}
            columns={columns}
            selectionMode={SelectionMode.multiple}
            selection={changedSelection}
            selectionPreservedOnEmptyClick
            enterModalSelectionOnTouch
            onRenderRow={(props, defaultRender) => (
              <MemberRowStyled>{defaultRender({ ...props })}</MemberRowStyled>
            )}
            onSortItems={onSortItems}
            onFilterItems={onFilterItems}
          />
        </TeamsContainer>
      );
    }

    return (
      <EmptyListPlaceHolder
        hidden={!!filteredAndSortedTeams?.length}
        noItemsText={getPlaceholderText()}
        listIconName={searchTerm ? 'SearchIssue' : 'People'}
      />
    );
  }

  if (isLoading) {
    return <LoadingSpinner label={t('loading.templates.text')} />;
  }

  return (
    <>
      <MainCommandBar
        className="full-width"
        items={[
          {
            key: 'employeePicker',
            onRender: () => {
              return (
                <EmployeePickerStyled>
                  <CombinedPicker
                    searchUser
                    focusPeoplePicker
                    selectedItems={selectedEmployee}
                    onChange={handleSelectedEmployee}
                    type={2}
                    placeholder={t('employeesInTeamsAdministration.selectEmployee')}
                  />
                </EmployeePickerStyled>
              );
            }
          },
          {
            key: 'removeMemberFromAllTeams',
            text: t('employeesInTeamsAdministration.removeMemberFromAllTeams'),
            disabled:
              !selectedEmployee ||
              !selection ||
              selection.length < 1 ||
              !selection.every((x) => x.canBeRemoved),
            iconProps: {
              iconName: 'UserRemove'
            },
            onClick: () => onShowRemoveFromSelectedConfDialog()
          },
          {
            key: 'replaceMemberInAllTeams',
            text: t('employeesInTeamsAdministration.replaceMemberInAllTeams'),
            disabled: !selectedEmployee || !selection || selection.length < 1,
            iconProps: {
              iconName: 'Transition'
            },
            onClick: () => onReplaceMemberInSelected()
          }
        ]}
      />
      <EmployeesInTeamsAdminStyled id="EmpTeamsList">
        {getMemberList()}
        {showReplaceDialog ? (
          <ReplaceMemberDialog
            hidden={!showReplaceDialog}
            onCancel={onReplaceCancel}
            onConfirm={onReplaceConfirm}
            member={selectedMember}
          />
        ) : null}
        {showDeleteConfirmation ? (
          <DialogCustom
            hidden={false}
            title={deleteConfTitle}
            subText={deleteConfSubText}
            defaultButtonLabel={t('employeesInTeamsAdministration.deleteDialog.defaultButtonLabel')}
            primaryButtonLabel={t('employeesInTeamsAdministration.deleteDialog.primaryButtonLabel')}
            primaryButtonFunc={onRemoveConfirm}
            defaultButtonFunc={onRemoveCancel}
            onDismiss={onRemoveCancel}
            dialogContentProps={{
              type: DialogType.normal,
              title: deleteConfTitle,
              subText: deleteConfSubText
            }}
            modalProps={{ isBlocking: true }}
          />
        ) : null}
        {showNotification ? (
          <DialogCustom
            defaultButtonFunc={onNotificationDialogCancel}
            defaultButtonLabel={t(
              'employeesInProcessesAdmin.notificationDialog.defaultButtonLabel'
            )}
            subText={notificationSubText}
            title={notificationTitle}
            onDismiss={onNotificationDialogCancel}
            hidden={false}
          />
        ) : null}
      </EmployeesInTeamsAdminStyled>
    </>
  );
}

EmployeesInTeamsAdministration.propTypes = {
  updateTeams: PropTypes.func.isRequired
};

export default EmployeesInTeamsAdministration;
