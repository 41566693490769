import { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { TooltipHost } from '@fluentui/react';
import { ChatItemReferenceContext, ISourceItem } from './ReferenceContext';
import { ResultItem } from './ChatMessageFragmentWebSearch';

function getSourceItemKey(item: ISourceItem) {
  return `${item.type} ${item.url}`;
}

const SourcesStyled = styled.div`
  > .c-title {
    font-weight: bold;
  }

  > .c-items {
    list-style: none;
  }
`;

export default function ChatItemAuxReferences() {
  const { t } = useTranslation();
  const references = useContext(ChatItemReferenceContext);

  if (!references.orderedSources.length) return null;

  return (
    <SourcesStyled>
      <div className="c-title">{t('ai.chat.fragments.references.title')}</div>
      <ul className="c-items">
        {references.orderedSources.map((item, index) => (
          <SourceItem
            key={getSourceItemKey(item)}
            index={index}
            item={item}
            isHighlighted={references.highlighted === item}
            isUnexpected={references.unexpectedSources.includes(item)}
          />
        ))}
      </ul>
    </SourcesStyled>
  );
}

const SourceItemStyled = styled.li`
  margin: 0.5rem 0.1rem 0.5rem 0;
  border-radius: 0.5rem;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.5rem;
  transition: box-shadow 0.2s;

  &.is-unexpected {
    grid-template-columns: auto 1fr auto;
  }

  > .c-index {
    color: rgb(${({ theme }) => theme.aiChatTextReference.foreground});
    min-width: 1.5rem;
    display: grid;
    place-content: center;
    font-weight: 600;
    font-variant-numeric: tabular-nums;
    text-align: center;
    border-radius: 0.5rem;
  }

  > .c-unexpected {
    min-width: 1rem;
    text-align: center;
    font-weight: bold;
    place-content: center;
    color: rgb(${({ theme }) => theme.aiChatTextReference.unexpectedForeground});
    cursor: default;
  }

  &.is-highlighted {
    box-shadow: 0 0 0 0.1rem rgb(${({ theme }) => theme.aiChatTextReference.foreground} / 0.5);
  }
`;

function SourceItem({
  item,
  index,
  isHighlighted,
  isUnexpected
}: {
  item: ISourceItem;
  index: number;
  isHighlighted: boolean;
  isUnexpected: boolean;
}) {
  const { t } = useTranslation();

  if (item.isIncomplete) return null;

  return (
    <SourceItemStyled
      className={`${isHighlighted ? 'is-highlighted' : ''} ${isUnexpected ? 'is-unexpected' : ''}`}
    >
      <div className="c-index">{index + 1}</div>
      <ResultItem url={item.url} />

      {isUnexpected ? (
        <TooltipHost
          delay={0}
          content={t('ai.chat.fragments.references.unexpectedReference')}
          hostClassName="c-unexpected"
        >
          ?
        </TooltipHost>
      ) : null}
    </SourceItemStyled>
  );
}
