import i18n from 'i18next';
import { useEffect, useState } from 'react';
import fetchRequest from 'services/api';
import { isInIframe } from 'utils/helpers';
import { app } from '@microsoft/teams-js';

function useMsTeamsInitialization() {
  const [contentToShow, setContentToShow] = useState({
    tasks: false,
    processes: false,
    projects: false
  });
  const [config, setConfig] = useState(null);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (isInIframe()) {
      app
        .initialize()
        .then(() => {
          window.sessionStorage.setItem('msTeamsEnvironment', 'true');

          app.getContext().then((context) => {
            const userLanguage = context.app?.locale;

            if (userLanguage) {
              i18n.changeLanguage(userLanguage);
            }

            const { channel, page, chat } = context;

            if ((channel || chat) && page && page.id?.startsWith('{')) {
              // this is for the old syntax of the entityId json
              const newConfig = JSON.parse(page.id);

              if (typeof newConfig === 'object') {
                setContentToShow({
                  ...newConfig,
                  tasks: newConfig.showTasks,
                  processes: newConfig.showProcesses,
                  projects: newConfig.showProjects
                });
              }
              setInitialized(true);
            } else if ((channel || chat) && page && page.id?.includes('/')) {
              // this is for the old syntax of the entityId (1/1/1)

              const showTasks = page && page.id?.split('/')[0] === '1';
              const showProcesses = page.id?.split('/')[1] === '1';
              const showProjects = page.id?.split('/')[2] === '1';

              setContentToShow({
                tasks: showTasks,
                processes: showProcesses,
                projects: showProjects
              });
              setInitialized(true);
            } else if ((channel || chat) && page?.id) {
              fetchRequest({ url: 'Init' })
                .then(({ currentTenantId }) => {
                  window.localStorage.setItem('tenantId', currentTenantId);
                })
                .then(() => {
                  fetchRequest({ url: `msteams/settings?id=${page?.id}` }).then((response) => {
                    const { showTasks, showProcesses, showProjects } = response;
                    setConfig(response);
                    setContentToShow({
                      ...response,
                      tasks: showTasks,
                      processes: showProcesses,
                      projects: showProjects
                    });
                    setInitialized(true);
                  });
                });
            } else {
              setContentToShow({ tasks: true, processes: true, projects: true });
              setInitialized(true);
            }
          });
        })
        .catch(() => {
          window.sessionStorage.removeItem('msTeamsEnvironment');

          setInitialized(true);
        });
    } else {
      setInitialized(true);
    }
  }, []);

  return { config, contentToShow, initialized };
}

export default useMsTeamsInitialization;
