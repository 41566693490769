import Label from 'components/inputs/Label';
import SmallTextField, { ISmallTextFieldProps } from 'components/inputs/SmallTextField';
import { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { Icon, IconButton, Stack } from '@fluentui/react';

/* eslint-disable react/jsx-props-no-spreading */

const SettingsContainerStyled = styled(Stack)<{ $collapsed?: boolean }>`
  display: flex;
  flex-flow: column nowrap;
  width: 600px;
  height: auto;
  box-sizing: border-box;
  border-radius: ${({ theme }) => theme.settingsContainer.cornerRadius};
  padding: ${(props) => (props.$collapsed ? '15px 24px 15px 24px' : '15px 24px 24px 24px')};
  background-color: rgb(${({ theme }) => theme.settingsContainer.background});
  box-shadow: ${({ theme }) => theme.settingsContainer.shadow};
`;

const Header = styled(Stack)``;

const Description = styled(Stack.Item)`
  font-size: 12px;
  max-width: 260px;
  font-weight: 400;
  color: rgb(${({ theme }) => theme.settingsContainer.descriptionText});
`;

const ContentWrapper = styled.div<{ $fullWidth?: boolean }>`
  margin: ${(props) => (props.$fullWidth ? '0' : '0px 80px 0px 25px')};
`;

interface SettingsContainerProps {
  children: React.ReactNode;
  defaultExpanded?: boolean;
  fullContentWidth?: boolean;
  description?: string | React.ReactNode;
  iconName?: string;
  isCollapsible?: boolean;
  label: string;
  descriptionPosition?: 'default' | 'bottom';
  styles?: React.CSSProperties;
}

function SettingsContainer({
  children,
  description,
  iconName,
  fullContentWidth = false,
  isCollapsible = true,
  defaultExpanded = false,
  descriptionPosition = 'default',
  label,
  styles
}: SettingsContainerProps): JSX.Element {
  const theme = useTheme();
  const [collapsed, setCollapsed] = useState<boolean>(isCollapsible && !defaultExpanded);

  return (
    <SettingsContainerStyled style={styles} $collapsed={collapsed} className="c-setting-container">
      <Header horizontal>
        <Stack verticalAlign="center" horizontal>
          <Icon
            styles={{ root: { color: `rgb(${theme.settingsContainer.icon})`, fontSize: 16 } }}
            iconName={iconName}
          />
          <Label
            styles={{
              container: {
                minWidth: 200,
                marginLeft: '8px'
              },
              fluentLabel: {
                root: {
                  fontSize: 13,
                  fontWeight: 600,
                  color: `rgb(${theme.settingsContainer.labelText})`
                }
              }
            }}
            label={label}
          />
        </Stack>
        {descriptionPosition === 'default' && <Description grow>{description}</Description>}
        {isCollapsible && (
          <IconButton
            styles={{ root: { marginLeft: 'auto !important' } }}
            iconProps={{
              iconName: collapsed ? 'ChevronDown' : 'ChevronUp',
              styles: { root: { fontSize: 14 } }
            }}
            onClick={() => setCollapsed((prevState) => !prevState)}
          />
        )}
      </Header>
      {descriptionPosition === 'bottom' && <Description grow>{description}</Description>}
      <ContentWrapper $fullWidth={fullContentWidth}>{!collapsed && children}</ContentWrapper>
    </SettingsContainerStyled>
  );
}

export function SettingsTextField(props: ISmallTextFieldProps) {
  const theme = useTheme();

  return (
    <SmallTextField
      {...props}
      labelStyles={{
        fluentLabel: {
          root: {
            fontSize: 12,
            fontWeight: 'normal',
            color: `rgb(${theme.settingsContainer.labelText})`
          }
        }
      }}
      styles={({ disabled }) => ({
        fieldGroup: [
          {
            border: `1px solid rgb(${theme.settingsContainer.textFieldOutline})`,
            borderRadius: 3,
            ':after': { borderRadius: 3 }
          }
        ],
        wrapper: disabled
          ? {
              border: `1px solid rgb(${theme.settingsContainer.textFieldOutline})`,
              borderRadius: 3
            }
          : undefined,
        field: { color: `rgb(${theme.settingsContainer.textFieldText})` }
      })}
    />
  );
}

export default SettingsContainer;
