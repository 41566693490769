import { RichTextEditor } from 'components';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { cloneObject } from 'utils/helpers';
import {
  Checkbox,
  DetailsHeader,
  DetailsList,
  DetailsListLayoutMode,
  DirectionalHint,
  Icon,
  IconButton,
  MessageBar,
  MessageBarType,
  SelectionMode,
  TextField,
  TooltipHost,
  getTheme
} from '@fluentui/react';
import FieldPicker from '../FieldPicker';
import CreateRequestPropDialog from './CreateRequestPropDialog';

const RequestFieldsListStyled = styled.div`
  position: relative;
  margin-top: 5px;

  .ms-DetailsList-headerWrapper {
    max-width: auto;
    padding: 0;
    margin-right: auto;
  }

  .ms-DetailsHeader {
    padding-top: 0;
  }

  .ms-DetailsHeader-cell:hover {
    background-color: transparent;
  }

  .ms-DetailsHeader-cell[data-item-key='required'] {
    .ms-DetailsHeader-cellTitle {
      padding: 0;

      .ms-DetailsHeader-cellName {
        width: 100%;
        text-align: center;
      }
    }
  }

  .centered {
    margin: 0;
    padding: 0;
    position: absolute;
    top: 50%;
    left: 51%;
    transform: translate(-50%, -51%);
  }
`;

const RequestFieldRowStyled = styled.div`
  border-bottom: 1px solid rgb(245, 245, 245);

  .editButton {
    visibility: hidden;
  }

  .deleteButton {
    visibility: hidden;
  }

  &:hover {
    .editButton {
      visibility: visible;
    }

    .deleteButton {
      visibility: visible;
    }
  }
`;

const MessageBarStyled = styled(MessageBar)`
  position: absolute;
  width: 100%;
  margin: 5px 30px 5px 0;
  top: -45px;
  z-index: 10;
`;

const FieldPickerContainer = styled.div`
  width: 100%;
`;

const DetailsHeaderStyled = styled(DetailsHeader)`
  .ms-DetailsHeader-cell {
    padding: 0 5px 0 5px;
  }

  .ms-DetailsHeader-cell {
    padding: 0;
  }
`;

const ColumnNameWithInfo = styled.div`
  display: flex;

  .info-icon {
    display: block;
    position: absolute;
    color: rgb(0, 120, 212);
    top: -3px;
  }
`;

const InfoIcon = styled(Icon)`
  &:hover {
    cursor: help;
  }
`;

const ColumnName = styled.div`
  font-weight: 600;
`;

function RequestFieldsList({
  hidden,
  fields,
  saveFields,
  showCreateDialog,
  hideCreateDialog,
  disabledNewRow,
  url,
  propType,
  forAutocomplete,
  handleErrors,
  queryFieldsErrors
}) {
  const { t } = useTranslation();

  const [newField, setNewField] = useState({});

  const [newFields, setNewFields] = useState(null);

  const [inputErrors, setInputErrors] = useState(null);

  const [newEditDialog, setNewEditDialog] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (showCreateDialog) {
      setNewEditDialog({ show: true, onlyDefault: disabledNewRow });
    }
  }, [showCreateDialog, disabledNewRow]);

  useEffect(() => {
    let newFields = [];

    const passedFields = cloneObject(fields);

    const indexFields = () => {
      let index = 1;

      if (Array.isArray(passedFields)) {
        newFields = passedFields.map((field) => {
          const newField = { ...field, index };

          index += 1;

          return newField;
        });
      } else {
        newFields.push({ ...passedFields, index });
      }
    };

    if (passedFields) {
      indexFields();
    }

    setNewFields(newFields);
  }, [fields]);

  useEffect(() => {
    setInputErrors(queryFieldsErrors);
  }, [queryFieldsErrors]);

  function onDismissNewEditDialog() {
    setNewEditDialog(null);
    hideCreateDialog();
  }

  function resetErrorMessages(inputIds, onlyClose) {
    if (inputErrors?.length && inputIds?.length) {
      const errors = [];

      inputErrors.map((err) => {
        let errorAdded = false;

        inputIds.map((id) => {
          if (err.inputId === id) {
            if (onlyClose) {
              errors.push({ ...err, showMessage: false });
              errorAdded = true;
            } else {
              errors.push({ ...err, message: null, showMessage: false, created: null });
              errorAdded = true;
            }
          }

          return null;
        });

        if (!errorAdded) {
          errors.push(err);
        }

        return null;
      });

      handleErrors(errors);
    }
  }

  function addError(fieldId, message, fieldWithDoubleName) {
    let isNewError = true;

    const newInputErrors = inputErrors?.length
      ? inputErrors.map((err) => {
          const newErr = err;
          if (newErr.inputId === fieldId) {
            isNewError = false;

            newErr.message = message;
            newErr.showMessage = true;
            newErr.field = { ...fieldWithDoubleName };
            newErr.created = Date.now();
          }

          return newErr;
        })
      : [];

    if (isNewError) {
      const newError = {
        inputId: fieldId,
        message,
        showMessage: true,
        list: propType,
        field: { ...fieldWithDoubleName },
        created: Date.now()
      };

      newInputErrors.push(newError);
    }

    handleErrors(newInputErrors);
  }

  function handleDoublePropKeyError(passedField) {
    const inputId = passedField?.index
      ? `${propType}-${passedField?.index}-internalName-txt`
      : `${propType}-newInternalName-txt`;

    addError(
      inputId,
      t('requestFieldsList.errorMessage.doublePropKey', { key: passedField?.internalName }),
      passedField
    );
  }

  // check each existing property in group if there is a double internal name
  function checkIfInternalNameExists(passedField) {
    let existingInternalName = false;
    let doubleNamesCounter = 0;

    if (newFields) {
      newFields.map((field) => {
        if (field?.internalName === passedField.internalName && field.index !== passedField.index) {
          existingInternalName = true;
        }

        if (field?.internalName.startsWith(`${passedField.internalName}(`)) {
          doubleNamesCounter += 1;
        }

        return null;
      });
    }

    if (existingInternalName) {
      handleDoublePropKeyError(passedField);

      doubleNamesCounter += 1;
    }

    return doubleNamesCounter;
  }

  function handleFields(passedField, doubleNameField, fromDialog) {
    if (passedField && Object.keys(passedField)) {
      setIsLoading(true);

      if (fromDialog) {
        const errorIds = [
          `${propType}-${passedField?.index}-internalName-txt`,
          `${propType}-${passedField?.index}-mappedField-picker`
        ];

        if (doubleNameField) {
          errorIds.push(`${propType}-${doubleNameField?.index}-internalName-txt`);
        }

        resetErrorMessages(errorIds);
      }

      let requestFields = null;

      const newField = { ...passedField };

      if (newField?.index || newField.index === 0) {
        const newRequestField = {
          internalName: newField.internalName,
          defaultValue: newField.defaultValue,
          isRequired: newField.isRequired,
          description: newField.description,
          mappedField: newField.mappedField
        };

        if (propType === 'query') {
          newRequestField.usage = newField.usage;
          newRequestField.formatString = newField.formatString;
        }

        if (newFields?.length) {
          let isNewProp = true;

          requestFields = newFields.map((oldField) => {
            let tempField = oldField;

            if (newField.index === oldField.index) {
              tempField = newField;
              isNewProp = false;
            } else if (doubleNameField?.index === oldField.index) {
              tempField = { ...doubleNameField, internalName: doubleNameField.internalName };
              isNewProp = false;
            }

            const requestField = {
              internalName: tempField.internalName,
              defaultValue: tempField.defaultValue,
              isRequired: tempField.isRequired,
              description: tempField.description,
              mappedField: tempField.mappedField
            };

            if (propType === 'query') {
              requestField.usage = tempField.usage;
              requestField.formatString = tempField.formatString;
            }

            return requestField;
          });

          if (isNewProp) {
            requestFields.push(newRequestField);
          }
        } else {
          requestFields = [newRequestField];
        }
      } else if (newFields?.length) {
        requestFields = [...newFields, { ...newField }];
      } else {
        requestFields = [{ ...newField }];
      }

      if (newEditDialog?.show) {
        setNewEditDialog(null);
      }

      saveFields(requestFields);

      if (isLoading) {
        setIsLoading(false);
      }
    }
  }

  function handleInternalNameChange(passedField, value) {
    resetErrorMessages([`${propType}-${passedField?.index}-internalName-txt`]);

    const newRequestField = { ...passedField, internalName: value };

    const doubleNamesCounter = checkIfInternalNameExists(newRequestField);

    const doubleNameError = inputErrors?.find(
      (err) =>
        err.inputId === `${propType}-${err.field?.index}-internalName-txt` &&
        err.field?.index !== passedField.index &&
        err.field?.internalName === passedField.internalName &&
        err.message
    );

    let doubleNameField = null;

    if (doubleNameError) {
      resetErrorMessages([`${propType}-${doubleNameError.field.index}-internalName-txt`]);

      doubleNameField = doubleNameError.field;
    }

    if (doubleNamesCounter) {
      newRequestField.internalName = `${value}(${doubleNamesCounter})`;
    }

    handleFields(newRequestField, doubleNameField);
  }

  function handleMappedFieldChange(passedField, value) {
    resetErrorMessages([`${propType}-${passedField?.index}-mappedField-picker`]);

    if (!forAutocomplete && passedField.isRequired && !value?.[0]?.id) {
      addError(
        `${propType}-${passedField?.index}-mappedField-picker`,
        t('requestFieldsList.errorMessage.requiredMappedField')
      );
    }

    const newRequestField = { ...passedField, mappedField: value?.[0] };

    handleFields(newRequestField);
  }

  function handleRequiredChange(passedField, checked) {
    resetErrorMessages([`${propType}-${passedField?.index}-mappedField-picker`]);

    if (!forAutocomplete && checked && !passedField.mappedField?.id) {
      addError(
        `${propType}-${passedField?.index}-mappedField-picker`,
        t('requestFieldsList.errorMessage.requiredMappedField')
      );
    }

    const newRequestField = { ...passedField, isRequired: checked };

    handleFields(newRequestField);
  }

  function saveNewField(fieldFromDialog) {
    const newRequestField = fieldFromDialog ? { ...fieldFromDialog } : { ...newField };

    if (newRequestField && !newRequestField.index) {
      newRequestField.index = (newFields.length || 0) + 1;
    }

    if (newField) {
      setNewField({});
    }

    if (newEditDialog?.show) {
      onDismissNewEditDialog();
    }

    handleFields(newRequestField);
  }

  function cancelNewField() {
    setNewField({});

    resetErrorMessages([`${propType}-newInternalName-txt`]);
  }

  function handleNewField(name, value) {
    const field = { ...newField, [name]: value };

    setNewField(field);
  }

  function handleNewInternalName(value) {
    resetErrorMessages([`${propType}-newInternalName-txt`]);

    const internalNameExist = !!checkIfInternalNameExists({
      ...newField,
      internalName: value
    });

    if (!internalNameExist) {
      handleNewField('internalName', value);
    } else {
      handleNewField('internalName', newField?.internalName);
    }
  }

  function handleNewMappedField(value) {
    resetErrorMessages([`${propType}-newMappedField-picker`]);

    if (!forAutocomplete && newField.isRequired && !value?.[0]?.id) {
      addError(
        `${propType}-newMappedField-picker`,
        t('requestFieldsList.errorMessage.requiredMappedField')
      );
    }

    handleNewField('mappedField', value?.[0]);
  }

  function handleNewRequired(value) {
    resetErrorMessages([`${propType}-newMappedField-picker`]);

    if (!forAutocomplete && value && !newField?.mappedField?.id) {
      addError(
        `${propType}-newMappedField-picker`,
        t('requestFieldsList.errorMessage.requiredMappedField')
      );
    }

    handleNewField('isRequired', value);
  }

  function handleDeleteQueryField(passedField) {
    if (passedField) {
      const errorIds = [
        `${propType}-${passedField?.index}-internalName-txt`,
        `${propType}-${passedField?.index}-mappedField-picker`
      ];

      const fields = cloneObject(newFields);

      const doubleNameError = inputErrors?.find(
        (err) =>
          err.inputId === `${propType}-${err.field?.index}-internalName-txt` &&
          err.field?.index !== passedField.index &&
          err.field?.internalName === passedField.internalName &&
          err.message
      );

      let doubleNameField = null;

      if (doubleNameError) {
        errorIds.push(`${propType}-${doubleNameError.field.index}-internalName-txt`);

        doubleNameField = doubleNameError.field;
      }

      resetErrorMessages(errorIds);

      if (doubleNameField) {
        const field = fields?.find((field) => field.index === doubleNameField.index);

        if (field) {
          field.inetrnalName = passedField.inetrnalName;
        }
      }

      fields.splice(passedField.index - 1, 1);

      saveFields(fields);
    }
  }

  function getErrorMessage() {
    if (inputErrors?.length) {
      let lastError = null;

      inputErrors.sort((a, b) => {
        return new Date(a.created) - new Date(b.created);
      });

      for (let i = inputErrors.length - 1; i >= 0; i -= 1) {
        if (
          inputErrors[i].showMessage &&
          inputErrors[i].message &&
          inputErrors[i].list === propType
        ) {
          lastError = inputErrors[i];
          break;
        }
      }

      if (lastError?.inputId) {
        return (
          <MessageBarStyled
            messageBarType={MessageBarType.error}
            isMultiline
            onDismiss={() => resetErrorMessages([lastError.inputId], true)}
            dismissButtonAriaLabel="Close"
          >
            {lastError?.message}
          </MessageBarStyled>
        );
      }
    }

    return null;
  }

  function thereIsAnErrorMessage(inputId) {
    if (inputErrors?.length) {
      const error = inputErrors.find((err) => err.inputId === inputId);

      return !!error?.message;
    }

    return false;
  }

  // Column widths
  const internalNameColumnWidth = forAutocomplete ? 319 : 150;
  const mappedFieldColumnWidth = 360;
  const requiredColumnWidth = 60;

  const columns = [
    {
      key: 'internalName',
      name: t('requestFieldsList.column.internalName'),
      fieldName: 'internalName',
      minWidth: internalNameColumnWidth,
      maxWidth: internalNameColumnWidth,
      isResizable: true,
      isMultiline: true,
      onRender: (passedField) => {
        const errorInField = inputErrors?.find(
          (err) =>
            err.inputId === `${propType}-${passedField?.index}-internalName-txt` && err.message
        );

        return (
          <TextField
            key={`${propType}-${passedField?.index}-${passedField?.internalName}`}
            id={`${propType}-${passedField?.index}-internalName-txt`}
            onChange={(ev, value) => handleInternalNameChange(passedField, value)}
            defaultValue={
              errorInField?.field ? errorInField.field.internalName : passedField.internalName
            }
            borderless
            styles={{
              root: {
                width: '100%'
              },
              field: {
                borderRadius: 0,
                borderBottom: errorInField ? '2px solid rgb(164, 38, 44)' : 'none',
                selectors: {
                  '&:hover': {
                    borderRadius: 0,
                    borderBottom: errorInField
                      ? '2px solid rgb(164, 38, 44)'
                      : '1px solid rgb(220, 220, 220)'
                  },
                  '&:hover:focus': {
                    borderRadius: 0,
                    borderBottom: errorInField
                      ? '2px solid rgb(164, 38, 44)'
                      : `2px solid ${getTheme().palette.themePrimary}`
                  },
                  ':focus': {
                    borderRadius: 0,
                    borderBottom: errorInField
                      ? '2px solid rgb(164, 38, 44)'
                      : `2px solid ${getTheme().palette.themePrimary}`
                  }
                }
              }
            }}
          />
        );
      }
    }
  ];

  if (!forAutocomplete) {
    columns.push({
      key: 'mappedField',
      name: t('requestFieldsList.column.mappedField.name'),
      fieldName: 'mappedField',
      minWidth: mappedFieldColumnWidth,
      maxWidth: mappedFieldColumnWidth,
      isResizable: true,
      onRender: (passedField) => {
        const errorInField = thereIsAnErrorMessage(
          `${propType}-${passedField?.index}-mappedField-picker`
        );

        const borderBottomColorOnHover = () => {
          if (errorInField) {
            return '2px solid rgb(164, 38, 44)';
          }

          if (!passedField?.defaultValue) {
            return '1px solid rgb(220, 220, 220)';
          }

          return null;
        };

        const fieldPickerStyles = {
          input: {
            minWidth: mappedFieldColumnWidth,
            maxWidth: mappedFieldColumnWidth,
            borderBottom: errorInField ? '2px solid rgb(164, 38, 44)' : 'none',
            marginBottom: '-2px',
            selectors: {
              '&:hover': {
                border: 'none',
                borderRadius: 0,
                borderBottom: borderBottomColorOnHover()
              },
              ':focus': {
                border: 'none',
                borderRadius: 0,
                borderBottom: errorInField
                  ? '2px solid rgb(164, 38, 44)'
                  : `2px solid ${getTheme().palette.themePrimary}`
              }
            }
          },
          text: {
            border: 'none',
            selectors: {
              '::after': {
                border: 'none'
              }
            }
          }
        };

        return (
          <FieldPickerContainer id={`${propType}-${passedField?.index}-mappedField-picker`}>
            <FieldPicker
              onChange={(value) => handleMappedFieldChange(passedField, value)}
              selectedField={passedField?.mappedField}
              styles={fieldPickerStyles}
              disabled={!!passedField?.defaultValue}
            />
          </FieldPickerContainer>
        );
      }
    });
  }

  columns.push({
    key: 'required',
    name: t('requestFieldsList.column.required'),
    fieldName: 'required',
    minWidth: requiredColumnWidth,
    maxWidth: requiredColumnWidth,
    isResizable: true,
    onRender: (passedField) => (
      <Checkbox
        id={`${propType}-${passedField?.index}-required-chk`}
        checked={passedField?.isRequired}
        onChange={(ev, checked) => handleRequiredChange(passedField, checked)}
        className="centered"
        disabled={!!passedField?.defaultValue}
      />
    )
  });

  columns.push({
    key: 'actions',
    fieldName: 'action',
    isResizable: true,
    onRender: (passedField) => (
      <div style={{ display: 'flex' }} className="centered">
        <TooltipHost
          content={t('requestFieldsList.column.editField')}
          calloutProps={{ gapSpace: 5, target: `#btn-edit-field-${passedField.index}` }}
        >
          <IconButton
            id={`btn-edit-field-${propType}-${passedField.index}`}
            className="editButton"
            iconProps={{ iconName: 'Edit' }}
            onClick={() =>
              setNewEditDialog({
                show: true,
                selectedField: passedField,
                onlyDefault: !!passedField.defaultValue
              })
            }
            styles={{ root: { marginRight: '2px', width: '30px', height: '30px' } }}
          />
        </TooltipHost>
        <TooltipHost
          content={t('requestFieldsList.column.deleteField')}
          calloutProps={{ gapSpace: 5, target: `#btn-delete-field-${passedField.index}` }}
        >
          <IconButton
            id={`btn-delete-field-${propType}-${passedField.index}`}
            className="deleteButton"
            iconProps={{ iconName: 'Delete' }}
            onClick={() => handleDeleteQueryField(passedField)}
            styles={{ root: { width: '30px', height: '30px' } }}
          />
        </TooltipHost>
      </div>
    )
  });

  function getEditorRow() {
    const passedField = newField;
    const rowKey = passedField && Object.keys(passedField)?.length ? 'filled' : 'empty';

    const editorColumns = [
      {
        key: 'new-internalName',
        fieldName: 'internalName',
        minWidth: internalNameColumnWidth,
        maxWidth: internalNameColumnWidth,
        isResizable: true,
        isMultiline: true,
        onRender: (passedField) => {
          const errorInField = thereIsAnErrorMessage(`${propType}-newInternalName-txt`);

          return (
            <TextField
              key={`newInternalName-${rowKey}`}
              id={`${propType}-newInternalName-txt`}
              onChange={(ev, value) => handleNewInternalName(value)}
              defaultValue={passedField.internalName || ''}
              borderless
              styles={{
                root: {
                  width: '100%'
                },
                field: {
                  borderRadius: 0,
                  borderBottom: errorInField
                    ? '2px solid rgb(164, 38, 44)'
                    : '1px solid rgb(96, 94, 92)',
                  selectors: {
                    '&:hover': {
                      borderRadius: 0,
                      borderBottom: errorInField
                        ? '2px solid rgb(164, 38, 44)'
                        : '1px solid rgb(96, 94, 92)',
                      marginBottom: '2px'
                    },
                    '&:hover:focus': {
                      borderRadius: 0,
                      borderBottom: errorInField
                        ? '2px solid rgb(164, 38, 44)'
                        : `2px solid ${getTheme().palette.themePrimary}`,
                      marginBottom: 0
                    },
                    ':focus': {
                      borderRadius: 0,
                      borderBottom: errorInField
                        ? '2px solid rgb(164, 38, 44)'
                        : `2px solid ${getTheme().palette.themePrimary}`
                    }
                  }
                }
              }}
            />
          );
        }
      }
    ];

    if (!forAutocomplete) {
      editorColumns.push({
        key: 'new-mappedField',
        fieldName: 'mappedField',
        minWidth: mappedFieldColumnWidth,
        maxWidth: mappedFieldColumnWidth,
        isResizable: true,
        onRender: (passedField) => {
          const errorInField = thereIsAnErrorMessage(`${propType}-newMappedField-picker`);

          const borderBottomColorOnHover = () => {
            if (errorInField) {
              return '2px solid rgb(164, 38, 44)';
            }

            if (!passedField?.defaultValue) {
              return '1px solid rgb(96, 94, 92)';
            }

            return null;
          };

          const fieldPickerStyles = {
            input: {
              minWidth: mappedFieldColumnWidth,
              maxWidth: mappedFieldColumnWidth,
              borderRadius: 0,
              borderBottom: errorInField
                ? '2px solid rgb(164, 38, 44)'
                : '1px solid rgb(96, 94, 92)',
              marginBottom: '-2px',
              selectors: {
                '&:hover': {
                  border: 'none',
                  borderRadius: 0,
                  borderBottom: borderBottomColorOnHover(),
                  marginBottom: 0
                },
                '&:hover:focus': {
                  marginBottom: '-2px'
                },
                ':focus': {
                  border: 'none',
                  borderRadius: 0,
                  borderBottom: errorInField
                    ? '2px solid rgb(164, 38, 44)'
                    : `2px solid ${getTheme().palette.themePrimary}`
                }
              }
            },
            text: {
              border: 'none',
              selectors: {
                '::after': {
                  border: 'none'
                }
              }
            }
          };

          return (
            <FieldPickerContainer id={`${propType}-newMappedField-picker`}>
              <FieldPicker
                key={`newMappedField-${rowKey}`}
                onChange={handleNewMappedField}
                selectedField={passedField?.mappedField}
                styles={fieldPickerStyles}
              />
            </FieldPickerContainer>
          );
        }
      });
    }

    editorColumns.push({
      key: 'new-required',
      fieldName: 'required',
      minWidth: requiredColumnWidth,
      maxWidth: requiredColumnWidth,
      isResizable: true,
      onRender: (passedField) => (
        <Checkbox
          key={`newRequired-${rowKey}`}
          id={`${propType}-newRequired-chk`}
          className="centered"
          checked={passedField?.isRequired}
          onChange={(ev, checked) => handleNewRequired(checked)}
        />
      )
    });

    editorColumns.push({
      key: 'new-actions',
      isResizable: true,
      onRender: (passedField) => (
        <div style={{ display: 'flex' }} className="centered">
          <TooltipHost
            content={t('requestFieldsList.column.action.saveNewProp')}
            calloutProps={{
              gapSpace: 0,
              target: `#btn-add-new-field-${propType}`
            }}
          >
            <IconButton
              id={`btn-add-new-field-${propType}`}
              className="addButton"
              iconProps={{ iconName: 'Accept' }}
              onClick={() => saveNewField(null)}
              disabled={
                !passedField?.internalName ||
                thereIsAnErrorMessage(`${propType}-newInternalName-txt`) ||
                thereIsAnErrorMessage(`${propType}-newMappedField-picker`)
              }
              styles={{
                root: {
                  marginRight: '2px',
                  width: '30px',
                  height: '30px',
                  display:
                    passedField?.internalName &&
                    !(
                      thereIsAnErrorMessage(`${propType}-newInternalName-txt`) ||
                      thereIsAnErrorMessage(`${propType}-newMappedField-picker`)
                    )
                      ? 'inline-block'
                      : 'none'
                }
              }}
            />
          </TooltipHost>
          <TooltipHost
            content={t('requestFieldsList.column.action.cancelNewProp')}
            calloutProps={{
              gapSpace: 0,
              target: `#btn-cancel-new-field-${propType}`
            }}
          >
            <IconButton
              id={`btn-cancel-new-field-${propType}`}
              className="cancelButton"
              iconProps={{ iconName: 'Cancel' }}
              onClick={cancelNewField}
              styles={{
                root: {
                  width: '30px',
                  height: '30px',
                  display: passedField?.internalName || passedField?.name ? 'inline-block' : 'none'
                }
              }}
            />
          </TooltipHost>
        </div>
      )
    });

    return (
      <DetailsList
        items={[passedField] || []}
        compact
        columns={editorColumns}
        selectionMode={SelectionMode.none}
        layoutMode={DetailsListLayoutMode.fixedColumns}
        isHeaderVisible={false}
        onRenderRow={(rowProps, defaultRender) => {
          return (
            <RequestFieldRowStyled>
              {defaultRender({
                ...rowProps,
                styles: {
                  root: {
                    selectors: {
                      '&:hover': {
                        backgroundColor: 'transparent'
                      }
                    }
                  }
                }
              })}
            </RequestFieldRowStyled>
          );
        }}
      />
    );
  }

  function renderCustomHeaderTooltip(tooltipHostProps) {
    return tooltipHostProps?.column?.key === 'mappedField' ? (
      <ColumnNameWithInfo>
        <span style={{ fontWeight: 600 }}>{tooltipHostProps.children}</span>
        <TooltipHost
          tooltipProps={{
            onRenderContent: () => (
              <RichTextEditor
                defaultValue={t('requestFieldsList.column.mappedField.infoText')}
                disabled
                displayCmdBar={false}
              />
            )
          }}
          calloutProps={{ gapSpace: 0 }}
          directionalHint={DirectionalHint.topCenter}
          styles={{
            root: { display: 'inline-block', maxWidth: 200 }
          }}
        >
          <InfoIcon iconName="Info" className="info-icon" />
        </TooltipHost>
      </ColumnNameWithInfo>
    ) : (
      <ColumnName>{tooltipHostProps.children}</ColumnName>
    );
  }

  const propertiesKey = newEditDialog?.show ? 'requestFieldsWithDialog' : propType;

  return (
    <RequestFieldsListStyled>
      {!hidden ? (
        <>
          {getErrorMessage()}
          <DetailsList
            key={propertiesKey}
            items={newFields || []}
            compact
            columns={columns}
            selectionMode={SelectionMode.none}
            layoutMode={DetailsListLayoutMode.fixedColumns}
            onRenderRow={(rowProps, defaultRender) => {
              return (
                <RequestFieldRowStyled>
                  {defaultRender({
                    ...rowProps,
                    styles: {
                      root: {
                        selectors: {
                          '&:hover': {
                            backgroundColor: 'transparent'
                          }
                        }
                      }
                    }
                  })}
                </RequestFieldRowStyled>
              );
            }}
            onRenderDetailsHeader={(headerProps) => {
              return (
                <DetailsHeaderStyled
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...headerProps}
                  onRenderColumnHeaderTooltip={renderCustomHeaderTooltip}
                />
              );
            }}
          />
          {!disabledNewRow && getEditorRow()}
        </>
      ) : null}
      {newEditDialog?.show ? (
        <CreateRequestPropDialog
          requestProperty={newEditDialog?.selectedField}
          groupRequestProps={newFields}
          onSaveChanges={(field, doubleNameField) =>
            newEditDialog?.selectedField
              ? handleFields(field, doubleNameField, true)
              : saveNewField(field)
          }
          onDismiss={onDismissNewEditDialog}
          hidden={!newEditDialog?.show}
          propType={propType}
          url={url}
          forAutocomplete={forAutocomplete}
          onlyDefault={newEditDialog?.onlyDefault}
          inputErrors={inputErrors}
        />
      ) : null}
    </RequestFieldsListStyled>
  );
}

RequestFieldsList.propTypes = {
  hidden: PropTypes.bool,
  fields: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
  saveFields: PropTypes.func.isRequired,
  showCreateDialog: PropTypes.bool,
  hideCreateDialog: PropTypes.func,
  disabledNewRow: PropTypes.bool,
  url: PropTypes.string,
  propType: PropTypes.string.isRequired,
  forAutocomplete: PropTypes.bool,
  handleErrors: PropTypes.func,
  queryFieldsErrors: PropTypes.arrayOf(PropTypes.object)
};

RequestFieldsList.defaultProps = {
  hidden: false,
  fields: null,
  showCreateDialog: null,
  hideCreateDialog: null,
  disabledNewRow: null,
  url: null,
  forAutocomplete: false,
  handleErrors: null,
  queryFieldsErrors: null
};

export default RequestFieldsList;
