import RichTextEditor, { IRichTextEditorProps } from 'components/inputs/RichTextEditor';
import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ICommentProps } from 'types';
import { IconButton } from '@fluentui/react';
import { useTheme } from 'styled-components';
import { IEditorValueProps } from './Conversation.types';

export interface IEditorProps {
  /**
   * Comment to display
   */
  comment?: ICommentProps;
  /**
   * Optional flag to mark Editor as readOnly / disabled
   * @defaultvalue true
   */
  disabled?: boolean;
  /**
   * Determines whether the editor text can be edited
   */
  editMode?: boolean;
  /**
   * Text for the label
   */
  onCancelEditMode?: () => void;
  /**
   * Callback issued when the comment is saved
   */
  onSaveComment: (newComment: IEditorValueProps) => void;
  /**
   * Callback issued when a tag is clicked.
   */
  onTagClick?: IRichTextEditorProps['onTagClick'];
  /**
   * Input placeholder text. Displayed until text is entered.
   */
  placeholder?: IRichTextEditorProps['placeholder'];
  /**
   * Call to provide customized styling that will layer on top of the variant rules.
   */
  styles?: IRichTextEditorProps['styles'];
  /**
   * Determines whether the cmdBar should be displayed
   * @defaultvalue true
   */
  displayCmdBar?: boolean;
  defaultTextValue?: string | null;
  onChange?: (value: IEditorValueProps) => void;
}

function Editor({
  comment,
  defaultTextValue,
  onChange,
  disabled = true,
  displayCmdBar = true,
  editMode,
  onCancelEditMode,
  onSaveComment,
  onTagClick,
  placeholder,
  styles
}: IEditorProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  const [newCommentValue, setNewCommentValue] = useState<IEditorValueProps | null>(
    defaultTextValue ? { message: defaultTextValue, tags: [], mentions: [] } : null
  );
  const [editorKey, setEditorKey] = useState(1);

  useEffect(() => {
    // by giving a new key the default value is displayed when canceled/changed/edited or deleted
    setEditorKey((prevKey) => prevKey + 1);
  }, [disabled, comment?.message]);

  /**
   * Sets the state to track current editor value
   */
  const onRTEChange = debounce(({ value, mentions, tags }) => {
    if (!disabled) {
      let valueToProvide = value;

      if (valueToProvide === '<p><br></p>') {
        // empty value
        valueToProvide = null;
      }

      if (onChange) {
        onChange({ message: valueToProvide, mentions, tags });
      }

      setNewCommentValue({ message: valueToProvide, mentions, tags });
    }
  }, 250);

  /**
   * handle saving. Only saves if a message is provided.
   */
  function onSave(): void {
    if (onSaveComment && newCommentValue) {
      const { message, mentions, tags } = newCommentValue;

      if (message) {
        onSaveComment({ message, mentions, tags });
      }
    }
  }

  /**
   * Cancel editing. Reset state
   */
  function onCancel(): void {
    setNewCommentValue(null);

    if (onCancelEditMode) {
      onCancelEditMode();
    }
  }

  const editorStyles: IRichTextEditorProps['styles'] = {
    ...styles,
    editor: {
      backgroundColor: `rgb(${theme.conversation.editorBackground})`,
      // padding: '10px 15px 8px 15px',
      ...styles?.editorWrapper
    }
  };

  return (
    <div className="c-conversation-editor-wrapper">
      <RichTextEditor
        allowMentioning
        allowTagging
        defaultValue={defaultTextValue || newCommentValue?.message || comment?.message}
        disabled={disabled}
        displayCmdBar={displayCmdBar}
        key={editorKey}
        onChange={onRTEChange}
        onTagClick={onTagClick}
        placeholder={placeholder}
        required={false}
        setInitialFocus
        styles={editorStyles}
      />
      {!disabled && !editMode && (
        <div style={{ position: 'relative' }}>
          <IconButton
            disabled={false}
            iconProps={{ iconName: 'Send', styles: { root: { fontSize: 16 } } }}
            onClick={onSave}
            styles={{ root: { position: 'absolute', top: '-34px', right: '4px' } }}
            title={t('conversation.editor.send')}
          />
        </div>
      )}
      {!disabled && editMode && (
        <div style={{ position: 'relative' }}>
          <IconButton
            disabled={false}
            iconProps={{ iconName: 'CheckMark', styles: { root: { fontSize: 16 } } }}
            onClick={onSave}
            styles={{ root: { position: 'absolute', top: '-42px', right: '4px' } }}
            title={t('conversation.editor.save')}
          />
          <IconButton
            disabled={false}
            iconProps={{ iconName: 'Cancel', styles: { root: { fontSize: 16 } } }}
            onClick={onCancel}
            styles={{ root: { position: 'absolute', top: '-42px', right: '34px' } }}
            title={t('conversation.editor.cancel')}
          />
        </div>
      )}
    </div>
  );
}

export default Editor;
