import styled from 'styled-components';
import {
  Table as FluentTable,
  TableBody,
  TableCell,
  TableCellLayout,
  TableHeader,
  TableHeaderCell,
  TableRow
} from '@fluentui/react-components';

const TableWrapper = styled.div`
  .fui-TableHeaderCell {
    padding-left: 8px;
    padding-right: 8px;
    font-size: 14px;
    color: rgb(36, 36, 36);
  }

  .fui-TableRow {
    border-bottom-color: #e0e0e0;
    border-bottom-width: 1px;
  }
`;

interface ITableCell {
  media?: React.ReactElement;
  main: React.ReactElement;
}

interface ITableItem {
  [key: string]: ITableCell | string;
  key: string;
}

interface ITableProps {
  items: ITableItem[];
  columns: { columnKey: string; label: string }[];
}

export default function Table({ items, columns }: ITableProps) {
  return (
    <TableWrapper>
      <FluentTable arial-label="Default table">
        <TableHeader>
          <TableRow>
            {columns.map((column) => (
              <TableHeaderCell key={column.columnKey}>{column.label}</TableHeaderCell>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {items.map((item) => (
            <TableRow key={item.key}>
              {columns.map((column) => {
                const defaultTableItemProps: ITableCell = {
                  media: <div />,
                  main: <div />
                };

                const tableItemProps = (item[column.columnKey] ||
                  defaultTableItemProps) as ITableCell;

                return (
                  <TableCell>
                    <TableCellLayout media={tableItemProps.media}>
                      {tableItemProps.main}
                    </TableCellLayout>
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </FluentTable>
    </TableWrapper>
  );
}
