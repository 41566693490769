import { createContext, useContext, useMemo, useState } from 'react';

export interface IProjectContext {
  projectId: string | null;
  templateId: string | null;
  setProjectId: (id: string) => void;
  setTemplateId: (id: string) => void;
}

const ProjectContext = createContext<IProjectContext>({
  projectId: null,
  templateId: null,
  setProjectId: () => undefined,
  setTemplateId: () => undefined
});

const TasksLoadingContext = createContext({
  tasksLoading: [],
  toggleLoading: () => []
} as {
  tasksLoading: string[];
  toggleLoading: (taskId: string) => void;
});

export const useProject = () => useContext(ProjectContext);
export const useTasksLoadingState = () => useContext(TasksLoadingContext);

export function ProjectProvider({ children }) {
  const [projectId, setProjectId] = useState(null);
  const [templateId, setTemplateId] = useState(null);

  const value = useMemo(
    () => ({
      projectId,
      templateId,
      setProjectId: (newId: string) => {
        if (newId && newId === projectId) return;

        setProjectId(newId);
        setTemplateId(null);
      },
      setTemplateId: (newId: string) => {
        if (newId && newId === templateId) return;

        setTemplateId(newId);
        setProjectId(null);
      }
    }),
    [projectId, templateId]
  );

  return (
    <ProjectContext.Provider value={value}>
      <TasksLoadingProvider>{children}</TasksLoadingProvider>
    </ProjectContext.Provider>
  );
}

function TasksLoadingProvider({ children }) {
  const [tasksLoading, setTasksLoading] = useState([]);

  const value = useMemo(
    () => ({
      tasksLoading,
      toggleLoading: (taskId: string) => {
        setTasksLoading((prevState) => {
          if (prevState.includes(taskId)) {
            return prevState.filter((id) => id !== taskId);
          }

          return [...prevState, taskId];
        });
      }
    }),
    [tasksLoading]
  );

  return <TasksLoadingContext.Provider value={value}>{children}</TasksLoadingContext.Provider>;
}
