import { LoadingSpinner } from 'components';
import { AppContext } from 'features/App';
import debounce from 'lodash/debounce';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import fetchRequest, { apiErrorHandler } from 'services/api';
import styled from 'styled-components';
import { ITagProps } from 'types';
import breakpoints from 'utils/breakpoints';
import { checkScreenWidth } from 'utils/helpers';
import {
  Callout,
  DirectionalHint,
  SearchBox as FluentSearchBox,
  ISearchBox,
  IconButton
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';

const SearchBoxContainerStyled = styled.div`
  max-width: 300px;
  width: 100%;
  margin: 0px 10px;

  .searchBox {
    border-radius: 5px;
    border: none;
    overflow: hidden;

    width: 100%;
    background: rgb(${({ theme }) => theme.header.searchBackground} / 0.6);
    color: rgb(${({ theme }) => theme.header.searchForeground});

    &:after {
      display: none;
    }

    input {
      color: inherit;
      &::placeholder {
        color: rgb(${({ theme }) => theme.header.searchForeground} / 0.55);
      }
    }
  }

  @media (max-width: ${breakpoints.extraSmallMax}px) {
    margin: 0px 50px;
    max-width: calc(100% - 105px);
    position: absolute;
    z-index: 10;

    .searchBox {
      background: rgb(${({ theme }) => theme.header.searchBackground});
      height: 36px;
    }
  }

  .ms-Button .ms-Button-icon {
    color: rgb(${({ theme }) => theme.header.searchForeground});
  }
  .ms-Button:hover {
    background: rgb(${({ theme }) => theme.header.searchBackground} / 0.6);
  }
`;

const CalloutContentContainer = styled.div`
  display: flex;
  flex-direction: column;

  .suggestions-title {
    padding: 0px 10px;
    line-height: 35px;
    font-size: 12px;
    border-bottom: 1px solid rgb(237, 235, 233);
    box-shadow: 0 0 3.6px rgb(0 0 0 / 13%), 0 0 0.9px rgb(0 0 0 / 10%);
  }

  .suggestion-item {
    padding: 0px 10px;
    line-height: 35px;
    font-size: 13px;
    border-bottom: 1px solid rgb(237, 235, 233);

    &:hover {
      cursor: pointer;
      background: rgb(237, 235, 233);
      color: ${({ theme }) => theme.themePrimary};
    }
  }

  .suggestions-loading {
    padding: 0px 10px;
    height: 45px;
    display: flex;
    align-items: left;
  }

  .suggestions-no-results {
    font-size: 12px;
    text-align: center;
    line-height: 30px;
  }

  .suggestions-wrapper {
    max-height: 300px;
    overflow-y: auto;
  }
`;

const CalloutStyled = styled(Callout)`
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  overflow: hidden;
`;

const IconButtonStyled = styled(IconButton)<{ searchActive: boolean }>`
  min-width: 50px;
  min-height: 45px;

  .ms-Button-icon {
    color: ${({ theme }) => theme.white};
  }

  background: ${({ theme, searchActive }) =>
    searchActive ? `${theme.white}99` : theme.themePrimary};

  .ms-Button-icon {
    color: ${({ theme, searchActive }) => (searchActive ? theme.themePrimary : theme.white)};
  }
`;

function SearchBox() {
  const { t } = useTranslation();
  const searchBoxId = useId('searchBox-');

  const navigate = useNavigate();

  const [searchTerm, setsearchTerm] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const searchBoxRef = useRef<HTMLDivElement>(null);
  const searchBoxComponentRef = useRef<ISearchBox>(null);

  const [displayBox, setDisplayBox] = useState(!checkScreenWidth(['extraSmall']));

  const [isCalloutVisible, setIsCalloutVisible] = useState(false);

  const [searchSuggestions, setSearchSuggestions] = useState<ITagProps[] | null>(null);

  const { globalAppState, dispatch } = useContext(AppContext);
  const globalAppStateSearchTerm = globalAppState.searchTerm;

  const [searchBoxKey, setSearchBoxKey] = useState(globalAppStateSearchTerm || 1);

  useEffect(() => {
    if (!globalAppStateSearchTerm) {
      setsearchTerm(null);
    }

    setSearchBoxKey(globalAppStateSearchTerm || 1);
  }, [globalAppStateSearchTerm]);

  const onSearch = useCallback(
    (seachTerm: string | null) => {
      dispatch({ type: 'searchTerm', data: seachTerm });
    },
    [dispatch]
  );

  useEffect(() => {
    if (searchTerm?.startsWith('#')) {
      setIsCalloutVisible(true);
    }
  }, [searchTerm]);

  const onSearchBoxChange = debounce((value) => {
    setsearchTerm(() => {
      if (!value || value === '') {
        return null;
      }

      return value;
    });
  }, 500);

  const getTagSearchResults = useCallback(async () => {
    const url = `tags?showDeleted=true&searchTerms=${encodeURIComponent(searchTerm as string)}`;

    let tags = (await fetchRequest({
      url,
      method: undefined,
      origin: undefined,
      body: undefined,
      ignoreContentType: undefined,
      signal: undefined,
      ignoreAlert: undefined,
      ignoreConsent: undefined,
      retries: undefined,
      anonymousCall: undefined
    }).catch(apiErrorHandler)) as ITagProps[];

    if (tags?.length) {
      tags = tags.filter((tag) => !tag.children?.length);
      tags = tags.filter((tag) => !tag.isDeleted);
    }

    return tags;
  }, [searchTerm]);

  useEffect(() => {
    if (searchTerm) {
      if (searchTerm.startsWith('#')) {
        setIsLoading(true);

        getTagSearchResults().then((tags) => {
          setSearchSuggestions(tags);
          setIsCalloutVisible(true);
          setIsLoading(false);
        });
      }
    }
  }, [getTagSearchResults, onSearch, searchTerm]);

  function onSuggestionClick(tag: ITagProps) {
    setIsCalloutVisible(false);
    setSearchSuggestions(null);
    setsearchTerm(null);

    setSearchBoxKey(Math.random());

    if (checkScreenWidth(['small', 'extraSmall'])) {
      setDisplayBox(false);
    }

    navigate(`/tags/${tag.id}`);
  }

  function resetSearch() {
    setIsCalloutVisible(false);
    setSearchSuggestions(null);
    onSearch(null);
  }

  function onSearchCommit(value: string | null) {
    if (value && !value.startsWith('#')) {
      setIsCalloutVisible(false);
      setSearchSuggestions(null);
      onSearch(value);

      if (checkScreenWidth(['small', 'extraSmall'])) {
        setDisplayBox(false);
      }
    }

    if (!value) {
      resetSearch();
    }
  }

  function onIconButtonClick() {
    setDisplayBox(true);

    if (!checkScreenWidth(['extraSmall'])) {
      // if not mobile, focus search box after 100ms
      setTimeout(() => {
        searchBoxComponentRef.current?.focus();
      }, 100);
    }
  }

  function onSearchBoxBlur() {
    if (!searchTerm && checkScreenWidth(['small', 'extraSmall'])) {
      setDisplayBox(false);
    }
  }

  function getCalloutContent() {
    const width = searchBoxRef?.current?.offsetWidth;

    if (!searchSuggestions?.length && isLoading) {
      return (
        <CalloutContentContainer style={{ width }}>
          <div className="suggestions-loading">
            <LoadingSpinner
              size={2}
              label={t('header.searchBox.suggestions.loading')}
              labelPosition="right"
            />
          </div>
        </CalloutContentContainer>
      );
    }

    if (!searchSuggestions?.length) {
      return (
        <CalloutContentContainer style={{ width }}>
          <div className="suggestions-no-results">
            {t('header.searchBox.suggestions.noResults')}
          </div>
        </CalloutContentContainer>
      );
    }

    return (
      <CalloutContentContainer style={{ width }}>
        <div className="suggestions-title">{t('header.searchBox.suggestions.title')}</div>
        <div className="suggestions-wrapper">
          {searchSuggestions?.map((suggestion) => {
            return (
              <div
                className="suggestion-item"
                role="presentation"
                key={`suggestion-${suggestion.id}`}
                onClick={() => onSuggestionClick(suggestion)}
              >
                {`#${suggestion.fullText}`}
              </div>
            );
          })}
        </div>
      </CalloutContentContainer>
    );
  }

  if (!displayBox) {
    return (
      <IconButtonStyled
        searchActive={!!globalAppStateSearchTerm}
        className={`searchBox-iconButton ${globalAppStateSearchTerm ? 'search-active' : ''}`}
        iconProps={{ iconName: 'Search' }}
        onClick={onIconButtonClick}
      />
    );
  }

  return (
    <SearchBoxContainerStyled>
      <div id={searchBoxId}>
        <FluentSearchBox
          id={`${searchBoxId}-input`}
          key={searchBoxKey}
          ref={searchBoxRef}
          autoFocus={checkScreenWidth(['extraSmall'])}
          componentRef={searchBoxComponentRef}
          onBlur={onSearchBoxBlur}
          className="searchBox"
          defaultValue={globalAppStateSearchTerm || undefined}
          placeholder={t('searchBar.placeholder')}
          autoComplete="off"
          onClear={() => setsearchTerm(null)}
          onChange={(_, value) => {
            onSearchBoxChange(value);

            if (!value || value === '') {
              onSearchCommit(null);
            }
          }}
          onSearch={onSearchCommit}
        />
      </div>
      {isCalloutVisible && (
        <CalloutStyled
          gapSpace={3}
          target={`#${searchBoxId}`}
          onDismiss={() => setIsCalloutVisible(false)}
          setInitialFocus
          isBeakVisible={false}
          directionalHint={DirectionalHint.bottomLeftEdge}
        >
          {getCalloutContent()}
        </CalloutStyled>
      )}
    </SearchBoxContainerStyled>
  );
}

export default SearchBox;
