// FIXME: remove this and replace with a better system
import { useEffect, useState } from 'react';

const globalLoadingTasks = new Map<string, Set<string>>();
const subscribers = new Map<string, Set<() => void>>();

function subscribe(taskId: string, handler: () => void) {
  if (!subscribers.has(taskId)) subscribers.set(taskId, new Set());
  subscribers.get(taskId).add(handler);
}

function unsubscribe(taskId: string, handler: () => void) {
  const items = subscribers.get(taskId);
  if (!items) return;
  items.delete(handler);
  if (!items.size) subscribers.delete(taskId);
}

function dispatchEvent(taskId: string) {
  for (const sub of subscribers.get(taskId) || []) {
    sub();
  }
}

export interface GlobalTaskLoadingToken {
  markFinished();
}

export function enterGlobalTaskLoadingState(taskId: string): GlobalTaskLoadingToken {
  if (!globalLoadingTasks.has(taskId)) globalLoadingTasks.set(taskId, new Set());
  const token = Math.random().toString();
  globalLoadingTasks.get(taskId).add(token);

  dispatchEvent(taskId);

  return {
    markFinished() {
      globalLoadingTasks.get(taskId).delete(token);
      if (!globalLoadingTasks.get(taskId).size) globalLoadingTasks.delete(taskId);

      dispatchEvent(taskId);
    }
  };
}

export function useGlobalTaskLoadingState(taskId: string | undefined, delay = 1000) {
  const [isLoading, setLoading] = useState(globalLoadingTasks.has(taskId));
  const [delayedLoading, setDelayedLoading] = useState(isLoading);

  useEffect(() => {
    setLoading(globalLoadingTasks.has(taskId));
    setDelayedLoading(globalLoadingTasks.has(taskId));

    const handler = () => {
      setLoading(globalLoadingTasks.has(taskId));
    };

    subscribe(taskId, handler);
    return () => {
      unsubscribe(taskId, handler);
    };
  }, [taskId]);

  useEffect(() => {
    if (isLoading) {
      const timeout = setTimeout(() => {
        setDelayedLoading(true);
      }, delay);
      return () => {
        clearTimeout(timeout);
      };
    }

    setDelayedLoading(false);
    return () => undefined;
  }, [delay, isLoading]);

  return delayedLoading;
}

// prevent leaving during pending task mutations
window.addEventListener('beforeunload', (e) => {
  alert(globalLoadingTasks.size);
  if (globalLoadingTasks.size) {
    e.preventDefault();
  }
});
