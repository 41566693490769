import styled from 'styled-components';
import breakpoints from 'utils/breakpoints';
import { getTheme } from '@fluentui/react';

const AttachmentsContainerStyled = styled.div`
  background-color: #f5f5f5;
  height: 100%;
  padding: 1rem 1.5rem;
  overflow: auto;

  @media (max-width: ${breakpoints.extraSmallMax}px) {
    padding: 1rem 10px;
  }

  .files-wrapper,
  .files-dropzone {
    width: 100%;
    height: 100%;
  }

  .files-dropzone-active {
    background: #e9e9e9;
    border-radius: 6px;
    transition: background 0.7s ease-out;
  }

  .rte-base > .editor-container {
    border-bottom: 2px solid white;
    transition: border-bottom 0.1s ease-out;
  }

  .rte-focused > .editor-container {
    border-bottom: 2px solid ${() => getTheme().palette.themePrimary};
    transition: border-bottom 0.1s ease-out;
  }

  .document-image-preview {
    height: 69px;
    max-width: 135px;
    object-fit: cover;

    :hover {
      cursor: pointer;
    }
  }

  .center-child {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .c-file-upload-job {
    margin: 1em;
  }
`;

export default AttachmentsContainerStyled;
