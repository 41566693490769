import { CombinedPickerJS as CombinedPicker } from 'components';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Label,
  PrimaryButton
} from '@fluentui/react';

function ReplaceMemberDialog({ hidden, onCancel, onConfirm, member }) {
  const { t } = useTranslation();
  const [selectedMember, setSelectedMember] = useState(null);

  function close() {
    onCancel();
  }

  function onConfirmNewMember() {
    return onConfirm(selectedMember);
  }

  function getDialogStyles() {
    return {
      main: [
        {
          selectors: {
            '@media (min-width: 650px)': {
              width: '100%',
              minWidth: '550px',
              minHeight: '200px'
            },
            '@media (max-width: 480px)': [
              {
                position: 'absolute',
                top: '10px',
                width: '100%',
                selectors: {
                  '.ms-Dialog-header': [
                    {
                      selectors: {
                        '.ms-Dialog-title': {
                          padding: '11px',
                          fontSize: '16px',
                          paddingRight: '40px'
                        },
                        'div[class^="topButton-"]': {
                          padding: '5px'
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        }
      ]
    };
  }

  async function handleSelectedUser(value) {
    if (value && value.length > 0) {
      const { userId, login, primaryText, name, pictureUrl, tenantId } = value[0];
      const newMember = {
        teamId: member ? member.teamId : '00000000-0000-0000-0000-000000000000',
        userId: userId || '00000000-0000-0000-0000-000000000000',
        login: login || primaryText || '',
        name: name || '',
        pictureUrl: pictureUrl || '',
        tenantId: tenantId || '00000000-0000-0000-0000-000000000000',
        roleId: member ? member.roleId : 0
      };
      setSelectedMember(newMember);
    } else if (selectedMember) {
      setSelectedMember(null);
    }
  }

  return (
    <Dialog
      hidden={hidden}
      onDismiss={() => close()}
      styles={getDialogStyles}
      dialogContentProps={{
        type: DialogType.close,
        title: member ? t('replaceMember.dialog.title') : t('replaceMember.dialog.multiple.title'),
        subText: member
          ? t('replaceMember.dialog.subText')
          : t('replaceMember.dialog.multiple.subText')
      }}
      modalProps={{
        isBlocking: true
      }}
    >
      <Label required>{t('replaceMember.dialog.selectNewEmployee')}</Label>
      {member && member.teamMembers ? (
        <CombinedPicker
          searchUser
          selectedItems={selectedMember}
          onChange={handleSelectedUser}
          createNewUserAllowed
          userToFilter={member ? member.teamMembers : null}
          type={1}
        />
      ) : (
        <CombinedPicker
          createNewUserAllowed
          searchUser
          selectedItems={selectedMember}
          onChange={handleSelectedUser}
          type={1}
        />
      )}
      <DialogFooter>
        <PrimaryButton
          disabled={!(selectedMember && selectedMember.userId)}
          onClick={() => onConfirmNewMember()}
          text={t('replaceMember.dialog.button.save')}
        />
        <DefaultButton onClick={() => close()} text={t('replaceMember.dialog.button.cancel')} />
      </DialogFooter>
    </Dialog>
  );
}

ReplaceMemberDialog.propTypes = {
  hidden: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  member: PropTypes.shape({
    teamId: PropTypes.string,
    roleId: PropTypes.number,
    userId: PropTypes.string,
    login: PropTypes.string,
    name: PropTypes.string,
    pictureUrl: PropTypes.string,
    tenantId: PropTypes.string,
    teamMembers: PropTypes.arrayOf(PropTypes.object)
  })
};

ReplaceMemberDialog.defaultProps = {
  hidden: true,
  member: null
};

export default ReplaceMemberDialog;
