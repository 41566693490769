import { Dialog, LoadingSpinner } from 'components';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import fetchRequest, { apiErrorHandler } from 'services/api';
import styled from 'styled-components';
import { ConstrainMode, DetailsList, SelectionMode } from '@fluentui/react';

/* a dialog to validate if a item can be deleted.
  can be used for fields (fieldgroups & fields), roles, teams, processTypes and end2EndTypes */

const DetailsListStyled = styled(DetailsList)`
  .ms-DetailsList-headerWrapper {
    position: sticky;
    top: 0;
    z-index: 100;
  }
`;

function ValidateDeleteDialog({ field, role, onDelete, onCancel, definition }) {
  const { t } = useTranslation();

  const [hidden, setHidden] = useState(true);
  const [deleting, setDeleting] = useState(false);
  const [validationResult, setValidationResult] = useState(null);

  const getUrlToValidate = useCallback(() => {
    if (field?.fields) return `Route/FieldGroup/ValidateDelete/${field.id}`;

    if (field) return `Route/Field/ValidateDelete/${field.id}`;

    if (role) return `Role/ValidateDelete/${role.id}`;

    if (definition) return `Route/Definition/ValidateDelete/${definition.id}`;

    return null;
  }, [field, role, definition]);

  useEffect(() => {
    if (field?.id || role?.id || definition?.id) {
      // display the dialog
      setHidden(false);

      const url = getUrlToValidate();

      if (!url) return;

      // validate if the item can be deleted
      fetchRequest({ url, method: 'PUT' })
        .then((result) => {
          setValidationResult(result);
        })
        .catch(apiErrorHandler);
    }
  }, [field, getUrlToValidate, role, definition]);

  function getDeleteType() {
    if (field?.fields) return 'fieldGroup';

    if (field) return 'field';

    if (role) return 'role';

    if (definition?.serviceData) return 'externalService';

    if (definition) return 'processTemplate';

    return '';
  }

  const context = getDeleteType();

  function onDismiss() {
    if (onCancel) onCancel();

    setHidden(true);
    setDeleting(false);

    setValidationResult(null);
  }

  function getDetailsList() {
    let items = [];
    let nameLabel = t('globals.processTemplate');

    const {
      involvedDefinitions,
      involvedFields,
      involvedInstances,
      involvedGroups,
      involvedInstancesWithFields
    } = validationResult;

    if (involvedDefinitions?.length) {
      items = involvedDefinitions;
    } else if (involvedInstances?.length) {
      items = involvedInstances;
      nameLabel = t('globals.processInstance');
    } else if (involvedGroups?.length) {
      items = involvedGroups;
      nameLabel = t('globals.fieldGroup');
    } else if (involvedInstancesWithFields?.length) {
      items = involvedInstancesWithFields;
      nameLabel = t('globals.processInstance');
    } else if (involvedFields?.length) {
      items = involvedFields;
      nameLabel = t('globals.externalField');
    }

    return (
      <div style={{ maxHeight: 450, overflowY: 'auto' }}>
        <DetailsListStyled
          compact
          items={items}
          selectionMode={SelectionMode.none}
          onShouldVirtualize={() => false}
          constrainMode={ConstrainMode.unconstrained}
          selectionPreservedOnEmptyClick
          enterModalSelectionOnTouch
          columns={[
            {
              key: 'name',
              fieldName: 'name',
              name: nameLabel,
              isResizable: true,
              minWidth: 150
            },
            {
              key: 'Team',
              fieldName: 'displayTitle',
              name: 'Team',
              isResizable: true,
              minWidth: 200,
              onRender: (item) => item.team?.displayTitle || '-'
            }
          ]}
        />
      </div>
    );
  }

  function renderSpinner() {
    const label = deleting
      ? t('validateDeleteDialog.deleting', { context })
      : t('validateDeleteDialog.validating');

    return (
      <LoadingSpinner
        size={3}
        label={label}
        labelPosition="bottom"
        styles={{ container: { margin: 10 } }}
      />
    );
  }

  function getDialogContent() {
    if (hidden) return undefined;

    if (!validationResult || deleting) {
      return renderSpinner();
    }

    if (
      validationResult.involvedDefinitions?.length ||
      validationResult.involvedInstances?.length ||
      validationResult.involvedGroups?.length ||
      validationResult.involvedInstancesWithFields?.length ||
      validationResult.involvedFields?.length
    ) {
      return getDetailsList();
    }

    return undefined;
  }

  function deleteRole() {
    const forceDelete = validationResult.involvedDefinitions?.length;

    // set deleting to true to display the spinner
    setDeleting(true);

    let url = `Role/${role.id}`;

    if (forceDelete) url += '?forceDelete=true';

    fetchRequest({ url, method: 'DELETE', ignoreAlert: true })
      .then(() => {
        if (onDelete) onDelete(role);

        onDismiss();
      })
      .catch(apiErrorHandler);
  }

  function deleteField() {
    // set deleting to true to display the spinner
    setDeleting(true);

    let url = `Route/Field/${field.id}`;

    if (context === 'fieldGroup') url = `Route/FieldGroup/${field.id}`;

    fetchRequest({ url, method: 'DELETE', ignoreAlert: true })
      .then(() => {
        if (onDelete) onDelete(field);

        onDismiss();
      })
      .catch(apiErrorHandler);
  }

  function deleteDefinition() {
    setDeleting(true);

    fetchRequest({ url: `Route/Definition/${definition.id}`, method: 'DELETE', ignoreAlert: true })
      .then(() => {
        if (onDelete) onDelete(definition);

        onDismiss();
      })
      .catch(apiErrorHandler);
  }

  function primaryButtonFunc() {
    if (!context) return;

    if (context === 'field' || context === 'fieldGroup') deleteField();

    if (context === 'role') deleteRole();

    if (context === 'processTemplate' || context === 'externalService') deleteDefinition();
  }

  function isPrimaryButtonDisabled() {
    if (!validationResult || deleting) return true;

    const {
      involvedDefinitions,
      involvedInstances,
      involvedGroups,
      involvedInstancesWithFields,
      involvedFields
    } = validationResult;

    if (
      involvedDefinitions?.length ||
      involvedInstances?.length ||
      involvedGroups?.length ||
      involvedInstancesWithFields?.length ||
      involvedFields?.length
    ) {
      return true;
    }

    return false;
  }

  function getDialogTitle() {
    return t(`validateDeleteDialog.delete`, { context });
  }

  function getSubText() {
    if (!validationResult || deleting) return undefined;

    const title = role?.name || field?.name || definition?.name;

    if (validationResult.involvedDefinitions?.length > 0) {
      return t(`validateDeleteDialog.usedInTemplates`, { context, title });
    }

    if (validationResult.involvedInstances?.length > 0) {
      return t(`validateDeleteDialog.usedInInstances`, { context, title });
    }

    if (validationResult.involvedGroups?.length > 0) {
      return t(`validateDeleteDialog.usedInGroups`, { context, title });
    }

    if (validationResult.involvedInstancesWithFields?.length > 0) {
      return t(`validateDeleteDialog.usedInInstancesWithFields`, { context, title });
    }

    if (validationResult.involvedFields?.length > 0) {
      return t(`validateDeleteDialog.usedInFields`, { context, title });
    }

    return t('validateDeleteDialog.confirm', { context });
  }

  if (hidden) return null;

  return (
    <Dialog
      defaultButtonProps={{
        text: t('globals.cancel'),
        onClick: onDismiss
      }}
      primaryButtonProps={{
        disabled: isPrimaryButtonDisabled(),
        text: t(`globals.delete`),
        onClick: primaryButtonFunc
      }}
      content={<div style={{ marginTop: 10 }}>{getDialogContent()}</div>}
      hidden={false}
      onDismiss={onDismiss}
      subText={<div style={{ margin: '10px 0' }}>{getSubText()}</div>}
      title={getDialogTitle()}
      styles={{
        main: [
          {
            selectors: {
              '@media (min-width: 850px)': {
                width: '100%',
                minWidth: 600,
                minHeight: 170
              }
            }
          }
        ]
      }}
    />
  );
}

ValidateDeleteDialog.propTypes = {
  field: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    fields: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  role: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  }),
  definition: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    serviceData: PropTypes.shape({})
  }),

  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

ValidateDeleteDialog.defaultProps = {
  field: null,
  role: null
};

export default ValidateDeleteDialog;
