import { DynamicField } from 'components';
import { AppContext } from 'features/App';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import fetchRequest from 'services/api';
import {
  fileContents,
  getDefinition,
  getDefinitionExternalData,
  getDefinitionExternalDataList,
  getStreamUrl
} from 'services/fetchRequests';
import styled from 'styled-components';
import { ChoiceFieldFormat, IFieldLinkValueProps, IFieldProps } from 'types';
import { onCombinedPickerSearch } from 'utils/helpers';
import { Dropdown, Shimmer, ShimmerElementType } from '@fluentui/react';

const FilterValueWrapper = styled.div`
  margin-left: 28px;
  div:first-child {
    margin-top: 0px;
  }
`;

export interface IDataFieldFilterRowProps {
  selectableFields: {
    field: IFieldProps;
    showInList: boolean;
  }[];
  loading: boolean;
  disabled: boolean;
  fieldId: string;
  onFieldSelection: (field: {
    id: string;
    name: string;
    fieldType: IFieldProps['fieldType'];
  }) => void;
  onFilterValueChange: (onChangeProps: { value?: IFieldLinkValueProps }) => void;
  filterValue: unknown;
}

export default function DataFieldFilterRow({
  selectableFields,
  loading,
  fieldId,
  disabled,
  onFieldSelection,
  onFilterValueChange,
  filterValue
}: IDataFieldFilterRowProps) {
  const { t } = useTranslation();

  const [field, setfield] = useState(null);
  const [fieldLoading, setFieldLoading] = useState(false);

  const { globalAppState } = useContext(AppContext);
  const { currentTenantId } = globalAppState;

  useEffect(() => {
    if (fieldId) {
      setFieldLoading(true);
      fetchRequest({ url: `Route/Field/${fieldId}` }).then((response) => {
        setFieldLoading(false);
        setfield(response);
      });
    }

    return () => setfield(null);
  }, [fieldId]);

  function renderDataFieldsFilterValue() {
    if (fieldLoading)
      return (
        <Shimmer
          shimmerElements={[{ height: 32, type: ShimmerElementType.line }]}
          styles={{ root: { marginTop: '18px !important' } }}
        />
      );

    if (!field) return null;

    return (
      <DynamicField
        getStreamUrl={getStreamUrl}
        fetchRequest={fetchRequest}
        getDefinitionExternalData={getDefinitionExternalData}
        getDefinitionExternalDataList={getDefinitionExternalDataList}
        getDefinition={getDefinition}
        disabled={false}
        tenantId={currentTenantId}
        fieldLink={{
          field: {
            ...field,
            // currently it is only possible to filter by a single value
            choiceFormat: ChoiceFieldFormat.SingleSelect,
            name: t('msTeams.config.dataFieldFilterRow.fieldFilterValueLabel')
          },
          value: filterValue,
          id: fieldId
        }}
        getFileContents={fileContents}
        onChange={(onChangeProps) => {
          onFilterValueChange(onChangeProps);
        }}
        onCombinedPickerSearch={onCombinedPickerSearch}
      />
    );
  }

  if (loading) {
    return (
      <Shimmer
        shimmerElements={[{ height: 32, type: ShimmerElementType.line }]}
        styles={{ root: { marginLeft: 28, marginTop: 18 } }}
      />
    );
  }

  if (selectableFields?.length) {
    const items = selectableFields.map((field) => ({
      key: field.field.id,
      text: field.field.name
    }));

    return (
      <div id="dataFieldFilterRow">
        {/* Dropdown to select a fied */}
        <Dropdown
          disabled={disabled}
          options={items}
          styles={{ root: { maxWidth: 500, marginLeft: 28 } }}
          defaultSelectedKey={fieldId}
          label={t('msTeams.config.dataFieldFilterRow.fieldSelectionLabel')}
          placeholder={t('msTeams.config.dataFieldFilterRow.fieldSelectionPlaceholder')}
          onChange={(_, option) => {
            const field = selectableFields.find((field) => field.field.id === option.key);

            onFieldSelection({
              id: field.field.id,
              name: field.field.name,
              fieldType: field.field.fieldType
            });
          }}
        />
        <FilterValueWrapper>{renderDataFieldsFilterValue()}</FilterValueWrapper>
      </div>
    );
  }
}
