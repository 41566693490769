import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import fetchRequest from 'services/api';
import styled from 'styled-components';
import { getEmployeesTypes, getIndustryTypes } from 'utils/helpers';
import {
  ComboBox,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  PrimaryButton
} from '@fluentui/react';

const DialogStyled = styled(Dialog)`
  .ms-Dialog-button--close {
    display: none;
  }
`;

function NewTenantDialog() {
  const { t } = useTranslation();
  const [industry, setIndustry] = useState(null);
  const [employees, setEmployees] = useState(null);
  const [isDialogHidden, setIsDialogHidden] = useState(false);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);

  useEffect(() => {
    const disabled = !industry || !employees;

    setIsSubmitButtonDisabled(disabled);
  }, [industry, employees]);

  function saveCompanyInformations() {
    setIsSubmitButtonDisabled(true);

    const body = JSON.stringify({ industry, employees });
    const method = 'POST';
    const url = `Init/Start`;

    fetchRequest({ url, method, body }).then(() => setIsDialogHidden(true));
  }

  const industryTypes = getIndustryTypes();
  // remove "no category"- option
  industryTypes.splice(0, 1);

  const employeeTypes = getEmployeesTypes();
  employeeTypes.splice(0, 1);
  // remove "unknown"- option

  return (
    <DialogStyled
      hidden={isDialogHidden}
      minWidth={450}
      dialogContentProps={{
        type: DialogType.close,
        title: t('newTenantDialog.title')
      }}
      styles={{ keyboardMoveIcon: { display: 'none' } }}
      modalProps={{ isBlocking: true }}
    >
      <div style={{ marginTop: '15px', marginBottom: '5px' }}>{t('newTenantDialog.subTitle')}</div>
      <ComboBox
        allowFreeform
        autoComplete="on"
        label={t('newTenantDialog.industry.label')}
        multiSelect={false}
        onChange={(_, value) => setIndustry(value?.key)}
        options={industryTypes}
        selectedKey={industry}
        placeholder={t('newTenantDialog.industry.placeholder')}
        required
        styles={{ optionsContainerWrapper: { maxHeight: '300px' } }}
      />
      <Dropdown
        label={t('newTenantDialog.employees.label')}
        required
        styles={{ dropdownItemsWrapper: { maxHeight: '300px' } }}
        placeHolder={t('newTenantDialog.employees.placeholder')}
        options={employeeTypes}
        onChange={(_, value) => setEmployees(value?.key)}
      />
      <DialogFooter>
        <PrimaryButton
          disabled={isSubmitButtonDisabled}
          onClick={() => saveCompanyInformations()}
          text={t('newTenantDialog.button.submit')}
        />
      </DialogFooter>
    </DialogStyled>
  );
}

export default NewTenantDialog;
