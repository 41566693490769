import { useEffect, useState } from 'react';
import { Rgb } from '../../../utils/themeUtils';

export interface ISampledPalette {
  averageColor: Rgb;
}

const cachedPalettes = new Map<string, ISampledPalette>();

async function samplePalette(cacheKey: string | null, imageUrl: string): Promise<ISampledPalette> {
  const cached = cacheKey ? cachedPalettes.get(cacheKey) : null;
  if (cached) return cached;

  const image = new Image();
  image.src = imageUrl;
  await new Promise((resolve, reject) => {
    image.addEventListener('load', resolve);
    image.addEventListener('error', reject);
  });

  const canvas = document.createElement('canvas');
  const canvas2 = document.createElement('canvas');
  canvas.style.position = 'fixed';
  canvas.style.top = '-10000px';
  canvas2.style.position = 'fixed';
  canvas2.style.top = '-10000px';

  // append so that it's not an off-screen canvas
  document.body.append(canvas, canvas2);

  const sizes = [128, 64, 32, 16, 8, 4, 2, 1];

  const ctx = canvas.getContext('2d');
  const ctx2 = canvas2.getContext('2d');

  let swap1 = { canvas, ctx };
  let swap2 = { canvas: canvas2, ctx: ctx2 };

  // this lint is nonsense
  // eslint-disable-next-line prefer-destructuring
  canvas.width = sizes[0];
  // eslint-disable-next-line prefer-destructuring
  canvas.height = sizes[0];

  ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, 100, 100);

  for (let i = 0; i < sizes.length - 1; i += 1) {
    const large = sizes[i];
    const small = sizes[i + 1];

    swap2.canvas.width = small;
    swap2.canvas.height = small;

    swap2.ctx.fillStyle = '#777777';
    swap2.ctx.fillRect(0, 0, small, small);
    swap2.ctx.drawImage(swap1.canvas, 0, 0, large, large, 0, 0, small, small);

    [swap1, swap2] = [swap2, swap1];
  }

  const imageData = swap2.ctx.getImageData(0, 0, 1, 1, { colorSpace: 'srgb' });
  const averageColor = new Rgb(imageData.data[0], imageData.data[1], imageData.data[2]);

  canvas.remove();
  canvas2.remove();

  const result = { averageColor };
  if (cacheKey) cachedPalettes.set(cacheKey, result);
  return result;
}

export function useSampledPalette(
  cacheKey: string | null,
  imageUrl: string | null | undefined
): ISampledPalette | null {
  const [palette, setPalette] = useState<ISampledPalette | null>(null);

  useEffect(() => {
    if (!imageUrl) {
      return () => null;
    }

    let canceled = false;
    setPalette(null);
    samplePalette(cacheKey, imageUrl).then((result) => {
      if (canceled) return;
      setPalette(result);
    });
    return () => {
      canceled = true;
    };
  }, [cacheKey, imageUrl]);

  return palette;
}
