import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { Icon, Spinner, SpinnerSize } from '@fluentui/react';
import {
  BackgroundJobs,
  BackgroundJobsEventType,
  BackgroundJobStatus,
  IBackgroundJob,
  IBackgroundJobsEvent
} from './model';

const TRANSIENT_OVERLAY_DURATION = 2000;

interface ITransientOverlay {
  key: string;
  job: IBackgroundJob;
  origin: DOMRect;
  ephemeral: boolean;
  /** Duration in ms. Should be at least two seconds */
  duration?: number;
}

export default function TransientOverlays() {
  const backgroundJobs = useContext(BackgroundJobs);
  const [overlays, setOverlays] = useState<ITransientOverlay[]>([]);

  const onEvent = useCallback((event: IBackgroundJobsEvent) => {
    if (event.type === BackgroundJobsEventType.Added) {
      let initiatingElement: HTMLElement | DOMRect;
      if (
        event.params.initiatingElement instanceof HTMLElement ||
        event.params.initiatingElement instanceof DOMRect
      ) {
        initiatingElement = event.params.initiatingElement;
      } else if (typeof event.params.initiatingElement === 'string') {
        const el = document.querySelector(event.params.initiatingElement);
        if (el instanceof HTMLElement) {
          initiatingElement = el;
        }
      }

      if (!initiatingElement) {
        return;
      }

      setOverlays((overlays) => [
        ...overlays,
        {
          key: Math.random().toString(),
          origin:
            initiatingElement instanceof DOMRect
              ? initiatingElement
              : initiatingElement.getBoundingClientRect(),
          job: event.params.job,
          ephemeral: event.params.ephemeral,
          duration: event.params.overlayDuration
        }
      ]);
    }
  }, []);

  useEffect(() => {
    backgroundJobs.addEventListener(onEvent);
    return () => backgroundJobs.removeEventListener(onEvent);
  }, [backgroundJobs, onEvent]);

  return (
    <>
      {overlays.map((overlay) => (
        <TransientOverlay
          key={overlay.key}
          overlay={overlay}
          onRemove={() =>
            setOverlays((overlays) => overlays.filter((item) => item.key !== overlay.key))
          }
        />
      ))}
    </>
  );
}

const PREVIEW_WIDTH = 200;
const PREVIEW_HEIGHT = 44;

const TransientOverlayStyled = styled.div`
  position: fixed;
  inset: 0;
  pointer-events: none;

  > .c-transient-preview-container {
    position: absolute;
    animation: background-job-overlay-preview-container-appear 0.7s;
  }

  > .c-menu-path-container {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;

    > .c-menu-path {
      fill: none;
      stroke: rgb(${({ theme }) => theme.backgroundJobs.transientPreviewPathColor});
      stroke-width: 4px;
      stroke-linecap: round;
      will-change: d;

      &.is-failed {
        stroke: rgb(${({ theme }) => theme.backgroundJobs.transientPreviewFailedPathColor});
      }
    }
  }

  .c-job-preview {
    width: ${PREVIEW_WIDTH}px;
    height: ${PREVIEW_HEIGHT}px;
    background: rgb(${({ theme }) => theme.backgroundJobs.transientPreviewBackground});
    color: rgb(${({ theme }) => theme.backgroundJobs.transientPreviewForeground});
    box-shadow: ${({ theme }) => theme.backgroundJobs.transientPreviewShadow};
    border-radius: 0.5em;
    pointer-events: all;
    cursor: default;
    overflow: hidden;
    animation: background-job-overlay-preview-appear 2s;

    > .c-contents {
      width: ${PREVIEW_WIDTH}px;
      height: ${PREVIEW_HEIGHT}px;
      display: grid;
      grid-template-columns: 2em 1fr;
      align-items: center;
      padding: 0 0.5em;
      gap: 0.5em;
      animation: background-job-overlay-preview-contents-appear 2s;

      > .c-completed-icon {
        place-self: center;
        animation: background-job-overlay-completed-icon 0.4s;
        color: rgb(${({ theme }) => theme.backgroundJobs.transientPreviewCompletedIconColor});
      }
      > .c-error-icon {
        place-self: center;
        color: rgb(${({ theme }) => theme.backgroundJobs.transientPreviewFailedIconColor});
      }
    }

    > .c-contents > .c-details {
      min-width: 0;

      > .c-title,
      > .c-description {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      > .c-description {
        font-size: smaller;
        line-height: 1.3em;
        max-height: 1.3em;
        color: rgb(${({ theme }) => theme.backgroundJobs.transientPreviewForeground} / 0.8);
      }
    }

    &.is-disappearing {
      pointer-events: none;
      animation: background-job-overlay-preview-disappear 0.5s forwards;
      --target-background: rgb(${({ theme }) => theme.backgroundJobs.transientPreviewPathColor});

      &.is-failed {
        --target-background: rgb(
          ${({ theme }) => theme.backgroundJobs.transientPreviewFailedPathColor}
        );
      }

      > .c-contents {
        animation: background-job-overlay-preview-contents-disappear 0.5s forwards;
      }
    }
    &.is-disappearing.is-ephemeral-success {
      animation: background-job-overlay-preview-disappear-fade 0.5s forwards;

      > .c-contents {
        animation: none;
      }
    }
  }

  @keyframes background-job-overlay-completed-icon {
    0% {
      animation-timing-function: cubic-bezier(0, 0.5, 0.7, 1);
    }
    20% {
      animation-timing-function: cubic-bezier(0.2, 0.2, 0, 1);
      transform: translateY(3px);
    }
  }

  @keyframes background-job-overlay-preview-container-appear {
    0% {
      animation-timing-function: cubic-bezier(0, 0.9, 0, 1);
      opacity: 0;
    }
  }
  @keyframes background-job-overlay-preview-appear {
    0% {
      animation-timing-function: cubic-bezier(0.05, 0.9, 0, 1);
      width: 2em;
      transform: translateX(-40px);
    }
  }
  @keyframes background-job-overlay-preview-contents-appear {
    0% {
      animation-timing-function: cubic-bezier(0.05, 0.9, 0, 1);
      transform: translateX(10px);
    }
  }

  @keyframes background-job-overlay-preview-disappear {
    0% {
      animation-timing-function: cubic-bezier(0.7, 0, 0.8, 0.5);
      transform: none;
      width: ${PREVIEW_WIDTH}px;
      height: ${PREVIEW_HEIGHT}px;
      opacity: 1;
      background: rgb(${({ theme }) => theme.backgroundJobs.transientPreviewBackground});
    }
    90% {
      opacity: 1;
    }
    100% {
      background: var(--target-background);
      transform: translate(${PREVIEW_WIDTH - 16 + 40}px, ${(PREVIEW_HEIGHT - 4) / 2}px);
      width: 16px;
      height: 4px;
      opacity: 0;
    }
  }
  @keyframes background-job-overlay-preview-contents-disappear {
    0% {
      animation-timing-function: cubic-bezier(0.7, 0, 0.8, 0.5);
      transform: none;
    }
    100% {
      transform: translate(${-PREVIEW_WIDTH - 16 - 10}px, ${-(PREVIEW_HEIGHT - 4) / 2}px);
    }
  }

  @keyframes background-job-overlay-preview-disappear-fade {
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

const MAX_MENU_PATH_DURATION = 1000;

function TransientOverlay({
  overlay,
  onRemove
}: {
  overlay: ITransientOverlay;
  onRemove: () => void;
}) {
  const createdTime = useMemo(() => document.timeline.currentTime, []);
  const [isDisappearing, setIsDisappearing] = useState(false);

  const succeededDuringOverlay = useRef(false);
  const jobRef = useRef(overlay.job);
  jobRef.current = overlay.job;

  useEffect(() => {
    const overlayDuration = overlay.duration ?? TRANSIENT_OVERLAY_DURATION;

    const timeLeft =
      createdTime + overlayDuration + MAX_MENU_PATH_DURATION - document.timeline.currentTime;
    const disappearTimeLeft = timeLeft - MAX_MENU_PATH_DURATION - 500;
    const timeout = setTimeout(() => {
      setIsDisappearing((disappearing) => {
        if (!disappearing) {
          // we are setting isDisappearing from false to true.
          // this is the last chance where the user might see that the job already succeeded,
          // so we'll set succeededDuringOverlay here
          succeededDuringOverlay.current =
            jobRef.current.getProgress().status === BackgroundJobStatus.Done;
        }
        return true;
      });
    }, Math.max(1, disappearTimeLeft));
    const timeout2 = setTimeout(onRemove, Math.max(1, timeLeft));
    return () => {
      clearTimeout(timeout);
      clearTimeout(timeout2);
    };
  }, [createdTime, overlay.duration, onRemove]);

  const overlayLeft = overlay.origin.left;
  const overlayTop =
    overlay.origin.height < PREVIEW_HEIGHT
      ? overlay.origin.top + (overlay.origin.height - PREVIEW_HEIGHT) / 2
      : overlay.origin.top;

  const ephemeralSuccess = overlay.ephemeral && succeededDuringOverlay.current;

  return (
    <TransientOverlayStyled>
      <div className="c-transient-preview-container" style={{ left: overlayLeft, top: overlayTop }}>
        <JobPreview
          job={overlay.job}
          isDisappearing={isDisappearing}
          ephemeralSuccess={ephemeralSuccess}
        />
      </div>
      {isDisappearing ? (
        <MenuPathAnimation
          start={{ x: overlayLeft + PREVIEW_WIDTH, y: overlayTop + PREVIEW_HEIGHT / 2 }}
          job={overlay.job}
          ephemeralSuccess={ephemeralSuccess}
        />
      ) : null}
    </TransientOverlayStyled>
  );
}

interface Vec2 {
  x: number;
  y: number;
}

function MenuPathAnimation({
  start,
  job,
  ephemeralSuccess
}: {
  start: Vec2;
  job: IBackgroundJob;
  ephemeralSuccess: boolean;
}) {
  const menuPathNode = useRef<SVGPathElement>(null);
  const backgroundJobs = useContext(BackgroundJobs);

  const end = useMemo(
    () =>
      [...backgroundJobs.getMenuTargets()][0]?.getPosition() ?? { x: window.innerWidth / 2, y: 0 },
    [backgroundJobs]
  );

  const frames = useMemo(() => {
    if (ephemeralSuccess) return [];

    const pos: Vec2 = { x: start.x + 20, y: start.y };
    const velocity: Vec2 = { x: 800, y: 0 };
    let t = 0;
    const dt = 1 / 64;

    const frames: Vec2[] = [{ ...pos }];

    for (let i = 0; i < 2000; i += 1) {
      t += dt;

      const maxXForce = 10 + t * 70;
      const frictionX = t * 13;

      const displacementX = end.x - pos.x;
      const dispX = Math.sign(displacementX) * Math.min(maxXForce, Math.abs(displacementX));

      const ddx = dispX * 157 - frictionX * velocity.x;
      const ddy = Math.sign(end.y - pos.y) * 1150 * -(1 - t * 5);
      velocity.x += ddx * dt;
      velocity.y += ddy * dt;

      const targetDistance = Math.hypot(end.x - pos.x, end.y - pos.y);
      let attractorStrength = Math.exp(-((targetDistance / 100) ** 2));
      attractorStrength = Math.max(attractorStrength, t - 0.5);

      const targetDirNorm = {
        x: (end.x - pos.x) / targetDistance,
        y: (end.y - pos.y) / targetDistance
      };

      const velocityMagnitude = Math.hypot(velocity.x, velocity.y);
      const velocityNorm = {
        x: velocity.x / velocityMagnitude,
        y: velocity.y / velocityMagnitude
      };
      velocityNorm.x += (targetDirNorm.x - velocityNorm.x) * attractorStrength;
      velocityNorm.y += (targetDirNorm.y - velocityNorm.y) * attractorStrength;
      velocity.x = velocityNorm.x * velocityMagnitude;
      velocity.y = velocityNorm.y * velocityMagnitude;

      pos.x += velocity.x * dt;
      pos.y += velocity.y * dt;

      frames.push({ ...pos });

      if (Math.hypot(end.x - pos.x, end.y - pos.y) < 10) {
        break;
      }
    }

    frames.push({ ...end });

    return frames;
  }, [start, end, ephemeralSuccess]);

  const sample = useCallback(
    (t: number) => {
      if (!frames.length) return { x: -999, y: -999 };

      const totalT = frames.length;
      const i1 = Math.max(0, Math.min(frames.length - 1, Math.floor(t * totalT)));
      const i2 = Math.max(0, Math.min(frames.length - 1, Math.ceil(t * totalT)));
      const sampleT = t * totalT - i1;

      const sampleA = frames[i1];
      const sampleB = frames[i2];

      return {
        x: (sampleB.x - sampleA.x) * sampleT + sampleA.x,
        y: (sampleB.y - sampleA.y) * sampleT + sampleA.y
      };
    },
    [frames]
  );

  const delay = 0.466;
  const duration = Math.min(MAX_MENU_PATH_DURATION / 1000, frames.length / 64);
  const totalDuration = duration + delay;

  const samplePath = useCallback(
    (t: number) => {
      if (t < delay) {
        return { d: null, end: false };
      }

      const animationT = (t - delay) / duration;
      const pos = sample(animationT);
      let d = `M${pos.x},${pos.y}`;

      // trail
      const trailLen = Math.min(10, 4 + animationT * 10);
      let lastTrailPos = pos;
      for (let i = 1; i < trailLen; i += 1) {
        const sampleT = animationT - i / 100 / duration;
        if (sampleT < 0) {
          d += `L${start.x - i},${start.y}`;
        } else {
          lastTrailPos = sample(sampleT);
          d += `L${lastTrailPos.x},${lastTrailPos.y}`;
        }
      }

      return { d, end: animationT >= 1 };
    },
    [duration, sample, start]
  );

  const state = useRef({ t: 0, animationStart: 0 });

  const updateWaAnimation = useCallback(() => {
    const now = document.timeline.currentTime / 1000;
    if (state.current.animationStart) {
      state.current.t += now - state.current.animationStart;
    }
    state.current.animationStart = now;

    const keyframes = [];
    const timeLeft = totalDuration - state.current.t;
    if (timeLeft <= 0) return null;

    for (let { t } = state.current; t <= totalDuration; t += 1 / 64) {
      keyframes.push({
        offset: (t - state.current.t) / timeLeft,
        d: `path("${samplePath(t).d ?? 'M0,0'}")`
      });
    }

    for (const anim of menuPathNode.current.getAnimations()) anim.cancel();
    return menuPathNode.current.animate(keyframes, {
      duration: timeLeft * 1000,
      easing: 'linear'
    });
  }, [totalDuration, samplePath]);

  const updateFallbackAnimation = useCallback(
    (dt: number) => {
      state.current.t += dt;
      const { t } = state.current;

      const { d, end } = samplePath(t);
      menuPathNode.current.setAttribute('d', d ?? 'M0,0');

      return end;
    },
    [samplePath]
  );

  const didEndOverlay = useRef(false);

  const emitEnd = useCallback(() => {
    if (didEndOverlay.current) return;
    didEndOverlay.current = true;
    backgroundJobs.signalOverlayEnded(job, ephemeralSuccess);
  }, [backgroundJobs, job, ephemeralSuccess]);

  useEffect(() => {
    const path = menuPathNode.current;
    if (!path) return () => undefined;

    if (CSS.supports('d: path("M0,0")')) {
      const animation = updateWaAnimation();
      if (animation) {
        animation.addEventListener('finish', () => {
          emitEnd();
        });
      } else {
        emitEnd();
      }
      return () => undefined;
    }

    let canceled = false;
    let lastTime = document.timeline.currentTime;
    const update = () => {
      if (canceled) return;

      const now = document.timeline.currentTime;
      const dt = (now - lastTime) / 1000;
      lastTime = now;
      const ended = updateFallbackAnimation(Math.min(1 / 30, Math.max(1 / 120, dt)));
      if (ended) {
        emitEnd();
        canceled = true;
        path.setAttribute('d', 'M0,0');
      } else {
        requestAnimationFrame(update);
      }
    };
    update();
    return () => {
      canceled = true;
    };
  }, [emitEnd, updateWaAnimation, updateFallbackAnimation]);

  useEffect(() => {
    // emit end on unmount
    return () => emitEnd();
  }, [emitEnd]);

  const isFailed = job.getProgress().status === BackgroundJobStatus.Failed;

  return (
    <svg className="c-menu-path-container">
      <path className={`c-menu-path ${isFailed ? 'is-failed' : ''}`} ref={menuPathNode} d="M0 0" />
    </svg>
  );
}

function JobPreview({
  job,
  isDisappearing,
  ephemeralSuccess
}: {
  job: IBackgroundJob;
  isDisappearing: boolean;
  ephemeralSuccess: boolean;
}) {
  const [progress, setProgress] = useState(() => job.getProgress());
  useEffect(() => {
    const onUpdate = () => setProgress(job.getProgress());
    job.addProgressUpdateListener(onUpdate);
    return () => job.removeProgressUpdateListener(onUpdate);
  }, [job]);

  if (progress.status === BackgroundJobStatus.Done) {
    const result = job.getResult();

    return (
      <div
        className={`c-job-preview ${isDisappearing ? 'is-disappearing' : ''} ${
          ephemeralSuccess ? 'is-ephemeral-success' : ''
        }`}
      >
        <div className="c-contents">
          <Icon
            iconName="Completed"
            className="c-completed-icon"
            styles={{ root: { fontSize: '20px' } }}
          />
          <div className="c-details">
            <div className="c-title">{result.title}</div>
            <div className="c-description">{result.description}</div>
          </div>
        </div>
      </div>
    );
  }

  const description = progress.items.find((item) => item.progress !== 1)?.label;
  const isFailed = progress.status === BackgroundJobStatus.Failed;

  return (
    <div
      className={`c-job-preview ${isDisappearing ? 'is-disappearing' : ''} ${
        isFailed ? 'is-failed' : ''
      }`}
    >
      <div className="c-contents">
        {progress.status === BackgroundJobStatus.Pending ? (
          <Spinner size={SpinnerSize.medium} />
        ) : (
          <Icon
            iconName="ErrorBadge"
            className="c-error-icon"
            styles={{ root: { fontSize: '20px' } }}
          />
        )}
        <div className="c-details">
          <div className="c-title">{progress.title}</div>
          <div className="c-description">{description}</div>
        </div>
      </div>
    </div>
  );
}
