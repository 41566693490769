import styled from 'styled-components';
import {
  ConstrainMode,
  DetailsList,
  IconButton,
  Label,
  Persona,
  PersonaSize,
  SelectionMode,
  Shimmer,
  ShimmerElementType
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { useId } from '@fluentui/react-hooks';
import {
  IProcessFieldEditValidation,
  ISmallProcessTemplateForUser,
  IUser,
  PROCESS_FIELD_GROUP_VALIDATE_EDIT,
  PROCESS_FIELD_VALIDATE_EDIT,
  useApiObject
} from '../../../../hooks/api2';
import { convertEpUserOrTeamToPersonaProps } from '../../../../utils/helpers';

const ContainerStyled = styled.div`
  > .used-in-other-templates-text {
    margin: 0.5rem 0;
  }

  > .inner-header {
    display: flex;
    align-items: end;
    flex-wrap: wrap;
    gap: 0.5em;

    .inner-spacer {
      flex: 1;
    }

    .templates-item {
      display: flex;
      gap: 0.5em;

      .template-count:not(.is-expanded) {
        font-weight: inherit;
      }
    }
  }
`;

export default function FieldOwnerAndDefinitions({
  isGroup,
  id,
  processDefFilter,
  expanded: forceExpanded,
  whileEditingProcessTemplate
}: {
  isGroup: boolean;
  id: string;
  processDefFilter?: (definition: ISmallProcessTemplateForUser) => boolean;
  expanded?: boolean;
  whileEditingProcessTemplate?: string;
}) {
  const { data: editingCharacteristics, isLoading } = useApiObject(
    isGroup ? PROCESS_FIELD_GROUP_VALIDATE_EDIT : PROCESS_FIELD_VALIDATE_EDIT,
    id
  );
  const { t } = useTranslation();

  const [expandedState, setExpanded] = useState(false);
  const expanded = expandedState || forceExpanded;

  const definitions = useMemo(() => {
    if (!editingCharacteristics) return [];
    if (processDefFilter) {
      return editingCharacteristics.involvedDefinitions.filter(processDefFilter);
    }
    return editingCharacteristics.involvedDefinitions;
  }, [editingCharacteristics, processDefFilter]);

  const expandDefsId = useId();

  if (isLoading) {
    return (
      <ContainerStyled>
        <div className="inner-header">
          <FieldOwnerShimmer />
        </div>
      </ContainerStyled>
    );
  }

  let isUsedOnlyInThisProcessTemplate = false;
  let isUsedInOtherProcessTemplates = false;
  if (whileEditingProcessTemplate) {
    isUsedOnlyInThisProcessTemplate = definitions.every(
      (def) => def.id === whileEditingProcessTemplate
    );
    isUsedInOtherProcessTemplates = definitions.some(
      (def) => def.id !== whileEditingProcessTemplate
    );
  }

  return (
    <ContainerStyled>
      {isUsedInOtherProcessTemplates ? (
        <div className="used-in-other-templates-text">
          {t('formbuilder.fieldPicker.usedInOtherDefinitionsNotice')}
        </div>
      ) : null}

      <div className="inner-header">
        <FieldOwner user={(editingCharacteristics as IProcessFieldEditValidation)?.owner} />

        <div className="inner-spacer" />

        {!forceExpanded && isUsedOnlyInThisProcessTemplate ? (
          <div className="templates-item">
            <Label className="template-count">
              {t('formbuilder.fieldPicker.usedInThisDefinition')}
            </Label>
          </div>
        ) : null}

        {!forceExpanded && !isUsedOnlyInThisProcessTemplate ? (
          <div className="templates-item">
            <Label
              className={`template-count ${expanded ? 'is-expanded' : ''}`}
              htmlFor={expandDefsId}
            >
              {definitions.length === 1
                ? t('formbuilder.fieldPicker.usedInDefinition')
                : t('formbuilder.fieldPicker.usedInDefinitions', { count: definitions.length })}
            </Label>
            {definitions.length ? (
              <IconButton
                id={expandDefsId}
                iconProps={{
                  iconName: expanded ? 'ChevronDown' : 'ChevronRight'
                }}
                onClick={() => setExpanded((expanded) => !expanded)}
              />
            ) : null}
          </div>
        ) : null}
      </div>

      {expanded ? <AffectedDefinitionsList items={definitions} /> : null}
    </ContainerStyled>
  );
}

function FieldOwnerShimmer() {
  const { t } = useTranslation();

  return (
    <div className="field-owner">
      <Label>{t('formbuilder.fieldPicker.owner')}</Label>
      <div style={{ height: 6 }} />
      <Shimmer
        shimmerElements={[
          { type: ShimmerElementType.circle, width: 24, height: 24 },
          { type: ShimmerElementType.gap },
          { type: ShimmerElementType.line, height: 14 }
        ]}
      />
      <div style={{ height: 6 }} />
    </div>
  );
}

function FieldOwner({ user }: { user?: IUser }) {
  const { t } = useTranslation();

  return (
    <div className="field-owner">
      <Label>{t('formbuilder.fieldPicker.owner')}</Label>
      <Persona {...convertEpUserOrTeamToPersonaProps(user)} size={PersonaSize.size24} />
    </div>
  );
}

const DetailsListStyled = styled(DetailsList)`
  .ms-DetailsList-headerWrapper {
    position: sticky;
    top: 0;
    z-index: 100;
  }

  .ms-DetailsHeader {
    border-bottom-color: transparent;
  }
`;

function AffectedDefinitionsList({ items }: { items: ISmallProcessTemplateForUser[] }) {
  const columns = [
    {
      key: 'name',
      fieldName: 'name',
      name: 'Name',
      isResizable: true,
      minWidth: 150
    },
    {
      key: 'Team',
      fieldName: 'displayTitle',
      name: 'Team',
      isResizable: true,
      minWidth: 200,
      onRender: (definition: ISmallProcessTemplateForUser) => definition.team.displayTitle
    }
  ];

  return (
    <div style={{ maxHeight: 350, overflowY: 'auto' }} data-is-scrollable="true">
      <DetailsListStyled
        compact
        items={items}
        selectionMode={SelectionMode.none}
        constrainMode={ConstrainMode.unconstrained}
        selectionPreservedOnEmptyClick
        enterModalSelectionOnTouch
        columns={columns}
      />
    </div>
  );
}
