import { createContext } from 'react';
import { ITeamProps, IUserProps } from 'types';

interface ITenant {
  tenantId: string;
  directoryId: string;
  name: string;
  status: number;
  url: string;
}
interface IGlobalAppStateProps {
  searchTerm: string | null;
  currentUser?: IUserProps & { isInternalUser: boolean; isAdmin: boolean };
  currentTenantId: string | null;
  tenants: ITenant[];
  teams?: ITeamProps[];
  permissions?: IInitPermissions;
  msTeamId?: string;
  theme?: Record<string, string>;
  preventNavigation?: boolean;
  isNavigationPanelHidden?: boolean;
  selectedProcess?: { id: string; name: string };
  useStepsMatrix: boolean;
}

interface IInitPermissions {
  viewApplication: boolean;
  createTeam: boolean;
  admin: boolean;
  viewTemplates: boolean;
  createTemplate: boolean;
  startInstances: boolean;
  useProcesses: boolean;
  useProjects: boolean;
  features: {
    useAutoTagging: boolean;
    useAIField: boolean;
    processesFromTaskManagement: boolean;
  };
}

interface IDispatchProps {
  type: string;
  data: string | null;
}

interface IAppContextProps {
  globalAppState: IGlobalAppStateProps;
  dispatch: (action: IDispatchProps) => void;
}

let globalTenantId: string | null = null;
let globalUserId: string | null = null;

export const setGlobalTenantId = (tenantId: string) => {
  globalTenantId = tenantId;
};

export const setGlobalUserId = (userId: string) => {
  globalUserId = userId;
};

export const getGlobalTenantId = () => globalTenantId;
export const getGlobalUserId = () => globalUserId;

const globalAppState: IGlobalAppStateProps = {
  searchTerm: null,
  currentTenantId: null,
  isNavigationPanelHidden: true,
  tenants: []
};

const AppContext = createContext<IAppContextProps>({
  globalAppState,
  dispatch: () => null
});
export default AppContext;
