import { LoadingSpinner } from 'components/progress';
import { useFileCache } from 'hooks';
import { IChatFile, IChatInputFile } from 'hooks/api2';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import breakpoints from 'utils/breakpoints';
import { Icon, IconButton, Image, Modal } from '@fluentui/react';
import { IChatInputFileStatus } from './ChatInput';
import { ShowErrorIcon } from '../../ShowError';

export interface IChatFileProps {
  file: IChatFile | IChatInputFile;
  status?: IChatInputFileStatus;
  onDelete?: (file: IChatFile | IChatInputFile) => void;
}

const ChatFileStyled = styled.div`
  max-width: 250px;
  max-height: 100px;
  width: min-content;
  border: 1px solid rgb(${({ theme }) => theme.aiChat.fileOutline});
  border-radius: 12px;

  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  background-color: rgb(${({ theme }) => theme.aiChat.fileBackground});
  display: flex;
  position: relative;

  @media (max-width: ${breakpoints.extraSmallMax}px) {
    max-height: 50px;
  }

  .file {
    display: flex;
    overflow: hidden;
    padding: 5px;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  .file-icon {
    font-size: 35px;
    margin: 0 auto;
    height: 100%;
    width: 55px;
    display: flex;
    align-items: center;

    @media (max-width: ${breakpoints.extraSmallMax}px) {
      font-size: 25px;
      width: auto;
    }
  }

  .file-name {
    padding: 7px;
    text-align: center;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .delete-button {
    position: absolute;
    top: -9px;
    right: -9px;
    background-color: #696969;
    color: white;
    border-radius: 50%;
    border: 1px solid #d5d5d5;
    width: 25px;
    height: 25px;

    @media (max-width: ${breakpoints.extraSmallMax}px) {
      top: -7px;
      right: -7px;
      width: 20px;
      height: 20px;
    }
  }

  .image {
    min-width: 120px;
    border-radius: 12px;
  }

  .ms-Icon {
    font-size: 9px;

    @media (max-width: ${breakpoints.extraSmallMax}px) {
      font-size: 8px;
    }
  }

  .ms-Image-image {
    width: 100%;
    height: 100%;
  }

  .file-loading-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 12px;

    .ms-Spinner-circle {
      border-width: 4px;
    }
  }

  .file-error {
    font-size: 1.5rem;
    margin: 0.5rem;
    align-self: center;
  }
`;

export default function ChatFile({ file, status, onDelete }: IChatFileProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isInputFile = file instanceof Blob;
  const contentType = isInputFile ? file.type : file.contentType;
  const isImage = contentType.startsWith('image/');
  const iconName = getFluentIconNameByFileType(contentType);

  const image = useFileCache(!isInputFile && isImage && { chatId: file.chatId, fileId: file.id });

  const imageSrc = useMemo(() => {
    if (isInputFile) {
      return URL.createObjectURL(file);
    }

    if (image.imageUrl) {
      return image.imageUrl;
    }

    return '';
  }, [file, image?.imageUrl, isInputFile]);

  const { name, id } = file;
  const isLoading = isInputFile
    ? file.isLoading || status?.isUploading || status?.isWaitingForCompletion
    : false;

  const error = status?.uploadError;

  return (
    <ChatFileStyled className="chat-file" title={file.name} id={`file-${id}`}>
      {isImage && (
        <Modal
          isOpen={isModalOpen}
          onDismiss={() => setIsModalOpen(false)}
          isBlocking={false}
          layerProps={{}}
          overlay={{ styles: { root: { backgroundColor: 'rgba(0, 0, 0, 0.8)' } } }}
        >
          <IconButton
            onClick={() => setIsModalOpen(false)}
            styles={{
              root: { position: 'fixed', top: 10, right: 10 },
              rootHovered: { backgroundColor: 'rgba(0, 0, 0, 0.1)' },
              rootPressed: { backgroundColor: 'rgba(0, 0, 0, 0.1)' }
            }}
            iconProps={{
              iconName: 'CalculatorMultiply',
              styles: { root: { fontSize: 20, color: 'white' } }
            }}
          />
          <Image alt={name} title={name} shouldFadeIn={false} src={imageSrc} />
        </Modal>
      )}
      {isLoading && <LoadingSpinner className="file-loading-spinner" />}
      {error ? <ShowErrorIcon className="file-error" error={error} /> : null}
      {!isLoading && onDelete && (
        <IconButton
          onClick={() => onDelete(file)}
          className="delete-button"
          iconProps={{ iconName: 'CalculatorMultiply' }}
        />
      )}
      {isImage ? (
        <Image
          onClick={() => setIsModalOpen(true)}
          className="image"
          alt={name}
          title={name}
          shouldFadeIn={false}
          src={imageSrc}
          styles={{
            image: {
              width: '100%',
              height: '100%',
              objectFit: 'fit',
              cursor: isLoading ? 'auto' : 'pointer'
            }
          }}
        />
      ) : (
        <div className="file">
          <Icon className="file-icon" iconName={iconName} />
          <div className="file-name">{name}</div>
        </div>
      )}
    </ChatFileStyled>
  );
}

function getFluentIconNameByFileType(fileType: string) {
  switch (fileType) {
    case 'image/jpeg':
    case 'image/png':
      return 'Photo2';
    case 'application/pdf':
      return 'PDF';
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/msword':
      return 'WordDocument';
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'application/vnd.ms-excel':
      return 'ExcelDocument';
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    case 'application/vnd.ms-powerpoint':
      return 'PowerPointDocument';
    default:
      return 'Document';
  }
}
