import i18n from 'i18next';
import {
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_NORMAL,
  KEY_MODIFIER_COMMAND,
  LexicalEditor,
  SELECTION_CHANGE_COMMAND
} from 'lexical';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { IRenderFunction, getTheme } from '@fluentui/react';
import { DecreaseIndentIcon, IncreaseIndentIcon } from '@fluentui/react-icons-mdl2';
import { ActionButton, IButtonProps, IButtonStyles, IconButton } from '@fluentui/react/lib/Button';
import { IOverflowSetItemProps, OverflowSet } from '@fluentui/react/lib/OverflowSet';
import { TOGGLE_LINK_COMMAND } from '@lexical/link';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { INSERT_HORIZONTAL_RULE_COMMAND } from '@lexical/react/LexicalHorizontalRuleNode';
import ColorPalette from '../components/ColorPalette';
import {
  applyStyleText,
  clearFormatting,
  formatBulletList,
  formatHeading,
  formatNumberedList,
  formatParagraph,
  formatText,
  indentSelection,
  outdentSelection,
  redo,
  syncSelectionState,
  undo
} from '../utils';

interface ISelectionState {
  isBold: boolean;
  isItalic: boolean;
  isUnderline: boolean;
  isStrikethrough: boolean;
  isSubscript: boolean;
  isSuperscript: boolean;
  isCode: boolean;
  isLink: boolean;
  isRTL: boolean;
  blockType: string;
  codeLanguage: string;
  fontSize: string;
  fontColor: string;
  bgColor: string;
  fontFamily: string;
  elementFormat: string;
  rootType: string;
  selectedElementKey: string;
}

const getToolBarItems = (
  editor: LexicalEditor,
  toolBarState: ISelectionState
): IOverflowSetItemProps[] => [
  {
    key: 'bold',
    name: i18n.t('components:richTextEditor.toolbar.bold'),
    title: i18n.t('components:richTextEditor.toolbar.bold'),
    iconProps: { iconName: 'Bold' },
    width: 30,
    type: 'iconButton',
    onClick: () => formatText(editor, 'bold')
  },
  {
    key: 'italic',
    name: i18n.t('components:richTextEditor.toolbar.italic'),
    title: i18n.t('components:richTextEditor.toolbar.italic'),
    iconProps: { iconName: 'Italic' },
    width: 30,
    type: 'iconButton',
    onClick: () => formatText(editor, 'italic')
  },
  {
    key: 'underline',
    name: i18n.t('components:richTextEditor.toolbar.underline'),
    title: i18n.t('components:richTextEditor.toolbar.underline'),
    iconProps: { iconName: 'Underline' },
    width: 30,
    type: 'iconButton',
    onClick: () => formatText(editor, 'underline')
  },
  {
    key: 'strikethrough',
    name: i18n.t('components:richTextEditor.toolbar.strikethrough'),
    title: i18n.t('components:richTextEditor.toolbar.strikethrough'),
    iconProps: { iconName: 'Strikethrough' },
    width: 30,
    type: 'iconButton',
    onClick: () => formatText(editor, 'strikethrough')
  },
  {
    key: 'devider1',
    width: 3,
    type: 'devider',
    itemType: 1
  },
  {
    key: 'highlightColor',
    name: i18n.t('components:richTextEditor.toolbar.highlight'),
    title: i18n.t('components:richTextEditor.toolbar.highlight'),
    iconProps: { iconName: 'Highlight' },
    width: 30,
    type: 'iconButton',
    menuProps: {
      styles: { root: { minWidth: 10 } },
      items: [
        {
          key: 'highlightColor',
          onRender: () => (
            <ColorPalette
              colors={[
                { color: '#D5949A', name: 'Kobi red' },
                { color: '#E9A696', name: 'Rose bud' },
                { color: '#F6D479', name: 'Goldenrod' },
                { color: '#E5F094', name: 'Primrose' },
                { color: '#91CCA8', name: 'Mantis' },
                { color: '#A9D8DA', name: 'Regent st. blue' },
                { color: '#CAD4E7', name: 'Periwinkle gray' },
                { color: '#E8D4E1', name: 'Pale rose' }
              ]}
              resetButtonLabel={i18n.t('components:richTextEditor.toolbar.noHighlight')}
              onReset={() => {
                applyStyleText(editor, { 'background-color': null });
              }}
              onChange={(color) => {
                applyStyleText(editor, { 'background-color': color });
              }}
            />
          )
        }
      ]
    }
  },
  {
    key: 'textColor',
    name: i18n.t('components:richTextEditor.toolbar.color'),
    title: i18n.t('components:richTextEditor.toolbar.color'),
    iconProps: { iconName: 'Color' },
    width: 30,
    type: 'iconButton',
    menuProps: {
      styles: { root: { minWidth: 10 } },
      items: [
        {
          styles: { root: { minWidth: 10 } },
          key: 'highlightColor',
          onRender: () => (
            <ColorPalette
              colors={[
                { color: '#AA464F', name: 'Red' },
                { color: '#C05C3F', name: 'Tuscan orange' },
                { color: '#F3C042', name: 'Sunglow' },
                { color: '#BECA54', name: 'Pear' },
                { color: '#4A9963', name: 'Eucalyptus' },
                { color: '#47787A', name: 'Faded jade' },
                { color: '#3154A1', name: 'Fun blue' },
                { color: '#9A3E7A', name: 'Tyrian purple' }
              ]}
              resetButtonLabel={i18n.t('components:richTextEditor.toolbar.automatic')}
              onReset={() => {
                applyStyleText(editor, { color: null });
              }}
              onChange={(color) => {
                applyStyleText(editor, { color });
              }}
            />
          )
        }
      ]
    }
  },
  {
    key: 'fontSize',
    name: i18n.t('components:richTextEditor.toolbar.fontSize.label'),
    title: i18n.t('components:richTextEditor.toolbar.fontSize.label'),
    iconProps: { iconName: 'FontSize' },
    width: 30,
    type: 'iconButton',
    menuProps: {
      styles: { root: { minWidth: 10 } },
      items: [
        {
          key: 14,
          name: i18n.t('components:richTextEditor.toolbar.fontSize.large'),
          title: i18n.t('components:richTextEditor.toolbar.fontSize.large'),
          onClick: () => {
            applyStyleText(editor, { 'font-size': `16px` });
          }
        },
        {
          key: 12,
          name: i18n.t('components:richTextEditor.toolbar.fontSize.medium'),
          title: i18n.t('components:richTextEditor.toolbar.fontSize.medium'),
          onClick: () => {
            applyStyleText(editor, { 'font-size': `14px` });
          }
        },
        {
          key: 10,
          name: i18n.t('components:richTextEditor.toolbar.fontSize.small'),
          title: i18n.t('components:richTextEditor.toolbar.fontSize.small'),
          onClick: () => {
            applyStyleText(editor, { 'font-size': `12px` });
          }
        }
      ]
    }
  },
  {
    key: 'paragraph',
    name: i18n.t('components:richTextEditor.toolbar.paragraph.label'),
    title: i18n.t('components:richTextEditor.toolbar.paragraph.label'),
    width: 110,
    type: 'actionButton',
    menuItems: [
      {
        key: 'heading1',
        name: i18n.t('components:richTextEditor.toolbar.paragraph.heading1'),
        title: i18n.t('components:richTextEditor.toolbar.paragraph.heading1'),
        onClick: () => formatHeading(editor, 'h1', toolBarState.blockType)
      },
      {
        key: 'heading2',
        name: i18n.t('components:richTextEditor.toolbar.paragraph.heading2'),
        title: i18n.t('components:richTextEditor.toolbar.paragraph.heading2'),
        onClick: () => formatHeading(editor, 'h2', toolBarState.blockType)
      },

      {
        key: 'heading3',
        name: i18n.t('components:richTextEditor.toolbar.paragraph.heading3'),
        title: i18n.t('components:richTextEditor.toolbar.paragraph.heading3'),
        onClick: () => formatHeading(editor, 'h3', toolBarState.blockType)
      },
      {
        key: 'paragraph',
        name: i18n.t('components:richTextEditor.toolbar.paragraph.paragraph'),
        title: i18n.t('components:richTextEditor.toolbar.paragraph.paragraph'),
        onClick: () => formatParagraph(editor)
      }
    ]
  },
  {
    key: 'clearFormatting',
    name: i18n.t('components:richTextEditor.toolbar.clearFormatting'),
    title: i18n.t('components:richTextEditor.toolbar.clearFormatting'),
    iconProps: { iconName: 'ClearFormatting' },
    width: 30,
    type: 'iconButton',
    onClick: () => clearFormatting(editor)
  },
  {
    key: 'devider2',
    width: 3,
    type: 'devider',
    itemType: 1
  },
  {
    key: 'decreaseIndent',
    name: i18n.t('components:richTextEditor.toolbar.decreaseIndent'),
    title: i18n.t('components:richTextEditor.toolbar.decreaseIndent'),
    iconProps: { iconName: 'DecreaseIndent' },
    width: 30,
    type: 'iconButton',
    onClick: () => outdentSelection(editor)
  },
  {
    key: 'increaseIndent',
    name: i18n.t('components:richTextEditor.toolbar.increaseIndent'),
    title: i18n.t('components:richTextEditor.toolbar.increaseIndent'),
    iconProps: { iconName: 'IncreaseIndentMirrored' },
    width: 30,
    type: 'iconButton',
    onClick: () => indentSelection(editor)
  },
  {
    key: 'bulletedList',
    name: i18n.t('components:richTextEditor.toolbar.bulletedList'),
    title: i18n.t('components:richTextEditor.toolbar.bulletedList'),
    iconProps: { iconName: 'BulletedList' },
    width: 30,
    type: 'iconButton',
    onClick: () => formatBulletList(editor, toolBarState.blockType)
  },
  {
    key: 'numberedList',
    name: i18n.t('components:richTextEditor.toolbar.numberedList'),
    title: i18n.t('components:richTextEditor.toolbar.numberedList'),
    iconProps: { iconName: 'NumberedList' },
    width: 30,
    type: 'iconButton',
    onClick: () => formatNumberedList(editor, toolBarState.blockType)
  },
  {
    key: 'devider3',
    width: 3,
    type: 'devider',
    itemType: 1
  },
  {
    key: 'Link',
    name: i18n.t('components:richTextEditor.toolbar.link'),
    title: i18n.t('components:richTextEditor.toolbar.link'),
    iconProps: { iconName: 'Link' },
    width: 30,
    type: 'iconButton',
    onClick: () => {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, '');
    }
  },
  {
    key: 'horizontalRule',
    name: i18n.t('components:richTextEditor.toolbar.horizontalRule'),
    title: i18n.t('components:richTextEditor.toolbar.horizontalRule'),
    iconProps: { iconName: 'ChromeMinimize' },
    width: 30,
    type: 'iconButton',
    onClick: () => {
      editor.dispatchCommand(INSERT_HORIZONTAL_RULE_COMMAND, undefined);
    }
  },
  {
    key: 'devider4',
    width: 3,
    type: 'devider',
    itemType: 1
  },
  {
    key: 'undo',
    name: i18n.t('components:richTextEditor.toolbar.undo'),
    title: i18n.t('components:richTextEditor.toolbar.undo'),
    iconProps: { iconName: 'Undo' },
    width: 30,
    type: 'iconButton',
    onClick: () => undo(editor)
  },
  {
    key: 'redo',
    name: i18n.t('components:richTextEditor.toolbar.redo'),
    title: i18n.t('components:richTextEditor.toolbar.redo'),
    iconProps: { iconName: 'Redo' },
    width: 30,
    type: 'iconButton',
    onClick: () => redo(editor)
  }
];

function Toolbar() {
  const [editor] = useLexicalComposerContext();

  const overflowSetWrapperRef = useRef<HTMLDivElement>(null);

  const [items, setItems] = useState<IOverflowSetItemProps[]>([]);
  const [overflowItems, setOverflowItems] = useState<IOverflowSetItemProps[]>([]);

  const [toolBarState, setToolBarState] = useState<ISelectionState>({
    isBold: false,
    isItalic: false,
    isUnderline: false,
    isStrikethrough: false,
    isSubscript: false,
    isSuperscript: false,
    isCode: false,
    isLink: false,
    isRTL: false,
    blockType: 'paragraph',
    codeLanguage: '',
    fontSize: '15px',
    fontColor: '#000',
    bgColor: '#fff',
    fontFamily: 'Arial',
    elementFormat: 'left',
    rootType: 'root',
    selectedElementKey: ''
  });

  const theme = getTheme();

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        setToolBarState(syncSelectionState(newEditor));
        return false;
      },
      COMMAND_PRIORITY_CRITICAL
    );
  }, [editor]);

  useEffect(() => {
    return editor.registerCommand(
      KEY_MODIFIER_COMMAND,
      (payload) => {
        const event: KeyboardEvent = payload;
        const { code, ctrlKey, metaKey } = event;

        if (code === 'KeyK' && (ctrlKey || metaKey)) {
          event.preventDefault();
          return editor.dispatchCommand(TOGGLE_LINK_COMMAND, '');
        }
        return false;
      },
      COMMAND_PRIORITY_NORMAL
    );
  }, [editor]);

  useLayoutEffect(() => {
    const overflowWidth = overflowSetWrapperRef.current?.offsetWidth;

    if (overflowWidth) {
      let totalWidth = 30;
      const items: IOverflowSetItemProps[] = [];
      const overflowItems: IOverflowSetItemProps[] = [];

      getToolBarItems(editor, toolBarState).forEach((item) => {
        totalWidth += item.width || 0;

        if (totalWidth <= overflowWidth) {
          items.push(item);
        } else {
          overflowItems.push(item);
        }
      });

      setItems(items);
      setOverflowItems(overflowItems);
    }
  }, [editor, toolBarState]);

  function onRenderIconButtonIcon(
    props?: IButtonProps,
    defaultRender?: IRenderFunction<IButtonProps>
  ) {
    if (!props || !defaultRender) return null;

    if (props.iconProps?.iconName === 'DecreaseIndent') {
      return <DecreaseIndentIcon style={{ color: theme.palette.neutralSecondary }} />;
    }

    if (props.iconProps?.iconName === 'IncreaseIndentMirrored') {
      return <IncreaseIndentIcon style={{ color: theme.palette.neutralSecondary }} />;
    }
    return defaultRender?.(props);
  }

  function renderIconButton(item: IOverflowSetItemProps) {
    const iconButtonStyles: IButtonStyles = {
      icon: { fontSize: 16, color: theme.palette.neutralSecondary },
      menuIcon: { display: 'none !important' }
    };

    return (
      <IconButton
        title={item.title}
        iconProps={{ iconName: item.iconProps.iconName }}
        onRenderIcon={onRenderIconButtonIcon}
        menuProps={item.menuProps}
        onClick={item.onClick}
        styles={iconButtonStyles}
      />
    );
  }

  function renderDevider() {
    return (
      <div
        style={{
          background: theme.palette.neutralTertiaryAlt,
          width: '1px',
          height: '40%',
          margin: 'auto 1px',
          alignSelf: 'center'
        }}
      />
    );
  }

  function renderActionButton(item: IOverflowSetItemProps) {
    return (
      <ActionButton
        styles={{ root: { maxHeight: '30px', fontSize: 12 } }}
        menuProps={{ items: item.menuItems }}
      >
        {item.name}
      </ActionButton>
    );
  }

  const onRenderOverflowSetItem = (item: IOverflowSetItemProps): JSX.Element => {
    if (item.type === 'iconButton') {
      return renderIconButton(item);
    }

    if (item.type === 'devider') {
      return renderDevider();
    }

    if (item.type === 'actionButton') {
      return renderActionButton(item);
    }

    return <div />;
  };

  const onRenderOverflowButton = (): JSX.Element => {
    const buttonStyles: Partial<IButtonStyles> = {
      root: { minWidth: 0, padding: '0 4px', maxHeight: '32px' }
    };

    return (
      <IconButton
        title="More options"
        styles={buttonStyles}
        menuIconProps={{ iconName: 'More' }}
        menuProps={{ items: overflowItems }}
      />
    );
  };

  return (
    <div id="rte-toolbar" className="rte-toolbar-wrapper" ref={overflowSetWrapperRef}>
      <OverflowSet
        items={items}
        overflowItems={overflowItems}
        onRenderOverflowButton={onRenderOverflowButton}
        onRenderItem={onRenderOverflowSetItem}
        styles={{ item: { maxHeight: '32px', margin: '0px !important' } }}
      />
    </div>
  );
}

export default Toolbar;
