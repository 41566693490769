import styled from 'styled-components';

const ChoiceGroupWrapper = styled.div`
  .c-choicegroup__wrapper {
    padding: 5px;
    padding-left: 10px;
    padding-bottom: 9px;
    border: 1px solid rgb(${({ theme }) => theme.choiceGroupField.outline});
    border-radius: ${({ theme }) => theme.choiceGroupField.cornerRadius};

    &:hover {
      border: 1px solid rgb(${({ theme }) => theme.choiceGroupField.hoverOutline});
    }
  }
`;

export default ChoiceGroupWrapper;
