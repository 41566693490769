import LanguagePicker from 'components/inputs/LanguagePicker';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import fetchRequest, { apiErrorHandler } from 'services/api';
import styled from 'styled-components';
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IconButton,
  Label,
  PrimaryButton,
  TextField,
  TooltipHost
} from '@fluentui/react';

const DialogInputContent = styled.div`
  display: flex;
  width: 100%;
`;

const TranslationInput = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 5px;
`;

const DialogContentStyled = styled.div`
  display: inline-block;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const VerticalDivider = styled.div`
  width: 50px;
`;

function CreateRoleDialog({ hidden, onCancel, onConfirm, role }) {
  const { t } = useTranslation();
  const [newRole, setNewRole] = useState(null);
  const [roleNameTranslated, setRoleNameTranslated] = useState(null);
  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(!role?.createRole);
  const [showLanguages, setShowLanguages] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(null);

  const translateRole = useCallback((role, language) => {
    // translate content to display it in other language than the browser's
    const translatedName =
      role?.allNames?.find((x) => x.language === language.key)?.text || role.name;

    const translatedRole = {
      language: language.key,
      text: translatedName
    };

    return translatedRole;
  }, []);

  useEffect(() => {
    if (role) {
      setNewRole({ ...role });
    }
  }, [role]);

  useEffect(() => {
    if (
      newRole &&
      selectedLanguage &&
      (!roleNameTranslated || roleNameTranslated.language !== selectedLanguage.key)
    ) {
      const translatedRole = translateRole(newRole, selectedLanguage);

      setRoleNameTranslated({ ...translatedRole });
    }
  }, [newRole, selectedLanguage, roleNameTranslated, translateRole]);

  function close() {
    onCancel();
  }

  function handleName(value, translation) {
    const oldName = translation
      ? role?.allNames?.find((x) => x.language === selectedLanguage.key)?.text
      : role.name;

    setIsPrimaryButtonDisabled(!(value !== oldName));

    const lang = selectedLanguage?.key;

    const translatedName = {
      language: lang,
      text: value
    };

    let translatedNames = [];

    if (translation) {
      if (newRole.allNames) {
        let newLang = true;

        translatedNames = newRole.allNames.map((name) => {
          if (name.language === lang) {
            newLang = false;

            return translatedName;
          }

          return name;
        });

        if (newLang) {
          translatedNames.push(translatedName);
        }
      } else {
        translatedNames = [translatedName];
      }

      setNewRole({ ...newRole, allNames: translatedNames });
      setRoleNameTranslated({ ...roleNameTranslated, text: value });
    } else {
      setNewRole({ ...newRole, name: value });
    }
  }

  async function saveRole(close) {
    setIsPrimaryButtonDisabled(true);

    const body = JSON.stringify(newRole);

    let method = 'POST';

    if (newRole.id) {
      method = 'PUT';
    }

    const roleFromDb = await fetchRequest({
      url: `Role`,
      method,
      body
    }).catch(apiErrorHandler);

    setNewRole(newRole.id ? newRole : roleFromDb);
    onConfirm(newRole.id ? newRole : roleFromDb, close);
  }

  function getDialogStyles() {
    if (showLanguages) {
      return {
        main: [
          {
            selectors: {
              '@media (min-width: 1150px)': {
                width: '100%',
                minWidth: `${showLanguages ? '1120' : '550'}px`,
                minHeight: '250px'
              },
              '@media (max-width: 1120px)': {
                minHeight: '100%',
                minWidth: '100%'
              }
            }
          }
        ]
      };
    }

    return {
      main: [
        {
          selectors: {
            '@media (min-width: 650px)': {
              width: '100%',
              minWidth: '550px',
              minHeight: '250px'
            },
            '@media (max-width: 480px)': {
              minHeight: '100%',
              minWidth: '100%'
            }
          }
        }
      ]
    };
  }

  const translatedLanguages = (newRole) => {
    if (newRole?.allNames?.length) {
      const transLang = newRole.allNames.map((name) => {
        return name.language;
      });

      return transLang;
    }

    return null;
  };

  function getTranslationInput(translation) {
    return (
      <TranslationInput>
        {translation ? (
          <div style={{ display: 'flex' }}>
            <Label style={{ marginRight: '5px' }}>{t('createRole.dialog.language.label')}</Label>
            <LanguagePicker
              key={`lang-${newRole?.allNames?.length || 0}`}
              disabled={!showLanguages}
              placeholder={t('createRole.dialog.language.placeholder')}
              onChange={setSelectedLanguage}
              styles={{
                root: {
                  width: '200px'
                }
              }}
              translatedLanguages={translatedLanguages(newRole)}
              defaultLanguageKey={selectedLanguage?.key}
            />
          </div>
        ) : (
          <TooltipHost
            content={t('createRole.dialog.translate.tooltip')}
            calloutProps={{ gapSpace: 5, target: '#translate-button' }}
          >
            <IconButton
              id="translate-button"
              iconProps={{ iconName: 'DoubleChevronRight12' }}
              onClick={() => setShowLanguages(true)}
              disabled={showLanguages}
              ariaLabel="Translate"
            />
          </TooltipHost>
        )}
      </TranslationInput>
    );
  }

  function getInputContent(translation) {
    return (
      <>
        {translation ? <VerticalDivider /> : null}
        <DialogContentStyled>
          {getTranslationInput(translation)}
          <TextField
            label={
              translation
                ? t('createRole.dialog.rolename-translated.label')
                : t('createRole.dialog.rolename.label')
            }
            required
            value={translation ? roleNameTranslated?.text : newRole?.name}
            onChange={(_, value) => handleName(value, translation)}
            autoFocus
            disabled={translation && !selectedLanguage}
          />
        </DialogContentStyled>
      </>
    );
  }

  return (
    <Dialog
      hidden={hidden}
      onDismiss={() => close()}
      styles={getDialogStyles}
      dialogContentProps={{
        type: DialogType.close,
        title: role && role.id ? t('createRole.dialog.titleEdit') : t('createRole.dialog.title')
      }}
      modalProps={{
        isBlocking: true
      }}
    >
      <DialogInputContent>
        {getInputContent()}
        {showLanguages ? getInputContent(showLanguages) : null}
      </DialogInputContent>

      <DialogFooter>
        <PrimaryButton
          text={t('createRole.dialog.button.saveAndClose')}
          split
          menuProps={{
            items: [
              {
                key: 'saveAndClose',
                text: t('createRole.dialog.button.save'),
                onClick: () => saveRole(false)
              }
            ]
          }}
          onClick={() => saveRole(true)}
          disabled={isPrimaryButtonDisabled}
        />
        <DefaultButton onClick={() => close()} text={t('createRole.dialog.button.cancel')} />
      </DialogFooter>
    </Dialog>
  );
}

CreateRoleDialog.propTypes = {
  hidden: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  role: PropTypes.shape({
    createRole: PropTypes.bool,
    id: PropTypes.string,
    name: PropTypes.string,
    allNames: PropTypes.arrayOf(PropTypes.object)
  })
};

CreateRoleDialog.defaultProps = {
  hidden: true,
  role: null
};

export default CreateRoleDialog;
