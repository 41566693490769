import numbro from 'numbro';
import { useTranslation } from 'react-i18next';
import { IFieldLinkProps } from 'types';
import { Stack } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';

interface IAggregationBlockProps {
  fieldLink: IFieldLinkProps;
}

function AggregationBlock({ fieldLink }: IAggregationBlockProps): JSX.Element {
  const { t } = useTranslation();
  const aggregationBlockId = useId('aggregation-block');

  const averageResultValue = fieldLink.values?.find((resultValue) => resultValue.aggregation === 4);
  const sumResultValue = fieldLink.values?.find((resultValue) => resultValue.aggregation === 2);

  const { field } = fieldLink;

  function renderHeader(type: string) {
    if (type === 'countNumberOfTasks') {
      return fieldLink.numberOfTasks;
    }

    if (type === 'sum') {
      let sum;

      if (sumResultValue) {
        sum = sumResultValue.value as number;
      }

      return numbro(sum).format({
        thousandSeparated: !!field?.useThousandsSeparator,
        mantissa: field?.numDecimals || 0
      });
    }

    let average;

    if (averageResultValue) {
      average = averageResultValue.value as number;
    }

    return numbro(average).format({
      thousandSeparated: !!fieldLink.field?.useThousandsSeparator,
      mantissa: field?.numDecimals || 0
    });
  }

  function renderFooter(type: string) {
    if (type === 'countNumberOfTasks') {
      return t('survey.results.answers');
    }

    if (type === 'sum') {
      return t('survey.results.number.sum');
    }

    return t('survey.results.number.average');
  }

  function renderBlock(type: string) {
    return (
      <div key={aggregationBlockId}>
        <div className="c-result-box">
          <div className="c-result-box-value">{renderHeader(type)}</div>
          <div className="c-result-box-text">{renderFooter(type)}</div>
        </div>
      </div>
    );
  }

  return (
    <Stack tokens={{ childrenGap: 20 }} wrap horizontal>
      {renderBlock('countNumberOfTasks')}
      {renderBlock(averageResultValue ? 'average' : 'sum')}
    </Stack>
  );
}

export default AggregationBlock;
