import IntegrationField, { IIntegrationFieldProps } from 'components/inputs/IntegrationField';
import { IFieldLinkProps, RouteFieldVisibility } from 'types';
import { getFluentIconNameByFieldType } from 'utils/helpers';

export interface IDynamicIntegrationFieldProps {
  disabled?: boolean;
  fieldLink: IFieldLinkProps;
  getDefinition: IIntegrationFieldProps['getDefinition'];
  getDefinitionExternalData: IIntegrationFieldProps['getDefinitionExternalData'];
  getDefinitionExternalDataList: IIntegrationFieldProps['onSearch'];
  renderSelectedData?: IIntegrationFieldProps['renderSelectedData'];
  onChange: (value?: string) => void;
}

function DynamicIntegrationField({
  disabled,
  fieldLink,
  getDefinition,
  getDefinitionExternalData,
  getDefinitionExternalDataList,
  onChange,
  renderSelectedData
}: IDynamicIntegrationFieldProps) {
  if (!fieldLink.field) return null;

  const { field, visibility } = fieldLink;
  const {
    name,
    description,
    descriptionPlacement,
    id,
    dataSelectMode,
    allowScan,
    externalServiceId
  } = field;
  const defaultValue = fieldLink.value as string | undefined;

  const displayDescriptionTooltip = descriptionPlacement === 1;

  const readOnly = visibility === RouteFieldVisibility.ReadOnly;
  const required = visibility === RouteFieldVisibility.Required;

  return (
    <IntegrationField
      allowScan={allowScan}
      dataSelectMode={dataSelectMode}
      defaultValue={defaultValue}
      description={displayDescriptionTooltip ? description : undefined}
      disabled={disabled || readOnly}
      externalServiceId={externalServiceId}
      getDefinition={getDefinition}
      getDefinitionExternalData={getDefinitionExternalData}
      id={id}
      label={name}
      renderSelectedData={renderSelectedData}
      labelIconName={getFluentIconNameByFieldType(fieldLink.field)}
      onChange={onChange}
      onSearch={getDefinitionExternalDataList}
      required={required}
    />
  );
}

export default DynamicIntegrationField;
