import { StatusIcon } from 'components';
import RichTextEditor from 'components/inputs/RichTextEditor';
import DetailsList from 'components/lists/DetailsList/DetailsList';
import { getProjectStatusOptions, getProjectTypeOptions } from 'features/Projects';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  filePropType,
  instancePropType,
  projectPropType,
  taskPropType,
  templatePropType
} from 'types';
import breakpoints from 'utils/breakpoints';
import {
  ColumnActionsMode,
  GroupedList,
  IconButton,
  ProgressIndicator,
  SelectionMode
} from '@fluentui/react';
import InstanceCellRenderer from './InstanceCellRenderer';

const GroupedListStyled = styled(GroupedList)`
  overflow-y: auto;
  padding: 30px;
  padding-top: 0px;
  padding-right: ${(props) => (props.isInstanceGroupList ? '0px' : '30px')};
  width: 100%;

  @media (max-width: ${breakpoints.smallMax}px) {
    padding: 15px;
    padding-top: 0px;
  }

  .ms-GroupedList-group {
    margin-top: 10px;
  }

  .ms-GroupHeader-expand {
    cursor: pointer;
  }

  a {
    text-decoration: none !important;

    &:hover {
      text-decoration: none !important;
    }
  }
`;

const ResultRow = styled.div`
  max-height: ${(props) => (props.$hasMaxHeight ? '400px' : 'none')};
  display: flex;

  :hover {
    cursor: pointer;
  }
`;

const HeaderTitle = styled.div`
  font-size: 22px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  margin-left: 5px;

  @media (max-width: ${breakpoints.smallMax}px) {
    font-size: 18px;
    margin-left: 0px;
  }

  span {
    font-size: 18px;
    font-weight: 400;

    @media (max-width: ${breakpoints.smallMax}px) {
      font-size: 15px;
    }
  }
`;

function GroupedTagList({
  searchResults,
  loading,
  instanceGroups,
  filterProps,
  loadNextPage,
  onSort,
  getFilterItems,
  onFilterChange
}) {
  const { t } = useTranslation();
  const { tagId } = useParams();

  const projectStatuses = getProjectStatusOptions(t);
  const projectTypes = getProjectTypeOptions(t);
  const navigate = useNavigate();

  const { tasks, instances, templates, comments, files, projects } = searchResults;

  const defaultColumn = useMemo(() => {
    return {
      isMultiline: false,
      isResizable: true,
      isPadded: true,
      columnActionsMode: ColumnActionsMode.disabled,
      className: 'details-list-column'
    };
  }, []);

  function getRedirectUrl(item, groupType) {
    const defaultId = '00000000-0000-0000-0000-000000000000';

    if (groupType === 'tasks') {
      return { url: `/tags/${tagId}/task/${item.id}/detail`, openInNewTab: false };
    }

    if (groupType === 'instances') {
      // show summary if possible, otherwise just redirect
      if (item.permissions.readSummary) {
        return {
          url: `/tags/${tagId}/process-instance/${item.id}/instance`,
          openInNewTab: false
        };
      }

      return {
        url: `/process-instances/all/${item.routeDefinitionId}/process-instance-tree/${item.id}`,
        openInNewTab: true
      };
    }

    if (groupType === 'templates') {
      return {
        url: `/process-designer/${item.id}/${item.version}/`,
        openInNewTab: false
      };
    }

    if (groupType === 'comments') {
      if (item.taskId !== defaultId) {
        return {
          url: `/tags/${tagId}/task/${item.originalTaskId || item.taskId}/conversation`,
          openInNewTab: false
        };
      }

      if (item.projectId !== defaultId) {
        return {
          url: `/tags/${tagId}/project/${item.projectId}/conversation`,
          openInNewTab: false
        };
      }

      // instance
      return {
        url: `/tags/${tagId}/process-instance/${item.routeInstanceId}/conversation`,
        openInNewTab: false
      };
    }

    if (groupType === 'files') {
      if (item.taskId && item.taskId !== defaultId) {
        return {
          url: `/tags/${tagId}/task/${item.originalTaskId || item.taskId}/attachments`,
          openInNewTab: false
        };
      }

      if (item.projectId && item.projectId !== defaultId) {
        return {
          url: `/tags/${tagId}/project/${item.projectId}/files`,
          openInNewTab: false
        };
      }

      // instance
      return {
        url: `/tags/${tagId}/process-instance/${item.routeInstanceId}/attachments`,
        openInNewTab: false
      };
    }

    if (groupType === 'projects') {
      return {
        url: `/tags/${tagId}/project/${item.id}/project`,
        openInNewTab: false
      };
    }

    return '';
  }

  // eslint-disable-next-line react/prop-types
  function getDetailsList({ id, items, columns, searchProps, loadNext, type }) {
    let hasMore = false;

    if (searchProps) {
      const { totalItems, pageIndex, itemsPerPage } = searchProps;

      hasMore = totalItems > pageIndex * itemsPerPage;
    }

    return (
      <DetailsList
        isLoading={loading[type]}
        id={id}
        items={items}
        loadNext={loadNext}
        hasMore={hasMore}
        selectionMode={SelectionMode.none}
        selectionPreservedOnEmptyClick
        enterModalSelectionOnTouch
        columns={columns}
        onRenderRow={(renderProps, defaultRender) => {
          const { url, openInNewTab } = getRedirectUrl(renderProps.item, type);

          const onClick = (event) => {
            if (openInNewTab) {
              event.preventDefault();

              window.open(url, '_blank', 'noopener,noreferrer');
            }
          };
          return (
            <Link onClick={onClick} className="c-details-list__name" to={url}>
              {defaultRender(renderProps)}
            </Link>
          );
        }}
      />
    );
  }

  const taskColumns = useMemo(() => {
    const columns = [
      {
        ...defaultColumn,
        fieldName: 'task-status',
        key: 'task-status',
        maxWidth: 40,
        minWidth: 40,
        name: 'Status',
        columnActionsMode: ColumnActionsMode.hasDropdown,
        defaultFilterValues: filterProps?.tasks?.statuses,
        isFiltered: filterProps?.tasks?.statuses?.length > 0,
        onFilterChange: (selectedFilterValues) => {
          const selectedValues = selectedFilterValues.map((item) => item.key);

          onFilterChange('tasks', { statuses: selectedValues });
        },
        getFilterItems: () => {
          return getFilterItems('tasks', ['statuses']).then(({ statuses }) => {
            return statuses.map((status) => {
              return {
                key: status,
                text: t(`taskPanelDetail.status.label.${status}`),
                filterType: 'statuses'
              };
            });
          });
        },
        onRender: (task) => <StatusIcon type="task" status={task.statusId} />
      },
      {
        ...defaultColumn,
        fieldName: 'task-id',
        key: 'task-id',
        maxWidth: 35,
        minWidth: 35,
        name: 'Id',
        onRender: (task) => task.intId
      },
      {
        ...defaultColumn,
        fieldName: 'task-type',
        key: 'task-type',
        minWidth: 100,
        maxWidth: 200,
        columnActionsMode: ColumnActionsMode.hasDropdown,
        defaultFilterValues: filterProps?.tasks?.types,
        isFiltered: filterProps?.tasks?.types?.length > 0,
        onFilterChange: (selectedFilterValues) => {
          const selectedValues = selectedFilterValues.map((item) => item.key);

          onFilterChange('tasks', { types: selectedValues });
        },
        getFilterItems: () => {
          return getFilterItems('tasks', ['types']).then(({ types }) => {
            return types.map((type) => {
              return {
                key: type,
                text: t(`taskType.${type}`),
                filterType: 'types'
              };
            });
          });
        },
        name: t('tags.resultpage.list.tasks.type'),
        onRender: (task) => t(`taskType.${task.typeId}`)
      },
      {
        ...defaultColumn,
        fieldName: 'task-title',
        isMultiline: true,
        key: 'task-title',
        maxWidth: 250,
        minWidth: 170,
        name: t('tags.resultpage.list.tasks.title'),
        onRender: (task) => task.title
      },
      {
        ...defaultColumn,
        fieldName: 'task-startdate',
        columnActionsMode: ColumnActionsMode.hasDropdown,
        onSort: (ascending) => {
          onSort('tasks', ascending, 5);
        },
        sortable: true,
        isSorted: filterProps?.tasks?.sortType === 5,
        isSortedDescending:
          filterProps?.tasks?.sortType === 5 && filterProps?.tasks?.sortAscending === false,
        key: 'task-startdate',
        maxWidth: 70,
        minWidth: 70,
        name: t('tags.resultpage.list.tasks.startDate'),
        onRender: (task) => moment(task.startDate).format('DD.MM.YYYY')
      },
      {
        ...defaultColumn,
        fieldName: 'task-dueDate',
        columnActionsMode: ColumnActionsMode.hasDropdown,
        onSort: (ascending) => {
          onSort('tasks', ascending, 1);
        },
        sortable: true,
        isSorted: filterProps?.tasks?.sortType === 1,
        isSortedDescending:
          filterProps?.tasks?.sortType === 1 && filterProps?.tasks?.sortAscending === false,
        key: 'task-dueDate',
        maxWidth: 100,
        minWidth: 100,
        name: t('tags.resultpage.list.tasks.dueDate'),
        onRender: (task) => moment(task.dueDate).format('DD.MM.YYYY')
      },
      {
        ...defaultColumn,
        fieldName: 'task-assignedTo',
        key: 'task-assignedTo',
        defaultFilterValues: filterProps?.tasks?.assignedToIds,
        columnActionsMode: ColumnActionsMode.hasDropdown,
        isFiltered: filterProps?.tasks?.assignedToIds?.length > 0,
        onFilterChange: (selectedFilterValues) => {
          const selectedValues = selectedFilterValues.map((item) => item.key);

          onFilterChange('tasks', { assignedToIds: selectedValues });
        },
        getFilterItems: () => {
          return getFilterItems('tasks', ['assignedToIds']).then(({ assignedTos }) => {
            return assignedTos.map((item) => {
              return {
                key: item.userId,
                text: item.name || 'Teampool',
                filterType: 'assignedToIds'
              };
            });
          });
        },
        maxWidth: 120,
        minWidth: 120,
        name: t('tags.resultpage.list.tasks.assignedTo'),
        onRender: (task) =>
          task.assignedTo?.name || task.assignedTo?.title || task.assignedToTeam?.displayTitle
      },
      {
        ...defaultColumn,
        fieldName: 'task-priority',
        key: 'task-priority',
        maxWidth: 70,
        minWidth: 70,
        name: t('tags.resultpage.list.tasks.priority'),
        columnActionsMode: ColumnActionsMode.hasDropdown,
        onSort: (ascending) => {
          onSort('tasks', ascending, 6);
        },
        sortable: true,
        isSorted: filterProps?.tasks?.sortType === 6,
        isSortedDescending:
          filterProps?.tasks?.sortType === 6 && filterProps?.tasks?.sortAscending === false,
        defaultFilterValues: filterProps?.tasks?.priorities,
        isFiltered: filterProps?.tasks?.priorities?.length > 0,
        onFilterChange: (selectedFilterValues) => {
          const selectedValues = selectedFilterValues.map((item) => item.key);

          onFilterChange('tasks', { priorities: selectedValues });
        },
        getFilterItems: () => {
          return getFilterItems('tasks', ['priorities']).then(({ priorities }) => {
            return priorities.map((priority) => {
              return {
                key: priority.toString(),
                text: priority.toString(),
                filterType: 'priorities'
              };
            });
          });
        },
        onRender: (task) => task.priority
      },
      {
        ...defaultColumn,
        fieldName: 'task-relation',
        key: 'task-relation',
        maxWidth: 100,
        minWidth: 200,
        name: t('tags.resultpage.list.tasks.relation'),
        columnActionsMode: ColumnActionsMode.hasDropdown,
        defaultFilterValues: [
          ...(filterProps?.tasks?.definitionIds || []),
          ...(filterProps?.tasks?.projectIds || [])
        ],
        isFiltered:
          [...(filterProps?.tasks?.definitionIds || []), ...(filterProps?.tasks?.projectIds || [])]
            .length > 0,
        onFilterChange: (selectedFilterValues, selectedValue) => {
          // could be project or route definition
          const filterType = selectedValue?.filterType;
          const selectedValues = selectedFilterValues
            .filter(({ filterType }) => filterType === selectedValue?.filterType)
            .map((item) => item.key);

          if (!filterType) {
            // reset filter
            onFilterChange('tasks', { definitionIds: [], projectIds: [] });
          } else {
            onFilterChange('tasks', { [filterType]: selectedValues });
          }
        },
        getFilterItems: () => {
          return getFilterItems('tasks', ['definitionIds', 'projectIds']).then(
            ({ routeDefinitions, projects }) => {
              // eslint-disable-next-line react/prop-types
              const projectsItems = projects.map((item) => {
                return { key: item.id, text: item.name, filterType: 'projectIds' };
              });

              const routeDefinitionsItems = routeDefinitions?.map((item) => {
                return { key: item.id, text: item.name, filterType: 'definitionIds' };
              });

              return [...routeDefinitionsItems, ...projectsItems];
            }
          );
        },
        onRender: (task) => {
          if (task.routeInstanceId) {
            return (
              <Link
                to={`/process-instances/all/${task.routeDefinitionId}/process-instance-tree/${task.routeInstanceId}/detail/${task.id}/detail`}
              >
                {task.routeInstanceName}
              </Link>
            );
          }

          if (task.typeId === 50 || task.typeId === 51) {
            return (
              <Link
                to={`/projects/all/task-list/${task.projectId}/${task.sprintId}/${task.id}/detail`}
              >
                {task.projectName}
              </Link>
            );
          }

          if (task.typeId === 60 || task.typeId === 61) {
            return (
              <Link to={`/projects/all/gantt/${task.projectId}/${task.id}/detail`}>
                {task.projectName}
              </Link>
            );
          }
          return '-';
        }
      },
      {
        ...defaultColumn,
        fieldName: 'task-team',
        key: 'task-team',
        maxWidth: 150,
        minWidth: 70,
        name: 'Team',
        columnActionsMode: ColumnActionsMode.hasDropdown,
        defaultFilterValues: filterProps?.tasks?.assignedToTeamIds,
        isFiltered: filterProps?.tasks?.assignedToTeamIds?.length > 0,
        onFilterChange: (selectedFilterValues) => {
          const selectedValues = selectedFilterValues.map((item) => item.key);

          onFilterChange('tasks', { assignedToTeamIds: selectedValues });
        },
        getFilterItems: () => {
          return getFilterItems('tasks', ['assignedToTeamIds']).then(({ teams }) => {
            return teams.map((item) => {
              return {
                key: item.id,
                text: item.displayTitle,
                filterType: 'assignedToTeamIds'
              };
            });
          });
        },
        onRender: (task) => task.assignedToTeam?.displayTitle || '-'
      },
      {
        ...defaultColumn,
        fieldName: 'task-created-by',
        key: 'task-created-by',
        onFilterChange: (selectedFilterValues) => {
          const selectedValues = selectedFilterValues.map((item) => item.key);

          onFilterChange('tasks', { creatorIds: selectedValues });
        },
        getFilterItems: () => {
          return getFilterItems('tasks', ['creatorIds']).then(({ creators }) => {
            return creators.map((item) => {
              return {
                key: item.userId,
                text: item.name,
                filterType: 'creatorIds'
              };
            });
          });
        },
        defaultFilterValues: filterProps?.tasks?.creatorIds,
        maxWidth: 150,
        columnActionsMode: ColumnActionsMode.hasDropdown,
        minWidth: 70,
        name: t('tags.resultpage.list.tasks.createdBy'),
        onRender: (task) => task.creator.name
      },
      {
        ...defaultColumn,
        fieldName: 'task-creationdate',
        columnActionsMode: ColumnActionsMode.hasDropdown,
        onSort: (ascending) => {
          onSort('tasks', ascending, 4);
        },
        sortable: true,
        isSorted: filterProps?.tasks?.sortType === 4,
        isSortedDescending:
          filterProps?.tasks?.sortType === 4 && filterProps?.tasks?.sortAscending === false,
        key: 'task-creationdate',
        maxWidth: 70,
        minWidth: 70,
        name: t('tags.resultpage.list.tasks.created'),
        onRender: (task) => moment(task.creationDate).format('DD.MM.YYYY')
      }
    ];

    return columns;
  }, [
    defaultColumn,
    filterProps?.tasks?.statuses,
    filterProps?.tasks?.types,
    filterProps?.tasks?.sortType,
    filterProps?.tasks?.sortAscending,
    filterProps?.tasks?.assignedToIds,
    filterProps?.tasks?.priorities,
    filterProps?.tasks?.definitionIds,
    filterProps?.tasks?.projectIds,
    filterProps?.tasks?.assignedToTeamIds,
    filterProps?.tasks?.creatorIds,
    t,
    onFilterChange,
    getFilterItems,
    onSort
  ]);

  function getTaskRowContent() {
    const { items, searchProps } = tasks;
    const loadNext = () => loadNextPage('tasks');

    return getDetailsList({
      id: 'tasks',
      items,
      columns: taskColumns || [],
      searchProps,
      loadNext,
      type: 'tasks'
    });
  }

  function getInstanceListColumns(instanceProps) {
    const columns = [];

    instanceProps.columnProps?.forEach((column) => {
      if (column.name === 'intId') {
        columns.push({
          ...defaultColumn,
          fieldName: 'instance-id',
          key: 'instance-id',
          maxWidth: 25,
          minWidth: 25,
          name: 'Id',
          onRender: (instance) => instance.intId
        });
      } else if (column.name === 'name') {
        columns.push({
          ...defaultColumn,
          fieldName: 'instance-title',
          isMultiline: true,
          key: 'instance-title',
          maxWidth: 250,
          minWidth: 170,
          name: t('tags.resultpage.list.instances.title'),
          onRender: (instance) => {
            return (
              <div>
                <span>{instance.name}</span>
                {instance.permissions.readSummary ? (
                  // we only need this menu if the default click action would open the summary instead
                  <IconButton
                    styles={{
                      rootHovered: { backgroundColor: '#E1E1E1' },
                      root: { height: '20px', float: 'right' },
                      menuIcon: { fontSize: '14px', fontWeight: '600' }
                    }}
                    menuIconProps={{ iconName: 'More' }}
                    menuProps={{
                      items: [
                        {
                          key: 'instancePanel',
                          text: t('routeInstances.detailList.props.panel'),
                          iconProps: { iconName: 'SidePanelMirrored' },
                          onClick: () => {
                            const url = `/process-instances/all/${instance.routeDefinitionId}/process-instance-tree/${instance.id}`;
                            window.open(url, '_blank', 'noopener,noreferrer');
                          }
                        }
                      ]
                    }}
                  />
                ) : null}
              </div>
            );
          }
        });
      } else if (column.name === 'percentComplete') {
        columns.push({
          ...defaultColumn,
          fieldName: 'instance-progress',
          isMultiline: true,
          key: 'instance-progress',
          maxWidth: 120,
          minWidth: 120,
          isResizable: false,
          name: t('tags.resultpage.list.instances.progress'),
          onRender: (instance) => (
            <ProgressIndicator barHeight={4} percentComplete={instance.percentComplete} />
          )
        });
      } else if (column.name === 'currentStepName') {
        columns.push({
          ...defaultColumn,
          fieldName: 'instance-current-step',
          isMultiline: true,
          key: 'instance-current-step',
          maxWidth: 120,
          minWidth: 120,
          name: t('tags.resultpage.list.instances.currentStep'),
          onRender: (instance) =>
            instance.status === 4
              ? t('tags.resultpage.list.instances.end')
              : instance.currentStepName
        });
      } else if (column.name === 'creator') {
        columns.push({
          ...defaultColumn,
          fieldName: 'instance-started-by',
          key: 'instance-started-by',
          maxWidth: 150,
          minWidth: 70,
          name: t('tags.resultpage.list.instances.createdBy'),
          onRender: (instance) => instance.creator.name
        });
      } else if (column.name === 'creationDate') {
        columns.push({
          ...defaultColumn,
          fieldName: 'instance-creationdate',
          key: 'instance-creationdate',
          maxWidth: 70,
          minWidth: 70,
          name: t('tags.resultpage.list.instances.creationDate'),
          onRender: (instance) => moment(instance.creationDate).format('DD.MM.YYYY')
        });
      } else if (column.name === 'plannedEndDate') {
        columns.push({
          ...defaultColumn,
          fieldName: 'instance-plannedEndDate',
          key: 'instance-planned-enddate',
          maxWidth: 70,
          minWidth: 70,
          name: t('tags.resultpage.list.instances.plannedEndDate'),
          onRender: (instance) => moment(instance.plannedEndDate).format('DD.MM.YYYY')
        });
      } else if (column.name === 'currentEndDate') {
        columns.push({
          ...defaultColumn,
          fieldName: 'instance-currentEndDate',
          key: 'instance-currentEndDate',
          maxWidth: 70,
          minWidth: 70,
          name: t('tags.resultpage.list.instances.currentEndDate'),
          onRender: (instance) => moment(instance.currentEndDate).format('DD.MM.YYYY')
        });
      } else if (column.name === 'version') {
        columns.push({
          ...defaultColumn,
          fieldName: 'version',
          key: 'version',
          maxWidth: 70,
          minWidth: 70,
          name: 'Version'
        });
      } else if (column.name !== 'tags') {
        columns.push({
          ...defaultColumn,
          fieldName: column?.name,
          name: column?.name,
          key: column?.id,
          onRender: (instance) => {
            return <InstanceCellRenderer column={column} instance={instance} />;
          }
        });
      }
    });

    return columns;
  }

  function getInstanceDetailsList(instanceProps) {
    const columns = getInstanceListColumns(instanceProps);

    columns.unshift({
      ...defaultColumn,
      fieldName: 'instance-status',
      key: 'instance-status',
      maxWidth: 30,
      minWidth: 30,
      name: 'Status',
      onRender: (instance) => <StatusIcon type="process-instance" status={instance.status} />
    });

    const { items, id } = instanceProps;
    const { searchProps } = instanceProps;
    const loadNext = () => loadNextPage('instances', id);

    return (
      <ResultRow $hasMaxHeight>
        {getDetailsList({
          id,
          items,
          searchProps,
          loadNext,
          columns,
          type: 'instances'
        })}
      </ResultRow>
    );
  }

  function getInstanceRowContent() {
    const groups = instanceGroups.map(({ name, id }, index) => {
      const instanceSearchResult = searchResults.instances.find((item) => item.id === id);
      const groupItems = instanceSearchResult.data;

      return {
        columnProps: instanceSearchResult.fullResponse?.columns || [],
        searchProps: instanceSearchResult.nextSearchProps || {},
        items: groupItems,
        key: `group${name}${index}`,
        name,
        value: id,
        id,
        startIndex: index,
        level: 0,
        count: 1,
        isCollapsed: false
      };
    });

    return (
      <GroupedListStyled
        isInstanceGroupList
        items={groups}
        selectionMode={SelectionMode.none}
        groups={groups}
        groupProps={{
          headerProps: {
            onRenderTitle: (renderProps) => {
              return (
                <HeaderTitle style={{ fontSize: 17 }}>
                  {getHeaderText(renderProps.group.name)}{' '}
                  <span style={{ fontSize: 15 }}>({renderProps.group.items?.length})</span>
                </HeaderTitle>
              );
            }
          }
        }}
        onRenderCell={(_, resultItem) => getInstanceDetailsList(resultItem)}
      />
    );
  }

  function getTemplateRowContent() {
    const columns = [
      {
        ...defaultColumn,
        fieldName: 'template-title',
        isMultiline: true,
        key: 'template-title',
        maxWidth: 250,
        minWidth: 170,
        name: t('tags.resultpage.list.templates.title'),
        onRender: (template) => template.name
      },
      {
        ...defaultColumn,
        fieldName: 'template-team',
        key: 'template-team',
        maxWidth: 250,
        minWidth: 170,
        name: 'Team',
        onRender: (template) => template.team?.displayTitle || '-'
      },
      {
        ...defaultColumn,
        fieldName: 'template-created-by',
        key: 'template-created-by',
        maxWidth: 250,
        minWidth: 170,
        name: t('tags.resultpage.list.templates.createdBy'),
        onRender: (template) => template.creator.name
      },
      {
        ...defaultColumn,
        fieldName: 'template-creationDate',
        key: 'template-creationDate',
        maxWidth: 250,
        minWidth: 170,
        name: t('tags.resultpage.list.templates.creationDate'),
        onRender: (template) => moment(template.creationDate).format('DD.MM.YYYY')
      }
    ];

    const { items, searchProps } = templates;
    const loadNext = () => loadNextPage('templates');

    return getDetailsList({
      id: 'templates',
      items,
      searchProps,
      loadNext,
      columns,
      type: 'templates'
    });
  }

  function getCommentRowContent() {
    const columns = [
      {
        ...defaultColumn,
        fieldName: 'comment-task-name',
        key: 'comment-task-name',
        maxWidth: 250,
        minWidth: 170,
        name: t('tags.resultpage.list.comments.taskName'),
        onRender: (comment) => {
          if (comment.taskName) {
            return comment.taskName;
          }

          if (comment.projectName) {
            return `${comment.projectName} (${t('tags.resultpage.list.file.type.project')})`;
          }

          if (comment.routeInstanceName) {
            return `${comment.routeInstanceName} (${t('tags.resultpage.list.file.type.instance')})`;
          }

          return '';
        }
      },
      {
        ...defaultColumn,
        fieldName: 'comments-message',
        key: 'comments-message',
        maxWidth: 250,
        minWidth: 170,
        isMultiline: true,
        name: t('tags.resultpage.list.comments.message'),
        onRender: (comment) => <RichTextEditor defaultValue={comment.message} disabled />
      }
    ];
    const { items, searchProps } = comments;
    const loadNext = () => loadNextPage('comments');

    return getDetailsList({
      searchProps,
      loadNext,
      id: 'comments',
      items,
      columns,
      type: 'comments'
    });
  }

  function getFileRowContent() {
    const columns = [
      {
        ...defaultColumn,
        fieldName: 'file-item-name',
        key: 'file-item-name',
        maxWidth: 250,
        minWidth: 170,
        name: t('tags.resultpage.list.file.itemName'),
        onRender: (file) => file.routeInstanceName || file.taskName || file.projectName
      },
      {
        ...defaultColumn,
        fieldName: 'file-item-type',
        key: 'file-item-type',
        maxWidth: 70,
        minWidth: 50,
        name: t('tags.resultpage.list.file.itemType'),
        onRender: ({ routeInstanceName, projectName }) => {
          let type = 'task';
          if (routeInstanceName) type = 'instance';
          if (projectName) type = 'project';

          return t(`tags.resultpage.list.file.type.${type}`);
        }
      },
      {
        ...defaultColumn,
        fieldName: 'file-name',
        key: 'file-name',
        maxWidth: 100,
        minWidth: 50,
        isMultiline: true,
        name: t('tags.resultpage.list.file.name'),
        onRender: (file) => file.name
      },
      {
        ...defaultColumn,
        fieldName: 'file-description',
        key: 'file-description',
        maxWidth: 200,
        minWidth: 170,
        isMultiline: true,
        name: t('tags.resultpage.list.file.description'),
        onRender: (file) =>
          file.description ? <RichTextEditor defaultValue={file.description} disabled /> : '-'
      },
      {
        ...defaultColumn,
        fieldName: 'file-created-by',
        key: 'file-created-by',
        maxWidth: 250,
        minWidth: 170,
        name: t('tags.resultpage.list.file.createdBy'),
        onRender: (file) => file.creator.name
      },
      {
        ...defaultColumn,
        fieldName: 'file-creationDate',
        key: 'file-creationDate',
        maxWidth: 250,
        minWidth: 170,
        name: t('tags.resultpage.list.file.creationDate'),
        onRender: (file) => moment(file.creationDate).format('DD.MM.YYYY')
      }
    ];
    const { items, searchProps } = files;
    const loadNext = () => loadNextPage('files');

    return getDetailsList({ loadNext, searchProps, id: 'files', items, columns, type: 'files' });
  }

  function getProjectRowContent() {
    const columns = [
      {
        ...defaultColumn,
        fieldName: 'project-id',
        key: 'project-id',
        maxWidth: 25,
        minWidth: 25,
        name: 'Id',
        onRender: (project) => project.intId
      },
      {
        ...defaultColumn,
        fieldName: 'project-type',
        key: 'project-type',
        maxWidth: 120,
        minWidth: 120,
        name: t('tags.resultpage.list.projects.type'),
        columnActionsMode: ColumnActionsMode.hasDropdown,
        defaultFilterValues: [filterProps?.projects?.projectType],
        isFiltered: typeof filterProps?.projects?.projectType !== 'undefined',
        singleSelectFilter: true,
        onFilterChange: (selectedFilterValues) => {
          onFilterChange('projects', { projectType: selectedFilterValues?.[0]?.key });
        },
        getFilterItems: () => {
          return getFilterItems('projects', ['projectType']).then(({ types }) => {
            return types.map((type) => {
              return {
                key: type,
                text: type === 1 ? t('projects.types.classic') : t('projects.types.agile'),
                filterType: 'projectType'
              };
            });
          });
        },
        onRender: (project) => {
          if (projectTypes?.length > 0) {
            return projectTypes.find((type) => type.key === project.type)?.text || '';
          }

          return '';
        }
      },
      {
        ...defaultColumn,
        fieldName: 'project-name',
        isMultiline: true,
        key: 'project-name',
        maxWidth: 250,
        minWidth: 170,
        name: t('tags.resultpage.list.projects.name'),
        columnActionsMode: ColumnActionsMode.hasDropdown,
        onSort: (ascending) => {
          onSort('projects', ascending, 1);
        },
        sortable: true,
        isSorted: filterProps?.projects?.sortType === 1,
        isSortedDescending:
          filterProps?.projects?.sortType === 1 && filterProps?.projects?.sortAscending === false,
        onRender: (project) => {
          return (
            <div>
              <span>{project.name}</span>
              <IconButton
                styles={{
                  rootHovered: { backgroundColor: '#E1E1E1' },
                  root: { height: '20px', float: 'right' },
                  menuIcon: { fontSize: '14px', fontWeight: '600' }
                }}
                menuIconProps={{ iconName: 'More' }}
                menuProps={{
                  items: [
                    {
                      key: 'instancePanel',
                      text: t('routeInstances.detailList.props.panel'),
                      iconProps: { iconName: 'SidePanelMirrored' },
                      onClick: () => {
                        let url = `/projects/all/gantt/${project.id}`;

                        if (project.type === 2) {
                          url = `/projects/all/task-list/${project.id}/00000000-0000-0000-0000-000000000000`;
                        }

                        navigate(url);
                      }
                    }
                  ]
                }}
              />
            </div>
          );
        }
      },
      {
        ...defaultColumn,
        fieldName: 'project-startdate',
        key: 'project-startdate',
        maxWidth: 70,
        minWidth: 70,
        name: t('tags.resultpage.list.projects.startDate'),
        columnActionsMode: ColumnActionsMode.hasDropdown,
        onSort: (ascending) => {
          onSort('projects', ascending, 4);
        },
        sortable: true,
        isSorted: filterProps?.projects?.sortType === 4,
        isSortedDescending:
          filterProps?.projects?.sortType === 4 && filterProps?.projects?.sortAscending === false,
        onRender: (project) => moment(project.startDate).format('DD.MM.YYYY')
      },
      {
        ...defaultColumn,
        fieldName: 'project-enddate',
        key: 'project-enddate',
        maxWidth: 70,
        minWidth: 70,
        name: t('tags.resultpage.list.projects.endDate'),
        columnActionsMode: ColumnActionsMode.hasDropdown,
        onSort: (ascending) => {
          onSort('projects', ascending, 5);
        },
        sortable: true,
        isSorted: filterProps?.projects?.sortType === 5,
        isSortedDescending:
          filterProps?.projects?.sortType === 5 && filterProps?.projects?.sortAscending === false,
        onRender: (project) => moment(project.plannedEndDate).format('DD.MM.YYYY')
      },
      {
        ...defaultColumn,
        fieldName: 'project-progress',
        isMultiline: true,
        key: 'project-progress',
        maxWidth: 120,
        minWidth: 120,
        isResizable: false,
        name: t('tags.resultpage.list.projects.progress'),
        onRender: (project) => (
          <ProgressIndicator barHeight={4} percentComplete={project.percentComplete} />
        )
      },
      {
        ...defaultColumn,
        fieldName: 'project-current',
        key: 'project-current',
        maxWidth: 150,
        minWidth: 70,
        name: t('tags.resultpage.list.projects.currentPhase.title'),
        columnActionsMode: ColumnActionsMode.hasDropdown,
        defaultFilterValues: filterProps?.projects?.statuses,
        isFiltered: filterProps?.projects?.statuses?.length > 0,
        onFilterChange: (selectedFilterValues) => {
          const selectedValues = selectedFilterValues.map((item) => item.key);

          onFilterChange('projects', { statuses: selectedValues });
        },
        getFilterItems: () => {
          return getFilterItems('projects', ['statuses']).then(({ statuses }) => {
            return statuses.map((status) => {
              return {
                key: status?.toString(),
                text: t(`projects.statuses.${status}`),
                filterType: 'statuses'
              };
            });
          });
        },
        onRender: (project) => {
          const status = projectStatuses.find((status) => status.key === project.status);

          if (project.status === 10) {
            return status?.text;
          }

          if (project.plannedEndDate) {
            const today = new Date(moment(new Date()).format('yyyy-MM-DD'));
            const endDate = new Date(moment(project.plannedEndDate).format('yyyy-MM-DD'));

            if (endDate < today) {
              return project.type === 2
                ? t('tags.resultpage.list.projects.currentPhase.finishedLastSprint')
                : t('tags.resultpage.list.projects.currentPhase.overdue');
            }
          }

          if (project.type === 2 && project.currentSprint) {
            return project.currentSprint.name;
          }

          return status?.text || '';
        }
      },
      {
        ...defaultColumn,
        fieldName: 'project-team',
        key: 'project-team',
        maxWidth: 170,
        minWidth: 100,
        name: t('tags.resultpage.list.projects.team'),
        columnActionsMode: ColumnActionsMode.hasDropdown,
        defaultFilterValues: filterProps?.projects?.teamIds,
        isFiltered: filterProps?.projects?.teamIds?.length > 0,
        onFilterChange: (selectedFilterValues) => {
          const selectedValues = selectedFilterValues.map((item) => item.key);

          onFilterChange('projects', { teamIds: selectedValues });
        },
        getFilterItems: () => {
          return getFilterItems('projects', ['teamIds']).then(({ teams }) => {
            return teams.map((item) => {
              return {
                key: item.id,
                text: item.displayTitle,
                filterType: 'teamIds'
              };
            });
          });
        },
        onSort: (ascending) => {
          onSort('projects', ascending, 2);
        },
        sortable: true,
        isSorted: filterProps?.projects?.sortType === 2,
        isSortedDescending:
          filterProps?.projects?.sortType === 2 && filterProps?.projects?.sortAscending === false,
        onRender: (project) => project.team?.displayTitle
      }
    ];

    const { items, searchProps } = projects;
    const loadNext = () => loadNextPage('projects');

    return getDetailsList({
      id: 'projects',
      items,
      columns,
      searchProps,
      loadNext,
      type: 'projects'
    });
  }

  function getHeaderText(type) {
    if (type === 'tasks') {
      return t('tags.resultpage.list.header.tasks');
    }

    if (type === 'comments') {
      return t('tags.resultpage.list.header.comments');
    }

    if (type === 'instances') {
      return t('tags.resultpage.list.header.instances');
    }

    if (type === 'templates') {
      return t('tags.resultpage.list.header.templates');
    }

    if (type === 'files') {
      return t('tags.resultpage.list.header.files');
    }

    if (type === 'projects') {
      return t('tags.resultpage.list.header.projects');
    }

    return type;
  }

  function resultRenderer(resultItem) {
    let rowContent = null;

    if (resultItem === 'tasks') {
      rowContent = getTaskRowContent();
    } else if (resultItem === 'projects') {
      rowContent = getProjectRowContent();
    } else if (resultItem === 'instances') {
      rowContent = getInstanceRowContent();
    } else if (resultItem === 'templates') {
      rowContent = getTemplateRowContent();
    } else if (resultItem === 'comments') {
      rowContent = getCommentRowContent();
    } else if (resultItem === 'files') {
      rowContent = getFileRowContent();
    }

    return <ResultRow $hasMaxHeight={resultItem !== 'instances'}>{rowContent}</ResultRow>;
  }

  const groupedDetailsListItems = [];

  if (tasks?.items.length || loading.tasks) {
    groupedDetailsListItems.push('tasks');
  }

  if (instances?.length || loading.instances) {
    groupedDetailsListItems.push('instances');
  }

  if (projects?.items.length || loading.projects) {
    groupedDetailsListItems.push('projects');
  }

  if (comments?.items.length || loading.comments) {
    groupedDetailsListItems.push('comments');
  }

  if (files?.items.length || loading.files) {
    groupedDetailsListItems.push('files');
  }

  if (templates?.items.length || loading.templates) {
    groupedDetailsListItems.push('templates');
  }

  const groups = groupedDetailsListItems.map((currentItem, index) => {
    return {
      key: `group${currentItem}${index}`,
      name: currentItem,
      value: currentItem,
      startIndex: index,
      level: 0,
      count: 1,
      isCollapsed: false
    };
  });

  return (
    <GroupedListStyled
      items={groupedDetailsListItems}
      selectionMode={SelectionMode.none}
      groups={groups}
      groupProps={{
        headerProps: {
          onRenderTitle: (renderProps) => {
            let count = 0;

            if (renderProps.group.name !== 'instances') {
              count = searchResults[renderProps.group.value].items.length;
            } else {
              searchResults.instances.forEach((templateGroup) => {
                count += templateGroup.data.length || 0;
              });
            }

            return (
              <HeaderTitle>
                {getHeaderText(renderProps.group.name)} <span>({count})</span>
              </HeaderTitle>
            );
          }
        }
      }}
      compact
      onRenderCell={(_, resultItem) => resultRenderer(resultItem)}
    />
  );
}

export const searchProps = PropTypes.shape({
  itemsPerPage: PropTypes.number,
  pageIndex: PropTypes.number,
  totalItems: PropTypes.number
});

GroupedTagList.propTypes = {
  loading: PropTypes.shape({
    tasks: PropTypes.bool,
    instances: PropTypes.bool,
    files: PropTypes.bool,
    templates: PropTypes.bool,
    projects: PropTypes.bool,
    comments: PropTypes.bool
  }),
  searchResults: PropTypes.shape({
    tasks: {
      items: PropTypes.arrayOf(taskPropType),
      searchProps: searchProps.isRequired
    },
    instances: PropTypes.arrayOf(
      PropTypes.shape({
        data: PropTypes.arrayOf(instancePropType),
        fullResponse: PropTypes.shape({
          columns: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string,
              name: PropTypes.string,
              type: PropTypes.number
            })
          )
        }),
        nextSearchProps: searchProps.isRequired
      })
    ),
    templates: PropTypes.shape({
      items: PropTypes.arrayOf(templatePropType),
      searchProps: searchProps.isRequired
    }),
    projects: PropTypes.shape({
      items: PropTypes.arrayOf(projectPropType),
      searchProps: searchProps.isRequired
    }),
    comments: PropTypes.shape({
      items: PropTypes.arrayOf(),
      searchProps: searchProps.isRequired
    }),
    files: PropTypes.shape({
      items: PropTypes.arrayOf(filePropType),
      searchProps: searchProps.isRequired
    })
  }),
  instanceGroups: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string
    })
  ).isRequired,
  filterProps: PropTypes.shape({
    tasks: PropTypes.shape({
      assignedToTeamIds: PropTypes.arrayOf(PropTypes.string),
      types: PropTypes.arrayOf(PropTypes.string),
      sortType: PropTypes.number,
      statuses: PropTypes.arrayOf(PropTypes.number),
      sortAscending: PropTypes.bool,
      priorities: PropTypes.arrayOf(PropTypes.string),
      definitionIds: PropTypes.arrayOf(PropTypes.string),
      projectIds: PropTypes.arrayOf(PropTypes.string),
      creatorIds: PropTypes.arrayOf(PropTypes.string),
      assignedToIds: PropTypes.arrayOf(PropTypes.string)
    }),
    projects: PropTypes.shape({
      projectType: PropTypes.arrayOf(PropTypes.string),
      teamIds: PropTypes.arrayOf(PropTypes.string),
      sortType: PropTypes.number,
      statuses: PropTypes.number,
      sortAscending: PropTypes.bool
    })
  }),
  loadNextPage: PropTypes.func.isRequired,
  getFilterItems: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired
};

GroupedTagList.defaultProps = {
  searchResults: null
};

export default GroupedTagList;
