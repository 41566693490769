/* eslint-disable jsx-a11y/no-static-element-interactions */

import { AppContext, useNotificationContext } from 'features/App';
import { deleteChat } from 'features/App/context/ChatsContext';
import { CHAT, ChatIncludes, CHATS, IChat, useApiObject } from 'hooks/api2';
import { evocomBeeLogo } from 'layouts';
import { NavStyled as DefaultNavStyled } from 'layouts/Navigation/Navigation';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useMatches, useNavigate } from 'react-router-dom';
import { fetchAIVoid } from 'services/api2';
import styled, { useTheme } from 'styled-components';
import breakpoints from 'utils/breakpoints';
import {
  Callout,
  DefaultButton,
  DirectionalHint,
  Icon,
  IconButton,
  IContextualMenuItem,
  INavLinkGroup,
  Persona,
  PersonaSize,
  Shimmer,
  TextField
} from '@fluentui/react';
import { useLocalStorage, writeStorage } from '@rehooks/local-storage';
import { useId } from '@fluentui/react-hooks';
import { UIMatch } from 'react-router';
import { DateHeader, getDateHeaders } from './chatDates';
import { NavigationContext } from './NavigationContext';

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

const NavStyled = styled(DefaultNavStyled)`
  max-width: none;
  min-width: fit-content;

  .ms-Nav-groupContent {
    margin-bottom: 0;
  }

  .ms-Nav-link {
    padding-right: 5px;
  }
`;

const NavContainer = styled.div<{ $collapsed?: boolean; $isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-left: 8px;
  max-width: ${({ $collapsed }) => ($collapsed ? '80px' : '250px')};
  min-width: ${({ $collapsed }) => ($collapsed ? '80px' : '250px')};
  color: rgb(${({ theme }) => theme.aiNavigation.foreground});

  transition: max-width 0.3s, min-width 0.3s, opacity 0.2s ease-out;

  &.is-overlay {
    min-width: 0;
    max-width: none;
    padding-right: 8px;
    opacity: ${({ $isOpen }) => ($isOpen ? '1' : '0')};
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .ms-Nav-compositeLink {
    .ms-Button {
      &:hover {
        background: rgb(${({ theme }) => theme.aiNavigation.itemActiveBackground});
        border-radius: 5px;
      }
    }

    .ms-Button-flexContainer {
      justify-content: ${({ $collapsed }) => ($collapsed ? 'center' : 'flex-start')};
      margin-left: ${({ $collapsed }) => ($collapsed ? '0px' : '5px')};
    }
  }

  .chats {
    margin-top: 3px;
    flex: 1;
    overflow-y: auto;
    margin-left: 20px;
    gap: 15px;
    display: flex;
    flex-direction: column;
  }

  .chat-date-title {
    color: rgb(${({ theme }) => theme.aiNavigation.chatItemForeground});
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1rem;
    padding-bottom: 0.5rem;
    padding-right: 0.5rem;
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .chat-title {
    display: grid;
    grid-template-columns: 1fr auto;
    margin-left: 5px;
    border-radius: 0.5rem;
    position: relative;
    color: rgb(${({ theme }) => theme.aiNavigation.chatItemForeground});
    font-size: 12px;
    font-weight: 500;

    > .c-link {
      color: inherit;
      text-decoration: none;
      padding: 6px;
      min-width: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &.menu-open,
    &.selected {
      background: rgb(${({ theme }) => theme.aiNavigation.itemActiveBackground});

      .chat-menu {
        visibility: visible;
      }
    }

    @media (hover: hover) {
      &:hover {
        background: rgb(${({ theme }) => theme.aiNavigation.itemActiveBackground});

        .chat-menu {
          visibility: visible;
        }
      }
    }

    @media (hover: none) {
      /* always visible on mobile */
      .chat-menu {
        position: relative;
        visibility: visible;
        background: none;
      }
    }
  }

  .chat-menu {
    background: rgb(${({ theme }) => theme.aiNavigation.itemActiveBackground});
    border-radius: 5px;
    position: absolute;
    top: 6px;
    right: 0;
    visibility: hidden;

    &.visible {
      visibility: visible;
    }
  }

  .chat-title-textfield {
    .ms-TextField-fieldGroup {
      height: 31px;
    }
  }

  .chat-group {
    ul {
      border-left: 1px solid rgb(${({ theme }) => theme.aiNavigation.spineColor});
    }
  }

  .nav-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: ${({ $collapsed }) => ($collapsed ? '5px auto 0px auto' : '5px 5px 0px 20px')};
  }

  .collapse-menu-button {
    margin-top: 5px;
    display: ${({ $collapsed }) => ($collapsed ? 'none' : 'block')};
    border-radius: 50%;

    &:hover {
      background: rgb(${({ theme }) => theme.aiNavigation.itemActiveBackground});
    }

    i {
      font-size: 17px;
      transform: rotate(90deg);
    }
  }

  .navigation-footer {
    .collapse-menu-button {
      display: ${({ $collapsed }) => ($collapsed ? 'block' : 'none')};

      @media (max-width: ${breakpoints.smallMax}px) {
        display: none;
      }

      i {
        font-size: 18px;
        transform: rotate(-90deg);
      }
    }
  }

  .logo {
    display: flex;
    gap: 10px;
    height: 50px;
    align-items: center;

    .icon {
      padding-top: 8px;
    }

    .logo-text {
      display: ${({ $collapsed }) => ($collapsed ? 'none' : 'block')};
      font-size: 27px;
    }
  }

  .navigation-footer {
    display: flex;
    flex-direction: ${({ $collapsed }) => ($collapsed ? 'column' : 'row')};
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid rgb(${({ theme }) => theme.aiNavigation.foreground} / 0.05);
    margin-top: auto;
  }

  .profile-button {
    margin: 6px 10px;
    border-radius: 9999px;
    padding: 10px;
    border: none;
    height: 45px;
    background: transparent;

    .ms-Icon {
      display: none;
    }

    &:hover {
      background: rgb(${({ theme }) => theme.aiNavigation.itemActiveBackground});
    }
  }

  .profile-button-content {
    display: flex;
    align-items: center;
    overflow: hidden;

    .ms-Persona {
      display: block;
      padding: 0px;
    }

    span {
      padding: 0px 10px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .settings-button {
    display: ${({ $collapsed }) => ($collapsed ? 'none' : 'block')};
    border-radius: 50%;

    &:hover {
      background: rgb(${({ theme }) => theme.aiNavigation.itemActiveBackground});
    }

    i {
      font-size: 15px;
      color: rgb(${({ theme }) => theme.aiNavigation.settingsIconColor});
    }
  }

  .ms-Nav-linkText {
    display: ${({ $collapsed }) => ($collapsed ? 'none' : 'block')};
  }
`;

const ChatsCalloutContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 15px;
  min-width: 200px;

  .group-header {
    font-weight: 500;
    font-size: 13px;
    margin-top: 6px;
    margin-bottom: 6px;
    color: rgb(${({ theme }) => theme.aiNavigation.calloutHeaderForeground});
  }

  .chat-list {
    border-left: 1px solid rgb(${({ theme }) => theme.aiNavigation.spineColor});
    margin-left: 2px;
    padding-left: 3px;
    display: flex;
    flex-direction: column;
    gap: 2px;

    > .c-empty {
      opacity: 0.5;
      padding: 0.25em;
      text-align: center;
    }
    &:has(> .c-empty) {
      border-left: none;
      margin-left: 0;
      padding-left: 0;
    }
  }

  .callout-button {
    background: none;
    text-decoration: none;
    padding: 6px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    border-radius: 0.5rem;
    position: relative;
    color: rgb(${({ theme }) => theme.aiNavigation.chatItemForeground});
    font-size: 12px;
    font-weight: 500;

    &:hover,
    &.menu-open,
    &.selected {
      background: rgb(${({ theme }) => theme.aiNavigation.itemActiveBackground});

      .chat-menu {
        visibility: visible;
      }
    }
  }

  .show-all-button {
    font-size: 14px;
    display: flex;
    gap: 8px;
    align-items: center;
  }
`;

interface NavigationProps {
  className?: string;
  isOverlay?: boolean;
  logout?: () => void;
}

function useCurrentChatId() {
  const match = window.location.pathname.match(/chat\/([^/]+)/);
  return match ? match[1] : null;
}

function getSelectedNavKey(route: UIMatch | undefined, collapsed: boolean) {
  if (!route) return '';

  const firstPath = route.pathname.split('/')[1];

  if (firstPath === 'dashboard') {
    return 'dashboard';
  }

  if (firstPath === 'agents') {
    return 'agents';
  }

  if (!firstPath || (firstPath === 'chat' && collapsed)) {
    return 'chats';
  }

  if (firstPath === 'teams') {
    return 'teams';
  }

  return '';
}

export default function Navigation({ className, isOverlay, logout }: NavigationProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  const [collapsed = false] = useLocalStorage<boolean>(`navigation-collapsed`);
  const setCollapsed = (collapsed: boolean) => writeStorage(`navigation-collapsed`, collapsed);

  const [isChatsCalloutOpen, setIsChatsCalloutOpen] = useState(false);

  const navChatsId = useId();

  const navigate = useNavigate();

  const routeMatches = useMatches();
  const firstRoute = routeMatches.find((route) => route.id !== 'root');

  const {
    globalAppState: { currentUser, tenants, currentTenantId }
  } = useContext(AppContext);

  const { isOpen, close } = useContext(NavigationContext);

  const navLinkGroups: INavLinkGroup[] = [
    {
      links: [
        {
          name: t('ai.navigation.links.chats'),
          icon: 'OfficeChat',
          id: navChatsId,
          url: '/',
          onClick: (event) => {
            event.preventDefault();

            if (collapsed) {
              setIsChatsCalloutOpen(true);
            } else {
              close();
              navigate('/');
            }
          },
          key: 'chats'
        },
        {
          name: t('ai.navigation.links.agents'),
          url: '/agents',
          key: 'agents',
          menuProps: {},
          icon: 'AppIconDefault'
        },
        {
          name: t('ai.navigation.links.dashboard'),
          url: '/dashboard',
          icon: 'AutoRacing',
          key: 'dashboard'
        },
        {
          name: t('ai.navigation.links.teams'),
          icon: 'Group',
          url: '/teams',
          key: 'teams'
        }
      ]
    }
  ];

  function onLinkClick(event, element) {
    event.preventDefault();
    close();
    navigate(element.url);
  }

  const settingsItems = [
    {
      key: 'Settings',
      text: t('ai.navigation.buttons.settings'),
      iconProps: { iconName: 'Settings' },
      onClick: () => {
        close();
        navigate('/settings');
      }
    }
  ];

  const profileMenuItems: IContextualMenuItem[] = [
    {
      key: 'tenants',
      text: t('ai.navigation.buttons.tenants'),
      iconProps: { iconName: 'Org' },

      subMenuProps: {
        styles: { root: { maxHeight: '300px', overflowY: 'auto' } },
        items: [
          ...tenants.map((tenant) => ({
            checked: tenant.tenantId === currentTenantId,
            canCheck: true,
            key: tenant.tenantId,
            text: tenant.name,
            onClick: () => {
              if (tenant.tenantId && currentTenantId !== tenant.tenantId) {
                window.localStorage.setItem('tenantId', tenant.tenantId);
                window.location.href = '/';
              }
            }
          }))
        ]
      }
    },
    {
      key: 'signOut',
      text: t('ai.navigation.buttons.logout'),
      iconProps: { iconName: 'SignOut' },
      onClick: logout
    },
    ...(collapsed ? settingsItems : [])
  ];

  return (
    <NavContainer
      $isOpen={isOpen}
      $collapsed={collapsed}
      className={`navigation-container ${className || ''} ${isOverlay ? 'is-overlay' : ''}`}
    >
      {collapsed && isChatsCalloutOpen && (
        <Callout
          gapSpace={5}
          directionalHint={DirectionalHint.rightCenter}
          hidden={!isChatsCalloutOpen}
          onDismiss={() => setIsChatsCalloutOpen(null)}
          target={`#${navChatsId}`}
        >
          <ChatsCalloutContent
            onClose={() => {
              setIsChatsCalloutOpen(false);
            }}
          />
        </Callout>
      )}
      <div className="nav-header">
        <div className="logo">
          <div className="icon">{evocomBeeLogo(44, `rgb(${theme.aiNavigation.logoColor})`)}</div>
          <div className="logo-text">evocom</div>
        </div>
        <IconButton
          className="collapse-menu-button"
          menuIconProps={{ iconName: 'Download' }}
          onClick={() => {
            if (isOverlay) {
              // hide navigation panel if we're in overlay mode
              close();
            } else {
              setCollapsed(!collapsed);
            }
          }}
        />
      </div>

      <NavStyled
        selectedKey={getSelectedNavKey(firstRoute, collapsed)}
        groups={navLinkGroups}
        styles={{ root: { height: '100%' } }}
        onLinkClick={onLinkClick}
      />

      {!collapsed && <Chats />}

      <div className="navigation-footer">
        <IconButton
          className="collapse-menu-button"
          menuIconProps={{ iconName: 'Download' }}
          onClick={() => setCollapsed(!collapsed)}
        />
        <DefaultButton
          className="profile-button"
          menuProps={{ items: profileMenuItems }}
          onRenderText={() => {
            return (
              <div key="profile-button" className="profile-button-content">
                <Persona
                  imageUrl={currentUser.pictureUrl}
                  size={PersonaSize.size32}
                  styles={{
                    secondaryText: { color: `rgb(${theme?.header?.foreground})` },
                    primaryText: {
                      color: `rgb(${theme?.header?.foreground})`,
                      height: '16px',
                      selectors: {
                        '&:hover': { color: `rgb(${theme?.header?.foreground})` }
                      }
                    },
                    root: { paddingTop: '5px' }
                  }}
                />
                {!collapsed && <span>{currentUser.name}</span>}
              </div>
            );
          }}
        />
        <IconButton
          className="settings-button"
          menuIconProps={{ iconName: 'Settings' }}
          onClick={() => {
            close();
            navigate('/settings');
          }}
        />
      </div>
    </NavContainer>
  );
}

export const NAV_DATE_HEADERS = [
  DateHeader.Today,
  DateHeader.Yesterday,
  DateHeader.ThisWeek,
  DateHeader.LastWeek
];

function Chats() {
  const { t } = useTranslation();
  const { showError } = useNotificationContext();

  const { data: chatsData, isLoading } = useApiObject(
    CHATS,
    {},
    { includes: [ChatIncludes.Agent] }
  );
  const navigate = useNavigate();

  const [renameChatId, setRenameChatId] = useState<string | null>(null);

  const chatId = useCurrentChatId();

  // this can't be memoized because we need dependency changes for isPinned
  const chatsSeparatedByDate = (() => {
    const buckets = getDateHeaders()
      .filter((header) => NAV_DATE_HEADERS.includes(header.label))
      .map((header) => ({
        ...header,
        chats: [] as IChat[]
      }));

    const sortedChats = (chatsData?.items ?? [])
      .filter((chat) => !chat.agent && chat.isPinned)
      .sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());

    for (const chat of sortedChats) {
      const chatDate = new Date(chat.updatedAt);
      for (const bucket of buckets) {
        if (chatDate >= bucket.earliestDate) {
          bucket.chats.push(chat);
          break;
        }
      }
    }

    return buckets;
  })();

  function onDeleteChat(id: string) {
    deleteChat(chatsData, id).catch(showError);

    if (chatId === id) {
      navigate('/');
    }
  }

  function renderChats(key: string, chats: IChat[], title: string) {
    if (!chats.length) return null;

    return (
      <div className="chat-group" key={key}>
        <div className="chat-date-title">{title}</div>
        <ul>
          {chats.map((chat) => (
            <ChatItem
              key={chat.id}
              chatId={chat.id}
              isRenaming={renameChatId === chat.id}
              isSelected={chatId === chat.id}
              onRenameStart={() => setRenameChatId(chat.id)}
              onRenameEnd={() => setRenameChatId(null)}
              onDelete={() => onDeleteChat(chat.id)}
            />
          ))}
        </ul>
      </div>
    );
  }

  return (
    <div className="chats">
      {chatsSeparatedByDate.map(({ chats, label }) =>
        renderChats(label, chats, t(`ai.navigation.dates.${label}`))
      )}
      {isLoading ? (
        <>
          <Shimmer />
          <Shimmer />
        </>
      ) : null}
    </div>
  );
}

function ChatItem({
  chatId,
  isRenaming,
  isSelected,
  onRenameStart,
  onRenameEnd,
  onDelete
}: {
  /**
   * Chat ID for this item.
   *
   * We only use the chat ID and reload the API object to work around some sync issues where a mutated CHATS object
   * doesn't receive updates if a CHAT object is changed.
   */
  chatId: string;
  isRenaming: boolean;
  isSelected: boolean;
  onRenameStart: () => void;
  onRenameEnd: () => void;
  onDelete: () => void;
}) {
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { showError } = useNotificationContext();
  const { close } = useContext(NavigationContext);

  const { data: chat, mutate } = useApiObject(CHAT, chatId, {
    revalidateOnMount: false,
    includes: []
  });

  function saveRenamed() {
    onRenameEnd();

    const inputElement = document.getElementById('rename-chat-input') as HTMLInputElement | null;
    const newName = inputElement?.value?.trim() ?? '';

    if (!newName || !chat || chat.name === newName) {
      return; // Do nothing if the name is empty or unchanged
    }

    mutate(
      fetchAIVoid(
        { url: `Chats/${chatId}`, method: 'PUT', body: { name: newName } },
        { addUserId: true }
      ).then(() => ({ name: newName })),
      { name: newName }
    ).promise.catch(showError);
  }

  return isRenaming ? (
    <TextField
      id="rename-chat-input"
      className="chat-title-textfield"
      defaultValue={chat?.name || t('ai.navigation.chats.defaultName')}
      autoFocus
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          saveRenamed();
        }
      }}
      borderless
      onFocus={(event) => event.target.select()}
      underlined
      onBlur={saveRenamed}
    />
  ) : (
    <li className={`chat-title ${isSelected ? 'selected' : ''}`}>
      <Link to={`/chat/${chatId}`} className="c-link" onClick={close}>
        {chat?.name || t('ai.navigation.chats.defaultName')}
      </Link>
      <IconButton
        styles={{
          root: { height: '20px' },
          menuIcon: { fontSize: '14px', fontWeight: '600' }
        }}
        className={`chat-menu ${isMenuOpen ? 'visible' : ''}`}
        menuIconProps={{ iconName: 'More' }}
        menuProps={{
          onMenuOpened: () => setIsMenuOpen(true),
          onMenuDismissed: () => setIsMenuOpen(false),
          items: [
            {
              key: 'rename',
              text: 'Rename',
              iconProps: { iconName: 'Edit' },
              onClick: onRenameStart
            },
            {
              key: 'delete',
              text: 'Delete',
              iconProps: { iconName: 'Delete' },
              onClick: onDelete
            }
          ]
        }}
      />
    </li>
  );
}

const RECENT_CHATS_CUTOFF_MS = 1000 * 86400 * 7;

function ChatsCalloutContent({ onClose }: { onClose: () => void }) {
  const { t } = useTranslation();

  const { data: chatsData } = useApiObject(
    CHATS,
    {},
    { includes: [ChatIncludes.Agent], revalidateOnMount: false }
  );
  const chats = chatsData?.items ?? [];

  const recentChats = chats
    .filter((chat) => !chat.agent)
    .filter((chat) => Date.now() - +new Date(chat.updatedAt) < RECENT_CHATS_CUTOFF_MS)
    .sort((a, b) => {
      return +new Date(b.updatedAt) - +new Date(a.updatedAt);
    });

  const chatId = useCurrentChatId();

  return (
    <ChatsCalloutContentStyled>
      <Link
        to="/chats"
        onClick={onClose}
        className={`callout-button show-all-button ${
          window.location.pathname.startsWith('/chats') ? 'selected' : ''
        }`}
      >
        <Icon iconName="OfficeChat" />
        {t('ai.navigation.chats.showAll')}
      </Link>

      <div className="group-header">{t('ai.navigation.chats.recent')}</div>
      <div className="chat-list">
        {recentChats.map((chat) => (
          <Link
            to={`/chat/${chat.id}`}
            key={chat.id}
            onClick={onClose}
            className={`callout-button ${chatId === chat.id ? 'selected' : ''}`}
          >
            {chat.name || t('ai.navigation.chats.defaultName')}
          </Link>
        ))}

        {recentChats.length ? null : (
          <div className="c-empty">{t('ai.navigation.chats.noRecents')}</div>
        )}
      </div>
    </ChatsCalloutContentStyled>
  );
}
