import { t } from 'i18next';
import styled from 'styled-components';
import { DropdownMenuItemType } from '@fluentui/react';

export default function getFieldTypes() {
  const fieldTypes = [
    { key: 'Standard', text: 'Standard', itemType: DropdownMenuItemType.Header },
    {
      key: 1,
      text: t('createField.dialog.field.types.smalltext')
    },
    {
      key: 2,
      text: t('createField.dialog.field.types.longtext')
    },
    {
      key: 3,
      text: t('createField.dialog.field.types.number')
    },
    {
      key: 5,
      text: t('createField.dialog.field.types.date')
    },
    {
      key: 6,
      text: t('createField.dialog.field.types.choice')
    },
    {
      key: 8,
      text: t('createField.dialog.field.types.rating')
    },
    {
      key: 4,
      text: t('createField.dialog.field.types.boolean')
    },
    { key: 'divider_1', text: '-', itemType: DropdownMenuItemType.Divider },
    {
      key: t('createField.dialog.field.header.data'),
      text: t('createField.dialog.field.header.data'),
      itemType: DropdownMenuItemType.Header
    },
    {
      key: 7,
      text: t('createField.dialog.field.types.person')
    },
    {
      key: 13,
      text: t('createField.dialog.field.types.location')
    },
    {
      key: 12,
      text: t('createField.dialog.field.types.lookup')
    },
    {
      key: 11,
      text: t('createField.dialog.field.types.data')
    },
    { key: 'divider_2', text: '-', itemType: DropdownMenuItemType.Divider },
    {
      key: t('createField.dialog.field.header.media'),
      text: t('createField.dialog.field.header.media'),
      itemType: DropdownMenuItemType.Header
    },
    {
      key: 10,
      text: t('createField.dialog.field.types.document')
    },
    {
      key: 9,
      text: t('createField.dialog.field.types.link')
    },
    {
      key: 15,
      text: t('createField.dialog.field.types.multimedia')
    },
    {
      key: 14,
      text: t('createField.dialog.field.types.signature')
    },
    {
      key: 16,
      text: t('createField.dialog.field.types.scanner')
    }
  ];
  const useAIField = window.localStorage.getItem('useAIField');

  if (useAIField === 'true') {
    // add ai field on index 6
    fieldTypes.splice(6, 0, {
      key: 17,
      text: t('createField.dialog.field.types.ai')
    });
  }

  return fieldTypes;
}

export function getDialogStyles(translation: boolean) {
  if (translation) {
    return {
      root: {},
      main: [
        {
          selectors: {
            '@media (min-width: 1150px)': {
              width: '100%',
              minWidth: `${translation ? '1120' : '550'}px`,
              minHeight: '250px'
            },
            '@media (max-width: 1120px)': {
              minHeight: '100%',
              minWidth: '100%'
            }
          }
        }
      ]
    };
  }

  return {
    root: {},
    main: [
      {
        selectors: {
          '@media (min-width: 650px)': {
            width: '100%',
            minWidth: '550px',
            minHeight: '250px'
          },
          '@media (max-width: 480px)': {
            minHeight: '100%',
            minWidth: '100%'
          }
        }
      }
    ]
  };
}

type RenderGrid = {
  $gridItem?: boolean;
};

const CreateFormContainer = styled.div<RenderGrid>`
  display: grid;
  grid-template-columns: ${(props) => (props.$gridItem ? 'repeat(2, 1fr)' : 'repeat(1, 1fr)')};
  grid-gap: 20px;
`;

export const TranslationDivContainer = styled(CreateFormContainer)`
  justify-items: end;
  padding-top: 10px;
`;

export const CreateFormDivContainer = styled(CreateFormContainer)`
  justify-items: stretch;
`;
