import { t } from 'i18next';
import { Slider } from '@fluentui/react';
import { IFormProps } from './FieldForm';

function RatingSettings({ field, setField, disabled, language }: IFormProps) {
  function updateRating(value: number) {
    setField((prevState) => ({ ...prevState, numStars: value }));
  }

  return (
    <Slider
      key={`rating-${field?.numStars}-${language}`}
      label={t('createField.dialog.ratingfield.label.numStars')}
      min={0}
      max={10}
      step={1}
      defaultValue={field.numStars || 4}
      showValue
      snapToStep
      onChanged={(_, value) => updateRating(value)}
      disabled={disabled}
      styles={{ root: { marginTop: '7px' }, slideBox: { paddingRight: 8, paddingLeft: 0 } }}
    />
  );
}

export default RatingSettings;
