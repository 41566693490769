import { ITaskFilters, TaskGroupingType, TasksViewType } from 'features/Tasks/model';
import { useEffect, useRef } from 'react';
import { useAPIClientStore } from '../features/App/context/APIClientStore';

export interface ITasksFilterViewV1 {
  filters: ITaskFilters;
  id: string;
  name: string;
  projectId?: string;
  scopeFilter?: string;
  teamId?: string;
  depiction: 'list' | 'grid' | 'gantt' | 'dashboard';
  groupBy?: 'dateType' | 'statuses' | 'assignedToIds' | 'sprintId' | 'columnId' | 'priorities';
  version?: number;
}

export interface ITasksFilterViewV2 {
  filters: ITaskFilters;
  id: string;
  name: string;
  projectId?: string;
  scopeFilter?: string;
  teamId?: string;
  depiction: TasksViewType;
  groupBy?: TaskGroupingType;
  version: number;
}

export type ITasksFilterView = ITasksFilterViewV1 | ITasksFilterViewV2;

function groupingV1ToV2(value: string) {
  switch (value) {
    case 'statuses':
      return TaskGroupingType.Statuses;
    case 'assignedToIds':
      return TaskGroupingType.AssignedToIds;
    case 'sprintId':
      return TaskGroupingType.SprintId;
    case 'priorities':
      return TaskGroupingType.Priorities;
    default:
      return TaskGroupingType.DateType;
  }
}

function viewTypeV1ToV2(value: string) {
  switch (value) {
    case 'dashboard':
      return TasksViewType.Dashboard;
    case 'gantt':
      return TasksViewType.Gantt;
    case 'grid':
      return TasksViewType.Grid;
    case 'list':
      return TasksViewType.List;
    default:
      return TasksViewType.Kanban;
  }
}

function adaptViewPropsByVersion(view: ITasksFilterView): ITasksFilterViewV2 {
  if (!view.version) {
    const viewV1 = view as ITasksFilterViewV1;

    return {
      ...viewV1,
      depiction: viewTypeV1ToV2(viewV1.depiction),
      groupBy: groupingV1ToV2(viewV1.groupBy),
      version: 2
    };
  }

  return view as ITasksFilterViewV2;
}

export default function useTasksFilterView({ teamId }: { teamId?: string }) {
  const {
    value: views,
    fetchData: getViews,
    status: viewsStatus,
    setValue: saveViews
  } = useAPIClientStore<ITasksFilterViewV2[]>({
    key: 'views',
    defaultValue: [],
    teamId
  });

  const getViewsRef = useRef(getViews);

  getViewsRef.current = getViews;

  useEffect(() => {
    getViewsRef.current?.();
  }, [teamId]);

  return {
    views: views.map(adaptViewPropsByVersion),
    viewsStatus,
    saveViews: (views: ITasksFilterView[]) => {
      saveViews(views.map(adaptViewPropsByVersion));
    }
  };
}
