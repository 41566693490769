import i18n from 'i18next';
import styled, { DefaultTheme } from 'styled-components';
import { IconButton } from '@fluentui/react';

interface SuggestionProps {
  readonly isSelected?: boolean;
}

const EditorWrapper = styled.div<{ $focused: boolean; $readOnly: boolean }>`
  padding: ${(props) => (props.$readOnly ? '0px' : '5px')};
  min-height: 20px;

  ol,
  ul {
    margin: 0px 0px 10px 25px;
    padding: 0px;
  }

  .DraftEditor-root {
    position: relative;
  }

  .public-DraftEditorPlaceholder-root {
    position: absolute;
    color: #b1b1b1;
    pointer-events: none;
  }

  .public-DraftEditor-content {
    height: ${(props) => {
      if (props.style && props.style.height) {
        return props.style.height;
      }

      return 'auto';
    }};
    min-height: ${(props) => {
      if (props.style && props.style.minHeight) {
        return props.style.minHeight;
      }

      return 'auto';
    }};
  }

  img {
    max-width: 50%;
    max-height: 50%;
  }
`;

function theme(key: keyof DefaultTheme['richTextEditor']) {
  return ({ theme }: { theme: DefaultTheme }) => theme.richTextEditor[key];
}

const LexicaWrapper = styled.div<{
  $focused: boolean;
}>`
  .other h2 {
    font-size: 18px;
    color: rgb(${theme('otherH2Foreground')});
    margin-bottom: 7px;
  }

  .other a {
    color: rgb(${theme('otherAnchorForeground')});
    text-decoration: underline;
    font-size: 14px;
  }

  .other ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  .App {
    font-family: sans-serif;
    text-align: center;
  }

  h1 {
    font-size: 24px;
    color: rgb(${theme('otherH1Foreground')});
  }

  .ltr {
    text-align: left;
  }

  .rtl {
    text-align: right;
  }

  .editor-container {
    border-radius: 2px;
    max-width: 600px;
    color: rgb(${theme('foreground')});
    position: relative;
    line-height: 20px;
    font-weight: 400;
    text-align: left;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .editor-inner {
    background: transparent;
    position: relative;
  }

  .editor-input {
    resize: none;
    font-size: 14px;
    padding-left: 5px;
    padding-right: 5px;
    position: relative;
    tab-size: 1;
    outline: 0;
    caret-color: rgb(${theme('caretColor')});
  }

  // do not style editor-input when it is within the rte-disabled class
  .editor-input {
    min-height: 32px;
    padding-top: 5px;
  }

  .rte-disabled {
    .editor-input {
      min-height: auto;
      line-height: normal;
    }
  }

  .editor-placeholder {
    color: rgb(${theme('placeholderForeground')});
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    top: 50%;
    left: 8px;
    font-size: 14px;
    user-select: none;
    display: inline-block;
    pointer-events: none;
    transform: translateY(-50%);
  }

  .editor-text-bold {
    font-weight: bold;
  }

  .editor-text-italic {
    font-style: italic;
  }

  .editor-text-underline {
    text-decoration: underline;
  }

  .editor-text-strikethrough {
    text-decoration: line-through;
  }

  .editor-text-underlineStrikethrough {
    text-decoration: underline line-through;
  }

  .editor-text-code {
    background-color: rgb(${theme('codeBackground')});
    padding: 1px 0.25rem;
    font-family: Menlo, Consolas, Monaco, monospace;
    font-size: 94%;
  }

  .editor-link {
    color: rgb(${theme('linkForeground')});
    cursor: ${({ $focused }) => ($focused ? 'text' : 'pointer')};
    position: relative;

    &:hover {
      ${({ $focused, theme }) =>
        $focused &&
        `
      ::before {
        content: '${i18n.t('rte.link.tooltip')}';
        position: absolute;
        padding: 5px;
        border-radius: 5px;
        font-size: 12px;
        top: 20px;
        width: max-content;
        z-index: 100;
        box-shadow: 0 1.6px 3.6px 0 rgba(0,0,0,.132),0 .3px .9px 0 rgba(0,0,0,.108);
        color: rgb(${theme.richTextEditor.linkTooltipForeground});
        background: rgb(${theme.richTextEditor.linkTooltipBackground});
      }
    `}
    }
  }

  .tree-view-output {
    display: block;
    background: #222;
    color: #fff;
    padding: 5px;
    font-size: 12px;
    white-space: pre-wrap;
    margin: 1px auto 10px auto;
    max-height: 250px;
    position: relative;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: auto;
    line-height: 14px;
  }

  .editor-code {
    background-color: rgb(${theme('codeBackground')});
    font-family: Menlo, Consolas, Monaco, monospace;
    display: block;
    padding: 8px 8px 8px 52px;
    line-height: 1.53;
    font-size: 13px;
    margin: 0;
    margin-top: 8px;
    margin-bottom: 8px;
    tab-size: 2;
    /* white-space: pre; */
    overflow-x: auto;
    position: relative;
  }

  .editor-code:before {
    content: attr(data-gutter);
    position: absolute;
    background-color: #eee;
    left: 0;
    top: 0;
    border-right: 1px solid #ccc;
    padding: 8px;
    color: #777;
    white-space: pre-wrap;
    text-align: right;
    min-width: 25px;
  }
  .editor-code:after {
    content: attr(data-highlight-language);
    top: 0;
    right: 3px;
    padding: 3px;
    font-size: 10px;
    text-transform: uppercase;
    position: absolute;
    color: rgba(0, 0, 0, 0.5);
  }

  .editor-tokenComment {
    color: slategray;
  }

  .editor-tokenPunctuation {
    color: #999;
  }

  .editor-tokenProperty {
    color: #905;
  }

  .editor-tokenSelector {
    color: #690;
  }

  .editor-tokenOperator {
    color: #9a6e3a;
  }

  .editor-tokenAttr {
    color: #07a;
  }

  .editor-tokenVariable {
    color: #e90;
  }

  .editor-tokenFunction {
    color: #dd4a68;
  }

  .editor-paragraph {
    margin: 0;
    /* margin-bottom: 8px; */
    position: relative;
  }

  .editor-paragraph:last-child {
    margin-bottom: 0;
  }

  .editor-heading-h1 {
    font-size: 24px;
    color: rgb(${theme('h1Foreground')});
    font-weight: 400;
    margin: 0;
    margin-bottom: 12px;
    padding: 0;
  }

  .editor-heading-h2 {
    font-size: 15px;
    color: rgb(${theme('h2Foreground')});
    font-weight: 700;
    margin: 0;
    margin-top: 10px;
    padding: 0;
    text-transform: uppercase;
  }

  .editor-quote {
    margin: 0;
    margin-left: 20px;
    font-size: 15px;
    color: rgb(101, 103, 107);
    border-left-color: rgb(206, 208, 212);
    border-left-width: 4px;
    border-left-style: solid;
    padding-left: 16px;
  }

  .editor-list-ol {
    padding: 0;
    margin: 0;
    margin-left: 16px;
  }

  .editor-list-ul {
    padding: 0;
    margin: 0;
    margin-left: 16px;
  }

  .editor-listitem {
    margin: 8px 32px 8px 32px;
  }

  .editor-nested-listitem {
    list-style-type: none;
  }

  pre::-webkit-scrollbar {
    background: transparent;
    width: 10px;
  }

  pre::-webkit-scrollbar-thumb {
    background: #999;
  }

  .debug-timetravel-panel {
    overflow: hidden;
    padding: 0 0 10px 0;
    margin: auto;
    display: flex;
  }

  .debug-timetravel-panel-slider {
    padding: 0;
    flex: 8;
  }

  .debug-timetravel-panel-button {
    padding: 0;
    border: 0;
    background: none;
    flex: 1;
    color: #fff;
    font-size: 12px;
  }

  .debug-timetravel-panel-button:hover {
    text-decoration: underline;
  }

  .debug-timetravel-button {
    border: 0;
    padding: 0;
    font-size: 12px;
    top: 10px;
    right: 15px;
    position: absolute;
    background: none;
    color: #fff;
  }

  .debug-timetravel-button:hover {
    text-decoration: underline;
  }

  .emoji {
    color: transparent;
    background-size: 16px 16px;
    background-position: center;
    background-repeat: no-repeat;
    vertical-align: middle;
    margin: 0 -1px;
  }

  .emoji-inner {
    padding: 0 0.15em;
  }

  .emoji-inner::selection {
    color: transparent;
    background-color: rgba(150, 150, 150, 0.4);
  }

  .emoji-inner::moz-selection {
    color: transparent;
    background-color: rgba(150, 150, 150, 0.4);
  }

  .emoji.happysmile {
    background-image: url(./images/emoji/1F642.png);
  }

  .toolbar {
    display: flex;
    margin-bottom: 1px;
    background: #fff;
    padding: 4px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    vertical-align: middle;
  }

  .toolbar button.toolbar-item {
    border: 0;
    display: flex;
    background: none;
    border-radius: 10px;
    padding: 8px;
    cursor: pointer;
    vertical-align: middle;
  }

  .toolbar button.toolbar-item:disabled {
    cursor: not-allowed;
  }

  .toolbar button.toolbar-item.spaced {
    margin-right: 2px;
  }

  .toolbar button.toolbar-item i.format {
    background-size: contain;
    display: inline-block;
    height: 18px;
    width: 18px;
    margin-top: 2px;
    vertical-align: -0.25em;
    display: flex;
    opacity: 0.6;
  }

  .toolbar button.toolbar-item:disabled i.format {
    opacity: 0.2;
  }

  .toolbar button.toolbar-item.active {
    background-color: rgba(223, 232, 250, 0.3);
  }

  .toolbar button.toolbar-item.active i {
    opacity: 1;
  }

  .toolbar .toolbar-item:hover:not([disabled]) {
    background-color: #eee;
  }

  .toolbar .divider {
    width: 1px;
    background-color: #eee;
    margin: 0 4px;
  }

  .toolbar select.toolbar-item {
    border: 0;
    display: flex;
    background: none;
    border-radius: 10px;
    padding: 8px;
    vertical-align: middle;
    width: 70px;
    font-size: 14px;
    color: #777;
    text-overflow: ellipsis;
  }

  .toolbar select.code-language {
    text-transform: capitalize;
    width: 130px;
  }

  .toolbar .toolbar-item .text {
    display: flex;
    line-height: 20px;
    width: 200px;
    vertical-align: middle;
    font-size: 14px;
    color: #777;
    text-overflow: ellipsis;
    width: 70px;
    overflow: hidden;
    height: 20px;
    text-align: left;
  }

  .toolbar .toolbar-item .icon {
    display: flex;
    width: 20px;
    height: 20px;
    user-select: none;
    margin-right: 8px;
    line-height: 16px;
    background-size: contain;
  }

  .toolbar i.chevron-down {
    margin-top: 3px;
    width: 16px;
    height: 16px;
    display: flex;
    user-select: none;
  }

  .toolbar i.chevron-down.inside {
    width: 16px;
    height: 16px;
    display: flex;
    margin-left: -25px;
    margin-top: 11px;
    margin-right: 10px;
    pointer-events: none;
  }

  i.chevron-down {
    background-color: transparent;
    background-size: contain;
    display: inline-block;
    height: 8px;
    width: 8px;
    background-image: url(images/icons/chevron-down.svg);
  }

  #block-controls button:hover {
    background-color: #efefef;
  }

  #block-controls button:focus-visible {
    border-color: blue;
  }

  #block-controls span.block-type {
    background-size: contain;
    display: block;
    width: 18px;
    height: 18px;
    margin: 2px;
  }

  #block-controls span.block-type.paragraph {
    background-image: url(images/icons/text-paragraph.svg);
  }

  #block-controls span.block-type.h1 {
    background-image: url(images/icons/type-h1.svg);
  }

  #block-controls span.block-type.h2 {
    background-image: url(images/icons/type-h2.svg);
  }

  #block-controls span.block-type.quote {
    background-image: url(images/icons/chat-square-quote.svg);
  }

  #block-controls span.block-type.ul {
    background-image: url(images/icons/list-ul.svg);
  }

  #block-controls span.block-type.ol {
    background-image: url(images/icons/list-ol.svg);
  }

  #block-controls span.block-type.code {
    background-image: url(images/icons/code.svg);
  }

  .dropdown {
    z-index: 5;
    display: block;
    position: absolute;
    box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
      inset 0 0 0 1px rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    min-width: 100px;
    min-height: 40px;
    background-color: #fff;
  }

  .dropdown .item {
    margin: 0 8px 0 8px;
    padding: 8px;
    color: #050505;
    cursor: pointer;
    line-height: 16px;
    font-size: 15px;
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-shrink: 0;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 8px;
    border: 0;
    min-width: 268px;
  }

  .dropdown .item .active {
    display: flex;
    width: 20px;
    height: 20px;
    background-size: contain;
  }

  .dropdown .item:first-child {
    margin-top: 8px;
  }

  .dropdown .item:last-child {
    margin-bottom: 8px;
  }

  .dropdown .item:hover {
    background-color: #eee;
  }

  .dropdown .item .text {
    display: flex;
    line-height: 20px;
    flex-grow: 1;
    width: 200px;
  }

  .dropdown .item .icon {
    display: flex;
    width: 20px;
    height: 20px;
    user-select: none;
    margin-right: 12px;
    line-height: 16px;
    background-size: contain;
  }

  .link-editor {
    position: absolute;
    z-index: 100;
    top: -10000px;
    left: -10000px;
    margin-top: -6px;
    max-width: 300px;
    width: 100%;
    opacity: 0;
    background-color: #fff;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    transition: opacity 0.5s;
  }

  .link-editor .link-input {
    display: block;
    width: calc(100% - 24px);
    box-sizing: border-box;
    margin: 8px 12px;
    padding: 8px 12px;
    border-radius: 15px;
    background-color: #eee;
    font-size: 15px;
    color: rgb(5, 5, 5);
    border: 0;
    outline: 0;
    position: relative;
    font-family: inherit;
  }

  .link-editor div.link-edit {
    background-image: url(images/icons/pencil-fill.svg);
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
    width: 35px;
    vertical-align: -0.25em;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
  }

  .link-editor .link-input a {
    color: rgb(33, 111, 219);
    text-decoration: none;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 30px;
    text-overflow: ellipsis;
  }

  .link-editor .link-input a:hover {
    text-decoration: underline;
  }

  .link-editor .button {
    width: 20px;
    height: 20px;
    display: inline-block;
    padding: 6px;
    border-radius: 8px;
    cursor: pointer;
    margin: 0 2px;
  }

  .link-editor .button.hovered {
    width: 20px;
    height: 20px;
    display: inline-block;
    background-color: #eee;
  }

  .link-editor .button i,
  .actions i {
    background-size: contain;
    display: inline-block;
    height: 20px;
    width: 20px;
    vertical-align: -0.25em;
  }

  .ImageNode__contentEditable {
    min-height: 20px;
    border: 0px;
    resize: none;
    cursor: text;
    caret-color: rgb(5, 5, 5);
    display: block;
    position: relative;
    outline: 0px;
    padding: 10px;
    user-select: text;
    font-size: 12px;
    width: calc(100% - 20px);
    white-space: pre-wrap;
    word-break: break-word;
  }

  .ImageNode__placeholder {
    font-size: 12px;
    color: #888;
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    top: 10px;
    left: 10px;
    user-select: none;
    white-space: nowrap;
    display: inline-block;
    pointer-events: none;
  }

  .image-control-wrapper--resizing {
    touch-action: none;
  }

  .editor-shell span.editor-image {
    cursor: default;
    display: inline-block;
    position: relative;
    user-select: none;
  }

  .editor-shell .editor-image img {
    max-width: 100%;
    cursor: default;
  }

  .editor-shell .editor-image img.focused {
    outline: 2px solid rgb(60, 132, 244);
    user-select: none;
  }

  .editor-shell .editor-image img.focused.draggable {
    cursor: grab;
  }

  .editor-shell .editor-image img.focused.draggable:active {
    cursor: grabbing;
  }

  .editor-shell .editor-image .image-caption-container .tree-view-output {
    margin: 0;
    border-radius: 0;
  }

  .editor-shell .editor-image .image-caption-container {
    display: block;
    position: absolute;
    bottom: 4px;
    left: 0;
    right: 0;
    padding: 0;
    margin: 0;
    border-top: 1px solid #fff;
    background-color: rgba(255, 255, 255, 0.9);
    min-width: 100px;
    color: #000;
    overflow: hidden;
  }

  .editor-shell .editor-image .image-caption-button {
    display: block;
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    width: 30%;
    padding: 10px;
    margin: 0 auto;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    min-width: 100px;
    color: #fff;
    cursor: pointer;
    user-select: none;
  }

  .editor-shell .editor-image .image-caption-button:hover {
    background-color: rgba(60, 132, 244, 0.5);
  }

  .editor-shell .editor-image .image-edit-button {
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    background-image: url(/src/images/icons/pencil-fill.svg);
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
    width: 35px;
    height: 35px;
    vertical-align: -0.25em;
    position: absolute;
    right: 4px;
    top: 4px;
    cursor: pointer;
    user-select: none;
  }

  .editor-shell .editor-image .image-edit-button:hover {
    background-color: rgba(60, 132, 244, 0.1);
  }

  .editor-shell .inline-editor-image img.focused.draggable {
    cursor: grab;
  }

  .editor-shell .inline-editor-image img.focused.draggable:active {
    cursor: grabbing;
  }

  .editor-shell .editor-image .image-resizer {
    display: block;
    width: 10px;
    height: 10px;
    position: absolute;
    background-color: rgb(60, 132, 244);
    border: 1px solid #fff;
    border-radius: 3px;
  }

  .editor-shell .editor-image .image-resizer.image-resizer-n {
    top: -6px;
    left: 48%;
    cursor: n-resize;
  }

  .editor-shell .editor-image .image-resizer.image-resizer-ne {
    top: -6px;
    right: -6px;
    cursor: ne-resize;
  }

  .editor-shell .editor-image .image-resizer.image-resizer-e {
    bottom: 48%;
    right: -6px;
    cursor: e-resize;
  }

  .editor-shell .editor-image .image-resizer.image-resizer-se {
    bottom: -2px;
    right: -6px;
    cursor: nwse-resize;
  }

  .editor-shell .editor-image .image-resizer.image-resizer-s {
    bottom: -2px;
    left: 48%;
    cursor: s-resize;
  }

  .editor-shell .editor-image .image-resizer.image-resizer-sw {
    bottom: -2px;
    left: -6px;
    cursor: sw-resize;
  }

  .editor-shell .editor-image .image-resizer.image-resizer-w {
    bottom: 48%;
    left: -6px;
    cursor: w-resize;
  }

  .editor-shell .editor-image .image-resizer.image-resizer-nw {
    top: -6px;
    left: -6px;
    cursor: nw-resize;
  }

  .image-wrapper {
    position: relative;
  }

  .image-loading-spinner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .image-loading-spinner,
  .image-lazy-loading-spinner {
    background-color: rgba(255, 255, 255, 0.5);
    border: 1px solid black;
  }

  .rte-toolbar-wrapper {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 100;
    /** required because it's sticky! */
    background: rgb(${theme('background')});
  }
`;

const RichTextEditorStyled = styled.div<{ $focused: boolean; $readOnly: boolean }>`
  /* border: ${(props) => (props.$readOnly ? 'none' : '1px solid black')}; */
  /* background-color: #eae8e7;
  position: relative;

  &:hover {
    &:after {
      border: 2px solid #d7d6d5;
      border-radius: 2px;
      bottom: -1px;
      content: '';
      left: -1px;
      pointer-events: none;
      position: absolute;
      right: -1px;
      top: -1px;
    }
  } */
`;

const MentionStyled = styled.span`
  color: ${(props) => props.theme.themePrimary};
`;

const TagStyled = styled.span<{ $readOnly: boolean }>`
  font-weight: 600;
  color: ${(props) => props.theme.themePrimary};
  cursor: ${(props) => (props.$readOnly ? 'pointer' : 'auto')};
`;

const RichStylingButton = styled(IconButton)``;

const RichTextEditorCmdBarStyled = styled.div`
  padding: 2px 2px 0 2px;
`;

const SuggestionsWrapper = styled.div<{ $left: number; $top: number }>`
  min-width: 250px;
  max-height: 300px;
  overflow-y: auto;
  border-radius: 8px;
  margin: 0;
  padding: 0;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 1px 10px rgba(0, 0, 0, 0.35);
  background: rgb(${({ theme }) => theme.richTextEditor.suggestionBackground});
  position: fixed;
  z-index: 10000000;
  left: ${(props) => `${props.$left}px`};
  top: ${(props) => `${props.$top}px`};

  .suggestion-title {
    padding-top: 0px;
    padding-right: 12px;
    padding-bottom: 0px;
    padding-left: 12px;
    font-size: 12px;
    color: rgb(${({ theme }) => theme.richTextEditor.suggestionTitleForeground});
    line-height: 40px;
    border-bottom: 1px solid rgb(${({ theme }) => theme.richTextEditor.suggestionDivider});
    font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system,
      BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
  }
`;

const Suggestion = styled.div<SuggestionProps>`
  margin: 0;

  background: rgb(
    ${({ isSelected, theme }) =>
      isSelected
        ? theme.richTextEditor.suggestionSelectedBackground
        : theme.richTextEditor.suggestionBackground}
  );
  color: rgb(${({ theme }) => theme.richTextEditor.suggestionForeground});
  cursor: pointer;

  &:hover {
    background: rgb(
      ${({ isSelected, theme }) =>
        isSelected
          ? theme.richTextEditor.suggestionSelectedHoverBackground
          : theme.richTextEditor.suggestionHoverBackground}
    );
  }
`;

const PersonSuggestion = styled(Suggestion)`
  padding: 7px 15px;
  width: 100%;
`;

const TagSuggestion = styled(Suggestion)`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 5px 15px;
  font-size: 14px;
  height: 34px;
  font-weight: 600;
  align-items: center;
  font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system,
    BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif;

  .create-tag-suggestion {
    color: ${(props) => props.theme.themePrimary};
    font-weight: 400;
  }
`;

export {
  EditorWrapper,
  MentionStyled,
  PersonSuggestion,
  LexicaWrapper,
  RichStylingButton,
  RichTextEditorCmdBarStyled,
  RichTextEditorStyled,
  SuggestionsWrapper,
  TagStyled,
  TagSuggestion
};
