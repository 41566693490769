import { DialogCustom } from 'components';
import PropTypes from 'prop-types';
import { useState } from 'react';
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton,
  TextField
} from '@fluentui/react';

export const getSortOrderValues = (type, items) => {
  if (type && items) {
    return items.map((item) => {
      return item.category.type === type ? item.sortOrder : null;
    });
  }

  return null;
};

export function isNewMainProcessGroup(mainGroupValue, items) {
  let mainGroupExists = false;
  if (items && mainGroupValue) {
    items.map((item) => {
      const existingProcGroupingArray = item.processGrouping.split('.');
      if (existingProcGroupingArray[0] === mainGroupValue) {
        mainGroupExists = true;
      }
      return false;
    });
  }
  return !mainGroupExists;
}

export function isBiggerOrEqualProcGrouping(existingProcGroupingValue, newProcGroupingValue) {
  if (existingProcGroupingValue && newProcGroupingValue) {
    const existingProcGroupingArray = existingProcGroupingValue.split('.');
    const newProcGroupingArray = newProcGroupingValue.split('.');

    if (
      newProcGroupingArray.length === 1 ||
      (existingProcGroupingArray.length !== newProcGroupingArray.length &&
        existingProcGroupingArray[0] === newProcGroupingArray[0])
    ) {
      return existingProcGroupingArray[0] >= newProcGroupingArray[0];
    }
    if (
      existingProcGroupingArray.length === newProcGroupingArray.length &&
      existingProcGroupingArray[0] === newProcGroupingArray[0]
    ) {
      let isEqual = true;
      for (let i = 0; i < existingProcGroupingArray.length; i += 1) {
        if (existingProcGroupingArray[i] !== newProcGroupingArray[i]) {
          isEqual = false;
          break;
        }
      }
      let isBigger = false;
      let isSameLevel = true;
      for (let i = 0; i < existingProcGroupingArray.length; i += 1) {
        if (isSameLevel && existingProcGroupingArray[i] > newProcGroupingArray[i]) {
          isBigger = true;
          break;
        }
        if (existingProcGroupingArray[i] !== newProcGroupingArray[i]) {
          isSameLevel = false;
        }
      }
      return isBigger || isEqual;
    }
  }
  return false;
}

export function isPositionOccupied(processType, items) {
  let exist = false;
  if (processType && items) {
    const filteredItems = items.filter(
      (x) => x.category.type === processType.category.type && x.id !== processType.id
    );
    if (processType.category.type === 2) {
      exist = !!filteredItems.find((x) => x.processGrouping === processType.processGrouping);
      if (!exist) {
        const procGroupingArray = processType.processGrouping.split('.');
        for (let i = 0; i < filteredItems.length; i += 1) {
          const itemsProcGroupingArray = filteredItems[i].processGrouping.split('.');
          if (
            itemsProcGroupingArray.length !== procGroupingArray.length &&
            itemsProcGroupingArray[0] === procGroupingArray[0]
          ) {
            exist = true;
          }
        }
      }
    } else {
      exist = !!filteredItems.find((x) => x.sortOrder === processType.sortOrder);
    }
  }
  return exist;
}

export const validateSortOrder = (value) => {
  if (value > 0) {
    return /^-?[1-9][0-9]*(([.]?||,?)[0-9]+)?$/.test(value);
  }
  return false;
};

export const validateProcessGrouping = (value) => {
  const result = /^-?[0-9]+(([.]?)[0-9]+)?(([.]?)[0-9]+)?$/.test(value);
  return result;
};

export const checkProcessGroupingInput = (value) => {
  let regex = null;
  regex = new RegExp(`^-?([1-9]?)([.]?)([1-9]?)([.]?)([1-9]?)$`);
  return regex.test(value);
};

export const checkSortOrderInput = (value) => {
  let regex = null;
  regex = new RegExp(`^-?[1-9][0-9]*(([.]?||,?)[0-9])?$`);
  return regex.test(value);
};

export const getProcGroupingErrorMessage = (value, t) => {
  return new Promise((resolve) => {
    setTimeout(
      () =>
        resolve(
          !value || validateProcessGrouping(value)
            ? ''
            : t('createProcessMapType.dialog.error.grouping.message')
        ),
      1000
    );
  });
};

export const getSortOrderErrorMessage = (value, t) => {
  return new Promise((resolve) => {
    setTimeout(
      () =>
        resolve(
          !value || validateProcessGrouping(value)
            ? ''
            : t('createProcessMapType.dialog.error.sorting.message')
        ),
      1000
    );
  });
};

export function getGroupElements(processType, items) {
  if (
    processType &&
    processType.category.type === 2 &&
    processType.processGrouping.split('.').length > 1
  ) {
    const prevElement = items.find((x) => x.id === processType.id);
    if (prevElement) {
      const newGroupOrder = processType.processGrouping.split('.')[0];
      const oldGroupOrder = prevElement.processGrouping.split('.')[0];
      const groupElements = items.filter(
        (x) =>
          x.category.type === processType.category.type &&
          x.processGrouping.split('.')[0] === oldGroupOrder &&
          x.processGrouping.split('.')[0] !== newGroupOrder &&
          x.id !== processType.id
      );
      const editedElements = [];
      groupElements.map((el) => {
        const newEl = {
          id: el.id,
          name: el.name,
          description: el.description,
          category: el.category,
          sortOrder: el.sortOrder,
          processGrouping: el.processGrouping
        };
        const procGrouping = el.processGrouping.split('.');
        procGrouping[0] = newGroupOrder;
        newEl.processGrouping = `${procGrouping[0]}.${procGrouping[1]}`;
        if (procGrouping.length === 3) {
          newEl.processGrouping += `.${procGrouping[2]}`;
        }
        editedElements.push(newEl);
        return editedElements;
      });
      editedElements.push(processType);
      return editedElements;
    }
  }
  return null;
}

export function NewProcessGroupingDialog({ onDismiss, onSave, type, t }) {
  const [processType, setProcessType] = useState(type);

  function saveProcessGrouping() {
    onSave(processType);
  }

  function handleKeyPress(ev, field) {
    if (field === 'processGrouping') {
      const { value, selectionStart } = ev.target;
      const valueToCheck = `${value.slice(0, selectionStart)}${String.fromCharCode(
        ev.which
      )}${value.slice(selectionStart)}`;
      if (!checkProcessGroupingInput(valueToCheck)) {
        ev.preventDefault();
      }
    }
    if (ev.key === 'Enter') {
      saveProcessGrouping();
    }
  }

  function handleProcessGrouping(value) {
    const tempType = { ...processType, processGrouping: value };
    setProcessType(tempType);
  }

  return (
    <Dialog
      hidden={false}
      onDismiss={onDismiss}
      styles={{
        main: [
          {
            selectors: {
              '@media (min-width: 650px)': {
                width: '100%',
                minWidth: '550px',
                minHeight: '200px'
              }
            }
          }
        ]
      }}
      dialogContentProps={{
        type: DialogType.normal,
        title: t('processMapAdministration.newProcessGroupingDialog.title')
      }}
      modalProps={{
        isBlocking: true
      }}
    >
      <TextField
        label={t('createProcessMapType.dialog.processGrouping.label')}
        autoComplete="off"
        onKeyPress={(ev) => handleKeyPress(ev, 'processGrouping')}
        required
        value={processType.processGrouping}
        onGetErrorMessage={(value) => getProcGroupingErrorMessage(value, t)}
        onChange={(_, value) => handleProcessGrouping(value)}
      />
      <DialogFooter>
        <PrimaryButton
          disabled={
            !processType.processGrouping && validateProcessGrouping(processType.processGrouping)
          }
          onClick={saveProcessGrouping}
          text={t('processMapAdministration.resolveOrderDialog.primaryButtonLabel')}
        />
        <DefaultButton
          onClick={onDismiss}
          text={t('processMapAdministration.resolveOrderDialog.defaultButtonLabel')}
        />
      </DialogFooter>
    </Dialog>
  );
}

export function ResolveOrderDialog({ title, subText, onAccept, onDismiss, t }) {
  return (
    <DialogCustom
      hidden={false}
      title={title}
      subText={subText}
      defaultButtonLabel={t('processMapAdministration.resolveOrderDialog.defaultButtonLabel')}
      primaryButtonLabel={t('processMapAdministration.resolveOrderDialog.primaryButtonLabel')}
      primaryButtonFunc={onAccept}
      defaultButtonFunc={onDismiss}
      onDismiss={onDismiss}
      dialogContentProps={{
        type: DialogType.normal,
        title,
        subText
      }}
      modalProps={{ isBlocking: true }}
    />
  );
}

export function ResolveChangeGroupDialog({ title, subText, onAccept, onDismiss, t }) {
  return (
    <DialogCustom
      hidden={false}
      title={title}
      subText={subText}
      defaultButtonLabel={t('processMapAdministration.resolveChangeGroupDialog.defaultButtonLabel')}
      primaryButtonLabel={t('processMapAdministration.resolveChangeGroupDialog.primaryButtonLabel')}
      primaryButtonFunc={onAccept}
      defaultButtonFunc={onDismiss}
      onDismiss={onDismiss}
      dialogContentProps={{
        type: DialogType.normal,
        title,
        subText
      }}
      modalProps={{ isBlocking: true }}
    />
  );
}

NewProcessGroupingDialog.propTypes = {
  onDismiss: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  type: PropTypes.oneOfType([PropTypes.object]).isRequired,
  t: PropTypes.func.isRequired
};

ResolveOrderDialog.propTypes = {
  title: PropTypes.string.isRequired,
  subText: PropTypes.string.isRequired,
  onAccept: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

ResolveChangeGroupDialog.propTypes = {
  title: PropTypes.string.isRequired,
  subText: PropTypes.string.isRequired,
  onAccept: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};
