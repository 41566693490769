import styled from 'styled-components';

const InstanceItem = styled.div`
  margin: 5px;

  .c-persona-wrapper {
    display: flex;
    margin-top: 2px;
    margin-bottom: 3px;
  }

  .c-persona-text-wrapper {
    margin-left: 6px;
  }
`;

const LookupWrapper = styled.div`
  .c-selected-instance_wrapper {
    border: 1px solid #a19f9d;
    border-radius: 3px;
    padding: 10px 10px 10px 10px;

    &:hover {
      border: 1px solid black;
    }
  }

  .c-selected-instance_label {
    display: flex;

    border-bottom: 1px solid #a19f9d;

    padding-left: 8px;
    align-items: center;
  }

  .c-selected-instance_name {
    color: rgb(50, 49, 48);
    font-size: 14px;
    font-weight: 400;
  }
`;

export { LookupWrapper, InstanceItem };
