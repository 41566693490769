import { HTMLAttributes, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { ICalloutProps, ITooltipProps, Tooltip } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';

export interface IInteractiveTooltipHostProps
  extends Omit<HTMLAttributes<HTMLDivElement>, 'content'> {
  /** Additional props for the callout (internal to the tooltip). */
  calloutProps?: ICalloutProps;

  /** Additional props for the tooltip. */
  tooltipProps?: ITooltipProps;

  /** Disables the tooltip. */
  disabled?: boolean;

  /** Forces the tooltip to be visible. */
  forceVisible?: boolean;

  /** Contents of the tooltip. */
  content: ReactNode;
}

type Vec2 = { x: number; y: number };
function vec2Sub(a: Vec2, b: Vec2) {
  return { x: a.x - b.x, y: a.y - b.y };
}
function mat2x2Determinant(x: Vec2, y: Vec2) {
  return x.x * y.y - y.x * x.y;
}
function isPointInTriangle(a: Vec2, b: Vec2, c: Vec2, p: Vec2) {
  const abp = mat2x2Determinant(vec2Sub(p, a), vec2Sub(b, a)) < 0;
  const bcp = mat2x2Determinant(vec2Sub(p, b), vec2Sub(c, b)) < 0;
  const cap = mat2x2Determinant(vec2Sub(p, c), vec2Sub(a, c)) < 0;
  const facing = mat2x2Determinant(vec2Sub(c, a), vec2Sub(b, a)) < 0;
  return facing === abp && facing === bcp && facing === cap;
}
/** Returns true if the `point` is in the convex hull formed by `points`. Not that `points` will be mutated a lot. */
function isPointInConvexHullMut(points: Vec2[], point: Vec2): boolean {
  // graham scan
  points.sort((a, b) => {
    if (a.y === b.y) return a.x - b.x;
    return a.y - b.y;
  });
  const lowestPoint = points.shift();

  // sort by angle
  points.sort((a, b) => {
    const aAngle = Math.atan2(a.y - lowestPoint.y, a.x - lowestPoint.x);
    const bAngle = Math.atan2(b.y - lowestPoint.y, b.x - lowestPoint.x);
    return aAngle - bAngle;
  });

  const hullPoints: Vec2[] = [];
  for (const point of points) {
    for (let i = 0; i < 256; i += 1) {
      const prev1 = hullPoints[hullPoints.length - 1];
      const prev2 = hullPoints[hullPoints.length - 2];
      if (!prev1 || !prev2) break;

      const isLeftTurn =
        (prev1.x - prev2.x) * (point.y - prev2.y) - (prev1.y - prev2.y) * (point.x - prev2.x) <= 0;

      if (isLeftTurn) hullPoints.pop();
      else break;
    }

    hullPoints.push(point);
  }

  // now we have a triangle fan. see if our point is in any triangle
  for (let i = 0; i < hullPoints.length; i += 1) {
    const a = hullPoints[i];
    const b = hullPoints[(i + 1) % hullPoints.length];

    if (isPointInTriangle(lowestPoint, a, b, point)) return true;
  }

  return false;
}

let activeTooltip: { id: string; dismiss: () => void } | null = null;

/**
 * Like a fluent-ui TooltipHost, but allows you to move your cursor onto the tooltip to interact with it.
 *
 * TooltipHost is *supposed* to already be capable of this, but in practice, it doesn't really work.
 */
export default function InteractiveTooltipHost({
  calloutProps,
  tooltipProps,
  content,
  disabled,
  forceVisible,
  ...props
}: IInteractiveTooltipHostProps) {
  const [isOverHost, setIsOverHost] = useState(false);
  const [isOverTooltip, setIsOverTooltip] = useState(false);

  const [isBridgingGap, setIsBridgingGap] = useState(false);

  const tooltipVisible =
    (isOverHost || isOverTooltip || isBridgingGap || forceVisible) && !disabled;

  const host = useRef<HTMLDivElement>(null);
  const tooltipId = useId('interactive-tooltip');

  useEffect(() => {
    if (!isBridgingGap) return () => null;

    // when the user moves off the host rectangle, we'll briefly bridge the gap to the tooltip.
    // this is subject to a timeout and a bounding polygon (the convex hull)

    const timeout = setTimeout(() => {
      setIsBridgingGap(false);
    }, 500);

    const onPointerMove = (e: PointerEvent) => {
      const hostRect = host.current?.getBoundingClientRect();
      const tooltipRect = document.getElementById(tooltipId)?.getBoundingClientRect();
      if (!hostRect || !tooltipRect) {
        setIsBridgingGap(false);
        return;
      }

      const points = [
        { x: hostRect.left, y: hostRect.top },
        { x: hostRect.right, y: hostRect.top },
        { x: hostRect.left, y: hostRect.bottom },
        { x: hostRect.right, y: hostRect.bottom },
        { x: tooltipRect.left, y: tooltipRect.top },
        { x: tooltipRect.right, y: tooltipRect.top },
        { x: tooltipRect.left, y: tooltipRect.bottom },
        { x: tooltipRect.right, y: tooltipRect.bottom }
      ];
      if (!isPointInConvexHullMut(points, { x: e.clientX, y: e.clientY })) {
        // user moved outside bounding polygon. stop bridging
        setIsBridgingGap(false);
      }
    };

    window.addEventListener('pointermove', onPointerMove);

    return () => {
      window.removeEventListener('pointermove', onPointerMove);
      clearTimeout(timeout);
    };
  }, [isBridgingGap, tooltipId]);

  useEffect(() => {
    if (tooltipVisible) {
      activeTooltip = {
        id: tooltipId,
        dismiss: () => {
          setIsOverHost(false);
          setIsOverTooltip(false);
          setIsBridgingGap(false);
        }
      };
    }
  }, [tooltipVisible, tooltipId]);

  const onPointerOver = useCallback(() => {
    setIsOverHost(true);
    setIsBridgingGap(false);

    if (activeTooltip && activeTooltip.id !== tooltipId) {
      // dismiss other tooltip
      activeTooltip.dismiss();
    }
  }, [tooltipId]);
  const onPointerOut = useCallback(() => {
    setIsOverHost(false);
    setIsBridgingGap(true);
  }, []);

  const onTooltipPointerOver = useCallback(() => {
    setIsOverTooltip(true);
    setIsBridgingGap(false);
  }, []);
  const onTooltipDismiss = useCallback(() => {
    setIsOverTooltip(false);
    setIsBridgingGap(false);
  }, []);

  return (
    <div ref={host} {...props} onPointerOver={onPointerOver} onPointerOut={onPointerOut}>
      {props.children}
      {tooltipVisible && (
        <Tooltip
          calloutProps={{
            id: tooltipId,
            onDismiss: onTooltipDismiss,
            ...calloutProps
          }}
          onPointerOver={onTooltipPointerOver}
          onPointerOut={onTooltipDismiss}
          {...tooltipProps}
          targetElement={host.current}
          // actually not useless, because fluent-ui doesn't support ReactNode
          // eslint-disable-next-line react/jsx-no-useless-fragment
          content={<>{content}</>}
        />
      )}
    </div>
  );
}
