import { BarChart, BurnChart, GroupedBarsChart, PieChart } from 'components/charts';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { projectPropType } from 'types';
import { checkScreenWidth } from 'utils/helpers';

const ProjectDashboardStyled = styled.div`
  height: 100%;
  width: 100%;

  .chartTitle {
    display: block;
    margin: 10px 10px 20px 10px;
    font-weight: 500;
    font-size: 14px;
  }

  .charts-wrapper {
    min-height: 100%;
    width: 100%;
  }

  .chart-row {
    display: flex;
    flex-wrap: wrap;
    min-height: 50%;
    width: 100%;
  }
`;

const ChartWrapper = styled.div`
  width: ${(props) => (props.$single ? '100%' : '50%')};
  min-height: 100%;
  padding: 10px;

  @media (max-width: 1350px) {
    width: 100%;
  }
`;

function ProjectDashboard({ project, dashboardProps, onSelectedChartGroup }) {
  const { t } = useTranslation();

  const [preparedNumbers, setPreparedNumbers] = useState(null);

  const { sprintId } = useParams();

  const hasValidSprintParam = sprintId && sprintId !== '00000000-0000-0000-0000-000000000000';

  const getNumbers = useCallback(
    (dashboardProps) => {
      const statusNumbers = [];
      const assignedToNumbers = [];
      const finishedNumbers = [];
      const finishedBySprintNumbers = [];

      dashboardProps.status.map((item, index) => {
        const newNumber = { ...item };
        newNumber.colorIndex = index + 1;

        if (!newNumber.key) {
          newNumber.key = item.title;
        }

        newNumber.title = t(`taskStatus.${item.title}`);

        statusNumbers.push(newNumber);

        return null;
      });

      dashboardProps.assignedTo.map((item, index) => {
        const newNumber = { ...item };
        newNumber.colorIndex = index + 1;

        assignedToNumbers.push(newNumber);

        return null;
      });

      dashboardProps.finished.map((item, index) => {
        const newNumber = { ...item };
        newNumber.colorIndex = index + 1;

        if (!newNumber.key) {
          newNumber.key = item.title;
        }

        newNumber.title = t(`taskStatus.${item.title}`);

        finishedNumbers.push(newNumber);

        return null;
      });

      dashboardProps.finishedBySprint.map((item) => {
        const newGroup = { ...item };

        if (
          item.key === '00000000-0000-0000-0000-000000000000' ||
          item.key === '11111111-1111-1111-1111-111111111111'
        ) {
          if (item.key === '00000000-0000-0000-0000-000000000000') {
            newGroup.key = '11111111-1111-1111-1111-111111111111';
          }

          if (!item.title) {
            newGroup.title = t('subheader.projects.commandbar.productBacklog');
          }
        }

        if (item.numbers?.length) {
          const newGroupNumbers = item.numbers.map((itemNumber, itemIndex) => {
            const newNumber = { ...itemNumber };
            newNumber.colorIndex = itemIndex + 1;

            if (!newNumber.key) {
              newNumber.key = itemNumber.title;
            }

            newNumber.title = t(`taskStatus.${itemNumber.title}`);

            return newNumber;
          });

          newGroup.numbers = newGroupNumbers;
        }

        finishedBySprintNumbers.push(newGroup);

        return null;
      });

      return {
        status: statusNumbers,
        assignedTo: assignedToNumbers,
        finished: finishedNumbers,
        finishedBySprint: finishedBySprintNumbers
      };
    },
    [t]
  );

  useEffect(() => {
    if (dashboardProps?.status && dashboardProps?.assignedTo) {
      const editedNumbers = getNumbers(dashboardProps);

      setPreparedNumbers(editedNumbers);
    }
  }, [getNumbers, dashboardProps]);

  const chartValues = preparedNumbers;

  if (
    !chartValues ||
    (!chartValues.status?.length &&
      !chartValues.assignedTo?.length &&
      !chartValues.finished?.length &&
      !chartValues.finishedBySprint?.length)
  ) {
    return null;
  }

  function getChart(taskGrouping) {
    if (taskGrouping === 'finished') {
      return (
        <BurnChart
          showText
          showTooltip
          chartValues={chartValues[taskGrouping]}
          onSelectedChartGroup={(value) =>
            onSelectedChartGroup({ key: value.key, type: taskGrouping })
          }
          yAxisTitle={t('taskDashboard.barChart.yAxisTitle')}
          chartHeight={280}
          chartWidth={!hasValidSprintParam || window.innerWidth < 1100 ? 530 : 950}
          noTasksLabel={t('projectDashboard.burnChart.noTasksLabel')}
        />
      );
    }

    if (taskGrouping === 'finishedBySprint') {
      return (
        <GroupedBarsChart
          showText
          showTooltip
          chartValues={chartValues[taskGrouping]}
          onSelectedChartGroup={(value) =>
            onSelectedChartGroup({
              key: value.key,
              type: taskGrouping,
              selectedSprintId: value.sprintId
            })
          }
          yAxisTitle={t('taskDashboard.barChart.yAxisTitle')}
          chartHeight={366}
          chartWidth={500}
          chartId={taskGrouping}
        />
      );
    }

    if (chartValues?.[taskGrouping]?.length < 11) {
      const chartSize = checkScreenWidth(['large']) ? 100 : 120;
      return (
        <PieChart
          showText
          showLegend
          showTooltip
          chartValues={chartValues[taskGrouping]}
          onSelectedChartGroup={(value) =>
            onSelectedChartGroup({ key: value.key, type: taskGrouping })
          }
          chartSize={chartSize}
          legendItemIndicatorSize={12}
          chartId={taskGrouping}
        />
      );
    }

    return (
      <BarChart
        showText
        showTooltip
        chartValues={chartValues[taskGrouping]}
        onSelectedChartGroup={(value) =>
          onSelectedChartGroup({ key: value.key, type: taskGrouping })
        }
        yAxisTitle={t('taskDashboard.barChart.yAxisTitle')}
        chartHeight={300}
        chartWidth={500}
        chartId={taskGrouping}
      />
    );
  }

  return (
    <ProjectDashboardStyled>
      <div className="charts-wrapper">
        <div className="chart-row">
          {/* By status */}
          {chartValues.status?.length ? (
            <ChartWrapper>
              <div className="chartTitle">{t('projects.dashboard.sprint.status')}</div>
              {getChart('status')}
            </ChartWrapper>
          ) : null}
          {/* By assigned to */}
          {chartValues.assignedTo?.length ? (
            <ChartWrapper>
              <div className="chartTitle">{t('projects.dashboard.sprint.assignedTo')}</div>
              {getChart('assignedTo')}
            </ChartWrapper>
          ) : null}
        </div>
        {project?.type === 2 ? (
          <div className="chart-row">
            {/* By finished/unfinished for current sprint */}
            {chartValues.finished?.length ? (
              <ChartWrapper $single={hasValidSprintParam}>
                <div className="chartTitle">
                  {hasValidSprintParam
                    ? t('projects.dashboard.sprint.finishedForSprint')
                    : t('projects.dashboard.sprint.finishedForProject')}
                </div>
                {getChart('finished')}
              </ChartWrapper>
            ) : null}
            {/* By finished/unfinished for each sprint only if sprint is not selected */}
            {!hasValidSprintParam && chartValues.finishedBySprint?.length ? (
              <ChartWrapper>
                <div className="chartTitle">{t('projects.dashboard.sprint.finishedBySprint')}</div>
                {getChart('finishedBySprint')}
              </ChartWrapper>
            ) : null}
          </div>
        ) : null}
      </div>
    </ProjectDashboardStyled>
  );
}

ProjectDashboard.propTypes = {
  project: projectPropType.isRequired,
  dashboardProps: PropTypes.shape({
    status: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        title: PropTypes.string,
        number: PropTypes.number,
        colorIndex: PropTypes.number
      })
    ),
    assignedTo: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        title: PropTypes.string,
        number: PropTypes.number,
        colorIndex: PropTypes.number
      })
    ),
    finished: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        title: PropTypes.string,
        number: PropTypes.number,
        colorIndex: PropTypes.number
      })
    ),
    finishedBySprint: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        title: PropTypes.string,
        numbers: PropTypes.arrayOf(
          PropTypes.shape({
            key: PropTypes.string,
            number: PropTypes.number,
            title: PropTypes.string,
            colorIndex: PropTypes.number
          })
        )
      })
    )
  }).isRequired,
  onSelectedChartGroup: PropTypes.func
};

ProjectDashboard.defaultProps = {
  onSelectedChartGroup: null
};

export default ProjectDashboard;
