import { EmptyListPlaceHolder, LoadingSpinner } from 'components';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';
import { instancePropType } from 'types';
import breakpoints from 'utils/breakpoints';
import { checkScreenWidth } from 'utils/helpers';
import {
  CheckboxVisibility,
  ColumnActionsMode,
  ConstrainMode,
  DetailsListLayoutMode,
  SelectionMode,
  ShimmeredDetailsList
} from '@fluentui/react';
import {
  ContextualFilterMenu,
  RouteInstanceListImageCell,
  RouteInstancesListRow
} from './Components';

// styles
const ContainerStyled = styled.div`
  display: initial;

  overflow: auto;
  .ms-DetailsList {
    background-color: transparent;
  }

  .hide-item {
    visibility: hidden;
  }

  .ms-DetailsHeader-filterChevron {
    visibility: hidden;
  }

  .details-list-wrapper {
    margin-right: 1rem;
    margin-left: 1rem;

    @media (min-width: ${breakpoints.extraSmallMax}px) {
      .ms-DetailsList-headerWrapper,
      div[data-item-key*='default-column-name'],
      .c-details-list__name {
        position: sticky;
        top: 0;

        z-index: 100;
      }
    }

    .ms-DetailsList-headerWrapper {
      z-index: 110;
      top: 0;
    }
  }

  .ms-DetailsHeader-cell:hover {
    .ms-DetailsHeader-filterChevron {
      visibility: visible;
    }
  }

  .c-details-list__more-button {
    visibility: ${checkScreenWidth(['extraSmall']) ? 'visible' : 'hidden'};
  }

  .ms-List-cell:hover {
    .c-details-list__more-button {
      visibility: visible;
    }
  }

  .ms-List-cell .is-selected {
    .c-details-list__more-button {
      visibility: visible;
    }
  }

  @media (max-width: ${breakpoints.extraSmallMax}px) {
    padding-bottom: 50px;
  }
`;
function InstanceListContainer({
  onOpenInstancePanel,
  onOpenInstanceView,
  handleContainerEnd,
  totalItems,
  searchTerm,
  onAbortInstance,
  // Type of possible results. 0 (default) = all, 1 = my, 2 = mentioned, 3 = completed
  filterType,
  onDeleteInstance,
  instances,
  isLoading,
  getContextualFilterMenuProps,
  fieldColumns,
  onCreateNewInstance,
  filterSettingProps,
  sortingSettingProps
}) {
  const { t } = useTranslation();
  const [contextualFilterMenuTarget, setContextualFilterMenuTarget] = useState(null);

  const [selectedColumnId, setSelectedColumnId] = useState(null);

  const { filterSettings, setFilterSettings } = filterSettingProps;
  const { sortingSettings, setSortingSettings } = sortingSettingProps;

  const defaultId = '00000000-0000-0000-0000-000000000000';

  function getInitialDefaultColumn(id) {
    let isSorted = false;
    let isSortedDescending = null;
    let isFiltered = false;

    if (sortingSettings && sortingSettings.sortField === id) {
      isSorted = true;
      isSortedDescending = sortingSettings.sortAscending;
    }

    let filterValues = [];

    const fieldFilterSettings = filterSettings.find((filter) => filter.filterField === id);
    if (fieldFilterSettings) {
      isFiltered = true;
      ({ filterValues } = fieldFilterSettings);
    }

    return {
      id,
      columnActionsMode: ColumnActionsMode.hasDropdown,
      isFiltered,
      isResizable: true,
      isSorted,
      isSortedDescending,
      filterValues: filterValues || []
    };
  }

  function isDefaultColumn(columnName, column) {
    if (!fieldColumns) {
      return false;
    }

    return column.id === defaultId && columnName === column.name;
  }

  const columns = [];

  fieldColumns.forEach((column) => {
    if (column.id !== defaultId) {
      columns.push({
        ...column,
        minWidth: 100,
        ...getInitialDefaultColumn(column.id)
      });
    } else if (isDefaultColumn('tags', column)) {
      columns.push({
        ...getInitialDefaultColumn('default-column-tags'),
        columnActionsMode: 0,
        maxWidth: 100,
        minWidth: 100,
        name: 'tags'
      });
    } else if (isDefaultColumn('version', column)) {
      columns.push({
        ...getInitialDefaultColumn('default-column-version'),
        columnActionsMode: 0,
        maxWidth: 100,
        minWidth: 100,
        name: 'version'
      });
    } else if (isDefaultColumn('currentEndDate', column)) {
      columns.push({
        ...getInitialDefaultColumn('default-column-currentEndDate'),
        maxWidth: 140,
        minWidth: 140,
        name: 'currentEndDate'
      });
    } else if (isDefaultColumn('plannedEndDate', column)) {
      columns.push({
        ...getInitialDefaultColumn('default-column-plannedEndDate'),
        maxWidth: 140,
        minWidth: 140,
        name: 'plannedEndDate'
      });
    } else if (isDefaultColumn('creationDate', column)) {
      columns.push({
        ...getInitialDefaultColumn('default-column-created'),
        maxWidth: 100,
        minWidth: 100,
        name: 'created'
      });
    } else if (isDefaultColumn('creator', column)) {
      columns.push({
        ...getInitialDefaultColumn('default-column-createdBy'),
        maxWidth: 150,
        minWidth: 120,
        name: 'createdBy'
      });
    } else if (isDefaultColumn('currentStepName', column)) {
      columns.push({
        ...getInitialDefaultColumn('default-column-currentTask'),
        maxWidth: 150,
        minWidth: 150,
        name: 'currentTask'
      });
    } else if (isDefaultColumn('percentComplete', column)) {
      columns.push({
        ...getInitialDefaultColumn('default-column-progress'),
        maxWidth: 100,
        minWidth: 100,
        name: 'progress'
      });
    } else if (isDefaultColumn('name', column)) {
      columns.push({
        ...getInitialDefaultColumn('default-column-name'),
        id: 'default-column-name',
        className: 'c-details-list__name',
        maxWidth: 300,
        minWidth: 150,
        name: 'name'
      });
    } else if (isDefaultColumn('intId', column)) {
      columns.push({
        ...getInitialDefaultColumn('default-column-id'),
        maxWidth: 60,
        minWidth: 40,
        name: 'id'
      });
    }
  });

  function onColumnHeaderClick(ev, column) {
    if (selectedColumnId && contextualFilterMenuTarget) {
      onFilterDismiss();
    } else {
      setSelectedColumnId(column.key);
      setContextualFilterMenuTarget(ev.currentTarget);
    }
  }

  function onFilterDismiss() {
    setSelectedColumnId(null);
    setContextualFilterMenuTarget(null);
  }

  function getFilterTypeByColumnId(id) {
    if (id === 'default-column-createdBy') {
      return 2;
    }

    if (id === 'default-column-currentTask') {
      return 4;
    }

    if (id === 'default-column-progress') {
      return 5;
    }

    return 100;
  }

  function onFilterChange(column) {
    const filterType = getFilterTypeByColumnId(column.id);
    const { filterValues } = column;

    const newFilterSettings = [...filterSettings].filter(
      (filter) => filter.filterType !== filterType || filter.filterField !== column.id
    );

    if (filterValues.length) {
      newFilterSettings.push({ filterType, filterField: column.id, filterValues });
    }

    setFilterSettings(newFilterSettings);
  }

  function getSortingTypeByColumnId(id) {
    if (id === 'default-column-name') {
      return 3;
    }

    if (id === 'default-column-createdBy') {
      return 2;
    }

    if (id === 'default-column-currentTask') {
      return 4;
    }

    if (id === 'default-column-progress') {
      return 5;
    }

    if (id === 'default-column-plannedEndDate') {
      return 6;
    }

    if (id === 'default-column-currentEndDate') {
      return 7;
    }

    if (id === 'default-column-created' || id === 'default-column-id') {
      return 1;
    }

    return 100;
  }

  function onSortingChange(column, descending) {
    let { isSorted, isSortedDescending } = column;

    const sortType = getSortingTypeByColumnId(column.id);

    if (!isSorted) {
      isSorted = true;
      isSortedDescending = descending;
    } else if ((descending && isSortedDescending) || (!descending && !isSortedDescending)) {
      isSorted = false;
      isSortedDescending = false;
    } else if (!descending && isSortedDescending) {
      isSorted = true;
      isSortedDescending = false;
    } else if (descending && !isSortedDescending) {
      isSorted = true;
      isSortedDescending = true;
    }

    let newSortingSettings = null;

    if (isSorted) {
      newSortingSettings = { sortType, sortAscending: isSortedDescending, sortField: column.id };
    }

    setSortingSettings(newSortingSettings);
  }

  function getColumnName(name, key) {
    if (key.includes('default-column-')) {
      if (name === 'id') {
        return 'Id';
      }

      if (name === 'name') {
        return t('routeInstances.column.name');
      }

      if (name === 'progress') {
        return t('routeInstances.column.progress');
      }

      if (name === 'currentTask') {
        return t('routeInstances.column.currentTask');
      }

      if (name === 'createdBy') {
        return t('routeInstances.column.createdBy');
      }

      if (name === 'created') {
        return t('routeInstances.column.created');
      }

      if (name === 'plannedEndDate') {
        return t('routeInstances.column.plannedEndDate');
      }

      if (name === 'currentEndDate') {
        return t('routeInstances.column.currentEndDate');
      }
      if (name === 'version') {
        return t('routeInstances.column.version');
      }
      if (name === 'tags') {
        return t('routeInstances.column.tags');
      }
    }
    return name;
  }

  function openInstance(instance) {
    onOpenInstanceView(instance);
  }

  function openInstancePanel(instance) {
    onOpenInstancePanel(instance);
  }

  const detailsListColumns = [];

  const listHasImages = instances?.some((instance) => instance.imageId !== defaultId);

  if (listHasImages) {
    detailsListColumns.push({
      fieldName: 'image',
      headerClassName: 'hide-item',
      key: 'image',
      maxWidth: 60,
      minWidth: 60,
      onRender: (instance) => <RouteInstanceListImageCell instance={instance} />
    });
  }

  columns.map((column) => {
    const {
      columnActionsMode,
      id,
      isFiltered,
      isSorted,
      className,
      isSortedDescending,
      maxWidth,
      minWidth,
      name
    } = column;

    detailsListColumns.push({
      canCheck: false,
      columnActionsMode,
      fieldName: name,
      className,
      isFiltered,
      isResizable: true,
      isSorted,
      isSortedDescending,
      key: id,
      maxWidth,
      minWidth,
      name: getColumnName(name, id),
      onRender: (instance) => (
        <RouteInstancesListRow
          column={column}
          onAbortInstance={onAbortInstance}
          onDeleteInstance={onDeleteInstance}
          instance={instance}
          onOpenInstancePanel={openInstancePanel}
          onOpenInstanceView={openInstance}
        />
      )
    });

    return null;
  });

  const selectedColumn = columns.find((column) => column.id === selectedColumnId);

  return (
    <>
      <ContainerStyled id="instance-list">
        <div className="details-list-wrapper">
          <ShimmeredDetailsList
            checkboxVisibility={CheckboxVisibility.hidden}
            columns={detailsListColumns}
            items={instances}
            onColumnHeaderClick={onColumnHeaderClick}
            selectionMode={SelectionMode.none}
            layoutMode={DetailsListLayoutMode.fixedColumns}
            constrainMode={ConstrainMode.unconstrained}
          />
        </div>
        {isLoading && (
          <LoadingSpinner
            center={false}
            styles={{ container: { marginTop: '1rem' } }}
            label={t('loading.routes.text')}
          />
        )}
        {!instances.length && !isLoading && (
          <EmptyListPlaceHolder
            hidden={!!instances?.length}
            onCreateNew={
              !searchTerm && !filterSettingProps?.filterSettings ? onCreateNewInstance : null
            }
            createNewText={t('routeInstances.createNewText')}
            noItemsText={
              searchTerm || filterSettingProps?.filterSettings
                ? t('routeInstances.noSearchedItemsText')
                : t('routeInstances.noListItemsText')
            }
            listIconName={
              searchTerm || filterSettingProps?.filterSettings ? 'SearchIssue' : 'Processing'
            }
          />
        )}
        <InfiniteScroll
          dataLength={instances.length}
          next={() => handleContainerEnd()}
          hasMore={instances.length < totalItems}
          scrollableTarget="scrollableDiv"
          loader={
            <ShimmeredDetailsList
              enableShimmer
              checkboxVisibility={CheckboxVisibility.hidden}
              columns={detailsListColumns}
              items={[]}
              isHeaderVisible={false}
            />
          }
        />
      </ContainerStyled>
      {selectedColumnId && contextualFilterMenuTarget ? (
        <ContextualFilterMenu
          column={selectedColumn}
          onDismiss={onFilterDismiss}
          filterType={filterType}
          onFilterChange={onFilterChange}
          onSortingChange={onSortingChange}
          target={contextualFilterMenuTarget}
          getContextualFilterMenuProps={getContextualFilterMenuProps}
        />
      ) : null}
    </>
  );
}

InstanceListContainer.propTypes = {
  filter: PropTypes.shape({ type: PropTypes.number, value: PropTypes.string }),
  onOpenInstancePanel: PropTypes.func,
  onOpenInstanceView: PropTypes.func,
  handleContainerEnd: PropTypes.func,
  filterType: PropTypes.string,
  totalItems: PropTypes.number,
  searchTerm: PropTypes.string,
  onDeleteInstance: PropTypes.func.isRequired,
  onAbortInstance: PropTypes.func.isRequired,
  onCreateNewInstance: PropTypes.func.isRequired,
  instances: PropTypes.arrayOf(instancePropType).isRequired,
  isLoading: PropTypes.bool,
  getContextualFilterMenuProps: PropTypes.func.isRequired,
  sortingSettingProps: PropTypes.shape({
    sortingSettings: PropTypes.shape({
      sortField: PropTypes.string,
      sortAscending: PropTypes.bool
    }),
    setSortingSettings: PropTypes.func
  }),
  filterSettingProps: PropTypes.shape({
    filterSettings: PropTypes.arrayOf(
      PropTypes.shape({
        filterField: PropTypes.string
      })
    ),
    setFilterSettings: PropTypes.func
  }),
  fieldColumns: PropTypes.arrayOf(
    PropTypes.shape({
      fieldType: PropTypes.number,
      id: PropTypes.string,
      name: PropTypes.string
    })
  ).isRequired
};

InstanceListContainer.defaultProps = {
  filter: null,
  onOpenInstancePanel: null,
  onOpenInstanceView: null,
  searchTerm: null
};

export default InstanceListContainer;
