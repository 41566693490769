import {
  createApiObjectDataFromPlainObject,
  IApiObjectCache,
  IApiObjectData,
  IApiObjectType,
  makeApiObjectVariantKey
} from '../object';
import { IApiObjectArray, ITextItem, Uuid } from './base-types';
import { createFieldRefs, simpleIdObject } from './utils';
import { fetchJson } from '../../../services/api2';

export interface IRoleItem extends IApiObjectData {
  id: Uuid;
  name: string | null;
  allNames: ITextItem[] | null;
}

export const ROLE = simpleIdObject<IRoleItem>({
  id: 'Role',
  url: (id) => `Role/${encodeURIComponent(id)}`
});

export type IRoleRef = Pick<IRoleItem, 'id'>;
export const SPARSE_ROLE_REF = (role: IRoleItem) => ({ id: role.id });

export interface IRolesParams {
  searchTerms?: string;
}

export const ROLES: IApiObjectType<IRolesParams, IApiObjectArray<IRoleItem>> = {
  id: 'Roles',
  createRefs(cache: IApiObjectCache, data: IApiObjectArray<IRoleItem>) {
    return createFieldRefs(
      cache,
      data,
      data.items.map((item, index) => ({
        path: `items/${index}`,
        type: ROLE,
        params: () => item.id
      }))
    );
  },
  async load(
    cache: IApiObjectCache,
    params: IRolesParams,
    abort: AbortSignal
  ): Promise<IApiObjectArray<IRoleItem>> {
    const search = new URLSearchParams(Object.entries(params));

    const url = `Roles${search ? `?${search}` : ''}`;
    const result = await fetchJson({ url, abort });
    return createApiObjectDataFromPlainObject(cache, makeApiObjectVariantKey(ROLES, params), {
      items: result
    }) as IApiObjectArray<IRoleItem>;
  }
};
