/* eslint-disable no-param-reassign */
import {
  $createParagraphNode,
  $getSelection,
  $isElementNode,
  $isRangeSelection,
  $isRootOrShadowRoot,
  $isTextNode,
  ElementNode,
  FORMAT_TEXT_COMMAND,
  INDENT_CONTENT_COMMAND,
  LexicalEditor,
  OUTDENT_CONTENT_COMMAND,
  REDO_COMMAND,
  RangeSelection,
  TextFormatType,
  TextNode,
  UNDO_COMMAND
} from 'lexical';
import { $isLinkNode } from '@lexical/link';
import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND
} from '@lexical/list';
import { $isDecoratorBlockNode } from '@lexical/react/LexicalDecoratorBlockNode';
import {
  $createHeadingNode,
  $isHeadingNode,
  $isQuoteNode,
  HeadingTagType
} from '@lexical/rich-text';
import {
  $getSelectionStyleValueForProperty,
  $isAtNodeEnd,
  $isParentElementRTL,
  $patchStyleText,
  $setBlocksType
} from '@lexical/selection';
import { $isTableNode } from '@lexical/table';
import {
  $findMatchingParent,
  $getNearestBlockElementAncestorOrThrow,
  $getNearestNodeOfType
} from '@lexical/utils';

const blockTypeToBlockName = {
  bullet: 'Bulleted List',
  check: 'Check List',
  code: 'Code Block',
  h1: 'Heading 1',
  h2: 'Heading 2',
  h3: 'Heading 3',
  h4: 'Heading 4',
  h5: 'Heading 5',
  h6: 'Heading 6',
  number: 'Numbered List',
  paragraph: 'Normal',
  quote: 'Quote'
};

export function formatText(editor: LexicalEditor, type: TextFormatType) {
  editor.dispatchCommand(FORMAT_TEXT_COMMAND, type);
}

export function indentSelection(editor: LexicalEditor) {
  editor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined);
}

export function outdentSelection(editor: LexicalEditor) {
  editor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined);
}

export function formatBulletList(editor: LexicalEditor, blockType?: string) {
  if (blockType !== 'bullet') {
    editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
  } else {
    editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
  }
}

export function formatNumberedList(editor: LexicalEditor, blockType?: string) {
  if (blockType !== 'number') {
    editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
  } else {
    editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
  }
}
export function undo(editor: LexicalEditor) {
  editor.dispatchCommand(UNDO_COMMAND, undefined);
}

export function redo(editor: LexicalEditor) {
  editor.dispatchCommand(REDO_COMMAND, undefined);
}

export function formatHeading(
  editor: LexicalEditor,
  headingSize: HeadingTagType,
  blockType: string
) {
  if (blockType !== headingSize) {
    editor.update(() => {
      const selection = $getSelection();

      if ($isRangeSelection(selection)) {
        $setBlocksType(selection, () => $createHeadingNode(headingSize));
      }
    });
  }
}

export function formatParagraph(editor: LexicalEditor) {
  editor.update(() => {
    const selection = $getSelection();

    if ($isRangeSelection(selection)) {
      $setBlocksType(selection, () => $createParagraphNode());
    }
  });
}

export function applyStyleText(editor: LexicalEditor, styles: Record<string, string | null>) {
  editor.update(() => {
    const selection = $getSelection();

    if ($isRangeSelection(selection)) {
      $patchStyleText(selection, styles);
    }
  });
}

export function getSelectedNode(selection: RangeSelection): TextNode | ElementNode {
  const { anchor } = selection;
  const { focus } = selection;

  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();

  if (anchorNode === focusNode) {
    return anchorNode;
  }

  const isBackward = selection.isBackward();

  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  }

  return $isAtNodeEnd(anchor) ? anchorNode : focusNode;
}

export function syncSelectionState(editor: LexicalEditor) {
  const state = {
    isBold: false,
    isItalic: false,
    isUnderline: false,
    isStrikethrough: false,
    isSubscript: false,
    isSuperscript: false,
    isCode: false,
    isLink: false,
    isRTL: false,
    blockType: 'paragraph',
    codeLanguage: '',
    fontSize: '15px',
    fontColor: '#000',
    bgColor: '#fff',
    fontFamily: 'Arial',
    elementFormat: 'left',
    rootType: 'root',
    selectedElementKey: ''
  };

  if (!editor) {
    return state;
  }

  const selection = $getSelection();

  if ($isRangeSelection(selection)) {
    const anchorNode = selection.anchor.getNode();
    let element =
      anchorNode.getKey() === 'root'
        ? anchorNode
        : $findMatchingParent(anchorNode, (e) => {
            const parent = e.getParent();
            return parent !== null && $isRootOrShadowRoot(parent);
          });

    if (element === null) {
      element = anchorNode.getTopLevelElementOrThrow();
    }

    const elementKey = element.getKey();
    const elementDOM = editor.getElementByKey(elementKey);

    state.isBold = selection.hasFormat('bold');
    state.isItalic = selection.hasFormat('italic');
    state.isUnderline = selection.hasFormat('underline');
    state.isStrikethrough = selection.hasFormat('strikethrough');
    state.isSubscript = selection.hasFormat('subscript');
    state.isSuperscript = selection.hasFormat('superscript');
    state.isCode = selection.hasFormat('code');
    state.isRTL = $isParentElementRTL(selection);

    // Update links
    const node = getSelectedNode(selection);
    const parent = node.getParent();

    if ($isLinkNode(parent) || $isLinkNode(node)) {
      state.isLink = true;
    } else {
      state.isLink = false;
    }

    const tableNode = $findMatchingParent(node, $isTableNode);
    if ($isTableNode(tableNode)) {
      state.rootType = 'table';
    } else {
      state.rootType = 'root';
    }

    if (elementDOM !== null) {
      state.selectedElementKey = elementKey;

      if ($isListNode(element)) {
        const parentList = $getNearestNodeOfType<ListNode>(anchorNode, ListNode);
        const type = parentList ? parentList.getListType() : element.getListType();

        state.blockType = type;
      } else {
        const type = $isHeadingNode(element) ? element.getTag() : element.getType();

        if (type in blockTypeToBlockName) {
          state.blockType = type;
        }
      }
    }

    state.fontSize = $getSelectionStyleValueForProperty(selection, 'font-size', '15px');
    state.fontColor = $getSelectionStyleValueForProperty(selection, 'color', '#000');
    state.bgColor = $getSelectionStyleValueForProperty(selection, 'background-color', '#fff');
    state.fontFamily = $getSelectionStyleValueForProperty(selection, 'font-family', 'Arial');
    state.elementFormat =
      ($isElementNode(node) ? node.getFormatType() : parent?.getFormatType()) || 'left';
  }

  return state;
}

export function clearFormatting(editor: LexicalEditor) {
  editor.update(() => {
    const selection = $getSelection();

    if ($isRangeSelection(selection)) {
      const { anchor } = selection;
      const { focus } = selection;

      const nodes = selection.getNodes();

      if (anchor.key === focus.key && anchor.offset === focus.offset) {
        return;
      }

      nodes.forEach((node, idx) => {
        // We split the first and last node by the selection
        // So that we don't format unselected text inside those nodes
        if ($isTextNode(node)) {
          if (idx === 0 && anchor.offset !== 0) {
            node = node.splitText(anchor.offset)[1] || node;
          }

          if (idx === nodes.length - 1) {
            node = node.splitText(focus.offset)[0] || node;
          }

          // eslint-disable-next-line no-underscore-dangle
          if (node.__style !== '') {
            node.setStyle('');
          }

          // eslint-disable-next-line no-underscore-dangle
          if (node.__format !== 0) {
            node.setFormat(0);
            $getNearestBlockElementAncestorOrThrow(node).setFormat('');
          }
        } else if ($isHeadingNode(node) || $isQuoteNode(node)) {
          node.replace($createParagraphNode(), true);
        } else if ($isDecoratorBlockNode(node)) {
          node.setFormat('');
        }
      });
    }
  });
}

const VERTICAL_GAP = 10;
const HORIZONTAL_OFFSET = 5;

export function setFloatingElemPositionForLinkEditor(
  targetRect: ClientRect | null,
  floatingElem: HTMLElement,
  anchorElem: HTMLElement,
  verticalGap: number = VERTICAL_GAP,
  horizontalOffset: number = HORIZONTAL_OFFSET
): void {
  const scrollerElem = anchorElem.parentElement;

  if (targetRect === null || !scrollerElem) {
    floatingElem.style.opacity = '0';
    floatingElem.style.transform = 'translate(-10000px, -10000px)';
    return;
  }

  const floatingElemRect = floatingElem.getBoundingClientRect();
  const anchorElementRect = anchorElem.getBoundingClientRect();
  const editorScrollerRect = scrollerElem.getBoundingClientRect();

  let top = targetRect.top - floatingElemRect.height - verticalGap;
  let left = targetRect.left - horizontalOffset;

  if (top < editorScrollerRect.top) {
    top += floatingElemRect.height + targetRect.height + verticalGap * 2;
  }

  if (left + floatingElemRect.width > editorScrollerRect.right) {
    left = editorScrollerRect.right - floatingElemRect.width - horizontalOffset;
  }

  top -= anchorElementRect.top;
  left -= anchorElementRect.left;

  floatingElem.style.opacity = '1';
  floatingElem.style.transform = `translate(${left}px, ${top}px)`;
}
