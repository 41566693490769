import { useState } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { LinkPlugin as LexicalLinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import LinkForm from '../components/LinkForm';
import SelectionCallout from '../components/SelectionCallout';

export default function LinkPlugin(): JSX.Element {
  const [editor] = useLexicalComposerContext();

  const [calloutOpen, setCalloutOpen] = useState(false);

  return (
    <>
      <LexicalLinkPlugin
        validateUrl={(url: string) => {
          setCalloutOpen(!url);

          return !!url;
        }}
      />
      {calloutOpen && (
        <SelectionCallout onDismiss={() => setCalloutOpen(false)}>
          <LinkForm editor={editor} />
        </SelectionCallout>
      )}
    </>
  );
}
