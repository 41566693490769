import Label from 'components/inputs/Label';
import LongTextField from 'components/inputs/LongTextField';
import Rating from 'components/inputs/Rating';
import RichTextEditor from 'components/inputs/RichTextEditor';
import LoadingSpinner from 'components/progress/LoadingSpinner';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getLookUpInstance, searchLookUpInstances } from 'services/fetchRequests';
import {
  DateTimeFieldFormat,
  IFieldProps,
  ILongTextFieldVersionProps,
  ILookupColumnField,
  IProcessInstanceProps,
  ITeamProps,
  IUserProps,
  ProcessLookupType,
  ProcessOutcome,
  RouteFieldType
} from 'types';
import { convertEpUserOrTeamToPersonaProps } from 'utils/helpers';
import {
  DetailsList,
  DetailsRow,
  Facepile,
  Callout as FluentCallout,
  IColumn,
  IDetailsRowProps,
  IFacepilePersona,
  ITextField,
  Icon,
  IconButton,
  Link,
  SpinnerSize,
  TextField
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import BooleanField from '../BooleanField';
import { LookupWrapper } from './LookUp.styles';

export interface ILookUpProps {
  id?: string;
  /**
   * Id of the selected instance
   */
  defaultValue?: {
    id: IProcessInstanceProps['id'];
    value?: string;
  };
  /**
   * ID of the template / definition to find instances
   */
  definitionId: string;
  /**
   * Description of the lookup field.
   */
  description?: string;
  /**
   * Optional flag to mark the lookupfield as readOnly
   * @defaultvalue false
   */
  disabled: boolean;
  /**
   * Field ids of the fields to be displayed
   */
  fields: ILookupColumnField[];
  /**
   * Gets the contents of a single file as byte stream
   */
  getFileContents: ({ id }: { id: string }) => Promise<Response>;
  /**
   * A label for the lookup field
   */
  label?: string;
  /**
   * The icon that will be displayed to the left of the label
   */
  labelIconName?: string;
  /**
   * Specifies whether to search for all instances or only for completed instances
   */
  lookupType: ProcessLookupType;
  /**
   * Callback issued when an instance is selected
   */
  onChange?: ({ id }: { id: string }) => void;
  /**
   * Specifies which instances are to be searched with which outcome result (positve, negative, undecided)
   */
  outcome: ProcessOutcome;
  /**
   * Whether the associated form field is required or not
   * @defaultvalue false
   */
  required?: boolean;
}

export interface ISearchInstancesProps {
  definitionId: ILookUpProps['definitionId'];
  lookupType: ILookUpProps['lookupType'];
  outcome: ILookUpProps['outcome'];
  searchTerm?: string;
  fieldIds: string[];
}

export interface IInstanceColumn {
  field: IFieldProps;
  fieldType: RouteFieldType;
  id: string;
  name: string;
  value: unknown;
}

export interface IChoiceProps {
  value: string;
  id: string;
}

interface ISearchResultColumn {
  id: string;
  name: string;
}

interface ISearchResult extends IProcessInstanceProps {
  [key: string]: unknown;
}

function LookUp(LookUpProps: ILookUpProps) {
  const {
    id,
    defaultValue,
    definitionId,
    description,
    disabled = false,
    fields,
    getFileContents,
    label,
    labelIconName,
    lookupType,
    onChange,
    outcome,
    required = false
  } = LookUpProps;

  const { t } = useTranslation();

  const searchFieldIds = useMemo(
    () => (fields?.map((field) => field.fieldId).filter((fieldId) => !!fieldId) as string[]) || [],
    [fields]
  );
  const searchResultFieldIds = useMemo(
    () =>
      (fields
        ?.filter(({ showInResult }) => showInResult)
        .map(({ fieldId }) => fieldId)
        .filter((fieldId) => !!fieldId) as string[]) || [],
    [fields]
  );

  const [searchResult, setSearchResult] = useState<ISearchResult[] | null>(null);
  const [searchResultsColumns, setSearchResultsColumns] = useState<ISearchResultColumn[] | null>(
    null
  );

  const textFieldRef = useRef<ITextField>(null);
  const [selectedInstance, setSelectedInstance] = useState<IProcessInstanceProps | null>();

  const [initialized, setinitialized] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<{ search: boolean; instance: boolean }>({
    search: false,
    instance: !!defaultValue
  });

  useEffect(() => {
    // useEffect to get instance on selection
    const instanceId = selectedInstance?.id;

    if (instanceId && isLoading.instance) {
      getLookUpInstance({ fieldIds: searchResultFieldIds, instanceId }).then((instance) => {
        setSelectedInstance(instance);
        setIsLoading((prevState) => ({ ...prevState, instance: false }));
      });
    }
  }, [defaultValue, searchResultFieldIds, selectedInstance?.id, isLoading.instance]);

  useEffect(() => {
    // useEffect to get instance on initialization
    if (!initialized && defaultValue) {
      setinitialized(true);

      if (defaultValue.id !== selectedInstance?.id) {
        getLookUpInstance({ fieldIds: searchResultFieldIds, instanceId: defaultValue.id }).then(
          (instance) => {
            setIsLoading((prevState) => ({ ...prevState, instance: false }));
            setSelectedInstance(instance);
          }
        );
      }
    }
  }, [defaultValue, searchResultFieldIds, initialized, selectedInstance?.id]);

  const textFieldHookId = useId('search-textfield');
  const textFieldId = id ? `input-${id}` : textFieldHookId;

  function convertInstanceToSearchResult(instances: IProcessInstanceProps[]) {
    const newSearchResult: ISearchResult[] = instances.map((item) => {
      const newItem = { ...item } as ISearchResult;

      item.columns?.forEach((column) => {
        if (column.id) {
          newItem[column.id] = column;
        }
      });

      return newItem;
    });

    return newSearchResult;
  }

  const onTextFieldChange = debounce((_: unknown, filterText?: string | undefined) => {
    if (filterText) {
      setIsLoading((prevState) => ({ ...prevState, search: true }));

      searchLookUpInstances({
        searchTerm: filterText,
        definitionId,
        lookupType,
        outcome,
        fieldIds: searchFieldIds
      }).then((result) => {
        setIsLoading((prevState) => ({ ...prevState, search: false }));

        const newSearchResult = convertInstanceToSearchResult(result.items);
        setSearchResult(newSearchResult);
      });
    }

    setSearchResult(null);
  }, 800);

  function onTextFieldFocus() {
    searchLookUpInstances({
      definitionId,
      lookupType,
      outcome,
      fieldIds: searchFieldIds
    }).then((result) => {
      setIsLoading((prevState) => ({ ...prevState, search: false }));

      const newSearchResult = convertInstanceToSearchResult(result.items);
      setSearchResult(newSearchResult);

      if (!searchResultsColumns) {
        setSearchResultsColumns(result.columns);
      }
    });
  }

  function onRenderDateColumn(item: IProcessInstanceProps) {
    const date = item.creationDate?.toString();

    return <div>{moment(date).format('L')}</div>;
  }

  function onRenderCreatorColumn(item: IProcessInstanceProps) {
    const creatorName = item.creator?.name;

    return <div>{creatorName}</div>;
  }

  function onRenderRow(props?: IDetailsRowProps) {
    if (props) {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <DetailsRow {...props} styles={{ root: { cursor: 'pointer' } }} />;
    }

    return null;
  }

  function onRowClick(instance: IProcessInstanceProps) {
    setIsLoading((prevState) => ({ ...prevState, instance: true }));

    setSelectedInstance(instance);
    if (onChange) onChange({ id: instance.id });

    setSearchResult(null);
  }

  function onRenderFieldColumn(item: ISearchResult, index?: number, column?: IColumn) {
    if (!column) return null;

    return renderResultListValueColumnContents(item[column.key] as IInstanceColumn);
  }

  function Callout() {
    let calloutContent: ReactNode = null;

    if (isLoading.search) {
      calloutContent = (
        <LoadingSpinner
          styles={{ container: { width: '450px', padding: 10 } }}
          // label="Loading Instances"
          size={SpinnerSize.large}
          // labelPosition="bottom"
        />
      );
    }

    const columns: IColumn[] = [];

    if (fields.find((field) => field.fieldName === 'intId' && field.showInList)) {
      columns.push({
        fieldName: 'intId',
        isResizable: true,
        key: 'intId',
        minWidth: 20,
        name: 'ID'
      });
    }

    if (fields.find((field) => field.fieldName === 'name' && field.showInList)) {
      columns.push({
        fieldName: 'name',
        isResizable: true,
        key: 'name',
        minWidth: 150,
        name: 'Name'
      });
    }

    if (fields.find((field) => field.fieldName === 'creator' && field.showInList)) {
      columns.push({
        fieldName: 'creator',
        isResizable: true,
        key: 'creator',
        minWidth: 120,
        name: t(`lookupField.instanceList.creator`),
        onRender: onRenderCreatorColumn
      });
    }

    if (fields.find((field) => field.fieldName === 'creationDate' && field.showInList)) {
      columns.push({
        fieldName: 'creationDate',
        isResizable: true,
        key: 'creationDate',
        minWidth: 100,
        name: t(`lookupField.instanceList.creationDate`),
        onRender: onRenderDateColumn
      });
    }

    if (searchResultsColumns) {
      searchResultsColumns.forEach((column) => {
        const field = fields.find((field) => field.fieldId === column.id);

        if (field?.showInList === false) return;

        columns.push({
          fieldName: column.id,
          isResizable: true,
          key: column.id,
          minWidth: 100,
          name: column.name,
          onRender: onRenderFieldColumn
        });
      });
    }

    if (searchResult?.length) {
      calloutContent = (
        <DetailsList
          compact
          checkboxVisibility={2}
          onRenderRow={onRenderRow}
          onActiveItemChanged={onRowClick}
          items={searchResult}
          columns={columns}
        />
      );
    }

    if (searchResult && searchResult.length === 0) {
      calloutContent = (
        <div style={{ textAlign: 'center', width: '450px', padding: 20 }}>
          <Icon styles={{ root: { fontSize: 28 } }} iconName="SearchIssue" />
          <div>Kein Ergebnis</div>
        </div>
      );
    }

    return (
      <FluentCallout
        onDismiss={() => {
          setSearchResult(null);
          setIsLoading((prevState) => ({ ...prevState, search: false }));
        }}
        isBeakVisible={false}
        directionalHint={4}
        gapSpace={2}
        styles={{ calloutMain: { maxWidth: '800px' } }}
        calloutMaxHeight={200}
        target={`#${textFieldId}`}
      >
        {calloutContent}
      </FluentCallout>
    );
  }

  function onRemoveSelectedInstance() {
    // reset instance
    setSelectedInstance(null);

    // focus textfield
    setTimeout(() => textFieldRef.current?.focus(), 100);
  }

  function getFieldIconName(fieldType: RouteFieldType): string | null {
    switch (fieldType) {
      case RouteFieldType.SmallText:
        return 'AlignLeft';

      case RouteFieldType.LongText:
        return 'AlignLeft';

      case RouteFieldType.Number:
        return 'NumberSymbol';

      case RouteFieldType.DateTime:
        return 'Calendar';

      case RouteFieldType.Choice:
        return 'CheckboxComposite';

      case RouteFieldType.Person:
        return 'Contact';

      case RouteFieldType.Rating:
        return 'FavoriteStarFill';

      case RouteFieldType.Hyperlink:
        return 'Link';

      case RouteFieldType.Document:
        return 'Attach';

      case RouteFieldType.Location:
        return 'LocationOutline';

      default:
        return null;
    }
  }

  function onRenderResultListIconColumn(item: IFieldProps) {
    const iconName = getFieldIconName(item.fieldType);

    if (iconName) {
      return <Icon iconName={iconName} styles={{ root: { marginTop: 2 } }} />;
    }

    return null;
  }

  function onRenderResultListNameColumn(item: IInstanceColumn) {
    return <div>{item.name}</div>;
  }

  function getLongText(
    value: string | { currentValue?: string; versions: ILongTextFieldVersionProps[] }
  ) {
    if (!value) {
      return null;
    }

    if (typeof value === 'string') {
      return (
        <RichTextEditor styles={{ editorWrapper: { padding: 0 } }} defaultValue={value} disabled />
      );
    }

    return (
      <LongTextField
        defaultValue={value.currentValue}
        disabled
        required={false}
        versions={value.versions}
        styles={{ version: { padding: 0, paddingBottom: 5 } }}
      />
    );
  }

  function getDateTime(date: string | null, displayTime: boolean): string | null {
    if (!date) {
      return null;
    }

    if (displayTime) {
      return `${moment(date).format('L')}, ${moment(date).format('LT')}`;
    }

    return moment(date).format('L');
  }

  function renderChoiceListRow(choice: IChoiceProps) {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }} key={`option-${choice.id}`}>
        <Icon iconName="CheckboxComposite" style={{ fontSize: '12px' }} />
        <div style={{ marginLeft: '5px' }}>{choice.value}</div>
      </div>
    );
  }

  function getChoiceList(choices: IChoiceProps[] | null) {
    if (!choices) {
      return null;
    }

    return choices.map(renderChoiceListRow);
  }

  function getBooleanField(value: boolean, field: IFieldProps) {
    if (field.confirmationFormat === 1) {
      if (typeof value === 'boolean') {
        return value ? (field.textOk as string) : (field.textNOk as string);
      }

      return null;
    }

    return (
      <BooleanField
        {...field}
        disabled
        defaultValue={value}
        styles={{
          label: { container: { display: 'none' } },
          checkboxWrapper: { border: 'none', padding: '0' }
        }}
      />
    );
  }

  function getPersonas(value: { teams?: ITeamProps[]; users?: IUserProps[] } | null) {
    if (!value || (!value.teams?.length && !value.users?.length)) {
      return null;
    }

    const values = [...(value.teams || []), ...(value.users || [])];

    let personas = values.map(convertEpUserOrTeamToPersonaProps);
    personas = personas.map((persona) => ({ ...persona, personaName: persona.text }));

    const maxDisplayablePersonas = 4;
    let title = '';

    if (personas.length > maxDisplayablePersonas) {
      title = personas
        .slice(maxDisplayablePersonas)
        .map((persona) => persona.text)
        .join(', ');
    }

    return (
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: 3 }}>
        <Facepile
          personas={personas as IFacepilePersona[]}
          maxDisplayablePersonas={maxDisplayablePersonas}
          personaSize={2}
          overflowButtonType={1}
          overflowButtonProps={{ title, disabled: true }}
        />
      </div>
    );
  }

  function getRating({ value, maxRating }: { value?: number; maxRating?: number }) {
    if (!value) {
      return null;
    }

    return (
      <Rating
        maxRating={maxRating || 5}
        defaultValue={value}
        disabled
        styles={{
          rootIsSmall: { height: '20px' },
          ratingButton: { padding: 0 },
          root: {
            height: '20px',
            border: 'none',
            backgroundColor: 'transparent',
            ':hover': { border: 'none' }
          }
        }}
      />
    );
  }

  function onDocumentLinkClick(id: string, text: string): void {
    const downloadLink = document.createElement('a');

    getFileContents({ id })
      .then((response) => response.blob())
      .then((blob) => URL.createObjectURL(blob))
      .then((blobUrl) => {
        downloadLink.href = blobUrl;
        downloadLink.download = text;

        document.body.appendChild(downloadLink);
        downloadLink.click();

        URL.revokeObjectURL(blobUrl);
      });
  }

  function getDocumentLink(documentProps: { text: string; url: string }) {
    if (documentProps && documentProps.url) {
      const fileIdIndex = documentProps.url.indexOf('id=') + 3;
      const id = documentProps.url.substr(fileIdIndex);

      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link onClick={() => onDocumentLinkClick(id, documentProps.text)}>
          {documentProps.text}
        </Link>
      );
    }

    return null;
  }

  function getHyperLink(linkProps: { url: string; text: string }) {
    if (linkProps && linkProps.url) {
      const { url, text } = linkProps;
      let linkTextWithProtocol = url;

      const hasProtocol = /https?:\/\//;

      if (!hasProtocol.test(url)) {
        linkTextWithProtocol = `http://${url}`;
      }

      return (
        <Link target="_blank" href={linkTextWithProtocol}>
          {text || linkTextWithProtocol}
        </Link>
      );
    }

    return null;
  }

  function isMobileDevice(): boolean {
    return typeof window.orientation !== 'undefined';
  }

  function isAppleDevice(): boolean {
    const platform = navigator.platform.toLowerCase();

    return (
      platform === 'macintosh' ||
      platform === 'macintel' ||
      platform === 'iphone' ||
      platform === 'ipad'
    );
  }

  function onOpenMap(latitude: number, longitude: number): void {
    if (isMobileDevice()) {
      // open native app
      const urlCoords = `?q=${latitude},${longitude}`;

      const url = isAppleDevice() ? `maps://${urlCoords}` : `geo:${urlCoords}`;

      window.open(url, '_system');
    } else {
      // open bing
      const url = `https://bing.com/maps/default.aspx?cp=${latitude}~${longitude}&lvl=18&sp=point.${latitude}_${longitude}`;

      window.open(url);
    }
  }

  function getLocationLink(locationProps: { latitude: number; longitude: number }) {
    if (locationProps && locationProps.latitude && locationProps.longitude) {
      const { latitude, longitude } = locationProps;

      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      return <Link onClick={() => onOpenMap(latitude, longitude)}>Open map</Link>;
    }

    return null;
  }

  function renderResultListValueColumnContents(item: IInstanceColumn): ReactNode {
    const defaultValue = <div>-</div>;

    if (!item) return defaultValue;

    const { fieldType, value, field } = item;

    if (!field) return defaultValue;

    switch (fieldType) {
      case RouteFieldType.SmallText:
        return <div>{value as string}</div> || defaultValue;

      case RouteFieldType.LongText:
        return (
          getLongText(
            value as string | { currentValue?: string; versions: ILongTextFieldVersionProps[] }
          ) || defaultValue
        );

      case RouteFieldType.Number:
        return <div>{value as number}</div> || defaultValue;

      case RouteFieldType.DateTime: {
        const displayTime = field.dateFormat === DateTimeFieldFormat.DateAndTime;

        return getDateTime((value as string) || null, displayTime) || defaultValue;
      }

      case RouteFieldType.Boolean: {
        return getBooleanField(value as boolean, field) || defaultValue;
      }

      case RouteFieldType.Choice: {
        return getChoiceList(value as IChoiceProps[]) || defaultValue;
      }

      case RouteFieldType.Person: {
        const userValue = value as IUserProps;
        if (userValue?.userId) {
          return (
            getPersonas(({ users: [userValue] } as { users?: IUserProps[] }) || null) ||
            defaultValue
          );
        }

        return (
          getPersonas((value as { teams?: ITeamProps[]; users?: IUserProps[] }) || null) ||
          defaultValue
        );
      }

      case RouteFieldType.Rating: {
        const { numStars } = field;
        return (
          getRating({ value, maxRating: numStars } as { value: number; maxRating: number }) ||
          defaultValue
        );
      }

      case RouteFieldType.Document: {
        return getDocumentLink(value as { text: string; url: string }) || defaultValue;
      }

      case RouteFieldType.Hyperlink: {
        return getHyperLink(value as { url: string; text: string }) || defaultValue;
      }

      case RouteFieldType.Location: {
        return getLocationLink(value as { latitude: number; longitude: number }) || defaultValue;
      }

      default:
        return defaultValue;
    }
  }

  function onRenderResultListValueColumn(item: IInstanceColumn): ReactNode {
    return <div style={{ userSelect: 'text' }}>{renderResultListValueColumnContents(item)}</div>;
  }

  if (isLoading.instance) {
    return (
      <LoadingSpinner
        styles={{ container: { padding: 10 } }}
        // label="Loading Instance"
        size={SpinnerSize.large}
        // labelPosition="bottom"
      />
    );
  }

  if (selectedInstance && selectedInstance.columns) {
    const columns: IColumn[] = [
      {
        isResizable: false,
        key: 'icon',
        minWidth: 20,
        maxWidth: 20,
        name: 'Icon',
        onRender: onRenderResultListIconColumn,
        isIconOnly: true
      },
      {
        fieldName: 'name',
        isResizable: true,
        key: 'name',
        onRender: onRenderResultListNameColumn,
        minWidth: 80,
        maxWidth: 140,
        name: 'Name'
      },
      {
        fieldName: 'value',
        isResizable: true,
        key: 'value',
        onRender: onRenderResultListValueColumn,
        minWidth: 160,
        name: 'Value'
      }
    ];

    const items = [];

    if (fields.find((field) => field.fieldName === 'creator' && field.showInResult)) {
      items.unshift({
        id: 'creator',
        name: t(`lookupField.instanceList.creator`),
        field: {
          name: t(`lookupField.instanceList.creator`)
        },
        fieldType: RouteFieldType.Person,
        value: selectedInstance.creator
      });
    }

    if (fields.find((field) => field.fieldName === 'creationDate' && field.showInResult)) {
      items.unshift({
        id: 'created',
        name: t(`lookupField.instanceList.creationDate`),
        field: {
          name: t(`lookupField.instanceList.creationDate`)
        },
        fieldType: RouteFieldType.DateTime,
        value: new Date(selectedInstance.creationDate as string)
      });
    }

    items.push(...selectedInstance.columns);

    return (
      <LookupWrapper>
        <Label
          required={required}
          iconName={labelIconName}
          label={label}
          description={description}
        />
        <div className="c-selected-instance_wrapper">
          <div className="c-selected-instance_label">
            <div className="c-selected-instance_name">
              #{selectedInstance.intId} - {selectedInstance.name}
            </div>
            {!disabled && (
              <IconButton
                styles={{ root: { marginTop: '3px', height: '25px' } }}
                iconProps={{
                  styles: { root: { fontSize: '13px' } },
                  iconName: 'CalculatorMultiply'
                }}
                onClick={onRemoveSelectedInstance}
              />
            )}
          </div>
          <DetailsList compact checkboxVisibility={2} items={items} columns={columns} />
        </div>
      </LookupWrapper>
    );
  }

  return (
    <LookupWrapper id={id}>
      <Label required={required} iconName={labelIconName} label={label} description={description} />
      <TextField
        autoComplete="off"
        componentRef={textFieldRef}
        disabled={disabled}
        placeholder={t('lookupField.placeholder', { context: disabled ? 'disabled' : undefined })}
        id={textFieldId}
        onChange={onTextFieldChange}
        onFocus={onTextFieldFocus}
      />
      <Callout />
    </LookupWrapper>
  );
}

export default LookUp;
