/* eslint-disable no-plusplus */

import SmallTextField from 'components/inputs/SmallTextField';
import LoadingSpinner from 'components/progress/LoadingSpinner';
import { AppContext } from 'features/App';
import { MouseEvent, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AIFieldPromptRequest } from 'services/fetchRequests';
import styled, { useTheme } from 'styled-components';
import { IAIFieldValueProps, IFieldLinkProps, IFieldProps, RouteFieldVisibility } from 'types';
import { getFluentIconNameByFieldType } from 'utils/helpers';
import { PrimaryButton } from '@fluentui/react';
import { getTextFieldStyles } from './DynamicField.styles';

const DynamicAiFieldStyled = styled.div`
  .notice {
    margin-top: 2px;
    font-size: 12px;
  }

  .button-refining-container {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 5px;

    > *:first-child {
      flex-grow: 1;
    }
  }
`;

export interface IDynamicAIFieldProps {
  disabled?: boolean;
  fieldLink: IFieldLinkProps;
  instanceId?: string;
  onChange: (value?: IAIFieldValueProps | null) => void;
}

function DynamicAIField({ instanceId, fieldLink, disabled, onChange }: IDynamicAIFieldProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  // global app state
  const { globalAppState } = useContext(AppContext);
  const { currentUser } = globalAppState;

  // userSettings
  const isEvocomUser = currentUser?.login?.endsWith('@evocom.de');

  const [status, setStatus] = useState<'init' | 'loading' | 'ready'>('init');

  const [refining, setRefining] = useState<string | undefined>();

  const buttonRef = useRef<PrimaryButton>(null);

  const { field, visibility } = fieldLink;
  const {
    requestType,
    name,
    description,
    descriptionPlacement,
    id,
    prompt,
    allowRefining,
    autoPrompt
  } = field as IFieldProps;

  const [currentValue, setCurrentValue] = useState<IAIFieldValueProps | undefined | null>(
    fieldLink.value as IAIFieldValueProps
  );

  const displayDescriptionTooltip = descriptionPlacement === 1;

  const readOnly = visibility === RouteFieldVisibility.ReadOnly;
  const required = visibility === RouteFieldVisibility.Required;

  useEffect(() => {
    if (!disabled && autoPrompt && status === 'init' && !currentValue) {
      setStatus('loading');

      const fakeEvent: Partial<MouseEvent<HTMLButtonElement>> = {
        target: {},
        type: 'click'
      } as MouseEvent<HTMLButtonElement>;

      buttonRef.current?.props?.onClick?.(fakeEvent as MouseEvent<HTMLButtonElement>);
    }
  }, [disabled, autoPrompt, currentValue, status]);

  function removeNewlinesAtStartAndEnd(text: string) {
    if (typeof text !== 'string') {
      return text;
    }

    let start = 0;
    let end = text.length - 1;

    while (text.charAt(start) === '\n' && start < text.length) {
      start++;
    }

    while (text.charAt(end) === '\n' && end >= 0) {
      end--;
    }

    return text.substring(start, end + 1);
  }

  async function getPromptResult() {
    setStatus('loading');
    setRefining('');

    let messages: IAIFieldValueProps['messages'] | undefined;

    if (allowRefining && refining && currentValue?.messages) {
      messages = [...currentValue.messages, { chatRole: 1, content: refining }];
    }

    const response = await AIFieldPromptRequest({
      fieldId: id,
      instanceId,
      requestType: requestType || 2,
      messages
    });

    const responseClone = { ...response };

    responseClone.choices = response.choices.map((choice) => {
      return { ...choice, text: removeNewlinesAtStartAndEnd(choice.text || '') };
    });

    setStatus('ready');
    return responseClone;
  }

  function onRenderButtonText() {
    if (status === 'loading') {
      return <LoadingSpinner key={1} size={1} />;
    }

    return <div key={2}>{t('dynamicField.aiField.generate')}</div>;
  }

  function onTextFieldChange(value?: string) {
    let newValue = null;

    if (currentValue) {
      newValue = { ...currentValue, choices: [{ text: value || '' }] };
    }

    setCurrentValue(newValue);
    onChange(newValue);
  }

  const textFieldValue = currentValue?.choices[0]?.text;

  async function onGenerateClick() {
    const response = await getPromptResult();

    setCurrentValue(response);
    onChange(response);
  }

  function renderButtonRefiningContainer() {
    if (disabled || readOnly) return null;

    return (
      <div className="button-refining-container">
        {allowRefining && textFieldValue && (
          <SmallTextField
            readOnly={!textFieldValue}
            onChange={setRefining}
            value={refining}
            disabled={disabled || readOnly}
            placeholder={t('dynamicField.aiField.refiningPlaceholder')}
            styles={getTextFieldStyles(theme)}
          />
        )}
        <PrimaryButton
          ref={buttonRef}
          styles={{ root: { borderRadius: 6, maxWidth: 100 } }}
          onRenderText={onRenderButtonText}
          disabled={status === 'loading' || disabled || readOnly || !prompt}
          onClick={onGenerateClick}
        />
      </div>
    );
  }

  function renderCosts() {
    if (!isEvocomUser || !currentValue?.totalTokens) return null;

    // 0,018466€ per 1000 Token
    const tokens = currentValue.totalTokens;
    let costs = (tokens / 1000) * 0.018466 * 24;

    // round to 5 decimals
    costs = Math.round(costs * 10000) / 10000;

    return <div className="notice">Kosten der letzten Anfrage: {costs}€</div>;
  }

  return (
    <DynamicAiFieldStyled>
      <SmallTextField
        labelIconName={getFluentIconNameByFieldType(field)}
        label={name}
        placeholder={prompt || ''}
        multiline
        autoAdjustHeight
        readOnly={!textFieldValue}
        onChange={textFieldValue ? onTextFieldChange : undefined}
        value={currentValue?.choices[0]?.text}
        description={displayDescriptionTooltip ? description : undefined}
        disabled={disabled || readOnly}
        id={id}
        required={required}
        styles={getTextFieldStyles(theme)}
      />
      {renderCosts()}
      {renderButtonRefiningContainer()}
    </DynamicAiFieldStyled>
  );
}

export default DynamicAIField;
