import { useCallback, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { IFileProps } from 'types';

export interface IMultiMediaImageProps {
  autoPlay?: boolean;
  controls?: boolean;
  height?: number | string;
  mediaValue: IFileProps;
  getStreamUrl: ({ fileId, tenantId }: { fileId: string; tenantId: string }) => Promise<string>;
  tenantId: string;
  width?: number | string;
}

function MultiMediaVideo({
  autoPlay = false,
  controls,
  height,
  getStreamUrl,
  mediaValue,
  tenantId,
  width
}: IMultiMediaImageProps): JSX.Element | null {
  const [blobUrl, setBlobUrl] = useState<string | null>('');

  const setStreamingUrl = useCallback(
    (fileId: string) => {
      getStreamUrl({ fileId, tenantId }).then((url) => {
        setBlobUrl(url);
      });
    },
    [getStreamUrl, tenantId]
  );

  useEffect(() => {
    let url = '';
    const isNewVideoFile = mediaValue.blobFile?.type?.includes('video');

    if (isNewVideoFile && mediaValue.blobFile) {
      url = URL.createObjectURL(mediaValue.blobFile);
      setBlobUrl(url);
    } else if (mediaValue.url) {
      const fileIdIndex = mediaValue.url.indexOf('id=') + 3;
      const id = mediaValue.url?.substr(fileIdIndex);

      setStreamingUrl(id);
    }

    return () => URL.revokeObjectURL(url);
  }, [mediaValue.blobFile, mediaValue.url, setStreamingUrl, tenantId]);

  if (!blobUrl) return null;

  return (
    <ReactPlayer
      playing={autoPlay}
      controls={controls}
      width={width || '100%'}
      height={height || '100%'}
      url={blobUrl}
    />
  );
}

export default MultiMediaVideo;
