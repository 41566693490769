import { DialogCustom, LanguagePicker } from 'components';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import fetchRequest, { apiErrorHandler } from 'services/api';
import styled from 'styled-components';
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IconButton,
  Label,
  PrimaryButton,
  TextField,
  TooltipHost
} from '@fluentui/react';

const TranslationInput = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 5px;
`;

const DialogInputContent = styled.div`
  display: flex;
  width: 100%;
`;

const DialogContentStyled = styled.div`
  display: inline-block;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const VerticalDivider = styled.div`
  width: 50px;
`;
function CreateEnd2EndTypeDialog({ hidden, onCancel, onConfirm, end2endType, items }) {
  const { t } = useTranslation();
  const [newType, setNewType] = useState(null);
  const [showResolveOrderDialog, setShowResolveOrderDialog] = useState(false);

  const [showLanguages, setShowLanguages] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [newTypeTranslated, setNewTypeTranslated] = useState(null);
  const [newTypeChanged, setNewTypeChanged] = useState(null);
  const [closeDialog, setCloseDialog] = useState(false);

  const getSortOrderValues = useCallback(() => {
    if (items) {
      return items.map((item) => {
        return item.sortOrder || null;
      });
    }
    return null;
  }, [items]);

  useEffect(() => {
    if (!newType) {
      if (end2endType) {
        setNewType(end2endType);
      } else {
        const nextSortOrder = Math.max(...getSortOrderValues()) + 1;

        const initialType = {
          sortOrder: nextSortOrder
        };

        setNewType(initialType);
      }
    }
  }, [newType, end2endType, getSortOrderValues]);

  const translateType = useCallback((type, language) => {
    // translate content to display it in other language than the browser's
    const translatedName =
      type?.allNames?.find((x) => x.language === language.key)?.text || type.name;
    const translatedDescription =
      type?.allDescriptions?.find((x) => x.language === language.key)?.text || type.description;

    const translatedType = {
      ...type,
      name: translatedName,
      description: translatedDescription
    };

    return translatedType;
  }, []);

  useEffect(() => {
    if (
      newType &&
      selectedLanguage &&
      (!newTypeTranslated || newTypeTranslated.lang !== selectedLanguage.key)
    ) {
      const translatedType = translateType(newType, selectedLanguage);

      setNewTypeTranslated({ ...translatedType, lang: selectedLanguage.key });
    }
  }, [newType, selectedLanguage, newTypeTranslated, translateType]);

  function close() {
    onCancel();
  }

  async function reorderProcessTypes(type, items) {
    const itemsToReorder = items.filter((x) => x.id !== type.id && x.sortOrder >= type.sortOrder);

    if (itemsToReorder) {
      return Promise.all(
        itemsToReorder.map(async (item, i) => {
          const typeToEdit = item;
          let prevSortOrder = 0;
          if (i > 0) {
            prevSortOrder = itemsToReorder[i - 1].sortOrder;
          }
          if (prevSortOrder === 0 || prevSortOrder === typeToEdit.sortOrder) {
            typeToEdit.sortOrder += 1;
            typeToEdit.processGrouping = null;
            const result = await updateType(typeToEdit, 'PUT');
            return result;
          }
          return null;
        })
      );
    }
    return null;
  }

  async function saveType(toReorderElements, close) {
    const typeToSave = newType;
    if (typeof typeToSave.sortOrder === 'string') {
      if (typeToSave.sortOrder.includes('.') || typeToSave.sortOrder.includes(',')) {
        typeToSave.sortOrder = parseFloat(typeToSave.sortOrder);
      } else {
        typeToSave.sortOrder = parseInt(typeToSave.sortOrder, 10);
      }
    }
    if (toReorderElements) {
      reorderProcessTypes(typeToSave, items);
    }

    let method = 'POST';

    if (typeToSave.id) {
      method = 'PUT';
    }

    const newCreatedType = await updateType(typeToSave, method);

    onConfirm(newCreatedType, close);
  }

  function onConfirmNewType(close) {
    if (!newType.sortOrder || (newType.sortOrder && validateNumber(newType.sortOrder))) {
      verifySortOrder(newType.sortOrder, close);
    }
  }

  function getDialogStyles() {
    if (showLanguages) {
      return {
        main: [
          {
            selectors: {
              '@media (min-width: 1150px)': {
                width: '100%',
                minWidth: `${showLanguages ? '1120' : '550'}px`,
                minHeight: '250px'
              },
              '@media (max-width: 1120px)': {
                minHeight: '100%',
                minWidth: '100%'
              }
            }
          }
        ]
      };
    }

    return {
      main: [
        {
          selectors: {
            '@media (min-width: 650px)': {
              width: '100%',
              minWidth: '550px',
              minHeight: '250px'
            },
            '@media (max-width: 480px)': {
              minHeight: '100%',
              minWidth: '100%'
            }
          }
        }
      ]
    };
  }

  const validateNumber = (value) => {
    if (value > 0) {
      return /^-?[1-9][0-9]*(([.]?||,?)[0-9]+)?$/.test(value);
    }
    return false;
  };

  const getSortOrderErrorMessage = (value, t) => {
    return new Promise((resolve) => {
      setTimeout(
        () =>
          resolve(
            !value || validateNumber(value)
              ? ''
              : t('createEnd2EndType.dialog.error.sorting.message')
          ),
        1000
      );
    });
  };

  const isFormValid = () => {
    return newType && newType.name && newType.sortOrder && validateNumber(newType.sortOrder);
  };

  function verifySortOrder(sortOrder, close) {
    const element = items.find((x) => x.id !== newType.id && x.sortOrder === sortOrder);
    if (element) {
      setShowResolveOrderDialog(true);

      if (close) {
        setCloseDialog(true);
      }
    } else {
      saveType(false, close);
    }
  }

  function handleName(value, translation) {
    const currentType = translation ? newTypeTranslated : newType;

    if (currentType?.name !== value) {
      if (!newTypeChanged?.name) {
        setNewTypeChanged((prevState) => ({ ...prevState, name: true }));
      }
    } else if (newTypeChanged?.name) {
      setNewTypeChanged((prevState) => ({ ...prevState, name: false }));
    }

    if (translation) {
      const lang = selectedLanguage?.key;

      const translatedName = {
        language: lang,
        text: value
      };

      let newLang = true;

      const translatedNames = newType.allNames.map((name) => {
        if (name.language === lang) {
          newLang = false;

          return translatedName;
        }

        return name;
      });

      if (newLang) {
        translatedNames.push(translatedName);
      }

      setNewType({ ...newType, allNames: translatedNames });
      setNewTypeTranslated({ ...newTypeTranslated, name: value });
    } else {
      setNewType({ ...newType, name: value });
    }
  }

  function handleDescription(value, translation) {
    const currentType = translation ? newTypeTranslated : newType;

    if (currentType?.description !== value) {
      if (!newTypeChanged?.description) {
        setNewTypeChanged((prevState) => ({ ...prevState, description: true }));
      }
    } else if (newTypeChanged?.description) {
      setNewTypeChanged((prevState) => ({ ...prevState, description: false }));
    }

    if (translation) {
      const lang = selectedLanguage.key;

      const translatedDescription = {
        language: lang,
        text: value
      };

      let newLang = true;
      const translatedDescriptions = newType.allDescriptions.map((description) => {
        if (description.language === lang) {
          newLang = false;

          return translatedDescription;
        }

        return description;
      });

      if (newLang) {
        translatedDescriptions.push(translatedDescription);
      }

      setNewType({ ...newType, allDescriptions: translatedDescriptions });
      setNewTypeTranslated({ ...newTypeTranslated, description: value });
    } else {
      setNewType({ ...newType, description: value });
    }
  }

  function handleSortOrder(value) {
    if (newType?.sortOrder !== value) {
      if (!newTypeChanged?.sortOrder) {
        setNewTypeChanged((prevState) => ({ ...prevState, sortOrder: true }));
      }
    } else if (newTypeChanged?.sortOrder) {
      setNewTypeChanged((prevState) => ({ ...prevState, sortOrder: false }));
    }

    let sortOrder = value;

    if (typeof sortOrder === 'string') {
      sortOrder = parseInt(sortOrder, 10);
    }

    setNewType({ ...newType, sortOrder });
  }

  function reorderElements() {
    setShowResolveOrderDialog(false);

    const reorder = items.filter((x) => x.id !== newType.id && x.sortOrder === newType.sortOrder);

    saveType(reorder, closeDialog);
  }

  function onCancelResolveOrderDialog() {
    setShowResolveOrderDialog(false);
  }

  const translatedLanguages = (passedTyped) => {
    if (newType?.allNames?.length) {
      const transLang = passedTyped.allNames.map((name) => {
        return name.language;
      });

      return transLang;
    }

    return null;
  };

  function getTranslationInput(translation) {
    return (
      <TranslationInput>
        {translation ? (
          <div style={{ display: 'flex' }}>
            <Label style={{ marginRight: '5px' }}>
              {t('createEnd2EndType.dialog.language.label')}
            </Label>
            <LanguagePicker
              key={`lang-${newType?.allNames?.length || 0}`}
              disabled={!showLanguages}
              placeholder={t('createEnd2EndType.dialog.language.placeholder')}
              onChange={setSelectedLanguage}
              styles={{
                root: {
                  width: '200px'
                }
              }}
              translatedLanguages={translatedLanguages(newType)}
              defaultLanguageKey={selectedLanguage?.key}
            />
          </div>
        ) : (
          <TooltipHost
            content={t('createEnd2EndType.dialog.translate.tooltip')}
            calloutProps={{ gapSpace: 5, target: '#translate-button' }}
          >
            <IconButton
              id="translate-button"
              iconProps={{ iconName: 'DoubleChevronRight12' }}
              onClick={() => setShowLanguages(true)}
              disabled={showLanguages}
              ariaLabel="Translate"
            />
          </TooltipHost>
        )}
      </TranslationInput>
    );
  }

  function getDialogContent(translation) {
    return (
      <>
        {translation ? <VerticalDivider /> : null}
        <DialogContentStyled>
          {getTranslationInput(translation)}
          <TextField
            key={
              translation && selectedLanguage && newTypeTranslated?.lang === selectedLanguage.key
                ? `translated-name-${selectedLanguage.key}`
                : 'name'
            }
            label={t('createEnd2EndType.dialog.name.label')}
            required
            value={translation ? newTypeTranslated?.name : newType?.name}
            onChange={(_, value) => handleName(value, translation)}
            autoFocus
            disabled={translation && !selectedLanguage}
          />
          <TextField
            key={
              translation && selectedLanguage && newTypeTranslated?.lang === selectedLanguage.key
                ? `translated-description-${selectedLanguage.key}`
                : 'description'
            }
            label={t('createEnd2EndType.dialog.description.label')}
            value={translation ? newTypeTranslated?.description : newType?.description}
            onChange={(_, value) => handleDescription(value, translation)}
            disabled={translation && !selectedLanguage}
          />
          <TextField
            label={t('createEnd2EndType.dialog.sortOrder.label')}
            autoComplete="off"
            required
            value={newType && newType.sortOrder ? newType.sortOrder : ''}
            onGetErrorMessage={(value) => getSortOrderErrorMessage(value, t)}
            onChange={(_, value) => handleSortOrder(value)}
            disabled={translation}
          />
        </DialogContentStyled>
      </>
    );
  }

  function getPrimaryButton() {
    const thereAreChanges = newTypeChanged
      ? !!Object.keys(newTypeChanged)?.find((key) => newTypeChanged[key] === true)
      : 0;

    return (
      <PrimaryButton
        text={t('createEnd2EndType.dialog.button.saveAndClose')}
        split
        menuProps={{
          items: [
            {
              key: 'save',
              text: t('createEnd2EndType.dialog.button.save'),
              onClick: () => onConfirmNewType(false)
            }
          ]
        }}
        onClick={() => onConfirmNewType(true)}
        disabled={!isFormValid() || !thereAreChanges}
      />
    );
  }

  return (
    <div>
      <Dialog
        hidden={hidden}
        onDismiss={() => close()}
        styles={getDialogStyles}
        dialogContentProps={{
          type: DialogType.close,
          title:
            end2endType && end2endType.id
              ? t('createEnd2EndType.dialog.titleEdit')
              : t('createEnd2EndType.dialog.title')
        }}
        modalProps={{
          isBlocking: true
        }}
      >
        <DialogInputContent>
          {getDialogContent()}
          {showLanguages ? getDialogContent(showLanguages) : null}
        </DialogInputContent>
        <DialogFooter>
          {getPrimaryButton()}
          <DefaultButton
            onClick={() => close()}
            text={t('createEnd2EndType.dialog.button.cancel')}
          />
        </DialogFooter>
      </Dialog>
      {showResolveOrderDialog ? (
        <DialogCustom
          hidden={false}
          title={t('processMapAdministration.resolveSortOrderDialog.title')}
          subText={t('processMapAdministration.resolveSortOrderDialog.subText')}
          defaultButtonLabel={t('processMapAdministration.resolveOrderDialog.defaultButtonLabel')}
          primaryButtonLabel={t('processMapAdministration.resolveOrderDialog.primaryButtonLabel')}
          primaryButtonFunc={reorderElements}
          defaultButtonFunc={onCancelResolveOrderDialog}
          onDismiss={onCancelResolveOrderDialog}
          dialogContentProps={{
            type: DialogType.normal,
            title: t('processMapAdministration.resolveSortOrderDialog.title'),
            subText: t('processMapAdministration.resolveSortOrderDialog.subText')
          }}
          modalProps={{ isBlocking: true }}
        />
      ) : null}
    </div>
  );
}

CreateEnd2EndTypeDialog.propTypes = {
  hidden: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  end2endType: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    sortOrder: PropTypes.number
  }),
  items: PropTypes.arrayOf(PropTypes.object).isRequired
};

CreateEnd2EndTypeDialog.defaultProps = {
  hidden: true,
  end2endType: null
};

export default CreateEnd2EndTypeDialog;

export async function updateType(type, method) {
  const body = JSON.stringify(type);

  const newCreatedType = await fetchRequest({
    url: `EndToEndType`,
    method,
    body
  }).catch(apiErrorHandler);

  return method === 'PUT' ? type : newCreatedType;
}
