import { LoadingSpinner, PagesSettingContainer, SettingsContainer } from 'components';
import { usePages } from 'features/App/context/PagesContext';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getDefinitions } from 'services/fetchRequests';
import styled from 'styled-components';
import { PageLocation, projectPropType } from 'types';
import { ComboBox, CommandBarButton, IconButton, MessageBar, Toggle } from '@fluentui/react';

const ProjectTabStyled = styled.div`
  background-color: #f5f5f5;
  overflow: auto;
  padding: 1rem;
  width: 100%;
  height: 100%;
  padding-bottom: 3rem;

  .c-header {
    display: flex;
    font-weight: 300;
    font-size: 20px;
    margin-bottom: 10px;
    margin-right: 5px;
  }
`;

const StartableDefinitionsStyled = styled.div`
  border-radius: 5px;
  overflow: hidden;
  margin-top: 15px;
  border: ${({ hasItems }) => (hasItems ? '1px solid #e5e5e5' : 'none')};
`;

const ProcessContainerStyled = styled.div`
  display: flex;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 10px;
  align-items: center;
  background-color: #faf9f8;

  .c-column_item-icon {
    font-size: 20px;
    padding-right: 2px;
    padding-left: 2px;
    color: #b4b4b4;
  }

  .c-column_item-title {
    width: 100%;
    background: white;
    border-radius: 5px;
    padding: 5px;
    padding-left: 7px;
    border: 1px solid #d3d3d3;
  }
`;

function ProjectProcessesPivotItem({ disabled, project = {}, setProject }) {
  const [status, setStatus] = useState('loading');
  const [displayEmptyRow, setDisplayEmptyRow] = useState(false);
  const [definitions, setDefinitions] = useState(null);

  const { pages } = usePages([
    PageLocation.project,
    PageLocation.projectTask,
    PageLocation.projectDetails
  ]);

  const comboBoxRef = useRef(null);

  const { processAssociations = [] } = project;

  const { t } = useTranslation();

  useEffect(() => {
    if (definitions === null) {
      getDefinitions({ pageIndex: 0, itemsPerPage: 200, type: 10 }).then((res) => {
        setDefinitions(res.items);
        setStatus(res.items.length ? 'loaded' : 'no-definitions');
      });
    }
  }, [definitions]);

  function renderAddProcessButton() {
    if (displayEmptyRow) return null;

    // check if all processes are already associated
    if (definitions && definitions.length === processAssociations.length) return null;

    return (
      <CommandBarButton
        styles={{ root: { height: '40px', borderRadius: 6, marginTop: 5 } }}
        iconProps={{ iconName: 'Add' }}
        text={t('projectPanel.processes.addButton')}
        onClick={() => setDisplayEmptyRow(true)}
      />
    );
  }

  function onProcessAssociationChange({ value, definition }) {
    setProject((prevState) => {
      const prevStateProcessAssociations = prevState.processAssociations || [];
      let updatedProcessAssociations = [];

      if (value && definition) {
        // update existing association
        updatedProcessAssociations = prevStateProcessAssociations.map((pa) => {
          if (pa.definitionId === definition.id) {
            return { definitionId: value.key, definitionName: value.text };
          }

          return pa;
        });
      } else if (value && !definition) {
        // add new association
        updatedProcessAssociations = [
          ...prevStateProcessAssociations,
          { definitionId: value.key, definitionName: value.text }
        ];
      } else if (!value && definition) {
        // delete association
        updatedProcessAssociations = prevStateProcessAssociations.filter(
          ({ definitionId }) => definitionId !== definition.id
        );
      }

      return { ...prevState, processAssociations: updatedProcessAssociations };
    });

    setDisplayEmptyRow(false);
  }

  function renderFieldSelector(definition) {
    if (!definition) {
      setTimeout(() => comboBoxRef.current?.focus(true), 100);
    }

    const options = definitions.map((d) => {
      return {
        key: d.id,
        text: d.name,
        hidden: processAssociations.some((pa) => pa.definitionId === d.id)
      };
    });

    return (
      <ComboBox
        allowFreeform
        autoComplete="on"
        disabled={disabled}
        componentRef={comboBoxRef}
        key={1}
        onChange={(e, value) => {
          onProcessAssociationChange({ value, definition });
        }}
        options={options}
        selectedKey={definition?.id}
        styles={{
          optionsContainerWrapper: { maxHeight: 500 },
          container: { width: '100%' },
          input: {
            ':after': { border: '1px solid #d3d3d3' },
            position: 'relative',
            bottom: '2px'
          },
          root: { ':after': { border: '1px solid #d3d3d3', borderRadius: 4 } },
          inputDisabled: { color: '#323130' },
          // rootDisabled: { ':after': { borderRadius: 4 }, backgroundColor: '#ffffff' },
          rootFocused: { ':after': { borderRadius: 4 } }
        }}
      />
    );
  }

  function deleteProcessAssociation(definition) {
    if (!definition) {
      setDisplayEmptyRow(false);

      return null;
    }

    const { id } = definition;

    setProject((prevState) => {
      return {
        ...prevState,
        processAssociations: prevState.processAssociations.filter(
          ({ definitionId }) => definitionId !== id
        )
      };
    });

    return null;
  }

  function renderDeleteIconButton(definition) {
    return (
      <IconButton
        iconProps={{ iconName: 'Cancel' }}
        disabled={disabled}
        onClick={() => deleteProcessAssociation(definition)}
        styles={{
          root: { margin: 'auto 4px 0px 4px' },
          rootDisabled: { backgroundColor: 'transparent' },
          icon: { fontWeight: '600', fontSize: '16px' },
          iconDisabled: { color: '#cccccc' }
        }}
      />
    );
  }

  function renderDefinitionRow(definition) {
    return (
      <div key={definition?.id} className="c-process-container">
        <ProcessContainerStyled>
          {renderFieldSelector(definition)}
          {renderDeleteIconButton(definition)}
        </ProcessContainerStyled>
      </div>
    );
  }

  function renderDefinitionRows() {
    return (
      <StartableDefinitionsStyled hasItems={processAssociations.length > 0 || displayEmptyRow}>
        {processAssociations.map(({ definitionId }, index) => {
          const definition = definitions?.find((def) => def.id === definitionId);

          return renderDefinitionRow(definition, index);
        })}

        {displayEmptyRow && renderDefinitionRow()}
      </StartableDefinitionsStyled>
    );
  }

  if (status === 'loading') {
    return (
      <div style={{ height: '100%' }}>
        <LoadingSpinner label={t('globals.loading', { label: '' })} />
      </div>
    );
  }

  if (status === 'no-definitions') {
    return (
      <ProjectTabStyled>
        <MessageBar>{t('projectPanel.processes.noTemplates')}</MessageBar>
      </ProjectTabStyled>
    );
  }

  return (
    <ProjectTabStyled>
      <VisibilitySettings disabled={disabled} project={project} setProject={setProject} />
      <div style={{ marginTop: 15 }} />
      <SettingsContainer
        styles={{ width: '100%' }}
        fullContentWidth
        defaultExpanded={project.processAssociations?.length > 0}
        label={t('projectPanel.processes.startableProcessesHeader')}
        iconName="LinkedDatabase"
        description={t('projectPanel.processes.description', { context: 'startable' })}
      >
        {renderDefinitionRows()}
        {!disabled && renderAddProcessButton()}
      </SettingsContainer>
      {pages?.length > 0 && (
        <>
          <div style={{ marginTop: 15 }} />
          <PagesSettingContainer
            pages={pages}
            description={t('projectPanel.processes.pagesDescription')}
            selectedPagesIds={project.includedPages}
            onChange={(selectedPagesIds) => {
              setProject((prevState) => ({ ...prevState, includedPages: selectedPagesIds }));
            }}
          />
        </>
      )}
    </ProjectTabStyled>
  );
}

ProjectProcessesPivotItem.propTypes = {
  disabled: PropTypes.bool,
  project: projectPropType.isRequired,
  setProject: PropTypes.func.isRequired
};

function VisibilitySettings({ disabled, project, setProject }) {
  const { t } = useTranslation();

  return (
    <SettingsContainer
      label={t('projectPanel.processes.visibility.header')}
      description={
        <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
          <span>{t('projectPanel.processes.visibility.description')}</span>
          <span>{t('projectPanel.processes.visibility.description2')}</span>
        </div>
      }
      iconName="Permissions"
      defaultExpanded
    >
      <Toggle
        checked={project.confidential}
        styles={{ root: { marginTop: 20 }, text: { fontSize: 13 } }}
        onText={t('projectPanel.processes.visibility.checkbox')}
        offText={t('projectPanel.processes.visibility.checkbox')}
        disabled={disabled}
        onChange={(_, checked) =>
          setProject((prevState) => ({ ...prevState, confidential: checked }))
        }
      />
    </SettingsContainer>
  );
}

VisibilitySettings.propTypes = {
  disabled: PropTypes.bool,
  project: projectPropType.isRequired,
  setProject: PropTypes.func.isRequired
};

export default ProjectProcessesPivotItem;
