import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Callout } from '@fluentui/react';

export interface RichTextEditorSuggestionsProps {
  children: React.ReactNode;
  onDismiss: () => void;
}

function RichTextEditorSuggestions({
  children,
  onDismiss
}: RichTextEditorSuggestionsProps): JSX.Element | null {
  const childrenWrapperRef = useRef<HTMLInputElement>(null);

  const [suggestionsDimensions, setSuggestionsDimensions] = useState({ width: 0, height: 0 });

  const [position, setPosition] = useState({ left: 0, top: 0 });

  const getProtalPosition = useCallback((): { left: number; top: number } => {
    const rowHeight = 25;
    const { height, width } = suggestionsDimensions;

    const selection = window?.getSelection();

    if (!selection?.focusNode) return { left: 0, top: 0 };
    const tempRange = selection.getRangeAt(0).cloneRange();

    if (!tempRange) return { left: 0, top: 0 };
    const offSet = tempRange.endOffset || 0;
    if (offSet < 0) return { left: 0, top: 0 };

    tempRange.setStart(tempRange.endContainer, offSet);
    const rect = tempRange.getBoundingClientRect();

    let left = rect.left + window.pageXOffset;
    let top = rect.top + window.pageYOffset + rect.height;

    top = height ? top - height - rowHeight : top;

    const paddingRight = 5;
    const { innerWidth } = window;

    if (left + width > innerWidth) {
      left = innerWidth - width - paddingRight;
    }

    if (left === 0 && top === 0) {
      const textField = document.activeElement;

      if (textField) {
        const rect = textField.getBoundingClientRect();
        return {
          left: rect.left + window.pageXOffset,
          top: rect.bottom + window.pageYOffset
        };
      }
    }

    return { left, top };
  }, [suggestionsDimensions]);

  useLayoutEffect(() => {
    if (childrenWrapperRef.current) {
      setSuggestionsDimensions({
        width: childrenWrapperRef.current?.offsetWidth || 0,
        height: childrenWrapperRef.current?.offsetHeight || 0
      });
    }
  }, []);

  useEffect(() => {
    const { left, top } = getProtalPosition();

    setPosition({ left, top });
  }, [getProtalPosition]);

  return (
    <Callout
      gapSpace={5}
      onDismiss={onDismiss}
      hidden={false}
      target={{
        x: position.left,
        y: position.top
      }}
    >
      <div ref={childrenWrapperRef}>{children}</div>
    </Callout>
  );
}

export default RichTextEditorSuggestions;
