/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  GroupedList,
  PrimaryButton,
  SelectionMode
} from '@fluentui/react';

const DialogStyled = styled(Dialog)`
  .ms-Dialog-subText {
    margin-bottom: 8px;
  }
`;

function DeleteMandatoryFieldsDialog({
  defaultButtonFunc,
  deleteType,
  hidden,
  mandatoryFields,
  onDismiss,
  primaryButtonFunc
}) {
  const { t } = useTranslation();
  const [groupdDetailsListProps, setGroupdDetailsListProps] = useState({ groups: [], steps: [] });

  useEffect(() => {
    const steps = [];
    mandatoryFields.map((field) => {
      if (field) {
        return field.steps.map((step) =>
          steps.push({ ...step, fieldName: field.fieldName, fieldId: field.id })
        );
      }
    });

    const compareComments = (a, b) => {
      if (a.fieldId < b.fieldId) return -1;
      if (a.fieldId > b.fieldId) return 1;
      return 0;
    };

    const sortedSteps = steps.sort(compareComments);
    const groups = sortedSteps.reduce((currentGroups, currentItem, index) => {
      const lastGroup = currentGroups[currentGroups.length - 1];
      const fieldValue = currentItem.fieldId;

      if (!lastGroup || lastGroup.value !== fieldValue) {
        currentGroups.push({
          key: `group${fieldValue}${index}`,
          name: currentItem.fieldName,
          value: fieldValue,
          startIndex: index,
          level: 0,
          count: 0,
          isCollapsed: false
        });
      }
      if (lastGroup) {
        lastGroup.count = index - lastGroup.startIndex;
      }
      return currentGroups;
    }, []);

    // Fix last group count
    const lastGroup = groups[groups.length - 1];

    if (lastGroup) {
      lastGroup.count = sortedSteps.length - lastGroup.startIndex;
    }

    setGroupdDetailsListProps({
      groups,
      steps
    });
  }, [mandatoryFields]);

  function getMandatoryFieldInformation(step) {
    return (
      <div style={{ padding: '12px 0 12px 48px', borderBottom: '1px solid rgba(50,49,48,0.3)' }}>
        {step.name}
      </div>
    );
  }

  function getTitle() {
    if (deleteType === 'step') {
      return {
        title: t('deleteMandatoryField.dialog.title.step'),
        subTitle: t('deleteMandatoryField.dialog.subTitle.step')
      };
    }
    if (deleteType === 'dataPersonSection') {
      return {
        title: t('deleteMandatoryField.dialog.title.dataPersonSection'),
        subTitle: t('deleteMandatoryField.dialog.subTitle.dataPersonSection')
      };
    }
    if (deleteType === 'section') {
      return {
        title: t('deleteMandatoryField.dialog.title.section'),
        subTitle: t('deleteMandatoryField.dialog.subTitle.section')
      };
    }
    if (deleteType === 'fieldLink') {
      return {
        title: t('deleteMandatoryField.dialog.title.fieldLink'),
        subTitle: t('deleteMandatoryField.dialog.subTitle.fieldLink')
      };
    }
    if (deleteType === 'subProcess') {
      return {
        title: t('deleteMandatoryField.dialog.title.subProcess'),
        subTitle: t('deleteMandatoryField.dialog.subTitle.subProcess')
      };
    }
  }

  return (
    <DialogStyled
      hidden={hidden}
      onDismiss={() => onDismiss()}
      styles={{
        main: [
          {
            selectors: {
              '@media (max-width: 480px)': {
                width: '95%'
              },
              '@media (min-width: 650px)': {
                width: '100%',
                minWidth: '550px',
                minHeight: '250px'
              }
            }
          }
        ]
      }}
      dialogContentProps={{
        styles: { innerContent: { maxHeight: '500px', overflowY: 'auto' } },
        type: DialogType.normal,
        title: getTitle().title,
        subText: getTitle().subTitle
      }}
    >
      <GroupedList
        items={groupdDetailsListProps.steps}
        selectionMode={SelectionMode.none}
        groups={groupdDetailsListProps.groups}
        onRenderCell={(_, step) => {
          return getMandatoryFieldInformation(step);
        }}
      />

      <DialogFooter>
        <PrimaryButton
          onClick={() => primaryButtonFunc()}
          text={t('deleteMandatoryField.dialog.button.primary')}
        />
        <DefaultButton
          onClick={() => defaultButtonFunc()}
          text={t('deleteMandatoryField.dialog.button.cancel')}
        />
      </DialogFooter>
    </DialogStyled>
  );
}

DeleteMandatoryFieldsDialog.propTypes = {
  defaultButtonFunc: PropTypes.func.isRequired,
  deleteType: PropTypes.string.isRequired,
  hidden: PropTypes.bool,
  mandatoryFields: PropTypes.arrayOf(
    PropTypes.shape({
      fieldName: PropTypes.string,
      steps: PropTypes.arrayOf(
        PropTypes.shape({ name: PropTypes.string, id: PropTypes.string, type: PropTypes.number })
      )
    })
  ).isRequired,
  onDismiss: PropTypes.func.isRequired,
  primaryButtonFunc: PropTypes.func.isRequired
};

DeleteMandatoryFieldsDialog.defaultProps = {
  hidden: true
};

export default DeleteMandatoryFieldsDialog;
