import { useTranslation } from 'react-i18next';
import { IContextualMenuProps, IconButton } from '@fluentui/react';

interface IRatingResultContentProps {
  showDetails: boolean;
  setShowDetails: (state: boolean) => void;
}

function RatingResultContent({
  showDetails,
  setShowDetails
}: IRatingResultContentProps): JSX.Element | null {
  const { t } = useTranslation();

  function getOverflowIconButtonMenuItems(): IContextualMenuProps['items'] {
    const menuItems: IContextualMenuProps['items'] = [
      {
        key: 'show-details',
        text: showDetails
          ? t('survey.results.button.details.hide')
          : t('survey.results.button.details.show'),
        iconProps: { iconName: showDetails ? 'BackToWindow' : 'FullScreen' },
        onClick: () => setShowDetails(!showDetails)
      }
    ];

    return menuItems;
  }

  return (
    <IconButton
      styles={{
        rootHovered: { backgroundColor: '#E1E1E1' },
        root: {
          height: '25px',
          backgroundColor: '#e1e1e1bf'
        },
        menuIcon: {
          fontSize: '15px',
          fontWeight: '600'
        }
      }}
      className="c-showdetails-overflow-button"
      menuIconProps={{ iconName: 'More' }}
      menuProps={{ items: getOverflowIconButtonMenuItems() }}
    />
  );
}

export default RatingResultContent;
