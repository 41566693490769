import { LoadingSpinner } from 'components';
import NoAccessContainer from 'pages/NoAccess';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import fetchRequest, { apiErrorHandler } from 'services/api';
import styled from 'styled-components';
import { userPropType } from 'types';
import { isInIframe } from 'utils/helpers';
import UserCountsTable from '../components/UserCountsTable';

const UserCountsAdminStyled = styled.div`
  margin-top: 2px;
  height: calc(100vh - ${() => (isInIframe() ? '138px' : '80px')});
  flex-shrink: 0;
  overflow: auto;
`;

function UserCountsAdministration({ currentUser }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [userCounts, setUserCounts] = useState(null);

  const loadItems = useCallback(async () => {
    const url = `User/Count`;
    const userCounts = [];
    const counts = await fetchRequest({ url }).catch(apiErrorHandler);
    if (counts) {
      userCounts.push(counts);
    }
    setUserCounts(userCounts);
    setIsLoading(false);
    return null;
  }, []);

  useEffect(() => {
    setIsLoading(true);
    loadItems();
  }, [loadItems]);

  if (!currentUser?.isAdmin) {
    return <NoAccessContainer />;
  }

  if (isLoading) {
    return <LoadingSpinner label={t('loading.userCounts.text')} />;
  }

  return (
    <UserCountsAdminStyled>
      {!isLoading && userCounts ? <UserCountsTable rows={userCounts} /> : null}
    </UserCountsAdminStyled>
  );
}

UserCountsAdministration.propTypes = {
  currentUser: userPropType.isRequired
};

export default UserCountsAdministration;
