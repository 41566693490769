import {
  AGENT_VERSION,
  AGENTS,
  AgentIncludes,
  IAgentLatest,
  makeApiObjectKey,
  useApiDataCache,
  useApiObject
} from 'hooks/api2';
import { createContext, useCallback, useContext, useMemo } from 'react';
import { apiErrorHandler } from 'services/api';
import { fetchAIVoid } from 'services/api2';

const AgentsContext = createContext({
  agents: [] as IAgentLatest[],
  isLoading: false
} as {
  agents: IAgentLatest[];
  isLoading: boolean;
  deleteAgent: (id: string) => void;
  replaceOrAddAgent: (agent: IAgentLatest) => void;
});

export const useAgentsContext = () => {
  const { agents, deleteAgent, replaceOrAddAgent } = useContext(AgentsContext);

  return { agents, deleteAgent, replaceOrAddAgent };
};

export function AgentsProvider({ children }: { children: React.ReactNode }) {
  const cache = useApiDataCache();

  const {
    data: agentsData,
    isLoading,
    mutate
  } = useApiObject(AGENTS, {}, { includes: [AgentIncludes.AuthorizedTeams] });

  const deleteAgent = useCallback(
    (id: string) => {
      const promise = fetchAIVoid(
        {
          url: `agents/${id}`,
          method: 'DELETE'
        },
        { addUserId: true }
      ).then(() => {
        return ({ items }) => ({ items: items.filter((chat) => chat.id !== id) });
      });

      mutate(promise, ({ items }) => ({
        items: items.filter((chat) => chat.id !== id)
      })).promise.catch(apiErrorHandler);
    },
    [mutate]
  );

  const replaceOrAddAgent = useCallback(
    (agent: IAgentLatest) => {
      const appObj = cache.getObject<typeof AGENT_VERSION>(
        makeApiObjectKey(AGENT_VERSION, { id: agent.id })
      );
      appObj.mutate(Promise.resolve(agent), {});

      mutate(
        Promise.resolve(({ items }) => ({
          items: items
            .map((c) => (c.id === agent.id ? agent : c))
            .concat(items.some((c) => c.id === agent.id) ? [] : [agent])
        })),
        {}
      ).promise.catch(apiErrorHandler);
    },
    [cache, mutate]
  );

  const value = useMemo(() => {
    return {
      isLoading,
      agents: agentsData?.items || [],
      replaceOrAddAgent,
      deleteAgent
    };
  }, [agentsData?.items, isLoading, replaceOrAddAgent, deleteAgent]);

  return <AgentsContext.Provider value={value}>{children}</AgentsContext.Provider>;
}
