import Label from 'components/inputs/Label';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';
import {
  IFieldLinkProps,
  IOutboundFieldProps,
  IProcessInstanceProps,
  RouteInstanceStatus
} from 'types';
import { formatDate } from 'utils/helpers';
import {
  IButtonStyles,
  Icon,
  IconButton,
  Persona,
  PersonaSize,
  PrimaryButton,
  ProgressIndicator,
  Spinner,
  SpinnerSize,
  Stack,
  TooltipHost,
  TooltipOverflowMode
} from '@fluentui/react';
import { DeleteAbortInstanceDialog } from '../../../features/ProcessInstances';
import {
  ApiObjectVariant,
  IListMutationItem,
  IProcessInstanceForList,
  IProcessInstanceGroup,
  PROCESS_INSTANCE,
  PROCESS_INSTANCES,
  createApiObjectDataFromPlainObject,
  deriveApiObjectVariantId,
  makeApiObjectVariantKey,
  mutate,
  useApiDataCache,
  useApiList
} from '../../../hooks/api2';
import { fetchVoid } from '../../../services/api2';
import StartProcessInstancePanel from '../../surfaces/StartProcessInstancePanel/StartProcessInstancePanel';
import { ChecklistInstanceGroupStyled } from './Checklist.styles';

export interface IStartableSubProcess {
  id: string;
  serviceId?: string;
  outboundFields?: IOutboundFieldProps[];
  name: string;
}

export interface IChecklistInstanceGroupProps {
  /** Parent task (mutually exclusive with projectId) */
  task?: {
    routeInstanceId?: string;
    routeStepId?: string;
    projectId?: string;
    id?: string;
    groups?: { fields: IFieldLinkProps[] }[];
  };
  /** Parent project (mutually exclusive with task) */
  projectId?: string;
  templateId: string;
  processGroup?: IProcessInstanceGroup;
  processStart?: IStartableSubProcess;
  onOpenInstance: (id: string) => void;
  /** If true, we should be linking to test processes instead. */
  isTest?: boolean;
  disabled?: boolean;
}

const MAX_ITEMS = 200;

export default function ChecklistInstanceGroup({
  task,
  projectId,
  templateId,
  processGroup,
  processStart,
  onOpenInstance,
  isTest = false,
  disabled = false
}: IChecklistInstanceGroupProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  const [isStarting, setIsStarting] = useState(false);

  const { items, totalItems, data, isLoading, isValidating, mutate } = useApiList(
    PROCESS_INSTANCES,
    (() => {
      if (task) {
        return {
          statuses: [
            RouteInstanceStatus.NotStarted,
            RouteInstanceStatus.Started,
            RouteInstanceStatus.InProgress,
            RouteInstanceStatus.Completed,
            RouteInstanceStatus.AbortedBySubProcess,
            RouteInstanceStatus.AbortedByUser
          ],
          itemsPerPage: MAX_ITEMS,
          parentInstanceId: task.routeInstanceId,
          parentProjectId: task.projectId,
          parentTaskId: task.id,
          definitionId: templateId
        };
      }

      if (projectId) {
        return {
          statuses: [
            RouteInstanceStatus.NotStarted,
            RouteInstanceStatus.Started,
            RouteInstanceStatus.InProgress,
            RouteInstanceStatus.Completed,
            RouteInstanceStatus.AbortedBySubProcess,
            RouteInstanceStatus.AbortedByUser
          ],
          itemsPerPage: MAX_ITEMS,
          parentProjectId: projectId,
          definitionId: templateId
        };
      }

      return null;
    })()
  );
  const template = data?.definition;

  const validItemCount = items.filter(
    (item) =>
      item.status !== RouteInstanceStatus.AbortedByUser &&
      item.status !== RouteInstanceStatus.AbortedBySubProcess
  ).length;

  function renderProgressBar() {
    const finishedItemsCount = items?.filter(
      (item) => item.status === RouteInstanceStatus.Completed
    ).length;
    const percentComplete = finishedItemsCount > 0 ? finishedItemsCount / validItemCount : 0;

    return (
      <Stack
        horizontal
        styles={{
          root: {
            borderBottom: `1px solid rgb(${theme.taskDetailsChecklist.instanceGroupDivider})`,
            backgroundColor: `rgb(${theme.taskDetailsChecklist.instanceGroupBackground})`
          }
        }}
      >
        <Label
          styles={{
            container: { minWidth: 'fit-content', marginLeft: 5 },
            fluentLabel: { root: { fontSize: '12px', fontWeight: 400 } }
          }}
          label={`${t('checklist.completed')} ${finishedItemsCount}/${validItemCount}`}
        />
        <ProgressIndicator
          className="c-checklist_progress-indicator"
          percentComplete={percentComplete}
          barHeight={4}
        />
      </Stack>
    );
  }

  const cache = useApiDataCache();

  function onInstanceCreated({
    closePanel,
    createdInstance
  }: {
    closePanel?: boolean;
    createdInstance: IProcessInstanceProps;
  }) {
    if (closePanel) setIsStarting(false);
    if (createdInstance) {
      // the instance panel doesn't use the cache, so we need to insert instance data into the cache first
      const key = makeApiObjectVariantKey(PROCESS_INSTANCE, createdInstance.id);
      cache.insertObjectData(createApiObjectDataFromPlainObject(cache, key, createdInstance));

      mutate(Promise.resolve(), (list) => {
        const id = deriveApiObjectVariantId(key);
        return [
          {
            key: makeApiObjectVariantKey(
              PROCESS_INSTANCE,
              createdInstance.id,
              ApiObjectVariant.Small
            ),
            id
          } as IListMutationItem<string>
        ].concat(list);
      });
    }
  }

  // !1289 used useMemo to cache the default links because on android gets into an infinite loop on startForm
  const cachedDefaultLinks = useMemo(
    () =>
      task?.groups
        ?.map((group) => {
          return group.fields;
        })
        ?.flat(),
    [task?.groups]
  );

  if (!task && !projectId) return null;

  return (
    <ChecklistInstanceGroupStyled>
      <div className="c-checklist_instance-group-wrapper">
        <div className="c-checklist_instance-group-header">
          <div style={{ fontSize: 20, paddingLeft: 5 }}>
            {processGroup?.name ?? processStart?.name}
            {isValidating ? (
              <Spinner
                style={{ display: 'inline-block', marginLeft: '0.5em', verticalAlign: 'center' }}
                size={SpinnerSize.small}
              />
            ) : null}
          </div>
          {processStart && (
            <PrimaryButton
              styles={{
                root: { marginBottom: 5, borderRadius: 4 }
              }}
              onClick={() => setIsStarting(true)}
              iconProps={{ iconName: 'Play' }}
              disabled={disabled || totalItems >= MAX_ITEMS}
            >
              {t('globals.new')}
            </PrimaryButton>
          )}
        </div>
        <div>{renderProgressBar()}</div>
        {items.map((instance) => (
          <CheckListProcessInstance
            key={instance.id}
            instance={instance}
            onOpenInstance={onOpenInstance}
            isTest={isTest}
          />
        ))}
        {isLoading ? <Spinner style={{ margin: '0.5em' }} /> : null}
      </div>

      {isStarting && (
        <StartProcessInstancePanel
          preventRedirect
          disabled={isTest}
          isOpen={isStarting}
          template={template}
          outboundFields={processStart.outboundFields}
          onInstanceCreated={onInstanceCreated}
          parentInstanceId={task?.routeInstanceId}
          parentProjectId={task?.projectId ?? projectId}
          parentTaskId={task?.id}
          defaultFieldLinks={cachedDefaultLinks}
          parentStepId={task?.routeStepId}
          parentServiceId={processStart.serviceId}
          onClosePanel={() => setIsStarting(false)}
        />
      )}
    </ChecklistInstanceGroupStyled>
  );
}

function CheckListProcessInstance({
  instance,
  onOpenInstance,
  isTest
}: {
  instance: IProcessInstanceForList;
  onOpenInstance: (id: string) => void;
  isTest: boolean;
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [isAborting, setIsAborting] = useState(false);

  const isAborted =
    instance.status === RouteInstanceStatus.AbortedByUser ||
    instance.status === RouteInstanceStatus.AbortedBySubProcess;

  function getOverflowButtonStyles(): IButtonStyles {
    return {
      root: {
        backgroundColor: `rgb(${theme.taskDetailsChecklist.itemControlHoverBackground})`,
        borderRadius: 4
      },
      rootExpanded: { visibility: 'visible !important', opacity: '1 !important' },
      rootHovered: {
        backgroundColor: `rgb(${theme.taskDetailsChecklist.itemControlHover2Background})`
      }
    };
  }

  function getOverflowMenuItems(id: string) {
    return [
      {
        key: 'openTask',
        text: t('checklist.overflowButton.openInstance'),
        iconProps: { iconName: 'FullScreen' },
        onClick: () => onOpenInstance(id)
      },
      instance.permissions?.abort
        ? {
            key: 'abortInstance',
            text: t('checklist.overflowButton.abortInstance'),
            iconProps: { iconName: 'Cancel' },
            onClick: () => setIsAborting(true)
          }
        : null
    ].filter((x) => x);
  }

  function renderSecondaryText(instance: IProcessInstanceForList) {
    const infoParts = [
      instance.creationDate
        ? `${t('routeInstances.column.created')} ${formatDate(
            new Date(instance.creationDate),
            'L'
          )}`
        : null
    ];

    if (!isAborted && instance.currentEndDate) {
      infoParts.push(
        `${t('routeInstances.column.currentEndDateShort')}: ${formatDate(
          new Date(instance.currentEndDate),
          'L'
        )}`
      );
    }

    if (!isAborted && instance.currentStepName) {
      infoParts.push(`${t('routeInstances.column.currentTaskShort')}: ${instance.currentStepName}`);
    }

    const textContent = infoParts.join(' - ');

    return (
      <TooltipHost overflowMode={TooltipOverflowMode.Parent} content={textContent}>
        {textContent}
      </TooltipHost>
    );
  }

  const onAbortInstance = () => {
    const mut = mutate(
      instance,
      fetchVoid({
        url: `Route/Instance/Abort/${instance.id}`,
        method: 'POST'
      }).then(() => ({ status: RouteInstanceStatus.AbortedByUser })),
      {}
    );
    return mut.promise.then(() => undefined);
  };

  return (
    <div
      key={instance.id}
      className="c-checklist_instance-container c-checklist_item-row c-checklist_item-row-instance"
    >
      <Stack
        className="c-checklist_item c-checklist_item-instance"
        styles={{ root: { width: '100%', paddingLeft: '2px' } }}
      >
        <Link
          className="c-checklist_item-title-instance"
          to={`/process-instances/all${
            isTest ? '-test' : ''
          }/00000000-0000-0000-0000-000000000000/process-instance-tree/${instance.id}`}
        >
          {`#${instance.intId}  ${instance.name}`}
        </Link>
        <div style={{ margin: '7px 0px 2px 0px' }}>
          <Persona
            hidePersonaDetails={false}
            imageUrl={instance.creator?.pictureUrl}
            size={PersonaSize.size24}
            showSecondaryText
            onRenderSecondaryText={() => renderSecondaryText(instance)}
            text={instance.creator?.name}
          />
        </div>
        <div className={`c-checklist_progress-indicator-instance ${isAborted ? 'is-aborted' : ''}`}>
          <ProgressIndicator
            className="c-progress-bar"
            percentComplete={isAborted ? 0 : instance.percentComplete}
            barHeight={4}
          />
          {isAborted ? <Icon iconName="Cancel" className="c-aborted-icon" /> : null}
        </div>
      </Stack>
      <IconButton
        className="c-checklist_item-overflowbutton"
        menuIconProps={{ iconName: 'More' }}
        title={t('globals.more')}
        styles={getOverflowButtonStyles()}
        menuProps={{ items: getOverflowMenuItems(instance.id) }}
      />
      {isAborting ? (
        <DeleteAbortInstanceDialog
          type="abort"
          instance={instance}
          abortInstance={onAbortInstance}
          deleteInstance={() => {
            // not used
          }}
          onDismiss={() => setIsAborting(false)}
        />
      ) : null}
    </div>
  );
}
