import { ILabelProps, Spinner, SpinnerLabelPosition, SpinnerSize } from '@fluentui/react';

export interface ILoadingSpinnerProps {
  /**
   * Wheneher to center the spinner
   */
  center?: boolean;
  /**
   * Additional class name for the spinner
   */
  className?: string;
  /**
   * Text for the label
   */
  label?: string;
  /**
   * Possible locations of the label in regards to the spinner
   */
  labelPosition?: SpinnerLabelPosition;
  /**
   * The size of Spinner to render.
   */
  size?: SpinnerSize;
  /**
   * Call to provide customized styling that will layer on top of the variant rules.
   */
  styles?: {
    label?: ILabelProps['styles'];
    container?: React.CSSProperties;
  };
}

function LoadingSpinner({
  center = true,
  className,
  label,
  labelPosition,
  size,
  styles
}: ILoadingSpinnerProps): JSX.Element | null {
  function getContainerStyles() {
    if (center) {
      return {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        ...styles?.container
      };
    }

    return styles?.container;
  }

  return (
    <div className={className} style={getContainerStyles()}>
      <Spinner
        size={size === undefined ? SpinnerSize.large : size}
        styles={{ label: styles?.label }}
        label={label}
        labelPosition={labelPosition || 'bottom'}
      />
    </div>
  );
}

export default LoadingSpinner;
