import { LoadingSpinner, LongTextField } from 'components';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { columnPropType } from 'types';
import { formatDate } from 'utils/helpers';
import {
  ContextualMenu,
  ContextualMenuItemType,
  DefaultButton,
  DirectionalHint,
  Icon,
  getTheme
} from '@fluentui/react';
import ContextualFilterMenuFilterOption from './ContextualFilterMenuFilterOption';
import ContextualFilterMenuSearchBox from './ContextualFilterMenuSearchBox';

const LongTextFieldWrapper = styled.div`
  max-height: 16px;

  * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 0px;
  }
`;
function ContextualFilterMenu({
  column,
  onDismiss,
  filterType,
  onFilterChange,
  onSortingChange,
  target,
  getContextualFilterMenuProps
}) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [filterOptions, setFilterOptions] = useState([]);
  const [filteredFilterOptions, setFilteredFilterOptions] = useState([]);

  const showFilterOptions =
    column.id === 'default-column-createdBy' ||
    (column.id === 'default-column-progress' && filterType === 'completed') ||
    column.id === 'default-column-currentTask' ||
    (!column.id.includes('default-column-') &&
      column.fieldType !== 10 &&
      column.fieldType !== 9 &&
      column.fieldType !== 2);

  const getFilterText = useCallback(
    (option, key) => {
      if (key === 'dateValues') {
        return formatDate(option, 'DD.MM.YYYY');
      }

      if (key === 'boolValues') {
        return option
          ? t('contextualMenu.option.boolValues.true')
          : t('contextualMenu.option.boolValues.false');
      }

      if (column.id === 'default-column-progress') {
        if (option === 4) {
          return t('taskPanelDetail.status.label.5');
        }

        if (option === 5) {
          return t('contextualMenu.filter.abortedBySubProcess');
        }

        return t('contextualMenu.filter.abortedByUser');
      }

      return option;
    },
    [column.id, t]
  );

  const createFilterOptions = useCallback(
    (filterProps) => {
      const options = [];
      const { hasEmptyValue } = filterProps;

      if (filterProps) {
        Object.keys(filterProps).map((key) => {
          if (key === 'hasEmptyValue') {
            return null;
          }

          // user
          if (key === 'userValues') {
            filterProps[key].map((user) =>
              options.push({
                key: user.userId,
                text: user.name,
                checked: false
              })
            );
            return null;
          }

          // links
          if (key === 'linkValues') {
            filterProps[key].map((link) =>
              options.push({
                key: link.url,
                text: link.text || link.url,
                checked: false
              })
            );
            return null;
          }

          // date
          if (key === 'dateValues') {
            filterProps[key].map((value) =>
              options.push({
                key: value,
                text: formatDate(value, 'DD.MM.YYYY'),
                checked: false
              })
            );
            return null;
          }

          // text
          if (key === 'lookupValues') {
            filterProps[key].map((value) => {
              return options.push({
                key: value.id,
                text: value.value,
                checked: false
              });
            });

            return null;
          }

          // text
          if (key === 'textValues') {
            filterProps[key].map((value) => {
              const isLongTextField = value.includes('<p>');
              const isAppendChangesLongTextField = value && value.includes('CurrentValue');

              if (isAppendChangesLongTextField) {
                return null;
              }

              return options.push({
                key: value,
                text: isLongTextField ? (
                  <LongTextFieldWrapper>
                    <LongTextField
                      styles={{ backgroundColor: 'none', color: '#323130', padding: 0 }}
                      defaultValue={value}
                      disabled
                    />
                  </LongTextFieldWrapper>
                ) : (
                  value
                ),
                checked: false
              });
            });

            return null;
          }

          filterProps[key].map((option) =>
            options.push({
              key: option,
              text: getFilterText(option, key),
              checked: false
            })
          );

          return null;
        });
      }

      if (hasEmptyValue) {
        options.push({
          key: 'emptyValue',
          text: t('contextualMenu.option.empty'),
          checked: false
        });
      }
      setIsLoading(false);
      setFilterOptions(options);
    },
    [getFilterText, t]
  );

  const getFilterProps = useCallback(async () => {
    let filterField = null;
    let filterType = 100;

    if (filterType === 100) {
      filterField = column.id;
    }

    if (column.id === 'default-column-createdBy') {
      filterType = 2;
    }

    if (column.id === 'default-column-currentTask') {
      filterType = 4;
    }

    if (column.id === 'default-column-progress') {
      filterType = 5;
    }

    const filterProps = await getContextualFilterMenuProps(filterType, filterField);

    createFilterOptions(filterProps);
  }, [column.id, createFilterOptions, getContextualFilterMenuProps]);

  useEffect(() => {
    if (showFilterOptions) {
      getFilterProps();
    }
  }, [showFilterOptions, getFilterProps]);

  function onClearFilterOptions() {
    onFilterChange({
      ...column,
      filterValues: [],
      isFiltered: false
    });
  }

  function onFilterOptionChange(option) {
    let filterValues = [...column.filterValues];
    const doesFilterValueAlreadyExists = filterValues.includes(option.key);

    let isFiltered = false;

    if (option.checked) {
      isFiltered = true;

      // Commented until API is enabled to filter on multiple values
      // filterValues.push(option.key);
      filterValues = [option.key];
    } else if (doesFilterValueAlreadyExists) {
      // Commented until API is enabled to filter on multiple values
      // filterValues = filterValues.filter((value) => value !== option.key);
      filterValues = [];
    }

    if (filterValues.length) {
      isFiltered = true;
    }

    onFilterChange({
      ...column,
      filterValues,
      isFiltered
    });
  }

  function getContextualMenuOptions(option) {
    if (option.key === 'noResults') {
      return option;
    }
    return {
      key: option.key,
      text: option.text,
      onRender: () => (
        <ContextualFilterMenuFilterOption
          option={{ ...option, checked: column.filterValues.includes(option.key) }}
          onFilterOptionChange={onFilterOptionChange}
        />
      )
    };
  }

  function onFilterOptionsSearchChange(ev, newValue) {
    const filteredItems = filterOptions.filter(
      (item) =>
        item.text && item.text.toString().toLowerCase().indexOf(newValue.toLowerCase()) !== -1
    );

    if (!filteredItems || !filteredItems.length) {
      filteredItems.push({
        key: 'noResults',
        onRender: () => (
          <div
            key="noResults"
            style={{
              alignItems: 'center',
              display: 'flex',
              height: '100px',
              justifyContent: 'center',
              width: '100%'
            }}
          >
            <Icon iconName="SearchIssue" title={t('contextualMenu.filterSearch.noItemsFound')} />
            <span>{t('contextualMenu.filterSearch.noItemsFound')}</span>
          </div>
        )
      });
    }
    setFilteredFilterOptions(filteredItems);
  }

  let items = [
    {
      canCheck: true,
      checked: column.isSorted && column.isSortedDescending,
      iconProps: { iconName: 'Ascending' },
      key: 'aToZ',
      name: t('contextualMenu.sort.ascending'),
      onClick: () => onSortingChange(column, true)
    },
    {
      canCheck: true,
      checked: column.isSorted && !column.isSortedDescending,
      iconProps: { iconName: 'Descending' },
      key: 'zToA',
      name: t('contextualMenu.sort.descending'),
      onClick: () => onSortingChange(column, false)
    },
    {
      key: 'divider_1',
      itemType: ContextualMenuItemType.Divider
    }
  ];

  if (showFilterOptions) {
    const filterSearchBox = {
      key: 'searchBox',
      onRender: () => (
        <ContextualFilterMenuSearchBox onFilterOptionsSearchChange={onFilterOptionsSearchChange} />
      )
    };

    const clearFilterButton = {
      key: 'clearFilter',
      onRender: () => (
        <div style={{ borderBottom: '1px solid #eaeaea' }}>
          <DefaultButton
            text={t('contextualMenu.filter.clear')}
            disabled={!column.isFiltered}
            iconProps={{ iconName: 'clearFilter' }}
            styles={{
              root: { border: 'none', height: '36px', width: '100%', paddingLeft: '28px' },
              label: { float: 'left', fontWeight: '400' },
              icon: {
                color: getTheme().palette.themePrimary
              }
            }}
            onClick={onClearFilterOptions}
          />
        </div>
      )
    };

    if (isLoading) {
      const isLoadingContainer = {
        key: 'loading',
        onRender: () => (
          <div
            key="loading"
            style={{
              alignItems: 'center',
              display: 'flex',
              height: '100px',
              justifyContent: 'center',
              width: '100%'
            }}
          >
            <LoadingSpinner
              className="loading-spinner"
              label={t('contextualMenu.loading.options')}
            />
          </div>
        )
      };
      items = [...items, isLoadingContainer];
    } else if (filteredFilterOptions.length > 0) {
      items = [
        ...items,
        filterSearchBox,
        clearFilterButton,
        ...filteredFilterOptions.map((option) => getContextualMenuOptions(option))
      ];
    } else if (filterOptions) {
      items = [
        ...items,
        filterSearchBox,
        clearFilterButton,
        ...filterOptions.map((option) => getContextualMenuOptions(option))
      ];
    }
  }

  return (
    <ContextualMenu
      directionalHint={DirectionalHint.bottomLeftEdge}
      gapSpace={10}
      isBeakVisible
      items={items}
      onDismiss={onDismiss}
      styles={{ root: { width: '300px', maxHeight: '700px' } }}
      target={target}
    />
  );
}

ContextualFilterMenu.propTypes = {
  column: columnPropType.isRequired,
  filterType: PropTypes.number,
  onDismiss: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onSortingChange: PropTypes.func.isRequired,
  getContextualFilterMenuProps: PropTypes.func.isRequired,
  target: PropTypes.instanceOf(Element).isRequired
};

export default ContextualFilterMenu;
