import CodeScannerField from 'components/inputs/CodeScannerField';
import Label from 'components/inputs/Label';
import LoadingSpinner from 'components/progress/LoadingSpinner';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DateTimeFieldFormat, IFieldProps, RouteFieldType } from 'types';
import {
  ConstrainMode,
  DetailsListLayoutMode,
  DetailsRow,
  Callout as FluentCallout,
  IColumn,
  IDetailsRowProps,
  ITextField,
  Icon,
  IconButton,
  SpinnerSize,
  TextField,
  getTheme
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { DetailsListStyled, IntegrationWrapper } from './IntegrationField.styles';
import { ExternalDataFieldSelectMode } from '../../../hooks/api2';

export interface IIntegrationFieldProps {
  id?: string;
  /**
   * Indicates whether it is possible to scan a QR code
   * @defaultvalue false
   */
  allowScan?: boolean;
  dataSelectMode?: ExternalDataFieldSelectMode;
  /**
   * Id of the selected data
   */
  defaultValue?: string;
  /**
   * Description of the lookup field.
   */
  description?: string;
  /**
   * Optional flag to mark the lookupfield as readOnly
   * @defaultvalue false
   */
  disabled?: boolean;
  /**
   * Field ids of the fields to be displayed
   */
  externalServiceId?: string;
  /**
   * Gets the full instance with all fields that are listed in displayFieldIds
   */
  getDefinition?: ({ id }: { id: string }) => Promise<IExternalServiceProps>;
  /**
   * Gets the full instance with all fields that are listed in displayFieldIds
   */
  getDefinitionExternalData?: ({
    parameterString
  }: {
    parameterString: string;
  }) => Promise<IServiceDataResult>;
  /**
   * A label for the lookup field
   */
  label?: string;
  /**
   * The icon that will be displayed to the left of the label
   */
  labelIconName?: string;
  /**
   * Callback issued when an instance is selected
   */
  onChange?: (selectedInstanceId?: string) => void;
  /**
   * A callback for what should happen when a user types text into the input.
   */
  onSearch?: ({ id, search }: IDataProps) => Promise<ISearchResultProps[]>;
  /**
   * Whether the associated form field is required or not
   * @defaultvalue false
   */
  required?: boolean;
  renderSelectedData?: (selectedData: IServiceDataProps[]) => JSX.Element;
}

export interface IDataProps {
  id: string;
  search?: string;
}

export interface IExternalServiceProps {
  serviceData?: {
    listConnection: {
      allItemsUrl?: string;
    };
    connection: {
      queryFields: { internalName: string }[];
    };
  };
}

export interface ISearchResultProps {
  values: IServiceDataProps[];
  debugInfo: null | {
    error?: string;
  };
}

export interface IServiceDataResult {
  fieldValues: IServiceDataProps[];
}

export interface IServiceDataProps {
  value?: string | number | string[] | number[] | { [key: string]: string };
  internalName: string;
  field: IFieldProps;
  key: string;
  title: string;
}

function IntegrationField(integrationFieldProps: IIntegrationFieldProps): JSX.Element {
  const {
    id,
    allowScan = false,
    dataSelectMode = 1,
    defaultValue,
    description,
    disabled = false,
    externalServiceId,
    getDefinition,
    getDefinitionExternalData,
    label,
    renderSelectedData,
    labelIconName,
    onChange,
    onSearch,
    required = false
  } = integrationFieldProps;

  const { t } = useTranslation();

  const [searchResult, setSearchResult] = useState<ISearchResultProps[] | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const textFieldRef = useRef<ITextField>(null);

  const [selectedId, setSelectedId] = useState<string | undefined | null>(defaultValue);
  const [selectedData, setSelectedData] = useState<IServiceDataProps[] | null>(null);

  const [initialized, setinitialized] = useState<boolean>(false);
  const [disabledOnFocusSearch, setDisabledOnFocusSearch] = useState(false);

  const [externalService, setExternalService] = useState<IExternalServiceProps | null>(null);

  const selectionMode = dataSelectMode === ExternalDataFieldSelectMode.SelectFromList;
  const newMode = dataSelectMode === ExternalDataFieldSelectMode.OnlyNewValue;

  const [isValidValue, setIsValidValue] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<{ search: boolean; selectedItem: boolean }>({
    search: false,
    selectedItem: !!defaultValue
  });

  const getDefinitionDataCallback = useCallback(() => {
    const requestName = externalService?.serviceData?.connection?.queryFields?.[0]?.internalName;

    const parameterString = `${externalServiceId}?${requestName}=${encodeURIComponent(selectedId)}`;

    if (requestName && getDefinitionExternalData) {
      getDefinitionExternalData({ parameterString })
        .then((result) => {
          setSelectedData(result.fieldValues);
          setIsLoading((prevState) => ({ ...prevState, selectedItem: false }));
        })
        .catch(() => {
          setIsLoading((prevState) => ({ ...prevState, selectedItem: false }));
        });
    }
  }, [
    externalService?.serviceData?.connection?.queryFields,
    externalServiceId,
    getDefinitionExternalData,
    selectedId
  ]);

  useEffect(() => {
    if (selectionMode && selectedId && isLoading.selectedItem) {
      getDefinitionDataCallback();
    }
  }, [selectionMode, getDefinitionDataCallback, isLoading.selectedItem, selectedId]);

  useEffect(() => {
    if (newMode && isLoading.selectedItem) {
      setIsLoading((prevState) => ({ ...prevState, selectedItem: false }));
    }
  }, [newMode, isLoading.selectedItem]);

  useEffect(() => {
    // useEffect to get definition on initialization
    if (!initialized && getDefinition && externalServiceId) {
      setinitialized(true);

      getDefinition({ id: externalServiceId }).then((fetchedExternalService) => {
        setExternalService(fetchedExternalService);
      });
    }
  }, [externalServiceId, getDefinition, initialized]);

  const textFieldHookId = useId('search-textfield');
  const textFieldId = id ? `input-${id}` : textFieldHookId;

  function checkExactSearchResultMatch(searchTerm: string, result: ISearchResultProps[]) {
    let exactMatchCount = 0;
    let exactMatchResultKey;

    result.forEach((resultRow) => {
      resultRow.values.forEach((columnValue) => {
        if (columnValue.value === searchTerm) {
          exactMatchCount += 1;
          exactMatchResultKey = columnValue.key;
        }
      });
    });

    if (exactMatchCount === 1) {
      setSelectedId(exactMatchResultKey);
      setIsLoading((prevState) => ({ ...prevState, selectedItem: true }));

      if (onChange) onChange(exactMatchResultKey);
    } else {
      setSearchResult(result);
    }

    setIsLoading((prevState) => ({ ...prevState, search: false }));
  }

  const onTextFieldChange = debounce((filterText?: string | null) => {
    if (filterText && onSearch && externalServiceId) {
      setIsLoading((prevState) => ({ ...prevState, search: true }));
      setErrorMessage(undefined);
    }

    if (dataSelectMode === ExternalDataFieldSelectMode.SelectFromList) {
      // if the user is in selection mode, we want to search and display the results in a dropdown
      if (filterText && onSearch && externalServiceId) {
        onSearch({ id: externalServiceId, search: filterText }).then((result) => {
          const resultError = result[0]?.debugInfo?.error;

          if (resultError) {
            setErrorMessage(resultError);
            setIsLoading((prevState) => ({ ...prevState, search: false }));
          } else {
            checkExactSearchResultMatch(filterText, result);
          }
        });
      }

      setSearchResult(null);
    }
  }, 800);

  function onTextFieldFocus() {
    if (disabledOnFocusSearch) return;

    if (dataSelectMode === ExternalDataFieldSelectMode.SelectFromList) {
      const currentTextFieldValue = textFieldRef.current?.value;

      if (
        !currentTextFieldValue &&
        onSearch &&
        externalServiceId &&
        externalService?.serviceData?.listConnection?.allItemsUrl
      ) {
        setErrorMessage(undefined);
        setIsLoading((prevState) => ({ ...prevState, search: true }));

        onSearch({ id: externalServiceId })
          .then((result) => {
            const resultError = result[0]?.debugInfo?.error;

            if (resultError) {
              setErrorMessage(resultError);
            } else {
              setSearchResult(result);
            }

            setIsLoading((prevState) => ({ ...prevState, search: false }));
          })
          .catch(() => setDisabledOnFocusSearch(true));

        setSearchResult(null);
      }
    }
  }

  function onRenderRow(props?: IDetailsRowProps): JSX.Element | null {
    if (props) {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <DetailsRow {...props} styles={{ root: { cursor: 'pointer' } }} />;
    }

    return null;
  }

  function onRowClick(selectedItem: IServiceDataProps) {
    if (dataSelectMode === ExternalDataFieldSelectMode.SelectFromList) {
      setSearchResult(null);

      setSelectedId(selectedItem.key);
      setIsLoading((prevState) => ({ ...prevState, selectedItem: true }));

      if (onChange) onChange(selectedItem.key);
    }
  }

  function getTextWidth(value: IServiceDataProps['value']): number {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    const text = Array.isArray(value) ? value.join(', ') : value?.toString();

    const font = `${getTheme().fonts.medium.fontSize} ${getTheme().fonts.medium.fontFamily}px`;

    if (context && text) {
      context.font = font || getComputedStyle(document.body).font;

      return Math.ceil(context.measureText(text).width);
    }

    return 80;
  }

  function getLongestColumnValue(title: string, internalName: string): IServiceDataProps['value'] {
    const allColumnValues: IServiceDataProps['value'][] = [];

    if (!searchResult) {
      return '';
    }

    searchResult.forEach((result) => {
      const columnValue = result.values.find((value) => value.internalName === internalName);

      if (columnValue?.value) {
        allColumnValues.push(columnValue.value);
      }
    });

    if (allColumnValues[0] && Array.isArray(allColumnValues[0])) {
      allColumnValues.push([title]);
    } else {
      allColumnValues.push(title);
    }

    const longest = allColumnValues.reduce((a, b) => {
      if (!a || !b) {
        return a || b;
      }

      if (Array.isArray(a) && Array.isArray(b)) {
        return a.join(', ').length > b.join(', ').length ? a : b;
      }

      return a.toString().length > b.toString().length ? a : b;
    });

    return longest;
  }

  function getMaxColumnWidth(title: string, internalName: string) {
    let maxWidth = 50;

    const longestColumnValue = getLongestColumnValue(title, internalName);

    if (longestColumnValue) {
      maxWidth = getTextWidth(longestColumnValue) + 5;
    }

    if (maxWidth > 200) {
      maxWidth = 200;
    }

    return maxWidth;
  }

  function renderCallout(): JSX.Element | null {
    let calloutContent: JSX.Element | null = null;

    if (dataSelectMode === ExternalDataFieldSelectMode.OnlyNewValue) {
      return null;
    }

    if (isLoading.search) {
      calloutContent = (
        <LoadingSpinner
          styles={{ container: { padding: 20 } }}
          label={t('integrationField.loading')}
          size={SpinnerSize.large}
          labelPosition="bottom"
        />
      );
    }

    if (searchResult?.length) {
      const columns: IColumn[] = [];
      const items: { [key: string]: unknown }[] = [];

      searchResult[0].values.forEach((resultValue) => {
        const { internalName, title } = resultValue;

        columns.push({
          fieldName: internalName,
          key: internalName,
          minWidth: 15,
          maxWidth: getMaxColumnWidth(title, internalName),
          isResizable: true,
          name: title
        });
      });

      searchResult.forEach((result) => {
        const detailsListItem: { [key: string]: unknown } = {
          internalName: '',
          key: '',
          title: ''
        };

        result.values.forEach((resultValue: IServiceDataProps) => {
          const { internalName, key } = resultValue;

          detailsListItem[internalName] = onRenderValue(resultValue);

          if (!detailsListItem.key) {
            detailsListItem.key = key;
          }
        });

        items.push(detailsListItem);
      });

      calloutContent = (
        <DetailsListStyled
          compact
          checkboxVisibility={2}
          onRenderRow={onRenderRow}
          onActiveItemChanged={onRowClick}
          items={items}
          styles={{
            headerWrapper: {
              position: 'sticky',
              top: 0,
              zIndex: 100
            }
          }}
          columns={columns}
          layoutMode={DetailsListLayoutMode.fixedColumns}
          constrainMode={ConstrainMode.unconstrained}
        />
      );
    }

    if (!calloutContent) return null;

    const textFieldElement = document.getElementById(textFieldId);
    const textFieldElementWidth = textFieldElement?.getBoundingClientRect().width;
    return (
      <FluentCallout
        isBeakVisible={false}
        directionalHint={4}
        onDismiss={() => {
          setIsLoading((prevState) => ({ ...prevState, search: false }));
          setSearchResult(null);
        }}
        gapSpace={2}
        calloutMaxHeight={400}
        styles={{ calloutMain: { overflow: 'auto' } }}
        calloutMinWidth={textFieldElementWidth}
        calloutMaxWidth={textFieldElementWidth || 500}
        target={`#${textFieldId}`}
      >
        {calloutContent}
      </FluentCallout>
    );
  }

  function onRemoveSelectedInstance() {
    setSelectedData(null);
    setSelectedId(null);

    if (onChange) onChange();

    // focus textfield
    setTimeout(() => textFieldRef.current?.focus(), 100);
  }

  function getFieldIconName({ value, field }: IServiceDataProps): string | null {
    const isString = typeof value === 'string';

    if (isString && field?.fieldType === RouteFieldType.DateTime) {
      return 'Calendar';
    }

    if (isString) {
      return 'AlignLeft';
    }

    if (typeof value === 'number') {
      return 'NumberSymbol';
    }

    return null;
  }

  function onRenderResultListIconColum(item: IServiceDataProps): JSX.Element | null {
    const iconName = getFieldIconName(item);

    if (iconName) {
      return <Icon iconName={iconName} styles={{ root: { marginTop: 2 } }} />;
    }

    return null;
  }

  function onRenderResultListNameColum(item: IServiceDataProps): JSX.Element {
    return <div>{item.title}</div>;
  }

  function getDateTime(date: string | null, displayTime: boolean): string | null {
    if (!date) {
      return null;
    }

    if (displayTime) {
      return `${moment(date).format('L')}, ${moment(date).format('LT')}`;
    }

    return moment(date).format('L');
  }

  function onRenderValue(item: IServiceDataProps): JSX.Element[] | JSX.Element | string {
    const { value, field } = item;

    const defaultValue = <div>-</div>;

    const isString = typeof value === 'string';

    if (field?.fieldType === RouteFieldType.DateTime && isString) {
      const displayTime = field.dateFormat === DateTimeFieldFormat.DateAndTime;

      return getDateTime((value as string) || null, displayTime) || defaultValue;
    }

    if (isString) {
      return <div>{value as string}</div> || defaultValue;
    }

    if (typeof value === 'number') {
      return <div>{value as number}</div> || defaultValue;
    }

    if (Array.isArray(value)) {
      // return all values separated by comma
      return <div>{value.join(', ')}</div>;
    }

    // check if value is an object with url and text
    if (value && typeof value === 'object' && value.url) {
      return (
        <Link target="_blank" to={value.url}>
          {value.text}
        </Link>
      );
    }

    return defaultValue;
  }

  /*
   * check if the given value already exists in the search results
   * and if so return a error message
   */
  function onGetErrorMessage(value?: string): Promise<string> {
    if (dataSelectMode === ExternalDataFieldSelectMode.OnlyNewValue) {
      setIsValidValue(false);

      // first reset current value
      if (onChange) onChange();

      if (value && onSearch && externalServiceId) {
        return onSearch({ id: externalServiceId, search: value }).then((result) => {
          setIsLoading((prevState) => ({ ...prevState, search: false }));
          let isEmptySearchResult = result && result.length === 0;

          // for admins there is one item with debugInfo
          const adminResultCheck = result?.length === 1 && result[0].values?.length === 0;

          if (!isEmptySearchResult && adminResultCheck) {
            isEmptySearchResult = true;
          }

          if (isEmptySearchResult) {
            // search result is empty so the given value is valid/new and can be used
            if (onChange) onChange(value);
            setIsValidValue(true);

            return '';
          }

          return t('integrationField.errorInvalidValue', { value });
        });
      }
    }

    return new Promise((resolve) => {
      resolve('');
    });
  }

  function renderInputTextField() {
    let textField = null;

    // styles for the checkmark && loading spinner
    let iconContainerStyles = {};

    if (allowScan) {
      iconContainerStyles = {
        position: 'absolute',
        right: 50,
        bottom: 8
      };

      textField = (
        <CodeScannerField
          defaultValue={newMode ? defaultValue : undefined}
          disabled={disabled}
          onChange={onTextFieldChange}
          deferredValidationTime={800}
          errorMessage={errorMessage}
          onGetErrorMessage={onGetErrorMessage}
          placeholder={t('integrationField.placeholder', {
            context: disabled ? 'disabled' : undefined
          })}
          onFocus={onTextFieldFocus}
          textFieldId={textFieldId}
          textFieldRef={textFieldRef}
        />
      );
    } else {
      iconContainerStyles = { position: 'absolute', right: 5, bottom: 8 };

      textField = (
        <TextField
          defaultValue={newMode ? defaultValue : undefined}
          autoComplete="off"
          componentRef={textFieldRef}
          disabled={disabled}
          placeholder={t('integrationField.placeholder', {
            context: disabled ? 'disabled' : undefined
          })}
          validateOnLoad={false}
          onGetErrorMessage={onGetErrorMessage}
          errorMessage={errorMessage}
          deferredValidationTime={800}
          id={textFieldId}
          styles={{
            fieldGroup: [
              { border: '1px solid #a19f9d', borderRadius: 3, ':after': { borderRadius: 3 } }
            ],
            wrapper: disabled ? { border: '1px solid #a19f9d', borderRadius: 3 } : undefined,
            field: { color: '#323130' }
          }}
          onFocus={onTextFieldFocus}
          onChange={(_, newValue) => onTextFieldChange(newValue)}
        />
      );
    }

    return (
      <div style={{ position: 'relative' }}>
        {textField}
        {isValidValue && (
          <Icon
            styles={{
              root: {
                ...iconContainerStyles,
                color: 'green',
                fontSize: '17px',
                transition: 'transform .2s ease-in-out'
              }
            }}
            iconName="Checkmark"
          />
        )}
        {isLoading.search && (
          <LoadingSpinner
            styles={{ container: { ...iconContainerStyles, height: undefined, width: undefined } }}
            size={SpinnerSize.small}
          />
        )}
      </div>
    );
  }

  if (isLoading.selectedItem) {
    return (
      <IntegrationWrapper>
        <Label
          required={required}
          iconName={labelIconName}
          label={label}
          description={description}
        />
        <LoadingSpinner
          styles={{ container: { margin: 10 } }}
          label={t('integrationField.loading')}
          size={SpinnerSize.large}
          labelPosition="bottom"
        />
      </IntegrationWrapper>
    );
  }

  if (selectedData) {
    if (renderSelectedData) return renderSelectedData(selectedData);

    const columns: IColumn[] = [
      {
        isResizable: false,
        key: 'icon',
        minWidth: 20,
        maxWidth: 20,
        name: 'Icon',
        onRender: onRenderResultListIconColum,
        isIconOnly: true
      },
      {
        fieldName: 'title',
        isResizable: true,
        key: 'title',
        onRender: onRenderResultListNameColum,
        minWidth: 80,
        maxWidth: 140,
        name: 'Name'
      },
      {
        fieldName: 'value',
        isResizable: true,
        key: 'value',
        onRender: onRenderValue,
        minWidth: 160,
        name: 'Value'
      }
    ];

    return (
      <IntegrationWrapper>
        <Label
          required={required}
          iconName={labelIconName}
          label={label}
          description={description}
        />
        <div className="c-selected-instance_wrapper">
          <div className="c-selected-instance_label">
            {/* <div className="c-selected-instance_name">{label}</div> */}
            {!disabled && (
              <IconButton
                styles={{ root: { marginTop: '3px', height: '25px' } }}
                iconProps={{
                  styles: { root: { fontSize: '13px' } },
                  iconName: 'CalculatorMultiply'
                }}
                onClick={onRemoveSelectedInstance}
              />
            )}
          </div>
          <DetailsListStyled
            compact
            checkboxVisibility={2}
            items={selectedData || []}
            columns={columns}
          />
        </div>
      </IntegrationWrapper>
    );
  }

  return (
    <IntegrationWrapper id={id}>
      <Label required={required} iconName={labelIconName} label={label} description={description} />
      {renderInputTextField()}
      {renderCallout()}
    </IntegrationWrapper>
  );
}

export default IntegrationField;
