import {
  ChatCompletionsFunctionToolCall,
  ChatRequestAssistantMessage,
  ChatRequestMessageUnion,
  ChatRequestSystemMessage,
  ChatRequestToolMessage,
  ChatRequestUserMessage,
  CompletionsFinishReason,
  OpenAIClient
} from '@azure/openai';
import {
  ChatConnectionStatus,
  ChatHistoryStatus,
  ChatItemType,
  IChatItem,
  IChatItemAssistant,
  IFunctionToolResult,
  ILocalFunctionToolDefinition,
  IMessageFragment,
  IMessageFragmentText,
  MessageFragmentType,
  SystemMessageType
} from './model';
import ChatBackend from './ChatBackend';
import config from '../../config';
import { acquireAccessToken } from '../../features/Authentication';
import { mutateExistingOrAppend } from './utils';
import { Author, IMessageUser } from '../../hooks/api2';
import { generateUuid } from '../../utils/helpers';

/** The assistant may create multiple messages that are technically just one message, just interspersed with tool calls. */
interface IAssistantSubMessageAssistant {
  role: 'assistant';
  id: string;
  text: string;
  toolCalls: ChatCompletionsFunctionToolCall[] | undefined;
}

interface IAssistantSubMessageTool {
  role: 'tool';
  id: string;
  result: IFunctionToolResult;
}

type IAssistantSubMessage = IAssistantSubMessageAssistant | IAssistantSubMessageTool;

/** Connects directly to an OpenAI chat */
export default class DirectChatBackend extends ChatBackend {
  client: OpenAIClient;

  showError: (error: Error) => void;

  toolsRef: { current: ILocalFunctionToolDefinition[] };

  deploymentName = 'gpt-4o';

  constructor(
    showError: (error: Error) => void,
    toolsRef: { current: ILocalFunctionToolDefinition[] },
    systemMessage: string
  ) {
    super();
    this.showError = showError;
    this.toolsRef = toolsRef;

    this.updateOrAppendItem({
      type: ChatItemType.System,
      systemType: SystemMessageType.SystemPrompt,
      id: 'prompt',
      text: systemMessage
    });

    this.historyStatus = ChatHistoryStatus.Idle;
    this.connectionStatus = ChatConnectionStatus.Stateless;

    this.client = new OpenAIClient(new URL(config.AI_DIRECT_URL, window.location.href).href, {
      async getToken() {
        const result = await acquireAccessToken();
        return { token: result.accessToken, expiresOnTimestamp: result.expiresOn.getTime() };
      }
    });

    if (systemMessage) {
      this.dispatchCompletionRequest();
    }
  }

  updateOrAppendItem(item: IChatItem) {
    this.items = mutateExistingOrAppend(
      this.items,
      (i) => i.id === item.id,
      () => item
    );
  }

  currentCompletionRequest: { abort: () => void } | null = null;

  assistantSubMessages = new Map<string, IAssistantSubMessage[]>();

  compileCurrentChatHistory(): ChatRequestMessageUnion[] {
    return this.items.flatMap((item): ChatRequestMessageUnion[] => {
      if (item.type === ChatItemType.System) {
        if (item.systemType === SystemMessageType.SystemPrompt) {
          return [{ role: 'system', content: item.text } as ChatRequestSystemMessage];
        }

        return [];
      }

      if (item.message.author === Author.AI) {
        const subMessages = this.assistantSubMessages.get(item.id);
        if (!subMessages) {
          return [
            {
              role: 'assistant',
              content: item.message.text
            } as ChatRequestAssistantMessage
          ];
        }

        return subMessages.map((message) =>
          message.role === 'assistant'
            ? ({
                role: 'assistant',
                content: message.text,
                toolCalls: message.toolCalls ?? undefined
              } as ChatRequestAssistantMessage)
            : ({
                toolCallId: message.id,
                role: 'tool',
                content: message.result.value
              } as ChatRequestToolMessage)
        );
      }

      if (item.message.author === Author.User) {
        return [{ role: 'user', content: item.message.text } as ChatRequestUserMessage];
      }

      return [];
    });
  }

  isClosed = false;

  dispatchCompletionRequest(
    continuingMessageId: string | null = null,
    continuingCreatedAt: string | null = null
  ) {
    if (this.currentCompletionRequest) return;

    const abort = new AbortController();
    const messageId = continuingMessageId ?? generateUuid();
    const createdAt = continuingCreatedAt ?? new Date().toISOString();
    let completed = false;
    let isFailed = false;

    const assistantSubMessageId = generateUuid();
    let subMessageContent = '';
    const toolCalls: ChatCompletionsFunctionToolCall[] = [];
    const toolCallResults: IAssistantSubMessageTool[] = [];

    this.currentCompletionRequest = { abort: () => abort.abort() };

    const write = () => {
      const assistantSubMessage: IAssistantSubMessage = {
        role: 'assistant',
        id: assistantSubMessageId,
        text: subMessageContent,
        toolCalls: toolCalls.length ? toolCalls : undefined
      };

      const assistantSubMessages = this.assistantSubMessages.get(messageId) ?? [];
      let newAssistantSubMessages = mutateExistingOrAppend(
        assistantSubMessages,
        (i) => i.id === assistantSubMessageId,
        () => assistantSubMessage
      );

      for (const result of toolCallResults) {
        newAssistantSubMessages = mutateExistingOrAppend(
          newAssistantSubMessages,
          (i) => i.id === result.id,
          () => result
        );
      }

      this.assistantSubMessages.set(messageId, newAssistantSubMessages);

      const item: IChatItemAssistant = {
        type:
          completed || isFailed
            ? ChatItemType.AssistantCompleted
            : ChatItemType.AssistantGenerating,
        id: messageId,
        message: {
          id: messageId,
          createdAt,
          updatedAt: new Date().toISOString(),
          author: Author.AI,
          text: assistantSubMessages
            .map((message) => (message.role === 'assistant' ? message.text : null))
            .filter((message) => message)
            .join('\n\n'),
          isFailed,
          files: []
        },
        fragments: assistantSubMessages.flatMap((item): IMessageFragment[] => {
          if (item.role === 'assistant') {
            const items: IMessageFragment[] = [
              {
                id: item.id,
                type: MessageFragmentType.Text,
                text: item.text
              } as IMessageFragmentText
            ];

            for (const toolCall of item.toolCalls ?? []) {
              const result = [...newAssistantSubMessages.values()].find(
                (item) => item.role === 'tool' && item.id === toolCall.id
              ) as IAssistantSubMessageTool;

              if (result) {
                items.push({
                  id: toolCall.id,
                  type: MessageFragmentType.LocalToolCall,
                  toolId: toolCall.function.name,
                  args: toolCall.function.arguments,
                  result: result.result
                });
              } else {
                items.push({
                  id: toolCall.id,
                  type: MessageFragmentType.LocalToolCall,
                  toolId: toolCall.function.name,
                  args: toolCall.function.arguments,
                  result: null
                });
              }
            }

            return items;
          }
          return [];
        })
      };
      this.updateOrAppendItem(item);

      if (completed || isFailed) {
        this.dispatchEvent('remoteMessageCompleted', { message: item.message });
      } else {
        this.dispatchEvent('remoteMessageUpdate', { message: item.message });
      }
    };

    const tools = this.toolsRef.current;

    this.client
      .streamChatCompletions(this.deploymentName, this.compileCurrentChatHistory(), {
        abortSignal: abort.signal,
        tools: tools.length ? tools.map((f) => ({ type: 'function', function: f })) : undefined
      })
      .then(async (stream) => {
        write();

        let finishReason: CompletionsFinishReason | null = null;

        for await (const event of stream) {
          for (const choice of event.choices) {
            finishReason = choice.finishReason;

            const { delta } = choice;
            if (delta) {
              if (delta.content) subMessageContent += delta.content;

              for (const call of delta.toolCalls ?? []) {
                const existing = toolCalls[call.index];
                if (existing) {
                  existing.function.arguments += (
                    call as ChatCompletionsFunctionToolCall
                  ).function.arguments;
                } else {
                  toolCalls[call.index] = call as ChatCompletionsFunctionToolCall;
                }
              }

              write();
            }
          }
        }

        await Promise.all(
          toolCalls.map(async ({ id, function: toolCall }) => {
            const tool = tools.find((tool) => tool.name === toolCall.name);

            try {
              if (!tool) throw new Error(`tool not found`);

              const args: Record<string, unknown> = JSON.parse(toolCall.arguments || '{}');
              if (args && typeof args !== 'object') {
                throw new Error('tool call arguments are not an object');
              }

              toolCallResults.push({
                role: 'tool',
                id,
                result: await tool.run(args)
              });
            } catch (error) {
              toolCallResults.push({
                role: 'tool',
                id,
                result: { value: error?.toString() ?? 'Error', error }
              });
            }
          })
        );

        if (finishReason === 'tool_calls') {
          write();
          this.currentCompletionRequest = null;
          this.dispatchCompletionRequest(messageId, createdAt);
        } else {
          completed = true;
          write();
        }
      })
      .catch((error) => {
        isFailed = true;
        write();

        if (this.isClosed) return;

        // eslint-disable-next-line no-console
        console.error(error);
        this.showError(error.message);
      })
      .finally(() => {
        this.currentCompletionRequest = null;
      });
  }

  appendUserMessage(message: IMessageUser) {
    this.items = [
      ...this.items,
      {
        type: ChatItemType.User,
        id: message.id,
        message,
        fragments: [{ id: message.id, type: MessageFragmentType.Text, text: message.text }]
      }
    ];
    this.dispatchCompletionRequest();
  }

  // eslint-disable-next-line class-methods-use-this
  receiveCompleteAssistantMessage() {
    throw new Error('receiveCompleteAssistantMessage not supported');
  }

  canRetryAssistantMessage(item: IChatItemAssistant): boolean {
    return this.items.at(-1) === item;
  }

  async retryAssistantMessage(item: IChatItemAssistant): Promise<void> {
    if (this.canRetryAssistantMessage(item)) throw new Error('this item cannot be retried');

    const subMessages = this.assistantSubMessages.get(item.id);
    if (!subMessages.length) throw new Error('invalid state');

    // remove last item & retry
    this.assistantSubMessages.set(item.id, subMessages.slice(0, subMessages.length - 1));
    this.dispatchCompletionRequest();
  }

  close() {
    this.isClosed = true;
    this.currentCompletionRequest?.abort();
  }
}
