import { InfoIconTooltip, LoadingSpinner, Panel } from 'components';
import { AppContext } from 'features/App';
import { useBubblesStore } from 'features/App/context/BubblesStore';
import i18n from 'i18next';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import fetchRequest from 'services/api';
import styled from 'styled-components';
import { IBubble, IPath, ITrainer, ITraining, ViewStatus } from 'types';
import { checkScreenWidth } from 'utils/helpers';
import { Checkbox, CommandBarButton, ProgressIndicator } from '@fluentui/react';

const headerHeight = checkScreenWidth(['extraSmall']) ? 0 : 48;

const NotificationsContainer = styled.div`
  padding: 0 5px 0 5px;
  overflow-y: auto;
  height: 100%;
  background-color: rgb(245, 245, 245);

  .youtube-video {
    border-radius: 5px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0.3px 0.9px, rgba(0, 0, 0, 0.13) 0px 1.6px 3.6px;
    margin: 5px 0 5px 0;
    height: 219px;
  }

  .training {
    background-color: white;
    border-radius: 5px;
    border: 1px solid rgb(229, 229, 229);
    overflow: hidden;
    padding: 10px;
    margin: 5px 0 5px 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0.3px 0.9px, rgba(0, 0, 0, 0.13) 0px 1.6px 3.6px;
    font-size: 14px;
    font-weight: 400;
  }

  .training-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .training-title {
    align-items: center;
    display: flex;
    gap: 5px;
  }

  .training-description {
    margin-top: 3px;
    margin-bottom: 7px;
  }

  .start-pause-button {
    height: 30px;
    border-radius: 8px;
    font-size: 13px;
  }

  .trainings-wrapper {
  }

  .ms-ProgressIndicator-progressBar,
  .ms-ProgressIndicator-progressTrack {
    border-radius: 5px;
    overflow: hidden;
  }

  .ms-Checkbox-checkbox {
    border-color: #cccccc;
    border-radius: 50%;
    height: 20px;
    width: 20px;
  }

  .is-checked .ms-Checkbox-checkbox {
    background-color: ${(props) => props.theme.themePrimary};
  }

  .ms-Checkbox-label:hover * {
    border-color: #b4b4b4;
  }

  .description-icon {
    font-size: 13px;
    margin-top: 3px;
  }
`;

interface ITrainerPanelProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

declare global {
  interface Window {
    config: {
      crossApiOrigin?: string;
      trainerTestMode: boolean;
    };
  }
}

const { crossApiOrigin = 'https://befa945a.azurewebsites.net', trainerTestMode } = window.config;

function TrainerPanel({ isOpen, setIsOpen }: ITrainerPanelProps) {
  const { t } = useTranslation('components', { keyPrefix: 'trainer' });

  const trainers = useBubblesStore((state) => state.trainers);
  const setTrainers = useBubblesStore((state) => state.setTrainers);
  const setTrainingStatus = useBubblesStore((state) => state.setTrainingStatus);
  const addActiveBubbleGroups = useBubblesStore((state) => state.addActiveBubbleGroups);
  const activeBubbleGroups = useBubblesStore((state) => state.activeBubbleGroups);
  const deleteActiveBubbleGroup = useBubblesStore((state) => state.deleteActiveBubbleGroup);

  const navigate = useNavigate();

  // Loading states
  const [isLoading, setIsLoading] = useState(true);

  // global app state
  const { globalAppState } = useContext(AppContext);
  const { currentTenantId, currentUser, tenants, teams } = globalAppState;
  const { pathname } = useLocation();

  // a ref of training ids to track for which training the panel is automatically opened
  const trainingIdsToTrack = useRef<string[]>([]);

  const tenant = useMemo(() => {
    return tenants.find((tenant) => tenant.tenantId === currentTenantId);
  }, [currentTenantId, tenants]);

  const trainingsToRender = useMemo(() => {
    return trainers.filter((trainer) => {
      const trainings = trainer.trainings.filter((training) => {
        const { paths } = training;

        const globalRoot = paths.find(({ path }) => path === '/');

        if (globalRoot) {
          return true;
        }

        const match = paths.find(({ path }) => {
          const matchedPath = matchPath(path, pathname);

          return matchedPath;
        });

        return match;
      });

      return trainings.length > 0;
    });
  }, [trainers, pathname]);

  const userId = currentUser?.userId as string;

  const getTrainings = useCallback((): Promise<ITrainer[]> => {
    // check if url contains staging or ui
    const body = { testMode: trainerTestMode, tenant, user: currentUser, language: i18n.language };

    return fetchRequest({
      method: 'POST',
      ignoreAlert: true,
      body: JSON.stringify(body),
      url: 'help/trainers',
      origin: `${crossApiOrigin}/api`
    })
      .then((response) => {
        // add trainerId to each training
        return response.map((trainer: ITrainer) => {
          return {
            ...trainer,
            trainings: trainer.trainings.map((training) => {
              return { ...training, trainerId: trainer.id };
            })
          };
        });
      })
      .catch(() => {
        return [];
      });
  }, [currentUser, tenant]);

  useEffect(() => {
    // this useEffect checks if the user has completed a active training
    // if so, it will remove the training from the activeBubbleGroups
    // and set the training to read
    // and open the trainer panel
    if (activeBubbleGroups?.length) {
      activeBubbleGroups.forEach((bubbleGroup) => {
        if (bubbleGroup.bubbles.every((bubble) => bubble.viewStatus === ViewStatus.read)) {
          deleteActiveBubbleGroup(bubbleGroup.id);
          setIsOpen(true);
          setTrainingStatus(bubbleGroup.id, ViewStatus.read, userId);
        }
      });
    }
  }, [activeBubbleGroups, deleteActiveBubbleGroup, setIsOpen, setTrainingStatus, userId]);

  useEffect(() => {
    if (isLoading && !checkScreenWidth(['extraSmall', 'small']))
      getTrainings().then((trainingsResponse) => {
        setIsLoading(false);

        setTrainers(trainingsResponse);

        trainingsResponse.forEach((trainer) => {
          trainer.trainings.forEach((training) => {
            // add training to store if it is running
            const autoStartTraining = training.viewStatus === ViewStatus.new && training.autoStart;

            if (training.viewStatus === ViewStatus.running || autoStartTraining) {
              addActiveBubbleGroups([
                { id: training.id, bubbles: training.bubbles, trainerId: trainer.id }
              ]);

              if (autoStartTraining) {
                setTrainingStatus(training.id, ViewStatus.running, userId);
              }
            }
          });
        });
      });
  }, [setTrainers, isLoading, getTrainings, addActiveBubbleGroups, setTrainingStatus, userId]);

  useEffect(() => {
    if (trainingsToRender && tenant?.status !== 3) {
      const flatTrainings = trainingsToRender.flatMap((trainer) => trainer.trainings);

      const openTrainings = flatTrainings.filter(
        (training) =>
          training.viewStatus === ViewStatus.new &&
          !training.autoStart &&
          !trainingIdsToTrack.current.includes(training.id)
      );

      if (openTrainings.length) {
        setIsOpen(true);

        openTrainings.forEach((training) => {
          trainingIdsToTrack.current.push(training.id);
        });
      }
    }
  }, [trainingsToRender, tenant, pathname, setIsOpen]);

  function onCheckboxChange(training: ITraining) {
    const viewStatus =
      training.viewStatus !== ViewStatus.read ? ViewStatus.read : ViewStatus.running;
    setTrainingStatus(training.id, viewStatus, userId, true);

    if (viewStatus === ViewStatus.running) {
      addActiveBubbleGroups([
        { id: training.id, bubbles: training.bubbles, trainerId: training.trainerId as string }
      ]);

      navigateToTraining(training);

      setIsOpen(false);
    } else {
      deleteActiveBubbleGroup(training.id);
    }
  }

  function getFirstOpenBubble(training: ITraining): IBubble {
    const openBubble = training.bubbles.find(({ viewStatus }) => viewStatus !== ViewStatus.read);

    if (openBubble) {
      return openBubble;
    }

    return training.bubbles[0];
  }

  function createUrlFromPath(path?: IPath): string | null {
    if (!path) return null;

    let url = path.path;

    Object.keys(path.params).forEach((param) => {
      if (path.params[param].length > 0) {
        url = url.replace(`:${param}`, path.params[param][0]);
      }
    });

    return url;
  }

  function getStartPathUrl(path?: IPath): string | null {
    if (path?.path === '/teams/:teamId' && teams?.length) {
      return `/teams/${teams[0].id}`;
    }

    return createUrlFromPath(path);
  }

  function navigateToTraining(training: ITraining): void {
    const bubble = getFirstOpenBubble(training);

    const firstOpenBubbleIsFirstBubble = bubble && bubble.id === training.bubbles[0]?.id;

    let url;

    if (training.startPath && firstOpenBubbleIsFirstBubble) {
      url = getStartPathUrl(training.startPath);
    } else {
      url = createUrlFromPath(bubble.startPath);
    }

    if (url && !url.includes('/:')) {
      navigate(url);
    }
  }

  function onStartPauseClick(training: ITraining) {
    const started = training.viewStatus === ViewStatus.running;
    const newStatus = started ? ViewStatus.deferred : ViewStatus.running;
    setTrainingStatus(training.id, newStatus, userId);

    if (newStatus === ViewStatus.running) {
      addActiveBubbleGroups([
        { id: training.id, bubbles: training.bubbles, trainerId: training.trainerId as string }
      ]);

      navigateToTraining(training);

      setIsOpen(false);
    } else {
      deleteActiveBubbleGroup(training.id);
    }
  }

  function getPercentComplete(training: ITraining): number {
    const totalBubbles = training.bubbles.length;
    const readBubbles = training.bubbles.filter(
      (bubble) => bubble.viewStatus === ViewStatus.read
    ).length;

    return readBubbles / totalBubbles;
  }

  function getStartPauseButtonText(training: ITraining) {
    if (training.viewStatus === ViewStatus.new || training.viewStatus === ViewStatus.read) {
      return t('start');
    }

    if (training.viewStatus === ViewStatus.running) {
      return t('pause');
    }

    return t('resume');
  }

  function renderTraining(training: ITraining) {
    const started = training.viewStatus === ViewStatus.running;
    const percentComplete = getPercentComplete(training);

    return (
      <div key={training.title} className="training">
        <div className="training-header">
          <div className="training-title">
            <Checkbox
              checked={training.viewStatus === ViewStatus.read}
              onChange={() => onCheckboxChange(training)}
            />
            {training.title}
            <InfoIconTooltip content={training.description} iconClassName="description-icon" />
          </div>
          <div>
            <CommandBarButton
              className="start-pause-button"
              styles={{ root: { background: 'transparent' } }}
              iconProps={{ iconName: started ? 'Pause' : 'Play' }}
              text={getStartPauseButtonText(training)}
              onClick={() => onStartPauseClick(training)}
            />
            {/* <IconButton
              className="c-checklist_item-overflowbutton"
              menuIconProps={{ iconName: 'More' }}
              styles={{ root: { borderRadius: 4 }, rootHovered: { backgroundColor: '#eeeeee' } }}
              menuProps={{
                items: [
                  {
                    key: 'pause',
                    iconProps: { iconName: 'Pause' },
                    onClick: () => console.log(1)
                  }
                ]
              }}
            /> */}
          </div>
        </div>
        <ProgressIndicator percentComplete={percentComplete} barHeight={5} />
      </div>
    );
  }

  function renderTrainer(trainer: ITrainer) {
    return <div className="trainings-wrapper">{trainer.trainings.map(renderTraining)}</div>;
  }

  function getPanelContent() {
    if (isLoading) {
      return (
        <LoadingSpinner
          styles={{ container: { margin: 'auto', width: '50%' } }}
          label={t('loading')}
        />
      );
    }

    const videoId = i18n.language === 'de' ? 'ge0ay95YwrU' : 'PF3ocdG8DZc';

    return (
      <NotificationsContainer data-is-scrollable="true">
        <div className="youtube-video">
          <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/${videoId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="YouTube video player"
          />
        </div>
        {trainingsToRender?.map(renderTrainer)}
      </NotificationsContainer>
    );
  }

  return (
    <Panel
      headerText={t('title')}
      isBlocking={false}
      styles={{
        footer: { height: '40px', textAlign: 'center' },
        footerInner: { padding: 'auto', paddingTop: 0 },
        headerText: { color: 'white', padding: '10px 0px' },
        navigation: {
          color: 'white',
          flexDirection: 'row',
          boxShadow: 'rgb(21 27 38 / 15%) 0px 1px 2px'
        },
        closeButton: { display: 'block', marginTop: 8 },
        root: { marginTop: `${headerHeight}px` }
      }}
      onRenderBody={() => getPanelContent()}
      isOpen={isOpen}
      type={7}
      customWidth="400px"
      onDismiss={() => {
        setIsOpen(false);
      }}
    />
  );
}

export default TrainerPanel;
