import Label from 'components/inputs/Label';
import { ComponentProps, Suspense, lazy, useRef, useState } from 'react';
import { checkScreenWidth } from 'utils/helpers';
import { DeepPartial } from '@fluentui/merge-styles';
import {
  IButtonStyles,
  ITextField,
  ITextFieldStyleProps,
  ITextFieldStyles,
  IconButton,
  TextField
} from '@fluentui/react';
import ModalStyled from './CodeScannerField.styles';

const QrReader = lazy(() =>
  import('react-qr-reader').then((module) => ({
    default: function WrappedComponent(props: ComponentProps<typeof module.QrReader>) {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <module.QrReader {...props} />;
    }
  }))
);

export interface ICodeScannerFieldProps {
  id?: string;
  /**
   * Defaultvalue for the CodeScannerField
   */
  defaultValue?: string;
  /**
   * Description for the CodeScannerField
   */
  description?: string;
  /**
   * Text field will start to validate after users stop typing for `deferredValidationTime` milliseconds.
   * Updates to this prop will not be respected.
   */
  deferredValidationTime?: number;
  /**
   * A label for the CodeScannerField
   */
  label?: string;
  /**
   * Function used to determine whether the input value is valid and get an error message if not.
   * Mutually exclusive with the static string `errorMessage` (it will take precedence over this).
   *
   * When it returns `string | JSX.Element`:
   * - If valid, it returns empty string.
   * - If invalid, it returns the error message and the text field will
   *   show a red border and show an error message below the text field.
   *
   * When it returns `Promise<string | JSX.Element>`:
   * - The resolved value is displayed as the error message.
   * - If rejected, the value is thrown away.
   */
  onGetErrorMessage?: (
    value: string
  ) => string | JSX.Element | PromiseLike<string | JSX.Element> | undefined;
  /**
   * The icon that will be displayed to the left of the label
   */
  labelIconName?: string;
  errorMessage?: string;
  onFocus?: () => void;
  /**
   * Callback issued when the state changes.
   */
  onChange?: (data?: string | null) => void;
  /**
   * specifies a short hint that describes the expected value of an input field (e.g. a sample value or a short description of the expected format).
   */
  placeholder?: string;
  /**
   * Optional flag to mark CodeScannerField as readOnly / disabled
   * @defaultvalue false
   */
  disabled: boolean;
  /**
   * Whether the associated form field is required or not
   * @defaultvalue false
   */
  required?: boolean;
  /**
   * Optional callback to access the ITextField component. Use this instead of ref for accessing
   * the public methods and properties of the component.
   */
  textFieldRef?: React.RefObject<ITextField>;
  /**
   * id for the textfield
   */
  textFieldId?: string;
}

function CodeScannerField({
  id,
  defaultValue,
  description,
  disabled = false,
  deferredValidationTime,
  onGetErrorMessage,
  errorMessage,
  label,
  labelIconName,
  onFocus,
  onChange,
  placeholder,
  required = false,
  textFieldId,
  textFieldRef
}: ICodeScannerFieldProps): JSX.Element {
  // data string from BarcodeScannerComponent
  const [data, setData] = useState<string | undefined>(defaultValue);
  const [scannerReady, setScannerReady] = useState(false);
  const [scannerError, setScannerError] = useState<DOMException | null>(null);

  const scannerRef = useRef<HTMLDivElement>(null);

  // display / open camera
  const [streaming, setStreaming] = useState<boolean>(false);

  function renderScannerIconButton() {
    return (
      <IconButton
        iconProps={{ iconName: 'GenericScan' }}
        onClick={() => setStreaming((prevState) => !prevState)}
        disabled={disabled}
        styles={{ root: { height: '30px' } }}
      />
    );
  }

  function onValueChange(newValue?: string | undefined) {
    setData(newValue);

    if (onChange) {
      onChange(newValue);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onBarCodeScannerUpdate(result?: string | null | undefined): void {
    if (result) {
      // reset state
      resetState();

      // set state to match the textfield
      setData(result);

      // call props onchange
      onValueChange(result);
    }
  }

  // function onScannerReady() {
  //   setScannerReady(true);
  // }

  // function onScannerError(error: DOMException) {
  //   setScannerError(error);
  // }

  function resetState() {
    setStreaming(false);
    setScannerReady(false);
    setScannerError(null);
  }

  function getCloseModalButton() {
    const ImagePreviewCloseButtonStyles: IButtonStyles = {
      root: {
        backgroundColor: 'rgb(207, 207, 207, 0.4)',
        color: 'rgb(50, 49, 48);',
        marginRight: 15,
        marginTop: 10,
        position: 'absolute',
        right: 0,
        zIndex: 1000
      },
      rootHovered: { backgroundColor: 'rgb(207, 207, 207, 0.6)', color: 'rgb(32, 31, 30)' },
      rootPressed: { backgroundColor: 'rgb(189, 189, 189, 0.6)', color: 'rgb(32, 31, 30)' }
    };

    return (
      <div style={{ position: 'relative' }}>
        <IconButton
          styles={ImagePreviewCloseButtonStyles}
          iconProps={{ iconName: 'Cancel' }}
          onClick={() => resetState()}
        />
      </div>
    );
  }

  function getModalContent() {
    if (scannerError) {
      return (
        <>
          {getCloseModalButton()}
          <div style={{ margin: 50 }}>{scannerError?.toString()}</div>
        </>
      );
    }

    return (
      <>
        {getCloseModalButton()}
        <div style={{ display: scannerReady ? 'inline' : 'none' }} ref={scannerRef} />
        {/* {!scannerReady && (
          <LoadingSpinner
            styles={{
              container: {
                margin: '120px'
              }
            }}
            size={3}
          />
        )} */}

        <QrReader
          constraints={{ width: 640, height: 480, facingMode: 'environment' }}
          onResult={(result) => {
            if (result) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onBarCodeScannerUpdate(result?.text);
            }
          }}
          containerStyle={{
            width: checkScreenWidth(['extraSmall']) ? '85vw' : '500px',
            height: '100%'
          }}
        />
      </>
    );
  }

  function getTextFieldStyles({ disabled }: ITextFieldStyleProps): DeepPartial<ITextFieldStyles> {
    return {
      fieldGroup: [{ border: '1px solid #a19f9d', borderRadius: 3, ':after': { borderRadius: 3 } }],
      wrapper: disabled ? { border: '1px solid #a19f9d', borderRadius: 3 } : undefined,
      field: { color: '#323130' },
      suffix: { backgroundColor: disabled ? '#f3f2f1' : 'transparent' }
    };
  }

  return (
    <div id={id}>
      <Label description={description} iconName={labelIconName} label={label} required={required} />
      <Suspense fallback={<div />}>
        {streaming && <ModalStyled isOpen={streaming}>{getModalContent()}</ModalStyled>}
        <TextField
          autoComplete="off"
          componentRef={textFieldRef}
          disabled={disabled}
          id={id ? `input-${id}` : textFieldId}
          errorMessage={errorMessage}
          deferredValidationTime={deferredValidationTime}
          onGetErrorMessage={onGetErrorMessage}
          validateOnLoad={false}
          onChange={(_, newValue?: string | undefined) => onValueChange(newValue)}
          onRenderSuffix={renderScannerIconButton}
          placeholder={placeholder}
          styles={getTextFieldStyles}
          onFocus={onFocus}
          value={data || undefined}
        />
      </Suspense>
    </div>
  );
}

export default CodeScannerField;
