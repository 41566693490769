import { ForwardedRef, forwardRef, PropsWithChildren, useContext, useMemo } from 'react';
import {
  classNamesFunction,
  IStyle,
  IStyleFunctionOrObject,
  styled,
  ThemeProvider as FluentThemeProvider,
  ThemeProviderProps
} from '@fluentui/react';
import { DeclaredTheme } from 'styled-components';
import { colorContrast, rgb, Rgb } from './themeUtils';
import { createFluentTheme, getResolvedTheme, getThemeStyles, ThemeConfigContext } from './theme';
import { KEEP_ALL_EXISTING_COLORS_FOR_NOW } from './themeDomains';

/**
 * Creates a fluent ThemeProvider that uses either a light or dark theme depending on the background color.
 */
export default function ContrastingThemeProvider({
  backgroundColor,
  children,
  ...extraProps
}: PropsWithChildren<{ backgroundColor: (theme: DeclaredTheme) => Rgb } & ThemeProviderProps>) {
  const config = useContext(ThemeConfigContext);
  const resolvedTheme = useMemo(() => getResolvedTheme(config), [config]);
  const backgroundColorResolved = useMemo(
    () => backgroundColor(resolvedTheme),
    [resolvedTheme, backgroundColor]
  );

  const isDark = useMemo(() => {
    if (KEEP_ALL_EXISTING_COLORS_FOR_NOW) return false;

    const white = rgb('#fff');
    const black = rgb('#000');

    return colorContrast(backgroundColorResolved, [white, black]) === white;
  }, [backgroundColorResolved]);
  const wasDark = config.dark;

  const newConfig = useMemo(() => ({ ...config, dark: isDark }), [config, isDark]);

  const themeStyles = useMemo(() => {
    if (newConfig.dark === wasDark) return {};
    return getThemeStyles(newConfig);
  }, [newConfig, wasDark]);

  const fluentTheme = useMemo(() => createFluentTheme(newConfig), [newConfig]);

  return (
    <ThemeConfigContext.Provider value={newConfig}>
      <FluentThemeProvider
        as={StyledThemeProviderElement}
        // @ts-expect-error this property exists on StyledThemeProviderElement
        styles={{ root: themeStyles }}
        {...extraProps}
        data-is-dark={isDark}
        theme={fluentTheme}
      >
        {children}
      </FluentThemeProvider>
    </ThemeConfigContext.Provider>
  );
}

interface IThemeProviderElementStyleProps {
  className?: string;
}
interface IThemeProviderElementStyles {
  root: IStyle;
}
type IThemeProviderElementStylesProp = IStyleFunctionOrObject<
  IThemeProviderElementStyleProps,
  IThemeProviderElementStyles
>;

const getClassNames = classNamesFunction<
  IThemeProviderElementStyleProps,
  IThemeProviderElementStyles
>();

function drop(...args: unknown[]) {
  return args;
}

const ThemeProviderElement = forwardRef(function ThemeProviderElement(
  {
    styles,
    children,
    theme,
    applyTo,
    customizerContext,
    ...extra
  }: PropsWithChildren<{
    styles?: IThemeProviderElementStylesProp;
    theme?: unknown;
    applyTo?: unknown;
    customizerContext?: unknown;
    className?: string;
  }>,
  ref: ForwardedRef<HTMLDivElement>
) {
  drop(theme, applyTo, customizerContext);

  const classNames = getClassNames(styles, {});
  return (
    <div ref={ref} {...extra} className={`${extra.className ?? ''} ${classNames.root}`}>
      {children}
    </div>
  );
});

const StyledThemeProviderElement = styled<
  unknown,
  IThemeProviderElementStyleProps,
  IThemeProviderElementStyles
>(ThemeProviderElement, {});
