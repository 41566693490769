import { AppContext } from 'features/App';
import { useContext } from 'react';

interface IIFramePageProps {
  height?: string;
  processId?: string;
  processTemplateId?: string;
  projectId?: string;
  projectTemplateId?: string;
  style?: React.CSSProperties;
  taskId?: string;
  teamId?: string;
  title?: string;
  src: string;
  width?: string;
}

export default function IFramePage({
  height = '100%',
  processId,
  processTemplateId,
  projectId,
  projectTemplateId,
  style,
  taskId,
  teamId,
  title,
  src,
  width = '100%'
}: IIFramePageProps) {
  const { globalAppState } = useContext(AppContext);
  const { currentUser } = globalAppState;

  let fullSrc = src;

  function replaceParam(src, key, value) {
    // replace the value of the key with the new value
    // example: https://productivity.evocom.net?task={taskId}
    // replaceParam(src, 'taskId', '1234') => https://productivity.evocom.net?task=1234

    const regex = new RegExp(`{${key}}`, 'g');
    return src.replace(regex, value);
  }

  if (processId) {
    fullSrc = replaceParam(fullSrc, 'processId', processId);
  }

  if (processTemplateId) {
    fullSrc = replaceParam(fullSrc, 'processTemplateId', processTemplateId);
  }

  if (projectId) {
    fullSrc = replaceParam(fullSrc, 'projectId', projectId);
  }

  if (projectTemplateId) {
    fullSrc = replaceParam(fullSrc, 'projectTemplateId', projectTemplateId);
  }

  if (taskId) {
    fullSrc = replaceParam(fullSrc, 'taskId', taskId);
  }

  if (teamId) {
    fullSrc = replaceParam(fullSrc, 'teamId', teamId);
  }

  if (currentUser.userId) {
    fullSrc = replaceParam(fullSrc, 'userId', currentUser.userId);
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <iframe
        title={title}
        src={fullSrc.toString()}
        height={height}
        width={width}
        style={style ? { border: 'none', ...style } : { border: 'none' }}
      />
    </div>
  );
}
