import { LoadingSpinner, SettingsContainer } from 'components';
import ChecklistInstanceGroup, {
  IStartableSubProcess
} from 'components/lists/Checklist/ChecklistInstanceGroup';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { IProjectProps, ProjectStatus } from 'types';
import { PROCESS_INSTANCES_GROUPED, useApiObject } from '../../../../hooks/api2';
import { InstancesContainerStyled } from '../../../../components/lists/Checklist/Checklist.styles';
import { ShowError } from '../../../../components/ShowError';

export interface IProjectStartedProcessesProps {
  project: IProjectProps;
  disabled?: boolean;
}

const ContainerStyled = styled.div`
  width: 100%;
  min-height: 100%;
  padding-top: 15px;

  .c-checklist_instance-group-wrapper {
    box-shadow: none;
    border: 1px solid #e5e5e5;
  }
`;

function ProjectStartedProcesses({ project, disabled }: IProjectStartedProcessesProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const startableSubProcesses: IStartableSubProcess[] = useMemo(() => {
    return project.processAssociations.map((process) => ({
      id: process.definitionId,
      name: process.definitionName
    }));
  }, [project]);

  const {
    data: instanceGroups,
    isLoading: isLoadingInstanceGroups,
    error: instanceGroupsError
  } = useApiObject(
    PROCESS_INSTANCES_GROUPED,
    startableSubProcesses
      ? {
          parentProjectId: project.id,
          statuses: [1, 2, 3, 4, 5, 6]
        }
      : null
  );

  function onOpenInstance(instanceId: string) {
    navigate(
      `/process-instances/all/00000000-0000-0000-0000-000000000000/process-instance-tree/${instanceId}`
    );
  }

  function renderInstances() {
    const templateIds = new Set(startableSubProcesses.map((proc) => proc.id));
    for (const group of instanceGroups?.items ?? []) {
      templateIds.add(group.id);
    }

    return [...templateIds].map((id) => (
      <ChecklistInstanceGroup
        key={id}
        projectId={project.id}
        templateId={id}
        onOpenInstance={onOpenInstance}
        processGroup={instanceGroups?.items?.find((group) => group.id === id)}
        processStart={startableSubProcesses.find((proc) => proc.id === id)}
        disabled={disabled || project.status === ProjectStatus.Completed}
      />
    ));
  }

  const centerStyles = { display: 'flex', alignItems: 'center', justifyContent: 'center' };

  function renderContent() {
    if (isLoadingInstanceGroups) {
      return (
        <ContainerStyled style={centerStyles}>
          <LoadingSpinner
            label={t('globals.loading', { label: '' })}
            size={3}
            labelPosition="bottom"
          />
        </ContainerStyled>
      );
    }

    if (instanceGroupsError) {
      return (
        <InstancesContainerStyled>
          <ShowError error={instanceGroupsError} />
        </InstancesContainerStyled>
      );
    }

    if (!startableSubProcesses.length && !instanceGroups?.items?.length) {
      return (
        <ContainerStyled style={{ ...centerStyles, flexDirection: 'column' }}>
          <div style={{ marginBottom: 150 }} />
        </ContainerStyled>
      );
    }

    return <ContainerStyled data-is-scrollable="true">{renderInstances()}</ContainerStyled>;
  }

  if (
    !project.id ||
    (!startableSubProcesses.length && instanceGroups && !instanceGroups.items?.length)
  ) {
    return null;
  }

  return (
    <SettingsContainer
      styles={{ width: '100%' }}
      fullContentWidth
      defaultExpanded
      label={t('projectPanel.processes.startedProcessesHeader')}
      iconName="AddNotes"
      description={t('projectPanel.processes.description', { context: 'started' })}
    >
      {renderContent()}
    </SettingsContainer>
  );
}

export default ProjectStartedProcesses;
