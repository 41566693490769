import { t } from 'i18next';
import { Slider } from '@fluentui/react';
import { IFormProps } from './FieldForm';

function SmallTextSettings({ field, setField, disabled, language }: IFormProps) {
  function updateSmallText(value: number) {
    setField((prevState) => ({ ...prevState, maxLength: value }));
  }

  return (
    <Slider
      defaultValue={field.maxLength || 150}
      disabled={disabled}
      key={`maxLength-${field?.maxLength}-${language}`}
      label={t('createField.dialog.shortTextField.label.maxLength')}
      max={250}
      min={1}
      onChanged={(_, value) => updateSmallText(value)}
      showValue
      step={1}
      styles={{ slideBox: { paddingRight: 8, paddingLeft: 0 }, root: { marginTop: '7px' } }}
    />
  );
}
export default SmallTextSettings;
