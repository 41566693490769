import Label, { ILabelProps } from 'components/inputs/Label';
import numbro from 'numbro';
import { FocusEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ITextFieldProps, TextField } from '@fluentui/react';
import german from './languages/de-DE';

export interface INumbersFieldProps {
  id?: string;
  /**
   * Whether or not the text field is borderless.
   * @defaultvalue false
   */
  borderless?: ITextFieldProps['borderless'];
  /**
   * Optional callback to access the ITextField component. Use this instead of ref for accessing
   * the public methods and properties of the component.
   */
  componentRef?: ITextFieldProps['componentRef'];
  /**
   * Default value of the numbers field.
   */
  defaultValue?: ITextFieldProps['defaultValue'] | number;
  /**
   * Description of the number field.
   */
  description?: string;
  /**
   * Disabled state of the number field.
   * @defaultvalue false
   */
  disabled?: ITextFieldProps['disabled'];
  /**
   * Label for the number field.
   */
  label?: string;
  /**
   * The icon that will be displayed to the left of the label
   */
  labelIconName?: string;
  /**
   * Maximum number of decimals allowed.
   * @defaultvalue 0
   */
  maxDecimals?: number;
  /**
   * Callback for blur event.
   */
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  /**
   * Callback for when the input value changes.
   */
  onChange?: (newValue?: number | null) => void;
  /**
   * Placeholder text for the number field.
   * @defaultvalue "Enter a number"
   */
  placeholder?: ITextFieldProps['placeholder'];
  /**
   * Whether the number field is a required field or not
   * @defaultvalue false
   */
  required?: ITextFieldProps['required'];
  /**
   * Call to provide customized styling that will layer on top of the variant rules.
   */
  styles?: ITextFieldProps['styles'];
  /**
   * Call to provide customized styling that will layer on top of the variant rules.
   */
  labelStyles?: ILabelProps['styles'];
  /**
   * Whether or not to use thousands separator.
   */
  useThousandsSeparator?: boolean;
  autoFocus?: boolean;
}

function NumbersField({
  borderless = false,
  componentRef,
  defaultValue,
  description,
  disabled = false,
  id,
  label,
  labelIconName,
  labelStyles,
  maxDecimals = 0,
  onBlur,
  onChange,
  placeholder,
  required = false,
  styles,
  useThousandsSeparator = false,
  autoFocus
}: INumbersFieldProps): JSX.Element {
  const { t } = useTranslation();

  const [valueEntered, setValueEntered] = useState(false);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      // useEffect to set numbro language
      const currentNumbroLanguage = numbro.language();
      const currentLanguage = navigator.language.toLowerCase();

      const currentLanguageIsGerman = currentLanguage.includes('de');

      if (currentLanguageIsGerman && currentNumbroLanguage !== 'de-DE') {
        numbro.registerLanguage(german);
        numbro.setLanguage('de-DE');
      }
    }
  }, [initialized]);

  /**
   * handle textfield change
   */
  function onTextFieldChange(_: unknown, newValue?: string | undefined): void {
    if (!valueEntered) {
      setValueEntered(true);
    }

    const isValid = isValidNumber(newValue);

    if (onChange && newValue && isValid) {
      const validNumber = convertStringToNumber(newValue);

      onChange(validNumber);
    } else if (onChange) {
      onChange(null);
    }
  }

  /**
   * Check if given value is a number.
   */
  function isNumber(valueToCheck: number | string): valueToCheck is number {
    return typeof valueToCheck === 'number';
  }

  /**
   * Obtain the number of decimal places of an arbitrary number.
   */
  function countDecimals(number: number): number {
    // Convert to string
    const stringNumber = String(number);

    // Check if string contains decimal
    const containsDecimal = stringNumber.includes('.');

    if (containsDecimal) {
      return stringNumber.split('.')[1].length;
    }

    // String does not contain decimal
    return 0;
  }

  function hasValidNumberOfDecimalNumbers(number: number): boolean {
    // If the string can be converted to a number, then it is valid
    const decimals = countDecimals(number);

    // check whether the maximum decimal number is exceeded
    return decimals <= maxDecimals;
  }

  /**
   * Converts a string value to a numeric value.
   */
  function convertStringToNumber(stringToParse: string): number | undefined {
    return numbro(stringToParse).value();
  }

  /**
   * Validates a given value. It is checked whether the value can
   * be converted into a number and whether the decimal number fits.
   */
  function isValidNumber(value?: string | number): boolean {
    if (value === undefined || value === null) {
      return false;
    }

    let stringValue = value;

    if (isNumber(stringValue)) {
      // Ensure the given value is a string
      stringValue = value.toString();
    }

    const convertedNumber = convertStringToNumber(stringValue);
    if (convertedNumber !== undefined) {
      // check whether the maximum decimal number is exceeded
      return hasValidNumberOfDecimalNumbers(convertedNumber);
    }

    // not valid
    return false;
  }

  /**
   * Show an error message if the given value is not valid
   */
  function onGetErrorMessage(value: string): string | undefined {
    if (!valueEntered) {
      // prevent inital error message
      return undefined;
    }

    const isValid = isValidNumber(value);

    if (isValid) {
      return undefined;
    }

    const convertedNumber = convertStringToNumber(value);

    if (convertedNumber && !hasValidNumberOfDecimalNumbers(convertedNumber)) {
      return t('numbersField.error.invaliDecimal');
    }

    return t('numbersField.error.invalidNumber');
  }

  /**
   * Validates the default value and returns it as a string
   */
  function getTextFieldDefaultValue(): string | undefined {
    const isValid = isValidNumber(defaultValue);

    if (isValid) {
      return numbro(defaultValue).format({
        thousandSeparated: useThousandsSeparator,
        mantissa: maxDecimals
      });
    }

    return undefined;
  }

  const textFieldDefaultValue = getTextFieldDefaultValue();

  return (
    <div id={id}>
      <Label
        styles={labelStyles}
        description={description}
        iconName={labelIconName}
        label={label}
        required={required}
      />
      <TextField
        key={initialized ? 1 : 2}
        id={`input-${id}`}
        borderless={borderless}
        componentRef={componentRef}
        autoComplete="off"
        defaultValue={textFieldDefaultValue}
        disabled={disabled}
        onChange={onTextFieldChange}
        onBlur={onBlur}
        onGetErrorMessage={onGetErrorMessage}
        placeholder={placeholder || t('numbersField.placeholder')}
        styles={styles}
        autoFocus={autoFocus}
      />
    </div>
  );
}

export default NumbersField;
