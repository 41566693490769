import { Callout, IconButton, Slider } from '@fluentui/react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useId } from '@fluentui/react-hooks';
import { useState } from 'react';
import { Label, NumbersField } from '../../components';

export const DEFAULT_TEMPERATURE = 0.35;

const SLIDER_STEPS = 5;

function fTemperatureSetting(x: number) {
  // curve that maps middle value to default temperature
  return x ** (Math.log(DEFAULT_TEMPERATURE) / Math.log(0.5));
}

export const TEMPERATURE_SETTINGS = [...Array(SLIDER_STEPS)].map((x, i) =>
  fTemperatureSetting(i / (SLIDER_STEPS - 1))
);

function toIndexSpace(value: number): number {
  let upperBound = TEMPERATURE_SETTINGS.findIndex((setting) => setting > value);
  if (upperBound === -1) upperBound = TEMPERATURE_SETTINGS.length - 1;

  const lowerBound = Math.max(0, upperBound - 1);

  if (lowerBound === upperBound) return lowerBound;

  const t =
    (value - TEMPERATURE_SETTINGS[lowerBound]) /
    (TEMPERATURE_SETTINGS[upperBound] - TEMPERATURE_SETTINGS[lowerBound]);

  return lowerBound + Math.max(0, Math.min(t, 1));
}

function toValueSpace(index: number): number {
  const lowerBound = Math.max(0, Math.floor(index));
  const upperBound = Math.min(Math.ceil(index), TEMPERATURE_SETTINGS.length - 1);

  if (lowerBound === upperBound) return TEMPERATURE_SETTINGS[index];

  const t = (index - lowerBound) / (upperBound - lowerBound);

  return (
    TEMPERATURE_SETTINGS[lowerBound] +
    t * (TEMPERATURE_SETTINGS[upperBound] - TEMPERATURE_SETTINGS[lowerBound])
  );
}

const TemperatureSettingStyled = styled.div`
  display: flex;
  flex-direction: column;

  > .c-label {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > .c-label {
      i {
        margin-left: 4px;
        margin-top: 6px;
      }
    }

    > .c-more {
      height: 1.25rem;
    }
  }

  > .c-slider {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    > .c-slider {
      flex: 1;
    }
  }
`;

const TemperatureSettingCalloutStyled = styled.div`
  padding: 0.5rem;
  padding-top: 0;
`;

export default function TemperatureSetting({
  value,
  onChange
}: {
  value: number;
  onChange: (setting: number) => void;
}) {
  const { t } = useTranslation();
  const sliderId = useId();
  const moreButton = useId();
  const [calloutOpen, setCalloutOpen] = useState(false);

  return (
    <TemperatureSettingStyled>
      <div className="c-label">
        <Label
          className="c-label"
          htmlFor={sliderId}
          label={t('ai.temperature.label')}
          description={t('ai.temperature.description')}
        />
        <IconButton
          id={moreButton}
          className="c-more"
          iconProps={{ iconName: 'More' }}
          onClick={() => setCalloutOpen(true)}
        />
      </div>

      <Callout
        target={`#${moreButton}`}
        hidden={!calloutOpen}
        beakWidth={0}
        onDismiss={() => setCalloutOpen(false)}
      >
        <TemperatureSettingCalloutStyled
          onKeyDown={(event) => {
            if (event.key === 'Escape' || event.key === 'Enter') setCalloutOpen(false);
          }}
        >
          {calloutOpen ? (
            <NumbersField
              label={t('ai.temperature.valueField')}
              autoFocus
              defaultValue={+value.toFixed(3)}
              maxDecimals={3}
              onChange={(value) => {
                if (value !== null) onChange(Math.max(0, Math.min(value, 1)));
              }}
              onBlur={() => setCalloutOpen(false)}
            />
          ) : null}
        </TemperatureSettingCalloutStyled>
      </Callout>

      <div className="c-slider">
        <span className="c-label">{t('ai.temperature.minLabel')}</span>
        <Slider
          id={sliderId}
          className="c-slider"
          value={toIndexSpace(value)}
          min={0}
          max={TEMPERATURE_SETTINGS.length - 1}
          onChange={(index) => onChange(toValueSpace(index))}
          showValue={false}
        />
        <span className="c-label">{t('ai.temperature.maxLabel')}</span>
      </div>
    </TemperatureSettingStyled>
  );
}
