import { BooleanField, HyperLinkField, RichTextEditor } from 'components';
import numbro from 'numbro';
import { useTranslation } from 'react-i18next';
import { fileContents } from 'services/fetchRequests';
import styled from 'styled-components';
import { columnPropType, instancePropType } from 'types';
import { convertEpUserOrTeamToPersonaProps, formatDate, onOpenWopi } from 'utils/helpers';
import { ActionButton, Facepile, TooltipHost, getTheme } from '@fluentui/react';

const LongTextFieldWrapper = styled.div`
  max-height: 16px;

  * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 0px;
  }
`;

export default function InstanceCellRenderer({ column, instance }) {
  const { t } = useTranslation();

  function onDownloadFile(id, name) {
    const downloadLink = document.createElement('a');

    if (id) {
      fileContents({ id })
        .then((response) => response.blob())
        .then((blob) => URL.createObjectURL(blob))
        .then((blobUrl) => {
          downloadLink.href = blobUrl;
          downloadLink.download = name || '';

          document.body.appendChild(downloadLink);
          downloadLink.click();

          URL.revokeObjectURL(blobUrl);
        });
    }
  }

  const index = instance.columns.findIndex((aColumn) => aColumn.id === column.id);
  const columnField = instance.columns[index];

  if (columnField) {
    const { value } = columnField;

    if (value && value.intId) {
      // if id-column
      return `#${value.intId}`;
    }

    if (value && value.name) {
      // if value is a user-object
      return value.name;
    }

    if (columnField.fieldType === 5) {
      // if datefield
      if (columnField.field?.dateFormat === 2) {
        return value ? formatDate(value, 'DD.MM.YYYY, HH:mm') : <div />;
      }

      return value ? formatDate(value, 'DD.MM.YYYY') : <div />;
    }

    if (columnField.fieldType === 2) {
      // if html longtextfield

      const key = typeof value === 'string' ? value?.substring(0, 10) : undefined;

      if (value && typeof value !== 'object') {
        const rte = <RichTextEditor key={key} defaultValue={value} disabled />;

        return (
          <TooltipHost
            content={<div style={{ margin: 10 }}>{rte}</div>}
            calloutProps={{ gapSpace: 0 }}
          >
            <LongTextFieldWrapper>{rte}</LongTextFieldWrapper>
          </TooltipHost>
        );
      }

      return <div />;
    }

    if (columnField.fieldType === 3) {
      // if numberfield

      if (value) {
        return numbro(value).format({
          thousandSeparated: !!columnField.field.useThousandsSeparator,
          mantissa: columnField.field.numDecimals || 0
        });
      }

      return <div />;
    }

    if (columnField.fieldType === 4) {
      // if bollean

      const { textNOk, textOk } = columnField.field;

      return (
        <BooleanField
          defaultValue={columnField.value}
          textOk={textOk}
          displayResultOnly
          displayCheckBoxOnly
          confirmationFormat={columnField.field.confirmationFormat}
          textNOk={textNOk}
          disabled
          styles={{
            checkboxWrapper: { border: 'none', maxWidth: 'fit-content', margin: 'auto' },
            positiveButton: { root: { height: '20px' } },
            negativeButton: { root: { height: '20px' } }
          }}
        />
      );
    }

    if (columnField.fieldType === 6) {
      // if choicefield
      if (value && Array.isArray(value)) {
        const dispalyValue = value.map(
          (option, index) => `${option.value}${index + 1 === value.length ? '' : ', '}`
        );
        return (
          <div style={{ maxWidth: '300px' }} title={dispalyValue}>
            {dispalyValue}
          </div>
        );
      }

      return value && value.value ? value.value : <div />;
    }

    if (columnField.fieldType === 7) {
      // if person field

      if (value) {
        const values = [...(value.teams || []), ...(value.users || [])];

        let personas = values.map(convertEpUserOrTeamToPersonaProps);
        personas = personas.map((persona) => ({ ...persona, personaName: persona.text }));

        const maxDisplayablePersonas = 2;
        let title = '';

        if (personas.length > maxDisplayablePersonas) {
          title = personas
            .slice(maxDisplayablePersonas)
            .map((persona) => persona.text)
            .join(', ');
        }

        return (
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: 3 }}>
            <Facepile
              personas={personas}
              maxDisplayablePersonas={2}
              personaSize={2}
              overflowButtonType={1}
              overflowButtonProps={{ title, disabled: true }}
            />
          </div>
        );
      }
    }

    if (columnField.fieldType === 9 && columnField.value) {
      // hyperLink
      return (
        <HyperLinkField
          styles={{
            linkWrapperStyles: {
              background: 'none',
              border: 'none',
              padding: 0
            }
          }}
          defaultValue={columnField.value}
          disabled
          key={columnField.id}
        />
      );
    }

    if (columnField.fieldType === 10 && columnField.value) {
      // document
      let menuProps;

      const id = columnField.value.url?.split('id=')[1];

      if (!id) return null;

      const downloadFileClick = () => {
        onDownloadFile(id, columnField.value.text);
      };

      if (columnField.value?.wopiUrl) {
        menuProps = {
          items: [
            {
              key: 'Download',
              text: t('globals.downloadFile'),
              onClick: downloadFileClick,
              iconProps: { iconName: 'Download' }
            },
            {
              key: 'OpenInNewTab',
              onClick: () => {
                onOpenWopi(id);
              },
              text: t('globals.openFile'),
              iconProps: { iconName: 'OpenInNewTab' }
            }
          ]
        };
      }

      return (
        <ActionButton
          title={columnField.value.text}
          menuProps={menuProps}
          onClick={() => {
            if (!columnField.value?.wopiUrl) {
              downloadFileClick();
            }
          }}
          styles={{
            root: {
              color: getTheme().palette.themePrimary,
              padding: 0
            }
          }}
        >
          {columnField.value.text}
        </ActionButton>
      );
    }

    if (columnField.fieldType === 11) {
      // external Data
      return columnField.value;
    }

    if (columnField.fieldType === 12) {
      // Lookup
      return value?.value || '';
    }

    if (columnField.fieldType === 17) {
      // ai
      return value?.choices?.[0]?.text;
    }

    // default case: return value - for example textfields
    return value;
  }

  return <div />;
}

InstanceCellRenderer.propTypes = {
  column: columnPropType.isRequired,
  instance: instancePropType.isRequired
};
