import { useEffect, useState } from 'react';
import { IFileProps } from 'types';
import { Image } from '@fluentui/react';

export interface IMultiMediaImageProps {
  getFileContents: ({ id }: { id: string }) => Promise<Response>;
  height?: number | string;
  mediaValue: IFileProps;
  objectFit?: string;
  width?: number | string;
}

function MultiMediaImage({
  getFileContents,
  height,
  mediaValue,
  objectFit,
  width
}: IMultiMediaImageProps): JSX.Element {
  const [blobUrl, setBlobUrl] = useState<string | null>(null);

  useEffect(() => {
    let newBlobUrl = '';

    const setImageBlobUrl = async () => {
      if (mediaValue.url) {
        const fileIdIndex = mediaValue.url.indexOf('id=') + 3;
        const id = mediaValue.url.substr(fileIdIndex);

        const fileContents = await getFileContents({ id });
        const blob = await fileContents.blob();
        newBlobUrl = URL.createObjectURL(blob);

        setBlobUrl(newBlobUrl);
      }
    };

    if (!mediaValue.blobFile) {
      setImageBlobUrl();
    }

    return () => URL.revokeObjectURL(newBlobUrl);
  }, [getFileContents, mediaValue.blobFile, mediaValue.url]);

  return (
    <Image
      shouldFadeIn={false}
      src={blobUrl || mediaValue.blobFile?.preview?.url}
      styles={{
        image: {
          width: width || '100%',
          height: height || '100%',
          maxHeight: '100vh',
          objectFit: objectFit || 'cover'
        }
      }}
    />
  );
}

export default MultiMediaImage;
