/* eslint-disable no-unused-vars */

import { EmptyListPlaceHolder, LoadingSpinner, Panel } from 'components';
import { useContainerEndNotifer } from 'hooks';
import PropTypes from 'prop-types';
import { createRef, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { checkScreenWidth, formatDate, validateDate } from 'utils/helpers';
import { Icon, IconButton, PanelType } from '@fluentui/react';

const headerHeight = checkScreenWidth(['extraSmall']) ? 0 : 48;

const ReleaseNotesContainer = styled.div`
  padding: 10px 15px 15px 15px;
  overflow-y: auto;
`;

const ReleaseNoteStyled = styled.div`
  .releaseNote-title-container {
    display: flex;
    height: 35px;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
  }

  .releaseNote-title-icon-chevron {
    margin-right: 6px;
  }

  .releaseNote-title {
    font-size: 18px;
    font-weight: 600;
  }

  .releaseNote-content {
    padding: 0 5px 0 18px;
  }

  .feature-content-container {
    display: flex;
    padding-bottom: 10px;
  }

  .feature-headline-number {
    min-width: 20px;
    max-width: 20px;
  }

  .feature-headline-number,
  .feature-headline {
    font-size: 16px;
    font-weight: 600;
  }

  .sub-feature-content {
    padding-top: 5px;
  }

  .sub-feature-headline {
    font-weight: 600;
  }
`;

const { crossApiOrigin = 'https://befa945a.azurewebsites.net' } = window.config;

function ReleaseNotesPanel({ showReleaseNotesPanel, onDismiss }) {
  const { t } = useTranslation();

  // List items
  const [releaseNotes, setReleaseNotes] = useState([]);

  const [collapseStates, setCollapseStates] = useState({ 0: true });

  const listRef = createRef();

  useContainerEndNotifer(listRef);

  // Loading states
  const [isLoading, setIsLoading] = useState(true);

  const loadReleaseNotes = useCallback(() => {
    let userLang = navigator.language || navigator.userLanguage;

    if (userLang.includes('-')) {
      [userLang] = userLang.split('-');
    }

    const url = `${crossApiOrigin}/api/RN/All?code=gm48cl9cISVdQBP7bRRlsfdHfjGI9dTQO2KSHgu5SW7ehFDCAjgEVQ==&language=${userLang}`;

    fetch(url)
      .then((response) => response.json())
      .then((releaseNotes) => {
        setReleaseNotes(releaseNotes);

        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    loadReleaseNotes();
  }, [loadReleaseNotes]);

  function toggleGroupCollapseState(index) {
    const collapsed = collapseStates[index];
    setCollapseStates({ ...collapseStates, [index]: !collapsed });
  }

  function formatDateString(value) {
    if (typeof value === 'string' && validateDate(value, '', true)) {
      return formatDate(value, 'L');
    }

    return value;
  }

  function getNotes() {
    const notes = [];

    releaseNotes.map((releaseNote, noteIndex) => {
      notes.push(
        <ReleaseNoteStyled key={`feature-${releaseNote.version}`}>
          <div
            role="presentation"
            className="releaseNote-title-container"
            onClick={() => toggleGroupCollapseState(noteIndex)}
          >
            <Icon
              className="releaseNote-title-icon-chevron"
              iconName={collapseStates[noteIndex] ? 'ChevronDown' : 'ChevronRight'}
            />
            <div className="releaseNote-title">
              {t('releaseNotesPanel.noteTitle', {
                version: releaseNote.version,
                date: formatDateString(releaseNote.releaseDate)
              })}
            </div>
          </div>
          {collapseStates[noteIndex] && releaseNote.features?.length ? (
            <div className="releaseNote-content" id={`releaseNote-${noteIndex}-content`}>
              {releaseNote.features.map((feature, featureIndex) => (
                <div className="feature-content-container" key={`feature-${feature.headline}`}>
                  <div className="feature-headline-number">{`${featureIndex + 1})`}</div>
                  <div>
                    <div className="feature-headline">{feature.headline}</div>
                    <div>
                      <div>{feature.description}</div>
                      {feature.subFeatures?.length
                        ? feature.subFeatures.map((subFeature) => {
                            return (
                              <div
                                className="sub-feature-content"
                                key={`sub-feature-${subFeature.headline}`}
                              >
                                <div className="sub-feature-headline">{subFeature.headline}</div>
                                <div>{subFeature.description}</div>
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : null}
        </ReleaseNoteStyled>
      );

      return null;
    });

    return notes;
  }

  function getPanelContent() {
    if (isLoading) {
      return <LoadingSpinner label={t('loading.releaseNotesPanel.text')} />;
    }

    if (releaseNotes?.length) {
      return (
        <ReleaseNotesContainer data-is-scrollable="true" ref={listRef}>
          {getNotes()}
        </ReleaseNotesContainer>
      );
    }

    return (
      <EmptyListPlaceHolder
        hidden={!!releaseNotes}
        noItemsText={t('releaseNotesPanel.placeHolder')}
        listIconName="Ringer"
      />
    );
  }

  const panelType = checkScreenWidth(['extraSmall', 'small'])
    ? PanelType.smallFluid
    : PanelType.custom;

  return (
    <Panel
      headerText={t('releaseNotesPanel.title')}
      // isBlocking={false}
      onRenderBody={() => getPanelContent()}
      isOpen={showReleaseNotesPanel}
      customWidth="500px"
      onDismiss={onDismiss}
      type={panelType}
      styles={{
        headerText: { color: 'white', padding: '10px 0px' },
        navigation: {
          color: 'white',
          flexDirection: 'row',
          boxShadow: 'rgb(21 27 38 / 15%) 0px 1px 2px'
        },
        closeButton: { display: 'block', marginTop: 8 },
        root: { marginTop: `${headerHeight}px` }
      }}
    />
  );
}

export default ReleaseNotesPanel;

ReleaseNotesPanel.propTypes = {
  showReleaseNotesPanel: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired
};
