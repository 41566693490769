import { AppContext } from 'features/App';
import moment from 'moment';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import fetchRequest from 'services/api';
import styled from 'styled-components';
import { RouteFieldType } from 'types';
import { v4 as uuidv4 } from 'uuid';
import { Dropdown, TextField } from '@fluentui/react';
import { pages } from '@microsoft/teams-js';
import MsTeamsProcessConfig from './components/MsTeamsProcessConfig';
import MsTeamsProjectConfig from './components/MsTeamsProjectConfig';
import MsTeamsTagsConfig from './components/MsTeamsTagsConfig';
import MsTeamsTasksConfig from './components/MsTeamsTasksConfig';

const MsTeamsConfigStyled = styled.div`
  max-height: 360px;
  overflow-y: auto;

  margin-left: 5px;

  .ms-TextField-field {
    font-size: 14px;
  }

  .ms-DetailsHeader {
    padding-top: 0;
  }
`;

function MsTeamsConfig() {
  const { t } = useTranslation();

  // global app state
  const { globalAppState, dispatch } = useContext(AppContext);

  const { currentUser, currentTenantId } = globalAppState;

  const { context } = useParams();

  const [config, setConfig] = useState({
    id: uuidv4(),
    selectedTenant: currentTenantId,
    tabName: 'Evocom',
    showTasks: true,
    showProcesses: true,
    selectedView: null,
    showProjects: true,
    showTagList: false,
    selectedTags: [],
    selectedProject: null,
    selectedProcess: null,
    selectedTeam: null
  });

  const [initialized, setInitialized] = useState(false);
  const [showTextField, setShowTextField] = useState(true);

  const stringifyFieldValues = useCallback((configProps) => {
    const configPropsCopy = { ...configProps };

    if (configPropsCopy.selectedProcess?.filter?.length > 0) {
      configPropsCopy.selectedProcess.filter = configProps.selectedProcess.filter.map((filter) => ({
        ...filter,
        value: JSON.stringify(filter.value)
      }));
    }

    return configPropsCopy;
  }, []);

  const updateConfig = useCallback(
    (configProps) => {
      // creates and updates the config
      const body = JSON.stringify(stringifyFieldValues(configProps));

      return fetchRequest({ method: 'POST', body, url: `msteams/settings` }).then((response) => {
        setConfig(normalizeMsConfigFieldValues(response));

        return response;
      });
    },
    [stringifyFieldValues]
  );

  const getConfig = useCallback((id) => {
    return fetchRequest({ url: `msteams/settings?id=${id}`, ignoreAlert: true }).then(
      (response) => {
        return normalizeMsConfigFieldValues(response);
      }
    );
  }, []);

  useEffect(() => {
    if (context === 'remove') {
      pages.config.registerOnRemoveHandler((removeEvent) => {
        fetchRequest({
          method: 'DELETE',
          url: `msteams/settings/${config.id}`
        }).then(() => {
          removeEvent.notifySuccess();
        });
      });

      pages.config.setValidityState(true);
    }
  }, [config.id, context]);

  useEffect(() => {
    if (!initialized && !context) {
      pages.getConfig().then((settings) => {
        const { entityId } = settings;

        if (entityId && entityId.startsWith('{')) {
          // this is for the old syntax of the entityId JSON
          const newConfig = JSON.parse(entityId);

          if (typeof newConfig === 'object') {
            updateConfig({
              ...newConfig,
              selectedTenant: newConfig.selectedTenant || currentTenantId,
              id: uuidv4()
            }).then(() => {
              setInitialized(true);
            });
          }
        } else if (entityId && entityId.includes('/')) {
          // this is for the old syntax of the entityId (1/1/1)

          const showTasks = entityId.split('/')[0] === '1';
          const showProcesses = entityId.split('/')[1] === '1';
          const showProjects = entityId.split('/')[2] === '1';

          updateConfig({
            selectedTenant: currentTenantId,
            showTasks,
            showProcesses,
            showProjects,
            id: uuidv4()
          }).then(() => setInitialized(true));

          setShowTextField(false);
        } else if (entityId) {
          getConfig(entityId).then((response) => {
            setConfig(response);
            setInitialized(true);
          });
        } else {
          setInitialized(true);
        }
      });
    }
  }, [context, initialized, currentTenantId, updateConfig, getConfig]);

  function getFilterValueByFieldType({ value, fieldType }) {
    if (fieldType === RouteFieldType.Choice) {
      return Array.isArray(value) ? value[0]?.value : value.value;
    }

    if (fieldType === RouteFieldType.Person) {
      return value.userId;
    }

    if (fieldType === RouteFieldType.DateTime) {
      // return utc date like this: "2023-04-19T22:00:00Z"
      return moment(value).utc().format();
    }

    return value.toString();
  }

  useEffect(() => {
    if (!context) {
      const {
        id,
        tabName,
        showTasks,
        showProcesses,
        showProjects,
        selectedProcess,
        selectedProject,
        showTagList,
        selectedTags,
        selectedTeam
      } = config;

      if (
        tabName &&
        (showTasks || showProcesses || showProjects || (showTagList && selectedTags?.length > 0))
      ) {
        pages.config.setValidityState(true);

        let url = window.location.origin;

        if (selectedProcess) {
          url += `/process-instances/all/${selectedProcess.id}`;

          if (selectedProcess.filter?.length > 0) {
            selectedProcess.filter.forEach(({ fieldId, value, fieldType }, index) => {
              if (!fieldId || !value) return;

              url += `${index === 0 ? '?' : '&'}filter-${fieldId}=${getFilterValueByFieldType({
                value,
                fieldType
              })}`;
            });
          }
        } else if (selectedProject && selectedProject.type === 2) {
          url += `/projects/all/task-list/${selectedProject.id}/00000000-0000-0000-0000-000000000000`;
        } else if (selectedProject && selectedProject.type === 1) {
          url += `/projects/all/gantt/${selectedProject.id}`;
        } else if (selectedTeam) {
          url += `/teams/${selectedTeam.id}`;
        }
        // default url without selected process or project
        else if (showTasks) {
          url += '/tasks/all';
        } else if (showProcesses) {
          url += '/process-instances/all';
        } else if (showProjects) {
          url += '/projects/all';
        } else if (showTagList && selectedTags?.length > 0) {
          url += `/tags/${selectedTags[0].id}`;
        }

        pages.config.registerOnSaveHandler((saveEvent) => {
          const entityId = id || uuidv4();

          pages.config.setConfig({
            websiteUrl: url,
            contentUrl: url,
            removeUrl: `${window.location.origin}/teams-config/remove`,
            entityId,
            suggestedDisplayName: tabName
          });

          updateConfig({ ...config, id: entityId });

          saveEvent.notifySuccess();
        });
      } else {
        pages.config.setValidityState(false);
      }
    }
  }, [context, config, updateConfig]);

  function onTenantChange(newTenant) {
    setConfig((prevState) => ({
      ...prevState,
      showSingleProcess: false,
      selectedProcess: null,
      showSingleProject: false,
      selectedProject: null
    }));

    dispatch({ type: 'tenantId', data: newTenant.tenantId });
    window.localStorage.setItem('tenantId', newTenant.tenantId);
  }

  if (!currentUser) {
    return t('msTeams.config.userNotFound');
  }

  if (context === 'remove') {
    return <p>{t('msTeams.config.remove')}</p>;
  }

  if (!initialized) {
    return null;
  }

  return (
    <MsTeamsConfigStyled>
      <div style={{ display: 'flex', widht: '100%' }}>
        {showTextField && (
          <TextField
            styles={{
              root: { marginBottom: '15px', maxWidth: '300px' }
            }}
            required
            label={t('msTeams.config.label.tabname')}
            defaultValue={config.tabName}
            onChange={(_, value) => setConfig((prevState) => ({ ...prevState, tabName: value }))}
          />
        )}
        <Dropdown
          styles={{ root: { marginLeft: '10px', widht: '100%' } }}
          label="Tenant"
          defaultSelectedKey={globalAppState?.currentTenantId}
          dropdownWidth={200}
          options={globalAppState?.tenants?.map((tenant) => ({
            ...tenant,
            key: tenant.tenantId,
            text: tenant.name
          }))}
          onChange={(_, option) => onTenantChange(option)}
          disabled={false}
        />
      </div>
      <div style={{ marginBottom: '8px', fontWeight: 600 }}>
        {t('msTeams.config.checkBox.header')}
      </div>
      <MsTeamsTasksConfig config={config} setConfig={setConfig} />
      <MsTeamsProcessConfig config={config} setConfig={setConfig} />
      <MsTeamsProjectConfig config={config} setConfig={setConfig} />
      <MsTeamsTagsConfig config={config} setConfig={setConfig} />
    </MsTeamsConfigStyled>
  );
}

export default MsTeamsConfig;

export function normalizeMsConfigFieldValues(configProps) {
  const configPropsCopy = { ...configProps };

  if (configPropsCopy.selectedProcess?.filter?.length > 0) {
    configPropsCopy.selectedProcess.filter = configProps.selectedProcess.filter.map((filter) => ({
      ...filter,
      value: JSON.parse(filter.value)
    }));
  }

  return configPropsCopy;
}
