import { CombinedPickerJS as CombinedPicker, DateTime, NumbersField } from 'components';
import { useTranslation } from 'react-i18next';
import { Dropdown, Label, TextField } from '@fluentui/react';
import {
  IFieldProps,
  ITeamProps,
  IUserProps,
  PersonFieldFormat,
  RouteFieldType
} from '../../../../types';

export default function ClauseCompareField({
  showLabel,
  onChange,
  value,
  field,
  hidden
}: {
  showLabel: boolean;
  value: string | number | Date | IUserProps | ITeamProps;
  onChange: (
    value:
      | string
      | number
      | Date
      | IUserProps
      | ITeamProps
      | { compareValue: string; compareValueId: string }
  ) => void;
  field: IFieldProps;
  hidden?: boolean;
}) {
  const { t } = useTranslation();

  let clauseCompareField = null;
  const label = showLabel ? t('formBuilder.clause.compareField.label') : null;
  const type = field?.fieldType;

  function getFieldOptions() {
    return field.choices.map((choice, index) => {
      return { key: index, text: choice && choice.value ? choice.value : '', id: choice.id };
    });
  }

  if (
    !type ||
    type === RouteFieldType.SmallText ||
    type === RouteFieldType.LongText ||
    type === RouteFieldType.Hyperlink ||
    type === RouteFieldType.Document ||
    type === RouteFieldType.Scanner ||
    type === RouteFieldType.ExternalData
  ) {
    clauseCompareField = (
      <TextField
        onChange={(_, value) => onChange(value)}
        label={label}
        value={(value as string) || ''}
      />
    );
  } else if (type === RouteFieldType.Number) {
    clauseCompareField = (
      <NumbersField
        key={field.id}
        label={label}
        maxDecimals={field.numDecimals}
        useThousandsSeparator={field.useThousandsSeparator}
        defaultValue={value as number}
        onChange={(value) => onChange(value)}
      />
    );
  } else if (type === RouteFieldType.Boolean && field?.confirmationFormat === 1) {
    const options = [
      { key: field.textOk, text: field.textOk },
      { key: field.textNOk, text: field.textNOk }
    ];

    let defaultSelectedKey = value as string;
    if (value === 'true') {
      defaultSelectedKey = field.textOk;
    } else if (value === 'false') {
      defaultSelectedKey = field.textNOk;
    }

    clauseCompareField = (
      <Dropdown
        label={label}
        options={options}
        defaultSelectedKey={defaultSelectedKey}
        onChange={(_, value) => {
          onChange(value.key === field.textOk ? 'true' : 'false');
        }}
      />
    );
  } else if (type === RouteFieldType.DateTime) {
    clauseCompareField = (
      <DateTime
        allowPastDate
        key={field.id}
        label={label}
        value={value as string | Date}
        onSelectDate={(value) => onChange(value)}
      />
    );
  } else if (type === RouteFieldType.Choice) {
    const options = getFieldOptions();
    clauseCompareField = (
      <Dropdown
        label={label}
        options={options}
        defaultSelectedKey={options.findIndex((option) => option.text === value)}
        onChange={(_, value) => onChange({ compareValue: value.text, compareValueId: value.id })}
      />
    );
  } else if (type === RouteFieldType.Person) {
    clauseCompareField = (
      <CombinedPicker
        selectedItems={value ? [value] : null}
        searchTeams={
          field.personFieldFormat === PersonFieldFormat.singleTeam ||
          field.personFieldFormat === PersonFieldFormat.multipleTeams ||
          field.personFieldFormat === PersonFieldFormat.personsAndTeams
        }
        searchUser={
          field.personFieldFormat === PersonFieldFormat.singlePerson ||
          field.personFieldFormat === PersonFieldFormat.multiplePersons ||
          field.personFieldFormat === PersonFieldFormat.personsAndTeams
        }
        onChange={(value) => onChange(value && value[0] ? value[0] : null)}
        itemLimit={1}
        isFormPeoplePicker={!!label}
        label={label}
        type={5}
      />
    );
  } else if (type === RouteFieldType.Rating) {
    const options = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 1; i <= field.numStars; i++) {
      const text =
        i === 1
          ? `${i} ${t('formBuilder.clause.compareField.star')}`
          : `${i} ${t('formBuilder.clause.compareField.stars')}`;

      options.push({ key: i, text });
    }

    const defaultSelectedKey = value || typeof value === 'number' ? Number(value) : null;

    clauseCompareField = (
      <Dropdown
        label={label}
        options={options}
        defaultSelectedKey={defaultSelectedKey}
        onChange={(_, value) => onChange(value.key)}
      />
    );
  }

  if (hidden && showLabel) {
    return <Label>{label}</Label>;
  }

  return hidden ? null : clauseCompareField;
}
