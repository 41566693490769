import styled from 'styled-components';
import {
  IMessageFragment,
  IMessageFragmentWebSearch,
  MessageFragmentType
} from '../../../services/ChatConnection/model';
import {
  ChatAuxiliaryFragmentWebSearchResults,
  ChatAuxiliaryFragmentWebSearchHeader
} from './ChatMessageFragmentWebSearch';
import ChatItemAuxReferences from './ChatItemAuxReferences';

const ChatItemAuxiliaryContentsStyled = styled.div`
  position: sticky;
  top: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  &:empty {
    display: none;
  }
`;

export default function ChatItemAuxiliaryContents({
  fragments
}: {
  fragments: IMessageFragment[];
}) {
  const webSearchFragments = fragments.filter(
    (frag) => frag.type === MessageFragmentType.WebSearch
  ) as IMessageFragmentWebSearch[];

  return (
    <ChatItemAuxiliaryContentsStyled className="chat-item-transitive-empty">
      {webSearchFragments.map((frag) => (
        <ChatAuxiliaryFragmentWebSearchHeader key={frag.id} fragment={frag} />
      ))}

      <ChatItemAuxReferences />

      {webSearchFragments.map((frag) => (
        <ChatAuxiliaryFragmentWebSearchResults key={frag.id} fragment={frag} />
      ))}
    </ChatItemAuxiliaryContentsStyled>
  );
}
