import { RichTextEditor } from 'components';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  Checkbox,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  DirectionalHint,
  Dropdown,
  Icon,
  PrimaryButton,
  TextField,
  TooltipHost
} from '@fluentui/react';
import FieldPicker from '../FieldPicker';
import PropertyTypes from './PropertyTypes';

const CustomLabelStyled = styled.div`
  display: flex;
  position: relative;
  margin-top: 10px;
  margin-bottom: 5px;

  .info-icon {
    font-size: 11px;
    position: absolute;
    top: 3px;
    color: rgb(0, 120, 212);
  }
`;

const InfoIcon = styled(Icon)`
  margin: 0 10px 0 5px;

  &:hover {
    cursor: help;
  }
`;

const FieldPickerContainer = styled.div`
  margin-top: 5px;
`;

// requestProperty - property to edit
// groupRequestProps - other properties in the same group (level) - for complex objects
// onSaveChanges - function that handles saving changes
// onDismiss - function that handles dismissing dialog
// propType - query, header, body
// requestProperties - all properties (for body properties and complex objects)
function CreateRequestPropDialog({
  requestProperty,
  groupRequestProps,
  onSaveChanges,
  onDismiss,
  hidden,
  propType,
  requestProperties,
  url,
  forAutocomplete,
  onlyDefault,
  inputErrors
}) {
  const { t } = useTranslation();

  const [errorMessages, setErrorMessages] = useState(null);

  const [newProperty, setNewProperty] = useState(null);

  const [newInternalName, setNewInternalName] = useState(null);

  const [doubleNameProperty, setDoubleNameProperty] = useState(null);

  const propertyTypeOptions = PropertyTypes(t);

  const propertyUsageOptions = [
    {
      key: 0,
      text: t('createRequestPropDialog.usageTypes.normal')
    },
    {
      key: 1,
      text: t('createRequestPropDialog.usageTypes.formatString')
    },
    {
      key: 2,
      text: t('createRequestPropDialog.usageTypes.url')
    }
  ];

  const handleDoublePropKeyError = useCallback(
    (property) => {
      setErrorMessages({
        ...errorMessages,
        internalName: t('createRequestPropDialog.errorMessage.doublePropKey', {
          key: property?.internalName
        }),
        doubleName: property?.internalName
      });
    },
    [errorMessages, t]
  );

  useEffect(() => {
    if (requestProperty && !newProperty) {
      if (inputErrors?.length) {
        let doubleNameProp = null;

        if (propType === 'body') {
          const doubleNameError = inputErrors?.find(
            (err) =>
              err.inputId ===
                `${err.property[Object.keys(err.property)[0]]?.index}-txt-internalName` &&
              err.property &&
              err.property[Object.keys(err.property)[0]]?.index === requestProperty.index &&
              err.message
          );

          const propKey = doubleNameError?.property
            ? Object.keys(doubleNameError.property)[0]
            : null;

          doubleNameProp = propKey ? { ...doubleNameError[propKey], internalName: propKey } : null;
        } else {
          const doubleNameError = inputErrors?.find(
            (err) =>
              err.inputId === `${propType}-${err.field?.index}-internalName-txt` &&
              err.field?.index === requestProperty.index &&
              err.message
          );

          doubleNameProp = doubleNameError?.field;
        }

        if (doubleNameProp) {
          handleDoublePropKeyError(doubleNameProp);
        }
      }

      setNewProperty(requestProperty);
    }
  }, [requestProperty, newProperty, inputErrors, propType, handleDoublePropKeyError]);

  // check each existing property in group if there is a double internal name
  function checkIfInternalNameExists(property) {
    let existingInternalName = false;

    if (groupRequestProps) {
      if (propType === 'body') {
        Object.keys(groupRequestProps).map((key) => {
          if (key === property.internalName && groupRequestProps.index !== property.index) {
            existingInternalName = true;
          }

          return null;
        });
      } else {
        groupRequestProps.map((prop) => {
          if (prop?.internalName === property.internalName && prop.index !== property.index) {
            existingInternalName = true;
          }

          return null;
        });
      }
    }

    if (existingInternalName) {
      handleDoublePropKeyError(property);
    }

    return existingInternalName;
  }

  function validateFieldUsage(name, value) {
    const removeUsagErrorMessage = () => {
      const newErrorMessages = { ...errorMessages };

      Object.keys(errorMessages).map((field) => {
        if (field === 'usage' || field === 'usage') {
          delete newErrorMessages.usage;
        }

        if (field === 'formatString') {
          delete newErrorMessages.formatString;
        }

        return null;
      });

      setErrorMessages(newErrorMessages);
    };

    const usageValue = name === 'usage' ? value : newProperty?.usage || 0;
    const formatString = name === 'formatString' ? value : newProperty?.formatString;

    if (propType === 'query' && (usageValue || usageValue === 0)) {
      if (errorMessages) {
        removeUsagErrorMessage();
      }

      if (usageValue === 2) {
        if (!url?.includes('{0}')) {
          setErrorMessages({
            ...errorMessages,
            usage: t('createRequestPropDialog.errorMessage.usage.url')
          });

          return false;
        }
      } else if (usageValue === 1) {
        if (name === 'usage') {
          if (url?.includes('{0}')) {
            setErrorMessages({
              ...errorMessages,
              usage: t('createRequestPropDialog.errorMessage.usage.formatString')
            });

            return false;
          }
        } else if (!formatString?.includes('{0}')) {
          setErrorMessages({
            ...errorMessages,
            formatString: t('createRequestPropDialog.errorMessage.formatString.noKey')
          });

          return false;
        }
      }
    }

    return true;
  }

  function saveChanges() {
    if (!newProperty?.index) {
      if (groupRequestProps) {
        newProperty.index = Object.keys(groupRequestProps).length + 1;
      } else {
        newProperty.index = 1;
      }
    }

    if (propType === 'body') {
      if (requestProperty?.internalName) {
        const prop = { [requestProperty.internalName]: { ...newProperty } };

        onSaveChanges(requestProperties, prop, newInternalName);
      } else {
        const newProps = {};

        Object.keys(newProperty).map((key) => {
          if (key !== 'internalName') {
            newProps[key] = newProperty[key];
          }

          return null;
        });

        const prop = { [newProperty.internalName]: { ...newProps } };

        onSaveChanges(requestProperties, prop);
      }
    } else if (newProperty?.usage === 1) {
      const isValidForm = validateFieldUsage('save', newProperty.formatString);

      if (isValidForm) {
        onSaveChanges(newProperty, doubleNameProperty);
      }
    } else {
      onSaveChanges(newProperty, doubleNameProperty);
    }
  }

  function handleProperty(name, value) {
    setNewProperty({ ...newProperty, [name]: value });
  }

  function handleInternalName(value) {
    if (errorMessages && Object.keys(errorMessages).length) {
      const newErrorMessage = { ...errorMessages };

      delete newErrorMessage.internalName;
      delete newErrorMessage.doubleName;

      setErrorMessages(newErrorMessage);
    }

    const internalNameExist = checkIfInternalNameExists({
      ...newProperty,
      internalName: value
    });

    if (inputErrors?.length) {
      let doubleNameProp = null;

      if (propType === 'body') {
        const doubleNameError = inputErrors?.find(
          (err) =>
            err.inputId ===
              `${err.property[Object.keys(err.property)[0]]?.index}-txt-internalName` &&
            err.property &&
            err.property[Object.keys(err.property)[0]]?.index !== newProperty.index &&
            err.property[Object.keys(err.property)[0]]?.internalName === newProperty.internalName &&
            err.message
        );

        doubleNameProp = doubleNameError?.property;
      } else {
        const doubleNameError = inputErrors?.find(
          (err) =>
            err.inputId === `${propType}-${err.field?.index}-internalName-txt` &&
            err.field?.index !== newProperty.index &&
            err.field?.internalName === newProperty.internalName &&
            err.message
        );

        doubleNameProp = doubleNameError?.field;
      }

      if (doubleNameProp) {
        setDoubleNameProperty(doubleNameProp);
      }
    }

    let newInternalName = null;

    if (!internalNameExist) {
      newInternalName = value;
    } else {
      newInternalName = newProperty?.internalName;
    }

    if (propType === 'body') {
      setNewInternalName(newInternalName);
    }

    handleProperty('internalName', newInternalName);
  }

  function handleDefaultValue(value) {
    let error = false;

    if (value && (newProperty?.mappedField?.id || newProperty?.['x-ep-mappedField']?.id)) {
      error = true;
    }

    if (!error) {
      handleProperty(propType === 'body' ? 'default' : 'defaultValue', value);
    }
  }

  function handleMappedField(value) {
    if (errorMessages && Object.keys(errorMessages).length) {
      const newErrorMessage = { ...errorMessages };

      delete newErrorMessage.mappedField;

      setErrorMessages(newErrorMessage);
    }

    if (!forAutocomplete && newProperty?.isRequired && !value?.[0]?.id) {
      setErrorMessages({
        ...errorMessages,
        mappedField: t('createRequestPropDialog.errorMessage.requiredMappedField')
      });
    }

    handleProperty(propType === 'body' ? 'x-ep-mappedField' : 'mappedField', value?.[0]);
  }

  function handleType(value) {
    handleProperty('type', value.key);
  }

  function handleRequired(value) {
    if (errorMessages && Object.keys(errorMessages).length) {
      const newErrorMessage = { ...errorMessages };

      delete newErrorMessage.mappedField;

      setErrorMessages(newErrorMessage);
    }

    if (
      !forAutocomplete &&
      value &&
      !(newProperty?.mappedField?.id || newProperty?.['x-ep-mappedField']?.id)
    ) {
      setErrorMessages({
        ...errorMessages,
        mappedField: t('createRequestPropDialog.errorMessage.requiredMappedField')
      });
    }

    handleProperty('isRequired', value);
  }

  function handleDescription(value) {
    handleProperty('description', value);
  }

  function filterNameCharachters(passedString) {
    let newString = passedString;

    while (
      newString.charAt(0) === '?' ||
      newString.charAt(0) === '&' ||
      newString.charAt(0) === ' '
    ) {
      newString = newString.substring(1);
    }

    return newString;
  }

  function handleFormatString(value) {
    const filteredValue = filterNameCharachters(value);

    if (filteredValue !== value) {
      setErrorMessages({
        ...errorMessages,
        formatString: t('createRequestPropDialog.errorMessage.formatString.forbidden')
      });
    } else {
      if (errorMessages?.formatString) {
        validateFieldUsage('formatString', value);
      }

      handleProperty('formatString', value);
    }
  }

  function handleUsage(value) {
    const isUsageValid = validateFieldUsage('usage', value.key);

    if (isUsageValid) {
      handleProperty('usage', value.key);

      if (value.key !== 1 && newProperty?.formatString) {
        setNewProperty({ ...newProperty, formatString: null });
      }
    }
  }

  function getDialogStyles() {
    return {
      main: [
        {
          selectors: {
            '@media (min-width: 640px)': {
              position: 'absolute',
              right: '20px',
              width: '100%',
              minWidth: '550px',
              minHeight: '200px'
            },
            '@media (min-width: 1023px)': {
              position: 'absolute',
              right: '45px'
            },
            '@media (max-width: 640px)': {
              width: '100%',
              minWidth: '400px'
            },
            '@media (max-width: 480px)': [
              {
                minWidth: '250px',
                width: '100%',
                selectors: {
                  '.ms-Dialog-header': [
                    {
                      selectors: {
                        '.ms-Dialog-title': {
                          padding: '11px',
                          fontSize: '16px',
                          paddingRight: '40px'
                        },
                        'div[class^="topButton-"]': {
                          padding: '5px'
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        }
      ]
    };
  }

  function onRenderLabelWithInfo(props, infoText) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    const fieldProps = { ...props };

    return (
      <CustomLabelStyled>
        <span style={{ fontWeight: 600, color: fieldProps.disabled ? 'rgb(161, 159, 157)' : null }}>
          {fieldProps.label}
        </span>
        {!fieldProps.disabled && fieldProps.required ? (
          <span style={{ color: 'rgb(164, 38, 44)', marginLeft: '5px' }}>*</span>
        ) : null}
        <TooltipHost
          tooltipProps={{
            onRenderContent: () => (
              <RichTextEditor defaultValue={infoText} disabled displayCmdBar={false} />
            )
          }}
          directionalHint={DirectionalHint.topRightEdge}
          calloutProps={{ gapSpace: 5, target: `#${fieldProps.id}` }}
          styles={{
            root: { display: 'inline-block', maxWidth: 200 }
          }}
        >
          <InfoIcon iconName="Info" className="info-icon" disabled={fieldProps.disabled} />
        </TooltipHost>
      </CustomLabelStyled>
    );
  }

  return (
    <Dialog
      hidden={hidden}
      onDismiss={() => onDismiss()}
      styles={getDialogStyles}
      dialogContentProps={{
        type: DialogType.close,
        title: newProperty?.internalName
          ? t('createRequestPropDialog.title.editField')
          : t('createRequestPropDialog.title.newField')
      }}
      modalProps={{ isBlocking: true, isDarkOverlay: false }}
    >
      <TextField
        key={errorMessages?.internalName ? errorMessages?.doubleName : newProperty?.internalName}
        autoFocus
        required
        label={t('createRequestPropDialog.label.internalName')}
        onChange={(ev, value) => handleInternalName(value)}
        defaultValue={
          errorMessages?.internalName ? errorMessages?.doubleName : newProperty?.internalName
        }
        errorMessage={errorMessages?.internalName}
      />
      {propType !== 'query' ? (
        <TextField
          id="default-value"
          label={t('createRequestPropDialog.label.defaultValue')}
          onChange={(ev, value) => handleDefaultValue(value)}
          value={newProperty?.default || newProperty?.defaultValue}
          disabled={
            newProperty?.mappedField?.id ||
            newProperty?.['x-ep-mappedField']?.id ||
            newProperty?.isRequired
          }
          required={onlyDefault}
          errorMessage={errorMessages?.defaultValue}
          onRenderLabel={(props) =>
            onRenderLabelWithInfo(props, t('createRequestPropDialog.infoText.defaultValue'))
          }
        />
      ) : null}
      {!forAutocomplete && !onlyDefault ? (
        <FieldPickerContainer>
          <FieldPicker
            onChange={handleMappedField}
            selectedField={
              propType === 'body' ? newProperty?.['x-ep-mappedField'] : newProperty?.mappedField
            }
            label={t('createRequestPropDialog.label.mappedField')}
            infoText={t('createRequestPropDialog.infoText.mappedField')}
            required={newProperty?.isRequired}
            errorMessage={errorMessages?.mappedField}
            disabled={propType === 'body' ? !!newProperty?.default : !!newProperty?.defaultValue}
          />
        </FieldPickerContainer>
      ) : null}
      {propType === 'body' ? (
        <Dropdown
          required
          placeholder={t('createRequestPropDialog.placeholder.type')}
          label={t('createRequestPropDialog.label.type')}
          options={propertyTypeOptions}
          defaultSelectedKey={newProperty?.type || null}
          onChange={(ev, value) => handleType(value)}
        />
      ) : null}
      {propType === 'query' ? (
        <>
          <Dropdown
            id="query-field-usage"
            required
            placeholder={t('createRequestPropDialog.placeholder.usage')}
            label={t('createRequestPropDialog.label.usage')}
            options={propertyUsageOptions}
            defaultSelectedKey={newProperty?.usage || 0}
            onChange={(ev, value) => handleUsage(value)}
            errorMessage={errorMessages?.usage}
            onRenderLabel={(props) =>
              onRenderLabelWithInfo(props, t('createRequestPropDialog.label.usageInfo'))
            }
          />
          {newProperty?.usage === 1 ? (
            <TextField
              id="formatted-string"
              label={t('createRequestPropDialog.label.formattedString')}
              onChange={(ev, value) => handleFormatString(value)}
              defaultValue={newProperty?.formatString}
              errorMessage={errorMessages?.formatString}
              onRenderLabel={(props) =>
                onRenderLabelWithInfo(props, t('createRequestPropDialog.label.formattedStringInfo'))
              }
            />
          ) : null}
        </>
      ) : null}
      {!onlyDefault ? (
        <Checkbox
          key={newProperty?.isRequired}
          label={t('createRequestPropDialog.label.required')}
          checked={newProperty?.isRequired}
          onChange={(ev, checked) => handleRequired(checked)}
          className="centered"
          styles={{ root: { marginTop: 15, marginBottom: 10 } }}
          disabled={propType === 'body' ? !!newProperty?.default : !!newProperty?.defaultValue}
        />
      ) : null}
      <TextField
        label={t('createRequestPropDialog.label.description')}
        multiline
        autoAdjustHeight
        value={newProperty?.description}
        onChange={(ev, value) => handleDescription(value)}
      />
      <DialogFooter>
        <PrimaryButton
          text={t('createRequestPropDialog.primaryButton')}
          onClick={saveChanges}
          allowDisabledFocus
          disabled={
            (errorMessages && Object.keys(errorMessages).length) ||
            !(newProperty?.internalName && (propType !== 'body' || newProperty?.type)) ||
            (!forAutocomplete &&
              newProperty?.isRequired &&
              !(newProperty?.mappedField?.id || newProperty?.['x-ep-mappedField']?.id)) ||
            (onlyDefault && !(newProperty?.default || newProperty?.defaultValue))
          }
        />
        <DefaultButton
          text={t('createRequestPropDialog.defaultButton')}
          onClick={onDismiss}
          disabled={false}
          style={{ marginLeft: '10px' }}
        />
      </DialogFooter>
    </Dialog>
  );
}

CreateRequestPropDialog.propTypes = {
  requestProperty: PropTypes.object,
  groupRequestProps: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  onSaveChanges: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  hidden: PropTypes.bool,
  propType: PropTypes.string.isRequired,
  requestProperties: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  url: PropTypes.string,
  forAutocomplete: PropTypes.bool,
  onlyDefault: PropTypes.bool,
  inputErrors: PropTypes.arrayOf(PropTypes.object)
};

CreateRequestPropDialog.defaultProps = {
  requestProperty: null,
  groupRequestProps: null,
  hidden: false,
  requestProperties: null,
  url: null,
  forAutocomplete: null,
  onlyDefault: false,
  inputErrors: null
};

export default CreateRequestPropDialog;
