import AddRule from 'features/ProcessDesigner/components/StepPanel/Buttons/AddRule';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { Dropdown, IconButton } from '@fluentui/react';
import FieldPicker from '../FieldPicker';
import Rule from './Rule';

const FieldLinkStyled = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 5px;

  .field-name {
    width: 100%;
    margin-right: 5px;
  }

  .field-setting {
    min-width: 150px;
    max-width: 150px;
    margin-right: 5px;
  }

  .add-rule-button {
    min-width: 180px;
    max-width: 180px;
    margin-right: 5px;
  }
`;

function FieldLink({
  fieldLink,
  selectedFields,
  onDeleteField,
  onUpdateFieldLink,
  required,
  autoFocused,
  onBlur,
  disabled,
  fieldIndex,
  fieldLinksLength,
  onChangeFieldLinkOrder,
  groupFields
}) {
  const { t } = useTranslation();

  const [newRules, setNewRules] = useState(null);

  useEffect(() => {
    if (fieldLink?.ruleGroups) {
      setNewRules(fieldLink.ruleGroups);
    }
  }, [fieldLink]);

  const fieldSettingOptions = [
    {
      key: 1,
      text: t('formbuilder.field.setting.optional')
    },
    {
      key: 2,
      text: t('formbuilder.field.setting.required')
    }
  ];

  function handleFieldChange(value) {
    if (fieldLink) {
      const field = { ...fieldLink, field: value?.[0] };
      onUpdateFieldLink(field);
    }
  }

  function handleFieldSettingsChange(value) {
    if (fieldLink) {
      const field = { ...fieldLink, visibility: value };

      onUpdateFieldLink(field);
    }
  }

  function onChangeFieldOrder(increase) {
    const from = fieldIndex;
    const to = increase ? fieldIndex + 1 : fieldIndex - 1;
    onChangeFieldLinkOrder({ from, to });
  }

  function handleRulesUpdates(rules) {
    const field = { ...fieldLink, ruleGroups: rules };

    onUpdateFieldLink(field, true);
  }

  function onAddRule() {
    const newCondition = {
      isFocused: true,
      key: uuidv4()
    };

    const newRule = { makeRequired: fieldLink.visibility === 2, rules: [newCondition] };

    if (newRules) {
      setNewRules((prevState) => [...prevState, newRule]);
    } else {
      setNewRules([newRule]);
    }
  }

  function updateRuleGroup(ruleGroup, groupIndex, conditionsChanged) {
    const updatedRules = [...newRules];
    updatedRules[groupIndex] = ruleGroup;

    setNewRules(updatedRules);
    handleRulesUpdates(updatedRules, conditionsChanged);
  }

  function deleteRuleGroup(groupIndex) {
    const updatedRules = [...newRules];
    updatedRules.splice(groupIndex, 1);

    handleRulesUpdates(updatedRules);
  }

  function getRules() {
    if (newRules?.length) {
      let counter = 0;

      const rules = newRules.map((ruleGroup, index) => {
        const rule = (
          <Rule
            key={`rule-${counter}-${fieldLink?.field?.id}`}
            ruleGroup={ruleGroup}
            groupFields={groupFields}
            updateRuleGroup={(group) => updateRuleGroup(group, index)}
            deleteRuleGroup={() => deleteRuleGroup(index)}
          />
        );

        counter += 1;

        return rule;
      });

      return <div key={`conditions-${newRules?.length || 0}`}>{rules}</div>;
    }

    return null;
  }

  return (
    <div>
      <FieldLinkStyled>
        <div className="field-name">
          <FieldPicker
            onChange={handleFieldChange}
            selectedField={fieldLink?.field}
            required={required}
            disabled={disabled}
            selectedFields={selectedFields}
            autoFocused={autoFocused}
            onBlur={onBlur}
            selectedLanguage
          />
        </div>
        <div className="field-setting">
          {newRules?.length ? null : (
            <Dropdown
              options={fieldSettingOptions}
              disabled={disabled}
              defaultSelectedKey={fieldLink?.visibility || 1}
              onChange={(_, value) => {
                handleFieldSettingsChange(value.key);
              }}
            />
          )}
        </div>
        <div className="add-rule-button">
          <AddRule onAddRule={onAddRule} disabled={!fieldLink?.field?.id} />
        </div>
        <IconButton
          disabled={disabled || required}
          className="delete-icon-button"
          iconProps={{
            iconName: 'Delete',
            className: 'delete-icon',
            style: { color: !(disabled || required) ? 'red' : null, fontSize: '16px' }
          }}
          onClick={() => onDeleteField(fieldLink)}
        />
        <IconButton
          disabled={fieldIndex === 0 || disabled}
          iconProps={{
            iconName: 'Up',
            styles: { root: { color: fieldIndex === 0 ? '#c2c2c2' : '#5c5c5c' } }
          }}
          onClick={() => onChangeFieldOrder(false)}
          styles={{ rootDisabled: { backgroundColor: 'white' } }}
        />
        <IconButton
          disabled={fieldIndex + 1 === fieldLinksLength || disabled}
          iconProps={{
            iconName: 'Down',
            styles: {
              root: {
                color: fieldIndex + 1 === fieldLinksLength ? '' : '#5c5c5c'
              }
            }
          }}
          styles={{ rootDisabled: { backgroundColor: 'white' } }}
          onClick={() => onChangeFieldOrder(true)}
        />
      </FieldLinkStyled>
      {getRules()}
    </div>
  );
}

FieldLink.propTypes = {
  fieldLink: PropTypes.object,
  selectedFields: PropTypes.arrayOf(PropTypes.object),
  onDeleteField: PropTypes.func.isRequired,
  onUpdateFieldLink: PropTypes.func.isRequired,
  required: PropTypes.bool,
  autoFocused: PropTypes.bool,
  onBlur: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  fieldIndex: PropTypes.number,
  fieldLinksLength: PropTypes.number,
  onChangeFieldLinkOrder: PropTypes.func.isRequired,
  groupFields: PropTypes.arrayOf(PropTypes.object)
};

FieldLink.defaultProps = {
  fieldLink: null,
  selectedFields: null,
  required: false,
  autoFocused: false,
  disabled: false,
  fieldIndex: 0,
  fieldLinksLength: 1,
  groupFields: null
};

export default FieldLink;
