import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ProgressIndicator } from '@fluentui/react';
import { BackgroundJobStatus, IBackgroundJob, IJobProgressItem } from './model';
import { ShowError } from '../../components/ShowError';

const JobProgressItemsStyled = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  > .c-progress-item {
    display: grid;
    gap: 0.3em;
    margin: 0.5rem 0;

    > .c-label {
      &:empty {
        display: none;
      }
    }

    > .c-error {
      margin: 0;
    }
  }
`;

export function useJobProgress(job: IBackgroundJob) {
  const [progress, setProgress] = useState(() => job.getProgress());

  useEffect(() => {
    setProgress(job.getProgress());
    const onUpdate = () => setProgress(job.getProgress());
    job.addProgressUpdateListener(onUpdate);
    return () => job.removeProgressUpdateListener(onUpdate);
  }, [job]);

  return progress;
}

export interface IJobProgressItemsProps {
  job: IBackgroundJob;
  showFailedOnly?: 'auto' | boolean;
  showIndividualItems?: IProgressItemsProps['showIndividualItems'];
  className?: IProgressItemsProps['className'];
}

/** Renders ProgressItems by automatically subscribing to the job. */
export function JobProgressItems({
  job,
  showFailedOnly,
  showIndividualItems,
  className
}: IJobProgressItemsProps) {
  const progress = useJobProgress(job);
  return (
    <ProgressItems
      items={progress.items}
      showFailedOnly={
        showFailedOnly === 'auto' ? progress.status === BackgroundJobStatus.Done : showFailedOnly
      }
      showIndividualItems={showIndividualItems}
      className={className}
    />
  );
}

export interface IProgressItemsProps {
  items: IJobProgressItem[];
  /** Whether to show failed items only. */
  showFailedOnly?: boolean;
  /** Whether to show just a single progress bar, or show individual items. */
  showIndividualItems?: 'auto' | boolean;
  className?: string;
}

/** Renders job progress items either just as a progress indicator or as a list. */
export function ProgressItems({
  items,
  showFailedOnly = false,
  showIndividualItems = 'auto',
  className
}: IProgressItemsProps) {
  const showIndividualItemsAuto =
    showFailedOnly || items.length > 1 || !!items[0]?.error || !!items[0]?.label;
  const showIndividual =
    showIndividualItems === 'auto' ? showIndividualItemsAuto : showIndividualItems;

  return showIndividual ? (
    <JobProgressItemsStyled className={`${className || ''} is-multi-progress`}>
      {items
        .filter((item) => !showFailedOnly || item.error)
        .map((item, index) => (
          <BackgroundJobProgressItem
            item={item}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          />
        ))}
    </JobProgressItemsStyled>
  ) : (
    <ProgressIndicator
      className={`${className || ''} is-single-progress`}
      percentComplete={items[0]?.progress}
    />
  );
}

function BackgroundJobProgressItem({ item }: { item: IJobProgressItem }) {
  if (item.error) {
    return (
      <li className="c-progress-item is-error">
        <div className="c-label">{item.label}</div>
        <ShowError className="c-error" error={item.error} />
      </li>
    );
  }

  return (
    <li className="c-progress-item">
      <div className="c-label">{item.label}</div>
      <ProgressIndicator
        className="c-progress"
        percentComplete={item.progress}
        styles={{
          itemProgress: { padding: '0' }
        }}
      />
    </li>
  );
}
