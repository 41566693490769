import LongTextField from 'components/inputs/LongTextField';
import { useTranslation } from 'react-i18next';
import {
  IFieldLinkProps,
  ILongTextFieldAppendChangesProps,
  ILongTextFieldVersionProps,
  RouteFieldVisibility
} from 'types';
import { getFluentIconNameByFieldType } from 'utils/helpers';

export interface IDynamicLongTextFieldProps {
  disabled?: boolean;
  fieldLink: IFieldLinkProps;
  onChange: (
    value?: string | null | { currentValue?: string | null; versions: ILongTextFieldVersionProps[] }
  ) => void;
}

function DynamicLongTextField({ fieldLink, disabled, onChange }: IDynamicLongTextFieldProps) {
  const { t } = useTranslation();

  if (!fieldLink.field) return null;

  const { field, visibility, value } = fieldLink;
  const { name, description, descriptionPlacement, id } = field;
  let defaultValue = value as string | undefined | null;

  const displayDescriptionTooltip = descriptionPlacement === 1;

  const readOnly = visibility === RouteFieldVisibility.ReadOnly;
  const required = visibility === RouteFieldVisibility.Required;

  const context = disabled ? 'disabled' : undefined;

  let versions: ILongTextFieldVersionProps[] | undefined;

  // check if value is an object
  if (fieldLink.field?.appendChanges && typeof fieldLink.value !== 'string') {
    const fieldLinkValue = value as ILongTextFieldAppendChangesProps;

    defaultValue = fieldLinkValue?.currentValue;
    versions = fieldLinkValue?.versions || [];
  }

  return (
    <LongTextField
      id={id}
      defaultValue={defaultValue || undefined}
      disabled={disabled || readOnly}
      labelIconName={getFluentIconNameByFieldType(fieldLink.field)}
      required={required}
      label={name}
      onChange={(newValue) => {
        if (Array.isArray(versions)) {
          onChange({ currentValue: newValue, versions });
        } else {
          onChange(newValue);
        }
      }}
      descriptionPosition={displayDescriptionTooltip ? 'info-icon' : 'bottom'}
      description={description}
      versions={versions || undefined}
      placeholder={t('dynamicField.longText.placeholder', { context })}
    />
  );
}

export default DynamicLongTextField;
