export function getNumberOfRowsInColumn(processes, currentProcess) {
  const currentColumn = parseInt(currentProcess.processGrouping.split('.')[0], 10);
  const rowsNumbers = [];
  processes.map((p) => {
    const colNumber = parseInt(p.processGrouping.split('.')[0], 10);
    if (currentColumn === colNumber) {
      const rowNumber = parseInt(p.processGrouping.split('.')[1], 10);
      if (rowsNumbers) {
        const exists = rowsNumbers.find((element) => {
          return element === rowNumber;
        });
        if (exists === undefined) {
          rowsNumbers.push(rowNumber);
        }
      }
    }
    return rowsNumbers;
  });
  const numberOfRowsForCoreProcesses = rowsNumbers.length;
  return numberOfRowsForCoreProcesses;
}

export function getNumberOfSubColumnsInRow(processes, currentProcess) {
  const currProcessGrouping =
    currentProcess && currentProcess.processGrouping
      ? currentProcess.processGrouping.split('.')
      : null;
  const currentCol = currProcessGrouping ? parseInt(currProcessGrouping[0], 10) : null;
  const currentRow = currProcessGrouping ? parseInt(currProcessGrouping[1], 10) : null;
  const colNumbers = [];
  processes.map((p) => {
    const colNumber = parseInt(p.processGrouping.split('.')[0], 10);
    const rowNumber = parseInt(p.processGrouping.split('.')[1], 10);
    if (currentCol === colNumber && currentRow === rowNumber) {
      const subColNumber = parseInt(p.processGrouping.split('.')[2], 10);
      if (colNumbers) {
        const exists = colNumbers.find((element) => {
          return element === subColNumber;
        });
        if (exists === undefined) {
          colNumbers.push(subColNumber);
        }
      }
    }
    return colNumbers;
  });
  const numberOfSubColumnsForCoreProcesses = colNumbers.length;
  return numberOfSubColumnsForCoreProcesses;
}

// all values must pass in px without the 'px'
export const formatStringHorizontal = (
  someString,
  width,
  height,
  startX,
  startY,
  rowDistance,
  fontSize
) => {
  const words = someString.split(' ');
  const newStrings = [];
  const firstWordWidth = getWidthOfText(words[0], 'Segoe UI', `${fontSize}px`);
  if (firstWordWidth > width) {
    newStrings.push(shortenString(width, firstWordWidth, words[0]));
  } else {
    newStrings.push(words[0]);
  }
  for (let i = 1; i < words.length; i += 1) {
    const nextWord = words[i];
    const nextWordWidth = getWidthOfText(nextWord, 'Segoe UI', `${fontSize}px`);
    if (nextWordWidth > width) {
      newStrings.push(shortenString(width, nextWordWidth, nextWord));
    } else {
      const prevString = newStrings[newStrings.length - 1];
      const newString = `${prevString} ${nextWord}`;
      const newStringWidth = getWidthOfText(newString, 'Segoe UI', `${fontSize}px`);
      if (newStringWidth <= width) {
        newStrings[newStrings.length - 1] = newString;
      } else {
        newStrings.push(nextWord);
      }
    }
  }

  const decoratedText = [];
  let numberOfStrings = newStrings.length;

  if (numberOfStrings > 1 && height && height < numberOfStrings * (fontSize / 3)) {
    const extraRows = Math.ceil(numberOfStrings * (fontSize / 3) - height);
    for (let i = 0; i < extraRows; i += 1) {
      newStrings.pop();
    }
    const lastRow = newStrings[newStrings.length - 1];
    if (lastRow) {
      const newLastRowWidth = getWidthOfText(`${lastRow}...`, 'Segoe UI', `${fontSize}px`);
      if (newLastRowWidth > width) {
        const shortedLastRow = `${lastRow.substring(0, lastRow.length - 3)}.`;
        newStrings[newStrings.length - 1] = `${shortedLastRow}...`;
      } else {
        newStrings[newStrings.length - 1] = `${lastRow}...`;
      }
    }
  } else {
    const stringRowWidth = getWidthOfText(newStrings[0], 'Segoe UI', `${fontSize}px`);
    if (stringRowWidth > width) {
      const shortedStringRow = `${newStrings[0].substring(0, newStrings[0].length - 3)}.`;
      newStrings[0] = `${shortedStringRow}...`;
    } else {
      newStrings[0] = `${newStrings[0]}`;
    }
  }

  numberOfStrings = newStrings.length;
  const calcFactor = numberOfStrings / 2;
  let counter = 0;
  newStrings.map((s, index) => {
    counter += 1;
    let calcY = startY - (calcFactor - (index + 0.5)) * rowDistance;
    if (numberOfStrings === 1) {
      calcY = startY;
    }
    decoratedText.push(
      <tspan key={counter} y={calcY} x={startX}>
        {s}
      </tspan>
    );
    return decoratedText;
  });

  if (decoratedText.length > 0) {
    return decoratedText;
  }
  return <tspan>{someString}</tspan>;
};

export const formatStringVertical = (
  someString,
  width,
  height,
  startX,
  startY,
  rowDistance,
  fontSize
) => {
  const words = someString.split(' ');
  const newStrings = [];
  const firstWordWidth = getWidthOfText(words[0], 'Segoe UI', `${fontSize}px`);
  if (firstWordWidth > height) {
    newStrings.push(shortenString(height, firstWordWidth, words[0]));
  } else {
    newStrings.push(words[0]);
  }
  for (let i = 1; i < words.length; i += 1) {
    const nextWord = words[i];
    const nextWordWidth = getWidthOfText(nextWord, 'Segoe UI', `${fontSize}px`);
    if (nextWordWidth > height) {
      newStrings.push(shortenString(height, nextWordWidth, nextWord));
    } else {
      const prevString = newStrings[newStrings.length - 1];
      const newString = `${prevString} ${nextWord}`;
      const newStringWidth = getWidthOfText(newString, 'Segoe UI', `${fontSize}px`);
      if (newStringWidth <= height) {
        newStrings[newStrings.length - 1] = newString;
      } else {
        newStrings.push(nextWord);
      }
    }
  }
  const decoratedText = [];
  const numberOfStrings = newStrings.length;
  const calcFactor = numberOfStrings / 2;
  let counter = 0;
  newStrings.map((s, index) => {
    counter += 1;
    let calcY = startY - (calcFactor - (index + 0.5)) * rowDistance;
    if (numberOfStrings === 1) {
      calcY = startY;
    }
    decoratedText.push(
      <tspan key={counter} y={calcY} x={startX}>
        {s}
      </tspan>
    );
    return decoratedText;
  });

  if (width && width < numberOfStrings * (fontSize / 3)) {
    const extraRows = Math.ceil(width - numberOfStrings * (fontSize / 3));
    for (let i = 0; i < extraRows; i += 1) {
      newStrings.pop();
    }
    const lastRow = newStrings[newStrings.length - 1];
    const newLastRowWidth = getWidthOfText(`${lastRow}...`, 'Segoe UI', `${fontSize}px`);
    if (newLastRowWidth > height) {
      const shortedLastRow = `${lastRow.substring(0, lastRow.length - 3)}.`;
      newStrings[newStrings.length - 1] = `${shortedLastRow}...`;
    } else {
      newStrings[newStrings.length - 1] = `${lastRow}...`;
    }
  }

  if (decoratedText.length > 0) {
    return decoratedText;
  }
  return <tspan>{someString}</tspan>;
};

export function shortenString(width, wordWidth, word) {
  const characterWidth = wordWidth / word.length;
  const extraCharacters = (wordWidth - width) / characterWidth;
  const lastIndex = word.length - Math.ceil(extraCharacters);
  let shorterWord = word;
  if (lastIndex < 2) {
    shorterWord = word.substring(0, 1);
  } else {
    shorterWord = `${word.substring(0, lastIndex)}.`;
  }
  return shorterWord;
}

export function getWidthOfText(someString, fontName, fontSize) {
  const font = `${fontSize} ${fontName}`;
  if (getWidthOfText.c === undefined) {
    getWidthOfText.c = document.createElement('canvas');
    getWidthOfText.ctx = getWidthOfText.c.getContext('2d');
  }
  getWidthOfText.ctx.font = font;
  const stringWidth = getWidthOfText.ctx.measureText(someString).width;
  return stringWidth;
}
