import { CommandBarButton, Spinner, SpinnerSize } from '@fluentui/react';
import styled from 'styled-components';
import { useEffect, useState } from 'react';

const SubmitButtonIconContainer = styled.div`
  width: 16px;
  height: 16px;
  margin: 0 4px;
  display: grid;
  place-content: center;
`;

/** A command bar button that can replace its icon with a loading spinner when data.isLoading is true. */
export function SpinnerCommandBarButton(props) {
  return (
    <CommandBarButton
      {...props}
      onRenderIcon={(props, defaultRender) => (
        <SubmitButtonIconContainer>
          {props.data?.isLoading ? (
            <Spinner className="inner-spinner" size={SpinnerSize.small} />
          ) : (
            defaultRender(props)
          )}
        </SubmitButtonIconContainer>
      )}
    />
  );
}

/** Debounces true but immediately returns false */
export function useSpinnerCmdBarButtonDebouncedDisabled(disabled: boolean): [boolean, () => void] {
  const [value, setValue] = useState(disabled);

  useEffect(() => {
    const timeout = setTimeout(
      () => {
        setValue(disabled);
      },
      disabled ? 500 : 0
    );
    return () => clearTimeout(timeout);
  }, [disabled]);

  const forceUpdate = () => setValue(disabled);

  return [value, forceUpdate];
}
