import Label from 'components/inputs/Label';
import { DialogContentStyled } from 'components/surfaces/FieldForm/styles';
import { t } from 'i18next';
import React, { useState } from 'react';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { IFieldChoiceProps } from 'types';
import { v4 as uuidv4 } from 'uuid';
import {
  Checkbox,
  ChoiceGroup,
  DefaultButton,
  IChoiceGroupOption,
  IMaskedTextField,
  Icon,
  IconButton,
  TextField
} from '@fluentui/react';
import { IFormProps } from './FieldForm';

function ChoiceSettings({ field, setField, disabled, language }: IFormProps) {
  const [newChoiceText, setNewChoiceText] = useState('');
  const newChoiceTextfieldRef = React.useRef<IMaskedTextField>(null);

  const option: IChoiceGroupOption[] = [
    { key: '1', text: t('createField.dialog.field.choice.single') },
    { key: '2', text: t('createField.dialog.field.choice.multi') }
  ];
  function onChoiceFormatChange() {
    setField((prevState) => ({ ...prevState, choiceFormat: prevState.choiceFormat === 1 ? 2 : 1 }));
  }
  function updateChoice() {
    setField((prevState) => {
      return { ...prevState, allowFillIn: !prevState.allowFillIn };
    });
  }

  function addChoiceItem(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const newChoice = { id: uuidv4(), value: newChoiceText, allValues: [] };

    setField((prevState) => {
      if (prevState.choices) {
        return { ...prevState, choices: [...prevState.choices, newChoice] };
      }

      return { ...prevState, choices: [newChoice] };
    });

    setNewChoiceText('');

    newChoiceTextfieldRef.current?.focus();
  }

  function getItemStyle(isDragging: boolean, index: number) {
    const styles: React.CSSProperties = { background: 'white' };
    const length = field.choices?.length || 0;
    const isLastItem = index === length - 1;

    if (!isLastItem && !isDragging) {
      styles.borderBottom = '1px solid #ababab';
    }

    if (isDragging) {
      styles.border = '1px solid #ababab';
      styles.borderRadius = 4;
    }

    return styles;
  }

  function onDeleteChoiceItem(id: string) {
    setField((prevState) => {
      const newChoices = prevState.choices?.filter((item) => item.id !== id);

      return { ...prevState, choices: newChoices };
    });
  }

  // Add the translated value of choices into the choices array
  function checkAddTranslation(choice: IFieldChoiceProps, newValue: string) {
    const newChoice = { ...choice };
    // if language selected
    if (language) {
      // Try to found an existing language index
      const translationIndex = newChoice.allValues.findIndex((lan) => lan.language === language);
      // If there's no existing language index, Add it
      if (translationIndex === -1) {
        const translations = newChoice.allValues || [];
        translations.push({ language, text: newValue });

        return { ...newChoice, allValues: translations };
      }

      // If there's an existing language, update the text value
      if (translationIndex > -1) {
        newChoice.allValues[translationIndex].text = newValue || '';

        return newChoice;
      }
    }

    return null;
  }

  function onChoiceTextChange(choice: IFieldChoiceProps, value: string) {
    let newChoice = checkAddTranslation(choice, value);

    if (!newChoice) {
      newChoice = { ...choice };
      newChoice.value = value;
    }

    setField((prevState) => {
      const choices = prevState.choices?.map((choice) =>
        choice.id === newChoice?.id ? newChoice : choice
      );

      return { ...prevState, choices };
    });
  }

  function getDefaultFieldOKValue(choice: IFieldChoiceProps) {
    if (!language) return choice.value;

    const translatedLanguageText = choice.allValues.find(
      (choiceLan) => choiceLan.language === language
    );

    return translatedLanguageText?.text || choice.value;
  }

  function onRenderChoice(choice: IFieldChoiceProps, index: number) {
    return (
      <Draggable key={choice.id} draggableId={choice.id} index={index} isDragDisabled={false}>
        {({ innerRef, dragHandleProps, draggableProps }, { isDragging }) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <div ref={innerRef} {...dragHandleProps} {...draggableProps} style={draggableProps.style}>
            <div style={getItemStyle(isDragging, index)} className="c-choice-container">
              <Icon
                style={{ fontSize: '20px', paddingRight: '2px', color: '#b4b4b4' }}
                iconName="GripperDotsVertical"
              />
              <TextField
                key={`option-${choice.id}-${language || ''}`}
                onRenderSuffix={() => (
                  <IconButton
                    onClick={() => onDeleteChoiceItem(choice.id)}
                    iconProps={{ iconName: 'Clear', styles: { root: { fontSize: '12px' } } }}
                    disabled={disabled}
                  />
                )}
                onChange={(_, value) => onChoiceTextChange(choice, value || '')}
                styles={{ suffix: { backgroundColor: 'transparent' }, root: { width: '100%' } }}
                borderless
                underlined={false}
                defaultValue={getDefaultFieldOKValue(choice)}
              />
            </div>
          </div>
        )}
      </Draggable>
    );
  }
  function reorder(list: IFieldChoiceProps[], sourceIndex: number, destinationIndex: number) {
    const result = Array.from(list);
    const [removed] = result.splice(sourceIndex, 1);

    result.splice(destinationIndex, 0, removed);

    return result;
  }
  function onDragEnd(result: DropResult) {
    if (!result.destination) return;

    const choiceItems = reorder(field.choices || [], result.source.index, result.destination.index);

    setField((prevState) => ({ ...prevState, choices: choiceItems }));
  }

  return (
    <DialogContentStyled>
      <ChoiceGroup
        key={`choiceFormat-${field?.fieldType}-${field?.choiceFormat}`}
        defaultSelectedKey={field.choiceFormat?.toString() || '1'}
        options={option}
        onChange={onChoiceFormatChange}
        label={t('createField.dialog.field.choice.label')}
        required
        disabled={disabled}
      />
      <Checkbox
        key={`allowFillIn-${field?.fieldType}-${field?.allowFillIn}}`}
        label={t('createField.dialog.allowFillInOption.checkbox.label')}
        className="chkAllowFillInn"
        defaultChecked={field.allowFillIn}
        onChange={updateChoice}
        styles={{ root: { marginTop: '15px', marginBottom: '15px' } }}
        disabled={disabled}
      />
      <Label required label={t('createField.dialog.field.choices.list.label')} />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable isDropDisabled={false} droppableId="choices-droppable">
          {(droppableProvided) => (
            <div
              style={{ overflowY: 'auto', height: '100%', maxHeight: 250 }}
              ref={droppableProvided.innerRef}
            >
              {field.choices && field.choices.length > 0 && field.choices.map(onRenderChoice)}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <form onSubmit={addChoiceItem}>
        <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
          <TextField
            disabled={disabled}
            componentRef={newChoiceTextfieldRef}
            placeholder={t('createField.dialog.add.textfield.placeholder')}
            value={newChoiceText || ''}
            onChange={(ev, value) => setNewChoiceText(value || '')}
            autoComplete="off"
            styles={{ root: { width: '50%' } }}
          />
          <DefaultButton
            styles={{ root: { minWidth: '108px' } }}
            text={t('createField.dialog.add.button.text')}
            type="submit"
            disabled={!newChoiceText}
          />
        </div>
      </form>
    </DialogContentStyled>
  );
}

export default ChoiceSettings;
