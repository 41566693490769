import { createContext, useCallback, useMemo, useState } from 'react';
import { unified } from 'unified';
import remarkParse from 'remark-parse';
import type { Node } from 'mdast';
import {
  ChatItemType,
  IChatItem,
  MessageFragmentType
} from '../../../services/ChatConnection/model';
import { REMARK_PLUGINS } from './markdown';
import { EcReferenceMdastNode, REF_NODE_ID } from './chatMessageReferenceSyntax';

export interface ISourceItemWebUrl {
  type: 'web-url';
  url: string;
  /** LLM is still writing it */
  isIncomplete: boolean;
}

export type ISourceItem = ISourceItemWebUrl;

/**
 * Provides a context for referencing sources in the message body.
 */
export interface IChatItemReferenceContext {
  orderedSources: ISourceItem[];
  /**
   * If we have a web search fragment, we can determine which sources weren't actually in the search results.
   *
   * Items will be a subset of orderedSources (including === identity)
   */
  unexpectedSources: ISourceItem[];

  highlighted: ISourceItem | null;

  /** Sets an item as highlighted. */
  setHighlighted(highlighted: ISourceItem): void;
  /** Clears the currently highlighted item if it is this one. */
  clearHighlighted(highlighted: ISourceItem): void;
}

export const ChatItemReferenceContext = createContext<IChatItemReferenceContext>({
  orderedSources: [],
  unexpectedSources: [],
  highlighted: null,
  setHighlighted: () => null,
  clearHighlighted: () => null
});

function findAllReferences(tree: Node, handleReference: (reference: EcReferenceMdastNode) => void) {
  const visit = (node: Node) => {
    if (node.type === REF_NODE_ID) {
      handleReference(node as EcReferenceMdastNode);
    } else if ('children' in node) {
      const node2 = node as unknown as { children: Node[] };
      node2.children.forEach(visit);
    }
  };
  visit(tree);
}

function sourceEq(a: ISourceItem, b: ISourceItem) {
  if (a.type !== b.type) return false;

  if (a.type === 'web-url') {
    return a.url === b.url;
  }

  return false;
}

function getChatItemReferences(
  chatItem: IChatItem
): Pick<IChatItemReferenceContext, 'orderedSources' | 'unexpectedSources'> {
  if (
    chatItem.type === ChatItemType.AssistantGenerating ||
    chatItem.type === ChatItemType.AssistantCompleted
  ) {
    const orderedSources: ISourceItem[] = [];
    const foundSources: ISourceItem[] = [];
    let canDetermineUnexpectedSources = false;

    for (const fragment of chatItem.fragments) {
      if (fragment.type === MessageFragmentType.Text) {
        const tree: Node = unified().use(remarkParse).use(REMARK_PLUGINS).parse(fragment.text);

        findAllReferences(tree, (ref) => {
          if (!orderedSources.some((item) => item.type === 'web-url' && item.url === ref.url)) {
            orderedSources.push({ type: 'web-url', url: ref.url, isIncomplete: ref.containsEof });
          }
        });
      } else if (fragment.type === MessageFragmentType.WebSearch) {
        canDetermineUnexpectedSources = true;

        for (const url of fragment.resultUrls) {
          if (!foundSources.some((item) => item.type === 'web-url' && item.url === url)) {
            foundSources.push({ type: 'web-url', url, isIncomplete: false });
          }
        }
      }
    }

    const unexpectedSources: ISourceItem[] = [];

    if (canDetermineUnexpectedSources) {
      for (const source of orderedSources) {
        if (source.isIncomplete) continue;

        if (!foundSources.some((item) => sourceEq(item, source))) {
          unexpectedSources.push(source);
        }
      }
    }

    return { orderedSources, unexpectedSources };
  }

  return {
    orderedSources: [],
    unexpectedSources: []
  };
}

export function useChatItemReferences(chatItem: IChatItem): IChatItemReferenceContext {
  const [highlightedItem, setHighlightedItem] = useState<ISourceItem | null>(null);
  const { orderedSources, unexpectedSources } = useMemo(
    () => getChatItemReferences(chatItem),
    [chatItem]
  );

  const setHighlighted = useCallback((item: ISourceItem) => {
    setHighlightedItem(item);
  }, []);
  const clearHighlighted = useCallback((item: ISourceItem) => {
    setHighlightedItem((prevState) => (prevState === item ? null : prevState));
  }, []);

  return useMemo(
    () => ({
      orderedSources,
      unexpectedSources,
      highlighted: highlightedItem,
      setHighlighted,
      clearHighlighted
    }),
    [clearHighlighted, highlightedItem, orderedSources, setHighlighted, unexpectedSources]
  );
}
