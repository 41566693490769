import PropTypes from 'prop-types';
import styled, { useTheme } from 'styled-components';
import { CommandBarButton, Icon } from '@fluentui/react';

// data-icon-name="ChevronDown"
const ButtonWrapper = styled.span`
  [data-icon-name='ChevronDown'] {
    display: none;
  }
`;

function HeaderCmdBarButton({
  iconName,
  title,
  onClickMethod,
  styles,
  badgeText,
  badgeIcon,
  menuProps
}) {
  const theme = useTheme();

  return (
    <ButtonWrapper style={{ position: badgeText || badgeIcon ? 'relative' : null }}>
      <CommandBarButton
        key={`${iconName}-key`}
        menuProps={menuProps}
        splitButtonMenuProps={{ styles: { root: { display: 'none' } } }}
        ariaLabel={title}
        styles={{
          root: {
            backgroundColor: 'transparent',
            transition: 'background-color 0.5s ease',
            width: '45px',
            height: '100%',
            ...styles?.commandBarButton?.root
          },
          rootHovered: {
            backgroundColor: `rgb(${theme.header.foreground} / 0.1)`,
            ...styles?.commandBarButton?.rootHovered
          },
          rootPressed: {
            backgroundColor: `rgb(${theme.header.foreground} / 0.2)`,
            ...styles?.commandBarButton?.rootPressed
          },
          iconHovered: {
            color: `rgb(${theme.header.foreground})`,
            ...styles?.commandBarButton?.iconHovered
          },
          iconPressed: {
            color: `rgb(${theme.header.foreground})`,
            ...styles?.commandBarButton?.iconPressed
          },
          iconExpanded: {
            color: `rgb(${theme.header.foreground})`,
            ...styles?.commandBarButton?.iconExpanded
          },
          rootExpanded: {
            backgroundColor: `rgb(${theme.header.foreground} / 0.2)`
          },
          rootExpandedHovered: {
            backgroundColor: `rgb(${theme.header.foreground} / 0.2)`
          }
        }}
        title={title}
        iconProps={{
          iconName,
          styles: {
            root: {
              color: `rgb(${theme.header.foreground})`,
              fontSize: '15px',
              ...styles?.commandBarButton?.iconProps?.root
            }
          }
        }}
        onClick={onClickMethod}
      />
      {badgeText ? <span className="header-badge">{badgeText}</span> : null}
      {badgeIcon ? <Icon className="header-badge-icon" iconName={badgeIcon} /> : null}
    </ButtonWrapper>
  );
}

HeaderCmdBarButton.propTypes = {
  iconName: PropTypes.string.isRequired,
  menuProps: PropTypes.objectOf(PropTypes.any),
  onClickMethod: PropTypes.func.isRequired,
  styles: PropTypes.objectOf(PropTypes.any),
  title: PropTypes.string,
  badgeText: PropTypes.string,
  badgeIcon: PropTypes.string
};

HeaderCmdBarButton.defaultProps = {
  styles: null,
  title: null,
  badgeText: null,
  badgeIcon: null
};

export default HeaderCmdBarButton;
