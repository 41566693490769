import { useTranslation } from 'react-i18next';
import { Icon } from '@fluentui/react';

function NoAccessContainer() {
  const { t } = useTranslation();
  return (
    <div className="m-5" style={{ margin: '3em' }}>
      <Icon
        iconName="Blocked"
        className="no-access-icon"
        style={{
          fontSize: '5em',
          fontWeight: 'bold',
          color: 'red',
          marginRight: '0.3em',
          float: 'left'
        }}
      />
      <div className="text-center ms-fontSize-xxl ms-fontWeight-semibold mb-1">
        {t('noAccess.message.noAccessText')}
      </div>
      <div className="text-center ms-fontWeight-semibold">{t('noAccess.message.contactAdmin')}</div>
    </div>
  );
}

export default NoAccessContainer;
