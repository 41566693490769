import { BLUR_COMMAND, COMMAND_PRIORITY_LOW, FOCUS_COMMAND } from 'lexical';
import { useLayoutEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';

function hasParentWithId(element: HTMLElement | null, id: string): boolean {
  if (!element) return false;
  if (element.id === id) return true;
  return hasParentWithId(element.parentElement, id);
}

export default function FocusBlurPlugin({
  onFocus,
  onBlur,
  id
}: {
  onFocus?: () => void;
  onBlur?: () => void;
  id?: string;
}) {
  const [editor] = useLexicalComposerContext();

  useLayoutEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        FOCUS_COMMAND,
        () => {
          onFocus?.();
          return false;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        BLUR_COMMAND,
        (e) => {
          const relatedTarget = e.relatedTarget as HTMLElement;

          if (hasParentWithId(relatedTarget, 'rte-toolbar') || relatedTarget?.id === `link-text`) {
            return false;
          }

          onBlur?.();
          return false;
        },
        COMMAND_PRIORITY_LOW
      )
    );
  }, [editor, onBlur, onFocus]);

  function focusEditor() {
    editor.focus();
  }

  return (
    <input
      style={{ display: 'none' }}
      id={`input-${id}`}
      onClick={() => {
        setTimeout(() => focusEditor(), 1);
      }}
    />
  );
}
