import { createBrowserHistory } from 'history';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const history = createBrowserHistory();

const { connectionString, instrumentationKey } = window.config;

const reactPlugin = instrumentationKey || connectionString ? new ReactPlugin() : null;

const ai = instrumentationKey
  ? new ApplicationInsights({
      config: {
        instrumentationKey,
        connectionString,
        maxBatchInterval: 0,
        disableFetchTracking: false,
        extensions: [reactPlugin],
        extensionConfig: { [reactPlugin.identifier]: { history } }
      }
    })
  : null;

if (ai) {
  ai.loadAppInsights();
}

export default (Component) => withAITracking(reactPlugin, Component);
export { ai };
export { reactPlugin };
