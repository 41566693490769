import { t } from 'i18next';
import { PersonFieldFormat } from 'types/enums';
import { Checkbox, Dropdown, IDropdownOption } from '@fluentui/react';
import { IFormProps } from './FieldForm';

function PersonSettings({ field, setField, disabled }: IFormProps) {
  function onSearchAadLabelChange(_: unknown, checked?: boolean | undefined) {
    setField((prevState) => ({ ...prevState, searchAAD: checked }));
  }

  function onConnectUserAndTeamChange() {
    setField((prevState) => ({ ...prevState, connectUserAndTeam: !prevState.connectUserAndTeam }));
  }

  function onPersonFieldFormatChange(_: unknown, value?: IDropdownOption | undefined) {
    const personFieldFormat = Number(value?.key) || 1;

    setField((prevState) => {
      const connectUserAndTeam = personFieldFormat === 1 ? prevState.connectUserAndTeam : false;

      return { ...prevState, personFieldFormat, connectUserAndTeam };
    });
  }

  const personFieldFormat = field?.personFieldFormat || 1;

  function renderSearchAadCheckbox() {
    if (
      field.personFieldFormat === PersonFieldFormat.singleTeam ||
      field.personFieldFormat === PersonFieldFormat.multipleTeams
    ) {
      return null;
    }

    return (
      <Checkbox
        label={t('createField.dialog.personField.searchAad.label')}
        checked={field.searchAAD}
        onChange={onSearchAadLabelChange}
        styles={{ root: { marginTop: '15px' } }}
        disabled={disabled}
      />
    );
  }

  return (
    <>
      <div style={{ display: 'flex' }}>
        <Dropdown
          disabled={disabled}
          required
          label={t('createField.dialog.personField.personFieldFormat.label')}
          selectedKey={personFieldFormat || 1}
          styles={{ root: { minWidth: '50%' } }}
          onChange={onPersonFieldFormatChange}
          options={[
            { key: 1, text: t(`createField.dialog.personField.personFieldFormat.${1}`) },
            { key: 2, text: t(`createField.dialog.personField.personFieldFormat.${2}`) },
            { key: 3, text: t(`createField.dialog.personField.personFieldFormat.${3}`) },
            { key: 4, text: t(`createField.dialog.personField.personFieldFormat.${4}`) },
            { key: 5, text: t(`createField.dialog.personField.personFieldFormat.${5}`) }
          ]}
        />
        {personFieldFormat === 1 && (
          <Checkbox
            label={t('createField.dialog.personField.connectUserAndTeam.label')}
            checked={field.connectUserAndTeam}
            onChange={onConnectUserAndTeamChange}
            styles={{ root: { marginTop: '35px', marginLeft: 30 } }}
            disabled={disabled}
          />
        )}
      </div>
      {renderSearchAadCheckbox()}
    </>
  );
}

export default PersonSettings;
