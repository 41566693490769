import {
  CSSProperties,
  DragEvent,
  forwardRef,
  useImperativeHandle,
  useRef,
  ReactNode
} from 'react';
import { IBlobFile } from 'types';

const mimeTypeRegexp =
  /^(application|audio|example|image|message|model|multipart|text|video)\/[a-z0-9.+*-]+$/;
const extRegexp = /\.[a-zA-Z0-9]*$/;

export interface IFileManagement {
  openFileChooser: () => void;
}

export interface IFileManagementProps {
  /**
   * Specify what file types the user can pick from the file input dialog box.
   */
  accepts?: string[] | null;
  /**
   * Children of the component.
   */
  children: ReactNode;
  /**
   * Classnames for the dropzone.
   * @defaultvalue 'files-dropzone'
   */
  className?: string;
  /**
   * Whether the dropzone is clickable.
   * @defaultvalue true
   */
  clickable?: boolean;
  /**
   * Classnames for the active dropzone.
   * @defaultvalue 'files-dropzone-active'
   */
  dropActiveClassName?: string;
  /**
   * Callback issued when a file is added.
   */
  onChange: (files: IBlobFile[]) => void;
  /**
   * Callback issued when an error occurs.
   */
  onError?: (error: { code: number; message: string }, file: File) => void;
  /**
   * When true, it specifies that the user is allowed to enter more than one file
   * @defaultvalue false
   */
  multiple: boolean;
  /**
   * Maximum number of files allowed
   * @defaultvalue 15
   */
  maxFiles?: number;
  /**
   * Maximum file size allowed (in bytes)
   * @defaultvalue 209715200 (200mb)
   */
  maxFileSize?: number;
  /**
   * Minimum file size allowed (in bytes)
   * @defaultvalue 0
   */
  minFileSize?: number;
  /**
   * Specifies the name of the input element
   * @defaultvalue 'file'
   */
  name?: string;
  /**
   * Define style information (CSS) for the dropzone
   */
  style?: CSSProperties;
  disabled?: boolean;
  id?: string;
}

// eslint-disable-next-line react/display-name
const FileManagement = forwardRef(
  (
    {
      accepts = null,
      disabled = false,
      children,
      className = 'files-dropzone',
      clickable = true,
      dropActiveClassName = 'files-dropzone-active',
      maxFileSize = Infinity,
      maxFiles = Infinity,
      minFileSize = 0,
      multiple = true,
      name = 'file',
      onChange,
      onError,
      style,
      id = 'file-input'
    }: IFileManagementProps,
    ref
  ) => {
    useImperativeHandle(ref, () => ({
      openFileChooser() {
        onOpenFileChooser();
      }
    }));

    const dropzone = useRef<HTMLDivElement>(null);
    const inputElement = useRef<HTMLInputElement>(null);

    function isDragEvent(
      event: DragEvent<HTMLDivElement> | React.ChangeEvent<HTMLInputElement>
    ): event is DragEvent<HTMLDivElement> {
      return (event as DragEvent<HTMLDivElement>).dataTransfer !== undefined;
    }

    function onDrop(event: DragEvent<HTMLDivElement> | React.ChangeEvent<HTMLInputElement>): void {
      event.preventDefault();
      onDragLeave();

      if (!disabled) {
        // Collect added files, perform checking, cast pseudo-array to Array,
        // then return to method
        let filesAdded: File[] = [];

        if (isDragEvent(event)) {
          filesAdded = Array.from(event.dataTransfer.files);
        } else if (event.target.files) {
          filesAdded = Array.from(event.target.files);
        }

        // Multiple files dropped when not allowed
        if (!multiple && filesAdded && filesAdded.length > 1) {
          filesAdded = [filesAdded[0]];
        }

        const newFiles: IBlobFile[] = [];

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < filesAdded.length; i++) {
          let file: IBlobFile = filesAdded[i];

          // Adjust file name if its on iphone images
          file = new File([file], getFileNameForIphoneImages(file), {
            type: file.type
          });

          // Tell file it's own extension
          file.extension = fileExtension(file);

          // Tell file it's own readable size
          file.sizeReadable = fileSizeReadable(file.size);

          // Add preview, either image or file extension
          if (file.type && mimeTypeLeft(file.type) === 'image') {
            file.preview = {
              type: 'image',
              url: window.URL.createObjectURL(file)
            };
          } else {
            file.preview = {
              type: 'file'
            };
          }

          // Check for file max limit
          if (newFiles.length >= maxFiles) {
            handleError({ code: 4, message: 'maximum file count reached' }, file);
            break;
          }

          // If file is acceptable, push or replace
          if (fileTypeAcceptable(file) && fileSizeAcceptable(file)) {
            newFiles.push(file);
          }
        }

        onChange(newFiles);
      }
    }

    function onDragOver(event: DragEvent<HTMLDivElement>): void {
      event.preventDefault();
      event.stopPropagation();
    }

    function onDragEnter(): void {
      const el = dropzone;

      if (el.current && !disabled) {
        el.current.className += ` ${dropActiveClassName}`;
      }
    }

    function onDragLeave(): void {
      const el = dropzone;

      if (dropzone.current && el.current) {
        dropzone.current.className = el.current.className.replace(` ${dropActiveClassName}`, '');
      }
    }

    function onOpenFileChooser(): void {
      if (inputElement?.current && !disabled) {
        inputElement.current.value = '';
        inputElement.current.click();
      }
    }

    function fileTypeAcceptable(file: IBlobFile): boolean {
      if (!accepts) {
        return true;
      }

      const result = accepts.some((accept) => {
        if (file.type && accept.match(mimeTypeRegexp)) {
          const typeLeft = mimeTypeLeft(file.type);
          const typeRight = mimeTypeRight(file.type);

          const acceptLeft = accept.split('/')[0];
          const acceptRight = accept.split('/')[1];

          if (acceptLeft && acceptRight) {
            if (acceptLeft === typeLeft && acceptRight === '*') {
              return true;
            }

            if (acceptLeft === typeLeft && acceptRight === typeRight) {
              return true;
            }
          }
        }

        if (file.extension && accept.match(extRegexp)) {
          const ext = accept.substr(1);

          return file.extension.toLowerCase() === ext.toLowerCase();
        }

        return false;
      });

      if (!result) {
        handleError({ code: 1, message: `${file.name} is not a valid file type` }, file);
      }

      return result;
    }

    function fileSizeAcceptable(file: File): boolean {
      if (file.size > maxFileSize) {
        handleError({ code: 2, message: `${file.name} is too large` }, file);

        return false;
      }

      if (file.size < minFileSize) {
        handleError({ code: 3, message: `${file.name} is too small` }, file);

        return false;
      }

      return true;
    }

    function mimeTypeLeft(mime: string): string {
      return mime.split('/')[0];
    }

    function mimeTypeRight(mime: string): string {
      return mime.split('/')[1];
    }

    function fileExtension(file: File): string {
      const extensionSplit = file.name.split('.');

      if (extensionSplit.length > 1) {
        return extensionSplit[extensionSplit.length - 1];
      }

      return 'none';
    }

    function getFileNameForIphoneImages(file: File): string {
      // check if on iphone
      if (navigator.userAgent.match(/iPhone/i)) {
        const extensionSplit = file.name.split('.');

        if (extensionSplit.length > 1) {
          const name = extensionSplit[0];

          if (name === 'image') {
            return `${name}_${file.lastModified}.${fileExtension(file)}`;
          }
        }
      }

      return file.name;
    }

    function fileSizeReadable(size: number): string {
      if (size >= 1000000000) {
        return `${Math.ceil(size / 1000000000)} GB`;
      }

      if (size >= 1000000) {
        return `${Math.ceil(size / 1000000)} MB`;
      }

      if (size >= 1000) {
        return `${Math.ceil(size / 1000)} kB`;
      }

      return `${Math.ceil(size)} B`;
    }

    function handleError(error: { code: number; message: string }, file: File): void {
      if (onError) {
        onError(error, file);
      }
    }

    function onClick(): void {
      if (clickable) {
        onOpenFileChooser();
      }
    }

    return (
      <div className="files-wrapper">
        <input
          accept={accepts?.length ? accepts.join() : ''}
          multiple={multiple}
          name={name}
          onChange={onDrop}
          ref={inputElement}
          style={{ display: 'none' }}
          id={id}
          type="file"
        />
        <div
          aria-hidden="true"
          className={className}
          onClick={onClick}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDragOver={onDragOver}
          onDrop={onDrop}
          ref={dropzone}
          style={style}
        >
          {children}
        </div>
      </div>
    );
  }
);

export default FileManagement;
