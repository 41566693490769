import { useTranslation } from 'react-i18next';
/* eslint-disable react/jsx-props-no-spreading */
import {
  ContextualMenu,
  ContextualMenuItemType,
  DirectionalHint,
  IContextualMenuProps
} from '@fluentui/react';
import { IFilterProps } from './DetailsList';
import FilterBox from './FilterBox/FilterBox';

export interface IContextualHeaderMenuProps extends IContextualMenuProps, IFilterProps {
  sortable?: boolean;
  onSortDescending?: () => void;
  onSortAscending?: () => void;
  isSortedDescending?: boolean;
  isSortedAscending?: boolean;
}

function ContextualHeaderMenu(props: IContextualHeaderMenuProps) {
  const {
    items,
    sortable,
    filterItems,
    getFilterItems,
    onSortDescending,
    onSortAscending,
    isSortedAscending,
    isSortedDescending,
    singleSelectFilter,
    onRenderFilterItem,
    defaultFilterValues,
    onFilterChange
  } = props;

  const { t } = useTranslation();

  const itemsClone = [];

  if (sortable) {
    itemsClone.unshift(
      {
        key: 'aToZ',
        text: t('contextualMenu.sort.ascending'),
        iconProps: { iconName: 'Ascending' },
        canCheck: true,
        checked: isSortedAscending,
        onClick: onSortAscending
      },
      {
        key: 'zToA',
        text: t('contextualMenu.sort.descending'),
        iconProps: { iconName: 'Descending' },
        canCheck: true,
        checked: isSortedDescending,
        onClick: onSortDescending
      }
    );
  }

  if (filterItems?.length || getFilterItems) {
    itemsClone.push({
      key: 'divider_1',
      itemType: ContextualMenuItemType.Divider
    });
  }

  itemsClone.push({
    key: 'filterBox',
    onRender: () => (
      <FilterBox
        filterItems={filterItems}
        singleSelect={singleSelectFilter}
        defaultSelectedKeys={defaultFilterValues}
        onRenderFilterItem={onRenderFilterItem}
        getFilterItems={getFilterItems}
        onChange={(selectedValues, value) => {
          if (onFilterChange) {
            onFilterChange(selectedValues, value);
          }
        }}
      />
    )
  });

  if (items?.length > 0) {
    itemsClone.push(...items);
  }

  return (
    <ContextualMenu
      directionalHint={DirectionalHint.bottomLeftEdge}
      gapSpace={0}
      calloutProps={{ styles: { calloutMain: { borderRadius: 8 } } }}
      styles={{ root: { minWidth: '200px', maxWidth: '400px', maxHeight: '700px' } }}
      {...props}
      items={itemsClone}
    />
  );
}

export default ContextualHeaderMenu;
