import { createContext, ReactNode } from 'react';
import { ErrorLike } from '../../components/ShowError';
import { isInMsTeamsEnvironment } from '../../utils/helpers';

export interface IJobProgressItem {
  /** Item label. Can be null if redundant with progress title. */
  label: ReactNode | null;
  /** Progress on this item in [0, 1]. Null means indeterminate. */
  progress: number | null;
  error: ErrorLike | null;
}

export enum BackgroundJobStatus {
  Pending = 'pending',
  Done = 'done',
  Failed = 'failed'
}

/** Total progress on a background job. */
export interface IJobProgress {
  icon?: ReactNode;
  iconName?: string;
  title: ReactNode;
  status: BackgroundJobStatus;
  items: IJobProgressItem[];
}

export type BackgroundJobActionHandler = ({
  initiatingElement
}: {
  initiatingElement: HTMLElement;
}) => void;

/** What to display as the result of a background job. */
export interface IBackgroundJobResult {
  icon?: ReactNode;
  iconName?: string;
  title: ReactNode;
  description?: ReactNode;
  /** Shows an action button. */
  action?: {
    label: string;
    run: BackgroundJobActionHandler;
  };
}

export interface IBackgroundJob {
  /** Current state of this job. */
  getProgress(): IJobProgress;

  /** Adds a listener that will be called any time progress changes. */
  addProgressUpdateListener(callback: () => void): void;
  removeProgressUpdateListener(callback: () => void): void;

  /**
   * Returns the result UI of this job.
   *
   * - Invariant: call only when status is Done
   */
  getResult(): IBackgroundJobResult;

  /**
   * Recovers from a failed job, e.g. by re-opening the UI that originally initiated the job.
   *
   * - Invariant: call only when status is Failed.
   * - May be unavailable, in which case recovery is not possible.
   */
  recovery?: { label: string; action: BackgroundJobActionHandler };
}

export interface IAddBackgroundJobParams {
  job: IBackgroundJob;
  /**
   * Shows a brief overlay animation to indicate that the background job was started from this element.
   * This is probably a button.
   *
   * Jobs of this type will come with an additional OverlayEnded event.
   *
   * Value can be either an HTMLElement, bounding rectangle, or CSS query.
   */
  initiatingElement?: HTMLElement | DOMRect | string;
  /**
   * If true, the job has no important result data and should not be added to the job panel if it
   * succeeds during the overlay.
   */
  ephemeral?: boolean;

  /** Overrides overlay duration (milliseconds). Should be at least two seconds */
  overlayDuration?: number;
}

export enum BackgroundJobsEventType {
  /** A new job was added. */
  Added,
  /** For events that show an overlay: the overlay ended, and the job can be added to the job panel. */
  OverlayEnded,
  /** A job's progress was updated. */
  Updated,
  AttemptedClose
}

export interface IBackgroundJobsEventJobAdded {
  type: BackgroundJobsEventType.Added;
  params: IAddBackgroundJobParams;
}

export interface IBackgroundJobsEventJobOverlayEnded {
  type: BackgroundJobsEventType.OverlayEnded;
  job: IBackgroundJob;
  /**
   * If true, this job is ephemeral and succeeded during the overlay, meaning it should now disappear.
   *
   * This is determined by the overlay, because it shouldn't count if it succeeds while the overlay is disappearing.
   */
  ephemeralSucceededDuringOverlay: boolean;
}

export interface IBackgroundJobsEventJobUpdated {
  type: BackgroundJobsEventType.Updated;
  job: IBackgroundJob;
}

export interface IBackgroundJobsEventAttemptedClose {
  type: BackgroundJobsEventType.AttemptedClose;
}

export type IBackgroundJobsEvent =
  | IBackgroundJobsEventJobAdded
  | IBackgroundJobsEventJobOverlayEnded
  | IBackgroundJobsEventJobUpdated
  | IBackgroundJobsEventAttemptedClose;

export type BackgroundJobsEventCallback = (event: IBackgroundJobsEvent) => void;

/** A menu target is an element which we can use to visually show where background jobs are going. */
export interface IBackgroundJobsMenuTarget {
  /** The position of the menu target, for animation. */
  getPosition(): { x: number; y: number };
}

export interface IBackgroundJobsContext {
  /** Adds a new background job. */
  add(params: IAddBackgroundJobParams): void;

  /** Removes a job. */
  remove(job: IBackgroundJob): void;

  /** Removes all completed jobs. */
  removeCompleted(): void;

  /** @internal */
  addEventListener(callback: BackgroundJobsEventCallback): void;
  /** @internal */
  removeEventListener(callback: BackgroundJobsEventCallback): void;

  /** @internal */
  signalOverlayEnded(job: IBackgroundJob, ephemeralSuccess: boolean): void;

  /** @internal */
  getMenuTargets(): Set<IBackgroundJobsMenuTarget>;
  /** @internal */
  addMenuTarget(menu: IBackgroundJobsMenuTarget): void;
  /** @internal */
  removeMenuTarget(menu: IBackgroundJobsMenuTarget): void;
}

function noContextError(): never {
  throw new Error('missing BackgroundJobs context');
}

export const BackgroundJobs = createContext<IBackgroundJobsContext>({
  add: noContextError,
  remove: noContextError,
  removeCompleted: noContextError,
  addEventListener: noContextError,
  removeEventListener: noContextError,
  signalOverlayEnded: noContextError,
  getMenuTargets: noContextError,
  addMenuTarget: noContextError,
  removeMenuTarget: noContextError
});

export interface IBackgroundJobItem {
  job: IBackgroundJob;
  isAwaitingOverlayEnd: boolean;
  lastUpdatedTime: number;
}

export const BackgroundJobsState = createContext<IBackgroundJobItem[]>([]);

export class SinglePromiseJob<T> implements IBackgroundJob {
  promise: Promise<T>;

  title: string;

  errorTitle: string;

  resultTitle: string;

  #progress: number | null = null;

  get progress() {
    return this.#progress;
  }

  set progress(value: number | null) {
    this.#progress = value;
    this.dispatchProgressUpdate();
  }

  icon?: ReactNode;

  iconName?: string;

  resultAction?: (result: T) => IBackgroundJobResult['action'];

  recovery?: IBackgroundJob['recovery'];

  status: BackgroundJobStatus = BackgroundJobStatus.Pending;

  error: ErrorLike | null = null;

  result: T | null = null;

  listeners = new Set<() => void>();

  constructor({
    promise,
    title,
    errorTitle,
    resultTitle,
    resultAction,
    recovery,
    icon,
    iconName
  }: {
    promise: Promise<T>;
    title: string;
    errorTitle: string;
    resultTitle: string;
    resultAction?: (result: T) => IBackgroundJobResult['action'];
    recovery?: IBackgroundJob['recovery'];
    icon?: ReactNode;
    iconName?: string;
  }) {
    this.promise = promise;
    this.title = title;
    this.errorTitle = errorTitle;
    this.resultTitle = resultTitle;
    this.resultAction = resultAction;
    this.icon = icon;
    this.iconName = iconName;
    this.recovery = recovery;

    this.promise
      .then((result) => {
        this.status = BackgroundJobStatus.Done;
        this.result = result;
        this.dispatchProgressUpdate();
      })
      .catch((error) => {
        this.status = BackgroundJobStatus.Failed;
        this.error = error;
        this.dispatchProgressUpdate();
      });
  }

  getProgress(): IJobProgress {
    let { title } = this;
    if (this.status === BackgroundJobStatus.Failed) {
      title = this.errorTitle;
    }

    return {
      icon: this.icon,
      iconName: this.iconName,
      status: this.status,
      title,
      items: [
        {
          label: null,
          progress: this.progress,
          error: this.error
        }
      ]
    };
  }

  addProgressUpdateListener(callback: () => void) {
    this.listeners.add(callback);
  }

  removeProgressUpdateListener(callback: () => void) {
    this.listeners.delete(callback);
  }

  dispatchProgressUpdate() {
    for (const listener of this.listeners) {
      listener();
    }
  }

  getResult(): IBackgroundJobResult {
    return {
      title: this.resultTitle,
      icon: this.icon,
      iconName: this.iconName,
      action: this.resultAction?.(this.result)
    };
  }
}

/** Returns true if we have sufficient header space to use background jobs */
export function areBackgroundJobsAvailable() {
  if (isInMsTeamsEnvironment()) {
    // the teams header has more things in it
    // this header space will change depending on localization, but this is probably close enough...
    return window.innerWidth > 1100;
  }
  return window.innerWidth > 900;
}
