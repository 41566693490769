import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { checkScreenWidth } from 'utils/helpers';
import { DetailsList, SelectionMode } from '@fluentui/react';

const UserCountsListTableContainer = styled.div`
  margin-left: 16px;
  width: ${checkScreenWidth(['extraSmall']) ? '280px' : '1010px'};

  .ms-DetailsHeader-cellName {
    margin: ${checkScreenWidth(['extraSmall']) ? null : 'auto'};
  }

  .textColumn {
    margin: auto;
    padding: 8px 0;
    text-align: center;
  }

  .typeColumn {
    margin: auto;
    text-align: left;
  }

  .countColumn {
    margin: auto;
    text-align: right;
    padding-right: 10px;
  }

  padding-bottom: 0.5rem;
`;

function UserCountsTable({ rows }) {
  const { t } = useTranslation();
  let tableModel = [...rows];
  if (checkScreenWidth(['extraSmall'])) {
    tableModel = [
      {
        type: t('userCountsAdmin.column.numActive'),
        count: rows[0].numActive
      },
      {
        type: t('userCountsAdmin.column.numExternal'),
        count: rows[0].numExternal
      },
      {
        type: t('userCountsAdmin.column.numInternal'),
        count: rows[0].numInternal
      },
      {
        type: t('userCountsAdmin.column.numAssignedTo'),
        count: rows[0].numAssignedTo
      },
      {
        type: t('userCountsAdmin.column.numTaskCreator'),
        count: rows[0].numTaskCreator
      },
      {
        type: t('userCountsAdmin.column.numRouteCreator'),
        count: rows[0].numRouteCreator
      },
      {
        type: t('userCountsAdmin.column.numMentioned'),
        count: rows[0].numMentioned
      }
    ];
  }
  let cols = null;
  if (checkScreenWidth(['extraSmall'])) {
    cols = [
      {
        key: 'type',
        fieldName: 'type',
        name: t('userCountsAdmin.column.type'),
        className: 'typeColumn',
        isResizable: true,
        minWidth: 150,
        maxWidth: 150,
        data: 'string',
        isPadded: true,
        onRender: (row) => row.type
      },
      {
        key: 'count',
        fieldName: 'count',
        name: t('userCountsAdmin.column.count'),
        className: 'countColumn',
        headerClassName: 'countHeader',
        isResizable: true,
        minWidth: 40,
        maxWidth: 40,
        data: 'string',
        isPadded: true,
        styles: {
          cellTitle: {
            position: 'absolute',
            right: '2px'
          }
        },
        onRender: (row) => row.count
      }
    ];
  } else {
    cols = [
      {
        key: 'numActive',
        fieldName: 'numActive',
        name: t('userCountsAdmin.column.numActive'),
        className: 'textColumn',
        isResizable: true,
        minWidth: 100,
        maxWidth: 100,
        data: 'string',
        isPadded: true,
        onRender: (row) => row.numActive
      },
      {
        key: 'numExternal',
        fieldName: 'numExternal',
        name: t('userCountsAdmin.column.numExternal'),
        className: 'textColumn',
        isResizable: true,
        minWidth: 100,
        maxWidth: 100,
        data: 'string',
        isPadded: true,
        onRender: (row) => row.numExternal
      },
      {
        key: 'numInternal',
        fieldName: 'numInternal',
        name: t('userCountsAdmin.column.numInternal'),
        className: 'textColumn',
        isResizable: true,
        minWidth: 100,
        maxWidth: 100,
        data: 'string',
        isPadded: true,
        onRender: (row) => row.numInternal
      },
      {
        key: 'numAssignedTo',
        fieldName: 'numAssignedTo',
        name: t('userCountsAdmin.column.numAssignedTo'),
        className: 'textColumn',
        isResizable: true,
        minWidth: 100,
        maxWidth: 100,
        data: 'string',
        isPadded: true,
        onRender: (row) => row.numAssignedTo
      },
      {
        key: 'numTaskCreator',
        fieldName: 'numTaskCreator',
        name: t('userCountsAdmin.column.numTaskCreator'),
        className: 'textColumn',
        isResizable: true,
        minWidth: 100,
        maxWidth: 100,
        data: 'string',
        isPadded: true,
        onRender: (row) => row.numTaskCreator
      },
      {
        key: 'numRouteCreator',
        fieldName: 'numRouteCreator',
        name: t('userCountsAdmin.column.numRouteCreator'),
        className: 'textColumn',
        isResizable: true,
        minWidth: 100,
        maxWidth: 100,
        data: 'string',
        isPadded: true,
        onRender: (row) => row.numRouteCreator
      },
      {
        key: 'numMentioned',
        fieldName: 'numMentioned',
        name: t('userCountsAdmin.column.numMentioned'),
        className: 'textColumn',
        isResizable: true,
        minWidth: 100,
        maxWidth: 100,
        data: 'string',
        isPadded: true,
        onRender: (row) => row.numMentioned
      }
    ];
  }

  const [columns, setColumns] = useState(cols);

  useEffect(() => {
    if (!columns) {
      setColumns(cols);
    }
  }, [columns, cols]);

  return columns && columns.length > 0 ? (
    <UserCountsListTableContainer className="details-list-wrapper">
      <DetailsList
        compact
        items={tableModel || []}
        selectionMode={SelectionMode.none}
        selectionPreservedOnEmptyClick
        enterModalSelectionOnTouch
        columns={columns}
      />
    </UserCountsListTableContainer>
  ) : null;
}

UserCountsTable.propTypes = {
  rows: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired
};

export default UserCountsTable;
