import {
  IIconProps,
  ITooltipHostProps,
  ITooltipHostStyles,
  Icon,
  TooltipHost,
  concatStyleSets
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { useTheme } from 'styled-components';

export interface IInfoIconTooltipProps {
  /**
   * Class name to apply to the *tooltip itself*, not the host.
   * To apply a class to the host, use `hostClassName` or `styles.root`.
   */
  tooltipClassName?: ITooltipHostProps['className'];
  /**
   * Content to display in the Tooltip.
   */
  content?: ITooltipHostProps['content'];
  /**
   * Call to provide customized styling that will layer on top of the variant rules.
   */
  styles?: {
    tooltipStyles?: ITooltipHostStyles;
    iconStyles?: IIconProps['styles'];
  };
  /**
   * Number of milliseconds to delay closing the tooltip, so that the user has time to hover over the tooltip and interact with it.
   * Hovering over the tooltip will count as hovering over the host, so that the tooltip will stay open if the user is actively interacting with it.
   *
   * @defaultvalue 500
   */
  closeDelay?: ITooltipHostProps['closeDelay'];
  /**
   * Select a custom icon
   * @defaultvalue 'Info'
   */
  iconName?: string;
  iconClassName?: IIconProps['className'];
}

function InfoIconTooltip({
  tooltipClassName,
  content,
  styles,
  closeDelay = 500,
  iconName,
  iconClassName
}: IInfoIconTooltipProps) {
  const theme = useTheme();
  const tooltipId = useId('tooltip');

  /**
   * Combines given  styles with current styles for the tooltip
   */
  function getTootipStyles(): ITooltipHostStyles {
    let tooltipStyles: ITooltipHostStyles = {
      root: { root: { display: 'inline-block' } }
    };

    if (styles?.tooltipStyles) {
      tooltipStyles = concatStyleSets(tootipStyles, styles.tooltipStyles);
    }

    return tooltipStyles;
  }

  /**
   * Combines given  styles with current styles for the tooltip
   */
  function getIconStyles(): IIconProps['styles'] {
    let iconStyles: IIconProps['styles'] = {
      root: {
        fontSize: '15px',
        color: `rgb(${theme.infoIconTooltip.iconColor})`,
        cursor: 'help'
      }
    };

    if (styles?.iconStyles) {
      iconStyles = concatStyleSets(iconStyles, styles.iconStyles);
    }

    return iconStyles;
  }

  if (!content) {
    return null;
  }

  const calloutProps = { gapSpace: 0 };

  const tootipStyles = getTootipStyles();
  const iconStyles = getIconStyles();

  return (
    <TooltipHost
      className={tooltipClassName}
      content={content}
      id={tooltipId}
      closeDelay={closeDelay}
      calloutProps={calloutProps}
      styles={tootipStyles}
    >
      <Icon className={iconClassName} iconName={iconName || 'Info'} styles={iconStyles} />
    </TooltipHost>
  );
}

export default InfoIconTooltip;
