import styled from 'styled-components';

const RatingStyled = styled.div`
  .ms-Label {
    display: block;
    padding-bottom: 0px;
  }
`;

export default RatingStyled;
