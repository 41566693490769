/* eslint-disable no-param-reassign */

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import breakpoints from 'utils/breakpoints';
import { cloneObject } from 'utils/helpers';
import {
  Checkbox,
  CommandBarButton,
  Dropdown,
  IconButton,
  Label,
  MessageBar,
  MessageBarType,
  Stack,
  TextField,
  TooltipHost,
  getTheme
} from '@fluentui/react';
import FieldPicker from '../FieldPicker';
import BodyPropertiesList from './BodyPropertiesList';
import PropertyTypes from './PropertyTypes';

// styles
const MessageBarStyled = styled(MessageBar)`
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 10;
`;

const DevidedRow = styled.div`
  @media (min-width: ${breakpoints.smallMin}px) {
    display: flex;
    justify-content: space-between;

    .left-half {
      max-width: 50%;
      padding-right: 0.25rem;
    }

    .right-half {
      padding-left: 0.25rem;
      max-width: 50%;
    }
  }
`;

const FieldPickerContainer = styled.div`
  width: 100%;

  .ms-BasePicker-text {
    width: 100%;
  }
`;

function ResponseBodyList({
  responseBody,
  handleResponseBody,
  forListConnection,
  disabledNewRow,
  handleErrors,
  bodyPropertiesErrors
}) {
  const { t } = useTranslation();

  const [newProp, setNewProp] = useState();
  const propertyTypeOptions = PropertyTypes(t);

  const [inputErrors, setInputErrors] = useState(null);

  // visibility state for new (editing) row in main group
  const [showNewRow, setShowNewRow] = useState(null);

  // does property list contains array or object types (grouped properties)
  const [hasGroups, setHasGroups] = useState(false);

  useEffect(() => {
    setInputErrors(bodyPropertiesErrors);
  }, [bodyPropertiesErrors]);

  function resetErrorMessages(inputIds, onlyClose) {
    if (inputErrors?.length && inputIds?.length) {
      const errors = [];

      inputErrors.map((err) => {
        let errorAdded = false;

        inputIds.map((id) => {
          if (err.inputId === id) {
            if (onlyClose) {
              errors.push({ ...err, showMessage: false });
              errorAdded = true;
            } else {
              errors.push({ ...err, message: null, showMessage: false });
              errorAdded = true;
            }
          }

          return null;
        });

        if (!errorAdded) {
          errors.push(err);
        }

        return null;
      });

      handleErrors(errors);
    }
  }

  function addError(fieldId, message, propertyWithDoubleName) {
    let isNewError = true;

    const newInputErrors = inputErrors?.length
      ? inputErrors.map((err) => {
          const newErr = err;

          if (newErr.inputId === fieldId) {
            isNewError = false;

            newErr.message = message;
            newErr.showMessage = true;
            newErr.property = { ...propertyWithDoubleName };
            newErr.created = Date.now();
          }

          return newErr;
        })
      : [];

    if (isNewError) {
      const newError = {
        inputId: fieldId,
        message,
        showMessage: true,
        property: { ...propertyWithDoubleName },
        created: Date.now()
      };

      newInputErrors.push(newError);
    }

    handleErrors(newInputErrors);
  }

  function handleDoublePropKeyError(prop, value) {
    const propKey = Object.keys(prop)[0];

    const doubleProp = { [value]: prop[propKey] };

    const inputId = prop[propKey]?.index
      ? `${prop[propKey]?.index}-txt-internalName`
      : `${prop?.group || 0}-txt-newInternalName`;

    addError(inputId, t('responseBodyList.errorMessage.doublePropKey', { key: value }), doubleProp);
  }

  // check each existing property in group if there is a double property key (internal name)
  function handlePropKeys(passedProps, prop, value) {
    const propKey = Object.keys(prop)[0];

    let newResponseProperties = {};
    let existingKey = false;
    let doubleNamesCounter = 1;

    if (passedProps) {
      // check if there is an existing property name
      Object.keys(passedProps).map((key) => {
        if (key === value && passedProps[key].index !== prop?.index) {
          existingKey = true;

          // increase the counter in the case of multiple same names
          if (key.startsWith(`${value}(`)) {
            doubleNamesCounter += 1;
          }
        }

        return null;
      });

      Object.keys(passedProps).map((key) => {
        if (key !== value) {
          if (key === propKey) {
            if (existingKey) {
              // change property name if it is duplicate
              newResponseProperties[`${value}(${doubleNamesCounter})`] = prop[propKey];
            } else {
              // assign new property name
              newResponseProperties[value] = prop[propKey];
            }
          } else {
            // pass property values without change
            newResponseProperties[key] = passedProps[key];
          }
        } else {
          // pass property values for existing name without change
          newResponseProperties[key] = passedProps[key];
        }

        return null;
      });
    } else {
      newResponseProperties = passedProps;
    }

    if (existingKey) {
      handleDoublePropKeyError(prop, value);

      return { props: newResponseProperties, doubleNamesCounter };
    }

    return { props: newResponseProperties };
  }

  function handleEPKeys(properties, propKey) {
    const newProperties = {};

    Object.keys(properties).map((key) => {
      if (key !== propKey && properties[key]['x-ep-key'] === 'yes') {
        newProperties[key] = { ...properties[key], 'x-ep-key': 'no' };
      } else {
        newProperties[key] = { ...properties[key] };
      }

      return null;
    });

    return newProperties;
  }

  // method goes through child properties and handles them
  // passedProperties - group of properties to check
  // propKey - key (name) of the property to add, edit or delete
  // passedProp - property to add, edit or delete
  // newPropKey - new neme of the it is beeing edited
  // fieldValue - value of the formField
  // toDeleteProp - boolean indicator should the property be deleted
  function handleChildProperties(
    passedProperties,
    propKey,
    passedProp,
    newPropKey,
    toDeleteProp,
    doubleNameProperty
  ) {
    const parentIndex = passedProp && propKey ? passedProp[propKey]?.parent?.index : null;

    let responseProperties = {};
    let doubleNamesCounter = 0;

    const replaceDoubleName = (properties) => {
      const newProperties = {};

      Object.keys(properties).map((key) => {
        if (properties[key].index === doubleNameProperty[propKey].index) {
          newProperties[propKey] = { ...properties[key] };
        } else if (key !== propKey) {
          newProperties[key] = { ...properties[key] };
        }

        return null;
      });

      return newProperties;
    };

    Object.keys(passedProperties).map((key) => {
      if (!(key === propKey && passedProperties[key].level === passedProp[propKey].level)) {
        const propType = passedProperties[key].type;

        const propIndex = passedProperties[key].index;

        if (parentIndex === propIndex) {
          let parentProperties = cloneObject(passedProperties[key]);

          if (propType === 'array') {
            if (parentProperties.items?.properties) {
              if (toDeleteProp) {
                if (doubleNameProperty) {
                  const newProps = replaceDoubleName(parentProperties.items?.properties);

                  parentProperties = {
                    ...parentProperties,
                    items: { properties: { ...newProps } }
                  };
                } else {
                  delete parentProperties.items?.properties[propKey];
                }
              } else if (newPropKey) {
                const result = handlePropKeys(
                  parentProperties.items?.properties,
                  passedProp,
                  newPropKey
                );

                if (result?.props && Object.keys(result.props)?.length) {
                  parentProperties.items.properties = result.props;
                }

                doubleNamesCounter = result?.doubleNamesCounter;

                if (doubleNameProperty) {
                  const newProps = replaceDoubleName(parentProperties.items?.properties);

                  parentProperties = {
                    ...parentProperties,
                    items: { properties: { ...newProps } }
                  };
                }
              } else {
                let newProperties = { ...parentProperties.items.properties, ...passedProp };

                if (passedProp[propKey]?.['x-ep-key'] === 'yes') {
                  newProperties = handleEPKeys(newProperties, propKey);
                }

                parentProperties.items.properties = newProperties;
              }
            } else {
              parentProperties = {
                ...parentProperties,
                items: { properties: { [propKey]: passedProp[propKey] } }
              };
            }
          } else if (propType === 'object') {
            if (parentProperties.properties) {
              if (toDeleteProp) {
                if (doubleNameProperty) {
                  const newProps = replaceDoubleName(parentProperties.properties);

                  parentProperties = {
                    ...parentProperties,
                    properties: { ...newProps }
                  };
                } else {
                  delete parentProperties.properties[propKey];
                }
              } else if (newPropKey) {
                const result = handlePropKeys(parentProperties.properties, passedProp, newPropKey);

                if (result?.props && Object.keys(result.props)?.length) {
                  parentProperties.properties = result.props;
                }

                doubleNamesCounter = result?.doubleNamesCounter;

                if (doubleNameProperty) {
                  const newProps = replaceDoubleName(parentProperties.properties);

                  parentProperties = {
                    ...parentProperties,
                    properties: { ...newProps }
                  };
                }
              } else {
                let newProperties = { ...parentProperties.properties, ...passedProp };

                if (passedProp[propKey]?.['x-ep-key'] === 'yes') {
                  newProperties = handleEPKeys(newProperties, propKey);
                }

                parentProperties.properties = newProperties;
              }
            } else {
              parentProperties = {
                ...parentProperties,
                properties: { [propKey]: passedProp[propKey] }
              };
            }
          }

          passedProperties[key] = { ...parentProperties };
        } else if (propType === 'array' && passedProperties[key]?.items?.properties) {
          handleChildProperties(
            passedProperties[key].items.properties,
            propKey,
            passedProp,
            newPropKey,
            toDeleteProp,
            doubleNameProperty
          );
        } else if (propType === 'object' && passedProperties[key]?.properties) {
          handleChildProperties(
            passedProperties[key].properties,
            propKey,
            passedProp,
            newPropKey,
            toDeleteProp,
            doubleNameProperty
          );
        }

        responseProperties = { ...passedProperties };
      }
      doubleNamesCounter = 0;

      return null;
    });

    if (doubleNamesCounter) {
      return null;
    }

    return responseProperties;
  }

  function onDeleteProp(indexedProps, property) {
    if (property) {
      const propKey = Object.keys(property)[0];

      resetErrorMessages([`${property?.[propKey]?.index}-txt-internalName`]);

      let responseProperties = cloneObject(indexedProps);
      let doubleNameProperty = null;

      const doubleNameError = inputErrors?.find(
        (err) =>
          err.inputId === `${err.property?.[propKey]?.index}-txt-internalName` &&
          err.property?.[propKey]?.index !== property[propKey].index &&
          Object.keys(err.property)?.[0] === propKey &&
          err.message
      );

      if (doubleNameError) {
        resetErrorMessages([`${doubleNameError.property[propKey]?.index}-txt-internalName`]);

        doubleNameProperty = doubleNameError.property;
      }

      if (property[propKey].parent) {
        handleChildProperties(
          responseProperties,
          propKey,
          property,
          null,
          true,
          doubleNameProperty
        );
      } else if (responseProperties) {
        if (doubleNameProperty) {
          const newProperties = {};

          Object.keys(responseProperties).map((key) => {
            if (responseProperties[key].index === doubleNameProperty[propKey].index) {
              newProperties[propKey] = { ...responseProperties[key] };
            } else if (key !== propKey) {
              newProperties[key] = { ...responseProperties[key] };
            }

            return null;
          });

          responseProperties = newProperties;
        } else {
          delete responseProperties[propKey];
        }
      }

      handleResponseBody('properties', responseProperties);
    }
  }

  function handleBodyProperties(indexedProps, prop) {
    if (prop) {
      const newProp = { ...prop };
      const propKey = Object.keys(newProp)[0];

      const responseProperties = cloneObject(indexedProps);

      let newResponseProperties = {};

      // let doubleNameProperty = null;

      // for exisiting properties
      if (responseProperties) {
        const handleProps = (passedProp) => {
          if (passedProp[propKey].parent) {
            newResponseProperties = handleChildProperties(responseProperties, propKey, passedProp);
          } else {
            newResponseProperties = { ...responseProperties, ...passedProp };

            if (passedProp[propKey]?.['x-ep-key'] === 'yes') {
              newResponseProperties = handleEPKeys(newResponseProperties, propKey);
            }
          }
        };

        handleProps(newProp);
      } else {
        // for new (first) property
        newResponseProperties = { ...newProp };
      }

      handleResponseBody('properties', newResponseProperties);
    }
  }

  // Save the new property
  function onAddNewProperty(indexedProps) {
    if (!newProp.internalName) {
      const inputId = `${newProp.index}-txt-internalName`;

      addError(inputId, t('responseBodyList.errorMessage.noInternalName'));
    } else {
      const property = {
        [newProp.internalName]: {
          'x-ep-mappedField': newProp['x-ep-mappedField'],
          type: newProp.type,
          index: newProp.index,
          parent: newProp.parent,
          group: newProp.group,
          'x-ep-field': newProp['x-ep-field'] ? 'yes' : 'no',
          'x-ep-key': newProp['x-ep-key'] ? 'yes' : 'no'
        }
      };

      setNewProp(null);

      handleBodyProperties(indexedProps, property);
    }
  }

  // Set the current state of the new property
  function handleNewProperty(indexedProperties, name, value, index, parentProperties, group) {
    if (name) {
      let property;

      if (name === 'type') {
        property = newProp
          ? { ...newProp, [name]: value.key }
          : {
              [name]: value.key,
              index,
              parent: parentProperties
                ? { type: parentProperties.type, index: parentProperties.index }
                : null,
              group
            };
      } else if (name === 'x-ep-mappedField') {
        if (newProp) {
          resetErrorMessages([`${group}-picker-newXEpMappedField`]);

          if (newProp['x-ep-field'] && !value?.[0]?.id) {
            addError(
              `${group}-picker-newXEpMappedField`,
              t('responseBodyList.errorMessage.requiredMappedField')
            );
          }
        }
        property = newProp
          ? { ...newProp, [name]: value?.[0] }
          : {
              [name]: value?.[0],
              index,
              parent: parentProperties
                ? { type: parentProperties.type, index: parentProperties.index }
                : null,
              group
            };
      } else {
        property = newProp
          ? { ...newProp, [name]: value }
          : {
              [name]: value,
              index,
              parent: parentProperties
                ? { type: parentProperties.type, index: parentProperties.index }
                : null,
              group
            };

        if (name === 'internalName') {
          resetErrorMessages([`${group}-txt-newInternalName`]);

          let properties = indexedProperties;

          if (parentProperties) {
            if (parentProperties.type === 'array' && parentProperties.items?.properties) {
              properties = parentProperties.items.properties;
            } else if (parentProperties.type === 'object' && parentProperties.properties) {
              properties = parentProperties.properties;
            }
          }

          handlePropKeys(properties, property, value);
        } else if (name === 'x-ep-field') {
          resetErrorMessages([`${group}-picker-newXEpMappedField`]);

          if (value && !newProp?.['x-ep-mappedField']?.id) {
            addError(
              `${group || 0}-picker-newXEpMappedField`,
              t('responseBodyList.errorMessage.requiredMappedField')
            );
          }
        }
      }

      setNewProp(property);
    }
  }

  // Cancel changes to the new property (reset the state of the new property)
  function cancelNewProperty(group) {
    resetErrorMessages([`${group}-txt-newInternalName`]);

    setNewProp(null);

    if (showNewRow) {
      setShowNewRow(0);
    }
  }

  function handleInternalNameChange(indexedProps, prop, value) {
    if (prop) {
      const newProp = { ...prop };
      const propKey = Object.keys(newProp)[0];

      if (!value) {
        const inputId = `${newProp[propKey].index}-txt-internalName`;

        addError(inputId, t('responseBodyList.errorMessage.noInternalName'));
      } else {
        resetErrorMessages([`${newProp[propKey]?.index}-txt-internalName`]);

        const responseProperties = cloneObject(indexedProps);

        let newResponseProperties = {};
        let doubleNameProperty = null;

        // check if there is a pending error with double property name
        const doubleNameError = inputErrors?.find(
          (err) =>
            err.inputId === `${err.property?.[propKey]?.index}-txt-internalName` &&
            err.property?.[propKey]?.index !== prop[propKey].index &&
            Object.keys(err.property)?.[0] === propKey &&
            err.message
        );

        if (doubleNameError) {
          // reset error message about double property name if there is one
          resetErrorMessages([`${doubleNameError.property[propKey]?.index}-txt-internalName`]);

          doubleNameProperty = doubleNameError.property;
        } else {
          resetErrorMessages([`${prop[propKey]?.index}-txt-internalName`]);
        }

        if (newProp[propKey].parent) {
          newResponseProperties = handleChildProperties(
            responseProperties,
            propKey,
            newProp,
            value,
            null,
            doubleNameProperty
          );
        } else {
          const result = handlePropKeys(responseProperties, newProp, value);

          if (result?.props) {
            if (result?.doubleNamesCounter) {
              newResponseProperties = result?.props;
            } else {
              const newProp = { [value]: { ...prop[propKey] } };

              newResponseProperties = { ...result.props, ...newProp };
            }
          }
        }

        if (newResponseProperties) {
          handleResponseBody('properties', newResponseProperties);
        }
      }
    }
  }

  function handleMappedFieldChange(indexedProps, prop, value) {
    if (prop) {
      const newProp = { ...prop };
      const propKey = Object.keys(newProp)[0];
      resetErrorMessages([`${newProp[propKey]?.index}-picker-xEpMappedField`]);

      if (newProp[propKey]?.['x-ep-field'] === 'yes' && !value?.[0]?.id) {
        addError(
          `${newProp[propKey]?.index}-picker-xEpMappedField`,
          t('responseBodyList.errorMessage.requiredMappedField')
        );
      }

      newProp[propKey]['x-ep-mappedField'] = value?.[0];

      handleBodyProperties(indexedProps, newProp);
    }
  }

  function handlePropTypeChange(indexedProps, prop, value) {
    const propKey = Object.keys(prop)[0];

    if (prop[propKey].type !== value.key) {
      const newProp = { ...prop };

      if (prop[propKey].type === 'array') {
        if (value.key === 'object' && prop[propKey].items?.properties) {
          newProp[propKey].properties = { ...prop[propKey].items.properties };
        }

        delete newProp[propKey].items;
      } else if (prop[propKey].type === 'object') {
        if (value.key === 'array' && prop[propKey].properties) {
          newProp[propKey].items = { properties: prop[propKey].properties };
        }

        delete newProp[propKey].properties;
      }

      newProp[propKey].type = value.key;

      handleBodyProperties(indexedProps, newProp);
    }
  }

  function handleXEPFieldChange(indexedProps, prop, checked) {
    if (prop) {
      const propKey = Object.keys(prop)[0];
      const newProp = { ...prop };

      resetErrorMessages([`${newProp[propKey]?.index}-picker-xEpMappedField`]);

      if (checked && !newProp[propKey]?.['x-ep-mappedField']?.id) {
        addError(
          `${newProp[propKey]?.index}-picker-xEpMappedField`,
          t('responseBodyList.errorMessage.requiredMappedField')
        );
      }

      newProp[propKey]['x-ep-field'] = checked ? 'yes' : 'no';

      handleBodyProperties(indexedProps, newProp);
    }
  }

  function handleXEPKeyChange(indexedProps, prop, checked) {
    const propKey = Object.keys(prop)[0];

    const newProp = { ...prop };
    newProp[propKey]['x-ep-key'] = checked ? 'yes' : 'no';

    handleBodyProperties(indexedProps, newProp);
  }

  function handleResponseType(type, checked) {
    let responseType = responseBody?.type;

    if (type !== responseType) {
      if (checked) {
        responseType = type;
      } else {
        responseType = type === 'object' ? 'array' : 'object';
      }

      handleResponseBody('responseType', responseType);
    }
  }

  function handleShowNewRow() {
    setNewProp(null);
    setShowNewRow(showNewRow ? showNewRow + 1 : 1);
  }

  function getErrorMessage() {
    if (inputErrors?.length) {
      let lastError = null;

      inputErrors.sort((a, b) => {
        return new Date(a.created) - new Date(b.created);
      });

      for (let i = inputErrors.length - 1; i >= 0; i -= 1) {
        if (inputErrors[i].showMessage && inputErrors[i].message) {
          lastError = inputErrors[i];
          break;
        }
      }

      if (lastError?.inputId) {
        return (
          <MessageBarStyled
            messageBarType={MessageBarType.error}
            isMultiline
            onDismiss={() => resetErrorMessages([lastError.inputId], true)}
            dismissButtonAriaLabel="Close"
          >
            {lastError?.message}
          </MessageBarStyled>
        );
      }
    }

    return null;
  }

  function thereIsAnErrorMessage(inputId) {
    if (inputErrors?.length) {
      const error = inputErrors.find((err) => err.inputId === inputId);

      return !!error?.message;
    }

    return false;
  }

  // Column widths
  const internalNameColumnWidth = forListConnection ? 110 : 150;
  const mappedFieldColumnWidth = forListConnection ? 155 : 300;
  const typeColumnWidth = 105;
  const xEpFieldColumnWidth = 75;
  const xEpKeyColumnWidth = 50;

  // Columns for displaying data in the list
  const columns = [
    {
      key: 'internalName',
      name: t('responseBodyList.column.internalName'),
      fieldName: 'internalName',
      minWidth: internalNameColumnWidth,
      maxWidth: internalNameColumnWidth,
      grow: 0,
      onRender: (indexedProps, property) => {
        const propKey = Object.keys(property)[0];

        const errorInField = inputErrors?.find(
          (err) => err.inputId === `${property[propKey]?.index}-txt-internalName` && err.message
        );

        return (
          <TextField
            id={`${property[propKey]?.index}-txt-internalName`}
            onChange={(ev, value) => handleInternalNameChange(indexedProps, property, value)}
            defaultValue={propKey || ''}
            borderless
            styles={{
              root: {
                width: '100%'
              },
              field: {
                borderRadius: 0,
                borderBottom: errorInField ? '2px solid rgb(164, 38, 44)' : 'none',
                selectors: {
                  '&:hover': {
                    borderRadius: 0,
                    borderBottom: errorInField
                      ? '2px solid rgb(164, 38, 44)'
                      : '1px solid rgb(220, 220, 220)'
                  },
                  '&:hover:focus': {
                    borderRadius: 0,
                    borderBottom: errorInField
                      ? '2px solid rgb(164, 38, 44)'
                      : `2px solid ${getTheme().palette.themePrimary}`
                  },
                  ':focus': {
                    borderRadius: 0,
                    borderBottom: errorInField
                      ? '2px solid rgb(164, 38, 44)'
                      : `2px solid ${getTheme().palette.themePrimary}`
                  }
                }
              }
            }}
          />
        );
      }
    },
    {
      key: 'xEpMappedField',
      name: t('responseBodyList.column.mappedField.name'),
      fieldName: 'xEpMappedField',
      minWidth: mappedFieldColumnWidth,
      maxWidth: mappedFieldColumnWidth,
      infoText: t('responseBodyList.column.mappedField.info'),
      onRender: (indexedProps, property) => {
        const propKey = Object.keys(property)[0];

        const errorInField = thereIsAnErrorMessage(
          `${property[propKey]?.index}-picker-xEpMappedField`
        );

        const borderBottomColorOnHover = () => {
          if (errorInField) {
            return '2px solid rgb(164, 38, 44)';
          }

          if (!property[propKey]?.default) {
            return '1px solid rgb(220, 220, 220)';
          }

          return null;
        };

        const fieldPickerStyles = {
          input: {
            minWidth: mappedFieldColumnWidth - 10,
            maxWidth: mappedFieldColumnWidth - 10,
            borderBottom: errorInField ? '2px solid rgb(164, 38, 44)' : 'none',
            marginBottom: '-1px',
            selectors: {
              '&:hover': {
                border: 'none',
                borderRadius: 0,
                borderBottom: borderBottomColorOnHover()
              },
              ':focus': {
                border: 'none',
                borderRadius: 0,
                borderBottom: errorInField
                  ? '2px solid rgb(164, 38, 44)'
                  : `2px solid ${getTheme().palette.themePrimary}`
              }
            }
          },
          text: {
            border: 'none',
            selectors: {
              '::after': {
                border: 'none'
              }
            }
          }
        };

        return (
          <FieldPickerContainer id={`${property[propKey]?.index}-picker-xEpMappedField`}>
            <FieldPicker
              onChange={(value) => handleMappedFieldChange(indexedProps, property, value)}
              selectedField={property[propKey]?.['x-ep-mappedField'] || null}
              styles={fieldPickerStyles}
            />
          </FieldPickerContainer>
        );
      }
    },
    {
      key: 'type',
      name: t('responseBodyList.column.propType'),
      fieldName: 'type',
      minWidth: typeColumnWidth,
      maxWidth: typeColumnWidth,
      onRender: (indexedProps, property) => {
        const propKey = Object.keys(property)[0];

        return (
          <Dropdown
            key={`${property[propKey]?.index}-${property[propKey]?.type}`}
            disabled={false}
            required={false}
            options={propertyTypeOptions}
            value={property[propKey]?.type}
            defaultSelectedKey={property[propKey]?.type}
            onChange={(_, value) => handlePropTypeChange(indexedProps, property, value)}
            styles={{
              title: { border: 'none' },
              root: {
                width: '100%',
                border: 'none',
                selectors: {
                  '&:hover': {
                    borderRadius: 0,
                    borderBottom: '1px solid rgb(220, 220, 220)'
                  }
                }
              }
            }}
          />
        );
      }
    },
    {
      key: 'x-ep-field',
      name: t('responseBodyList.column.x-ep-field'),
      fieldName: 'x-ep-field',
      minWidth: xEpFieldColumnWidth,
      maxWidth: xEpFieldColumnWidth,
      centered: true,
      onRender: (indexedProps, property) => {
        const propKey = Object.keys(property)[0];
        return (
          <Checkbox
            checked={property[propKey]?.['x-ep-field'] === 'yes'}
            onChange={(ev, checked) => handleXEPFieldChange(indexedProps, property, checked)}
          />
        );
      }
    }
  ];

  // Add column only for list connection
  if (forListConnection) {
    columns.push({
      key: 'x-ep-key',
      name: t('responseBodyList.column.x-ep-key'),
      fieldName: 'x-ep-key',
      minWidth: xEpKeyColumnWidth,
      maxWidth: xEpKeyColumnWidth,
      centered: true,
      onRender: (indexedProps, property) => {
        const propKey = Object.keys(property)[0];
        return (
          <Checkbox
            checked={property[propKey]?.['x-ep-key'] === 'yes'}
            onChange={(ev, checked) => handleXEPKeyChange(indexedProps, property, checked)}
          />
        );
      }
    });
  }

  // Add action column only for all response bodys
  columns.push({
    key: 'actions',
    fieldName: 'actions',
    centered: true,
    onRender: (indexedProps, prop, addNewProp, deleteProp) => {
      const propKey = Object.keys(prop)[0];

      let propType = prop[propKey]?.type;

      if (prop[propKey]?.type && Array.isArray(prop[propKey].type)) {
        propType = prop[propKey].type.find((x) => x !== null);
      }

      const disableNewChildForm = !!inputErrors?.find(
        (err) =>
          err.message &&
          err.property &&
          err.property[Object.keys(err.property)[0]]?.group === prop[propKey].group
      );

      return (
        <div>
          <TooltipHost
            content={t('responseBodyList.column.action.addProp')}
            calloutProps={{
              gapSpace: 0,
              target: `#btn-add-new-body-${prop[propKey]?.index || 0}-prop`
            }}
          >
            <IconButton
              id={`btn-add-new-body-${prop[propKey]?.index || 0}-prop`}
              className="groupAddButton"
              iconProps={{ iconName: 'Add' }}
              onClick={() => {
                setNewProp(null);
                addNewProp();
                return null;
              }}
              disabled={disableNewChildForm}
              styles={{
                root: {
                  marginRight: '2px',
                  display: propType === 'array' || propType === 'object' ? 'inline-block' : 'none',
                  width: '30px',
                  height: '30px'
                }
              }}
            />
          </TooltipHost>
          <TooltipHost
            content={t('responseBodyList.column.action.deleteProp')}
            calloutProps={{
              gapSpace: 0,
              target: `#btn-del-body-${prop[propKey]?.index || 0}-prop`
            }}
          >
            <IconButton
              id={`btn-del-body-${prop[propKey]?.index || 0}-prop`}
              className="deleteButton"
              iconProps={{ iconName: 'Delete' }}
              onClick={deleteProp}
              styles={{ root: { width: '30px', height: '30px' } }}
            />
          </TooltipHost>
        </div>
      );
    }
  });

  // Columns for new property form
  const newEditColumns = [
    {
      key: 'newInternalName',
      minWidth: internalNameColumnWidth,
      maxWidth: internalNameColumnWidth,
      grow: 0,
      onRender: (property, changeNewProp) => {
        const errorInField = thereIsAnErrorMessage(`${property?.group || 0}-txt-newInternalName`);

        return (
          <TextField
            id={`${property?.group || 0}-txt-newInternalName`}
            onChange={(ev, value) => changeNewProp('internalName', value)}
            value={property?.internalName || ''}
            borderless
            styles={{
              root: { width: '100%' },
              field: {
                borderRadius: 0,
                borderBottom: errorInField
                  ? '2px solid rgb(164, 38, 44)'
                  : '1px solid rgb(96, 94, 92)',
                selectors: {
                  '&:hover': {
                    borderRadius: 0,
                    borderBottom: errorInField
                      ? '2px solid rgb(164, 38, 44)'
                      : '1px solid rgb(96, 94, 92)',
                    marginBottom: '2px'
                  },
                  '&:hover:focus': {
                    borderRadius: 0,
                    borderBottom: errorInField
                      ? '2px solid rgb(164, 38, 44)'
                      : `2px solid ${getTheme().palette.themePrimary}`,
                    marginBottom: 0
                  },
                  ':focus': {
                    borderRadius: 0,
                    borderBottom: errorInField
                      ? '2px solid rgb(164, 38, 44)'
                      : `2px solid ${getTheme().palette.themePrimary}`
                  }
                }
              }
            }}
          />
        );
      }
    },
    {
      key: 'newXEpMappedField',
      minWidth: mappedFieldColumnWidth,
      maxWidth: mappedFieldColumnWidth,
      onRender: (property, changeNewProp) => {
        const errorInField = thereIsAnErrorMessage(
          `${property?.group || 0}-picker-newXEpMappedField`
        );

        const borderBottomColorOnHover = () => {
          if (errorInField) {
            return '2px solid rgb(164, 38, 44)';
          }

          if (!property?.default) {
            return '1px solid rgb(96, 94, 92)';
          }

          return null;
        };

        const fieldPickerStyles = {
          input: {
            minWidth: mappedFieldColumnWidth - 10,
            maxWidth: mappedFieldColumnWidth - 10,
            borderRadius: 0,
            borderBottom: errorInField ? '2px solid rgb(164, 38, 44)' : '1px solid rgb(96, 94, 92)',
            marginBottom: '-1px',
            selectors: {
              '&:hover': {
                borderRadius: 0,
                borderBottom: borderBottomColorOnHover(),
                marginBottom: '1px'
              },
              '&:hover:focus': {
                marginBottom: '-1px'
              },
              ':focus': {
                borderRadius: 0,
                borderBottom: errorInField
                  ? '2px solid rgb(164, 38, 44)'
                  : `2px solid ${getTheme().palette.themePrimary}`
              }
            }
          },
          text: {
            border: 'none',
            selectors: {
              '::after': {
                border: 'none'
              }
            }
          }
        };

        return (
          <FieldPickerContainer id={`${property?.group || 0}-picker-newXEpMappedField`}>
            <FieldPicker
              key={`${property?.group || 0}-picker-newXEpMappedField`}
              onChange={(value) => changeNewProp('x-ep-mappedField', value)}
              selectedField={property?.['x-ep-mappedField'] || null}
              styles={fieldPickerStyles}
            />
          </FieldPickerContainer>
        );
      }
    },
    {
      key: 'newType',
      fieldName: 'newType',
      minWidth: typeColumnWidth,
      maxWidth: typeColumnWidth,
      onRender: (property, changeNewProp) => {
        return (
          <Dropdown
            options={propertyTypeOptions}
            defaultSelectedKey={property?.type || null}
            onChange={(ev, value) => changeNewProp('type', value)}
            styles={{
              title: { border: 'none' },
              root: {
                borderRadius: 0,
                borderBottom: '1px solid rgb(96, 94, 92)',
                width: '100%'
              }
            }}
          />
        );
      }
    },
    {
      key: 'newxpEpField',
      fieldName: 'newxpEpField',
      minWidth: xEpFieldColumnWidth,
      maxWidth: xEpFieldColumnWidth,
      centered: true,
      onRender: (property, changeNewProp) => {
        return (
          <Checkbox
            checked={property?.['x-ep-field'] || false}
            onChange={(ev, checked) => changeNewProp('x-ep-field', checked)}
          />
        );
      }
    }
  ];

  // Add column only for list connection
  if (forListConnection) {
    newEditColumns.push({
      key: 'newxpEpKey',
      fieldName: 'newxpEpKey',
      minWidth: xEpKeyColumnWidth,
      maxWidth: xEpKeyColumnWidth,
      centered: true,
      onRender: (property, changeNewProp) => {
        return (
          <Checkbox
            checked={property?.['x-ep-key'] || false}
            onChange={(ev, checked) => changeNewProp('x-ep-key', checked)}
          />
        );
      }
    });
  }

  // Add action column only for all response bodys
  newEditColumns.push({
    key: 'newActions',
    fieldName: 'newActions',
    centered: true,
    onRender: (property, cancelNewProp, saveNewProp) => {
      return (
        <div>
          <TooltipHost
            content={t('responseBodyList.column.action.saveNewProp')}
            calloutProps={{
              gapSpace: 0,
              target: `#btn-add-new-body-prop-${property.index}`
            }}
          >
            <IconButton
              id={`btn-add-new-body-prop-${property.index}`}
              className="addButton"
              iconProps={{ iconName: 'Accept' }}
              onClick={(ev) => saveNewProp(ev)}
              disabled={
                !(property?.internalName && property?.type) ||
                (inputErrors?.inputId === `${property?.group || 0}-txt-newInternalName` &&
                  inputErrors.message)
              }
              styles={{
                root: {
                  marginRight: '2px',
                  display: property?.internalName && property?.type ? 'inline-block' : 'none'
                }
              }}
            />
          </TooltipHost>
          <TooltipHost
            content={t('responseBodyList.column.action.cancelNewProp')}
            calloutProps={{
              gapSpace: 0,
              target: `#btn-cancel-new-body-prop-${property.internalName}`
            }}
          >
            <IconButton
              id={`btn-cancel-new-body-prop-${property.internalName}`}
              className="cancelButton"
              iconProps={{ iconName: 'Cancel' }}
              onClick={() => cancelNewProp()}
              styles={{
                root: {
                  display:
                    property?.type || property?.internalName || property?.['x-ep-field']
                      ? 'inline-block'
                      : 'none'
                }
              }}
            />
          </TooltipHost>
        </div>
      );
    }
  });

  function checkGroups(passedProps) {
    let thereAreGroups = false;

    if (passedProps) {
      Object.keys(passedProps).map((key) => {
        let property = null;

        const responseType = responseBody?.type;

        if (responseType === 'object') {
          property = responseBody?.properties[key];
        } else if (responseType === 'array') {
          property = responseBody?.items?.properties[key];
        }

        if (property) {
          let propType = property?.type;

          if (property?.type && Array.isArray(property.type)) {
            propType = property.type.find((x) => x !== null);
          }

          if (propType === 'array') {
            thereAreGroups = true;
          } else if (propType === 'object') {
            thereAreGroups = true;
          }
        }

        return null;
      });
    }

    if (hasGroups !== thereAreGroups) {
      setHasGroups(thereAreGroups);
    }
  }

  if (responseBody) {
    if (responseBody.type === 'array') {
      checkGroups(responseBody.items?.properties);
    } else {
      checkGroups(responseBody.properties);
    }
  }

  const errorsInList = !!inputErrors?.find(
    (err) =>
      err.inputId.endsWith('-txt-internalName') &&
      err.message &&
      err.property &&
      err.property[Object.keys(err.property)[0]]?.level === 1
  );

  return (
    <div style={{ position: 'relative', overflowX: 'auto', whiteSpace: 'nowrap' }}>
      {getErrorMessage()}
      <Label required={false} style={{ marginTop: '5px' }}>
        {t('responseBodyList.label.response-body')}
      </Label>
      <Label required={false} style={{ marginTop: '5px' }}>
        {t('responseBodyList.label.responseType')}
      </Label>
      <DevidedRow className="mt-1">
        <div className="left-half half-width">
          <Checkbox
            key="responseType-obj"
            label={t('responseBodyList.label.object')}
            className="responseType"
            checked={!(responseBody?.type === 'array')}
            disabled={false}
            onChange={(_, checked) => handleResponseType('object', checked)}
            styles={{ root: { marginBottom: '10px' } }}
          />
        </div>
        <div className="right-half half-width">
          <Checkbox
            key="responseType-array"
            label={t('responseBodyList.label.array')}
            className="responseType"
            checked={responseBody?.type === 'array'}
            disabled={false}
            onChange={(_, checked) => handleResponseType('array', checked)}
            styles={{ root: { marginBottom: '10px' } }}
          />
        </div>
      </DevidedRow>
      {hasGroups ? (
        <Stack horizontal styles={{ root: { height: 44, marginTop: 10 } }}>
          <CommandBarButton
            id="add-new-body-prop-btn"
            iconProps={{ iconName: 'Add' }}
            text={t('responseBodyList.label.newResponseProp')}
            disabled={errorsInList}
            onClick={handleShowNewRow}
            style={{ borderBottom: '1px solid #D3D3D3' }}
          />
        </Stack>
      ) : null}
      <BodyPropertiesList
        body={responseBody}
        columns={columns}
        newEditColumns={newEditColumns}
        onChangeNewProp={handleNewProperty}
        onCancelNewProp={cancelNewProperty}
        onSaveNewProp={onAddNewProperty}
        onDeleteProp={onDeleteProp}
        newProp={newProp}
        showNewRow={showNewRow}
        disabledNewRow={disabledNewRow || errorsInList}
      />
    </div>
  );
}

ResponseBodyList.propTypes = {
  responseBody: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropertyTypes.object)]),
  handleResponseBody: PropTypes.func.isRequired,
  forListConnection: PropTypes.bool,
  disabledNewRow: PropTypes.bool,
  handleErrors: PropTypes.func,
  bodyPropertiesErrors: PropTypes.arrayOf(PropTypes.object)
};

ResponseBodyList.defaultProps = {
  responseBody: null,
  forListConnection: false,
  disabledNewRow: false,
  handleErrors: null,
  bodyPropertiesErrors: null
};

export default ResponseBodyList;
