/* eslint-disable no-bitwise */

import { CombinedPickerJS as CombinedPicker, Description, LoadingSpinner } from 'components';
import PropTypes from 'prop-types';
import { createRef, useCallback, useEffect, useState } from 'react';
import Files from 'react-files';
import { useTranslation } from 'react-i18next';
import fetchRequest, { apiErrorHandler } from 'services/api';
import styled from 'styled-components';
import { teamPropType } from 'types';
import breakpoints from 'utils/breakpoints';
import { checkScreenWidth } from 'utils/helpers';
import {
  Checkbox,
  ComboBox,
  DefaultButton,
  DirectionalHint,
  Icon,
  IconButton,
  Image,
  Label,
  TextField,
  TooltipHost
} from '@fluentui/react';

// styles
const GeneralTabStyled = styled.div`
  padding: 1rem;
  padding-bottom: 3rem;

  .form-fields-width {
    width: 100%;
  }

  .error-usage {
    .ms-Checkbox-checkbox {
      border-color: rgb(164, 38, 44);
    }
  }

  .remove-image-button {
    padding: 0;
    width: auto;
    visibility: hidden;

    .ms-Button-icon {
      font-size: 10;
      margin: 0;
      margin-top: -15px;
    }
  }

  .remove-image-button:hover {
    background-color: transparent;
  }

  .service-image:hover {
    cursor: pointer;
  }

  .image-container:hover {
    .remove-image-button {
      visibility: visible;
    }
  }
`;

const DevidedRow = styled.div`
  @media (min-width: ${breakpoints.smallMin}px) {
    display: flex;
    justify-content: space-between;

    .left-half {
      max-width: 50%;
      padding-right: 0.25rem;
    }

    .right-half {
      padding-left: 0.25rem;
      max-width: 50%;
    }
  }
`;

const TabDescriptionStyled = styled.div`
  margin-bottom: 10px;
  padding-bottom: 10px;
  font-size: 0.9em;
  color: #808080;
  font-style: italic;
  border-bottom: 1px solid rgb(235, 235, 235);
`;

const LabelStyled = styled.div`
  display: flex;
  justify-content: flex-start;

  .info-icon {
    font-size: 11px;
    color: rgb(0, 120, 212);
  }
`;

const InfoIcon = styled(Icon)`
  margin: 0 10px 0 5px;

  &:hover {
    cursor: help;
  }
`;

function ServiceGeneralPivotItem({
  service,
  team,
  handleServiceState,
  handleTeamState,
  fieldToFocus,
  locations,
  images
}) {
  const { t } = useTranslation();

  // helpers for functional usage on mobile devices beause of automatic activation of virtual keyboard
  const [toFocusCombo, setToFocusCombo] = useState(true);
  const comboLocationRef = createRef();
  const comboImageRef = createRef();

  const [isLoading, setIsLoading] = useState(true);

  const [selectedUsages, setSelectedUsages] = useState(null);

  const [defaultSelectedAssignedItems, setDefaultSelectedAssignedItems] = useState([]);

  let reactFilesRef;

  const usageOptions = [
    { key: 0, text: t('serviceGeneralPivotItem.usageTypes.deactivated') },
    { key: 2, text: t('serviceGeneralPivotItem.usageTypes.integration') },
    { key: 4, text: t('serviceGeneralPivotItem.usageTypes.field') }
  ];

  const getDefaultAssignedItems = useCallback(() => {
    if (service) {
      let assignedItems = [];
      if (service?.assignedTeams?.length) {
        assignedItems = assignedItems.concat(...service.assignedTeams);
      }
      if (service?.assignedUsers?.length) {
        assignedItems = assignedItems.concat(...service.assignedUsers);
      }

      return assignedItems;
    }

    return [];
  }, [service]);

  useEffect(() => {
    setDefaultSelectedAssignedItems(getDefaultAssignedItems());
  }, [service, getDefaultAssignedItems]);

  useEffect(() => {
    const usages = [];

    if (service?.usage || service?.usage === 0) {
      if ((service.usage & 2) === 2) {
        usages.push(2);
      }
      if ((service.usage & 4) === 4) {
        usages.push(4);
      }
      if (service.usage === 0) {
        usages.push(0);
      }
    }

    setSelectedUsages(usages);

    setIsLoading(false);
  }, [service, fieldToFocus]);

  function handleCurrentInput(name, value) {
    const newCurrentService = { ...service, [name]: value };
    handleServiceState(newCurrentService);
  }

  function handleName(value) {
    handleCurrentInput('name', value);
  }

  function handleLocation(value) {
    handleCurrentInput('location', value);
  }

  function handleUsage(key, value) {
    if (key === 0) {
      const newUsageKey = value ? key : null;
      handleCurrentInput('usage', newUsageKey);
    } else if (key > 0) {
      const currentUsageKey = service?.usage || 0;
      const newUsageKey = value ? currentUsageKey + key : currentUsageKey - key;

      handleCurrentInput('usage', newUsageKey > 0 ? newUsageKey : null);
    }
  }

  function handleDescription(value) {
    handleCurrentInput('description', value);
  }

  function onMenuOpen(type) {
    if (checkScreenWidth(['extraSmall'])) {
      let comboRef;
      if (type === 'location') {
        comboRef = comboLocationRef;
      } else if (type === 'image') {
        comboRef = comboImageRef;
      }

      if (toFocusCombo) {
        if (comboRef && comboRef.current) {
          const comboBox = { ...comboRef.current };
          comboRef.current.dismissMenu(true);
          setToFocusCombo(false);
          setTimeout(() => {
            if (comboBox) {
              comboBox.focus(true);
            }
          }, 400);
        }
      } else {
        setToFocusCombo(true);
      }
    }
  }

  function getUsageOptionsForIndex(index) {
    const checkboxes = [];

    const isSelected =
      selectedUsages && selectedUsages.find((key) => key === usageOptions[index].key) > -1;

    let disabled = false;
    let forbidden = false;

    if (index === 2) {
      const connection = service?.serviceData?.connection;

      if (
        (connection?.queryFields?.length &&
          connection.requestBody?.properties &&
          Object.keys(connection.requestBody.properties).length) ||
        (connection.requestBody?.properties &&
          Object.keys(connection.requestBody.properties).length > 1)
      ) {
        disabled = true;
        forbidden = true;
      }
    }

    checkboxes.push(
      <Checkbox
        key={`usage-${index}`}
        label={usageOptions[index].text}
        className="usage"
        checked={!forbidden && isSelected}
        disabled={disabled}
        onChange={(_, value) => handleUsage(usageOptions[index].key, value)}
        styles={{ root: { marginBottom: '10px' } }}
      />
    );

    return checkboxes;
  }

  function errorMessageForUsage() {
    const usages = document.getElementsByClassName('usage');

    if (usages) {
      if (fieldToFocus?.name === 'usage') {
        for (let i = 0; i < usages.length; i += 1) {
          usages[i].classList.add('error-usage');
        }

        return (
          <p className="ms-TextField-errorMessage">
            <span style={{ color: 'rgb(164, 38, 44)', fontSize: '12px', paddingTop: '5px' }}>
              {fieldToFocus?.message}
            </span>
          </p>
        );
      }

      for (let i = 0; i < usages.length; i += 1) {
        usages[i].classList.remove('error-usage');
      }
    }

    return null;
  }

  function handleImage(value) {
    handleCurrentInput('pic', value);
  }

  function uploadImage(imagesArray) {
    const image = imagesArray[0];

    let newImage = {
      new: true
    };

    if (image.size !== 0) {
      const body = new FormData();
      body.append('file', image, image.name);

      fetchRequest({
        url: 'File/Pic',
        method: 'POST',
        body,
        ignoreContentType: true
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data) {
            newImage = { ...newImage, ...data };

            handleImage(newImage);
          }
        })
        .catch(apiErrorHandler);
    }
  }

  function onRemoveImage() {
    handleCurrentInput('pic', null);
  }

  function onRenderImageOption(option) {
    return (
      <div key={`combo-option-${option?.index}`}>
        <TooltipHost
          delay={2}
          directionalHint={DirectionalHint.rightCenter}
          tooltipProps={{
            onRenderContent: () => (
              <Image
                className="service-image-large"
                width="100px"
                height="100px"
                src={option?.pictureData}
                alt={option?.name}
              />
            )
          }}
          calloutProps={{ gapSpace: 20 }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Image
              className="service-image-small"
              width="20px"
              height="20px"
              src={option?.pictureData}
              alt={option?.name}
            />
            <div style={{ marginLeft: '20px' }}>{option?.name}</div>
          </div>
        </TooltipHost>
      </div>
    );
  }

  function onAssignedTeamsChange(selectedItems) {
    const teams = selectedItems.filter((item) => !item.userId);
    const users = selectedItems.filter((item) => item.userId);

    handleCurrentInput('assignedUsers', users);
    handleCurrentInput('assignedTeams', teams);
  }

  return isLoading ? (
    <LoadingSpinner label={t('loading.serviceGeneralPivotItem.text')} />
  ) : (
    <GeneralTabStyled>
      <TabDescriptionStyled>{t('serviceGeneralPivotItem.description')}</TabDescriptionStyled>
      <TextField
        autoFocus
        disabled={false}
        label={t('serviceGeneralPivotItem.label.name')}
        onChange={(ev, value) => handleName(value)}
        required
        defaultValue={service?.name || ''}
        errorMessage={fieldToFocus?.name === 'name' ? fieldToFocus?.message : null}
      />
      <DevidedRow className="mt-1">
        <div className="left-half half-width">
          <Label required={false}>{t('serviceGeneralPivotItem.label.team')}</Label>
          <CombinedPicker
            placeholder={t('serviceGeneralPivotItem.placeholder.team')}
            searchUser={false}
            searchTeams
            selectedItems={team}
            onChange={handleTeamState}
          />
        </div>
        <div className="right-half half-width">
          <ComboBox
            componentRef={comboLocationRef}
            allowFreeform
            autoComplete="on"
            calloutProps={{
              calloutMaxHeight: checkScreenWidth(['extraSmall']) ? 110 : 250,
              coverTarget: false
            }}
            disabled={false}
            label={t('serviceGeneralPivotItem.label.location')}
            onChange={(_, value) => handleLocation(value)}
            options={locations || []}
            placeholder={t('serviceGeneralPivotItem.placeholder.location')}
            required={false}
            selectedKey={service?.location ? service.location.id : null}
            styles={{ container: { width: '100%' } }}
            onMenuOpen={checkScreenWidth(['extraSmall']) ? () => onMenuOpen('location') : null}
          />
        </div>
      </DevidedRow>
      <Label required style={{ marginTop: '5px', marginBottom: '-3px' }}>
        {t('serviceGeneralPivotItem.label.usage')}
      </Label>
      <DevidedRow className="mt-1">
        <div className="left-half half-width">
          <div>{getUsageOptionsForIndex(1)}</div>
        </div>
        <div className="right-half half-width">
          <div>{getUsageOptionsForIndex(2)}</div>
        </div>
      </DevidedRow>
      <div>
        <Label>{t('serviceGeneralPivotItem.label.assignedTeams')}</Label>
        <CombinedPicker
          searchUser={false}
          placeholder={t('serviceGeneralPivotItem.placeholder.assignedTeams')}
          searchTeams
          selectedItems={defaultSelectedAssignedItems}
          itemLimit={20}
          userToFilter={getDefaultAssignedItems()}
          onChange={onAssignedTeamsChange}
        />
      </div>
      {errorMessageForUsage()}
      <Description
        defaultValue={service?.description}
        disabled={false}
        label={t('serviceGeneralPivotItem.label.description')}
        displayCmdBar
        styles={{ editorWrapper: { minHeight: '200px' } }}
        onChange={handleDescription}
      />
      <div style={{ margin: '20px 0' }}>
        <LabelStyled>
          <Label>{t('serviceGeneralPivotItem.label.image')}</Label>
          <TooltipHost
            content={t('serviceGeneralPivotItem.info.image')}
            directionalHint={DirectionalHint.topCenter}
            calloutProps={{ gapSpace: 5, target: '#service-image' }}
            styles={{
              root: { display: 'inline-block', maxWidth: 200 }
            }}
          >
            <InfoIcon iconName="Info" className="info-icon" />
          </TooltipHost>
        </LabelStyled>
        <div style={{ display: 'flex', width: '100%' }}>
          <ComboBox
            id="service-image"
            componentRef={comboImageRef}
            allowFreeform
            autoComplete="on"
            calloutProps={{
              calloutMaxHeight: checkScreenWidth(['extraSmall']) ? 110 : 250,
              coverTarget: false
            }}
            disabled={!service?.name}
            onChange={(_, value) => handleImage(value)}
            options={images || []}
            placeholder={t('serviceGeneralPivotItem.placeholder.image')}
            required={false}
            selectedKey={service?.pic?.id ? service.pic.id : null}
            styles={{ container: { width: '100%', marginRight: '10px' } }}
            onMenuOpen={checkScreenWidth(['extraSmall']) ? () => onMenuOpen('image') : null}
            onRenderOption={onRenderImageOption}
          />
          <Files
            className="upload-image"
            onChange={uploadImage}
            clickable={false}
            multiple={false}
            accepts={['image/*']}
            ref={(node) => {
              reactFilesRef = node;
            }}
          >
            <DefaultButton
              disabled={!service?.name}
              iconProps={{
                iconName: 'Attach'
              }}
              onClick={() => reactFilesRef.openFileChooser()}
              styles={{
                root: {
                  width: '100%',
                  paddingLeft: '5px',
                  paddingRight: '10px',
                  border: 'none'
                },
                label: {
                  lineHeight: '20px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  width: '100%'
                }
              }}
              text={t('serviceGeneralPivotItem.button.uploadImage')}
            />
          </Files>
        </div>
        {service?.pic?.pictureData ? (
          <div
            className="image-container"
            style={{
              marginTop: 10,
              display: 'flex',
              width: 134
            }}
          >
            <Image
              className="service-image"
              width="100px"
              height="100px"
              src={service?.pic?.pictureData}
              alt={service?.pic?.name}
            />
            <IconButton
              className="remove-image-button"
              iconProps={{ iconName: 'Cancel' }}
              title={t('serviceGeneralPivotItem.button.removeImage')}
              ariaLabel={t('serviceGeneralPivotItem.button.removeImage')}
              onClick={onRemoveImage}
            />
          </div>
        ) : null}
      </div>
    </GeneralTabStyled>
  );
}

ServiceGeneralPivotItem.propTypes = {
  service: PropTypes.object,
  team: teamPropType,
  handleServiceState: PropTypes.func.isRequired,
  handleTeamState: PropTypes.func.isRequired,
  fieldToFocus: PropTypes.object,
  locations: PropTypes.arrayOf(PropTypes.object),
  images: PropTypes.arrayOf(PropTypes.object)
};

ServiceGeneralPivotItem.defaultProps = {
  service: null,
  team: null,
  fieldToFocus: null,
  locations: null,
  images: null
};

export default ServiceGeneralPivotItem;
