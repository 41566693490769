export function getProjectTypeOptions(t) {
  return [
    {
      key: 1,
      text: t('projects.types.classic')
    },
    {
      key: 2,
      text: t('projects.types.agile')
    }
  ];
}

export function getProjectStatusOptions(t) {
  return [
    {
      key: 1,
      text: t('projects.statuses.1')
    },
    {
      key: 2,
      text: t('projects.statuses.2')
    },
    {
      key: 3,
      text: t('projects.statuses.3')
    },
    {
      key: 10,
      text: t('projects.statuses.10')
    }
  ];
}
