import styled from 'styled-components';
import breakpoints from 'utils/breakpoints';
import { CommandBar, ICommandBarProps } from '@fluentui/react';

const MainCommandBarStyled = styled(CommandBar)`
  width: 100%;
  z-index: 100;
  box-shadow: ${({ theme }) => theme.pageCommandBar.shadow};
  border-top: 1px solid rgb(${({ theme }) => theme.pageCommandBar.divider});

  @media (min-width: ${breakpoints.largeMin}px) {
    .ms-FocusZone.ms-CommandBar {
      padding-left: 11px;
    }
  }

  @media (max-width: ${breakpoints.extraSmallMax}px) {
    .ms-FocusZone.ms-CommandBar {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
`;

function MainCommandBar(props: ICommandBarProps): JSX.Element {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <MainCommandBarStyled {...props} />;
}

export default MainCommandBar;
