import CombinedPicker from 'components/inputs/CombinedPicker';
import LongTextField from 'components/inputs/LongTextField';
import RichTextEditor from 'components/inputs/RichTextEditor';
import SmallTextField from 'components/inputs/SmallTextField';
import DetailsList from 'components/lists/DetailsList/DetailsList';
import EmptyListPlaceHolder from 'components/lists/EmptyListPlaceHolder';
import MainCommandBar from 'components/surfaces/MainCommandBar/MainCommandBar';
import Panel from 'components/surfaces/Panel';
import { usePages } from 'features/App/context/PagesContext';
import { useHeaderStylesOnScroll } from 'hooks';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import fetchRequest, { apiErrorHandler } from 'services/api';
import styled from 'styled-components';
import { PageLocation } from 'types';
import breakpoints from 'utils/breakpoints';
import {
  checkScreenWidth,
  convertEpUserOrTeamToPersonaProps,
  getPagesLocations,
  onCombinedPickerSearch
} from 'utils/helpers';
import {
  ComboBox,
  CommandBar,
  Facepile,
  PanelType,
  SelectionMode,
  TextField
} from '@fluentui/react';
import { ADMINISTRATION_PAGES, useApiObject } from '../../../hooks/api2/index';
import { useTenant } from '../../../features/App/context/TenantContext';
import { fetchJson } from '../../../services/api2';

const ListTableContainer = styled.div`
  margin: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 10%) 0px 0.3px 0.9px, rgb(0 0 0 / 13%) 0px 1.6px 3.6px;

  max-height: 100%;
  display: flex;
  flex-direction: column;

  .details-list-wrapper {
    overflow-y: auto;
    height: 100%;
  }

  .ms-DetailsList-headerWrapper {
    top: 0;
    position: sticky;
    z-index: 100;
  }

  .ms-FocusZone {
    padding-top: 0px;
  }

  .codeColumn {
    margin: auto;
    text-align: center;
    padding-right: 0;
    padding-left: 0;
  }
`;

const CommandBarStyled = styled(CommandBar)`
  box-shadow: rgba(21, 27, 38, 0.15) 0px 1px 2px;
  border-top: 1px solid rgb(232, 236, 238);
  position: relative;
  z-index: 100;

  @media (min-width: ${breakpoints.largeMin}px) {
    .ms-FocusZone.ms-CommandBar {
      padding-left: 11px;
    }
  }

  @media (max-width: ${breakpoints.extraSmallMax}px) {
    .ms-FocusZone.ms-CommandBar {
      padding-left: 5px;
      padding-right: 5px;
    }
    .ms-Button-menuIcon {
      display: none;
    }
  }
`;

const TitleStyled = styled.span`
  color: ${(props) =>
    props.$disabled
      ? `rgb(${props.theme.detailsList.linkNameDisabledForeground})`
      : `rgb(${props.theme.detailsList.linkNameForeground})`};

  &:hover {
    color: ${(props) =>
      props.$disabled
        ? `rgb(${props.theme.detailsList.linkNameDisabledForeground})`
        : `rgb(${props.theme.detailsList.linkNameHoverForeground})`};
    cursor: ${(props) => (props.$disabled ? 'default' : 'pointer')};
  }
`;

function PagesList({ location, isAdmin = false, searchTerm, listRef, onPageClick }) {
  const { t } = useTranslation();

  const tenantId = useTenant();
  const { pages: allPages, revalidate } = usePages();
  const { data: adminPages, mutate } = useApiObject(ADMINISTRATION_PAGES, isAdmin ? {} : null);

  const pages = (isAdmin ? adminPages?.items ?? [] : allPages).filter((page) => {
    if (location) {
      return page.locations.includes(location);
    }

    return true;
  });

  const [currentPage, setCurrentPage] = useState(null);

  useHeaderStylesOnScroll(listRef);

  const onAddNewPage = useCallback(() => {
    setCurrentPage({ name: '', description: '' });
  }, []);

  function onDeletePage() {
    const deletedPage = currentPage.id;
    const promise = fetchRequest({
      url: `tenants/${tenantId}/administration/Pages/${currentPage.id}`,
      method: 'DELETE'
    }).then(() => {
      setCurrentPage(null);

      revalidate();
      return ({ items }) => ({ items: items.filter((page) => page.id !== deletedPage) });
    });

    mutate(promise, ({ items }) => ({
      items: items.filter((page) => page.id !== deletedPage)
    })).promise.catch(apiErrorHandler);
  }

  function onDialogDismiss() {
    setCurrentPage(null);
  }

  function onDialogConfirm() {
    const method = currentPage.id ? 'PUT' : 'POST';
    const url = currentPage.id
      ? `tenants/${tenantId}/administration/Pages/${currentPage.id}`
      : `tenants/${tenantId}/administration/Pages`;

    setCurrentPage(null);

    const mutation =
      (newPage) =>
      ({ items }) => {
        let newPages = [...items];

        if (method === 'PUT') {
          newPages = newPages.map((page) => (page.id === newPage.id ? newPage : page));
        } else {
          newPages = [...newPages, newPage].sort((a, b) => a.name.localeCompare(b.name));
        }

        return { items: newPages };
      };

    const promise = fetchJson({ url, method, body: currentPage }).then((response) => {
      revalidate();

      return mutation(response);
    });

    mutate(promise, mutation(currentPage)).promise.catch(apiErrorHandler);
  }

  function renderTeamsCell(teams) {
    if (!teams) return null;

    if (teams.length === 1) return teams[0].title;

    return (
      <div>
        <Facepile personaSize={1} personas={teams.map((team) => ({ personaName: team.title }))} />
      </div>
    );
  }

  function getLocationList() {
    if (pages?.length) {
      return (
        <ListTableContainer>
          <DetailsList
            items={pages}
            selectionMode={SelectionMode.none}
            selectionPreservedOnEmptyClick
            enterModalSelectionOnTouch
            styles={{ root: { height: '100%' } }}
            columns={[
              {
                key: 'name',
                isResizable: true,
                name: t('globals.title'),
                maxWidth: checkScreenWidth(['extraSmall']) ? 70 : 200,
                minWidth: checkScreenWidth(['extraSmall']) ? 70 : 200,
                onRender: (item) => (
                  <TitleStyled
                    role="presentation"
                    onClick={() => {
                      if (onPageClick) {
                        onPageClick(item);
                      } else {
                        setCurrentPage(item);
                      }
                    }}
                  >
                    {item.name}
                  </TitleStyled>
                )
              },
              isAdmin && {
                key: 'teams',
                isResizable: true,
                maxWidth: checkScreenWidth(['extraSmall']) ? 70 : 200,
                minWidth: checkScreenWidth(['extraSmall']) ? 70 : 200,
                name: t('analyticsAdministration.eligibleTeamsLabel'),
                onRender: (item) => renderTeamsCell(item.assignedTeams)
              },
              {
                isResizable: true,
                key: 'description',
                name: t('globals.description'),
                maxWidth: checkScreenWidth(['extraSmall']) ? 70 : 800,
                onRender: (item) =>
                  item.description && (
                    <RichTextEditor
                      styles={{ editorWrapper: { padding: 0 } }}
                      defaultValue={item.description}
                      disabled
                      required={false}
                    />
                  )
              }
            ].filter((x) => x)}
          />
        </ListTableContainer>
      );
    }

    return (
      <EmptyListPlaceHolder
        hidden={!!pages?.length}
        onCreateNew={isAdmin ? () => onAddNewPage() : undefined}
        createNewText={t('analyticsAdministration.newSite')}
        noItemsText={
          searchTerm
            ? t('analyticsAdministration.emptySearchResults')
            : t('analyticsAdministration.placeHolder', {
                context: isAdmin ? 'admin' : undefined
              })
        }
        listIconName={searchTerm ? 'SearchIssue' : 'BIDashboard'}
      />
    );
  }

  return (
    <>
      {isAdmin && (
        <MainCommandBar
          className="full-width"
          items={[
            {
              key: 'addRole',
              className: 'command-bar-item',
              text: t('analyticsAdministration.newSite'),
              iconProps: { iconName: 'Add' },
              onClick: () => onAddNewPage()
            }
          ]}
        />
      )}
      {getLocationList()}
      {currentPage && (
        <Panel
          onRenderHeader={() => (
            <div>
              <div
                style={{
                  fontSize: '28px',
                  paddingLeft: '16px',
                  color: 'white',
                  marginBottom: 20
                }}
              >
                {t('analyticsAdministration.panelHeaderTitle', {
                  context: currentPage?.id ? 'edit' : 'create'
                })}
              </div>
              <CommandBarStyled
                farItems={[
                  {
                    iconProps: { iconName: 'Delete' },
                    key: 'delete',
                    onClick: () => onDeletePage(),
                    text: t(`globals.delete`)
                  }
                ]}
                items={[
                  {
                    key: 'save',
                    text: t('detailTask.commandBar.button.save'),
                    iconProps: { iconName: 'Save' },
                    disabled: !currentPage?.name || !currentPage?.url,
                    onClick: () => onDialogConfirm()
                  }
                ]}
                styles={{
                  root: {
                    position: 'relative',
                    zIndex: 300,
                    boxShadow: '0 1.6px 3.6px 0 rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%);'
                  }
                }}
              />
            </div>
          )}
          isLightDismiss={false}
          isOpen={!!currentPage}
          onDismiss={onDialogDismiss}
          onRenderBody={() => (
            <PagePanelBody
              page={currentPage}
              onChange={(page) => {
                setCurrentPage({ ...page });
              }}
            />
          )}
          type={PanelType.medium}
        />
      )}
    </>
  );
}

const PARAMS = {
  processId: 'processId',
  processTemplateId: 'processTemplateId',
  projectId: 'projectId',
  projectTemplateId: 'projectTemplateId',
  taskId: 'taskId',
  teamId: 'teamId',
  userId: 'userId'
};

function PagePanelBody({ page, onChange }) {
  const { t } = useTranslation();

  const [availableParams, setAvailableParams] = useState([]);

  useEffect(() => {
    setAvailableParams(() => {
      const params = [PARAMS.userId];
      const { processId, processTemplateId, projectId, projectTemplateId, taskId, teamId } = PARAMS;

      switch (page.locations?.[0]) {
        case PageLocation.processDetails:
          params.push(processId, processTemplateId, teamId);
          break;

        case PageLocation.processList:
          params.push(processId, processTemplateId, teamId);
          break;

        case PageLocation.processTask:
          params.push(processId, processTemplateId, teamId, taskId);
          break;

        case PageLocation.projectTask:
          params.push(projectId, projectTemplateId, teamId, taskId);
          break;

        case PageLocation.projectDetails:
          params.push(projectId, projectTemplateId, teamId);
          break;

        case PageLocation.project:
          params.push(projectId, projectTemplateId, teamId);
          break;

        case PageLocation.tasksLists:
          params.push(teamId);
          break;

        default:
          break;
      }

      return params.map((param) => `{${param}}`);
    });
  }, [page.locations]);

  return (
    <div style={{ margin: '20px' }}>
      <SmallTextField
        defaultValue={page?.name}
        label={t('globals.title')}
        onChange={(value) => onChange({ ...page, name: value })}
        required
        styles={{
          root: { margin: 0 },
          fieldGroup: [
            {
              border: '1px solid #a19f9d',
              borderRadius: 3,
              ':after': { borderRadius: 3 }
            }
          ],
          field: { color: '#323130' }
        }}
      />
      <ComboBox
        allowFreeform
        autoComplete="on"
        label={t('analyticsAdministration.location.label')}
        selectedKey={page.locations}
        multiSelect={false}
        onChange={(_, value) => onChange({ ...page, locations: [value?.key] })}
        options={getPagesLocations()}
      />
      <TextField
        defaultValue={page?.url}
        label="URL"
        maxLength={3000}
        onChange={(_, value) => onChange({ ...page, url: value })}
        required
        description={`${t('analyticsAdministration.urlDescription')}: ${availableParams.join(
          ', '
        )}`}
        styles={{
          description: { fontSize: 12 },
          root: { margin: 0 },
          fieldGroup: [
            {
              border: '1px solid #a19f9d',
              borderRadius: 3,
              ':after': { borderRadius: 3 }
            }
          ],
          field: { color: '#323130' }
        }}
      />
      <CombinedPicker
        label={t('analyticsAdministration.eligibleTeamsLabel')}
        defaultValue={page?.assignedTeams?.map(convertEpUserOrTeamToPersonaProps)}
        itemLimit={100}
        required={false}
        onSearch={(filterText) => {
          return onCombinedPickerSearch({
            filterText,
            searchTeams: true,
            searchUser: false
          }).then((response) => {
            const { teams } = response;
            return teams;
          });
        }}
        onChange={(selectedPersonArray) => {
          onChange({ ...page, assignedTeams: selectedPersonArray });
        }}
        styles={{ itemsWrapper: { padding: 2 } }}
      />
      {/* <CombinedPicker
        label={t('analyticsAdministration.eligibleUserLabel')}
        defaultValue={page?.assignedUsers?.map(convertEpUserOrTeamToPersonaProps)}
        itemLimit={100}
        required={false}
        onSearch={(filterText) => {
          return onCombinedPickerSearch({
            filterText,
            searchTeams: false,
            searchUser: true
          }).then((response) => {
            const { users } = response;
            return users;
          });
        }}
        onChange={(selectedPersonArray) => {
          onChange({ ...page, assignedUsers: selectedPersonArray });
        }}
        styles={{ itemsWrapper: { padding: 2 } }}
      /> */}
      <LongTextField
        label={t('createField.dialog.description.label')}
        defaultValue={page?.description}
        onChange={(value) => onChange({ ...page, description: value })}
      />
    </div>
  );
}

PagesList.propTypes = {
  searchTerm: PropTypes.string,
  isAdmin: PropTypes.bool,
  location: PropTypes.number,
  onPageClick: PropTypes.func,
  listRef: PropTypes.object
};

PagesList.defaultProps = {
  searchTerm: null,
  listRef: null,
  location: null
};

PagePanelBody.propTypes = {
  page: PropTypes.object.isRequired,
  onChange: PropTypes.func
};

export default PagesList;
