import { useCallback, useEffect, useState } from 'react';
import fetchRequest, { apiErrorHandler } from 'services/api';
import { instancePropType } from 'types';
import { Image } from '@fluentui/react';

export default function RouteInstanceListImageCell({ instance }) {
  const [instanceImage, setInstanceImage] = useState(null);

  const setImageBlobUrl = useCallback(async (instance) => {
    let blobUrl;
    if (instance && instance.imageId !== '00000000-0000-0000-0000-000000000000') {
      blobUrl = await fetchRequest({
        url: `File/Contents?id=${instance.imageId}&size=s`,
        ignoreContentType: true
      }).catch(apiErrorHandler);
      blobUrl = await blobUrl.blob();
      blobUrl = URL.createObjectURL(blobUrl);
    }
    setInstanceImage(blobUrl);
  }, []);

  useEffect(
    () => () => {
      if (instance.imageUrl) {
        window.URL.revokeObjectURL(instanceImage);
      }
      return null;
    },
    [instance, instanceImage]
  );

  useEffect(() => {
    if (instance.imageId) {
      setImageBlobUrl(instance);
    }
  }, [instance, setImageBlobUrl]);

  if (instance.imageId && instance.imageId !== '00000000-0000-0000-0000-000000000000') {
    return <Image className="image-preview-image" src={instanceImage} />;
  }
  return null;
}

RouteInstanceListImageCell.propTypes = {
  instance: instancePropType.isRequired
};
