import Label, { ILabelProps } from 'components/inputs/Label';
import { $getRoot, EditorState, LexicalEditor } from 'lexical';
import { CSSProperties, useState } from 'react';
import { ITagProps } from 'types';
import { $generateHtmlFromNodes } from '@lexical/html';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { InitialConfigType, LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { NodeEventPlugin } from '@lexical/react/LexicalNodeEventPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { LexicaWrapper } from './RichTextEditor.styles';
import Theme from './Theme';
import { ExtentedTextNode } from './components/ExtentedTextNode';
import { ImageNode } from './components/ImageNode';
import { $isMentionNode, MentionNode } from './components/MentionNode';
import { $isTagNode, TagNode } from './components/TagNode';
import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import ClickableLinkPlugin from './plugins/ClickableLinkPlugin';
import DragDropPastePlugin from './plugins/DragDropPastePlugin';
import FocusBlurPlugin from './plugins/FocusBlurPlugin';
import HorizontalRulePlugin from './plugins/HorizontalRulePlugin';
import ImagesPlugin from './plugins/ImagesPlugin';
import InitializePlugin from './plugins/InitializePlugin';
import LinkPlugin from './plugins/LinkPlugin';
import MentionsPlugin from './plugins/MentionsPlugin';
import TabFocusPlugin from './plugins/TabFocusPlugin';
import TagsPlugin from './plugins/TagsPlugin';
import Toolbar from './plugins/Toolbar';

export interface IMentionProps {
  id?: string | null;
  name?: string | null;
  avatar?: string | null;
  userId: string;
  login: string;
  pictureUrl?: string | null;
  tenantId?: string | null;
}

export interface IRichTextEditorProps {
  id?: string;
  /**
   * Optional flag to enable/disable mentioning
   * @defaultvalue false
   */
  allowMentioning?: boolean;
  /**
   * Optional flag to enable/disable tagging
   * @defaultvalue false
   */
  allowTagging?: boolean;
  /**
   * Default value of the rich text editor.
   */
  defaultValue?: string | null;
  /**
   * Description of the rich text editor.
   */
  description?: string;
  /**
   * Optional flag to show/hide the rte-commandbar
   * @defaultvalue false
   */
  displayCmdBar?: boolean;
  /**
   * Label displayed above the rich text editor
   */
  label?: string;
  /**
   * The icon that will be displayed to the left of the label
   */
  labelIconName?: string;
  /**
   * Callback issued when the editor changes.
   */
  onFocus?: (event?: React.SyntheticEvent) => void;
  /**
   * Callback issued when the editor changes.
   */
  onChange?: (onChangeProps: {
    value?: string | null;
    mentions: IMentionProps[];
    tags: ITagProps[];
  }) => void;
  /**
   * Callback issued when the editor changes.
   */
  onBlur?: (event?: React.SyntheticEvent) => void;
  /**
   * Callback issued when a tag is clicked.
   */
  onTagClick?: (id: string) => void;
  /**
   * Input placeholder text. Displayed until text is entered.
   */
  placeholder?: string;
  /**
   * Optional flag to mark rte as readOnly
   * @defaultvalue false
   */
  disabled?: boolean;
  /**
   * Whether the associated form field is required or not
   * @defaultvalue false
   */
  required?: boolean;
  /**
   * Whether the rte is focused on render
   * @defaultvalue false
   */
  setInitialFocus?: boolean;
  /**
   * Call to provide customized styling that will layer on top of the variant rules.
   */
  styles?: {
    editorWrapper?: CSSProperties;
    editor?: CSSProperties;
    label?: ILabelProps['styles'];
  };
}

function getEditorConfig(
  {
    allowMentioning,
    allowTagging,
    disabled = false
  }: {
    allowMentioning?: boolean;
    allowTagging?: boolean;
    disabled?: boolean;
  } = { allowMentioning: false, allowTagging: false }
) {
  const editorConfig: InitialConfigType = {
    // The editor theme
    theme: Theme,
    // Handling of errors during update
    onError(error) {
      throw error;
    },
    editable: !disabled,
    namespace: 'editor',
    nodes: [
      ExtentedTextNode,
      ImageNode,
      HeadingNode,
      ListNode,
      LinkNode,
      ListItemNode,
      HorizontalRuleNode,
      QuoteNode,
      AutoLinkNode
    ]
  };

  if (editorConfig?.nodes && Array.isArray(editorConfig?.nodes)) {
    if (disabled || allowMentioning) {
      editorConfig?.nodes.push(MentionNode);
    }

    if (disabled || allowTagging) {
      editorConfig?.nodes.push(TagNode);
    }
  }

  return editorConfig;
}

export default function Editor({
  id,
  allowMentioning = false,
  allowTagging = false,
  defaultValue,
  description,
  disabled = false,
  displayCmdBar = false,
  label,
  labelIconName,
  onBlur,
  onChange,
  onFocus,
  onTagClick,
  placeholder,
  required = false,
  setInitialFocus = false,
  styles
}: IRichTextEditorProps) {
  const [initialized, setInitialized] = useState(false);
  const [focused, setFocused] = useState(false);

  const onEditorChange = (editorState: EditorState, editor: LexicalEditor) => {
    if (initialized && onChange) {
      editorState.read(() => {
        const tags: ITagProps[] = [];
        const mentions: IMentionProps[] = [];

        const root = $getRoot();
        const allTextNodes = root.getAllTextNodes();

        allTextNodes.forEach((textNode) => {
          if ($isMentionNode(textNode)) {
            mentions.push({
              id: textNode.userId,
              name: textNode.mentionName,
              userId: textNode.userId,
              login: textNode.login
            });
          } else if ($isTagNode(textNode)) {
            tags.push({ id: textNode.id, fullText: textNode.tag, text: textNode.tag });
          }
        });

        let html: string | null = $generateHtmlFromNodes(editor, null);

        if (!html || html === '<p class="editor-paragraph"><br></p>') {
          html = null;
        }

        onChange?.({ value: html, tags, mentions });
      });
    }
  };

  function getRichTextEditorClassNames(): string {
    let classNames = 'rte-base ';

    if (focused) {
      classNames = 'rte-focused ';
    }

    if (disabled) {
      classNames = 'rte-disabled ';
    }

    return classNames;
  }

  return (
    <LexicaWrapper $focused={focused}>
      <Label
        styles={styles?.label}
        label={label}
        required={required}
        description={description}
        iconName={labelIconName}
      />
      <div
        style={styles?.editorWrapper}
        className={`editor-shell ${getRichTextEditorClassNames()}`}
      >
        <LexicalComposer
          key={disabled?.toString()}
          initialConfig={getEditorConfig({ allowMentioning, allowTagging, disabled })}
        >
          <NodeEventPlugin
            nodeType={TagNode}
            eventType="click"
            eventListener={(e: Event) => {
              const target = e.target as HTMLElement;
              const id = target?.id;

              if (id && onTagClick) {
                onTagClick?.(target?.id);
              }
            }}
          />
          <OnChangePlugin onChange={onEditorChange} />
          <InitializePlugin
            onInitialized={() => setInitialized(true)}
            defaultValue={defaultValue || undefined}
          />
          <div className="editor-container">
            {displayCmdBar && <Toolbar />}
            <div className="editor-inner">
              <RichTextPlugin
                contentEditable={
                  <ContentEditable style={styles?.editor} className="editor-input" />
                }
                placeholder={<div className="editor-placeholder">{placeholder}</div>}
                ErrorBoundary={LexicalErrorBoundary}
              />
              {allowMentioning && <MentionsPlugin />}
              {allowTagging && <TagsPlugin />}
              {setInitialFocus && <AutoFocusPlugin />}
              <FocusBlurPlugin
                id={id}
                onBlur={() => {
                  setFocused(false);
                  onBlur?.();
                }}
                onFocus={() => {
                  setFocused(true);
                  onFocus?.();
                }}
              />
              <HistoryPlugin />
              <ImagesPlugin />
              <TabFocusPlugin />
              <DragDropPastePlugin />
              <ClickableLinkPlugin focused={focused} />
              <ListPlugin />
              <HorizontalRulePlugin />
              <LinkPlugin />
              <AutoLinkPlugin />
            </div>
          </div>
        </LexicalComposer>
      </div>
    </LexicaWrapper>
  );
}
