import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Spinner, SpinnerSize } from '@fluentui/react';

const LoadingMoreStyled = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
`;

function LoadingMore({ text }) {
  return (
    <LoadingMoreStyled>
      <Spinner size={SpinnerSize.large} label={text} />
    </LoadingMoreStyled>
  );
}

LoadingMore.propTypes = {
  text: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
};

LoadingMore.defaultProps = {
  text: null
};

export default LoadingMore;
