import { ExpiredTestPeriodDialog } from 'components';
import { GlobalLoadingScreen } from 'components/progress';
import { useOnBoarding } from 'hooks';
import RedirectContainer from 'layouts/RedirectContainer';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useReducer, useState } from 'react';
import fetchRequest, { apiErrorHandler } from 'services/api';
import { ThemeProvider } from 'styled-components';
import { getDefaultTheme } from 'utils/helpers';
import { createFluentTheme, createStyledTheme, ThemeConfigContext } from 'utils/theme';
import { KEEP_ALL_EXISTING_COLORS_FOR_NOW } from 'utils/themeDomains';
import { ThemeProvider as FluentThemeProvider, loadTheme } from '@fluentui/react';
import { ApiDataCache, ApiDataCacheContext } from 'hooks/api2/cache';
import { AppContext, NotificationProvider, Routing } from './features/App';
import { APIClientStoreProvider } from './features/App/context/APIClientStore';
import { MsTeamsConfigProvider } from './features/App/context/MsTeamsConfigProvider';
import { PagesProvider } from './features/App/context/PagesContext';
import { useMsTeamsInitialization } from './hooks';
import GlobalStyle from './index.styled';
import { ai } from './features/ErrorHandling/components/AppInsights';
import { TenantContext } from './features/App/context/TenantContext';
import { BackgroundJobsProvider } from './features/BackgroundJobs';

function reducer(state, action) {
  switch (action.type) {
    case 'init':
      return { ...state, ...action.data };
    case 'searchTerm':
      return { ...state, searchTerm: action.data };
    case 'addTeam':
      return { ...state, teams: [action.data, ...state.teams] };
    case 'updateTeam': {
      const index = state.teams.findIndex((team) => team.id === action.data.id);

      state.teams.splice(index, 1, action.data);

      return { ...state, teams: state.teams };
    }
    case 'deleteTeam':
      return { ...state, teams: state.teams.filter((team) => team.id !== action.data.id) };
    case 'setTheme':
      return { ...state, theme: { ...state.theme, palette: action.data } };
    case 'usePlanner':
      return { ...state, theme: { ...state.theme, usePlanner: action.data } };
    case 'tenantId':
      return { ...state, currentTenantId: action.data };
    case 'updateNotificationsCount':
      return { ...state, unreadNotifications: action.data };
    case 'toggleNavigation':
      return { ...state, isNavigationPanelHidden: !state.isNavigationPanelHidden };
    case 'setUseStepsMatrix':
      return { ...state, useStepsMatrix: action.data };
    default:
      throw new Error();
  }
}

const ALLOW_DARK_THEME = !KEEP_ALL_EXISTING_COLORS_FOR_NOW;

function AppContainer({ logout }) {
  const [loaded, setLoaded] = useState(false);
  const [loadError, setLoadError] = useState(null);

  const [globalAppState, dispatch] = useReducer(reducer, {
    searchTerm: null,
    isNavigationPanelHidden: true,
    theme: { palette: getDefaultTheme() }
  });
  const [tenantId, setTenantId] = useState('');
  const [memberId, setMemberId] = useState('');

  const defaultTitle = 'Evocom';
  const onboardingBubbles = useOnBoarding(globalAppState.currentUser);

  const [initFetched, setInitFetched] = useState(false);
  const [showLoadingContent, setShowLoadingContent] = useState(false);
  // const [showNewTenantDialog, setShowNewTenantDialog] = useState(false);

  const { contentToShow, initialized, config } = useMsTeamsInitialization();

  const appContextValue = useMemo(() => ({ globalAppState, dispatch }), [globalAppState]);

  const noAccess = window.location.pathname.indexOf('access') === 1;
  // const isMsTeamsConfigPage = window.location.href.includes('teams-config');

  // let onBoardingActive;
  // if (window.baseConfig) {
  //   ({ onBoardingActive } = window.baseConfig);
  // }

  useEffect(() => {
    import('chart.js').then(({ Chart, ArcElement, Legend, Title, Tooltip }) => {
      Chart.register(ArcElement, Legend, Title, Tooltip);
    });
  }, []);

  useEffect(() => {
    if (!noAccess && initialized && !initFetched) {
      const init = async () => {
        setInitFetched(true);

        const initQueryParam = window.localStorage.getItem('initQueryParam');
        const url = initQueryParam ? `Init/${initQueryParam}` : 'Init';

        fetchRequest({ url })
          .then((data) => {
            const localStorageTenantId = window.localStorage.getItem('tenantId');
            const localStorageVersion = JSON.parse(window.localStorage.getItem('version'));

            window.localStorage.setItem('useAIField', data.permissions.features?.useAIField);

            // load fabric theme
            const palette = { ...data.theme.palette };
            Object.keys(palette).forEach((key) => {
              // remove empty palette keys
              if (palette[key] === null) {
                delete palette[key];
              }
            });

            if (data.currentTenantId !== localStorageTenantId) {
              window.localStorage.setItem('tenantId', data.currentTenantId);
            }
            setTenantId(data.currentTenantId);
            setMemberId(data.currentUser.userId);

            if (!localStorageVersion || localStorageVersion.number !== data.apiVersion) {
              window.localStorage.setItem(
                'version',
                JSON.stringify({ number: data.apiVersion, new: true })
              );
            }

            const currentTenant = data.tenants.find(
              (tenant) => tenant.tenantId === data.currentTenantId
            );

            const currentUser = {
              ...data.currentUser,
              isInternalUser: currentTenant?.directoryId === data.currentUser?.tenantId,
              isAdmin: data.permissions?.admin
            };

            ai?.setAuthenticatedUserContext(data.currentUser.userId);

            document
              .querySelector('meta[name="theme-color"]')
              .setAttribute('content', data.theme.palette.themePrimary);

            dispatch({
              type: 'init',
              data: {
                ...data,
                preventNavigation: contentToShow?.selectedProject || contentToShow?.selectedProcess,
                selectedProcess: contentToShow?.selectedProcess,
                currentUser,
                theme: {
                  ...data.theme,
                  title: data.theme.title || defaultTitle,
                  palette: { ...globalAppState.theme.palette, ...palette }
                }
              }
            });

            if (initQueryParam) {
              window.localStorage.removeItem('initQueryParam');
            }

            setLoaded(true);
            // setShowNewTenantDialog(data.isNewTenant);
          })
          .catch((err) => {
            setLoadError(err);

            apiErrorHandler(err);
          });
      };

      init();
    }
  }, [
    noAccess,
    initialized,
    defaultTitle,
    globalAppState.theme.palette,
    initFetched,
    contentToShow?.selectedProject,
    contentToShow?.selectedProcess
  ]);

  useEffect(() => {
    if (initialized && !loaded) {
      setTimeout(() => {
        // Wait 500 milliseconds before the loading content is displayed
        // otherwise there will be a flickering on every init call
        setShowLoadingContent(true);
      }, 400);
    }
  }, [initialized, loaded]);

  const [isDarkTheme, setIsDarkTheme] = useState(
    window.matchMedia('(prefers-color-scheme: dark)').matches
  );
  useEffect(() => {
    const dark = window.matchMedia('(prefers-color-scheme: dark)');
    const onChange = () => {
      setIsDarkTheme(dark.matches);
    };
    dark.addEventListener('change', onChange);
    return () => {
      dark.removeEventListener('change', onChange);
    };
  }, []);

  const [themeOverride, setThemeOverride] = useState(/** @type {Partial<ThemeConfig>|null} */ null);

  const themeConfig = useMemo(() => {
    const setOverride = (cfg, set) =>
      setThemeOverride((current) => {
        if (set) return cfg;
        return current === cfg ? null : current;
      });

    return {
      fluentPalette: globalAppState.theme.palette,
      dark: isDarkTheme && ALLOW_DARK_THEME,
      ...(themeOverride || {}),
      setOverride
    };
  }, [globalAppState.theme.palette, isDarkTheme, themeOverride]);
  const styledTheme = useMemo(() => createStyledTheme(themeConfig), [themeConfig]);
  const fluentTheme = useMemo(() => createFluentTheme(themeConfig), [themeConfig]);
  useEffect(() => {
    loadTheme(fluentTheme);
  }, [fluentTheme]);

  const apiDataCache = useMemo(() => new ApiDataCache(tenantId, memberId), [tenantId, memberId]);

  if (noAccess) {
    return <Routing currentUser={{}} />;
  }

  if (loaded && globalAppState.currentUser) {
    return (
      <AppContext.Provider value={appContextValue}>
        <RedirectContainer />
        <TenantContext.Provider value={tenantId}>
          <ApiDataCacheContext.Provider value={apiDataCache}>
            <MsTeamsConfigProvider config={config}>
              <PagesProvider>
                <APIClientStoreProvider>
                  <ThemeConfigContext.Provider value={themeConfig}>
                    <FluentThemeProvider
                      theme={fluentTheme}
                      style={{ height: '100%', background: 'transparent' }}
                    >
                      <ThemeProvider theme={styledTheme}>
                        <BackgroundJobsProvider>
                          <NotificationProvider>
                            <GlobalStyle themeConfig={themeConfig} />
                            <ExpiredTestPeriodDialog
                              currentTenantId={window.localStorage.getItem('tenantId')}
                              tenants={globalAppState.tenants}
                            />
                            <Routing contentToShow={contentToShow} logout={logout} />
                            {/* {!isMsTeamsConfigPage && showNewTenantDialog && onBoardingActive && <NewTenantDialog />} */}
                            {onboardingBubbles}
                          </NotificationProvider>
                        </BackgroundJobsProvider>
                      </ThemeProvider>
                    </FluentThemeProvider>
                  </ThemeConfigContext.Provider>
                </APIClientStoreProvider>
              </PagesProvider>
            </MsTeamsConfigProvider>
          </ApiDataCacheContext.Provider>
        </TenantContext.Provider>
      </AppContext.Provider>
    );
  }

  if (showLoadingContent) {
    return (
      <ThemeProvider theme={styledTheme}>
        <GlobalStyle themeConfig={themeConfig} />
        <GlobalLoadingScreen error={loadError} />
      </ThemeProvider>
    );
  }

  return null;
}

AppContainer.propTypes = {
  logout: PropTypes.func
};

AppContainer.defaultProps = {
  logout: null
};

export default AppContainer;
