// eslint-disable-next-line import/prefer-default-export
export function mutateExistingOrAppend<T>(
  items: Readonly<T[]>,
  isSameItem: (a: T) => boolean,
  item: (existing?: T) => T
) {
  const existingIndex = items.findIndex((i) => isSameItem(i));

  if (existingIndex > -1) {
    const newItems = [...items];
    newItems[existingIndex] = item(newItems[existingIndex]);
    return newItems;
  }

  return [...items, item()];
}
