import styled from 'styled-components';
import { IconButton } from '@fluentui/react';

const MultiMediaFieldStyled = styled.div`
  .document-image-preview {
    height: 50px;
    max-width: 150px;
    object-fit: cover;

    :hover {
      cursor: pointer;
    }
  }

  .c-preview-overlay_overflow-button {
    display: none;
    position: absolute;
    top: 5px;
    right: 5px;
  }

  .c-preview-overlay_wrapper:hover {
    cursor: pointer;

    .c-preview-overlay_overflow-button {
      z-index: 99;
      display: inline;
    }
  }

  .c-preview-overlay {
    z-index: 90;
    width: 132px;
    height: 132px;
    position: absolute;
    background: transparent;
    top: 0px;
    left: 0px;
  }

  .c-preview-icon {
    z-index: 90;
    font-size: 28px;
    color: white;
    position: absolute;
    top: 50px;
  }
`;

const IconButtonStyled = styled(IconButton)``;

export { MultiMediaFieldStyled, IconButtonStyled };
