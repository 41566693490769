import { AppContext } from 'features/App';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import breakpoints from 'utils/breakpoints';
import { isInIframe } from 'utils/helpers';
import AdministrationBar from './AdministrationBar';

// styles
const SideBarStyled = styled.div`
  background: rgb(${(props) => props.theme.sidebar.background});
  width: 100%;
  max-width: 250px;
  min-width: 250px;
  border-right: 1px solid rgb(${(props) => props.theme.sidebar.border});
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .ms-SearchBox {
    height: 44px;
    border: ${(props) => props.theme.neutralLight};
    border-bottom: 1px solid ${(props) => props.theme.neutralLight};
  }

  @media (max-width: ${breakpoints.smallMax}px) {
    min-width: 100%;
    width: 100%;

    .ms-SearchBox {
      padding-right: 9px;
    }
  }
`;

function SideBar({ children, onAdministrationButtonClick }) {
  const [showSidebar, setShowSidebar] = useState(false);
  const { pathname } = useLocation();

  // global app state
  const { globalAppState } = useContext(AppContext);
  const { currentUser } = globalAppState;

  useEffect(() => {
    if (isInIframe() || pathname.includes('/process-designer')) {
      setShowSidebar(false);
    } else {
      setShowSidebar(true);
    }
  }, [pathname]);

  if (!showSidebar) {
    return null;
  }

  return (
    <SideBarStyled id="sidebar">
      {children}
      {currentUser?.isAdmin ? <AdministrationBar onClick={onAdministrationButtonClick} /> : null}
    </SideBarStyled>
  );
}

export default SideBar;

SideBar.propTypes = {
  onAdministrationButtonClick: PropTypes.func,
  children: PropTypes.node.isRequired
};
