import styled from 'styled-components';
import { Icon } from '@fluentui/react';

// eslint-disable-next-line import/prefer-default-export
export const DialogContentStyled = styled.div`
  display: inline-block;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;

  .c-choice-container {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .c-checklist_item-icon {
    font-size: 20px;
    padding-right: 2px;
    color: #b4b4b4;
  }

  .field-type-dropdown {
    width: 50%;
    margin-right: 10px;
  }

  .chkAllowFillInn {
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .chkAllowScan {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .additional-field-settings-checkbox-container {
    padding: 15px 0 4px 3px;
  }

  .ms-ChoiceFieldGroup-flexContainer {
    display: flex;
  }

  .ms-ChoiceField {
    margin-right: 15px;
    margin-top: 2px;
  }

  .ms-Slider {
    margin-top: 7px;
  }

  .ms-Slider .ms-Label {
    margin-left: 6px;
  }

  .ms-Slider-container {
    margin-top: 5px;
  }

  .ms-Slider-inactive {
    height: 3px;
  }

  .ms-Slider-active {
    height: 3px;
  }

  .choice-list {
    padding-bottom: 2px;
    max-height: 200px;
  }

  .add-choice-textfield {
    width: 100%;
    margin-right: 8px;
  }

  .add-choice-container {
    margin-top: 10px;
  }

  .choice-render-row {
    padding: 7px 0 7px 8px;
    margin-right: 9px;
    justify-content: space-between;

    .ms-Button-icon {
      font-size: 10px;
    }

    .ms-Button {
      max-height: 19px;
    }
  }

  .choice-row-border-bottom {
    border-bottom: 1px solid #edebe9;
  }

  .clear-choice-button {
    font-size: 9px;
  }

  .dialog-header {
    font-size: 11px;
    color: red;
  }
`;
export const LookupOptionsContainer = styled.div`
  .row-container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 5px;
  }

  .right-side {
    padding-bottom: 5px;
  }
`;
export const CustomLabelStyled = styled.div`
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;

  .info-icon {
    font-size: 11px;
    color: rgb(0, 120, 212);
  }
`;
export const InfoIcon = styled(Icon)`
  margin: 0 10px 0 5px;

  &:hover {
    cursor: help;
  }
`;

export const TemplateFieldLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 3px;
  width: 100%;
  margin-bottom: 5px;
  border-bottom: 1px solid rgb(220, 220, 220);
  color: ${(props) => (props.$translation ? 'rgb(161, 159, 157)' : null)};

  .display-settings-icon {
    min-width: 17px;
    font-size: 17px;
    margin-right: 7px;
    margin-left: 7px;
  }

  &:hover {
    .deleteTemplateFieldLink-btn {
      visibility: ${(props) => (props.$translation ? null : 'visible')};
    }
  }

  .deleteTemplateFieldLink-btn {
    visibility: hidden;
    margin-left: 20px;
    background-color: transparent;
  }

  .deleteTemplateFieldLink-icon {
    font-size: 14px;
    margin-bottom: -2px;
  }
`;

export const TranslationInput = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 5px;
`;

export const DialogInputContent = styled.div`
  display: flex;
  width: 100%;
`;

export const LookupFieldsListStyled = styled.div`
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 10px;
`;
