import { useState } from 'react';
import { IFileProps } from 'types';
import { IButtonStyles, IconButton, Modal } from '@fluentui/react';
import MultiMediaImage from './MultiMediaImage';
import MultiMediaVideo from './MultiMediaVideo';

export interface IMultiMediaModalProps {
  defaultMediaValueIndex: number;
  getFileContents: ({ id }: { id: string }) => Promise<Response>;
  getStreamUrl: ({ fileId, tenantId }: { fileId: string; tenantId: string }) => Promise<string>;
  mediaValues: IFileProps[];
  onDismiss: () => void;
  tenantId: string;
}

function MultiMediaModal({
  defaultMediaValueIndex,
  getFileContents,
  mediaValues,
  getStreamUrl,
  onDismiss,
  tenantId
}: IMultiMediaModalProps): JSX.Element {
  const [mediaValueIndex, setMediaValueIndex] = useState(defaultMediaValueIndex);

  function isImage(text?: string): boolean {
    if (!text) return false;
    return /.png|.jpg|.jpeg|.gif|.tiff|.tif/i.test(text);
  }

  function isVideo(text?: string): boolean {
    if (!text) return false;
    return /.mp4|.ogg|.ogv|.webm|.MOV|.WMV|.AVI/i.test(text);
  }

  function getButtonStyles(): IButtonStyles {
    const IconButtonStyles: IButtonStyles = {
      root: {
        backgroundColor: 'rgb(207, 207, 207, 0.4)',
        color: 'rgb(50, 49, 48);',
        position: 'absolute',
        zIndex: 100
      },
      rootHovered: { backgroundColor: 'rgb(207, 207, 207, 0.6)', color: 'rgb(32, 31, 30)' },
      rootPressed: { backgroundColor: 'rgb(189, 189, 189, 0.6)', color: 'rgb(32, 31, 30)' }
    };

    return IconButtonStyles;
  }

  function renderImagePreviewCloseButton(): JSX.Element {
    const styles = getButtonStyles();

    if (typeof styles.root === 'object') {
      styles.root = { ...styles.root, marginRight: 15, marginTop: 10, right: 0 };
    }

    return <IconButton iconProps={{ iconName: 'Cancel' }} onClick={onDismiss} styles={styles} />;
  }

  function renderChangeIndexIconButtons() {
    const styles = getButtonStyles();

    const leftStyles = { ...styles };

    if (typeof leftStyles.root === 'object') {
      leftStyles.root = { ...leftStyles.root, top: '50%', left: 15, width: 50, height: 50 };
      leftStyles.icon = { fontSize: 20 };
    }

    const rightStyles = { ...styles };

    if (typeof rightStyles.root === 'object') {
      rightStyles.root = { ...rightStyles.root, top: '50%', right: 15, width: 50, height: 50 };
      rightStyles.icon = { fontSize: 20 };
    }

    return (
      <>
        {mediaValueIndex > 0 && (
          <IconButton
            iconProps={{ iconName: 'ChevronLeftMed' }}
            onClick={() => setMediaValueIndex((p) => p - 1)}
            styles={leftStyles}
          />
        )}
        {mediaValueIndex < mediaValues.length - 1 && (
          <IconButton
            iconProps={{ iconName: 'ChevronRightMed' }}
            onClick={() => setMediaValueIndex((p) => p + 1)}
            styles={rightStyles}
          />
        )}
      </>
    );
  }

  function renderModalContent(): JSX.Element | null {
    const mediaValueToDisplay = mediaValues[mediaValueIndex];

    if (mediaValueToDisplay?.url?.includes('https://web.microsoftstream.com/')) {
      let url = mediaValueToDisplay?.url;

      if (mediaValueToDisplay?.url?.includes('?')) {
        url += '&autoplay=true&showinfo=true';
      } else {
        url += '?autoplay=true&showinfo=true';
      }

      return (
        <>
          {renderImagePreviewCloseButton()}
          {renderChangeIndexIconButtons()}
          <iframe
            title="test"
            width="100%"
            style={{ border: 'none' }}
            height="100%"
            src={url}
            allowFullScreen
          />
        </>
      );
    }

    if (
      mediaValueToDisplay.blobFile?.type?.includes('image') ||
      isImage(mediaValueToDisplay.text)
    ) {
      return (
        <div style={{ overflow: 'auto' }}>
          {renderImagePreviewCloseButton()}
          {renderChangeIndexIconButtons()}
          <MultiMediaImage
            objectFit="contain"
            mediaValue={mediaValueToDisplay}
            getFileContents={getFileContents}
          />
        </div>
      );
    }

    if (
      mediaValueToDisplay.blobFile?.type?.includes('video') ||
      isVideo(mediaValueToDisplay.text)
    ) {
      return (
        <>
          {renderImagePreviewCloseButton()}
          {renderChangeIndexIconButtons()}
          <MultiMediaVideo
            autoPlay
            getStreamUrl={getStreamUrl}
            controls
            mediaValue={mediaValueToDisplay}
            tenantId={tenantId}
          />
        </>
      );
    }

    return null;
  }

  return (
    <Modal
      isOpen
      onDismiss={onDismiss}
      isBlocking={false}
      styles={{
        scrollableContent: { height: '100%' },
        main: { width: '100%', height: '100%' }
      }}
    >
      {renderModalContent()}
    </Modal>
  );
}

export default MultiMediaModal;
