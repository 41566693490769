import styled from 'styled-components';

const DynamicSurveyResultFieldStyled = styled.div`
  .c-survey-result-content-wrapper {
    position: relative;
    padding: 8px;
    border: 1px solid #a19f9d;
    border-radius: 3px;
    width: 100%;

    .c-showdetails-overflow-button {
      visibility: hidden;
      position: absolute;
      right: 7px;
      top: 7px;
    }
  }

  .c-survey-result-content-wrapper:hover {
    .c-showdetails-overflow-button {
      visibility: visible;
    }
  }

  .c-result-box {
    text-align: center;
    width: fit-content;
    padding-left: 5px;
    padding-right: 5px;
    min-width: 120px;
    height: 60px;
    box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108);
  }

  .c-result-box-value {
    font-size: 18px;
    padding-top: 8px;
    color: ${(props) => props.theme.themePrimary};
  }

  .c-result-box-text {
    padding-top: 2px;
  }
`;

export default DynamicSurveyResultFieldStyled;
