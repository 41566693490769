import { CombinedPicker, Label } from 'components';
import useTasksFilterView from 'hooks/useTasksFilterView';
import { useTranslation } from 'react-i18next';
import { ITeamProps } from 'types';
import { onCombinedPickerSearch } from 'utils/helpers';
import { Checkbox, Dropdown } from '@fluentui/react';

interface IView {
  id: string;
  name: string;
}

export default function MsTeamsTasksConfig({ config, setConfig }) {
  const { t } = useTranslation();

  const { views } = useTasksFilterView({ teamId: config.selectedTeam?.id });

  function handleCombinedPickerSearch(filterText) {
    return onCombinedPickerSearch({ filterText, searchTeams: true }).then((searchResult) => {
      return searchResult.teams.map((persona) => ({ ...persona }));
    });
  }

  function renderViewsDropdown() {
    if (!views || !(views as IView[]).length || !config.selectedTeam?.id) {
      return null;
    }

    return (
      <Dropdown
        options={(views as IView[]).map((view) => ({ key: view.id, text: view.name })) || []}
        styles={{ root: { maxWidth: 500, marginLeft: 28 } }}
        disabled={!config.showTasks}
        defaultSelectedKey={config.selectedView}
        label={t('msTeams.config.tasksConfig.viewsSelectionLabel')}
        placeholder={t('msTeams.config.tasksConfig.viewsSelectionPlaceholder')}
        onChange={(_, option) => {
          setConfig((prevState) => ({ ...prevState, selectedView: option.key as string }));
        }}
      />
    );
  }

  return (
    <>
      <Checkbox
        // @ts-expect-error: text prop allows JSX
        label={
          <div>
            <span style={{ fontWeight: 600 }}>{t('msTeams.config.checkBox.label.tasks')}</span>
            <div style={{ maxWidth: 500 }}>{t('msTeams.config.checkBox.subtext.tasks')}</div>
          </div>
        }
        disabled={config.selectedProcess || config.selectedProject}
        checked={config.showTasks}
        onChange={(_, checked) =>
          setConfig((prevState) => ({
            ...prevState,
            showTasks: checked,
            selectedView: checked ? prevState.selectedView : null,
            selectedTeam: checked ? prevState.selectedTeam : null
          }))
        }
      />
      <Label
        styles={{ container: { marginTop: 5, marginLeft: 23, maxWidth: 505 } }}
        label={t('msTeams.config.teamPicker.label')}
        description={t('msTeams.config.teamPicker.description')}
      />
      <CombinedPicker
        key={config.selectedTeam?.id}
        itemLimit={1}
        placeholder={t('msTeams.config.teamPicker.placeholder')}
        onSearch={handleCombinedPickerSearch}
        disabled={!config.showTasks}
        onChange={(teams) => {
          if (teams.length > 0) {
            setConfig((prevState) => ({
              ...prevState,
              showTasks: true,
              showProcesses: false,
              selectedProcess: null,
              showProjects: false,
              showTagList: false,
              selectedProject: null,
              selectedTeam: {
                id: teams[0].id as ITeamProps['id'],
                name: teams[0].title as ITeamProps['title']
              },
              selectedView: null
            }));
          } else {
            setConfig((prevState) => ({ ...prevState, selectedTeam: null }));
          }
        }}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onEmptyInputFocus={() => {}}
        defaultValue={
          config.selectedTeam
            ? [
                {
                  id: config.selectedTeam.id,
                  text: config.selectedTeam.name
                }
              ]
            : undefined
        }
        styles={{ root: { maxWidth: 500, marginLeft: 28, height: 35 } }}
      />

      {renderViewsDropdown()}
    </>
  );
}
