import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Callout, DirectionalHint, Icon, List } from '@fluentui/react';

const CmdMenuCalloutStyled = styled(Callout)`
  z-index: 10;
  disabled: ${(props) => props.disabled};
`;

const CmdBarMenuStyled = styled.div`
  position: relative;
  overflow: 'hidden';
  height: auto;
  height: auto;
`;

const CmdBarMenuItem = styled.div`
  display: flex;
  cursor: ${(props) => (props.$disabled ? 'default' : 'pointer')};
  padding: 8px;
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => (props.$disabled ? '#828485' : '#323130')};

  &:hover {
    background-color: ${(props) => (props.$disabled ? null : '#f3f2f1')};
  }

  .menu-item-icon {
    margin-right: 5px;
    font-size: 17px;
  }
`;
function CmdBarOverflowMenu({ isCalloutVisible, items, onDismiss, disabled }) {
  return (
    <CmdMenuCalloutStyled
      gapSpace={0}
      doNotLayer
      target=".cmdMoreMenu"
      onDismiss={onDismiss}
      hidden={!isCalloutVisible}
      directionalHint={DirectionalHint.bottomLeftEdge}
      isBeakVisible={false}
      preventDismissOnScroll="true"
      disabled={disabled}
    >
      <CmdBarMenuStyled>
        <List
          items={items}
          onRenderCell={(item) => {
            return (
              <CmdBarMenuItem
                className="callout-menu-item"
                role="menuitem"
                onClick={disabled ? null : item.onClick}
                onKeyDown={item.onClick}
                tabIndex={item.index}
                key={item.key}
                $disabled={item.disabled}
              >
                <Icon className="menu-item-icon" iconName={item.iconProps.iconName} />
                <div className="callout-menu-text">{item.text}</div>
              </CmdBarMenuItem>
            );
          }}
        />
      </CmdBarMenuStyled>
    </CmdMenuCalloutStyled>
  );
}

CmdBarOverflowMenu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  isCalloutVisible: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

CmdBarOverflowMenu.defaultProps = {
  disabled: false
};

export default CmdBarOverflowMenu;
