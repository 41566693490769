import PropTypes from 'prop-types';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { MessageBar, MessageBarButton, MessageBarType } from '@fluentui/react';
import { ai } from './AppInsights';

export function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <MessageBar
      messageBarType={MessageBarType.error}
      dismissButtonAriaLabel="Close"
      overflowButtonAriaLabel="See more"
      onDismiss={() => {}}
      actions={
        <div>
          <MessageBarButton onClick={resetErrorBoundary}>try again</MessageBarButton>
        </div>
      }
      styles={{ root: { maxWidth: '500px' } }}
    >
      <div style={{ fontSize: 17 }}>Something went wrong:</div>
      <div>{error.message}</div>
      {ai ? (
        <div style={{ fontSize: 'smaller', opacity: 0.7 }}>
          Trace-ID: {ai.context.telemetryTrace.traceID}
        </div>
      ) : null}
    </MessageBar>
  );
}

export function ErrorBoundary({ children, fallbackComponent }) {
  if (import.meta.env.DEV) {
    window.onerror = (event, source, lineno, colno, err) => {
      // must be within function call because that's when the element is defined for sure.
      const ErrorOverlay = customElements.get('vite-error-overlay');
      // don't open outside vite environment
      if (!ErrorOverlay) {
        return;
      }
      const overlay = new ErrorOverlay(err);
      document.body.appendChild(overlay);
    };
  }

  return (
    <ReactErrorBoundary
      onReset={() => window.location.reload()}
      FallbackComponent={fallbackComponent || ErrorFallback}
    >
      {children}
    </ReactErrorBoundary>
  );
}

ErrorFallback.propTypes = {
  resetErrorBoundary: PropTypes.func.isRequired,
  error: PropTypes.any
};

ErrorBoundary.propTypes = {
  children: PropTypes.any,
  fallbackComponent: PropTypes.func
};
