import Label from 'components/inputs/Label';
import Dialog from 'components/surfaces/Dialog';
import FileManagement, { IFileManagement } from 'components/surfaces/FileManagement';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IBlobFile, IFileProps } from 'types';
import {
  FontIcon,
  IButtonStyles,
  IContextualMenuProps,
  IImageProps,
  ILabelProps,
  ITextField,
  IconButton,
  Stack,
  TextField
} from '@fluentui/react';
import { MultiMediaFieldStyled } from './MultiMediaField.styles';
import MultiMediaImage from './MultiMediaImage';
import MultiMediaModal from './MultiMediaModal';
import MultiMediaVideo from './MultiMediaVideo';

export interface MultiMediaFieldStyles {
  actionButtonStyles?: IButtonStyles;
  imagePreviewStyles?: IImageProps['styles'];
  labelStyles?: ILabelProps['styles'];
}

export interface IMultiMediaFieldProps {
  id?: string;
  defaultValue?: IFileProps[];
  description?: string;
  disabled?: boolean;
  getFileContents: ({ id }: { id: string }) => Promise<Response>;
  getStreamUrl: ({ fileId, tenantId }: { fileId: string; tenantId: string }) => Promise<string>;
  label?: string;
  labelIconName?: string;
  onChange?: (files: IFileProps[] | null) => void;
  required?: boolean;
  tenantId: string;
}

export interface IErrorDialogProps {
  isHidden: boolean;
  errorType: number | null;
  file: File | null;
}

function MultiMediaField({
  id,
  defaultValue,
  description,
  disabled = false,
  getFileContents,
  getStreamUrl,
  label,
  labelIconName,
  onChange,
  required = false,
  tenantId
}: IMultiMediaFieldProps): JSX.Element {
  const { t } = useTranslation();

  const fileManagementRef = useRef<IFileManagement>(null);
  const textFieldRef = useRef<ITextField>(null);

  const [currentMediaValues, setCurrentMediaValues] = useState<IFileProps[]>(defaultValue || []);
  const [modalProps, setModalProps] = useState<{ isOpen: boolean; index: number }>({
    isOpen: false,
    index: 0
  });

  const [isLinkDialogHidden, setIsLinkDialogHidden] = useState(true);
  const [errorDialogProps, setErrorDialogProps] = useState<IErrorDialogProps>({
    isHidden: true,
    errorType: null,
    file: null
  });

  const isImage = useCallback((text?: string) => {
    if (!text) return false;

    return /.png|.jpg|.jpeg|.gif|.tiff|.tif/i.test(text);
  }, []);

  const isVideo = useCallback((text?: string) => {
    if (!text) return false;

    return /.mp4|.ogg|.ogv|.webm|.MOV|.WMV|.AVI/i.test(text);
  }, []);

  function openFileChooser() {
    if (fileManagementRef.current?.openFileChooser && currentMediaValues.length < 12) {
      fileManagementRef.current.openFileChooser();
    }
  }

  function onFileSelection(files: IBlobFile[]) {
    const mediaValues: IFileProps[] = [...currentMediaValues, ...files.map(convertMediaValue)];

    // state
    setCurrentMediaValues(mediaValues);

    // onChange
    if (onChange) onChange(mediaValues);
  }

  function getDownloadUrl(mediaValue: IFileProps) {
    if (mediaValue.blobFile) {
      return window.URL.createObjectURL(mediaValue.blobFile);
    }

    return mediaValue.downloadUrl;
  }

  function downloadDocument(itemIndex: number) {
    const mediaValue = currentMediaValues[itemIndex];

    if (mediaValue.blobFile) {
      // for files that have just been selected/added
      const url = getDownloadUrl(mediaValue);
      if (url) {
        const element = document.createElement('a');

        element.setAttribute('href', url);
        element.setAttribute('download', mediaValue.blobFile.name);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
      }
    } else if (mediaValue.url && mediaValue.url !== 'https://tempuri.org') {
      // default file need to be fetched first

      const downloadLink = document.createElement('a');

      const fileIdIndex = mediaValue.url.indexOf('id=') + 3;
      const id = mediaValue.url?.substr(fileIdIndex);

      getFileContents({ id })
        .then((response) => response.blob())
        .then((blob) => URL.createObjectURL(blob))
        .then((blobUrl) => {
          downloadLink.href = blobUrl;
          downloadLink.download = mediaValue.text || '';

          document.body.appendChild(downloadLink);
          downloadLink.click();

          URL.revokeObjectURL(blobUrl);
        });
    }
  }

  function renderImagePreview(mediaValue: IFileProps): JSX.Element {
    return (
      <MultiMediaImage
        width="132px"
        height="132px"
        mediaValue={mediaValue}
        getFileContents={getFileContents}
      />
    );
  }

  function getStreamLinkDialogContent() {
    return (
      <TextField
        componentRef={textFieldRef}
        label="Video URL"
        placeholder="https://web.microsoftstream.com/video/{id}"
      />
    );
  }

  function convertMediaValue(mediaValue: IBlobFile): IFileProps {
    return {
      blobFile: mediaValue,
      url: 'https://tempuri.org',
      text: mediaValue?.name
    };
  }

  function onConfirmStreamUrl() {
    setIsLinkDialogHidden(true);

    let url = textFieldRef.current?.value;

    // check if it is a stream link
    if (url && url.includes('https://web.microsoftstream.com/')) {
      if (!url.includes('/embed/')) {
        url = `${url.slice(0, 32)}embed/${url.slice(32)}`;
      }

      const newMediaValues = [...currentMediaValues, { url }];
      setCurrentMediaValues(newMediaValues);

      if (onChange) {
        onChange(newMediaValues);
      }
    }
  }

  function onDeleteMediaValue(index: number) {
    setCurrentMediaValues((prevState) => {
      // clone prevState
      const prevStateClone = [...prevState];
      // remove
      prevStateClone.splice(index, 1);

      if (onChange) {
        onChange(prevStateClone);
      }

      // set new suggestions array
      return prevStateClone;
    });
  }

  function getOverflowIconButtonMenuItems(itemIndex: number): IContextualMenuProps['items'] {
    const menuItems: IContextualMenuProps['items'] = [];

    const mediaValue = currentMediaValues[itemIndex];

    if (!mediaValue.url?.includes('microsoftstream.com')) {
      menuItems.push({
        key: 'download',
        text: t('documentField.button.menu.download'),
        onClick: () => downloadDocument(itemIndex),
        iconProps: { iconName: 'Download' }
      });
    }

    // {
    //   key: 'replace',
    //   text: t('documentField.button.menu.replace'),
    //   onClick: openFileChooser,
    //   iconProps: { iconName: 'Upload' }
    // }

    if (!disabled) {
      menuItems.push({
        key: 'delete',
        text: t('documentField.button.menu.delete'),
        onClick: () => onDeleteMediaValue(itemIndex),
        iconProps: { iconName: 'Delete' }
      });
    }

    return menuItems;
  }

  function getPreviewDivOverlay(children: JSX.Element, index: number, key: string): JSX.Element {
    return (
      <div
        key={key}
        aria-hidden="true"
        className="c-preview-overlay_wrapper"
        onClick={() => {
          setModalProps({ isOpen: true, index });
        }}
        style={{
          width: 132,
          height: 132,
          position: 'relative',
          border: '1px solid #a19f9d',
          borderRadius: '3px',
          overflow: 'hidden'
        }}
      >
        <div className="c-preview-overlay" />
        {isVideo(currentMediaValues[index].text) && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <FontIcon aria-label="Play" iconName="Play" className="c-preview-icon" />
          </div>
        )}
        <IconButton
          styles={{
            rootHovered: { backgroundColor: '#E1E1E1' },
            root: {
              height: '25px',
              backgroundColor: '#e1e1e1bf'
            },
            menuIcon: {
              fontSize: '15px',
              fontWeight: '600'
            }
          }}
          className="c-preview-overlay_overflow-button"
          menuIconProps={{ iconName: 'More' }}
          menuProps={{ items: getOverflowIconButtonMenuItems(index) }}
        />
        {children}
      </div>
    );
  }

  function renderPreview(mediaValue: IFileProps, index: number) {
    if (mediaValue.url && mediaValue.url.includes('microsoftstream.com')) {
      return getPreviewDivOverlay(
        <iframe
          title="microsoftstream"
          width="132"
          style={{ border: 'none' }}
          height="132"
          src={`${mediaValue.url}?autoplay=false&showinfo=false`}
          allowFullScreen
        />,
        index,
        mediaValue.text || ''
      );
    }

    if (mediaValue.blobFile?.type?.includes('image') || isImage(mediaValue.text)) {
      return getPreviewDivOverlay(renderImagePreview(mediaValue), index, mediaValue.text || '');
    }

    if (mediaValue.blobFile?.type?.includes('video') || isVideo(mediaValue.text)) {
      return getPreviewDivOverlay(
        <MultiMediaVideo
          getStreamUrl={getStreamUrl}
          mediaValue={mediaValue}
          tenantId={tenantId}
          width={132}
          height={132}
        />,
        index,
        mediaValue.text || ''
      );
    }

    return null;
  }

  function onModalDismiss() {
    setModalProps({
      isOpen: false,
      index: 0
    });
  }

  function renderAddMediaIconButton() {
    if (disabled) {
      return null;
    }

    return (
      <IconButton
        className="c-add-media-button"
        menuIconProps={{ iconName: 'Add' }}
        menuProps={{
          items: [
            {
              key: 'file',
              text: t('multiMediaField.uploadFile'),
              onClick: openFileChooser,
              iconProps: { iconName: 'MediaAdd' }
            },
            {
              key: 'link',
              text: 'MS-Stream Link',
              onClick: () => setIsLinkDialogHidden(false),
              iconProps: { iconName: 'StreamLogo' }
            }
          ],
          coverTarget: true
        }}
        styles={{
          menuIcon: { fontSize: '40px' },
          root: {
            margin: 4,
            minWidth: 132,
            height: 132,
            border: '1px solid #a19f9d',
            borderRadius: 3
          }
        }}
        onClick={openFileChooser}
      />
    );
  }

  function renderEmptyDisabledPlaceholder(): JSX.Element | null {
    if (disabled && !currentMediaValues.length) {
      return (
        <TextField
          disabled
          placeholder={t('multiMediaField.emptyValue')}
          styles={{
            root: {
              width: '100%',
              margin: '4px'
            },
            wrapper: {
              border: '1px solid #a19f9d',
              borderRadius: '3px'
            }
          }}
        />
      );
    }

    return null;
  }

  function onFileSelectionError(error: { code: number; message: string }, file: File) {
    if (errorDialogProps.isHidden && error.code === 2) {
      setErrorDialogProps({ isHidden: false, errorType: error.code, file });
    }
  }

  function getErrorDialogContent() {
    if (errorDialogProps.file) {
      return (
        <div style={{ marginTop: 20 }}>
          {t('multiMediaField.errorDialog.contentMessage', {
            fileName: errorDialogProps.file.name
          })}
        </div>
      );
    }

    return null;
  }

  function onErrorDialogDismiss() {
    setErrorDialogProps({ isHidden: true, errorType: null, file: null });
  }

  const maxFiles = 12 - currentMediaValues.length;

  return (
    <MultiMediaFieldStyled id={id}>
      <FileManagement
        ref={fileManagementRef}
        clickable={false}
        maxFiles={maxFiles}
        onError={onFileSelectionError}
        maxFileSize={52428800}
        accepts={['image/*', 'video/WebM', 'video/ogg', 'video/mp4', '.mov']}
        multiple
        onChange={onFileSelection}
      >
        <Label
          label={label}
          required={required}
          iconName={labelIconName}
          description={description}
        />
        <Stack wrap tokens={{ childrenGap: 8 }} horizontal>
          {currentMediaValues.map(renderPreview)}
          {renderEmptyDisabledPlaceholder()}
          {renderAddMediaIconButton()}
        </Stack>
      </FileManagement>
      {modalProps.isOpen && (
        <MultiMediaModal
          tenantId={tenantId}
          defaultMediaValueIndex={modalProps.index}
          mediaValues={currentMediaValues}
          getStreamUrl={getStreamUrl}
          onDismiss={onModalDismiss}
          getFileContents={getFileContents}
        />
      )}
      <Dialog
        title={t('multiMediaField.dialog.header')}
        hidden={isLinkDialogHidden}
        defaultButtonProps={{
          text: t('multiMediaField.dialog.buttonLabel.cancel'),
          onClick: () => setIsLinkDialogHidden(true)
        }}
        primaryButtonProps={{
          text: t('multiMediaField.dialog.buttonLabel.confirm'),
          onClick: onConfirmStreamUrl
        }}
        content={getStreamLinkDialogContent()}
      />
      <Dialog
        title={t('multiMediaField.errorDialog.header')}
        hidden={errorDialogProps.isHidden}
        defaultButtonProps={{
          text: t('multiMediaField.errorDialog.buttonLabel.cancel'),
          onClick: onErrorDialogDismiss
        }}
        content={getErrorDialogContent()}
      />
    </MultiMediaFieldStyled>
  );
}

export default MultiMediaField;
