import BooleanField from 'components/inputs/BooleanField';
import { IFieldLinkProps, RouteFieldVisibility } from 'types';
import { getFluentIconNameByFieldType } from 'utils/helpers';

export interface IDynamicBooleanFieldProps {
  disabled?: boolean;
  fieldLink: IFieldLinkProps;
  onChange: (value?: boolean | null) => void;
}

function DynamicBooleanField({ fieldLink, disabled, onChange }: IDynamicBooleanFieldProps) {
  if (!fieldLink.field) return null;

  const { field, visibility } = fieldLink;
  const { name, description, descriptionPlacement, id, confirmationFormat, textOk, textNOk } =
    field;
  const defaultValue = fieldLink.value as boolean | undefined;

  const displayDescriptionTooltip = descriptionPlacement === 1;

  const readOnly = visibility === RouteFieldVisibility.ReadOnly;
  const required = visibility === RouteFieldVisibility.Required;

  return (
    <BooleanField
      id={id}
      defaultValue={defaultValue}
      onChange={onChange}
      labelIconName={getFluentIconNameByFieldType(fieldLink.field)}
      confirmationFormat={confirmationFormat}
      label={name}
      textOk={textOk}
      textNOk={textNOk}
      required={required}
      description={displayDescriptionTooltip ? description : undefined}
      disabled={disabled || readOnly}
    />
  );
}

export default DynamicBooleanField;
