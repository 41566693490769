import moment from 'moment/moment';

export enum DateHeader {
  Today = 'today',
  Yesterday = 'yesterday',
  ThisWeek = 'thisWeek',
  LastWeek = 'lastWeek',
  ThisMonth = 'thisMonth',
  LastMonth = 'lastMonth',
  ThisYear = 'thisYear',
  LastYear = 'lastYear',
  Older = 'older'
}

interface IDateHeader {
  label: DateHeader;
  earliestDate: Date;
}

export function getDateHeaders(): IDateHeader[] {
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

  const headers: IDateHeader[] = [{ label: DateHeader.Today, earliestDate: today }];

  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  headers.push({ label: DateHeader.Yesterday, earliestDate: yesterday });

  const beginningOfWeek = moment(today).weekday(0).toDate();
  if (beginningOfWeek < yesterday) {
    headers.push({ label: DateHeader.ThisWeek, earliestDate: beginningOfWeek });
  }

  const beginningOfLastWeek = moment(today).weekday(-7).toDate();
  headers.push({ label: DateHeader.LastWeek, earliestDate: beginningOfLastWeek });

  const beginningOfThisMonth = new Date(today);
  beginningOfThisMonth.setDate(1);
  if (beginningOfThisMonth < beginningOfLastWeek) {
    headers.push({ label: DateHeader.ThisMonth, earliestDate: beginningOfThisMonth });
  }

  const beginningOfLastMonth = new Date(beginningOfThisMonth);
  beginningOfLastMonth.setMonth(beginningOfThisMonth.getMonth() - 1);
  headers.push({ label: DateHeader.LastMonth, earliestDate: beginningOfLastMonth });

  const beginningOfThisYear = new Date(today);
  beginningOfThisYear.setMonth(0);
  beginningOfThisYear.setDate(1);
  if (beginningOfThisYear < beginningOfLastMonth) {
    headers.push({ label: DateHeader.ThisYear, earliestDate: beginningOfThisYear });
  }

  const beginningOfLastYear = new Date(beginningOfThisYear);
  beginningOfLastYear.setFullYear(today.getFullYear() - 1);
  headers.push({ label: DateHeader.LastYear, earliestDate: beginningOfLastYear });

  headers.push({ label: DateHeader.Older, earliestDate: new Date(0) });

  return headers;
}
