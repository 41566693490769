import { useId } from '@fluentui/react-hooks';

/** Modification of the fluent-ui Frigid icon */
export default function TemperatureIcon({ temperature }: { temperature: number }) {
  const clipPathId = useId();

  return (
    <svg viewBox="0 0 2048 2048">
      <clipPath id={clipPathId}>
        <rect x="960" y="257" width="128" height="1343" rx="64" fill="white" />
      </clipPath>
      <g
        fill="none"
        fillRule="nonzero"
        transform="translate(1024 1024) scale(0.875) translate(-1024 -1024)"
      >
        <path
          d="M1344 1319c38 42 67.333 89 88 141s31.333 106.333 32 163c0 60-11.667 115.667-35 167s-55.333 96.333-96 135-87.667 68.667-141 90-109.333 32.333-168 33c-58.667 0-114.667-11-168-33s-100-52-140-90-72-82.667-96-134-36-107.333-36-168c0-56.667 10.333-111 31-163s50.333-99 89-141V320c0-44 8.333-85.333 25-124s39.333-72.333 68-101 62.667-51.667 102-69 81-26 125-26 85.333 8.333 124 25 72.333 39.667 101 69 51.667 63.333 69 102 26 80 26 124v999Zm-320 601c41.333 0 80.667-7.667 118-23s70.667-36.333 100-63 52-58.333 68-95 24.667-76 26-118c0-50-11-95.667-33-137s-51-79-87-113V320c0-26.667-5-51.667-15-75s-23.667-43.667-41-61-37.667-31-61-41-48.333-15-75-15-51.667 5-75 15-43.667 23.667-61 41-31 37.667-41 61-15 48.333-15 75v1051c-36 33.333-65 70.667-87 112s-33 87.333-33 138c0 42 8.333 81.333 25 118s39.667 68.333 69 95 62.333 47.667 99 63c36.667 15.333 76.333 23 119 23v64-64Z"
          fill="currentColor"
        />
        <circle fill="currentColor" cx="1024" cy="1600" r="192" />
        <path
          stroke="currentColor"
          clipPath={`url(#${clipPathId})`}
          strokeWidth="128"
          d={`M1024 1423v${temperature * (257 - 1423)}`}
        />
      </g>
    </svg>
  );
}
