export default function PropertyTypes(t) {
  const propertyTypeOptions = [
    {
      key: 'string',
      text: t('propertyTypes.option.string')
    },
    {
      key: 'number',
      text: t('propertyTypes.option.number')
    },
    {
      key: 'integer',
      text: t('propertyTypes.option.integer')
    },
    {
      key: 'object',
      text: t('propertyTypes.option.object')
    },
    {
      key: 'array',
      text: t('propertyTypes.option.array')
    },
    {
      key: 'sp-multi',
      text: 'SP-Multiselect'
    }
  ];

  return propertyTypeOptions;
}
