import { AppContext } from 'features/App';
import { ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CompoundButton, MessageBar, MessageBarType, Stack, Toggle } from '@fluentui/react';
import { SettingsContainer, SettingsTextField } from '../../../../components';
import { ShowError } from '../../../../components/ShowError';
import { IFESettingsItem } from '../../../../hooks/api2/types/administration';
import { fetchVoid } from '../../../../services/api2';

export default function DWHSettings({
  settings,
  originalSettings,
  onSettingsChange
}: {
  settings: IFESettingsItem;
  originalSettings: IFESettingsItem;
  onSettingsChange: (changes: Partial<IFESettingsItem>) => void;
}) {
  const { t } = useTranslation();

  const [messageBar, setMessageBar] = useState<null | {
    type: MessageBarType;
    contents: ReactNode;
  }>(null);

  const { globalAppState } = useContext(AppContext);
  const { currentUser } = globalAppState;

  const isEvocomUser = currentUser?.login?.endsWith('@evocom.de');

  const isDwhExportDisabled =
    !!originalSettings.dwhConnectionError ||
    !(originalSettings.dwhDbName && originalSettings.dwhSqlServer) ||
    originalSettings.dwhDbName !== settings.dwhDbName ||
    originalSettings.dwhSqlServer !== settings.dwhSqlServer;

  const displayDwhHistoryToggleButton = useMemo(
    () => isEvocomUser || window.location.hostname.match(/\bconstantia\b/),
    [isEvocomUser]
  );

  useEffect(() => {
    setMessageBar((messageBar) => {
      if (originalSettings.dwhConnectionError) {
        return {
          type: MessageBarType.error,
          contents: originalSettings.dwhConnectionError
        };
      }

      if (messageBar?.type === MessageBarType.error) {
        // clear any errors
        return null;
      }

      return messageBar;
    });
  }, [originalSettings]);

  const [isExporting, setIsExporting] = useState(false);

  function onInitialExport() {
    setIsExporting(true);

    fetchVoid({ url: 'Admin/DwhExport', method: 'POST' })
      .then(() => {
        setMessageBar({
          type: MessageBarType.success,
          contents: t('settingsAdministration.form.messageBar.initializeExport.success')
        });
      })
      .catch((error) => {
        setMessageBar({
          type: MessageBarType.error,
          contents: (
            <>
              {t('settingsAdministration.form.messageBar.initializeExport.error')}
              <ShowError error={error} />
            </>
          )
        });
      })
      .finally(() => {
        setIsExporting(false);
      });
  }

  function closeMessageBar() {
    setMessageBar(null);
  }

  function renderMessageBar() {
    if (!messageBar) return null;

    return (
      <div style={{ marginTop: 15 }}>
        <MessageBar
          messageBarType={messageBar.type}
          dismissButtonAriaLabel="Close"
          styles={{ root: { borderRadius: 4 } }}
          onDismiss={closeMessageBar}
        >
          {messageBar.contents}
        </MessageBar>
      </div>
    );
  }

  return (
    <SettingsContainer
      defaultExpanded
      label={t('settingsAdministration.form.label.dwhExport')}
      iconName="Database"
      description={t('settingsAdministration.form.summary.dwhExport')}
    >
      <div style={{ fontSize: 'smaller', margin: '1em 0' }}>
        {t('settingsAdministration.form.description.dwhExport')}
      </div>
      <Stack tokens={{ childrenGap: 8 }}>
        {renderMessageBar()}
        <SettingsTextField
          id="settings-dwhSqlServer"
          label={t('settingsAdministration.form.label.dwhSqlServer')}
          onChange={(value) => onSettingsChange({ dwhSqlServer: value })}
          value={settings.dwhSqlServer}
        />
        <SettingsTextField
          label={t('settingsAdministration.form.label.dwhDbName')}
          onChange={(value) => onSettingsChange({ dwhDbName: value })}
          value={settings.dwhDbName}
        />
        {displayDwhHistoryToggleButton && (
          <>
            <Toggle
              onChange={(_, checked) => onSettingsChange({ dwhHistory: checked })}
              checked={settings.dwhHistory}
              label={t('settingsAdministration.form.label.dwhHistory')}
              onText={t('settingsAdministration.form.label.dwhHistoryOn')}
              offText={t('settingsAdministration.form.label.dwhHistoryOff')}
              styles={{
                root: { marginTop: 20 },
                text: { fontSize: 13 },
                pill: { flexShrink: '0' }
              }}
            />
            <div style={{ fontSize: 'smaller' }}>
              {t('settingsAdministration.form.label.dwhHistoryDescription')}
            </div>
          </>
        )}

        <CompoundButton
          onClick={onInitialExport}
          primary
          iconProps={{
            iconName: 'DatabaseSync',
            styles: {
              root: { fontSize: 18, marginTop: 'auto', marginBottom: 'auto', marginRight: 15 }
            }
          }}
          disabled={isDwhExportDisabled || isExporting}
          title={t('settingsAdministration.form.button.initializeExport.title')}
          secondaryText={t('settingsAdministration.form.button.initializeExport.info')}
          text={t('settingsAdministration.form.button.initializeExport.text')}
          styles={{ root: { marginTop: '16px !important', borderRadius: 4 } }}
        />
      </Stack>
    </SettingsContainer>
  );
}
