import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { isEpUserValue } from 'utils/helpers';
import { Dropdown, IconButton } from '@fluentui/react';
import ClauseCompareField from '../../../ProcessDesignerOld/components/StepPanel/ClauseCompareField';
import FieldPicker from '../FieldPicker';

const ConditionsStyled = styled.div`
  padding: 4px 0px 4px 0;
  display: flex;
  justify-content: space-between;

  .visible {
    visibility: visible;
  }

  .hidden {
    visibility: hidden;
  }

  .logic-operator {
    margin-right: 5px;
    min-width: 75px;
    max-width: 75px;
    position: relative;
  }

  .logic-operator-label {
    margin-right: 5px;
    position: absolute;
    top: -25px;
  }

  .condition-field {
    margin-right: 5px;
    width: 200px;
  }

  .condition-comparison {
    margin-right: 5px;
    min-width: 160px;
    max-width: 160px;
  }

  .condition-compare-field-value {
    margin-right: 5px;
    width: 200px;
  }

  .delete-icon-button {
    right: 4px;
  }

  .delete-icon-button-first-row {
    right: 4px;
    top: 28px;
  }

  .add-icon {
    font-size: 16px;
  }

  .add-icon-button {
    right: 4px;
  }

  .add-icon-button-first-row {
    right: 4px;
    top: 28px;
  }
`;

function RuleCondition({
  condition,
  conditionIndex,
  groupFields,
  onUpdateCondition,
  onUpdateLogicOperator,
  onAddNewCondition,
  onDeleteCondition,
  disableDelete
}) {
  const { t } = useTranslation();

  const [field, setField] = useState(null);

  useEffect(() => {
    if (condition && groupFields?.length) {
      setField(groupFields.find((field) => field.id === condition?.fieldId));
    }
  }, [condition, groupFields]);

  const fieldType = field && field.fieldType ? field.fieldType : null;

  function onSetConditionValue(fieldName, fieldValue) {
    const updatedCondition = { ...condition, [fieldName]: fieldValue };

    if (fieldName === 'fieldId' && fieldValue !== condition.fieldId) {
      // reset compare values
      updatedCondition.compareValue = null;
      updatedCondition.compareValueUser = null;
      updatedCondition.compareValueUserId = null;
    }

    onUpdateCondition(updatedCondition, conditionIndex);
  }

  function onComparisonOperatorChange(value) {
    onSetConditionValue('condition', value.key);
  }

  function onCompareValueChange(value) {
    let compareValueType = 'compareValue';

    if (fieldType === 7) {
      compareValueType = isEpUserValue(value) ? 'compareValueUser' : 'compareValueTeam';
    }

    let updatedCondition = { ...condition, [compareValueType]: value };

    if (value?.compareValueId && value?.compareValue) {
      updatedCondition = {
        ...condition,
        [compareValueType]: value.compareValue,
        compareValueId: value.compareValueId
      };
    }

    onUpdateCondition(updatedCondition, conditionIndex);
  }

  function onFieldChange(value) {
    onSetConditionValue('fieldId', value);
  }

  function getComparisonOperatorDropDownList() {
    const operators = [
      { key: 1, text: t('formbuilder.clause.operators.equal') },
      { key: 2, text: t('formbuilder.clause.operators.notEqual') },
      {
        key: 3,
        text: t('formbuilder.clause.operators.greaterThan')
      },
      {
        key: 4,
        text: t('formbuilder.clause.operators.lessThan')
      },
      {
        key: 5,
        text: t('formbuilder.clause.operators.greaterOrEqual')
      },
      {
        key: 6,
        text: t('formbuilder.clause.operators.lessOrEqual')
      },
      {
        key: 7,
        text: t('formbuilder.clause.operators.beginsWith')
      },
      {
        key: 8,
        text: t('formbuilder.clause.operators.contains')
      },
      {
        key: 9,
        text: t('formbuilder.clause.operators.endsWith')
      },
      {
        key: 10,
        text: t('formbuilder.clause.operators.notContains')
      },
      {
        key: 11,
        text: t('formbuilder.clause.operators.empty')
      },
      {
        key: 12,
        text: t('formbuilder.clause.operators.notEmpty')
      }
    ];
    const options = [];

    if (fieldType === 1 || fieldType === 16) {
      // smalltext, scanner
      [1, 2, 7, 8, 9, 10, 11, 12].map((key) => options.push(operators[key - 1]));
    } else if (fieldType === 2) {
      // longtext
      [7, 8, 9, 10, 11, 12].map((key) => options.push(operators[key - 1]));
    } else if (fieldType === 9) {
      // hyperLink
      [1, 2, 7, 8, 9, 11, 12].map((key) => options.push(operators[key - 1]));
    } else if (fieldType === 3 || fieldType === 8) {
      // number & rating
      [1, 2, 3, 4, 5, 6, 11, 12].map((key) => options.push(operators[key - 1]));
    } else if (fieldType === 7) {
      // person
      if (
        field.personFieldFormat === 2 ||
        field.personFieldFormat === 4 ||
        field.personFieldFormat === 5
      ) {
        [8, 10, 11, 12].map((key) => options.push(operators[key - 1]));
      } else {
        [1, 2, 11, 12].map((key) => options.push(operators[key - 1]));
      }
    } else if (fieldType === 4) {
      // boolean
      if (field && field?.confirmationFormat === 2) {
        // checkbox
        [11, 12].map((key) => options.push(operators[key - 1]));
      } else {
        // 2 buttons
        [1, 2, 11, 12].map((key) => options.push(operators[key - 1]));
      }
    } else if (fieldType === 6) {
      // choice
      if (field && field.choiceFormat === 2) {
        // multi
        [8, 10, 11, 12].map((key) => options.push(operators[key - 1]));
      } else if (field && field.choiceFormat === 1) {
        // single
        [1, 2, 11, 12].map((key) => options.push(operators[key - 1]));
      }
    } else if (
      fieldType === 14 ||
      fieldType === 15 ||
      fieldType === 12 ||
      fieldType === 13 ||
      fieldType === 5 ||
      fieldType === 10 ||
      fieldType === 17
    ) {
      // location = 13, lookup = 13,  Signature = 14, Multimedia = 15, datetime = 5, document = 10, ai = 17
      [11, 12].map((key) => options.push(operators[key - 1]));
    } else if (fieldType === 11) {
      // external data
      [1, 2, 11, 12].map((key) => options.push(operators[key - 1]));
    }

    return (
      <div className="condition-comparison margin-lr">
        <Dropdown
          key={`${condition?.key}-comparison`}
          required={conditionIndex === 0}
          placeholder={t('formbuilder.clause.compare.dropdown.placeholder')}
          label={conditionIndex === 0 ? t('formbuilder.comparisonOperator.label') : null}
          defaultSelectedKey={condition?.condition ? condition.condition : null}
          onChange={(_, value) => onComparisonOperatorChange(value)}
          options={fieldType ? options : operators}
        />
      </div>
    );
  }

  function getConditionCompareFieldValue() {
    if (fieldType === 7) {
      return condition?.compareValueUser || condition?.compareValueTeam || null;
    }

    if (fieldType === 6 && condition?.compareValueId) {
      return field.choices.find((choice) => choice.id === condition.compareValueId)?.value || null;
    }

    return condition?.compareValue || null;
  }

  return (
    <ConditionsStyled>
      <div className="logic-operator">
        {conditionIndex === 1 ? (
          <div className="logic-operator-label">{t('formbuilder.clause.condition.label')}</div>
        ) : null}
        {conditionIndex > 0 ? (
          <Dropdown
            key={`${condition?.key}-condition`}
            options={[
              {
                key: 1,
                text: t('formbuilder.clause.condition.and')
              },
              {
                key: 2,
                text: t('formbuilder.clause.condition.or')
              }
            ]}
            defaultSelectedKey={condition?.linkType || 1}
            onChange={(_, value) => onUpdateLogicOperator(value.key)}
          />
        ) : null}
      </div>
      <div className="condition-field margin-lr">
        <FieldPicker
          selectedField={field}
          required
          disabled={false}
          availableFields={groupFields}
          label={conditionIndex === 0 ? t('formbuilder.clause.field.label') : null}
          disableCreate
          selectedLanguage
          onChange={(value) => {
            onFieldChange(value[0] ? value[0].id : null);
          }}
        />
      </div>
      {getComparisonOperatorDropDownList()}
      <div className="condition-compare-field-value margin-lr">
        <ClauseCompareField
          hidden={condition.condition === 11 || condition.condition === 12}
          showLabel={conditionIndex === 0}
          onChange={onCompareValueChange}
          value={getConditionCompareFieldValue()}
          field={field}
          condition={condition?.condition ? condition.condition : null}
        />
      </div>
      <IconButton
        className={conditionIndex === 0 ? 'delete-icon-button-first-row' : 'delete-icon-button'}
        iconProps={{
          iconName: 'Delete',
          className: 'delete-icon',
          style: { color: !disableDelete ? 'red' : null, fontSize: '16px' }
        }}
        onClick={() => onDeleteCondition(conditionIndex)}
        disabled={disableDelete}
      />
      <IconButton
        className={conditionIndex === 0 ? 'add-icon-button-first-row' : 'add-icon-button'}
        iconProps={{ iconName: 'Add', className: 'add-icon' }}
        onClick={() => onAddNewCondition(conditionIndex)}
      />
    </ConditionsStyled>
  );
}

RuleCondition.propTypes = {
  condition: PropTypes.object.isRequired,
  conditionIndex: PropTypes.number.isRequired,
  groupFields: PropTypes.arrayOf(PropTypes.object).isRequired,
  onUpdateCondition: PropTypes.func.isRequired,
  onUpdateLogicOperator: PropTypes.func.isRequired,
  onAddNewCondition: PropTypes.func.isRequired,
  onDeleteCondition: PropTypes.func.isRequired,
  disableDelete: PropTypes.bool
};

RuleCondition.defaultProps = {
  disableDelete: false
};

export default RuleCondition;
