import Panel from 'components/surfaces/Panel';
import { AppContext } from 'features/App';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import styled from 'styled-components';
import { checkScreenWidth } from 'utils/helpers';
import { IconButton, PanelType, getTheme } from '@fluentui/react';

const PanelStyled = styled(Panel)`
  .panel-header {
    background-color: ${(props) => props.theme.themePrimary};
    height: 45px;
    display: flex;
    justify-content: space-between;
  }
`;

const TitleStyled = styled.div`
  padding-left: 15px;
  padding-top: 8px;
  color: white;
  font-size: 19px;
  font-weight: 100;
  width: 100%;
`;

export default function NavigationPanel({ children }) {
  // global app state
  const { globalAppState, dispatch } = useContext(AppContext);
  const { isNavigationPanelHidden } = globalAppState;

  return (
    <div>
      <PanelStyled
        hasCloseButton={false}
        onRenderNavigation={() => (
          <div
            className="panel-header"
            style={{
              backgroundColor: getTheme().palette.themePrimary
            }}
          >
            <TitleStyled>{window.theme.title}</TitleStyled>
            <IconButton
              iconProps={{
                iconName: 'ChromeClose'
              }}
              styles={{
                root: {
                  color: 'white',
                  fontWeight: 100,
                  paddingTop: '12px',
                  paddingRight: '20px',
                  fontSize: '16px'
                },
                rootHovered: {
                  color: 'white',
                  backgroundColor: getTheme().palette.themePrimary
                },
                rootPressed: {
                  color: 'white',
                  backgroundColor: getTheme().palette.themePrimary
                }
              }}
              onClick={() => dispatch({ type: 'toggleNavigation' })}
            />
          </div>
        )}
        isOpen={!isNavigationPanelHidden}
        styles={{
          main: { maxWidth: checkScreenWidth(['extraSmall', 'small']) ? '100%' : '250px' }
        }}
        isLightDismiss
        type={
          checkScreenWidth(['extraSmall', 'small'])
            ? PanelType.smallFluid
            : PanelType.smallFixedNear
        }
        onRenderBody={() => children}
      />
    </div>
  );
}

NavigationPanel.propTypes = {
  children: PropTypes.node.isRequired
};
