import DocumentField from 'components/inputs/DocumentField';
import { IFieldLinkProps, IFileProps, RouteFieldVisibility } from 'types';
import { getFluentIconNameByFieldType } from 'utils/helpers';

export interface IDynamicDocumentFieldProps {
  disabled?: boolean;
  fieldLink: IFieldLinkProps;
  getFileContents: ({ id }: { id: string }) => Promise<Response>;
  onOpenWopi?: (fileId: string) => void;
  onChange: (value?: IFileProps | null) => void;
}

function DynamicDocumentField({
  fieldLink,
  disabled,
  onChange,
  getFileContents,
  onOpenWopi
}: IDynamicDocumentFieldProps) {
  if (!fieldLink.field) return null;

  const { field, visibility } = fieldLink;
  const { name, description, descriptionPlacement, id } = field;
  let defaultValue = fieldLink.value as IFileProps | undefined;

  const displayDescriptionTooltip = descriptionPlacement === 1;

  const readOnly = visibility === RouteFieldVisibility.ReadOnly;
  const required = visibility === RouteFieldVisibility.Required;

  const fieldLinkValue = fieldLink.value as IFileProps;

  if (fieldLinkValue?.url && fieldLinkValue?.url !== 'https://tempuri.org') {
    // if default value comes from BE the file does not exists
    const fileIdIndex = fieldLinkValue.url.indexOf('id=') + 3;
    const id = fieldLinkValue.url.substr(fileIdIndex);

    defaultValue = { ...fieldLinkValue, id };
  } else if (fieldLinkValue?.blobFile) {
    // default value comes from state
    defaultValue = { ...fieldLinkValue };
  }

  return (
    <DocumentField
      id={id}
      defaultValue={defaultValue}
      description={displayDescriptionTooltip ? description : undefined}
      disabled={disabled || readOnly}
      displayImagePreview
      getFileContents={getFileContents}
      label={name}
      onOpenWopi={onOpenWopi}
      labelIconName={getFluentIconNameByFieldType(fieldLink.field)}
      onChange={(file) => {
        if (file) {
          onChange({ ...file, url: 'https://tempuri.org', text: file.blobFile?.name });
        } else {
          onChange(null);
        }
      }}
      required={required}
    />
  );
}

export default DynamicDocumentField;
