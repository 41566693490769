import moment from 'moment';
import { IFieldSurveyResultValueProps } from 'types';
import { Persona, PersonaSize, Text } from '@fluentui/react';

interface ISurveyResultPersonaProps {
  fieldResultProps: IFieldSurveyResultValueProps;
  onRenderContent: (fieldResultProps: IFieldSurveyResultValueProps) => string | JSX.Element | null;
  renderBottomBorder: boolean;
}

function SurveyResultDefaultValueWrapper({
  onRenderContent,
  fieldResultProps,
  renderBottomBorder = false
}: ISurveyResultPersonaProps): JSX.Element | null {
  const { person, rolename } = fieldResultProps;
  function renderPersonaCoin() {
    return (
      <div style={{ marginRight: 10 }}>
        <Persona
          size={PersonaSize.size32}
          hidePersonaDetails
          imageUrl={person.pictureUrl}
          text={person.name || ''}
        />
      </div>
    );
  }

  /**
   * Get format string. if the comment was written in current year, the year does not have to be displayed
   */
  function getDateFormat(): string {
    let dateFormatToDisplay = 'DD.MM.YYYY HH:mm';

    const currentYear = new Date().getFullYear();
    const commentCreationYear = new Date('01/01/2021').getFullYear();

    if (currentYear === commentCreationYear) {
      dateFormatToDisplay = 'DD.MM HH:mm';
    }

    return dateFormatToDisplay;
  }

  function renderHeader() {
    const dateFormat = getDateFormat();
    return (
      <div>
        <div>
          <Text
            as="span"
            styles={{ root: { fontSize: 12, marginRight: 10, fontWeight: 600, color: '#252423' } }}
          >
            {person.name || ''}
          </Text>
          <Text as="span" styles={{ root: { fontSize: 12, marginRight: 10, color: '#484644' } }}>
            {moment(fieldResultProps.editDate).format(dateFormat)}
          </Text>
        </div>
        {rolename && (
          <Text
            as="div"
            styles={{
              root: {
                display: 'block',
                lineHeight: 13,
                fontSize: 12,
                marginRight: 10,
                marginBottom: 3,
                color: '#484644'
              }
            }}
          >
            {rolename}
          </Text>
        )}
      </div>
    );
  }

  return (
    <div style={{ display: 'flex' }}>
      <div
        style={{
          width: '100%',
          borderBottom: renderBottomBorder ? '1px solid #d9d9d9' : undefined,
          display: 'flex',
          padding: `7px 12px 7px 12px`
        }}
      >
        {renderPersonaCoin()}
        <div style={{ overflow: 'hidden', width: '100%', position: 'relative' }}>
          {renderHeader()}
          {onRenderContent(fieldResultProps)}
        </div>
      </div>
    </div>
  );
}

export default SurveyResultDefaultValueWrapper;
