import { getTheme } from '@fluentui/react';
import {
  Spread,
  type DOMConversionMap,
  type DOMConversionOutput,
  type DOMExportOutput,
  type EditorConfig,
  type LexicalNode,
  type NodeKey,
  type SerializedTextNode,
  $applyNodeReplacement,
  TextNode
} from 'lexical';

export type SerializedTagNode = Spread<
  {
    tagName: string;
  },
  SerializedTextNode
>;

function convertTagElement(domNode: HTMLElement): DOMConversionOutput | null {
  const { textContent, id } = domNode;

  if (textContent !== null) {
    const node = $createTagNode({ tagName: textContent, id });

    return { node };
  }

  return null;
}

const tagStyle = `color: ${getTheme().palette.themePrimary}; font-weight: bold;`;

export class TagNode extends TextNode {
  tag: string;

  static getType(): string {
    return 'tag';
  }

  static clone(node: TagNode): TagNode {
    return new TagNode(node.tag, node.id, node.key);
  }

  static importJSON(serializedNode: SerializedTagNode): TagNode {
    const node = $createTagNode({ tagName: serializedNode.tagName || '' });
    node.setTextContent(serializedNode.text);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  constructor(text: string, id?: string, key?: NodeKey) {
    super(text, key);
    this.tag = text;
    this.id = id;
  }

  exportJSON(): SerializedTagNode {
    return {
      ...super.exportJSON(),
      tagName: this.tag,
      type: 'tag',
      version: 1
    };
  }

  createDOM(config: EditorConfig): HTMLElement {
    const dom = super.createDOM(config);

    dom.style.cssText = tagStyle;
    dom.className = 'tag';
    dom.id = this.id;
    dom.onmouseover = () => {
      dom.style.cssText = `${tagStyle}text-decoration: underline; cursor: pointer;`;
    };
    dom.onmouseout = () => {
      dom.style.cssText = tagStyle;
    };

    return dom;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('span');
    element.setAttribute('entity-type', 'TAG');
    element.setAttribute('id', this.id);
    element.textContent = this.tag;

    return { element };
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (domNode: HTMLElement) => {
        if (domNode.hasAttribute('entity-type') && domNode.getAttribute('entity-type') === 'TAG') {
          return {
            conversion: convertTagElement,
            priority: 4
          };
        }

        return null;
      }
    };
  }

  static isTextEntity(): true {
    return true;
  }

  static canInsertTextBefore(): boolean {
    return false;
  }

  static canInsertTextAfter(): boolean {
    return false;
  }
}

export function $createTagNode({ tagName, id }: { tagName: string; id?: string }): TagNode {
  const tagNode = new TagNode(tagName, id);

  tagNode.setMode('segmented').toggleDirectionless();

  return $applyNodeReplacement(tagNode);
}

export function $isTagNode(node: LexicalNode | null | undefined): node is TagNode {
  return node instanceof TagNode;
}
