import { IButtonProps, IconButton, concatStyleSets } from '@fluentui/react';
import { useTheme } from 'styled-components';

interface ICloseButtonProps {
  onClick: () => void;
  styles?: IButtonProps['styles'];
}

function CloseButton({ onClick, styles = {} }: ICloseButtonProps) {
  const theme = useTheme();

  const buttonStyles = {
    root: { marginTop: '3px', height: '25px', position: 'absolute', right: '5px', top: '5px' },
    rootHovered: { backgroundColor: `rgb(${theme.panelHeader.foreground} / 0.1)` },
    rootPressed: { backgroundColor: `rgb(${theme.panelHeader.foreground} / 0.3)` }
  };

  return (
    <IconButton
      styles={concatStyleSets(buttonStyles, styles)}
      iconProps={{
        styles: { root: { fontSize: '18px', color: `rgb(${theme.panelHeader.foreground})` } },
        iconName: 'CalculatorMultiply'
      }}
      onClick={onClick}
    />
  );
}

export default CloseButton;
