import NoAccessContainer from 'pages/NoAccess';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import fetchRequest, { apiErrorHandler } from 'services/api';
import styled from 'styled-components';
import { userPropType } from 'types';
import { isInIframe } from 'utils/helpers';
import {
  AnimationStyles,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Toggle
} from '@fluentui/react';

const inIframe = isInIframe();

const AuthorizationAdminStyled = styled.div`
  color: #323130;
  height: calc(100vh - ${() => (inIframe ? '138px' : '80px')});
  margin: 15px;

  .c-authorization-header {
    font-size: 20px;
  }

  .c-authorization-description {
    font-size: 13px;
    max-width: 600px;
  }

  .c-authorization-description-2 {
    margin-top: 5px;
  }

  .c-authorization-description .c-authorization-header {
    font-weight: 300;
  }
`;

function AuthorizationAdministration({ setUsePlanner, currentUser, theme }) {
  const { t } = useTranslation();

  const [consentAlreadyGiven, setConsentAlreadyGiven] = useState(false);
  const [isAuthorizationButtonDisabled, setIsAuthorizationButtonDisabled] = useState(false);
  const [aDmessageBar, setADMessageBar] = useState(null);

  const [plannerMessageBar, setPlannerMessageBar] = useState(null);
  const [isUsePlannerToggleDisabled, setIsUsePlannerToggleDisabled] = useState(true);

  const getMessageBar = useCallback((text, messageBarType) => {
    if (!text) {
      return null;
    }

    return (
      <div key={text.slice(0, 5)} style={{ ...AnimationStyles.slideDownIn20 }}>
        <MessageBar
          styles={{ root: { marginTop: '12px', maxWidth: 'fit-content' } }}
          isMultiline={false}
          messageBarType={messageBarType}
        >
          {text}
        </MessageBar>
      </div>
    );
  }, []);

  useEffect(() => {
    // search user to check if consent is already given
    fetchRequest({ url: `User/Search?searchTerm=xxxxxxxxxxxxx` })
      .then((searchResult) => setConsentAlreadyGiven(!searchResult.consentNeeded))
      .catch(apiErrorHandler);

    fetchRequest({ url: 'tasks/planner', ignoreAlert: true, ignoreConsent: true })
      .then(() => {
        const newMessageBarText = t('authorizationAdministration.planner.messageBar.alreadyGiven');
        const newMessageBar = getMessageBar(newMessageBarText, MessageBarType.success);

        setPlannerMessageBar(newMessageBar);
        setIsUsePlannerToggleDisabled(false);
      })
      .catch(() => {
        setIsUsePlannerToggleDisabled(true);
      });
  }, [getMessageBar, t]);

  useEffect(() => {
    if (consentAlreadyGiven && !inIframe) {
      // if consent is already given disable primarybutton and show messagebar
      setIsAuthorizationButtonDisabled(true);

      const newMessageBarText = t('authorizationAdministration.azureAD.messageBar.alreadyGiven');
      const newMessageBar = getMessageBar(newMessageBarText, MessageBarType.success);

      setADMessageBar(newMessageBar);
    }
  }, [consentAlreadyGiven, getMessageBar, t]);

  useEffect(() => {
    if (inIframe) {
      // if in teams disable primarybutton and show messagebar
      setIsAuthorizationButtonDisabled(true);

      const newMessageBarText = t('authorizationAdministration.azureAD.messageBar.msTeamsWarning');
      const newMessageBar = getMessageBar(newMessageBarText, MessageBarType.warning);

      setADMessageBar(newMessageBar);
    }
  }, [getMessageBar, t]);

  function onAuthorize() {
    setIsAuthorizationButtonDisabled(true);

    fetchRequest({ url: 'init/consent' })
      .then(() => {
        const newMessageBarText = t(
          'authorizationAdministration.azureAD.messageBar.positiveResult'
        );
        const newMessageBar = getMessageBar(newMessageBarText);

        setADMessageBar(newMessageBar);
      })
      .catch(apiErrorHandler);
  }

  function onAuthorizePlannerTasks() {
    fetchRequest({ url: 'tasks/planner' })
      .then(() => {
        setTimeout(() => {
          const newMessageBarText = t(
            'authorizationAdministration.planner.messageBar.positiveResult'
          );
          const newMessageBar = getMessageBar(newMessageBarText, MessageBarType.success);
          setPlannerMessageBar(newMessageBar);
        }, 1000);
      })
      .catch(apiErrorHandler);
  }

  function fetchUsePlanner(checked) {
    const body = JSON.stringify({ ...theme, usePlanner: checked });

    fetchRequest({ url: 'FESettings', method: 'PUT', body })
      .then(() => {
        setUsePlanner(checked);
      })
      .catch(apiErrorHandler);
  }

  if (!currentUser?.isAdmin) {
    return <NoAccessContainer />;
  }

  return (
    <AuthorizationAdminStyled>
      <div className="c-authorization-header">
        {t('authorizationAdministration.azureAD.header')}
      </div>
      <div className="c-authorization-description">
        <div>{t('authorizationAdministration.azureAD.description1')}</div>
        <div className="c-authorization-description-2">
          {t('authorizationAdministration.azureAD.description2')}
        </div>
      </div>
      {aDmessageBar}
      <PrimaryButton
        disabled={isAuthorizationButtonDisabled}
        styles={{ root: { marginTop: '12px' } }}
        onClick={onAuthorize}
        text={t('authorizationAdministration.azureAD.button')}
      />
      <div style={{ marginTop: '2rem' }} className="c-authorization-header">
        {t('authorizationAdministration.planner.header')}
      </div>
      <div className="c-authorization-description">
        <div>{t('authorizationAdministration.planner.description1')}</div>
      </div>
      {plannerMessageBar}
      <PrimaryButton
        disabled={!isUsePlannerToggleDisabled}
        styles={{ root: { marginTop: '12px' } }}
        onClick={onAuthorizePlannerTasks}
        text={t('authorizationAdministration.planner.button')}
      />
      <Toggle
        styles={{ root: { marginTop: '0.5rem' } }}
        disabled={isUsePlannerToggleDisabled}
        label={t('authorizationAdministration.planner.toggle')}
        checked={theme?.usePlanner}
        onChange={(_, checked) => fetchUsePlanner(checked)}
      />
    </AuthorizationAdminStyled>
  );
}

AuthorizationAdministration.propTypes = {
  currentUser: userPropType.isRequired,
  setUsePlanner: PropTypes.func.isRequired,
  theme: PropTypes.shape({ usePlanner: PropTypes.bool }).isRequired
};

export default AuthorizationAdministration;
