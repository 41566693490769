import { IChatInputFile } from 'hooks/api2';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import breakpoints from 'utils/breakpoints';
import { ITextField, IconButton, TextField, Callout, PrimaryButton } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import ChatFile, { IChatFileProps } from './ChatFile';
import TemperatureSetting from '../../../features/AI/TemperatureSetting';
import TemperatureIcon from '../../../features/AI/TemperatureIcon';
import { useNotificationContext } from '../../../features/App';

interface IChatInputProps {
  id?: string;
  disabled?: boolean;
  onSubmit: (message: string) => Promise<void>;
  isGenerating: boolean;
  onDeleteFile?: IChatFileProps['onDelete'];
  stopGenerating: () => void;
  files?: IChatInputFile[];
  fileStatuses?: IChatInputFileStatus[];
  transitionName?: string;
  placeholder?: string;
  onCancel?: () => void;
  temperature?: number;
  onTemperatureChange?: (temperature: number) => Promise<void>;
}

export interface IChatInputFileStatus {
  file: File;
  isUploading: boolean;
  uploadProgress: number | null;
  isWaitingForCompletion: boolean;
  uploaded: boolean;
  uploadError: Error | null;
}

const ChatInputStyled = styled.div<{ disabled: boolean }>`
  border-radius: calc(${({ theme }) => theme.aiChat.inputCornerRadius} + 0.5rem);
  padding: 0.5rem;
  background: rgb(${({ theme }) => theme.aiChat.inputContainerBackground});
  flex-shrink: 0;
  position: sticky;
  bottom: 20px;
  left: 0;
  margin-top: auto;
  right: 0;
  transition: background 0.3s ease;

  @media (max-width: ${breakpoints.extraSmallMax}px) {
    margin: 10px;
  }

  .input-content-wrapper {
    border: 1px solid rgb(${({ theme }) => theme.aiChat.inputOutline});
    background: rgb(
      ${({ theme, disabled }) =>
        disabled ? theme.aiChat.inputDisabledBackground : theme.aiChat.inputBackground}
    );
    border-radius: ${({ theme }) => theme.aiChat.inputCornerRadius};
    transition: background 0.3s ease;
  }

  .chat-input-container {
    padding: 0 5px;
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: auto auto;
    grid-template-areas:
      'input input input'
      'attach . send';
    align-items: end;
  }

  .chat-input {
    grid-area: input;
    width: 100%;
    border: none;
    resize: none;
    border-radius: 5px;

    .ms-TextField-fieldGroup {
      min-height: 0;
      background: transparent;
    }

    .ms-TextField-field {
      line-height: 1.5rem;
      font-size: 16px;
      padding: 0.5rem 0.75rem;
      max-height: 500px;
      background: none;
    }

    .ms-TextField-wrapper {
      border: none;
    }
  }

  .send-button,
  .attach-button {
    border-radius: 5px;
    align-self: end;
    margin-bottom: 4px;
  }

  .send-button:disabled,
  .attach-button:disabled {
    background: transparent;
  }

  .send-container {
    grid-area: send;
    display: flex;
    gap: 0.5rem;

    > .c-layer-container {
      display: none;
    }
  }

  .send-button {
    .ms-Icon {
      font-size: 19px;
    }
  }

  .attach-button {
    grid-area: attach;
    .ms-Icon {
      font-size: 20px;
    }
  }

  .files {
    padding: 9px 10px 5px 10px;
    display: flex;
    gap: 10px;
    overflow-x: auto;

    @media (max-width: ${breakpoints.extraSmallMax}px) {
      gap: 13px;
    }
  }
`;

export default function ChatInput({
  files,
  fileStatuses,
  onDeleteFile,
  id,
  disabled = false,
  onSubmit,
  isGenerating,
  stopGenerating,
  transitionName,
  placeholder,
  onCancel,
  temperature,
  onTemperatureChange
}: IChatInputProps) {
  const { t } = useTranslation();

  const textFieldRef = useRef<ITextField>(null);

  const [textFieldValue, setTextFieldValue] = useState('');
  const textFieldId = useId('prompt-textfield-input');

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!disabled && textFieldRef.current) {
      textFieldRef.current.focus();
    }
  }, [disabled]);

  const textFieldInputHeight = useMemo(() => {
    const textField = document.getElementById(textFieldId) as HTMLTextAreaElement | null;

    if (textField && textFieldValue) {
      textField.style.height = '1px';
      const { scrollHeight } = textField;

      const height = Math.max(scrollHeight, 64);

      textField.style.height = `${height}px`;
      return height;
    }

    if (textField) {
      textField.style.height = '64px';
    }
    return 64;
  }, [textFieldId, textFieldValue]);

  function convertNewlinesToBreaks(text: string) {
    return text.replace(/\n/g, '  \n');
  }

  function handleSubmit() {
    const prompt = textFieldValue?.trim() ?? '';

    if (!prompt || isGenerating || disabled) return;

    setIsSubmitting(true);
    onSubmit(convertNewlinesToBreaks(textFieldValue))
      .then(() => {
        // Clear the input
        setTextFieldValue('');
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  function onKeyDown(event) {
    if (event.key === 'Escape') {
      onCancel?.();
    } else if (event.key === 'Enter') {
      if (!event.shiftKey) {
        event.preventDefault();
      }

      if (!event.shiftKey && textFieldValue) {
        handleSubmit();
      }
    }
  }

  function openFileChooser() {
    const fileInput = document.getElementById(`${id}-file-input`);

    if (fileInput) {
      fileInput.click();
    }
  }

  function renderFiles() {
    if (!files || files.length === 0) return null;

    return (
      <div className="files">
        {files.map((file) => (
          <ChatFile
            onDelete={onDeleteFile}
            key={file.name}
            file={file}
            status={fileStatuses?.find((item) => item.file === file)}
          />
        ))}
      </div>
    );
  }

  return (
    <ChatInputStyled
      disabled={disabled}
      className="chat-input-wrapper"
      style={{ viewTransitionName: transitionName }}
    >
      <div className="input-content-wrapper">
        {renderFiles()}

        <div className="chat-input-container">
          <IconButton
            disabled={disabled}
            id="attach-button"
            className="attach-button"
            iconProps={{ iconName: 'Attach' }}
            title={t('ai.chatInput.attach')}
            onClick={openFileChooser}
          />
          <TextField
            componentRef={textFieldRef}
            id={textFieldId}
            autoAdjustHeight
            multiline
            disabled={disabled}
            value={textFieldValue}
            onChange={(e, newValue) => {
              if (isSubmitting) {
                e.preventDefault();
                return;
              }
              setTextFieldValue(newValue);
            }}
            resizable={false}
            className="chat-input"
            borderless
            styles={({ focused }) => {
              return {
                field: { height: textFieldInputHeight },
                wrapper: {
                  borderBottom: focused ? undefined : '1px solid transparent'
                }
              };
            }}
            onKeyDown={onKeyDown}
            placeholder={placeholder || t('ai.chatInput.placeholder')}
          />
          <div className="send-container">
            {temperature !== undefined && onTemperatureChange ? (
              <SendOptions temperature={temperature} onTemperatureChange={onTemperatureChange} />
            ) : null}

            <IconButton
              disabled={disabled || (!textFieldValue && !isGenerating) || isSubmitting}
              className="send-button"
              iconProps={{ iconName: isGenerating ? 'Stop' : 'Send' }}
              title={t(`ai.chatInput.${isGenerating ? 'stop' : 'send'}`)}
              onClick={() => {
                if (isGenerating) {
                  stopGenerating();
                  return;
                }

                if (textFieldValue) handleSubmit();
              }}
            />
          </div>
        </div>
      </div>
    </ChatInputStyled>
  );
}

function SendOptions({
  temperature,
  onTemperatureChange
}: {
  temperature: number;
  onTemperatureChange: (temperature: number) => Promise<void>;
}) {
  const [open, setOpen] = useState(false);
  const buttonId = useId();

  return (
    <div className="c-send-options">
      <IconButton
        id={buttonId}
        iconProps={{ iconName: 'More' }}
        onRenderIcon={() => <TemperatureIcon temperature={temperature} />}
        onClick={() => setOpen(true)}
      />

      <div className="c-layer-container">
        <Callout
          target={`#${buttonId}`}
          hidden={!open}
          beakWidth={0}
          onDismiss={() => setOpen(false)}
        >
          {open && (
            <SendOptionsCallout
              initialTemperature={temperature}
              onSave={(temperature) => onTemperatureChange(temperature).then(() => setOpen(false))}
            />
          )}
        </Callout>
      </div>
    </div>
  );
}

const ChatInputSendOptionsStyled = styled.div`
  width: 100vw;
  max-width: 400px;
  padding: 0.5rem;

  > .c-buttons {
    display: flex;
    justify-content: end;
    gap: 0.5rem;
    margin-top: 0.5rem;
  }
`;

function SendOptionsCallout({
  initialTemperature,
  onSave
}: {
  initialTemperature: number;
  onSave: (temperature: number) => Promise<void>;
}) {
  const { t } = useTranslation();
  const [temperature, setTemperature] = useState(initialTemperature);
  const [isSaving, setIsSaving] = useState(false);
  const { showError } = useNotificationContext();

  const save = () => {
    if (isSaving) return;
    setIsSaving(true);
    onSave(temperature)
      .catch(showError)
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <ChatInputSendOptionsStyled>
      <TemperatureSetting value={temperature} onChange={setTemperature} />

      <div className="c-buttons">
        <PrimaryButton onClick={save} disabled={isSaving}>
          {t('globals.save')}
        </PrimaryButton>
      </div>
    </ChatInputSendOptionsStyled>
  );
}
