import {
  PointerEvent as ReactPointerEvent,
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import styled from 'styled-components';
import Navigation from './Navigation';
import breakpoints from '../../utils/breakpoints';
import { NavigationContext } from './NavigationContext';

const NavigationContainerStyled = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: auto 1fr;
  min-height: 0;
  overflow: clip;

  --contents-open-pos: 0px;

  > .c-contents {
    position: relative;
    display: grid;
    min-width: 0;
    min-height: 0;
    background: rgb(${({ theme }) => theme.aiPage.background});
    color: rgb(${({ theme }) => theme.aiPage.foreground});
    margin: 9px 9px 0 9px;
    border-radius: 0.5rem;
    box-shadow: 0 0 0 1px rgb(${({ theme }) => theme.aiPage.outline}),
      ${({ theme }) => theme.aiPage.shadow};

    @media (max-width: ${breakpoints.extraSmallMax}px) {
      margin: 0;
    }
  }

  &.is-overlay {
    grid-template-columns: 1fr;

    > .c-navigation {
      grid-area: 1 / 1;
      max-width: var(--contents-open-pos);
      z-index: 2;
    }

    > .c-contents {
      grid-area: 1 / 1;
      z-index: 3;
      transition: box-shadow 0.2s ease-out;

      &::before {
        content: '';
        opacity: 0;
        visibility: hidden;
        position: relative;
        border-radius: inherit;
        background: rgb(${({ theme }) => theme.aiPage.background} / 0.5);
        z-index: 9999;
        grid-area: 1 / 1;

        transition: all 0.3s ease-out;
      }
    }

    > .c-interaction-overlay {
      grid-area: 1 / 1;
      touch-action: none;
      z-index: 1;
    }

    &.is-open > .c-contents {
      transform: translateX(var(--contents-open-pos));
      box-shadow: 0 0 0 1px rgb(${({ theme }) => theme.aiPage.outline}),
        ${({ theme }) => theme.aiNavigation.modalShadow};

      &::before {
        opacity: 1;
        visibility: visible;
      }
    }
  }
`;

function rubberBandBounds(x: number, low: number, high: number): number {
  if (x < low) {
    const x1 = low - x;
    return low - Math.sqrt(x1);
  }
  if (x > high) {
    const x1 = x - high;
    return high + Math.sqrt(x1);
  }
  return x;
}

export default function NavigationContainer({
  logout,
  children
}: {
  logout: () => void;
  children: ReactNode;
}) {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  const [isOverlay, setIsOverlay] = useState(innerWidth <= breakpoints.smallMax);
  const [isOpen, setIsOpen] = useState(false);

  const contentsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const onResize = () => setInnerWidth(window.innerWidth);
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  useEffect(() => {
    const isOverlay = window.innerWidth <= breakpoints.smallMax;
    setIsOverlay(isOverlay);
    if (!isOverlay) setIsOpen(false);
  }, [innerWidth]);

  const contentsOpenPos = Math.min(innerWidth - 44, 300);

  const animateContents = useCallback((initialPos: number, zero: number, initialVel: number) => {
    const contents = contentsRef.current;
    if (!contents) return;

    for (const animation of contents.getAnimations()) animation.cancel();
    const keyframes: Keyframe[] = [];
    const step = 1 / 120;

    let x = initialPos;
    let v = initialVel;

    for (let i = 0; i < 2000; i += 1) {
      keyframes.push({ transform: `translateX(${x}px)` });

      v += (-440 * (x - zero) - 40 * v) * step;
      x += v * step;

      if (Math.abs(x - zero) < 1e-3) break;
    }
    contents.animate(keyframes, { duration: keyframes.length * step * 1000 });
  }, []);

  const ignoreNextIsOpenChange = useRef(false);
  const wasOpenRef = useRef(isOpen);

  useLayoutEffect(() => {
    const contents = contentsRef.current;
    if (!contents) return;
    contents.inert = isOpen;

    const wasOpen = wasOpenRef.current;
    wasOpenRef.current = isOpen;

    if (ignoreNextIsOpenChange.current) {
      ignoreNextIsOpenChange.current = false;
      return;
    }

    if (isOpen && !wasOpen) {
      animateContents(0, contentsOpenPos, 0);
    } else if (!isOpen && wasOpen) {
      animateContents(contentsOpenPos, 0, 0);
    }
  }, [isOpen, animateContents, contentsOpenPos]);

  const onInteractionOverlayPointerDown = useCallback(
    (event: ReactPointerEvent<HTMLDivElement>) => {
      // the constants here were chosen by testing on an iPhone, but should still work elsewhere

      const contents = contentsRef.current;
      if (!contents) return;

      event.preventDefault();

      let moved = 0;

      let lastX = event.clientX;

      const positionSamples = [{ t: document.timeline.currentTime, x: lastX }];
      const samplePosition = (t: number) => {
        // upper sample as first sample after t
        let i1 = positionSamples.findIndex((sample) => sample.t >= t);
        if (i1 === -1) i1 = positionSamples.length - 1;

        const sample1 = positionSamples[i1];

        // find any prior sample with enough time difference
        let i0 = i1;
        while (i0 > 0) {
          i0 -= 1;
          if (positionSamples[i0].t < sample1.t - 1e-3) break;
        }

        const sample0 = positionSamples[i0];

        if (Math.abs(sample1.t - sample0.t) < 1e-6) {
          // division by zero
          return sample0.x;
        }

        return sample0.x + (sample1.x - sample0.x) * ((t - sample0.t) / (sample1.t - sample0.t));
      };
      const currentVelocity = () => {
        // finite differencing 16ms apart
        const a = samplePosition(document.timeline.currentTime - 16);
        const b = samplePosition(document.timeline.currentTime);
        // output in px/s
        return (b - a) * 62.5;
      };

      const interactionOverlay = event.currentTarget;
      const contentsRect = contents.getBoundingClientRect();
      const contentsMargin = parseInt(getComputedStyle(contents).marginLeft, 10);

      const offset = lastX - contentsRect.left + contentsMargin;

      for (const animation of contents.getAnimations()) animation.cancel();
      contents.animate(
        [{ transform: `translateX(${rubberBandBounds(lastX - offset, 0, contentsOpenPos)}px)` }],
        { fill: 'forwards' }
      );

      const capturedPointer = event.pointerId;
      interactionOverlay.setPointerCapture(capturedPointer);

      const onPointerMove = (event: PointerEvent) => {
        if (event.pointerId !== capturedPointer) return;

        event.preventDefault();

        positionSamples.push({ t: document.timeline.currentTime, x: event.clientX });

        moved += Math.abs(event.clientX - lastX);
        lastX = event.clientX;

        for (const animation of contents.getAnimations()) animation.cancel();
        contents.animate(
          [{ transform: `translateX(${rubberBandBounds(lastX - offset, 0, contentsOpenPos)}px)` }],
          { fill: 'forwards' }
        );
      };

      const end = (newOpenState: boolean) => {
        const zero = newOpenState ? contentsOpenPos : 0;

        ignoreNextIsOpenChange.current = true;
        setIsOpen(newOpenState);
        animateContents(
          rubberBandBounds(lastX - offset, 0, contentsOpenPos),
          zero,
          currentVelocity()
        );
      };

      const onPointerUp = (event: PointerEvent) => {
        if (event.pointerId !== capturedPointer) return;
        event.preventDefault();
        detach();

        positionSamples.push({ t: document.timeline.currentTime, x: event.clientX });

        const throwTargetEstimate = event.clientX - offset + currentVelocity();
        const mustClose = moved < 16;

        const isOpen = throwTargetEstimate > contentsOpenPos / 2 && !mustClose;
        end(isOpen);
      };

      const onPointerCancel = (event: PointerEvent) => {
        if (event.pointerId !== capturedPointer) return;

        detach();
        end(isOpen);
      };

      interactionOverlay.addEventListener('pointermove', onPointerMove);
      interactionOverlay.addEventListener('pointerup', onPointerUp);
      interactionOverlay.addEventListener('pointercancel', onPointerCancel);

      function detach() {
        interactionOverlay.releasePointerCapture(capturedPointer);
        interactionOverlay.removeEventListener('pointermove', onPointerMove);
        interactionOverlay.removeEventListener('pointerup', onPointerUp);
        interactionOverlay.removeEventListener('pointerup', onPointerCancel);
      }
    },
    [isOpen, animateContents, contentsOpenPos]
  );

  const context = useMemo(
    () => ({
      isOverlay,
      isOpen,
      open: () => setIsOpen(true),
      close: () => setIsOpen(false)
    }),
    [isOverlay, isOpen]
  );

  return (
    <NavigationContext.Provider value={context}>
      <NavigationContainerStyled
        className={`${isOverlay ? 'is-overlay' : ''} ${isOpen ? 'is-open' : ''}`}
        style={
          {
            '--contents-open-pos': `${contentsOpenPos}px`
          } as Record<string, string>
        }
      >
        <Navigation className="c-navigation" isOverlay={isOverlay} logout={logout} />
        <div className="c-contents" ref={contentsRef}>
          {children}
        </div>
        {isOpen ? (
          <div className="c-interaction-overlay" onPointerDown={onInteractionOverlayPointerDown} />
        ) : null}
      </NavigationContainerStyled>
    </NavigationContext.Provider>
  );
}
