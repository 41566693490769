import Label from 'components/inputs/Label';
import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import SignaturePad from 'signature_pad';
import { ISignatureValueProps } from 'types';
import { DefaultButton, IButtonStyles, Stack } from '@fluentui/react';
import SignaturePadWrapper from './SignatureField.styles';

export interface ISignatureFieldProps {
  id?: string;
  /**
   * Defaultvalue for the SignatureField
   */
  defaultValue?: ISignatureValueProps;
  /**
   * Description for the SignatureField
   */
  description?: string;
  /**
   * A label for the SignatureField
   */
  label?: string;
  /**
   * The icon that will be displayed to the left of the label
   */
  labelIconName?: string;
  /**
   * Callback issued when the state changes.
   */
  onChange?: (data?: ISignatureValueProps) => void;
  /**
   * Optional flag to mark SignatureField as readOnly / disabled
   * @defaultvalue false
   */
  disabled: boolean;
  /**
   * Whether the associated form field is required or not
   * @defaultvalue false
   */
  required?: boolean;
}

function SignatureField({
  id,
  defaultValue,
  description,
  disabled = false,
  label,
  labelIconName,
  onChange,
  required = false
}: ISignatureFieldProps): JSX.Element {
  const { t } = useTranslation();

  const signaturePad = useRef<SignaturePad | null>();

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const onCanvasEnd = useCallback(() => {
    let data: string | null | undefined = null;

    if (signaturePad && !signaturePad.current?.isEmpty()) {
      data = signaturePad.current?.toDataURL();
    }

    if (onChange) {
      onChange({ signature: data });
    }
  }, [onChange, signaturePad]);

  const updateCanvasSize = useCallback(() => {
    if (canvasRef.current && wrapperRef.current) {
      const wrapperWidth = wrapperRef.current.offsetWidth;
      const prevContent = canvasRef.current.toDataURL();

      canvasRef.current.width = wrapperWidth;

      if (signaturePad.current) {
        signaturePad.current.fromDataURL(prevContent);
      }
    }
  }, []);

  useLayoutEffect(() => {
    if (!disabled) {
      updateCanvasSize();

      const resizeObserver = new ResizeObserver(() => {
        updateCanvasSize();
      });

      if (wrapperRef.current) {
        resizeObserver.observe(wrapperRef.current);
      }

      return () => {
        resizeObserver.disconnect();
      };
    }

    return () => {
      return null;
    };
  }, [disabled, updateCanvasSize]);

  useEffect(() => {
    if (!signaturePad.current && canvasRef.current) {
      signaturePad.current = new SignaturePad(canvasRef.current, {
        maxWidth: 2,
        minDistance: 1,
        throttle: 0
      });

      signaturePad.current.addEventListener('endStroke', onCanvasEnd);

      if (defaultValue && defaultValue.signature) {
        signaturePad.current?.fromDataURL(defaultValue.signature);
      }
    }
  }, [defaultValue, onCanvasEnd, signaturePad]);

  function onUndo() {
    const data = signaturePad.current?.toData();

    if (data) {
      data.pop(); // remove the last dot or line
      signaturePad.current?.fromData(data);
    }
  }

  function onClear() {
    signaturePad.current?.clear();

    if (onChange) {
      onChange();
    }
  }

  function getCanvas() {
    if (!defaultValue && disabled) {
      return (
        <canvas
          height="100"
          style={{
            width: '100%',
            maxHeight: '100px',
            background: '#f3f2f1',
            border: '1px solid #a19f9d',
            borderRadius: 3
          }}
        />
      );
    }

    if (defaultValue?.signature && disabled) {
      return (
        <img
          style={{
            background: '#f3f2f1',
            border: '1px solid #a19f9d',
            borderRadius: 3,
            maxWidth: '100%'
          }}
          aria-hidden="true"
          alt="img"
          src={defaultValue.signature}
        />
      );
    }

    return (
      <canvas
        className="c-signature-pad"
        height="100"
        ref={canvasRef}
        style={{ border: '1px solid #a19f9d', borderRadius: 3 }}
      />
    );
  }

  function getEditButtons() {
    if (disabled) {
      return null;
    }

    const buttonStyle: IButtonStyles = {
      root: {
        borderRadius: 3
      }
    };

    return (
      <Stack horizontal tokens={{ childrenGap: 5 }}>
        <DefaultButton
          iconProps={{ iconName: 'Undo', styles: { root: { fontSize: 12 } } }}
          styles={buttonStyle}
          text={t('signatureField.undo')}
          onClick={onUndo}
        />
        <DefaultButton
          iconProps={{ iconName: 'Clear', styles: { root: { fontSize: 12 } } }}
          styles={buttonStyle}
          text={t('signatureField.clear')}
          onClick={onClear}
        />
      </Stack>
    );
  }

  return (
    <SignaturePadWrapper id={id} ref={wrapperRef}>
      <Label description={description} iconName={labelIconName} label={label} required={required} />
      {getCanvas()}
      {getEditButtons()}
    </SignaturePadWrapper>
  );
}

export default SignatureField;
