import { evocomBeeLogo } from 'layouts';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import breakpoints from 'utils/breakpoints';
import { Spinner, SpinnerSize } from '@fluentui/react';
import { ShowError } from '../../ShowError';

const LoadingContainer = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(${({ theme }) => theme.appLoading.background});
  color: rgb(${({ theme }) => theme.appLoading.foreground});

  .c-loading-container {
    text-align: center;
    padding: 25px 50px;

    @media (min-width: ${breakpoints.smallMax}px) {
      width: 50%;
      padding: 50px 100px;
      box-shadow: 0 3.2px 7.2px 0 rgb(0 0 0 / 13%), 0 0.6px 1.8px 0 rgb(0 0 0 / 11%);
      background: rgb(${({ theme }) => theme.appLoading.cardBackground});
      border-radius: 5px;
      border: 1px solid rgb(${({ theme }) => theme.appLoading.cardBorder});
    }
  }

  .c-loading-container_logo {
    max-width: max-content;
    margin-right: auto;
    margin-left: auto;
  }

  .c-loading-container_header {
    margin-top: 20px;
    font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system,
      BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif;
    font-size: 46px;
  }

  .c-loading-container_subText {
    margin-top: 30px;
    font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system,
      BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif;
    font-size: 17px;
  }

  .c-loading-container_spinner-wrapper {
    margin-top: 40px;
  }
`;

export default function GlobalLoadingScreen({ error }: { error?: Error }) {
  const { t } = useTranslation();
  const theme = useTheme();

  const subText = t('app.container.loading.subHeader');

  return (
    <LoadingContainer>
      <div className="c-loading-container">
        <div className="c-loading-container_logo">{evocomBeeLogo(150)}</div>
        <div className="c-loading-container_header">Evocom</div>
        <div className="c-loading-container_subText">{subText}</div>
        {!error ? (
          <div className="c-loading-container_spinner-wrapper">
            <Spinner
              styles={{
                label: {
                  color: `rgb(${theme.appLoading.loadingLabelText})`
                }
              }}
              size={SpinnerSize.large}
              label={t('app.container.loading.spinner')}
            />
          </div>
        ) : (
          <ShowError error={error} />
        )}
      </div>
    </LoadingContainer>
  );
}
