/* eslint-disable max-classes-per-file */

import convert from 'color-convert';

/** An 8-bit sRGB color value. */
export class Rgb extends String {
  readonly r: number;

  readonly g: number;

  readonly b: number;

  constructor(r: number, g: number, b: number) {
    // clamp to 0..255
    const r2 = Math.min(Math.max(0, Math.round(r)), 255);
    const g2 = Math.min(Math.max(0, Math.round(g)), 255);
    const b2 = Math.min(Math.max(0, Math.round(b)), 255);

    super(`${r2} ${g2} ${b2}`);
    this.r = r2;
    this.g = g2;
    this.b = b2;
  }

  get cssColor() {
    return `rgb(${this})`;
  }

  get relativeLuminance() {
    // https://www.w3.org/TR/WCAG20/#relativeluminancedef
    const rSrgb = this.r / 255;
    const gSrgb = this.g / 255;
    const bSrgb = this.b / 255;
    const r = rSrgb < 0.03928 ? rSrgb / 12.92 : ((rSrgb + 0.055) / 1.055) ** 2.4;
    const g = gSrgb < 0.03928 ? gSrgb / 12.92 : ((gSrgb + 0.055) / 1.055) ** 2.4;
    const b = bSrgb < 0.03928 ? bSrgb / 12.92 : ((bSrgb + 0.055) / 1.055) ** 2.4;
    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
  }

  getContrastRatio(other: Rgb): number {
    // https://www.w3.org/TR/WCAG20/#contrast-ratiodef
    let l1 = this.relativeLuminance;
    let l2 = other.relativeLuminance;

    // L1 is always lighter
    if (l2 > l1) {
      [l1, l2] = [l2, l1];
    }

    return (l1 + 0.05) / (l2 + 0.05);
  }

  asArray(): [number, number, number] {
    return [this.r, this.g, this.b];
  }

  get lchLuminance(): number {
    return convert.rgb.lch(this.asArray())[0];
  }

  /** Applies a luminance delta with some adjustments to avoid ugly colors. */
  withLchLuminanceAdjustment(delta: number): Rgb {
    const [l, c, h] = convert.rgb.lch(this.asArray());
    const actualDelta = Math.max(0, Math.min(l + delta, 100)) - l;

    const newL = l + actualDelta;
    let newC = c;
    let newH = h;

    if (actualDelta < 0) {
      // saturate darker colors (unless it was entirely grayscale)
      newC += c ? Math.abs(actualDelta) / 3 : 0;
      newC = Math.min(100, newC);

      // dark yellow is kinda ugly. We'll move away from yellow as we get darker
      const yellowDistance = Math.abs(h - 103);
      const yellowDeltaSign = Math.sign(h - 103);
      const yellowAdjustmentAmount = Math.max(0, 30 - yellowDistance);
      newH += yellowDeltaSign * yellowAdjustmentAmount * Math.min(1, Math.abs(actualDelta / 30));
    } else if (actualDelta > 0) {
      // desaturate lighter colors
      newC = Math.max(0, newC - Math.abs(actualDelta) / 3);

      // dark pink is better associated with bright red or blue instead of pink
      const pinkDistance = Math.abs(h - 332);
      const pinkDeltaSign = Math.sign(h - 332);
      const pinkAdjustmentAmount = Math.max(0, 20 - pinkDistance);
      newH += pinkDeltaSign * pinkAdjustmentAmount * Math.min(1, Math.abs(actualDelta / 40));
    }

    const [r, g, b] = convert.lch.rgb([newL, newC, newH]);
    return new Rgb(r, g, b);
  }

  /**
   * Similar to this#withLchLuminanceAdjustment,
   * but will compress instead of clamping when the original color was already particularly light or dark.
   */
  withCompressedLchLumAdj(delta: number): Rgb {
    const [l] = convert.rgb.lch(this.asArray());
    const unitLuma = l / 100;
    let adjustment: number;
    if (delta > 0) {
      adjustment = (1 - unitLuma) * (1 - Math.exp(-delta / 50));
    } else {
      adjustment = -unitLuma * (1 - Math.exp(delta / 50));
    }
    return this.withLchLuminanceAdjustment(adjustment * 100);
  }

  /** Calls withCompressedLchLumAdj in both directions and selects the color that contrasts more. */
  withLumContrastAdj(delta: number): Rgb {
    const a = this.withCompressedLchLumAdj(delta);
    const b = this.withCompressedLchLumAdj(-delta);
    return this.getContrastRatio(a) > this.getContrastRatio(b) ? a : b;
  }

  invertLuminance(): Rgb {
    const [l, c, h] = convert.rgb.lch(this.asArray());
    const [r, g, b] = convert.lch.rgb([100 - l, c, h]);
    return new Rgb(r, g, b);
  }

  withLchChromaAdj(chroma: (c: number) => number): Rgb {
    const [l, c, h] = convert.rgb.lch(this.asArray());
    const [r, g, b] = convert.lch.rgb([l, Math.max(0, Math.min(100, chroma(c))), h]);
    return new Rgb(r, g, b);
  }

  mix(other: Rgb, alpha: number): Rgb {
    const lerp = (a: number, b: number) => (b - a) * alpha + a;
    return new Rgb(lerp(this.r, other.r), lerp(this.g, other.g), lerp(this.b, other.b));
  }

  triadHarmony(): [Rgb, Rgb, Rgb] {
    const [h, c, g] = convert.rgb.hcg(this.asArray());
    const harmony1 = Rgb.fromArray(convert.hcg.rgb([h + 120, c, g]));
    const harmony2 = Rgb.fromArray(convert.hcg.rgb([h + 240, c, g]));
    return [this, harmony1, harmony2];
  }

  static fromArray(rgb: number[]): Rgb {
    return new Rgb(rgb[0], rgb[1], rgb[2]);
  }

  static parse(value: unknown): Rgb | null {
    if (value instanceof Rgb) return value;

    if (typeof value === 'string') {
      const hexColorMatch = value.match(/^#([0-9a-f]{3}|[0-9a-f]{6})$/i);
      if (hexColorMatch) {
        if (hexColorMatch[1].length === 3) {
          const [rString, gString, bString] = hexColorMatch[1];
          const r = parseInt(rString, 16);
          const g = parseInt(gString, 16);
          const b = parseInt(bString, 16);
          return new Rgb((r << 4) | r, (g << 4) | g, (b << 4) | b);
        }
        const r = hexColorMatch[1].substring(0, 2);
        const g = hexColorMatch[1].substring(2, 4);
        const b = hexColorMatch[1].substring(4, 6);
        return new Rgb(parseInt(r, 16), parseInt(g, 16), parseInt(b, 16));
      }
    }

    return null;
  }
}

/** A color with an identifier. Used to extract global variable references (e.g. global foreground color) */
export class GlobalRgb extends Rgb {
  id: string;

  constructor(r: number, g: number, b: number, id: string) {
    super(r, g, b);
    this.id = id;
  }
}

/**
 * Creates an RGB color.
 *
 * Asserts that the value can be parsed to an RGB color. Throws if this is not true.
 */
export function rgb(value: unknown): Rgb {
  const rgb = Rgb.parse(value);
  if (!rgb) throw new Error(`invalid color: ${value}`);
  return rgb;
}

export function globalRgb(value: unknown, id: string): Rgb {
  const rgbValue = rgb(value);
  return new GlobalRgb(rgbValue.r, rgbValue.g, rgbValue.b, id);
}

const DECENT_CONTRAST_RATIO = 3;

/**
 * Selects the first color from colorOptions that contrasts well against the cmpColor parameter,
 * or the best color if there isn't one that contrasts particularly well.
 */
export function colorContrast(cmpColor: Rgb, colorOptions: Rgb[]): Rgb {
  let maxContrast = 0;
  let maxColor = colorOptions[0];
  for (const color of colorOptions) {
    const ratio = color.getContrastRatio(cmpColor);
    if (ratio > maxContrast) {
      maxContrast = ratio;
      maxColor = color;
    }
    if (ratio >= DECENT_CONTRAST_RATIO) {
      return color;
    }
  }
  return maxColor;
}

const MIN_SYNTHETIC_CONTRAST = 4.4;

/** Synthesizes a version of the color that contrasts sufficiently against cmpColor. */
export function synthesizeContrastingVariant(
  cmpColor: Rgb,
  color: Rgb,
  minContrast = MIN_SYNTHETIC_CONTRAST
): Rgb {
  const initialRatio = cmpColor.getContrastRatio(color);
  if (initialRatio > minContrast) {
    return color;
  }
  const cmpColorIsLight = cmpColor.relativeLuminance > 0.5;

  let lumDelta = 0;
  for (let i = 0; i < 10; i += 1) {
    lumDelta += cmpColorIsLight ? -10 : 10; // move away from cmpColor
    const newColor = color.withLchLuminanceAdjustment(lumDelta);
    if (cmpColor.getContrastRatio(newColor) > minContrast) {
      return newColor;
    }
  }

  return color.invertLuminance();
}
