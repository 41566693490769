import Label from 'components/inputs/Label';
import SmallTextField from 'components/inputs/SmallTextField';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import {
  ChoiceGroup,
  ComboBox,
  DefaultButton,
  Checkbox as FabricCheckbox,
  ICheckboxProps,
  IChoiceGroupOption,
  IChoiceGroupOptionProps,
  IChoiceGroupOptionStyles,
  IChoiceGroupProps,
  IComboBoxProps,
  ITextField,
  IconButton,
  TextField,
  concatStyleSets
} from '@fluentui/react';
import { useTheme } from 'styled-components';
import { useId } from '@fluentui/react-hooks';
import ChoiceGroupWrapper from './ChoiceGroupField.styles';

export interface IChoiceGroupFieldProps {
  id?: string;
  asCombobox?: boolean;
  /**
   * Allow the user to add their own option.
   * @defaultvalue false
   */
  allowFillIn?: boolean;
  /**
   * Optional prop that indicates if multi-choice selections are allowed or not.
   * @defaultvalue false
   */
  allowMultipleSelections?: boolean;
  /**
   * Description of the choicegroup field.
   */
  description?: string;
  /**
   * Default selected options of the choicegroup field.
   * @defaultvalue []
   */
  defaultValue?: IOption[];
  /**
   * Disabled state of the choicegroup field.
   * @defaultvalue false
   */
  disabled?: boolean;
  /**
   * Label for the choicegroup field.
   */
  label?: string;
  /**
   * The icon that will be displayed to the left of the label
   */
  labelIconName?: string;
  /**
   * Callback for when the input value changes.
   */
  onChange?: (selectedOptions: IOption[] | []) => void;
  /**
   * The options for the choice group.
   */
  options: IOption[];
  /**
   * specifies a short hint that describes the expected value of an input field (e.g. a sample value or a short description of the expected format).
   */
  placeholder?: string;
  /**
   * Whether the choice field is a required field or not
   * @defaultvalue false
   */
  required?: boolean;
  /**
   * Call to provide customized styling that will layer on top of the variant rules.
   */
  styles?: IChoiceGroupStyles;
}

interface IChoiceGroupStyles {
  choiceGroup: IChoiceGroupProps['styles'];
  comboBox: IComboBoxProps['styles'];
}

export interface IOption {
  key: string;
  text: string;
  data?: unknown;
  isCustomOption?: boolean;
}
interface IComboBoxOption {
  key: number | string;
  text: string;
  data?: unknown;
  isCustomOption?: boolean;
  selected?: boolean;
}

function ChoiceGroupField({
  id,
  asCombobox = false,
  allowFillIn = false,
  allowMultipleSelections = false,
  defaultValue = [],
  description,
  disabled = false,
  label,
  labelIconName,
  onChange,
  options,
  placeholder,
  required = false,
  styles
}: IChoiceGroupFieldProps) {
  const theme = useTheme();

  const [initialized, setInitialized] = useState<boolean>(false);

  const [choiceGroupOptions, setChoiceGroupOptions] = useState<IOption[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<IOption[]>(() => defaultValue);

  const [customOptionKey, setCustomOptionKey] = useState<string | null>(null);

  const customOptionTextFieldRef = useRef<ITextField>(null);

  const displayAsComboBox = asCombobox || (options && options.length > 7);

  const { t } = useTranslation();

  // the same field may be present on the page multiple times, which breaks <label for="">.
  // hence, we'll add a unique prefix
  const checkboxIdPrefix = useId();

  useEffect(() => {
    const customOpion = choiceGroupOptions.find((option) => option.isCustomOption);

    setCustomOptionKey(customOpion?.key || null);
  }, [choiceGroupOptions]);

  useEffect(() => {
    if (!initialized) {
      const fullOptions = [...options];

      if (defaultValue?.length) {
        // find custom option
        const customOption = defaultValue.find(
          (defaultOption) => !options.some((option) => option.key === defaultOption.key)
        );

        if (customOption) {
          customOption.isCustomOption = true;

          if (displayAsComboBox) {
            fullOptions.unshift(customOption);
          } else {
            fullOptions.push(customOption);
          }

          setCustomOptionKey(customOption.key);
        }
      }

      if (fullOptions) setChoiceGroupOptions(fullOptions);

      setInitialized(true);
    }
  }, [options, initialized, defaultValue, displayAsComboBox]);

  function handleCustomComboBoxOption(newOptionString: string): void {
    // check if there is already an custom option
    const customOptionIndex = choiceGroupOptions.findIndex(
      (option: IOption) => !!option.isCustomOption
    );

    const customOptionAlreadyExsits = customOptionIndex > -1;

    // create new custom option
    const customSelectableOption: IOption = {
      key: newOptionString,
      text: newOptionString,
      isCustomOption: true
    };

    if (customOptionAlreadyExsits) {
      // replace custom option
      replaceCustomComboBoxOption(customSelectableOption);
    } else {
      // add custom option to selectableOptions list
      setChoiceGroupOptions((prevState) => [customSelectableOption, ...prevState]);
    }
  }

  function setSelectedComboBoxOption(option: IOption, selected: boolean): void {
    setSelectedOptions((prevState) => {
      let newSelectedOptionsArray = [...prevState];

      if (allowMultipleSelections) {
        // multiple selections

        if (selected) {
          // add new key
          newSelectedOptionsArray = [...newSelectedOptionsArray, option];
        } else {
          // find key index
          const optionIndexToRemove = newSelectedOptionsArray.findIndex(
            (selectedOption) => selectedOption.key === option.key
          );
          // remove given keys
          newSelectedOptionsArray.splice(optionIndexToRemove, 1);
        }
      } else {
        // only one selection possible
        newSelectedOptionsArray = [option];
      }

      if (onChange) onChange(newSelectedOptionsArray);

      return newSelectedOptionsArray;
    });
  }

  function onComboBoxChange(
    _: unknown,
    option?: IComboBoxOption,
    __?: unknown,
    value?: string
  ): void {
    if (allowFillIn && !option && value) {
      // If allowFreeform is true, the newly selected option might be something the user typed that
      // doesn't exist in the options list yet. So there's extra work to manually add it.
      const newOption: IOption = { key: value, text: value };
      handleCustomComboBoxOption(value);
      setSelectedComboBoxOption(newOption, true);
    } else if (option) {
      // existing option selected
      const fullOption = options.find((fullOption) => fullOption.key === option.key);

      // fulloption could be undefined
      const fallbackOption: IOption = {
        key: option.key.toString(),
        text: option.text,
        isCustomOption: false
      };

      setSelectedComboBoxOption(fullOption || fallbackOption, !!option.selected);
    } else if (!option && !value) {
      // no option selected
      setSelectedOptions([]);
      if (onChange) onChange([]);
    }
  }

  function replaceCustomComboBoxOption(customSelectableOption: IOption): void {
    setChoiceGroupOptions((prevSelectableOptions) => {
      const prevSelectableOptionsClone = [...prevSelectableOptions];
      prevSelectableOptionsClone[0] = customSelectableOption;

      return prevSelectableOptionsClone;
    });
  }

  function onChoiceGroupChange(_?: unknown, option?: IOption): void {
    const newSelectedOptionsArray = [];

    const deselection = selectedOptions[0]?.key === option?.key && !required;

    if (option && !deselection) {
      newSelectedOptionsArray.push(option);
    }

    setSelectedOptions(newSelectedOptionsArray);

    if (onChange) {
      onChange(newSelectedOptionsArray);
    }
  }

  function onCheckboxChange(option: IOption, checked?: boolean): void {
    setSelectedOptions((prevSelectedOptions): IOption[] => {
      const selectedOptionsClone = [...prevSelectedOptions];

      // add to selected keys
      if (checked) {
        selectedOptionsClone.push(option);

        if (onChange) {
          onChange(selectedOptionsClone);
        }

        return selectedOptionsClone;
      }

      // remove from selected keys
      const selectedOptionIndex = selectedOptionsClone.findIndex(
        (selectedOption) => selectedOption.key === option.key
      );

      if (selectedOptionIndex > -1) {
        selectedOptionsClone.splice(selectedOptionIndex, 1);

        if (onChange) {
          onChange(selectedOptionsClone);
        }

        return selectedOptionsClone;
      }

      // fallback: return unchanged selected keys
      return selectedOptionsClone;
    });
  }

  function onRenderCheckboxLabel(
    checkBoxProps?: ICheckboxProps,
    defaultRender?: (props?: ICheckboxProps) => JSX.Element | null
  ): JSX.Element | null {
    const completeOption = choiceGroupOptions.find((option) => option.isCustomOption);

    if (checkBoxProps?.id === 'custom' && checkBoxProps) {
      return (
        <div style={{ width: '100%', display: 'inline-block', paddingLeft: '4px' }}>
          <span>{checkBoxProps.label}</span>
          <IconButton
            styles={{
              root: {
                display: disabled ? 'none' : 'inline',
                height: 15,
                marginLeft: 12,
                marginTop: 2,
                position: 'relative',
                top: 2,
                width: 20
              },
              rootHovered: { backgroundColor: 'transparent' }
            }}
            iconProps={{ styles: { root: { fontSize: '13px' } }, iconName: 'CalculatorMultiply' }}
            onClick={() => removeCustomOption(completeOption?.key || '')}
          />
        </div>
      );
    }

    if (defaultRender) {
      return defaultRender(checkBoxProps);
    }

    return null;
  }

  function renderCheckBox(option: IOption): JSX.Element {
    const checked = !!selectedOptions.find((selectedOption) => option.key === selectedOption.key);

    // custom id to identify custom option for rendering label
    const checkBoxId = option.isCustomOption ? 'custom' : option.key;

    return (
      <FabricCheckbox
        checked={checked}
        disabled={disabled}
        id={checkboxIdPrefix + checkBoxId}
        key={option.key}
        label={option.text}
        onChange={(_?: unknown, checked?: boolean) => onCheckboxChange(option, checked)}
        onRenderLabel={onRenderCheckboxLabel}
        styles={{
          root: { marginTop: 6 },
          checkbox: {
            background: checked
              ? `rgb(${theme.choiceGroupField.controlCheckedFill})`
              : 'transparent',
            borderColor: `rgb(${theme.choiceGroupField.foreground})`
          },
          text: { color: `rgb(${theme.choiceGroupField.foreground})` }
        }}
      />
    );
  }

  function renderMultipleChoiceGroup(): JSX.Element | null {
    if (!choiceGroupOptions?.length) {
      return null;
    }

    return (
      <ChoiceGroupWrapper id={id}>
        <Label
          required={required}
          iconName={labelIconName}
          label={label}
          description={description}
        />
        <div
          className="c-choicegroup__wrapper"
          style={{
            background: disabled
              ? `rgb(${theme.choiceGroupField.disabledBackground})`
              : `rgb(${theme.choiceGroupField.background})`
          }}
        >
          {choiceGroupOptions.map(renderCheckBox)} <CustomOptionTextField />
        </div>
      </ChoiceGroupWrapper>
    );
  }

  function removeCustomOption(key: string): void {
    setChoiceGroupOptions((prevState) => {
      const newChoiceGroupOptions = [...prevState];

      const optionIndexToRemove = newChoiceGroupOptions.findIndex((option) => option.key === key);

      if (optionIndexToRemove > -1) {
        newChoiceGroupOptions.splice(optionIndexToRemove, 1);
      }

      return newChoiceGroupOptions;
    });

    // check if the cutom option was selected and if so remove it from selected keys
    removeSelectedKey(key);
  }

  function removeSelectedKey(key: string): void {
    setSelectedOptions((prevState) => {
      const newSelectedOptions = [...prevState];

      const optionIndexToRemove = newSelectedOptions.findIndex((option) => option.key === key);

      if (optionIndexToRemove > -1) {
        newSelectedOptions.splice(optionIndexToRemove, 1);
      }

      if (onChange) {
        onChange(newSelectedOptions);
      }

      return newSelectedOptions;
    });
  }

  function onRenderComboBoxOption(
    renderComboBoxOptionProps?: IComboBoxOption,
    defaultRender?: (props?: IComboBoxOption) => JSX.Element | null
  ): JSX.Element | null {
    if (renderComboBoxOptionProps?.isCustomOption) {
      const deleteButtonStyles = {
        root: {
          border: 'none',
          height: 15,
          marginLeft: 15,
          marginTop: 2,
          minWidth: 10,
          padding: 0,
          width: 15
        }
      };

      return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>{renderComboBoxOptionProps.text}</div>
          <DefaultButton
            styles={deleteButtonStyles}
            iconProps={{ iconName: 'CalculatorMultiply', styles: { root: { fontSize: 15 } } }}
            onClick={(ev) => {
              ev.stopPropagation();
              removeCustomOption(renderComboBoxOptionProps?.key.toString());
            }}
          />
        </div>
      );
    }

    if (defaultRender) {
      return defaultRender(renderComboBoxOptionProps);
    }

    return null;
  }

  function addCustomOption(customOption: IOption): void {
    setChoiceGroupOptions((prevState) => [...prevState, customOption]);
  }

  function onSaveCustomOption(): void {
    if (customOptionTextFieldRef.current?.value) {
      const { value } = customOptionTextFieldRef.current;
      const newOption = { key: uuidv4(), text: value, isCustomOption: true };

      addCustomOption(newOption);

      if (allowMultipleSelections) {
        onCheckboxChange(newOption, true);
      } else {
        onChoiceGroupChange(undefined, newOption);
      }
    }
  }

  function doesCustomOptionExist(): boolean {
    if (!allowFillIn) {
      return false;
    }

    if (displayAsComboBox) {
      // combobox displays custom option on first index
      return !!choiceGroupOptions[0].isCustomOption;
    }

    const lastChoicegroupIndex = choiceGroupOptions.length - 1;
    return !!choiceGroupOptions[lastChoicegroupIndex].isCustomOption;
  }

  function onRenderChoiceGroupLabel(
    renderChoiceGroupOptionProps?: IChoiceGroupOption & IChoiceGroupOptionProps,
    defaultRender?: (props?: IChoiceGroupOption & IChoiceGroupOptionProps) => JSX.Element | null
  ): JSX.Element | null {
    const isCustomOption = renderChoiceGroupOptionProps?.key === customOptionKey;

    if (renderChoiceGroupOptionProps && isCustomOption) {
      const completeOption = choiceGroupOptions.find((option) => option.isCustomOption);

      return (
        <div style={{ width: '100%', display: 'inline-block', paddingLeft: '26px' }}>
          <span>{renderChoiceGroupOptionProps.text}</span>
          <IconButton
            styles={{
              root: {
                display: disabled ? 'none' : 'inline',
                height: 15,
                marginLeft: 12,
                marginTop: 2,
                position: 'relative',
                top: 2,
                width: 20
              },
              rootHovered: { backgroundColor: 'transparent' }
            }}
            iconProps={{ styles: { root: { fontSize: '13px' } }, iconName: 'CalculatorMultiply' }}
            onClick={() => removeCustomOption(completeOption?.key || '')}
          />
        </div>
      );
    }

    if (defaultRender) return defaultRender(renderChoiceGroupOptionProps);

    return null;
  }

  function onRenderChoiceGroupField(
    choiceGroupOptionProps?: IChoiceGroupOption & IChoiceGroupOptionProps,
    defaultRender?: (props?: IChoiceGroupOption & IChoiceGroupOptionProps) => JSX.Element | null
  ): JSX.Element | null {
    if (defaultRender && choiceGroupOptionProps && choiceGroupOptionProps.id) {
      const { key, text } = choiceGroupOptionProps;
      return (
        <div
          aria-hidden="true"
          onClick={() => {
            // handle on change with this div to make it possible to deselect option
            if (!disabled) {
              onChoiceGroupChange(undefined, { text, key: key || '' });
            }
          }}
        >
          {defaultRender(choiceGroupOptionProps)}
        </div>
      );
    }

    return null;
  }

  function addChoiceGroupProps(option: IOption): IChoiceGroupOption {
    // function to add option styles and render method
    const choiceGroupOptionStyles: IChoiceGroupOptionStyles = {
      root: { marginTop: 0 },
      field: {
        selectors: {
          ':after': {
            borderColor: `rgb(${theme.choiceGroupField.controlCheckedOutline})`
          },
          '&:before': {
            borderColor: `rgb(${theme.choiceGroupField.foreground})`,
            background: disabled
              ? `rgb(${theme.choiceGroupField.disabledBackground})`
              : `rgb(${theme.choiceGroupField.background})`
          },
          '&.is-checked:before': {
            borderColor: `rgb(${theme.choiceGroupField.controlCheckedOutline})`
          },
          '.ms-ChoiceFieldLabel': {
            color: `rgb(${theme.choiceGroupField.foreground})`
          },
          ':hover .childElement': {
            color: `rgb(${theme.choiceGroupField.background})`
          }
        }
      }
    };

    return {
      ...option,
      id: option.key,
      onRenderLabel: onRenderChoiceGroupLabel,
      onRenderField: onRenderChoiceGroupField,
      styles: choiceGroupOptionStyles
    };
  }

  function onRenderTextFieldSuffix(): JSX.Element {
    return <IconButton iconProps={{ iconName: 'Save' }} onClick={onSaveCustomOption} />;
  }

  function getChoiceGroupStyles(): IChoiceGroupProps['styles'] {
    let choiceGroupStyles: IChoiceGroupProps['styles'] = { root: { paddingTop: 3 } };

    if (styles?.choiceGroup) {
      choiceGroupStyles = concatStyleSets(choiceGroupStyles, styles.choiceGroup);
    }

    return choiceGroupStyles;
  }

  function getComboBoxstyles(): IComboBoxProps['styles'] {
    let comboBoxStyles: IComboBoxProps['styles'] = {
      optionsContainerWrapper: { maxHeight: 300 },

      // set min width for Firefox because it cuts off the text
      // TODO - Check if this is fixed with a fluent Update
      optionsContainer: { minWidth: 'fit-content', paddingRight: 10 },
      // TODO - END

      inputDisabled: {
        '::placeholder': {
          color: defaultValue?.length
            ? `rgb(${theme.choiceGroupField.foreground})`
            : `rgb(${theme.choiceGroupField.placeholderForeground})`
        },
        color: `rgb(${theme.choiceGroupField.foreground})`
      },
      root: {
        ':after': {
          border: `1px solid rgb(${theme.choiceGroupField.outline})`,
          borderRadius: theme.choiceGroupField.cornerRadius,
          ':hover': {
            border: `1px solid rgb(${theme.choiceGroupField.hoverOutline})`,
            borderRadius: theme.choiceGroupField.cornerRadius
          }
        }
      }
    };

    if (styles?.comboBox) {
      comboBoxStyles = concatStyleSets(comboBoxStyles, styles.comboBox);
    }

    return comboBoxStyles;
  }

  function CustomOptionTextField(): JSX.Element | null {
    const hasACustomOption = doesCustomOptionExist();

    if (!allowFillIn || hasACustomOption || disabled) {
      return null;
    }

    return (
      <TextField
        componentRef={customOptionTextFieldRef}
        onBlur={onSaveCustomOption}
        onKeyPress={(event) => event.key === 'Enter' && onSaveCustomOption()}
        onRenderSuffix={onRenderTextFieldSuffix}
        placeholder={t('choiceGroupField.input.addNewOption.placeholder')}
        styles={{ suffix: { backgroundColor: 'transparent' } }}
        suffix="reset"
        underlined
      />
    );
  }

  function renderReadOnlyField(optionsString?: string) {
    return (
      <SmallTextField
        required={required}
        labelIconName={labelIconName}
        label={label}
        description={description}
        multiline
        autoAdjustHeight
        defaultValue={optionsString}
        disabled
        styles={{
          fieldGroup: {
            minHeight: 20,
            border: `1px solid rgb(${theme.choiceGroupField.outline})`,
            borderRadius: theme.choiceGroupField.cornerRadius,
            ':after': { borderRadius: theme.choiceGroupField.cornerRadius }
          },
          wrapper: {
            border: `1px solid rgb(${theme.choiceGroupField.outline})`,
            borderRadius: theme.choiceGroupField.cornerRadius
          },
          field: { color: `rgb(${theme.choiceGroupField.foreground})` }
        }}
      />
    );
  }

  if (!initialized || !options || !options.length) {
    return null;
  }

  if (displayAsComboBox) {
    const comboBoxstyles = getComboBoxstyles();

    const selectedKeys = selectedOptions.map((option) => option.key);
    const selectedKeysText = selectedOptions.map((option) => option.text)?.join(', ');

    if (disabled) return renderReadOnlyField(selectedKeysText);

    return (
      <div id={id}>
        <Label
          required={required}
          iconName={labelIconName}
          label={label}
          description={description}
        />
        <ComboBox
          allowFreeform
          title={selectedKeysText}
          autoComplete="on"
          id={`input-${id}`}
          disabled={disabled}
          multiSelect={allowMultipleSelections}
          onChange={onComboBoxChange}
          onRenderOption={onRenderComboBoxOption}
          options={choiceGroupOptions}
          placeholder={placeholder || t('comboBoxField.comboBox.placeholder')}
          selectedKey={selectedKeys}
          styles={comboBoxstyles}
        />
      </div>
    );
  }

  if (allowMultipleSelections) {
    return renderMultipleChoiceGroup();
  }

  // empty string as default to make it possible to deselect if the field is not required
  const selectedKey = selectedOptions?.length ? selectedOptions[0].key : '';

  const choiceGroupStyles = getChoiceGroupStyles();

  return (
    <ChoiceGroupWrapper id={id}>
      <Label required={required} iconName={labelIconName} label={label} description={description} />
      <div
        className="c-choicegroup__wrapper"
        style={{
          background: disabled
            ? `rgb(${theme.choiceGroupField.disabledBackground})`
            : `rgb(${theme.choiceGroupField.background})`
        }}
      >
        <ChoiceGroup
          disabled={disabled}
          options={choiceGroupOptions.map(addChoiceGroupProps)}
          selectedKey={selectedKey}
          styles={choiceGroupStyles}
        />
        <CustomOptionTextField />
      </div>
    </ChoiceGroupWrapper>
  );
}

export default ChoiceGroupField;
