/* eslint-disable react/jsx-props-no-spreading */

import { useLayoutEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';
import {
  DetailsList as FluentDetailsList,
  IDetailsHeaderProps,
  IDetailsRowProps,
  IColumn as IFluentColumn,
  IDetailsListProps as IFluentDetailsListProps,
  IRenderFunction,
  ShimmeredDetailsList
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import ContextualHeaderMenu, { IContextualHeaderMenuProps } from './ContextualHeaderMenu';

const DetailsListContainer = styled.div<{ isLoading?: boolean; maxWidth?: string; id: string }>`
  max-height: fit-content;
  max-width: ${({ maxWidth }) => maxWidth || '100%'};
  width: 100%;
  overflow: hidden;
  background-color: rgb(${({ theme }) => theme.detailsList.background});
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0 0 3.6px rgb(0 0 0 / 13%), 0 0 0.9px rgb(0 0 0 / 10%);

  .details-list-wrapper,
  .ms-Viewport,
  .ms-DetailsList,
  .ms-DetailsList div[role='grid'],
  .ms-DetailsList-contentWrapper {
    height: ${({ isLoading }) => (isLoading ? 'auto' : '100%')};
    display: flex;
    flex-direction: column;
  }

  .ms-DetailsList {
    overflow: hidden;
  }

  .ms-DetailsList-contentWrapper {
    overflow-y: auto;
  }

  .ms-DetailsList-headerWrapper:not(.shimmer-details-list .ms-DetailsList-headerWrapper) {
    z-index: 100;
    border-bottom: 1px solid rgb(${({ theme }) => theme.detailsList.headerDividerLine2});
    box-shadow: 0 0.6px 3.6px rgb(0 0 0 / 13%), 0 0.2px 0.9px rgb(0 0 0 / 10%),
      0 -16px 0 16px rgb(${({ theme }) => theme.detailsList.dividerLine});
  }

  .shimmer-details-list {
    .ms-DetailsList-contentWrapper {
      overflow: hidden;
    }
  }

  .ms-FocusZone {
    padding-top: 0px;
  }

  .ms-DetailsHeader-cell.is-actionable {
    cursor: pointer;
  }
`;

const DetailsListHeaderWrapper = styled.div`
  overflow-x: auto;
  overflow-y: hidden;

  /* hide scroll bar */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }
`;

export interface IFilterItem {
  key: string | number;
  text: string;
  filterType?: string;
}

export interface IFilterProps {
  filterItems?: IFilterItem[];
  singleSelectFilter?: boolean;
  getFilterItems?: () => Promise<IFilterItem[]>;
  filterMultiple?: boolean;
  defaultFilterValues?: IFilterItem['key'][];
  onFilterChange?: (selectedValues: IFilterItem[], value?: IFilterItem) => void;
  onRenderFilterItem?: (item: IFilterItem) => JSX.Element;
}

export interface IColumnData extends IFilterProps {
  sortable?: boolean;
  onSort?: (isSortAscending: boolean, column?: IFluentColumn) => void;
}

export interface IDetailsListColumn extends IFluentColumn, IColumnData {}
export interface IDetailsListProps extends IFluentDetailsListProps {
  containerClassName?: string;
  id?: string;
  loadNext?: () => void;
  maxWidth?: string;
  hasMore?: boolean;
  columns: IDetailsListColumn[];
  isLoading?: boolean;
  onRowClick?: (props: IDetailsRowProps) => void;
}

function DetailsList(props: IDetailsListProps) {
  const {
    columns,
    containerClassName,
    hasMore,
    id,
    isLoading,
    items,
    loadNext,
    maxWidth,
    onRowClick
  } = props;

  const detailsListId = useId('details-list-id');
  const detailsListHeaderId = useId('details-list-header-id');

  const [contextualMenuProps, setContextualMenuProps] = useState<IContextualHeaderMenuProps>();

  const columnsWithData: IFluentColumn[] = useMemo(
    () =>
      columns.map((column) => {
        return {
          ...column,
          data: column.data || {
            onSort: column.onSort,
            singleSelectFilter: column.singleSelectFilter,
            filterItems: column.filterItems,
            getFilterItems: column.getFilterItems,
            onFilterChange: column.onFilterChange,
            filterMultiple: column.filterMultiple,
            defaultFilterValues: column.defaultFilterValues,
            sortable: column.sortable,
            onRenderFilterItem: column.onRenderFilterItem
          }
        };
      }),
    [columns]
  );

  const rerender = useState(0)[1];

  useLayoutEffect(() => {
    const target = document.querySelector(`#${detailsListId} .ms-DetailsList-contentWrapper`);

    if (target) {
      target.id = `${id || ''}-scrollableTarget`;
    }

    const header = document.querySelector(`#${detailsListHeaderId}`);

    // add scroll listener to the details list
    if (target && header) {
      target.addEventListener('scroll', () => {
        // scroll header
        header.scrollLeft = target.scrollLeft;
      });
    }

    rerender((prevState) => prevState + 1);
  }, [id, detailsListId, rerender, detailsListHeaderId]);

  function renderInfiniteScroll() {
    const target = document.querySelector(`#${detailsListId} .ms-DetailsList-contentWrapper`);

    if (!target || !loadNext) return null;

    return ReactDOM.createPortal(
      <InfiniteScroll
        dataLength={items?.length || 0}
        next={loadNext}
        hasMore={!!hasMore}
        scrollableTarget={`${id || ''}-scrollableTarget`}
        loader={
          <div className="shimmer-details-list">
            <ShimmeredDetailsList
              enableShimmer
              shimmerLines={4}
              checkboxVisibility={2}
              columns={columns}
              items={[]}
              isHeaderVisible={false}
            />
          </div>
        }
      >
        <div />
      </InfiniteScroll>,
      target
    );
  }

  function onRenderDetailsHeader(
    props?: IDetailsHeaderProps,
    defaultRender?: IRenderFunction<IDetailsHeaderProps>
  ) {
    return (
      <DetailsListHeaderWrapper id={detailsListHeaderId}>
        {defaultRender?.(props)}
      </DetailsListHeaderWrapper>
    );
  }

  function onRenderRow(
    rowProps?: IDetailsRowProps,
    defaultRender?: IRenderFunction<IDetailsRowProps>
  ) {
    if (onRowClick) {
      return (
        <div onClick={() => onRowClick(rowProps)} style={{ cursor: 'pointer' }} aria-hidden="true">
          {props.onRenderRow
            ? props.onRenderRow(rowProps, defaultRender)
            : defaultRender?.(rowProps)}
        </div>
      );
    }

    if (props.onRenderRow) {
      return props.onRenderRow(rowProps, defaultRender);
    }

    return defaultRender?.(rowProps);
  }

  function onColumnHeaderClick(event?: React.MouseEvent<HTMLElement>, column?: IFluentColumn) {
    if (!column?.columnActionsMode) {
      return;
    }

    if (contextualMenuProps && event?.currentTarget === contextualMenuProps.target) {
      setContextualMenuProps(undefined);

      return;
    }

    const { data }: { data?: IColumnData } = column || {};

    setContextualMenuProps({
      isSortedDescending: column?.isSortedDescending,
      isSortedAscending: column?.isSorted && !column?.isSortedDescending,
      onSortAscending: () => data?.onSort?.(true, column),
      onSortDescending: () => data?.onSort?.(false, column),
      getFilterItems: data?.getFilterItems,
      onRenderFilterItem: data?.onRenderFilterItem,
      singleSelectFilter: data?.singleSelectFilter,
      defaultFilterValues: data?.defaultFilterValues,
      onFilterChange: data?.onFilterChange,
      target: event?.currentTarget,
      filterItems: data?.filterItems,
      items: [],
      sortable: data?.sortable,
      onDismiss: () => {
        setContextualMenuProps(undefined);
      }
    });
  }

  return (
    <DetailsListContainer
      isLoading={isLoading}
      id={detailsListId}
      className={containerClassName}
      maxWidth={maxWidth}
    >
      <div className="details-list-wrapper">
        {contextualMenuProps && <ContextualHeaderMenu {...contextualMenuProps} />}
        <FluentDetailsList
          setKey="items"
          onRenderDetailsHeader={onRenderDetailsHeader}
          onColumnHeaderClick={onColumnHeaderClick}
          onRenderRow={onRenderRow}
          {...props}
          items={isLoading ? [] : props.items}
          columns={columnsWithData}
          styles={{
            ...props.styles,
            ...(isLoading && {
              contentWrapper: {
                display: 'none !important',
                height: '0px !important'
              }
            })
          }}
        />
        {renderInfiniteScroll()}
      </div>
      {isLoading && (
        <ShimmeredDetailsList
          enableShimmer
          shimmerLines={4}
          checkboxVisibility={2}
          columns={columns}
          items={[]}
          isHeaderVisible={false}
        />
      )}
    </DetailsListContainer>
  );
}

export default DetailsList;
