/* eslint-disable no-plusplus */

export interface IMasonryProps {
  columns?: number;
  gap?: number;
  children: JSX.Element[];
}

function Masonry({ columns = 1, gap = 25, children }: IMasonryProps): JSX.Element {
  const columnWrapper: { [key: string]: JSX.Element[] } = {};
  const result = [];

  // create columns
  for (let i = 0; i < columns; i++) {
    columnWrapper[`column${i}`] = [];
  }

  // divide children into columns
  for (let i = 0; i < children.length; i++) {
    const columnIndex = i % columns;
    columnWrapper[`column${columnIndex}`].push(
      <div key={children[i].key} style={{ marginBottom: `${gap}px` }}>
        {children[i]}
      </div>
    );
  }

  // wrap children in each column with a div
  for (let i = 0; i < columns; i++) {
    result.push(
      <div
        key={columnWrapper[`column${i}`][0]?.key}
        style={{
          marginLeft: `${i > 0 ? gap : 0}px`,
          flex: 1,
          width: `${100 / columns}%`
        }}
      >
        {columnWrapper[`column${i}`]}
      </div>
    );
  }

  return <div style={{ display: 'flex' }}>{result}</div>;
}

export default Masonry;
