// eslint-disable-next-line import/no-named-as-default
import { IconFolder } from 'layouts/Icons';
import PropTypes from 'prop-types';
import { DirectionalHint, TooltipHost } from '@fluentui/react';
import FolderStyled from './Folder.styled';

function Folder({ item, onFolderClick }) {
  const { id, name, number } = item;

  return (
    <FolderStyled key={id} onClick={() => onFolderClick(id)}>
      <div className="icon">
        <IconFolder />
        <span className="number-instances ms-font-m">{number}</span>
      </div>
      <TooltipHost content={name} directionalHint={DirectionalHint.bottomCenter}>
        <span className="name block ms-font-m">{name}</span>
      </TooltipHost>
    </FolderStyled>
  );
}

Folder.propTypes = {
  item: PropTypes.shape({ id: PropTypes.string, name: PropTypes.string, number: PropTypes.number })
    .isRequired,
  onFolderClick: PropTypes.func.isRequired
};

export default Folder;
