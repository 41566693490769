import { LongTextField } from 'components/inputs';
import { t } from 'i18next';
import { IFieldProps, RouteFieldType } from 'types';
import { getFluentIconNameByFieldType } from 'utils/helpers';
import { Dropdown, IDropdownOption, Icon, TextField, Toggle } from '@fluentui/react';
import getFieldTypes from '../utils';
import AIFieldSettings from './AIFieldSettings';
import ChoiceSettings from './ChoiceSettings';
import ConfirmationSettings from './ConfirmationSettings';
import DateTimeSettings from './DateTimeSettings';
import ExternalServiceSettings from './ExtrenalServiceSettings';
import LongTextSettings from './LongTextSettings';
import LookupSettings from './LookupSettings';
import NumberSettings from './NumberSettings';
import PersonSettings from './PersonSettings';
import RatingSettings from './RatingSettings';
import SmallTextSettings from './SmallTextSettings';

export interface IFormProps {
  field: IFieldProps;
  setField: React.Dispatch<React.SetStateAction<IFieldProps>>;
  disabled?: boolean;
  language?: string;
}

export default function FieldForm({ field, setField, disabled, language }: IFormProps) {
  // update text or language field into the translation Field
  function updateFieldTranslation(prop: 'allNames' | 'allDescriptions', newValue?: string | null) {
    setField((prevState) => {
      if (Array.isArray(prevState[prop]) && language) {
        const translations = prevState[prop];

        // Find existing translation
        const translation = translations?.find((name) => {
          return name.language === language;
        });

        if (!translations) return prevState;

        if (translation) {
          // Update existing translation
          translation.text = newValue || '';
        } else {
          // Add new translation
          translations.push({ language, text: newValue || '' });
        }

        return { ...prevState, [prop]: translations };
      }

      return prevState;
    });
  }

  function updateFieldName(_: unknown, newValue: string | undefined) {
    const name = newValue;

    if (field.allNames && language) {
      updateFieldTranslation('allNames', name);
    } else {
      setField((prevState) => ({ ...prevState, name }));
    }
  }

  function updateFieldDescription(newValue: string | null = '') {
    const description = newValue || '';

    if (field.allDescriptions && language) {
      updateFieldTranslation('allDescriptions', description);
    } else {
      setField((prevState) => ({ ...prevState, description }));
    }
  }

  function getDefaultFieldValuesByType(fieldType: RouteFieldType) {
    if (fieldType === RouteFieldType.Number) {
      return { numDecimals: 0 };
    }

    if (fieldType === RouteFieldType.SmallText) {
      return { maxLength: 150 };
    }

    if (fieldType === RouteFieldType.LongText) {
      return { appendChanges: false };
    }

    if (fieldType === RouteFieldType.DateTime) {
      return { dateFormat: 1 };
    }

    if (fieldType === RouteFieldType.Rating) {
      return { numStars: 4 };
    }

    if (fieldType === RouteFieldType.Person) {
      return { searchAAD: false };
    }

    if (fieldType === RouteFieldType.Choice) {
      return { choices: [], choiceFormat: 1, allowFillIn: false };
    }

    if (fieldType === RouteFieldType.Boolean) {
      return { confirmationFormat: 1, textOk: 'OK', textNOk: 'NOK' };
    }

    if (fieldType === RouteFieldType.ExternalData) {
      return { externalServiceId: '', allowScan: false };
    }

    if (fieldType === RouteFieldType.Lookup) {
      return {
        lookupDefinitionId: '',
        lookupDisplayFields: [
          {
            fieldId: null,
            fieldName: 'intId',
            showInList: true,
            showInResult: true
          },
          {
            fieldId: null,
            fieldName: 'name',
            showInList: true,
            showInResult: true
          },
          {
            fieldId: null,
            fieldName: 'creator',
            showInList: true,
            showInResult: false
          },
          {
            fieldId: null,
            fieldName: 'creationDate',
            showInList: true,
            showInResult: false
          }
        ],
        lookupOnlyOutcome: 0,
        lookupType: 1
      };
    }

    if (fieldType === RouteFieldType.AI) {
      return {
        aiTemperature: 0.5,
        maxTokens: 3000,
        autoPrompt: false,
        allowRefining: false,
        prompt: undefined,
        requestType: 2,
        description: `<p>${t('createField.dialog.aiField.defaultValue.notice')}</p>`,
        descriptionPlacement: 2,
        additionalPromptFieldIds: []
      };
    }

    return {};
  }
  function updateFieldType(
    _: unknown,
    option?: IDropdownOption<{ key: RouteFieldType; text: string }> | undefined
  ) {
    const defaultFieldValues = getDefaultFieldValuesByType(option?.key as RouteFieldType);

    setField((prevState) => {
      return {
        name: prevState.name,
        description: prevState.description,
        allNames: prevState.allNames,
        allDescriptions: prevState.allDescriptions,
        descriptionPlacement: prevState.descriptionPlacement,
        fieldType: option?.key as RouteFieldType,
        ...defaultFieldValues
      };
    });
  }

  function handleDescriptionPlacement(checked: boolean | undefined) {
    setField((prevState) => ({ ...prevState, descriptionPlacement: checked ? 2 : 1 }));
  }

  function getDefaultFieldNameValue() {
    if (!language) {
      return field.name;
    }

    const languageName = field.allNames?.find(
      (translatedName) => translatedName.language === language
    );

    return languageName?.text || '';
  }

  function getDefaultFieldDescriptionValue() {
    if (!language) {
      return field.description;
    }

    const languageName = field.allDescriptions?.find(
      (translatedName) => translatedName.language === language
    );

    return languageName?.text || '';
  }

  function renderOption(option?: { key: string | number; text: string }) {
    if (!option) return null;

    return (
      <div key={option.key} style={{ display: 'flex' }}>
        <Icon
          iconName={getFluentIconNameByFieldType({ fieldType: option.key as RouteFieldType })}
          styles={{ root: { marginRight: 9 } }}
        />
        <div>{option.text}</div>
      </div>
    );
  }

  return (
    <div style={{ flexBasis: '45%' }}>
      <TextField
        autoFocus
        key={disabled && language ? `translated-ok-${language}` : 'ok'}
        label={t('createField.dialog.fieldname.label')}
        defaultValue={getDefaultFieldNameValue()}
        onChange={updateFieldName}
        required
        disabled={disabled && !language}
      />
      <LongTextField
        key={
          disabled && language
            ? `translated-description-${language}-${field.fieldType}`
            : field.fieldType
        }
        label={t('createField.dialog.description.label')}
        defaultValue={getDefaultFieldDescriptionValue()}
        displayCmdBar
        onChange={updateFieldDescription}
        disabled={disabled && !language}
      />
      <div style={{ display: 'flex', gap: '20px' }}>
        <div style={{ width: '50%', marginRight: '10px' }}>
          <Dropdown
            onRenderOption={renderOption}
            placeholder={t('createField.dialog.dropdown.placeholder')}
            label={t('createField.dialog.dropdown.label')}
            options={getFieldTypes()}
            onChange={updateFieldType}
            disabled={disabled || !!field.id}
            defaultSelectedKey={field.fieldType}
            required
          />
        </div>

        <Toggle
          label={t('createField.dialog.descriptionPlacement')}
          checked={field.descriptionPlacement === 2}
          onText={t('globals.yes')}
          offText={t('globals.no')}
          onChange={(_, checked) => {
            handleDescriptionPlacement(checked);
          }}
          styles={{ label: { marginBottom: 5 } }}
          disabled={disabled}
        />
      </div>
      {field.fieldType === RouteFieldType.Number && (
        <NumberSettings field={field} setField={setField} disabled={disabled} language={language} />
      )}
      {field.fieldType === RouteFieldType.SmallText && (
        <SmallTextSettings
          field={field}
          setField={setField}
          disabled={disabled}
          language={language}
        />
      )}
      {field.fieldType === RouteFieldType.LongText && (
        <LongTextSettings field={field} setField={setField} disabled={disabled} />
      )}
      {field.fieldType === RouteFieldType.Boolean && (
        <ConfirmationSettings
          field={field}
          setField={setField}
          disabled={disabled}
          language={language}
        />
      )}
      {field.fieldType === RouteFieldType.DateTime && (
        <DateTimeSettings field={field} setField={setField} disabled={disabled} />
      )}
      {field.fieldType === RouteFieldType.Choice && (
        <ChoiceSettings field={field} setField={setField} disabled={disabled} language={language} />
      )}
      {field.fieldType === RouteFieldType.Person && (
        <PersonSettings field={field} setField={setField} disabled={disabled} />
      )}
      {field.fieldType === RouteFieldType.Rating && (
        <RatingSettings field={field} setField={setField} disabled={disabled} />
      )}
      {field.fieldType === RouteFieldType.ExternalData && (
        <ExternalServiceSettings field={field} setField={setField} disabled={disabled} />
      )}
      {field.fieldType === RouteFieldType.Lookup && (
        <LookupSettings field={field} setField={setField} disabled={disabled} language={language} />
      )}
      {field.fieldType === RouteFieldType.AI && (
        <AIFieldSettings
          field={field}
          setField={setField}
          disabled={disabled}
          language={language}
        />
      )}
    </div>
  );
}
