import { EmptyListPlaceHolder, LoadingSpinner, MainCommandBar } from 'components';
import CombinedPicker from 'components/inputs/CombinedPickerJS/CombinedPickerJS';
import ListCustom from 'components/lists/ListCustom/ListCustom';
import DialogCustom from 'components/surfaces/Dialog/DialogCustom';
import { AppContext } from 'features/App';
import NoAccessContainer from 'pages/NoAccess';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import fetchRequest, { apiErrorHandler } from 'services/api';
import styled from 'styled-components';
import breakpoints from 'utils/breakpoints';
import { checkScreenWidth } from 'utils/helpers';
import { IconButton, Selection, SelectionMode, TooltipHost } from '@fluentui/react';
import ReplaceUserInTemplateDialog from '../components/ReplaceUserInTemplateDialog';

const TitleColumnStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

const EmployeesInProcessesAdminStyled = styled.div`
  @media (max-width: ${breakpoints.extraSmallMax}px) {
    padding-bottom: 50px;
  }
`;

const ProcessesContainer = styled.div`
  .ms-DetailsRow-check {
    height: 100%;
  }

  .replaceButton {
    visibility: ${checkScreenWidth(['extraSmall']) ? 'visible' : 'hidden'};
    background-color: transparent;
  }

  padding-bottom: 0.5rem;
`;

const EmployeePickerStyled = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div:first-of-type {
    margin: auto 10px !important;
  }
`;

const ProcessRowStyled = styled.div`
  .ms-DetailsRow .ms-DetailsRow-fields {
    width: 100%;
  }

  &:hover {
    .replaceButton {
      visibility: visible;
    }
  }
`;

const TemplateRowStyled = styled.div`
  .ms-GroupHeader-check {
    opacity: ${(props) => (props.$isSelected ? '1' : '0')};
  }
`;

function EmployeesInProcessesAdministration() {
  // global app state
  const { globalAppState } = useContext(AppContext);
  const { currentUser, searchTerm } = globalAppState;

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedStepId, setSelectedStepId] = useState(null);
  const [selection, setSelection] = useState(null);

  const [templates, setTemplates] = useState(null);
  const [steps, setSteps] = useState(null);

  const [showReplaceDialog, setShowReplaceDialog] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  const [notificationSubText, setNotificationSubText] = useState('');
  const [notificationTitle, setNotificationTitle] = useState('');

  const [groups, setGroups] = useState(null);

  const columns = [
    {
      key: 'name',
      name: t('employeesInProcessesAdmin.column.name'),
      fieldName: 'name',
      className: 'textColumn',
      minWidth: checkScreenWidth(['extraSmall']) ? window.innerWidth - 110 : 200,
      maxWidth: checkScreenWidth(['extraSmall']) ? window.innerWidth - 110 : 200,
      isResizable: true,
      isMultiline: true,
      columnActionsMode: 0,
      onRender: (row) => (
        <TitleColumnStyled>
          <div>{row.name}</div>
          <TooltipHost
            content={t('employeesInProcessesAdmin.column.replaceUser')}
            calloutProps={{ gapSpace: 5, target: `#btn-delete-${row.key}` }}
          >
            <IconButton
              id={`btn-delete-${row.key}`}
              className="replaceButton"
              iconProps={{ iconName: 'Transition' }}
              onClick={() => onReplaceUser(row)}
              styles={{
                root: {
                  height: '20px',
                  marginLeft: '30px'
                },
                icon: {
                  fontSize: '14px',
                  fontWeight: '600'
                }
              }}
            />
          </TooltipHost>
        </TitleColumnStyled>
      )
    }
  ];

  const loadItems = useCallback(async () => {
    setIsLoading(true);

    const url = `Route/Definitions/ForUser?userId=${
      selectedEmployee ? selectedEmployee.userId : null
    }`;
    const templates = await fetchRequest({ url }).catch(apiErrorHandler);

    if (templates?.length) {
      let filteredTemplates = [];

      if (searchTerm) {
        templates.map((tmpl) => {
          if (tmpl.name.trim().toLowerCase().includes(searchTerm.trim().toLowerCase())) {
            filteredTemplates.push(tmpl);
          } else if (tmpl.steps && tmpl.steps.length > 0) {
            tmpl.steps.map((step) => {
              if (step.name.trim().toLowerCase().includes(searchTerm.trim().toLowerCase())) {
                filteredTemplates.push(tmpl);
              }

              return null;
            });
          }

          return null;
        });
      } else {
        filteredTemplates = [...templates];
      }

      const sortedTemplates = filteredTemplates
        .slice(0)
        .sort((a, b) => (a && b && a.name > b.name ? 1 : -1));
      setTemplates(sortedTemplates);

      let startIndex = 0;
      const groups = [];
      const steps = [];

      sortedTemplates.map((template) => {
        const count = template.steps.filter((x) => x.isUserInvolved).length;
        const group = { key: template.id, name: template.name, startIndex, count };

        startIndex += count;

        template.steps.map((step) => {
          if (step.isUserInvolved) {
            steps.push({ key: step.id, name: step.name });
          }

          return step;
        });

        return groups.push(group);
      });

      setSteps(steps);
      setGroups(groups);
      setIsLoading(false);
    } else {
      setTemplates(null);
      setIsLoading(false);
    }
  }, [selectedEmployee, searchTerm]);

  function handleSelectedEmployee(value) {
    setSelectedEmployee(value[0]);
    setGroups(null);
  }

  useEffect(() => {
    if (selectedEmployee && selectedEmployee.userId) {
      loadItems();
    }
  }, [selectedEmployee, loadItems, searchTerm]);

  const onShowNotification = useCallback(
    (title, message) => {
      const defaultTitle = t('employeesInProcessesAdmin.notificationDialog.title.forbidden');

      setNotificationTitle(title || notificationTitle || defaultTitle);

      if (message && message !== '') {
        setNotificationSubText(message);
      }

      setShowNotification(true);
    },
    [notificationTitle, t]
  );

  async function replaceUserInSteps(stepIds, oldUserId, newUserId) {
    const url = `Route/Definitions/ChangeUser`;

    const body = JSON.stringify({
      stepIds,
      oldUserId,
      newUserId
    });

    await fetchRequest({ url, method: 'PUT', body, ignoreAlert: true }).catch(
      async (errResponse) => {
        const err = await errResponse;

        if (
          err &&
          !(err instanceof SyntaxError) &&
          (err.indexOf('400') !== -1 || err.indexOf('401') !== -1 || err.indexOf('403') !== -1)
        ) {
          const error = JSON.parse(err);

          onShowNotification(null, error.message);
        }

        setIsLoading(false);
      }
    );
  }

  function onNotificationDialogCancel() {
    setShowNotification(false);
  }

  function onReplaceUser(step) {
    setSelectedStepId(step.key);
    setShowReplaceDialog(true);
  }

  function onReplaceUserInSelected() {
    setSelectedStepId(null);
    setShowReplaceDialog(true);
  }

  const changedSelection = new Selection({
    onSelectionChanged: () => {
      const selectedSteps = [];

      changedSelection.getSelection().map((sel) => selectedSteps.push(sel.key));

      setSelection(selectedSteps);
    }
  });

  function onReplaceCancel() {
    setShowReplaceDialog(false);
  }

  async function onReplaceConfirm(newUserId, inMupltipleSteps) {
    setShowReplaceDialog(false);
    setIsLoading(true);

    if (inMupltipleSteps && selection?.length) {
      await replaceUserInSteps(selection, selectedEmployee.userId, newUserId);
    } else {
      await replaceUserInSteps([selectedStepId], selectedEmployee.userId, newUserId);
    }

    loadItems();
  }

  if (!currentUser?.isAdmin) {
    return <NoAccessContainer />;
  }

  function getPlaceholderText() {
    if (searchTerm) {
      if (selectedEmployee) {
        return t('employeesInProcessesAdmin.emptySearchResults');
      }

      return t('employeesInProcessesAdmin.searchNoEmployee');
    }

    if (selectedEmployee) {
      t('employeesInProcessesAdmin.placeHolder');
    }

    return t('employeesInProcessesAdmin.placeHolderNoEmployee');
  }

  function getProcessList() {
    if (!isLoading && selectedEmployee && templates?.length && groups?.length) {
      return (
        <ProcessesContainer className="details-list-wrapper">
          <div style={{ maxWidth: '600px' }}>
            <ListCustom
              items={steps || []}
              groups={groups}
              columns={columns}
              selectionMode={SelectionMode.multiple}
              selection={changedSelection}
              selectionPreservedOnEmptyClick
              enterModalSelectionOnTouch
              groupProps={{
                isAllGroupsCollapsed: true,
                onRenderHeader: (props, defaultRender) => (
                  // eslint-disable-next-line react/prop-types
                  <TemplateRowStyled $isSelected={props.selected}>
                    {defaultRender({ ...props })}
                  </TemplateRowStyled>
                )
              }}
              onRenderRow={(props, defaultRender) => (
                <ProcessRowStyled props={props}>{defaultRender({ ...props })}</ProcessRowStyled>
              )}
            />
          </div>
        </ProcessesContainer>
      );
    }

    return (
      <EmptyListPlaceHolder
        hidden={!!groups?.length}
        noItemsText={getPlaceholderText()}
        listIconName={searchTerm ? 'SearchIssue' : 'People'}
      />
    );
  }

  if (isLoading) {
    return <LoadingSpinner label={t('loading.templates.text')} />;
  }

  return (
    <>
      <MainCommandBar
        items={[
          {
            key: 'employeePicker',
            onRender: () => {
              return (
                <EmployeePickerStyled>
                  <CombinedPicker
                    searchUser
                    focusPeoplePicker
                    selectedItems={selectedEmployee}
                    onChange={handleSelectedEmployee}
                    type={5}
                    placeholder={t('employeesInProcessesAdmin.selectEmployee')}
                  />
                </EmployeePickerStyled>
              );
            }
          },
          {
            key: 'replaceUserInSelected',
            text: t('employeesInProcessesAdmin.replaceUserInSelected'),
            disabled: !selectedEmployee || !selection?.length,
            iconProps: {
              iconName: 'Transition'
            },
            onClick: () => onReplaceUserInSelected()
          }
        ]}
      />
      <EmployeesInProcessesAdminStyled id="EmpProcessList">
        {getProcessList()}
        {showReplaceDialog ? (
          <ReplaceUserInTemplateDialog
            hidden={!showReplaceDialog}
            onCancel={onReplaceCancel}
            onConfirm={onReplaceConfirm}
            stepId={selectedStepId}
          />
        ) : null}
        {showNotification ? (
          <DialogCustom
            defaultButtonFunc={onNotificationDialogCancel}
            defaultButtonLabel={t(
              'employeesInProcessesAdmin.notificationDialog.defaultButtonLabel'
            )}
            subText={notificationSubText}
            title={notificationTitle}
            onDismiss={onNotificationDialogCancel}
            hidden={false}
          />
        ) : null}
      </EmployeesInProcessesAdminStyled>
    </>
  );
}

export default EmployeesInProcessesAdministration;
