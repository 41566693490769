import { ReactNode } from 'react';
import {
  DefaultButton,
  DialogFooter,
  DialogType,
  Dialog as FluentDialog,
  IButtonProps,
  IDialogContentProps,
  IDialogStyles,
  IDialogProps as IFluentDialogProps,
  PrimaryButton,
  concatStyleSets
} from '@fluentui/react';
import { useTheme } from 'styled-components';

export interface IDialogProps extends IFluentDialogProps {
  secondaryButtonProps?: IButtonProps;
  primaryButtonProps?: IButtonProps;
  defaultButtonProps?: IButtonProps;
  /**
   * Content to render within the Dialog
   */
  content?: ReactNode;
  children?: ReactNode;
  /**
   * Whether the dialog is hidden.
   * @defaultvalue true
   */
  hidden?: IFluentDialogProps['hidden'];
  /**
   * Props to be passed through to Modal
   */
  modalProps?: IFluentDialogProps['modalProps'];
  /**
   * A callback function for when the Dialog is dismissed from the close button or light dismiss.
   * Can also be specified separately in content and modal.
   */
  onDismiss?: IFluentDialogProps['onDismiss'];
  /**
   * The subtext to display in the dialog
   */
  subText?: IDialogContentProps['subText'];
  /**
   * The title text to display at the top of the dialog.
   */
  title?: IDialogContentProps['title'];
  /**
   * Call to provide customized styling that will layer on top of the variant rules
   */
  styles?: IDialogStyles;
  onRenderFooter?: (props: IDialogProps, defaultRender: () => ReactNode) => ReactNode;
}

function Dialog(props: IDialogProps) {
  const {
    content,
    secondaryButtonProps,
    hidden = true,
    modalProps,
    onDismiss,
    primaryButtonProps,
    defaultButtonProps,
    styles,
    subText,
    onRenderFooter,
    title,
    ...rest
  } = props;

  const theme = useTheme();

  function renderFooter() {
    const defaultRender = () => (
      <DialogFooter>
        {primaryButtonProps ? (
          <PrimaryButton {...primaryButtonProps} styles={primaryButtonStyles} />
        ) : null}
        {secondaryButtonProps ? (
          <DefaultButton {...secondaryButtonProps} styles={secondaryButtonStyles} />
        ) : null}
        {defaultButtonProps ? (
          <DefaultButton {...defaultButtonProps} styles={defaultButtonStyles} />
        ) : null}
      </DialogFooter>
    );

    if (onRenderFooter) {
      return onRenderFooter(props, defaultRender);
    }

    return defaultRender();
  }

  const buttonStyles: IButtonProps['styles'] = { root: { borderRadius: '3px' } };

  let completeStyles: IDialogStyles = { root: {}, main: { borderRadius: 8 } };

  let primaryButtonStyles = { ...buttonStyles };

  if (primaryButtonProps?.styles) {
    primaryButtonStyles = concatStyleSets(buttonStyles, primaryButtonProps.styles);
  }

  let secondaryButtonStyles = { ...buttonStyles };

  if (secondaryButtonProps?.styles) {
    secondaryButtonStyles = concatStyleSets(buttonStyles, secondaryButtonProps.styles);
  }

  let defaultButtonStyles = { ...buttonStyles };

  if (defaultButtonProps?.styles) {
    defaultButtonStyles = concatStyleSets(buttonStyles, defaultButtonProps.styles);
  }

  if (styles) {
    completeStyles = concatStyleSets(styles, completeStyles);
  }

  return (
    <FluentDialog
      {...rest}
      hidden={hidden}
      onDismiss={onDismiss}
      dialogContentProps={{
        type: DialogType.normal,
        title,
        subText,
        styles: {
          subText: { marginBottom: 10, marginTop: 5 },
          title: {
            backgroundColor: `rgb(${theme.dialog.headerBackground}) !important`,
            color: `rgb(${theme.dialog.headerForeground}) !important`
          }
        },
        ...props.dialogContentProps
      }}
      modalProps={modalProps}
      styles={completeStyles}
    >
      {content}
      {renderFooter()}
    </FluentDialog>
  );
}

export default Dialog;
