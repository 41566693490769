import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { ITeamProps } from 'types';
import breakpoints from 'utils/breakpoints';
import { Pivot, PivotItem } from '@fluentui/react';
import { checkScreenWidth, UUID_NULL } from 'utils/helpers';

const NewEditTeamHeader = styled.div`
  background-color: rgb(${(props) => props.theme.panelHeader.background});
  color: rgb(${(props) => props.theme.panelHeader.foreground});
  height: 100px;
  padding-bottom: 10px;

  .new-edit-team-title {
    font-size: 28px;
    padding-left: 16px;
  }

  .new-edit-team-subtitle {
    font-size: 14px;
    padding-left: 16px;
  }

  @media (max-width: ${breakpoints.smallMax}px) {
    height: auto;

    .new-edit-team-title {
      font-size: 18px;
    }

    .new-edit-team-subtitle {
      font-size: 12px;
    }
  }
`;

export default function TeamPanelNewEditHeader({
  team,
  tab,
  onTabChange
}: {
  team: ITeamProps;
  tab: string;
  onTabChange: (tab: string) => void;
}) {
  const { t } = useTranslation();

  // TODO: move these into a component
  const theme = useTheme();

  const linkStyles = {
    ':hover': { backgroundColor: 'transparent' },
    ':active': { backgroundColor: 'transparent' },
    ':hover::before': { backgroundColor: `rgb(${theme.panelHeader.foreground})` },
    ':before': { marginBottom: 5 }
  };

  const pivotStyles = {
    linkContent: {},
    count: {},
    linkInMenu: { '*': { color: 'black !important' } },
    overflowMenuButton: {
      color: `rgb(${theme.panelHeader.foreground})`,
      ':active': { color: `rgb(${theme.panelHeader.foreground})` },
      ':hover': { color: `rgb(${theme.panelHeader.foreground})` }
    },
    root: {
      backgroundColor: 'transparent',
      borderBottom: '3px solid transparent',
      color: `rgb(${theme.panelHeader.foreground})`,
      paddingLeft: '10px'
    },
    text: { marginLeft: '0px', color: `rgb(${theme.panelHeader.foreground})` },
    icon: {
      paddingLeft: checkScreenWidth(['extraSmall']) ? undefined : '5px',
      color: `rgb(${theme.panelHeader.foreground})`
    },
    link: { selectors: linkStyles },
    linkIsSelected: {
      selectors: {
        ...linkStyles,
        ':before': { backgroundColor: `rgb(${theme.panelHeader.foreground})`, marginBottom: 5 }
      }
    }
  };

  return (
    <NewEditTeamHeader>
      <div className="new-edit-team-title">
        {team?.id && team?.id !== UUID_NULL
          ? t('teamPanelNewEdit.title.editTeam')
          : t('teamPanelNewEdit.title.newTeam')}
      </div>
      <div className="new-edit-team-subtitle">
        {team?.id && team?.id !== UUID_NULL
          ? `${team.displayTitle}`
          : t('teamPanelNewEdit.subTitle.create')}
      </div>

      <Pivot
        linkSize="normal"
        overflowBehavior="menu"
        styles={pivotStyles}
        selectedKey={tab}
        onLinkClick={(item) => onTabChange(item.props.itemKey)}
      >
        <PivotItem itemKey="detail" headerText={t('teamPanelNewEdit.pivotItem.detail')} />
        {team?.id && team.id !== UUID_NULL ? (
          <PivotItem itemKey="activity" headerText={t('teamPanelNewEdit.pivotItem.activity')} />
        ) : null}
      </Pivot>
    </NewEditTeamHeader>
  );
}
