import { EmptyListPlaceHolder, LoadingSpinner, MainCommandBar } from 'components';
import CombinedPicker from 'components/inputs/CombinedPickerJS/CombinedPickerJS';
import DialogCustom from 'components/surfaces/Dialog/DialogCustom';
import { AppContext } from 'features/App';
import NoAccessContainer from 'pages/NoAccess';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import fetchRequest, { apiErrorHandler } from 'services/api';
import styled from 'styled-components';
import breakpoints from 'utils/breakpoints';
import { checkScreenWidth } from 'utils/helpers';
import {
  ConstrainMode,
  DetailsList,
  DetailsListLayoutMode,
  IconButton,
  Selection,
  SelectionMode,
  TooltipHost
} from '@fluentui/react';
import ReplaceUserInTemplateDialog from '../components/ReplaceUserInTemplateDialog';

const TitleColumnStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

const EmployeesInProcessesAdminStyled = styled.div`
  @media (max-width: ${breakpoints.extraSmallMax}px) {
    padding-bottom: 50px;
  }
`;

const ProcessesContainer = styled.div`
  .ms-DetailsRow-check {
    height: 100%;
  }

  .replaceButton {
    visibility: ${checkScreenWidth(['extraSmall']) ? 'visible' : 'hidden'};
    background-color: transparent;
  }

  padding-bottom: 0.5rem;
`;

const EmployeePickerStyled = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div:first-of-type {
    margin: auto 10px !important;
  }
`;

const ProcessRowStyled = styled.div`
  .ms-DetailsRow .ms-DetailsRow-fields {
    width: 100%;
  }

  &:hover {
    .replaceButton {
      visibility: visible;
    }
  }
`;

function EmployeesInProcessesInstancesAdministration() {
  // global app state
  const { globalAppState } = useContext(AppContext);
  const { currentUser, searchTerm } = globalAppState;

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedInstanceId, setSelectedInstanceId] = useState(null);
  const [selection, setSelection] = useState(null);

  const [instances, setInstances] = useState(null);

  const [showReplaceDialog, setShowReplaceDialog] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  const [notificationSubText, setNotificationSubText] = useState('');
  const [notificationTitle, setNotificationTitle] = useState('');

  const columns = [
    {
      key: 'name',
      name: t('employeesInProcessInstancesAdmin.column.name'),
      fieldName: 'name',
      className: 'textColumn',
      minWidth: checkScreenWidth(['extraSmall']) ? window.innerWidth - 110 : 400,
      maxWidth: checkScreenWidth(['extraSmall']) ? window.innerWidth - 110 : 400,
      isResizable: true,
      isMultiline: true,
      columnActionsMode: 0,
      onRender: (row) => (
        <TitleColumnStyled>
          <div>{`${row.name} (${row.numberOfInstances})`}</div>
          <TooltipHost
            content={t('employeesInProcessesAdmin.column.replaceUser')}
            calloutProps={{ gapSpace: 5, target: `#btn-delete-${row.key}` }}
          >
            <IconButton
              id={`btn-delete-${row.key}`}
              className="replaceButton"
              iconProps={{ iconName: 'Transition' }}
              onClick={() => onReplaceUser(row)}
              styles={{
                root: {
                  height: '20px',
                  marginLeft: '30px'
                },
                icon: {
                  fontSize: '14px',
                  fontWeight: '600'
                }
              }}
            />
          </TooltipHost>
        </TitleColumnStyled>
      )
    }
  ];

  const loadItems = useCallback(() => {
    setIsLoading(true);

    const url = `Route/Instances/ForUser?userId=${
      selectedEmployee ? selectedEmployee.userId : null
    }`;

    fetchRequest({ url })
      .then((fetchedInstances) => {
        if (searchTerm) {
          const filteredInstances = fetchedInstances.filter((instance) =>
            instance.name.toLowerCase().includes(searchTerm.toLowerCase())
          );
          setInstances(filteredInstances);
        } else {
          setInstances(fetchedInstances);
        }

        setIsLoading(false);
      })
      .catch(apiErrorHandler);
  }, [selectedEmployee, searchTerm]);

  function handleSelectedEmployee(value) {
    setSelectedEmployee(value[0]);
  }

  useEffect(() => {
    if (selectedEmployee && selectedEmployee.userId) {
      loadItems(searchTerm);
    }
  }, [selectedEmployee, loadItems, searchTerm]);

  const onShowNotification = useCallback(
    (title, message) => {
      const defaultTitle = t('employeesInProcessesAdmin.notificationDialog.title.forbidden');

      setNotificationTitle(title || notificationTitle || defaultTitle);

      if (message && message !== '') {
        setNotificationSubText(message);
      }

      setShowNotification(true);
    },
    [notificationTitle, t]
  );

  async function replaceUserInInstances(definitionIds, oldUserId, newUserId) {
    const url = `Route/Instances/ChangeUser`;

    const body = JSON.stringify({
      definitionIds,
      oldUserId,
      newUserId
    });

    await fetchRequest({ url, method: 'PUT', body, ignoreAlert: true }).catch(
      async (errResponse) => {
        const err = await errResponse;

        if (
          err &&
          !(err instanceof SyntaxError) &&
          (err.indexOf('400') !== -1 || err.indexOf('401') !== -1 || err.indexOf('403') !== -1)
        ) {
          const error = JSON.parse(err);

          onShowNotification(null, error.message);
        }

        setIsLoading(false);
      }
    );
  }

  function onNotificationDialogCancel() {
    setShowNotification(false);
  }

  function onReplaceUser(instance) {
    setSelectedInstanceId(instance.id);
    setShowReplaceDialog(true);
  }

  function onReplaceUserInSelected() {
    setSelectedInstanceId(null);
    setShowReplaceDialog(true);
  }

  const changedSelection = new Selection({
    onSelectionChanged: () => {
      const selectedDefinition = [];

      changedSelection.getSelection().map((sel) => selectedDefinition.push(sel.id));

      setSelection(selectedDefinition);
    }
  });

  function onReplaceCancel() {
    setShowReplaceDialog(false);
  }

  async function onReplaceConfirm(newUserId, inMupltipleInstances) {
    setShowReplaceDialog(false);
    setIsLoading(true);

    if (inMupltipleInstances && selection?.length) {
      await replaceUserInInstances(selection, selectedEmployee.userId, newUserId);
    } else {
      await replaceUserInInstances([selectedInstanceId], selectedEmployee.userId, newUserId);
    }

    loadItems();
  }

  if (!currentUser?.isAdmin) {
    return <NoAccessContainer />;
  }

  function getPlaceholderText() {
    if (searchTerm) {
      if (selectedEmployee) {
        return t('employeesInProcessesAdmin.emptySearchResults');
      }

      return t('employeesInProcessesAdmin.searchNoEmployee');
    }

    if (selectedEmployee) {
      t('employeesInProcessesAdmin.placeHolder');
    }

    return t('employeesInProcessesAdmin.placeHolderNoEmployee');
  }

  function getProcessList() {
    if (!isLoading && selectedEmployee && instances?.length) {
      return (
        <ProcessesContainer className="details-list-wrapper">
          <DetailsList
            items={instances || []}
            selectionMode={SelectionMode.multiple}
            selection={changedSelection}
            selectionPreservedOnEmptyClick
            enterModalSelectionOnTouch
            isHeaderVisible
            getKey={(item) => item.id}
            columns={columns}
            onRenderRow={(props, defaultRender) => (
              <ProcessRowStyled props={props}>{defaultRender({ ...props })}</ProcessRowStyled>
            )}
            constrainMode={ConstrainMode.unconstrained}
            layoutMode={DetailsListLayoutMode.fixedColumns}
          />
        </ProcessesContainer>
      );
    }

    return (
      <EmptyListPlaceHolder
        hidden={!!instances?.length}
        noItemsText={getPlaceholderText()}
        listIconName={searchTerm ? 'SearchIssue' : 'People'}
      />
    );
  }

  if (isLoading) {
    return <LoadingSpinner label={t('loading.templates.text')} />;
  }

  return (
    <>
      <MainCommandBar
        items={[
          {
            key: 'employeePicker',
            onRender: () => {
              return (
                <EmployeePickerStyled>
                  <CombinedPicker
                    searchUser
                    focusPeoplePicker
                    selectedItems={selectedEmployee}
                    onChange={handleSelectedEmployee}
                    type={5}
                    placeholder={t('employeesInProcessesAdmin.selectEmployee')}
                  />
                </EmployeePickerStyled>
              );
            }
          },
          {
            key: 'replaceUserInSelected',
            text: t('employeesInProcessInstancesAdmin.replaceUserInSelected'),
            disabled: !selectedEmployee || !selection?.length,
            iconProps: {
              iconName: 'Transition'
            },
            onClick: () => onReplaceUserInSelected()
          }
        ]}
      />
      <EmployeesInProcessesAdminStyled id="EmpProcessList">
        {getProcessList()}
        {showReplaceDialog ? (
          <ReplaceUserInTemplateDialog
            hidden={!showReplaceDialog}
            title={t('employeesInProcessInstancesAdmin.replaceDialogTitle')}
            onCancel={onReplaceCancel}
            onConfirm={onReplaceConfirm}
            stepId={selectedInstanceId}
          />
        ) : null}
        {showNotification ? (
          <DialogCustom
            defaultButtonFunc={onNotificationDialogCancel}
            defaultButtonLabel={t(
              'employeesInProcessesAdmin.notificationDialog.defaultButtonLabel'
            )}
            subText={notificationSubText}
            title={notificationTitle}
            onDismiss={onNotificationDialogCancel}
            hidden={false}
          />
        ) : null}
      </EmployeesInProcessesAdminStyled>
    </>
  );
}

export default EmployeesInProcessesInstancesAdministration;
