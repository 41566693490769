import styled from 'styled-components';

const LabelContainerStyled = styled.div`
  display: flex;
  justify-content: space-between;

  .icon-label-container {
    display: flex;
  }
`;

export default LabelContainerStyled;
