import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { DefaultButton } from '@fluentui/react';

const DefaultButtonStyled = styled(DefaultButton)`
  padding: 0;
  background: none;
  border: none;

  &:hover {
    background: rgb(
      ${({ theme }) =>
        `${theme.processDesignerComponents.addButtonHoverBackground} / ${theme.processDesignerComponents.addButtonHoverBackgroundOpacity}`}
    );
  }

  .ms-Button-label {
    color: ${(props) =>
      props.disabled
        ? `rgb(${props.theme.processDesignerComponents.addButtonDisabledForeground})`
        : `rgb(${props.theme.processDesignerComponents.addButtonForeground})`};
    font-weight: 400;
  }

  .add-rule-icon-button {
    color: ${(props) =>
      props.disabled
        ? `rgb(${props.theme.processDesignerComponents.addButtonDisabledIconColor})`
        : `rgb(${props.theme.processDesignerComponents.addButtonIconColor})`};
  }
`;

function AddRule({ onAddRule, disabled }) {
  const { t } = useTranslation();

  return (
    <DefaultButtonStyled
      iconProps={{ iconName: 'Add', className: 'add-rule-icon-button' }}
      text={t('formbuilder.buttons.addRule')}
      onClick={() => onAddRule()}
      disabled={disabled}
    />
  );
}

AddRule.propTypes = {
  onAddRule: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

AddRule.defaultProps = {
  disabled: false
};

export default AddRule;
