import { PublicClientApplication } from '@azure/msal-browser';
import { authentication } from '@microsoft/teams-js';

const { clientId, authority, scopes, extraScopesToConsent } = window.config;

export const msalInstance = new PublicClientApplication({
  auth: {
    clientId,
    authority,
    navigateToLoginRequestUrl: false,
    redirectUri: window.location.origin,
    extraScopesToConsent
  },
  cache: { cacheLocation: 'localStorage', storeAuthStateInCookie: false }
});

const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
const accounts = msalInstance.getAllAccounts();

function isInIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

function isInMsTeamsEnvironment() {
  const msTeamsEnvironment = window.sessionStorage.getItem('msTeamsEnvironment');
  return msTeamsEnvironment;
}

function openMsTeamsPopUpAndAcquireToken() {
  return new Promise((resolve, reject) => {
    authentication.authenticate({
      url: `${window.location.origin}/authentication-start`,
      width: 550,
      height: 535,
      successCallback: () => {
        acquireAccessToken().then((token) => {
          resolve(token);
        });
      },
      failureCallback: () => {
        reject();
      }
    });
  });
}

const account = activeAccount || accounts[0];

export const loginRequest = {
  scopes,
  forceRefresh: false,
  account
};

let clientType = null;

export const setClientType = (newClientType) => {
  clientType = newClientType;
};

export const acquireTokenPopup = () => {
  return msalInstance.acquireTokenPopup(loginRequest);
};

// export const acquireAccessToken = async (useRedirectAuth) => {
export const acquireAccessToken = async () => {
  return msalInstance.acquireTokenSilent(loginRequest).catch((error) => {
    // fallback to interaction when silent call fails
    if (error) {
      if (isInMsTeamsEnvironment() && isInIframe()) {
        // for ms teams environment
        if (clientType === 'web') {
          return acquireTokenPopup();
        }

        return openMsTeamsPopUpAndAcquireToken();
      }

      // if (!useRedirectAuth || (!isInMsTeamsEnvironment() && isInIframe())) {
      //   // for web environment -> Popup
      //   return msalInstance.acquireTokenPopup(loginRequest).catch((error) => {
      //     if (error) {
      //       alert(`Error acquiring token via Popup ${error}`);
      //     }

      //     return msalInstance.acquireTokenRedirect(loginRequest);
      //   });
      // }

      if (!isInIframe()) {
        localStorage.setItem('redirectUri', window.location.href);
        // for web environment
        return msalInstance.acquireTokenRedirect(loginRequest);
      }
    }

    return null;
  });
};
