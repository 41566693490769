/* eslint-disable max-classes-per-file */

import { createRef, PureComponent, ReactNode } from 'react';
import styled from 'styled-components';

interface IdItem {
  id: string;
}

export interface IChatScrollViewProps<T extends IdItem> {
  /** List of items from top to bottom. */
  items: T[];

  /**
   * Estimates the pixel height of an item.
   * This is used to reserve space and does not need to be accurate.
   */
  estimateItemHeight(item: T): number | null;

  /** Renders an item. The output node SHOULD NOT have a margin. */
  renderItem(item: T): ReactNode;

  className?: string;
}

/** The scroll view is anchored to a number of pixels offset from a specific item's top edge. */
type ScrollAnchorItemTop = {
  type: 'item-top';
  id: string;
  /** Item top edge minus view top edge */
  offset: number;
};
/** The scroll view is anchored to the end of the view. */
type ScrollAnchorBottom = { type: 'bottom'; offset: number };

type ScrollAnchor = ScrollAnchorItemTop | ScrollAnchorBottom;

const IS_DEV = process.env.NODE_ENV !== 'production';

const MAX_AUTO_SCROLL_VELOCITY_PX_S = 250;

// lazy fix: Mobile Safari doesn't like it when we change current scroll position,
// so we'll disable list virtualization there.
const isMobileSafari =
  navigator.userAgent.includes('Safari/') &&
  !navigator.userAgent.includes('Chrome/') &&
  window.matchMedia('(hover: none)').matches;

const VIRTUAL_VISIBILITY_ABOVE = isMobileSafari ? Infinity : 2;
const VIRTUAL_VISIBILITY_BELOW = isMobileSafari ? Infinity : 2;

const ChatScrollViewStyled = styled.div`
  position: relative;
  overflow: hidden scroll;

  > .c-scroll-view-item {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
`;

/**
 * Handles virtualization and scroll anchors for a chat view.
 * All items are laid out below each other with no gaps.
 *
 * This component performs layout without going through a React component update to minimize latency
 * and unnecessary updates.
 */
export default class ChatScrollView<T extends IdItem> extends PureComponent<
  IChatScrollViewProps<T>
> {
  animationState = {
    /** requestAnimationFrame ID */
    id: null as number | null,
    velocity: 0,
    lastTime: document.timeline.currentTime,
    limitVelocity: false,
    /**
     * When an item shrinks, we might end up with less scroll height than before.
     * We don't want it to jitter when this happens, however, so we'll keep a minimum scroll height around.
     */
    minScrollHeight: null as number | null,
    /**
     * We need to keep the fractional part of scrollTop separately, because browsers may not support this.
     */
    scrollTopFract: 0
  };

  /** root node */
  nodeRef = createRef<HTMLDivElement>();

  itemRefs = new Map<string, ChatScrollViewItem>();

  itemHeights = new Map<string, number>();

  /** will be updated by layout */
  scrollHeight = 0;

  itemPositions = new Map<string, number>();

  /** if true, we've had first render */
  isInitialized = false;

  /** scroll height node */
  scrollHeightRef = createRef<HTMLDivElement>();

  /**
   * Our scroll anchor, which determines the scroll position to be preserved across updates.
   * This should be an item-relative anchor if possible.
   */
  scrollAnchor: ScrollAnchor = { type: 'bottom', offset: 0 };

  /**
   * Our animation target scroll anchor, which determines the scroll position we're animating to.
   */
  animateToAnchor: ScrollAnchor | null = null;

  /** if true, we'll keep the scroll view stuck to the bottom */
  isScrolledToBottom = true;

  ctx: IChatScrollViewContext = {
    setItemRef: (id: string, ref: ChatScrollViewItem) => {
      this.itemRefs.set(id, ref);
      this.updateVisibleItemsAndRenderIfNeeded();
    },
    removeItemRef: (id: string, ref: ChatScrollViewItem) => {
      if (this.itemRefs.get(id) === ref) {
        this.itemRefs.delete(id);
      }
    },
    setItemHeight: (id: string, height: number | null) => {
      const currentHeight = this.itemHeights.get(id) ?? null;
      if (height === currentHeight) return;

      this.saveScrollAnchorState();
      this.itemHeights.set(id, height);
      this.performLayout();
      this.animationState.limitVelocity = height > currentHeight;
      this.restoreScrollAnchorFromState();
    }
  };

  resizeObserver = new ResizeObserver(() => {
    this.animationState.limitVelocity = false;
    this.restoreScrollAnchorFromState(this.scrollAnchor);
  });

  /** @see {getStableRenderOrder} */
  stableRenderOrder: string[] = [];

  /** set of currently visible children */
  visibleItems = new Set<string>();

  componentDidMount() {
    this.node.addEventListener('scroll', this.onScroll);
    this.node.addEventListener('wheel', this.onInteract);
    this.node.addEventListener('pointerdown', this.onInteract);

    this.resizeObserver.observe(this.node);

    // now that we have a viewport, we can compute visible items
    this.updateVisibleItems();
    // force an update to re-render with visible
    this.forceUpdate();

    // eslint-disable-next-line no-underscore-dangle
    (this.node as unknown as { _debug: object })._debug = this;
  }

  getSnapshotBeforeUpdate() {
    if (!this.isInitialized) {
      return null;
    }
    return this.readScrollAnchorState();
  }

  componentDidUpdate(
    prevProps: Readonly<IChatScrollViewProps<T>>,
    _: unknown,
    snapshot: ScrollAnchor | null
  ) {
    if (this.props.items !== prevProps.items) {
      const prevIds = new Set(prevProps.items.map((item) => item.id));
      const hasAnyItemsInCommon = this.props.items.some((item) => prevIds.has(item.id));
      if (!hasAnyItemsInCommon) {
        this.isInitialized = false;
      }
    }

    if (!this.isInitialized) {
      setTimeout(() => {
        // this should be enough time for us to obtain item heights
        this.isInitialized = true;
        // set initial position
        this.animationState.limitVelocity = false;
        this.restoreScrollAnchorFromState(this.scrollAnchor);
      }, 10);
    }

    let restoreAnchor = snapshot;

    if (restoreAnchor && this.props.items !== prevProps.items) {
      if (restoreAnchor.type === 'item-top') {
        const { id } = restoreAnchor;
        if (!this.props.items.some((item) => item.id === id)) {
          // scroll anchor was removed! find a new one
          restoreAnchor = this.readScrollAnchorState();
        }
      }
    }

    if (restoreAnchor) {
      this.restoreScrollAnchorFromState(restoreAnchor);
    }
    this.performLayout();
  }

  componentWillUnmount() {
    this.node.removeEventListener('scroll', this.onScroll);
    this.node.removeEventListener('wheel', this.onInteract);
    this.node.removeEventListener('pointerdown', this.onInteract);

    this.resizeObserver.disconnect();

    this.stopAnimation();
  }

  onScroll = () => {
    if (this.animationState.id) {
      // don't save anchor while animating
      this.updateVisibleItemsAndRenderIfNeeded();
      return;
    }

    this.updateScrolledToBottom();
    this.saveScrollAnchorState();
    this.updateVisibleItemsAndRenderIfNeeded();
  };

  onInteract = () => {
    this.stopAnimation();
  };

  get node() {
    return this.nodeRef.current;
  }

  /** Returns either real or estimated height */
  getAnyItemHeight(item: T): number {
    if (this.itemHeights.has(item.id)) {
      return this.itemHeights.get(item.id);
    }
    return this.props.estimateItemHeight(item);
  }

  /** Returns all children in an order that is likely to stay the same across re-renders. */
  getStableRenderOrder() {
    const items = new Map<string, T>();
    for (const item of this.props.items) {
      if (!this.stableRenderOrder.includes(item.id)) this.stableRenderOrder.push(item.id);
      items.set(item.id, item);
    }
    this.stableRenderOrder = this.stableRenderOrder.filter((id) => items.has(id));
    return this.stableRenderOrder.map((id) => items.get(id));
  }

  scrollAnimationLoop = () => {
    this.animationState.id = null;
    const targetAnchor = this.animateToAnchor;

    if (targetAnchor === null) return;

    const { node, scrollHeight } = this;
    if (!node) return;
    const { offsetHeight, scrollTop } = node;

    if (scrollHeight <= offsetHeight) {
      // can't scroll
      this.stopAnimation();
      return;
    }

    const targetPos = this.scrollAnchorPosToScrollTop(targetAnchor);

    const now = document.timeline.currentTime;
    const deltaTime = Math.min(1 / 30, (now - this.animationState.lastTime) / 1000);
    this.animationState.lastTime = now;

    let value = scrollTop + this.animationState.scrollTopFract;

    const displacement = value - targetPos;
    this.animationState.velocity +=
      (-displacement * 130 - 20 * this.animationState.velocity) * deltaTime;

    if (this.animationState.limitVelocity) {
      const velMagnitude = Math.abs(this.animationState.velocity);
      const velSign = Math.sign(this.animationState.velocity);
      this.animationState.velocity =
        velSign * Math.min(velMagnitude, MAX_AUTO_SCROLL_VELOCITY_PX_S);
    }

    value += this.animationState.velocity * deltaTime;

    if (Math.abs(this.animationState.velocity) + Math.abs(displacement) < 2) {
      // close enough; we're done
      node.scrollTo({ top: targetPos });
      this.stopAnimation();
      return;
    }

    this.animationState.minScrollHeight = value + offsetHeight;
    this.updateScrollHeightNode();
    node.scrollTo({ top: value });
    this.animationState.scrollTopFract = value - node.scrollTop;
    this.animationState.id = requestAnimationFrame(this.scrollAnimationLoop);
  };

  /** Converts a scroll anchor position to a scrollTop value. */
  scrollAnchorPosToScrollTop(pos: ScrollAnchor) {
    const { node, scrollHeight } = this;
    if (!node) return null;
    const { offsetHeight } = node;

    if (pos.type === 'item-top') {
      return (this.itemPositions.get(pos.id) ?? NaN) - pos.offset;
    }

    if (pos.type === 'bottom') {
      return scrollHeight - offsetHeight - pos.offset;
    }

    throw new Error('unknown anchor type');
  }

  beginAnimation() {
    if (this.animationState.id) return;

    this.animationState.id = requestAnimationFrame(this.scrollAnimationLoop);
    this.animationState.lastTime = document.timeline.currentTime;
    this.animationState.velocity = 0;
    this.animationState.scrollTopFract = 0;
  }

  stopAnimation() {
    if (this.animationState.id) cancelAnimationFrame(this.animationState.id);
    this.animationState.id = null;
    this.animateToAnchor = null;
    this.animationState.minScrollHeight = null;

    this.saveScrollAnchorState();
    this.updateScrollHeightNode();
  }

  updateScrollHeightNode() {
    const scrollHeight = this.scrollHeightRef.current;
    if (!scrollHeight) return;

    scrollHeight.style.height = `${Math.max(
      this.scrollHeight,
      this.animationState.minScrollHeight ?? 0
    )}px`;
  }

  performLayout(isForVisibilityComputationBeforeUpdate = false) {
    let y = 0;

    const seenItemIds = IS_DEV ? new Set<string>() : null;

    for (const item of this.props.items) {
      const height = this.getAnyItemHeight(item);
      this.itemPositions.set(item.id, y);

      const element = this.itemRefs.get(item.id);
      if (element) element.updatePosition();

      y += height;

      if (IS_DEV && seenItemIds) {
        if (seenItemIds.has(item.id)) {
          throw new Error(`ChatScrollView: duplicate item ID ${item.id}`);
        }
        seenItemIds.add(item.id);
      }
    }

    if (!isForVisibilityComputationBeforeUpdate) {
      this.scrollHeight = y;
      this.updateScrollHeightNode();
    }
  }

  restoreScrollAnchorFromState(scrollAnchor = this.scrollAnchor) {
    const { node } = this;
    if (!node) return;

    this.scrollAnchor = scrollAnchor;
    const intendedScrollTop = this.scrollAnchorPosToScrollTop(scrollAnchor);
    node.scrollTo({ top: intendedScrollTop });

    let mustAnimate = false;
    if (Math.abs(node.scrollTop - intendedScrollTop) > 3 && this.scrollHeight > node.offsetHeight) {
      // scroll height might've shrunk, meaning we can't scroll down there anymore.
      // fix this with animation
      this.animationState.minScrollHeight = intendedScrollTop + node.offsetHeight;
      this.updateScrollHeightNode();
      node.scrollTo({ top: intendedScrollTop });
      mustAnimate = true;
    }

    if (this.isScrolledToBottom) {
      this.animateToAnchor = { type: 'bottom', offset: 0 };
      this.beginAnimation();
    } else if (mustAnimate) {
      this.animateToAnchor = this.scrollAnchor;
      this.beginAnimation();
    }
  }

  updateScrolledToBottom() {
    if (!this.isInitialized) return;

    const { node, scrollHeight } = this;
    if (!node) return;
    const { offsetHeight, scrollTop } = node;

    const maxScrollTop = scrollHeight - offsetHeight;
    // a few points of tolerance, because certain DPI settings cause quite a margin of error
    const isActuallyScrolledToBottom = scrollTop >= maxScrollTop - 5;
    const isAnimatingScrollToBottom =
      this.animateToAnchor?.type === 'bottom' && this.animateToAnchor.offset === 0;

    this.isScrolledToBottom = isActuallyScrolledToBottom || isAnimatingScrollToBottom;
  }

  readScrollAnchorState(): ScrollAnchor | null {
    if (!this.isInitialized) {
      return null;
    }

    const { node } = this;
    if (!node) return null;
    const { offsetHeight, scrollTop, scrollHeight } = node;

    const maxScrollTop = scrollHeight - offsetHeight;

    const visualCenterY = scrollTop + offsetHeight / 2;

    let minDistanceFromCenter = Infinity;
    let minItem: string | null = null;
    let offset = 0;

    for (const item of this.props.items) {
      const itemTop = this.itemPositions.get(item.id);
      const itemBottom = itemTop + this.getAnyItemHeight(item);

      const topDistanceFromCenter = Math.max(itemTop - visualCenterY, 0);
      const bottomDistanceFromCenter = Math.max(visualCenterY - itemBottom, 0);
      const distanceFromCenter = Math.max(topDistanceFromCenter, bottomDistanceFromCenter);

      if (distanceFromCenter < minDistanceFromCenter) {
        minItem = item.id;
        minDistanceFromCenter = distanceFromCenter;
        offset = itemTop - scrollTop;
      }
    }

    if (minItem !== null) {
      return { type: 'item-top', id: minItem, offset };
    }

    // if there's no reference item, fall back to a bottom anchor
    return { type: 'bottom', offset: maxScrollTop - scrollTop };
  }

  saveScrollAnchorState() {
    const anchor = this.readScrollAnchorState();
    if (anchor) this.scrollAnchor = anchor;
  }

  updateVisibleItemsAndRenderIfNeeded(callback?: () => void) {
    if (this.updateVisibleItems()) this.forceUpdate(callback);
  }

  /** Updates visible items and returns true if there was a change. */
  updateVisibleItems(): boolean {
    const { node } = this;
    if (!node) return false;

    if (IS_DEV) {
      for (const item of this.props.items) {
        if (!item.id) {
          // eslint-disable-next-line no-console
          console.error('item without ID', item);
          throw new Error('ChatScrollView: item has no ID');
        }
      }
    }

    const visible = new Set<string>();
    const { offsetHeight } = node;

    const scrollTop = this.scrollAnchorPosToScrollTop(this.animateToAnchor ?? this.scrollAnchor);

    if (this.scrollAnchor.type === 'item-top') {
      const { id, offset } = this.scrollAnchor;
      const anchorIndex = this.props.items.findIndex((item) => item.id === id);
      const anchorItem = this.props.items[anchorIndex];

      if (!anchorItem) {
        // bad anchor. try again?
        this.saveScrollAnchorState();
        return this.updateVisibleItems();
      }

      visible.add(id);

      let yTop = this.itemPositions.get(id) + offset - scrollTop;
      let yBottom = yTop + this.getAnyItemHeight(anchorItem);

      for (let i = anchorIndex - 1; i >= 0; i -= 1) {
        const item = this.props.items[i];
        visible.add(item.id);

        const height = this.getAnyItemHeight(item);
        yTop -= height;

        if (yTop < -offsetHeight * VIRTUAL_VISIBILITY_ABOVE) break;
      }

      for (let i = anchorIndex + 1; i < this.props.items.length; i += 1) {
        const item = this.props.items[i];
        visible.add(item.id);

        const height = this.getAnyItemHeight(item);
        yBottom += height;

        if (yBottom > offsetHeight + offsetHeight * VIRTUAL_VISIBILITY_BELOW) break;
      }
    } else if (this.scrollAnchor.type === 'bottom') {
      let y = 0;
      for (let i = this.props.items.length - 1; i >= 0; i -= 1) {
        const item = this.props.items[i];
        visible.add(item.id);

        const height = this.getAnyItemHeight(item);
        y += height;

        if (y > offsetHeight + offsetHeight * VIRTUAL_VISIBILITY_BELOW) break;
      }
    }

    let changed = false;
    for (const item of visible) {
      if (!this.visibleItems.has(item)) {
        changed = true;
        break;
      }
    }
    if (!changed) {
      for (const item of this.visibleItems) {
        if (!visible.has(item)) {
          changed = true;
          break;
        }
      }
    }

    this.visibleItems = visible;
    return changed;
  }

  renderItem(item: T, visible: boolean) {
    return (
      <ChatScrollViewItem
        key={item.id}
        id={item.id}
        ctx={this.ctx}
        renderItem={() => this.props.renderItem(item)}
        positions={this.itemPositions}
        height={this.getAnyItemHeight(item)}
        visible={visible}
      />
    );
  }

  render(): ReactNode {
    const { className } = this.props;

    if (this.node) {
      this.performLayout(true);
      this.updateVisibleItems();
    }

    const stableRenderOrder = this.getStableRenderOrder();

    return (
      <ChatScrollViewStyled ref={this.nodeRef} className={className} data-is-scrollable="true">
        <div ref={this.scrollHeightRef} className="c-scroll-height" />
        {stableRenderOrder.map((item) => this.renderItem(item, this.visibleItems.has(item.id)))}
      </ChatScrollViewStyled>
    );
  }
}

interface IChatScrollViewContext {
  setItemRef(id: string, ref: ChatScrollViewItem): void;
  removeItemRef(id: string, ref: ChatScrollViewItem): void;
  setItemHeight(id: string, height: number | null): void;
}

class ChatScrollViewItem extends PureComponent<{
  ctx: IChatScrollViewContext;
  id: string;
  renderItem(): ReactNode;
  positions: Map<string, number>;
  height: number;
  visible: boolean;
}> {
  nodeRef = createRef<HTMLDivElement>();

  resizeObserver = new ResizeObserver(() => this.onResized());

  componentDidMount() {
    this.onResized();
    this.resizeObserver.observe(this.node);
    this.props.ctx.setItemRef(this.props.id, this);

    this.updatePosition();

    if (!this.props.id) throw new Error('ChatScrollViewItem has no ID');

    // eslint-disable-next-line no-underscore-dangle
    (this.node as unknown as { _debug: object })._debug = this;
  }

  componentWillUnmount() {
    this.resizeObserver.disconnect();
    this.props.ctx.removeItemRef(this.props.id, this);
  }

  onResized() {
    const { node } = this;
    if (!node) {
      this.dispatchHeightUpdate(null);
    }
    this.dispatchHeightUpdate(node.offsetHeight);
  }

  get node() {
    return this.nodeRef.current;
  }

  updatePosition() {
    const { node } = this;
    if (!node) return;

    const y = this.props.positions.get(this.props.id);
    node.style.top = `${y}px`;
  }

  dispatchHeightUpdate(height: number | null) {
    if (this.props.visible) {
      this.props.ctx.setItemHeight(this.props.id, height);
    }
  }

  render() {
    if (this.props.visible) {
      return (
        <div ref={this.nodeRef} data-id={this.props.id} className="c-scroll-view-item is-visible">
          {this.props.renderItem()}
        </div>
      );
    }

    return (
      <div
        ref={this.nodeRef}
        data-id={this.props.id}
        className="c-scroll-view-item is-hidden"
        style={{ height: this.props.height }}
      />
    );
  }
}
