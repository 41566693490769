import PropTypes from 'prop-types';
import styled from 'styled-components';
import { adjustColor, getRandomColorForSet } from 'utils/helpers';

const DiagramContainerStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const BarStyled = styled.g`
  rect:hover {
    cursor: pointer;
    fill: ${(props) => adjustColor(props.$rectFill, -60)};
    x: ${(props) => props.$xTick - (props.$barWidth * 0.2) / 2}px;
    y: ${(props) => {
      if (props.yVal < 0) {
        return props.$yTick + 5;
      }
      if (props.yVal === 0) {
        return props.$yTick - 5;
      }
      return props.$yTick;
    }}px;
    width: ${(props) => props.$barWidth * 1.2}px;
    height: ${(props) => (props.$yVal !== 0 ? props.$barHeight - 5 : props.$barHeight + 10)}px;
  }

  rect:hover ~ .barText {
    font-size: 1.2em;
    font-weight: bold;
  }

  rect:hover ~ .xAxisText {
    font-size: 1em;
    font-weight: bold;
  }
`;

const DiagramStyled = styled.div`
  margin: 0 2em;

  .axis {
    font-size: 11px;
  }

  .axis path,
  .axis line {
    fill: none;
    stroke: #000;
    shape-rendering: crispEdges;
  }

  .barRect {
    font-size: 1em;
    transition: all 0.3s ease-in-out;
  }

  .barText {
    font-size: 1em;
    transition: all 0.3s ease-in-out;
  }

  .xAxisText {
    font-size: 11px;
    transition: all 0.3s ease-in-out;
  }
`;

function DoubleBarChart({ showText, showTooltip, chartValues, onSelectedChartGroup }) {
  const chartStartX = 0;
  const chartStartY = 0;
  const maxChartWidth = 960;
  const maxChartHeight = 800;
  const yAxisLength = maxChartHeight - 150;
  const xAxisLength = maxChartWidth - 50;

  let totalAmount = 0;
  chartValues.map((value) => {
    totalAmount += value.number;
    return totalAmount;
  });

  function getYAxisValues() {
    const maxPlusValue = Math.max(...chartValues.map((value) => value.time), 0);
    const minMinusValue = Math.min(...chartValues.map((value) => value.time), 0);

    const maxValue =
      Math.abs(maxPlusValue) >= Math.abs(minMinusValue)
        ? Math.abs(maxPlusValue)
        : Math.abs(minMinusValue);

    let stepValue = 1;
    if (maxValue > 10000) {
      stepValue = 5000;
    } else if (maxValue > 5000) {
      stepValue = 1000;
    } else if (maxValue > 2000) {
      stepValue = 500;
    } else if (maxValue > 1000) {
      stepValue = 200;
    } else if (maxValue > 500) {
      stepValue = 100;
    } else if (maxValue > 200) {
      stepValue = 50;
    } else if (maxValue > 100) {
      stepValue = 20;
    } else if (maxValue > 50) {
      stepValue = 10;
    } else if (maxValue > 20) {
      stepValue = 5;
    } else if (maxValue > 10) {
      stepValue = 2;
    }

    const maxTopRoundedValue = Math.ceil(maxPlusValue / stepValue) * stepValue;
    const maxBottomRoundedValue = -Math.ceil(Math.abs(minMinusValue) / stepValue) * stepValue;
    const positivNumberOfSteps = Math.abs(maxTopRoundedValue) / stepValue;
    const negativNumberOfSteps = Math.abs(maxBottomRoundedValue) / stepValue;
    const totalNumberOfSteps = positivNumberOfSteps + negativNumberOfSteps;

    const stepHeight = (yAxisLength - 40) / totalNumberOfSteps;
    return {
      stepValue,
      stepHeight,
      totalNumberOfSteps,
      negativNumberOfSteps,
      maxBottomRoundedValue
    };
  }

  function getYAxis() {
    const y = getYAxisValues();
    const yAxis = [];
    yAxis.push(
      <g
        className="tick"
        transform={`translate(0,${yAxisLength})`}
        style={{ opacity: 1 }}
        key="y-tick"
      >
        <line x2="-6" y2="0" />
      </g>
    );
    for (let i = 0; i < y.totalNumberOfSteps + 1; i += 1) {
      const yTranslation = yAxisLength - 20 - i * y.stepHeight;
      const yValue = y.maxBottomRoundedValue + i * y.stepValue;
      yAxis.push(
        <g
          className="tick"
          transform={`translate(0,${yTranslation})`}
          style={{ opacity: 1 }}
          key={`y-${yValue}`}
        >
          <line x2="-6" y2="0" />
          <text x="-9" y="3" style={{ textAnchor: 'end' }}>
            {`${yValue}%`}
          </text>
        </g>
      );
    }
    return (
      <g className="y axis" key="y-axis">
        {yAxis}
        <path className="domain" d={`M-6,0H0V${yAxisLength}H-6`} />
        <text x="-20" y="-8" style={{ textAnchor: 'middle' }}>
          Duration
        </text>
      </g>
    );
  }

  function getXAxis() {
    const y = getYAxisValues();
    const numberOfBars = chartValues.length;
    const tickDistance = xAxisLength / (numberOfBars + 1);
    const xAxis = [];
    for (let i = 0; i < numberOfBars; i += 1) {
      const xTranslation = (i + 1) * tickDistance;
      xAxis.push(
        <g
          className="tick"
          transform={`translate(${xTranslation},0)`}
          style={{ opacity: 1 }}
          key={`x-${i + 1}`}
        >
          <line
            y1={chartValues[i].time >= 0 ? '0' : '-6'}
            y2={chartValues[i].time >= 0 ? '6' : '0'}
            x2="0"
          />
          )
        </g>
      );
    }

    return (
      <g
        className="x axis"
        transform={`translate(0,${yAxisLength - 20 - y.negativNumberOfSteps * y.stepHeight})`}
        key="x-axis"
      >
        {xAxis}
        <path className="domain" d={`M0,6V0H${xAxisLength}V6`} />
      </g>
    );
  }

  function getAxises() {
    const axises = [];
    axises.push(getXAxis());
    axises.push(getYAxis());
    return axises;
  }

  function getBars() {
    const numberOfBars = chartValues.length;
    const tickDistance = xAxisLength / (numberOfBars + 1);
    const barWidth = tickDistance - 10;
    const y = getYAxisValues();
    const bars = [];
    for (let i = 0; i < numberOfBars; i += 1) {
      const xTick = (i + 1) * tickDistance - barWidth / 2;
      let barHeight = Math.abs(chartValues[i].time * (y.stepHeight / y.stepValue));
      let yBarValue =
        chartValues[i].time >= 0
          ? yAxisLength - 20 - y.negativNumberOfSteps * y.stepHeight - barHeight
          : yAxisLength - 20 - y.negativNumberOfSteps * y.stepHeight;
      let yTextValue =
        chartValues[i].time >= 0
          ? yAxisLength - 20 - y.negativNumberOfSteps * y.stepHeight - barHeight - 10
          : yAxisLength - 20 - y.negativNumberOfSteps * y.stepHeight + barHeight + 20;
      if (chartValues[i].time === 0) {
        barHeight = 8;
        yBarValue = yAxisLength - 20 - y.negativNumberOfSteps * y.stepHeight - barHeight / 2;
        yTextValue = yAxisLength - 20 - y.negativNumberOfSteps * y.stepHeight - barHeight / 2 - 10;
      }
      const tooltip = `${chartValues[i].title}: ${chartValues[i].number}`;
      const rectColor = getRandomColorForSet(numberOfBars, i);
      bars.push(
        <BarStyled
          className="barContainer"
          onClick={() => onSelectedChartGroup(chartValues[i])}
          key={`bar-${i + 1}`}
          $rectFill={rectColor}
          $barWidth={barWidth}
          $barHeight={barHeight}
          $xTick={xTick}
          $yTick={yBarValue}
          $yVal={chartValues[i].time}
        >
          <rect
            className="barRect"
            x={xTick}
            width={barWidth}
            y={yBarValue}
            height={barHeight}
            fill={rectColor}
            data-title="test"
            aria-labelledby={tooltip}
          />
          {showTooltip ? <title className="pieSliceTooltip">{tooltip}</title> : null}
          <text
            className="xAxisText"
            transform={`translate(${xTick + barWidth / 2}, ${yBarValue}), rotate(-90)`}
            y="3"
            x={chartValues[i].time >= 0 ? -barHeight - 15 : 15}
            style={chartValues[i].time >= 0 ? { textAnchor: 'end' } : { textAnchor: 'start' }}
          >
            {chartValues[i].title}
          </text>
          {showText ? (
            <text className="barText" textAnchor="middle" x={xTick + barWidth / 2} y={yTextValue}>
              {`${chartValues[i].time}%`}
            </text>
          ) : null}
        </BarStyled>
      );
    }
    return bars;
  }

  function getBarChart() {
    return (
      <g transform="translate(40,20)">
        {getAxises()}
        {getBars()}
      </g>
    );
  }

  function getSvgChart() {
    return (
      <svg
        width={maxChartWidth}
        height={maxChartHeight}
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        preserveAspectRatio="xMinYMin meet"
        viewBox={`${chartStartX * 1.5} ${chartStartY * 1.5} ${maxChartWidth} ${maxChartHeight}`}
        className="chart"
      >
        {getBarChart()}
      </svg>
    );
  }

  return (
    <DiagramContainerStyled>
      <DiagramStyled>{getSvgChart()}</DiagramStyled>
    </DiagramContainerStyled>
  );
}

DoubleBarChart.propTypes = {
  showText: PropTypes.bool,
  showTooltip: PropTypes.bool,
  onSelectedChartGroup: PropTypes.func.isRequired,
  chartValues: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.string,
      number: PropTypes.number,
      time: PropTypes.number
    })
  ).isRequired
};

DoubleBarChart.defaultProps = {
  showText: false,
  showTooltip: false
};

export default DoubleBarChart;
