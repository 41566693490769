import { IApiObjectData, IApiObjectType } from '../object';
import { DateTime, ITextItem, Ref, Uuid } from './base-types';
import {
  FieldAggregationType,
  ProcessOutcome,
  RouteFieldVisibility,
  RouteInstanceStepServiceStatus,
  RouteInstanceStepStatus,
  RouteStepType,
  TaskType
} from '../../../types';
import {
  GateOutcomeBehavior,
  getProcessTemplateStepSubProcessFieldRefsSparse,
  IAutoTagSettingsItem,
  IProcessStepLink,
  IProcessTemplateStepSubProcess,
  IReminderSettings,
  SurveyProcessVisibility
} from './process-template-steps';
import { FieldRefSpec, simpleIdObject } from './utils';
import {
  getProcessFieldGroupRuleGroupFieldRefsSparse,
  getProcessStepLinkRuleFieldRefsSparse,
  IProcessField,
  IProcessFieldGroupRuleGroup,
  IProcessStepLinkRule,
  PROCESS_FIELD
} from './process-fields';
import { IUser, USER } from './users';
import { ITagItem, TAG } from './tags';

export interface IProcessInstanceStepBase extends IApiObjectData {
  id: Uuid;
  routeInstanceId: Uuid;
  status: RouteInstanceStepStatus;
  taskId: Uuid;
  taskType: TaskType;
  dateFrom: DateTime | null;
  dateTo: DateTime | null;
  name: string | null;
  description: string | null;
  duration: number;
  sortOrder: number;
  stepType: RouteStepType;
  effort: number | null;
  successors: IProcessStepLink[] | null;
  predecessors: IProcessStepLink[] | null;
}

/** has refs */
export interface IProcessInstanceStepStart extends IProcessInstanceStepBase {
  stepType: RouteStepType.Start;
  /** has refs */
  groups: IProcessInstanceFieldGroup[];
  instanceTags: Ref<ITagItem>[];
}

function getProcessInstanceStepStartFieldRefs(
  obj: IProcessInstanceStepStart
): FieldRefSpec<unknown>[] {
  return [
    ...obj.groups.flatMap((group, index) =>
      getProcessInstanceFieldGroupFieldRefs(`groups/${index}/`, group)
    ),
    ...obj.instanceTags.map((item, index) => ({
      path: `instanceTags/${index}`,
      type: TAG,
      params: () => item.id
    }))
  ];
}

export interface IProcessInstanceStepEnd extends IProcessInstanceStepBase {
  stepType: RouteStepType.End;
  processOutcome: ProcessOutcome;
}

/** has refs */
export interface IProcessInstanceStepApproval extends IProcessInstanceStepBase {
  stepType: RouteStepType.Approval;
  outcomes: string[];
  outcomeBehavior: GateOutcomeBehavior;
  reminderSettings: IReminderSettings;
  durationFieldId: Uuid | null;
  nameFieldId: Uuid | null;
  showPrevApprovals: boolean | null;
  /** has refs */
  groups: IProcessInstanceFieldGroup[];
}

function getProcessInstanceStepApprovalFieldRefs(
  obj: IProcessInstanceStepApproval
): FieldRefSpec<unknown>[] {
  return [
    ...obj.groups.flatMap((group, index) =>
      getProcessInstanceFieldGroupFieldRefs(`groups/${index}/`, group)
    )
  ];
}

/** has refs */
export interface IProcessInstanceStepData extends IProcessInstanceStepBase {
  stepType: RouteStepType.Data;
  waitForCompletion: boolean;
  reminderSettings: IReminderSettings;
  durationFieldId: Uuid | null;
  nameFieldId: Uuid | null;
  subProcesses: IProcessInstanceStepSubProcess[];
  showPrevApprovals: boolean | null;
  /** has refs */
  groups: IProcessInstanceFieldGroup[];
}

function getProcessInstanceStepDataFieldRefs(
  obj: IProcessInstanceStepData
): FieldRefSpec<unknown>[] {
  return [
    ...obj.subProcesses.flatMap((proc, index) =>
      getProcessInstanceStepSubProcessFieldRefs(`subProcesses/${index}/`, proc)
    ),
    ...obj.groups.flatMap((group, index) =>
      getProcessInstanceFieldGroupFieldRefs(`groups/${index}/`, group)
    )
  ];
}

/** has refs */
export interface IProcessInstanceStepSurvey extends IProcessInstanceStepBase {
  stepType: RouteStepType.Survey;
  reminderSettings: IReminderSettings;
  durationFieldId: Uuid | null;
  nameFieldId: Uuid | null;
  /** has refs */
  groups: IProcessInstanceFieldGroup[];
  processVisibility: SurveyProcessVisibility;
}

function getProcessInstanceStepSurveyFieldRefs(
  obj: IProcessInstanceStepSurvey
): FieldRefSpec<unknown>[] {
  return [
    ...obj.groups.flatMap((group, index) =>
      getProcessInstanceFieldGroupFieldRefs(`groups/${index}/`, group)
    )
  ];
}

/** has refs */
export interface IProcessInstanceStepIntegration extends IProcessInstanceStepBase {
  stepType: RouteStepType.Integration;
  subProcesses: IProcessInstanceStepSubProcess[];
}

function getProcessInstanceStepIntegrationFieldRefs(
  obj: IProcessInstanceStepIntegration
): FieldRefSpec<unknown>[] {
  return [
    ...obj.subProcesses.flatMap((proc, index) =>
      getProcessInstanceStepSubProcessFieldRefs(`subProcesses/${index}/`, proc)
    )
  ];
}

export type IProcessInstanceStep =
  | IProcessInstanceStepStart
  | IProcessInstanceStepEnd
  | IProcessInstanceStepApproval
  | IProcessInstanceStepData
  | IProcessInstanceStepSurvey
  | IProcessInstanceStepIntegration;

export const PROCESS_INSTANCE_STEP: IApiObjectType<Uuid, IProcessInstanceStep> = simpleIdObject({
  id: 'Route/Instance/Step',
  url: (id) => `Route/Instance/Step/${id}`,
  dynFields(data) {
    switch (data.stepType) {
      case RouteStepType.Start:
        return getProcessInstanceStepStartFieldRefs(data);
      case RouteStepType.End:
        return []; // no refs
      case RouteStepType.Approval:
        return getProcessInstanceStepApprovalFieldRefs(data);
      case RouteStepType.Data:
        return getProcessInstanceStepDataFieldRefs(data);
      case RouteStepType.Survey:
        return getProcessInstanceStepSurveyFieldRefs(data);
      case RouteStepType.Integration:
        return getProcessInstanceStepIntegrationFieldRefs(data);
      default:
        throw new Error(`unknown step type ${(data as IProcessInstanceStepBase).stepType}`);
    }
  }
});

/** has refs */
export interface IProcessInstanceFieldGroup {
  id: Uuid;
  name: string;
  description: string;
  /** has refs */
  fields: IProcessInstanceStepFieldLink[];
  /** has refs */
  rules: IProcessStepLinkRule[] | null;
  customCode: string;
}

export function getProcessInstanceFieldGroupFieldRefs(
  basePath: string,
  obj: IProcessInstanceFieldGroup
): FieldRefSpec<unknown>[] {
  return [
    ...obj.fields.flatMap((field, index) =>
      getProcessInstanceStepFieldLinkFieldRefs(`${basePath}fields/${index}/`, field)
    ),
    ...(obj.rules?.flatMap((field, index) =>
      getProcessStepLinkRuleFieldRefsSparse(`${basePath}rules/${index}/`, field)
    ) ?? [])
  ];
}

/** has refs */
export interface IProcessInstanceStepFieldLink {
  id: Uuid;
  taskId: Uuid;
  /** Only set and used when evaluating field group rules in the start form of a new process */
  startStepId: Uuid | null;
  /** Used when evaluating field group rules in task forms */
  taskStepId: Uuid | null;
  field: Ref<IProcessField>;
  /** has refs */
  fieldGroup: IProcessFieldGroupInstance;
  visibility: RouteFieldVisibility;
  /** @see FieldRelationType */
  relation: number;
  sortOrder: number;
  hasLaterChanges: boolean;
  value: unknown | null;
  numberOfTasks: number;
  /** has refs */
  values: IProcessInstanceSurveyFieldValue[];
}

export function getProcessInstanceStepFieldLinkFieldRefs(
  basePath: string,
  obj: IProcessInstanceStepFieldLink
): FieldRefSpec<unknown>[] {
  return [
    { path: `${basePath}field`, type: PROCESS_FIELD, params: () => obj.field?.id },
    ...(obj.fieldGroup
      ? getProcessFieldGroupInstanceFieldRefs(`${basePath}fieldGroup/`, obj.fieldGroup)
      : []),

    ...(obj.values?.flatMap((value, index) =>
      getProcessInstanceSurveyFieldValueFieldRefs(`${basePath}values/${index}/`, value)
    ) ?? [])
  ];
}

/** has refs */
export interface IProcessFieldGroupInstance {
  id: Uuid;
  name: string | null;
  allNames: ITextItem[] | null;
  description: string | null;
  allDescriptions: ITextItem[] | null;
  fields: IProcessFieldGroupInstanceItem[] | null;
}

export function getProcessFieldGroupInstanceFieldRefs(
  basePath: string,
  obj: IProcessFieldGroupInstance
): FieldRefSpec<unknown>[] {
  return [
    ...(obj.fields?.flatMap((field, index) =>
      getProcessFieldGroupInstanceItemFieldRefs(`${basePath}fields/${index}/`, field)
    ) ?? [])
  ];
}

/** has refs */
export interface IProcessFieldGroupInstanceItem {
  field: Ref<IProcessField>;
  sortOrder: number;
  visibility: RouteFieldVisibility;
  aggregation: FieldAggregationType;
  /** has refs */
  ruleGroups: IProcessFieldGroupRuleGroup[];

  value: unknown | null;
  numberOfTasks: number | null;
  values: IProcessInstanceSurveyFieldValue[] | null;
  autoTagSettings: IAutoTagSettingsItem[] | null;
  hasLaterChanges: boolean;
}

export function getProcessFieldGroupInstanceItemFieldRefs(
  basePath: string,
  obj: IProcessFieldGroupInstanceItem
): FieldRefSpec<unknown>[] {
  return [
    { path: `${basePath}field`, type: PROCESS_FIELD, params: () => obj.field?.id },

    ...(obj.ruleGroups?.flatMap((rule, index) =>
      getProcessFieldGroupRuleGroupFieldRefsSparse(`${basePath}rules/${index}/`, rule)
    ) ?? [])
  ];
}

/** has refs */
export interface IProcessInstanceSurveyFieldValue {
  aggregation: FieldAggregationType;
  visibiltiy: RouteFieldVisibility;
  person: Ref<IUser>;
  rolename: string | null;
  sortOrder: number;
  editDate: DateTime;
  value: unknown | null;
}

export function getProcessInstanceSurveyFieldValueFieldRefs(
  basePath: string,
  obj: IProcessInstanceSurveyFieldValue
): FieldRefSpec<unknown>[] {
  return [{ path: `${basePath}person`, type: USER, params: () => obj.person?.userId }];
}

/** has refs */
export interface IProcessInstanceStepSubProcess extends IProcessTemplateStepSubProcess {
  subInstanceId: Uuid;
  subDefinitionName: string | null;
  status: RouteInstanceStepServiceStatus;
  numberOfIterations: number;
  httpStatus: number;
  errorMessage: string | null;
  allowInvoke: boolean;
}

export function getProcessInstanceStepSubProcessFieldRefs(
  basePath: string,
  obj: IProcessInstanceStepSubProcess
): FieldRefSpec<unknown>[] {
  return getProcessTemplateStepSubProcessFieldRefsSparse(basePath, obj);
}
