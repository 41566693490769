import { useEffect, useRef, useState } from 'react';
import { deleteFromStorage, useLocalStorage, writeStorage } from '@rehooks/local-storage';

/**
 * this hook saves the message draft to localStorage when the component unmounts
 * and deletes message drafts that are older than a day
 * it returns the message draft if it exists, otherwise null
 */

// Interface for the properties expected by the hook
interface IUseMessageDraftProps {
  id: string; // The unique identifier for this message draft
  message?: string | null; // The content of the draft message
}

const ONE_DAY = 24 * 60 * 60 * 1000; // Duration of one day in milliseconds
const MESSAGE_DRAFT_KEY = 'message-draft-'; // Key prefix for storing message drafts in localStorage

export default function useMessageDraft({
  id,
  message
}: IUseMessageDraftProps): [string | null, (value: boolean) => void, () => void] {
  // Attempt to retrieve existing draft from localStorage
  const [messageDraft] = useLocalStorage<{ date: number; message: string }>(`message-draft-${id}`);

  // State to control if we should save the message on component unmount
  const [saveOnUnmount, setSaveOnUnmount] = useState(false);
  const messageRef = useRef(message); // Reference to hold the current message
  const dateRef = useRef(Date.now()); // Reference to hold the current date

  // Whenever the message prop changes, we update our references
  useEffect(() => {
    messageRef.current = message;
    dateRef.current = Date.now();
  }, [message]);

  // When the component is about to unmount, we save the draft to localStorage
  useEffect(() => {
    return () => {
      if (messageRef.current && saveOnUnmount) {
        writeStorage(`${MESSAGE_DRAFT_KEY}${id}`, {
          date: dateRef.current,
          message: messageRef.current
        });
      }
    };
  }, [id, saveOnUnmount]);

  // On initial render, we check localStorage for any expired drafts and delete them
  useEffect(() => {
    const now = Date.now();

    // Iterate over all keys in localStorage
    Object.keys(localStorage).forEach((key) => {
      // If the key represents a message draft, check its age
      if (key.startsWith(MESSAGE_DRAFT_KEY)) {
        try {
          // Parse the stored value for this key
          const storedValue = JSON.parse(localStorage.getItem(key) as string);

          // If the draft is older than a day, delete it
          if (now - storedValue.date > ONE_DAY) {
            deleteFromStorage(key);
          }
        } catch (e) {
          // If there was an error parsing the stored value, log it and delete the key
          // eslint-disable-next-line no-console
          console.error('Error parsing localStorage item', key, e);
          deleteFromStorage(key);
        }
      }
    });
  }, []);

  // The hook returns the current draft (or null if none exists), a function to control saving on unmount, and a function to delete the draft
  return [
    messageDraft?.message || null,
    setSaveOnUnmount,
    () => {
      deleteFromStorage(`${MESSAGE_DRAFT_KEY}${id}`);
      setSaveOnUnmount(false);
      messageRef.current = null;
    }
  ];
}
