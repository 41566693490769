// unified convention
/* eslint-disable no-underscore-dangle */

import type { Processor, Plugin } from 'unified';
import type {
  Code,
  Effects,
  Extension as MicromarkExtension,
  State,
  TokenizeContext
} from 'micromark-util-types';
import type {
  CompileContext,
  Extension as FromMarkdownExtension,
  Token
} from 'mdast-util-from-markdown';
import type { Options as ToMarkdownExtension } from 'mdast-util-to-markdown';
import type { Node, Root } from 'mdast';
import type { Element } from 'hast';

type REF_NODE_ID = 'ecReference';
export const REF_NODE_ID: REF_NODE_ID = 'ecReference';
const OPEN_CHAR = '\u25d8';
const OPEN_CHAR_CODE = OPEN_CHAR.charCodeAt(0);

export interface EcReferenceMdastNode extends Node {
  type: REF_NODE_ID;
  containsEof: boolean;
  url?: string;
}

declare module 'micromark-util-types' {
  interface TokenTypeMap {
    [REF_NODE_ID]: REF_NODE_ID;
  }
  interface Token {
    _ecData: { containsEof: boolean };
  }
}
declare module 'mdast' {
  interface RootContentMap {
    [REF_NODE_ID]: EcReferenceMdastNode;
  }
  interface PhrasingContentMap {
    [REF_NODE_ID]: EcReferenceMdastNode;
  }
}

/**
 * Tokenizes a reference, which looks like <u+25D8>https://example.com<u+25D8>.
 * If we encounter EOF, we'll mark this reference as incomplete and not show anything yet.
 */
function tokenizeReference(this: TokenizeContext, effects: Effects, ok: State, nok: State) {
  const data = {
    containsEof: false
  };

  function open(code: Code) {
    if (code === OPEN_CHAR_CODE) {
      effects.enter(REF_NODE_ID, { _ecData: data });
      // we have to enter data here, because data must not be empty.
      // if we're directly before EOF, we'll at least get our opening character in.
      // I'm sure there's a better solution to this, but it's fine.
      effects.enter('data');
      effects.consume(code);
      return urlBody;
    }

    return nok(code);
  }

  function urlBody(code: Code) {
    if (code === null) {
      // EOF: this is probably incomplete
      data.containsEof = true;
      effects.exit('data');
      effects.exit(REF_NODE_ID);
      return ok(code);
    }

    if (code === 0x20 || code === 0x0a || code === OPEN_CHAR_CODE) {
      // whitespace or end
      effects.consume(code);
      effects.exit('data');
      effects.exit(REF_NODE_ID);
      return ok;
    }

    effects.consume(code);
    return urlBody;
  }

  return open;
}

const referenceSyntax: MicromarkExtension = {
  text: {
    [OPEN_CHAR.charCodeAt(0)]: {
      name: REF_NODE_ID,
      tokenize: tokenizeReference
    }
  }
};

function fromMarkdownEnterNode(this: CompileContext, token: Token) {
  this.enter({ type: REF_NODE_ID, containsEof: token._ecData.containsEof }, token);
  this.buffer();
}

function fromMarkdownExitNode(this: CompileContext, token: Token) {
  const value = this.resume();
  // remove opening and closing characters
  const url = value.replace(/^\u25d8/, '').replace(/[\s\u25d8]$/, '');

  (this.stack.at(-1) as EcReferenceMdastNode).url = url;
  this.exit(token);
}

const referenceFromMarkdown: FromMarkdownExtension = {
  enter: {
    [REF_NODE_ID]: fromMarkdownEnterNode
  },
  exit: {
    [REF_NODE_ID]: fromMarkdownExitNode
  }
};

const referenceToMarkdown: ToMarkdownExtension = {
  handlers: {
    [REF_NODE_ID]: (node) => {
      return OPEN_CHAR + node.url + OPEN_CHAR;
    }
  }
};

/**
 * Remark plugin that parses <u+25D8>references<u+25D8>.
 */
export const remarkEcReference: Plugin<void[], Root> = function referenceExtension(
  this: Processor
) {
  interface ProcessorExt extends Processor {
    micromarkExtensions?: MicromarkExtension[];
    fromMarkdownExtensions?: FromMarkdownExtension[];
    toMarkdownExtensions?: ToMarkdownExtension[];
  }
  const data = this.data() as ProcessorExt;

  data.micromarkExtensions = [...(data.micromarkExtensions ?? []), referenceSyntax];
  data.fromMarkdownExtensions = [...(data.fromMarkdownExtensions ?? []), referenceFromMarkdown];
  data.toMarkdownExtensions = [...(data.toMarkdownExtensions ?? []), referenceToMarkdown];
};

/** Converts a reference node to HTML as a tagged span */
export function handleReferenceNode(anyNode: EcReferenceMdastNode): Element {
  const node = anyNode as EcReferenceMdastNode;

  if (node.containsEof) {
    return {
      type: 'element',
      tagName: 'span',
      properties: {
        dataEcReference: true,
        dataIsIncomplete: true,
        dataUrl: node.url
      },
      children: []
    };
  }

  return {
    type: 'element',
    tagName: 'span',
    properties: {
      dataEcReference: true,
      dataUrl: node.url
    },
    children: [
      {
        type: 'element',
        tagName: 'a',
        properties: {
          href: node.url
        },
        children: [
          {
            type: 'text',
            value: '[*]'
          }
        ]
      }
    ]
  };
}
