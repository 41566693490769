import { Uuid } from './base-types';
import { IApiObjectData, IApiObjectType } from '../object';
import { simpleIdObject } from './utils';

export interface ITagItem extends IApiObjectData {
  id: Uuid | null;
  parentId: Uuid | null;
  text: string | null;
  isDeleted: boolean;
  fullText: string | null;
  children: ITagItem[];
}

export const TAG: IApiObjectType<string, ITagItem> = simpleIdObject<ITagItem>({
  id: 'Tag',
  url: (id) => `Tag/${id}`,
  dynFields: (tag) =>
    tag.children.map((tag, index) => ({
      path: `children/${index}`,
      type: TAG,
      params: () => tag.id
    })),
  insertFieldsOnlyIfNeeded: true,
  loadDedupIntervalSecs: 600
});
