/*
# Summary
## Objects
API objects are shared cache values that can link to other objects.
For example, a Task object would contain a `creator: <link to a User object>` field.
This means that if that particular User object is changed, it will be changed everywhere.
The link is transparent to users, and does not require special handling.
Links are proxied through the REFS symbol on the object.

An API object always knows how to load itself, and may do so in certain circumstances
(e.g. when a component requests it for the first time).

## Variants
Object may have multiple variants (Small, Full) for cases like Tasks/Small,
where we receive only partial data and may need to load more data later.
If a user should request a Full task while only Small data is present in the cache,
then Full data will be loaded.

## Object keys and IDs
Objects are identified by an object key, which identifies an object by type and parameters,
or an object variant key, which additionally specifies the requested variant.

Because Javascript lacks == overloading, we additionally use object IDs/object variant IDs,
which are string versions of object keys.

## Mutations
Objects may be mutated, which happens in two phases:
1. we optimistically change the data to the new value to be already visible everywhere
2. we send a request to the backend to actually perform the change

While the mutation is pending, we use a data overlay to apply the mutation without prematurely
modifying the underlying data.
If the request fails, we can then revert the mutation simply by deleting the overlay.

## Paginated Lists
API lists are collections of special page objects which are not present in the cache,
but still link to objects in the cache. Hence, list items must be API objects.
*/

import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import stableHash from 'stable-hash';
import { ApiDataCache, ApiDataCacheContext } from './cache';
import {
  ApiObject,
  ApiObjectEvent,
  ApiObjectVariant,
  IApiObjectData,
  IApiObjectSubscriber,
  IApiObjectType,
  IDataOverlay,
  InferApiObjectTypeData,
  InferApiObjectTypeDataFull,
  InferApiObjectTypeDataSmall,
  InferApiObjectTypeParams,
  IObjectMutationOptions,
  IPendingMutation
} from './object';

export * from './types';
export * from './object';
export * from './list';

export function useApiDataCache(): ApiDataCache {
  return useContext(ApiDataCacheContext);
}

export interface IApiObjectOptions {
  /** Causes data to be reloaded when this component is mounted. Defaults to true. */
  revalidateOnMount?: boolean;
  /** Causes data to be loaded if we have no data when this component is mounted. Defaults to true. */
  loadIfNeeded?: boolean;
  /**
   * The minimum variant that we need. Prefer Small if you only need minimal info (e.g. name).
   * Defaults to Full.
   *
   * The resulting object may be a larger variant, but never a smaller variant.
   * Certain list endpoints such as Tasks/Small will load small variants of objects,
   * so using smaller variants can lighten server load by using cached data.
   */
  variant?: ApiObjectVariant;
  /**
   * Specifies what variant data to include.
   * Implies ApiObjectVariant.Small!
   */
  includes?: string[];
}

export interface UseApiObjectResult<T> {
  /** Loaded data. */
  data?: T;

  /** Load error, if any. */
  error?: Error;

  /** If true, we're loading data from the backend while having nothing in the cache. */
  isLoading: boolean;

  /** If true, we're loading data from the backend while we already have the data in cache. */
  isValidating: boolean;

  /** If true, we have a pending mutation on this object. */
  isMutating: boolean;

  /**
   * Creates a new mutation on this object.
   *
   * @param promise a promise that performs the request and returns permanent mutations
   * @param overlay data to overlay over our cache data while the promise is pending
   * @param options additional options
   */
  mutate(
    promise: Promise<IDataOverlay<T>> | ((data: T) => Promise<IDataOverlay<T>>),
    overlay: IDataOverlay<T>,
    options?: IObjectMutationOptions
  ): IPendingMutation<T>;

  /** Triggers a load immediately. */
  forceLoad(): void;
}

export function useApiObject<T extends IApiObjectType<unknown, IApiObjectData>>(
  type: T | null | undefined,
  params: InferApiObjectTypeParams<T> | null | undefined,
  options?: IApiObjectOptions & { variant?: ApiObjectVariant.Full }
): UseApiObjectResult<InferApiObjectTypeDataFull<T>>;

export function useApiObject<T extends IApiObjectType<unknown, IApiObjectData>>(
  type: T | null | undefined,
  params: InferApiObjectTypeParams<T> | null | undefined,
  options: IApiObjectOptions & { variant: ApiObjectVariant.Small }
): UseApiObjectResult<InferApiObjectTypeDataSmall<T>>;

export function useApiObject<T extends IApiObjectType<unknown, IApiObjectData>>(
  type: T | null | undefined,
  params: InferApiObjectTypeParams<T> | null | undefined,
  options: IApiObjectOptions
): UseApiObjectResult<InferApiObjectTypeData<T>>;

/**
 * Loads a typed API object.
 *
 * If either `type` or `params` are falsy, then no data will be loaded.
 *
 * All requests make use of a shared cache, so if this object has already been loaded elsewhere,
 * cached data will be available immediately.
 *
 * @param type The API endpoint that serves the object
 * @param params Parameters for the endpoint.
 * @param options additional options
 */
export function useApiObject<T extends IApiObjectType<unknown, IApiObjectData>>(
  type: T | null | undefined,
  params: InferApiObjectTypeParams<T> | null | undefined,
  options?: IApiObjectOptions
): UseApiObjectResult<InferApiObjectTypeData<T>> {
  const variant =
    options?.variant ?? (options?.includes ? ApiObjectVariant.Small : ApiObjectVariant.Full);

  if (variant === ApiObjectVariant.Full && options?.includes) {
    throw new Error('invalid call: requested Full object with includes');
  }

  const revalidateOnMount = options?.revalidateOnMount ?? true;
  const loadIfNeeded = options?.loadIfNeeded ?? true;

  const cache = useApiDataCache();
  const [data, setData] = useState<
    { paramsHash: string; data: InferApiObjectTypeData<T> } | undefined
  >(() =>
    type && params
      ? {
          paramsHash: stableHash([params, options?.includes]),
          data: cache.getObject<T>({ type, params }).get(variant, options?.includes)
        }
      : null
  );

  const [error, setError] = useState<Error | undefined>();
  const [isLoading, setLoading] = useState(true);
  const [isMutating, setMutating] = useState(false);

  const paramsHash = useMemo(
    () => stableHash([params, options?.includes]),
    [params, options?.includes]
  );
  const paramsRef = useRef({ params, includes: options?.includes });
  paramsRef.current = { params, includes: options?.includes };

  const objRef = useRef<ApiObject<T> | null>(null);

  useEffect(() => {
    if (!type || paramsRef.current.params === null || paramsRef.current.params === undefined) {
      setData(undefined);
      setError(undefined);
      setLoading(false);
      setMutating(false);

      return () => undefined;
    }

    const { includes } = paramsRef.current;

    const obj = cache.getObject<T>({ type, params: paramsRef.current.params });
    setData({ paramsHash, data: obj.get(variant, includes) });
    setError(undefined);
    setLoading(obj.isLoading);
    setMutating(obj.isMutating);

    const subscriber: IApiObjectSubscriber<T> = {
      variant,
      includes,
      handle(_, event) {
        setData({ paramsHash, data: obj.get(variant, includes) });

        if (event === ApiObjectEvent.LoadStarted) {
          setLoading(true);
          setError(undefined);
        } else if (event === ApiObjectEvent.LoadSucceeded) {
          setLoading(false);
          setError(undefined);
        } else if (event === ApiObjectEvent.LoadFailed) {
          setLoading(false);
          setError(obj.lastLoadError);
        } else if (event === ApiObjectEvent.MutationStarted) {
          setMutating(true);
        } else if (event === ApiObjectEvent.MutationSucceeded) {
          setMutating(false);
        } else if (event === ApiObjectEvent.MutationFailed) {
          setMutating(false);
        }
      }
    };

    obj.addSubscriber(
      subscriber,
      (() => {
        if (revalidateOnMount) return 'revalidateStale';
        if (loadIfNeeded) return 'initialLoadOnly';
        return 'none';
      })()
    );
    objRef.current = obj;
    return () => {
      obj.removeSubscriber(subscriber);
      if (objRef.current === obj) objRef.current = null;
    };
  }, [cache, type, paramsHash, variant, revalidateOnMount, loadIfNeeded]);

  const mutate = useCallback(
    (
      promise:
        | Promise<IDataOverlay<InferApiObjectTypeData<T>>>
        | ((data: InferApiObjectTypeData<T>) => Promise<IDataOverlay<InferApiObjectTypeData<T>>>),
      overlay: IDataOverlay<InferApiObjectTypeData<T>>,
      options?: IObjectMutationOptions
    ) => {
      if (!objRef.current) return null;

      const resolvedPromise =
        promise instanceof Promise ? promise : promise(objRef.current.get(variant));

      return objRef.current.mutate(resolvedPromise, overlay, options);
    },
    [variant]
  );

  const forceLoad = useCallback(() => {
    objRef.current?.beginLoad();
  }, []);

  return {
    // useEffect has a delay, so we need to validate the paramsHash to avoid a frame of invalid data
    data:
      data?.paramsHash === paramsHash
        ? data.data
        : (() => {
            // temporarily access the data directly if we have it, to avoid an unnecessary frame of `undefined` data
            const obj = cache.getObject<T>({ type, params });
            return obj.get(variant);
          })(),
    error,
    isLoading: data?.data === undefined && isLoading,
    isValidating: isLoading,
    isMutating,
    mutate,
    forceLoad
  };
}

export type UseApiObjectsResult<T> = Omit<UseApiObjectResult<T>, 'mutate' | 'forceLoad'>[];

export function useApiObjects<T extends IApiObjectType<unknown, IApiObjectData>>(
  type: T | null | undefined,
  params: (InferApiObjectTypeParams<T> | null)[],
  options?: IApiObjectOptions & { variant?: ApiObjectVariant.Full }
): UseApiObjectsResult<InferApiObjectTypeDataFull<T>>;

export function useApiObjects<T extends IApiObjectType<unknown, IApiObjectData>>(
  type: T | null | undefined,
  params: (InferApiObjectTypeParams<T> | null)[],
  options: IApiObjectOptions & { variant: ApiObjectVariant.Small }
): UseApiObjectsResult<InferApiObjectTypeDataSmall<T>>;

export function useApiObjects<T extends IApiObjectType<unknown, IApiObjectData>>(
  type: T | null | undefined,
  params: (InferApiObjectTypeParams<T> | null)[],
  options: IApiObjectOptions
): UseApiObjectsResult<InferApiObjectTypeData<T>>;

/**
 * Similar to useApiObject, but for loading multiple objects at the same time.
 *
 * @param type The API endpoint that serves the object
 * @param params Parameters for each object
 * @param options additional options
 */
export function useApiObjects<T extends IApiObjectType<unknown, IApiObjectData>>(
  type: T | null | undefined,
  params: (InferApiObjectTypeParams<T> | null)[],
  options?: IApiObjectOptions
): UseApiObjectsResult<InferApiObjectTypeData<T>> {
  const variant = options?.variant ?? ApiObjectVariant.Full;
  const revalidateOnMount = options?.revalidateOnMount ?? true;
  const loadIfNeeded = options?.loadIfNeeded ?? true;

  const cache = useApiDataCache();
  const [data, setData] = useState<
    ({ paramsHash: string; obj: ApiObject<T>; data: InferApiObjectTypeData<T> } | null)[]
  >(() =>
    type
      ? params.map((params) => {
          if (!params) return null;
          const obj = cache.getObject<T>({ type, params });
          return {
            paramsHash: stableHash(params),
            obj,
            data: obj.get(variant)
          };
        })
      : []
  );

  const [states, setStates] = useState(
    () =>
      new Map<
        ApiObject<T>,
        {
          isLoading: boolean;
          isMutating: boolean;
          error: Error | undefined;
        }
      >()
  );
  const objRefs = useRef<ApiObject<T>[]>([]);

  const paramsHashes = useMemo(() => params.map((params) => stableHash(params)), [params]);
  const paramsRef = useRef(params);
  paramsRef.current = params;

  const allParamsHash = useMemo(() => stableHash(paramsHashes), [paramsHashes]);

  const paramsHashesRef = useRef(paramsHashes);
  paramsHashesRef.current = paramsHashes;

  useEffect(() => {
    if (!type) {
      setStates(new Map());
      setData([]);
      return () => undefined;
    }

    const params = paramsRef.current;
    const unsub: (() => void)[] = [];

    for (let i = 0; i < params.length; i += 1) {
      const index = i;

      const entry = params[index];
      if (!entry) continue;

      const paramsHash = paramsHashesRef.current[index];

      const obj = cache.getObject<T>({ type, params: entry });
      setData((data) => {
        const newData = [...data];
        newData[index] = { paramsHash, obj, data: obj.get(variant) };
        return newData;
      });
      setStates((states) => {
        const newStates = new Map(states);
        newStates.set(obj, {
          error: undefined,
          isLoading: obj.isLoading,
          isMutating: obj.isMutating
        });
        return newStates;
      });

      const subscriber: IApiObjectSubscriber<T> = {
        variant,
        includes: null,
        handle(_, event) {
          setData((data) => {
            const index = data.findIndex((item) => item?.obj === obj);
            if (index === -1) {
              return data;
            }
            const newData = [...data];
            newData[index] = { paramsHash, obj, data: obj.get(variant) };
            return newData;
          });

          setStates((states) => {
            let state = states.get(obj) ?? {
              error: undefined,
              isLoading: obj.isLoading,
              isMutating: obj.isMutating
            };

            if (event === ApiObjectEvent.LoadStarted) {
              state = { ...state, error: undefined, isLoading: true };
            } else if (event === ApiObjectEvent.LoadSucceeded) {
              state = { ...state, error: undefined, isLoading: false };
            } else if (event === ApiObjectEvent.LoadFailed) {
              state = { ...state, error: obj.lastLoadError, isLoading: false };
            } else if (event === ApiObjectEvent.MutationStarted) {
              state = { ...state, isMutating: true };
            } else if (event === ApiObjectEvent.MutationSucceeded) {
              state = { ...state, isMutating: false };
            } else if (event === ApiObjectEvent.MutationFailed) {
              state = { ...state, isMutating: false };
            }

            const newStates = new Map(states);
            newStates.set(obj, state);
            return newStates;
          });
        }
      };

      obj.addSubscriber(
        subscriber,
        (() => {
          if (revalidateOnMount) return 'revalidateStale';
          if (loadIfNeeded) return 'initialLoadOnly';
          return 'none';
        })()
      );
      objRefs.current[index] = obj;

      unsub.push(() => {
        obj.removeSubscriber(subscriber);
        if (objRefs.current[index] === obj) objRefs.current[index] = null;
      });
    }

    return () => {
      for (const f of unsub) f();
    };
  }, [cache, type, allParamsHash, variant, revalidateOnMount, loadIfNeeded]);

  return data.map((entry, i) =>
    entry
      ? {
          // useEffect has a delay, so we need to validate the paramsHash to avoid a frame of invalid data
          data: entry.paramsHash === paramsHashes[i] ? entry.data : undefined,
          error: states.get(entry.obj)?.error,
          isLoading: (entry?.data === undefined && states.get(entry.obj)?.isLoading) ?? false,
          isValidating: states.get(entry.obj)?.isLoading ?? false,
          isMutating: states.get(entry.obj)?.isMutating ?? false
        }
      : null
  );
}
