import ChatMessageFragmentText from './ChatMessageFragmentText';
import ChatMessageFragmentToolCall, { IToolRenderers } from './ChatMessageFragmentToolCall';
import { ChatMessageFragmentWebSearch } from './ChatMessageFragmentWebSearch';
import { IMessageFragment, MessageFragmentType } from '../../../services/ChatConnection/model';

const NO_TOOLS: IToolRenderers = {};

export default function ChatItemContents({
  fragments,
  isAssistant,
  isGenerating,
  toolRenderers = NO_TOOLS
}: {
  fragments: IMessageFragment[];
  isAssistant?: boolean;
  isGenerating?: boolean;
  /** must be memoized! */
  toolRenderers?: IToolRenderers;
}) {
  return (
    <div>
      {fragments.map((fragment, i) => {
        const isLastFragment = i === fragments.length - 1;

        return (
          <ChatMessageFragment
            key={fragment.id}
            fragment={fragment}
            isAssistant={isAssistant}
            isGenerating={isLastFragment && isGenerating}
            toolRenderers={toolRenderers}
          />
        );
      })}
    </div>
  );
}

function ChatMessageFragment({
  fragment,
  isAssistant,
  isGenerating,
  toolRenderers
}: {
  fragment: IMessageFragment;
  isAssistant: boolean;
  isGenerating: boolean;
  toolRenderers: IToolRenderers;
}) {
  if (fragment.type === MessageFragmentType.Text) {
    return (
      <ChatMessageFragmentText
        isAssistant={isAssistant}
        fragment={fragment}
        appendCaret={isGenerating}
      />
    );
  }

  if (fragment.type === MessageFragmentType.LocalToolCall) {
    return <ChatMessageFragmentToolCall fragment={fragment} toolRenderers={toolRenderers} />;
  }

  if (fragment.type === MessageFragmentType.WebSearch) {
    return <ChatMessageFragmentWebSearch fragment={fragment} />;
  }

  return null;
}
