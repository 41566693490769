import { AppContext } from 'features/App';
import { usePages } from 'features/App/context/PagesContext';
import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import breakpoints from 'utils/breakpoints';
import { Nav } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';

const NavStyled = styled(Nav)`
  width: 100%;
  max-width: 250px;
  min-width: 250px;
  height: 100%;

  .ms-SearchBox {
    height: 44px;
    border-bottom: 1px solid rgb(${(props) => props.theme.sidebar.border});
  }

  @media (max-width: ${breakpoints.smallMax}px) {
    min-width: 100%;
    width: 100%;

    .ms-SearchBox {
      padding-right: 9px;
    }
  }

  .ms-Nav-compositeLink .ms-Nav-linkText {
    color: rgb(${(props) => props.theme.sidebar.foregroundHighlighted});
  }
  .ms-Nav-compositeLink:not(.is-expanded) .ms-Nav-linkText {
    color: rgb(${(props) => props.theme.sidebar.foreground});
  }

  .ms-Nav-link:hover {
    .ms-Nav-linkText {
      color: rgb(${(props) => props.theme.sidebar.foregroundHighlighted}) !important;
    }
  }

  .ms-Nav-navItem {
    margin: 3px 3px;
    border-radius: 8px;
    overflow: hidden;
  }

  .ms-Nav-link::after {
    border-left: none;
    inset: 20px;
    left: 2px;
    width: 2px;
    top: 9px;
    background: rgb(${(props) => props.theme.sidebar.foregroundHighlighted});
    height: 25px;
  }

  #teams-parent-link-${({ id }) => id} ~ ul {
    max-height: 264px;
    overflow-y: auto;
  }

  .ms-Nav-groupContent {
    animation-name: none;
    animation-duration: 0;
    animation-timing-function: ease;
    animation-fill-mode: none;
  }

  a {
    text-decoration: none !important;

    &:hover {
      text-decoration: none !important;
    }
  }
`;

function Navigation() {
  const { t } = useTranslation();

  const [activeGroup, setActiveGroup] = useState(null);
  const lastSelectedKey = useRef(null);
  const navRef = useRef(null);
  const panelId = useId('nav');

  const location = useLocation();
  const navigate = useNavigate();

  // global app state
  const { globalAppState, dispatch } = useContext(AppContext);
  const { isNavigationPanelHidden, currentUser, teams, permissions } = globalAppState;

  const { pages: allPages } = usePages();

  const pages = useMemo(
    () => allPages.filter((page) => Array.isArray(page.locations) && page.locations.includes(1)),
    [allPages]
  );

  const checkIfUrlContainsString = useCallback(
    (stringToCheck) => location?.pathname.includes(stringToCheck),
    [location]
  );

  const getSelectedKey = useCallback(
    (pathname) => {
      if (!pathname) return 'myOpen';

      const checkPathName = (urlToCheck) => pathname.includes(urlToCheck);

      if (
        checkPathName('/teams') &&
        !checkPathName('/administration') &&
        !checkPathName('/process-templates')
      ) {
        lastSelectedKey.current = teams.find((team) => checkPathName(team.id))?.id;
        return teams.find((team) => checkPathName(team.id))?.id;
      }
      if (checkPathName('/tasks/my-open')) {
        lastSelectedKey.current = 'myOpen';
        return 'myOpen';
      }
      if (checkPathName('/tasks/allocated')) {
        lastSelectedKey.current = 'allocated';
        return 'allocated';
      }
      if (checkPathName('/tasks/all')) {
        lastSelectedKey.current = 'all';
        return 'all';
      }
      if (checkPathName('/tasks/involved')) {
        lastSelectedKey.current = 'involved';
        return 'involved';
      }
      if (checkPathName('/tasks/completed')) {
        lastSelectedKey.current = 'completed';
        return 'completed';
      }
      if (checkPathName('/process-instances/my')) {
        lastSelectedKey.current = 'myRoutes';
        return 'myRoutes';
      }
      if (checkPathName('/process-instances/involved')) {
        lastSelectedKey.current = 'involvedRoutes';
        return 'involvedRoutes';
      }
      if (checkPathName('/process-instances/all')) {
        lastSelectedKey.current = 'allRoutes';
        return 'allRoutes';
      }
      if (checkPathName('/process-instances/completed')) {
        lastSelectedKey.current = 'completedRoutes';
        return 'completedRoutes';
      }
      if (checkPathName('/projects/my')) {
        lastSelectedKey.current = 'myProjects';
        return 'myProjects';
      }
      if (checkPathName('/projects/involved')) {
        lastSelectedKey.current = 'involvedProjects';
        return 'involvedProjects';
      }
      if (checkPathName('/projects/all')) {
        lastSelectedKey.current = 'allProjects';
        return 'allProjects';
      }
      if (checkPathName('/projects/completed')) {
        lastSelectedKey.current = 'completedProjects';
        return 'completedProjects';
      }
      if (checkPathName('/projects/templates')) {
        lastSelectedKey.current = 'projectTemplates';
        return 'projectTemplates';
      }
      if (checkPathName('/process-templates/landscape')) {
        lastSelectedKey.current = 'processLandscape';
        return 'processLandscape';
      }
      if (checkPathName('/process-templates/end2end')) {
        lastSelectedKey.current = 'templatesByEnd2End';
        return 'templatesByEnd2End';
      }
      if (checkPathName('/process-templates/teams')) {
        lastSelectedKey.current = 'templatesByTeams';
        return 'templatesByTeams';
      }

      if (checkPathName('/administration')) {
        lastSelectedKey.current = null;
        return null;
      }

      if (checkPathName('/pages')) {
        lastSelectedKey.current = 'pages';
        return 'pages';
      }

      if (checkPathName('/favorites')) {
        lastSelectedKey.current = 'favoriteTemplates';
        return 'favoriteTemplates';
      }

      return lastSelectedKey.current;
    },
    [teams]
  );

  const selectedKey = useMemo(() => {
    return getSelectedKey(location?.pathname);
  }, [getSelectedKey, location?.pathname]);

  useEffect(() => {
    if (!activeGroup) {
      if (checkIfUrlContainsString('/tasks') || checkIfUrlContainsString('/teams')) {
        setActiveGroup('tasks');
      } else if (checkIfUrlContainsString('/process-templates')) {
        setActiveGroup('process-templates');
      } else if (checkIfUrlContainsString('/process-instances')) {
        setActiveGroup('process-instances');
      } else if (checkIfUrlContainsString('/administration')) {
        setActiveGroup('administration');
      }
    }
  }, [checkIfUrlContainsString, location, activeGroup]);

  useLayoutEffect(() => {
    // this useEffect is used to add the id to the ul element
    if (selectedKey) {
      setTimeout(() => {
        const liElements = navRef.current?.querySelectorAll('li');

        if (!liElements) return;

        const requiredElements = Array.from(liElements).filter((li) => {
          const children = Array.from(li.children);
          return (
            children.length === 2 && children[0].tagName === 'DIV' && children[1].tagName === 'UL'
          );
        });

        requiredElements.forEach((li) => {
          const divElement = li.children[0];
          const ulElement = li.children[1];

          ulElement.id = `${divElement.id}-group`;
        });
      }, 1);
    }
  }, [selectedKey]);

  const addDataIsScrollableProp = useCallback(() => {
    setTimeout(() => {
      const teamsContainer = document.querySelector(`#teams-parent-link-${panelId} ~ ul`);

      if (teamsContainer) teamsContainer.setAttribute('data-is-scrollable', 'true');
    }, 1);
  }, [panelId]);

  useLayoutEffect(() => {
    addDataIsScrollableProp();
  }, [addDataIsScrollableProp]);

  const [groupsExpandationState, setGroupsExpandationState] = useState({
    tasks: false,
    teams: false,
    routes: false,
    projects: false,
    processTemplates: false,
    pages: false
  });

  const onExpandNavigationItems = useCallback(
    (item) => {
      setGroupsExpandationState({
        tasks: item.key === 'tasks' || false,
        teams: item.key === 'teams' || false,
        routes: item.key === 'routes' || false,
        projects: item.key === 'projects' || false,
        processTemplates: item.key === 'processTemplates' || false,
        pages: item.key === 'pages' || false
      });
      setTimeout(addDataIsScrollableProp, 1);
    },
    [addDataIsScrollableProp]
  );

  useEffect(() => {
    const expandationState = {
      tasks: false,
      teams: false,
      routes: false,
      projects: false,
      processTemplates: false,
      pages: false
    };
    if (checkIfUrlContainsString('/tasks')) {
      expandationState.tasks = true;
    } else if (checkIfUrlContainsString('/process-templates')) {
      expandationState.processTemplates = true;
    } else if (checkIfUrlContainsString('/teams') && !checkIfUrlContainsString('/administration')) {
      expandationState.teams = true;
    } else if (checkIfUrlContainsString('/process-instances')) {
      expandationState.routes = true;
    } else if (checkIfUrlContainsString('/projects')) {
      expandationState.projects = true;
    } else if (checkIfUrlContainsString('/pages')) {
      expandationState.pages = true;
    } else if (!checkIfUrlContainsString('/administration')) {
      expandationState.tasks = true;
    }

    setGroupsExpandationState((prevState) => {
      if (checkIfUrlContainsString('/tags')) {
        return prevState;
      }

      return expandationState;
    });
  }, [checkIfUrlContainsString]);

  function resetSearch() {
    dispatch({ type: 'searchTerm', data: null });
  }

  function generateTeamNavigationLinks() {
    return teams.map((team) => ({
      key: team.id,
      name: team.displayTitle,
      title: t('navigation.group.title.team', { teamName: team.displayTitle }),
      url: `/teams/${team.id}`,
      group: 'tasks'
    }));
  }

  function onLinkClick(event, element) {
    event.preventDefault();
    const fromAdmin = window.location.href.includes('administration');

    if ((fromAdmin && element.group !== 'administration') || activeGroup !== element.group) {
      resetSearch();
      setActiveGroup(element.group);
    }

    navigate(element.url);

    if (!isNavigationPanelHidden) {
      dispatch({ type: 'toggleNavigation' });
    }
  }

  const links = [
    {
      name: t('navigation.group.name.tasks'),
      links: [
        {
          key: 'myOpen',
          name: t('navigation.group.name.tasksForMe'),
          title: t('navigation.group.title.tasksForMe'),
          url: '/tasks/my-open',
          group: 'tasks'
        },
        {
          key: 'allocated',
          name: t('navigation.group.name.tasksFromMe'),
          title: t('navigation.group.title.tasksFromMe'),
          url: '/tasks/allocated',
          group: 'tasks'
        },
        {
          key: 'all',
          name: t('navigation.group.name.allTasks'),
          title: t('navigation.group.title.allTasks'),
          url: '/tasks/all',
          group: 'tasks'
        },
        {
          key: 'involved',
          name: t('navigation.group.name.involvedTasks'),
          title: t('navigation.group.title.involvedTasks'),
          url: '/tasks/involved',
          group: 'tasks'
        },
        {
          key: 'completed',
          name: t('navigation.group.name.completedTasks'),
          title: t('navigation.group.title.completedTasks'),
          url: '/tasks/completed',
          group: 'tasks'
        }
      ],
      isExpanded: groupsExpandationState.tasks,
      onClick: (_, item) => onExpandNavigationItems(item),
      key: 'tasks',
      id: 'navigation-tasks'
    }
  ];

  if (teams.length > 0) {
    links.push({
      name: 'Teams',
      links: generateTeamNavigationLinks(),
      isExpanded: groupsExpandationState.teams,
      title: t('navigation.group.title.teams'),
      onClick: (_, item) => onExpandNavigationItems(item),
      key: 'teams',
      id: `teams-parent-link-${panelId}`
    });
  }

  links.push({
    name: t('navigation.group.name.projects'),
    links: [
      {
        key: 'myProjects',
        name: t('navigation.group.name.myProjects'),
        title: t('navigation.group.title.myProjects'),
        url: '/projects/my',
        group: 'projects'
      },
      {
        key: 'involvedProjects',
        name: t('navigation.group.name.involvedProjects'),
        title: t('navigation.group.title.involvedProjects'),
        url: '/projects/involved',
        group: 'projects'
      },
      {
        key: 'allProjects',
        name: t('navigation.group.name.allProjects'),
        title: t('navigation.group.title.allProjects'),
        url: '/projects/all',
        group: 'projects'
      },
      {
        key: 'completedProjects',
        name: t('navigation.group.name.completedProjects'),
        title: t('navigation.group.title.completedProjects'),
        url: '/projects/completed',
        group: 'projects'
      },
      {
        key: 'projectTemplates',
        name: t('navigation.group.name.projectTemplates'),
        title: t('navigation.group.title.projectTemplates'),
        url: '/projects/templates',
        group: 'projects'
      }
    ],
    isExpanded: groupsExpandationState.projects,
    onClick: (_, item) => onExpandNavigationItems(item),
    key: 'projects',
    id: 'navigation-projects'
  });

  if (permissions?.startInstances || currentUser?.isAdmin) {
    links.push({
      name: t('navigation.group.name.routes'),
      links: [
        {
          key: 'myRoutes',
          name: t('navigation.group.name.myRoutes'),
          title: t('navigation.group.title.myRoutes'),
          url: '/process-instances/my',
          group: 'routes'
        },
        {
          key: 'involvedRoutes',
          name: t('navigation.group.name.involvedRoutes'),
          title: t('navigation.group.title.involvedRoutes'),
          url: '/process-instances/involved',
          group: 'routes'
        },
        {
          key: 'allRoutes',
          name: t('navigation.group.name.allRoutes'),
          title: t('navigation.group.title.allRoutes'),
          url: '/process-instances/all',
          group: 'routes'
        },
        {
          key: 'completedRoutes',
          name: t('navigation.group.name.completedRoutes'),
          title: t('navigation.group.title.completedRoutes'),
          url: '/process-instances/completed',
          group: 'routes'
        }
      ],
      isExpanded: groupsExpandationState.routes,
      onClick: (_, item) => onExpandNavigationItems(item),
      key: 'routes',
      id: 'navigation-processes'
    });
  }

  if (permissions?.startInstances || currentUser?.isAdmin) {
    links.push({
      name: t('navigation.group.name.processTemplates'),
      links: [
        {
          key: 'processLandscape',
          name: t('navigation.group.name.processLandscape'),
          title: t('navigation.group.title.processLandscape'),
          url: '/process-templates/landscape',
          group: 'processTemplates'
        },
        {
          key: 'templatesByEnd2End',
          name: t('navigation.group.name.templatesByEnd2End'),
          title: t('navigation.group.title.templatesByEnd2End'),
          url: '/process-templates/end2end',
          group: 'processTemplates'
        },
        {
          key: 'templatesByTeams',
          name: t('navigation.group.name.templatesByTeams'),
          title: t('navigation.group.title.templatesByTeams'),
          url: '/process-templates/teams',
          group: 'processTemplates'
        },
        {
          key: 'favoriteTemplates',
          name: t('navigation.group.name.favoriteTemplates'),
          title: t('navigation.group.title.favoriteTemplates'),
          url: '/process-templates/favorites/all',
          group: 'processTemplates'
        }
      ],
      isExpanded: groupsExpandationState.processTemplates,
      onClick: (_, item) => onExpandNavigationItems(item),
      key: 'processTemplates',
      isGroup: true,
      id: 'navigation-process-templates'
    });
  }

  if (pages?.length) {
    links.push({
      key: 'pages',
      title: t('navigation.group.title.pages'),
      name: t('navigation.group.name.pages'),
      url: '/pages'
    });
  }

  const navigationGroups = [{ links }];

  return (
    <div ref={navRef}>
      <NavStyled
        id={`${panelId}`}
        onLinkClick={onLinkClick}
        onRenderGroupHeader={(linkProps, defaultRender) => {
          return <div id={linkProps.id}>{defaultRender(linkProps)}</div>;
        }}
        selectedKey={selectedKey}
        onLinkExpandClick={(_, item) => onExpandNavigationItems(item)}
        groups={navigationGroups}
        styles={{ root: { height: '100%' } }}
      />
    </div>
  );
}

Navigation.propTypes = {};

Navigation.defaultProps = {};

export default Navigation;

export { NavStyled };
