import { $getRoot, $isDecoratorNode, $isElementNode } from 'lexical';
import { useLayoutEffect, useState } from 'react';
import { $generateNodesFromDOM } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

export default function InitializePlugin({
  defaultValue,
  onInitialized
}: {
  defaultValue?: string;
  onInitialized: () => void;
}) {
  const [initialized, setInitialized] = useState(false);
  const [editor] = useLexicalComposerContext();

  useLayoutEffect(() => {
    if (defaultValue && !initialized) {
      setInitialized(true);
      editor.update(() => {
        // In the browser you can use the native DOMParser API to parse the HTML string.
        const parser = new DOMParser();

        let stringToParse = defaultValue;

        if (!stringToParse.startsWith('<') || !stringToParse.endsWith('>')) {
          stringToParse = `<p class="editor-paragraph" dir="ltr">
              <span style="white-space: pre-wrap;">${stringToParse}</span>
            </p>`;
        }

        const dom = parser.parseFromString(stringToParse, 'text/html');

        // Once you have the DOM instance it's easy to generate LexicalNodes.
        const nodes = $generateNodesFromDOM(editor, dom);

        const root = $getRoot();

        nodes?.forEach((node) => {
          if ($isElementNode(node) || $isDecoratorNode(node)) {
            root.append(node);
          }
        });

        root.getFirstDescendant()?.remove();
      });

      setTimeout(() => {
        onInitialized();
      }, 300);
    } else if (!initialized) {
      setInitialized(true);
      onInitialized();
    }
  }, [defaultValue, initialized, editor, onInitialized]);

  return <div />;
}
