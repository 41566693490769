import {
  forwardRef,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { DirectionalHint, FocusTrapZone, ISize, Layer } from '@fluentui/react';
import styled from 'styled-components';

export interface ICallout2Props {
  /** If true, the callout is visible. */
  open?: boolean;

  /** Should close the callout. */
  onDismiss: () => void;

  /** The element that this callout will be positioned relative to. */
  target: RefObject<HTMLElement> | DOMRect;

  /** Distance between target and callout. */
  gapSpace?: number;

  /** If true, the callout will cover the target instead of appearing beside it. */
  coverTarget?: boolean;

  directionalHint?: DirectionalHint;

  className?: string;

  children?: ReactNode | undefined;
}

export interface ICallout2 {
  /** Notifies the callout that the target has been moved. */
  updateTargetRect: () => void;

  nodeRef: RefObject<HTMLDivElement>;
  contentRef: RefObject<HTMLDivElement>;
}

interface IPoint {
  x: number;
  y: number;
}

interface IRect extends IPoint, ISize {}

enum Axis {
  X = 'x',
  Y = 'y'
}

enum MainAlign {
  Before,
  After
}

enum CrossAlign {
  Center,
  AutoEdge,
  Before,
  After
}

function sizeForAxis(size: ISize, axis: Axis) {
  if (axis === Axis.X) return size.width;
  return size.height;
}

function layoutCallout(
  targetRect: IRect,
  contentSize: ISize,
  windowSize: ISize,
  dir: DirectionalHint,
  gapSpace: number,
  coverTarget: boolean
): IPoint {
  const { mainAxis, mainAlign, crossAlign } = (() => {
    switch (dir) {
      case DirectionalHint.leftCenter:
        return { mainAxis: Axis.X, mainAlign: MainAlign.Before, crossAlign: CrossAlign.Center };
      case DirectionalHint.leftTopEdge:
        return { mainAxis: Axis.X, mainAlign: MainAlign.Before, crossAlign: CrossAlign.After };
      case DirectionalHint.leftBottomEdge:
        return { mainAxis: Axis.X, mainAlign: MainAlign.Before, crossAlign: CrossAlign.Before };
      case DirectionalHint.rightCenter:
        return { mainAxis: Axis.X, mainAlign: MainAlign.After, crossAlign: CrossAlign.Center };
      case DirectionalHint.rightTopEdge:
        return { mainAxis: Axis.X, mainAlign: MainAlign.After, crossAlign: CrossAlign.After };
      case DirectionalHint.rightBottomEdge:
        return { mainAxis: Axis.X, mainAlign: MainAlign.After, crossAlign: CrossAlign.Before };
      case DirectionalHint.topCenter:
        return { mainAxis: Axis.Y, mainAlign: MainAlign.Before, crossAlign: CrossAlign.Center };
      case DirectionalHint.topLeftEdge:
        return { mainAxis: Axis.Y, mainAlign: MainAlign.Before, crossAlign: CrossAlign.After };
      case DirectionalHint.topRightEdge:
        return { mainAxis: Axis.Y, mainAlign: MainAlign.Before, crossAlign: CrossAlign.Before };
      case DirectionalHint.topAutoEdge:
        return { mainAxis: Axis.Y, mainAlign: MainAlign.Before, crossAlign: CrossAlign.AutoEdge };
      case DirectionalHint.bottomCenter:
        return { mainAxis: Axis.Y, mainAlign: MainAlign.After, crossAlign: CrossAlign.Center };
      case DirectionalHint.bottomLeftEdge:
        return { mainAxis: Axis.Y, mainAlign: MainAlign.After, crossAlign: CrossAlign.After };
      case DirectionalHint.bottomRightEdge:
        return { mainAxis: Axis.Y, mainAlign: MainAlign.After, crossAlign: CrossAlign.Before };
      case DirectionalHint.bottomAutoEdge:
        return { mainAxis: Axis.Y, mainAlign: MainAlign.After, crossAlign: CrossAlign.AutoEdge };
      default:
        throw new Error(`unexpected direction ${dir}`);
    }
  })();
  const crossAxis = mainAxis === Axis.X ? Axis.Y : Axis.X;

  // read parameters into main and cross axes
  const mainTargetStart = targetRect[mainAxis];
  const mainTargetEnd = mainTargetStart + sizeForAxis(targetRect, mainAxis);
  const crossTargetStart = targetRect[crossAxis];
  const crossTargetEnd = crossTargetStart + sizeForAxis(targetRect, crossAxis);

  const mainWindowSize = sizeForAxis(windowSize, mainAxis);
  const crossWindowSize = sizeForAxis(windowSize, crossAxis);

  const mainContentSize = sizeForAxis(contentSize, mainAxis);
  const crossContentSize = sizeForAxis(contentSize, crossAxis);

  // determine initial positions
  let mainPos: number;
  if (mainAlign === MainAlign.Before) {
    if (coverTarget) {
      mainPos = mainTargetEnd - mainContentSize - gapSpace;
    } else {
      mainPos = mainTargetStart - mainContentSize - gapSpace;
    }
  } else if (coverTarget) {
    mainPos = mainTargetStart + gapSpace;
  } else {
    mainPos = mainTargetEnd + gapSpace;
  }

  const crossPosBefore = Math.min(crossTargetStart, crossTargetEnd - crossContentSize);
  const crossPosAfter = Math.max(crossTargetStart, crossTargetEnd - crossContentSize);

  let crossPos: number;
  if (crossAlign === CrossAlign.Before) {
    crossPos = crossPosBefore;
  } else if (crossAlign === CrossAlign.Center) {
    const crossTargetCenter = (crossTargetEnd + crossTargetStart) / 2;
    crossPos = crossTargetCenter - crossContentSize / 2;
  } else if (crossAlign === CrossAlign.After) {
    crossPos = crossPosAfter;
  } else {
    // AutoEdge
    const spaceBefore = crossTargetEnd;
    const spaceAfter = crossWindowSize - crossTargetStart;
    crossPos = spaceBefore > spaceAfter ? crossPosBefore : crossPosAfter;
  }

  // now fit it into the window if it's overflowing
  const mainEnd = mainPos + mainContentSize;
  const crossEnd = crossPos + crossContentSize;

  if (mainEnd > mainWindowSize) mainPos = mainWindowSize - mainContentSize;
  if (crossEnd > crossWindowSize) crossPos = crossWindowSize - crossContentSize;
  if (mainPos < 0) mainPos = 0;
  if (crossPos < 0) crossPos = 0;

  if (mainAxis === Axis.X) return { x: mainPos, y: crossPos };
  return { y: mainPos, x: crossPos };
}

function getDir(dir: DirectionalHint) {
  switch (dir) {
    case DirectionalHint.leftCenter:
    case DirectionalHint.leftTopEdge:
    case DirectionalHint.leftBottomEdge:
      return 'left';
    case DirectionalHint.rightCenter:
    case DirectionalHint.rightTopEdge:
    case DirectionalHint.rightBottomEdge:
      return 'right';
    case DirectionalHint.topCenter:
    case DirectionalHint.topLeftEdge:
    case DirectionalHint.topRightEdge:
    case DirectionalHint.topAutoEdge:
      return 'top';
    case DirectionalHint.bottomCenter:
    case DirectionalHint.bottomLeftEdge:
    case DirectionalHint.bottomRightEdge:
    case DirectionalHint.bottomAutoEdge:
      return 'bottom';
    default:
      throw new Error(`unexpected direction ${dir}`);
  }
}

// I don't know why <Layer> renders a span, but it does. We don't really want this to affect layout, however...
const CalloutLayerContainerStyled = styled.div`
  display: none;
`;

/**
 * Like a fluent-ui callout, but handles dynamic layout better.
 */
export default forwardRef<ICallout2, ICallout2Props>(function Callout2({ open, ...props }, ref) {
  if (!open) return null;
  return (
    <CalloutLayerContainerStyled>
      <Layer>
        <InnerCallout ref={ref} {...props} />
      </Layer>
    </CalloutLayerContainerStyled>
  );
});

const DEFAULT_DIR = DirectionalHint.topAutoEdge;
const DEFAULT_GAP = 8;

const Callout2Styled = styled.div<{ $dir: string; $hasContentSize: boolean }>`
  position: absolute;
  display: flex;
  border-radius: 8px;
  background: rgb(${({ theme }) => theme.extraFluentComponentStyles.calloutBackground});
  box-shadow: ${({ theme }) => theme.extraFluentComponentStyles.calloutShadow};
  overflow: hidden;
  transform-origin: 0 0;
  visibility: ${({ $hasContentSize }) => ($hasContentSize ? 'initial' : 'hidden')};
  animation: appear-${({ $dir }) => $dir} 0.3s cubic-bezier(0, 0, 0, 1);

  @keyframes appear-left {
    from {
      transform: translateX(1em);
      opacity: 0;
    }
  }
  @keyframes appear-right {
    from {
      transform: translateX(-1em);
      opacity: 0;
    }
  }
  @keyframes appear-top {
    from {
      transform: translateY(1em);
      opacity: 0;
    }
  }
  @keyframes appear-bottom {
    from {
      transform: translateY(-1em);
      opacity: 0;
    }
  }

  .c-callout-contents {
    font-size: 14px;
    max-height: calc(100svh - 1em);
    overflow: auto;
  }
`;

const InnerCallout = forwardRef<ICallout2, Omit<ICallout2Props, 'open'>>(function InnerCallout(
  { className, target, directionalHint, gapSpace, coverTarget, onDismiss, children },
  ref
) {
  const [targetRect, setTargetRect] = useState<IRect | null>(null);
  const [contentSize, setContentSize] = useState<ISize>({ width: 0, height: 0 });
  const [windowSize, setWindowSize] = useState<ISize>({
    width: window.innerWidth,
    height: window.innerHeight
  });

  const nodeRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const currentNode = nodeRef.current;
  const createdTime = useRef(Date.now());

  useEffect(() => {
    const isInNode = (node: Element) => {
      if (node === currentNode) return true;
      if (node.parentNode) return isInNode(node.parentNode as Element);
      return false;
    };

    const onClick = (event: MouseEvent) => {
      if (createdTime.current > Date.now() - 200) return;
      if (isInNode(event.target as Element)) return;
      onDismiss();
    };

    const onScroll = (event: WheelEvent) => {
      if (createdTime.current > Date.now() - 200) return;
      if (isInNode(event.target as Element)) return;
      onDismiss();
    };

    window.addEventListener('click', onClick);
    window.addEventListener('wheel', onScroll);
    return () => {
      window.removeEventListener('click', onClick);
      window.removeEventListener('wheel', onScroll);
    };
  }, [onDismiss, currentNode]);

  const currentTarget = target instanceof DOMRect ? target : target.current;
  const updateTargetRect = useCallback(() => {
    if (currentTarget instanceof DOMRect) {
      setTargetRect({
        x: currentTarget.left,
        y: currentTarget.top,
        width: currentTarget.width,
        height: currentTarget.height
      });
      return;
    }

    if (currentTarget) {
      const rect = currentTarget.getBoundingClientRect();
      setTargetRect({
        x: rect.left,
        y: rect.top,
        width: rect.width,
        height: rect.height
      });
    } else {
      setTargetRect(null);
    }
  }, [currentTarget]);

  useLayoutEffect(updateTargetRect, [updateTargetRect]);

  useEffect(() => {
    const onResize = () => {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
      updateTargetRect();
    };
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [updateTargetRect]);

  const contentNode = contentRef.current;

  useEffect(() => {
    if (!contentNode) return () => undefined;
    setContentSize(contentNode.getBoundingClientRect());

    const ro = new ResizeObserver(() => {
      setContentSize(contentNode.getBoundingClientRect());
    });
    ro.observe(contentNode);

    return () => ro.disconnect();
  }, [contentNode]);

  useImperativeHandle(
    ref,
    () => {
      return {
        nodeRef,
        contentRef,
        updateTargetRect
      };
    },
    [updateTargetRect]
  );

  const pos = useMemo(() => {
    if (!targetRect) return null;

    return layoutCallout(
      targetRect,
      contentSize,
      windowSize,
      directionalHint ?? DEFAULT_DIR,
      gapSpace ?? DEFAULT_GAP,
      coverTarget
    );
  }, [targetRect, contentSize, windowSize, directionalHint, gapSpace, coverTarget]);

  const prevPos = useRef<IPoint | null>(null);
  const hasContentSize = contentSize.width + contentSize.height > 0;
  useLayoutEffect(() => {
    if (!nodeRef.current) return;
    if (!pos || !hasContentSize) {
      prevPos.current = null;
      return;
    }

    const didMove = prevPos.current?.x !== pos.x || prevPos.current?.y !== pos.y;
    if (!didMove) return;

    if (!prevPos.current) {
      prevPos.current = pos;
      return;
    }

    nodeRef.current.animate(
      [
        {
          offset: 0,
          transform: `translate(${prevPos.current.x - pos.x}px, ${prevPos.current.y - pos.y}px)`
        }
      ],
      {
        duration: 300,
        composite: 'add',
        easing: 'cubic-bezier(0, 0.4, 0, 1)'
      }
    );

    prevPos.current = pos;
  }, [hasContentSize, pos]);

  return (
    <Callout2Styled
      ref={nodeRef}
      className={className}
      $dir={getDir(directionalHint ?? DEFAULT_DIR)}
      $hasContentSize={hasContentSize}
      style={{ top: pos?.y, left: pos?.x }}
    >
      <FocusTrapZone isClickableOutsideFocusTrap className="c-callout-contents" ref={contentRef}>
        {children}
      </FocusTrapZone>
    </Callout2Styled>
  );
});
