import PropTypes from 'prop-types';
import { createContext } from 'react';
import { reactPlugin } from '../components/AppInsights';

const AppInsightsContext = createContext(reactPlugin);

function AppInsightsContextProvider({ children }) {
  return <AppInsightsContext.Provider value={reactPlugin}>{children}</AppInsightsContext.Provider>;
}

AppInsightsContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { AppInsightsContext, AppInsightsContextProvider };
