import Label from 'components/inputs/Label';
import { useEffect, useState } from 'react';
import {
  Rating as FabricRating,
  IRatingProps as IFabricRatingProps,
  concatStyleSets
} from '@fluentui/react';
import RatingStyled from './Rating.styles';

export interface IRatingProps {
  id?: string;
  /**
   * A label for the ratingfield
   */
  label?: string;
  /**
   * The icon that will be displayed to the left of the label
   */
  labelIconName?: string;
  /**
   * Maximum rating, defaults to 5, has to be \>= 0
   * @defaultvalue 5
   */
  maxRating: number;
  /**
   * Callback issued when the rating changes.
   */
  onChange?: (rating: number) => void;
  /**
   * Selected rating, has to be an integer between zero and maxRating
   */
  defaultValue?: number;
  /**
   * Description of the rating field.
   */
  description?: string;
  /**
   * Optional flag to mark rating control as readOnly / disabled
   * @defaultvalue false
   */
  disabled?: boolean;
  /**
   * Whether the associated form field is required or not
   * @defaultvalue false
   */
  required?: boolean;
  /**
   * Call to provide customized styling that will layer on top of the variant rules.
   * @defaultvalue false
   */
  styles?: IFabricRatingProps['styles'];
}

function Rating({
  id,
  defaultValue,
  description,
  disabled = false,
  label,
  labelIconName,
  maxRating = 5,
  onChange,
  required = false,
  styles
}: IRatingProps): JSX.Element {
  const [ratingState, setRatingState] = useState(defaultValue);

  useEffect(() => {
    setRatingState(defaultValue);
  }, [defaultValue]);

  function onRatingChange(_: React.FormEvent<HTMLElement>, newRating: number | undefined): void {
    if (newRating) {
      setRatingState(newRating);

      if (onChange) {
        onChange(newRating);
      }
    }
  }

  function getRatingFieldStyles(): IFabricRatingProps['styles'] {
    let fieldStyles: IFabricRatingProps['styles'] = {
      root: {
        position: 'relative',
        marginTop: 5,
        height: '32px',
        border: '1px solid #a19f9d',
        borderRadius: '3px',
        ':hover': {
          border: '1px solid black'
        }
      },
      ratingFocusZone: {
        paddingLeft: 3
      }
    };

    if (disabled) {
      const disabledStyles: IFabricRatingProps['styles'] = {
        root: {
          backgroundColor: '#f3f2f1',
          ':hover': { border: '1px solid #a19f9d' }
        }
      };

      fieldStyles = concatStyleSets(fieldStyles, disabledStyles);
    }

    if (styles) {
      fieldStyles = concatStyleSets(fieldStyles, styles);
    }

    return fieldStyles;
  }

  const ratingStyles = getRatingFieldStyles();

  return (
    <RatingStyled id={id}>
      <Label label={label} required={required} iconName={labelIconName} description={description} />
      <FabricRating
        allowZeroStars
        max={maxRating}
        onChange={onRatingChange}
        rating={ratingState}
        readOnly={disabled}
        styles={ratingStyles}
      />
    </RatingStyled>
  );
}

export default Rating;
