import { ITextItem, Uuid } from './base-types';
import {
  FieldAggregationType,
  ProcessOutcome,
  RouteConnectionType,
  RouteFieldVisibility,
  RouteStepAssignment,
  RouteStepType
} from '../../../types';
import { IRoleRef, ROLE, SPARSE_ROLE_REF } from './roles';
import { ITeamRef, IUserRef, SPARSE_TEAM_REF, SPARSE_USER_REF, TEAM, USER } from './users';
import { createFieldRefs, FieldRefSpec } from './utils';
import {
  createApiObjectDataFromPlainObject,
  IApiObjectCache,
  IApiObjectData,
  IApiObjectType,
  LocalApiObject,
  makeApiObjectVariantKey
} from '../object';
import {
  getProcessStepLinkRuleFieldRefsSparse,
  IProcessFieldRef,
  IProcessStepLinkRule,
  PROCESS_FIELD,
  SPARSE_PROCESS_FIELD_REF
} from './process-fields';
import { fetchJson } from '../../../services/api2';

/** no refs */
export interface IProcessTemplateStepBaseFields {
  id: Uuid;
  name: string | null;
  description: string | null;
  duration: number;
  sortOrder: number;
  stepType: RouteStepType;
  effort: number | null;
  successors: IProcessStepLink[] | null;
  predecessors: IProcessStepLink[] | null;
  routeDefinitionId: string | null;
  allNames: ITextItem[] | null;
  allDescriptions: ITextItem[] | null;
}

/** has refs */
export interface IProcessTemplateStepSuperFields {
  outcomes: string[] | null;
  allOutcomes: ITextItem[] | null;
  outcomeBehavior: GateOutcomeBehavior;
  resultVisibility: SurveyResultVisibility;
  processVisibility: SurveyProcessVisibility;
  processOutcome: ProcessOutcome;
  /** has refs */
  groups: IProcessTemplateFieldGroup[] | null;
  /** has refs */
  persons: IProcessStepDataPerson[] | null;
  waitForCompletion: boolean;
  /** has refs */
  personLinks: IProcessStepPersonLink[] | null;
  /** has refs */
  rules: IProcessStepLinkRule[];
  /** has refs */
  subProcesses: IProcessTemplateStepSubProcess[] | null;
  reminderSettings: IReminderSettings;
  autoCompletion: IAutoCompletionSettings;
  durationFieldId: Uuid | null;
  nameFieldId: Uuid | null;
  showPrevApprovals: boolean | null;
  submitButtonText: string | null;
}

/** has refs */
export interface IProcessTemplateStepStartFields {
  /** has refs */
  groups: IProcessTemplateFieldGroup[] | null;
}

export interface IProcessTemplateStepEndFields {
  /** Will be set automatically in BE code */
  processOutcome?: ProcessOutcome;
}

/** has refs */
export interface IProcessTemplateStepApprovalFields {
  /** has refs */
  groups: IProcessTemplateFieldGroup[];
  /** has refs */
  personLinks: IProcessStepPersonLink[];
  reminderSettings: IReminderSettings;
  outcomes: string[] | null;
  allOutcomes: ITextItem[] | null;
  outcomeBehavior: GateOutcomeBehavior;
  durationFieldId: Uuid | null;
  nameFieldId: Uuid | null;
  showPrevApprovals: boolean | null;
}

/** has refs */
export interface IProcessTemplateStepDataFields {
  /** has refs */
  persons: IProcessStepDataPerson[];
  waitForCompletion: boolean;
  reminderSettings: IReminderSettings;
  autoCompletion: IAutoCompletionSettings;
  durationFieldId: Uuid | null;
  nameFieldId: Uuid | null;
  /** has refs */
  subProcesses: IProcessTemplateStepSubProcess[];
  showPrevApprovals: boolean | null;
  submitButtonText: string | null;
  allSubmitButtonTexts: ITextItem[] | null;
}

/** has refs */
export interface IProcessTemplateStepSurveyFields {
  /** has refs */
  groups: IProcessTemplateFieldGroup[];
  /** has refs */
  personLinks: IProcessStepPersonLink[];
  resultVisibility: SurveyResultVisibility;
  processVisibility: SurveyProcessVisibility;
  reminderSettings: IReminderSettings;
  durationFieldId: Uuid | null;
  nameFieldId: Uuid | null;
  submitButtonText: string | null;
  allSubmitButtonTexts: ITextItem[] | null;
}

/** has refs */
export interface IProcessTemplateStepIntegrationFields {
  /** has refs */
  subProcesses: IProcessTemplateStepSubProcess[];
}

function getProcessTemplateStepSuperFieldsFieldRefsSparse(
  basePath: string,
  obj: IProcessTemplateStepSuperFields
): FieldRefSpec<unknown>[] {
  return [
    ...(obj.groups?.flatMap((group, index) =>
      getProcessTemplateFieldGroupFieldRefsSparse(`${basePath}groups/${index}/`, group)
    ) ?? []),
    ...(obj.persons?.flatMap((person, index) =>
      getProcessStepDataPersonFieldRefsSparse(`${basePath}persons/${index}/`, person)
    ) ?? []),
    ...(obj.personLinks?.flatMap((person, index) =>
      getProcessStepPersonLinkFieldRefsSparse(`${basePath}personLinks/${index}/`, person)
    ) ?? []),
    ...(obj.rules?.flatMap((rule, index) =>
      getProcessStepLinkRuleFieldRefsSparse(`${basePath}rules/${index}/`, rule)
    ) ?? []),
    ...(obj.subProcesses?.flatMap((process, index) =>
      getProcessTemplateStepSubProcessFieldRefsSparse(`${basePath}subProcesses/${index}/`, process)
    ) ?? [])
  ];
}

export enum GateOutcomeBehavior {
  FirstApplies = 1,
  MajorityApplies = 2,
  UnanimityNecessary = 3,
  AllDecideWithMajority = 4,
  AllDecideUnanimous = 5
}

/** Determines visibility of survey results. */
export enum SurveyResultVisibility {
  All = 1,
  Restricted = 2
}

/**
 * Determines visibility of the process the survey belongs to.
 * When restricted, survey assignees cannot access the process itself.
 */
export enum SurveyProcessVisibility {
  All = 1,
  Restricted = 2
}

/** has refs */
export interface IProcessTemplateStepSubProcess {
  id: Uuid;
  subDefinitionId: Uuid;
  /** has refs */
  outboundFields: IProcessStepFieldMapping[] | null;
  /** has refs */
  inboundFields: IProcessStepFieldMapping[] | null;
  /** has refs */
  startRules: IProcessStepLinkRule[];
  /** has refs */
  repeatRules: IProcessStepLinkRule[];
  repeat: boolean;
  startAsSystem: boolean;
  waitForCompletion: boolean;
  /** @see SubProcessBreakType */
  breakType: number;
}

export const SubProcessBreakType = {
  NoBreak: 0,
  BreakParentOnDecline: 1
};

export function getProcessTemplateStepSubProcessFieldRefsSparse(
  basePath: string,
  obj: IProcessTemplateStepSubProcess
): FieldRefSpec<unknown>[] {
  return [
    ...(obj.inboundFields?.flatMap((field, index) =>
      getProcessStepFieldMappingFieldRefsSparse(`${basePath}inboundFields/${index}/`, field)
    ) ?? []),
    ...(obj.outboundFields?.flatMap((field, index) =>
      getProcessStepFieldMappingFieldRefsSparse(`${basePath}outboundFields/${index}/`, field)
    ) ?? []),
    ...(obj.startRules?.flatMap((rule, index) =>
      getProcessStepLinkRuleFieldRefsSparse(`${basePath}startRules/${index}/`, rule)
    ) ?? []),
    ...(obj.repeatRules?.flatMap((rule, index) =>
      getProcessStepLinkRuleFieldRefsSparse(`${basePath}repeatRules/${index}/`, rule)
    ) ?? [])
  ];
}

/** has refs */
export interface IProcessStepFieldMapping {
  id: Uuid | null;
  ownField: IProcessFieldRef | null;
  foreignFieldId: Uuid | null;
  foreignFieldName: string | null;
}

function getProcessStepFieldMappingFieldRefsSparse(
  basePath: string,
  obj: IProcessStepFieldMapping
): FieldRefSpec<unknown>[] {
  return [
    {
      path: `${basePath}ownField`,
      type: PROCESS_FIELD,
      sparseRef: SPARSE_PROCESS_FIELD_REF,
      params: () => obj.ownField?.id
    }
  ];
}

/** no refs */
export interface IReminderSettings {
  reminder: IReminderSettingsItem;
  firstEscalation: IReminderSettingsItem;
  secondEscalation: IReminderSettingsItem;
}

/** no refs */
export interface IAutoCompletionSettings {
  daysAfterDueDate: number | null;
}

/** no refs */
export interface IReminderSettingsItem {
  startDay: number;
  repeatAfter: number;
}

/** has refs */
export interface IProcessStepDataPerson {
  id: Uuid | null;
  role: IRoleRef | null;
  user: IUserRef | null;
  team: ITeamRef | null;
  userFieldId: string | null;
  assignment: RouteStepAssignment;
  /** has refs */
  rules: IProcessStepLinkRule[];
  taskData: IProcessStepTaskData;
  /** has refs */
  groups: IProcessTemplateFieldGroup[] | null;
}

function getProcessStepDataPersonFieldRefsSparse(
  basePath: string,
  obj: IProcessStepDataPerson
): FieldRefSpec<unknown>[] {
  return [
    { type: ROLE, sparseRef: SPARSE_ROLE_REF, path: `${basePath}role`, params: () => obj.role?.id },
    {
      type: USER,
      sparseRef: SPARSE_USER_REF,
      path: `${basePath}user`,
      params: () => obj.user?.userId
    },
    { type: TEAM, sparseRef: SPARSE_TEAM_REF, path: `${basePath}team`, params: () => obj.team?.id },
    ...(obj.rules?.flatMap((rule, index) =>
      getProcessStepLinkRuleFieldRefsSparse(`${basePath}rules/${index}/`, rule)
    ) ?? []),
    ...(obj.groups?.flatMap((rule, index) =>
      getProcessTemplateFieldGroupFieldRefsSparse(`${basePath}groups/${index}/`, rule)
    ) ?? [])
  ];
}

/** has refs */
export interface IProcessTemplateFieldGroup {
  id: Uuid | null;
  name: string | null;
  allNames: ITextItem[] | null;
  description: string | null;
  allDescriptions: ITextItem[] | null;
  /** has refs */
  fieldLinks: IProcessStepFieldLink[] | null;
  /** has refs */
  rules: IProcessStepLinkRule[];
  customCode: string | null;
}

function getProcessTemplateFieldGroupFieldRefsSparse(
  basePath: string,
  obj: IProcessTemplateFieldGroup
): FieldRefSpec<unknown>[] {
  return [
    ...(obj.fieldLinks?.flatMap((link, index) =>
      getProcessStepFieldLinkFieldRefsSparse(`${basePath}fieldLinks/${index}/`, link)
    ) ?? []),
    ...(obj.rules?.flatMap((rule, index) =>
      getProcessStepLinkRuleFieldRefsSparse(`${basePath}rules/${index}/`, rule)
    ) ?? [])
  ];
}

/** has refs */
export interface IProcessStepFieldLink {
  id: Uuid | null;
  field: IProcessFieldRef | null;
  /** has refs */
  fieldGroup: IProcessFieldGroupDefinition | null;
  visibility: RouteFieldVisibility;
  /** @see FieldRelationType */
  relation: number;
  aggregation: FieldAggregationType;
  sortOrder: number;
  role: IRoleRef | null;
  user: IUserRef | null;
  team: ITeamRef | null;
  userFieldId: Uuid | null;
  value: unknown;
  assignment: RouteStepAssignment;
  /** has refs */
  rules: IProcessStepLinkRule[];
  taskData: IProcessStepTaskData | null;
  autoTagSettings: IAutoTagSettingsItem[] | null;
}

export const FieldRelationType = {
  None: 0,
  /** The value of this field is used as the route instance's name. */
  IsTitle: 1,
  /** This field is shown as a column in the route instances list. */
  InInstance: 1 << 1,
  /** The value of this field will be used in repeated process starts. Only used in start form */
  Repeat: 1 << 2
};

function getProcessStepFieldLinkFieldRefsSparse(
  basePath: string,
  obj: IProcessStepFieldLink
): FieldRefSpec<unknown>[] {
  return [
    {
      type: PROCESS_FIELD,
      sparseRef: SPARSE_PROCESS_FIELD_REF,
      path: `${basePath}field`,
      params: () => obj.field?.id
    },
    { type: ROLE, sparseRef: SPARSE_ROLE_REF, path: `${basePath}role`, params: () => obj.role?.id },
    {
      type: USER,
      sparseRef: SPARSE_USER_REF,
      path: `${basePath}user`,
      params: () => obj.user?.userId
    },
    { type: TEAM, sparseRef: SPARSE_TEAM_REF, path: `${basePath}team`, params: () => obj.team?.id },
    ...(obj.fieldGroup
      ? getProcessFieldGroupDefinitionFieldRefsSparse(`${basePath}fieldGroup/`, obj.fieldGroup)
      : []),
    ...(obj.rules?.flatMap((rule, index) =>
      getProcessStepLinkRuleFieldRefsSparse(`${basePath}rules/${index}/`, rule)
    ) ?? [])
  ];
}

/** no refs */
export interface IProcessStepTaskData {
  assignmentId: Uuid;
  name: string | null;
  allNames: ITextItem[] | null;
  description: string | null;
  allDescriptions: ITextItem[] | null;
  duration: number | null;
  effort: number | null;
  nameFieldId: Uuid | null;
  reminderSettings: IReminderSettings;
  autoCompletion: IAutoCompletionSettings;
}

/** no refs */
export interface IAutoTagSettingsItem {
  evocomFieldId: Uuid;
  mappedFieldId: Uuid;
  parentTagName: string | null;
  tagInstance: boolean;
  tagTask: boolean;
}

/** has refs */
export interface IProcessFieldGroupDefinition {
  id: Uuid;
  /** has refs */
  fields: IProcessFieldGroupDefinitionItem[] | null;
}

function getProcessFieldGroupDefinitionFieldRefsSparse(
  basePath: string,
  obj: IProcessFieldGroupDefinition
): FieldRefSpec<unknown>[] {
  return [
    ...(obj.fields?.flatMap((field, index) =>
      getProcessFieldGroupDefinitionItemFieldRefsSparse(`${basePath}fields/${index}/`, field)
    ) ?? [])
  ];
}

/** has refs (from superclass) */
export interface IProcessFieldGroupDefinitionItem {
  field: IProcessFieldRef;
  value: unknown;
  autoTagSettings: IAutoTagSettingsItem[] | null;
  aggregation: FieldAggregationType;
  // we'll pretend the other fields don't exist, because we should not be using them from here
}

function getProcessFieldGroupDefinitionItemFieldRefsSparse(
  basePath: string,
  obj: IProcessFieldGroupDefinitionItem
): FieldRefSpec<unknown>[] {
  return [
    {
      path: `${basePath}field`,
      type: PROCESS_FIELD,
      sparseRef: SPARSE_PROCESS_FIELD_REF,
      params: () => obj.field?.id
    }
  ];
}

export interface IProcessStepPersonLink {
  id: Uuid | null;
  role: IRoleRef | null;
  user: IUserRef | null;
  team: ITeamRef | null;
  userFieldId: Uuid | null;
  assignment: RouteStepAssignment;
  rules: IProcessStepLinkRule[];
}

function getProcessStepPersonLinkFieldRefsSparse(
  basePath: string,
  obj: IProcessStepPersonLink
): FieldRefSpec<unknown>[] {
  return [
    { type: ROLE, sparseRef: SPARSE_ROLE_REF, path: `${basePath}role`, params: () => obj.role?.id },
    {
      type: USER,
      sparseRef: SPARSE_USER_REF,
      path: `${basePath}user`,
      params: () => obj.user?.userId
    },
    { type: TEAM, sparseRef: SPARSE_TEAM_REF, path: `${basePath}team`, params: () => obj.team?.id },
    ...(obj.rules?.flatMap((rule, index) =>
      getProcessStepLinkRuleFieldRefsSparse(`${basePath}rules/${index}/`, rule)
    ) ?? [])
  ];
}

/** no refs */
export interface IProcessStepLink {
  id: Uuid;
  branch: RouteConnectionType;
}

type IProcessTemplateStepAnyFields =
  | IProcessTemplateStepStartFields
  | IProcessTemplateStepEndFields
  | IProcessTemplateStepApprovalFields
  | IProcessTemplateStepDataFields
  | IProcessTemplateStepSurveyFields
  | IProcessTemplateStepIntegrationFields;

/** A process template step. Note that all field refs are sparse! */
export type IProcessTemplateStep =
  | IProcessTemplateStepStart
  | IProcessTemplateStepEnd
  | IProcessTemplateStepApproval
  | IProcessTemplateStepData
  | IProcessTemplateStepSurvey
  | IProcessTemplateStepIntegration;

export type IProcessTemplateStepStart = IApiObjectData &
  IProcessTemplateStepBaseFields & {
    stepType: RouteStepType.Start;
  } & IProcessTemplateStepStartFields;

export type IProcessTemplateStepEnd = IApiObjectData &
  IProcessTemplateStepBaseFields & { stepType: RouteStepType.End } & IProcessTemplateStepEndFields;

export type IProcessTemplateStepApproval = IApiObjectData &
  IProcessTemplateStepBaseFields & {
    stepType: RouteStepType.Approval;
  } & IProcessTemplateStepApprovalFields;

export type IProcessTemplateStepData = IApiObjectData &
  IProcessTemplateStepBaseFields & {
    stepType: RouteStepType.Data;
  } & IProcessTemplateStepDataFields;

export type IProcessTemplateStepSurvey = IApiObjectData &
  IProcessTemplateStepBaseFields & {
    stepType: RouteStepType.Survey;
  } & IProcessTemplateStepSurveyFields;

export type IProcessTemplateStepIntegration = IApiObjectData &
  IProcessTemplateStepBaseFields & {
    stepType: RouteStepType.Integration;
  } & IProcessTemplateStepIntegrationFields;

export const PROCESS_TEMPLATE_STEP: IApiObjectType<
  { id: Uuid; version?: string },
  IProcessTemplateStep
> = {
  id: 'Route/Definition/Step',
  createRefs(cache: IApiObjectCache, data: IProcessTemplateStep): void {
    createFieldRefs(
      cache,
      data,
      getProcessTemplateStepSuperFieldsFieldRefsSparse(
        '',
        data as IProcessTemplateStepAnyFields as IProcessTemplateStepSuperFields
      )
    );
  },
  async load(
    cache: IApiObjectCache,
    params: { id: Uuid; version?: string },
    abort: AbortSignal
  ): Promise<IProcessTemplateStep> {
    const { id, version } = params;

    const result: LocalApiObject<IProcessTemplateStep> = await fetchJson({
      url: version
        ? `Route/Definition/Step/${id}?version=${version}`
        : `Route/Definition/Step/${id}`,
      abort
    });

    return createApiObjectDataFromPlainObject(
      cache,
      makeApiObjectVariantKey(PROCESS_TEMPLATE_STEP, params),
      result
    ) as IProcessTemplateStep;
  }
};
