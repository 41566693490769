import PropTypes from 'prop-types';
import { createRef, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import fetchRequest from 'services/api';
import { checkScreenWidth } from 'utils/helpers';
import {
  ComboBox,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton,
  TextField
} from '@fluentui/react';

function CreateLocationDialog({ hidden, onCancel, onConfirm, countries, location }) {
  const { t } = useTranslation();
  const [comboCountries, setComboCountries] = useState(null);
  const [newLocation, setNewLocation] = useState(location);
  const comboCountryRef = createRef();
  const [toFocusCombo, setToFocusCombo] = useState(true);

  const convertToDropDownObject = useCallback(() => {
    const countryOptions = countries.map((country) => {
      return {
        ...country,
        text: country.name,
        key: country.id
      };
    });
    return countryOptions;
  }, [countries]);

  useEffect(() => {
    if (!comboCountries && countries) {
      const dropDownCountriesObjects = convertToDropDownObject();
      setComboCountries(dropDownCountriesObjects);
    }
  }, [comboCountries, convertToDropDownObject, countries]);

  const setInitialLocation = useCallback(() => {
    if (!newLocation) {
      if (location) {
        setNewLocation(location);
      } else {
        setNewLocation({
          name: '',
          code: '',
          country: null
        });
      }
    }
  }, [newLocation, location]);

  useEffect(() => {
    setInitialLocation();
  }, [setInitialLocation]);

  function close() {
    onCancel();
  }

  async function saveLocation() {
    const body = JSON.stringify(newLocation);
    let method = 'POST';
    if (newLocation.id) {
      method = 'PUT';
    }
    let error;
    const locationFromDB = await fetchRequest({
      url: `Location`,
      method,
      body
    });

    if (!error) {
      setNewLocation(locationFromDB);
      if (newLocation.id) {
        onConfirm(newLocation);
      } else {
        onConfirm(locationFromDB);
      }
    }
  }

  function getDialogStyles() {
    return {
      main: [
        {
          selectors: {
            '@media (min-width: 650px)': {
              width: '100%',
              minWidth: '550px',
              minHeight: '200px'
            },
            '@media (max-width: 480px)': [
              {
                position: 'absolute',
                top: '10px',
                width: '100%',
                minHeight: '250px',
                selectors: {
                  '.ms-Dialog-header': [
                    {
                      selectors: {
                        '.ms-Dialog-title': {
                          padding: '11px',
                          fontSize: '16px',
                          paddingRight: '40px'
                        },
                        'div[class^="topButton-"]': {
                          padding: '5px'
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        }
      ]
    };
  }

  async function handleKeyPress(e) {
    if (
      e.key === 'Enter' &&
      newLocation &&
      newLocation.name &&
      newLocation.code &&
      newLocation.country &&
      newLocation.country.id
    ) {
      saveLocation();
    }
  }

  function handleName(value) {
    const tempLocation = { ...newLocation, name: value };
    setNewLocation(tempLocation);
  }

  function handleCode(value) {
    const tempLocation = { ...newLocation, code: value };
    setNewLocation(tempLocation);
  }

  function handleCountry(value) {
    const tempLocation = { ...newLocation, country: value };
    setNewLocation(tempLocation);
  }

  function onMenuOpen() {
    if (checkScreenWidth(['extraSmall'])) {
      const comboRef = comboCountryRef;
      if (toFocusCombo) {
        if (comboRef && comboRef.current) {
          const comboBox = { ...comboRef.current };
          comboRef.current.dismissMenu(true);
          setToFocusCombo(false);
          setTimeout(() => {
            if (comboBox) {
              comboBox.focus(true);
            }
          }, 400);
        }
      } else {
        setToFocusCombo(true);
      }
    }
  }

  return (
    <Dialog
      hidden={hidden}
      onDismiss={() => close()}
      styles={getDialogStyles}
      dialogContentProps={{
        type: DialogType.close,
        title:
          location && location.id
            ? t('createLocation.dialog.titleEdit')
            : t('createLocation.dialog.title')
      }}
      modalProps={{
        isBlocking: true
      }}
    >
      <TextField
        label={t('createLocation.dialog.name.label')}
        required
        defaultValue={newLocation && newLocation.name ? newLocation.name : ''}
        onChange={(_, value) => handleName(value)}
        onKeyPress={handleKeyPress}
        autoFocus
      />
      <TextField
        label={t('createLocation.dialog.code.label')}
        required
        defaultValue={newLocation && newLocation.code ? newLocation.code : ''}
        onChange={(_, value) => handleCode(value)}
        onKeyPress={handleKeyPress}
      />
      <ComboBox
        componentRef={comboCountryRef}
        allowFreeform
        autoComplete="on"
        styles={{ container: { width: '100%' } }}
        calloutProps={{
          calloutMaxHeight: checkScreenWidth(['extraSmall']) ? 110 : 250,
          coverTarget: false
        }}
        label={t('createLocation.dialog.country.label')}
        onChange={(_, value) => handleCountry(value)}
        options={comboCountries || []}
        placeholder={t('createLocation.dialog.country.placeholder')}
        required
        selectedKey={newLocation && newLocation.country ? newLocation.country.id : null}
        onMenuOpen={checkScreenWidth(['extraSmall']) ? () => onMenuOpen('department') : null}
      />
      <DialogFooter>
        <PrimaryButton
          disabled={
            !(
              newLocation &&
              newLocation.name &&
              newLocation.code &&
              newLocation.country &&
              newLocation.country.id
            )
          }
          onClick={() => saveLocation()}
          text={t('createLocation.dialog.button.save')}
        />
        <DefaultButton onClick={() => close()} text={t('createLocation.dialog.button.cancel')} />
      </DialogFooter>
    </Dialog>
  );
}

CreateLocationDialog.propTypes = {
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
  hidden: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  location: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    code: PropTypes.string,
    country: PropTypes.oneOfType([PropTypes.object])
  })
};

CreateLocationDialog.defaultProps = {
  hidden: true,
  location: null
};

export default CreateLocationDialog;
