import { createGlobalStyle } from 'styled-components';
import { getThemeStylesAsCss } from 'utils/theme';

const GlobalStyle = createGlobalStyle`
  :root {
    ${(props) => getThemeStylesAsCss(props.themeConfig)}
  }

  * {
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    margin: 0;
    padding: 0;
  }

  html {
    line-height: inherit;
    -ms-text-size-adjust: inherit;
    -webkit-text-size-adjust: inherit;
    -webkit-tap-highlight-color: transparent;
  }

  body {
    margin: 0;
    padding: 0;
    background: rgb(${({ theme }) => theme.app.background});
    color: rgb(${({ theme }) => theme.app.foreground});
 }

  html,
  body {
    height: 100%;
    overflow: hidden;
  }

  a {
    text-decoration: none;
    color: rgb(${({ theme }) => theme.app.anchorForeground});

    &:hover {
      text-decoration: underline;
    }
  }

  ::-webkit-scrollbar {
    background-color: transparent;
    width: 14px;
    margin: 10px;
  }

  ::-webkit-scrollbar-thumb {
      background-color: rgb(${({ theme }) => theme.app.scrollbarThumb});
      border-radius: 16px;
      background-clip: content-box;
      border: 4px solid transparent;
  }

  ::-webkit-scrollbar-thumb:hover {
      background-color: rgb(${({ theme }) => theme.app.scrollbarThumbHover});
  }

  :focus,
  :active {
    outline: 0 !important;
  }

  ol, ul {
    margin: 0;
  }

  .sticky {
    top: 0px;
    position: sticky;
    z-index: 20;
    width: 100%;
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
     .sticky {
     position: absolute;
     top: auto;
    }

     .panel-body-wrapper {
      padding-top: 45px;
    }
  }

  .ms-Fabric {
      /** this is hardcoded in fluent-ui for some reason */
      color: rgb(${({ theme }) => theme.extraFluentComponentStyles.fabricForeground});
  }

  .ms-CalendarDay-dayIsToday {
    background-color: ${(props) => props.theme.themeLighter} !important;
    color: #323130  !important;
    font-weight: 300 !important;
  }

  .ms-Callout:not(.ms-TeachingBubble) {
    border-radius: 8px;
    overflow: hidden;
  }

  .ms-Callout {
    border-radius: 8px;
    box-shadow: ${({ theme }) => theme.extraFluentComponentStyles.calloutShadow};
  }
  .ms-Callout,
  .ms-Callout-main,
  .ms-Callout-beakCurtain,
  .ms-Callout-main > .ms-ContextualMenu-container > .ms-ContextualMenu  {
    background: rgb(${({ theme }) => theme.extraFluentComponentStyles.calloutBackground});
  }

  .ms-ContextualMenu-list {
    padding:  4px;
  }
  
  .ms-ContextualMenu-link {
    overflow: hidden;
    border-radius: 4px;
  }
  
  .ms-OverflowSet-item {
    overflow: hidden;
    border-radius: 4px;
    height: auto;
    margin: 5px;
  }

  .ms-CalendarDay-daySelected {
    background-color: ${(props) => props.theme.themePrimary} !important;
    color: white  !important;
    font-weight: 600 !important;

    &:hover { 
    background-color: ${(props) => props.theme.themeTertiary} !important;
    color: white  !important;
    }
  }


  .ms-Button.ms-Button--icon.ms-Panel-closeButton.ms-PanelAction-close {
    &:hover {
      background: rgba(255,255,255, 0.1);
    }

    margin-right: 10px;
    color: white;
    z-index: 1;
  }

  .ms-Dialog-subText{
    padding-top: 8px;
  }

  .ms-Dialog-title {
    background-color: rgb(${({ theme }) =>
      theme.extraFluentComponentStyles.dialogHeaderBackground});
      color: rgb(${({ theme }) => theme.extraFluentComponentStyles.dialogHeaderForeground});
  }

  .ms-Dialog-header .ms-Button-icon {
    color: white;
  }

  .pivot-item-height {
    height: 100%;
    overflow-y: auto;
  }

  .minimap-viewport {
    background-color: rgba(0, 0, 0, 0.2);
  }

  .minimap {
    right: 10px !important;
    z-index: 300 !important;
    background-color: #eaeaea !important;
    border: 1px solid #c8c8c8 !important;
  }

  .minimap-container {
    width: 100% !important;
    overflow: auto !important;
  }

  .minimap-children {
    background: #a6a6a6 !important;
    border: 1px solid #a6a6a6 !important;
  }

  .ms-Dialog-button:hover {
    background-color: ${(props) => props.theme.themeSecondary};
  }

  .details-list-wrapper {

    .ms-DetailsRow-fields {
      color: rgb(${({ theme }) => theme.detailsList.fieldForeground});
    }

    .ms-DetailsRow {
      background-color: rgb(${({ theme }) => theme.detailsList.rowBackground});
      border-bottom-color: rgb(${({ theme }) => theme.detailsList.dividerLine});
    }

    .ms-DetailsRow:hover {
      background-color: rgb(${({ theme }) => theme.detailsList.rowBackgroundHover});
      border-bottom-color: rgb(${({ theme }) => theme.detailsList.rowBackgroundHover});
    }

    .ms-DetailsHeader {
      background-color: rgb(
        ${({ theme }) => theme.detailsList.headerBackground} /
        ${({ theme }) => theme.detailsList.headerBackgroundOpacity});
      border-bottom-color: rgb(${({ theme }) => theme.detailsList.headerDividerLine});
    }
  }

  #typeahead-menu {
    z-index: 9999999;
    padding-top: 25px;
  }

  .full-height {
    height: 100%;
  }

  .full-width {
    width: 100%;
  }

  .half-width {
    width: 100%;
  }

  .relative {
    position: relative;
  }

  .absolute {
    position: absolute;
  }

  .flex {
    display: flex;
  }

  .block {
    display: block;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .flex-no-shrink {
    flex-shrink: 0;
  }

  .space-between {
    justify-content: space-between;
  }

  .justify-center {
    justify-content: center;
  }

  .pr-1 {
    padding-right: 0.25rem;
  }

  .pl-1 {
    padding-left: 0.25rem;
  }

  .pl-2 {
    padding-left: .5rem;
  }

  .pt-1 {
    padding-top: 0.25rem;
  }

  .pt-2 {
    padding-top: 0.5rem;
  }

  .mb-1 {
    margin-bottom: 0.25rem;
  }

  .mb-2 {
    margin-bottom: 0.5rem;
  }

  .mt-1 {
    margin-top: 0.25rem;
  }

  .mt-2 {
    margin-top: 0.5rem;
  }

  .ml-1 {
    margin-left: .25rem;
  }

  .mr-1 {
    margin-right: 0.25rem;
  }

  .mr-2 {
    margin-right: .5rem;
  }

  .m-5 {
    margin: 1rem;
  }

  .ml-auto {
    margin: 0 auto;
  }

  .pin-b {
    bottom: 0;
  }
`;

export default GlobalStyle;
