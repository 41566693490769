import SmallTextField from 'components/inputs/SmallTextField';
import { useTranslation } from 'react-i18next';
import { IFieldLinkProps, RouteFieldVisibility } from 'types';
import { getFluentIconNameByFieldType } from 'utils/helpers';
import { useTheme } from 'styled-components';
import { getTextFieldStyles } from './DynamicField.styles';

export interface IDynamicSmallTextFieldProps {
  disabled?: boolean;
  fieldLink: IFieldLinkProps;
  onChange: (value?: string) => void;
}

function DynamicSmallTextField({ fieldLink, disabled, onChange }: IDynamicSmallTextFieldProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  if (!fieldLink.field) return null;

  const { field, visibility } = fieldLink;
  const { name, description, descriptionPlacement, id, maxLength } = field;
  const defaultValue = fieldLink.value as string | undefined;

  const displayDescriptionTooltip = descriptionPlacement === 1;

  const readOnly = visibility === RouteFieldVisibility.ReadOnly;
  const required = visibility === RouteFieldVisibility.Required;

  const context = disabled ? 'disabled' : undefined;

  return (
    <SmallTextField
      defaultValue={defaultValue}
      description={displayDescriptionTooltip ? description : undefined}
      disabled={disabled || readOnly}
      id={id}
      label={name}
      labelIconName={getFluentIconNameByFieldType(fieldLink.field)}
      maxLength={maxLength}
      maxSingleLineChars={90}
      onChange={onChange}
      placeholder={t('dynamicField.smallText.placeholder', { context })}
      required={required}
      styles={getTextFieldStyles(theme)}
    />
  );
}

export default DynamicSmallTextField;
