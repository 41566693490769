import RichTextEditor from 'components/inputs/RichTextEditor';
import {
  FunctionComponent,
  ReactNode,
  createContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  useContext
} from 'react';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { generateUuid, getFluentIconNameByFieldType } from 'utils/helpers';
import {
  ActionButton,
  DirectionalHint,
  Icon,
  IconButton,
  Label,
  TagPicker,
  TooltipHost,
  IBasePicker,
  ITag,
  IPickerItemProps,
  ISuggestionItemProps,
  IBasePickerProps
} from '@fluentui/react';
import FieldEditDialog from 'components/inputs/FieldPicker/FieldEditDialog';
import { useId } from '@fluentui/react-hooks';
import {
  ConfirmationFieldFormat,
  IProcessField,
  IProcessFieldGroup,
  PROCESS_FIELD,
  PROCESS_FIELD_GROUP,
  PROCESS_FIELD_GROUPS,
  PROCESS_FIELDS,
  useApiDataCache,
  useApiObject
} from '../../../hooks/api2';
import { ChoiceFieldFormat, DateTimeFieldFormat, RouteFieldType } from '../../../types';
import { FieldId } from '../model';
import InteractiveTooltipHost from '../../../components/surfaces/InteractiveTooltipHost';

const SuggestionItemStyled = styled.div`
  padding: 7px;
  text-align: left;
  display: flex;
  justify-content: space-between;

  .field-group-warning-icon {
    margin-top: 8px;
  }

  .create-tag {
    padding-right: 5px;
    padding-left: 2px;
    color: rgb(${({ theme }) => theme.processDesignerComponents.pickerCreateSuggestionForeground});
  }

  .field-type-text {
    color: rgb(${({ theme }) => theme.processDesignerComponents.pickerSecondaryForeground});
    font-weight: 400;
    font-size: 12px;
    text-align: left;
  }
`;

const CalloutContentWrapper = styled.div`
  margin: 7px;

  .c-callout-content_field-name {
    font-size: 21px;
    font-weight: 300;
  }

  .c-callout-content_info-wrapper {
    margin-top: 6px;
  }

  .c-callout-content_info-header-2 {
    font-weight: 400;
    font-size: 14px;
  }

  .c-callout-content_info-text {
    font-weight: 300;
    font-size: 13px;
  }
`;

interface ITagExt extends ITag {
  linkId: LinkedFieldValue | 'create-field' | 'create-field-group';
  loadedField?: IProcessField;
  loadedFieldGroup?: IProcessFieldGroup;
}

const TagPicker2 = TagPicker as FunctionComponent<IBasePickerProps<ITagExt>>;
const TagPickerStyled = styled(TagPicker2)`
  .ms-BasePicker-text {
    border-color: rgb(${({ theme }) => theme.processDesignerComponents.pickerOutline});
  }
`;

export enum LinkedFieldType {
  Field = 'field',
  FieldGroup = 'field-group'
}

export type LinkedFieldValue =
  | {
      type: LinkedFieldType.Field;
      /** Field ID, or `data:<fieldName>` for a data field */
      id: string;
    }
  | {
      type: LinkedFieldType.FieldGroup;
      id: string;
    };

export interface IFieldPickerProps {
  prevUsedFields?: Set<FieldId>;
  value: LinkedFieldValue | null;
  onChange: (id: LinkedFieldValue | null) => void;
  focus?: boolean;
  label?: string;
  onBlur?: () => void;
  onFocus?: () => void;
  placeholder?: string;
  required?: boolean;
  selectOnly?: boolean;
  /** If true, we are picking a field for use in a survey, where several field types are disallowed. */
  isSurvey?: boolean;
  searchFields?: boolean;
  searchFieldGroups?: boolean;

  /** Allows filtering fields. If this callback returns false, the field will not be available for selection. */
  filterField?: (field: IProcessField) => boolean;
}

export const FieldPickerEditing = createContext<{
  beginEditing: (item: IProcessField | IProcessFieldGroup) => void;
}>({
  beginEditing: () => undefined
});

export default function FieldPicker({
  prevUsedFields = new Set(),
  value,
  onChange,
  focus = false,
  label = null,
  onBlur = null,
  onFocus = null,
  placeholder = null,
  required = false,
  selectOnly = true,
  isSurvey,
  searchFields = false,
  searchFieldGroups = false,
  filterField
}: IFieldPickerProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  const cache = useApiDataCache();

  const [, setResetFieldValue] = useState(0);

  const [editing, setEditing] = useState<
    Partial<IProcessField> | Partial<IProcessFieldGroup> | null
  >(null);
  const fieldPickerEditing = useMemo(() => ({ beginEditing: setEditing }), []);

  const [fieldPickerKey, setFieldPickerKey] = useState(null);

  useEffect(() => {
    if (!fieldPickerKey) {
      setFieldPickerKey(generateUuid());
    }
  }, [fieldPickerKey]);

  const pickerRef = useRef<IBasePicker<ITagExt>>();
  useEffect(() => {
    if (focus) {
      setTimeout(() => {
        // @TODO find solution without timeout
        pickerRef.current?.focusInput();
      });
    }
  }, [focus]);

  const resultsMaximumNumber = 50;

  const onEmptyResolveSuggestions = async (): Promise<ITagExt[]> => {
    return (await getDataFieldsSearchResults(''))
      .filter((field) => {
        if (isSurvey && 'fieldType' in field) {
          if (
            [
              RouteFieldType.Lookup,
              RouteFieldType.Location,
              RouteFieldType.Signature,
              RouteFieldType.Multimedia,
              RouteFieldType.ExternalData
            ].includes(field.fieldType)
          ) {
            return false;
          }
        }
        if (filterField && 'fieldType' in field) {
          return filterField(field);
        }
        return true;
      })
      .map((field) => ({
        key: field.id,
        name: field.name,
        linkId: { type: LinkedFieldType.Field, id: field.id }
      }));
  };

  const onFilterChanged = async (filterText: string): Promise<ITagExt[]> => {
    let results: (IProcessField | IProcessFieldGroup)[] = [];

    if (filterText) {
      const filteredFieldSearchResults = searchFields
        ? await getFieldsSearchResults(filterText)
        : [];

      const filteredFieldGroupSearchResults = searchFieldGroups
        ? await getFieldGroupsSearchResults(filterText)
        : [];

      // show full array if one result type is empty
      if (filteredFieldSearchResults.length === 0 || filteredFieldGroupSearchResults.length === 0) {
        results.push(...filteredFieldSearchResults, ...filteredFieldGroupSearchResults);
      }

      if (filteredFieldSearchResults.length > 0 && filteredFieldGroupSearchResults.length > 0) {
        // concat fieldGroups and fields array and always return 10 results
        // fieldGroups results are dynamic, depending on fields length

        const fieldsLimiter =
          filteredFieldGroupSearchResults?.length >= 5
            ? 5
            : 10 - (filteredFieldGroupSearchResults?.length || 0);
        const groupsLimiter =
          filteredFieldSearchResults?.length >= 5
            ? 5
            : 10 - (filteredFieldSearchResults?.length || 0);

        results = filteredFieldSearchResults.splice(0, fieldsLimiter);
        results = results.concat(filteredFieldGroupSearchResults.splice(0, groupsLimiter));
      }

      const isFilterTextInResults = !!results.find(
        (result) => result.name.toLowerCase() === filterText.toLowerCase()
      );

      if (isSurvey) {
        // remove lookup & location fields
        results = results.filter((field) => {
          if ('fieldType' in field) {
            return (
              field.fieldType !== RouteFieldType.Lookup &&
              field.fieldType !== RouteFieldType.Location &&
              field.fieldType !== RouteFieldType.Signature &&
              field.fieldType !== RouteFieldType.Multimedia
            );
          }
          return true;
        });
      }

      if (filterField) {
        results = results.filter((field) => {
          if ('fieldType' in field) {
            return filterField(field);
          }
          return true;
        });
      }

      const showCreateFieldOption = !selectOnly && !isFilterTextInResults;

      const tagResults: ITagExt[] = results.map((item) => {
        if ('fields' in item) {
          return {
            name: item.name,
            key: item.id,
            linkId: { type: LinkedFieldType.FieldGroup, id: item.id },
            loadedFieldGroup: item
          };
        }
        return {
          name: item.name,
          key: item.id,
          linkId: { type: LinkedFieldType.Field, id: item.id },
          loadedField: item
        };
      });

      if (showCreateFieldOption) {
        return [
          ...tagResults,
          { name: filterText, key: 'create-field', linkId: 'create-field' },
          { name: filterText, key: 'create-field-group', linkId: 'create-field-group' }
        ];
      }

      return tagResults;
    }

    return null;
  };

  const getFieldsSearchResults = async (filterText: string) => {
    if (!selectOnly) {
      const result = await cache.getListData(
        PROCESS_FIELDS,
        {
          itemsPerPage: resultsMaximumNumber,
          ...(filterText ? { searchTerms: filterText } : {})
        },
        0
      );
      return result.items;
    }

    return getDataFieldsSearchResults(filterText);
  };

  const getFieldGroupsSearchResults = async (filterText: string) => {
    if (selectOnly) return [];

    const result = await cache.getListData(
      PROCESS_FIELD_GROUPS,
      {
        itemsPerPage: resultsMaximumNumber,
        ...(filterText ? { searchTerms: filterText } : {})
      },
      0
    );
    return result.items;
  };

  const getDataFieldsSearchResults = async (filterText: string) => {
    const fields = await Promise.all(
      [...prevUsedFields].map((id) => cache.getData(PROCESS_FIELD, id))
    );

    fields.sort((a, b) => a.name.localeCompare(b.name));

    return fields.filter((field) => field.name.toLowerCase().includes(filterText.toLowerCase()));
  };

  const getTextFromItem = (item: ITagExt) => {
    return item.name;
  };

  const onItemSelected = (item: ITagExt) => {
    if (item.linkId === 'create-field') {
      setEditing({ fieldType: RouteFieldType.SmallText, name: item.name, maxLength: 150 });
      return null;
    }

    if (item.linkId === 'create-field-group') {
      setEditing({ name: item.name, fields: [] });
      return null;
    }

    return item;
  };

  const onDialogConfirm = (field: IProcessField | IProcessFieldGroup) => {
    onChange(
      'fieldType' in field
        ? { type: LinkedFieldType.Field, id: field.id }
        : { type: LinkedFieldType.FieldGroup, id: field.id }
    );
  };

  const valueAsField = value?.type === LinkedFieldType.Field ? value : null;
  const valueAsFieldGroup = value?.type === LinkedFieldType.FieldGroup ? value : null;

  const tagPickerSelectedItems = useMemo(() => {
    if (valueAsField) {
      return [{ key: valueAsField.id, name: valueAsField.id, linkId: valueAsField }];
    }
    if (valueAsFieldGroup) {
      return [{ key: valueAsFieldGroup.id, name: valueAsFieldGroup.id, linkId: valueAsFieldGroup }];
    }
    return [];
  }, [valueAsField, valueAsFieldGroup]);

  return (
    <div className="c-field-picker" key={fieldPickerKey}>
      {label ? <Label required={required}>{label}</Label> : null}
      <FieldPickerEditing.Provider value={fieldPickerEditing}>
        <TagPickerStyled
          key={`${fieldPickerKey}-tagpicker`}
          selectedItems={tagPickerSelectedItems}
          onRenderSuggestionsItem={(_, itemProps) => {
            if (isSurvey) {
              return <FieldPickerSuggestionItemForSurvey {...itemProps} />;
            }
            return <FieldPickerSuggestionItem {...itemProps} />;
          }}
          onItemSelected={onItemSelected}
          onResolveSuggestions={onFilterChanged}
          onEmptyResolveSuggestions={prevUsedFields.size ? onEmptyResolveSuggestions : null}
          onRenderItem={(props) => <FieldPickerTagItem {...props} />}
          resolveDelay={250}
          getTextFromItem={getTextFromItem}
          onChange={(value) => {
            setResetFieldValue((prevState) => prevState + 1);

            if (value[0]) {
              if (typeof value[0].linkId === 'object') onChange(value[0].linkId);
            } else {
              onChange(null);
            }
          }}
          itemLimit={1}
          componentRef={pickerRef}
          onBlur={onBlur}
          styles={{
            itemsWrapper: { paddingLeft: 3, paddingRight: 3 },
            text: {
              selectors: {
                '::after': value
                  ? { border: 'none' }
                  : {
                      pointerEvents: 'none',
                      content: '',
                      position: 'absolute',
                      left: '-1px',
                      top: '-1px',
                      bottom: '-1px',
                      right: '-1px',
                      borderWidth: '2px',
                      borderStyle: 'solid',
                      borderColor: `rgb(${theme.processDesignerComponents.pickerFocusOutline})`,
                      borderImage: 'initial',
                      borderRadius: '2px'
                    }
              }
            }
          }}
          inputProps={{ placeholder, onFocus }}
          pickerSuggestionsProps={{
            resultsMaximumNumber,
            suggestionsHeaderText: selectOnly
              ? t('formbuilder.fieldPicker.suggestionsHeaderText1')
              : t('formbuilder.fieldPicker.suggestionsHeaderText2'),
            noResultsFoundText: t('formbuilder.fieldPicker.noFields')
          }}
        />
      </FieldPickerEditing.Provider>

      <FieldEditDialog
        open={!!editing}
        onClose={() => setEditing(null)}
        isGroup={!!editing && 'fields' in editing}
        value={editing}
        onChange={onDialogConfirm}
      />
    </div>
  );
}

function getFieldTypeText(fieldType: RouteFieldType) {
  switch (fieldType) {
    case RouteFieldType.SmallText:
      return t('formbuilder.fieldPicker.smallText');
    case RouteFieldType.LongText:
      return t('formbuilder.fieldPicker.longText');
    case RouteFieldType.Number:
      return t('formbuilder.fieldPicker.number');
    case RouteFieldType.Boolean:
      return t('formbuilder.fieldPicker.boolean');
    case RouteFieldType.DateTime:
      return t('formbuilder.fieldPicker.dateTime');
    case RouteFieldType.Choice:
      return t('formbuilder.fieldPicker.choice');
    case RouteFieldType.Person:
      return t('formbuilder.fieldPicker.person');
    case RouteFieldType.Rating:
      return t('formbuilder.fieldPicker.rating');
    case RouteFieldType.Hyperlink:
      return t('formbuilder.fieldPicker.link');
    case RouteFieldType.Document:
      return t('formbuilder.fieldPicker.document');
    case RouteFieldType.ExternalData:
      return t('formbuilder.fieldPicker.externalData');
    case RouteFieldType.Lookup:
      return t('formbuilder.fieldPicker.lookup');
    case RouteFieldType.Location:
      return t('formbuilder.fieldPicker.location');
    case RouteFieldType.Signature:
      return t('formbuilder.fieldPicker.signature');
    case RouteFieldType.Multimedia:
      return t('formbuilder.fieldPicker.multimedia');
    case RouteFieldType.Scanner:
      return t('formbuilder.fieldPicker.scanner');
    case RouteFieldType.AI:
      return t('createField.dialog.field.types.ai');
    default:
      return '';
  }
}

export function FieldTooltip({
  item,
  showFieldGroupWarning = false,
  nonInteractive
}: {
  item: IProcessField | IProcessFieldGroup;
  showFieldGroupWarning?: boolean;
  nonInteractive?: boolean;
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { beginEditing } = useContext(FieldPickerEditing);

  const itemAsField = 'fieldType' in item ? item : null;
  const itemAsFieldGroup = 'fields' in item ? item : null;

  const {
    allowFillIn,
    appendChanges,
    choiceFormat,
    choices,
    dateFormat,
    fieldType,
    maxLength,
    confirmationFormat,
    numDecimals,
    numStars,
    textNOk,
    textOk
  } = itemAsField ?? {};

  const { fields } = itemAsFieldGroup ?? {};

  const { description, name } = item;

  let maxLengthInfo: ReactNode | undefined;
  let descriptionInfo: ReactNode | undefined;
  let numStarsInfo: ReactNode | undefined;
  let choiceInfo: ReactNode | undefined;
  let appendTeChangesInfo: ReactNode | undefined;
  let numDecimalsInfo: ReactNode | undefined;
  let dateTimeInfo: ReactNode | undefined;
  let fieldsInfo: ReactNode | undefined;
  let buttonTextPositive: ReactNode | undefined;
  let buttonTextNegative: ReactNode | undefined;

  if (description) {
    descriptionInfo = (
      <div className="c-callout-content_info-text" style={{ maxWidth: '300px' }}>
        <RichTextEditor
          disabled
          styles={{
            editor: {
              fontSize: '12px',
              color: `rgb(${theme.processDesignerComponents.pickerTooltipSecondaryForeground})`
            }
          }}
          defaultValue={description}
        />
      </div>
    );
  }

  const getInfoRow = (header, text) => {
    return (
      <div className="c-callout-content_info-wrapper">
        <div className="c-callout-content_info-header-2">{header}</div>
        <div className="c-callout-content_info-text">{text}</div>
      </div>
    );
  };

  const fieldTypeInfo = getInfoRow(
    t('formbuilder.fieldPicker.info.type'),
    <div>
      <Icon
        styles={{ root: { marginRight: '5px', fontSize: '12px' } }}
        iconName={getFluentIconNameByFieldType(itemAsField)}
      />
      {getFieldTypeText(fieldType)}
    </div>
  );

  if (maxLength) {
    maxLengthInfo = getInfoRow(t('formbuilder.fieldPicker.info.maxChars'), maxLength);
  }

  if (numStars) {
    numStarsInfo = getInfoRow(t('formbuilder.fieldPicker.info.numStars'), numStars);
  }

  if (fieldType === RouteFieldType.LongText) {
    appendTeChangesInfo = getInfoRow(
      t('formbuilder.fieldPicker.info.appendChanges.header'),
      appendChanges
        ? t('formbuilder.fieldPicker.info.appendChanges.yes')
        : t('formbuilder.fieldPicker.info.appendChanges.no')
    );
  }

  if (fieldType === RouteFieldType.Number) {
    numDecimalsInfo = getInfoRow(t('formbuilder.fieldPicker.info.numDecimal'), numDecimals);
  }

  if (fieldType === RouteFieldType.Boolean) {
    let label = t('createField.dialog.ok.label');

    if (confirmationFormat === ConfirmationFieldFormat.Checkbox) {
      label = t('createField.dialog.confirm.label');
    }

    let positiveText: ReactNode = textOk;

    if (confirmationFormat === ConfirmationFieldFormat.Checkbox) {
      positiveText = (
        <RichTextEditor
          disabled
          styles={{
            editor: {
              fontSize: '12px',
              color: `rgb(${theme.processDesignerComponents.pickerTooltipSecondaryForeground})`
            }
          }}
          defaultValue={textOk}
        />
      );
    }

    buttonTextPositive = getInfoRow(label, positiveText);

    if (confirmationFormat === ConfirmationFieldFormat.TwoButtons) {
      buttonTextNegative = getInfoRow(t('createField.dialog.nok.label'), textNOk);
    }
  }

  if (fieldType === RouteFieldType.DateTime) {
    dateTimeInfo = getInfoRow(
      t('formbuilder.fieldPicker.info.dateTime.header'),
      dateFormat === DateTimeFieldFormat.DateAndTime
        ? t('formbuilder.fieldPicker.info.dateTime.yes')
        : t('formbuilder.fieldPicker.info.dateTime.no')
    );
  }

  if (choiceFormat && choices?.length) {
    choiceInfo = (
      <>
        {getInfoRow(
          t('formbuilder.fieldPicker.info.choice.format'),
          choiceFormat === ChoiceFieldFormat.SingleSelect
            ? t('formbuilder.fieldPicker.info.choice.single')
            : t('formbuilder.fieldPicker.info.choice.multiple')
        )}
        {getInfoRow(
          t('formbuilder.fieldPicker.info.choice.allowFillIn.header'),
          allowFillIn
            ? t('formbuilder.fieldPicker.info.choice.allowFillIn.yes')
            : t('formbuilder.fieldPicker.info.choice.allowFillIn.no')
        )}
        {getInfoRow(
          t('formbuilder.fieldPicker.info.choice.choices'),
          <div style={{ maxHeight: '600px' }}>
            {choices.map((choice) => (
              <div key={choice.value}> - {choice.value}</div>
            ))}
          </div>
        )}
      </>
    );
  }

  if (fields) {
    fieldsInfo = (
      <div className="c-callout-content_info-text" style={{ maxWidth: '300px', margin: '5px 0' }}>
        <div className="c-callout-content_info-header-2">
          {t('formbuilder.fieldPicker.info.fields')}
        </div>
        {fields.map((link) =>
          link.field ? (
            <div key={`field-${link.field?.id}`} className="c-callout-content_info-text">
              - {link.field?.name}
            </div>
          ) : null
        )}
      </div>
    );
  }

  return (
    <CalloutContentWrapper>
      <span className="c-callout-content_field-name">{name}</span>
      {!nonInteractive && (
        <ActionButton
          text={t('formbuilder.fieldPicker.edit')}
          styles={{
            root: { color: `rgb(${theme.processDesignerComponents.pickerTooltipActionForeground})` }
          }}
          onClick={() => beginEditing(item)}
        />
      )}
      {descriptionInfo}
      {showFieldGroupWarning && (
        <div
          className="c-callout-content_info-text"
          style={{ marginTop: '5px', color: 'red', display: 'flex' }}
        >
          <Icon
            styles={{ root: { marginTop: '3px', marginRight: '8px', fontSize: '22px' } }}
            iconName="Warning"
          />
          <div>{t('formbuilder.fieldPicker.surveyWarning')}</div>
        </div>
      )}
      {fieldType ? fieldTypeInfo : null}
      {maxLengthInfo}
      {numStarsInfo}
      {choiceInfo}
      {buttonTextPositive}
      {buttonTextNegative}
      {appendTeChangesInfo}
      {numDecimalsInfo}
      {dateTimeInfo}
      {fieldsInfo}
    </CalloutContentWrapper>
  );
}

function FieldPickerTagItem({ item, onRemoveItem }: IPickerItemProps<ITagExt>) {
  const theme = useTheme();

  const linkId = typeof item.linkId === 'object' ? item.linkId : null;

  const { data: field } = useApiObject(
    PROCESS_FIELD,
    linkId?.type === LinkedFieldType.Field ? linkId.id : null,
    { revalidateOnMount: false }
  );
  const { data: fieldGroup } = useApiObject(
    PROCESS_FIELD_GROUP,
    linkId?.type === LinkedFieldType.FieldGroup ? linkId.id : null,
    { revalidateOnMount: false }
  );
  const itemData = linkId?.type === LinkedFieldType.Field ? field : fieldGroup;

  if (!linkId) return null;

  return (
    <InteractiveTooltipHost
      content={itemData ? <FieldTooltip item={itemData} /> : null}
      calloutProps={{
        gapSpace: 5,
        directionalHint: DirectionalHint.leftCenter
      }}
      style={{
        width: '100%',
        maxHeight: 24,
        backgroundColor: `rgb(${theme.processDesignerComponents.pickerTagItemBackground})`,
        color: `rgb(${theme.processDesignerComponents.pickerTagItemForeground})`,
        borderRadius: '3px'
      }}
    >
      <div style={{ justifyContent: 'space-between', display: 'flex', width: '100%' }}>
        <div
          style={{
            cursor: 'default',
            marginRight: 10,
            marginLeft: 10,
            paddingTop: 2,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
          }}
        >
          {itemData?.name}
        </div>
        <IconButton
          iconProps={{
            iconName: 'Cancel',
            styles: { root: { color: 'inherit' } }
          }}
          styles={{
            root: { maxHeight: 24, color: 'inherit' },
            rootHovered: {
              maxHeight: 24,
              backgroundColor: `rgb(${theme.processDesignerComponents.pickerTagItemForeground} / 0.1)`,
              color: 'inherit'
            }
          }}
          onClick={onRemoveItem}
        />
      </div>
    </InteractiveTooltipHost>
  );
}

function FieldPickerSuggestionItemForSurvey(props: ISuggestionItemProps<ITagExt>) {
  // warning in surveys
  let showFieldGroupWarning = false;

  const {
    suggestionModel: { item }
  } = props;

  if (Array.isArray(item.loadedFieldGroup?.fields)) {
    // check if there are location or lookup fields
    showFieldGroupWarning = !!item.loadedFieldGroup?.fields.find(
      ({ field }) => field.fieldType === 12 || field.fieldType === 13
    );
  }

  return <FieldPickerSuggestionItem {...props} showFieldGroupWarning={showFieldGroupWarning} />;
}

function FieldPickerSuggestionItem({
  suggestionModel,
  showFieldGroupWarning
}: ISuggestionItemProps<ITagExt> & { showFieldGroupWarning?: boolean }) {
  const id = useId();

  const { item } = suggestionModel;

  const alreadyLoadedItemData = item.loadedField ?? item.loadedFieldGroup;
  const linkId = 'linkId' in item && typeof item.linkId === 'object' ? item.linkId : null;
  const { data: loadedItemData } = useApiObject(
    PROCESS_FIELD,
    !alreadyLoadedItemData && linkId?.type === LinkedFieldType.Field ? linkId.id : null
  );
  const itemData = alreadyLoadedItemData ?? loadedItemData;

  if (item.linkId === 'create-field' || item.linkId === 'create-field-group') {
    const text =
      item.linkId === 'create-field'
        ? t('formbuilder.fieldPicker.create')
        : t('formbuilder.fieldPicker.createGroup');

    return (
      <SuggestionItemStyled>
        <div className="flex">
          <div className="create-tag">{text}</div>
          <div>{item.name}</div>
        </div>
      </SuggestionItemStyled>
    );
  }

  if (!itemData) return null;

  return (
    <TooltipHost
      delay={2}
      directionalHint={9}
      tooltipProps={{
        onRenderContent: () => (
          <FieldTooltip
            nonInteractive
            item={itemData}
            showFieldGroupWarning={showFieldGroupWarning}
          />
        )
      }}
      id={`${id}-suggestion-tooltip`}
      calloutProps={{ gapSpace: 10 }}
      styles={{ root: { width: '100%' } }}
    >
      <SuggestionItemStyled>
        <div>
          <div>{`${itemData.name}`}</div>
          <div className="field-type-text">
            {'fieldType' in itemData ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Icon
                  styles={{ root: { marginRight: '5px', fontSize: '12px' } }}
                  iconName={getFluentIconNameByFieldType(itemData)}
                />
                {getFieldTypeText(itemData.fieldType)}
              </div>
            ) : (
              <>{t('formbuilder.fieldPicker.fieldGroup')}</>
            )}
          </div>
        </div>
        {showFieldGroupWarning && (
          <div className="field-group-warning-icon">
            <Icon iconName="Warning" />
          </div>
        )}
      </SuggestionItemStyled>
    </TooltipHost>
  );
}
