import moment from 'moment';
import { t } from 'i18next';
import { IActivityProps, IGroupedActivityProps, IUserProps } from 'types';
import { FontIcon, List, Persona, TooltipHost } from '@fluentui/react';
import ActivitiesListStyled from './ActivitiesList.styles';
import Activity from './Activity';
import GroupedActivity from './GroupedActivity';

export interface ActivitiesListProps {
  activities?: IActivityProps[];
  groupedActivities?: IGroupedActivityProps[];
  handleOnActivityClick?: (activity: IActivityProps) => void;
  handleOnReadIconClick?: (activity: IActivityProps) => void;
  handleOnGroupedReadIconClick?: (groupedActivity: IGroupedActivityProps) => void;
  handleOnGroupedActivityClick?: (groupedActivity: IGroupedActivityProps) => void;
  containerContext?: string;
}

function getToolTipContent(activityCreator: IUserProps): JSX.Element | null {
  if (!activityCreator) {
    return null;
  }

  const { email, pictureUrl, name, userId } = activityCreator;

  return (
    <Persona
      key={userId}
      imageUrl={pictureUrl || undefined}
      text={name || ''}
      secondaryText={email || ''}
    />
  );
}

function getUserTooltip(user: IUserProps, activityId: string): JSX.Element {
  const calloutProps = { gapSpace: 0 };

  return (
    <TooltipHost
      delay={2}
      key={`activity-tool-tip-${user.userId + activityId}`}
      tooltipProps={{ onRenderContent: () => getToolTipContent(user) }}
      id={user.userId}
      calloutProps={calloutProps}
    >
      <span key={`activity-creator-name-${user.userId}`} className="activity-creator">
        {user?.name}
      </span>
    </TooltipHost>
  );
}

function getCreatorTooltip(activity: IActivityProps): JSX.Element {
  return getUserTooltip(activity.creator, activity.id);
}

export function callInterpolationFunction(
  functionName: string,
  activity: IActivityProps
): JSX.Element {
  const fallback = <span />;

  if (functionName === 'notificationCreator') {
    return getCreatorTooltip(activity);
  }

  if (functionName === 'newTaskAssignedTo') {
    if (activity.newTask?.assignedTo) {
      return getUserTooltip(activity.newTask.assignedTo, activity.id);
    }

    return fallback;
  }

  if (functionName === 'oldTaskAssignedTo') {
    if (activity.oldTask?.assignedTo) {
      return getUserTooltip(activity.oldTask.assignedTo, activity.id);
    }

    return fallback;
  }

  if (functionName === 'mentionedPerson') {
    if (activity.comment?.mentions?.length) {
      return getUserTooltip(activity.comment?.mentions[0], activity.id);
    }

    return fallback;
  }

  return fallback;
}

export function timeAgo(value: string, format?: string): string {
  let dateFormat = format;
  const date = moment(value).utc(true);
  const now = moment().startOf('second').utc(true);

  if (!format) {
    dateFormat = 'DD.MM.YYYY';
  }

  const startOfOneHourAgo = now.clone().add(-1, 'h');
  const startOfToday = now.clone().startOf('day');
  const startOfYesterday = now.clone().add(-1, 'd').startOf('day');

  let formattedTimeAgo = '';

  if (date.isBefore(startOfYesterday)) {
    formattedTimeAgo = moment(value).format(dateFormat);
  }

  if (date.isSameOrAfter(startOfYesterday) && date.isBefore(startOfToday)) {
    formattedTimeAgo = t('activitiesList.timeAgo.yesterday');
  }

  if (date.isSameOrAfter(startOfToday) && date.isBefore(startOfOneHourAgo)) {
    const diffHours = now.diff(date, 'hours');

    formattedTimeAgo =
      diffHours === 1
        ? t('activitiesList.timeAgo.hour', { hour: diffHours.toString() })
        : t('activitiesList.timeAgo.hours', { hours: diffHours.toString() });
  }

  if (date.isSameOrAfter(startOfOneHourAgo) && date.isSameOrBefore(now)) {
    const diffMinutes = now.diff(date, 'minutes');
    formattedTimeAgo =
      diffMinutes === 1
        ? t('activitiesList.timeAgo.minute', { minute: diffMinutes.toString() })
        : t('activitiesList.timeAgo.minutes', { minutes: diffMinutes.toString() });
  }

  return formattedTimeAgo;
}

function getActivityIconName(type: number): string {
  if (
    (type >= 0 && type <= 6) ||
    type === 34 ||
    (type >= 45 && type <= 54) ||
    (type >= 79 && type <= 83)
  ) {
    return 'TaskSolid';
  }

  if ((type >= 7 && type <= 10) || type === 84 || type === 85) {
    return 'Message';
  }

  if (type >= 11 && type <= 17) {
    return 'CheckList';
  }

  if (type >= 18 && type <= 20) {
    return 'FileImage';
  }

  if (type >= 18 && type <= 20) {
    return 'FileImage';
  }

  if (type >= 21 && type <= 29) {
    return 'Teamwork';
  }

  if (type >= 30 && type <= 33) {
    return 'FeedbackRequestSolid';
  }

  if (type >= 35 && type <= 37) {
    return 'ServerProcesses';
  }

  if (
    (type >= 38 && type <= 42) ||
    (type >= 73 && type <= 75) ||
    type === 86 ||
    type === 87 ||
    type === 155
  ) {
    return 'Processing';
  }

  if (type === 200) {
    return 'FieldChanged';
  }

  if (type === 43 || type === 44 || type === 55 || type === 56 || type === 69 || type === 76) {
    return 'ProcessMetaTask';
  }

  if ((type >= 57 && type <= 60) || type === 70) {
    return 'Checkbox';
  }

  if ((type >= 61 && type <= 64) || type === 71 || type === 77 || type === 201) {
    return 'Database';
  }

  if (type >= 65 || type <= 68 || type === 72 || type === 78) {
    return 'BarChartHorizontal';
  }

  return 'SingleColumnEdit';
}

export function getActivityIcon(type: number): JSX.Element {
  return (
    <FontIcon iconName={getActivityIconName(type)} className={getActivityIconClassName(type)} />
  );
}

function getActivityIconClassName(type: number): string {
  if ((type >= 57 && type <= 60) || type === 70) {
    return 'route-approval-icon';
  }

  return '';
}

export default function ActivitiesList({
  activities,
  groupedActivities,
  handleOnActivityClick,
  handleOnReadIconClick,
  handleOnGroupedReadIconClick,
  handleOnGroupedActivityClick,
  containerContext
}: ActivitiesListProps): JSX.Element | null {
  function onRenderCell(activity?: IActivityProps): JSX.Element | null {
    if (!activity) {
      return null;
    }

    return (
      <Activity
        activity={activity}
        callInterpolationFunction={callInterpolationFunction}
        containerContext={containerContext}
        getActivityIcon={getActivityIcon}
        handleOnActivityClick={handleOnActivityClick}
        handleOnReadIconClick={handleOnReadIconClick}
        key={activity.id}
        timeAgo={timeAgo}
      />
    );
  }

  function onRenderGroupCell(groupedActivity?: IGroupedActivityProps): JSX.Element | null {
    if (!groupedActivity || !groupedActivity.notifications.length) {
      return null;
    }

    return (
      <GroupedActivity
        key={groupedActivity.id}
        groupedActivity={groupedActivity}
        handleOnActivityClick={handleOnActivityClick}
        handleOnReadIconClick={handleOnReadIconClick}
        handleOnGroupedReadIconClick={handleOnGroupedReadIconClick}
        handleOnGroupedActivityClick={handleOnGroupedActivityClick}
        callInterpolationFunction={callInterpolationFunction}
        getActivityIcon={getActivityIcon}
        getCreatorTooltip={getCreatorTooltip}
        timeAgo={timeAgo}
      />
    );
  }

  let content = null;

  if (activities?.length) {
    content = <List items={activities} onRenderCell={onRenderCell} />;
  }

  if (groupedActivities?.length) {
    content = groupedActivities.map(onRenderGroupCell);
  }

  if (content) {
    return <ActivitiesListStyled>{content}</ActivitiesListStyled>;
  }

  return null;
}
