import PropTypes from 'prop-types';
import styled from 'styled-components';
import { projectPropType } from 'types';
import ProjectStartedProcesses from './ProjectStartedProcesses';

const ProjectTabStyled = styled.div`
  background-color: #f5f5f5;
  overflow: auto;
  padding: 1rem;
  width: 100%;
  height: 100%;
  padding-bottom: 3rem;

  .c-header {
    display: flex;
    font-weight: 300;
    font-size: 20px;
    margin-bottom: 10px;
    margin-right: 5px;
  }

  .c-template-wrapper {
  }
`;

const TemplatesListStyled = styled.div`
  .c-process-container {
    height: 100%;
    width: 100%;
    border-bottom: 1px solid #e9e9e9;
  }
`;

function ProjectProcessesPivotItem({ disabled, displayStartedProcesses = true, project = {} }) {
  return (
    <ProjectTabStyled>
      {displayStartedProcesses && (
        <TemplatesListStyled>
          <ProjectStartedProcesses disabled={disabled} project={project} />
        </TemplatesListStyled>
      )}
    </ProjectTabStyled>
  );
}

ProjectProcessesPivotItem.propTypes = {
  disabled: PropTypes.bool,
  displayStartedProcesses: PropTypes.bool,
  project: projectPropType.isRequired
};

export default ProjectProcessesPivotItem;
