import { SettingsContainer } from 'components';
import i18n from 'i18next';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getPagesLocations } from 'utils/helpers';
import { ComboBox, CommandBarButton, IconButton } from '@fluentui/react';

const PageRowsStyled = styled.div<{ hasItems: boolean }>`
  border-radius: 5px;
  overflow: hidden;
  margin-top: 15px;
  border: ${({ hasItems }) => (hasItems ? '1px solid #e5e5e5' : 'none')};
`;

const ContainerStyled = styled.div`
  display: flex;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 10px;
  align-items: center;
  background-color: #faf9f8;

  .c-column_item-icon {
    font-size: 20px;
    padding-right: 2px;
    padding-left: 2px;
    color: #b4b4b4;
  }

  .c-column_item-title {
    width: 100%;
    background: white;
    border-radius: 5px;
    padding: 5px;
    padding-left: 7px;
    border: 1px solid #d3d3d3;
  }
`;

interface IPage {
  id: string;
  name: string;
  locations: number[];
}

interface IPagesSettingContainerProps {
  disabled?: boolean;
  label?: string;
  description?: string;
  onChange: (ids: string[]) => void;
  pages: IPage[];
  selectedPagesIds: string[];
}

function PagesSettingContainer({
  disabled = false,
  label = i18n.t('pagesSettingContainer.label'),
  description,
  onChange,
  pages = [],
  selectedPagesIds: selectedPagesIdsProps = []
}: IPagesSettingContainerProps) {
  const [displayEmptyRow, setDisplayEmptyRow] = useState(false);

  const selectedPagesIds = useMemo(
    () => selectedPagesIdsProps.filter((id) => pages.some((page) => page.id === id)),
    [pages, selectedPagesIdsProps]
  );

  const comboBoxRef = useRef(null);

  const { t } = useTranslation();

  function renderAddButton() {
    if (displayEmptyRow) return null;
    if (pages && pages.length === selectedPagesIds.length) return null;

    return (
      <CommandBarButton
        styles={{ root: { height: '40px', borderRadius: 6, marginTop: 5 } }}
        iconProps={{ iconName: 'Add' }}
        text={t('pagesSettingContainer.addButtonLabel')}
        onClick={() => setDisplayEmptyRow(true)}
      />
    );
  }

  function handleComnboBoxChange(page, id) {
    if (!id) return;

    setDisplayEmptyRow(false);

    if (!page) {
      // Add new page
      onChange([...selectedPagesIds, id]);
    } else {
      // Update page
      onChange(
        selectedPagesIds.map((selectedPageId) => (selectedPageId === page.id ? id : selectedPageId))
      );
    }
  }

  function renderPageSelector(page?: IPage) {
    if (!page) {
      setTimeout(() => comboBoxRef.current?.focus(true), 100);
    }

    const pageLocations = getPagesLocations();

    const options = pages?.map(({ id, name, locations }) => {
      const pageLocation = pageLocations.find((loc) => loc.key === locations[0]);

      return {
        key: id,
        text: pageLocation ? `${name}  (${pageLocation.text})` : name,
        hidden: selectedPagesIds.includes(id)
      };
    });

    return (
      <ComboBox
        allowFreeform
        autoComplete="on"
        disabled={disabled}
        componentRef={comboBoxRef}
        key={page?.id || 'empty-row'}
        onChange={(e, value) => handleComnboBoxChange(page, value?.key)}
        options={options || []}
        selectedKey={page?.id}
        styles={{
          optionsContainerWrapper: { maxHeight: 500 },
          container: { width: '100%' },
          input: {
            ':after': { border: '1px solid #d3d3d3' },
            position: 'relative',
            bottom: '2px'
          },
          root: { ':after': { border: '1px solid #d3d3d3', borderRadius: 4 } },
          inputDisabled: { color: '#323130' },
          rootFocused: { ':after': { borderRadius: 4 } }
        }}
      />
    );
  }

  function deletePage(page) {
    if (!page) {
      setDisplayEmptyRow(false);
    } else {
      onChange(selectedPagesIds.filter((selectedPageId) => selectedPageId !== page.id));
    }
  }

  function renderDeleteIconButton(page) {
    return (
      <IconButton
        iconProps={{ iconName: 'Cancel' }}
        disabled={disabled}
        onClick={() => deletePage(page)}
        styles={{
          root: { margin: 'auto 4px 0px 4px' },
          rootDisabled: { backgroundColor: 'transparent' },
          icon: { fontWeight: '600', fontSize: '16px' },
          iconDisabled: { color: '#cccccc' }
        }}
      />
    );
  }

  function renderPageRow(page?: IPage) {
    return (
      <div key={page?.id || 'empty-row'}>
        <ContainerStyled>
          {renderPageSelector(page)}
          {renderDeleteIconButton(page)}
        </ContainerStyled>
      </div>
    );
  }

  function renderPageRows() {
    return (
      <PageRowsStyled hasItems={selectedPagesIds.length > 0 || displayEmptyRow}>
        {selectedPagesIds?.map((id) => {
          const page = pages?.find((def) => def.id === id);

          if (!page) return null;

          return renderPageRow(page);
        })}

        {displayEmptyRow && renderPageRow()}
      </PageRowsStyled>
    );
  }

  return (
    <SettingsContainer
      styles={{ width: '100%' }}
      fullContentWidth
      label={label}
      iconName="Page"
      defaultExpanded={selectedPagesIds.length > 0}
      description={description}
    >
      {renderPageRows()}
      {!disabled && renderAddButton()}
    </SettingsContainer>
  );
}

export default PagesSettingContainer;
