import { List, Spinner } from '@fluentui/react';
import { useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useId } from '@fluentui/react-hooks';
import { useNavigate } from 'react-router-dom';
import Activity from './Activity';
import { callInterpolationFunction, getActivityIcon, timeAgo } from './ActivitiesList';
import ActivitiesListStyled from './ActivitiesList.styles';
import { getActivityRedirectUrl } from '../../../utils/helpers';
import {
  ACTIVITIES,
  ACTIVITY,
  IActivity,
  IApiListType,
  IGetActivitiesParams,
  useApiList
} from '../../../hooks/api2';
import { ShowError } from '../../ShowError';

/**
 * Renders the ActivitiesList with infinite scrolling pagination and automatic loading.
 */
export default function ActivitiesListInfiniteScroll({
  endpoint: endpointParameter,
  params,
  containerContext,
  onActivityClick,
  onReadIconClick
}: {
  endpoint?: IApiListType<IGetActivitiesParams, typeof ACTIVITY>;
  params: IGetActivitiesParams;
  containerContext?: string;
  onActivityClick?: (activity: IActivity) => void;
  onReadIconClick?: (activity: IActivity) => void;
}) {
  const endpoint = endpointParameter || ACTIVITIES;

  const scrollableId = useId('activities-list-');
  const navigate = useNavigate();

  const { items, error, isAtEndOfList, isLoading, loadedSize, setSize, retryFromError } =
    useApiList(endpoint, params);

  const handleOnActivityClick = useCallback(
    (activity: IActivity) => {
      if (onActivityClick) {
        onActivityClick(activity);
      } else {
        const url = getActivityRedirectUrl(activity);
        if (url) navigate(url);
      }
    },
    [onActivityClick, navigate]
  );

  const renderActivityCell = useCallback(
    (item: IActivity) => {
      return (
        <Activity
          activity={item}
          callInterpolationFunction={callInterpolationFunction}
          containerContext={containerContext}
          getActivityIcon={getActivityIcon}
          handleOnActivityClick={handleOnActivityClick}
          handleOnReadIconClick={onReadIconClick}
          key={item.id}
          timeAgo={timeAgo}
        />
      );
    },
    [containerContext, handleOnActivityClick, onReadIconClick]
  );

  return (
    <ActivitiesListStyled id={scrollableId} data-is-scrollable="true" $isScrollable>
      <InfiniteScroll
        dataLength={items.length}
        hasChildren={!!items.length}
        hasMore={!isAtEndOfList}
        loader={null} // not using this because it's way less reliable than isLoading
        next={() => setSize(loadedSize + 1)}
        scrollableTarget={scrollableId}
        style={{ overflow: 'initial' }}
      >
        <List items={items} onRenderCell={renderActivityCell} />
        {error ? <ShowError error={error} onRetry={retryFromError} /> : null}
        {isLoading ? <Spinner /> : null}
      </InfiniteScroll>
    </ActivitiesListStyled>
  );
}
