import {
  IAIFieldValueProps,
  IActivityProps,
  IBlobFile,
  IChecklistItemProps,
  ICommentProps,
  IFieldLinkProps,
  IFieldProps,
  IFileProps,
  IGetGroupedInstancesProps,
  IGetInstancesProps,
  IGetInstancesResponse,
  IGroupedInstanceProps,
  IProcessDefinitionProps,
  IProcessInstanceProps,
  IProcessInstanceStepProps,
  IProcessTemplate,
  IProjectProps,
  ISprintProps,
  ITagProps,
  ITaskProps,
  ITeamProps,
  IUserProps,
  PageLocation,
  TaskStatus,
  TaskType
} from 'types';
import fetchRequest from './api';
import { enterGlobalTaskLoadingState } from '../features/Tasks/components/TaskCard/globalTaskLoadingState';
import { fetchJson } from './api2';

interface ISearchProps {
  itemsPerPage: number;
  pageIndex: number;
  totalItems: number;
}

export function tagsSearch({
  searchTerm,
  onlyParents,
  parentId,
  showDeleted,
  signal
}: {
  searchTerm: string;
  onlyParents?: boolean;
  parentId?: string;
  showDeleted?: boolean;
  signal?: AbortSignal;
}): Promise<ITagProps[]> {
  const encodedSearchTerm = encodeURIComponent(searchTerm);

  let url = `tags?searchTerms=${encodedSearchTerm}`;

  if (onlyParents) {
    url += '&onlyParents=true';
  }

  if (parentId) {
    url += `&parentId=${parentId}`;
  }

  if (showDeleted) {
    url += `&showDeleted=true`;
  }

  return fetchRequest({ url, signal });
}

export function userSearch({
  searchTerm,
  type = 0,
  teamId,
  signal
}: {
  searchTerm?: string;
  type?: number;
  teamId?: string;
  signal?: AbortSignal;
}): Promise<{ consentNeeded: boolean; items: IUserProps[] }> {
  let encodedSearchTerm;

  if (searchTerm?.trim()) {
    encodedSearchTerm = encodeURIComponent(searchTerm || '');
  }

  let url = `User/Search?searchTerm=${encodedSearchTerm || '***'}&type=${type}`;

  if (teamId) {
    url += `&teamId=${teamId}`;
  }

  return fetchRequest({ url, signal });
}

export function teamsSearch({
  searchTerm,
  userId,
  userId2
}: {
  searchTerm?: string;
  userId?: string;
  userId2?: string;
}): Promise<ITeamProps[]> {
  const encodedSearchTerm = encodeURIComponent(searchTerm || '');

  let url = `Teams?`;

  if (encodedSearchTerm) {
    url += `searchTerms=${encodedSearchTerm}&`;
  }

  if (userId) {
    url += `userId=${userId}&`;
  }

  if (userId2) {
    url += `userId2=${userId2}`;
  }

  return fetchRequest({ url });
}

export function getTeam(id: string): Promise<ITeamProps> {
  const url = `Team/${id}`;

  return fetchRequest({ url });
}

export function getTask({ id }: { id: string }): Promise<ITaskProps> {
  const url = `Task/${id}`;

  return fetchRequest({ url });
}

export function getUser({ id }: { id: string }): Promise<IUserProps> {
  const url = `User/Get?epId=${id}`;

  return fetchRequest({ url });
}

export function getUsersByIds(ids: string[]): Promise<IUserProps[]> {
  return Promise.all(ids.map((id) => getUser({ id })));
}

export function getTeamsByIds(ids: string[]): Promise<ITeamProps[]> {
  return Promise.all(ids.map((id) => getTeam(id)));
}

export function getProjectsByIds(ids: string[]): Promise<IProjectProps[]> {
  return Promise.all(ids.map((id) => getProject(id)));
}

export function getProcessTemplatesByIds(ids: string[]): Promise<IProcessTemplate[]> {
  return Promise.all(ids.map((id) => getProcessTemplate(id)));
}

export function createTask(task: ITaskProps): Promise<ITaskProps> {
  const url = `Task`;
  const body = JSON.stringify(task);

  return fetchRequest({ url, method: 'POST', body });
}

export function createTemplateTask(task: ITaskProps): Promise<ITaskProps> {
  const url = `Project/Template/Task`;
  const body = JSON.stringify(task);

  return fetchRequest({ url, method: 'POST', body });
}

export function updateTask(task: ITaskProps): Promise<ITaskProps> {
  const url = `Task`;
  const body = JSON.stringify(task);

  const { markFinished } = enterGlobalTaskLoadingState(task.id);
  return fetchRequest({ url, method: 'PUT', body }).finally(markFinished);
}

export function updateTemplateTask(task: ITaskProps): Promise<ITaskProps> {
  const url = `Project/Template/Task`;
  const body = JSON.stringify(task);

  return fetchRequest({ url, method: 'PUT', body });
}

/**
 * Gets the contents of a single file as byte stream. If the file is an image it can also be resized.
 */
export function fileContents({ id, size }: { id: string; size?: string }): Promise<Response> {
  let url = `File/Contents?id=${id}`;

  if (size) {
    url += `&size=${size}`;
  }

  return fetchRequest({ url, ignoreContentType: true });
}

export function getFile({ id }: { id: string }): Promise<IFileProps> {
  const url = `File/${id}`;

  return fetchRequest({ url, ignoreContentType: true });
}

export function updateFile(file: IFileProps): Promise<IFileProps> {
  const body = JSON.stringify(file);

  return fetchRequest({ url: 'File', body, method: 'PUT' });
}

export function getFiles({
  taskId,
  routeInstanceId,
  routeDefinitionId,
  routeDefinitionVersion,
  projectId,
  tagId
}: {
  taskId?: string;
  routeInstanceId?: string;
  routeDefinitionId?: string;
  routeDefinitionVersion?: string;
  projectId?: string;
  tagId?: string;
}): Promise<IFileProps[]> {
  let url = `Files?`;

  if (taskId) {
    url += `taskId=${taskId}&`;
  }

  if (routeInstanceId) {
    url += `routeInstanceId=${routeInstanceId}&`;
  }

  if (routeDefinitionId) {
    url += `routeDefinitionId=${routeDefinitionId}&`;
  }

  if (routeDefinitionVersion) {
    url += `routeDefinitionVersion=${routeDefinitionVersion}&`;
  }

  if (projectId) {
    url += `projectId=${projectId}&`;
  }

  if (tagId) {
    url += `tagId=${tagId}&`;
  }

  return fetchRequest({ url });
}

export function onDeleteFile({
  id,
  taskId,
  routeInstanceId
}: {
  id?: string;
  taskId?: string;
  routeInstanceId?: string;
}): Promise<void> {
  let url = `File?`;
  const method = 'DELETE';

  if (id) {
    url += `id=${id}`;
  }

  if (taskId) {
    url += `taskId=${taskId}`;
  }

  if (routeInstanceId) {
    url += `routeInstanceId=${routeInstanceId}`;
  }

  return fetchRequest({ url, method });
}

export function getLookUpInstance({
  instanceId,
  fieldIds
}: {
  instanceId: string;
  fieldIds: string[];
}): Promise<IProcessInstanceProps> {
  const url = 'Route/Instance/LookupRow';
  const method = 'POST';
  const body = JSON.stringify({
    instanceId,
    displayFieldIds: fieldIds
  });

  return fetchRequest({ url, method, body });
}

export function searchLookUpInstances({
  searchTerm,
  definitionId,
  lookupType,
  outcome,
  fieldIds
}: {
  searchTerm?: string;
  definitionId?: string;
  lookupType?: string | number;
  outcome?: string | number;
  fieldIds?: string[];
}): Promise<{
  items: IProcessInstanceProps[];
  columns: { id: string; name: string }[];
}> {
  const url = `Route/Instance/LookupList`;
  const method = 'POST';
  const body = JSON.stringify({
    definitionId,
    lookupType,
    outcome,
    searchTerms: searchTerm,
    displayFieldIds: fieldIds
  });

  return fetchRequest({ url, method, body });
}

export function evaluateFieldGroup(fieldGroup: IFieldLinkProps): Promise<IFieldLinkProps> {
  const url = `Route/FieldGroup/EvalRules`;
  const method = 'PUT';
  const body = JSON.stringify(fieldGroup);

  return fetchRequest({ url, method, body });
}

interface IUploadFileProps {
  file: IBlobFile;
  taskId?: string;
  fieldId?: string;
  definitionId?: string;
  instanceId?: string;
  projectId?: string;
  fileType?: string;
}

export function uploadFile({
  file,
  taskId,
  fieldId,
  definitionId,
  instanceId,
  projectId,
  fileType
}: IUploadFileProps): Promise<IFileProps> {
  const url = `File`;
  const method = 'POST';

  // build body
  const body = new FormData();
  body.append('file', file, file.name);

  if (taskId) {
    body.append('relatedTask', taskId);
  }

  if (fieldId) {
    body.append('relatedField', fieldId);
  }

  if (definitionId) {
    body.append('relatedDefinition', definitionId);
  }

  if (instanceId) {
    body.append('relatedInstance', instanceId);
  }

  if (projectId) {
    body.append('relatedProject', projectId);
  }

  if (fileType) {
    body.append('fileType', fileType);
  }

  return fetchRequest({ url, method, body, ignoreContentType: true }).then((response) =>
    response.json()
  );
}

export function uploadFile2({
  file,
  taskId,
  fieldId,
  definitionId,
  instanceId,
  projectId,
  fileType,
  onUploadProgress
}: IUploadFileProps & {
  onUploadProgress: (progress: number) => void;
}): Promise<IFileProps> {
  // build body
  const body = new FormData();
  body.append('file', file, file.name);

  if (taskId) {
    body.append('relatedTask', taskId);
  }

  if (fieldId) {
    body.append('relatedField', fieldId);
  }

  if (definitionId) {
    body.append('relatedDefinition', definitionId);
  }

  if (instanceId) {
    body.append('relatedInstance', instanceId);
  }

  if (projectId) {
    body.append('relatedProject', projectId);
  }

  if (fileType) {
    body.append('fileType', fileType);
  }

  return fetchJson({
    url: 'File',
    method: 'POST',
    body,
    onUploadProgress: (progress) => {
      if (progress.lengthComputable) {
        onUploadProgress(progress.loaded / progress.total);
      }
    }
  });
}

export function getDefinition({
  id,
  status,
  version
}: {
  id: string;
  status?: string | number;
  version?: string | number;
}): Promise<IProcessDefinitionProps> {
  let url = `Route/Definition/${id}`;

  if (status) {
    url += `&status=${status}`;
  }

  if (version) {
    url += `&version=${version}`;
  }

  return fetchRequest({ url });
}

export interface IServiceDataResult {
  fieldValues: IServiceDataProps[];
}

export interface IServiceDataProps {
  value?: string | number | string[] | number[];
  internalName: string;
  field: IFieldProps;
  key: string;
  title: string;
}

export interface ISearchResultProps {
  values: IServiceDataProps[];
  debugInfo: null | {
    error?: string;
  };
}

export function getDefinitionExternalDataList({
  id,
  search
}: {
  id: string;
  search?: string;
}): Promise<ISearchResultProps[]> {
  let url = `Route/Definition/ExternalDataList/${id}`;

  if (search) {
    const encodedSearchTerm = encodeURIComponent(search);

    url += `?search=${encodedSearchTerm}`;
  }

  return fetchRequest({ url });
}

export function getDefinitionExternalData({
  parameterString
}: {
  parameterString: string;
}): Promise<IServiceDataResult> {
  const url = `Route/Definition/ExternalData/${parameterString}`;

  return fetchRequest({ url, ignoreAlert: true });
}

export function getComments({
  taskId,
  discussionId,
  routeInstanceId,
  projectId,
  tagId
}: {
  taskId?: string;
  discussionId?: string;
  routeInstanceId?: string;
  projectId?: string;
  tagId?: string;
}): Promise<ICommentProps[]> {
  let url = `Comments?`;

  if (taskId) {
    url += `taskId=${taskId}&`;
  }

  if (discussionId) {
    url += `discussionId=${discussionId}&`;
  }

  if (routeInstanceId) {
    url += `routeInstanceId=${routeInstanceId}&`;
  }

  if (projectId) {
    url += `projectId=${projectId}&`;
  }

  if (tagId) {
    url += `tagId=${tagId}&`;
  }

  return fetchRequest({ url });
}

export function createComment(bodyProps: ICommentProps): Promise<ICommentProps> {
  const url = `Comment`;
  const method = 'POST';
  const body = JSON.stringify(bodyProps);

  return fetchRequest({ url, method, body });
}

export function updateComment(bodyProps: ICommentProps): Promise<ICommentProps> {
  const url = `Comment`;
  const method = 'PUT';
  const body = JSON.stringify(bodyProps);

  return fetchRequest({ url, method, body });
}

export function deleteComment(commentId: string): Promise<void> {
  const url = `Comment?id=${commentId}`;
  const method = 'DELETE';

  return fetchRequest({ url, method });
}

export function getChecklist({ taskId }: { taskId: string }): Promise<IChecklistItemProps[]> {
  const url = `Checklist?taskId=${taskId}`;

  return fetchRequest({ url });
}

interface IConvertChecklistProps {
  assignedTo?: IUserProps;
  dueDate?: Date | string;
  title?: string;
  description?: string;
  startDate?: Date | string;
}

export function createChecklistItem(
  bodyProps: IConvertChecklistProps
): Promise<IChecklistItemProps> {
  const url = 'Checklist';
  const body = JSON.stringify(bodyProps);

  return fetchRequest({ url, method: 'POST', body });
}

export function updateCheckListItem(
  bodyProps: IConvertChecklistProps
): Promise<IChecklistItemProps> {
  const url = 'Checklist';
  const body = JSON.stringify(bodyProps);

  return fetchRequest({ url, method: 'PUT', body });
}

export function convertChecklist(bodyProps: IConvertChecklistProps): Promise<ITaskProps> {
  const url = 'Task/ConvertChecklist';
  const body = JSON.stringify(bodyProps);

  return fetchRequest({ url, method: 'POST', body });
}

export function deleteCheckListItem(id: string): Promise<void> {
  const url = `Checklist?id=${id}`;

  return fetchRequest({ url, method: 'DELETE' });
}

export function setChecklistItemSortOrder(item: {
  id: string;
  sortOrder: number;
}): Promise<{ checklistItems: IChecklistItemProps[]; tasks: ITaskProps[] }> {
  const url = `Checklist/SortOrder`;

  return fetchRequest({ url, method: 'PUT', body: JSON.stringify(item) });
}

interface IGetTasksProps {
  pageIndex?: number;
  itemsPerPage?: number;
  parentTaskId?: string;
  projectIds?: string | string[];
  statuses?: string[];
  types?: string[];
  small?: boolean;
}

export function getTasks(getTasksProps: IGetTasksProps): Promise<{
  searchProps: ISearchProps;
  items: ITaskProps[];
}> {
  const {
    pageIndex = 0,
    itemsPerPage = 100,
    parentTaskId,
    projectIds,
    statuses,
    types,
    small
  } = getTasksProps;

  let url = `Tasks?pageIndex=${pageIndex}&itemsPerPage=${itemsPerPage}`;

  if (small) {
    url = `Tasks/small?pageIndex=${pageIndex}&itemsPerPage=${itemsPerPage}`;
  }

  if (parentTaskId) {
    url += `&parentTaskId=${parentTaskId}`;
  }

  if (projectIds && Array.isArray(projectIds)) {
    projectIds.forEach((projectId) => {
      url += `&projectIds=${projectId}`;
    });
  }

  if (statuses && Array.isArray(statuses)) {
    statuses.forEach((status) => {
      url += `&statuses=${status}`;
    });
  }

  if (types && Array.isArray(types)) {
    types.forEach((type) => {
      url += `&types=${type}`;
    });
  }

  if (projectIds && typeof projectIds === 'string') {
    url += `&projectIds=${projectIds}`;
  }

  return fetchRequest({ url });
}

interface IGetActivitiesProps {
  pageIndex?: number;
  itemsPerPage?: number;
  taskId?: string;
  continuationToken?: string;
}

export function getActivities({
  itemsPerPage = 100,
  taskId,
  continuationToken
}: IGetActivitiesProps): Promise<IActivityProps> {
  let url = `Activities?itemsPerPage=${itemsPerPage}&`;

  if (continuationToken) {
    url += `continuationToken=${encodeURIComponent(continuationToken)}&`;
  }

  if (taskId) {
    url += `taskId=${taskId}`;
  }

  return fetchRequest({ url });
}

export function delegateTask({
  taskId,
  userId,
  type,
  teamId
}: {
  taskId: string;
  teamId?: string;
  userId: string;
  type: number;
}): Promise<ITaskProps> {
  let url = `Task/Delegate?taskId=${taskId}&newAssignedTo=${userId}&type=${type}`;

  if (teamId) {
    url += `&newAssignedToTeam=${teamId}`;
  }

  return fetchRequest({ url, method: 'PUT' });
}

export function deleteTask(taskId: string): Promise<void> {
  return fetchRequest({
    url: `Task/${taskId}`,
    method: 'DELETE'
  });
}

export function updateRequestStatus(task: ITaskProps): Promise<ITaskProps> {
  const method = 'PUT';
  const body = JSON.stringify(task);

  return fetchRequest({ url: `Task/RequestStatus`, method, body });
}

export function getProjectSprints(projectId: string): Promise<ISprintProps> {
  return fetchRequest({ url: `Projects/Sprints?projectId=${projectId}` });
}

export function getProject(projectId: string): Promise<IProjectProps> {
  return fetchRequest({ url: `Project/${projectId}` });
}

export function getProcessTemplate(templateId: string): Promise<IProcessTemplate> {
  return fetchRequest({ url: `Route/Definition/${templateId}` });
}

export function setTaskSortOrder(
  task: ITaskProps
): Promise<{ checklistItems: IChecklistItemProps[]; tasks: ITaskProps[] }> {
  const body = JSON.stringify(task);

  return fetchRequest({ url: 'Task/SortOrder', method: 'PUT', body });
}

export function getStreamUrl({
  fileId,
  tenantId
}: {
  fileId: string;
  tenantId: string;
}): Promise<string> {
  const url = `File/StreamURL?id=${fileId}&tenantId=${tenantId}`;

  return fetchRequest({ url });
}

export function setApprovalStatus(task: ITaskProps): Promise<ITaskProps> {
  const url = `Task/ApprovalStatus`;
  const body = JSON.stringify(task);

  return fetchRequest({ url, body, method: 'PUT' });
}

export function readNotification(id: string): Promise<void> {
  const url = `Notification/Read?notificationId=${id}`;

  return fetchRequest({ url, method: 'PUT' });
}

export function getTeamForAssignedUser(userId: string): Promise<ITeamProps> {
  const url = `Team/ForTask?assignedToId=${userId}`;

  return fetchRequest({ url });
}

export function getInstanceStep(stepId: string): Promise<IProcessInstanceStepProps> {
  const url = `route/instance/step/${stepId}`;

  return fetchRequest({ url });
}

export function getInstance(id: string): Promise<IProcessInstanceProps> {
  const url = `route/instance/${id}`;

  return fetchRequest({ url });
}

export function getGroupedInstances({
  types,
  statuses,
  parentInstanceId,
  parentProjectId,
  parentTaskId
}: IGetGroupedInstancesProps): Promise<IGroupedInstanceProps[]> {
  let url = `route/instances/grouped?`;

  if (types && Array.isArray(types)) {
    types.forEach((type) => {
      url += `types=${type}&`;
    });
  }

  if (statuses && Array.isArray(statuses)) {
    statuses.forEach((status) => {
      url += `statuses=${status}&`;
    });
  }

  if (parentInstanceId) {
    url += `parentInstanceId=${parentInstanceId}&`;
  }

  if (parentProjectId) {
    url += `parentProjectId=${parentProjectId}&`;
  }

  if (parentTaskId) {
    url += `parentTaskId=${parentTaskId}`;
  }

  return fetchRequest({ url });
}

export function getInstances({
  statuses,
  pageIndex = 0,
  parentInstanceId,
  parentProjectId,
  parentTaskId,
  itemsPerPage = 20,
  definitionId
}: IGetInstancesProps): Promise<IGetInstancesResponse> {
  let url = `route/instances?pageIndex=${pageIndex}&itemsPerPage=${itemsPerPage}&`;

  if (statuses && Array.isArray(statuses)) {
    statuses.forEach((status) => {
      url += `statuses=${status}&`;
    });
  }

  if (definitionId) {
    url += `definitionId=${definitionId}&`;
  }

  if (parentInstanceId) {
    url += `parentInstanceId=${parentInstanceId}&`;
  }

  if (parentProjectId) {
    url += `parentProjectId=${parentProjectId}&`;
  }

  if (parentTaskId) {
    url += `parentTaskId=${parentTaskId}`;
  }

  return fetchRequest({ url });
}

export function getInstanceFieldValues(id: string): Promise<IFieldLinkProps[]> {
  const url = `route/instancefieldvalues/byinstance?instanceId=${id}`;

  return fetchRequest({ url });
}

export function updateField(field: IFieldProps): Promise<IFieldProps> {
  const url = `Route/Field/${field.id}`;
  const method = 'PUT';
  const body = JSON.stringify(field);

  return fetchRequest({ url, method, body });
}

export function getField(id: string): Promise<IFieldProps> {
  const url = `Route/Field/${id}`;

  return fetchRequest({ url });
}

export function createField(field: IFieldProps): Promise<IFieldProps> {
  const url = `Route/Field`;
  const method = 'POST';
  const body = JSON.stringify(field);

  return fetchRequest({ url, method, body });
}
export function getLookupOptionsField({
  pageIndex = 0,
  itemsPerPage = 200,
  status = 1,
  type = 6,
  onlyEditable = false
}): Promise<{
  items: IProcessDefinitionProps[];
  searchProps: ISearchProps;
}> {
  let url = `Route/Definitions?pageIndex=${pageIndex}&itemsPerPage=${itemsPerPage}&`;
  const method = 'GET';

  if (status) {
    url += `status=${status}&`;
  }

  if (type) {
    url += `type=${type}&`;
  }

  if (onlyEditable) {
    url += `onlyEditable=${onlyEditable}&`;
  }

  return fetchRequest({ url, method });
}

export function getDefinitions({
  pageIndex = 0,
  itemsPerPage = 200,
  status = 1,
  type = 0,
  onlyEditable = false
}): Promise<{
  items: IProcessDefinitionProps[];
  searchProps: ISearchProps;
}> {
  let url = `Route/Definitions?pageIndex=${pageIndex}&itemsPerPage=${itemsPerPage}&`;
  const method = 'GET';

  if (status) {
    url += `status=${status}&`;
  }

  if (type) {
    url += `type=${type}&`;
  }

  if (onlyEditable) {
    url += `onlyEditable=${onlyEditable}&`;
  }

  return fetchRequest({ url, method });
}

export function getExternalDataAPI({
  pageIndex = 0,
  itemsPerPage = 200,
  status = 1,
  type = 5,
  onlyEditable = false
}): Promise<{
  items: IProcessDefinitionProps[];
  searchProps: ISearchProps;
}> {
  let url = `Route/Definitions?pageIndex=${pageIndex}&itemsPerPage=${itemsPerPage}&`;

  if (status) {
    url += `status=${status}&`;
  }

  if (type) {
    url += `type=${type}&`;
  }

  if (onlyEditable) {
    url += `onlyEditable=${onlyEditable}&`;
  }

  const method = 'GET';

  return fetchRequest({ url, method });
}

interface ITagCreationProps {
  text: string;
  type: number;
}

export function createTag(tag: ITagCreationProps): Promise<ITagProps> {
  return fetchRequest({ url: 'Tag', method: 'POST', body: JSON.stringify(tag) });
}

interface IAIFieldFetchProps {
  instanceId?: string;
  fieldId?: string;
  prompt?: string;
  requestType?: number;
  messages?: { content: string; chatRole: number }[];
}

export function AIFieldPromptRequest({
  instanceId,
  fieldId,
  requestType,
  prompt,
  messages
}: IAIFieldFetchProps): Promise<IAIFieldValueProps> {
  let body;

  if (instanceId || fieldId) {
    body = JSON.stringify({ instanceId, fieldId, prompt, messages, requestType });
  }

  return fetchRequest({ url: 'Route/Instance/AIField', method: 'POST', body });
}
interface ITaskFilterProps {
  teamIds?: string[];
  statuses?: TaskStatus[];
  types?: TaskType[];
  searchTerms?: string;
  type?: number;
  dateType?: number;
  startDate?: Date;
  endDate?: Date;
  definitionIds?: string[];
  priorities?: number;
  projectIds?: string[];
  sprintId?: string;
  tagIds?: string[];
  showAssociatedBacklogs?: boolean;
}

interface IProjectFilterProps {
  teamIds?: string[];
  statuses?: TaskStatus[];
  types?: TaskType[];
  searchTerms?: string;
  type?: number;
  dateType?: number;
  startDate?: Date;
  endDate?: Date;
  definitionIds?: string[];
  priorities?: number;
  projectIds?: string[];
  sprintId?: string;
  tagIds?: string[];
  showAssociatedBacklogs?: boolean;
}

export function getTasksFilterProps(filterProps?: ITaskFilterProps): Promise<ITaskFilterProps> {
  let body;

  if (filterProps) {
    body = JSON.stringify(filterProps);
  }

  return fetchRequest({ url: 'Tasks/FilterProps', method: 'POST', body });
}

export function getProjectsFilterProps(
  filterProps?: IProjectFilterProps
): Promise<IProjectFilterProps> {
  let body;

  if (filterProps) {
    body = JSON.stringify(filterProps);
  }

  return fetchRequest({ url: 'Projects/FilterProps', method: 'POST', body });
}

export function createWopiFileCopy(id: string): Promise<IFileProps> {
  return fetchRequest({ url: `File/Copy?id=${id}`, method: 'POST' });
}

export function getPages(tenantId: string, { locations }: { locations?: PageLocation[] }) {
  let url = `tenants/${tenantId}/Pages`;

  if (locations) {
    locations.forEach((location) => {
      url += `&location=${location}`;
    });
  }

  return fetchRequest({ url, method: 'GET' });
}
