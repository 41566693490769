import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DirectChatBackend from 'services/ChatConnection/DirectChatBackend';
import styled, { useTheme } from 'styled-components';
import { z } from 'zod';
import zodToJsonSchema from 'zod-to-json-schema';
import { Icon, IconButton, Spinner, SpinnerSize } from '@fluentui/react';
import { useNotificationContext } from '../../../../features/App';
import { AGENT_THUMBNAIL, useApiDataCache, useApiObject } from '../../../../hooks/api2';
import { generateImage } from '../../../../services/directImageGeneration';
import { IAgentPatch } from './model';

export function useAgentThumbnail({
  agentId,
  version
}: {
  agentId: string | null;
  version: number | null;
}) {
  const { data, isLoading } = useApiObject(
    AGENT_THUMBNAIL,
    agentId && version !== null
      ? {
          id: agentId,
          version
        }
      : null
  );

  const [currentImageUrl, setCurrentImageUrl] = useState<string | null>(null);
  useEffect(() => {
    if (data?.data) {
      const url = URL.createObjectURL(data.data);
      setCurrentImageUrl(url);
      return () => URL.revokeObjectURL(url);
    }

    setCurrentImageUrl(null);
    return () => null;
  }, [data]);

  return {
    url: currentImageUrl,
    hasThumbnail: !!data?.data,
    isLoading
  };
}

function promptForAgentThumbnail(agent: IAgentPatch): Promise<string> {
  const AssistantResult = z.object({
    designProcess: z.string(),
    output: z.string()
  });

  return new Promise((resolve, reject) => {
    try {
      const directBackend = new DirectChatBackend(
        (error) => {
          reject(error);
        },
        { current: [] },
        `
You are an expert app icon designer tasked with creating a description for a DALL-E prompt that will generate an app icon. Your goal is to craft a concise, visually appealing icon concept based on the provided app information, in an iOS-inspired minimalistic style without a button-like or zoomed-out appearance.

Here are the details of the app you'll be working with:

<app_name> ${agent.name} </app_name>

<app_description> ${agent.description} </app_description>

Your task is to create a one-sentence description for an app icon that captures the essence of this application. This description will be used to generate an actual icon image using DALL-E.

Before crafting the final description, think through the design process using the following steps. Use <icon_design_process> tags to show your thought process:

<icon_design_process>

1. Analyze the app description and identify 2-3 key concepts or features that best represent the app's purpose.
2. Consider the app's target audience and how it might influence the design choices.
3. Explore metaphors or symbols that could represent the app's main function or purpose.
4. Brainstorm simple, clear visual elements that could represent these key concepts. Remember, the icon must be recognizable at 100x100 pixels, so avoid complex designs.
5. Consider color schemes that would work well for this app. Remember, you must use a maximum of two colors.
6. Reflect on how to make the icon stand out and be memorable while maintaining simplicity and clarity.
7. Think about how the icon might look alongside other app icons on a user's device.
8. Consider how to incorporate the required phrase "iOS-inspired minimalistic style" naturally into your description.
9. Consider how the app name might be incorporated into the icon design, if appropriate.
10. Explicitly consider the balance between simplicity and recognizability in your design.
Draft a preliminary description and then refine it to ensure it's concise, clear, and likely to produce an effective icon. </icon_design_process>
After your analysis, create the final app icon description adhering to these requirements:

The description must be one sentence only.
Include only two colors maximum.
Ensure the described icon would be clear and recognizable at 100x100 pixels.
Use simple, clear visual elements representing the app's main function or purpose.
Include the phrase "iOS-inspired minimalistic style" in the description.
Format your output exactly as follows: [Main visual element], iOS-inspired minimalistic style, [color scheme]

Example format (do not use this content, it's just to illustrate the structure): Stylized musical note with sound waves, iOS-inspired minimalistic style, blue and white color scheme

Please provide your final output as a JSON object with two key-value pairs:

"designProcess": The content of your <icon_design_process> tags
"output": Your final one-sentence app icon description

Schema: ${JSON.stringify(zodToJsonSchema(AssistantResult), null, 2)}
Ensure that your JSON is properly formatted without any additional labels or backticks.
    `.trim()
      );

      const timeoutId = setTimeout(() => {
        reject(new Error('Icon generation timeout'));
      }, 30000);

      directBackend.addEventListener('remoteMessageCompleted', (messageEvent) => {
        const response = messageEvent.message.text.trim();
        let json: z.infer<typeof AssistantResult>;
        try {
          json = AssistantResult.parse(JSON.parse(response));
        } catch (error) {
          reject(error);
          return;
        }
        const prompt = json.output;

        clearTimeout(timeoutId);

        resolve(prompt);
      });
    } catch (error) {
      reject(error);
    }
  });
}

const ThumbnailSettingsStyled = styled.div`
  display: grid;
  align-items: start;

  > .c-icon-container {
    display: grid;
    box-shadow: 0 0 0 1px rgb(${({ theme }) => theme.agentSettings.fieldOutline});
    border-radius: 0.75rem;
    overflow: hidden;

    > .c-buttons {
      grid-area: 1 / 1;
      position: relative;
      display: grid;
      pointer-events: none;

      > .c-edit-menu {
        pointer-events: all;
        position: absolute;
        /* these colors do not need theming; they're always over an image */
        background: rgb(0, 0, 0, 0.3);
        box-shadow: 0 0 0 1px rgb(255, 255, 255, 0.1);
        backdrop-filter: blur(0.5rem) saturate(2);
        color: white;
        top: 0.5rem;
        right: 0.5rem;
        transition: all 0.1s;

        &:hover,
        &.is-expanded {
          background: rgb(127, 127, 127, 0.3);
        }
      }
    }
  }

  > .c-icon-container > .c-icon {
    width: 100px;
    height: 100px;
    background-color: rgb(${({ theme }) => theme.agentCard.foreground} / 0.05);
    color: rgb(${({ theme }) => theme.agentCard.foreground} / 0.7);
    display: grid;
    place-content: center;
    font-size: 40px;
    grid-area: 1 / 1;

    > img {
      grid-area: 1 / 1;
      width: 100px;
      height: 100px;
      object-fit: cover;
      transition: opacity 0.2s;
    }

    > .c-icon {
      transition: opacity 0.2s;
    }

    > .c-icon,
    > .c-spinner {
      grid-area: 1 / 1;
    }

    &.is-loading {
      > img,
      > .c-icon {
        opacity: 0.3;
      }
    }

    &:hover + .c-buttons > .c-edit-menu {
      background: transparent;
      backdrop-filter: blur(0) saturate(1);
      color: rgba(255, 255, 255, 0.1);
      transition: all 0.5s 0.5s;
    }
  }
`;

export interface INewThumbnail {
  data: Blob | null;
}

export default function AgentThumbnailSettings({
  isLoading,
  currentUrl,
  agent,
  onNewThumbnailChange,
  className
}: {
  isLoading: boolean;
  currentUrl: string | null;
  agent: IAgentPatch;
  onNewThumbnailChange: (thumb: INewThumbnail | null) => void;
  className?: string;
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const cache = useApiDataCache();
  const { showError } = useNotificationContext();

  const [isGenerating, setIsGenerating] = useState(false);

  const generate = useCallback(() => {
    if (isGenerating) return;
    setIsGenerating(true);

    promptForAgentThumbnail(agent)
      .then((prompt) =>
        generateImage(cache, {
          textPrompt: prompt,
          quality: 'standard',
          style: 'natural',
          generateAsSize: '1024x1024',
          outputSize: [400, 400]
        })
      )
      .then((data) => onNewThumbnailChange({ data }))
      .catch((error) => showError(error.message))
      .finally(() => {
        setIsGenerating(false);
      });
  }, [agent, cache, isGenerating, onNewThumbnailChange, showError]);

  const upload = useCallback(() => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.style.display = 'none';
    document.body.append(input);
    input.click();
    input.addEventListener('change', () => {
      const file = input.files[0];
      if (!file) return;

      onNewThumbnailChange({ data: file });
    });
    input.remove();
  }, [onNewThumbnailChange]);

  const remove = useCallback(() => {
    onNewThumbnailChange({ data: null });
  }, [onNewThumbnailChange]);

  const [newThumbnailUrl, setNewThumbnailUrl] = useState<string | null>(null);
  useEffect(() => {
    if (agent.newThumbnail?.data) {
      const url = URL.createObjectURL(agent.newThumbnail?.data);
      setNewThumbnailUrl(url);
      return () => URL.revokeObjectURL(url);
    }
    setNewThumbnailUrl(null);
    return () => null;
  }, [agent.newThumbnail]);

  const isLoadingSomething = isLoading || isGenerating;

  let iconPreview = isLoadingSomething ? null : <Icon className="c-icon" iconName="Photo2" />;
  if (newThumbnailUrl) {
    iconPreview = <img src={newThumbnailUrl} alt="" />;
  } else if (!agent.newThumbnail && currentUrl) {
    iconPreview = <img src={currentUrl} alt="" />;
  }

  const hasThumbnail = agent.newThumbnail ? !!agent.newThumbnail.data : !!currentUrl;

  return (
    <ThumbnailSettingsStyled className={className}>
      <div className="c-icon-container">
        <div className={`c-icon ${isLoadingSomething ? 'is-loading' : ''}`}>
          {iconPreview}

          {isLoadingSomething ? <Spinner className="c-spinner" size={SpinnerSize.large} /> : null}
        </div>
        {!isLoadingSomething ? (
          <div className="c-buttons">
            <IconButton
              className="c-edit-menu"
              iconProps={{ iconName: 'Edit' }}
              aria-label={t('globals.edit')}
              styles={{ menuIcon: { display: 'none !important' } }}
              menuProps={{
                items: [
                  {
                    key: 'generate',
                    iconProps: { iconName: 'AutoEnhanceOn' },
                    disabled: isLoadingSomething,
                    text: t('ai.agentSettings.thumbnail.generate'),
                    onClick: generate
                  },
                  {
                    key: 'upload',
                    iconProps: { iconName: 'Upload' },
                    disabled: isLoadingSomething,
                    text: t('ai.agentSettings.thumbnail.upload'),
                    onClick: upload
                  },
                  hasThumbnail
                    ? {
                        key: 'remove',
                        iconProps: {
                          iconName: 'Delete',
                          styles: {
                            root: {
                              color: `rgb(${theme.agentSettings.deleteButtonColor})`
                            }
                          }
                        },
                        text: t('ai.agentSettings.thumbnail.delete'),
                        onClick: remove
                      }
                    : null
                ].filter((item) => item)
              }}
            />
          </div>
        ) : null}
      </div>
    </ThumbnailSettingsStyled>
  );
}
