import { any, arrayOf, bool, func, instanceOf, number, oneOfType, shape, string } from 'prop-types';

export const userPropType = shape({
  login: string,
  name: string,
  pictureUrl: string,
  tenantId: string,
  userId: string
});

export const teamPropType = shape({
  id: string,
  title: string,
  type: shape({ id: number, title: string }),
  department: shape({ id: number, title: string }),
  status: shape({ id: number, title: string }),
  members: arrayOf(shape({}))
});

export const processCategoryPropType = shape({
  id: string,
  name: string,
  description: string,
  sortOrder: number,
  type: number,
  allNames: arrayOf(shape({ language: string, text: string })),
  allDescriptions: arrayOf(shape({ language: string, text: string }))
});

export const projectPropType = shape({
  id: string,
  name: string,
  processAssociations: arrayOf(shape({ definitionId: string })),
  description: string,
  status: number,
  type: number,
  startDate: any,
  plannedEndDate: any,
  numberOfSprints: number,
  sprintDuration: number,
  currentSprint: any,
  team: teamPropType,
  allNames: arrayOf(shape({ language: string, text: string })),
  allDescriptions: arrayOf(shape({ language: string, text: string }))
});

export const matchPropType = shape({
  params: shape({
    id: string,
    mode: string,
    taskId: string,
    tab: string
  })
});

export const historyPropType = shape({
  push: func,
  location: shape({ pathname: string })
});

export const matchDefaultPropType = shape({
  params: shape({
    id: null,
    mode: null,
    taskId: null,
    tab: null
  })
});

export const taskPropType = shape({
  parentTaskId: string,
  typeId: number,
  id: string || number,
  title: string,
  creationDate: oneOfType([string, instanceOf(Date)]),
  startDate: any,
  dueDate: any,
  creator: userPropType,
  assignedTo: userPropType,
  description: string,
  routeInstanceId: string,
  type: oneOfType([
    shape({
      id: number,
      title: string
    }),
    string
  ]),
  statusId: number
});

export const filePropType = shape({
  creationDate: string,
  creator: userPropType,
  editDate: string,
  editor: userPropType,
  extension: string,
  id: string,
  name: string,
  routeInstanceId: string,
  taskId: string,
  taskName: string,
  type: oneOfType([string, number]),
  url: string
});

export const fieldPropType = shape({
  field: shape({
    dateFormat: number,
    choiceFormat: number,
    choices: arrayOf(string),
    maxLength: number,
    numStars: number,
    numDecimals: number,
    id: string,
    name: string,
    description: string,
    fieldType: number
  })
});

export const stepPropType = shape({
  groups: arrayOf(
    shape({
      name: string,
      description: null,
      fields: arrayOf(
        shape({
          field: fieldPropType,
          visibility: number,
          sortOrder: number,
          value: oneOfType([
            number,
            string,
            bool,
            arrayOf(string),
            arrayOf(shape({ id: string, value: string })),
            userPropType
          ])
        })
      )
    })
  ),
  routeInstanceId: string,
  status: number,
  taskId: string,
  taskPropType: number,
  dateFrom: string,
  dateTo: string,
  id: string,
  name: string,
  description: string,
  stepType: number,
  successors: arrayOf(
    shape({
      id: string,
      branch: number
    })
  ),
  predecessors: arrayOf(
    shape({
      id: string,
      branch: number
    })
  )
});

export const instancePropType = shape({
  id: string,
  routeDefinitionId: string,
  name: string,
  description: string,
  steps: arrayOf(stepPropType),
  status: number,
  version: string,
  imageUrl: string,
  creationDate: string,
  creator: userPropType,
  editDate: string,
  editor: userPropType,
  team: teamPropType
});

export const columnPropType = shape({
  id: string,
  name: string,
  isFiltered: bool,
  isResizable: bool,
  isSorted: bool,
  isSortedDescending: bool,
  fieldType: number
});

export const templatePropType = shape({
  id: string,
  name: string,
  description: string,
  steps: arrayOf(stepPropType),
  stepsMatrix: shape({
    items: arrayOf(arrayOf(shape({ key: string, id: string }))),
    size: arrayOf(number)
  }),
  version: string,
  creationDate: string,
  creator: userPropType,
  editDate: string,
  editor: userPropType,
  team: teamPropType,
  dataFields: arrayOf(
    shape({
      id: string,
      name: string,
      description: string,
      fieldType: number
    })
  )
});

export const locationPropType = shape({
  code: string,
  country: shape({
    id: string,
    name: string,
    code: string
  }),
  id: string,
  name: string,
  text: string
});

export const tagPropType = shape({
  id: string,
  text: string
});
