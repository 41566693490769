/* eslint-disable @typescript-eslint/no-empty-function */

import { AppContext } from 'features/App';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import fetchRequest from 'services/api';

export type statuses = 'idle' | 'loading' | 'success' | 'error';

interface ClientStoreValue {
  key: string;
  value: unknown;
}

interface APIClientStoreContextProps {
  clientStore: ClientStoreValue[];
  setClientStoreValue: (props: ISaveClientStoreValueProps) => void;
  fetchData: (props: { key: string; teamId?: string; defaultValue: unknown }) => Promise<unknown>;
  status: statuses;
}

interface ISaveClientStoreValueProps {
  key: string;
  value: unknown;
  teamId?: string;
}

const APIClientStoreContext = createContext<APIClientStoreContextProps>({
  clientStore: [],
  fetchData: () => Promise.resolve(),
  status: 'loading',
  setClientStoreValue: () => {}
});

export interface UseApiClientStoreResult<T> {
  value: T;
  setValue: (value: T) => void;
  fetchData: () => Promise<unknown>;
  status: statuses;
}

export function useAPIClientStore<T>({
  key,
  teamId,
  defaultValue
}: {
  key: string;
  teamId?: string;
  defaultValue: T;
}): UseApiClientStoreResult<T> {
  const {
    clientStore,
    setClientStoreValue: setValue,
    fetchData,
    status
  } = useContext(APIClientStoreContext);

  const value = useMemo(() => {
    return clientStore.find((item) => item.key === key)?.value || defaultValue;
  }, [clientStore, defaultValue, key]);

  return {
    value: value as T,
    setValue: (value: T) => {
      setValue({ key, value, teamId });
    },
    fetchData: () => {
      return fetchData({ key, teamId, defaultValue });
    },
    status
  };
}

export function APIClientStoreProvider({ children }: { children: React.ReactNode }) {
  const tenantId = window.localStorage.getItem('tenantId');
  const [clientStore, setClientStore] = useState<ClientStoreValue[]>([]);
  const [status, setStatus] = useState<statuses>('loading');

  const { globalAppState } = useContext(AppContext);

  const { currentUser } = globalAppState;

  const updateState = useCallback(
    ({ key, value, defaultValue }: { key: string; value: unknown; defaultValue?: unknown }) => {
      setClientStore((prev) => {
        const newState = [...prev];
        const index = newState.findIndex((item) => item.key === key);

        if (index > -1) {
          newState[index].value = value || defaultValue;
        } else {
          newState.push({ key, value: value || defaultValue });
        }

        return [...newState];
      });
    },
    []
  );

  const fetchData = useCallback(
    async ({ key, teamId, defaultValue }) => {
      let url: string;

      if (teamId) {
        url = `tenants/${tenantId}/teams/${teamId}/client-store/${key}`;
      } else {
        url = `tenants/${tenantId}/members/${currentUser.userId}/client-store/${key}`;
      }

      setStatus('loading');

      return fetchRequest({ url, ignoreAlert: true })
        .then((response) => {
          updateState({ key, value: response || defaultValue });
          setStatus('idle');
        })
        .catch(() => {
          updateState({ key, value: defaultValue });
          setStatus('idle');
        });
    },
    [tenantId, currentUser?.userId, updateState]
  );

  const setClientStoreValue = useCallback(
    async ({ key, value, teamId }: ISaveClientStoreValueProps) => {
      let url: string;

      if (teamId) {
        url = `tenants/${tenantId}/teams/${teamId}/client-store/${key}`;
      } else {
        url = `tenants/${tenantId}/members/${currentUser.userId}/client-store/${key}`;
      }

      fetchRequest({
        url,
        method: 'PUT',
        body: JSON.stringify(JSON.stringify(value))
      });

      updateState({ key, value });
    },
    [tenantId, updateState, currentUser?.userId]
  );

  const value = useMemo(
    () => ({ clientStore, setClientStoreValue, fetchData, status }),
    [clientStore, fetchData, setClientStoreValue, status]
  );

  return <APIClientStoreContext.Provider value={value}>{children}</APIClientStoreContext.Provider>;
}
