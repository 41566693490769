import PropTypes from 'prop-types';

function PivotDetailBlock({ label, content }) {
  return (
    <>
      <div className="ms-Label ms-font-s ms-fontColor-neutralSecondary">{label}</div>
      <div className="ms-font-m">{content}</div>
    </>
  );
}

PivotDetailBlock.propTypes = {
  label: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.any])
};

PivotDetailBlock.defaultProps = {
  label: null,
  content: null
};

export default PivotDetailBlock;
