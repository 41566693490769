/* eslint-disable no-bitwise */

import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import breakpoints from 'utils/breakpoints';
import { checkScreenWidth, cloneObject } from 'utils/helpers';
import { CommandBarButton, Dropdown, Label, Stack, TextField } from '@fluentui/react';
import RequestBodyPropertiesList from './RequestBodyPropertiesList';
import RequestFieldsList from './RequestFieldsList';

const methodOptions = [
  {
    key: 'GET',
    text: 'GET'
  },
  {
    key: 'POST',
    text: 'POST'
  },
  {
    key: 'PUT',
    text: 'PUT'
  },
  {
    key: 'PATCH',
    text: 'PATCH'
  }
];

const contentTypeOptions = [
  {
    key: 1,
    text: 'JSON'
  },
  {
    key: 2,
    text: 'XML'
  }
];

// styles
const ConnectionTabStyled = styled.div`
  padding: 1rem;
  padding-bottom: 3rem;

  .form-fields-width {
    width: 100%;
  }
`;

const DevidedRow = styled.div`
  @media (min-width: ${breakpoints.smallMin}px) {
    display: flex;
    justify-content: space-between;

    .left-half {
      max-width: 50%;
      padding-right: 0.25rem;
    }

    .right-half {
      padding-left: 0.25rem;
      max-width: 50%;
    }
  }
`;

const PropertiesContainer = styled.div`
  width: 100%;
  margin: 14px 0 14px 0;
`;

const RequestPropertyError = styled.div`
  margin-top: 30px;
  margin-bottom: -30px;
  color: rgb(164, 38, 44);
`;

const TabDescriptionStyled = styled.div`
  margin-bottom: 10px;
  padding-bottom: 10px;
  font-size: 0.9em;
  color: #808080;
  font-style: italic;
  border-bottom: 1px solid rgb(235, 235, 235);
`;

function ServiceConnectionPivotItem({
  service,
  handleServiceState,
  fieldToFocus,
  handleErrors,
  inputErrors
}) {
  const { t } = useTranslation();

  const [hasGroupedBodyProps, setHasGroupedBodyProps] = useState(false);

  // visibility states for new (editing) dialogs
  const [showNewEditQueryFieldDialog, setShowNewEditQueryFieldDialog] = useState(false);
  const [showNewEditHeaderFieldDialog, setShowNewEditHeaderFieldDialog] = useState(false);
  const [showNewEditBodyPropDialog, setShowNewEditBodyPropDialog] = useState(false);

  const maxStringLength = checkScreenWidth(['small']) ? 35 : 100;

  function handleCurrentInput(name, value) {
    const newService = cloneObject(service);
    const connection = newService?.serviceData?.connection
      ? { ...newService?.serviceData?.connection }
      : {};

    connection[name] = value;

    newService.serviceData.connection = connection;

    if (showNewEditQueryFieldDialog) {
      onDismissQueryFieldDialog();
    }

    handleServiceState(newService);
  }

  const handleUrl = debounce((value) => {
    handleCurrentInput('url', value);
  }, 400);

  function handleMethod(value) {
    handleCurrentInput('method', value.key);
  }

  function handleContentType(value) {
    handleCurrentInput('contentType', value.key);
  }

  function handleAuthType(value) {
    handleCurrentInput('auth', value.key);
  }

  function handleScopes(value) {
    const scopes = value.split(',');

    handleCurrentInput('scopes', scopes);
  }

  function handleAuthority(value) {
    let appAuthConfig = { authority: value };

    if (service?.serviceData?.connection?.appAuthConfig) {
      appAuthConfig = { ...service.serviceData.connection.appAuthConfig, authority: value };
    }

    handleCurrentInput('appAuthConfig', appAuthConfig);
  }

  function handleUsername(username) {
    let basicAuthConfig = { username };

    if (service?.serviceData?.connection?.basicAuthConfig) {
      basicAuthConfig = { ...service.serviceData.connection.basicAuthConfig, username };
    }

    handleCurrentInput('basicAuthConfig', basicAuthConfig);
  }

  function handlePassword(password) {
    let basicAuthConfig = { password };

    if (service?.serviceData?.connection?.basicAuthConfig) {
      basicAuthConfig = { ...service.serviceData.connection.basicAuthConfig, password };
    }

    handleCurrentInput('basicAuthConfig', basicAuthConfig);
  }

  function handleClientId(value) {
    let appAuthConfig = { clientId: value };

    if (service?.serviceData?.connection?.appAuthConfig) {
      appAuthConfig = { ...service.serviceData.connection.appAuthConfig, clientId: value };
    }

    handleCurrentInput('appAuthConfig', appAuthConfig);
  }

  function handleClientSecret(value) {
    let appAuthConfig = { clientSecret: value };

    if (service?.serviceData?.connection?.appAuthConfig) {
      appAuthConfig = { ...service.serviceData.connection.appAuthConfig, clientSecret: value };
    }

    handleCurrentInput('appAuthConfig', appAuthConfig);
  }

  function onDismissQueryFieldDialog() {
    setShowNewEditQueryFieldDialog(false);
  }

  function onDismissHeaderFieldDialog() {
    setShowNewEditHeaderFieldDialog(false);
  }

  function onDismissBodyPropDialog() {
    setShowNewEditBodyPropDialog(false);
  }

  function getAuthenticationFields() {
    if (service?.serviceData?.connection?.auth === 1) {
      return (
        <TextField
          id="scopes-field"
          disabled={false}
          label={t('serviceConnectionPivotItem.label.scopes')}
          placeholder={t('serviceConnectionPivotItem.placeholder.scopes')}
          onChange={(ev, value) => handleScopes(value)}
          required={false}
          value={service?.serviceData?.connection?.scopes || ''}
          multiline={service?.serviceData?.connection?.scopes?.length > maxStringLength}
          autoAdjustHeight
        />
      );
    }

    if (service?.serviceData?.connection?.auth === 2) {
      return (
        <>
          <TextField
            disabled={false}
            label={t('serviceConnectionPivotItem.label.appAuthConfig.authority')}
            onChange={(ev, value) => handleAuthority(value)}
            required={false}
            defaultValue={service?.serviceData?.connection?.appAuthConfig?.authority}
            multiline={
              service?.serviceData?.connection?.appAuthConfig?.authority?.length > maxStringLength
            }
            autoAdjustHeight
          />
          <TextField
            disabled={false}
            label={t('serviceConnectionPivotItem.label.appAuthConfig.clientId')}
            onChange={(ev, value) => handleClientId(value)}
            required={false}
            defaultValue={service?.serviceData?.connection?.appAuthConfig?.clientId}
            multiline={
              service?.serviceData?.connection?.appAuthConfig?.clientId?.length > maxStringLength
            }
            autoAdjustHeight
          />
          <TextField
            disabled={false}
            label={t('serviceConnectionPivotItem.label.appAuthConfig.clientSecret')}
            onChange={(ev, value) => handleClientSecret(value)}
            required={false}
            defaultValue={service?.serviceData?.connection?.appAuthConfig?.clientSecret}
            multiline={
              service?.serviceData?.connection?.appAuthConfig?.clientSecret?.length >
              maxStringLength
            }
            autoAdjustHeight
          />
        </>
      );
    }

    if (service?.serviceData?.connection?.auth === 3) {
      return (
        <>
          <TextField
            disabled={false}
            label={t('serviceConnectionPivotItem.label.basicAuthConfig.username')}
            onChange={(ev, value) => handleUsername(value)}
            required
            defaultValue={service?.serviceData?.connection?.basicAuthConfig?.username}
            autoAdjustHeight
          />
          <TextField
            disabled={false}
            label={t('serviceConnectionPivotItem.label.basicAuthConfig.password')}
            onChange={(ev, value) => handlePassword(value)}
            required
            type="password"
            canRevealPassword
            revealPasswordAriaLabel={t(
              'serviceConnectionPivotItem.label.basicAuthConfig.passwordReveal'
            )}
            defaultValue={service?.serviceData?.connection?.basicAuthConfig?.password}
          />
        </>
      );
    }

    return null;
  }

  function handleErrorsFromRequestLists(errors) {
    handleErrors('connection', errors);
  }

  const propertiesDisabled =
    !service.name || !service.usage || !service?.serviceData?.connection?.url;

  function getRequestFieldsLists() {
    const fieldUsageSelected = (service?.usage & 4) === 4;
    let hidden = false;

    if (fieldUsageSelected) {
      const requestBody = service?.serviceData?.connection?.requestBody;

      hidden =
        (requestBody?.properties && Object.keys(requestBody.properties)?.length) ||
        (requestBody?.items?.properties && Object.keys(requestBody.items?.properties)?.length);
    }

    if (!hidden) {
      let disabledNewRow = false;

      if (fieldUsageSelected) {
        const header = service?.serviceData?.connection?.headerFields;
        const query = service?.serviceData?.connection?.queryFields;

        let numberOfRequestFields = 0;

        if (header) {
          header.map((prop) => {
            if (!prop.defaultValue) {
              numberOfRequestFields += 1;
            }

            return null;
          });
        }

        if (!numberOfRequestFields && query) {
          query.map((prop) => {
            if (!prop.defaultValue) {
              numberOfRequestFields += 1;
            }

            return null;
          });
        }

        disabledNewRow = numberOfRequestFields > 0;
      }

      let disabledNewHeaderRow = disabledNewRow;
      let disabledNewQueryRow = disabledNewRow;

      if (inputErrors?.length) {
        inputErrors.map((err) => {
          if (err.inputId.endsWith('-internalName-txt') && err.field && err.message) {
            if (err.inputId.startsWith('header-')) {
              disabledNewHeaderRow = true;
            }
            if (err.inputId.startsWith('query-')) {
              disabledNewQueryRow = true;
            }
          }

          return null;
        });
      }

      return (
        <>
          <div style={{ marginTop: '30px' }}>
            <Label>{t('serviceConnectionPivotItem.label.headerFields')}</Label>
            <Stack horizontal styles={{ root: { height: 44 } }}>
              <CommandBarButton
                id="add-new-header-field-btn"
                iconProps={{ iconName: 'Add' }}
                text={t('serviceConnectionPivotItem.label.newHeaderField')}
                disabled={propertiesDisabled || disabledNewHeaderRow}
                onClick={() => setShowNewEditHeaderFieldDialog(true)}
                style={{ borderBottom: '1px solid #D3D3D3' }}
              />
            </Stack>
            {!propertiesDisabled ? (
              <RequestFieldsList
                hidden={
                  (propertiesDisabled || disabledNewHeaderRow) &&
                  !service?.serviceData?.connection?.headerFields?.length
                }
                fields={service?.serviceData?.connection?.headerFields}
                saveFields={(props) => handleCurrentInput('headerFields', props)}
                showCreateDialog={showNewEditHeaderFieldDialog}
                hideCreateDialog={onDismissHeaderFieldDialog}
                disabledNewRow={propertiesDisabled || disabledNewHeaderRow}
                url={service?.serviceData?.connection?.url}
                propType="header"
                handleErrors={handleErrorsFromRequestLists}
                queryFieldsErrors={inputErrors}
              />
            ) : null}
          </div>
          <div style={{ marginTop: '30px' }}>
            <Label>{t('serviceConnectionPivotItem.label.queryFields')}</Label>
            <Stack horizontal styles={{ root: { height: 44 } }}>
              <CommandBarButton
                id="add-new-query-field-btn"
                iconProps={{ iconName: 'Add' }}
                text={t('serviceConnectionPivotItem.label.newQueryField')}
                disabled={propertiesDisabled || disabledNewQueryRow}
                onClick={() => setShowNewEditQueryFieldDialog(true)}
                style={{ borderBottom: '1px solid #D3D3D3' }}
              />
            </Stack>
            {!propertiesDisabled ? (
              <RequestFieldsList
                hidden={
                  (propertiesDisabled || disabledNewQueryRow) &&
                  !service?.serviceData?.connection?.queryFields?.length
                }
                fields={service?.serviceData?.connection?.queryFields}
                saveFields={(props) => handleCurrentInput('queryFields', props)}
                showCreateDialog={showNewEditQueryFieldDialog}
                hideCreateDialog={onDismissQueryFieldDialog}
                disabledNewRow={propertiesDisabled || disabledNewQueryRow}
                url={service?.serviceData?.connection?.url}
                propType="query"
                handleErrors={handleErrorsFromRequestLists}
                queryFieldsErrors={inputErrors}
              />
            ) : null}
          </div>
        </>
      );
    }

    return null;
  }

  function getRequestBodyList() {
    const fieldUsageSelected = (service?.usage & 4) === 4;
    let hidden = false;
    const connection = service?.serviceData?.connection;

    hidden = !connection?.method || connection?.method === 'GET';

    if (fieldUsageSelected) {
      hidden = hidden || connection?.queryFields?.length || connection?.headerFields?.length;
    }

    if (!hidden) {
      let disabledNewRow = false;

      if (fieldUsageSelected) {
        const checkGroups = (passedProps) => {
          let thereAreGroups = false;

          if (passedProps) {
            Object.keys(passedProps).map((key) => {
              const property = service?.serviceData?.connection?.requestBody?.properties?.[key];

              let propType = property?.type;

              if (property && Array.isArray(property.type)) {
                propType = property.type.find((x) => x !== null);
              }

              if (propType === 'array') {
                thereAreGroups = true;
              } else if (propType === 'object') {
                thereAreGroups = true;
              }

              return null;
            });
          }

          if (hasGroupedBodyProps !== thereAreGroups) {
            setHasGroupedBodyProps(thereAreGroups);
          }
        };

        let bodyPropsLength = 0;

        let properties = null;

        if (service?.serviceData?.connection?.requestBody?.type === 'array') {
          properties = service?.serviceData?.connection?.requestBody?.items?.properties;
        } else {
          properties = service?.serviceData?.connection?.requestBody?.properties;
        }

        if (properties) {
          Object.keys(properties).map((prop) => {
            if (!prop.default) {
              bodyPropsLength += 1;
            }

            return null;
          });

          checkGroups(properties);
        }

        disabledNewRow = bodyPropsLength > 0;
      }

      const hiddenListOnly =
        (propertiesDisabled || disabledNewRow) &&
        !(
          (connection?.requestBody?.properties &&
            Object.keys(connection?.requestBody.properties)?.length) ||
          (connection?.requestBody?.items?.properties &&
            Object.keys(connection?.requestBody.items?.properties)?.length)
        );

      let errorInList = false;

      if (inputErrors?.length) {
        inputErrors.map((err) => {
          if (
            err.list === 'body' &&
            err.inputId.endsWith('-txt-internalName') &&
            err.message &&
            err.property
          ) {
            if (err.property[Object.keys(err.property)[0]]?.level === 1) {
              disabledNewRow = true;
              errorInList = true;
            }
          }

          return null;
        });
      }

      return (
        <div style={{ marginTop: '30px' }}>
          <Label>{t('serviceConnectionPivotItem.label.bodyProps')}</Label>
          <Stack horizontal styles={{ root: { height: 44 } }}>
            <CommandBarButton
              id="add-new-req-body-prop-btn"
              iconProps={{ iconName: 'Add' }}
              text={t('serviceConnectionPivotItem.label.newReqBodyProp')}
              disabled={propertiesDisabled || errorInList}
              onClick={() => setShowNewEditBodyPropDialog(true)}
              style={{ borderBottom: '1px solid #D3D3D3' }}
            />
          </Stack>
          {service && !propertiesDisabled ? (
            <RequestBodyPropertiesList
              hidden={hiddenListOnly}
              service={service}
              saveBodyProperties={(props) => handleCurrentInput('requestBody', props)}
              showCreateDialog={showNewEditBodyPropDialog}
              hideCreateDialog={onDismissBodyPropDialog}
              disabledNewRow={propertiesDisabled || disabledNewRow}
              handleErrors={handleErrorsFromRequestLists}
              bodyPropertiesErrors={inputErrors}
            />
          ) : null}
        </div>
      );
    }

    return null;
  }

  function isMethodDisabled() {
    const connection = service?.serviceData?.connection;

    let disabled = false;

    if (
      connection?.method === 'POST' &&
      connection.requestBody?.properties &&
      Object.keys(connection.requestBody.properties)?.length
    ) {
      disabled = true;
    }

    return disabled;
  }

  return (
    <ConnectionTabStyled>
      <TabDescriptionStyled>{t('serviceConnectionPivotItem.description')}</TabDescriptionStyled>
      <TextField
        id="connection-url"
        autoFocus
        disabled={false}
        label={t('serviceConnectionPivotItem.label.url')}
        onChange={(ev, value) => handleUrl(value)}
        required
        defaultValue={service?.serviceData?.connection?.url}
        multiline={service?.serviceData?.connection?.url?.length > maxStringLength}
        autoAdjustHeight
        errorMessage={fieldToFocus?.name === 'connection-url' ? fieldToFocus?.message : null}
      />
      <DevidedRow className="mt-1">
        <div className="left-half half-width">
          <Dropdown
            id="connection-method"
            disabled={isMethodDisabled()}
            label={t('serviceConnectionPivotItem.label.method')}
            placeholder={t('serviceConnectionPivotItem.placeholder.method')}
            required
            options={methodOptions}
            value={service?.serviceData?.connection?.method}
            defaultSelectedKey={service?.serviceData?.connection?.method || 'GET'}
            onChange={(_, value) => handleMethod(value)}
            errorMessage={fieldToFocus?.name === 'connection-method' ? fieldToFocus?.message : null}
          />
        </div>
        <div className="right-half half-width">
          <Dropdown
            disabled={false}
            label={t('serviceConnectionPivotItem.label.contentType')}
            placeholder={t('serviceConnectionPivotItem.placeholder.contentType')}
            required={false}
            options={contentTypeOptions}
            value={service?.serviceData?.connection?.contentType}
            defaultSelectedKey={service?.serviceData?.connection?.contentType || 1}
            onChange={(_, value) => handleContentType(value)}
          />
        </div>
      </DevidedRow>
      <PropertiesContainer>
        {fieldToFocus?.name === 'connection-property' && fieldToFocus?.message ? (
          <RequestPropertyError id="connection-property">
            {fieldToFocus.message}
          </RequestPropertyError>
        ) : null}
        {getRequestFieldsLists()}
        {getRequestBodyList()}
      </PropertiesContainer>
      <Dropdown
        id="connection-auth"
        autoFocus={!!(fieldToFocus?.name === 'auth' && fieldToFocus?.message)}
        label={t('serviceConnectionPivotItem.label.authType')}
        placeholder={t('serviceConnectionPivotItem.placeholder.authType')}
        required
        options={authTypeOptions(t)}
        errorMessage={fieldToFocus?.name === 'connection-auth' ? fieldToFocus?.message : null}
        defaultSelectedKey={
          service?.serviceData?.connection?.auth || service?.serviceData?.connection?.auth === 0
            ? service?.serviceData?.connection?.auth
            : null
        }
        onChange={(_, value) => handleAuthType(value)}
      />
      {getAuthenticationFields()}
    </ConnectionTabStyled>
  );
}

ServiceConnectionPivotItem.propTypes = {
  service: PropTypes.any,
  handleServiceState: PropTypes.func.isRequired,
  fieldToFocus: PropTypes.object,
  handleErrors: PropTypes.func,
  inputErrors: PropTypes.arrayOf(PropTypes.object)
};

ServiceConnectionPivotItem.defaultProps = {
  service: null,
  fieldToFocus: null,
  handleErrors: null,
  inputErrors: null
};

export default ServiceConnectionPivotItem;

export function authTypeOptions(t) {
  return [
    {
      key: 0,
      text: t('serviceConnectionPivotItem.authType.none')
    },
    {
      key: 1,
      text: t('serviceConnectionPivotItem.authType.user')
    },
    {
      key: 2,
      text: t('serviceConnectionPivotItem.authType.app')
    },
    {
      key: 3,
      text: 'Basic'
    }
  ];
}
