import BooleanField from 'components/inputs/BooleanField';
import DocumentField from 'components/inputs/DocumentField';
import HyperLinkField from 'components/inputs/HyperLinkField';
import MultiMediaField, { IMultiMediaFieldProps } from 'components/inputs/MultiMediaField';
import Rating from 'components/inputs/Rating';
import RichTextEditor from 'components/inputs/RichTextEditor';
import SignatureField from 'components/inputs/SignatureField';
import moment from 'moment';
import {
  IAIFieldValueProps,
  IFieldChoiceProps,
  IFieldLinkProps,
  IFieldSurveyResultValueProps,
  IFileProps,
  ILinkProps,
  IPersonOrTeamProps,
  ISignatureValueProps,
  IUserProps,
  RouteFieldType
} from 'types';
import { convertEpUserOrTeamToPersonaProps } from 'utils/helpers';
import { v4 as uuidv4 } from 'uuid';
import { Persona, PersonaSize } from '@fluentui/react';
import SurveyResultDefaultValueWrapper from './SurveyResultDefaultValueWrapper';

export interface IDefaultResultContentProps {
  fieldLink: IFieldLinkProps;
  tenantId?: string;
  onOpenWopi?: (fileId: string) => void;
  getFileContents?: ({ id }: { id: string }) => Promise<Response>;
  getStreamUrl?: IMultiMediaFieldProps['getStreamUrl'];
}

interface IAppendChangesProps {
  currentValue: string;
  versions: IAppendChangesVersionProps[];
}

interface IAppendChangesVersionProps {
  value: string;
  editDate?: string;
  taskId?: string;
  editor?: IUserProps;
}

function DefaultResultContent({
  fieldLink,
  tenantId,
  onOpenWopi,
  getStreamUrl,
  getFileContents
}: IDefaultResultContentProps): JSX.Element | null {
  const { values, field } = fieldLink;
  const fieldType = field?.fieldType;

  const defaultValueToDisplay = <div>-</div>;

  function renderSmallTextContent(textFieldResultProps: IFieldSurveyResultValueProps) {
    if (typeof textFieldResultProps.value === 'string') {
      return <div>{textFieldResultProps.value}</div>;
    }

    return defaultValueToDisplay;
  }

  function isLongTextFieldWithAppendChanges(
    valueToCheck: IAppendChangesProps | unknown
  ): valueToCheck is IAppendChangesProps {
    return (
      !!(valueToCheck as IAppendChangesProps).currentValue ||
      !!(valueToCheck as IAppendChangesProps).versions?.length
    );
  }

  function renderLongTextContent(textFieldResultProps: IFieldSurveyResultValueProps) {
    const { value } = textFieldResultProps;
    let valueToDisplay;

    if (value && isLongTextFieldWithAppendChanges(value)) {
      // check both: currentValue and versions
      if (value?.currentValue) {
        // currentValue
        valueToDisplay = value?.currentValue;
      } else if (value?.versions?.length) {
        // get the last version
        const latestVersion = value?.versions[0];
        valueToDisplay = latestVersion?.value;
      }
    } else {
      valueToDisplay = value;
    }

    if (typeof valueToDisplay === 'string') {
      return (
        <RichTextEditor
          styles={{ editorWrapper: { padding: 0 } }}
          defaultValue={valueToDisplay}
          disabled
          required={false}
        />
      );
    }

    return defaultValueToDisplay;
  }

  function renderDateTimeContent(textFieldResultProps: IFieldSurveyResultValueProps) {
    const { value } = textFieldResultProps;
    const valueType = typeof value;

    if (value && (valueType === 'string' || value instanceof Date)) {
      if (field?.dateFormat === 1) {
        // without time
        return moment(value as string).format('LL');
      }

      if (field?.dateFormat === 2) {
        // with time
        return moment(value as string).format('LLLL');
      }

      return defaultValueToDisplay;
    }

    return defaultValueToDisplay;
  }

  function renderBooleanFieldContent(textFieldResultProps: IFieldSurveyResultValueProps) {
    const { value } = textFieldResultProps;

    return (
      <div style={{ marginTop: 5 }}>
        <BooleanField
          defaultValue={!!value}
          textOk={field?.textOk}
          confirmationFormat={field?.confirmationFormat}
          textNOk={field?.textNOk}
          disabled
          styles={{
            positiveButton: { root: { height: '25px' } },
            negativeButton: { root: { height: '25px' } }
          }}
        />
      </div>
    );
  }

  function renderPersonFieldContent(textFieldResultProps: IFieldSurveyResultValueProps) {
    const { value } = textFieldResultProps;
    const styles = {
      marginTop: '5px',
      border: '1px solid #d5d5d5',
      borderRadius: '21px',
      maxWidth: 'fit-content',
      padding: '4px',
      paddingLeft: '4px',
      background: '#fdfafa'
    };

    if (value) {
      const { teams, users } = value as IPersonOrTeamProps;
      const personas = [...teams, ...users].map(convertEpUserOrTeamToPersonaProps);

      return (
        <div style={{ display: 'flex', gap: 5, flexWrap: 'wrap' }}>
          {personas.map((personaProps) => {
            return (
              <div key={uuidv4()} style={styles}>
                <Persona
                  size={PersonaSize.size32}
                  imageUrl={personaProps.imageUrl}
                  text={personaProps.text}
                  showSecondaryText
                  secondaryText={personaProps.secondaryText}
                  styles={{ root: { maxWidth: 'fit-content' } }}
                />
              </div>
            );
          })}
        </div>
      );
    }

    return defaultValueToDisplay;
  }

  function renderHyperlinkFieldContent(textFieldResultProps: IFieldSurveyResultValueProps) {
    const { value } = textFieldResultProps;

    if (value) {
      return (
        <HyperLinkField
          styles={{
            linkWrapperStyles: {
              backgroundColor: 'transparent',
              padding: 3,
              border: 'none'
            }
          }}
          defaultValue={value as ILinkProps}
          disabled
        />
      );
    }

    return defaultValueToDisplay;
  }

  function renderDocumentFieldContent(textFieldResultProps: IFieldSurveyResultValueProps) {
    const { value } = textFieldResultProps;
    const file = value as IFileProps;

    if (file && file.url) {
      const fileIdIndex = file.url.indexOf('id=') + 3;
      const id = file.url.substr(fileIdIndex);

      const defaultValue = { ...file, id };

      return (
        <div style={{ marginTop: 6 }}>
          <DocumentField
            disabled
            onOpenWopi={onOpenWopi}
            displayImagePreview
            getFileContents={getFileContents}
            defaultValue={defaultValue}
          />
        </div>
      );
    }

    return defaultValueToDisplay;
  }

  function renderSignatureFieldContent(textFieldResultProps: IFieldSurveyResultValueProps) {
    const { value } = textFieldResultProps;
    const signature = value as ISignatureValueProps;

    if (value) {
      return (
        <div style={{ marginTop: 6 }}>
          <SignatureField defaultValue={signature} disabled />
        </div>
      );
    }

    return defaultValueToDisplay;
  }

  function renderMultiMediaFieldContent(textFieldResultProps: IFieldSurveyResultValueProps) {
    const { value } = textFieldResultProps;

    if (!getStreamUrl) return defaultValueToDisplay;

    if (Array.isArray(value) && getFileContents && tenantId) {
      const defaultValue = value?.map((fileValue: IFileProps) => {
        if (
          fileValue.url &&
          fileValue.url !== 'https://tempuri.org' &&
          !fileValue.url?.includes('microsoftstream.com')
        ) {
          const fileIdIndex = fileValue.url.indexOf('id=') + 3;
          const id = fileValue.url.substr(fileIdIndex);

          return { ...fileValue, id };
        }

        return { ...fileValue };
      });

      return (
        <div style={{ marginTop: 6 }}>
          <MultiMediaField
            getStreamUrl={getStreamUrl}
            defaultValue={defaultValue}
            tenantId={tenantId}
            getFileContents={getFileContents}
            disabled
          />
        </div>
      );
    }

    return defaultValueToDisplay;
  }

  function renderRatingFieldContent(textFieldResultProps: IFieldSurveyResultValueProps) {
    const { value } = textFieldResultProps;

    // check if value exists and is a number
    if (value && typeof value === 'number') {
      return (
        <Rating
          styles={{
            root: {
              marginTop: 0,
              border: 'none',
              backgroundColor: 'none',
              borderRadius: '0px',
              ':hover': { border: 'none' }
            }
          }}
          defaultValue={value}
          disabled
          maxRating={field?.numStars || 5}
        />
      );
    }

    return defaultValueToDisplay;
  }

  function renderNumberFieldContent(textFieldResultProps: IFieldSurveyResultValueProps) {
    if (
      typeof textFieldResultProps.value === 'string' ||
      typeof textFieldResultProps.value === 'number'
    ) {
      return <div>{textFieldResultProps.value}</div>;
    }

    return defaultValueToDisplay;
  }

  function renderChoiceFieldContent(textFieldResultProps: IFieldSurveyResultValueProps) {
    const { value, aggregation } = textFieldResultProps;

    const selectedChoices = value as IFieldChoiceProps[];

    if (Array.isArray(selectedChoices) && aggregation !== 8) {
      return (
        <div>
          {selectedChoices.map((v) => (
            <>
              {v.value ? `- ${v.value}` : '-'}
              <br />
            </>
          ))}
        </div>
      );
    }

    return defaultValueToDisplay;
  }

  function renderAIFieldContent(textFieldResultProps: IFieldSurveyResultValueProps) {
    const { value } = textFieldResultProps;

    const createdValue = value as IAIFieldValueProps;

    return <div>{createdValue?.choices?.[0].text}</div>;
  }

  function onRenderContent(textFieldResultProps: IFieldSurveyResultValueProps) {
    if (fieldType === RouteFieldType.SmallText || fieldType === RouteFieldType.Scanner) {
      return renderSmallTextContent(textFieldResultProps);
    }

    if (fieldType === RouteFieldType.LongText) {
      return renderLongTextContent(textFieldResultProps);
    }

    if (fieldType === RouteFieldType.DateTime) {
      return renderDateTimeContent(textFieldResultProps);
    }

    if (fieldType === RouteFieldType.Boolean) {
      return renderBooleanFieldContent(textFieldResultProps);
    }

    if (fieldType === RouteFieldType.Person) {
      return renderPersonFieldContent(textFieldResultProps);
    }

    if (fieldType === RouteFieldType.Hyperlink) {
      return renderHyperlinkFieldContent(textFieldResultProps);
    }

    if (fieldType === RouteFieldType.Document) {
      return renderDocumentFieldContent(textFieldResultProps);
    }

    if (fieldType === RouteFieldType.Signature) {
      return renderSignatureFieldContent(textFieldResultProps);
    }

    if (fieldType === RouteFieldType.Multimedia) {
      return renderMultiMediaFieldContent(textFieldResultProps);
    }

    if (fieldType === RouteFieldType.Rating) {
      return renderRatingFieldContent(textFieldResultProps);
    }

    if (fieldType === RouteFieldType.Number) {
      return renderNumberFieldContent(textFieldResultProps);
    }

    if (fieldType === RouteFieldType.Choice) {
      return renderChoiceFieldContent(textFieldResultProps);
    }

    if (fieldType === RouteFieldType.AI) {
      return renderAIFieldContent(textFieldResultProps);
    }

    return defaultValueToDisplay;
  }

  function renderTextFieldValue(textFieldResultProps: IFieldSurveyResultValueProps, index: number) {
    const valuesLength = fieldLink.values?.length;

    if (textFieldResultProps.aggregation === 1) {
      return (
        <div>
          <SurveyResultDefaultValueWrapper
            fieldResultProps={textFieldResultProps}
            onRenderContent={onRenderContent}
            renderBottomBorder={index + 1 !== valuesLength}
          />
        </div>
      );
    }

    return null;
  }

  if (values && Array.isArray(values)) {
    return <div>{values.map(renderTextFieldValue)}</div>;
  }

  return null;
}

export default DefaultResultContent;
