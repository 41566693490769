import {
  ButtonHTMLAttributes,
  forwardRef,
  ReactNode,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  ActionButton,
  Callout,
  DefaultButton,
  DirectionalHint,
  Icon,
  Layer,
  ProgressIndicator
} from '@fluentui/react';
import moment from 'moment';
import { useId } from '@fluentui/react-hooks';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import {
  BackgroundJobs,
  BackgroundJobsEventType,
  BackgroundJobsState,
  BackgroundJobStatus,
  IBackgroundJob,
  IBackgroundJobResult,
  IBackgroundJobsEvent,
  IBackgroundJobsMenuTarget,
  IJobProgress
} from './model';
import SpinningProcessingIcon from './SpinningProcessingIcon';
import { ProgressItems, useJobProgress } from './JobProgress';

/**
 * While we prioritize pending jobs over completed jobs in the menu button preview,
 * we want to keep showing the current preview job for a little while after it ends.
 */
const PREVIEW_ENDED_LINGER_DURATION = 2000;

/** Duration of the 'do not close your browser' warning */
const CLOSE_WARNING_DURATION = 5000;

/** Tasks that appear with a close warning will be artificially extended to be 'in progress' for at least this amount of time */
const CLOSE_WARNING_ARTIFICIAL_EXTENSION = 3500;

/** Minimum time interval between two warnings that you should not close your browser window */
const MIN_CLOSE_WARNING_INTERVAL = 5 * 60 * 1000; // 5 min

interface IPreviewJob {
  job: IBackgroundJob;
  addedTime: number;
  endedTime: number | null;
  /** Will be set at endedTime + linger duration */
  ended: boolean;
  /** If true, this job is waiting for its overlay to end and cannot be shown yet. */
  isAwaitingOverlayEnd: boolean;
}

/** Returns ordering of two preview jobs. */
function previewJobCmp(lhs: IPreviewJob, rhs: IPreviewJob) {
  if (lhs.ended !== rhs.ended) {
    if (lhs.ended && !rhs.ended) return 1;
    return -1;
  }
  if (lhs.ended && rhs.ended) {
    return rhs.endedTime - lhs.endedTime;
  }
  return rhs.addedTime - lhs.addedTime;
}

function createPreviewJob(
  job: IBackgroundJob,
  isAwaitingOverlayEnd: boolean,
  now = document.timeline.currentTime
): IPreviewJob {
  const didEnd = job.getProgress().status !== BackgroundJobStatus.Pending;

  return {
    job,
    addedTime: now,
    endedTime: didEnd ? now : null,
    ended: didEnd,
    isAwaitingOverlayEnd
  };
}

/** Returns visible jobs in preview order, i.e. ordered by 'relevance'. */
function usePreviewJobs(): { jobs: IBackgroundJob[]; noJobs: boolean } {
  const backgroundJobs = useContext(BackgroundJobs);
  const jobList = useContext(BackgroundJobsState);
  const [previewJobs, setPreviewJobs] = useState<IPreviewJob[]>(() => {
    const now = document.timeline.currentTime;
    return jobList.map(({ job, isAwaitingOverlayEnd }) =>
      createPreviewJob(job, isAwaitingOverlayEnd, now)
    );
  });

  useEffect(() => {
    setPreviewJobs((previewJobs) => {
      const result: IPreviewJob[] = [];

      for (const { job, isAwaitingOverlayEnd } of jobList) {
        const progress = job.getProgress();
        const existingItem = previewJobs.find((item) => item.job === job);

        if (existingItem) {
          let newItem = existingItem;

          if (progress.status !== BackgroundJobStatus.Pending && existingItem.endedTime === null) {
            // just ended
            newItem = { ...existingItem, endedTime: document.timeline.currentTime };
          }

          if (existingItem.isAwaitingOverlayEnd !== isAwaitingOverlayEnd) {
            newItem = { ...existingItem, isAwaitingOverlayEnd };
          }

          result.push(newItem);
        } else {
          result.push(createPreviewJob(job, isAwaitingOverlayEnd));
        }
      }

      return result;
    });
  }, [backgroundJobs, jobList]);

  useEffect(() => {
    let nextTimerJob: IBackgroundJob | null = null;
    let nextTimerJobEndedTime = Infinity;
    for (const item of previewJobs) {
      if (item.endedTime !== null && !item.ended && item.endedTime < nextTimerJobEndedTime) {
        nextTimerJob = item.job;
        nextTimerJobEndedTime = item.endedTime;
      }
    }

    if (nextTimerJob) {
      const timeLeft =
        nextTimerJobEndedTime + PREVIEW_ENDED_LINGER_DURATION - document.timeline.currentTime;

      const timeout = setTimeout(() => {
        setPreviewJobs((jobs) =>
          jobs.map((item) => {
            if (item.job === nextTimerJob) {
              return { ...item, ended: true };
            }
            return item;
          })
        );
      }, Math.max(timeLeft, 0));
      return () => clearTimeout(timeout);
    }

    return () => undefined;
  }, [previewJobs]);

  const jobs = useMemo(
    () =>
      previewJobs
        .filter((item) => !item.isAwaitingOverlayEnd)
        .sort((a, b) => previewJobCmp(a, b))
        .map((item) => item.job),
    [previewJobs]
  );
  return { jobs, noJobs: !previewJobs.length };
}

const OBJECT_KEYS = new WeakMap<object, string>();
function getObjectKey(obj: object) {
  if (OBJECT_KEYS.has(obj)) return OBJECT_KEYS.get(obj);
  const key = Math.random().toString();
  OBJECT_KEYS.set(obj, key);
  return key;
}

const MenuButtonStyled = styled.button`
  background: rgb(
    ${({ theme }) => theme.backgroundJobs.menuButtonBackground} /
      ${({ theme }) => theme.backgroundJobs.menuButtonBackgroundOpacity}
  );
  border: none;
  margin: 0;
  border-radius: 4px;
  color: rgb(${({ theme }) => theme.backgroundJobs.menuButtonForeground});
  font: inherit;
  text-align: initial;
  overflow: hidden;
  width: 100%;
  max-width: 200px;
  height: 36px;
  display: grid;
  padding: 0 0.5em 0 0;
  grid-template-columns: 1fr auto;
  transition: background 0.2s, max-width 0.3s;

  &.is-empty {
    visibility: hidden;
  }

  &.no-jobs {
    max-width: 0;
  }

  &:focus-visible {
    box-shadow: inset 0 0 0 1px rgb(${({ theme }) => theme.backgroundJobs.menuButtonForeground});
  }

  @media (hover: hover) {
    &:hover {
      background: rgb(
        ${({ theme }) => theme.backgroundJobs.menuButtonHoverBackground} /
          ${({ theme }) => theme.backgroundJobs.menuButtonHoverBackgroundOpacity}
      );
    }
  }

  &:active {
    transition: background 0.1s;
    background: rgb(
      ${({ theme }) => theme.backgroundJobs.menuButtonActiveBackground} /
        ${({ theme }) => theme.backgroundJobs.menuButtonActiveBackgroundOpacity}
    );
  }

  .c-job-preview {
    display: grid;
    grid-template-columns: 32px 1fr;
    align-items: center;
    height: 36px;
    padding-right: 0.5em;
    gap: 0.2em;
    animation: background-job-menu-button-job-preview-appear 0.5s;

    > .c-icon {
      place-self: center;

      &.is-completed {
        animation: background-job-menu-button-job-completed-icon 0.4s;
      }
    }

    > .c-details {
      min-width: 0;

      > .c-title,
      > .c-description {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      > .c-title {
        line-height: 1.2;
      }

      > .c-description {
        font-size: smaller;
        line-height: 1;
        max-height: 1em;
      }

      > .c-progress-indicator {
        margin: 2px 0;
      }
    }
  }

  > .c-extra-items {
    place-self: center;
    background: rgb(${({ theme }) => theme.backgroundJobs.menuButtonForeground} / 0.1);
    border: 1px solid rgb(${({ theme }) => theme.backgroundJobs.menuButtonForeground} / 0.1);
    box-sizing: content-box;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    font-size: smaller;
    text-align: center;
    overflow: hidden;
  }

  @keyframes background-job-menu-button-job-preview-appear {
    0% {
      transform: translateY(70%);
      opacity: 0;
      animation-timing-function: cubic-bezier(0.1, 0.4, 0.3, 1);
    }
    30% {
      transform: translateY(-4px);
      opacity: 1;
      animation-timing-function: cubic-bezier(0.3, 0, 0, 1);
    }
  }

  @keyframes background-job-menu-button-job-completed-icon {
    0% {
      animation-timing-function: cubic-bezier(0, 0.5, 0.7, 1);
    }
    20% {
      animation-timing-function: cubic-bezier(0.2, 0.2, 0, 1);
      transform: translateY(3px);
    }
  }
`;

export interface IBackgroundJobsMenuButtonRef {
  node: HTMLButtonElement | null;
}

export const BackgroundJobsMenuButton = forwardRef<
  IBackgroundJobsMenuButtonRef,
  ButtonHTMLAttributes<HTMLButtonElement> & { suppressPopovers?: boolean }
>(function BackgroundJobsMenuButton(
  {
    suppressPopovers,
    ...props
  }: ButtonHTMLAttributes<HTMLButtonElement> & { suppressPopovers?: boolean },
  ref
) {
  const backgroundJobs = useContext(BackgroundJobs);
  const nodeRef = useRef<HTMLButtonElement>(null);
  const { jobs: previewJobs, noJobs } = usePreviewJobs();

  const currentNode = nodeRef.current;
  useImperativeHandle(ref, () => ({ node: currentNode }), [currentNode]);

  // register menu target
  useEffect(() => {
    const target: IBackgroundJobsMenuTarget = {
      getPosition() {
        const rect = currentNode.getBoundingClientRect();
        return { x: rect.left + rect.width / 2, y: rect.bottom };
      }
    };
    backgroundJobs.addMenuTarget(target);
    return () => backgroundJobs.removeMenuTarget(target);
  }, [backgroundJobs, currentNode]);

  const newlyAddedRunningJobs: IBackgroundJob[] = [];
  const prevPreviewJobs = useRef(new WeakSet<IBackgroundJob>());
  for (const job of previewJobs) {
    if (!prevPreviewJobs.current.has(job)) {
      prevPreviewJobs.current.add(job);

      if (job.getProgress().status === BackgroundJobStatus.Pending) {
        newlyAddedRunningJobs.push(job);
      }
    }
  }

  /** Set of jobs that we should artificially extend the runtime of to a minimum end time due to a close warning */
  const artificiallyExtendedEndTimes = useRef(new WeakMap<IBackgroundJob, number>());

  const lastCloseWarningTime = useRef(0);
  const [shouldShowCloseWarning, setShouldShowCloseWarning] = useState(false);

  const sinceLastCloseWarning = Date.now() - lastCloseWarningTime.current;
  const shouldShowCloseWarningNow =
    newlyAddedRunningJobs.length && sinceLastCloseWarning > MIN_CLOSE_WARNING_INTERVAL;
  if (shouldShowCloseWarningNow) {
    lastCloseWarningTime.current = Date.now();
    for (const job of newlyAddedRunningJobs) {
      artificiallyExtendedEndTimes.current.set(
        job,
        document.timeline.currentTime + CLOSE_WARNING_ARTIFICIAL_EXTENSION
      );
    }
  }

  useEffect(() => {
    if (shouldShowCloseWarningNow) {
      setShouldShowCloseWarning(true);
    }
  }, [shouldShowCloseWarningNow]);

  const currentPreview = previewJobs[0];
  const extraItemCount = Math.max(0, previewJobs.length - 1);

  useEffect(() => {
    if (suppressPopovers) {
      setShouldShowCloseWarning(false);
    }
  }, [suppressPopovers]);

  return (
    <MenuButtonStyled
      type="button"
      {...props}
      disabled={props.disabled || noJobs}
      className={`background-jobs-menu-button ${noJobs ? 'no-jobs' : ''} ${
        previewJobs.length ? '' : 'is-empty'
      } ${props.className ?? ''}`}
      ref={nodeRef}
    >
      {currentPreview ? (
        <MenuJobPreview
          job={currentPreview}
          key={getObjectKey(currentPreview)}
          artificiallyExtendedEndTime={
            artificiallyExtendedEndTimes.current.get(currentPreview) ?? null
          }
        />
      ) : null}
      {extraItemCount ? <div className="c-extra-items">+{extraItemCount}</div> : null}

      {shouldShowCloseWarning ? (
        <CloseWarning anchor={nodeRef.current} onEnd={() => setShouldShowCloseWarning(false)} />
      ) : null}
    </MenuButtonStyled>
  );
});

function MenuJobPreview({
  job,
  artificiallyExtendedEndTime
}: {
  job: IBackgroundJob;
  artificiallyExtendedEndTime: number | null;
}) {
  const theme = useTheme();
  const [progress, setProgress] = useState(() => job.getProgress());

  const [canShowDone, setCanShowDone] = useState(() =>
    artificiallyExtendedEndTime ? document.timeline.currentTime > artificiallyExtendedEndTime : true
  );
  useEffect(() => {
    if (!artificiallyExtendedEndTime) return () => undefined;

    const timeLeft = artificiallyExtendedEndTime - document.timeline.currentTime;
    const timeout = setTimeout(() => {
      setCanShowDone(true);
    }, Math.max(1, timeLeft));
    return () => clearTimeout(timeout);
  }, [job, artificiallyExtendedEndTime]);

  useEffect(() => {
    const onUpdate = () => {
      const progress = job.getProgress();
      if (progress.status === BackgroundJobStatus.Done && !canShowDone) {
        return;
      }
      setProgress(job.getProgress());
    };
    onUpdate();

    job.addProgressUpdateListener(onUpdate);
    return () => job.removeProgressUpdateListener(onUpdate);
  }, [job, canShowDone]);

  if (canShowDone && progress.status === BackgroundJobStatus.Done) {
    const result = job.getResult();

    return (
      <div className="c-job-preview">
        <JobIcon progress={progress} result={result} />
        <div className="c-details">
          <div className="c-title">{result.title}</div>
          <div className="c-description">{result.description}</div>
        </div>
      </div>
    );
  }

  const percentComplete = (() => {
    if (progress.items.length === 1) {
      return progress.items[0].progress ?? undefined;
    }

    if (progress.items.length > 1) {
      return (
        progress.items
          .map((item) => {
            if (item.progress !== undefined) {
              return item.progress;
            }
            return 0.5;
          })
          .reduce((a, b) => a + b, 0) / progress.items.length
      );
    }

    return undefined;
  })();

  return (
    <div className="c-job-preview">
      <JobIcon progress={progress} />
      <div className="c-details">
        <div className="c-title">{progress.title}</div>
        {progress.status === BackgroundJobStatus.Pending ? (
          <ProgressIndicator
            className="c-progress-indicator"
            percentComplete={percentComplete}
            styles={{
              itemProgress: { padding: '0' },
              progressTrack: {
                background: `rgb(${theme.backgroundJobs.menuButtonForeground} / 0.1)`
              },
              progressBar: {
                background: `rgb(${theme.backgroundJobs.menuButtonForeground})`
              }
            }}
          />
        ) : null}
      </div>
    </div>
  );
}

const CLOSE_WARNING_WIDTH = 250;
const CloseWarningStyled = styled.div`
  position: absolute;
  width: 100%;
  margin-top: 0.5rem;
  max-width: ${CLOSE_WARNING_WIDTH}px;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  background: rgb(${({ theme }) => theme.backgroundJobs.closeWarningBackground});
  color: rgb(${({ theme }) => theme.backgroundJobs.closeWarningForeground});
  border: 1px solid rgb(${({ theme }) => theme.backgroundJobs.closeWarningOutline});
  box-shadow: ${({ theme }) => theme.backgroundJobs.closeWarningShadow};
  animation: background-jobs-close-warning-appear 1s 0.5s backwards;
  pointer-events: none;

  display: flex;
  align-items: center;
  gap: 0.5rem;

  > .c-icon {
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
  }

  &.is-disappearing {
    animation: background-jobs-close-warning-disappear 0.5s forwards;
  }

  @keyframes background-jobs-close-warning-appear {
    from {
      animation-timing-function: cubic-bezier(0, 0, 0, 1);
      opacity: 0;
      transform: translateY(-1rem);
    }
  }
  @keyframes background-jobs-close-warning-disappear {
    from {
      animation-timing-function: cubic-bezier(1, 0, 1, 1);
    }
    to {
      opacity: 0;
      transform: translateY(-0.5rem);
    }
  }
`;

function CloseWarning({ anchor, onEnd }: { anchor: HTMLElement | null; onEnd: () => void }) {
  const { t } = useTranslation();
  const timeAdded = useMemo(() => document.timeline.currentTime, []);
  const [isDisappearing, setIsDisappearing] = useState(false);

  useEffect(() => {
    const timeLeftUntilEnd = timeAdded + CLOSE_WARNING_DURATION - document.timeline.currentTime;
    const timeLeftUntilDisappear = timeLeftUntilEnd - 500;

    const timeout1 = setTimeout(() => setIsDisappearing(true), Math.max(1, timeLeftUntilDisappear));
    const timeout2 = setTimeout(onEnd, Math.max(1, timeLeftUntilEnd));
    return () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);
    };
  }, [onEnd, timeAdded]);

  if (!anchor) return null;

  const closeWarningWidth = Math.min(window.innerWidth, CLOSE_WARNING_WIDTH);
  const anchorRect = anchor.getBoundingClientRect();

  return (
    <div style={{ display: 'none' }}>
      <Layer styles={{ root: { zIndex: 'calc(infinity)' } }}>
        <CloseWarningStyled
          className={isDisappearing ? 'is-disappearing' : ''}
          style={{
            top: anchorRect.bottom,
            left: Math.max(0, anchorRect.left + (anchorRect.width - closeWarningWidth) / 2)
          }}
        >
          <SpinningProcessingIcon className="c-icon" />
          {t('backgroundJobs.closeWarning.title')}
        </CloseWarningStyled>
      </Layer>
    </div>
  );
}

function JobIcon({ progress, result }: { progress: IJobProgress; result?: IBackgroundJobResult }) {
  if (progress.status === BackgroundJobStatus.Done) {
    if (result?.icon) {
      return <div className="c-icon">{result.icon}</div>;
    }

    return (
      <Icon
        iconName={result?.iconName ?? 'Completed'}
        className="c-icon is-completed"
        styles={{ root: { fontSize: '20px' } }}
      />
    );
  }

  if (progress.status === BackgroundJobStatus.Failed) {
    return (
      <Icon
        iconName="ErrorBadge"
        className="c-icon is-failed"
        styles={{ root: { fontSize: '20px', fontWeight: '500' } }}
      />
    );
  }

  if (progress.icon) {
    return <div className="c-icon">{progress.icon}</div>;
  }

  return (
    <Icon iconName={progress.iconName} className="c-icon" styles={{ root: { fontSize: '20px' } }} />
  );
}

const MenuContentsStyled = styled.div`
  > .c-header {
    border-bottom: 1px solid rgb(${({ theme }) => theme.backgroundJobs.menuDivider});

    > .c-title-row {
      display: flex;
      gap: 0.5rem;
      padding-left: 0.5rem;
      align-items: center;

      > .c-icon {
        width: 1.5rem;
        height: 1.5rem;
      }

      > .c-title {
        font-weight: bold;
        font-size: 1.2em;
        flex: 1;
      }

      > .c-clear.is-disabled {
        opacity: 0.5;
      }
    }

    > .c-description {
      padding: 0 0.5rem 0.5rem;
      font-size: 0.8rem;
    }
  }

  > .background-job-item + .background-job-item {
    border-top: 1px solid rgb(${({ theme }) => theme.backgroundJobs.menuDivider});
  }
`;

export function BackgroundJobsMenuContents({ onDismiss }: { onDismiss: () => void }) {
  const { t } = useTranslation();
  const backgroundJobs = useContext(BackgroundJobs);
  const jobs = useContext(BackgroundJobsState);

  const { hasRunningJobs, hasCompletedJobs } = useMemo(
    () => ({
      hasRunningJobs: jobs.some(
        (item) => item.job.getProgress().status === BackgroundJobStatus.Pending
      ),
      hasCompletedJobs: jobs.some(
        (item) => item.job.getProgress().status !== BackgroundJobStatus.Pending
      )
    }),
    [jobs]
  );

  return (
    <MenuContentsStyled>
      <div className="c-header">
        <div className="c-title-row">
          <SpinningProcessingIcon className="c-icon" paused={!hasRunningJobs} />
          <div className="c-title">{t('backgroundJobs.menu.title')}</div>
          <ActionButton
            iconProps={{ iconName: 'CheckMark' }}
            className="c-clear"
            text={t('backgroundJobs.menu.clearAllCompleted')}
            disabled={!hasCompletedJobs}
            onClick={() => {
              backgroundJobs.removeCompleted();
              onDismiss();
            }}
          />
        </div>
        <div className="c-description">{t('backgroundJobs.menu.description')}</div>
      </div>
      {jobs.map((item) => (
        <BackgroundJobItem
          job={item.job}
          lastUpdatedTime={item.lastUpdatedTime}
          key={getObjectKey(item.job)}
          onRemove={() => {
            backgroundJobs.remove(item.job);
            onDismiss();
          }}
        />
      ))}
    </MenuContentsStyled>
  );
}

const BackgroundJobItemStyled = styled.div`
  padding: 0.5rem;

  > .c-contents > .c-header {
    display: grid;
    grid-template-columns: 2rem 1fr auto;
    gap: 0.25rem;
    align-items: center;

    > .c-icon {
      place-self: center;

      &.is-completed {
        color: rgb(${({ theme }) => theme.backgroundJobs.menuCompletedIconColor});
      }
      &.is-failed {
        color: rgb(${({ theme }) => theme.backgroundJobs.menuFailedIconColor});
      }
    }

    > .c-title {
      font-weight: bold;
    }

    > .c-time {
      opacity: 0.8;
      font-size: smaller;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  > .c-contents > .c-description {
    font-size: smaller;
    margin-left: 2.25rem;

    &:empty {
      display: none;
    }
  }

  > .c-contents > .c-progress-items {
    font-size: smaller;
    margin: 0 0 0 2.25rem;
  }

  > .c-action-container {
    margin-top: 0.5rem;
    padding-left: 2.25rem;
  }
`;

function BackgroundJobItem({
  job,
  lastUpdatedTime,
  onRemove
}: {
  job: IBackgroundJob;
  lastUpdatedTime: number;
  onRemove: () => void;
}) {
  const progress = useJobProgress(job);
  const lastUpdatedTimeFormatted = moment(new Date(lastUpdatedTime)).fromNow();

  const shouldShowFailedProgressItemsOnly = progress.status === BackgroundJobStatus.Done;
  const progressItems = (
    <ProgressItems
      className="c-progress-items"
      showFailedOnly={shouldShowFailedProgressItemsOnly}
      showIndividualItems="auto"
      items={progress.items}
    />
  );

  if (progress.status === BackgroundJobStatus.Done) {
    const result = job.getResult();

    let action: ReactNode | null = null;
    if (result.action) {
      action = (
        <div className="c-action-container">
          <DefaultButton
            className="c-action"
            text={result.action.label}
            onClick={(event) => {
              result.action.run({
                initiatingElement: event.currentTarget as HTMLElement
              });
              onRemove();
            }}
          />
        </div>
      );
    }

    return (
      <BackgroundJobItemStyled className="background-job-item is-done">
        <div className="c-contents">
          <div className="c-header">
            <JobIcon progress={progress} result={result} />
            <div className="c-title">{result.title}</div>
            <div className="c-time">{lastUpdatedTimeFormatted}</div>
          </div>
          <div className="c-description">{result.description}</div>
          {progressItems}
        </div>
        {action}
      </BackgroundJobItemStyled>
    );
  }

  let action: ReactNode | null = null;
  if (progress.status === BackgroundJobStatus.Failed && job.recovery) {
    action = (
      <div className="c-action-container">
        <DefaultButton
          className="c-action"
          text={job.recovery.label}
          onClick={(event) => {
            job.recovery.action({
              initiatingElement: event.currentTarget as HTMLElement
            });
            onRemove();
          }}
        />
      </div>
    );
  }

  return (
    <BackgroundJobItemStyled className="background-job-item">
      <div className="c-contents">
        <div className="c-header">
          <JobIcon progress={progress} />
          <div className="c-title">{progress.title}</div>
          <div className="c-time">{lastUpdatedTimeFormatted}</div>
        </div>
        {progressItems}
      </div>
      {action}
    </BackgroundJobItemStyled>
  );
}

export function BackgroundJobsMenu() {
  const backgroundJobs = useContext(BackgroundJobs);
  const [open, setOpen] = useState(false);
  const buttonId = useId();

  useEffect(() => {
    const handler = (event: IBackgroundJobsEvent) => {
      if (event.type === BackgroundJobsEventType.AttemptedClose) setOpen(true);
    };

    backgroundJobs.addEventListener(handler);
    return () => backgroundJobs.removeEventListener(handler);
  }, [backgroundJobs]);

  return (
    <>
      <BackgroundJobsMenuButton
        id={buttonId}
        onClick={() => setOpen(true)}
        suppressPopovers={open}
      />
      <Callout
        hidden={!open}
        onDismiss={() => setOpen(false)}
        target={`#${buttonId}`}
        directionalHint={DirectionalHint.bottomCenter}
        beakWidth={0}
        gapSpace={0}
        styles={{
          calloutMain: {
            width: '400px',
            maxWidth: '100%'
          }
        }}
      >
        <BackgroundJobsMenuContents onDismiss={() => setOpen(false)} />
      </Callout>
    </>
  );
}
