import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import breakpoints from 'utils/breakpoints';
import { DefaultButton } from '@fluentui/react';

const AdministrationBarStyled = styled.div`
  @media (max-width: ${breakpoints.smallMax}px) {
    min-width: 100%;
    width: 100%;
  }
`;

function AdministrationBar({ onClick }) {
  const { t } = useTranslation();

  const theme = useTheme();

  return (
    <AdministrationBarStyled>
      <div
        style={{
          backgroundColor: `rgb(${theme.sidebar.border})`,
          height: '1px',
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '93%'
        }}
      />
      <DefaultButton
        iconProps={{ iconName: 'Settings' }}
        styles={{
          root: {
            border: 'none',
            borderRight: `1px solid rgb(${theme.sidebar.border})`,
            height: '45px',
            paddingLeft: '6px',
            width: '100%'
          },
          label: { marginLeft: '3px', float: 'left', fontWeight: 400 }
        }}
        onClick={onClick}
        text={t('sideBar.administration')}
      />
    </AdministrationBarStyled>
  );
}

AdministrationBar.propTypes = {
  onClick: PropTypes.func
};

AdministrationBar.defaultProps = {
  onClick: null
};

export default AdministrationBar;
