import {
  DynamicFieldSection,
  IDynamicFieldSectionOnChangeProps,
  IDynamicFieldSectionProps,
  SectionType
} from 'components/inputs/DynamicField';
import Masonry from 'components/surfaces/Masonry';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import {
  createRef,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import { IFieldLinkProps, IFieldLinkValueProps, IUserProps } from 'types';
import useResizeObserver from 'use-resize-observer';
import { getSectionDocuments, prepareFieldGroupForEvaluation } from 'utils/helpers';
import DynamicFieldFormStyled from './DynamicFieldForm.styles';

export type SubmitHandle = {
  onBeforeSubmit?: () => void;
};

export interface IDynamicFieldFormProps extends IDynamicFieldSectionProps {
  currentUser?: IUserProps;
  formRef?: React.RefObject<SubmitHandle>;
  sections: IDynamicFieldSectionStateProps[];
  evaluateFieldGroup: (fieldGroupLink: IFieldLinkProps) => Promise<IFieldLinkProps>;
  onSectionsUpdate: (sections: IDynamicFieldSectionStateProps[]) => void;
  onOpenWopi?: (fileId: string) => void;
}

export interface IDynamicFieldSectionStateProps {
  fields: IFieldLinkProps[];
  id: string;
  name: string;
  customCode?: string;
}

function DynamicFieldForm({
  currentUser,
  sections,
  onCombinedPickerSearch,
  getDefinition,
  formRef,
  getDefinitionExternalDataList,
  getStreamUrl,
  getDefinitionExternalData,
  fetchRequest,
  disabled,
  instanceId,
  tenantId,
  onSectionsUpdate,
  evaluateFieldGroup,
  getFileContents,
  onOpenWopi,
  requestEdit,
  fieldHistoryCtx,
  alwaysShowHistory
}: IDynamicFieldFormProps) {
  const [sectionRefs, setSectionRefs] = useState<React.RefObject<SubmitHandle>[]>([]);

  const [initialized, setInitialized] = useState(false);
  const [numberOfColumns, setNumberOfColumns] = useState(1);
  const focusInitialized = useRef(false);

  const { ref } = useResizeObserver<HTMLDivElement>({
    round: Math.floor,
    onResize: (size: { width: number | undefined }) => {
      const width = size.width || 1;
      const columns = Math.round(width / 530) || 1;

      setNumberOfColumns(columns);
    }
  });

  useLayoutEffect(() => {
    if (sections.length && initialized && !focusInitialized.current) {
      focusInitialized.current = true;

      let firstFieldLink = sections[0]?.fields?.[0];

      if (!firstFieldLink) return;

      // check if fieldLink is a fieldGroup
      if (firstFieldLink.fieldGroup && firstFieldLink.fieldGroup.fields?.[0]) {
        // eslint-disable-next-line prefer-destructuring
        firstFieldLink = firstFieldLink.fieldGroup.fields[0];
      }

      const fieldType = firstFieldLink.field?.fieldType;

      let id = firstFieldLink.field?.id;

      if (fieldType === 6) {
        id += '-input';
      }

      setTimeout(() => {
        const element = document.getElementById(`input-${id}`) as HTMLElement;

        if (!element) return;

        if (fieldType === 2) {
          element.click();
        } else {
          element.focus();
        }
      }, 50);
    }
  }, [sections, initialized]);

  const sectionsRef = useRef<IDynamicFieldSectionStateProps[]>(sections);

  useEffect(() => {
    if (!initialized && sections) {
      // create a ref for each section and add it to the state
      setSectionRefs(sections.map(() => createRef()));
      // sectionRefs is used to call a specific function before the form is submitted
      setInitialized(true);
    }
  }, [initialized, sections]);

  useImperativeHandle(
    formRef,
    () => ({
      onBeforeSubmit() {
        // call every section's onBeforeSubmit function
        return sectionRefs
          .map((sectionRef) => (sectionRef?.current ? sectionRef.current.onBeforeSubmit?.() : null))
          .filter((section) => section !== null);
      }
    }),
    [sectionRefs]
  );

  function findSectionIndex(
    sectionsToSearch: IDynamicFieldSectionStateProps[],
    sectionIdToFind: string
  ): number {
    return sectionsToSearch.findIndex((section) => section.id === sectionIdToFind);
  }

  function findFieldIndex(fieldsToSearch: IFieldLinkProps[], fieldIdToFind: string): number {
    return fieldsToSearch.findIndex((field) => field.id === fieldIdToFind);
  }

  function getFieldIndices({
    nestedSectionId,
    sectionId,
    fieldId
  }: {
    nestedSectionId?: string;
    sectionId: string;
    fieldId: string;
  }) {
    const sectionIndex = findSectionIndex(sections, sectionId);

    let fieldIndex = -1;
    let fieldGroupIndex = -1;

    if (nestedSectionId) {
      // find fieldgroupfield
      fieldGroupIndex = findFieldIndex(sections[sectionIndex].fields, nestedSectionId);

      const fieldsToSearch = sections[sectionIndex]?.fields[fieldGroupIndex]?.fieldGroup?.fields;

      if (fieldsToSearch) fieldIndex = findFieldIndex(fieldsToSearch, fieldId);
    } else {
      // find field
      fieldIndex = findFieldIndex(sections[sectionIndex].fields, fieldId);
    }

    return { sectionIndex, fieldGroupIndex, fieldIndex };
  }

  function updateFieldGroupLinkValue(updateFieldGroupLinkValueProps: {
    sectionsClone: IDynamicFieldSectionStateProps[];
    sectionIndex: number;
    fieldGroupIndex: number;
    fieldIndex: number;
    value?: IFieldLinkValueProps;
  }) {
    const { sectionsClone, sectionIndex, fieldGroupIndex, fieldIndex, value } =
      updateFieldGroupLinkValueProps;

    let newFieldGroupLink = sectionsClone[sectionIndex].fields[fieldGroupIndex];
    newFieldGroupLink = cloneDeep(newFieldGroupLink);

    // update value
    if (
      newFieldGroupLink?.fieldGroup &&
      newFieldGroupLink.fieldGroup?.fields &&
      newFieldGroupLink.fieldGroup.fields[fieldIndex]
    ) {
      newFieldGroupLink.fieldGroup.fields[fieldIndex].value = value;
    }

    return newFieldGroupLink;
  }

  const debounceOnDynamicFormChange = debounce(
    ({ nestedSectionId, sectionId, fieldId, value }: IDynamicFieldSectionOnChangeProps) => {
      const sectionsClone = [...sectionsRef.current];

      const { sectionIndex, fieldGroupIndex, fieldIndex } = getFieldIndices({
        nestedSectionId,
        sectionId,
        fieldId
      });

      if (sectionIndex > -1 && fieldIndex > -1 && fieldGroupIndex > -1) {
        // fieldgroup link to evaluate
        let newFieldGroupLink = updateFieldGroupLinkValue({
          sectionsClone,
          sectionIndex,
          fieldGroupIndex,
          fieldIndex,
          value
        });

        const documents = getSectionDocuments(newFieldGroupLink.fieldGroup?.fields);

        newFieldGroupLink = prepareFieldGroupForEvaluation(newFieldGroupLink);

        // evaluate
        evaluateFieldGroup(newFieldGroupLink).then((evaluatedFieldGroupLink) => {
          const evaluatedFieldGroupLinkClone = { ...evaluatedFieldGroupLink };

          documents.forEach(({ file, fieldLinkId }) => {
            let field: IFieldLinkProps | undefined;

            const fieldLinkIndex = evaluatedFieldGroupLinkClone?.fieldGroup?.fields?.findIndex(
              (fieldLink) => fieldLink.id === fieldLinkId
            );

            if (
              typeof fieldLinkIndex === 'number' &&
              fieldLinkIndex > -1 &&
              evaluatedFieldGroupLinkClone?.fieldGroup &&
              evaluatedFieldGroupLinkClone.fieldGroup.fields &&
              evaluatedFieldGroupLinkClone.fieldGroup?.fields[fieldLinkIndex]
            ) {
              field = evaluatedFieldGroupLinkClone?.fieldGroup?.fields[fieldLinkIndex];
            }

            if (field) {
              field.value = file;
            }
          });

          sectionsClone[sectionIndex].fields[fieldGroupIndex] = evaluatedFieldGroupLinkClone;

          onSectionsUpdate(sectionsClone);
        });
      } else if (sectionIndex > -1 && fieldIndex > -1) {
        // update normal field value
        sectionsClone[sectionIndex].fields[fieldIndex].value = value;

        onSectionsUpdate(sectionsClone);
      }
    },
    400
  );

  function updateSectionsRef({
    nestedSectionId,
    sectionId,
    fieldId,
    value
  }: IDynamicFieldSectionOnChangeProps) {
    const sectionsClone = [...sectionsRef.current];

    const { sectionIndex, fieldGroupIndex, fieldIndex } = getFieldIndices({
      nestedSectionId,
      sectionId,
      fieldId
    });

    if (sectionIndex > -1 && fieldIndex > -1 && fieldGroupIndex > -1) {
      // fieldgroup link to evaluate
      const newFieldGroupLink = updateFieldGroupLinkValue({
        sectionsClone,
        sectionIndex,
        fieldGroupIndex,
        fieldIndex,
        value
      });

      sectionsClone[sectionIndex].fields[fieldGroupIndex] = newFieldGroupLink;

      sectionsRef.current = sectionsClone;
    } else if (sectionIndex > -1 && fieldIndex > -1) {
      // update normal field value
      sectionsClone[sectionIndex].fields[fieldIndex].value = value;

      sectionsRef.current = sectionsClone;
    }
  }

  function onDynamicFormChange({
    nestedSectionId,
    sectionId,
    fieldId,
    value
  }: IDynamicFieldSectionOnChangeProps) {
    // always update sectionsRef first to prevent any race conditions between sections update and fieldgroup evaluation
    updateSectionsRef({ nestedSectionId, sectionId, fieldId, value });

    debounceOnDynamicFormChange({ nestedSectionId, sectionId, fieldId, value });
  }

  if (!sections || !sections.length || !initialized) {
    return null;
  }

  return (
    <DynamicFieldFormStyled ref={ref}>
      <Masonry columns={numberOfColumns} gap={numberOfColumns === 1 ? 5 : 25}>
        {sections.map((section, index) => {
          return (
            <DynamicFieldSection
              currentUser={currentUser}
              disabled={disabled}
              instanceId={instanceId}
              fetchRequest={fetchRequest}
              getStreamUrl={getStreamUrl}
              getDefinition={getDefinition}
              getDefinitionExternalData={getDefinitionExternalData}
              getDefinitionExternalDataList={getDefinitionExternalDataList}
              getFileContents={getFileContents}
              key={section.id}
              onChange={onDynamicFormChange}
              onCombinedPickerSearch={onCombinedPickerSearch}
              section={section}
              sectionRef={sectionRefs[index]}
              sectionType={SectionType.default}
              tenantId={tenantId}
              onOpenWopi={onOpenWopi}
              requestEdit={requestEdit}
              fieldHistoryCtx={fieldHistoryCtx}
              alwaysShowHistory={alwaysShowHistory}
            />
          );
        })}
      </Masonry>
    </DynamicFieldFormStyled>
  );
}

export default DynamicFieldForm;
