import { ITagPickerProps } from 'components/inputs/TagPicker';
import LoadingSpinner from 'components/progress/LoadingSpinner';
import Dialog from 'components/surfaces/Dialog';
import FileManagement, { IFileManagement } from 'components/surfaces/FileManagement';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IBlobFile, IFileProps, ITagProps } from 'types';
import { Icon, PrimaryButton, Separator, SpinnerSize } from '@fluentui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import AttachmentsContainerStyled from './Attachments.styles';
import DocumentCard, { IDocumentCardProps } from './DocumentCard';
import {
  areBackgroundJobsAvailable,
  BackgroundJobs,
  BackgroundJobStatus,
  IBackgroundJob,
  SinglePromiseJob
} from '../../../features/BackgroundJobs';
import { JobProgressItems, useJobProgress } from '../../../features/BackgroundJobs/JobProgress';

export interface IAttachmentsProps {
  disabled?: boolean;
  getFiles?: () => Promise<IFileProps[]>;
  getFileContents: ({ id }: { id: string }) => Promise<Response>;
  onDeleteFile: (file: IFileProps) => void;
  getUniqueAndDuplicateFiles: (
    selectedFiles: IFileProps[],
    files: IFileProps[]
  ) => {
    uniqueFiles: IFileProps[];
    duplicateFiles: IFileProps[];
  };
  additionalDocumentCardMenuItems?: IDocumentCardProps['additionalMenuItems'];
  uploadFile: (file: IBlobFile, onProgress: (progress: number) => void) => Promise<IFileProps>;
  updateFile: (file: IFileProps) => Promise<IFileProps> | void;
  grouped?: boolean;
  onTagsSearch: ITagPickerProps['onTagsSearch'];
  onTagClick: ITagPickerProps['onTagClick'];
  id?: string;
  onOpenWopi?: (fileId: string) => void;

  fileUploadJobs?: IBackgroundJob[];
  onAddFileUploadJob?: (job: IBackgroundJob) => void;
  onRemoveFileUploadJob?: (job: IBackgroundJob) => void;
}

function Attachments({
  additionalDocumentCardMenuItems,
  disabled = false,
  getFileContents,
  getFiles,
  getUniqueAndDuplicateFiles,
  onDeleteFile,
  onTagClick,
  onTagsSearch,
  grouped = false,
  updateFile,
  uploadFile,
  id,
  onOpenWopi,

  fileUploadJobs,
  onAddFileUploadJob,
  onRemoveFileUploadJob
}: IAttachmentsProps) {
  const { t } = useTranslation();

  const dropzoneRef = useRef<IFileManagement>(null);

  const [files, setFiles] = useState<IFileProps[] | null>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [openGroups, setOpenGroups] = useState<string[]>(id ? [id] : []);
  const [initialized, setInitialized] = useState(false);
  const [duplicateFiles, setDuplicateFiles] = useState<IFileProps[]>([]);
  const fileNames = duplicateFiles.map((file) => ` ${file.name}`);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);

      if (!getFiles) {
        setIsLoading(false);
      }

      getFiles?.().then((filesResult) => {
        setFiles(filesResult);
        setIsLoading(false);
      });
    }
  }, [getFiles, initialized]);

  useEffect(() => {
    if (initialized) {
      // scroll to the discussion if id is provided
      setTimeout(() => {
        if (id) {
          const group = document.getElementById(`group-${id}`);

          if (group) {
            group.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }, 400);
    }
  }, [id, initialized]);

  function convertBlobFileToFile(blobFile: IBlobFile): IFileProps {
    return {
      blobFile,
      name: blobFile.name
    };
  }

  const currentLocation = useLocation();
  const navigate = useNavigate();

  function onUploadFiles(selectedFiles: IBlobFile[], ignoreDuplicateFiles: boolean): void {
    // setIsLoading(true);

    const convertedSelectedFiles = selectedFiles.map(convertBlobFileToFile);

    let uniqueFiles: IFileProps[] = [];
    let duplicateFiles: IFileProps[] = [];

    if (!ignoreDuplicateFiles) {
      ({ uniqueFiles, duplicateFiles } = getUniqueAndDuplicateFiles(
        convertedSelectedFiles,
        files || []
      ));
    }

    // set duplicateFiles to display the dialog if there are any
    setDuplicateFiles(duplicateFiles);

    const filesToUpload = ignoreDuplicateFiles ? convertedSelectedFiles : uniqueFiles;

    if (filesToUpload.length) {
      const filePromises = filesToUpload.map((file) => {
        const fileName = file.name ?? file.text;

        const uploadJob = new SinglePromiseJob({
          promise: uploadFile(file.blobFile as IBlobFile, (progress) => {
            uploadJob.progress = progress;
          }),
          title: t('attachments.fileUploadJob.title', { name: fileName }),
          errorTitle: t('attachments.fileUploadJob.errorTitle', { name: fileName }),
          resultTitle: t('attachments.fileUploadJob.resultTitle', { name: fileName }),
          resultAction() {
            return {
              label: t('attachments.fileUploadJob.openResult'),
              run: () => {
                // re-open the current URL, which is the URL to this attachments panel. probably
                navigate(currentLocation);
              }
            };
          }
        });

        onAddFileUploadJob?.(uploadJob);

        return uploadJob.promise;
      });

      Promise.all(filePromises).then((uploadedFiles) => {
        setFiles((prevSateFiles) => {
          if (Array.isArray(prevSateFiles) && prevSateFiles.length) {
            if (!ignoreDuplicateFiles) {
              // add the uploaded files to the state
              return [...prevSateFiles, ...uploadedFiles];
            }

            // replace files with the uploaded files
            return prevSateFiles.map((prevStateFile) => {
              const newFile = uploadedFiles.find(({ name }) => name === prevStateFile.name);

              return newFile || prevStateFile;
            });
          }

          return uploadedFiles;
        });

        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }

  function handleDeleteFile(fileToDelete: IFileProps): void {
    // remove file from state file array
    setFiles((prevStateFiles) => {
      if (files && Array.isArray(prevStateFiles)) {
        const prevStateFilesClone = [...prevStateFiles];

        const fileIndexToDelete = prevStateFilesClone.findIndex((file) => {
          if (fileToDelete.id) {
            return file.id === fileToDelete.id;
          }

          // new files have distinct names and no id
          return file.name === fileToDelete.name;
        });

        prevStateFilesClone.splice(fileIndexToDelete, 1);

        return prevStateFilesClone;
      }

      return prevStateFiles;
    });

    onDeleteFile(fileToDelete);
  }

  function onFileSelection(selectedFiles: IBlobFile[]) {
    if (selectedFiles && selectedFiles.length) {
      onUploadFiles(selectedFiles, false);
    }
  }

  function updateStateFile(fileToUpdate: IFileProps) {
    setFiles((prevStateFiles) => {
      if (prevStateFiles && Array.isArray(prevStateFiles)) {
        const prevStateFilesClone = [...prevStateFiles];

        const fileIndexToUpdate = prevStateFilesClone.findIndex((stateFile) => {
          if (stateFile.id && fileToUpdate.id) return stateFile.id === fileToUpdate.id;

          return stateFile.name === fileToUpdate.name;
        });

        prevStateFilesClone[fileIndexToUpdate] = fileToUpdate;

        return prevStateFilesClone;
      }

      return prevStateFiles;
    });
  }

  function onTagChange(file: IFileProps, tags: ITagProps[]) {
    const fileToUpdate = { ...file, tags };

    updateStateFile(fileToUpdate);
    updateFile(fileToUpdate);
  }

  function onSaveComment(file: IFileProps, description?: string) {
    const fileToUpdate = { ...file, description };

    updateStateFile(fileToUpdate);
    updateFile(fileToUpdate);
  }

  function onCancelDuplicateFilesDialog() {
    setDuplicateFiles([]);
  }

  function onConfirmDuplicateFilesDialog() {
    onUploadFiles(
      duplicateFiles.map((f) => f.blobFile as IBlobFile),
      true
    );
    setDuplicateFiles([]);
  }

  function renderFileCards(givenFiles: IFileProps[]): JSX.Element[] {
    return givenFiles.map((file) => (
      <DocumentCard
        disabled={disabled}
        onTagsSearch={onTagsSearch}
        additionalMenuItems={additionalDocumentCardMenuItems}
        onTagClick={onTagClick}
        key={file.id || file.name}
        onSaveComment={onSaveComment}
        onDownload={getFileContents}
        onFileUpdate={setFiles}
        onDeleteFile={() => handleDeleteFile(file)}
        documentFile={file}
        onTagChange={onTagChange}
        onOpenWopi={onOpenWopi}
      />
    ));
  }

  function onSeparatorClick(id: string): void {
    setOpenGroups((prevState) => {
      if (prevState.includes(id)) {
        return prevState.filter((groupId) => groupId !== id);
      }

      return [...prevState, id];
    });
  }

  function groupFiles(filesToGroup: IFileProps[], groupByProp: 'taskId' | 'originalTaskId') {
    return filesToGroup.reduce((acc, file) => {
      let id: string;

      const similarFile = filesToGroup.find(
        (otherFile) =>
          otherFile.id !== file.id &&
          otherFile.stepId === file.stepId &&
          file.stepId !== '00000000-0000-0000-0000-000000000000' &&
          otherFile.taskName === file.taskName
      );

      if (similarFile) {
        id = `${file.stepId}`;
      } else {
        id = file[groupByProp] || 'temp-id';
      }

      if (!id) return acc;

      if (!acc[id]) acc[id] = [];

      acc[id].push(file);

      return acc;
    }, {} as { [key: string]: IFileProps[] });
  }

  function getGroupName(file: IFileProps): string {
    const { originalTaskName, stepName, taskName, routeInstanceName } = file;

    return originalTaskName || stepName || taskName || routeInstanceName || '';
  }

  function isGroupOpen(groupId: string, groupedFiles: { [key: string]: IFileProps[] }): boolean {
    if (groupId === 'temp-id') return true;

    return !!openGroups.find((openGroupId: string) => {
      if (openGroupId === groupId) {
        return true;
      }

      // check for stepId
      const groupFiles = groupedFiles[groupId];

      if (groupFiles) {
        const foundGroupFile = groupFiles.find((file) => file.originalTaskId === openGroupId);

        if (foundGroupFile) return true;
      }

      return false;
    });
  }

  function renderContent(): JSX.Element[] | null {
    if (!files || files.length === 0) return null;

    if (!grouped) {
      // render files without grouping
      return renderFileCards(files);
    }

    // render grouped files
    const hasOriginalTaskId = files.some(({ originalTaskId }) => !!originalTaskId);
    const groupByProp = hasOriginalTaskId ? 'originalTaskId' : 'taskId';

    // group files by taskId or by originalTaskId
    const groupedFiles: { [key: string]: IFileProps[] } = groupFiles(files, groupByProp);

    return Object.keys(groupedFiles).map((groupId) => {
      const stepFiles = groupedFiles[groupId];
      const open = isGroupOpen(groupId, groupedFiles);

      return (
        <div id={`group-${groupId}`} key={groupId}>
          <div aria-hidden="true" onClick={() => onSeparatorClick(groupId)}>
            <Separator
              styles={{
                content: { backgroundColor: '#f5f5f5' },
                root: {
                  ':before': { backgroundColor: '#C3C3C3' },
                  ':hover': { cursor: 'pointer' }
                }
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Icon
                  styles={{
                    root: {
                      marginRight: 5,
                      fontSize: 12,
                      transform: open ? 'rotate(0deg)' : 'rotate(-90deg)',
                      transition: 'transform 0.2s ease-in-out'
                    }
                  }}
                  iconName="ChevronDown"
                />
                {getGroupName(stepFiles[0])}
              </div>
            </Separator>
          </div>
          {open && renderFileCards(stepFiles)}
        </div>
      );
    });
  }

  if (isLoading) {
    return (
      <AttachmentsContainerStyled>
        <div className="center-child">
          <LoadingSpinner
            styles={{ container: { margin: 10 } }}
            label="Loading Files"
            size={SpinnerSize.large}
            labelPosition="bottom"
          />
        </div>
      </AttachmentsContainerStyled>
    );
  }

  if ((!files || !files.length) && !fileUploadJobs?.length) {
    return (
      <AttachmentsContainerStyled>
        <FileManagement
          id={id ? `file-input-${id}` : undefined}
          disabled={disabled}
          ref={dropzoneRef}
          clickable={false}
          multiple
          onChange={onFileSelection}
        >
          <div className="center-child">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
                marginBottom: '150px'
              }}
            >
              <Icon
                styles={{
                  root: {
                    color: '#737373',
                    fontSize: '84px',
                    fontWeight: 400,
                    lineHeight: '100px',
                    margin: '15px',
                    marginBottom: '10px'
                  }
                }}
                iconName="Attach"
              />
              <span style={{ color: '#737373' }}>
                {t('attachmentsPivotItem.placeholder.noAttachments')}
              </span>
              <PrimaryButton
                styles={{
                  root: {
                    maxWidth: '200px',
                    marginRight: 'auto',
                    marginLeft: 'auto',
                    marginTop: '15px',
                    borderRadius: 4
                  }
                }}
                iconProps={{ iconName: 'Add' }}
                text={t('attachmentsPivotItem.placeholder.add')}
                onClick={() => dropzoneRef.current?.openFileChooser()}
                allowDisabledFocus
                disabled={disabled}
              />
            </div>
          </div>
        </FileManagement>
      </AttachmentsContainerStyled>
    );
  }
  return (
    <AttachmentsContainerStyled data-is-scrollable="true">
      <FileManagement
        disabled={disabled}
        clickable={false}
        multiple
        id={id ? `file-input-${id}` : 'file-input'}
        onChange={onFileSelection}
      >
        {fileUploadJobs?.map((job) => (
          <FileUploadJob
            job={job}
            key={getObjKey(job)}
            onRemove={() => {
              onRemoveFileUploadJob?.(job);

              // revalidate in case the user switched tabs in the interim and the promise doesn't affect our state here anymore
              getFiles?.().then((result) => setFiles(result));
            }}
          />
        ))}

        {renderContent() || <div />}
      </FileManagement>
      <Dialog
        title={t('dialog.attachments.duplicate.title')}
        subText={
          fileNames.length > 1
            ? t('dialog.attachments.duplicate.1.subText', { fileName: fileNames })
            : t('dialog.attachments.duplicate.multiple.subText', { fileName: fileNames })
        }
        defaultButtonProps={{
          onClick: onCancelDuplicateFilesDialog,
          text: t('dialog.attachments.duplicate.button.default')
        }}
        primaryButtonProps={{
          onClick: onConfirmDuplicateFilesDialog,
          text: t('dialog.attachments.duplicate.button.primary')
        }}
        onDismiss={onCancelDuplicateFilesDialog}
        hidden={duplicateFiles.length === 0}
      />
    </AttachmentsContainerStyled>
  );
}

export default Attachments;

const OBJECT_KEYS = new WeakMap<object, string>();
function getObjKey(obj: object) {
  if (!OBJECT_KEYS.has(obj)) OBJECT_KEYS.set(obj, Math.random().toString());
  return OBJECT_KEYS.get(obj);
}

function FileUploadJob({ job, onRemove }: { job: IBackgroundJob; onRemove: () => void }) {
  const progress = useJobProgress(job);

  useEffect(() => {
    if (progress.status === BackgroundJobStatus.Done) {
      onRemove();
    }
  }, [progress.status, onRemove]);

  if (progress.status === BackgroundJobStatus.Done) {
    return null;
  }

  return (
    <div className="c-file-upload-job">
      <div className="c-title">{progress.title}</div>
      <JobProgressItems job={job} />
    </div>
  );
}

export function useFileUploadJobs(isPanelOpen: boolean) {
  const [fileUploadJobs, setFileUploadJobs] = useState<IBackgroundJob[]>([]);

  const jobs = useContext(BackgroundJobs);
  const currentFileUploadJobs = useRef(fileUploadJobs);
  currentFileUploadJobs.current = fileUploadJobs;
  useEffect(() => {
    return () => {
      // a bit confusing: this is checking the previous state.
      // this means that this will return if we changed from !isOpen -> isOpen
      if (!isPanelOpen) return;

      // when closing the panel, add all file uploads to background jobs
      if (areBackgroundJobsAvailable()) {
        for (const job of currentFileUploadJobs.current) {
          jobs.add({ job });
        }
      }
    };
  }, [jobs, isPanelOpen]);

  const addFileUploadJob = useCallback((job: IBackgroundJob) => {
    setFileUploadJobs((jobs) => [...jobs, job]);
  }, []);
  const removeFileUploadJob = useCallback((job: IBackgroundJob) => {
    setFileUploadJobs((jobs) => jobs.filter((j) => j !== job));
  }, []);

  return { fileUploadJobs, setFileUploadJobs, addFileUploadJob, removeFileUploadJob };
}
