import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getPivotStyles } from 'utils/helpers';
import {
  CommandBar,
  ICommandBarItemProps,
  IconButton,
  Pivot,
  PivotItem,
  TooltipHost
} from '@fluentui/react';
import {
  SpinnerCommandBarButton,
  useSpinnerCmdBarButtonDebouncedDisabled
} from '../Panel/SpinnerCommandBarButton';

// styles
const NewInstanceHeaderStyled = styled.div`
  background-color: ${(props) => props.theme.themePrimary};
  color: white;

  .new-instance-title {
    font-size: 28px;
    padding-left: 16px;
  }

  .new-instance-subtitle {
    font-size: 14px;
    padding-left: 16px;
  }
`;

const StickyHeaderStyled = styled.div`
  position: sticky;
  top: 0;
  z-index: 400;
`;

export interface IStartProcessHeaderProps {
  isFollowing: boolean;
  onSubmit: (initiatingElement?: HTMLElement | string, startAndNew?: boolean) => void;
  setTab: (tab?: string) => void;
  startAndNewEnabled: boolean;
  submitButtonDisabled: boolean;
  /**
   * Shows a loading indicator on the submit button (with a delay).
   * Used to indicate that form fields are being evaluated.
   */
  submitButtonEvaluating?: boolean;
  tab: string;
  title: string;
  isTest?: boolean;
  toggleIsFollowing: () => void;
  /**
   * If passed, will show a close button here.
   * Intended to replace the built-in close button in the panel in certain situations.
   */
  onClose?: () => void;
  disabled?: boolean;
}

function StartProcessHeader({
  isFollowing,
  onSubmit,
  setTab,
  startAndNewEnabled,
  submitButtonDisabled,
  submitButtonEvaluating,
  tab,
  title,
  toggleIsFollowing,
  onClose,
  isTest,
  disabled
}: IStartProcessHeaderProps) {
  const { t } = useTranslation();

  const [debouncedSubmitButtonEvaluating, forceUpdateSubmitButtonEval] =
    useSpinnerCmdBarButtonDebouncedDisabled(submitButtonEvaluating);

  const cmdBarItems: ICommandBarItemProps[] = [
    {
      id: 'start-panel-start-button',
      key: 'submitInstance',
      className: 'command-bar-item',
      text: t('attachmentsPivotItem.commandbar.start'),
      iconProps: {
        iconName: 'Play'
      },
      disabled: disabled || submitButtonDisabled || debouncedSubmitButtonEvaluating,
      onClick: () => {
        // 'disabled' is debounced for visual reasons, but we do actually want to disable it
        if (submitButtonEvaluating) {
          forceUpdateSubmitButtonEval();
          return;
        }
        onSubmit('#start-panel-start-button');
      },
      data: { isLoading: debouncedSubmitButtonEvaluating },
      commandBarButtonAs: SpinnerCommandBarButton,
      title: submitButtonEvaluating
        ? t('attachmentsPivotItem.commandbar.startInhibitedDueToEvalRules')
        : undefined
    }
  ];

  const cmdBarFarItems = [
    {
      key: 'isFollowing',
      onRender: () => (
        <TooltipHost
          content={
            <div>
              <div>
                {t('startProcessInstancePanel.isFollowingPrimary', {
                  context: isFollowing ? 'on' : 'off'
                })}
              </div>
              <div>
                {t('startProcessInstancePanel.isFollowingSubtext', {
                  context: isFollowing ? 'on' : 'off'
                })}
              </div>
            </div>
          }
          id="tooltip"
          calloutProps={{ gapSpace: 0 }}
          styles={{ root: { display: 'inline-block' } }}
          setAriaDescribedBy={false}
        >
          <IconButton
            disabled={disabled}
            styles={{
              root: {
                height: '100%',
                width: '100%'
              }
            }}
            iconProps={{
              iconName: isFollowing ? 'RingerSolid' : 'Ringer'
            }}
            onClick={() => toggleIsFollowing()}
          />
        </TooltipHost>
      )
    }
  ];

  if (startAndNewEnabled) {
    cmdBarItems.push({
      id: 'start-panel-start-and-new-button',
      key: 'startAndNew',
      className: 'command-bar-item',
      text: t('attachmentsPivotItem.commandbar.startAndNew'),
      iconProps: {
        iconName: 'PlaybackRate1x'
      },
      disabled: disabled || submitButtonDisabled,
      onClick: () => onSubmit('#start-panel-start-and-new-button', true)
    });
  }

  if (tab === 'attachments') {
    cmdBarItems.push({
      key: 'newFile',
      className: 'command-bar-item',
      text: t('attachmentsPivotItem.commandbar.newFile'),
      iconProps: {
        iconName: 'add'
      },
      disabled,
      onClick: () => {
        const fileInput = document.getElementById('file-input-start-form-attachments');

        if (fileInput) {
          fileInput.click();
        }
      }
    });
  }

  const customCloseButton = onClose ? (
    <IconButton
      styles={{
        root: {
          margin: '10px 5px 5px auto',
          display: 'block'
        },
        rootHovered: { backgroundColor: 'rgba(255, 255, 255, 0.1)' },
        rootPressed: { backgroundColor: 'rgba(255, 255, 255, 0.3)' }
      }}
      iconProps={{
        styles: { root: { fontSize: '18px', color: 'white' } },
        iconName: 'Cancel'
      }}
      onClick={onClose}
    />
  ) : null;

  return (
    <>
      <NewInstanceHeaderStyled>
        {customCloseButton}
        <div className="new-instance-title">{title}</div>
        <div className="new-instance-subtitle">
          {isTest
            ? t('newInstance.panel.subtitle.newRouteTest')
            : t('newInstance.panel.subtitle.newRoute')}
        </div>
      </NewInstanceHeaderStyled>
      <StickyHeaderStyled>
        <Pivot
          linkSize="normal"
          overflowBehavior="menu"
          selectedKey={`${tab}`}
          onLinkClick={(item) => setTab(item?.props.itemKey)}
          styles={getPivotStyles}
        >
          <PivotItem
            className="pivot-item-height"
            headerText={t('newInstance.panel.pivotItem.label.startform')}
            itemKey="start"
            key="start"
          />
          <PivotItem
            headerButtonProps={{ 'data-target': 'files-pivot' }}
            headerText={t('newInstance.panel.pivotItem.label.attachments')}
            itemKey="attachments"
            key="attachments"
          />
        </Pivot>
        <CommandBar
          farItems={cmdBarFarItems}
          items={cmdBarItems}
          styles={{
            root: {
              position: 'relative',
              zIndex: 300,
              boxShadow: '0 1.6px 3.6px 0 rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%);'
            }
          }}
        />
      </StickyHeaderStyled>
    </>
  );
}

export default StartProcessHeader;
