import styled, { useTheme } from 'styled-components';
import { ITaskProps, IUserProps } from 'types';
import { Facepile as FabricFacePile, Icon, PersonaSize } from '@fluentui/react';

/* eslint-disable react/jsx-props-no-spreading */

const FacepileStyled = styled(FabricFacePile)`
  .ms-Facepile-itemButton {
    cursor: default;
  }
`;

interface FacePileProps {
  task: ITaskProps;
}

function FacePile({ task }: FacePileProps) {
  const theme = useTheme();

  function getTeamImageInitials(title?: string) {
    if (!title) return '';
    // @TODO: remove duplicate function
    const splitTitle = title.split(' ');
    let imageInitials = splitTitle[0][0];
    if (splitTitle[1]) {
      imageInitials += splitTitle[1][0];
    }
    return imageInitials;
  }

  function renderChevronItem(type: number) {
    if ([3, 7, 9, 11, 13].indexOf(type) === -1) {
      return (
        <Icon
          styles={{
            root: {
              fontSize: 13,
              fontWeight: 100,
              paddingTop: '15px',
              marginLeft: 5,
              marginRight: 5,
              color: `rgb(${theme.facePile.chevronColor})`
            }
          }}
          iconName="ChevronRight"
        />
      );
    }

    return null;
  }

  function renderFacepile(user: IUserProps, task: ITaskProps, renderChevronIcon?: boolean) {
    let facepile = null;
    if (user) {
      facepile = (
        <>
          <FacepileStyled
            aria-describedby={user.userId}
            personaSize={PersonaSize.size24}
            personas={[
              { imageUrl: user?.pictureUrl || undefined, personaName: user?.name || undefined }
            ]}
          />
          {renderChevronIcon && task.typeId ? renderChevronItem(task.typeId) : null}
        </>
      );
    }
    return facepile;
  }

  function renderTeamFacepile(task: ITaskProps) {
    if (task && task.assignedToTeam) {
      return (
        <FacepileStyled
          personas={[
            {
              personaName: task.assignedToTeam.displayTitle,
              imageInitials: getTeamImageInitials(task.assignedToTeam.title)
            }
          ]}
          personaSize={PersonaSize.size24}
          aria-describedby={task.assignedToTeam.id}
        />
      );
    }
    return null;
  }

  function renderTeamPoolFacePile() {
    return (
      <FacepileStyled
        personas={[{ personaName: 'Teampool', imageInitials: 'TT' }]}
        personaSize={PersonaSize.size24}
      />
    );
  }

  function renderFacePileAssignedTo(task: ITaskProps) {
    switch (task.typeId) {
      case 1:
      case 4:
      case 7:
      case 8:
      case 10:
      case 14:
      case 20:
      case 50:
      case 51:
      case 60:
      case 61:
      case 100:
        if (task.assignedTo) {
          return renderFacepile(task.assignedTo, task);
        }

        if (task.assignedToTeam) {
          return renderTeamFacepile(task);
        }

        if (task.typeId === 50 || task.typeId === 51) {
          return renderTeamPoolFacePile();
        }

        return null;
      case 2:
        if (task.requestStatusId === 2 && task.assignedTo) {
          return renderFacepile(task.assignedTo, task);
        }

        return renderTeamFacepile(task);
      case 12:
        if (task.assignedTo) {
          return renderFacepile(task.assignedTo, task);
        }
        return renderTeamFacepile(task);

      default:
        return renderTeamFacepile(task);
    }
  }

  return (
    <div style={{ display: 'flex' }}>
      {task.creator && renderFacepile(task.creator, task, true)}
      {renderFacePileAssignedTo(task)}
    </div>
  );
}

export default FacePile;
