import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { cloneObject } from 'utils/helpers';
import { DefaultButton, Label, TextField } from '@fluentui/react';
import DeleteFieldInRulesDialog from './DeleteFieldInRulesDialog';
import FieldLink from './FieldLink';

const FieldLinkList = styled.div`
  margin: 10px 0;

  .add-field-button:disabled {
    .add-field-button-icon {
      color: #cccccc;
    }

    .ms-Button-label {
      color: #cccccc;
    }
  }
`;

function FieldLinksList({ fieldLinks, updateFields, disabled, selectedLanguage }) {
  const { t } = useTranslation();

  const [newFieldLinks, setNewFieldLinks] = useState(null);

  const [enabledAddButton, setEnabledAddButton] = useState(false);

  const [selectedFields, setSelectedFields] = useState([]);

  const [focusFieldLinkIndex, setFocusFieldLinkIndex] = useState(-1);

  const [deleteDialog, setDeleteDialog] = useState({ show: false });
  const [deletedFieldFromRule, setDeletedFieldFromRule] = useState(false);

  useEffect(() => {
    if (fieldLinks) {
      setNewFieldLinks([...fieldLinks]);
      setDeletedFieldFromRule(false);
    }
  }, [fieldLinks]);

  useEffect(() => {
    const translateFields = (fields, language) => {
      const fieldLinks = [];

      fields.map((link) => {
        let fieldName = link.field.name;

        const translatedName = link.field.allNames?.find((x) => x.language === language.key)?.text;

        if (translatedName) {
          fieldName = translatedName;
        }

        const newFieldLink = {
          ...link,
          field: { ...link.field, name: fieldName },
          lang: language.key
        };

        fieldLinks.push({ ...newFieldLink });

        return null;
      });

      return fieldLinks;
    };

    if (selectedLanguage && fieldLinks?.length) {
      setNewFieldLinks(translateFields(fieldLinks, selectedLanguage));
    }
  }, [selectedLanguage, fieldLinks]);

  useEffect(() => {
    const getSelectedFields = (links) => {
      const fields = [];

      links.map((link) => {
        if (link.field?.id) {
          fields.push(link.field);
        }

        return null;
      });

      return fields;
    };

    if (newFieldLinks?.length) {
      setSelectedFields(getSelectedFields(newFieldLinks));

      const emptyField = newFieldLinks.find((link) => !link?.field?.id);
      setEnabledAddButton(!emptyField);
    }
  }, [newFieldLinks]);

  function checkRemoveFieldInRules(fieldLink) {
    let used = false;
    const clonedFieldLinks = cloneObject(newFieldLinks);

    const fieldsWithRules = [];

    for (let i = 0; i < clonedFieldLinks.length; i += 1) {
      if (clonedFieldLinks[i].ruleGroups?.length) {
        for (let j = 0; j < clonedFieldLinks[i].ruleGroups.length; j += 1) {
          if (clonedFieldLinks[i].ruleGroups[j].rules?.length) {
            for (let k = 0; k < clonedFieldLinks[i].ruleGroups[j].rules.length; k += 1) {
              if (
                fieldLink?.field?.id &&
                fieldLink.field.id === clonedFieldLinks[i].ruleGroups[j].rules[k].fieldId
              ) {
                used = true;
                const exist = fieldsWithRules.find(
                  (fieldName) => fieldName === clonedFieldLinks[i].field?.name
                );

                if (!exist) {
                  fieldsWithRules.push(clonedFieldLinks[i].field?.name);
                }

                clonedFieldLinks[i].ruleGroups[j].rules[k].fieldId = null;
              }
            }
          }
        }
      }
    }

    if (used) {
      return { handledFieldLinks: clonedFieldLinks, fieldsWithRules };
    }

    return null;
  }

  function onAddField() {
    setNewFieldLinks((prevState) => [...prevState, { field: {} }]);
    setEnabledAddButton(false);
  }

  function onConfirmDelete(fieldLink, index, handledFieldLinks) {
    const fieldLinks = handledFieldLinks || newFieldLinks;

    if (!fieldLinks[index]?.field?.id && !enabledAddButton) {
      setEnabledAddButton(true);
    }

    fieldLinks.splice(index, 1);

    setDeleteDialog({ show: false });

    updateFields(fieldLinks);
  }

  function onDeleteField(fieldLink, index) {
    const oldFieldLink = newFieldLinks?.[index];
    if (oldFieldLink) {
      let handledFieldLinks = null;
      let fieldsWithRules = null;

      const checkResults = checkRemoveFieldInRules(oldFieldLink);

      if (checkResults) {
        handledFieldLinks = checkResults.handledFieldLinks;
        fieldsWithRules = checkResults.fieldsWithRules;
      }

      if (handledFieldLinks && fieldsWithRules) {
        setDeleteDialog({
          show: true,
          onConfirm: () => {
            onConfirmDelete(fieldLink, index, handledFieldLinks);
            setDeletedFieldFromRule(true);
          },
          onCancel: () => setDeleteDialog({ show: false }),
          fieldsWithRules
        });
      } else {
        onConfirmDelete(fieldLink, index);
      }
    }
  }

  function onConfirmUpdate(fieldLink, index, changed, handledFieldLinks) {
    let fields = [];

    let fieldAdded = false;

    const fieldLinks = handledFieldLinks || newFieldLinks;

    if (fieldLinks?.length && index >= 0) {
      fields = [...fieldLinks];

      fieldAdded = true;
      fields[index] = fieldLink;
    }

    if (!fieldAdded && fieldLink) {
      fields.push(fieldLink);
    }

    if (!fieldLink?.field?.id) {
      setFocusFieldLinkIndex(index);
    }

    setDeleteDialog({ show: false });

    updateFields(fields, changed);
  }

  function onUpdateFieldLinks(fieldLink, index, changed) {
    let runUpdate = false;

    if (fieldLink?.field?.id) {
      runUpdate = true;
    } else {
      const oldFieldLink = newFieldLinks?.[index];
      if (oldFieldLink) {
        let handledFieldLinks = null;
        let fieldsWithRules = null;

        const checkResults = checkRemoveFieldInRules(oldFieldLink);

        if (checkResults) {
          handledFieldLinks = checkResults.handledFieldLinks;
          fieldsWithRules = checkResults.fieldsWithRules;
        }

        if (handledFieldLinks && fieldsWithRules) {
          setDeleteDialog({
            show: true,
            onConfirm: () => {
              onConfirmUpdate(fieldLink, index, changed, handledFieldLinks);
              setDeletedFieldFromRule(true);
            },
            onCancel: () => setDeleteDialog({ show: false }),
            fieldsWithRules
          });
        } else {
          runUpdate = true;
        }
      }
    }

    if (runUpdate) {
      onConfirmUpdate(fieldLink, index, changed);
    }
  }

  function onUpdateSortOrder({ from, to }) {
    const tempFieldLinks = [...newFieldLinks];
    tempFieldLinks.splice(to, 0, tempFieldLinks.splice(from, 1)[0]);

    updateFields(tempFieldLinks);
  }

  function checkIfRequiredFieldLink(fieldLink) {
    if (newFieldLinks?.length < 3) {
      return true;
    }

    if (newFieldLinks?.length === 3) {
      const emptyField = newFieldLinks.find((link) => !link?.field?.id);

      if (emptyField) {
        if (!fieldLink?.field?.id) {
          return false;
        }

        return true;
      }
    }

    return false;
  }

  function checkFocus(fieldLink, index) {
    if (fieldLinks?.find((link) => !!link?.field?.id)) {
      if (
        (index === (newFieldLinks?.length || 0) - 1 && !fieldLink.field?.id) ||
        index === focusFieldLinkIndex
      ) {
        return true;
      }
    }

    return false;
  }

  function getGroupFields(fieldLink) {
    if (fieldLinks?.length) {
      const fields = [];

      fieldLinks.map((link) => {
        if (
          link.field &&
          link.field.fieldType !== 12 &&
          link.field.fieldType !== 13 &&
          link.field.id !== fieldLink?.field?.id
        ) {
          fields.push(link.field);
        }

        return null;
      });

      return fields;
    }

    return null;
  }

  function getFieldLinks() {
    if (newFieldLinks?.length) {
      let counter = 0;

      const links = newFieldLinks.map((fieldLink, index) => {
        const link = selectedLanguage ? (
          <div
            key={
              `${counter + 1}-${fieldLink.field?.id}-translation` ||
              `${counter + 1}-field-translation`
            }
            style={{ width: '100%', marginBottom: '5px' }}
          >
            <TextField disabled={disabled} defaultValue={fieldLink.field?.name} />
          </div>
        ) : (
          <FieldLink
            key={`${counter + 1}-${fieldLink.field?.id}-${
              fieldLink.visibility
            }-${deletedFieldFromRule}`}
            fieldLink={fieldLink}
            selectedFields={selectedFields}
            onDeleteField={(value) => onDeleteField(value, index)}
            onUpdateFieldLink={(value, changed) => onUpdateFieldLinks(value, index, changed)}
            required={checkIfRequiredFieldLink(fieldLink)}
            autoFocused={checkFocus(fieldLink, index)}
            onBlur={() => setFocusFieldLinkIndex(-1)}
            disabled={disabled}
            fieldIndex={index}
            fieldLinksLength={newFieldLinks.length}
            onChangeFieldLinkOrder={onUpdateSortOrder}
            groupFields={getGroupFields(fieldLink)}
          />
        );

        counter += 1;

        return link;
      });

      return links;
    }

    return null;
  }

  return (
    <FieldLinkList
      key={
        selectedLanguage && newFieldLinks?.length && newFieldLinks[0].lang === selectedLanguage.key
          ? `translated-fields-${selectedLanguage.key}`
          : 'fields'
      }
    >
      <Label>{t('viewPanelBody.label.fields')}</Label>
      {getFieldLinks()}
      {!disabled ? (
        <DefaultButton
          disabled={!enabledAddButton}
          className="add-field-button"
          style={{ border: 'none' }}
          iconProps={{ iconName: 'Add', className: 'add-field-button-icon' }}
          text={t('viewPanelBody.button.addField')}
          onClick={() => onAddField()}
        />
      ) : null}
      {deleteDialog?.show ? (
        <DeleteFieldInRulesDialog
          hidden={!deleteDialog?.show}
          defaultButtonFunc={deleteDialog?.onCancel}
          primaryButtonFunc={deleteDialog?.onConfirm}
          onDismiss={deleteDialog?.onCancel}
          fieldsWithRules={deleteDialog.fieldsWithRules}
        />
      ) : null}
    </FieldLinkList>
  );
}

FieldLinksList.propTypes = {
  fieldLinks: PropTypes.arrayOf(PropTypes.object),
  updateFields: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  selectedLanguage: PropTypes.object
};

FieldLinksList.defaultProps = {
  fieldLinks: null,
  disabled: false,
  selectedLanguage: null
};

export default FieldLinksList;
