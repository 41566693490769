import { LoadingSpinner } from 'components';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectStatus, RouteInstanceStatus, TaskStatus } from 'types';
import { IButtonProps, Icon, IconButton, SpinnerSize, concatStyleSets } from '@fluentui/react';
import { useTheme } from 'styled-components';

interface StatusIconProps {
  status: TaskStatus | RouteInstanceStatus | ProjectStatus;
  circleSize?: number;
  disabled?: boolean;
  type?: 'task' | 'project' | 'process-instance';
  onClick?: IButtonProps['onClick'];
  styles?: {
    buttonStyles?: IButtonProps['styles'];
    iconStyles?: IButtonProps['iconProps']['styles'];
  };
  id?: string;
  loading?: boolean;
}

function StatusIcon({
  onClick,
  type = 'task',
  status,
  styles,
  disabled = false,
  circleSize = 20,
  loading: propsLoading,
  id
}: StatusIconProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (status) setLoading(false);
  }, [status]);

  const circleHalfFullIconProps = useMemo(
    () => ({ iconName: 'CircleHalfFull', border: 'none', fontSize: circleSize * 1.08 }),
    [circleSize]
  );

  const pauseIconProps = useMemo(
    () => ({ iconName: 'Pause', fontSize: circleSize * 0.7 }),
    [circleSize]
  );

  const skypeClockIconProps = useMemo(
    () => ({
      iconName: 'SkypeClock',
      fontWeight: '100',
      fontSize: 10,
      paddingLeft: circleSize * 0.1
    }),
    [circleSize]
  );

  const checkMarkIconProps = useMemo(
    () => ({
      iconName: 'CheckMark',
      color: `rgb(${theme.statusIcon.filledIconForeground})`,
      backgroundColor: `rgb(${theme.statusIcon.filledIconBackground})`,
      borderColor: `rgb(${theme.statusIcon.filledIconBackground})`
    }),
    [theme.statusIcon]
  );

  const getTaskIconProps = useCallback(() => {
    if (status === TaskStatus.InProgress) {
      return circleHalfFullIconProps;
    }

    if (status === TaskStatus.Deferred) {
      return pauseIconProps;
    }

    if (status === TaskStatus.Waiting) {
      return skypeClockIconProps;
    }

    if (status === TaskStatus.Completed) {
      return checkMarkIconProps;
    }

    return { iconName: '' };
  }, [checkMarkIconProps, circleHalfFullIconProps, pauseIconProps, skypeClockIconProps, status]);

  const getProjectIconProps = useCallback(() => {
    if (status === ProjectStatus.InProgress) {
      return circleHalfFullIconProps;
    }

    if (status === ProjectStatus.Completed) {
      return checkMarkIconProps;
    }

    return { iconName: '' };
  }, [checkMarkIconProps, circleHalfFullIconProps, status]);

  const getProcessInstanceIconProps = useCallback(() => {
    if (status === RouteInstanceStatus.InProgress) {
      return circleHalfFullIconProps;
    }

    if (status === RouteInstanceStatus.Completed) {
      return checkMarkIconProps;
    }

    return { iconName: '' };
  }, [checkMarkIconProps, circleHalfFullIconProps, status]);

  const iconProps = useMemo(() => {
    if (type === 'task') {
      return getTaskIconProps();
    }

    if (type === 'project') {
      return getProjectIconProps();
    }

    if (type === 'process-instance') {
      return getProcessInstanceIconProps();
    }

    return { iconName: '' };
  }, [type, getTaskIconProps, getProjectIconProps, getProcessInstanceIconProps]);

  function getIconStyles() {
    let iconStyles: IButtonProps['iconProps']['styles'] = {
      root: {
        alignItems: 'center',
        border: `1px solid rgb(${theme.statusIcon.iconColor})`,
        borderRadius: '50%',
        color: `rgb(${theme.statusIcon.iconColor})`,
        display: 'flex',
        fontSize: circleSize * 0.8,
        height: circleSize,
        justifyContent: 'center',
        margin: 'auto',
        width: circleSize,
        fontFamily: 'FabricMDL2Icons !important',
        ...iconProps,
        ...styles?.iconStyles
      }
    };

    if (styles?.iconStyles) {
      iconStyles = concatStyleSets(iconStyles, styles.iconStyles);
    }

    return iconStyles;
  }

  function getButtonStyles() {
    let buttonStyles: IButtonProps['styles'] = {
      root: {
        width: circleSize,
        height: circleSize,
        margin: 'auto',
        borderRadius: circleSize
      }
    };

    if (styles?.buttonStyles) {
      buttonStyles = concatStyleSets(buttonStyles, styles.buttonStyles);
    }

    return buttonStyles;
  }

  if (loading || propsLoading) {
    return (
      <div
        aria-hidden="true"
        onClick={({ stopPropagation, preventDefault }) => {
          stopPropagation();
          preventDefault();
        }}
      >
        <LoadingSpinner
          size={SpinnerSize.medium}
          styles={{ container: { minHeight: circleSize, justifyContent: 'flex-start' } }}
        />
      </div>
    );
  }

  if (onClick && !disabled) {
    return (
      <IconButton
        id={id}
        onClick={(event) => {
          setLoading(true);
          onClick(event);
        }}
        title={t(`taskPanelDetail.status.label.${status}`)}
        styles={getButtonStyles()}
        iconProps={{ ...iconProps, styles: getIconStyles() }}
      />
    );
  }

  return (
    <Icon
      title={t(`taskPanelDetail.status.label.${status}`)}
      styles={getIconStyles()}
      iconName={iconProps.iconName}
    />
  );
}

export default StatusIcon;
