import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import {
  BaseSlots,
  ColorPicker,
  getColorFromString,
  Stack,
  ThemeGenerator,
  themeRulesStandardCreator
} from '@fluentui/react';
import { IFESettingsItem } from '../../../../hooks/api2/types/administration';
import { ThemeConfigContext } from '../../../../utils/theme';
import { Label, SettingsContainer, SettingsTextField } from '../../../../components';

export default function AppearanceSettings({
  settings,
  onSettingsChange
}: {
  settings: IFESettingsItem;
  onSettingsChange: (changes: Partial<IFESettingsItem>) => void;
}) {
  const { t } = useTranslation();
  const titlePrefix = 'Evocom';

  const [themeOverride, setThemeOverride] = useState(/** @type {Partial<ThemeConfig>|null} */ null);
  const themeConfigCtx = useContext(ThemeConfigContext);

  const [debouncedThemeOverride] = useDebounce(themeOverride, 200, {
    maxWait: 2000
  });
  useEffect(() => {
    themeConfigCtx.setOverride?.(debouncedThemeOverride, true);

    return () => {
      themeConfigCtx.setOverride(debouncedThemeOverride, false);
    };
  }, [themeConfigCtx, debouncedThemeOverride]);

  function handleColorChange(value: { hex: string }) {
    const themeRules = themeRulesStandardCreator();

    const colors = {
      primaryColor: getColorFromString(`#${value.hex}`),
      textColor: getColorFromString('#323130'),
      backgroundColor: getColorFromString('#ffffff')
    };
    ThemeGenerator.insureSlots(themeRules, false);
    ThemeGenerator.setSlot(
      themeRules[BaseSlots[BaseSlots.primaryColor]],
      colors.primaryColor,
      undefined,
      true,
      true
    );
    ThemeGenerator.setSlot(
      themeRules[BaseSlots[BaseSlots.foregroundColor]],
      colors.textColor,
      undefined,
      false,
      false
    );
    ThemeGenerator.setSlot(
      themeRules[BaseSlots[BaseSlots.backgroundColor]],
      colors.backgroundColor,
      undefined,
      false,
      false
    );

    const palette = ThemeGenerator.getThemeAsJson(themeRules);

    onSettingsChange({ palette });
    setThemeOverride({ fluentPalette: palette });
  }

  function handleTitle(value: string) {
    onSettingsChange({ title: `${titlePrefix} ${value}` });
  }

  function handleBackgroundImage(originalValue?: string) {
    const value = originalValue?.trim() ?? '';

    if (value.startsWith('http')) {
      onSettingsChange({ background: value });
    } else if (value) {
      onSettingsChange({ background: `/React/${value}` });
    } else {
      onSettingsChange({ background: '' });
    }
  }

  function getTitleDefaultValue() {
    if (settings.title?.startsWith(titlePrefix)) {
      return settings.title.substring(titlePrefix.length).trimStart();
    }

    return settings.title;
  }

  function getBackgroundDefaultValue() {
    if (settings.background?.startsWith('/React/')) {
      return settings.background.substring('/React/'.length);
    }

    return settings.background;
  }

  return (
    <SettingsContainer
      defaultExpanded
      iconName="Color"
      label={t('settingsAdministration.form.label.appearance')}
      description={t('settingsAdministration.form.summary.appearance')}
    >
      <Stack tokens={{ childrenGap: 8 }}>
        <SettingsTextField
          label={t('settingsAdministration.form.label.title')}
          prefix={titlePrefix || ''}
          required={false}
          onChange={(value) => handleTitle(value)}
          defaultValue={getTitleDefaultValue()}
        />
        <SettingsTextField
          label={t('settingsAdministration.form.label.backgroundImageTitle')}
          required={false}
          onChange={(value) => handleBackgroundImage(value)}
          defaultValue={getBackgroundDefaultValue()}
        />
        <div>
          <Label
            styles={{
              fluentLabel: { root: { fontSize: 12, fontWeight: 'normal', color: '#424242' } }
            }}
            label={t('settingsAdministration.form.label.themePrimary')}
          />
          <ColorPicker
            color={getColorFromString(settings.palette.themePrimary)}
            onChange={(_, value) => handleColorChange(value)}
            styles={{
              colorRectangle: { borderRadius: 8, overflow: 'hidden' },
              panel: { padding: 0 },
              root: { maxWidth: 352, minWidth: 352 }
            }}
            alphaType="none"
            strings={{
              blue: t('settingsAdministration.colorPicker.blue'),
              green: t('settingsAdministration.colorPicker.green'),
              hex: t('settingsAdministration.colorPicker.hex'),
              red: t('settingsAdministration.colorPicker.red')
            }}
          />
        </div>
      </Stack>
    </SettingsContainer>
  );
}
