function RedirectContainer() {
  // Get the redirect URI from local storage (this is set in api.js when the user is redirected to the consent page)
  const redirectUri = localStorage.getItem('redirectUri');

  // If the redirect URI exists, redirect the user to that location
  if (redirectUri) {
    // Remove the redirect URI from local storage
    localStorage.removeItem('redirectUri');

    // Check if the redirect URI is not the authentication start or end page (this is to prevent an infinite loop in ms-teams)
    if (
      !redirectUri.includes('authentication-end') &&
      !redirectUri.includes('authentication-start')
    ) {
      // Redirect the user to the specified location
      window.location = redirectUri;
    }
  }

  // If there is no redirect URI, return an empty div element
  return <div />;
}

export default RedirectContainer;
