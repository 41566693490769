import FieldPicker from 'components/inputs/FieldPicker/FieldPickerTS';
import Label from 'components/inputs/Label/Label';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getField } from 'services/fetchRequests';
import { IFieldProps } from 'types';
import { Checkbox, Slider, TextField } from '@fluentui/react';
import { IFormProps } from './FieldForm';

function AISettings({ field, setField, disabled, language }: IFormProps) {
  const [selectedPromptFields, setselectedPromptFields] = useState<IFieldProps[]>([]);
  const [status, setStatus] = useState<'init' | 'loading' | 'loaded'>('init');

  const { t } = useTranslation('components', { keyPrefix: 'aIFieldSettings' });

  useEffect(() => {
    if (status === 'init' && field.additionalPromptFieldIds) {
      Promise.all(field.additionalPromptFieldIds.map((id) => getField(id))).then((fields) => {
        setselectedPromptFields(fields);
        setStatus('loaded');
      });
    }
  }, [field.additionalPromptFieldIds, status]);

  function updatePromptTranslation(newValue?: string | null) {
    setField((prevState) => {
      if (Array.isArray(prevState.allPrompts) && language) {
        const translations = prevState.allPrompts;

        // Find existing translation
        const translation = translations?.find((name) => {
          return name.language === language;
        });

        if (!translations) return prevState;

        if (translation) {
          // Update existing translation
          translation.text = newValue || '';
        } else {
          // Add new translation
          translations.push({ language, text: newValue || '' });
        }

        return { ...prevState, allPrompts: translations };
      }

      return prevState;
    });
  }

  function updatePrompt(value?: string) {
    if (field.allPrompts && language) {
      updatePromptTranslation(value);
    } else {
      setField((prevState) => ({ ...prevState, prompt: value }));
    }
  }

  function onCheckboxChange(property: 'autoPrompt' | 'allowRefining', checked?: boolean) {
    setField((prevState) => ({ ...prevState, [property]: checked }));
  }

  function onFieldPickerChange(value: IFieldProps[] = []) {
    setselectedPromptFields(value);

    const additionalPromptFieldIds: string[] = [];

    value.forEach((field) => {
      if (field.id) {
        additionalPromptFieldIds.push(field.id);
      }
    });

    setField((prevState) => ({ ...prevState, additionalPromptFieldIds }));
  }

  function getDefaultFieldPromptValue() {
    if (!language) {
      return field.prompt || '';
    }

    const languageName = field.allPrompts?.find(
      (translatedName) => translatedName.language === language
    );

    return languageName?.text || '';
  }

  function onRenderTemperature(value: number) {
    const aiTemperature = 10 - value;
    return t(`temperature.${aiTemperature}`);
  }

  function getDefaultTemperatureValue() {
    // check if field.aiTemperature is a number
    if (field.aiTemperature && typeof field.aiTemperature === 'number') {
      return 10 - field.aiTemperature * 10;
    }

    return 5;
  }

  function onTemperatureChange(value: number) {
    let aiTemperature = 1 - value / 10;

    aiTemperature = Math.round(aiTemperature * 100) / 100;

    setField((prevState) => ({ ...prevState, aiTemperature }));
  }

  return (
    <div>
      <Checkbox
        label={t('label.autoPrompt')}
        defaultChecked={field.autoPrompt}
        onChange={(_, checked = false) => onCheckboxChange('autoPrompt', checked)}
        styles={{ root: { marginTop: '15px', marginBottom: '15px' } }}
        disabled={disabled}
      />
      <Checkbox
        label={t('label.allowRefining')}
        defaultChecked={field.allowRefining}
        onChange={(_, checked = false) => onCheckboxChange('allowRefining', checked)}
        styles={{ root: { marginTop: '15px', marginBottom: '15px' } }}
        disabled={disabled}
      />
      <FieldPicker
        label={t('label.fields')}
        placeholder={t('placeholder.fields')}
        onChange={onFieldPickerChange}
        selectedFields={selectedPromptFields}
        itemLimit={100}
      />
      <Label
        description={t('temperature.description')}
        label={t('temperature.label')}
        styles={{ container: { justifyContent: 'normal', gap: 10, marginTop: 15, height: 25 } }}
      />
      <Slider
        min={0}
        max={10}
        step={1}
        valueFormat={onRenderTemperature}
        defaultValue={getDefaultTemperatureValue()}
        showValue
        onChange={onTemperatureChange}
        styles={{ root: { width: '40%' } }}
      />
      <TextField
        key={language || 1}
        required
        label="Prompt"
        placeholder={t('placeholder.prompt')}
        defaultValue={getDefaultFieldPromptValue()}
        multiline
        autoAdjustHeight
        onChange={(_, value) => updatePrompt(value)}
      />
    </div>
  );
}

export default AISettings;
