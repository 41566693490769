import LongTextField from 'components/inputs/LongTextField';
import { t } from 'i18next';
import { Dropdown, TextField } from '@fluentui/react';
import { IFormProps } from './FieldForm';

function ConfirmationSettings({ field, setField, disabled, language }: IFormProps) {
  // update text or language field into the translation Field
  function updateFieldTranslation(prop: 'allTextsOk' | 'allTextsNOk', newValue?: string | null) {
    setField((prevState) => {
      if (Array.isArray(prevState[prop]) && language) {
        const translations = prevState[prop];

        // Find existing translation
        const translation = translations?.find((name) => {
          return name.language === language;
        });

        if (!translations) return prevState;

        if (translation) {
          // Update existing translation
          translation.text = newValue || '';
        } else {
          // Add new translation
          translations.push({ language, text: newValue || '' });
        }

        return { ...prevState, [prop]: translations };
      }

      return prevState;
    });
  }
  // Update the confirmation button value(textOk) of textOk on the allTextsOk Field
  function updateButtonOK(newValue: string | undefined) {
    const textOk = newValue;

    if (field.allTextsOk && language) {
      updateFieldTranslation('allTextsOk', textOk);
    } else {
      setField((prevState) => ({ ...prevState, textOk }));
    }
  }

  // Update the confirmation button value(textNOk) of textNOk on the allTextsNOk Field
  function updateButtonNOK(newValue: string | null = '') {
    const textNOk = newValue || '';

    if (field.allTextsNOk && language) {
      updateFieldTranslation('allTextsNOk', textNOk);
    } else {
      setField((prevState) => ({ ...prevState, textNOk }));
    }
  }

  // Update the default value of TextField - textOk
  function getDefaultFieldOKValue() {
    if (!language) return field.textOk;

    const languageName = field.allTextsOk?.find(
      (translatedName) => translatedName.language === language
    );

    return languageName?.text || '';
  }

  // Update the default value of TextField - textNOk
  function getDefaultFieldNOKValue() {
    if (!language) return field.textNOk;

    const languageName = field.allTextsNOk?.find(
      (translatedName) => translatedName.language === language
    );

    return languageName?.text || '';
  }

  return (
    <div>
      <Dropdown
        disabled={disabled}
        required
        label={t('createField.dialog.confirmationFormat.label')}
        defaultSelectedKey={field?.confirmationFormat || 1}
        styles={{ root: { width: '100%' } }}
        onChange={(_, value) =>
          setField(() => ({
            ...field,
            confirmationFormat: Number(value?.key),
            textOk: 'OK',
            textNOk: 'NOK'
          }))
        }
        options={[
          { key: 1, text: t('createField.dialog.confirmationFormat.twoButtons') },
          { key: 2, text: t('createField.dialog.confirmationFormat.checkBox') }
        ]}
      />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: '100%', marginRight: '5px' }}>
          {field.confirmationFormat === 2 ? (
            <LongTextField
              key={language}
              displayCmdBar
              label={t('createField.dialog.confirm.label')}
              onChange={(value) => updateButtonOK(value || '')}
              disabled={disabled && !language}
              defaultValue={getDefaultFieldOKValue()}
            />
          ) : (
            <TextField
              key={language}
              label={t('createField.dialog.ok.label')}
              required
              onChange={(_, value) => updateButtonOK(value || '')}
              disabled={disabled && !language}
              defaultValue={getDefaultFieldOKValue()}
            />
          )}
        </div>
        <div
          style={{
            width: '100%',
            marginLeft: '5px',
            display: field?.confirmationFormat === 2 ? 'none' : 'inline'
          }}
        >
          <TextField
            key={language}
            label={t('createField.dialog.nok.label')}
            required
            onChange={(_, value) => updateButtonNOK(value || '')}
            disabled={disabled && !language}
            defaultValue={getDefaultFieldNOKValue()}
          />
        </div>
      </div>
    </div>
  );
}

export default ConfirmationSettings;
