import { acquireAccessToken } from 'features/Authentication';
import i18next from 'i18next';
import config from '../config';
import { debugFailApiRequest } from './api2';
import { ErrorLike } from '../components/ShowError';

interface IFetchRequestProps {
  anonymousCall?: boolean;
  body?: BodyInit | null;
  ignoreAlert?: boolean;
  ignoreConsent?: boolean;
  ignoreContentType?: boolean;
  contentType?: string;
  method?: string;
  origin?: string;
  retries?: number;
  signal?: AbortSignal;
  url: string;
}

function isInIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export function apiErrorHandler(error: { message: string }) {
  if (!error) {
    return false;
  }

  if (error.message === 'reload') {
    localStorage.clear();
    window.location.reload();
    return false;
  }

  if (error.message === 'reloadOnly') {
    window.location.reload();
    return false;
  }

  if (window.location.pathname.indexOf('access') < 1 && error.message === 'access') {
    const win: Window = window;
    win.location = '/access';

    return false;
  }

  return false;
}

function transformTextToObjectForErrorMessage(text: string) {
  let textMessage = JSON.parse(text);

  // check if the api returned an object instead of a string
  // convert string to object and check if message is defined
  if ({}.hasOwnProperty.call(textMessage, 'Message')) {
    textMessage = textMessage.Message;
  } else {
    textMessage = 'Undefined error';
  }

  return textMessage;
}

type ErrorAlertHandler = (error: ErrorLike) => void;
const errorAlertHandlers: Set<ErrorAlertHandler> = new Set();
export function registerErrorAlertHandler(handler: ErrorAlertHandler) {
  errorAlertHandlers.add(handler);
  return () => {
    errorAlertHandlers.delete(handler);
  };
}
function dispatchErrorAlert(error: ErrorLike) {
  if (!errorAlertHandlers.size) {
    let errorTitle = error?.toString() ?? '???';
    if (error && typeof error === 'object') {
      errorTitle = (error as { title: string }).title ?? (error as { message: string }).message;
    }
    alert(errorTitle);
  }

  for (const handler of errorAlertHandlers) {
    try {
      handler(error);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('error in error alert handler', err);
    }
  }
}

function status(
  fetchRequestParameters: IFetchRequestProps,
  response: Response,
  ignoreAlert: boolean,
  ignoreConsent: boolean
) {
  if (response.status >= 200 && response.status < 300) {
    return Promise.resolve(response);
  }

  // 403 extra consent required
  const wwwAuthenticateHeader = response.headers.get('www-authenticate');
  if (response.status === 403 && !ignoreConsent && wwwAuthenticateHeader) {
    const consentUri = wwwAuthenticateHeader?.split('consentUri="')[1].split('"')[0];

    if (!isInIframe() && wwwAuthenticateHeader?.includes('consentUri=')) {
      localStorage.setItem('redirectUri', window.location.href);

      window.open(consentUri, '_self');
    }

    return Promise.reject(response.text());
  }

  if (response.status === 400 || response.status === 401 || response.status === 403) {
    if (fetchRequestParameters.url === 'Init') {
      return Promise.reject(new Error('access'));
    }

    let textMessage = '';

    const result = response.text().then((text: string) => {
      textMessage = text;

      if (text.indexOf('"Message"') !== -1) {
        textMessage = transformTextToObjectForErrorMessage(text);
      }

      if (!ignoreAlert) {
        dispatchErrorAlert({
          type: 'about:blank',
          status: response.status,
          title: textMessage.replace(/^"|"$/g, '')
        });
      }

      const errMessage = {
        status: response.status,
        message: textMessage.replace(/^"|"$/g, '')
      };

      return errMessage;
    });

    return Promise.reject(result);
  }

  if (response.status === 425) {
    // retry fetch 2x when status is 425
    const { retries } = fetchRequestParameters;
    const defaultRetries = 3;
    const retriesCount = retries ? retries - 1 : defaultRetries;

    if (retriesCount > 0) {
      setTimeout(() => {
        return fetchRequest({
          ...fetchRequestParameters,
          retries: retriesCount
        });
      }, 5000);
    }

    return Promise.reject(new Error('reloadOnly'));
  }

  if (response.status === 404) {
    if (!ignoreAlert) {
      dispatchErrorAlert({ type: 'about:blank', status: 404, title: i18next.t('error.404') });
    }

    const error = new Error(i18next.t('error.404'));
    (error as unknown as { status: number }).status = response.status;

    return Promise.reject(error);
  }

  if (response.status >= 500 && response.status <= 600) {
    const result = response.text().then((text: string) => {
      if (!ignoreAlert) {
        dispatchErrorAlert({ type: 'about:blank', status: response.status, title: text });
      }

      const errMessage = JSON.stringify({
        status: response.status,
        message: text.replace(/^"|\\r\\n|\\|"$/g, '')
      });

      return errMessage;
    });

    return Promise.reject(result);
  }

  return Promise.reject(new Error('reload'));
}

function dataTransform(response: Response, ignoreContentType?: boolean) {
  if (response.status === 204 || ignoreContentType) {
    return response;
  }

  return response.json();
}

async function addAuthorizationHeaders(currentHeaders: Headers) {
  // async function addAuthorizationHeaders(currentHeaders, useRedirectAuth) {
  let token;

  try {
    token = await acquireAccessToken();
    // token = await acquireAccessToken(useRedirectAuth);
  } catch (error) {
    return currentHeaders;
  }

  currentHeaders.append('Authorization', `Bearer ${token.accessToken}`);

  // set tenant id if available
  const tenantId = window.localStorage.getItem('tenantId');
  if (tenantId) {
    currentHeaders.append('x-ep-tenant', tenantId);
  }

  return currentHeaders;
}

async function fetchRequest({
  anonymousCall,
  body,
  ignoreAlert = false,
  ignoreConsent = false,
  ignoreContentType = false,
  method,
  origin,
  contentType = 'application/json',
  retries,
  signal,
  url
}: IFetchRequestProps) {
  // set default headers for every request
  let headers = new Headers();
  headers.append('Content-Type', contentType);

  if (!anonymousCall) {
    // add authorization headers
    headers = await addAuthorizationHeaders(headers);
  }

  headers.append('x-ep-utcoffset', new Date().getTimezoneOffset().toString());

  // auto set headers if none is given
  if (ignoreContentType) {
    headers.delete('Content-Type');
  }

  await debugFailApiRequest();

  const fetchRequestParameters: IFetchRequestProps = {
    url,
    method,
    body,
    ignoreContentType,
    signal,
    ignoreAlert,
    ignoreConsent,
    retries: Number.isInteger(retries) ? retries : 7
  };

  const completeUrl = `${origin || config.API_URL}/${url}`;

  // execute fetch
  return fetch(completeUrl, {
    method: method || 'GET',
    // If we don't omit credentials, we might rarely, randomly, unpredictably, get a mystery 500 Internal Server Error
    // response from *somewhere* in the network for all requests with a body.
    // This mystery response has content-length 0, and absolutely no other details, so we have no idea where it comes
    // from. This sure does fix it, though!
    credentials: 'omit',
    signal,
    headers,
    body
  })
    .then((response) => status(fetchRequestParameters, response, ignoreAlert, ignoreConsent))
    .then((response) => dataTransform(response, ignoreContentType))
    .catch((error) => {
      if (error.name === 'TypeError' && error.message === 'Failed to fetch' && retries > 0) {
        return new Promise((resolve) => {
          setTimeout(resolve, 1500);
        }).then(() => fetchRequest({ ...fetchRequestParameters, retries: retries - 1 }));
      }

      return Promise.reject(error);
    });
}

export default fetchRequest;
