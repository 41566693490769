/* eslint-disable import/no-cycle */
import { RichTextEditor } from 'components';
import PropTypes from 'prop-types';
import { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import fetchRequest, { apiErrorHandler } from 'services/api';
import styled from 'styled-components';
import { fieldPropType } from 'types';
import { generateUuid } from 'utils/helpers';
import {
  ActionButton,
  DirectionalHint,
  getTheme,
  Icon,
  IconButton,
  Label,
  TagPicker,
  TooltipHost
} from '@fluentui/react';
import FieldEditDialog from 'components/inputs/FieldPicker/FieldEditDialog';
import InteractiveTooltipHost from 'components/surfaces/InteractiveTooltipHost';

const SuggestionItemStyled = styled.div`
  padding: 7px;
  text-align: left;

  .create-tag {
    padding-right: 5px;
    padding-left: 2px;
    color: ${(props) => props.theme.themePrimary};
  }

  .field-type-text {
    color: rgb(133, 133, 133);
    font-weight: 400;
    font-size: 12px;
    text-align: left;
  }
`;

const CalloutContentWrapper = styled.div`
  margin: 7px;
  color: #323130;

  .c-callout-content_field-name {
    font-size: 21px;
    font-weight: 300;
  }

  .c-callout-content_info-wrapper {
    margin-top: 6px;
  }

  .c-callout-content_info-header-2 {
    font-weight: 400;
    font-size: 14px;
  }

  .c-callout-content_info-text {
    font-weight: 300;
    font-size: 13px;
  }
`;

const ErrorMessage = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: rgb(164, 38, 44);
  padding-top: 5px;
  display: flex;
`;

const LabelWithInfoStyled = styled.div`
  display: flex;
  position: relative;
  margin-top: 10px;
  margin-bottom: 5px;

  .info-icon {
    font-size: 11px;
    position: absolute;
    top: 3px;
    color: rgb(0, 120, 212);
  }
`;

const InfoIcon = styled(Icon)`
  margin: 0 10px 0 5px;

  &:hover {
    cursor: help;
  }
`;

const SelectedFieldStyled = styled(InteractiveTooltipHost)`
  display: flex;
  padding-left: 3px;
  margin: 3px;
  align-items: center;
  max-height: 24;
  background-color: #f3f2f1;
  width: 100%;

  .ms-TooltipHost {
    display: inline-block;
    width: calc(100% - 24px);
  }

  .field-name {
    cursor: default;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 3px;
  }
`;

const TagPickerStyled = styled(TagPicker)`
  background-color: white;
  .ms-BasePicker-text {
    min-width: 145px;
  }
`;

function FieldPicker({
  onChange,
  selectedField,
  placeholder,
  styles,
  label,
  infoText,
  required,
  errorMessage,
  disabled,
  definitionId,
  disableCreate,
  selectedFields,
  availableFields,
  autoFocused,
  onBlur
}) {
  const { t } = useTranslation();

  const resultsMaximumNumber = 50;

  const [, setResetFieldValue] = useState(0);

  const [field, setField] = useState(true);

  const [fieldPickerKey, setFieldPickerKey] = useState(null);
  const [fieldInputId, setFieldInputId] = useState(null);
  const [dataFieldsInitialized, setDataFieldsInitialized] = useState(false);
  const [isPickerFocused, setIsPickerFocused] = useState(false);

  let pickerRef = createRef();

  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (!fieldPickerKey) {
      setFieldPickerKey(generateUuid());
    }

    if (!fieldInputId) {
      setFieldInputId(generateUuid());
    }
  }, [fieldPickerKey, fieldInputId]);

  useEffect(() => {
    if (autoFocused) {
      setIsPickerFocused(true);
    }
  }, [autoFocused]);

  function filterUnselecetdFileds(fields) {
    if (selectedFields?.length && fields?.length) {
      const filteredFields = [];

      fields.map((field) => {
        let fieldSelected = false;

        selectedFields.map((selectedField) => {
          if (field.id === selectedField.id) {
            fieldSelected = true;
          }

          return null;
        });

        if (!fieldSelected) {
          filteredFields.push(field);
        }

        return null;
      });

      return filteredFields;
    }

    return fields;
  }

  const fetchFields = async (filterText) => {
    if (availableFields) {
      if (availableFields?.length) {
        if (filterText) {
          const filteredFields = availableFields.filter((field) =>
            field.name?.toLowerCase().includes(filterText?.toLowerCase())
          );

          return filteredFields;
        }

        return availableFields;
      }

      return null;
    }

    let url = `Route/Fields?pageIndex=0&itemsPerPage=${resultsMaximumNumber}`;

    if (definitionId) {
      url += `&routeDefId=${definitionId}`;
    }

    if (filterText && typeof filterText !== 'object') {
      url += `&searchTerms=${encodeURIComponent(filterText)}`;
    }

    const fields = await fetchRequest({ url }).catch(apiErrorHandler);

    if (!dataFieldsInitialized) {
      setDataFieldsInitialized(true);
    }

    return fields?.items;
  };

  const onEmptyResolveSuggestions = async () => {
    const dataFields = await fetchFields(null);
    const filteredFields = filterUnselecetdFileds(dataFields);

    return filteredFields || [];
  };

  function getDefaultSelectedItems() {
    if (selectedField?.id) {
      return [selectedField];
    }

    return [];
  }

  useEffect(() => {
    if (isPickerFocused) {
      if (pickerRef) {
        pickerRef.focusInput();
      }
    }
  }, [isPickerFocused, pickerRef]);

  const onResolveSuggestions = async (text) => {
    const results = await fetchFields(text);

    const filteredResults = filterUnselecetdFileds(results);

    const isFilterTextInResults =
      !results || !!results.find((result) => result.name.toLowerCase() === text.toLowerCase());

    const showCreateFieldOption = !disableCreate && !isFilterTextInResults;

    if (showCreateFieldOption) {
      return [
        ...filteredResults.slice(0, resultsMaximumNumber - 1),
        { createField: true, name: text }
      ];
    }

    return filteredResults;
  };

  const getFieldTypeText = (fieldType) => {
    switch (fieldType) {
      case 1:
        return t('formbuilder.fieldPicker.smallText');
      case 2:
        return t('formbuilder.fieldPicker.longText');
      case 3:
        return t('formbuilder.fieldPicker.number');
      case 4:
        return t('formbuilder.fieldPicker.boolean');
      case 5:
        return t('formbuilder.fieldPicker.dateTime');
      case 6:
        return t('formbuilder.fieldPicker.choice');
      case 7:
        return t('formbuilder.fieldPicker.person');
      case 8:
        return t('formbuilder.fieldPicker.rating');
      case 9:
        return t('formbuilder.fieldPicker.link');
      case 10:
        return t('formbuilder.fieldPicker.document');
      case 11:
        return t('formbuilder.fieldPicker.externalData');
      case 12:
        return t('createField.dialog.field.types.lookup');
      case 13:
        return t('createField.dialog.field.types.location');
      case 14:
        return t('createField.dialog.field.types.signature');
      case 15:
        return t('createField.dialog.field.types.multimedia');
      case 16:
        return t('createField.dialog.field.types.scanner');
      default:
        return '';
    }
  };

  const getToolTipContent = (field) => {
    const {
      name,
      description,
      maxLength,
      fieldType,
      numStars,
      choices,
      choiceFormat,
      allowFillIn,
      appendChanges,
      numDecimals,
      dateFormat
    } = field;

    let maxLengthInfo;
    let descriptionInfo;
    let numStarsInfo;
    let choiceInfo;
    let appendTeChangesInfo;
    let numDecimalsInfo;
    let dateTimeInfo;

    if (description) {
      descriptionInfo = (
        <div className="c-callout-content_info-text" style={{ maxWidth: '300px' }}>
          <RichTextEditor
            disabled
            styles={{ editor: { fontSize: '12px', color: '#605e5c' } }}
            defaultValue={description}
          />
        </div>
      );
    }

    const getInfoRow = (header, text) => {
      return (
        <div className="c-callout-content_info-wrapper">
          <div className="c-callout-content_info-header-2">{header}</div>
          <div className="c-callout-content_info-text">{text}</div>
        </div>
      );
    };

    const fieldTypeInfo = getInfoRow(
      t('formbuilder.fieldPicker.info.type'),
      getFieldTypeText(fieldType)
    );

    if (maxLength) {
      maxLengthInfo = getInfoRow(t('formbuilder.fieldPicker.info.maxChars'), maxLength);
    }

    if (numStars) {
      numStarsInfo = getInfoRow(t('formbuilder.fieldPicker.info.numStars'), numStars);
    }

    if (fieldType === 2) {
      appendTeChangesInfo = getInfoRow(
        t('formbuilder.fieldPicker.info.appendChanges.header'),
        appendChanges
          ? t('formbuilder.fieldPicker.info.appendChanges.yes')
          : t('formbuilder.fieldPicker.info.appendChanges.no')
      );
    }

    if (fieldType === 3) {
      numDecimalsInfo = getInfoRow(t('formbuilder.fieldPicker.info.numDecimal'), numDecimals);
    }

    if (fieldType === 5) {
      dateTimeInfo = getInfoRow(
        t('formbuilder.fieldPicker.info.dateTime.header'),
        dateFormat === 2
          ? t('formbuilder.fieldPicker.info.dateTime.yes')
          : t('formbuilder.fieldPicker.info.dateTime.no')
      );
    }

    if (choiceFormat && choices?.length) {
      choiceInfo = (
        <>
          {getInfoRow(
            t('formbuilder.fieldPicker.info.choice.format'),
            choiceFormat === 1
              ? t('formbuilder.fieldPicker.info.choice.single')
              : t('formbuilder.fieldPicker.info.choice.multiple')
          )}
          {getInfoRow(
            t('formbuilder.fieldPicker.info.choice.allowFillIn.header'),
            allowFillIn
              ? t('formbuilder.fieldPicker.info.choice.allowFillIn.yes')
              : t('formbuilder.fieldPicker.info.choice.allowFillIn.no')
          )}
          {getInfoRow(
            t('formbuilder.fieldPicker.info.choice.choices'),
            <div style={{ maxHeight: '600px' }}>
              {choices.map((choice) => (
                <div key={choice.value}> - {choice.value}</div>
              ))}
            </div>
          )}
        </>
      );
    }

    return (
      <CalloutContentWrapper>
        <span className="c-callout-content_field-name">{name}</span>
        <ActionButton
          text={t('formbuilder.fieldPicker.edit')}
          styles={{ root: { color: getTheme().palette.themePrimary } }}
          onClick={() => setIsEditing(true)}
        />
        {descriptionInfo}
        {fieldTypeInfo}
        {maxLengthInfo}
        {numStarsInfo}
        {choiceInfo}
        {appendTeChangesInfo}
        {numDecimalsInfo}
        {dateTimeInfo}
      </CalloutContentWrapper>
    );
  };

  const onRenderSuggestionsItem = (suggestionsProps) => {
    if (suggestionsProps.createField && !disableCreate) {
      return (
        <SuggestionItemStyled>
          <div className="flex">
            <div className="create-tag">{t('formbuilder.fieldPicker.create')}</div>
            <div>{suggestionsProps.name}</div>
          </div>
        </SuggestionItemStyled>
      );
    }

    return (
      <TooltipHost
        delay={2}
        directionalHint={9}
        tooltipProps={{ onRenderContent: () => getToolTipContent(suggestionsProps) }}
        id={`${suggestionsProps.id}-suggestion-tooltip`}
        key={`${suggestionsProps.id}-suggestion-tooltip`}
        calloutProps={{ gapSpace: 10 }}
      >
        <SuggestionItemStyled>
          <div>{suggestionsProps.name}</div>
          <div className="field-type-text">{getFieldTypeText(suggestionsProps.fieldType)}</div>
        </SuggestionItemStyled>
      </TooltipHost>
    );
  };

  const onRenderItem = (itemProps) => {
    return (
      <SelectedFieldStyled
        content={getToolTipContent(itemProps.item)}
        calloutProps={{
          gapSpace: 5,
          directionalHint: DirectionalHint.leftCenter
        }}
        key={`item-key-${itemProps?.item?.id}`}
      >
        <div className="field-name">{itemProps.item.name}</div>
        <IconButton
          iconProps={{
            key: `${itemProps.id}-cancel`,
            iconName: 'Cancel',
            styles: {
              root: {
                color: 'black',
                fontSize: '12px',
                margin: 'auto'
              }
            }
          }}
          styles={{
            root: { height: '24px', width: '24px', padding: 0 },
            rootHovered: { height: '24px', width: '24px', backgroundColor: '#e6e6e6' }
          }}
          onClick={itemProps.onRemoveItem}
        />
      </SelectedFieldStyled>
    );
  };

  const onItemSelected = (item) => {
    if (item.createField) {
      if (disableCreate) return null;

      setField(item);
      requestAnimationFrame(() => {
        // wait until after setField is complete
        setIsEditing(true);
      });
    }

    return item;
  };

  const onDialogCancel = () => {
    setIsEditing(false);
  };

  const onDialogConfirm = (field) => {
    onChange([field]);
  };

  function getLabel() {
    if (infoText && label) {
      return (
        <LabelWithInfoStyled>
          <span style={{ fontWeight: 600, color: disabled ? 'rgb(161, 159, 157)' : null }}>
            {label}
            {required ? (
              <span style={{ color: 'rgb(164, 38, 44)', marginLeft: '5px' }}>*</span>
            ) : null}
          </span>
          {!disabled ? (
            <TooltipHost
              tooltipProps={{
                onRenderContent: () => (
                  <RichTextEditor defaultValue={infoText} disabled displayCmdBar={false} />
                )
              }}
              directionalHint={DirectionalHint.topCenter}
              calloutProps={{ gapSpace: 5 }}
              styles={{
                root: { display: 'inline-block', maxWidth: 200 }
              }}
            >
              <InfoIcon iconName="Info" className="info-icon" disabled={disabled} />
            </TooltipHost>
          ) : null}
        </LabelWithInfoStyled>
      );
    }

    if (label) {
      return (
        <Label required={required} disabled={disabled}>
          {label}
        </Label>
      );
    }

    return null;
  }

  function onBlurInput(e) {
    setIsPickerFocused(false);
    if (e?.target?.value) {
      const fieldPickerInput = document.getElementById(fieldInputId);

      if (fieldPickerInput?.value) {
        setFieldPickerKey(generateUuid());
      }
    }

    if (onBlur) {
      onBlur();
    }
  }

  function onFocus() {
    setIsPickerFocused(true);
  }

  let newStyles = { ...styles };
  if (errorMessage) {
    newStyles = {
      text: {
        borderColor: 'rgb(164, 38, 44)',
        selectors: {
          '::after': {
            borderColor: 'rgb(164, 38, 44)'
          },
          '&:hover': {
            borderColor: 'rgb(164, 38, 44)'
          }
        }
      }
    };
  }

  return (
    <>
      {getLabel()}
      <TagPickerStyled
        key={fieldPickerKey}
        selectedItems={getDefaultSelectedItems() || []}
        onResolveSuggestions={onResolveSuggestions}
        onEmptyResolveSuggestions={onEmptyResolveSuggestions}
        pickerSuggestionsProps={{
          resultsMaximumNumber,
          noResultsFoundText: <div>{t('fieldPicker.noResultsFoundText')}</div>
        }}
        onRenderSuggestionsItem={(props, itemProps) => onRenderSuggestionsItem(props, itemProps)}
        onRenderItem={onRenderItem}
        onItemSelected={onItemSelected}
        inputProps={{
          placeholder,
          id: fieldInputId,
          onFocus
        }}
        itemLimit={1}
        resolveDelay={250}
        disabled={disabled}
        onBlur={(e) => onBlurInput(e)}
        onChange={(value) => {
          setResetFieldValue((prevState) => prevState + 1);
          onChange(value);
        }}
        styles={{ ...newStyles }}
        componentRef={(input) => {
          pickerRef = input;
        }}
      />
      {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}

      <FieldEditDialog
        open={isEditing}
        onClose={onDialogCancel}
        isGroup={false}
        value={selectedField || field}
        onChange={onDialogConfirm}
      />
    </>
  );
}

FieldPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedField: PropTypes.object,
  placeholder: PropTypes.string,
  styles: PropTypes.object,
  label: PropTypes.string,
  infoText: PropTypes.string,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  definitionId: PropTypes.string,
  disableCreate: PropTypes.bool,
  selectedFields: PropTypes.arrayOf(fieldPropType),
  availableFields: PropTypes.arrayOf(fieldPropType),
  autoFocused: PropTypes.bool,
  onBlur: PropTypes.func
};

FieldPicker.defaultProps = {
  selectedField: null,
  placeholder: null,
  styles: null,
  label: null,
  infoText: null,
  required: null,
  errorMessage: null,
  disabled: false,
  definitionId: null,
  disableCreate: false,
  selectedFields: null,
  availableFields: null,
  autoFocused: false,
  onBlur: null
};

export default FieldPicker;
