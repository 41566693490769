import { LanguagePicker } from 'components';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import fetchRequest, { apiErrorHandler } from 'services/api';
import styled from 'styled-components';
import { processCategoryPropType } from 'types';
import {
  ComboBox,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IconButton,
  Label,
  PrimaryButton,
  TextField,
  TooltipHost
} from '@fluentui/react';
import {
  ResolveChangeGroupDialog,
  ResolveOrderDialog,
  getGroupElements,
  getProcGroupingErrorMessage,
  getSortOrderErrorMessage,
  getSortOrderValues,
  isBiggerOrEqualProcGrouping,
  isPositionOccupied,
  validateProcessGrouping,
  validateSortOrder
} from './ProcessMapTypeValidation';

const DialogContentStyled = styled.div`
  display: inline-block;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const TranslationInput = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 5px;
`;

const DialogInputContent = styled.div`
  display: flex;
  width: 100%;
`;

const VerticalDivider = styled.div`
  width: 50px;
`;

function CreateProcessMapTypeDialog({
  hidden,
  onCancel,
  onConfirm,
  categories,
  processType,
  items
}) {
  const { t } = useTranslation();
  const [newType, setNewType] = useState(null);
  const [comboCats, setComboCats] = useState([]);
  const [showResolveOrderDialog, setShowResolveOrderDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState(false);
  const [dialogSubText, setDialogSubText] = useState(false);
  const [showResolveChangeGroupDialog, setShowResolveChangeGroupDialog] = useState(false);
  const [typeGroup, setTypeGroupToUpdate] = useState([]);
  const [moveGroup, setMoveGroup] = useState(false);

  const [showLanguages, setShowLanguages] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [newTypeTranslated, setNewTypeTranslated] = useState(null);
  const [comboCatsTranslated, setComboCatsTranslated] = useState([]);
  const [newTypeChanged, setNewTypeChanged] = useState(null);
  const [closeDialog, setCloseDialog] = useState(false);

  const translateComboOptions = useCallback((comboOptions, language) => {
    const options = comboOptions.map((option) => {
      const translatedValue =
        option?.allNames?.find((x) => x.language === language.key)?.text || option.value;

      return { ...option, text: translatedValue };
    });

    return options;
  }, []);

  const convertToDropDownObject = useCallback(() => {
    return categories.map((category) => {
      return {
        ...category,
        text: category.name,
        key: category.id,
        type: category.type
      };
    });
  }, [categories]);

  useEffect(() => {
    if (processType && !newType) {
      setNewType(processType);
    }
  }, [processType, newType, convertToDropDownObject]);

  useEffect(() => {
    if (categories && !comboCats.length) {
      setComboCats(convertToDropDownObject);
    }
  }, [categories, comboCats, convertToDropDownObject]);

  const translateType = useCallback((type, language) => {
    // translate content to display it in other language than the browser's
    const translatedName =
      type?.allNames?.find((x) => x.language === language.key)?.text || type.name;
    const translatedDescription =
      type?.allDescriptions?.find((x) => x.language === language.key)?.text || type.description;

    const translatedType = {
      ...type,
      name: translatedName,
      description: translatedDescription
    };

    return translatedType;
  }, []);

  useEffect(() => {
    if (
      newType &&
      selectedLanguage &&
      (!newTypeTranslated || newTypeTranslated.lang !== selectedLanguage.key)
    ) {
      const translatedType = translateType(newType, selectedLanguage);

      setNewTypeTranslated({ ...translatedType, lang: selectedLanguage.key });

      if (comboCats?.length) {
        const translatedOptions = translateComboOptions(comboCats, selectedLanguage);
        setComboCatsTranslated(translatedOptions);
      }
    }
  }, [
    newType,
    selectedLanguage,
    newTypeTranslated,
    translateType,
    comboCats,
    translateComboOptions
  ]);

  function close() {
    onCancel();
  }

  async function reorderProcessTypes(type, items, moveGroup, groupItems) {
    let itemsToReorder = [];
    let filteredItems = items.filter((x) => x.id !== type.id);

    if (type.category.type === 2) {
      if (groupItems && groupItems.length > 0) {
        filteredItems = [];
        items.map((item) => {
          let exist = false;
          groupItems.map((groupItem) => {
            if (item.id === groupItem.id) {
              exist = true;
            }
            return null;
          });
          if (!exist) {
            filteredItems.push(item);
          }
          return null;
        });
      }

      const positionOccupied = isPositionOccupied(type, filteredItems);

      if (positionOccupied) {
        itemsToReorder = filteredItems.filter(
          (x) =>
            x.category.type === type.category.type &&
            isBiggerOrEqualProcGrouping(x.processGrouping, type.processGrouping)
        );
        itemsToReorder.sort((a, b) => {
          const aProcGroupingArray = a.processGrouping.split('.');
          const bProcGroupingArray = b.processGrouping.split('.');
          let sizeRelation = 0;
          for (let i = 0; i < aProcGroupingArray.length; i += 1) {
            if (aProcGroupingArray[i] < bProcGroupingArray[i]) {
              sizeRelation = -1;
              break;
            }
            if (aProcGroupingArray[i] > bProcGroupingArray[i]) {
              sizeRelation = 1;
              break;
            }
          }
          return sizeRelation;
        });
      }

      const newProcGroupingArray = type.processGrouping.split('.');

      if (itemsToReorder) {
        return Promise.all(
          itemsToReorder.map(async (item, i) => {
            const typeToEdit = item;
            const existingProcGroupingArray = typeToEdit.processGrouping.split('.');

            const index = newProcGroupingArray.length - 1;
            const currentGroupingValue = existingProcGroupingArray[index];

            let prevGroupingValue = 0;

            if (i > 0) {
              prevGroupingValue = parseInt(
                itemsToReorder[i - 1].processGrouping.split('.')[index],
                10
              ).toString();
            }

            if (
              existingProcGroupingArray.length !== newProcGroupingArray.length &&
              existingProcGroupingArray[0] === newProcGroupingArray[0]
            ) {
              let taken = false;

              do {
                existingProcGroupingArray[0] = parseInt(existingProcGroupingArray[0], 10) + 1;
                typeToEdit.processGrouping = `${existingProcGroupingArray[0]}`;
                if (existingProcGroupingArray.length === 2) {
                  typeToEdit.processGrouping += `.${existingProcGroupingArray[1]}`;
                } else if (existingProcGroupingArray.length === 3) {
                  typeToEdit.processGrouping += `.${existingProcGroupingArray[1]}.${existingProcGroupingArray[2]}`;
                }
                let newFilteredItems = items.filter((x) => x.id !== type.id);
                if (moveGroup) {
                  newFilteredItems = filteredItems;
                }
                taken = isPositionOccupied(typeToEdit, newFilteredItems);
              } while (taken);
            } else if (
              typeToEdit.processGrouping === type.processGrouping ||
              currentGroupingValue === prevGroupingValue
            ) {
              existingProcGroupingArray[index] = parseInt(existingProcGroupingArray[index], 10) + 1;

              typeToEdit.processGrouping = `${existingProcGroupingArray[0]}`;

              if (existingProcGroupingArray.length === 2) {
                typeToEdit.processGrouping += `.${existingProcGroupingArray[1]}`;
              } else if (existingProcGroupingArray.length === 3) {
                typeToEdit.processGrouping += `.${existingProcGroupingArray[1]}.${existingProcGroupingArray[2]}`;
              }
            }

            typeToEdit.sortOrder = 0;

            const result = updateType(typeToEdit, 'PUT');

            return result;
          })
        );
      }

      return null;
    }

    itemsToReorder = items.filter(
      (x) =>
        x.id !== type.id && x.category.type === type.category.type && x.sortOrder >= type.sortOrder
    );

    if (itemsToReorder) {
      return Promise.all(
        itemsToReorder.map(async (item, i) => {
          const typeToEdit = item;

          if (typeToEdit.category && typeToEdit.category.type === 2) {
            typeToEdit.sortOrder = 0;
          } else {
            typeToEdit.processGrouping = '';
          }

          let prevSortOrder = 0;

          if (i > 0) {
            prevSortOrder = itemsToReorder[i - 1].sortOrder;
          }

          if (prevSortOrder === 0 || prevSortOrder === typeToEdit.sortOrder) {
            typeToEdit.sortOrder += 1;
            typeToEdit.processGrouping = null;

            const result = await updateType(typeToEdit, 'PUT');

            return result;
          }

          return null;
        })
      );
    }

    return null;
  }

  async function saveType(toReorderElements, close) {
    const typeToSave = newType;
    if (typeof typeToSave.sortOrder === 'string') {
      typeToSave.sortOrder = parseInt(typeToSave.sortOrder, 10);
    }

    if (toReorderElements) {
      reorderProcessTypes(typeToSave, items, false, null);
    }

    if (typeToSave.category && typeToSave.category.type === 2) {
      typeToSave.sortOrder = 0;
    } else {
      typeToSave.processGrouping = '';
    }

    let method = 'POST';

    if (typeToSave.id) {
      method = 'PUT';
    }

    const result = await updateType(typeToSave, method);

    onConfirm(result, close);
  }

  function verifySortOrder(sortOrder, close) {
    if (newType.category) {
      const element = items.find(
        (x) =>
          x.id !== newType.id &&
          x.category.type === newType.category.type &&
          x.sortOrder === sortOrder
      );
      if (element) {
        setDialogTitle(t('processMapAdministration.resolveSortOrderDialog.title'));
        setDialogSubText(t('processMapAdministration.resolveSortOrderDialog.subText'));
        setShowResolveOrderDialog(true);
      } else {
        saveType(false, close);
      }
    }
  }

  function verifyProcessGrouping(close) {
    if (newType.category) {
      const prevElement = items.find((x) => x.id === processType?.id);
      const prevElProcGroupingArray = prevElement?.processGrouping.split('.');
      const currElProcGroupingArray = newType.processGrouping.split('.');

      let changingGroupElement = false;

      if (
        prevElProcGroupingArray &&
        currElProcGroupingArray &&
        currElProcGroupingArray.length > 1 &&
        prevElProcGroupingArray.length === currElProcGroupingArray.length
      ) {
        let changedOnlyMainGroup = true;
        for (let i = 1; i < prevElProcGroupingArray.length; i += 1) {
          if (prevElProcGroupingArray[i] !== currElProcGroupingArray[i]) {
            changedOnlyMainGroup = false;
            break;
          }
        }

        if (changedOnlyMainGroup) {
          const groupElements = getGroupElements(newType, items);

          if (groupElements && groupElements.length > 1) {
            changingGroupElement = true;
            setTypeGroupToUpdate(groupElements);
            setShowResolveChangeGroupDialog(true);
            setDialogTitle(t('processMapAdministration.resolveChangeGroupDialog.title'));
            setDialogSubText(t('processMapAdministration.resolveChangeGroupDialog.subText'));
          }
        }
      }
      if (!changingGroupElement) {
        setMoveGroup(false);
        continueUpdateCoreElements(close);
      }
    }
  }

  function onConfirmNewType(close) {
    if (
      (!newType.sortOrder || (newType.sortOrder && validateSortOrder(newType.sortOrder))) &&
      (!newType.processGrouping ||
        (newType.processGrouping && validateProcessGrouping(newType.processGrouping)))
    ) {
      if (close) {
        setCloseDialog(close);
      }

      if (newType.sortOrder) {
        verifySortOrder(newType.sortOrder, close);
      }
      if (newType.processGrouping) {
        verifyProcessGrouping(close);
      }
    }
  }

  function getDialogStyles() {
    if (showLanguages) {
      return {
        main: [
          {
            selectors: {
              '@media (min-width: 1150px)': {
                width: '100%',
                minWidth: `${showLanguages ? '1120' : '550'}px`,
                minHeight: '250px'
              },
              '@media (max-width: 1120px)': {
                minHeight: '100%',
                minWidth: '100%'
              }
            }
          }
        ]
      };
    }

    return {
      main: [
        {
          selectors: {
            '@media (min-width: 650px)': {
              width: '100%',
              minWidth: '550px',
              minHeight: '250px'
            },
            '@media (max-width: 480px)': {
              minHeight: '100%',
              minWidth: '100%'
            }
          }
        }
      ]
    };
  }

  function reorderElements() {
    setShowResolveOrderDialog(false);
    if (moveGroup) {
      updateTypeGroup(typeGroup, true);
    } else {
      saveType(true, closeDialog);
    }
  }

  function onCancelResolveOrderDialog() {
    setShowResolveOrderDialog(false);
  }

  function continueUpdateCoreElements(close) {
    const positionOccupied = isPositionOccupied(newType, items);
    if (positionOccupied) {
      setDialogTitle(t('processMapAdministration.resolveProcessGroupingDialog.title'));
      setDialogSubText(t('processMapAdministration.resolveProcessGroupingDialog.subText'));
      setShowResolveOrderDialog(true);
    } else {
      saveType(false, close);
    }
  }

  const updateTypeGroup = async (group, toReorderElements) => {
    const filteredItems = [];

    items.map((item) => {
      let exist = false;

      group.map((groupItem) => {
        if (item.id === groupItem.id) {
          exist = true;
        }
        return null;
      });

      if (!exist) {
        filteredItems.push(item);
      }

      return null;
    });

    await Promise.all(
      group.map(async (type) => {
        const procType = type;

        if (procType.category.type === 2 && validateProcessGrouping(procType.processGrouping)) {
          if (toReorderElements && filteredItems.length > 0) {
            await reorderProcessTypes(procType, items, moveGroup, typeGroup);
          }

          const result = updateType(procType, 'PUT');

          return result;
        }
        return null;
      })
    );

    setTypeGroupToUpdate(null);
    setNewType(newType);

    onConfirm(newType, closeDialog);
  };

  function changeGroupOrder() {
    setShowResolveChangeGroupDialog(false);
    setMoveGroup(true);
    if (typeGroup) {
      const positionOccupied = isPositionOccupied(newType, items);
      if (positionOccupied) {
        setDialogTitle(t('processMapAdministration.resolveProcessGroupingDialog.title'));
        setDialogSubText(t('processMapAdministration.resolveProcessGroupingDialog.subText'));
        setShowResolveOrderDialog(true);
      } else {
        updateTypeGroup(typeGroup, false);
      }
    }
  }

  function onCancelResolveChangeGroupOrder() {
    setShowResolveChangeGroupDialog(false);
    setMoveGroup(false);
    continueUpdateCoreElements();
  }

  const isFormValid = () => {
    let result = false;
    result =
      !!newType &&
      !!newType.name &&
      !!newType.category &&
      ((newType.category.type !== 2 &&
        !!newType.sortOrder &&
        validateSortOrder(newType.sortOrder)) ||
        (newType.category.type === 2 &&
          !!newType.processGrouping &&
          validateProcessGrouping(newType.processGrouping)));
    return result;
  };

  function handleName(value, translation) {
    const currentType = translation ? newTypeTranslated : newType;

    if (currentType?.name !== value) {
      if (!newTypeChanged?.name) {
        setNewTypeChanged((prevState) => ({ ...prevState, name: true }));
      }
    } else if (newTypeChanged?.name) {
      setNewTypeChanged((prevState) => ({ ...prevState, name: false }));
    }

    if (translation) {
      const lang = selectedLanguage?.key;

      const translatedName = {
        language: lang,
        text: value
      };

      let newLang = true;

      const translatedNames = newType.allNames.map((name) => {
        if (name.language === lang) {
          newLang = false;

          return translatedName;
        }

        return name;
      });

      if (newLang) {
        translatedNames.push(translatedName);
      }

      setNewType({ ...newType, allNames: translatedNames });
      setNewTypeTranslated({ ...newTypeTranslated, name: value });
    } else {
      setNewType({ ...newType, name: value });
    }
  }

  function handleDescription(value, translation) {
    const currentType = translation ? newTypeTranslated : newType;

    if (currentType?.description !== value) {
      if (!newTypeChanged?.description) {
        setNewTypeChanged((prevState) => ({ ...prevState, description: true }));
      }
    } else if (newTypeChanged?.description) {
      setNewTypeChanged((prevState) => ({ ...prevState, description: false }));
    }

    if (translation) {
      const lang = selectedLanguage.key;

      const translatedDescription = {
        language: lang,
        text: value
      };

      let newLang = true;
      const translatedDescriptions = newType.allDescriptions.map((description) => {
        if (description.language === lang) {
          newLang = false;

          return translatedDescription;
        }

        return description;
      });

      if (newLang) {
        translatedDescriptions.push(translatedDescription);
      }

      setNewType({ ...newType, allDescriptions: translatedDescriptions });
      setNewTypeTranslated({ ...newTypeTranslated, description: value });
    } else {
      setNewType({ ...newType, description: value });
    }
  }

  function handleCategory(value) {
    if (newType?.category?.id !== value?.id) {
      if (!newTypeChanged?.category) {
        setNewTypeChanged((prevState) => ({ ...prevState, category: true }));
      }
    } else if (newTypeChanged?.category) {
      setNewTypeChanged((prevState) => ({ ...prevState, category: false }));
    }

    const tempType = { ...newType };
    tempType.category = value;

    if (value.type === 1 || value.type === 3) {
      const nextSortOrder = Math.max(...getSortOrderValues(value.type, items)) + 1;
      tempType.sortOrder = nextSortOrder;
      tempType.processGrouping = '';
    } else {
      tempType.sortOrder = '';
    }

    setNewType(tempType);
  }

  function handleSortOrder(value) {
    if (newType?.sortOrder !== value) {
      if (!newTypeChanged?.sortOrder) {
        setNewTypeChanged((prevState) => ({ ...prevState, sortOrder: true }));
      }
    } else if (newTypeChanged?.sortOrder) {
      setNewTypeChanged((prevState) => ({ ...prevState, sortOrder: false }));
    }

    let sortOrder = value;

    if (typeof sortOrder === 'string') {
      sortOrder = parseInt(sortOrder, 10);
    }

    setNewType({ ...newType, sortOrder });
  }

  function handleProcessGrouping(value) {
    if (newType?.processGrouping !== value) {
      if (!newTypeChanged?.processGrouping) {
        setNewTypeChanged((prevState) => ({ ...prevState, processGrouping: true }));
      }
    } else if (newTypeChanged?.processGrouping) {
      setNewTypeChanged((prevState) => ({ ...prevState, processGrouping: false }));
    }

    setNewType({ ...newType, processGrouping: value });
  }

  const translatedLanguages = (passedType) => {
    if (newType?.allNames?.length) {
      const transLang = passedType.allNames.map((name) => {
        return name.language;
      });

      return transLang;
    }

    return null;
  };

  function getTranslationInput(translation) {
    return (
      <TranslationInput>
        {translation ? (
          <div style={{ display: 'flex' }}>
            <Label style={{ marginRight: '5px' }}>
              {t('createProcessMapType.dialog.language.label')}
            </Label>
            <LanguagePicker
              key={`lang-${newType?.allNames?.length || 0}`}
              disabled={!showLanguages}
              placeholder={t('createProcessMapType.dialog.language.placeholder')}
              onChange={setSelectedLanguage}
              styles={{
                root: {
                  width: '200px'
                }
              }}
              translatedLanguages={translatedLanguages(newType)}
              defaultLanguageKey={selectedLanguage?.key}
            />
          </div>
        ) : (
          <TooltipHost
            content={t('createProcessMapType.dialog.translate.tooltip')}
            calloutProps={{ gapSpace: 5, target: '#translate-button' }}
          >
            <IconButton
              id="translate-button"
              iconProps={{ iconName: 'DoubleChevronRight12' }}
              onClick={() => setShowLanguages(true)}
              disabled={showLanguages}
              ariaLabel="Translate"
            />
          </TooltipHost>
        )}
      </TranslationInput>
    );
  }

  function getDialogContent(translation) {
    return (
      <>
        {translation ? <VerticalDivider /> : null}
        <DialogContentStyled>
          {getTranslationInput(translation)}
          <TextField
            key={
              translation && selectedLanguage && newTypeTranslated?.lang === selectedLanguage.key
                ? `translated-name-${selectedLanguage.key}`
                : 'name'
            }
            label={t('createProcessMapType.dialog.name.label')}
            required
            value={translation ? newTypeTranslated?.name : newType?.name}
            onChange={(_, value) => handleName(value, translation)}
            autoFocus
            disabled={translation && !selectedLanguage}
          />
          <TextField
            key={
              translation && selectedLanguage && newTypeTranslated?.lang === selectedLanguage.key
                ? `translated-description-${selectedLanguage.key}`
                : 'description'
            }
            label={t('createProcessMapType.dialog.description.label')}
            value={translation ? newTypeTranslated?.description : newType?.description}
            onChange={(_, value) => handleDescription(value, translation)}
            disabled={translation && !selectedLanguage}
          />
          <ComboBox
            allowFreeform={false}
            disabled={translation}
            autoComplete="on"
            styles={{ container: { width: '100%' } }}
            calloutProps={{ calloutMaxHeight: 250 }}
            label={t('createProcessMapType.dialog.category.label')}
            onChange={(_, value) => handleCategory(value)}
            options={translation ? comboCatsTranslated : comboCats || []}
            placeholder={t('createProcessMapType.dialog.category.placeholder')}
            required
            selectedKey={newType?.category?.id || undefined}
          />
          {newType &&
          newType.category &&
          newType.category.type &&
          (newType.category.type === 1 || newType.category.type === 3) ? (
            <TextField
              disabled={
                !(newType?.category?.type === 1 || newType?.category?.type === 3) || translation
              }
              label={t('createProcessMapType.dialog.sortOrder.label')}
              autoComplete="off"
              required
              value={newType?.sortOrder || ''}
              onGetErrorMessage={(value) => getSortOrderErrorMessage(value, t)}
              onChange={(_, value) => handleSortOrder(value)}
            />
          ) : null}
          {newType && newType.category && newType.category.type && newType.category.type === 2 ? (
            <TextField
              disabled={newType?.category?.type !== 2 || translation}
              label={t('createProcessMapType.dialog.processGrouping.label')}
              autoComplete="off"
              required
              placeholder={t('createProcessMapType.dialog.processGrouping.placeholder')}
              value={newType?.processGrouping || ''}
              onGetErrorMessage={(value) => getProcGroupingErrorMessage(value, t)}
              onChange={(_, value) => handleProcessGrouping(value)}
            />
          ) : null}
        </DialogContentStyled>
      </>
    );
  }

  function getPrimaryButton() {
    const thereAreChanges = newTypeChanged
      ? !!Object.keys(newTypeChanged)?.find((key) => newTypeChanged[key] === true)
      : 0;

    return (
      <PrimaryButton
        text={t('createProcessMapType.dialog.button.saveAndClose')}
        split
        menuProps={{
          items: [
            {
              key: 'save',
              text: t('createProcessMapType.dialog.button.save'),
              onClick: () => onConfirmNewType(false)
            }
          ]
        }}
        onClick={() => onConfirmNewType(true)}
        disabled={!isFormValid() || !thereAreChanges}
      />
    );
  }

  return (
    <div>
      <Dialog
        hidden={hidden}
        onDismiss={() => close()}
        styles={getDialogStyles}
        dialogContentProps={{
          type: DialogType.close,
          title:
            processType && processType.id
              ? t('createProcessMapType.dialog.titleEdit')
              : t('createProcessMapType.dialog.title')
        }}
        modalProps={{
          isBlocking: true
        }}
      >
        <DialogInputContent>
          {getDialogContent()}
          {showLanguages ? getDialogContent(showLanguages) : null}
        </DialogInputContent>
        <DialogFooter>
          {getPrimaryButton()}
          <DefaultButton
            onClick={() => close()}
            text={t('createProcessMapType.dialog.button.cancel')}
          />
        </DialogFooter>
      </Dialog>
      {showResolveOrderDialog ? (
        <ResolveOrderDialog
          title={dialogTitle}
          subText={dialogSubText}
          onAccept={reorderElements}
          onDismiss={onCancelResolveOrderDialog}
          t={t}
        />
      ) : null}
      {showResolveChangeGroupDialog ? (
        <ResolveChangeGroupDialog
          title={dialogTitle}
          subText={dialogSubText}
          onAccept={changeGroupOrder}
          onDismiss={onCancelResolveChangeGroupOrder}
          t={t}
        />
      ) : null}
    </div>
  );
}

export default CreateProcessMapTypeDialog;

CreateProcessMapTypeDialog.propTypes = {
  hidden: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(processCategoryPropType).isRequired,
  processType: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    sortOrder: PropTypes.number,
    processGrouping: PropTypes.string,
    category: processCategoryPropType,
    allNames: PropTypes.arrayOf(PropTypes.object),
    allDescriptions: PropTypes.arrayOf(PropTypes.object)
  }),
  items: PropTypes.arrayOf(PropTypes.object).isRequired
};

CreateProcessMapTypeDialog.defaultProps = {
  hidden: true,
  processType: null
};

export async function updateType(type, method) {
  const body = JSON.stringify(type);

  const newCreatedType = await fetchRequest({
    url: `ProcessType`,
    method,
    body
  }).catch(apiErrorHandler);

  return method === 'PUT' ? type : newCreatedType;
}
