import { t } from 'i18next';
import { Checkbox } from '@fluentui/react';
import { IFormProps } from './FieldForm';

function LongTextSettings({ field, setField, disabled }: IFormProps) {
  function updateLongText(_?: unknown, checked?: boolean | undefined) {
    setField((prevState) => {
      return { ...prevState, appendChanges: checked };
    });
  }

  return (
    <div>
      <Checkbox
        key={`appendChanges-${field?.fieldType}-${field?.appendChanges}`}
        label={t('createField.dialog.appendChanges.checkbox.label')}
        defaultChecked={field.appendChanges}
        onChange={updateLongText}
        styles={{ root: { marginTop: '15px' } }}
        disabled={disabled}
      />
    </div>
  );
}
export default LongTextSettings;
