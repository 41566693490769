import {
  ChatConnectionStatus,
  ChatFileStatus,
  ChatHistoryStatus,
  IChatItem,
  IChatItemAssistant
} from './model';
import { IChat, IMessage, IMessageAI, IMessageUser, LocalApiObject } from '../../hooks/api2';

/** Types of events on chat backends */
export type ChatBackendEventMap = {
  disconnect: { error?: Error };
  connectionStatusChange: { status: ChatConnectionStatus };

  chatUpdate: { chat: LocalApiObject<IChat> };
  fileUpdate: { id: string; status: ChatFileStatus };
  fileCompleted: { id: string };

  remoteMessageUpdate: { message: IMessage };
  remoteMessageCompleted: { message: IMessage };

  itemsChange: { items: Readonly<IChatItem[]> };

  historyLoadStarted: null;
  historyLoadCompleted: null;
  historyLoadError: { error: Error };
};

/**
 * A chat backend handles the connection to the server and manages chat history.
 */
export default abstract class ChatBackend {
  #connectionStatus = ChatConnectionStatus.NotConnected;

  historyStatus = ChatHistoryStatus.NotReady;

  #items: Readonly<IChatItem[]> = [];

  get connectionStatus() {
    return this.#connectionStatus;
  }

  protected set connectionStatus(status: ChatConnectionStatus) {
    if (status === this.#connectionStatus) return;
    this.#connectionStatus = status;
    this.dispatchEvent('connectionStatusChange', { status });
  }

  get items(): Readonly<IChatItem[]> {
    return this.#items;
  }

  protected set items(items: Readonly<IChatItem[]>) {
    this.#items = items;
    this.dispatchEvent('itemsChange', { items: this.items });
  }

  private eventListeners = new Map<keyof ChatBackendEventMap, Set<(event: unknown) => void>>();

  addEventListener<E extends keyof ChatBackendEventMap>(
    event: E,
    listener: (event: ChatBackendEventMap[E]) => void
  ) {
    if (!this.eventListeners.has(event)) {
      this.eventListeners.set(event, new Set());
    }
    this.eventListeners.get(event).add(listener);
  }

  protected dispatchEvent<E extends keyof ChatBackendEventMap>(
    event: E,
    data: ChatBackendEventMap[E]
  ) {
    const listeners = this.eventListeners.get(event);
    if (!listeners) return;
    for (const listener of listeners) listener(data);
  }

  removeEventListener<E extends keyof ChatBackendEventMap>(
    event: E,
    listener: (event: ChatBackendEventMap[E]) => void
  ) {
    this.eventListeners.get(event)?.delete(listener);
    if (!this.eventListeners.get(event)?.size) {
      this.eventListeners.delete(event);
    }
  }

  abstract appendUserMessage(message: IMessageUser): void;

  abstract receiveCompleteAssistantMessage(message: IMessageAI): void;

  abstract canRetryAssistantMessage(item: IChatItemAssistant): boolean;

  abstract retryAssistantMessage(item: IChatItemAssistant): Promise<void>;

  /** This method must be called before you stop using this object. */
  abstract close(): void;
}
