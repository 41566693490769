import { CombinedPickerJS as CombinedPicker, DateTime } from 'components';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import fetchRequest, { apiErrorHandler } from 'services/api';
import styled from 'styled-components';
import breakpoints from 'utils/breakpoints';
import { getEmployeesTypes, getIndustryTypes } from 'utils/helpers';
import {
  Checkbox,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  Label,
  PrimaryButton,
  Stack,
  TextField
} from '@fluentui/react';

const DevidedRow = styled.div`
  @media (min-width: ${breakpoints.smallMin}px) {
    display: flex;
    justify-content: space-between;

    .left-half {
      max-width: 50%;
      padding-right: 0.25rem;
    }

    .right-half {
      padding-left: 0.25rem;
      max-width: 50%;
    }
  }
`;

function CreateTenantDialog({ hidden, onCancel, onConfirm, tenant }) {
  const { t } = useTranslation();
  const [newTenant, setNewTenant] = useState(null);
  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(true);
  const [invalidEmailMessage, setInvalidEmailMessage] = useState(null);
  const [invalidIdMessage, setInvalidIdMessage] = useState(null);
  const [invalidLicensedUsersMessage, setInvalidLicensedUsersMessage] = useState(null);
  const [invalidSubscrPeriodMessage, setInvalidSubscrPeriodMessage] = useState(null);
  const [technicalContact, setTechnicalContact] = useState(null);
  const [commercialContact, setCommercialContact] = useState(null);

  const tenantStatusOption = getTenantStatuses(t);
  tenantStatusOption.splice(2, 1);

  const industryTypes = getIndustryTypes();

  useEffect(() => {
    setNewTenant(tenant);
    setTechnicalContact(tenant?.technicalContact);
    setCommercialContact(tenant?.commercialContact);
  }, [tenant]);

  useEffect(() => {
    const detectValidChanges = () => {
      if (tenant?.name) {
        if (
          (tenant.name !== newTenant?.name ||
            tenant?.tenantId !== newTenant?.tenantId ||
            tenant?.adminLogin !== newTenant?.adminLogin ||
            tenant?.endDateTestPeriod1 !== newTenant?.endDateTestPeriod1 ||
            tenant?.subscriptionStartDate !== newTenant?.subscriptionStartDate ||
            tenant?.licensedUsers !== newTenant?.licensedUsers ||
            tenant?.subscriptionMonths !== newTenant?.subscriptionMonths ||
            tenant?.industry !== newTenant?.industry ||
            tenant?.employees !== newTenant?.employees ||
            tenant?.status !== newTenant?.status ||
            tenant?.technicalContact?.userId !== technicalContact?.userId ||
            tenant?.useProcesses !== newTenant?.useProcesses ||
            tenant?.useProjects !== newTenant?.useProjects ||
            tenant?.features?.useAutoTagging !== newTenant?.features?.useAutoTagging ||
            tenant?.features?.processesFromTaskManagement !==
              newTenant?.features?.processesFromTaskManagement ||
            tenant?.features?.useAIField !== newTenant?.features?.useAIField ||
            tenant?.commercialContact?.userId !== commercialContact?.userId) &&
          !invalidIdMessage &&
          !invalidEmailMessage &&
          !invalidLicensedUsersMessage &&
          !invalidSubscrPeriodMessage
        ) {
          return true;
        }

        return false;
      }

      if (
        newTenant?.name &&
        (!newTenant?.tenantId || !invalidIdMessage) &&
        (!newTenant?.adminLogin || !invalidEmailMessage)
      ) {
        return true;
      }

      return false;
    };

    setIsPrimaryButtonDisabled(!detectValidChanges());
  }, [
    newTenant,
    tenant,
    invalidIdMessage,
    invalidEmailMessage,
    invalidLicensedUsersMessage,
    invalidSubscrPeriodMessage,
    technicalContact,
    commercialContact
  ]);

  function close() {
    onCancel();
  }

  function handleName(value) {
    const tempTenant = { ...newTenant, name: value };

    setNewTenant(tempTenant);
  }

  function handleId(value) {
    let isValid = false;

    if (!value) {
      isValid = true;
    } else {
      const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

      isValid = regex.test(value);
    }

    const tenant = { ...newTenant, directoryId: value };

    if (!isValid) {
      if (!invalidIdMessage) {
        setInvalidIdMessage(t('createTenant.dialog.errorMessage.id'));
      }
    } else if (invalidIdMessage) {
      setInvalidIdMessage(null);
    }

    setNewTenant(tenant);
  }

  function handleResponsible(value) {
    let isValid = false;

    if (!value) {
      isValid = true;
    } else {
      const regex =
        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      isValid = regex.test(value);
    }

    const tenant = { ...newTenant, adminLogin: value };

    if (!isValid) {
      if (!invalidEmailMessage) {
        setInvalidEmailMessage(t('createTenant.dialog.errorMessage.responsible'));
      }
    } else if (invalidEmailMessage) {
      setInvalidEmailMessage(null);
    }

    setNewTenant(tenant);
  }

  function handleTestPeriod(value) {
    const tenant = { ...newTenant, endDateTestPeriod1: value };
    setNewTenant(tenant);
  }

  function handleSubscriptionStartDate(value) {
    const tenant = { ...newTenant, subscriptionStartDate: value };
    setNewTenant(tenant);
  }

  function handleLicensedUsers(value) {
    let isValid = false;

    if (!value) {
      isValid = true;
    } else {
      const regex = /^[0-9]*$/;

      isValid = regex.test(value);
    }

    const tenant = { ...newTenant, licensedUsers: value };

    if (!isValid) {
      if (!invalidLicensedUsersMessage) {
        setInvalidLicensedUsersMessage(t('createTenant.dialog.errorMessage.licensedUsers'));
      }
    } else if (invalidLicensedUsersMessage) {
      setInvalidLicensedUsersMessage(null);
    }

    setNewTenant(tenant);
  }

  function handleSubscriptionMonths(value) {
    let isValid = false;

    if (!value) {
      isValid = true;
    } else {
      const regex = /^[0-9]*$/;

      isValid = regex.test(value);
    }

    const tenant = { ...newTenant, subscriptionMonths: value };

    if (!isValid) {
      if (!invalidSubscrPeriodMessage) {
        setInvalidSubscrPeriodMessage(t('createTenant.dialog.errorMessage.subscriptionMonths'));
      }
    } else if (invalidSubscrPeriodMessage) {
      setInvalidSubscrPeriodMessage(null);
    }

    setNewTenant(tenant);
  }

  function saveTenant() {
    setIsPrimaryButtonDisabled(true);
    const body = JSON.stringify({ ...newTenant, technicalContact, commercialContact });
    const url = tenant?.name ? 'Admin/UpdateTenant' : 'Admin/CreateTenant';
    const method = tenant?.name ? 'PUT' : 'POST';

    fetchRequest({
      url,
      method,
      body
    })
      .then((tenantFromDB) => {
        onConfirm(tenantFromDB);
      })
      .catch((errResponse) => {
        return apiErrorHandler(errResponse);
      });
  }

  function getDialogStyles() {
    return {
      main: [
        {
          selectors: {
            '@media (min-width: 650px)': {
              width: '100%',
              minWidth: '550px',
              minHeight: '200px'
            },
            '@media (max-width: 480px)': {
              minHeight: '100%',
              minWidth: '100%'
            }
          }
        }
      ]
    };
  }

  function handleKeyPress(e) {
    if (e.key === 'Enter' && !isPrimaryButtonDisabled) {
      saveTenant();
    }
  }

  function handleIndustry(key) {
    const tenant = { ...newTenant, industry: key };
    setNewTenant(tenant);
  }

  function handleEmployees(key) {
    const tenant = { ...newTenant, employees: key };
    setNewTenant(tenant);
  }

  function handleStatus(key) {
    const tenant = { ...newTenant, status: key };
    setNewTenant(tenant);
  }

  function handleTechnicalContact(value) {
    setTechnicalContact(value[0]);
  }

  function handleCommercialContact(value) {
    setCommercialContact(value[0]);
  }

  function handleUseProcesses(checked) {
    const tenant = { ...newTenant, useProcesses: checked };
    setNewTenant(tenant);
  }

  function handleUseProjects(checked) {
    const tenant = { ...newTenant, useProjects: checked };
    setNewTenant(tenant);
  }

  function handleUseAutoTagging(checked) {
    setNewTenant((prevState) => ({
      ...prevState,
      features: {
        ...prevState.features,
        useAutoTagging: checked
      }
    }));
  }

  function handleStartableInTasksContainer(checked) {
    setNewTenant((prevState) => ({
      ...prevState,
      features: {
        ...prevState.features,
        processesFromTaskManagement: checked
      }
    }));
  }

  function handleUseAIField(checked) {
    setNewTenant((prevState) => ({
      ...prevState,
      features: {
        ...prevState.features,
        useAIField: checked
      }
    }));
  }

  return (
    <Dialog
      hidden={hidden}
      onDismiss={close}
      styles={getDialogStyles}
      dialogContentProps={{
        type: DialogType.close,
        title: tenant?.name ? t('createTenant.dialog.titleEdit') : t('createTenant.dialog.title')
      }}
      modalProps={{
        isBlocking: true
      }}
    >
      <div style={{ padding: '1rem', width: '100%', maxHeight: 500, overflowY: 'scroll' }}>
        <div style={{ marginBottom: '1rem' }}>
          <TextField
            label={t('createTenant.dialog.name.label')}
            required
            defaultValue={newTenant?.name || ''}
            onChange={(_, value) => handleName(value)}
            autoFocus
          />
        </div>
        {tenant?.name ? (
          <>
            <TextField
              label={t('createTenant.dialog.directoryId.label')}
              defaultValue={newTenant?.tenantId}
              disabled
              errorMessage={invalidIdMessage}
            />
            <TextField
              label="Directory Id"
              defaultValue={newTenant?.directoryId || ''}
              onChange={(_, value) => handleId(value)}
              errorMessage={invalidIdMessage}
            />
            <TextField
              label={t('createTenant.dialog.label.responsible')}
              defaultValue={newTenant?.adminLogin || ''}
              onChange={(_, value) => handleResponsible(value)}
              errorMessage={invalidEmailMessage}
            />
            <DevidedRow className="mt-1">
              <DateTime
                label={t('createTenant.dialog.label.endDateTestPeriod1')}
                onSelectDate={handleTestPeriod}
                value={newTenant?.endDateTestPeriod1}
              />
              <div className="right-half half-width">
                <DateTime
                  label={t('createTenant.dialog.label.subscriptionStartDate')}
                  onSelectDate={handleSubscriptionStartDate}
                  value={newTenant?.subscriptionStartDate}
                />
              </div>
            </DevidedRow>
            <DevidedRow className="mt-1">
              <div className="left-half half-width">
                <TextField
                  label={t('createTenant.dialog.label.licensedUsers')}
                  defaultValue={newTenant?.licensedUsers}
                  onChange={(_, value) => handleLicensedUsers(value)}
                  errorMessage={invalidLicensedUsersMessage}
                />
              </div>
              <div className="right-half half-width">
                <TextField
                  label={t('createTenant.dialog.label.subscriptionMonths')}
                  defaultValue={newTenant?.subscriptionMonths}
                  onChange={(_, value) => handleSubscriptionMonths(value)}
                  onKeyPress={handleKeyPress}
                  errorMessage={invalidSubscrPeriodMessage}
                />
              </div>
            </DevidedRow>
            <DevidedRow className="mt-1">
              <div className="left-half half-width">
                <Label>{t('createTenant.dialog.label.technicalContact')}</Label>
                <CombinedPicker
                  searchUser
                  selectedItems={technicalContact}
                  onChange={handleTechnicalContact}
                  type={9}
                />
              </div>
              <div className="right-half half-width">
                <Label>{t('createTenant.dialog.label.commercialContact')}</Label>
                <CombinedPicker
                  searchUser
                  selectedItems={commercialContact}
                  onChange={handleCommercialContact}
                  type={9}
                />
              </div>
            </DevidedRow>
            <DevidedRow className="mt-1">
              <div className="left-half half-width">
                <Dropdown
                  placeholder={t('createTenant.dialog.placeholder.status')}
                  label={t('createTenant.dialog.label.status')}
                  defaultSelectedKey={newTenant?.status}
                  onChange={(_, value) => handleStatus(value.key)}
                  options={tenantStatusOption}
                />
              </div>
              <div className="right-half half-width">
                <Dropdown
                  placeholder={t('createTenant.dialog.placeholder.employees')}
                  label={t('createTenant.dialog.label.employees')}
                  defaultSelectedKey={newTenant?.employees}
                  onChange={(_, value) => handleEmployees(value.key)}
                  options={getEmployeesTypes(t)}
                />
              </div>
            </DevidedRow>
            <div>
              <Dropdown
                placeholder={t('createTenant.dialog.placeholder.industry')}
                label={t('createTenant.dialog.label.industry')}
                defaultSelectedKey={newTenant?.industry}
                onChange={(_, value) => handleIndustry(value.key)}
                options={industryTypes}
              />
            </div>
            <div style={{ margin: '10px 0' }}>
              <Label>{t('createTenant.dialog.label.plans')}</Label>
              <Stack tokens={{ childrenGap: 10 }}>
                <Checkbox
                  label={t('createTenant.dialog.label.useProcesses')}
                  defaultChecked={newTenant?.useProcesses}
                  onChange={(_, value) => handleUseProcesses(value)}
                />
                <Checkbox
                  label={t('createTenant.dialog.label.useProjects')}
                  defaultChecked={newTenant?.useProjects}
                  onChange={(_, value) => handleUseProjects(value)}
                />
                <Checkbox
                  label={t('createTenant.dialog.label.useAutoTagging')}
                  defaultChecked={newTenant?.features?.useAutoTagging}
                  onChange={(_, value) => handleUseAutoTagging(value)}
                />
                <Checkbox
                  label={t('create.route.template.settings.startableInTasksContainer')}
                  defaultChecked={newTenant?.features?.processesFromTaskManagement}
                  onChange={(_, value) => handleStartableInTasksContainer(value)}
                />
                <Checkbox
                  label={t('createTenant.dialog.label.useAIField')}
                  defaultChecked={newTenant?.features?.useAIField}
                  onChange={(_, value) => handleUseAIField(value)}
                />
              </Stack>
            </div>
          </>
        ) : (
          <div style={{ marginBottom: '1rem' }}>
            <TextField
              label={t('createTenant.dialog.label.responsible')}
              defaultValue={newTenant?.adminLogin || ''}
              onChange={(_, value) => handleResponsible(value)}
              errorMessage={invalidEmailMessage}
            />
          </div>
        )}
      </div>
      <DialogFooter>
        <PrimaryButton
          disabled={isPrimaryButtonDisabled}
          onClick={saveTenant}
          text={t('createTenant.dialog.button.save')}
        />
        <DefaultButton onClick={close} text={t('createTenant.dialog.button.cancel')} />
      </DialogFooter>
    </Dialog>
  );
}

CreateTenantDialog.propTypes = {
  hidden: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  tenant: PropTypes.shape({
    tenantId: PropTypes.string,
    name: PropTypes.string,
    directoryId: PropTypes.string,
    adminLogin: PropTypes.string,
    endDateTestPeriod1: PropTypes.instanceOf(Date),
    endDateTestPeriod2: PropTypes.instanceOf(Date),
    subscriptionStartDate: PropTypes.instanceOf(Date),
    licensedUsers: PropTypes.number,
    subscriptionMonths: PropTypes.number,
    technicalContact: PropTypes.object,
    commercialContact: PropTypes.object,
    industry: PropTypes.number,
    employees: PropTypes.number,
    status: PropTypes.number,
    useProcesses: PropTypes.bool,
    features: PropTypes.shape({
      useAutoTagging: PropTypes.bool,
      useAIField: PropTypes.bool,
      processesFromTaskManagement: PropTypes.bool
    }),
    useProjects: PropTypes.bool
  })
};

CreateTenantDialog.defaultProps = {
  hidden: true,
  tenant: null
};

export default CreateTenantDialog;

export function getTenantStatuses(t) {
  const licenceStatuses = [
    {
      key: 0,
      text: t('tenantsAdministration.status.unknown')
    },
    {
      key: 1,
      text: t('tenantsAdministration.status.firstTest')
    },
    {
      key: 2,
      text: t('tenantsAdministration.status.extendedTest')
    },
    {
      key: 3,
      text: t('tenantsAdministration.status.customer')
    },
    {
      key: 4,
      text: t('tenantsAdministration.status.formerCustomer')
    },
    {
      key: 5,
      text: t('tenantsAdministration.status.locked')
    }
  ];

  return licenceStatuses;
}
