import { Label } from 'components';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import fetchRequest from 'services/api';
import { IProcessDefinitionProps, IProcessInstanceProps, RouteFieldType } from 'types';
import { Checkbox, TagPicker } from '@fluentui/react';
import DataFieldFilterRow from './DataFieldFilterRow';

export default function MsTeamsProcessConfig({ config, setConfig }) {
  const { t } = useTranslation();

  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectedProcess, setSelectedProcess] = useState(null);

  // all data fields that are shown in list
  const [processDataFields, setProcessDataFields] = useState<IProcessDefinitionProps['dataFields']>(
    []
  );
  // selected data fields that can be used to filter the list
  const selectedProcessFields = useMemo(
    () => config.selectedProcess?.filter?.map((f) => ({ ...f, id: f.fieldId })),
    [config.selectedProcess?.filter]
  );

  function getProcessDataFields(template: IProcessDefinitionProps) {
    if (template?.dataFields?.length) {
      return template.dataFields.filter(
        (fieldLink) =>
          // custom template fields which are shown in list
          fieldLink?.field?.id !== '00000000-0000-0000-0000-000000000000' &&
          fieldLink.showInList &&
          fieldLink.field?.fieldType !== RouteFieldType.FieldGroup &&
          fieldLink.field?.fieldType !== RouteFieldType.LongText &&
          fieldLink.field?.fieldType !== RouteFieldType.Hyperlink &&
          fieldLink.field?.fieldType !== RouteFieldType.Document &&
          fieldLink.field?.fieldType !== RouteFieldType.Signature &&
          fieldLink.field?.fieldType !== RouteFieldType.Scanner &&
          fieldLink.field?.fieldType !== RouteFieldType.Multimedia &&
          fieldLink.field?.fieldType !== RouteFieldType.AI &&
          fieldLink.field?.fieldType !== RouteFieldType.Location
      );
    }

    return [];
  }

  function handleTemplateResponse(template: IProcessDefinitionProps) {
    setSelectedProcess(template);
    setLoading(false);

    const dataFields = getProcessDataFields(template);
    setProcessDataFields(dataFields);

    return template;
  }

  function getProcessTemplate(id: string) {
    setLoading(true);
    return fetchRequest({ url: `Route/Definition/${id}` })
      .then(handleTemplateResponse)
      .catch(() => setLoading(false));
  }

  useEffect(() => {
    if (config.selectedProcess && !initialized) {
      setInitialized(true);
      getProcessTemplate(config.selectedProcess.id);
    } else if (!initialized) {
      setInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config.selectedProcess, initialized]);

  function onResolveProcessses(searchTerm) {
    if (searchTerm) {
      return fetchRequest({
        url: `Route/Definitions?pageIndex=0&itemsPerPage=200&status=0&type=0&searchTerms=${searchTerm}`
      }).then((response) => response.items);
    }

    return undefined;
  }

  function onProcessChange(processes) {
    if (processes.length > 0) {
      const process = processes[0] as unknown as IProcessInstanceProps;
      setSelectedProcess(process);
      setConfig((prevState) => ({
        ...prevState,
        showProjects: false,
        showTasks: false,
        selectedProject: null,
        selectedTeam: null,
        selectedProcess: { id: process.id, name: process.name }
      }));
      setProcessDataFields(getProcessDataFields(process));
    } else {
      setConfig((prevState) => ({ ...prevState, selectedProcess: null }));
      setSelectedProcess(null);
      setProcessDataFields([]);
    }
  }

  function onFieldSelection(field) {
    setConfig((prevState) => ({
      ...prevState,
      selectedProcess: {
        ...prevState.selectedProcess,
        filter: [{ fieldId: field?.id, value: null, fieldType: field?.fieldType }]
      }
    }));
  }

  function renderDataFieldsFilter() {
    return (
      <DataFieldFilterRow
        key={selectedProcess?.id}
        selectableFields={processDataFields}
        disabled={!config.showProcesses}
        loading={loading}
        fieldId={selectedProcessFields?.[0]?.id}
        onFieldSelection={onFieldSelection}
        onFilterValueChange={({ value }) => {
          setConfig((prevState) => ({
            ...prevState,
            selectedProcess: {
              ...prevState.selectedProcess,
              filter: [
                {
                  fieldId: selectedProcessFields?.[0]?.id,
                  value,
                  fieldType: selectedProcessFields?.[0]?.fieldType
                }
              ]
            }
          }));
        }}
        filterValue={selectedProcessFields?.[0]?.value}
      />
    );
  }

  return (
    <>
      <Checkbox
        // @ts-expect-error: text prop allows JSX
        label={
          <div>
            <span style={{ fontWeight: 600 }}>{t('msTeams.config.checkBox.label.processes')}</span>
            <div style={{ maxWidth: 500 }}>{t('msTeams.config.checkBox.subtext.processes')}</div>
          </div>
        }
        checked={config.showProcesses}
        disabled={config.selectedProject || config.selectedTeam}
        styles={{ root: { marginTop: '15px' } }}
        onChange={(_, checked) => {
          setConfig((prevState) => ({
            ...prevState,
            showProcesses: checked,
            showTagList: false,
            selectedProcess: null
          }));
          setProcessDataFields([]);
        }}
      />
      <Label
        styles={{ container: { marginTop: 5, marginLeft: 23, maxWidth: 505 } }}
        label={t('msTeams.config.processPicker.label')}
        description={t('msTeams.config.processPicker.description')}
      />
      <TagPicker
        resolveDelay={500}
        inputProps={{ placeholder: t('msTeams.config.processPicker.placeholder') }}
        onResolveSuggestions={onResolveProcessses}
        getTextFromItem={(item) => item.name}
        disabled={!config.showProcesses}
        pickerSuggestionsProps={{
          noResultsFoundText: t('msTeams.config.processPicker.noResultsFoundText')
        }}
        selectedItems={config.selectedProcess ? [config.selectedProcess] : []}
        itemLimit={1}
        onChange={onProcessChange}
        styles={{ root: { maxWidth: 500, marginLeft: 28 } }}
      />
      {renderDataFieldsFilter()}
    </>
  );
}
