import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { adjustColor, colorsForDashboards } from 'utils/helpers';
import { getTheme } from '@fluentui/react';

const DiagramContainerStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;

  .no-tasks-label {
    position: absolute;
    top: 130px;
    left: 80px;
    padding: 20px;
    color: grey;
    border: 1px solid grey;
  }
`;

const TriangleStyled = styled.g`
  transition: all 0.3s ease-in-out;

  polygon {
    stroke-width: 1px;
  }

  .back-triangle {
    fill: ${(props) =>
      props.triangleVal > 0 ? adjustColor(getTheme().palette.themePrimary, 60) : null};
    stroke: ${(props) => (props.$triangleVal > 0 ? getTheme().palette.themePrimary : null)};

    :hover {
      fill: ${(props) =>
        props.triangleVal > 0 ? adjustColor(getTheme().palette.themePrimary, 10) : null};
    }
  }

  .front-triangle {
    fill: ${(props) => (props.$triangleVal > 0 ? getTheme().palette.themePrimary : null)};

    stroke: ${(props) =>
      props.triangleVal > 0 ? adjustColor(getTheme().palette.themePrimary, -30) : null};

    :hover {
      fill: ${(props) =>
        props.triangleVal > 0 ? adjustColor(getTheme().palette.themePrimary, -40) : null};
    }
  }

  polygon:hover {
    cursor: ${(props) => (props.$triangleVal > 0 && props.$clickable ? 'pointer' : 'default')};
  }

  .triangleText:hover {
    cursor: ${(props) => (props.$triangleVal > 0 && props.$clickable ? 'pointer' : 'default')};
    font-size: ${(props) => {
      if (props.$triangleVal > 0) return props.$chartWidth < 950 ? '0.8em' : '1.2em';
      return null;
    }};
    font-weight: ${(props) => (props.$triangleVal > 0 ? 'bold' : 'normal')};
  }

  .focusedTriangle.front-triangle {
    fill: ${(props) =>
      props.$triangleVal > 0 ? adjustColor(getTheme().palette.themePrimary, -40) : null};
  }

  .focusedTriangle.back-triangle {
    fill: ${(props) =>
      props.$triangleVal > 0 ? adjustColor(getTheme().palette.themePrimary, 10) : null};
  }

  polygon:hover ~ .triangleText {
    font-size: ${(props) => {
      if (props.$triangleVal > 0) return props.$chartWidth < 950 ? '0.8em' : '1.2em';
      return null;
    }};
    font-weight: ${(props) => (props.$triangleVal > 0 ? 'bold' : 'normal')};
  }

  polygon:hover ~ .xAxisText {
    font-size: ${(props) => {
      if (props.$triangleVal > 0) return props.$chartWidth < 950 ? '0.8em' : '1.2em';
      return null;
    }};
    font-weight: ${(props) => (props.$triangleVal > 0 ? 'bold' : 'normal')};
  }
`;

const DiagramStyled = styled.div`
  margin: 0 1em 1em 0;

  .axis {
    font-size: ${(props) => (props.$chartWidth < 950 ? '0.7em' : '1em')};
  }

  .axis path,
  .axis line {
    fill: none;
    stroke: #000;
    shape-rendering: crispEdges;
  }

  .chart-triangle {
    transition: all 0.3s ease-in-out;
  }

  .triangleText {
    font-size: ${(props) => (props.$chartWidth < 950 ? '0.7em' : '1em')};
    transition: all 0.3s ease-in-out;
  }
`;

function BurnChart({
  showText,
  showTooltip,
  chartValues,
  onSelectedChartGroup,
  yAxisTitle,
  chartHeight,
  chartWidth,
  noTasksLabel
}) {
  const chartStartX = 0;
  const chartStartY = 0;
  const maxChartWidth = chartWidth || 950;
  const maxChartHeight = chartHeight || 650;
  const yAxisLength = maxChartHeight - 25;
  const xAxisLength = maxChartWidth - (maxChartWidth < 950 ? 150 : 200);
  const barColors = colorsForDashboards();

  const { t } = useTranslation();

  let totalAmount = 0;
  chartValues.map((value) => {
    totalAmount += value.number;
    return totalAmount;
  });

  function getXAxis() {
    return (
      <g className="x axis" transform={`translate(0,${yAxisLength})`} key="burn-x-axis">
        <path className="domain" d={`M0,6V0H${xAxisLength}V6`} />
      </g>
    );
  }

  function getYAxisValues() {
    const maxValue = chartValues[0].number + chartValues[1].number;

    if (maxValue > 0) {
      let stepValue = 1;
      if (maxValue > 10000) {
        stepValue = 5000;
      } else if (maxValue > 5000) {
        stepValue = 1000;
      } else if (maxValue > 2000) {
        stepValue = 500;
      } else if (maxValue > 1000) {
        stepValue = 200;
      } else if (maxValue > 500) {
        stepValue = 100;
      } else if (maxValue > 200) {
        stepValue = 50;
      } else if (maxValue > 100) {
        stepValue = 20;
      } else if (maxValue > 50) {
        stepValue = 10;
      } else if (maxValue > 20) {
        stepValue = 5;
      } else if (maxValue > 10) {
        stepValue = 2;
      }

      const maxTopRoundedValue = Math.ceil(maxValue / stepValue) * stepValue;
      const numberOfSteps = maxTopRoundedValue / stepValue;
      const stepHeight = (yAxisLength - 20) / numberOfSteps;

      return {
        stepValue,
        maxTopRoundedValue,
        stepHeight,
        numberOfSteps
      };
    }

    return {
      stepValue: 1,
      maxTopRoundedValue: 0,
      stepHeight: 0,
      numberOfSteps: 0
    };
  }

  function getYAxis() {
    const y = getYAxisValues();

    const yAxis = [];

    for (let i = 0; i < y.numberOfSteps + 1; i += 1) {
      const yTranslation = yAxisLength - i * y.stepHeight;

      yAxis.push(
        <g
          className="tick"
          transform={`translate(0,${yTranslation})`}
          style={{ opacity: 1 }}
          key={`burn-y-tick-${i}`}
        >
          <line x2="-6" y2="0" />
          <text dy=".32em" x="-9" y="0" style={{ textAnchor: 'end' }}>
            {y.stepValue * i}
          </text>
        </g>
      );
    }

    return (
      <g className="y axis" key="burn-y-axis">
        {yAxis}
        <path className="domain" d={`M-6,0H0V${yAxisLength}H-6`} />
        <text x="-8" y="-15" dy=".71em" style={{ textAnchor: 'start' }}>
          {yAxisTitle}
        </text>
      </g>
    );
  }

  function getAxises() {
    const axises = [];
    axises.push(getXAxis());
    axises.push(getYAxis());
    return axises;
  }

  function focusTriangle(triangleClassName) {
    const triangleElement = document.getElementsByClassName(triangleClassName)[0];

    if (triangleElement) {
      triangleElement.classList.add('focusedTriangle');
    }
  }

  function blurTriangle(triangleClassName) {
    const triangleElement = document.getElementsByClassName(triangleClassName)[0];

    if (triangleElement) {
      triangleElement.classList.remove('focusedTriangle');
    }
  }

  function getTriangles() {
    const y = getYAxisValues();

    const triangleOpenedValue = chartValues[0].number;
    const triangleFinishedValue = chartValues[1].number;

    const triangleOpenedHeight =
      triangleOpenedValue >= triangleFinishedValue
        ? (triangleOpenedValue + triangleFinishedValue) * (y.stepHeight / y.stepValue)
        : triangleOpenedValue * (y.stepHeight / y.stepValue);
    const triangleOpenedColor = barColors[chartValues[0].colorIndex];

    const triangleOpenedTooltip = `${chartValues[0].title}: ${triangleOpenedValue}`;

    let openedTextY = triangleOpenedValue
      ? yAxisLength - triangleOpenedValue * (y.stepHeight / y.stepValue)
      : yAxisLength;
    const totalTextY = 15;

    const triangleFinishedHeight =
      triangleOpenedValue >= triangleFinishedValue
        ? triangleFinishedValue * (y.stepHeight / y.stepValue)
        : (triangleOpenedValue + triangleFinishedValue) * (y.stepHeight / y.stepValue);
    const triangleFinishedColor = barColors[chartValues[1].colorIndex];
    const triangleFinishedTooltip = `${chartValues[1].title}: ${triangleFinishedValue}`;
    let finishedTextY = yAxisLength - triangleFinishedValue * (y.stepHeight / y.stepValue);

    if (!triangleFinishedValue) {
      if (!triangleOpenedValue) {
        finishedTextY = yAxisLength - 15;
      } else {
        if (triangleOpenedValue > triangleFinishedValue && triangleFinishedValue === 0) {
          openedTextY = yAxisLength - triangleOpenedValue * (y.stepHeight / y.stepValue) + 10;
        }

        finishedTextY = yAxisLength;
      }
    } else if (triangleOpenedValue === triangleFinishedValue) {
      openedTextY = yAxisLength - triangleOpenedValue * (y.stepHeight / y.stepValue) - 6;
      finishedTextY = yAxisLength - triangleFinishedValue * (y.stepHeight / y.stepValue) + 9;
    } else if (triangleOpenedValue < triangleFinishedValue && !triangleOpenedValue) {
      finishedTextY = yAxisLength - triangleFinishedValue * (y.stepHeight / y.stepValue) + 10;
    }

    const openedTriangleClassName = `chart-triangle ${
      triangleOpenedValue >= triangleFinishedValue ? 'back-triangle' : 'front-triangle'
    }`;

    const finishedTriangleClassName = `chart-triangle ${
      triangleOpenedValue >= triangleFinishedValue ? 'front-triangle' : 'back-triangle'
    }`;

    const openedTriangle = (
      <TriangleStyled
        key={`burn-triangle-${chartValues[0].key}`}
        $triangleFill={triangleOpenedColor}
        $triangleVal={triangleOpenedValue}
        $chartWidth={maxChartWidth}
        $clickable={!!onSelectedChartGroup}
      >
        <polygon
          points={`${chartStartX},${yAxisLength} ${xAxisLength},${yAxisLength} ${xAxisLength},${
            yAxisLength - triangleOpenedHeight
          }`}
          className={openedTriangleClassName}
          onClick={() => (onSelectedChartGroup ? onSelectedChartGroup(chartValues[0]) : null)}
        />
        {showTooltip ? <title className="triangleTooltip">{triangleOpenedTooltip}</title> : null}
        {showText ? (
          <>
            {triangleOpenedValue >= triangleFinishedValue &&
            (triangleOpenedValue || triangleFinishedValue) ? (
              <text
                className="triangleText opened-text"
                textAnchor="start"
                x={xAxisLength + 5}
                y={totalTextY}
              >
                {`${t('burnChart.total')} (${triangleOpenedValue + triangleFinishedValue})`}
              </text>
            ) : null}
            <text
              className="triangleText"
              textAnchor="start"
              x={xAxisLength + 5}
              y={openedTextY}
              onClick={() => (onSelectedChartGroup ? onSelectedChartGroup(chartValues[0]) : null)}
              onMouseOver={() => focusTriangle(openedTriangleClassName)}
              onMouseOut={() => blurTriangle(openedTriangleClassName)}
            >
              {`${chartValues[0].title} (${triangleOpenedValue})`}
            </text>
          </>
        ) : null}
      </TriangleStyled>
    );

    const finishedTriangle = (
      <TriangleStyled
        key={`burn-triangle-${chartValues[1].key}`}
        $triangleFill={triangleFinishedColor}
        $triangleVal={triangleFinishedValue}
        $chartWidth={maxChartWidth}
        $clickable={!!onSelectedChartGroup}
      >
        <polygon
          points={`${chartStartX},${yAxisLength} ${xAxisLength},${yAxisLength} ${xAxisLength},${
            yAxisLength - triangleFinishedHeight
          }`}
          className={finishedTriangleClassName}
          onClick={() => (onSelectedChartGroup ? onSelectedChartGroup(chartValues[1]) : null)}
        />
        {showTooltip ? <title className="triangleTooltip">{triangleFinishedTooltip}</title> : null}
        {showText ? (
          <>
            {triangleOpenedValue < triangleFinishedValue ? (
              <text
                className="triangleText opened-text"
                textAnchor="start"
                x={xAxisLength + 5}
                y={totalTextY}
              >
                {`${t('burnChart.total')} (${triangleOpenedValue + triangleFinishedValue})`}
              </text>
            ) : null}
            <text
              className="triangleText"
              textAnchor="start"
              x={xAxisLength + 5}
              y={finishedTextY}
              onClick={() => (onSelectedChartGroup ? onSelectedChartGroup(chartValues[1]) : null)}
              onMouseOver={() => focusTriangle(finishedTriangleClassName)}
              onMouseOut={() => blurTriangle(finishedTriangleClassName)}
            >
              {`${chartValues[1].title} (${triangleFinishedValue})`}
            </text>
          </>
        ) : null}
      </TriangleStyled>
    );

    const triangles = [openedTriangle];

    if (triangleOpenedValue >= triangleFinishedValue) {
      triangles.push(finishedTriangle);
    } else {
      triangles.unshift(finishedTriangle);
    }

    return triangles;
  }

  function getBurnChart() {
    return (
      <g transform="translate(40,20)">
        {getAxises()}
        {getTriangles()}
      </g>
    );
  }

  function getSvgChart() {
    return (
      <svg
        width={maxChartWidth}
        height={maxChartHeight}
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        preserveAspectRatio="xMinYMin meet"
        viewBox={`${chartStartX * 1.5} ${chartStartY * 1.5} ${maxChartWidth} ${maxChartHeight}`}
        className="burn-chart"
      >
        {getBurnChart()}
      </svg>
    );
  }

  return (
    <DiagramContainerStyled>
      <DiagramStyled $chartWidth={maxChartWidth}>{getSvgChart()}</DiagramStyled>
      {!chartValues[0].number && !chartValues[1].number && noTasksLabel ? (
        <div className="no-tasks-label">{noTasksLabel}</div>
      ) : null}
    </DiagramContainerStyled>
  );
}

BurnChart.propTypes = {
  showText: PropTypes.bool,
  showTooltip: PropTypes.bool,
  onSelectedChartGroup: PropTypes.func,
  chartValues: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.string,
      number: PropTypes.number,
      colorIndex: PropTypes.number
    })
  ).isRequired,
  yAxisTitle: PropTypes.string,
  chartHeight: PropTypes.number,
  chartWidth: PropTypes.number,
  noTasksLabel: PropTypes.string
};

BurnChart.defaultProps = {
  showText: false,
  showTooltip: false,
  onSelectedChartGroup: null,
  yAxisTitle: null,
  chartHeight: null,
  chartWidth: null,
  noTasksLabel: null
};

export default BurnChart;
