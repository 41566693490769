import {
  createApiObjectDataFromPlainObject,
  IApiObjectCache,
  IApiObjectData,
  IApiObjectType,
  makeApiObjectVariantKey
} from '../object';
import { VoidParams } from './base-types';
import { fetchJson } from '../../../services/api2';

export interface IFESettingsItem extends IApiObjectData {
  background: string | null;
  title: string | null;
  themePrimary: string | null;
  neutralPrimary: string | null;
  white: string | null;
  palette: IFESettingsPalette;
  dwhSqlServer: string | null;
  dwhDbName: string | null;
  dwhHistory: boolean;
  dwhConnectionError: string | null;
  usePlanner: boolean;
  useProcesses: boolean | null;
  useProjects: boolean | null;
  useAllUsers: boolean | null;
  features: ITenantFeatures;
  exchangeSettings: ITenantExchangeSettings;
}

export interface IFESettingsPalette {
  themePrimary: string | null;
  themeLighterAlt: string | null;
  themeLighter: string | null;
  themeLight: string | null;
  themeTertiary: string | null;
  themeSecondary: string | null;
  themeDarkAlt: string | null;
  themeDark: string | null;
  themeDarker: string | null;
  neutralLighterAlt: string | null;
  neutralLighter: string | null;
  neutralLight: string | null;
  neutralQuaternaryAlt: string | null;
  neutralQuaternary: string | null;
  neutralTertiaryAlt: string | null;
  neutralTertiary: string | null;
  neutralSecondary: string | null;
  neutralPrimaryAlt: string | null;
  neutralPrimary: string | null;
  neutralDark: string | null;
  black: string | null;
  white: string | null;
}

export interface ITenantFeatures {
  useAutoTagging: boolean;
  useAIField: boolean;
  processesFromTaskManagement: boolean;
}

export interface ITenantExchangeSettings {
  clientId: string | null;
  clientSecret: string | null;
  senderEmail: string | null;
}

export const FE_SETTINGS: IApiObjectType<VoidParams, IFESettingsItem> = {
  id: 'FESettings',
  createRefs() {
    // nothing to do
  },
  async load(
    cache: IApiObjectCache,
    params: VoidParams,
    abort: AbortSignal
  ): Promise<IFESettingsItem> {
    const result = await fetchJson({ url: 'FESettings', abort });

    return createApiObjectDataFromPlainObject(
      cache,
      makeApiObjectVariantKey(FE_SETTINGS, params),
      result
    ) as IFESettingsItem;
  }
};
