import { t } from 'i18next';
import { Checkbox } from '@fluentui/react';
import { IFormProps } from './FieldForm';

function DateTimeSettings({ field, setField, disabled }: IFormProps) {
  function updateDateField() {
    const dateFormat = !field.dateFormat || field.dateFormat === 1 ? 2 : 1;
    setField({ ...field, dateFormat });
  }

  return (
    <div>
      <Checkbox
        key={`includeTime-${field?.fieldType}-${field?.dateFormat}`}
        className="additional-field-settings-checkbox-container"
        label={t('createField.dialog.showTime.checkbox.label')}
        defaultChecked={field.dateFormat === 2}
        onChange={updateDateField}
        styles={{ root: { marginTop: '15px' } }}
        disabled={disabled}
      />
    </div>
  );
}

export default DateTimeSettings;
