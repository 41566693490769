import { $getNodeByKey, LexicalEditor, NodeKey } from 'lexical';
import { useCallback } from 'react';
import { LinkNode } from '@lexical/link';
import { NodeEventPlugin } from '@lexical/react/LexicalNodeEventPlugin';

export default function ClickableLinkPlugin({ focused }: { focused: boolean }): JSX.Element {
  const handleEvent = useCallback(
    (e: Event, editor: LexicalEditor, nodeKey: NodeKey) => {
      const event = e as MouseEvent;

      let url = null;

      editor.getEditorState().read(() => {
        const node = $getNodeByKey(nodeKey);

        if (node instanceof LinkNode) {
          url = node.getURL();
        }
      });

      if (url && (!focused || event.ctrlKey)) {
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
          url = `https://${url}`;
        }
        window.open(url, '_blank');
      }
    },
    [focused]
  );

  return <NodeEventPlugin nodeType={LinkNode} eventType="mousedown" eventListener={handleEvent} />;
}
