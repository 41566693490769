import LocationField, { ILocationFieldProps } from 'components/inputs/LocationField';
import { IFieldLinkProps, RouteFieldVisibility } from 'types';
import { getFluentIconNameByFieldType } from 'utils/helpers';

export interface IDynamicLocationFieldProps {
  disabled?: boolean;
  fieldLink: IFieldLinkProps;
  onChange: ILocationFieldProps['onChange'];
}

function DynamicLocationField({ fieldLink, disabled, onChange }: IDynamicLocationFieldProps) {
  if (!fieldLink.field) return null;

  const { field, visibility } = fieldLink;
  const { name, description, descriptionPlacement, id } = field;
  const defaultValue = fieldLink.value as ILocationFieldProps['defaultValue'];

  const displayDescriptionTooltip = descriptionPlacement === 1;

  const readOnly = visibility === RouteFieldVisibility.ReadOnly;
  const required = visibility === RouteFieldVisibility.Required;

  return (
    <LocationField
      id={id}
      label={name}
      description={displayDescriptionTooltip ? description : undefined}
      labelIconName={getFluentIconNameByFieldType(fieldLink.field)}
      defaultValue={defaultValue}
      onChange={onChange}
      disabled={disabled || readOnly}
      required={required}
    />
  );
}

export default DynamicLocationField;
