import { getTheme } from '@fluentui/react';
import {
  Spread,
  type DOMConversionMap,
  type DOMConversionOutput,
  type DOMExportOutput,
  type EditorConfig,
  type LexicalNode,
  type NodeKey,
  type SerializedTextNode,
  $applyNodeReplacement,
  TextNode
} from 'lexical';

export type SerializedMentionNode = Spread<
  {
    mentionName: string;
    login: string;
    userId: string;
  },
  SerializedTextNode
>;

function convertMentionElement(domNode: HTMLElement): DOMConversionOutput | null {
  const { textContent } = domNode;

  const userId = domNode.getAttribute('data-user-id') || '';
  const login = domNode.getAttribute('data-login') || '';

  if (textContent !== null) {
    const node = $createMentionNode({ mentionName: textContent, login, userId });
    return {
      node
    };
  }

  return null;
}

const mentionStyle = `color: ${getTheme().palette.themePrimary}; font-weight: bold;`;

export class MentionNode extends TextNode {
  mentionName: string;

  login: string;

  userId: string;

  static getType(): string {
    return 'mention';
  }

  static clone(node: MentionNode): MentionNode {
    return new MentionNode(node.mentionName, node.login, node.userId, node.key);
  }

  constructor(mentionName: string, login: string, userId: string, key?: NodeKey) {
    super(mentionName, key);
    this.mentionName = mentionName;
    this.login = login;
    this.userId = userId;
  }

  static importJSON(serializedNode: SerializedMentionNode): MentionNode {
    const node = $createMentionNode({
      mentionName: serializedNode.mentionName,
      login: serializedNode.login,
      userId: serializedNode.userId
    });
    node.setTextContent(serializedNode.text);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  exportJSON(): SerializedMentionNode {
    return {
      ...super.exportJSON(),
      mentionName: this.mentionName,
      login: this.login,
      userId: this.userId,
      type: 'mention',
      version: 1
    };
  }

  createDOM(config: EditorConfig): HTMLElement {
    const dom = super.createDOM(config);

    dom.style.cssText = mentionStyle;
    dom.className = 'mention';
    dom.setAttribute('data-user-id', this.userId);
    dom.setAttribute('data-login', this.login);
    dom.onmouseover = () => {
      dom.style.cssText = `${mentionStyle}text-decoration: underline;`;
    };
    dom.onmouseout = () => {
      dom.style.cssText = mentionStyle;
    };

    return dom;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('span');
    element.setAttribute('entity-type', 'MENTION');
    element.setAttribute('data-user-id', this.userId);
    element.setAttribute('data-login', this.login);

    element.textContent = this.mentionName;

    return { element };
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (domNode: HTMLElement) => {
        if (
          domNode.hasAttribute('entity-type') &&
          domNode.getAttribute('entity-type') === 'MENTION'
        ) {
          return { conversion: convertMentionElement, priority: 4 };
        }

        return null;
      }
    };
  }

  static isTextEntity(): true {
    return true;
  }

  static canInsertTextBefore(): boolean {
    return false;
  }

  static canInsertTextAfter(): boolean {
    return false;
  }
}

export function $createMentionNode({
  mentionName,
  login,
  userId
}: {
  mentionName: string;
  login: string;
  userId: string;
}): MentionNode {
  const mentionNode = new MentionNode(mentionName, login, userId);

  mentionNode.setMode('segmented').toggleDirectionless();

  return $applyNodeReplacement(mentionNode);
}

export function $isMentionNode(node: LexicalNode | null | undefined): node is MentionNode {
  return node instanceof MentionNode;
}
