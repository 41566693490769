import PropTypes from 'prop-types';
import { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { isInIframe } from 'utils/helpers';
import { SearchBox } from '@fluentui/react';

const SearchBarStyled = styled.div`
  .ms-team-searchbox {
    background-color: '#ffffff';
    border-left: none;
    border-bottom: none;
    border-right: none;
    border-top: 1px solid rgb(232, 236, 238);

    &:hover {
      border-top: 1px solid rgb(232, 236, 238);
    }
  }
  .is-active {
    border: 1px solid rgb(232, 236, 238);
  }
`;

function SearchBar({ onSearchChanged, searchTerm, autoFocus, toggleSearchBar, styles }) {
  const [initialized, setInitialized] = useState(false);
  const { t } = useTranslation();

  let inputRef = createRef();

  useEffect(() => {
    if (autoFocus && !initialized && inputRef) {
      setInitialized(true);
      inputRef.focus();
    }
  }, [autoFocus, initialized, inputRef]);

  const defaulStyles = {
    root: {
      height: '45px',
      width: '249px',
      borderTop: '',
      boxShadow: isInIframe() ? 'rgba(21,27,38,0.15) 0px 1px 2px' : 'none'
    }
  };

  return (
    <SearchBarStyled>
      <SearchBox
        className={isInIframe() ? 'ms-team-searchbox' : 'SearchBox'}
        placeholder={t('searchBar.placeholder')}
        styles={styles || defaulStyles}
        componentRef={(input) => {
          inputRef = input;
        }}
        onBlur={() => {
          if (toggleSearchBar && !searchTerm) {
            toggleSearchBar();
          }
        }}
        onClear={() => onSearchChanged('')}
        onChange={(_, value) => {
          if (value === '') {
            onSearchChanged('');
          }
        }}
        onSearch={(value) => {
          if (value.length > 0 && value.trim().length === 0) {
            onSearchChanged('');
          } else {
            onSearchChanged(value);
          }
        }}
      />
    </SearchBarStyled>
  );
}

SearchBar.propTypes = {
  autoFocus: PropTypes.bool,
  onSearchChanged: PropTypes.func.isRequired,
  searchTerm: PropTypes.string,
  styles: PropTypes.any,
  toggleSearchBar: PropTypes.func
};

SearchBar.defaultProps = {
  autoFocus: false,
  searchTerm: null,
  toggleSearchBar: null
};

export default SearchBar;
