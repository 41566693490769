import ListContextMenu from 'components/lists/ListContextMenu';
import PropTypes from 'prop-types';
import { useState } from 'react';
import styled from 'styled-components';
import { ConstrainMode, DetailsList, DetailsListLayoutMode } from '@fluentui/react';

const ListCustomStyled = styled.div`
  .ms-DetailsHeader-filterChevron {
    visibility: hidden;
  }

  .ms-DetailsHeader-cell:hover {
    .ms-DetailsHeader-filterChevron {
      visibility: visible;
    }
  }
`;

function ListCustom(props) {
  // List props
  const {
    className,
    items,
    getFilterOptions,
    selectionMode,
    selection,
    selectionPreservedOnEmptyClick,
    enterModalSelectionOnTouch,
    columns,
    groups,
    styles,
    groupProps,
    onRenderRow,
    onSortItems,
    onFilterItems,
    onRenderDetailsHeader,
    setKey
  } = props;

  // Context menu properties (for selected column)
  const [contextMenuTarget, setContextMenuTarget] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [filterOptions, setFilterOptions] = useState(null);

  function onDismiss() {
    setContextMenuTarget(null);
    setSelectedColumn(null);
    setFilterOptions(null);
  }

  const onColumnHeaderClick = (ev, column) => {
    if (selectedColumn && contextMenuTarget) {
      onDismiss();
    } else if (columns) {
      const index = columns.findIndex((col) => col.key === column.key);

      if (typeof index === 'number') {
        setSelectedColumn({ ...column, ...columns[index] });
        setContextMenuTarget(ev.currentTarget);
      }
    }
  };

  function onSortRows(column, isDesc) {
    const newColumns = columns.slice();

    const currColumn = newColumns.filter((currCol) => column.key === currCol.key)[0];

    if (currColumn.isSortedDescending !== isDesc || !currColumn.isSorted) {
      const setColumnsSorting = (newCol) => {
        const col = newCol;

        if (newCol === currColumn) {
          currColumn.isSortedDescending = isDesc;
          currColumn.isSorted = true;
        } else {
          col.isSorted = false;
          col.isSortedDescending = false;
        }

        return null;
      };

      newColumns.map(setColumnsSorting);

      onSortItems(currColumn, newColumns);
    }
  }

  function onFilterRows(column) {
    const newColumn = { ...selectedColumn, ...column };
    let newColumns = [...columns];

    // reset other columns filter
    newColumns = [
      ...columns.map((column) => ({ ...column, filterOptionsState: null, isFiltered: false }))
    ];

    const index = newColumns.findIndex((columnToUpdate) => columnToUpdate.key === newColumn.key);

    if (index > -1) {
      newColumns[index] = newColumn;
    }

    onFilterItems(column, newColumns);
    setSelectedColumn(newColumn);
  }

  if (!filterOptions && items && selectedColumn && getFilterOptions) {
    const newFilterOptions = getFilterOptions(items, selectedColumn);

    if (newFilterOptions?.length) {
      setFilterOptions(newFilterOptions);
    }
  }

  return (
    <ListCustomStyled>
      <DetailsList
        className={className}
        items={items}
        selectionMode={selectionMode}
        selection={selection}
        selectionPreservedOnEmptyClick={selectionPreservedOnEmptyClick}
        enterModalSelectionOnTouch={enterModalSelectionOnTouch}
        isHeaderVisible={!!onSortItems || !!onFilterItems}
        columns={columns}
        groups={groups}
        groupProps={groupProps}
        onColumnHeaderClick={onColumnHeaderClick}
        onRenderRow={onRenderRow}
        styles={styles}
        onRenderDetailsHeader={onRenderDetailsHeader}
        constrainMode={ConstrainMode.unconstrained}
        layoutMode={DetailsListLayoutMode.fixedColumns}
        setKey={setKey}
      />
      {selectedColumn && contextMenuTarget ? (
        <ListContextMenu
          filterOptions={filterOptions}
          column={selectedColumn}
          target={contextMenuTarget}
          onSorting={onSortRows}
          onFilter={onFilterRows}
          onDismiss={onDismiss}
        />
      ) : null}
    </ListCustomStyled>
  );
}

ListCustom.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  getFilterOptions: PropTypes.func,
  selectionMode: PropTypes.number,
  selection: PropTypes.objectOf(PropTypes.any),
  selectionPreservedOnEmptyClick: PropTypes.bool,
  enterModalSelectionOnTouch: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.any).isRequired,
  groups: PropTypes.arrayOf(PropTypes.any),
  groupProps: PropTypes.objectOf(PropTypes.any),
  styles: PropTypes.objectOf(PropTypes.any),
  onRenderRow: PropTypes.func,
  onSortItems: PropTypes.func,
  onFilterItems: PropTypes.func,
  onRenderDetailsHeader: PropTypes.func,
  setKey: PropTypes.string
};

ListCustom.defaultProps = {
  className: null,
  getFilterOptions: null,
  selectionMode: null,
  selectionPreservedOnEmptyClick: null,
  enterModalSelectionOnTouch: null,
  groups: null,
  groupProps: null,
  onRenderRow: null,
  onSortItems: null,
  onFilterItems: null,
  onRenderDetailsHeader: undefined,
  setKey: null
};

export default ListCustom;
