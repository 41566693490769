import {
  createApiObjectDataFromPlainObject,
  IApiObjectCache,
  IApiObjectData,
  IApiObjectType,
  makeApiObjectVariantKey
} from '../object';
import { createFieldRefs, simpleIdObject } from './utils';
import { IApiObjectArray, ITextItem, Ref, Uuid, VoidParams } from './base-types';
import { fetchJson } from '../../../services/api2';

export interface ICountryItem extends IApiObjectData {
  id: Uuid;
  name: string | null;
  allNames: ITextItem[] | null;
  code: string | null;
}

export const COUNTRY = simpleIdObject<ICountryItem>({
  id: 'Country',
  url: (id) => `Country/${id}`,
  loadDedupIntervalSecs: 600
});

export interface ILocationItem extends IApiObjectData {
  id: Uuid;
  name: string | null;
  allNames: ITextItem[] | null;
  code: string | null;
  country: Ref<ICountryItem> | null;
}

export const LOCATION = simpleIdObject<ILocationItem>({
  id: 'Location',
  url: (id) => `Location/${id}`,
  fields: [{ path: 'country', type: COUNTRY, params: (data) => data.country?.id }],
  loadDedupIntervalSecs: 600
});

export const LOCATIONS: IApiObjectType<VoidParams, IApiObjectArray<ILocationItem>> = {
  id: 'Locations',
  createRefs(cache: IApiObjectCache, data: IApiObjectArray<ILocationItem>) {
    return createFieldRefs(
      cache,
      data,
      data.items.map((item, index) => ({
        path: `items/${index}`,
        type: LOCATION,
        params: () => item.id
      }))
    );
  },
  async load(
    cache: IApiObjectCache,
    params: VoidParams,
    abort: AbortSignal
  ): Promise<IApiObjectArray<ILocationItem>> {
    const result = await fetchJson({ url: 'Locations', abort });

    return createApiObjectDataFromPlainObject(cache, makeApiObjectVariantKey(LOCATIONS, params), {
      items: result
    }) as IApiObjectArray<ILocationItem>;
  }
};
