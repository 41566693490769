import Label from 'components/inputs/Label';
import RichTextEditor from 'components/inputs/RichTextEditor';
import moment from 'moment';
import { useState } from 'react';
import styled from 'styled-components';
import { ILongTextFieldVersionProps } from 'types';
import { Persona, PersonaSize } from '@fluentui/react';

export interface ILongTextFieldProps {
  /**
   * Defaultvalue for the longtextfield
   */
  defaultValue?: string;
  /**
   * Optional flag to show/hide the rte-commandbar
   * @defaultvalue false
   */
  displayCmdBar?: boolean;
  /**
   * Description for the longtextfield
   */
  description?: string;
  /**
   * A label for the longtextfield
   */
  label?: string;
  /**
   * The icon that will be displayed to the left of the label
   */
  labelIconName?: string;
  id?: string;
  onBlur?: (value?: string | undefined | null) => void;
  /**
   * Callback issued when the text changes.
   */
  onChange?: (value?: string | null) => void;
  /**
   * Optional flag to mark longtextfield as readOnly / disabled
   * @defaultvalue false
   */
  disabled?: boolean;
  /**
   * specifies a short hint that describes the expected value of an input field (e.g. a sample value or a short description of the expected format).
   */
  placeholder?: string;
  /**
   * Whether the associated form field is required or not
   * @defaultvalue false
   */
  required?: boolean;
  /**
   * Versions of the longtextfield to append at the bottom
   */
  versions?: ILongTextFieldVersionProps[];
  /**
   * Call to provide customized styling that will layer on top of the variant rules.
   */
  styles?: {
    version?: React.CSSProperties;
  };
  descriptionPosition?: 'info-icon' | 'bottom';
}

const RteWrapper = styled.div`
  .rte-focused,
  .rte-base {
    position: relative;
    min-height: 32px;
  }

  .rte-focused {
    border: 1px solid transparent;

    &:after {
      border: 2px solid rgb(${({ theme }) => theme.longTextField.focusOutline});
      border-radius: ${({ theme }) => theme.longTextField.cornerRadius};
      bottom: -1px;
      content: '';
      left: -1px;
      pointer-events: none;
      position: absolute;
      right: -1px;
      top: -1px;
    }
  }

  .rte-base {
    border: 1px solid rgb(${({ theme }) => theme.longTextField.outline});
    border-radius: ${({ theme }) => theme.longTextField.cornerRadius};

    &:hover {
      border: 1px solid transparent;
      border-radius: ${({ theme }) => theme.longTextField.cornerRadius};
      &:after {
        border: 1px solid rgb(${({ theme }) => theme.longTextField.hoverOutline});
        border-radius: ${({ theme }) => theme.longTextField.cornerRadius};
        bottom: -1px;
        content: '';
        left: -1px;
        pointer-events: none;
        position: absolute;
        right: -1px;
        top: -1px;
      }
    }
  }

  .rte-disabled {
    background-color: rgb(${({ theme }) => theme.longTextField.disabledBackground});
    position: relative;
    min-height: 32px;
    border: 1px solid rgb(${({ theme }) => theme.longTextField.disabledOutline});
    border-radius: ${({ theme }) => theme.longTextField.cornerRadius};
    color: rgb(${({ theme }) => theme.longTextField.disabledForegroundSecondary});

    .DraftEditor-editorContainer * {
      color: rgb(${({ theme }) => theme.longTextField.disabledForeground});
    }

    .public-DraftEditorPlaceholder-root * {
      color: rgb(${({ theme }) => theme.longTextField.disabledForegroundSecondary});
    }
  }
`;

const VersionsWrapper = styled.div`
  border: 1px solid rgb(${({ theme }) => theme.longTextField.disabledOutline});
  border-radius: ${({ theme }) => theme.longTextField.cornerRadius};
  background-color: rgb(${({ theme }) => theme.longTextField.disabledBackground});
`;

function LongTextField({
  id,
  defaultValue,
  description,
  descriptionPosition = 'info-icon',
  disabled,
  displayCmdBar = false,
  label,
  labelIconName,
  onChange,
  placeholder,
  onBlur,
  required,
  versions,
  styles
}: ILongTextFieldProps): JSX.Element {
  const [focused, setFocused] = useState<boolean>(false);
  const [value, setValue] = useState<string | undefined | null>(defaultValue || '');

  function onFocus() {
    setFocused(true);
  }

  function handleBlur() {
    if (onBlur) {
      onBlur(value);
    }

    setFocused(false);
  }

  function renderVersion(version: ILongTextFieldVersionProps) {
    let versionStyles: React.CSSProperties = { padding: 7 };

    if (styles?.version) {
      versionStyles = { ...versionStyles, ...styles?.version };
    }

    return (
      <div style={versionStyles} key={version.editDate}>
        <div style={{ fontWeight: 500, display: 'flex' }}>
          <Persona text={version.editor.name || ''} size={PersonaSize.size24} />
          <div style={{ color: '#605e5c', fontWeight: 400, fontSize: 12, marginTop: 5 }}>
            {moment(version.editDate).format('DD.MM.YYYY, HH:mm')}
          </div>
        </div>
        <RichTextEditor
          styles={{ editor: { marginLeft: 33 }, editorWrapper: { padding: 0 } }}
          defaultValue={version.value}
          disabled
          required={false}
        />
      </div>
    );
  }

  function getPrevVersions() {
    if (versions) {
      return versions.map(renderVersion);
    }

    return null;
  }

  function renderBottomDescription() {
    if (!description || descriptionPosition !== 'bottom') return null;

    return (
      <RichTextEditor
        disabled
        styles={{ editor: { margin: '3px 6px 3px 6px', fontSize: '12px', color: '#605e5c' } }}
        defaultValue={description}
      />
    );
  }

  if (disabled && versions?.length) {
    return (
      <div>
        <Label
          label={label}
          required={required}
          iconName={labelIconName}
          description={descriptionPosition === 'info-icon' ? description : undefined}
        />
        <VersionsWrapper>{getPrevVersions()}</VersionsWrapper>
        {renderBottomDescription()}
      </div>
    );
  }

  return (
    <div id={id}>
      <RteWrapper>
        <RichTextEditor
          id={id}
          defaultValue={defaultValue}
          description={descriptionPosition === 'info-icon' ? description : undefined}
          disabled={disabled}
          displayCmdBar={displayCmdBar || focused}
          label={label}
          labelIconName={labelIconName}
          onBlur={handleBlur}
          onFocus={onFocus}
          onChange={({ value }) => {
            let valueToProvide = value;

            if (valueToProvide === '<p><br></p>') {
              valueToProvide = null;
            }

            setValue(valueToProvide);

            if (onChange) {
              onChange(valueToProvide);
            }
          }}
          placeholder={placeholder}
          required={required}
          styles={{ editorWrapper: { padding: 7, minHeight: '40px' } }}
        />
      </RteWrapper>
      {renderBottomDescription()}
      <div style={{ marginTop: 8 }}>{getPrevVersions()}</div>
    </div>
  );
}

export default LongTextField;
