import HyperLinkField from 'components/inputs/HyperLinkField';
import { IFieldLinkProps, ILinkProps, RouteFieldVisibility } from 'types';
import { getFluentIconNameByFieldType } from 'utils/helpers';

export interface IDynamicHyperLinkFieldProps {
  disabled?: boolean;
  fieldLink: IFieldLinkProps;
  onChange: (value?: ILinkProps) => void;
}

function DynamicHyperLinkField({ fieldLink, disabled, onChange }: IDynamicHyperLinkFieldProps) {
  if (!fieldLink.field) return null;

  const { field, visibility } = fieldLink;
  const { name, description, descriptionPlacement, id } = field;
  const defaultValue = fieldLink.value as ILinkProps | undefined;

  const displayDescriptionTooltip = descriptionPlacement === 1;

  const readOnly = visibility === RouteFieldVisibility.ReadOnly;
  const required = visibility === RouteFieldVisibility.Required;

  return (
    <HyperLinkField
      id={id}
      defaultValue={defaultValue}
      description={displayDescriptionTooltip ? description : undefined}
      disabled={disabled || readOnly}
      label={name}
      labelIconName={getFluentIconNameByFieldType(fieldLink.field)}
      onChange={onChange}
      required={required}
    />
  );
}

export default DynamicHyperLinkField;
