import { CombinedPickerJS as CombinedPicker } from 'components';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Label,
  PrimaryButton
} from '@fluentui/react';

function ReplaceUserInTemplateDialog({ hidden, onCancel, onConfirm, stepId, title }) {
  const { t } = useTranslation();
  const [selectedUser, setSelectedUser] = useState(null);

  function close() {
    onCancel();
  }

  function onConfirmNewMember() {
    return onConfirm(selectedUser.userId, !stepId);
  }

  function getDialogStyles() {
    return {
      main: [
        {
          selectors: {
            '@media (min-width: 650px)': {
              width: '100%',
              minWidth: '550px',
              minHeight: '200px'
            },
            '@media (max-width: 480px)': [
              {
                position: 'absolute',
                top: '10px',
                width: '100%',
                selectors: {
                  '.ms-Dialog-header': [
                    {
                      selectors: {
                        '.ms-Dialog-title': {
                          padding: '11px',
                          paddingRight: '40px',
                          fontSize: '16px'
                        },
                        'div[class^="topButton-"]': {
                          marginLeft: '5px',
                          padding: '5px'
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        }
      ]
    };
  }

  async function handleSelectedUser(value) {
    if (value && value.length > 0) {
      const { userId, login, primaryText, name, pictureUrl, tenantId } = value[0];
      const newUser = {
        userId: userId || '00000000-0000-0000-0000-000000000000',
        login: login || primaryText || '',
        name: name || '',
        pictureUrl: pictureUrl || '',
        tenantId: tenantId || '00000000-0000-0000-0000-000000000000'
      };
      setSelectedUser(newUser);
    }
  }

  function getDialogTitle() {
    if (title) return title;

    return stepId
      ? t('replaceUserInTemplate.dialog.title')
      : t('replaceUserInTemplate.dialog.multiple.title');
  }

  return (
    <Dialog
      hidden={hidden}
      onDismiss={() => close()}
      styles={getDialogStyles}
      dialogContentProps={{
        type: DialogType.close,
        title: getDialogTitle(),
        subText: stepId
          ? t('replaceUserInTemplate.dialog.subText')
          : t('replaceUserInTemplate.dialog.multiple.subText')
      }}
      modalProps={{
        isBlocking: true
      }}
    >
      <Label required>{t('replaceUserInTemplate.dialog.selectNewEmployee')}</Label>
      <CombinedPicker
        searchUser
        selectedItems={selectedUser}
        onChange={handleSelectedUser}
        type={5}
      />
      <DialogFooter>
        <PrimaryButton
          disabled={!(selectedUser && selectedUser.userId)}
          onClick={() => onConfirmNewMember()}
          text={t('replaceMember.dialog.button.save')}
        />
        <DefaultButton onClick={() => close()} text={t('replaceMember.dialog.button.cancel')} />
      </DialogFooter>
    </Dialog>
  );
}

ReplaceUserInTemplateDialog.propTypes = {
  hidden: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  stepId: PropTypes.string,
  title: PropTypes.string
};

ReplaceUserInTemplateDialog.defaultProps = {
  hidden: true,
  stepId: null
};

export default ReplaceUserInTemplateDialog;
