import { ViewPanelBody as FieldGroupForm } from 'features/Administration';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Dialog, DialogType } from '@fluentui/react';

const DialogContentStyled = styled.div`
  /* margin-top: 1rem; */
  /* margin-bottom: 2rem; */
  max-height: 700px;
  overflow: auto;
  padding: 10px;
`;

const DialogStyled = styled(Dialog)`
  .ms-Dialog-inner {
    padding: 0px;
  }
`;

function FieldGroupDialog({ hidden, onCancel, onConfirm, fieldGroup }) {
  const [minWidth, setMinWidth] = useState(750);

  const { t } = useTranslation();

  function onDismiss() {
    onCancel();
  }
  const isEdit = fieldGroup?.id && fieldGroup.id !== '00000000-0000-0000-0000-000000000000';

  return (
    <DialogStyled
      hidden={hidden}
      onDismiss={onDismiss}
      styles={{
        main: [
          {
            selectors: {
              '@media (min-width: 850px)': {
                width: '100%',
                minWidth,
                minHeight: '200px'
              }
            }
          }
        ]
      }}
      dialogContentProps={{
        type: DialogType.close,
        title: isEdit ? t('viewPanelHeader.title.editView') : t('viewPanelHeader.title.newView')
      }}
      modalProps={{ isBlocking: true }}
    >
      <DialogContentStyled>
        <FieldGroupForm
          onClosePanel={onDismiss}
          view={fieldGroup}
          onViewUpdate={onConfirm}
          displayCmdBar={false}
          changePanelSize={(value) => {
            setMinWidth(value);
          }}
        />
      </DialogContentStyled>
    </DialogStyled>
  );
}

FieldGroupDialog.propTypes = {
  hidden: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  fieldGroup: PropTypes.shape({
    dateFormat: PropTypes.number,
    choiceFormat: PropTypes.number,
    choices: PropTypes.arrayOf(PropTypes.object),
    maxLength: PropTypes.number,
    numStars: PropTypes.number,
    numDecimals: PropTypes.number,
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    fieldType: PropTypes.number,
    allowFillIn: PropTypes.bool,
    allowScan: PropTypes.bool,
    allNames: PropTypes.arrayOf(PropTypes.object),
    allDescriptions: PropTypes.arrayOf(PropTypes.object),
    lookupDefinitionId: PropTypes.string,
    appendChanges: PropTypes.bool,
    lookupOnlyOutcome: PropTypes.number,
    externalServiceId: PropTypes.string,
    lookupType: PropTypes.number,
    textOk: PropTypes.string,
    allTextsOk: PropTypes.arrayOf(PropTypes.object),
    textNOk: PropTypes.string,
    allTextsNOk: PropTypes.arrayOf(PropTypes.object)
  }).isRequired
};

FieldGroupDialog.defaultProps = {
  hidden: true
};

export default FieldGroupDialog;
