import styled from 'styled-components';
import { ActionButton, DirectionalHint, Icon } from '@fluentui/react';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  IMessageFragmentWebSearch,
  WebSearchQueryStatus
} from '../../../services/ChatConnection/model';
import AnimateExpanded from '../../surfaces/AnimateExpanded';
import InteractiveTooltipHost from '../../surfaces/InteractiveTooltipHost';
import { ChatItemReferenceContext } from './ReferenceContext';

const WebSearchFragmentContainerStyled = styled.div`
  background: rgb(${({ theme }) => theme.aiChatWebSearch.inlineBackground});
  color: rgb(${({ theme }) => theme.aiChatWebSearch.inlineForeground});
  box-shadow: inset 0 0 0 1px rgb(${({ theme }) => theme.aiChatWebSearch.inlineOutline});
  border-radius: 1rem;
`;

export function ChatMessageFragmentWebSearch({
  fragment
}: {
  fragment: IMessageFragmentWebSearch;
}) {
  return (
    <WebSearchFragmentContainerStyled>
      <AnimateExpanded
        collapsed={
          // currently removed
          null
        }
        expanded={<ExpandedWebSearchFragment fragment={fragment} />}
        isExpanded={!fragment.isCompleted}
        animationDuration={500}
      />
    </WebSearchFragmentContainerStyled>
  );
}

const ExpandedWebSearchFragmentStyled = styled.div`
  overflow: hidden;

  &:not(.is-completed) {
    min-height: 10em;
  }

  > .c-queries {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 0.5rem;
    padding: 0.25rem 0.5rem;

    > .c-icon {
      line-height: 2em;
    }

    > .c-items {
      display: flex;
      flex-wrap: wrap;
      gap: 1px 0.25rem;

      > .c-is-creating {
        line-height: 2em;
        font-weight: 500;
      }
    }
  }

  > .c-results {
    display: grid;
    height: 8rem;
    animation: web-search-results-appear 0.5s;

    > .c-activity {
      grid-area: 1 / 1;
      border-radius: 1rem;
      background: linear-gradient(
        to bottom,
        rgb(${({ theme }) => theme.aiChatWebSearch.inlineResultsActivityEffect}) 0%,
        rgb(${({ theme }) => theme.aiChatWebSearch.inlineResultsActivityEffect} / 0) 4rem
      );
      opacity: 0;
      filter: blur(1px);
    }

    > .c-list {
      grid-area: 1 / 1;
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      gap: 0.25rem;
      background: rgb(${({ theme }) => theme.aiChatWebSearch.inlineResultsBackground});
      border: 1px solid rgb(${({ theme }) => theme.aiChatWebSearch.inlineResultsOutline});
      position: relative;
      margin: 0.2rem;
      border-radius: 0.8rem;
      padding: 0.5rem;
      overflow: hidden auto;

      > li {
        animation: web-search-result-item-appear 0.5s 0.25s backwards;
      }
    }
  }

  > .c-results-status {
    background: rgb(${({ theme }) => theme.aiChatWebSearch.inlineResultsBackground});
    border: 1px solid rgb(${({ theme }) => theme.aiChatWebSearch.inlineResultsOutline});
    font-size: 14px;
    font-weight: 500;
    border-radius: 0.8rem;
    margin: 0.2rem;
    padding: 0.25rem 0.5rem;
    display: grid;
    grid-template-columns: 1rem 1fr;
    gap: 0.5rem;

    > .c-icon {
      text-align: center;
      font-size: 1rem;
    }
  }

  @keyframes web-search-results-appear {
    from {
      height: 0;
      opacity: 0;
    }
  }

  @keyframes web-search-result-item-appear {
    from {
      opacity: 0;
    }
  }
`;

function ExpandedWebSearchFragment({ fragment }: { fragment: IMessageFragmentWebSearch }) {
  const { t } = useTranslation();

  const queries = [...fragment.queries.keys()];
  const maxStatus = queries
    .map((query) => fragment.queries.get(query))
    .reduce((a, b) => Math.max(a, b), WebSearchQueryStatus.Created);

  return (
    <ExpandedWebSearchFragmentStyled className={fragment.isCompleted ? 'is-completed' : ''}>
      <div className="c-queries">
        <div className="c-icon">
          <Icon iconName="Search" />
        </div>
        <div className="c-items">
          {fragment.isCreatingQueries ? (
            <div className="c-is-creating">{t('ai.chat.fragments.webSearch.creatingQueries')}</div>
          ) : (
            [...fragment.queries].map(([query, status], index) => (
              <SearchQuery
                key={query}
                index={index}
                query={query}
                status={status}
                maxStatus={maxStatus}
              />
            ))
          )}
        </div>
      </div>
      {maxStatus >= WebSearchQueryStatus.Executing && !fragment.isCompleted ? (
        <InlineResultsBox resultUrls={fragment.resultUrls} />
      ) : null}

      {fragment.isCreatingResults ? (
        <div className="c-results-status">
          <Icon className="c-icon" iconName="ReadingMode" />
          {t('ai.chat.fragments.webSearch.creatingResults')}
        </div>
      ) : null}

      {fragment.isCompleted ? (
        <div className="c-results-status">
          <Icon className="c-icon" iconName="Completed" />
          {t('ai.chat.fragments.webSearch.isCompleted')}
        </div>
      ) : null}
    </ExpandedWebSearchFragmentStyled>
  );
}

const SearchQueryStyled = styled.div`
  display: grid;
  --index: 0;

  > .c-activity {
    grid-area: 1 / 1;
    position: relative;
    overflow: hidden;
    isolation: isolate;
    filter: blur(1px);
    border-radius: 9999px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
    --activity-a: rgb(${({ theme }) => theme.aiChatWebSearch.inlineQueryActivityEffectA});
    --activity-b: rgb(${({ theme }) => theme.aiChatWebSearch.inlineQueryActivityEffectB});

    > .c-visual-effect {
      position: absolute;
      left: 0;
      top: 0;
      width: 200%;
      height: 100%;
      background: linear-gradient(
        to right,
        var(--activity-a) 0%,
        var(--activity-b) 25%,
        var(--activity-a) 50%,
        var(--activity-b) 75%,
        var(--activity-a) 100%
      );
      animation: web-search-query-visual-effect 2s linear infinite;

      @keyframes web-search-query-visual-effect {
        0% {
          transform: translateX(-50%);
        }
        100% {
          transform: translateX(0);
        }
      }
    }
  }

  > .c-box {
    position: relative;
    grid-area: 1 / 1;
    margin: 0.25em;
    background: rgb(${({ theme }) => theme.aiChatWebSearch.inlineQueryBackground});
    padding: 0 0.5rem;
    line-height: 1.8;
    border-radius: 9999px;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: opacity 0.3s;

    animation: web-search-query-appear 0.5s calc(var(--index) * 0.05s) backwards;

    @keyframes web-search-query-appear {
      from {
        opacity: 0;
      }
    }
  }

  &[data-status='created']:not([data-max-status='created']) {
    opacity: 0.5;
  }

  &[data-status='executing'] > .c-activity {
    visibility: visible;
    opacity: 1;
  }
`;

function statusString(status: WebSearchQueryStatus): string {
  return {
    [WebSearchQueryStatus.Created]: 'created',
    [WebSearchQueryStatus.Executing]: 'executing',
    [WebSearchQueryStatus.Executed]: 'executed',
    [WebSearchQueryStatus.CreatingResults]: 'creating-results',
    [WebSearchQueryStatus.CompletedResults]: 'completed'
  }[status];
}

function SearchQuery({
  query,
  index,
  status,
  maxStatus
}: {
  query: string;
  index: number;
  status: WebSearchQueryStatus;
  maxStatus: WebSearchQueryStatus;
}) {
  return (
    <SearchQueryStyled
      data-status={statusString(status)}
      data-max-status={statusString(maxStatus)}
      style={
        {
          '--index': index.toString()
        } as Record<string, string>
      }
    >
      <div className="c-activity">
        <div className="c-visual-effect" />
      </div>
      <div className="c-box">{query}</div>
    </SearchQueryStyled>
  );
}

function InlineResultsBox({ resultUrls }: { resultUrls: string[] }) {
  const listRef = useRef<HTMLUListElement>(null);
  const activityRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // scroll to bottom when items are added
    const { current: list } = listRef;
    const { current: activity } = activityRef;
    if (!list) return;

    if (list.scrollHeight > list.offsetHeight) {
      list.scrollTo({
        top: list.scrollHeight - list.offsetHeight,
        behavior: 'smooth'
      });

      if (activity) {
        activity.animate([{ opacity: 0 }, { opacity: 1, offset: 0.1 }, { opacity: 0 }], {
          duration: 400,
          easing: 'ease-out'
        });
      }
    }
  }, [resultUrls]);

  return (
    <div className="c-results">
      <div className="c-activity" ref={activityRef} />
      <ul className="c-list" ref={listRef}>
        {resultUrls.map((url) => (
          <li key={url}>
            <ResultItem url={url} />
          </li>
        ))}
      </ul>
    </div>
  );
}

const ResultItemStyled = styled.a`
  display: block;
  padding: 0.25rem 0.5rem;
  font-size: 14px;
  background: rgb(${({ theme }) => theme.aiChatWebSearch.resultItemBackground});
  color: rgb(${({ theme }) => theme.aiChatWebSearch.resultItemForeground});
  border-radius: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  > .c-hostname {
    font-weight: 500;
  }

  > .c-path {
    > .c-arrow {
      font-size: 0.6em;
      margin: 0 0.2em;
    }
  }
`;

export function ResultItem({ url: urlString }: { url: string }) {
  const url = useMemo(() => {
    try {
      return new URL(urlString);
    } catch {
      return null;
    }
  }, [urlString]);

  if (!url) return null;

  return (
    <ResultItemStyled href={urlString} target="_blank" rel="noopener">
      <span className="c-hostname">{url.hostname}</span>
      <span className="c-path">
        {`${url.pathname}${url.search}`
          .split('/')
          .filter((part) => part)
          .flatMap((part, i) => [
            // the path isn't going to change. this is fine
            // eslint-disable-next-line react/no-array-index-key
            <Icon className="c-arrow" key={`${i}-arrow`} iconName="CaretSolidRight" />,
            // eslint-disable-next-line react/no-array-index-key
            <span className="c-path-part" key={`${i}-part`}>
              {decodeURIComponent(part)}
            </span>
          ])}
      </span>
    </ResultItemStyled>
  );
}

const ChatAuxiliaryFragmentWebSearchStyled = styled.div`
  overflow: hidden;

  > .c-queries {
    display: grid;
    background: rgb(${({ theme }) => theme.aiChatWebSearch.auxQueryBackground});
    color: rgb(${({ theme }) => theme.aiChatWebSearch.auxQueryForeground});
    border-radius: calc(0.75rem + 2px);
    border: 1px solid rgb(${({ theme }) => theme.aiChatWebSearch.auxQueryOutline});
  }

  > .c-queries > .c-queries-preview {
    background: none;
    border: none;
    padding: 0 0 0 0.5rem;
    color: inherit;
    font: inherit;
    text-align: inherit;
    line-height: 1.5rem;
    height: 1.5rem;
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 0.5rem;
    align-items: center;

    > .c-query {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    > .c-expand {
      background: rgb(${({ theme }) => theme.aiChatWebSearch.auxQueryForeground} / 0.1);
      padding: 0 0.5rem;
      min-width: 1.5rem;
      border-radius: 9999px;
    }
  }

  > .c-title {
    font-weight: bold;
  }

  > .c-sources {
    list-style: none;

    > li {
      margin: 0.5rem 0;
      --index: 0;

      &.c-item {
        display: grid;
        grid-template-columns: 1.5rem 1fr;
        gap: 0.5rem;
        padding-right: 0.1rem;

        &::before {
          content: '';
          place-self: center;
          width: 0.2rem;
          height: 0.2rem;
          border-radius: 9999px;
          background: rgb(${({ theme }) => theme.aiChatTextReference.foreground});
          animation: chat-aux-fragment-item-appear 0.3s backwards
            calc((1 - exp(var(--index) * -0.01)) * 1s);
        }
      }

      > .c-inner {
        min-width: 0;
        animation: chat-aux-fragment-item-appear 0.3s backwards
          calc((1 - exp(var(--index) * -0.01)) * 1s);
      }
    }
  }

  &.is-animated {
    > .c-queries {
      animation: chat-aux-fragment-item-appear-slide 0.7s 0.4s backwards cubic-bezier(0, 0, 0, 1);
    }
    > .c-sources > li {
      animation: chat-aux-fragment-item-appear-slide 0.7s calc(var(--index) * 0.02s + 0.4s)
        backwards cubic-bezier(0, 0, 0, 1);
    }
  }

  @keyframes chat-aux-fragment-item-appear {
    from {
      opacity: 0;
    }
  }
  @keyframes chat-aux-fragment-item-appear-slide {
    from {
      opacity: 0;
      transform: translateX(-2rem);
    }
  }
`;

const QueryListTooltipContents = styled.div`
  font-size: 14px;

  > .c-list {
    display: grid;
    gap: 0.25rem;

    > li {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 0.5rem;
      border-radius: 1rem;
      background: rgb(${({ theme }) => theme.aiChatWebSearch.auxQueryBackground});
      color: rgb(${({ theme }) => theme.aiChatWebSearch.auxQueryForeground});
      border: 1px solid rgb(${({ theme }) => theme.aiChatWebSearch.auxQueryOutline});
      padding: 0 0.5rem;
      line-height: 1.75;
    }
  }
`;

export function ChatAuxiliaryFragmentWebSearchHeader({
  fragment
}: {
  fragment: IMessageFragmentWebSearch;
}) {
  const wasCompleted = useRef(fragment.isCompleted);
  const shouldAnimateAppearance = fragment.isCompleted && !wasCompleted.current;

  const takenQueries = [...fragment.queries.entries()]
    .filter(([, status]) => status > WebSearchQueryStatus.Created)
    .map(([query]) => query);

  if (!fragment.isCompleted) {
    return null;
  }

  return (
    <ChatAuxiliaryFragmentWebSearchStyled className={shouldAnimateAppearance ? 'is-animated' : ''}>
      <InteractiveTooltipHost
        disabled={takenQueries.length <= 1}
        className="c-queries"
        calloutProps={{
          directionalHint: DirectionalHint.bottomLeftEdge,
          gapSpace: 0,
          beakWidth: 0
        }}
        content={
          <QueryListTooltipContents>
            <ul className="c-list">
              {takenQueries.slice(1).map((query) => (
                <li className="c-item" key={query}>
                  <Icon iconName="Search" />
                  {query}
                </li>
              ))}
            </ul>
          </QueryListTooltipContents>
        }
      >
        <div className="c-queries-preview">
          <Icon iconName="Search" />
          <div className="c-query">{takenQueries[0]}</div>
          {takenQueries.length > 1 ? (
            <div className="c-expand">+{takenQueries.length - 1}</div>
          ) : null}
        </div>
      </InteractiveTooltipHost>
    </ChatAuxiliaryFragmentWebSearchStyled>
  );
}

export function ChatAuxiliaryFragmentWebSearchResults({
  fragment
}: {
  fragment: IMessageFragmentWebSearch;
}) {
  const { t } = useTranslation();

  const [showAllSources, setShowAllSources] = useState(false);
  const references = useContext(ChatItemReferenceContext);

  const wasCompleted = useRef(fragment.isCompleted);
  const shouldAnimateAppearance = fragment.isCompleted && !wasCompleted.current;

  // our auxiliary fragment only shows result URLs that weren't referenced
  const previewUrls = useMemo(
    () =>
      fragment.resultUrls
        .slice(0, showAllSources ? undefined : 5)
        .filter(
          (url) =>
            !references.orderedSources.some((item) => item.type === 'web-url' && item.url === url)
        ),
    [fragment.resultUrls, references.orderedSources, showAllSources]
  );

  const remainingItemCount = showAllSources
    ? 0
    : Math.max(
        0,
        fragment.resultUrls.filter(
          (url) =>
            !references.orderedSources.some((item) => item.type === 'web-url' && item.url === url)
        ).length - previewUrls.length
      );

  if (!fragment.isCompleted) {
    return null;
  }

  return (
    <ChatAuxiliaryFragmentWebSearchStyled className={shouldAnimateAppearance ? 'is-animated' : ''}>
      {references.orderedSources.length ? (
        <div className="c-title">{t('ai.chat.fragments.webSearch.notReferencedTitle')}</div>
      ) : null}
      <ul className="c-sources">
        {previewUrls.map((url, i) => (
          <li
            className="c-item"
            key={url}
            style={{ '--index': i.toString() } as Record<string, string>}
          >
            <div className="c-inner">
              <ResultItem url={url} />
            </div>
          </li>
        ))}

        {remainingItemCount || showAllSources ? (
          <li
            className="c-more"
            style={{ '--index': previewUrls.length.toString() } as Record<string, string>}
          >
            <div className="c-inner">
              <ActionButton
                iconProps={{ iconName: showAllSources ? 'ChevronUp' : 'ChevronDown' }}
                onClick={() => {
                  setShowAllSources((show) => !show);
                  // remove animated appearance if applicable
                  wasCompleted.current = fragment.isCompleted;
                }}
              >
                {showAllSources ? (
                  <span>{t('ai.chat.fragments.webSearch.showFewerSources')}</span>
                ) : (
                  <span>
                    {t('ai.chat.fragments.webSearch.showMoreSources', {
                      count: remainingItemCount
                    })}
                  </span>
                )}
              </ActionButton>
            </div>
          </li>
        ) : null}
      </ul>
    </ChatAuxiliaryFragmentWebSearchStyled>
  );
}
