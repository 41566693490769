import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ComboBox, SelectableOptionMenuItemType } from '@fluentui/react';

const languageList = [
  {
    key: 'bs',
    text: 'bosanski'
  },
  {
    key: 'el',
    text: 'Ελληνικά'
  },
  {
    key: 'en',
    text: 'english',
    frequent: true
  },
  {
    key: 'es',
    text: 'español',
    frequent: true
  },
  {
    key: 'de',
    text: 'deutsch',
    frequent: true
  },
  {
    key: 'af',
    text: 'afrikaans'
  },
  {
    key: 'ar',
    text: 'العربية'
  },
  {
    key: 'cs',
    text: 'čeština'
  },
  {
    key: 'db',
    text: 'български'
  },
  {
    key: 'da',
    text: 'dansk'
  },
  {
    key: 'fa',
    text: 'فارسی'
  },
  {
    key: 'fi',
    text: 'suomi'
  },
  {
    key: 'fr',
    text: 'français',
    frequent: true
  },
  {
    key: 'tr',
    text: 'türk'
  },
  {
    key: 'ga',
    text: 'gaeilge'
  },
  {
    key: 'hi',
    text: 'हिन्दी'
  },
  {
    key: 'hr',
    text: 'hrvatski'
  },
  {
    key: 'hu',
    text: 'magyar'
  },
  {
    key: 'id',
    text: 'bahasa indonesia'
  },
  {
    key: 'it',
    text: 'italiano'
  },
  {
    key: 'jp',
    text: '日本語'
  },
  {
    key: 'km',
    text: 'ភាសាខ្មែរ'
  },
  {
    key: 'ko',
    text: '한국어'
  },
  {
    key: 'lt',
    text: 'lietuvių'
  },
  {
    key: 'lv',
    text: 'latviešu'
  },
  {
    key: 'ms',
    text: 'bahasa melayu'
  },
  {
    key: 'nl',
    text: 'Nederlands'
  },
  {
    key: 'no',
    text: 'norsk bokmål'
  },
  {
    key: 'pl',
    text: 'polski'
  },
  {
    key: 'pt',
    text: 'português'
  },
  {
    key: 'ro',
    text: 'română'
  },
  {
    key: 'ru',
    text: 'русский'
  },
  {
    key: 'sk',
    text: 'slovenčina'
  },
  {
    key: 'sl',
    text: 'slovenščina'
  },
  {
    key: 'sr',
    text: 'српски/srpski'
  },
  {
    key: 'sv',
    text: 'svenska'
  },
  {
    key: 'th',
    text: 'ไทย'
  },
  {
    key: 'vi',
    text: 'tiếng việt'
  },
  {
    key: 'zh',
    text: '中文'
  }
];

function LanguagePicker({
  disabled,
  placeholder,
  label,
  required,
  styles,
  onChange,
  translatedLanguages,
  defaultLanguageKey
}) {
  const { t } = useTranslation();

  const [languages, setLanguages] = useState(null);

  let userLang = navigator.language || navigator.userLanguage;

  if (userLang.includes('-')) {
    [userLang] = userLang.split('-');
  }

  function sortLanguages(items) {
    const compare = (a, b) => {
      if (a.text < b.text) {
        return -1;
      }
      if (a.text > b.text) {
        return 1;
      }
      return 0;
    };

    const sortedItems = items.sort(compare);

    return sortedItems;
  }

  function filterLanguages() {
    if (languageList?.length) {
      let sortedLanguages = [];

      const filteredLanguages = languageList.filter((x) => x.key !== userLang);

      const topLanguages = [];
      const frequentLanguages = [];
      const otherLanguages = [];

      filteredLanguages.map((lang) => {
        let langIsTranslated = false;

        if (translatedLanguages?.length) {
          translatedLanguages.map((usedLang) => {
            if (lang.key === usedLang) {
              langIsTranslated = true;
            }

            return null;
          });
        }

        if (langIsTranslated) {
          topLanguages.push(lang);
        } else if (lang.frequent) {
          frequentLanguages.push(lang);
        } else {
          otherLanguages.push(lang);
        }

        return null;
      });

      if (topLanguages?.length) {
        sortedLanguages.push({
          key: 'translatedHeader',
          text: t('languagePicker.header.translatedLanguages'),
          itemType: SelectableOptionMenuItemType.Header
        });
        sortedLanguages = sortedLanguages.concat(sortLanguages(topLanguages));
      }

      if (frequentLanguages?.length) {
        if (topLanguages?.length) {
          sortedLanguages.push({
            key: 'divider_1',
            text: '-',
            itemType: SelectableOptionMenuItemType.Divider
          });
        }
        sortedLanguages.push({
          key: 'frequentHeader',
          text: t('languagePicker.header.usedLanguages'),
          itemType: SelectableOptionMenuItemType.Header
        });
        sortedLanguages = sortedLanguages.concat(sortLanguages(frequentLanguages));
      }

      if (otherLanguages?.length) {
        if (topLanguages?.length || frequentLanguages?.length) {
          sortedLanguages.push({
            key: 'divider_2',
            text: '-',
            itemType: SelectableOptionMenuItemType.Divider
          });
        }
        sortedLanguages.push({
          key: 'restHeader',
          text: t('languagePicker.header.otherLanguages'),
          itemType: SelectableOptionMenuItemType.Header
        });

        sortedLanguages = sortedLanguages.concat(sortLanguages(otherLanguages));
      }

      setLanguages(sortedLanguages);
    }
  }

  if (!languages?.length) {
    filterLanguages();
  }

  return (
    <ComboBox
      defaultSelectedKey={defaultLanguageKey}
      disabled={disabled}
      placeholder={placeholder}
      label={label}
      onChange={(_, value) => onChange(value)}
      autoComplete="on"
      allowFreeform
      options={languages || []}
      required={required}
      styles={{ optionsContainerWrapper: { maxHeight: '250px' }, ...styles }}
      useComboBoxAsMenuWidth
    />
  );
}

LanguagePicker.propTypes = {
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  styles: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  translatedLanguages: PropTypes.arrayOf(PropTypes.string),
  defaultLanguageKey: PropTypes.string
};

LanguagePicker.defaultProps = {
  disabled: false,
  placeholder: null,
  label: null,
  styles: null,
  required: false,
  translatedLanguages: null,
  defaultLanguageKey: null
};

export default LanguagePicker;
