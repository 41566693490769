import SignatureField, { ISignatureFieldProps } from 'components/inputs/SignatureField';
import { IFieldLinkProps, RouteFieldVisibility } from 'types';
import { getFluentIconNameByFieldType } from 'utils/helpers';

export interface IDynamicSignatureFieldProps {
  disabled?: boolean;
  fieldLink: IFieldLinkProps;
  onChange: ISignatureFieldProps['onChange'];
}

function DynamicSignatureField({ fieldLink, disabled, onChange }: IDynamicSignatureFieldProps) {
  if (!fieldLink.field) return null;

  const { field, visibility } = fieldLink;
  const { name, description, descriptionPlacement, id } = field;
  const defaultValue = fieldLink.value as ISignatureFieldProps['defaultValue'];

  const displayDescriptionTooltip = descriptionPlacement === 1;

  const readOnly = visibility === RouteFieldVisibility.ReadOnly;
  const required = visibility === RouteFieldVisibility.Required;

  return (
    <SignatureField
      id={id}
      label={name}
      description={displayDescriptionTooltip ? description : undefined}
      labelIconName={getFluentIconNameByFieldType(fieldLink.field)}
      defaultValue={defaultValue}
      onChange={onChange}
      disabled={disabled || readOnly}
      required={required}
    />
  );
}

export default DynamicSignatureField;
