import { ComponentType } from 'react';

import { IMessageFragmentLocalToolCall } from '../../../services/ChatConnection/model';

export interface IToolRendererProps {
  fragment: IMessageFragmentLocalToolCall;
}

export type IToolRenderers = Record<string, ComponentType<IToolRendererProps>>;

export default function ChatMessageFragmentToolCall({
  fragment,
  toolRenderers
}: {
  fragment: IMessageFragmentLocalToolCall;
  /** must be memoized! */
  toolRenderers: IToolRenderers;
}) {
  const ToolRenderer = toolRenderers[fragment.toolId];

  if (ToolRenderer) {
    return <ToolRenderer fragment={fragment} />;
  }

  return null;
}
