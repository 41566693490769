import i18next from 'i18next';
import { isEqual } from 'lodash';
import { RefObject, createRef, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  ActionButton,
  ComboBox,
  CommandBar,
  DetailsList,
  IColumn,
  IComboBox,
  IconButton,
  Label,
  List,
  Persona,
  PersonaSize,
  TextField
} from '@fluentui/react';
import fetchRequest, { apiErrorHandler } from '../../../../services/api';
import {
  DepartmentType,
  ICountryProps,
  ILocationProps,
  ISmallTeamProps,
  ITeamMemberProps,
  ITeamProps,
  IUserProps,
  MemberRole,
  TeamType
} from '../../../../types';
import breakpoints from '../../../../utils/breakpoints';
import { checkScreenWidth } from '../../../../utils/helpers';
import CombinedPicker from '../../../inputs/CombinedPickerJS/CombinedPickerJS';

const getTypeOfTeamOptions = (allowedTeamTypes: TeamType[]) => {
  const allOptions = [
    {
      key: TeamType.Department,
      text: i18next.t('teamPanelNewEdit.form.value.typeOfTeam.department')
    },
    { key: TeamType.Internal, text: i18next.t('teamPanelNewEdit.form.value.typeOfTeam.internal') },
    { key: TeamType.External, text: i18next.t('teamPanelNewEdit.form.value.typeOfTeam.external') },
    { key: TeamType.Board, text: i18next.t('teamPanelNewEdit.form.value.typeOfTeam.board') }
  ];

  if (allowedTeamTypes.length === 0) {
    return allOptions;
  }

  return allOptions.filter((option) => allowedTeamTypes.includes(option.key));
};

const getDepartmentOptions = (t: (id: string) => string) => {
  // sets department options for dropdown
  // create option out of i18n references and push option-object into departmentOptions
  const departmentOptions: { key: number; text: string }[] = Object.values(DepartmentType)
    .filter((item) => typeof item === 'number')
    .map((key: number) => ({
      key,
      text: t(`teamPanelNewEdit.form.value.department.${key}`)
    }));
  // sort departmentOptions by text
  return departmentOptions.sort((a, b) => a.text.localeCompare(b.text));
};

function getInitialEdit(teamToEdit: ITeamProps | undefined, currentUser: IUserProps): ITeamProps {
  if (teamToEdit?.id) {
    return teamToEdit;
  }

  return {
    typeId: null,
    departmentId: 0,
    title: '',
    parent: null,
    members: [{ ...currentUser, roleId: MemberRole.TeamLeader }],
    permissions: {
      delete: true,
      update: true
    },
    ...(teamToEdit || {})
  };
}

function shouldShowParentTeam(team: ITeamProps, administration: boolean): boolean {
  return (
    administration && !(team?.typeId === TeamType.TenantAdmin || team?.typeId === TeamType.AllUsers)
  );
}

function isNewlyCreatedTeam(team: ITeamProps) {
  return !team?.id || team.id === '00000000-0000-0000-0000-000000000000';
}

function canSaveTeam(team: ITeamProps, savedTeam?: ITeamProps) {
  if (savedTeam && isEqual(team, savedTeam)) {
    // nothing changed
    return false;
  }

  if (team.title && team.typeId > 0 && team.country && team.location) {
    return true;
  }
  if (team.typeId === TeamType.TenantAdmin) {
    return true;
  }
  return false;
}

function getTeamLeaderCount(team: ITeamProps) {
  return team.members.filter((member) => member.roleId === MemberRole.TeamLeader).length;
}

const NewEditTeamFormStyled = styled.div`
  padding: 1rem;
  padding-bottom: 0px;

  .form-fields-width {
    width: 100%;
  }

  .ms-ComboBox::after,
  .ms-TextField-fieldGroup::after,
  .ms-BasePicker-text::after,
  .ms-BasePicker-text,
  .ms-ComboBox,
  .ms-TextField-fieldGroup {
    border-radius: 4px;
    overflow: hidden;
  }

  .team-panel-new-edit-details-list div[role='gridcell'] {
    line-height: 24px;
    height: 36px;
  }
`;

export default function TeamPanelNewEdit({
  currentUser,
  teamToEdit,
  onSave,
  onDelete,
  tasksLength,
  administration,
  allowedTeamTypes
}: {
  currentUser: IUserProps;
  teamToEdit: ITeamProps;
  onSave: (team: ITeamProps) => Promise<void>;
  onDelete: () => void;
  tasksLength: number;
  administration?: boolean;
  allowedTeamTypes?: TeamType[];
}) {
  // we use an 'edit + manual save' model here, so this is our edited state.
  const [editedTeam, setEditedTeam] = useState(() => getInitialEdit(teamToEdit, currentUser));

  useEffect(() => {
    setEditedTeam(getInitialEdit(teamToEdit, currentUser));
  }, [teamToEdit, currentUser]);

  const [isSaving, setSaving] = useState(false);

  const save = () => {
    setSaving(true);
    onSave(editedTeam).finally(() => {
      setSaving(false);
    });
  };

  return (
    <form>
      <TeamCommandBar
        team={editedTeam}
        savedTeam={teamToEdit}
        disabled={isSaving}
        onSave={save}
        onDelete={onDelete}
        tasksLength={tasksLength}
      />
      <div className="panel-body-wrapper">
        <NewEditTeamFormStyled>
          <GeneralSettings
            team={editedTeam}
            onTeamChange={setEditedTeam}
            disabled={isSaving}
            administration={administration}
            allowedTeamTypes={allowedTeamTypes}
          />
        </NewEditTeamFormStyled>
        <TeamMembers
          currentUser={currentUser}
          team={editedTeam}
          onTeamChange={setEditedTeam}
          disabled={isSaving}
        />
      </div>
    </form>
  );
}

const TeamCommandBarStyled = styled(CommandBar)`
  .command-bar-item:hover {
    background-color: #e5eff8;
  }

  .command-bar-item {
    background-color: #f4f9fd;
  }

  .ms-FocusZone.ms-CommandBar {
    background-color: #f4f9fd;
  }

  .hideItem {
    display: none;
  }
`;

function TeamCommandBar({
  team,
  savedTeam,
  disabled,
  onSave,
  onDelete,
  tasksLength
}: {
  team: ITeamProps;
  savedTeam?: ITeamProps;
  disabled: boolean;
  onSave: () => void;
  onDelete: () => void;
  tasksLength: number;
}) {
  const { t } = useTranslation();

  const isEdit = !isNewlyCreatedTeam(team);

  const cmdBarItems = [];
  const cmdBarFarItems = [];

  if (team.permissions?.delete) {
    cmdBarFarItems.push({
      key: 'delete',
      className: 'command-bar-item',
      text: t('teamPanelNewEdit.commandBar.button.delete'),
      iconProps: {
        iconName: 'delete'
      },
      disabled: !isEdit || tasksLength > 0,
      onClick: onDelete
    });
  }

  if (team.permissions?.update) {
    cmdBarItems.push({
      key: 'save',
      className: 'command-bar-item',
      text: t('teamPanelNewEdit.commandBar.button.save'),
      iconProps: {
        iconName: 'save'
      },
      disabled: disabled || !canSaveTeam(team, savedTeam),
      onClick: onSave
    });
  }

  let commandBar = null;
  if (cmdBarItems.length || cmdBarFarItems.length) {
    commandBar = <TeamCommandBarStyled items={cmdBarItems} farItems={cmdBarFarItems} />;
  }

  return (
    <div className={`${checkScreenWidth(['extraSmall']) ? null : 'sticky'}`}>{commandBar}</div>
  );
}

function convertToDropDownObject(countriesOrLocations) {
  return countriesOrLocations.map((countryOrLocation) => {
    return {
      ...countryOrLocation,
      text: countryOrLocation.name,
      key: countryOrLocation.id
    };
  });
}

function GeneralSettings({
  team,
  onTeamChange,
  disabled,
  administration,
  allowedTeamTypes
}: {
  team: ITeamProps;
  onTeamChange: (team: ITeamProps) => void;
  disabled: boolean;
  administration: boolean;
  allowedTeamTypes: TeamType[];
}) {
  const { t } = useTranslation();

  const onTitleChange = (title: string) => onTeamChange({ ...team, title });

  const showParentTeam = shouldShowParentTeam(team, administration);
  const onParentTeamChange = (parent: ISmallTeamProps) => onTeamChange({ ...team, parent });

  const comboTypeRef = createRef<IComboBox>();
  const typeOfTeamOptions = useMemo(
    () => getTypeOfTeamOptions(allowedTeamTypes),
    [allowedTeamTypes]
  );
  const onTeamTypeChange = (typeId?: TeamType) => onTeamChange({ ...team, typeId });

  const comboDepartmentRef = createRef<IComboBox>();
  const departmentOptions = useMemo(() => getDepartmentOptions(t), [t]);
  const onDepartmentChange = (departmentId?: number) => onTeamChange({ ...team, departmentId });

  const comboCountryRef = createRef<IComboBox>();
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    fetchRequest({ url: 'Countries' })
      .then((countries) => {
        if (countries) {
          setCountries(convertToDropDownObject(countries));
        }
      })
      .catch(apiErrorHandler);
  }, []);
  const onCountryChange = (country?: ICountryProps) => onTeamChange({ ...team, country });

  const comboLocationRef = createRef<IComboBox>();
  const [locations, setLocations] = useState([]);
  useEffect(() => {
    fetchRequest({ url: 'Locations' })
      .then((locations) => {
        if (locations) {
          setLocations(convertToDropDownObject(locations));
        }
      })
      .catch(apiErrorHandler);
  }, []);
  const onLocationChange = (location?: ILocationProps) => onTeamChange({ ...team, location });

  const [toFocusCombo, setToFocusCombo] = useState(true);
  const delayMenuDropdown = (ref: RefObject<IComboBox>) => {
    if (checkScreenWidth(['extraSmall'])) {
      if (toFocusCombo) {
        if (ref.current) {
          const comboBox = { ...ref.current };
          ref.current.dismissMenu();
          setToFocusCombo(false);
          setTimeout(() => {
            if (comboBox) {
              comboBox.focus(true);
            }
          }, 400);
        }
      } else {
        setToFocusCombo(true);
      }
    }
  };

  return (
    <div className="form-fields-width">
      <TextField
        autoFocus
        disabled={disabled || team?.typeId === TeamType.TenantAdmin}
        label={t('teamPanelNewEdit.form.label.title')}
        onChange={(_, value) => onTitleChange(value)}
        required
        value={team.title}
      />

      {showParentTeam ? (
        <div>
          <Label>{t('teamPanelNewEdit.form.label.parentTeam')}</Label>
          <CombinedPicker
            searchTeams
            itemLimit={1}
            selectedItems={team?.parent}
            onChange={([parent]) => onParentTeamChange(parent)}
            userToFilter={team?.id ? [{ id: team.id }] : []}
          />
        </div>
      ) : null}

      <div className={checkScreenWidth(['extraSmall']) ? null : 'flex'}>
        <ComboBox
          componentRef={comboTypeRef}
          allowFreeform
          autoComplete="on"
          calloutProps={{ calloutMaxHeight: checkScreenWidth(['extraSmall']) ? 150 : 250 }}
          disabled={team?.id && team.id !== '00000000-0000-0000-0000-000000000000'}
          label={t('teamPanelNewEdit.form.label.typeOfTeam')}
          onChange={(_, value) => onTeamTypeChange(value?.key as number | undefined)}
          options={typeOfTeamOptions}
          placeholder={t('teamPanelNewEdit.form.value.teamType.placeholder')}
          required
          selectedKey={team.typeId}
          styles={{ container: { width: '100%', marginRight: '10px' } }}
          onMenuOpen={
            checkScreenWidth(['extraSmall']) ? () => delayMenuDropdown(comboTypeRef) : null
          }
        />
        <ComboBox
          componentRef={comboDepartmentRef}
          allowFreeform
          autoComplete="on"
          calloutProps={{ calloutMaxHeight: 250 }}
          disabled={team?.typeId === TeamType.TenantAdmin}
          label={t('teamPanelNewEdit.form.label.department')}
          onChange={(_, value) => onDepartmentChange(value?.key as number | undefined)}
          options={departmentOptions || []}
          placeholder={t('teamPanelNewEdit.form.value.department.placeholder')}
          selectedKey={team.departmentId}
          key={team.departmentId}
          styles={{ container: { width: '100%' } }}
          onMenuOpen={
            checkScreenWidth(['extraSmall']) ? () => delayMenuDropdown(comboDepartmentRef) : null
          }
        />
      </div>
      <div className={checkScreenWidth(['extraSmall']) ? null : 'flex'}>
        <ComboBox
          componentRef={comboCountryRef}
          allowFreeform
          autoComplete="on"
          calloutProps={{
            calloutMaxHeight: checkScreenWidth(['extraSmall']) ? 110 : 250,
            coverTarget: false
          }}
          dropdownMaxWidth={200}
          useComboBoxAsMenuWidth={false}
          disabled={team?.typeId === TeamType.TenantAdmin}
          label={t('teamPanelNewEdit.form.value.country.label')}
          onChange={(_, value) => onCountryChange(value)}
          options={countries || []}
          placeholder={t('teamPanelNewEdit.form.value.country.placeholder')}
          required
          selectedKey={team.country ? team.country.id : null}
          styles={{
            container: {
              width: '100%',
              marginRight: '10px'
            }
          }}
          onMenuOpen={
            checkScreenWidth(['extraSmall']) ? () => delayMenuDropdown(comboCountryRef) : null
          }
        />
        <ComboBox
          componentRef={comboLocationRef}
          allowFreeform
          autoComplete="on"
          calloutProps={{
            calloutMaxHeight: checkScreenWidth(['extraSmall']) ? 110 : 250,
            coverTarget: false
          }}
          disabled={team?.typeId === TeamType.TenantAdmin}
          label={t('teamPanelNewEdit.form.value.location.label')}
          onChange={(_, value) => onLocationChange(value)}
          options={locations || []}
          placeholder={t('teamPanelNewEdit.form.value.location.placeholder')}
          required
          selectedKey={team.location ? team.location.id : null}
          styles={{ container: { width: '100%' } }}
          onMenuOpen={
            checkScreenWidth(['extraSmall']) ? () => delayMenuDropdown(comboLocationRef) : null
          }
        />
      </div>
    </div>
  );
}

const AddMemberStyled = styled.div`
  position: ${checkScreenWidth(['extraSmall', 'small']) ? null : 'sticky'};
  bottom: 0px;
  padding: 10px 0 10px 1px;
  background-color: white;

  .close {
    background-color: white;
    border: none;
    &:hover {
      background-color: white;
    }
  }

  .add-member-input {
    padding-bottom: 5px;
    padding-right: 15px;
    border-bottom: 1px solid ${(props) => props.theme.themePrimary};
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: space-between;

    @media (max-width: ${breakpoints.extraSmallMax}px) {
      padding-right: 0;
    }
  }

  @media (max-width: ${breakpoints.smallMax}px) {
    padding: 5px 5px 5px 1px;
  }
`;

const CircleAdditionButtonStyled = styled(ActionButton)`
  background-color: white;
  padding-left: 6px;

  &:hover {
    background-color: white;
  }

  .ms-Button-icon.member-add-icon {
    color: ${(props) => props.theme.themePrimary};
    font-size: 22px;
  }

  @media (max-width: ${breakpoints.smallMax}px) {
    padding-left: 0;
  }
`;

const ListStyled = styled(List)`
  .member-list-cell {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #f2f2f2;
    padding: 5px;
  }
  .list-persona-container {
    overflow: hidden;
  }
`;

const TeamDetailsListStyled = styled(DetailsList)`
  overflow-x: hidden;
  .ms-DetailsRow.is-selected {
    background-color: white;
  }

  .ms-DetailsRow:hover {
    background-color: white;
  }

  .hideItem {
    display: none;
  }
`;

type AddMemberParams = Partial<ITeamMemberProps> & { primaryText?: string };

function TeamMembers({
  currentUser,
  team,
  onTeamChange,
  disabled
}: {
  currentUser: IUserProps;
  team: ITeamProps;
  onTeamChange: (team: ITeamProps) => void;
  disabled: boolean;
}) {
  const onAddMember = (params: AddMemberParams) => {
    const { userId, login, primaryText, name, pictureUrl, tenantId } = params;

    const newMember = {
      userId: userId || '00000000-0000-0000-0000-000000000000',
      login: login || primaryText || '',
      name: name || '',
      pictureUrl: pictureUrl || '',
      tenantId: tenantId || '00000000-0000-0000-0000-000000000000',
      roleId: MemberRole.TeamMember
    };

    onTeamChange({ ...team, members: [...team.members, newMember] });
  };

  const onMemberChange = (member: ITeamMemberProps) => {
    const index = team.members.findIndex(
      (item) => item.userId === member.userId && item.login === member.login
    );
    if (index === -1) return;

    const members = team.members.slice();
    members[index] = member;
    onTeamChange({ ...team, members });
  };

  const onMemberRemove = (member: ITeamMemberProps) => {
    const index = team.members.indexOf(member);
    if (index === -1) return;
    const members = team.members.slice();
    members.splice(index, 1);
    onTeamChange({ ...team, members });
  };

  return checkScreenWidth(['extraSmall', 'small']) ? (
    <>
      <AddMember team={team} onAdd={onAddMember} disabled={disabled} narrow />
      <TeamMemberListNarrow
        currentUser={currentUser}
        team={team}
        onMemberChange={onMemberChange}
        onMemberRemove={onMemberRemove}
        disabled={disabled}
      />
    </>
  ) : (
    <>
      <div className="team-panel-new-edit-details-list">
        <TeamMemberList
          currentUser={currentUser}
          team={team}
          onMemberChange={onMemberChange}
          onMemberRemove={onMemberRemove}
          disabled={disabled}
        />
      </div>
      <AddMember team={team} onAdd={onAddMember} disabled={disabled} />
    </>
  );
}

function AddMemberInput({
  team,
  onAdd,
  onClose,
  narrow,
  disabled
}: {
  team: ITeamProps;
  onAdd: (params: AddMemberParams) => void;
  onClose: () => void;
  narrow?: boolean;
  disabled?: boolean;
}) {
  const { t } = useTranslation();

  const [email, setEmail] = useState('');

  const canSave = useMemo(() => {
    if (!email) {
      return false;
    }

    // Regular expression for email validation
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

    // checks if e-mail is already used
    const alreadyUsed =
      team.members.findIndex((user) => user.login.toLowerCase() === email.toLowerCase()) > -1;

    return email.match(regex) && !alreadyUsed;
  }, [email, team.members]);

  return (
    <div className="flex add-member-input">
      <div
        style={{
          paddingLeft: checkScreenWidth(['extraSmall']) ? '34px' : '39px',
          maxWidth: '80%',
          width: '100%'
        }}
      >
        <CombinedPicker
          borderless
          disabled={disabled}
          placeholder={t('teamPanelNewEdit.addMember.placeholder')}
          focusPeoplePicker
          onFilterTextChange={setEmail}
          onChange={(users: IUserProps[]) => {
            if (!users.length) return;
            onAdd({ ...users[0], primaryText: email });
            onClose();
          }}
          userToFilter={team.members}
          createNewUserAllowed
          searchUser
          type={1}
        />
      </div>
      <div style={{ display: 'flex' }}>
        {!narrow && (
          <IconButton
            className="close"
            iconProps={{ iconName: 'save', className: 'close' }}
            onClick={() => {
              onAdd({ primaryText: email });
              onClose();
            }}
            disabled={!canSave}
          />
        )}
        <IconButton
          className="close"
          iconProps={{ iconName: 'CalculatorMultiply', className: 'close' }}
          onClick={onClose}
        />
      </div>
    </div>
  );
}

function AddMember({
  team,
  onAdd,
  narrow,
  disabled
}: {
  team: ITeamProps;
  onAdd: (params: AddMemberParams) => void;
  narrow?: boolean;
  disabled?: boolean;
}) {
  const { t } = useTranslation();
  const [isAdding, setAdding] = useState(false);

  return (
    <AddMemberStyled>
      {!isAdding || disabled ? (
        <CircleAdditionButtonStyled
          styles={{ label: { fontWeight: '500' } }}
          text={t('teamPanelNewEdit.addMember.button.addNew')}
          iconProps={{
            iconName: 'CircleAddition',
            className: 'member-add-icon'
          }}
          onClick={() => setAdding(true)}
          disabled={disabled}
        />
      ) : (
        <AddMemberInput
          team={team}
          onAdd={onAdd}
          onClose={() => setAdding(false)}
          narrow={narrow}
        />
      )}
    </AddMemberStyled>
  );
}

function TeamMemberListNarrow({
  currentUser,
  team,
  onMemberChange,
  onMemberRemove,
  disabled
}: {
  currentUser: IUserProps;
  team: ITeamProps;
  onMemberChange: (member: ITeamMemberProps) => void;
  onMemberRemove: (member: ITeamMemberProps) => void;
  disabled?: boolean;
}) {
  const { t } = useTranslation();

  const isEdit = !isNewlyCreatedTeam(team);
  const teamLeaderCount = useMemo(() => getTeamLeaderCount(team), [team]);

  return (
    <ListStyled
      items={team.members}
      onRenderCell={(member: ITeamMemberProps) => {
        return (
          <div className="member-list-cell">
            <div className="list-persona-container">
              <Persona
                text={member.name || member.login}
                imageUrl={member.pictureUrl}
                size={PersonaSize.size24}
                showSecondaryText
                secondaryText={
                  member.roleId === 1
                    ? t('teamPanelNewEdit.detailList.role.teamLeader')
                    : t('teamPanelNewEdit.detailList.role.teamMember')
                }
              />
            </div>
            <div>
              <TeamMemberActions
                member={member}
                onMemberChange={onMemberChange}
                onMemberRemove={onMemberRemove}
                isEdit={isEdit}
                teamLeaderCount={teamLeaderCount}
                currentUser={currentUser}
                disabled={disabled}
              />
            </div>
          </div>
        );
      }}
    />
  );
}

function TeamMemberList({
  currentUser,
  team,
  onMemberChange,
  onMemberRemove,
  disabled
}: {
  currentUser: IUserProps;
  team: ITeamProps;
  onMemberChange: (member: ITeamMemberProps) => void;
  onMemberRemove: (member: ITeamMemberProps) => void;
  disabled?: boolean;
}) {
  const { t } = useTranslation();

  const isEdit = !isNewlyCreatedTeam(team);
  const teamLeaderCount = useMemo(() => getTeamLeaderCount(team), [team]);

  const [sortByField, setSortByField] = useState<{
    field: string;
    descending: boolean;
  }>({ field: 'role', descending: false });

  const sortedMembers = useMemo(() => {
    const { field, descending } = sortByField;
    const actualFieldName = {
      member: 'name',
      role: 'roleId'
    }[field];
    if (!actualFieldName) return team.members;

    return team.members.slice().sort((a, b) => {
      let aValue = a[actualFieldName];
      let bValue = b[actualFieldName];

      if (descending) {
        [bValue, aValue] = [aValue, bValue];
      }

      if (typeof aValue === 'number') {
        return aValue - bValue;
      }

      aValue = (aValue || '').toString();
      bValue = (bValue || '').toString();

      return aValue.toString().localeCompare(bValue);
    });
  }, [team, sortByField]);

  const onSortArray = (_: unknown, column: IColumn) => {
    if (sortByField.field === column.fieldName) {
      // if column is already sorted, sort descending
      setSortByField({ field: column.fieldName, descending: !sortByField.descending });
    } else {
      setSortByField({ field: column.fieldName, descending: false });
    }
  };

  const getColumnSorting = (field: string) => ({
    isSorted: sortByField.field === field,
    isSortedDescending: sortByField.field === field && sortByField.descending
  });

  return (
    <TeamDetailsListStyled
      selectionMode={0}
      onColumnHeaderClick={onSortArray}
      items={sortedMembers}
      columns={[
        {
          name: t('teamPanelNewEdit.detailList.member'),
          fieldName: 'member',
          ...getColumnSorting('member'),
          key: 'member',
          minWidth: 40,
          onRender: (item) => (
            <Persona
              text={item.name || item.login}
              imageUrl={item.pictureUrl}
              size={PersonaSize.size24}
            />
          )
        },
        {
          key: 'role',
          name: t('teamPanelNewEdit.detailList.role.label'),
          fieldName: 'role',
          ...getColumnSorting('role'),
          minWidth: null,
          onRender: (item) => (
            <div>
              {item.roleId === 1
                ? t('teamPanelNewEdit.detailList.role.teamLeader')
                : t('teamPanelNewEdit.detailList.role.teamMember')}
            </div>
          )
        },
        {
          key: 'actions',
          name: '',
          fieldName: '',
          columnActionsMode: 0,
          minWidth: 40,
          maxWidth: 40,
          onRender: (item) => (
            <TeamMemberActions
              member={item}
              onMemberChange={onMemberChange}
              onMemberRemove={onMemberRemove}
              isEdit={isEdit}
              teamLeaderCount={teamLeaderCount}
              currentUser={currentUser}
              disabled={disabled}
            />
          )
        }
      ]}
    />
  );
}

const ChevronButtonContainer = styled.div`
  @media (max-width: ${breakpoints.smallMax}px) {
    max-width: 60px;
  }
`;

const ChevronDownDefaultButtonStyled = styled(ActionButton)`
  background-color: white;
  &:hover {
    background-color: white;
  }
  max-height: 25px;

  @media (max-width: ${breakpoints.smallMax}px) {
    padding-bottom: 0;
  }
`;

function TeamMemberActions({
  member,
  onMemberChange,
  onMemberRemove,
  isEdit,
  teamLeaderCount,
  currentUser,
  disabled
}: {
  member: ITeamMemberProps;
  onMemberChange: (member: ITeamMemberProps) => void;
  onMemberRemove: (member: ITeamMemberProps) => void;
  isEdit: boolean;
  teamLeaderCount: number;
  currentUser: IUserProps;
  disabled?: boolean;
}) {
  const { t } = useTranslation();

  const menuItems = [
    {
      key: 'deleteMember',
      text: t('teamPanelNewEdit.detailList.menu.delete'),
      iconProps: { iconName: 'Delete' },
      onClick: () => {
        onMemberRemove(member);
      },
      disabled:
        (!isEdit && currentUser.userId === member.userId) ||
        (isEdit && teamLeaderCount < 2 && member.roleId === 1)
    }
  ];

  if (member.roleId === MemberRole.TeamLeader) {
    menuItems.push({
      key: 'roleToTeamMember',
      text: t('teamPanelNewEdit.detailList.menu.teamMember'),
      iconProps: { iconName: 'SingleColumnEdit' },
      onClick: () => {
        onMemberChange({ ...member, roleId: MemberRole.TeamMember });
      },
      disabled: teamLeaderCount < 2
    });
  }

  if (member.roleId === MemberRole.TeamMember) {
    menuItems.push({
      key: 'roleToTeamLeader',
      text: t('teamPanelNewEdit.detailList.menu.teamLeader'),
      iconProps: { iconName: 'SingleColumnEdit' },
      disabled: !member.userId,
      onClick: () => {
        onMemberChange({ ...member, roleId: MemberRole.TeamLeader });
      }
    });
  }

  return (
    <ChevronButtonContainer>
      <ChevronDownDefaultButtonStyled
        disabled={disabled}
        menuIconProps={{ iconName: 'ChevronDown' }}
        menuProps={{ items: menuItems }}
      />
    </ChevronButtonContainer>
  );
}
