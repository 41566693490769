import styled from 'styled-components';

export const WrapperStyled = styled.div`
  min-width: 200px;
  max-width: 300px;
  width: fit-content;

  .filter-items {
    display: flex;
    flex-direction: column;
    max-height: 500px;
    overflow-y: auto;
  }
`;

export const EmptyResultStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
`;
