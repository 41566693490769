import { Label } from 'components';
import { useTranslation } from 'react-i18next';
import fetchRequest from 'services/api';
import { IProjectProps } from 'types';
import { Checkbox, TagPicker } from '@fluentui/react';

export default function MsTeamsProjectConfig({ config, setConfig }) {
  const { t } = useTranslation();

  function onResolveProjects(searchTerm) {
    if (searchTerm) {
      return fetchRequest({
        url: `Projects?pageIndex=0&itemsPerPage=200&type=2&searchTerms=${searchTerm}`
      }).then((response) => response.items);
    }

    return undefined;
  }

  return (
    <>
      <Checkbox
        // @ts-expect-error: text prop allows JSX
        label={
          <div>
            <span style={{ fontWeight: 600 }}>{t('msTeams.config.checkBox.label.projects')}</span>
            <div style={{ maxWidth: 500 }}>{t('msTeams.config.checkBox.subtext.projects')}</div>
          </div>
        }
        checked={config.showProjects}
        disabled={config.selectedProcess || config.selectedTeam}
        styles={{ root: { marginTop: '15px' } }}
        onChange={(_, checked) => {
          setConfig((prevState) => ({
            ...prevState,
            showProjects: checked,
            showTagList: false,
            selectedProject: null
          }));
        }}
      />
      <Label
        styles={{ container: { marginTop: 5, marginLeft: 23, maxWidth: 505 } }}
        label={t('msTeams.config.projectPicker.label')}
        description={t('msTeams.config.projectPicker.description')}
      />
      <TagPicker
        resolveDelay={500}
        inputProps={{ placeholder: t('msTeams.config.projectPicker.placeholder') }}
        onResolveSuggestions={onResolveProjects}
        selectedItems={config.selectedProject ? [config.selectedProject] : []}
        getTextFromItem={(item) => item.name}
        disabled={!config.showProjects}
        pickerSuggestionsProps={{
          noResultsFoundText: t('msTeams.config.projectPicker.noResultsFoundText')
        }}
        onChange={(projects) => {
          if (projects.length > 0) {
            const project = projects[0] as unknown as IProjectProps;

            setConfig((prevState) => ({
              ...prevState,
              showTasks: false,
              showProcesses: false,
              selectedProcess: null,
              selectedTeam: null,
              selectedProject: {
                id: project.id,
                type: project.type,
                name: project.name,
                processAssociations: [] // Add this line to fix the missing property error
              }
            }));
          } else {
            setConfig((prevState) => ({
              ...prevState,
              selectedProject: null
            }));
          }
        }}
        itemLimit={1}
        styles={{ root: { maxWidth: 500, marginLeft: 28 } }}
      />
    </>
  );
}
