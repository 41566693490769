import { LoadingSpinner, Panel } from 'components';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { userPropType } from 'types';
import breakpoints from 'utils/breakpoints';
import { PanelType, getTheme } from '@fluentui/react';
import ViewPanelBody from './ViewPanelBody';

// styles
const ViewPanelHeaderStyled = styled.div`
  background-color: ${() => getTheme().palette.themePrimary};
  color: white;
  height: 100px;

  .new-edit-view-title {
    font-size: 28px;
    padding-left: 16px;
  }

  .new-edit-view-subtitle {
    font-size: 14px;
    padding-left: 16px;
  }

  @media (max-width: ${breakpoints.smallMax}px) {
    height: auto;

    .new-edit-view-title {
      font-size: 18px;
    }

    .new-edit-view-subtitle {
      font-size: 12px;
    }
  }
`;

function ViewPanel({ view, onClosePanel, onDeleteView, onViewUpdate, currentUser }) {
  const { t } = useTranslation();

  const { id, mode } = useParams();

  const isEdit = id && id !== '00000000-0000-0000-0000-000000000000';

  const [panelSize, setPanelSize] = useState('800px');

  function onDismiss(event) {
    if (event && event.type !== 'mousedown') {
      if (onClosePanel) {
        onClosePanel();
      }
    }
  }

  function getPanelContent() {
    if (!id || id === '00000000-0000-0000-0000-000000000000' || (id && view)) {
      return {
        header: (
          <ViewPanelHeaderStyled>
            <div className="new-edit-view-title">
              {isEdit ? t('viewPanelHeader.title.editView') : t('viewPanelHeader.title.newView')}
            </div>
            <div className="new-edit-view-subtitle">
              {view && isEdit ? `${view.name}` : t('viewPanelHeader.subTitle.create')}
            </div>
          </ViewPanelHeaderStyled>
        ),
        body: (
          <ViewPanelBody
            view={view}
            onClosePanel={onClosePanel}
            onDeleteView={onDeleteView}
            onViewUpdate={onViewUpdate}
            currentUser={currentUser}
            changePanelSize={setPanelSize}
          />
        )
      };
    }

    return {
      body: <LoadingSpinner label={t('viewPanel.body.loading')} />,
      header: <div style={{ minHeight: '131px' }} />
    };
  }

  return (
    <Panel
      isLightDismiss={false}
      isOpen={!!mode}
      onDismiss={onDismiss}
      onRenderBody={() => getPanelContent().body}
      onRenderHeader={() => <div>{getPanelContent().header}</div>}
      type={PanelType.custom}
      customWidth={panelSize}
    />
  );
}

ViewPanel.propTypes = {
  view: PropTypes.object,
  onClosePanel: PropTypes.func.isRequired,
  onDeleteView: PropTypes.func.isRequired,
  onViewUpdate: PropTypes.func.isRequired,
  currentUser: userPropType.isRequired
};
ViewPanel.defaultProps = {
  view: null
};

export default ViewPanel;
