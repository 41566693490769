import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { getPages } from 'services/fetchRequests';
import { PageLocation } from 'types';
import { useLocalStorage, writeStorage } from '@rehooks/local-storage';
import { useTenant } from './TenantContext';

export interface IPage {
  id: string;
  name: string;
  locations: PageLocation[];
  url: string;
}

const PagesContext = createContext({
  pages: [],
  revalidate: () => undefined
} as {
  pages: IPage[];
  revalidate: () => void;
});

export const usePages = (locations?: PageLocation[]) => {
  const { pages, revalidate } = useContext(PagesContext);

  const filteredPages = useMemo(
    () => ({
      pages:
        pages?.filter?.(
          (page) => !locations || page.locations.some((location) => locations.includes(location))
        ) || []
    }),
    [locations, pages]
  );

  return { ...filteredPages, revalidate };
};

export function PagesProvider({ children }: { children: React.ReactNode }) {
  const tenantId = useTenant();
  const [revalidateCounter, setRevalidateCounter] = useState(0);

  const [pages = []] = useLocalStorage<IPage[]>(`pages-${tenantId}`);
  const setPages = useCallback(
    (pages: IPage[]) => {
      writeStorage(`pages-${tenantId}`, pages);
    },
    [tenantId]
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _ = revalidateCounter;

    getPages(tenantId, {}).then((response) => {
      setPages(response);
    });

    return () => {
      setPages([]);
    };
  }, [tenantId, setPages, revalidateCounter]);

  const revalidate = useCallback(() => setRevalidateCounter((x) => x + 1), []);

  const value = useMemo(() => ({ pages, revalidate }), [pages, revalidate]);

  return <PagesContext.Provider value={value}>{children}</PagesContext.Provider>;
}
