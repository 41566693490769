import { EmptyListPlaceHolder, LoadingSpinner, MainCommandBar } from 'components';
import ListCustom from 'components/lists/ListCustom/ListCustom';
import DialogCustom from 'components/surfaces/Dialog/DialogCustom';
import { AppContext } from 'features/App';
import NoAccessContainer from 'pages/NoAccess';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import fetchRequest, { apiErrorHandler } from 'services/api';
import styled from 'styled-components';
import breakpoints from 'utils/breakpoints';
import { checkScreenWidth } from 'utils/helpers';
import { GroupHeader, IconButton, SelectionMode, TooltipHost } from '@fluentui/react';
import CreateTagDialog from '../components/CreateTagDialog';

const TagsRowStyled = styled.div`
  .ms-DetailsRow .ms-DetailsRow-fields {
    width: 100%;
  }

  &:hover {
    .toggleDeleteButton {
      visibility: visible;
    }
  }
`;

const TitleColumnStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TitleStyled = styled.div`
  color: #323130;

  &:hover {
    color: rgb(0, 69, 120);
    cursor: pointer;
    text-decoration: underline;
  }
`;

const TagsAdminStyled = styled.div`
  @media (max-width: ${breakpoints.extraSmallMax}px) {
    padding-bottom: 50px;
  }
`;

const TagsContainer = styled.div`
  margin: 1rem;
  margin-left: ${checkScreenWidth(['extraSmall']) ? 0 : '1rem'};
  width: ${checkScreenWidth(['extraSmall']) ? '350px' : '450px'};

  .ms-DetailsRow-check {
    height: 100%;
  }

  .toggleDeleteButton {
    visibility: ${checkScreenWidth(['extraSmall']) ? 'visible' : 'hidden'};
    background-color: transparent;
  }

  .ms-DetailsRow {
    width: 100%;
  }

  .ms-DetailsRow-cell {
    width: 100% !important;
    margin-left: 30px;
    margin-right: 0px;
  }

  .parentTagTitle {
    padding-top: 5px;
  }

  .childTagTitle {
    padding-top: 8px;
  }

  .simpleTagTitle {
    padding-top: 7px;
  }

  .titleDisabled {
    color: #bbbbbb;
    text-decoration: none;
    cursor: default;
  }

  .ms-List-cell:hover > div > div > .ms-TooltipHost > .toggleDeleteButton {
    visibility: visible;
  }

  .ms-List-page {
    &:hover {
      background-color: #f3f2f1;
    }
  }
`;

const GroupHeaderStyled = styled(GroupHeader)`
  .ms-GroupHeader-expand {
    margin-left: ${checkScreenWidth(['extraSmall']) ? '0.9rem' : '0.8rem'};
  }

  &:hover {
    .toggleDeleteButton {
      visibility: visible;
    }
  }
`;

const GroupHeaderTitle = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${(props) => (props.$isGroupedHeader ? '100%' : 'auto')};
  margin-left: ${(props) => (props.$isGroupedHeader ? '14px' : '58px')};
  font-size: 16px;
  font-weight: 600;
  margin-right: 8px;
`;

function TagsAdministration() {
  const { globalAppState } = useContext(AppContext);
  const { currentUser, searchTerm } = globalAppState;

  const [isLoading, setIsLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);

  // dialog visibility states
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  // Tag to edit/new Tag
  const [tag, setTag] = useState(null);

  // Taglist
  const [tags, setTags] = useState(null);
  const [groupCollapseState, setGroupCollapseState] = useState(null);

  const { t } = useTranslation();

  const defaultFilterState = [
    { key: 0, text: t('tagsAdmin.filter.option.all'), selected: true }, // All tags
    { key: 1, text: t('tagsAdmin.filter.option.grouped'), selected: false }, // Grouped tags
    { key: 2, text: t('tagsAdmin.filter.option.simple'), selected: false } // Simple tags
  ];
  const [filterOptions, setFilterOptions] = useState(defaultFilterState);

  const columns = [
    {
      key: 'tagText',
      name: t('tagsAdmin.column.tagText'),
      fieldName: 'tagText',
      className: 'textColumn',
      minWidth: checkScreenWidth(['extraSmall']) ? window.innerWidth - 35 : 300,
      maxWidth: checkScreenWidth(['extraSmall']) ? window.innerWidth - 35 : 380,
      isResizable: true,
      isMultiline: true,
      columnActionsMode: 0,
      onRender: (row) => (
        <TitleColumnStyled>
          <TitleStyled
            className={`childTagTitle ${row.disabled ? 'titleDisabled' : ''}`}
            role="presentation"
            onClick={() => !row.disabled && onEditTag(row)}
          >
            {row.name}
          </TitleStyled>
          {!row.disabled || !checkIsParentDeleted(row.parentId) ? (
            <TooltipHost
              content={
                row.disabled ? t('tagsAdmin.column.activateTag') : t('tagsAdmin.column.deleteTag')
              }
              calloutProps={{ gapSpace: 5, target: `#btn-toggleDelete-${row.key}` }}
            >
              <IconButton
                id={`btn-toggleDelete-${row.key}`}
                className="toggleDeleteButton"
                iconProps={{
                  iconName: row.disabled ? 'CheckMark' : 'Untag'
                }}
                onClick={() => onToggleDeleteTag(row)}
                styles={{
                  root: {
                    marginLeft: '30px'
                  },
                  icon: {
                    fontSize: '18px',
                    fontWeight: '600'
                  }
                }}
              />
            </TooltipHost>
          ) : null}
        </TitleColumnStyled>
      )
    }
  ];

  const loadTagsFromApi = useCallback(() => {
    setIsLoading(true);

    fetchRequest({ url: 'Tags?showDeleted=true' })
      .then((tags) => {
        setTags(tags);
        setIsLoading(false);
      })
      .catch(apiErrorHandler);
  }, []);

  const onFilterChange = useCallback(
    (selectedOption) => {
      const setSelectedOption = (option) => {
        const cloneOption = { ...option, selected: false };

        if (!selectedOption && option.key === 0) {
          // reset filter
          cloneOption.selected = true;
        } else if (selectedOption && option.key === selectedOption.key) {
          cloneOption.selected = true;
        }

        return cloneOption;
      };

      const prevSelectedOption = filterOptions.find((option) => option.selected);

      if (selectedOption || prevSelectedOption.key !== 0) {
        // Change filter options only if a new option is selected or previously selected option is not default ("All tasks")
        const newFilterOptions = filterOptions.map(setSelectedOption);

        setFilterOptions(newFilterOptions);
      }
    },
    [filterOptions]
  );

  const getUnselectedFilterItems = () => {
    const unselectedOptions = filterOptions.filter((option) => !option.selected);

    const items = unselectedOptions.map((option) => ({
      key: option.key,
      text: option.text,
      onClick: () => onFilterChange(option)
    }));

    return items;
  };

  useEffect(() => {
    // call API only on initial load until paging is implemented
    // allTags contain all parent and children tags, while search result could
    // contain only children tags without necessary data about parent tag
    // Each child tag must be displayed under a parent tag, no mather if search returns only child tag(s).

    if (!initialized) {
      setInitialized(true);
      loadTagsFromApi();
    }
  }, [loadTagsFromApi, initialized]);

  function sortTags() {
    const tagsClone = [...tags];

    return tagsClone.slice(0).sort((a, b) => {
      if (a.parentId === b.paretntId) {
        return a && b && a.text > b.text ? 1 : -1;
      }

      return null;
    });
  }

  function doesTagTextMatchSearchTerm(tag) {
    return tag.text.toLowerCase().includes(searchTerm.trim().toLowerCase());
  }

  function filterTagsBymatchingSearchTerm(tagsToFilter = tags) {
    return tagsToFilter.filter((tag) => doesTagTextMatchSearchTerm(tag));
  }

  function findParentTag(parentId) {
    return tags.find((tag) => tag.id === parentId);
  }

  function findTagsBySearchTerm(tagsToSearch) {
    if (searchTerm) {
      const foundTags = [];

      tagsToSearch.map((tagToSearch) => {
        const matchingTextFound = doesTagTextMatchSearchTerm(tagToSearch);

        if (matchingTextFound) {
          if (tagToSearch.parentId) {
            const existingParentTag = foundTags.find((tag) => tag.id === tagToSearch.parentId);

            // If only child tag is found, include the parent tag too
            if (!existingParentTag) {
              const parentTag = findParentTag(tagToSearch.parentId);

              if (parentTag) {
                const children = filterTagsBymatchingSearchTerm(parentTag.children);
                foundTags.push({ ...parentTag, children });
              }
            }
          }

          foundTags.push(tagToSearch);
        }

        return null;
      });

      return foundTags;
    }

    return tagsToSearch;
  }

  function groupTags(tagsToFilter) {
    const groups = [];
    const children = [];
    let startIndex = 0;

    const type = filterOptions.find((option) => option.selected).key;

    const setChild = (tag) => {
      const existingChild = children.find((child) => child.key === tag.id);

      if (!existingChild) {
        children.push({
          key: tag.id,
          parentId: tag.parentId,
          name: tag.text,
          disabled: tag.isDeleted
        });
      }
    };

    const setGroup = (tag) => {
      const existingParent = groups.find((parent) => parent.key === tag.id);
      if (
        (type === 0 ||
          (type === 1 && tag.children.length > 0) ||
          (type === 2 && tag.children.length === 0)) &&
        !existingParent
      ) {
        const childrenCount = tag.children.length;

        const isGroupCollapsed = () => {
          if (
            groupCollapseState &&
            Object.prototype.hasOwnProperty.call(groupCollapseState, tag.id)
          ) {
            return groupCollapseState[tag.id];
          }

          return true;
        };

        groups.push({
          key: tag.id,
          name: tag.text,
          startIndex,
          count: childrenCount,
          disabled: tag.isDeleted,
          isCollapsed: isGroupCollapsed()
        });

        startIndex += childrenCount;

        if (tag.children.length) {
          tag.children.map((childTag) => setChild(childTag));
        }
      }
    };

    tagsToFilter.map((tag) => {
      if (!tag.parentId) {
        setGroup(tag);
      } else if (type !== 2) {
        setChild(tag);
      }

      return null;
    });

    return { children, groups };
  }

  function getTags() {
    const sortedTags = sortTags();
    const foundTags = findTagsBySearchTerm(sortedTags);
    const { children, groups } = groupTags(foundTags);

    return { children, groups };
  }

  function onDialogCancel() {
    setShowCreateDialog(false);
  }

  function onToggleLoading() {
    setIsLoading(!isLoading);
  }

  function onDialogConfirm() {
    setShowCreateDialog(false);
    loadTagsFromApi();
  }

  function onToggleDeleteTag(item) {
    let selectedTag = { ...tags.find((tag) => tag.id === item.key) };
    selectedTag.isDeleted = !selectedTag.isDeleted;

    if (selectedTag.parentId && !item.disabled) {
      // Check if deleting the last active child tag and if so, delete the parent tag
      const parentTag = { ...findParentTag(selectedTag.parentId) };

      const detectEnabledChildTags = () => {
        for (let index = 0; index < parentTag.children.length; index += 1) {
          const childTag = parentTag.children[index];
          if (childTag.id !== selectedTag.id && !childTag.isDeleted) {
            return true;
          }
        }

        return false;
      };

      const foundEnabledChildTag = detectEnabledChildTags();

      if (!foundEnabledChildTag) {
        parentTag.isDeleted = true;
        parentTag.children.find((childTag) => childTag.id === selectedTag.id).isDeleted = true;

        selectedTag = parentTag;
      }
    } else if (selectedTag.children?.length) {
      // Check if activating or deactivating the parent tag and if so, activate or deactivate all child tags
      const children = selectedTag.children.map((child) => ({
        ...child,
        isDeleted: !item.disabled
      }));

      selectedTag.children = children;
    }

    setTag(selectedTag);
    setShowDeleteConfirmation(true);
  }

  function onConfirmToggleDeleteTag() {
    setShowDeleteConfirmation(false);
    setIsLoading(true);

    const body = JSON.stringify(tag);

    fetchRequest({ url: `Tag`, method: 'PUT', body })
      .then(() => {
        loadTagsFromApi();
      })
      .catch((err) => {
        setIsLoading(false);
        apiErrorHandler(err);
      });
  }

  function onCreateNewTag() {
    setTag({ type: 0, text: '', children: [] });
    setShowCreateDialog(true);
  }

  function checkIsParentDeleted(parentId) {
    if (parentId && tags) {
      const parentTag = tags.find((parent) => parent.id === parentId);

      if (parentTag) {
        return parentTag.isDeleted;
      }
    }

    return true;
  }

  function onEditTag(item) {
    const selectedTag = { ...tags.find((tag) => tag.id === item.key) };

    selectedTag.type = selectedTag.children?.length ? 1 : 0;

    setTag(selectedTag);
    setShowCreateDialog(true);
  }

  function onRenderTitle(options) {
    const isGroupedHeader = options.group.count > 0;
    return (
      <GroupHeaderTitle $isGroupedHeader={isGroupedHeader}>
        <TitleStyled
          className={`${isGroupedHeader ? 'parentTagTitle' : 'simpleTagTitle'} ${
            options.group.disabled ? 'titleDisabled' : ''
          }`}
          role="presentation"
          onClick={() => !options.group.disabled && onEditTag(options.group)}
        >
          {options.group.name} {isGroupedHeader ? `(${options.group.count})` : null}
        </TitleStyled>
        <TooltipHost
          content={
            options.group.disabled
              ? t('tagsAdmin.column.activateTag')
              : t('tagsAdmin.column.deleteTag')
          }
          calloutProps={{ gapSpace: 5, target: `#btn-toggleDelete-${options.group.key}` }}
        >
          <IconButton
            id={`btn-toggleDelete-${options.group.key}`}
            className="toggleDeleteButton"
            iconProps={{
              iconName: options.group.disabled ? 'CheckMark' : 'Untag'
            }}
            onClick={() => onToggleDeleteTag(options.group)}
            styles={{
              root: {
                marginLeft: '30px'
              },
              icon: {
                paddingTop: isGroupedHeader ? null : '2px',
                fontSize: '18px',
                fontWeight: '600'
              }
            }}
          />
        </TooltipHost>
      </GroupHeaderTitle>
    );
  }

  function onRenderHeader(options) {
    if (options.group.count > 0) {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <GroupHeaderStyled {...options} onRenderTitle={onRenderTitle} />;
    }

    return onRenderTitle(options);
  }

  function getTagList() {
    if (!isLoading && initialized && tags?.length) {
      const { children, groups } = getTags();

      return groups?.length ? (
        <TagsContainer className="details-list-wrapper">
          <ListCustom
            items={children || []}
            groups={groups}
            columns={columns}
            selectionMode={SelectionMode.none}
            selectionPreservedOnEmptyClick
            enterModalSelectionOnTouch
            groupProps={{
              onRenderHeader,
              showEmptyGroups: true,
              headerProps: {
                onToggleCollapse: (group) =>
                  setGroupCollapseState((prevState) => {
                    return prevState
                      ? { ...prevState, [group.key]: !group.isCollapsed }
                      : { [group.key]: !group.isCollapsed };
                  })
              }
            }}
            onRenderRow={(props, defaultRender) => (
              <TagsRowStyled>{defaultRender({ ...props })}</TagsRowStyled>
            )}
          />
        </TagsContainer>
      ) : (
        <EmptyListPlaceHolder
          hidden={!!groups?.length}
          onCreateNew={!searchTerm ? onCreateNewTag : null}
          createNewText={t('tagsAdmin.primaryButton.createTag')}
          noItemsText={searchTerm ? t('tagsAdmin.emptySearchResults') : t('tagsAdmin.placeHolder')}
          listIconName={searchTerm ? 'TagUnknown' : 'Tag'}
        />
      );
    }

    return null;
  }

  if (!currentUser?.isAdmin) {
    return <NoAccessContainer />;
  }

  if (isLoading) {
    return <LoadingSpinner label={t('loading.tags.text')} />;
  }

  return (
    <>
      <MainCommandBar
        className="full-width"
        items={[
          {
            key: 'filterItem',
            text: filterOptions.find((option) => option.selected).text,
            className: 'tag-filter',
            subMenuProps: {
              items: getUnselectedFilterItems()
            }
          },
          {
            key: 'addParentTag',
            className: 'command-bar-item',
            text: t('tagsAdmin.commandBar.createNewTag'),
            iconProps: { iconName: 'Add' },
            onClick: () => onCreateNewTag()
          }
        ]}
      />
      <TagsAdminStyled id="TagsList">
        {getTagList()}
        <CreateTagDialog
          hidden={!showCreateDialog}
          onCancel={onDialogCancel}
          onConfirm={onDialogConfirm}
          toggleLoading={onToggleLoading}
          tag={tag}
        />
        <DialogCustom
          hidden={!showDeleteConfirmation}
          title={
            !tag?.isDeleted
              ? t('tagsAdmin.activateDialog.title')
              : t('tagsAdmin.deleteDialog.title')
          }
          subText={
            !tag?.isDeleted
              ? t('tagsAdmin.activateDialog.subText', {
                  item: tag?.text
                })
              : t('tagsAdmin.deleteDialog.subText', {
                  item: tag?.text
                })
          }
          defaultButtonLabel={t('tagsAdmin.deleteDialog.defaultButtonLabel')}
          primaryButtonLabel={t('tagsAdmin.deleteDialog.primaryButtonLabel')}
          primaryButtonFunc={onConfirmToggleDeleteTag}
          defaultButtonFunc={() => setShowDeleteConfirmation(false)}
          onDismiss={() => setShowDeleteConfirmation(false)}
          modalProps={{ isBlocking: true }}
        />
      </TagsAdminStyled>
    </>
  );
}

export default TagsAdministration;
