import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import stableHash from 'stable-hash';
import {
  AnyApiObjectType,
  ApiObject,
  ApiObjectEvent,
  ApiObjectVariant,
  CACHE,
  createApiObjectField,
  deriveApiObjectVariantId,
  IApiObjectCache,
  IApiObjectData,
  IApiObjectSubscriber,
  IApiObjectType,
  IApiObjectVariantKey,
  KEY,
  InferApiObjectTypeDataFull,
  InferApiObjectTypeParams,
  makeApiObjectKey,
  makeApiObjectVariantKey,
  REFS,
  IApiObjectVariantId,
  ID,
  AnyApiObject,
  decodeObjFieldPath,
  encodeObjFieldPath,
  IApiObjectDataRefs,
  InferApiObjectTypeDataSmall
} from './object';
import { ApiDataCacheContext } from './cache';

/** SearchPropertiesModel in API */
export interface ISearchProps {
  itemsPerPage: number;
  totalItems: number;
  pageIndex: number;
  useContinuationToken: boolean;
  continuationToken: string | null;
}

export const DEFAULT_MAX_PAGES_TO_KEEP_ON_REVALIDATION = 5;

export interface IApiListPage<
  T extends AnyApiObjectType,
  D = void,
  V extends ApiObjectVariant = ApiObjectVariant.Full
> extends IApiObjectData {
  items: V extends ApiObjectVariant.Full
    ? InferApiObjectTypeDataFull<T>[]
    : InferApiObjectTypeDataSmall<T>[];
  searchProps: ISearchProps;
  data: D;
}

export interface IApiListFetchResultBase<T extends AnyApiObjectType> {
  items: IApiObjectVariantKey<InferApiObjectTypeParams<T>>[];
  searchProps: ISearchProps;
}

export interface IApiListFetchResultWithData<T extends AnyApiObjectType, D>
  extends IApiListFetchResultBase<T> {
  data: D;
}

export type IApiListFetchResult<T extends AnyApiObjectType, Data = void> = Data extends void
  ? IApiListFetchResultBase<T>
  : IApiListFetchResultWithData<T, Data>;

/** A list endpoint provides a set of items in pages. */
export interface IApiListType<
  Params,
  T extends AnyApiObjectType,
  Data = void,
  ItemVariant extends ApiObjectVariant = ApiObjectVariant.Full
> {
  /** Unique ID for this list endpoint */
  id: string;
  /**
   * Loads a new page.
   *
   * Note about Data: If not void, Data can have CACHE and REFS fields to reference cache objects almost like an
   * API object, but without needing to be a cache object itself. All of its refs will be attached to the page object.
   */
  fetchPage(
    cache: IApiObjectCache,
    params: Params,
    pageIndex: number,
    prevPageData?: IApiListPage<T, Data, ItemVariant>,
    abort?: AbortSignal
  ): Promise<IApiListFetchResult<T, Data>>;
}
export type AnyApiListType = IApiListType<unknown, AnyApiObjectType, unknown, ApiObjectVariant>;

export type InferApiListItemVariant<T> = T extends IApiListType<
  unknown,
  AnyApiObjectType,
  unknown,
  infer V
>
  ? V
  : never;

/** Type function: extracts Item type from an ApiListEndpoint */
export type InferApiListItemType<T> = T extends IApiListType<
  unknown,
  infer I,
  unknown,
  ApiObjectVariant
>
  ? I
  : never;

export type InferApiListItem<T> = InferApiListItemVariant<T> extends ApiObjectVariant.Full
  ? InferApiObjectTypeDataFull<InferApiListItemType<T>>
  : InferApiObjectTypeDataSmall<InferApiListItemType<T>>;

export type InferApiListItemParams<T> = InferApiObjectTypeParams<InferApiListItemType<T>>;

/** Type function: extracts Params from an ApiListEndpoint */
export type InferApiListParams<T> = T extends IApiListType<
  infer P,
  AnyApiObjectType,
  unknown,
  ApiObjectVariant
>
  ? P
  : never;

/** Type function: extracts Data from an ApiListEndpoint */
export type InferApiListData<T> = T extends IApiListType<
  unknown,
  AnyApiObjectType,
  infer D,
  ApiObjectVariant
>
  ? D
  : never;

interface ObjParamsForList<L> {
  params: InferApiListParams<L>;
  pageIndex: number;
  prevPageRef?: { get(variant: ApiObjectVariant): ObjDataForList<L> };
}
type ObjDataForList<L> = IApiListPage<
  InferApiListItemType<L>,
  InferApiListData<L>,
  InferApiListItemVariant<L>
>;
type ObjTypeForList<L> = IApiObjectType<ObjParamsForList<L>, ObjDataForList<L>>;

function createObjectTypeForListType<L extends AnyApiListType>(endpoint: L): ObjTypeForList<L> {
  const thisType = {
    id: `${endpoint.id}~page`,
    createRefs() {
      throw new Error('invalid: createRefs on a list type');
    },
    async load(
      cache: IApiObjectCache,
      params: ObjParamsForList<L>,
      abort: AbortSignal
    ): Promise<ObjDataForList<L>> {
      const prevPageData = params.prevPageRef?.get(ApiObjectVariant.Full);
      const result = (await endpoint.fetchPage(
        cache,
        params.params,
        params.pageIndex,
        prevPageData,
        abort
      )) as IApiListFetchResultWithData<InferApiListItemType<L>, InferApiListData<L>>;

      const key = makeApiObjectVariantKey(thisType, params);
      const obj: ObjDataForList<L> = {
        [CACHE]: cache,
        [KEY]: key,
        [ID]: deriveApiObjectVariantId(key),
        [REFS]: new Map(),
        items: [],
        searchProps: result.searchProps,
        data: result.data
      };

      if (obj.data?.[REFS] instanceof Map) {
        if (obj.data?.[CACHE] !== cache) {
          throw new Error('list data has invalid cache');
        }

        // apply refs from data to our page object, if they exist
        for (const [k, v] of obj.data[REFS] as IApiObjectDataRefs) {
          const newPath = encodeObjFieldPath(['data'].concat(decodeObjFieldPath(k)));
          obj[REFS].set(newPath, v);
        }
      }

      for (const item of result.items) {
        const index = obj.items.length;
        createApiObjectField(obj, ['items', index.toString()], item);
      }
      return obj;
    }
  };
  return thisType;
}

const OBJECT_TYPES_FOR_LIST_TYPES = new WeakMap<AnyApiListType, AnyApiObjectType>();

export function getObjectTypeForListType<L extends AnyApiListType>(list: L): ObjTypeForList<L> {
  if (!OBJECT_TYPES_FOR_LIST_TYPES.has(list)) {
    OBJECT_TYPES_FOR_LIST_TYPES.set(list, createObjectTypeForListType(list));
  }
  return OBJECT_TYPES_FOR_LIST_TYPES.get(list) as ObjTypeForList<L>;
}

/**
 * Lists use a local cache for any page that isn't the first page.
 *
 * Would it make sense to show a cached page 3 from 10 minutes ago after loading a fresh page 2? Probably not.
 * The further we get from the first page, the more likely it is that mutations will invalidate our cached data,
 * so we might as well limit our scope to just one instance of a list.
 */
interface LocalPageCache<L extends AnyApiListType> {
  pages: ApiObject<ObjTypeForList<L>>[];
}

function useApiListData<L extends AnyApiListType>(
  endpoint: L | null | undefined,
  params: InferApiListParams<L> | null | undefined
) {
  const cache = useContext(ApiDataCacheContext);
  const localCacheRef = useRef<LocalPageCache<L>>({ pages: [] });
  const [size, setSize] = useState(1);

  const listType = endpoint ? getObjectTypeForListType(endpoint) : null;
  const paramsHash = stableHash(params);

  const prevParamsHash = useRef(null);
  const paramsChanged = paramsHash !== prevParamsHash.current;
  prevParamsHash.current = paramsHash;

  const paramsRef = useRef(params);
  paramsRef.current = params;

  useEffect(() => {
    // reset size when params change
    setSize(1);
  }, [paramsHash]);

  // collect a list of pages. note that page data will not be loaded yet
  const pages = useMemo(() => {
    const { current: params } = paramsRef;
    const { current: localCache } = localCacheRef;

    if (!listType || !params) {
      for (const page of localCache.pages) page.drop();
      localCache.pages = [];
      return [];
    }

    // first page from shared cache
    const firstPage = cache.getObject<ObjTypeForList<L>>(
      makeApiObjectKey(listType, { params, pageIndex: 0 })
    );
    const pages = [firstPage];

    // all subsequent pages from local cache
    for (let i = 0; i < size - 1; i += 1) {
      const page = localCache.pages[i];
      const shouldRecreatePage =
        !page ||
        page.cache !== cache ||
        page.key.type !== listType ||
        stableHash(page.key.params.params) !== paramsHash;

      if (shouldRecreatePage) {
        localCache.pages[i]?.drop(); // previous value may already exist and must be dropped
        localCache.pages[i] = new ApiObject<ObjTypeForList<L>>(
          cache,
          makeApiObjectKey(listType, {
            params,
            pageIndex: i + 1,
            prevPageRef: pages[pages.length - 1]
          })
        );
        localCache.pages[i].autoLoad = false;
        pages.push(localCache.pages[i]);
      } else {
        pages.push(page);
      }
    }

    // drop extra pages
    while (localCache.pages.length > size - 1) {
      localCache.pages.pop().drop();
    }

    return pages;
  }, [cache, listType, paramsHash, size]);

  const [pageStates, setPageStates] = useState<
    {
      data?: ObjDataForList<L>;
      isLoading?: boolean;
    }[]
  >([]);

  const [error, setError] = useState<Error | undefined>();

  // add subscriptions
  useEffect(() => {
    setPageStates(
      pages.map((page) => ({
        data: page.get(ApiObjectVariant.Full),
        isLoading: page.isLoading
      }))
    );

    const subscriber: IApiObjectSubscriber<ObjTypeForList<L>> = {
      variant: ApiObjectVariant.Full,
      handle(page: ApiObject<ObjTypeForList<L>>, type: ApiObjectEvent) {
        const { pageIndex } = page.key.params;

        if (type === ApiObjectEvent.LoadStarted) {
          setError(undefined);
        } else if (type === ApiObjectEvent.LoadSucceeded) {
          setError(undefined);

          // load subsequent page
          pages[pageIndex + 1]?.beginLoad();
        } else if (type === ApiObjectEvent.LoadFailed) {
          setError(page.lastLoadError);
        }

        setPageStates((states) => {
          const newStates = [...states];
          newStates[pageIndex] = { ...newStates[pageIndex] };
          newStates[pageIndex].data = page.get(ApiObjectVariant.Full);
          newStates[pageIndex].isLoading = page.isLoading;
          return newStates;
        });
      }
    };

    for (const page of pages) {
      page.addSubscriber(subscriber, 'none');
    }

    return () => {
      for (const page of pages) {
        page.removeSubscriber(subscriber);
      }
    };
  }, [pages]);

  const isLoading = !pageStates.length || pageStates.some((page) => page.isLoading && !page.data);
  const isValidating = pageStates.some((page) => page.isLoading && page.data);

  // schedule load for all new pages
  const previouslySeenPages = useRef(new WeakSet());

  if (paramsChanged) {
    // clear seen pages
    previouslySeenPages.current = new WeakSet();
  }

  useEffect(() => {
    for (const page of pages) {
      // something is already loading! don't load anything right now
      if (page.isLoading) return;
    }

    for (const page of pages) {
      const isNew = !previouslySeenPages.current.has(page);
      previouslySeenPages.current.add(page);

      // start loading here either if the page is new, or if we stopped here due to an error
      const shouldStartLoadingHere = isNew || page.lastLoadError;

      if (shouldStartLoadingHere) {
        page.beginLoad();
        break;
      }
    }
  }, [pages]);

  const retryFromError = useCallback(() => {
    // same deal as above
    for (const page of pages) {
      if (page.isLoading) return;
    }
    pages.find((page) => page.lastLoadError)?.beginLoad();
  }, [pages]);

  const [mutationVersionCounter, setMutationVersionCounter] = useState(0);
  const hasPendingRevalidation = useRef(false);

  useEffect(() => {
    if (!isValidating && hasPendingRevalidation) {
      // we finished revalidating, probably
      setMutationVersionCounter((counter) => counter + 1);
    }
  }, [isValidating, hasPendingRevalidation]);

  const pagesRef = useRef(pages);
  pagesRef.current = pages;
  const revalidateAll = useCallback((maxSize = DEFAULT_MAX_PAGES_TO_KEEP_ON_REVALIDATION) => {
    const { current: pages } = pagesRef;

    setSize((size) => Math.min(size, maxSize));
    hasPendingRevalidation.current = true;

    for (const page of pages) page.cancelLoad();
    pages[0]?.beginLoad();
  }, []);

  const data = useMemo(() => {
    return pageStates.map((state) => state.data).filter((x) => x);
  }, [pageStates]);

  let totalItems = null;
  // use the bottommost page, which is probably the latest data
  for (let i = pageStates.length - 1; i >= 0; i -= 1) {
    const state = pageStates[i];
    if (state.data) {
      totalItems = state.data.searchProps.totalItems;
      break;
    }
  }

  const lastPageData = pageStates[pageStates.length - 1]?.data;
  const lastPageIsEmpty = !!lastPageData && !lastPageData.items.length;
  const dataItemCount = data.map((page) => page.items.length).reduce((a, b) => a + b, 0);
  const hasAllPages =
    lastPageIsEmpty || (Number.isFinite(totalItems) && dataItemCount >= totalItems);

  return {
    data,
    error,
    isLoading,
    isValidating,
    size,
    setSize,
    totalItems,
    hasAllPages,
    mutationVersionCounter,
    revalidateAll,
    firstPageObject: pages[0],
    retryFromError
  };
}

/** Statuses for an IListMutation. */
export enum ListMutationStatus {
  /** The mutation request is pending. */
  Pending,
  /** The mutation request succeeded, but we still need to keep this mutation around. */
  Succeeded,
  /** The mutation request failed. */
  Failed,
  /** The list mutation has been removed because the list changed. */
  Invalid
}

export interface IListMutationItem<P> {
  key: IApiObjectVariantKey<P>;
  id: IApiObjectVariantId;
}

export type IListMutationApplyFn<P> = (items: IListMutationItem<P>[]) => IListMutationItem<P>[];

// TODO: list mutations can add items, and therefore, dependencies! we need to inform the cache of this (addObjectDependency)

/**
 * A list mutation works much the same as a pending mutation on API objects, except that it applies to the entire list,
 * and that it persists even after success, because we don't really want to reload the entire list.
 * It will be removed only when the entire list is invalidated, or, like pending mutations, when the action fails.
 */
export interface IListMutation<P> {
  status: ListMutationStatus;
  /** The mutation request. Should this promise fail, this mutation will be removed. */
  promise: Promise<void>;
  /** Applies the mutation, returning a new list of items. */
  apply: IListMutationApplyFn<P>;
}

export interface IListMutationOptions<P> {
  promise?: Promise<void>;
  /** Applies the mutation to cached data. */
  apply?: IListMutationApplyFn<P>;
  /** Revalidates all loaded pages (up to `MAX_PAGES_TO_KEEP_ON_REVALIDATE`) after the mutation. */
  revalidate?: boolean;
}

export interface IListMutateFn<P> {
  (promise: Promise<void>, apply: IListMutationApplyFn<P>): IListMutation<P>;
  (promise: Promise<void>, options: IListMutationOptions<P>): IListMutation<P>;
  (options: IListMutationOptions<P>): IListMutation<P>;
}

export interface IApiListResult<
  T extends AnyApiObjectType,
  D = void,
  V extends ApiObjectVariant = ApiObjectVariant.Full
> {
  /** List of currently loaded items. */
  items: (V extends ApiObjectVariant.Full
    ? InferApiObjectTypeDataFull<T>
    : InferApiObjectTypeDataSmall<T>)[];
  /** Total item count. Unavailable before first load. */
  totalItems: number | null;
  /** If true, we've probably loaded all items in the list. */
  isAtEndOfList: boolean;
  /** If true, we are doing an initial load of the list. */
  isLoading: boolean;
  /** If true, either we're loading more data, or reloading existing data. */
  isValidating: boolean;
  /** If true, there's a pending mutation on this list. */
  isMutating: boolean;
  /** Most recent error. */
  error: Error | null;
  /** Current requested page count. */
  size: number;
  /** Current actual page count. */
  loadedSize: number;
  /** Sets requested page count. */
  setSize: (size: number | ((current: number) => number)) => void;
  /**
   * Applies a local mutation to this list.
   * Mutations should be idempotent, because synchronization issues can happen sometimes.
   */
  mutate: IListMutateFn<InferApiObjectTypeParams<T>>;
  /** Force-reloads all loaded pages, up to maxPages or DEFAULT_MAX_PAGES_TO_KEEP_ON_REVALIDATION */
  revalidateAll: (maxPages?: number) => void;

  /** Some lists have ancillary data. */
  data: D;

  /** Retries loading from a page that failed to load, if there is any. */
  retryFromError: () => void;
}

/**
 * We store list mutations keyed by first page so that we can show them while we validate our initial cache data
 */
const LIST_INIT_MUTATIONS = new Map<AnyApiObject, IListMutation<unknown>[]>();

/**
 * Loads a typed paginated list.
 *
 * If the endpoint or params are falsy, nothing will be loaded.
 */
export function useApiList<L extends AnyApiListType>(
  endpoint: L | null | undefined,
  params: InferApiListParams<L> | null | undefined
): IApiListResult<InferApiListItemType<L>, InferApiListData<L>, InferApiListItemVariant<L>> {
  type ListMutation = IListMutation<InferApiListItemParams<L>>;

  const endpointParamsHash = useMemo(() => stableHash([endpoint, params]), [endpoint, params]);
  const {
    data,
    error,
    totalItems,
    hasAllPages,
    isLoading,
    isValidating,
    size,
    setSize,
    mutationVersionCounter,
    revalidateAll,
    firstPageObject,
    retryFromError
  } = useApiListData(endpoint, params);
  const [mutations, setMutations] = useState<ListMutation[]>([]);
  const [pendingMutations, setPendingMutations] = useState<ListMutation[]>([]);

  const extraData = data[data.length - 1]?.data;

  const didInitRef = useRef(false);
  useEffect(() => {
    // reset mutations because we are now either looking at a different list, or we revalidated
    setMutations((mutations) => {
      for (const mut of mutations) mut.status = ListMutationStatus.Invalid;
      didInitRef.current = true;
      return (LIST_INIT_MUTATIONS.get(firstPageObject) as ListMutation[]) ?? [];
    });
  }, [endpointParamsHash, firstPageObject, mutationVersionCounter]);

  useEffect(() => {
    // check didInitRef to avoid overwriting LIST_INIT_MUTATIONS entry before loading
    if (!firstPageObject || !didInitRef.current) return;

    // store our mutations for future instances of this list.
    // we don't really care about crosstalk from two instances of this list overwriting each other at this time.
    // maybe in the future!
    LIST_INIT_MUTATIONS.set(
      firstPageObject,
      mutations.filter((mut) => mut.status === ListMutationStatus.Succeeded)
    );
  }, [firstPageObject, pendingMutations, mutations]);

  const mutationsRef = useRef(mutations);
  mutationsRef.current = mutations;

  const revalidateAllRef = useRef(revalidateAll);
  revalidateAllRef.current = revalidateAll;

  const mutate = useCallback((param1: unknown, param2: unknown) => {
    const options =
      typeof (param1 || param2) === 'object'
        ? ((param1 || param2) as IListMutationOptions<InferApiListItemParams<L>>)
        : null;
    const promise = param1 instanceof Promise ? param1 : options.promise ?? Promise.resolve();
    const apply =
      typeof param2 === 'function'
        ? (param2 as IListMutationApplyFn<InferApiListItemParams<L>>)
        : options.apply ?? ((items: IListMutationItem<InferApiListItemParams<L>>[]) => items);

    const mutation: IListMutation<InferApiListItemParams<L>> = {
      status: ListMutationStatus.Pending,
      promise,
      apply
    };

    const didAddMutation = new Promise((resolve) => {
      setMutations((mutations) => {
        setTimeout(resolve, 10);
        return mutations.concat([mutation]);
      });
    });
    setPendingMutations((mutations) => mutations.concat([mutation]));

    const isValidMutation = async () => {
      // if the mutation is too fast, it won't be in the mutations array either,
      // so we need to wait a bit
      await didAddMutation;
      return mutationsRef.current.includes(mutation);
    };

    promise
      .then(async () => {
        if (!(await isValidMutation())) return;
        mutation.status = ListMutationStatus.Succeeded;

        if (options.revalidate) {
          revalidateAllRef.current();
        }
      })
      .catch(async (error) => {
        if (!(await isValidMutation())) return;
        mutation.status = ListMutationStatus.Failed;
        setMutations((mutations) => mutations.filter((m) => m !== mutation));

        if (options.revalidate) {
          revalidateAllRef.current();
        }

        throw error;
      })
      .finally(() => {
        if (!isValidMutation()) return;
        setPendingMutations((mutations) => {
          const newMutations = [...mutations];
          const index = newMutations.indexOf(mutation);
          if (index > -1) newMutations.splice(index, 1);
          return newMutations;
        });
      });

    return mutation;
  }, []) as IListMutateFn<InferApiListItemParams<L>>;

  const cache = useContext(ApiDataCacheContext);

  const { itemKeysAfterMutation } = useMemo(() => {
    let items: { key: IApiObjectVariantKey<InferApiListItemParams<L>>; id: IApiObjectVariantId }[] =
      [];

    // add deduplicated
    const itemValues = new Map<IApiObjectVariantId, InferApiListItem<L>>();
    for (const page of data) {
      for (const item of page.items) {
        const id = deriveApiObjectVariantId(item[KEY]);
        if (!itemValues.has(id)) {
          // this as-cast *should* be unnecessary, but it seems that TypeScript has given up on type inference here
          itemValues.set(id, item as InferApiListItem<L>);
          items.push({
            key: item[KEY] as IApiObjectVariantKey<InferApiListItemParams<L>>,
            id: item[ID]
          });
        }
      }
    }

    for (const mutation of mutations) {
      items = mutation.apply(items);
    }

    return { itemKeysAfterMutation: items };
  }, [data, mutations]);

  const [mutationItemForceUpdateCounter, setMutationItemForceUpdateCounter] = useState(0);

  // Subscribe to all items, after mutations.
  // These items may not be in our original list page objects, so we don't automatically get their updates (#4515)
  // (originally, this used to just subscribe to new items, but it seems there are even more updates getting lost... oh well)
  useEffect(() => {
    const unsub: (() => void)[] = [];

    for (const { key } of itemKeysAfterMutation) {
      const subscriber: IApiObjectSubscriber<AnyApiObjectType> = {
        variant: key.variant,
        handle() {
          setMutationItemForceUpdateCounter((x) => x + 1);
        }
      };

      cache.getObject(key).addSubscriber(subscriber, 'none');
      unsub.push(() => cache.getObject(key).removeSubscriber(subscriber));
    }

    return () => {
      for (const f of unsub) f();
    };
  }, [cache, itemKeysAfterMutation]);

  const items = useMemo(() => {
    fakeUser(mutationItemForceUpdateCounter);

    const output: InferApiListItem<L>[] = [];
    for (let i = 0; i < itemKeysAfterMutation.length; i += 1) {
      const { id, key } = itemKeysAfterMutation[i];
      Object.defineProperty(output, i, {
        get() {
          const obj = cache.getObject(key).get(key.variant);
          if (!obj) {
            // eslint-disable-next-line no-console
            console.error('missing cache object referenced in list', id, key);
            throw new Error(`missing cache object referenced in list: ${id}`);
          }
          return obj;
        }
      });
    }
    return output;
  }, [cache, itemKeysAfterMutation, mutationItemForceUpdateCounter]);

  return {
    items,
    data: extraData,
    totalItems,
    isAtEndOfList: hasAllPages,
    isLoading,
    isValidating,
    isMutating: !!pendingMutations.length,
    error,
    size,
    loadedSize: data?.length || 0,
    setSize,
    mutate,
    revalidateAll,
    retryFromError
  };
}

/** eslint reasons */
function fakeUser<T>(x: T): T {
  return x;
}
