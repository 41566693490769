import { GlobalLoadingScreen } from 'components/progress';
import RedirectContainer from 'layouts/RedirectContainer';
import { useEffect, useMemo, useReducer, useState } from 'react';
import fetchRequest, { apiErrorHandler } from 'services/api';
import { ThemeProvider } from 'styled-components';
import { getDefaultTheme } from 'utils/helpers';
import {
  createFluentTheme,
  createStyledTheme,
  DEFAULT_THEME_PREFERENCE,
  DEVICE_THEME_PREFERENCE_KEY,
  DeviceThemePreference,
  getResolvedTheme,
  ThemeConfigContext
} from 'utils/theme';
import { KEEP_ALL_EXISTING_COLORS_FOR_NOW } from 'utils/themeDomains';
import { loadTheme, ThemeProvider as FluentThemeProvider } from '@fluentui/react';
import { ApiDataCache, ApiDataCacheContext } from 'hooks/api2/cache';
import { useLocalStorage } from '@rehooks/local-storage';
import { useMsTeamsInitialization } from '../../hooks';
import GlobalStyle from '../../index.styled';
import { AppContext, NotificationProvider, setGlobalTenantId, setGlobalUserId } from '../App';
import { AgentsProvider } from '../App/context/AgentsContext';
import { APIClientStoreProvider } from '../App/context/APIClientStore';
import { ChatsProvider } from '../App/context/ChatsContext';
import { MsTeamsConfigProvider } from '../App/context/MsTeamsConfigProvider';
import Routing from './Routing';

function reducer(state, action) {
  switch (action.type) {
    case 'init':
      return { ...state, ...action.data };
    case 'searchTerm':
      return { ...state, searchTerm: action.data };
    case 'setTheme':
      return { ...state, theme: { ...state.theme, palette: action.data } };
    case 'usePlanner':
      return { ...state, theme: { ...state.theme, usePlanner: action.data } };
    case 'tenantId':
      return { ...state, currentTenantId: action.data };
    default:
      throw new Error();
  }
}

const ALLOW_DARK_THEME = !KEEP_ALL_EXISTING_COLORS_FOR_NOW;

export default function AppContainer({ logout }) {
  const [loaded, setLoaded] = useState(false);
  const [loadError, setLoadError] = useState(null);

  const [globalAppState, dispatch] = useReducer(reducer, {
    searchTerm: null,
    isNavigationPanelHidden: true,
    theme: { palette: getDefaultTheme() }
  });

  const defaultTitle = 'Evocom';

  const [initFetched, setInitFetched] = useState(false);
  const [showLoadingContent, setShowLoadingContent] = useState(false);

  const { initialized, config } = useMsTeamsInitialization();

  const appContextValue = useMemo(() => ({ globalAppState, dispatch }), [globalAppState]);
  const [tenantId, setTenantId] = useState('');
  const [memberId, setMemberId] = useState('');

  const [themePreference] = useLocalStorage<DeviceThemePreference>(DEVICE_THEME_PREFERENCE_KEY);

  useEffect(() => {
    import('chart.js').then(({ Chart, ArcElement, Legend, Title, Tooltip }) => {
      Chart.register(ArcElement, Legend, Title, Tooltip);
    });
  }, []);

  useEffect(() => {
    if (initialized && !initFetched) {
      const init = async () => {
        setInitFetched(true);

        fetchRequest({ url: 'Init' })
          .then((data) => {
            const localStorageTenantId = window.localStorage.getItem('tenantId');
            const localStorageVersion = JSON.parse(window.localStorage.getItem('version'));

            // load fabric theme
            const palette = { ...data.theme.palette };

            Object.keys(palette).forEach((key) => {
              // remove empty palette keys
              if (palette[key] === null) {
                delete palette[key];
              }
            });

            if (data.currentTenantId !== localStorageTenantId) {
              window.localStorage.setItem('tenantId', data.currentTenantId);
            }

            if (!localStorageVersion || localStorageVersion.number !== data.apiVersion) {
              window.localStorage.setItem(
                'version',
                JSON.stringify({ number: data.apiVersion, new: true })
              );
            }

            const currentTenant = data.tenants.find(
              (tenant) => tenant.tenantId === data.currentTenantId
            );

            const currentUser = {
              ...data.currentUser,
              isInternalUser: currentTenant?.directoryId === data.currentUser?.tenantId,
              isAdmin: data.permissions?.admin
            };

            setGlobalTenantId(data.currentTenantId);
            setGlobalUserId(data.currentUser.userId);

            setTenantId(data.currentTenantId);
            setMemberId(data.currentUser.userId);

            dispatch({
              type: 'init',
              data: {
                ...data,
                currentUser,
                theme: {
                  ...data.theme,
                  title: data.theme.title || defaultTitle,
                  palette: { ...globalAppState.theme.palette, ...palette }
                }
              }
            });

            setLoaded(true);
          })
          .catch((err) => {
            setLoadError(err);
            apiErrorHandler(err);
          });
      };

      init();
    }
  }, [initialized, defaultTitle, globalAppState.theme.palette, initFetched]);

  useEffect(() => {
    if (initialized && !loaded) {
      setTimeout(() => {
        // Wait 500 milliseconds before the loading content is displayed
        // otherwise there will be a flickering on every init call
        setShowLoadingContent(true);
      }, 400);
    }
  }, [initialized, loaded]);

  const [systemIsDark, setSystemIsDark] = useState(
    window.matchMedia('(prefers-color-scheme: dark)').matches
  );
  useEffect(() => {
    const dark = window.matchMedia('(prefers-color-scheme: dark)');
    const onChange = () => {
      setSystemIsDark(dark.matches);
    };
    dark.addEventListener('change', onChange);
    return () => {
      dark.removeEventListener('change', onChange);
    };
  }, []);

  const isDarkTheme = {
    [DeviceThemePreference.System]: systemIsDark && ALLOW_DARK_THEME,
    [DeviceThemePreference.Light]: false,
    [DeviceThemePreference.Dark]: true
  }[themePreference ?? DEFAULT_THEME_PREFERENCE];

  const themeConfig = useMemo(
    () => ({
      fluentPalette: globalAppState.theme.palette,
      dark: isDarkTheme,
      isAI: true
    }),
    [globalAppState.theme.palette, isDarkTheme]
  );
  const styledTheme = useMemo(() => createStyledTheme(themeConfig), [themeConfig]);
  const fluentTheme = useMemo(() => createFluentTheme(themeConfig), [themeConfig]);

  useEffect(() => {
    const appBackground = getResolvedTheme(themeConfig).app.background;
    document
      .querySelector('meta[name="theme-color"]')
      .setAttribute('content', `rgb(${appBackground})`);
  }, [themeConfig]);

  const apiDataCache = useMemo(() => new ApiDataCache(tenantId, memberId), [tenantId, memberId]);

  useEffect(() => {
    loadTheme(fluentTheme);
  }, [fluentTheme]);

  if (loaded && globalAppState.currentUser) {
    return (
      <AppContext.Provider value={appContextValue}>
        <ApiDataCacheContext.Provider value={apiDataCache}>
          <APIClientStoreProvider>
            <AgentsProvider>
              <ChatsProvider>
                <RedirectContainer />
                <MsTeamsConfigProvider config={config}>
                  <ThemeConfigContext.Provider value={themeConfig}>
                    <FluentThemeProvider
                      theme={fluentTheme}
                      style={{ height: '100%', background: 'transparent' }}
                    >
                      <ThemeProvider theme={styledTheme}>
                        <NotificationProvider>
                          <GlobalStyle themeConfig={themeConfig} />
                          <Routing logout={logout} />
                        </NotificationProvider>
                      </ThemeProvider>
                    </FluentThemeProvider>
                  </ThemeConfigContext.Provider>
                </MsTeamsConfigProvider>
              </ChatsProvider>
            </AgentsProvider>
          </APIClientStoreProvider>
        </ApiDataCacheContext.Provider>
      </AppContext.Provider>
    );
  }

  if (showLoadingContent) {
    return (
      <ThemeProvider theme={styledTheme}>
        <GlobalStyle themeConfig={themeConfig} />
        <GlobalLoadingScreen error={loadError} />
      </ThemeProvider>
    );
  }

  return null;
}
