import styled from 'styled-components';
import breakpoints from 'utils/breakpoints';

const ConversationContainerStyled = styled.div`
  background-color: rgb(${({ theme }) => theme.conversation.background});
  height: 100%;

  .c-conversation-content-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .c-conversation-content-scroll-container {
    overflow: auto;

    @media (max-width: ${breakpoints.extraSmallMax}px) {
      overflow: inherit;
    }
  }

  .c-conversation_new-discussion-container {
    z-index: 10;
    transition: box-shadow 0.4s ease-out;
    padding: 20px 40px 20px 45px;
    margin-left: 20px;
    margin-right: 20px;

    @media (max-width: ${breakpoints.extraSmallMax}px) {
      margin-left: 0px;
      margin-right: 0px;
      padding: 10px 10px 10px 15px;
    }
  }

  .c-conversation_open-discussion > :first-child {
    box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108);
    border-radius: 4px;
    overflow: hidden;
  }

  .c-conversation_open-discussion {
    padding: 10px 40px 10px 45px;

    @media (max-width: ${breakpoints.extraSmallMax}px) {
      padding: 10px;
    }
  }

  .c-conversation_discussion-wrapper {
    display: flex;
    margin: 0px 45px 0px 15px;

    @media (max-width: ${breakpoints.extraSmallMax}px) {
      margin: 0px 15px 0px 5px;
    }
  }

  .rte-base > .editor-container {
    border-bottom: 2px solid rgb(${({ theme }) => theme.conversation.editorBackground});
    transition: border-bottom 0.1s ease-out;
  }

  .rte-focused > .editor-container {
    border-bottom: 2px solid rgb(${({ theme }) => theme.conversation.editorActiveLine});
    transition: border-bottom 0.1s ease-out;
  }

  .c-conversation_reply-action-button:hover {
    .c-conversation_reply-action-button-icon {
      font-weight: 900;
    }
  }

  .c-conversation_seeMore-action-button-icon {
    visibility: hidden;
    opacity: 0;
    font-size: 8px;
    padding-top: 1px;
  }

  .c-conversation_seeMore-action-button:hover {
    .c-conversation_seeMore-action-button-icon {
      visibility: visible;
      opacity: 0.8;
      font-size: 8px;
    }
  }

  .c-conversation__comment-wrapper {
    .c-conversation__more-menu-button {
      visibility: hidden;
      opacity: 0;
    }

    &:hover {
      .c-conversation__more-menu-button {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.5s ease-out;
        transition-delay: 0.4s;
      }
    }
  }
`;

export default ConversationContainerStyled;
