import { LoadingSpinner } from 'components/progress';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ISearchBox, Icon, getTheme } from '@fluentui/react';
import { DefaultButton, IButtonStyles } from '@fluentui/react/lib/Button';
import { Checkbox, ICheckboxStyles } from '@fluentui/react/lib/Checkbox';
import { ISearchBoxProps, ISearchBoxStyles, SearchBox } from '@fluentui/react/lib/SearchBox';
import { EmptyResultStyled, WrapperStyled } from './FilterBox.styles';

interface IFilterItem {
  key: string | number;
  text: string;
  filterType?: string;
}

interface IFilterBoxProps {
  filterItems?: IFilterItem[];
  defaultSelectedKeys?: IFilterItem['key'][];
  getFilterItems?: () => Promise<IFilterItem[]>;
  onRenderFilterItem?: (item: IFilterItem) => JSX.Element;
  onChange?: (selectedValues: IFilterItem[], value?: IFilterItem) => void;
  singleSelect?: boolean;
}

const buttonStyles: Partial<IButtonStyles> = {
  root: { border: 'none', height: '36px' },
  flexContainer: { justifyContent: 'left', maxWidth: 'fit-content' }
};

export const checkboxStyles: Partial<ICheckboxStyles> = {
  text: {
    marginLeft: '15px',
    maxWidth: '240px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  checkbox: { borderRadius: '4px' }
};

const searchBoxStyles: Partial<ISearchBoxStyles> = {
  root: { margin: '3px', border: 'none', paddingLeft: '6px' }
};

export default function FilterBox(props: IFilterBoxProps) {
  const { t } = useTranslation();
  const {
    filterItems,
    defaultSelectedKeys,
    onChange,
    getFilterItems,
    onRenderFilterItem,
    singleSelect
  } = props;

  const searchBoxRef = useRef<ISearchBox>(null);

  const [status, setStatus] = useState('loading');
  const [items, setItems] = useState(filterItems ?? []);

  const [searchQuery, setSearchQuery] = useState('');
  const [selectedKeys, setSelectedKeys] = useState<IFilterItem['key'][]>(defaultSelectedKeys ?? []);

  useEffect(() => {
    if (status === 'loading' && !filterItems && getFilterItems) {
      getFilterItems().then((res) => {
        setItems(res);
        setStatus('loaded');
      });
    } else if (status === 'loading' && filterItems) {
      setStatus('loaded');
    }
  }, [filterItems, getFilterItems, status]);

  useEffect(() => {
    return () => {
      setStatus('loading');
      setItems([]);
    };
  }, []);

  const filteredItems = useMemo(
    () =>
      items?.filter((item) => item.key && item.text?.toLowerCase().includes(searchQuery)) as
        | IFilterItem[]
        | undefined,
    [items, searchQuery]
  );

  const handleSearchOnChange = useCallback<NonNullable<ISearchBoxProps['onChange']>>(
    (_event, newValue) => {
      setSearchQuery(newValue ?? '');
    },
    []
  );

  function renderLoadingSpinner() {
    if (status !== 'loading' || !getFilterItems) {
      return null;
    }

    return <LoadingSpinner styles={{ container: { margin: '20px auto' } }} />;
  }

  function renderSearchBox() {
    if (!items?.length || status === 'loading') {
      return null;
    }

    return (
      <SearchBox
        componentRef={searchBoxRef}
        styles={searchBoxStyles}
        onFocus={(event) => {
          // this is a fix for the search box not being able to focus on mobile
          event.stopPropagation();
          event.preventDefault();

          searchBoxRef.current?.focus();
        }}
        autoComplete="off"
        underlined
        placeholder={t('searchBar.placeholder')}
        onChange={handleSearchOnChange}
      />
    );
  }

  function renderClearButton() {
    if (!items?.length || status === 'loading') {
      return null;
    }

    return (
      <DefaultButton
        text={t('contextualMenu.filter.clear')}
        disabled={!selectedKeys.length}
        iconProps={{ iconName: 'clearFilter' }}
        styles={{
          root: {
            border: 'none',
            height: '36px',
            width: '100%',
            paddingLeft: '14px',
            borderBottom: '1px solid #eaeaea'
          },
          label: { float: 'left', fontWeight: '400' },
          icon: {
            color: getTheme().palette.themePrimary
          }
        }}
        onClick={() => {
          setSelectedKeys([]);
          onChange?.([]);
        }}
      />
    );
  }

  function getItemByKey(key: IFilterItem['key']) {
    return items?.find((item) => item.key === key);
  }

  function handleClick(item: IFilterItem) {
    setSelectedKeys((prevState) => {
      let newSelectedKeys = [];

      if (singleSelect) {
        newSelectedKeys = selectedKeys && selectedKeys[0] === item.key ? [] : [item.key];
      } else if (prevState.find((key) => key === item.key)) {
        newSelectedKeys = prevState.filter((key) => key !== item.key);
      } else {
        newSelectedKeys = [...prevState, item.key];
      }

      onChange?.(
        newSelectedKeys.map((key) => getItemByKey(key)).filter((item) => item) as IFilterItem[],
        item
      );

      return newSelectedKeys;
    });
  }

  function renderFilterItems() {
    if (!filteredItems) {
      return null;
    }

    return filteredItems.map((item) => {
      return (
        <DefaultButton
          key={item.key}
          styles={buttonStyles}
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();

            handleClick(item);
          }}
        >
          <Checkbox
            styles={checkboxStyles}
            checked={!!selectedKeys.find((key) => key === item.key)}
            ariaSetSize={15}
            label={item.text}
            onRenderLabel={(props, defaultRender) => {
              if (onRenderFilterItem) {
                return onRenderFilterItem(item);
              }

              return defaultRender?.(props) || <div />;
            }}
          />
        </DefaultButton>
      );
    });
  }

  function renderNoItemsFound() {
    if (items?.length || status === 'loading') {
      return null;
    }

    return (
      <EmptyResultStyled key="noResults">
        <Icon
          iconName="SearchIssue"
          title={t('contextualMenu.filterSearch.noItemsFound')}
          style={{ marginRight: '5px' }}
        />
        <span>{t('contextualMenu.filterSearch.noItemsFound')}</span>
      </EmptyResultStyled>
    );
  }

  return (
    <WrapperStyled>
      {renderSearchBox()}
      {renderClearButton()}
      {renderLoadingSpinner()}
      <div className="filter-items">{renderFilterItems()}</div>
      {renderNoItemsFound()}
    </WrapperStyled>
  );
}
