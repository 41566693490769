import LookUp, { ILookUpProps } from 'components/inputs/LookUp/LookUp';
import { IFieldLinkProps, RouteFieldVisibility } from 'types';
import { getFluentIconNameByFieldType } from 'utils/helpers';

export interface IDynamicLookupFieldProps {
  disabled?: boolean;
  fieldLink: IFieldLinkProps;
  onChange: ILookUpProps['onChange'];
  getFileContents: ({ id }: { id: string }) => Promise<Response>;
}

function DynamicLookupField({
  fieldLink,
  disabled,
  onChange,
  getFileContents
}: IDynamicLookupFieldProps) {
  if (!fieldLink.field) return null;

  const { field, visibility } = fieldLink;
  const {
    name,
    description,
    descriptionPlacement,
    id,
    lookupOnlyOutcome,
    lookupType,
    lookupDisplayFields,
    lookupDefinitionId
  } = field;
  const defaultValue = fieldLink.value as ILookUpProps['defaultValue'];

  const displayDescriptionTooltip = descriptionPlacement === 1;

  const readOnly = visibility === RouteFieldVisibility.ReadOnly;
  const required = visibility === RouteFieldVisibility.Required;

  return (
    <LookUp
      id={id}
      defaultValue={defaultValue}
      definitionId={lookupDefinitionId || ''}
      description={displayDescriptionTooltip ? description : undefined}
      disabled={disabled || readOnly}
      fields={lookupDisplayFields || []}
      getFileContents={getFileContents}
      label={name}
      labelIconName={getFluentIconNameByFieldType(fieldLink.field)}
      lookupType={lookupType || 0}
      onChange={onChange}
      outcome={lookupOnlyOutcome || 0}
      required={required}
    />
  );
}

export default DynamicLookupField;
