import Label from 'components/inputs/Label';
import isFinite from 'lodash/isFinite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ICoordsProps } from 'types';
import {
  DefaultButton,
  MessageBar,
  MessageBarType,
  Spinner,
  SpinnerSize,
  TextField
} from '@fluentui/react';

export interface ILocationFieldProps {
  id?: string;
  /**
   * Description of the rating field.
   */
  description?: string;
  /**
   * Defaultvalue for the locationfield
   */
  defaultValue?: ICoordsProps;
  /**
   * A label for the locationfield
   */
  label?: string;
  /**
   * The icon that will be displayed to the left of the label
   */
  labelIconName?: string;
  /**
   * Callback issued when the location changes.
   */
  onChange?: (coords: ICoordsProps) => void;
  /**
   * Optional flag to mark location as readOnly / disabled
   * @defaultvalue false
   */
  disabled: boolean;
  /**
   * Whether the associated form field is required or not
   * @defaultvalue false
   */
  required?: boolean;
}

function LocationField({
  id,
  defaultValue,
  label,
  description,
  onChange,
  disabled = false,
  labelIconName,
  required = false
}: ILocationFieldProps): JSX.Element {
  const [coords, setCoords] = useState<ICoordsProps>(
    defaultValue || {
      latitude: undefined,
      longitude: undefined
    }
  );
  const [error, setError] = useState<string | null | undefined>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { t } = useTranslation('components', { keyPrefix: 'locationField' });

  const [shortCoordinates, setShortCoordinates] = useState<{
    latitude: boolean;
    longitude: boolean;
  }>({ latitude: true, longitude: true });

  const xsMaxMediaSelector = `@media (max-width: ${480}px)`;

  function isCoordValid(coord?: number | string): boolean {
    const numberValue = Number(coord);

    return isFinite(numberValue) && numberValue !== 0;
  }

  function successCallback(result: GeolocationPosition): void {
    const { latitude, longitude } = result.coords;

    setShortCoordinates({ latitude: true, longitude: true });
    setCoords({ latitude: Number(latitude.toString()), longitude: Number(longitude.toString()) });

    if (error) {
      resetError();
    }

    setIsLoading(false);

    if (onChange) {
      onChange({ latitude, longitude });
    }
  }

  function getErrorString(errorId: number): string {
    // i18n:scanner: comment possible errors for translator scanner because unused/dynamic strings are not detected
    // t('error.1');
    // t('error.2');
    // t('error.3');

    return t(`error.${errorId}`);
  }

  function errorCallback(geolocationError: GeolocationPositionError) {
    const errorString = getErrorString(geolocationError.code);

    setError(errorString);
    setIsLoading(false);
  }

  function onGetLocation(): void {
    setIsLoading(true);

    if ('geolocation' in navigator) {
      const currentPositionOptions: PositionOptions = {
        enableHighAccuracy: true,
        maximumAge: 10000,
        timeout: 60000
      };

      navigator.geolocation.getCurrentPosition(
        successCallback,
        errorCallback,
        currentPositionOptions
      );
    } else {
      setError(t('error.4'));

      setIsLoading(false);
    }
  }

  function onCoordsTextFieldChange(attribute: 'longitude' | 'latitude', value?: string): void {
    if (!isLoading) {
      // self-penned coordinates should not be shortened
      setShortCoordinates((prevState) => ({ ...prevState, [attribute]: false }));

      let numberValue: string | number | undefined;

      if (value) {
        numberValue = Number(value);
      }

      setCoords((prevState) => {
        const newState = { ...prevState, [attribute]: numberValue };

        if (onChange) onChange(newState);

        return newState;
      });
    }
  }

  function isMobileDevice(): boolean {
    return typeof window.orientation !== 'undefined';
  }

  function isAppleDevice(): boolean {
    const platform = navigator.platform.toLowerCase();

    return (
      platform === 'macintosh' ||
      platform === 'macintel' ||
      platform === 'iphone' ||
      platform === 'ipad'
    );
  }

  function onOpenMap(): void {
    const { latitude, longitude } = coords;

    if (isMobileDevice()) {
      // open native app
      const urlCoords = `?q=${latitude},${longitude}`;

      const url = isAppleDevice() ? `maps://${urlCoords}` : `geo:${urlCoords}`;

      window.open(url, '_system');
    } else {
      // open bing
      const url = `https://bing.com/maps/default.aspx?cp=${latitude}~${longitude}&lvl=18&sp=point.${latitude}_${longitude}`;

      window.open(url);
    }
  }

  function resetError(): void {
    setError(null);
  }

  function getCoordsTextFields(): JSX.Element {
    const areTextfieldsDisabled = disabled || isLoading;

    const prefixStyles = { color: '#323130', background: 'transparent' };

    return (
      <div style={{ width: '100%' }}>
        <TextField
          type="number"
          styles={{
            prefix: prefixStyles,
            fieldGroup: [
              { border: '1px solid #a19f9d', borderRadius: 3, ':after': { borderRadius: 3 } }
            ]
          }}
          id={`input-${id}`}
          disabled={areTextfieldsDisabled}
          prefix={`${t('latitude')}:`}
          placeholder={t('placeholder.latitude')}
          onChange={(_, value) => onCoordsTextFieldChange('latitude', value)}
          value={latitude?.toString() || ''}
        />
        <TextField
          type="number"
          styles={{
            fieldGroup: [
              { border: '1px solid #a19f9d', borderRadius: 3, ':after': { borderRadius: 3 } }
            ],
            prefix: prefixStyles,
            root: { marginTop: 10 }
          }}
          disabled={areTextfieldsDisabled}
          prefix={`${t('longitude')}:`}
          placeholder={t('placeholder.longitude')}
          onChange={(_, value) => onCoordsTextFieldChange('longitude', value)}
          value={longitude?.toString() || undefined}
        />
      </div>
    );
  }

  let latitude = coords?.latitude;
  if (shortCoordinates.latitude && isCoordValid(latitude)) {
    latitude = Number(latitude).toFixed(8);
  }

  let longitude = coords?.longitude;
  if (shortCoordinates.longitude && isCoordValid(longitude)) {
    longitude = Number(longitude).toFixed(8);
  }

  const hasValidCoords = isCoordValid(coords?.latitude) && isCoordValid(coords?.longitude);

  return (
    <div id={id}>
      <Label label={label} required={required} iconName={labelIconName} description={description} />

      {/* coords textfields */}
      {!disabled && getCoordsTextFields()}

      {/* error message bar */}
      {error && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline
          styles={{
            root: {
              marginTop: '5px',
              selectors: { [xsMaxMediaSelector]: { width: '100%' } }
            }
          }}
          onDismiss={resetError}
        >
          {error}
        </MessageBar>
      )}

      {/* get position button */}
      <div style={{ display: 'flex', marginTop: disabled ? 0 : 10 }}>
        <DefaultButton
          styles={{
            textContainer: { flexGrow: 0 },
            root: {
              marginRight: '5px',
              minWidth: '50%',
              display: disabled ? 'none' : 'inline',
              width: '100%',
              selectors: { [xsMaxMediaSelector]: { height: '40px' } }
            }
          }}
          disabled={disabled || isLoading}
          iconProps={{ iconName: 'Location' }}
          onClick={onGetLocation}
          primary={false}
          text={t('button.getLocation')}
        />

        {/* loading spinner when the position is queried */}
        {isLoading && (
          <div style={{ minWidth: '50%', marginTop: 8 }}>
            <Spinner
              styles={{
                root: {
                  marginLeft: '5px',
                  selectors: { [xsMaxMediaSelector]: { width: '100%' } }
                }
              }}
              size={SpinnerSize.small}
              label={t('loading')}
              labelPosition="right"
            />
          </div>
        )}

        {/* button to redirect to bing maps or default native map app  */}
        {((!isLoading && !disabled) || (disabled && hasValidCoords)) && (
          <DefaultButton
            text={t('button.openMap')}
            onClick={onOpenMap}
            styles={{
              textContainer: { flexGrow: 0 },
              root: {
                backgroundColor: '#f3f2f1',
                visibility: hasValidCoords ? 'visible' : 'hidden',
                width: '100%',
                selectors: { [xsMaxMediaSelector]: { height: '40px' } }
              }
            }}
            iconProps={{ iconName: 'Nav2DMapView' }}
            primary={false}
          />
        )}

        {disabled && !hasValidCoords && (
          <div
            style={{
              padding: 7,
              border: '1px solid #a19f9d',
              borderRadius: 3,
              backgroundColor: '#f3f2f1',
              width: '100%',
              color: '#a19f9d'
            }}
          >
            {t('placeholder.disabled')}
          </div>
        )}
      </div>
    </div>
  );
}

export default LocationField;
