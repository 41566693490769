import CombinedPicker from 'components/inputs/CombinedPicker';
import { useTeamMembersSearch } from 'hooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  IFieldLinkProps,
  IPersonOrTeamProps,
  ITeamProps,
  IUserProps,
  PersonFieldFormat,
  RouteFieldVisibility
} from 'types';
import {
  checkScreenWidth,
  convertEpUserOrTeamToPersonaProps,
  getFluentIconNameByFieldType
} from 'utils/helpers';
import { IPersonaProps } from '@fluentui/react';

interface ICombinedPickerSearchProps {
  filterText?: string;
  searchUser?: boolean;
  searchTeams?: boolean;
  userId?: string;
  searchAAD?: boolean;
  type?: number;
}

interface IcombinedPickerSearchResult {
  consentNeeded: boolean;
  teams: ITeamProps[];
  users: IUserProps[];
}

export interface IDynamicPersonFieldProps {
  disabled?: boolean;
  onCombinedPickerSearch: (
    combinedPickerSearchProps: ICombinedPickerSearchProps
  ) => Promise<IcombinedPickerSearchResult>;
  fieldLink: IFieldLinkProps;
  onChange: (value?: { users: IUserProps[]; teams: ITeamProps[] } | undefined | null) => void;
}

const PickerWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;

  @media (max-width: 481px) {
    gap: 0px;
    flex-direction: column;
  }
`;

type SearchResultItem = IUserProps | ITeamProps;

function DynamicPersonField({
  fieldLink,
  disabled,
  onChange,
  onCombinedPickerSearch
}: IDynamicPersonFieldProps) {
  const { t } = useTranslation();
  const [consentNeeded, setConsentNeeded] = useState<boolean>(false);
  const [initialized, setInitialized] = useState(false);

  const [team, setTeam] = useState<ITeamProps | null>(null);
  const [user, setUser] = useState<IPersonaProps | null>(null);

  const { searchTeamMembers } = useTeamMembersSearch(team?.id);

  useEffect(() => {
    if (!initialized) {
      const value = fieldLink.value as IPersonOrTeamProps;

      if (value?.teams?.[0]) {
        setTeam(value?.teams?.[0]);
      }

      if (value?.users?.[0]) {
        setUser(value?.users?.[0]);
      }

      setInitialized(true);
    }
  }, [fieldLink.value, initialized]);

  if (!fieldLink.field) return null;

  const { singlePerson, multiplePersons, singleTeam, multipleTeams, personsAndTeams } =
    PersonFieldFormat;

  const { field, visibility, value } = fieldLink;
  const fieldLinkValue = value as IPersonOrTeamProps;

  const {
    name,
    description,
    descriptionPlacement,
    id,
    searchAAD,
    personFieldFormat = 1,
    connectUserAndTeam
  } = field;

  let itemLimit = 30;

  if (personFieldFormat === singlePerson || personFieldFormat === singleTeam) {
    itemLimit = 1;
  }

  function isUserItem(item: SearchResultItem): item is IUserProps {
    return (item as IUserProps).userId !== undefined;
  }

  function filterSelectedItems(
    items: SearchResultItem[],
    selectedItems: IPersonaProps[]
  ): SearchResultItem[] {
    return items.filter((item) => {
      if (isUserItem(item)) {
        return !selectedItems.some((selectedItem) => selectedItem.id === item.userId);
      }

      return !selectedItems.some((selectedItem) => selectedItem.id === item.id);
    });
  }

  function handleDependentTeamPickerSearch(filterText: string) {
    const userId = user?.id;

    return onCombinedPickerSearch({
      filterText,
      searchTeams: true,
      searchUser: false,
      userId
    }).then(({ teams = [] }) => {
      return teams;
    });
  }

  function handleDependentTeamPickerChange(selectedPersonArray: IPersonaProps[] | undefined) {
    if (!selectedPersonArray || selectedPersonArray.length === 0) {
      if (connectUserAndTeam) setTeam(null);

      onChange(user ? { users: [user], teams: [] } : null);

      return;
    }

    const { teams } = getUserAndTeams(selectedPersonArray);

    if (connectUserAndTeam) setTeam(teams[0]);

    // if user is selected, update user and team
    // otherwise, do not update field value because user is not selected yet (which could be mandatory)
    onChange(user ? { users: [user], teams } : null);
  }

  async function handleCombinedPickerSearch(
    filterText: string,
    selectedItems: IPersonaProps[],
    searchAAD?: boolean
  ) {
    if (connectUserAndTeam && team) {
      // if team is selected, search team members only
      const teamMember = await searchTeamMembers(filterText);

      return teamMember.map(convertEpUserOrTeamToPersonaProps);
    }

    const searchTeams = [singleTeam, multipleTeams, personsAndTeams].includes(personFieldFormat);
    const searchUser = [singlePerson, multiplePersons, personsAndTeams].includes(personFieldFormat);

    return onCombinedPickerSearch({ filterText, searchAAD, searchTeams, searchUser }).then(
      ({ consentNeeded = false, teams = [], users = [] }) => {
        setConsentNeeded(!!consentNeeded);

        return filterSelectedItems([...teams, ...users], selectedItems);
      }
    );
  }

  function getUserAndTeams(items: IPersonaProps[]): { users: IUserProps[]; teams: ITeamProps[] } {
    const users = items.filter((item) => {
      return isUserItem(item);
    });

    const teams = items.filter((item) => {
      return !isUserItem(item);
    });

    return { users, teams };
  }

  function handlePickerChange(selectedPersonArray: IPersonaProps[] | undefined) {
    if (!selectedPersonArray || selectedPersonArray.length === 0) {
      onChange(null);
      setUser(null);

      return;
    }

    const { users, teams } = getUserAndTeams(selectedPersonArray);

    if (connectUserAndTeam) {
      setUser(users[0]);

      onChange({ users, teams: team ? [team] : [] });
    } else {
      onChange({ users, teams });
    }
  }

  const displayDescriptionTooltip = descriptionPlacement === 1;

  const readOnly = visibility === RouteFieldVisibility.ReadOnly;
  const required = visibility === RouteFieldVisibility.Required;

  const context = disabled ? 'disabled' : undefined;
  const personpickerPlaceholder = t('dynamicField.combinedPicker.placeholder', { context });
  const teampickerPlaceholder = t('dynamicField.combinedPicker.teamPlaceholder', { context });

  let defaultValue: IPersonaProps[] = [];

  if (value && !connectUserAndTeam) {
    defaultValue = [...fieldLinkValue.users, ...fieldLinkValue.teams].map(
      convertEpUserOrTeamToPersonaProps
    );
  } else if (value && connectUserAndTeam) {
    defaultValue = fieldLinkValue.users.map(convertEpUserOrTeamToPersonaProps);
  }

  const pickerStyles = {
    text: {
      border: '1px solid rgb(161, 159, 157)',
      borderRadius: 4
    },
    itemsWrapper: { minHeight: 42, padding: 2 },
    input: { minHeight: 42 }
  };

  function renderPicker() {
    const fieldDescription =
      checkScreenWidth(['extraSmall']) || (displayDescriptionTooltip && !connectUserAndTeam)
        ? description
        : undefined;

    let placeholder = personpickerPlaceholder;

    if (personFieldFormat === singleTeam || personFieldFormat === multipleTeams) {
      placeholder = teampickerPlaceholder;
    }

    return (
      <CombinedPicker
        id={id}
        label={name}
        styles={pickerStyles}
        labelIconName={getFluentIconNameByFieldType(fieldLink.field)}
        description={fieldDescription}
        defaultValue={defaultValue}
        disabled={disabled || readOnly}
        displayConsentWarning={consentNeeded}
        itemLimit={itemLimit}
        placeholder={placeholder}
        required={required}
        onSearch={(filterText = '', selectedItems = []) => {
          return handleCombinedPickerSearch(filterText, selectedItems, searchAAD);
        }}
        onChange={handlePickerChange}
      />
    );
  }

  if (connectUserAndTeam) {
    // team picker if the connectUserAndTeam option is selected
    return (
      <PickerWrapper>
        <div style={{ flexGrow: 1 }}>{renderPicker()}</div>
        <div style={{ flexGrow: 1, marginTop: 'auto' }}>
          <CombinedPicker
            defaultValue={fieldLinkValue?.teams?.map(convertEpUserOrTeamToPersonaProps)}
            label=" "
            description={
              !checkScreenWidth(['extraSmall']) && displayDescriptionTooltip
                ? description
                : undefined
            }
            disabled={disabled || readOnly}
            itemLimit={1}
            placeholder={teampickerPlaceholder}
            onSearch={(filterText = '') => handleDependentTeamPickerSearch(filterText)}
            onChange={handleDependentTeamPickerChange}
            styles={pickerStyles}
          />
        </div>
      </PickerWrapper>
    );
  }

  return renderPicker();
}

export default DynamicPersonField;
