import {
  Attachments,
  DynamicFieldForm,
  LoadingSpinner,
  Panel,
  RichTextEditor,
  TagPicker
} from 'components';
import { AppContext, useNotificationContext } from 'features/App';
import { useEventListener } from 'hooks';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import fetchRequest from 'services/api';
import {
  createWopiFileCopy,
  evaluateFieldGroup,
  fileContents,
  getDefinition,
  getDefinitionExternalData,
  getDefinitionExternalDataList,
  getStreamUrl,
  onDeleteFile,
  tagsSearch,
  uploadFile2
} from 'services/fetchRequests';
import styled from 'styled-components';
import {
  IBlobFile,
  IFieldGroupLinkProps,
  IFieldLinkProps,
  IFileProps,
  IFormGroupProps,
  IOutboundFieldProps,
  IProcessDefinitionProps,
  IProcessInstanceProps,
  IProcessStartModelProps,
  ITagProps
} from 'types';
import {
  bitTest,
  checkScreenWidth,
  cloneObject,
  getDefaultGroups,
  getFormDocumentFields,
  markAllRequiredFields,
  onCombinedPickerSearch,
  onOpenWopi
} from 'utils/helpers';
import {
  DefaultButton,
  IContextualMenuItem,
  MessageBar,
  MessageBarType,
  PanelType
} from '@fluentui/react';
import StartProcessHeader from './StartProcessHeader';
import { ShowError } from '../../ShowError';
import { fetchJson } from '../../../services/api2';
import {
  areBackgroundJobsAvailable,
  BackgroundJobs,
  BackgroundJobStatus
} from '../../../features/BackgroundJobs';
import StartProcessJob from './StartProcessJob';
import { JobProgressItems, useJobProgress } from '../../../features/BackgroundJobs/JobProgress';

const FieldGroupsContainer = styled.div`
  padding: 0 24px 24px 24px;

  .c-form-wrapper {
    margin-top: 1rem;
  }
`;

export type SubmitHandle = {
  onBeforeSubmit?: () => void;
};

interface IStartProcessInstancePanelProps {
  isOpen: boolean;
  preventRedirect?: boolean;
  outboundFields?: IOutboundFieldProps[];
  onClosePanel: () => void;
  parentInstanceId?: string;
  parentProjectId?: string;
  parentTaskId?: string;
  parentServiceId?: string;
  parentStepId?: string;
  defaultFieldLinks?: IFieldLinkProps[];
  template: IProcessDefinitionProps;
  /** If true, we're starting this process in test mode. */
  isTest?: boolean;
  onInstanceCreated?: ({
    closePanel,
    createdInstance,
    asyncStart
  }: {
    closePanel?: boolean;
    createdInstance: IProcessInstanceProps;
    asyncStart?: boolean;
  }) => void;
  /** If true, you will be able to edit the form, but not actually create an instance. */
  disabled?: boolean;
}

interface IDocumentFieldsProps extends IFieldLinkProps {
  groupIndex: number;
  fieldIndex: number;
  groupFieldIndex?: number;
  mediaValueIndex?: number;
}

interface IStartAndNewProps {
  startAndNewEnabled: boolean;
  startAndNewSubmit: boolean;
  documents: { [key: string]: IFileProps };
  defaultGroups?: IFormGroupProps[];
}

export default function StartProcessInstancePanel({
  isOpen,
  onClosePanel,
  preventRedirect = false,
  template,
  isTest,
  outboundFields,
  parentInstanceId,
  defaultFieldLinks,
  parentProjectId,
  parentTaskId,
  parentStepId,
  parentServiceId,
  onInstanceCreated,
  disabled = false
}: IStartProcessInstancePanelProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showError } = useNotificationContext();
  const backgroundJobs = useContext(BackgroundJobs);

  const formRef = useRef<SubmitHandle>(null);

  const [tab, setTab] = useState('start');
  const [templateStartStep, setTemplateStartStep] = useState<IProcessStartModelProps>({
    id: '',
    name: '',
    delayStart: false,
    startAsync: false,
    isFollowing: true,
    groups: []
  });
  const [files, setFiles] = useState<IFileProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [syncProgressJob, setSyncProgressJob] = useState<StartProcessJob | null>(null);
  const [isLoadingStartForm, setIsLoadingStartForm] = useState(true);
  const [startFormError, setStartFormError] = useState<Error | null>(null);
  const [isEvaluatingFieldGroups, setIsEvaluatingFieldGroups] = useState(false);
  const [submitButtonInitialized, setSubmitButtonInitialized] = useState(false);
  const [tags, setTags] = useState<ITagProps[]>([]);
  const [instanceImageName, setInstanceImageName] = useState<string>();
  const copiedFiles = useRef<
    {
      originalFile: IFileProps;
      newFile: IFileProps;
    }[]
  >([]);

  const [createdInstance, setCreatedInstance] = useState<IProcessInstanceProps | null>(null);
  const [startFailed, setStartFailed] = useState<boolean>(false);

  const startAndNewProps: IStartAndNewProps = useRef({
    startAndNewEnabled: false,
    startAndNewSubmit: false,
    documents: {}
  }).current;

  const { startAndNewEnabled } = startAndNewProps;

  // global app state
  const { globalAppState } = useContext(AppContext);
  const { currentTenantId, currentUser } = globalAppState;

  const handler = useCallback(
    (event: Event) => {
      if (isLoading) {
        // eslint-disable-next-line no-param-reassign
        event.returnValue = true;
      }
    },
    [isLoading]
  );

  useEventListener('beforeunload', handler, window);

  const findStartAndNewField = useCallback((groups: IFieldGroupLinkProps[]) => {
    return groups.some((group) => {
      return group.fields?.some((field) => {
        return bitTest(field.relation as number, 4);
      });
    });
  }, []);

  const copyWopiFilesDocuments = useCallback(
    async (fields: IFieldLinkProps[]): Promise<IFieldLinkProps[]> => {
      return Promise.all(
        fields.map(async (fieldLink) => {
          if (fieldLink.fieldGroup?.fields) {
            // field is a fieldgroup -> check the fields of the fieldgroup
            return {
              ...fieldLink,
              fieldGroup: {
                ...fieldLink.fieldGroup,
                fields: await copyWopiFilesDocuments(fieldLink.fieldGroup.fields)
              }
            };
          }

          if (fieldLink.field && fieldLink.field?.fieldType !== 10) {
            // field is not a document field
            return fieldLink;
          }

          const value = fieldLink.value as IFileProps;

          // copy only wopi file
          if (!value || !value.url || !value.wopiUrl) return fieldLink;

          // eslint-disable-next-line no-unsafe-optional-chaining
          const fileIdIndex = value?.url?.indexOf('id=') + 3;
          const id = value.url?.substr(fileIdIndex);

          const alreadyCopiedFile = copiedFiles.current.find((file) => file.originalFile.id === id);

          if (alreadyCopiedFile) {
            // file is already copied
            return { ...fieldLink, value: { ...alreadyCopiedFile.newFile } };
          }
          // create a copy of the file
          let newValue = await createWopiFileCopy(id);
          newValue = { ...newValue, text: newValue.name };

          // push the copied file to the copiedFiles array so that it is not copied again
          // and if the panel is closed without submitting the instance, the copied file can be deleted
          copiedFiles.current.push({ originalFile: { ...value, id }, newFile: newValue });

          return { ...fieldLink, value: newValue };
        })
      );
    },
    []
  );

  const copyWopiFiles = useCallback(
    async (groups: IFieldGroupLinkProps[]): Promise<IFieldGroupLinkProps[]> => {
      const newGroups = await Promise.all(
        groups.map(async (group) => {
          return {
            ...group,
            fields: await copyWopiFilesDocuments(group.fields as IFieldLinkProps[])
          };
        })
      );

      return newGroups;
    },
    [copyWopiFilesDocuments]
  );

  const getTemplateStartStep = useCallback(async () => {
    setIsLoadingStartForm(true);
    setStartFormError(null);

    try {
      const templateStartStep = await fetchJson({
        url: `Route/Definition/StartModel/${template.id}?testing=${isTest || false}`
      });

      const hasStartAndNewField = findStartAndNewField(templateStartStep.groups);

      startAndNewProps.startAndNewEnabled = hasStartAndNewField;
      // to restore the default values of the fields
      startAndNewProps.defaultGroups = cloneObject({ ...templateStartStep.groups });

      templateStartStep.groups = await getDefaultGroups({
        startFormGroups: templateStartStep.groups,
        instanceId: parentInstanceId,
        fieldLinksToMap: defaultFieldLinks,
        outboundFields
      });

      templateStartStep.groups = await copyWopiFiles(templateStartStep.groups);
      setTemplateStartStep(templateStartStep);
    } catch (error) {
      setStartFormError(error);
    } finally {
      setIsLoadingStartForm(false);
    }
  }, [
    template?.id,
    isTest,
    findStartAndNewField,
    copyWopiFiles,
    startAndNewProps,
    defaultFieldLinks,
    parentInstanceId,
    outboundFields
  ]);

  useEffect(() => {
    if (isOpen && template?.id) {
      getTemplateStartStep();
    }
  }, [isOpen, template?.id, getTemplateStartStep]);

  useEffect(() => {
    if (submitButtonInitialized && templateStartStep?.groups) {
      markAllRequiredFields(templateStartStep.groups);
    }
  }, [submitButtonInitialized, templateStartStep?.groups]);

  function uploadFieldFile(
    job: StartProcessJob,
    documentValue: IDocumentFieldsProps,
    instance: IProcessInstanceProps
  ) {
    const { mediaValueIndex, field } = documentValue;

    const value = documentValue.value as IFileProps | IFileProps[];

    const uploadFileProps = { instanceId: instance.id, fieldId: field?.id };

    let promise: Promise<IFileProps>;
    const progress: { value: number | null } = { value: null };
    let fileName: string;
    if (Array.isArray(value)) {
      const item = value[mediaValueIndex as number];
      fileName = item.name ?? item.text;

      promise = uploadFile2({
        ...uploadFileProps,
        file: item.blobFile as IBlobFile,
        onUploadProgress: (p) => {
          progress.value = p;
          job.dispatchUpdate();
        }
      });
    } else {
      fileName = value.name ?? value.text;
      promise = uploadFile2({
        ...uploadFileProps,
        file: value.blobFile as IBlobFile,
        onUploadProgress: (p) => {
          progress.value = p;
          job.dispatchUpdate();
        }
      });
    }

    job.addSubTask(
      t('startProcessInstancePanel.progress.uploadingFile', { name: fileName }),
      promise,
      true,
      progress
    );
    return promise;
  }

  function resetFieldLink(groupIndex: number, fieldLink: IFieldLinkProps, fieldIndex: number) {
    const { documents } = startAndNewProps;
    const defaultGroups = cloneObject(startAndNewProps.defaultGroups);

    if (!defaultGroups) return fieldLink;

    if (bitTest(fieldLink.relation as number, 4)) {
      // if start and new is enabled for the field return the field with the current value

      const { field, fieldGroup } = fieldLink;

      if (field?.id && documents?.[field.id]) {
        // if the field is a document field, return the field with the stored value
        return { ...fieldLink, value: documents[field?.id] };
      }

      if (fieldGroup?.fields?.some((f) => f.field?.id && documents[f.field.id])) {
        // if the fieldgroup contains a document field, return the field with the stored value
        return {
          ...fieldLink,
          fieldGroup: {
            ...fieldGroup,
            fields: fieldGroup.fields.map((f) => {
              const documentValue = documents[f.field?.id as string];
              return { ...f, value: documentValue || f.value };
            })
          }
        };
      }

      return fieldLink;
    }

    // return the field with the default value
    return defaultGroups[groupIndex].fields[fieldIndex];
  }

  function resetFieldValues(groups: IFormGroupProps[]): IFormGroupProps[] {
    return groups.map((group, groupIndex) => ({
      ...group,
      fields: group.fields?.map((fieldLink, fieldIndex) =>
        resetFieldLink(groupIndex, fieldLink, fieldIndex)
      )
    }));
  }

  function resetStateForStartAndNew() {
    startAndNewProps.startAndNewSubmit = false;

    setInstanceImageName(undefined);
    setTemplateStartStep((prevState) => ({
      ...prevState,
      groups: resetFieldValues([...templateStartStep.groups])
    }));

    startAndNewProps.documents = {};

    setSubmitButtonInitialized(false);
    setIsLoading(false);
  }

  function resetStateAfterAsyncStart() {
    setFiles([]);
    if (startAndNewProps.startAndNewSubmit) {
      resetStateForStartAndNew();
    }
    setIsLoading(false);
  }

  function onInstanceStartFinished(
    instance: IProcessInstanceProps,
    isStartAndNew = false
  ): Promise<void> {
    // always reset files after instance is started, because this panel might be re-opened later
    setFiles([]);

    if (isStartAndNew) {
      setCreatedInstance(instance);

      resetStateForStartAndNew();

      if (onInstanceCreated) {
        onInstanceCreated({ closePanel: false, createdInstance: instance });
      }

      return;
    }

    setIsLoading(false);

    if (!preventRedirect && isTest) {
      // test mode: always open process
      navigate(
        `/process-instances/my-test/${instance.routeDefinitionId}/process-instance-tree/${instance.id}`
      );
    } else if (!preventRedirect && instance.firstTaskId) {
      // first task of the instance is assigned to current user -> open the task
      const url = `/process-instances/my/${instance.routeDefinitionId}/process-instance-tree/${instance.id}/detail/${instance.firstTaskId}/detail`;
      navigate(url);
    } else if (onInstanceCreated) {
      // close panel and update the instance list
      onInstanceCreated({ closePanel: true, createdInstance: instance });
    }
  }

  function updateTemplateStartStep(sections: IFormGroupProps[]) {
    setTemplateStartStep((prevState) => ({ ...prevState, groups: sections }));
  }

  function onDrop(file: IBlobFile) {
    const preparedFile = {
      name: file.name,
      creator: currentUser,
      tags: [],
      blobFile: file
    };

    setFiles((prevState) => {
      const prevStateClone = Array.isArray(prevState) ? [...prevState] : [];
      // check if temp file with same name already exists
      const existingFileIndex = prevStateClone.findIndex((tempFile) => tempFile.name === file.name);
      if (existingFileIndex !== -1) {
        // if so, replace it
        prevStateClone[existingFileIndex] = preparedFile;
      } else {
        // otherwise add it
        prevStateClone.push(preparedFile);
      }

      return prevStateClone;
    });

    return Promise.resolve(preparedFile);
  }

  function deleteFile(fileToDelete: IFileProps) {
    setFiles((prevState) => {
      const prevStateClone = Array.isArray(prevState) ? [...prevState] : [];
      const fileIndex = prevStateClone.findIndex((file) => file.name === fileToDelete.name);
      if (fileIndex !== -1) {
        prevStateClone.splice(fileIndex, 1);
      }

      return prevStateClone;
    });
  }

  function onTagClick(tagId?: string) {
    if (tagId) {
      navigate(`/tags/${tagId}`);
    }
  }

  function getUniqueAndDuplicateFiles(filesToSeparate: IFileProps[], currentFiles: IFileProps[]) {
    const uniqueFiles: IFileProps[] = [];
    const duplicateFiles: IFileProps[] = [];

    if (!currentFiles || currentFiles.length === 0) {
      // no duplicate files possible. return given files
      return { uniqueFiles: filesToSeparate, duplicateFiles };
    }

    filesToSeparate.forEach((file) => {
      const isDuplicateFile = !!findFile(file, currentFiles);

      if (isDuplicateFile) {
        duplicateFiles.push(file);
      } else {
        uniqueFiles.push(file);
      }
    });

    return { uniqueFiles, duplicateFiles };
  }

  function findFile(fileToFind: IFileProps, currentFiles: IFileProps[]) {
    if (!currentFiles || currentFiles.length === 0) {
      return undefined;
    }

    const { name } = fileToFind;

    return currentFiles.find((stateFile) => {
      return stateFile.name === name;
    });
  }

  function onUpdateFile(file: IFileProps) {
    // replace temp file
    setFiles((prevState) => {
      return prevState.map((tempFile) => {
        if (tempFile.name === file.name) {
          return file;
        }

        return tempFile;
      });
    });
  }

  function onSubmit(initiatingElement?: HTMLElement | string, startAndNew = false): void {
    setStartFailed(false);
    setCreatedInstance(null);

    if (!submitButtonInitialized) setSubmitButtonInitialized(true);

    const emptyRequiredFields = markAllRequiredFields(templateStartStep.groups, true);

    if (emptyRequiredFields.length > 0) {
      if (startAndNew) setStartFailed(true);

      return;
    }

    startAndNewProps.startAndNewSubmit = startAndNew;
    setIsLoading(true);
    setSyncProgressJob(null);

    const promises = formRef.current?.onBeforeSubmit?.();

    if (Array.isArray(promises)) {
      Promise.all(promises)
        .then(() => onSubmitNewInstance(initiatingElement))
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      onSubmitNewInstance(initiatingElement);
    }
  }

  function checkIfFieldHasDocumentValues(value: unknown): value is IDocumentFieldsProps[] {
    return Array.isArray(value) && value.length > 0;
  }

  function resetDocumentFieldValues(
    templateStartForm: IProcessStartModelProps,
    documentFields: IDocumentFieldsProps[]
  ): IProcessStartModelProps {
    // reset field values because the files are uploaded separately
    const templateStartFormClone = cloneObject(templateStartForm);

    documentFields.forEach((document) => {
      const { groupIndex, fieldIndex, groupFieldIndex } = document;

      let fieldLink = null;

      if (groupFieldIndex && groupFieldIndex > -1) {
        fieldLink =
          templateStartFormClone.groups[groupIndex].fields[fieldIndex].fieldGroup?.fields?.[
            groupFieldIndex
          ];

        if (!fieldLink) return;

        fieldLink.relation = templateStartFormClone.groups[groupIndex].fields[fieldIndex].relation;
      } else {
        fieldLink = templateStartFormClone.groups[groupIndex].fields[fieldIndex];
      }

      if (!fieldLink?.field?.id) return;

      if (
        bitTest(fieldLink.relation as number, 4) &&
        !startAndNewProps.documents[fieldLink.field.id]
      ) {
        // if start and new is enabled for the field store the current value
        startAndNewProps.documents[fieldLink.field.id] = fieldLink.value as IFileProps;
      }

      if (fieldLink.field.fieldType === 15 && checkIfFieldHasDocumentValues(fieldLink.value)) {
        fieldLink.value = fieldLink.value.filter((value: IFileProps) => !value.blobFile);
      } else {
        fieldLink.value = null;
      }
    });

    return templateStartFormClone;
  }

  function onSubmitNewInstance(initiatingElement?: HTMLElement | string) {
    const startAsync = areBackgroundJobsAvailable() && templateStartStep.startAsync;

    const documentFields = getFormDocumentFields(templateStartStep.groups);

    // delay start to upload files
    // delayed start -> upload files -> start instance
    const delayStart = documentFields.length > 0;

    let templateForm = { ...templateStartStep, delayStart };

    // reset field values because the files are uploaded separately
    templateForm = resetDocumentFieldValues(templateForm, documentFields);

    // closing the panel should not delete these files anymore
    copiedFiles.current = [];

    const run = () => {
      const newInstance = createNewInstance(templateForm);
      const job = new StartProcessJob(
        newInstance,
        (instance) => {
          if (isTest) {
            return {
              label: t('startProcessInstancePanel.progress.successOpenButton'),
              run: () =>
                navigate(
                  `/process-instances/my-test/${instance.routeDefinitionId}/process-instance-tree/${instance.id}`
                )
            };
          }

          if (instance.firstTaskId) {
            // first task of the instance is assigned to current user -> open the task
            return {
              label: t('startProcessInstancePanel.progress.successOpenButtonFirstTask'),
              run: () =>
                navigate(
                  `/process-instances/my/${instance.routeDefinitionId}/process-instance-tree/${instance.id}/detail/${instance.firstTaskId}/detail`
                )
            };
          }

          return {
            label: t('startProcessInstancePanel.progress.successOpenButton'),
            run: () =>
              navigate(
                `/process-instances/my/${instance.routeDefinitionId}/process-instance-tree/${instance.id}`
              )
          };
        },
        (initiatingElement) => {
          const { job } = run();
          backgroundJobs.add({ job, initiatingElement });
        }
      );

      const promise = newInstance.then(async (instance: IProcessInstanceProps) => {
        if (delayStart) {
          await uploadInstanceFieldFiles(job, instance, documentFields);
        } else if (files.length > 0) {
          await uploadInstanceFiles(job, instance);
        }
        return instance;
      });
      return { job, promise };
    };

    const isStartAndNew = startAndNewProps.startAndNewSubmit;

    const { job, promise } = run();

    if (startAsync) {
      backgroundJobs.add({
        job,
        initiatingElement
      });
      resetStateAfterAsyncStart();

      if (!isStartAndNew) {
        onClosePanel();
      }

      promise.then((instance) => {
        if (onInstanceCreated) {
          onInstanceCreated?.({
            closePanel: false,
            asyncStart: true,
            createdInstance: instance
          });
        }
      });
    } else {
      setSyncProgressJob(job);

      promise
        .then(async (instance) => {
          setSyncProgressJob(null);
          await onInstanceStartFinished(instance, isStartAndNew);
        })
        .catch((err) => showError(err));
    }
  }

  function createNewInstance(
    templateForm: IProcessStartModelProps
  ): Promise<IProcessInstanceProps> {
    return fetchRequest({
      url: `Route/Instance`,
      method: 'POST',
      body: JSON.stringify({
        ...templateForm,
        tags,
        parentInstanceId,
        parentProjectId,
        parentTaskId,
        parentServiceId,
        parentStepId
      })
    });
  }

  async function uploadInstanceFieldFiles(
    job: StartProcessJob,
    instance: IProcessInstanceProps,
    documentFields: IDocumentFieldsProps[]
  ): Promise<unknown> {
    await Promise.all(
      documentFields.map((documentValue) => uploadFieldFile(job, documentValue, instance))
    );

    if (files.length > 0) {
      return Promise.all([startInstance(job, instance), uploadInstanceFiles(job, instance)]);
    }

    return startInstance(job, instance);
  }

  function startInstance(job: StartProcessJob, instance: IProcessInstanceProps) {
    const promise = fetchRequest({ url: `Route/Instance/Start?id=${instance.id}`, method: 'PUT' });
    job.addStartInstanceTask(t('startProcessInstancePanel.progress.startingInstance'), promise);
    return promise;
  }

  function fetchInstanceImage(job: StartProcessJob, file: IFileProps): Promise<unknown> {
    const body = JSON.stringify({ ...file, type: 1 });

    const promise = fetchRequest({ url: 'File', method: 'PUT', body });
    job.addSubTask(t('startProcessInstancePanel.progress.settingImage'), promise, true);
    return promise;
  }

  function uploadInstanceFiles(
    job: StartProcessJob,
    instance: IProcessInstanceProps
  ): Promise<void> {
    const filePromises = files.map((file) => {
      const progress: { value: number | null } = { value: null };
      const promise = uploadFile2({
        file: file.blobFile as IBlobFile,
        instanceId: instance.id,
        onUploadProgress: (p) => {
          progress.value = p;
          job.dispatchUpdate();
        }
      });
      job.addSubTask(
        t('startProcessInstancePanel.progress.uploadingInstanceFile', {
          name: file.name ?? file.text
        }),
        promise,
        true,
        progress
      );
      return promise;
    });

    return Promise.all(filePromises).then(async (instanceFiles) => {
      const instanceImage = instanceFiles.find((file) => file.name === instanceImageName);

      if (instanceImageName && instanceImage) {
        await fetchInstanceImage(job, instanceImage);
      }
    });
  }

  function deleteCopiedFiles() {
    copiedFiles.current.forEach((file) => {
      onDeleteFile({ id: file.newFile.id });
    });

    copiedFiles.current = [];
  }

  function onDismiss() {
    setSubmitButtonInitialized(false);
    setCreatedInstance(null);

    onClosePanel();

    deleteCopiedFiles();
  }

  function onEvaluateFieldGroup(fieldGroup: IFieldLinkProps): Promise<IFieldLinkProps> {
    setIsEvaluatingFieldGroups(true);
    return evaluateFieldGroup(fieldGroup)
      .then((evaluatedFieldGroup) => {
        return copyWopiFilesDocuments([evaluatedFieldGroup]).then((evaluatedFieldGroups) => {
          return evaluatedFieldGroups[0];
        });
      })
      .finally(() => {
        setIsEvaluatingFieldGroups(false);
      });
  }

  function toggleIsFollowing() {
    setTemplateStartStep((prevState) => ({ ...prevState, isFollowing: !prevState.isFollowing }));
  }

  function getAdditionalDocumentCardMenuItems(file: IFileProps): IContextualMenuItem[] {
    let iconName = '';
    if (instanceImageName === file.name) iconName = 'CheckMark';

    return [
      {
        key: 'editItem',
        text: t('attachmentsPivotItem.detailList.menu.processImage'),
        iconProps: { iconName },
        className: 'checklist-more-subitem',
        onClick: (e) => {
          e.preventDefault(); // show check mark instead of closing menu
          setInstanceImageName((value) => {
            if (value === file.name) return undefined;
            return file.name;
          });
        }
      }
    ];
  }

  function renderTemplateDescription() {
    if (!template?.description || template.description === '<p><br></p>') {
      return null;
    }

    return (
      <div style={{ paddingTop: '10px' }}>
        <RichTextEditor
          defaultValue={template.description}
          disabled
          label={t('attachmentsPivotItem.label.description')}
        />
      </div>
    );
  }

  function renderHeader(needsCustomCloseButton = false) {
    return (
      <StartProcessHeader
        isFollowing={templateStartStep?.isFollowing}
        toggleIsFollowing={toggleIsFollowing}
        startAndNewEnabled={startAndNewEnabled}
        submitButtonDisabled={isLoading}
        submitButtonEvaluating={isEvaluatingFieldGroups}
        onSubmit={onSubmit}
        tab={tab}
        title={template?.name || ''}
        isTest={isTest}
        setTab={(newTab) => setTab(newTab || 'start')}
        onClose={needsCustomCloseButton ? onDismiss : undefined}
        disabled={disabled || isLoading || !!startFormError}
      />
    );
  }

  const isTestingDraft = isTest && template?.version && template.version.split('.')[1] !== '0';

  function renderBody() {
    if (isLoading && syncProgressJob) {
      return <StartProcessProgress job={syncProgressJob} onDismiss={() => setIsLoading(false)} />;
    }

    if (isLoading || isLoadingStartForm) {
      return (
        <LoadingSpinner
          label={
            isLoadingStartForm
              ? t('globals.loading', { label: '' })
              : t('attachmentsPivotItem.create.process.spinner')
          }
        />
      );
    }

    if (startFormError) {
      return <ShowError error={startFormError} onRetry={getTemplateStartStep} />;
    }

    if (tab === 'start' && templateStartStep?.groups) {
      return (
        <FieldGroupsContainer className="panel-body-wrapper">
          <div className="c-form-wrapper">
            {isTestingDraft ? (
              <MessageBar
                styles={{ root: { borderRadius: 6 } }}
                messageBarType={MessageBarType.info}
              >
                {t('startProcessInstancePanel.testingDraftVersion', { version: template.version })}
              </MessageBar>
            ) : null}
          </div>

          {renderTemplateDescription()}
          <div className="c-form-wrapper">
            {createdInstance && (
              <MessageBar
                styles={{ root: { borderRadius: 6 } }}
                messageBarType={MessageBarType.success}
                onDismiss={() => setCreatedInstance(null)}
                dismissButtonAriaLabel="Close"
              >
                {t('startProcessInstancePanel.messageBar', { context: 'success' })}
              </MessageBar>
            )}
            {startFailed && (
              <MessageBar
                styles={{ root: { borderRadius: 6 } }}
                messageBarType={MessageBarType.error}
                onDismiss={() => setStartFailed(false)}
                dismissButtonAriaLabel="Close"
              >
                {t('startProcessInstancePanel.messageBar', { context: 'error' })}
              </MessageBar>
            )}
            <DynamicFieldForm
              currentUser={currentUser}
              formRef={formRef}
              getStreamUrl={getStreamUrl}
              fetchRequest={fetchRequest}
              getDefinition={getDefinition}
              getDefinitionExternalData={getDefinitionExternalData}
              getDefinitionExternalDataList={getDefinitionExternalDataList}
              evaluateFieldGroup={onEvaluateFieldGroup}
              disabled={false}
              tenantId={currentTenantId || ''}
              onCombinedPickerSearch={({
                filterText,
                searchAAD,
                userId,
                searchUser = true,
                searchTeams = false
              }) => {
                return onCombinedPickerSearch({
                  filterText,
                  userId,
                  searchUser,
                  searchTeams,
                  type: searchAAD ? 8 : 5
                });
              }}
              getFileContents={fileContents}
              onSectionsUpdate={updateTemplateStartStep}
              onOpenWopi={onOpenWopi}
              sections={templateStartStep.groups}
            />
          </div>
          <TagPicker
            disabled={disabled}
            onChange={setTags}
            onTagClick={onTagClick}
            onTagsSearch={tagsSearch}
            placeHolder={t('taskDetailsBody.tagPicker.placeholder')}
            selectedTags={tags}
            defaultValue={tags || []}
          />
        </FieldGroupsContainer>
      );
    }

    if (tab === 'attachments') {
      return (
        <Attachments
          id="start-form-attachments"
          disabled={disabled}
          updateFile={onUpdateFile}
          additionalDocumentCardMenuItems={getAdditionalDocumentCardMenuItems}
          onTagsSearch={tagsSearch}
          onTagClick={onTagClick}
          getUniqueAndDuplicateFiles={getUniqueAndDuplicateFiles}
          getFiles={() => Promise.resolve(files)}
          getFileContents={fileContents}
          onDeleteFile={deleteFile}
          uploadFile={(file) => onDrop(file)}
        />
      );
    }

    return null;
  }

  return (
    <Panel
      isLightDismiss={false}
      hideCloseButton={checkScreenWidth(['extraSmall'])}
      styles={{
        navigation: { flexDirection: 'column' },
        subComponentStyles: {
          closeButton: { root: { order: '-1' } } // close button comes first
        }
      }}
      isOpen={isOpen}
      onDismiss={onDismiss}
      onRenderBody={() => {
        if (checkScreenWidth(['extraSmall'])) {
          return (
            <>
              {renderHeader(true)}
              {renderBody()}
            </>
          );
        }

        return renderBody();
      }}
      onRenderHeader={() => {
        if (checkScreenWidth(['extraSmall'])) return null;

        return renderHeader();
      }}
      type={PanelType.medium}
    />
  );
}

const StartProcessProgressStyled = styled.div`
  padding: 1rem;

  > .c-close-warning {
    display: grid;
    grid-template-rows: 1fr;
    overflow: hidden;
    min-height: 0;
    animation: start-process-progress-close-warning-appear 1s 1s backwards;

    > * {
      min-height: 0;
    }
  }

  @keyframes start-process-progress-close-warning-appear {
    0% {
      grid-template-rows: 0fr;
    }
  }
`;

function StartProcessProgress({ job, onDismiss }: { job: StartProcessJob; onDismiss: () => void }) {
  const { t } = useTranslation();
  const progress = useJobProgress(job);
  const isFailed = progress.status === BackgroundJobStatus.Failed;

  return (
    <StartProcessProgressStyled>
      {!isFailed && (
        <div className="c-close-warning">
          <MessageBar
            styles={{ root: { borderRadius: 6 } }}
            messageBarType={MessageBarType.warning}
          >
            {t('startProcessInstancePanel.syncStartCloseWarning')}
          </MessageBar>
        </div>
      )}

      <JobProgressItems job={job} />

      {isFailed && (
        <DefaultButton
          onClick={onDismiss}
          iconProps={{ iconName: 'Back' }}
          text={t('startProcessInstancePanel.syncStartFailedDismiss')}
        />
      )}
    </StartProcessProgressStyled>
  );
}
