/* eslint-disable react/jsx-props-no-spreading */
import Label, { ILabelProps } from 'components/inputs/Label';
import { useState } from 'react';
import { ITextFieldProps, TextField } from '@fluentui/react';

export interface ISmallTextFieldProps extends Omit<ITextFieldProps, 'onChange'> {
  /**
   * Defaultvalue for the smalltextfield
   */
  defaultValue?: string;
  /**
   * Description for the smalltextfield
   */
  description?: string;
  /**
   * A label for the smalltextfield
   */
  label?: string;
  /**
   * The icon that will be displayed to the left of the label
   */
  labelIconName?: string;
  /**
   * Max text-length to display the textfield as single line
   */
  maxSingleLineChars?: number;
  /**
   * Max length for the smalltextfield
   */
  maxLength?: number;
  /**
   * Whether or not the text field is a multiline text field.
   * @defaultvalue false
   */
  multiline?: boolean;
  /**
   * Callback issued when the text changes.
   */
  onChange?: (value?: string) => void;
  /**
   * Optional flag to mark smalltextfield as readOnly / disabled
   * @defaultvalue false
   */
  disabled?: boolean;
  /**
   * specifies a short hint that describes the expected value of an input field (e.g. a sample value or a short description of the expected format).
   */
  placeholder?: string;
  /**
   * Whether the associated form field is required or not
   * @defaultvalue false
   */
  required?: boolean;
  /**
   * Call to provide customized styling that will layer on top of the variant rules.
   * @defaultvalue false
   */
  styles?: ITextFieldProps['styles'];
  labelStyles?: ILabelProps['styles'];
}

function SmallTextField(props: ISmallTextFieldProps): JSX.Element {
  const {
    defaultValue,
    description,
    disabled = false,
    id,
    label,
    labelIconName,
    labelStyles,
    maxLength = 150,
    maxSingleLineChars = 150,
    multiline,
    onChange,
    placeholder,
    required = false,
    styles
  } = props;

  const [charsLength, setCharsLength] = useState<number>(defaultValue?.length || 0);

  function onTextFieldChange(_: unknown, newValue?: string): void {
    if (onChange) onChange(newValue);

    // track char length
    setCharsLength(newValue?.length || 0);
  }

  return (
    <div id={id}>
      <Label
        description={description}
        iconName={labelIconName}
        label={label}
        required={required}
        styles={labelStyles}
      />
      <TextField
        {...props}
        label={undefined}
        description={undefined}
        required={undefined}
        id={`input-${id}`}
        autoComplete="off"
        defaultValue={defaultValue}
        disabled={disabled}
        maxLength={maxLength}
        multiline={multiline || maxSingleLineChars < charsLength}
        onChange={onTextFieldChange}
        placeholder={placeholder}
        styles={styles}
      />
    </div>
  );
}

export default SmallTextField;
