import { useEffect, useRef } from 'react';

type EventHandler = (event: Event) => void;
type EventTargetType = typeof global & EventTarget;

export default function useEventListener(
  eventName: string,
  handler: EventHandler,
  element: EventTargetType = global as EventTargetType
): void {
  const savedHandler = useRef<EventHandler>();

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;

    if (!isSupported) {
      return undefined;
    }

    const eventListener = (event: Event) => savedHandler.current && savedHandler.current(event);
    element.addEventListener(eventName, eventListener);

    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
}
