import { t } from 'i18next';
import {
  BackgroundJobStatus,
  IBackgroundJob,
  IBackgroundJobResult,
  IJobProgress,
  IJobProgressItem
} from '../../../features/BackgroundJobs';
import { IProcessInstanceProps } from '../../../types';

interface IPromiseState<T> {
  label: string;
  ended: boolean;
  success: boolean;
  result?: T;
  error?: Error;
  /** If false, this promise is not strictly required to start the process */
  required: boolean;
  progress?: { value: number | null };
}

function promiseToState<T>(
  label: string,
  promise: Promise<T>,
  onUpdate: () => void,
  required: boolean,
  progress?: IPromiseState<T>['progress']
): IPromiseState<T> {
  const state = {
    label,
    ended: false,
    success: false,
    result: undefined,
    error: undefined,
    required,
    progress
  };

  promise
    .then((result) => {
      state.ended = true;
      state.success = true;
      state.result = result;
      setTimeout(() => {
        // delay update because we might be adding more sub-tasks in .then() handlers
        onUpdate();
      }, 20);
    })
    .catch((error) => {
      state.ended = true;
      state.error = error;
      onUpdate();
    });

  return state;
}

function promiseStateToProgressItem(state: IPromiseState<unknown>): IJobProgressItem {
  let progress: number | null = null;
  if (state.ended && state.success) {
    progress = 1;
  } else if (state.progress) {
    progress = state.progress.value;
  }

  return {
    label: state.label,
    progress,
    error: state.error
  };
}

function promiseStatesToStatus(states: IPromiseState<unknown>[]): BackgroundJobStatus {
  let anyPending = false;

  for (const state of states) {
    if (state.ended && !state.success && state.required) {
      return BackgroundJobStatus.Failed;
    }
    if (!state.ended) {
      anyPending = true;
    }
  }

  if (anyPending) return BackgroundJobStatus.Pending;
  return BackgroundJobStatus.Done;
}

export default class StartProcessJob implements IBackgroundJob {
  createInstance: IPromiseState<IProcessInstanceProps>;

  startInstance: IPromiseState<IProcessInstanceProps> | null = null;

  subTasks: IPromiseState<unknown>[] = [];

  listeners = new Set<() => void>();

  onOpen: (instance: IProcessInstanceProps) => IBackgroundJobResult['action'];

  onRetry: (initiatingElement: HTMLElement) => void;

  recovery = {
    label: t('startProcessInstancePanel.progress.recoveryButtonLabel'),
    action: ({ initiatingElement }) => {
      this.onRetry(initiatingElement);
    }
  };

  constructor(
    createInstance: Promise<IProcessInstanceProps>,
    onOpen: (instance: IProcessInstanceProps) => IBackgroundJobResult['action'],
    onRetry: (initiatingElement: HTMLElement) => void
  ) {
    this.createInstance = promiseToState(
      t('startProcessInstancePanel.progress.creatingInstance'),
      createInstance,
      this.dispatchUpdate,
      true
    );
    this.onOpen = onOpen;
    this.onRetry = onRetry;
  }

  addProgressUpdateListener(callback: () => void) {
    this.listeners.add(callback);
  }

  removeProgressUpdateListener(callback: () => void) {
    this.listeners.delete(callback);
  }

  dispatchUpdate = () => {
    for (const listener of this.listeners) {
      listener();
    }
  };

  addSubTask(
    label: string,
    promise: Promise<unknown>,
    required: boolean,
    progress?: IPromiseState<unknown>['progress']
  ) {
    this.subTasks.push(promiseToState(label, promise, this.dispatchUpdate, required, progress));
    this.dispatchUpdate();
  }

  addStartInstanceTask(label: string, promise: Promise<IProcessInstanceProps>) {
    this.startInstance = promiseToState(label, promise, this.dispatchUpdate, true);
    this.subTasks.push(this.startInstance);
    this.dispatchUpdate();
  }

  getProgress(): IJobProgress {
    const status = promiseStatesToStatus([this.createInstance, ...this.subTasks]);

    return {
      iconName: 'Play',
      title: (status === BackgroundJobStatus.Failed
        ? t('startProcessInstancePanel.progress.errorTitle')
        : t('startProcessInstancePanel.progress.pendingTitle')) as string,
      status,
      items: [
        promiseStateToProgressItem(this.createInstance),
        ...this.subTasks.map(promiseStateToProgressItem)
      ]
    };
  }

  getResult(): IBackgroundJobResult {
    const instance = this.startInstance?.result ?? this.createInstance.result;

    return {
      title: t('startProcessInstancePanel.progress.successTitle') as string,
      description: instance.firstTaskId ? (
        <div>
          <div>{instance.name}</div>
          <div style={{ marginTop: 5 }}>
            {t('startProcessInstancePanel.progress.successFirstTask') as string}
          </div>
        </div>
      ) : (
        instance.name
      ),
      action: this.onOpen(instance)
    };
  }
}
