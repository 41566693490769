import { EmptyListPlaceHolder, LoadingSpinner, MainCommandBar } from 'components';
import ListCustom from 'components/lists/ListCustom/ListCustom';
import { AppContext } from 'features/App';
import NoAccessContainer from 'pages/NoAccess';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import fetchRequest, { apiErrorHandler } from 'services/api';
import styled from 'styled-components';
import breakpoints from 'utils/breakpoints';
import { checkScreenWidth } from 'utils/helpers';
import { ColumnActionsMode, SelectionMode } from '@fluentui/react';
import CreateCountryDialog from '../components/CreateCountryDialog';

const CountriesListContainer = styled.div`
  background-color: #fafafa;
  overflow: hidden;
  height: 100%;
  display: flex;

  .ms-Button--icon {
    max-height: 20px;
  }

  .edit {
    color: rgb(0, 120, 212);
    &:hover {
      color: rgb(0, 69, 120);
    }
  }

  @media (max-width: ${breakpoints.extraSmallMax}px) {
    padding-bottom: 50px;
  }
`;

const CountriesRowStyled = styled.div`
  &:hover {
    .deleteButton {
      visibility: visible;
    }
  }
`;

const CountryTitleStyled = styled.span`
  color: ${(props) =>
    props.$disabled
      ? `rgb(${props.theme.detailsList.linkNameDisabledForeground})`
      : `rgb(${props.theme.detailsList.linkNameForeground})`};

  &:hover {
    color: ${(props) =>
      props.$disabled
        ? `rgb(${props.theme.detailsList.linkNameDisabledForeground})`
        : `rgb(${props.theme.detailsList.linkNameHoverForeground})`};
    cursor: ${(props) => (props.$disabled ? 'default' : 'pointer')};
  }
`;

const CountryListTableContainer = styled.div`
  margin: 20px;
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 10%) 0px 0.3px 0.9px, rgb(0 0 0 / 13%) 0px 1.6px 3.6px;
  max-width: 430px;
  max-height: 100%;
  display: flex;
  flex-direction: column;

  .details-list-wrapper {
    overflow-y: auto;
    height: 100%;
  }

  .ms-DetailsList-headerWrapper {
    top: 0;
    position: sticky;
    z-index: 100;
  }

  .ms-FocusZone {
    padding-top: 0px;
  }

  .codeColumn {
    margin: auto;
    text-align: center;
    padding-right: 0;
    padding-left: 0;
  }
`;

function CountriesAdministration() {
  // global app state
  const { globalAppState } = useContext(AppContext);
  const { currentUser, searchTerm } = globalAppState;

  const { t } = useTranslation();

  // List items
  const [countries, setCountries] = useState([]);

  // List item to create/edit/delete
  const [country, setCountry] = useState(null);

  const [selectedColumn, setSelectedColumn] = useState(null);

  // List loading (visibility) states
  const [isLoading, setIsLoading] = useState(true);

  // Dialog visibility states
  const [showCreateDialog, setShowCreateDialog] = useState(false);

  const cols = [
    {
      key: 'name',
      fieldName: 'name',
      isPadded: true,
      isMultiline: false,
      isFiltered: false,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      columnActionsMode: ColumnActionsMode.hasDropdown,
      maxWidth: checkScreenWidth(['extraSmall']) ? 215 : 250,
      minWidth: checkScreenWidth(['extraSmall']) ? 215 : 250,
      name: t('countriesAdministration.column.name'),
      onRender: (item) => (
        <CountryTitleStyled role="presentation" onClick={() => onUpdateItem(item)}>
          {item.name}
        </CountryTitleStyled>
      )
    },
    {
      key: 'code',
      fieldName: 'code',
      isPadded: true,
      isMultiline: false,
      isFiltered: false,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      columnActionsMode: ColumnActionsMode.hasDropdown,
      maxWidth: 50,
      minWidth: 50,
      name: t('countriesAdministration.column.code'),
      className: 'codeColumn',
      onRender: (item) => item.code,
      styles: {
        cellTitle: {
          paddingLeft: '31px'
        }
      }
    }
  ];

  const [columns, setColumns] = useState(cols);

  function searchItems(items) {
    if (searchTerm) {
      const foundItems = items.filter(
        (country) =>
          country.name?.trim().toLowerCase().includes(searchTerm.trim().toLowerCase()) ||
          country.code?.trim().toLowerCase().includes(searchTerm.trim().toLowerCase())
      );

      return foundItems;
    }

    return items;
  }

  const sortItems = (itemsToSort, sortBy, sortDesc) => {
    const copyAndSort = (items, columnKey, isSortedDescending) => {
      const sortedItems = items.slice(0).sort((a, b) => {
        let valueA = a[columnKey];
        let valueB = b[columnKey];

        if (typeof a[columnKey] === 'string') {
          valueA = a[columnKey].toLowerCase();
        }

        if (typeof b[columnKey] === 'string') {
          valueB = b[columnKey].toLowerCase();
        }

        if (valueA && valueB) {
          return (isSortedDescending ? valueA < valueB : valueA > valueB) ? 1 : -1;
        }

        if (valueA && !valueB) {
          return isSortedDescending ? -1 : 1;
        }

        return isSortedDescending ? 1 : -1;
      });

      return sortedItems;
    };

    const foundItems = searchItems(itemsToSort);

    const sortedItems = sortBy ? copyAndSort(foundItems, sortBy, sortDesc) : null;

    return sortedItems;
  };

  function onSortItems(column, columns) {
    const sortedItems = sortItems(countries, column.fieldName, column.isSortedDescending);

    setColumns(columns);
    setSelectedColumn(column);
    setCountries(sortedItems);
  }

  const onAddNewItem = () => {
    setCountry(null);
    setShowCreateDialog(true);
  };

  const onDialogCancel = () => {
    setShowCreateDialog(false);
  };

  const onDialogConfirm = (newCountry, closeDialog) => {
    if (closeDialog) {
      setShowCreateDialog(false);
    }

    setCountries((prevState) => {
      const newCountries = [...prevState];
      const index = newCountries.findIndex((c) => c.id === newCountry.id);

      if (index > -1) {
        newCountries[index] = newCountry;
      } else {
        newCountries.unshift(newCountry);
      }

      return newCountries;
    });
  };

  const loadItems = useCallback(async () => {
    setIsLoading(true);

    const items = await fetchRequest({ url: 'Countries' }).catch(apiErrorHandler);

    if (items) {
      const setCountry = (country, index) => ({ ...country, index });

      const allCountries = items.map((item, index) => setCountry(item, index));

      setCountries(allCountries);

      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    loadItems();
  }, [loadItems]);

  if (!currentUser?.isAdmin) {
    return <NoAccessContainer />;
  }

  const onUpdateItem = (country) => {
    setCountry(country);
    setShowCreateDialog(true);
  };

  function getCountries() {
    let newCountries = searchItems(countries);

    if (newCountries) {
      const sortedColumn = columns.find((col) => col.isSorted);

      if (sortedColumn) {
        newCountries = sortItems(
          countries,
          selectedColumn?.fieldName,
          selectedColumn?.isSortedDescending
        );
      }
    }

    return newCountries;
  }

  function getCountryList() {
    const filteredAndSortedCountries = getCountries();

    if (filteredAndSortedCountries?.length) {
      return (
        <CountryListTableContainer>
          <div className="details-list-wrapper" key={countries?.length}>
            <ListCustom
              items={filteredAndSortedCountries}
              selectionMode={SelectionMode.none}
              selectionPreservedOnEmptyClick
              enterModalSelectionOnTouch
              columns={columns}
              onRenderRow={(props, defaultRender) => (
                <CountriesRowStyled>{defaultRender({ ...props })}</CountriesRowStyled>
              )}
              onSortItems={onSortItems}
            />
          </div>
        </CountryListTableContainer>
      );
    }

    return (
      <EmptyListPlaceHolder
        hidden={!!filteredAndSortedCountries?.length}
        onCreateNew={!searchTerm ? onAddNewItem : null}
        createNewText={t('countriesAdministration.newCountry')}
        noItemsText={
          searchTerm
            ? t('countriesAdministration.emptySearchResults')
            : t('countriesAdministration.placeHolder')
        }
        listIconName={searchTerm ? 'SearchIssue' : 'MapPinSolid'}
      />
    );
  }

  if (isLoading) {
    return <LoadingSpinner label={t('loading.countries.text')} />;
  }

  return (
    <>
      <MainCommandBar
        className="full-width"
        items={[
          {
            key: 'addCountry',
            className: 'command-bar-item',
            text: t('countriesAdministration.newCountry'),
            iconProps: { iconName: 'Add' },
            onClick: () => onAddNewItem()
          }
        ]}
      />
      <CountriesListContainer id="CountriesList">
        {getCountryList()}
        {showCreateDialog ? (
          <CreateCountryDialog
            hidden={!showCreateDialog}
            onCancel={onDialogCancel}
            onConfirm={onDialogConfirm}
            country={country}
          />
        ) : null}
      </CountriesListContainer>
    </>
  );
}

export default CountriesAdministration;
