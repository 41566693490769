import { createContext } from 'react';
import {
  AnyApiObject,
  AnyApiObjectType,
  ApiObject,
  deriveApiObjectId,
  IApiObjectCache,
  IApiObjectData,
  IApiObjectId,
  IApiObjectKey,
  IApiObjectVariantId,
  KEY,
  InferApiObjectTypeParams,
  ApiObjectVariant,
  ApiObjectEvent,
  InferApiObjectTypeDataFull,
  IApiObjectSubscriber,
  InferApiObjectTypeData,
  InferApiObjectTypeDataSmall
} from './object';
import {
  AnyApiListType,
  getObjectTypeForListType,
  IApiListPage,
  InferApiListData,
  InferApiListItemType,
  InferApiListItemVariant,
  InferApiListParams
} from './list';

// FIXME: cannot implement GC yet because of TODO in list.ts

export class ApiDataCache implements IApiObjectCache {
  tenantId: string;

  memberId: string;

  objects = new Map<IApiObjectId, AnyApiObject>();

  variantDependents = new Map<IApiObjectVariantId, Set<AnyApiObject>>();

  objectLoadDedupIntervalSecs = 3;

  constructor(tenantId: string, memberId: string) {
    this.tenantId = tenantId;
    this.memberId = memberId;
  }

  private createObject(key: IApiObjectKey<unknown>) {
    const object = new ApiObject(this, key);
    this.objects.set(object.id, object);
  }

  getObject<T extends AnyApiObjectType>(
    key: IApiObjectKey<InferApiObjectTypeParams<T>>
  ): ApiObject<T> {
    const id = deriveApiObjectId(key);
    if (!this.objects.has(id)) {
      this.createObject(key);
    }
    return this.objects.get(id);
  }

  insertObjectData(data: IApiObjectData): void {
    this.getObject(data[KEY]).acceptLoaded(data);
  }

  addObjectDependency(object: AnyApiObject, dependency: IApiObjectVariantId) {
    if (!this.variantDependents.has(dependency)) {
      this.variantDependents.set(dependency, new Set());
    }
    this.variantDependents.get(dependency).add(object);
  }

  removeObjectDependency(object: AnyApiObject, dependency: IApiObjectVariantId) {
    this.variantDependents.get(dependency)?.delete(object);
    if (!this.variantDependents.get(dependency)?.size) {
      this.variantDependents.delete(dependency);
    }
  }

  getVariantDependents(variant: IApiObjectVariantId): Set<AnyApiObject> {
    return this.variantDependents.get(variant) ?? new Set();
  }

  getData<T extends AnyApiObjectType>(
    type: T,
    params: InferApiObjectTypeParams<T>,
    options?: { alwaysLoad?: boolean; variant?: ApiObjectVariant.Full; includes?: string[] }
  ): Promise<InferApiObjectTypeDataFull<T>>;

  getData<T extends AnyApiObjectType>(
    type: T,
    params: InferApiObjectTypeParams<T>,
    options?: { alwaysLoad?: boolean; variant: ApiObjectVariant.Small; includes?: string[] }
  ): Promise<InferApiObjectTypeDataSmall<T>>;

  getData<T extends AnyApiObjectType>(
    type: T,
    params: InferApiObjectTypeParams<T>,
    options?: { alwaysLoad?: boolean; variant: ApiObjectVariant; includes?: string[] }
  ): Promise<InferApiObjectTypeData<T>>;

  /** Returns data for the object, loading it if needed. */
  getData<T extends AnyApiObjectType>(
    type: T,
    params: InferApiObjectTypeParams<T>,
    options?: { alwaysLoad?: boolean; variant?: ApiObjectVariant; includes?: string[] }
  ): Promise<InferApiObjectTypeData<T>> {
    const variant = options?.includes
      ? ApiObjectVariant.Small
      : options?.variant ?? ApiObjectVariant.Full;

    const obj = this.getObject({ type, params });

    if (!options?.alwaysLoad) {
      const data = obj.get(variant, options?.includes);
      if (data) return Promise.resolve(data);
    }

    return new Promise((resolve, reject) => {
      const subscriber: IApiObjectSubscriber<T> = {
        variant,
        includes: options?.includes,
        handle(obj, type) {
          if (type === ApiObjectEvent.LoadSucceeded) {
            obj.removeSubscriber(subscriber);
            resolve(obj.get(variant));
          } else if (type === ApiObjectEvent.LoadFailed) {
            obj.removeSubscriber(subscriber);
            reject(obj.lastLoadError);
          }
        }
      };
      obj.addSubscriber(subscriber, 'always');
    });
  }

  /**
   * Loads a list page imperatively.
   *
   * Take care to pass previous page data if required!
   */
  getListData<T extends AnyApiListType>(
    type: T,
    params: InferApiListParams<T>,
    pageIndex: number,
    prevPageData?: IApiListPage<
      InferApiListItemType<T>,
      InferApiListData<T>,
      InferApiListItemVariant<T>
    >
  ): Promise<
    IApiListPage<InferApiListItemType<T>, InferApiListData<T>, InferApiListItemVariant<T>>
  > {
    const objType = getObjectTypeForListType(type);
    const abort = new AbortController();
    return objType.load(
      this,
      {
        params,
        pageIndex,
        prevPageRef: prevPageData ? { get: () => prevPageData } : undefined
      },
      abort.signal,
      { includes: [] }
    );
  }
}

export const ApiDataCacheContext = createContext<ApiDataCache>(new ApiDataCache('null', 'null'));
