import { useState } from 'react';
import { getTeam } from 'services/fetchRequests';
import { ITeamProps, IUserProps } from 'types';

export default function useTeamMembersSearch(teamId?: string): {
  searchTeamMembers: (searchTerm?: string) => Promise<IUserProps[]>;
} {
  const [team, setTeam] = useState<ITeamProps | null>(null);

  async function searchTeamMembers(searchTerm?: string): Promise<IUserProps[]> {
    let teamMembers: IUserProps[] = [];

    if (team) {
      teamMembers = team.members || [];
    }

    if ((teamId && !team) || (teamId && team?.id !== teamId)) {
      const teamResult = await getTeam(teamId);

      if (teamResult) {
        setTeam(teamResult);

        teamMembers = teamResult.members || [];
      }
    }

    if (searchTerm && searchTerm !== '***') {
      teamMembers = teamMembers.filter((member) => {
        const { name, login } = member;

        return (
          name?.toLowerCase().includes(searchTerm.toLowerCase()) || login?.includes(searchTerm)
        );
      });
    }

    return teamMembers;
  }

  return { searchTeamMembers };
}
