/* eslint-disable jsx-a11y/click-events-have-key-events */

import { TextNode } from 'lexical';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { tagsSearch } from 'services/fetchRequests';
import styled from 'styled-components';
import { ITagProps } from 'types';
import { Shimmer, ShimmerElementType, ShimmerElementsGroup, Text } from '@fluentui/react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  LexicalTypeaheadMenuPlugin,
  MenuOption,
  MenuTextMatch,
  useBasicTypeaheadTriggerMatch
} from '@lexical/react/LexicalTypeaheadMenuPlugin';
import { TagSuggestion } from '../RichTextEditor.styles';
import { $createTagNode } from '../components/TagNode';
import { generateUuid } from '../../../../utils/helpers';

const ItemsWrapper = styled.div<{ $bottom?: number }>`
  min-width: fit-content;
  z-index: 9999999;
  position: absolute;
  bottom: ${(props) => props.$bottom}px;

  .dropdown .item {
    display: flex;
    padding: 3px;
    border-radius: 8px;
    width: 20px;
    height: 20px;
    user-select: none;
    margin-right: 12px;
    line-height: 16px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .tags-menu {
    width: 250px;
  }

  .tag:focus {
    box-shadow: rgb(180 213 255) 0px 0px 0px 2px;
    outline: none;
  }

  .typeahead-popover {
    background: rgb(${({ theme }) => theme.richTextEditor.typeaheadPopoverBackground});
    box-shadow: ${({ theme }) => theme.richTextEditor.typeaheadPopoverShadow};
    border-radius: 8px;
    width: auto;
  }

  .typeahead-popover ul {
    padding: 0;
    list-style: none;
    margin: 0;
    border-radius: 8px;
    max-height: 200px;
    overflow-y: scroll;
  }

  .typeahead-popover ul::-webkit-scrollbar {
    display: none;
  }

  .typeahead-popover ul {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .typeahead-popover ul li {
    margin: 0;
    min-width: 180px;
    font-size: 14px;
    outline: none;
    cursor: pointer;
    border-radius: 8px;
  }

  .typeahead-popover ul li.selected {
    background: rgb(${({ theme }) => theme.richTextEditor.typeaheadPopoverItemSelectedBackground});
  }

  .typeahead-popover li {
    margin: 4px;

    color: rgb(${({ theme }) => theme.richTextEditor.typeaheadPopoverForeground});
    cursor: pointer;
    line-height: 16px;
    font-size: 15px;
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-shrink: 0;
    background: rgb(${({ theme }) => theme.richTextEditor.typeaheadPopoverBackground});
    border-radius: 8px;
    border: 0;
  }

  .typeahead-popover li.active {
    display: flex;
    width: 20px;
    height: 20px;
    background-size: contain;
  }

  .typeahead-popover li:first-child {
    border-radius: 8px 8px 0px 0px;
  }

  .typeahead-popover li:last-child {
    border-radius: 0px 0px 8px 8px;
  }

  .typeahead-popover li:hover {
    background-color: rgb(
      ${({ theme }) => theme.richTextEditor.typeaheadPopoverItemSelectedBackground}
    );
  }

  .typeahead-popover li .text {
    display: flex;
    line-height: 20px;
    flex-grow: 1;
    min-width: 150px;
  }

  .suggestion-title {
    padding-top: 0px;
    padding-right: 12px;
    padding-bottom: 0px;
    padding-left: 12px;
    font-size: 12px;
    color: rgb(${({ theme }) => theme.richTextEditor.suggestionTitleForeground});
    line-height: 40px;
    border-bottom: 1px solid rgb(${({ theme }) => theme.richTextEditor.suggestionDivider});
    font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system,
      BlinkMacSystemFont, Roboto, 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
  }
`;

const PUNCTUATION = '\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%\'"~=<>_:;';

const TRIGGERS_HASHTAG = ['#'].join('');

const VALID_CHARS = `[^${TRIGGERS_HASHTAG}${PUNCTUATION}\\s]`;

const VALID_JOINS_HASHTAG = '.';

const HASHTAG_ALIAS_LENGTH_LIMIT = 50;

const HashTagsRegex = new RegExp(
  `(^|\\s|\\()([${TRIGGERS_HASHTAG}]((?:${VALID_CHARS})+)(?:${VALID_JOINS_HASHTAG}((?:${VALID_CHARS})+))?)`
);

const HashTagsAliasRegex = new RegExp(
  `(^|\\s|\\()([${TRIGGERS_HASHTAG}]((?:${VALID_CHARS}){0,${HASHTAG_ALIAS_LENGTH_LIMIT}}))$`
);

const SUGGESTION_LIST_LENGTH_LIMIT = 4;

function getTags(searchText: string, signal?: AbortSignal) {
  return tagsSearch({ searchTerm: searchText, signal }).then((searchResult) => searchResult);
}

function useTagLookupService(tagString: string | null) {
  const [results, setResults] = useState<(ITagProps & { isNewlyCreated?: boolean })[]>([]);
  const [loading, setLoading] = useState(false);

  const abortController = useRef(new AbortController());
  const debouncedGetTags = useRef(
    debounce((searchTerm, signal) => {
      let finalSearchTerm = searchTerm;
      let childTagText = '';

      if (searchTerm.includes('.')) {
        // eslint-disable-next-line prefer-destructuring
        finalSearchTerm = searchTerm.split('.')[0];
        // eslint-disable-next-line prefer-destructuring
        childTagText = searchTerm.split('.')[1];
      }

      setLoading(true);
      getTags(finalSearchTerm, signal).then((result) => {
        let resultCopy: (ITagProps & { isNewlyCreated?: boolean })[] = [...result];

        if (finalSearchTerm !== searchTerm) {
          resultCopy = resultCopy.filter(
            (tag) =>
              tag.children?.length && tag.text?.toLowerCase() === finalSearchTerm.toLowerCase()
          );

          if (resultCopy.length > 0 && resultCopy[0].children) {
            resultCopy = resultCopy[0].children.filter(
              (tag: ITagProps) => tag.text?.toLowerCase() === childTagText.toLowerCase()
            );
          }
        }

        resultCopy = resultCopy.slice(0, SUGGESTION_LIST_LENGTH_LIMIT);

        if (
          searchTerm &&
          !searchTerm.includes('.') &&
          !result.some(
            (result) => result.text?.toLocaleLowerCase() === searchTerm.toLocaleLowerCase()
          )
        ) {
          resultCopy.push({
            id: generateUuid(),
            text: searchTerm,
            fullText: searchTerm,
            isNewlyCreated: true
          });
        }

        setResults(resultCopy);
        setLoading(false);
      });
    }, 300)
  ).current;

  useEffect(() => {
    abortController.current.abort();
    abortController.current = new AbortController();

    if (tagString == null) {
      setResults([]);
      return;
    }

    if (tagString && tagString.length) {
      setResults([]);

      debouncedGetTags(tagString, abortController.current.signal);
    }
  }, [debouncedGetTags, tagString]);

  return { results, loading };
}

function checkForAtSignTags(text: string, minMatchLength: number): MenuTextMatch | null {
  let match = HashTagsRegex.exec(text);

  if (match === null) {
    match = HashTagsAliasRegex.exec(text);
  }

  if (match !== null) {
    // The strategy ignores leading whitespace but we need to know it's
    // length to add it to the leadOffset
    const maybeLeadingWhitespace = match[1];

    const parentTag = match[3];
    const childTag = match[4]; // Verwende den Unter-Tag, falls vorhanden. Sonst den Haupt-Tag.

    const tagString = childTag || parentTag;

    if (tagString.length >= minMatchLength) {
      return {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString: childTag ? `${parentTag}.${childTag}` : tagString,
        replaceableString: match[2]
      };
    }
  }

  return null;
}

function getPossibleQueryMatch(text: string): MenuTextMatch | null {
  return checkForAtSignTags(text, 1);
}

class TagTypeaheadOption extends MenuOption {
  text: string;

  fullText?: string;

  parentId?: string;

  id?: string;

  children?: Array<ITagProps>;

  /** If true, we just created this tag, and it doesn't exist in the backend */
  isNewlyCreated: boolean;

  constructor({
    text = '',
    fullText = '',
    parentId = '',
    id = undefined,
    children = [],
    isNewlyCreated = false
  }: {
    text?: string;
    fullText?: string;
    parentId?: string;
    id?: string;
    children?: Array<ITagProps>;
    isNewlyCreated?: boolean;
  }) {
    super(text);
    this.text = text;
    this.fullText = fullText;
    this.parentId = parentId;
    this.id = id;
    this.children = children;
    this.isNewlyCreated = isNewlyCreated;
  }
}

function TagsTypeaheadMenuItem({
  index,
  isSelected,
  onClick,
  onMouseEnter,
  option
}: {
  index: number;
  isSelected: boolean;
  onClick: () => void;
  onMouseEnter: () => void;
  option: TagTypeaheadOption;
}) {
  const { t } = useTranslation();

  let className = 'item';

  if (isSelected) {
    className += ' selected';
  }

  const optionText = () => {
    if (option.id && !option.isNewlyCreated) {
      return `#${option.fullText}`;
    }

    return `${t('rte.suggestions.createTag')} #${option.text}`;
  };

  return (
    <li
      style={{ minWidth: 200 }}
      key={option.key}
      tabIndex={-1}
      className={className}
      ref={option.setRefElement}
      role="option"
      aria-selected={isSelected}
      id={`typeahead-item-${index}`}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
    >
      <TagSuggestion
        key={option.text}
        isSelected={isSelected}
        onClick={(event) => {
          event.preventDefault();
          // commitSelection({ event, selectedIndex: index });
        }}
      >
        {optionText()}
      </TagSuggestion>
    </li>
  );
}

export default function TagsPlugin(): JSX.Element | null {
  const { t } = useTranslation();

  const [editor] = useLexicalComposerContext();

  const [queryString, setQueryString] = useState<string | null>(null);

  const { results, loading } = useTagLookupService(queryString);

  const checkForSlashTriggerMatch = useBasicTypeaheadTriggerMatch('/', {
    minLength: 0
  });

  const options = useMemo(() => results.map((result) => new TagTypeaheadOption(result)), [results]);

  const onSelectOption = useCallback(
    (selectedOption: TagTypeaheadOption, nodeToReplace: TextNode | null, closeMenu: () => void) => {
      editor.update(() => {
        const tagNode = $createTagNode({
          tagName: `#${selectedOption.fullText}`,
          id: selectedOption.id
        });

        if (nodeToReplace) {
          nodeToReplace.replace(tagNode);
        }

        tagNode.select();

        closeMenu();
      });
    },
    [editor]
  );

  const checkForTagMatch = useCallback(
    (text: string) => {
      const slashMatch = checkForSlashTriggerMatch(text, editor);
      if (slashMatch !== null) {
        return null;
      }

      return getPossibleQueryMatch(text);
    },
    [checkForSlashTriggerMatch, editor]
  );

  function getShimmer() {
    return (
      <div style={{ padding: '3px 10px' }}>
        <Shimmer
          customElementsGroup={
            <div style={{ display: 'flex', width: 200 }}>
              <ShimmerElementsGroup
                shimmerElements={[{ type: ShimmerElementType.gap, width: 16, height: 40 }]}
              />
              <ShimmerElementsGroup
                flexWrap
                width="100%"
                shimmerElements={[
                  {
                    type: ShimmerElementType.line,
                    width: '100%',
                    height: 10,
                    verticalAlign: 'bottom'
                  },
                  { type: ShimmerElementType.line, width: '90%', height: 8 },
                  { type: ShimmerElementType.gap, width: '10%', height: 20 }
                ]}
              />
            </div>
          }
        />
      </div>
    );
  }

  function renderMenu(
    anchorElementRef: React.RefObject<HTMLElement>,
    {
      selectedIndex,
      selectOptionAndCleanUp,
      setHighlightedIndex
    }: {
      selectedIndex: number | null;
      selectOptionAndCleanUp: (option: TagTypeaheadOption) => void;
      setHighlightedIndex: (index: number) => void;
    }
  ) {
    if (!anchorElementRef.current) {
      return null;
    }

    // diff is the distance from the bottom of the menu to the bottom of the window
    const diff = window.innerHeight - anchorElementRef.current.getBoundingClientRect().bottom;

    if (options.length || loading) {
      return ReactDOM.createPortal(
        <ItemsWrapper $bottom={diff < 70 ? 35 : undefined}>
          <div className="typeahead-popover tags-menu">
            <div className="suggestion-title">{t('rte.suggestions.selectOrCreate')}</div>
            {loading ? (
              <>
                <div>{getShimmer()}</div>
                <div>{getShimmer()}</div>
              </>
            ) : (
              <ul>
                {options.map((option, i: number) => (
                  <TagsTypeaheadMenuItem
                    index={i}
                    isSelected={selectedIndex === i}
                    onClick={() => {
                      setHighlightedIndex(i);
                      selectOptionAndCleanUp(option);
                    }}
                    onMouseEnter={() => {
                      setHighlightedIndex(i);
                    }}
                    key={option.key}
                    option={option}
                  />
                ))}
              </ul>
            )}
          </div>
        </ItemsWrapper>,
        anchorElementRef.current
      );
    }

    if (queryString) {
      return ReactDOM.createPortal(
        <ItemsWrapper $bottom={diff < 70 ? 35 : undefined}>
          <div style={{ minWidth: 200 }} className="typeahead-popover tags-menu">
            <div className="suggestion-title">{t('rte.suggestions.selectOrCreate')}</div>
            <Text styles={{ root: { lineHeight: '35px', margin: '12px' } }}>
              {t('tagPicker.suggestions.noResult', { query: queryString })}
            </Text>
          </div>
        </ItemsWrapper>,
        anchorElementRef.current
      );
    }

    return null;
  }

  return (
    <LexicalTypeaheadMenuPlugin<TagTypeaheadOption>
      onQueryChange={setQueryString}
      onSelectOption={onSelectOption}
      triggerFn={checkForTagMatch}
      options={options}
      menuRenderFn={renderMenu}
    />
  );
}
