import styled from 'styled-components';

const SignaturePadWrapper = styled.div`
  width: 100%;
  user-select: none;

  .c-signature-pad {
    background-color: white;
  }
`;

export default SignaturePadWrapper;
