import { IMessageAI, IMessageUser } from '../../hooks/api2';

/** Types of objects in a chat. */
export enum ChatItemType {
  /** An assistant chat item that is being generated. */
  AssistantGenerating,
  /** An assistant chat item that is completed, whether successfully or not. */
  AssistantCompleted,
  /** A chat item sent by a user. */
  User,
  /** Some kind of hidden system message */
  System
}

/** Network connection status for a chat. */
export enum ChatConnectionStatus {
  /** This is a stateless connection, which means that we're just making individual requests to the backend. */
  Stateless = 'stateless',
  NotConnected = 'notConnected',
  Connecting = 'connecting',
  Connected = 'connected',
  Disconnecting = 'disconnecting'
}

/** Current status of chat history */
export enum ChatHistoryStatus {
  /** No history has been loaded yet */
  NotReady = 'not-ready',
  /** Initial history is being loaded */
  LoadingInitial = 'loading-init',
  /** Sufficient history has been loaded */
  Idle = 'idle',
  /** More history is being loaded, e.g. due to scrolling */
  LoadingMore = 'loading-more',
  /** An error occurred while loading history */
  Error = 'error'
}

export enum ChatActivityType {
  CreateWebSearchQueries = 'create-websearch-queries',
  CompletedWebSearchQueries = 'completed-websearch-queries',
  ExecuteWebSearchQuery = 'execute-websearch-query',
  CompletedWebSearchQuery = 'completed-websearch-query'
}

interface IChatActivityWebSearchBase {
  $type: ChatActivityType;
  messageId: string;
}

export interface IChatActivityCreateWebSearchQueries extends IChatActivityWebSearchBase {
  $type: ChatActivityType.CreateWebSearchQueries;
}

export interface IChatActivityCompletedWebSearchQueries extends IChatActivityWebSearchBase {
  $type: ChatActivityType.CompletedWebSearchQueries;
  /** List of possible search queries */
  queries: string[];
}

export interface IChatActivityExecuteWebSearchQuery extends IChatActivityWebSearchBase {
  $type: ChatActivityType.ExecuteWebSearchQuery;
  /** Search query */
  query: string;
}

export interface IChatActivityCompletedWebSearchQuery extends IChatActivityWebSearchBase {
  $type: ChatActivityType.CompletedWebSearchQuery;
  /** List of URLs */
  results: string[];
}

export type IChatActivityWebSearch =
  | IChatActivityCreateWebSearchQueries
  | IChatActivityCompletedWebSearchQueries
  | IChatActivityExecuteWebSearchQuery
  | IChatActivityCompletedWebSearchQuery;

// there are other activity types not represented here
export type IChatActivity = IChatActivityWebSearch;

/** Returns a ChatActivityWebSearch if the activity is one. */
export function chatActivityAsWebSearch(activity: IChatActivity): IChatActivityWebSearch | null {
  if (
    activity.$type === ChatActivityType.CreateWebSearchQueries ||
    activity.$type === ChatActivityType.CompletedWebSearchQueries ||
    activity.$type === ChatActivityType.ExecuteWebSearchQuery ||
    activity.$type === ChatActivityType.CompletedWebSearchQuery
  ) {
    return activity;
  }

  return null;
}

/** TODO */
export enum ChatFileStatus {
  StartingExtract = 'starting_extract'
}

export enum MessageFragmentType {
  Text,
  LocalToolCall,
  WebSearch
}

/** Shows normal text. Markdown for the assistant */
export interface IMessageFragmentText {
  id: string;
  type: MessageFragmentType.Text;
  text: string;
}

/** Represents a local tool call: the assistant called a tool in the client */
export interface IMessageFragmentLocalToolCall {
  id: string;
  type: MessageFragmentType.LocalToolCall;
  toolId: string;
  /** Function arguments, *probably* JSON */
  args: string;
  /** The result from the tool function */
  result: IFunctionToolResult | null;
}

/** Note: this enum has ordering */
export enum WebSearchQueryStatus {
  Created,
  Executing,
  Executed,
  CreatingResults,
  CompletedResults
}

export const INTERNAL_STATE_LAST_UPDATED_QUERY = Symbol('lastUpdatedQuery');

/** Represents a web search performed by the assistant */
export interface IMessageFragmentWebSearch {
  /** Client-side ID because we don't get one from the backend at the moment */
  id: string;
  type: MessageFragmentType.WebSearch;

  isCreatingQueries: boolean;
  isCreatingResults: boolean;

  /**
   * If true, we got a completed-webresults event,
   * but this doesn't guarantee that the assistant won't decide to search something else again.
   */
  isMaybeCompleted: boolean;

  /** True if isMaybeCompleted and the assistant continued writing some text */
  isCompleted: boolean;

  /** List of queries and their statuses */
  queries: Map<string, WebSearchQueryStatus>;

  /** List of web search result URLs */
  resultUrls: string[];

  /** State needed to update queries properly */
  [INTERNAL_STATE_LAST_UPDATED_QUERY]: string | null;
}

export type IMessageFragment =
  | IMessageFragmentText
  | IMessageFragmentLocalToolCall
  | IMessageFragmentWebSearch;

export interface IChatItemAssistant {
  type: ChatItemType.AssistantGenerating | ChatItemType.AssistantCompleted;
  id: string;
  message: IMessageAI;
  fragments: IMessageFragment[];
}

export interface IChatItemUser {
  type: ChatItemType.User;
  id: string;
  message: IMessageUser;
  fragments: IMessageFragment[];
}

export enum SystemMessageType {
  /** The system prompt */
  SystemPrompt
}

export interface IChatItemSystem {
  type: ChatItemType.System;
  systemType: SystemMessageType;
  id: string;
  text: string;
}

export type IChatItem = IChatItemAssistant | IChatItemUser | IChatItemSystem;

/** A function tool that performs an action in the client. */
export interface ILocalFunctionToolDefinition {
  name: string;
  description?: string;
  parameters?: Record<string, unknown>;
  run: (parameters: Record<string, unknown>) => Promise<IFunctionToolResult>;
}

export interface IFunctionToolResult {
  /** String value to return to assistant */
  value: string;
  /** If not undefined: unsuccessful result */
  error?: Error;
  /** Data for UI. Undefined if an error occurred */
  uiData?: unknown;
}
