import RichTextEditor, { IRichTextEditorProps } from 'components/inputs/RichTextEditor';
import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ITagProps, IUserProps } from 'types';
import { IconButton } from '@fluentui/react';

export interface IEditorProps {
  /**
   * Comment to display
   */
  comment?: string | null;
  /**
   * Optional flag to mark Editor as readOnly / disabled
   * @defaultvalue true
   */
  disabled?: boolean;
  /**
   * Determines whether the editor text can be edited
   */
  editMode?: boolean;
  /**
   * Text for the label
   */
  onCancelEditMode?: () => void;
  /**
   * Callback issued when the comment is saved
   */
  onSaveComment: (newComment: IEditorValueProps) => void;
  /**
   * Callback issued when a tag is clicked.
   */
  onTagClick?: IRichTextEditorProps['onTagClick'];
  /**
   * Input placeholder text. Displayed until text is entered.
   */
  placeholder?: IRichTextEditorProps['placeholder'];
  /**
   * Determines whether the cmdBar should be displayed
   * @defaultvalue true
   */
  displayCmdBar?: boolean;
}

export interface IEditorValueProps {
  message?: string | null;
  mentions: IUserProps[];
  tags: ITagProps[];
  discussionId?: string;
  id?: string;
}

function Editor({
  comment,
  disabled = true,
  displayCmdBar = true,
  editMode,
  onCancelEditMode,
  onSaveComment,
  onTagClick,
  placeholder
}: IEditorProps): JSX.Element | null {
  const { t } = useTranslation();

  const [newCommentValue, setNewCommentValue] = useState<IEditorValueProps | null>(null);
  const [editorKey, setEditorKey] = useState(1);

  useEffect(() => {
    // by giving a new key the default value is displayed when canceled/changed/edited or deleted
    setEditorKey((prevKey) => prevKey + 1);
  }, [disabled, comment]);

  /**
   * Sets the state to track current editor value
   */
  const onRTEChange = debounce(({ value, mentions, tags }) => {
    if (!disabled) {
      let valueToProvide = value;

      if (valueToProvide === '<p><br></p>') {
        // empty value
        valueToProvide = null;
      }

      setNewCommentValue({ message: value, mentions, tags });
    }
  }, 250);

  /**
   * handle saving. Only saves if a message is provided.
   */
  function onSave(): void {
    if (onSaveComment && newCommentValue) {
      const { message, mentions, tags } = newCommentValue;

      if (message) {
        onSaveComment({ message, mentions, tags });
      }
    }
  }

  /**
   * Cancel editing. Reset state
   */
  function onCancel(): void {
    setNewCommentValue(null);

    if (onCancelEditMode) {
      onCancelEditMode();
    }
  }

  const editorStyles: IRichTextEditorProps['styles'] = {
    editor: {
      borderBottom: '1px solid #edebe9',
      backgroundColor: 'rgb(250, 249, 248)',
      fontSize: '13px'
    },
    editorWrapper: {
      backgroundColor: 'white',
      color: 'rgb(96, 94, 92)',
      padding: editMode ? '10px 15px 8px 15px' : '6px 6px 8px 6px'
    }
  };

  return (
    <>
      <RichTextEditor
        allowMentioning={false}
        allowTagging
        defaultValue={newCommentValue?.message || comment}
        disabled={disabled}
        displayCmdBar={displayCmdBar}
        key={editorKey}
        onChange={onRTEChange}
        onTagClick={onTagClick}
        placeholder={placeholder}
        required={false}
        setInitialFocus
        styles={editorStyles}
      />
      {!disabled && !editMode && (
        <div style={{ position: 'relative' }}>
          <IconButton
            disabled={false}
            iconProps={{ iconName: 'Send', styles: { root: { fontSize: 16 } } }}
            onClick={onSave}
            styles={{ root: { position: 'absolute', top: '-42px', right: '4px' } }}
            title={t('conversation.editor.send')}
          />
        </div>
      )}
      {!disabled && editMode && (
        <div style={{ position: 'relative' }}>
          <IconButton
            disabled={false}
            iconProps={{ iconName: 'CheckMark', styles: { root: { fontSize: 16 } } }}
            onClick={onSave}
            styles={{ root: { position: 'absolute', top: '-42px', right: '4px' } }}
            title={t('conversation.editor.save')}
          />
          <IconButton
            disabled={false}
            iconProps={{ iconName: 'Cancel', styles: { root: { fontSize: 16 } } }}
            onClick={onCancel}
            styles={{ root: { position: 'absolute', top: '-42px', right: '34px' } }}
            title={t('conversation.editor.cancel')}
          />
        </div>
      )}
    </>
  );
}

export default Editor;
