/* eslint-disable react/jsx-props-no-spreading */

import { DateTime } from 'components';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { projectPropType } from 'types';
import { formatDate } from 'utils/helpers';
import {
  AnimationStyles,
  DetailsHeader,
  DetailsList,
  DetailsListLayoutMode,
  IconButton,
  MessageBar,
  MessageBarType,
  SelectionMode,
  TextField,
  TooltipHost,
  getTheme
} from '@fluentui/react';

const SprintsContainer = styled.div`
  position: relative;
  margin-top: 5px;
  margin-bottom: 10px;
  padding: 10px;

  .ms-DetailsList-headerWrapper {
    max-width: auto;
    padding: 0;
    margin-right: auto;
  }

  .ms-DetailsHeader {
    padding-top: 0;
  }

  .ms-DetailsHeader-cell:hover {
    background-color: transparent;
  }

  .ms-DetailsHeader-cell[data-item-key='required'] {
    .ms-DetailsHeader-cellTitle {
      padding: 0;

      .ms-DetailsHeader-cellName {
        width: 100%;
        text-align: center;
      }
    }
  }

  .centered {
    margin: 0;
    padding: 0;
    position: absolute;
    top: 50%;
    left: 51%;
    transform: translate(-50%, -51%);
  }

  .hidden {
    display: none;
  }
`;

const SprintRowStyled = styled.div`
  border-bottom: 1px solid rgb(245, 245, 245);

  .edit-button {
    visibility: hidden;
  }

  .delete-button {
    visibility: hidden;
  }

  &:hover {
    .edit-button {
      visibility: visible;
    }

    .delete-button {
      visibility: visible;
    }
  }
`;

const DetailsHeaderStyled = styled(DetailsHeader)`
  .ms-DetailsHeader-cell {
    padding: 0 5px 0 5px;
  }

  .ms-DetailsHeader-cell {
    padding: 0;
  }
`;

const ColumnName = styled.div`
  font-weight: 600;
`;

const ErrorMessage = styled.p`
  color: rgb(164, 38, 44);
  margin-left: 10px;
  margin-right: 15px;
`;
function ProjectSprintsPivotItem({
  project,
  sprints,
  saveSprint,
  addNewSprint,
  disabled,
  updateSprintErrors,
  deleteSprint
}) {
  const { t } = useTranslation();
  const sprintListRef = useRef(null);
  const editorRef = useRef(null);

  const [newSprint, setNewSprint] = useState({});
  const [editedSprint, setEditedSprint] = useState(null);

  const [initiallyFocused, setInitiallyFocused] = useState(false);

  const { sprintId } = useParams();

  const [inputErrors, setInputErrors] = useState(null);
  const [notification, setNotification] = useState(null);

  const hasValidSprintParam =
    sprintId &&
    sprintId !== '00000000-0000-0000-0000-000000000000' &&
    sprintId !== '11111111-1111-1111-1111-111111111111' &&
    sprintId !== 'ffffffff-ffff-ffff-ffff-ffffffffffff';

  const scrollToIndex = useCallback((sprintListRef, index) => {
    sprintListRef.current.scrollToIndex(index || sprintListRef.current.props.items.length - 1);
  }, []);

  const calculateNewSprintsDates = useCallback(
    (passedSprints) => {
      let { startDate } = project;
      let endDate = formatDate(moment(startDate).add(project.sprintDuration, 'w'), 'yyyy-MM-DD');

      if (passedSprints?.length) {
        startDate = formatDate(
          moment(passedSprints[passedSprints.length - 1]?.endDate).add(1, 'd'),
          'yyyy-MM-DD'
        );
        endDate = formatDate(
          moment(startDate).add(project.sprintDuration * 7 - 1, 'd'),
          'yyyy-MM-DD'
        );
      }

      return { startDate, endDate };
    },
    [project]
  );

  // Scroll to specific sprint row and focus field
  useEffect(() => {
    if (sprintListRef.current.scrollToIndex && sprints && !initiallyFocused) {
      // addNewSprint used for distingushing if sprint is being edited or creating a new sprint
      if (hasValidSprintParam && sprints?.length && addNewSprint === false) {
        const sprintField = document.getElementById(`sprint-name-${sprintId}`);

        if (sprintField) {
          setTimeout(() => {
            const sprintIndex = hasValidSprintParam
              ? sprints.findIndex((sprint) => sprint.id === sprintId)
              : null;

            setInitiallyFocused(true);

            scrollToIndex(sprintListRef, sprintIndex);

            sprintField.select();
            sprintField.focus();
          }, 0);
        }
      } else if (editorRef && addNewSprint) {
        const fillNewSprint = () => {
          const newSprintName = `Sprint ${(sprints?.length || 0) + 1}`;
          const index = sprints?.length;
          const { startDate, endDate } = calculateNewSprintsDates(sprints);

          setNewSprint({
            name: newSprintName,
            startDate,
            endDate,
            index,
            projectId: project.id
          });
        };

        fillNewSprint();

        setTimeout(() => {
          setInitiallyFocused(true);

          scrollToIndex(sprintListRef);

          editorRef.current.select();
          editorRef.current.focus();
        }, 0);
      }
    }
  }, [
    scrollToIndex,
    sprintId,
    sprints,
    hasValidSprintParam,
    initiallyFocused,
    addNewSprint,
    editorRef,
    calculateNewSprintsDates,
    project
  ]);

  function handleErrors(field, id, value) {
    const existingSprints = sprints?.filter(
      (sprint) => sprint[field].toLowerCase() === value.toLowerCase()
    );

    if (field === 'name') {
      let nameInputErrors = {};

      if (existingSprints?.length) {
        if (inputErrors?.name && Object.keys(inputErrors.name)?.length) {
          nameInputErrors = { ...inputErrors.name };
        }

        nameInputErrors[id] = value;

        existingSprints.map((sprint) => {
          nameInputErrors[sprint.id] = sprint.name;
          return null;
        });

        setInputErrors((prevState) => ({
          ...prevState,
          name: { ...nameInputErrors }
        }));
      } else if (inputErrors?.name?.[id]) {
        nameInputErrors = { ...inputErrors.name };

        if (Object.keys(nameInputErrors).length === 2) {
          Object.keys(inputErrors.name).map((sprintId) => {
            if (inputErrors.name[sprintId].toLowerCase() === inputErrors.name[id].toLowerCase()) {
              delete nameInputErrors[sprintId];
            }

            return null;
          });
        } else {
          delete nameInputErrors[id];
        }

        setInputErrors({ ...inputErrors, name: { ...nameInputErrors } });
      }

      updateSprintErrors(!!Object.keys(nameInputErrors).length);
    }
  }

  function updateSprints(sprintToSave, sprints) {
    const newSprints = [...sprints];

    if (sprintToSave.index > -1) {
      newSprints[sprintToSave.index] = sprintToSave;
    } else {
      newSprints.push(sprintToSave);
    }

    return newSprints;
  }

  function handleName(sprint, value) {
    if (sprint) {
      handleErrors('name', sprint.id, value);

      const newSprint = { ...sprint, name: value };

      setEditedSprint({ ...newSprint });

      if (newSprint?.projectId) {
        setNewSprint({});
      }
    }
  }

  function handleStartDate(sprint, value) {
    if (sprint && value) {
      // format value as date string, because API works only with date
      const newSprint = { ...sprint, startDate: formatDate(value, 'yyyy-MM-DD') };

      const newSprints = updateSprints(newSprint, sprints);

      if (newSprints?.length > 1 && newSprint.index > 0) {
        const prevSprint = { ...newSprints[newSprint.index - 1] };
        const newEndDate = formatDate(moment(newSprint.startDate).add(-1, 'd'), 'yyyy-MM-DD');

        if (new Date(prevSprint.startDate) < new Date(newEndDate)) {
          setNotification(t('sprintPivotItem.notification.datesUpdated'));
        }
      }

      setEditedSprint({ ...newSprint });

      if (newSprint?.projectId) {
        setNewSprint({});
      }
    }
  }

  function handleEndDate(sprint, value) {
    if (sprint && value) {
      // format value as date string, because API works only with date
      const newSprint = { ...sprint, endDate: formatDate(value, 'yyyy-MM-DD') };

      const newSprints = updateSprints(newSprint, sprints);

      if (newSprints?.length > 1) {
        let datesUpdated = false;

        sprints.map((existingSprint) => {
          if (newSprint.index < existingSprint.index) {
            datesUpdated = true;
          }

          return null;
        });

        if (datesUpdated) {
          setNotification(t('sprintPivotItem.notification.datesUpdated'));
        }
      }

      setEditedSprint({ ...newSprint });

      if (newSprint?.projectId) {
        setNewSprint({});
      }
    }
  }

  function handleNewSprint(name, value) {
    if (name) {
      const index = sprints?.length;
      let sprint = { ...newSprint, [name]: value, index, projectId: project.id };

      if (name === 'name') {
        handleErrors('name', 0, value);

        if (!(sprint?.startDate && sprint?.endDate)) {
          const { startDate, endDate } = calculateNewSprintsDates(sprints);

          sprint = { ...sprint, startDate, endDate };
        }
      }

      setNewSprint(sprint);

      if (editedSprint?.id) {
        setEditedSprint(null);
      }
    }
  }

  function cancelNewSprint() {
    handleErrors('name', 0, '');
    handleErrors('startDate', 0, '');
    handleErrors('endDate', 0, '');

    setNewSprint({});
  }

  function getErrorMessages() {
    if (inputErrors && Object.keys(inputErrors)?.length) {
      const messages = [];

      if (inputErrors.name && Object.keys(inputErrors.name)?.length) {
        messages.push(
          <ErrorMessage key="error-message-name">
            {t('sprintPivotItem.inputError.name')}
          </ErrorMessage>
        );
      }

      if (inputErrors.startDate && Object.keys(t('sprintPivotItem.inputError.startDate'))?.length) {
        messages.push(
          <ErrorMessage key="error-message-start-date">
            {inputErrors.startDate.message}
          </ErrorMessage>
        );
      }

      if (inputErrors.endDate && Object.keys(inputErrors.endDate)?.length) {
        messages.push(
          <ErrorMessage key="error-message-end-date" style={{ ...AnimationStyles.slideDownIn20 }}>
            {t('sprintPivotItem.inputError.endDate')}
          </ErrorMessage>
        );
      }

      if (messages?.length) {
        return messages;
      }
    }

    return null;
  }

  function getFieldError(id, field) {
    return inputErrors?.[field]?.[id];
  }

  // dateType can be 'startDate' or 'endDate'
  function checkIfSprintDateIsInFuture(sprint, dateType) {
    const today = new Date(formatDate(new Date(), 'yyyy-MM-DD'));
    const sprintDate = new Date(formatDate(sprint?.[dateType], 'yyyy-MM-DD')?.substring(0, 10));

    return !(sprintDate < today);
  }

  const rowTextFieldStyle = (errorInField, disabled) => {
    if (disabled) {
      return {
        backgroundColor: '#f1f1f1',
        borderRadius: 4,
        border: 'none'
      };
    }

    return {
      borderRadius: 0,
      borderBottom: errorInField ? '2px solid rgb(164, 38, 44)' : '1px solid transparent',
      selectors: {
        '&:hover': {
          borderRadius: 0,
          borderBottom: errorInField ? '2px solid rgb(164, 38, 44)' : '1px solid rgb(220, 220, 220)'
        },

        '&:hover:focus': {
          borderRadius: 0,
          borderBottom: errorInField
            ? '2px solid rgb(164, 38, 44)'
            : `2px solid ${getTheme().palette.themePrimary}`
        },
        ':focus': {
          borderRadius: 0,
          borderBottom: errorInField
            ? '2px solid rgb(164, 38, 44)'
            : `2px solid ${getTheme().palette.themePrimary}`
        }
      }
    };
  };

  function getMinimalSprintStartDate(sprint) {
    let sprintIndex = 0;

    if (sprint) {
      sprintIndex = sprint.index;
    } else if (sprints?.length > 1) {
      sprintIndex = sprints.length;
    }

    let minStartDate = moment(Date.now()).add(1, 'd').format('yyyy-MM-DD');

    if (sprintIndex > 0) {
      const newMinimalSprintStartDate = new Date(
        moment(sprints[sprintIndex - 1].startDate).add(2, 'd')
      );
      const today = new Date(formatDate(Date.now(), 'yyyy-MM-DD'));

      if (newMinimalSprintStartDate > today) {
        minStartDate = formatDate(
          moment(sprints[sprintIndex - 1].startDate).add(2, 'd'),
          'yyyy-MM-DD'
        );
      } else {
        minStartDate = formatDate(moment(today).add(1, 'd'), 'yyyy-MM-DD');
      }
    }

    return minStartDate;
  }

  // Column widths
  const nameColumnWidth = 230;
  const dateColumnWidth = 115;
  const actionsColumnWidth = 60;

  const columns = [
    {
      key: `name`,
      name: t('projectSprintsPivotItem.column.name'),
      fieldName: 'name',
      minWidth: nameColumnWidth,
      maxWidth: nameColumnWidth,
      isResizable: true,
      isMultiline: true,
      onRender: (sprint) => {
        const currentSprint =
          !!editedSprint && editedSprint.id === sprint?.id ? editedSprint : sprint;

        const errorInField = getFieldError(currentSprint?.id, 'name');

        return (
          <TextField
            autoFocus={hasValidSprintParam && sprintId === currentSprint?.id && !addNewSprint}
            key={`name-${currentSprint?.index}`}
            id={`sprint-name-${currentSprint?.id}`}
            onChange={(ev, value) => handleName(currentSprint, value)}
            value={currentSprint?.name}
            disabled={disabled}
            borderless
            styles={({ disabled }) => ({
              root: { width: '100%', marginTop: 10 },
              field: rowTextFieldStyle(errorInField, disabled)
            })}
          />
        );
      }
    },
    {
      key: 'startDate',
      name: t('projectSprintsPivotItem.column.startDate'),
      fieldName: 'startDate',
      minWidth: dateColumnWidth,
      maxWidth: dateColumnWidth,
      onRender: (sprint) => {
        const currentSprint =
          !!editedSprint && editedSprint.id === sprint?.id ? editedSprint : sprint;

        const errorInField = getFieldError(currentSprint?.id, 'startDate');
        const minStartDate = getMinimalSprintStartDate(currentSprint);

        return (
          <DateTime
            key={`startDate-${currentSprint?.index}`}
            onSelectDate={(value) => handleStartDate(currentSprint, value)}
            value={currentSprint?.startDate}
            minDate={minStartDate}
            maxDate={
              currentSprint?.endDate
                ? moment(currentSprint?.endDate).add(-1, 'd').format('yyyy-MM-DD')
                : null
            }
            borderless
            disabled={
              // eslint-disable-next-line react/prop-types
              disabled || !checkIfSprintDateIsInFuture(currentSprint, 'startDate')
            }
            textField={{
              styles: ({ disabled }) => ({
                root: { width: '100%' },
                field: rowTextFieldStyle(errorInField, disabled)
              })
            }}
          />
        );
      }
    },
    {
      key: 'endDate',
      name: t('projectSprintsPivotItem.column.endDate'),
      fieldName: 'endDate',
      minWidth: dateColumnWidth,
      maxWidth: dateColumnWidth,
      onRender: (sprint) => {
        const currentSprint =
          !!editedSprint && editedSprint.id === sprint?.id ? editedSprint : sprint;

        const errorInField = getFieldError(currentSprint?.id, 'endDate');
        const minStartDate = getMinimalSprintStartDate(currentSprint);

        return (
          <DateTime
            key={`endDate-${currentSprint?.index}`}
            onSelectDate={(value) => handleEndDate(currentSprint, value)}
            value={currentSprint?.endDate}
            minDate={
              currentSprint?.startDate
                ? moment(currentSprint.startDate).add(1, 'd').format('yyyy-MM-DD')
                : moment(minStartDate).add(1, 'd').format('yyyy-MM-DD')
            }
            borderless
            disabled={
              // eslint-disable-next-line react/prop-types
              disabled || !checkIfSprintDateIsInFuture(currentSprint, 'endDate')
            }
            textField={{
              styles: ({ disabled }) => ({
                root: { width: '100%' },
                field: rowTextFieldStyle(errorInField, disabled)
              })
            }}
          />
        );
      }
    },
    {
      key: 'actions',
      fieldName: 'action',
      isResizable: true,
      minWidth: actionsColumnWidth,
      maxWidth: actionsColumnWidth,
      onRender: (sprint) => {
        const errorInNameField = getFieldError(sprint?.id, 'name');
        const errorInStartDateField = getFieldError(sprint?.id, 'startDate');
        const errorInEndDateField = getFieldError(sprint?.id, 'endDate');

        return (
          // Option hidden for saved Sprints because there is not API for deleting sprints
          <div style={{ display: 'flex' }} className="centered">
            <TooltipHost
              content={t('projectSprintsPivotItem.column.action.saveSprintChanges')}
              calloutProps={{
                gapSpace: 0,
                target: `#btn-save-sprint-changes-${sprint.id}`
              }}
            >
              <IconButton
                id={`btn-save-sprint-changes-${sprint.id}`}
                className="save-button"
                iconProps={{ iconName: 'Accept' }}
                onClick={() => {
                  if (editedSprint?.id === sprint.id) {
                    saveSprint(editedSprint);

                    setEditedSprint(null);

                    if (notification) {
                      setNotification(null);
                    }

                    if (editorRef?.current) {
                      editorRef.current.focus();
                    }
                  }
                }}
                disabled={
                  disabled ||
                  !sprint?.name ||
                  !sprint.startDate ||
                  !sprint.endDate ||
                  !editedSprint ||
                  editedSprint?.id !== sprint.id ||
                  !editedSprint?.name ||
                  errorInNameField ||
                  errorInNameField ||
                  errorInStartDateField ||
                  errorInEndDateField
                }
                styles={{
                  root: {
                    marginRight: '2px',
                    width: '30px',
                    height: '30px',
                    display:
                      !editedSprint || editedSprint?.id !== sprint.id ? 'none' : 'inline-block'
                  }
                }}
              />
            </TooltipHost>
            <TooltipHost
              content={t('projectSprintsPivotItem.column.action.cancelSprintChanges')}
              calloutProps={{
                gapSpace: 0,
                target: `#btn-cancel-sprint-changes-${sprint.id}`
              }}
            >
              <IconButton
                id={`btn-cancel-sprint-changes-${sprint.id}`}
                className="cancel-button"
                iconProps={{ iconName: 'Cancel' }}
                onClick={() => {
                  if (editedSprint?.id === sprint.id) {
                    setEditedSprint(null);

                    if (notification) {
                      setNotification(null);
                    }

                    if (editorRef?.current) {
                      editorRef.current.focus();
                    }
                  }
                }}
                styles={{
                  root: {
                    width: '30px',
                    height: '30px',
                    display:
                      !editedSprint || editedSprint?.id !== sprint.id ? 'none' : 'inline-block'
                  }
                }}
              />
            </TooltipHost>
            <TooltipHost
              content={t('projectSprintsPivotItem.column.action.deleteSprint')}
              calloutProps={{
                gapSpace: 0,
                target: `#btn-delete-sprint-${sprint.id}`
              }}
            >
              <IconButton
                id={`btn-delete-sprint-${sprint.id}`}
                className="delete-button"
                iconProps={{ iconName: 'Delete' }}
                onClick={() => {
                  deleteSprint(sprint);

                  if (editorRef?.current) {
                    editorRef.current.focus();
                  }
                }}
                // eslint-disable-next-line react/prop-types
                disabled={disabled}
                styles={{
                  root: {
                    width: '30px',
                    height: '30px',
                    display:
                      // eslint-disable-next-line react/prop-types
                      sprint.index !== (sprints?.length || 0) - 1 || disabled
                        ? 'none'
                        : 'inline-block'
                  }
                }}
              />
            </TooltipHost>
          </div>
        );
      }
    }
  ];

  function getEditorRow() {
    if (disabled) return null;

    const sprint = newSprint;

    const editorTextFieldStyle = (errorInField) => {
      return {
        borderRadius: 0,
        borderBottom: errorInField ? '2px solid rgb(164, 38, 44)' : '1px solid rgb(96, 94, 92)',
        selectors: {
          '&:hover': {
            borderRadius: 0,
            borderBottom: errorInField ? '2px solid rgb(164, 38, 44)' : '1px solid rgb(96, 94, 92)',
            marginBottom: 0 // '2px'
          },
          '&:hover:focus': {
            borderRadius: 0,
            borderBottom: errorInField
              ? '2px solid rgb(164, 38, 44)'
              : `2px solid ${getTheme().palette.themePrimary}`,
            marginBottom: 0
          },
          ':focus': {
            borderRadius: 0,
            borderBottom: errorInField
              ? '2px solid rgb(164, 38, 44)'
              : `2px solid ${getTheme().palette.themePrimary}`
          }
        }
      };
    };

    const errorInNameField = getFieldError(0, 'name');
    const errorInStartDateField = getFieldError(0, 'startDate');
    const errorInEndDateField = getFieldError(0, 'endDate');

    const startDate = getMinimalSprintStartDate();

    const editorColumns = [
      {
        key: 'new-name',
        fieldName: 'name',
        minWidth: nameColumnWidth,
        maxWidth: nameColumnWidth,
        isResizable: true,
        isMultiline: true,
        onRender: (sprint) => {
          return (
            <TextField
              autoComplete="off"
              componentRef={editorRef}
              autoFocus
              id="editor-name"
              onChange={(ev, value) => handleNewSprint('name', value)}
              value={sprint?.name || ''}
              borderless
              styles={{
                root: { width: '100%', paddingTop: '10px' },
                field: editorTextFieldStyle(errorInNameField)
              }}
            />
          );
        }
      },
      {
        key: 'new-startDate',
        fieldName: 'startDate',
        minWidth: dateColumnWidth,
        maxWidth: dateColumnWidth,
        onRender: (sprint) => {
          return (
            <DateTime
              onSelectDate={(value) => handleNewSprint('startDate', value)}
              value={sprint?.startDate}
              minDate={startDate}
              maxDate={
                sprint?.endDate ? moment(sprint?.endDate).add(-1, 'd').format('yyyy-MM-DD') : null
              }
              borderless
              textField={{
                styles: {
                  root: { width: '100%' },
                  field: editorTextFieldStyle(errorInStartDateField)
                }
              }}
            />
          );
        }
      },
      {
        key: 'new-endDate',
        fieldName: 'endDate',
        minWidth: dateColumnWidth,
        maxWidth: dateColumnWidth,
        onRender: (sprint) => {
          return (
            <DateTime
              onSelectDate={(value) => handleNewSprint('endDate', value)}
              value={sprint?.endDate}
              minDate={
                sprint?.startDate
                  ? moment(sprint?.startDate).add(1, 'd').format('yyyy-MM-DD')
                  : moment(startDate).add(1, 'd').format('yyyy-MM-DD')
              }
              borderless
              textField={{
                styles: ({ disabled }) => ({
                  root: { width: '100%' },
                  field: rowTextFieldStyle(errorInEndDateField, disabled)
                })
              }}
            />
          );
        }
      },
      {
        key: 'new-actions',
        isResizable: true,
        minWidth: actionsColumnWidth,
        maxWidth: actionsColumnWidth,
        onRender: (sprint) => {
          return (
            <div style={{ display: 'flex' }} className="centered">
              <TooltipHost
                content={t('projectSprintsPivotItem.column.action.saveNewSprint')}
                calloutProps={{
                  gapSpace: 0,
                  target: '#btn-save-new-sprint'
                }}
              >
                <IconButton
                  id="btn-save-new-sprint"
                  className="save-button"
                  iconProps={{ iconName: 'Accept' }}
                  onClick={() => {
                    saveSprint(sprint);

                    const updatedSprints = updateSprints(sprint, sprints);

                    const newSprintName = `Sprint ${(updatedSprints?.length || 0) + 1}`;
                    const index = updatedSprints?.length;
                    const { startDate, endDate } = calculateNewSprintsDates(updatedSprints);

                    setNewSprint({
                      name: newSprintName,
                      startDate,
                      endDate,
                      index,
                      // eslint-disable-next-line react/prop-types
                      projectId: project.id
                    });

                    if (editorRef?.current) {
                      editorRef.current.select();
                      editorRef.current.focus();
                    }
                  }}
                  disabled={
                    disabled ||
                    !sprint?.name ||
                    !sprint.startDate ||
                    !sprint.endDate ||
                    errorInNameField ||
                    errorInStartDateField ||
                    errorInEndDateField
                  }
                  styles={{
                    root: {
                      marginRight: '2px',
                      width: '30px',
                      height: '30px',
                      display:
                        sprint.name && sprint.startDate && sprint.endDate ? 'inline-block' : 'none'
                    }
                  }}
                />
              </TooltipHost>
              <TooltipHost
                content={t('projectSprintsPivotItem.column.action.cancelNewSprint')}
                calloutProps={{
                  gapSpace: 0,
                  target: '#btn-cancel-new-sprint'
                }}
              >
                <IconButton
                  id="btn-cancel-new-sprint"
                  className="cancel-button"
                  iconProps={{ iconName: 'Cancel' }}
                  onClick={() => {
                    cancelNewSprint();

                    if (editorRef?.current) {
                      editorRef.current.focus();
                    }
                  }}
                  styles={{
                    root: {
                      width: '30px',
                      height: '30px',
                      display:
                        sprint?.name || sprint?.startDate || sprint?.endDate
                          ? 'inline-block'
                          : 'none'
                    }
                  }}
                />
              </TooltipHost>
            </div>
          );
        }
      }
    ];

    return (
      <DetailsList
        items={[sprint] || []}
        compact
        columns={editorColumns}
        selectionMode={SelectionMode.none}
        layoutMode={DetailsListLayoutMode.fixedColumns}
        isHeaderVisible={false}
        onRenderRow={(rowProps, defaultRender) => {
          return (
            <SprintRowStyled>
              {defaultRender({
                ...rowProps,
                styles: {
                  root: {
                    selectors: {
                      '&:hover': {
                        backgroundColor: 'transparent'
                      }
                    }
                  }
                }
              })}
            </SprintRowStyled>
          );
        }}
      />
    );
  }

  function getMessageBar() {
    // setTimeout(() => setNotification(null), 5000);

    return (
      <div style={{ ...AnimationStyles.slideDownIn20 }}>
        <MessageBar
          messageBarType={MessageBarType.warning}
          isMultiline
          onDismiss={() => setNotification(null)}
          dismissButtonAriaLabel="Close"
        >
          {notification}
        </MessageBar>
      </div>
    );
  }

  return (
    <div id="project-sprints" style={{ width: '100%' }}>
      <SprintsContainer>
        {notification ? getMessageBar() : null}
        {getErrorMessages()}
        <DetailsList
          componentRef={sprintListRef}
          items={sprints || []}
          compact
          columns={columns}
          selectionMode={SelectionMode.none}
          layoutMode={DetailsListLayoutMode.fixedColumns}
          onRenderRow={(rowProps, defaultRender) => {
            return (
              <SprintRowStyled>
                {defaultRender({
                  ...rowProps,
                  styles: { root: { selectors: { '&:hover': { backgroundColor: 'transparent' } } } }
                })}
              </SprintRowStyled>
            );
          }}
          onRenderDetailsHeader={(headerProps) => {
            return (
              <DetailsHeaderStyled
                {...headerProps}
                onRenderColumnHeaderTooltip={(tooltipHostProps) => (
                  <ColumnName>{tooltipHostProps.children}</ColumnName>
                )}
              />
            );
          }}
        />
        {getEditorRow()}
      </SprintsContainer>
    </div>
  );
}

ProjectSprintsPivotItem.propTypes = {
  project: projectPropType,
  sprints: PropTypes.arrayOf(PropTypes.object),
  saveSprint: PropTypes.func.isRequired,
  addNewSprint: PropTypes.bool,
  disabled: PropTypes.bool,
  updateSprintErrors: PropTypes.func.isRequired,
  deleteSprint: PropTypes.func.isRequired
};

ProjectSprintsPivotItem.defaultProps = {
  sprints: [],
  addNewSprint: null
};

export default ProjectSprintsPivotItem;
