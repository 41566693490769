import styled from 'styled-components';
import { adjustColor } from 'utils/helpers';
import { getTheme } from '@fluentui/react';
import { DefaultButton } from '@fluentui/react/lib/Button';

const ColorPaletteWrapper = styled.div`
  margin: 8px;

  .reset-button {
    margin-bottom: 5px;
    width: 100%;
    height: 24px;
    font-size: 12px;
    font-weight: 400;
    border-radius: 4px;
  }

  .color-palette {
    margin-top: 5px;
    gap: 8px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: auto;
    justify-items: center;
    align-items: center;

    .ms-Button {
      box-shadow: 0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108);
      max-width: 16px;
      min-width: 16px;
      width: 16px;
      height: 16px;
      padding: 0px;
      border-radius: 4px;
    }
  }
`;

interface IColor {
  color: string;
  name: string;
}

interface IColorPaletteProps {
  colors: IColor[];
  onChange: (color: string) => void;
  onReset: () => void;
  resetButtonLabel: string;
}

function ColorPalette({ colors, onChange, onReset, resetButtonLabel }: IColorPaletteProps) {
  const theme = getTheme();

  return (
    <ColorPaletteWrapper>
      <DefaultButton
        className="reset-button"
        text={resetButtonLabel}
        onClick={onReset}
        styles={{
          root: {
            borderColor: theme.palette.neutralTertiaryAlt,
            color: theme.palette.neutralSecondary
          }
        }}
      />
      <div className="color-palette">
        {colors.map(({ color, name }) => (
          <DefaultButton
            title={name}
            key={name}
            styles={{
              root: { backgroundColor: color, borderColor: adjustColor(color, -30) },
              rootHovered: { backgroundColor: color }
            }}
            onClick={() => onChange(color)}
          />
        ))}
      </div>
    </ColorPaletteWrapper>
  );
}

export default ColorPalette;
