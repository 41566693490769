import { useEffect, useState } from 'react';
import { IFieldLinkProps } from 'types';
import AggregationBlock from './AggregationBlock';
import DefaultResultContent from './DefaultResultContent';
import SurveyResultOverflowButton from './SurveyResultOverflowButton';

interface INumberResultContentProps {
  fieldLink: IFieldLinkProps;
}

function NumberResultContent({ fieldLink }: INumberResultContentProps): JSX.Element | null {
  const [showDetails, setShowDetails] = useState(false);
  const [showAggregation, setShowAggregation] = useState(false);
  const [displayOverflowButton, setDisplayOverflowButton] = useState(false);

  const { values } = fieldLink;

  useEffect(() => {
    if (values) {
      const shouldShowDetails = !values.find(
        (value) => value.aggregation === 4 || value.aggregation === 2
      );
      const shouldShowAggregation = !!values.find((value) => value.aggregation !== 1);
      const shouldDisplayOverflowButton = !!values.find((value) => value.aggregation === 1);

      setDisplayOverflowButton(shouldShowAggregation && shouldDisplayOverflowButton);
      setShowDetails(shouldShowDetails);
      setShowAggregation(shouldShowAggregation);
    }
  }, [values]);

  function renderAggregatedField() {
    return <AggregationBlock fieldLink={fieldLink} />;
  }

  function renderDetails() {
    return (
      <div style={{ marginTop: showAggregation ? 10 : 0 }}>
        <DefaultResultContent fieldLink={fieldLink} />
      </div>
    );
  }

  return (
    <div>
      {displayOverflowButton && (
        <SurveyResultOverflowButton showDetails={showDetails} setShowDetails={setShowDetails} />
      )}
      {showAggregation && renderAggregatedField()}
      {showDetails && renderDetails()}
    </div>
  );
}

export default NumberResultContent;
