import styled from 'styled-components';
import { Modal } from '@fluentui/react';

const ModalStyled = styled(Modal)`
  .drawingBuffer {
    display: none;
  }
`;

export default ModalStyled;
