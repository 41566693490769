import { Panel } from 'components';
import { DialogCustom } from 'components/surfaces/Dialog';
import { AppContext } from 'features/App';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import fetchRequest, { apiErrorHandler } from 'services/api';
import { TeamType, teamPropType, userPropType } from 'types';
import { PanelType } from '@fluentui/react';
import TeamActivities from './components/TeamActivities';
import Body from './components/TeamPanelNewEdit';
import Header from './components/TeamPanelNewEditHeader';

export default function TeamPanel({
  currentUser: propCurrentUser,
  onUpdateTeams,
  onClosePanel,
  team,
  hidden,
  administration,
  allowedTeamTypes
}) {
  // This is necessary to ensure the customCode functions correctly
  // It can probably be removed in the future once the customer no longer has any ongoing legacy processes
  const {
    globalAppState: { currentUser: contextCurrentUser }
  } = useContext(AppContext);
  const currentUser = propCurrentUser || contextCurrentUser;

  const [tasksLength, setTasksLength] = useState(null);
  const [isDialogHidden, setIsDialogHidden] = useState(true);
  const [tab, setTab] = useState('detail');

  const [teamToDelete, setTeamToDelete] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (!hidden && team?.id && !administration) {
      const url = `Tasks/Small?pageIndex=0&itemsPerPage=1&assignedToTeamIds=${team?.id}`;
      fetchRequest({ url }).then((response) => setTasksLength(response.searchProps.totalItems));
    }
  }, [team, hidden, administration]);

  const onSaveTeam = (newTeam) => {
    const isEdit =
      (team?.id && team.id !== '00000000-0000-0000-0000-000000000000') ||
      (newTeam?.id && newTeam.id !== '00000000-0000-0000-0000-000000000000');
    const method = isEdit ? 'PUT' : 'POST';

    const newTeamFromPicker = newTeam?.id === '00000000-0000-0000-0000-000000000000';

    return fetchRequest({ url: 'Team', method, body: JSON.stringify(newTeam) })
      .then((team) => {
        if (!isEdit && team?.id) {
          onUpdateTeams(team, 'POST');
        } else if (team?.id) {
          onUpdateTeams(team, 'PUT');
        }

        if (newTeamFromPicker) {
          onClosePanel(team);
        }
      })
      .catch(apiErrorHandler);
  };

  function onDeleteTeam() {
    fetchRequest({
      url: `Team/${teamToDelete.id}`,
      method: 'DELETE'
    }).catch(apiErrorHandler);

    setIsDialogHidden(true);
    onUpdateTeams(teamToDelete, false);
    onClosePanel();
  }

  function onDismiss(event) {
    if (event && event.type !== 'mousedown') {
      if (onClosePanel) {
        onClosePanel();
      }
    }
  }

  function renderDetailBody() {
    return (
      <Body
        currentUser={currentUser}
        onSave={onSaveTeam}
        onDelete={() => {
          setTeamToDelete(team);
          setIsDialogHidden(false);
        }}
        tasksLength={tasksLength}
        teamToEdit={team}
        administration={administration}
        allowedTeamTypes={allowedTeamTypes}
      />
    );
  }

  function renderActivityBody() {
    return <TeamActivities teamId={team?.id} />;
  }

  let renderBody = renderDetailBody;
  if (tab === 'activity') {
    renderBody = renderActivityBody;
  }

  return (
    <>
      {!isDialogHidden && (
        <DialogCustom
          title={t('dialog.teams.deleteTeam.confirmation.title')}
          subText={t('dialog.teams.deleteTeam.confirmation.subText', { item: team?.title })}
          defaultButtonLabel={t('dialog.tasks.deleteTask.confirmation.button.default')}
          primaryButtonLabel={t('dialog.tasks.deleteTask.confirmation.button.primary')}
          primaryButtonFunc={() => onDeleteTeam()}
          defaultButtonFunc={() => setIsDialogHidden(true)}
          hidden={false}
          onDismiss={() => setIsDialogHidden(true)}
        />
      )}
      <Panel
        isLightDismiss={false}
        isOpen={!hidden}
        onDismiss={onDismiss}
        onRenderBody={renderBody}
        onRenderHeader={() => (
          <div>
            <Header team={team} tab={tab} onTabChange={setTab} />
          </div>
        )}
        type={PanelType.medium}
      />
    </>
  );
}

TeamPanel.propTypes = {
  currentUser: userPropType,
  onUpdateTeams: PropTypes.func.isRequired,
  onClosePanel: PropTypes.func.isRequired,
  team: teamPropType,
  allowedTeamTypes: PropTypes.arrayOf(TeamType),
  hidden: PropTypes.bool.isRequired,
  administration: PropTypes.bool
};

TeamPanel.defaultProps = {
  administration: false,
  allowedTeamTypes: []
};
