import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SearchBox } from '@fluentui/react';

function ContextualFilterMenuSearchBox({ onFilterOptionsSearchChange }) {
  const { t } = useTranslation();

  return (
    <div style={{ borderBottom: '1px solid #eaeaea' }}>
      <SearchBox
        onChange={onFilterOptionsSearchChange}
        placeholder={t('contextualMenu.searchBox.placeholder')}
        styles={{ root: [{ margin: '3px', marginLeft: '20px', border: 'none' }] }}
      />
    </div>
  );
}

ContextualFilterMenuSearchBox.propTypes = {
  onFilterOptionsSearchChange: PropTypes.func.isRequired
};

export default ContextualFilterMenuSearchBox;
