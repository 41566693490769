import {
  AnyApiObjectType,
  AnyObjFieldPath,
  ApiObjectVariant,
  createApiObjectDataFromPlainObject,
  createApiObjectField,
  decodeObjFieldPath,
  getFieldPathParent,
  IApiObjectCache,
  IApiObjectData,
  IApiObjectDataBase,
  IApiObjectType,
  InferApiObjectTypeParams,
  makeApiObjectVariantKey
} from '../object';
import { fetchAIJson, fetchJson } from '../../../services/api2';

/**
 * Describes where and how to create a field reference.
 *
 * For example, for the following object:
 * ```
 * {
 *   field1: true,
 *   field2: {
 *     name: 'hello',
 *     creator: IUser {
 *       userId: '12345678-9999-ef01-2345-ab9999999999'
 *     }
 *   }
 * }
 * ```
 *
 * The `creator` field should be a field reference to a USER object.
 * The field ref spec would then be:
 *
 * ```
 * {
 *   // path to the creator object
 *   path: 'field2/creator', // or `['field2', 'creator']` if you prefer
 *   // an IApiObjectType
 *   type: USER,
 *   params: data => data.field2.creator?.userId,
 *   variant: ApiObjectVariant.Small,
 * }
 * ```
 */
export type FieldRefSpec<T> = {
  /** Path to the field. */
  path: AnyObjFieldPath;
  /** The API object type at this path. */
  type: AnyApiObjectType;
  /**
   * Parameters for the object at this path, given to the `type`.
   *
   * TypeScript does not represent this well, but the return type here would technically be something like
   * `InferApiObjectTypeParams<typeof this.type>`.
   *
   * Can also return nullish value to indicate that there's no object here.
   */
  params: (data: T) => unknown | undefined;
  /**
   * Optional, defaults to Full: specify the variant loaded here. Must be correct, or may cause a crash!
   *
   * Some endpoints only deliver smaller variants of items, such as Tasks/Small, which delivers a SmallTaskItem.
   */
  variant?: ApiObjectVariant;

  /**
   * Optional, defaults to null: specify the includes loaded here. Must be correct, or may cause a crash!
   *
   * This will imply Variant.Small and must not be used with Full.
   */
  includes?: string[];

  /**
   * If set, we're creating a sparse ref.
   * This means that we'll cache the data we found at this path, but the resulting object will *not* have a field ref.
   * Instead, it'll be replaced by a sparse ID object, i.e. the minimal amount of data that is required to identify it.
   */
  sparseRef?: (data: T) => Partial<T>;
};

/**
 * Creates field references in an object using a set of FieldRefSpec objects.
 *
 * @param cache cache where we should insert the original object data
 * @param data the object
 * @param fields field refs to create
 * @param insertOnlyIfNeeded if true, values from field refs will only be inserted into the cache if they don't
 *   already exist
 */
export function createFieldRefs<T extends IApiObjectDataBase>(
  cache: IApiObjectCache,
  data: T,
  fields: FieldRefSpec<T>[],
  // due to several endpoints delivering incorrect data for embedded objects (e.g. teams will have no members),
  // we will be defaulting this to true now...
  insertOnlyIfNeeded = true
) {
  for (const { path, type, params, variant, includes, sparseRef } of fields) {
    const resolvedParams = params(data);

    if (resolvedParams) {
      const variant2 = includes ? ApiObjectVariant.Small : variant;
      const objId = makeApiObjectVariantKey(type, resolvedParams, variant2, includes);

      let obj: IApiObjectDataBase;
      if (sparseRef) {
        const fieldPath = decodeObjFieldPath(path);
        const lastPart = fieldPath[fieldPath.length - 1];
        const parent = getFieldPathParent(data, fieldPath);

        const value = parent[lastPart];
        obj = createApiObjectDataFromPlainObject(cache, objId, value);

        getFieldPathParent(data, fieldPath)[lastPart] = sparseRef(value);
      } else {
        obj = createApiObjectField(data, path, objId);
      }

      let shouldInsert = true;
      if (insertOnlyIfNeeded) {
        shouldInsert = !cache.getObject(objId).get(objId.variant, objId.includes);
      }

      if (shouldInsert) cache.insertObjectData(obj);
    }
  }
}

/**
 * Creates an IApiObjectType for simple objects that are uniquely identified by just a string ID.
 */
export function simpleIdObject<T extends IApiObjectData>({
  id,
  useTenant,
  useAITenant,
  useAIMember,
  url,
  fields,
  dynFields,
  loadDedupIntervalSecs,
  wrapInArray,
  insertFieldsOnlyIfNeeded
}: {
  /** Object type ID. Must be unique across all object types */
  id: string;
  /** If true, will use new API URLs (tenant/{tenantId}/...) */
  useTenant?: boolean;
  /** If true, will use new evocom.ai tenant URLs (tenant/{tenantId}/...) */
  useAITenant?: boolean;
  /** If true, will use new evocom.ai member URLs (tenant/{tenantId}/members/{memberId}/...) */
  useAIMember?: boolean;
  url: (id: string) => string;
  /** Static field refs */
  fields?: FieldRefSpec<T>[];
  /** Dynamically determined field refs (e.g. in arrays) */
  dynFields?: (data: T) => FieldRefSpec<T>[];

  /** Certain types of data will change very rarely, meaning we can deduplicate it for far longer */
  loadDedupIntervalSecs?: number;

  /** If true, resulting data is an array and needs to be in an IApiObjectArray wrapper */
  wrapInArray?: boolean;

  /** Inserts data from a field only if we don't already have the data. Exists to avoid infinite recursion from a ref-cycle. */
  insertFieldsOnlyIfNeeded?: boolean;
}): IApiObjectType<string, T> {
  const thisType: IApiObjectType<string, T> = {
    id,
    loadDedupIntervalSecs,
    createRefs(cache: IApiObjectCache, data: T) {
      if (fields || dynFields) {
        const resolved = (fields || []).concat(dynFields?.(data) || []);
        createFieldRefs(cache, data, resolved, insertFieldsOnlyIfNeeded);
      }
    },
    async load(cache: IApiObjectCache, id: string, abort: AbortSignal): Promise<T> {
      let fullUrl = url(id);

      if (useAIMember) {
        fullUrl = `members/${cache.memberId}/${fullUrl}`;
      }
      if (useTenant) {
        fullUrl = `tenants/${cache.tenantId}/${fullUrl}`;
      }

      const func = useAIMember || useAITenant ? fetchAIJson : fetchJson;

      const result = await func({ url: fullUrl, abort });

      return createApiObjectDataFromPlainObject(
        cache,
        makeApiObjectVariantKey(thisType, id as InferApiObjectTypeParams<typeof thisType>),
        wrapInArray ? { items: result } : result
      ) as T;
    }
  };
  return thisType;
}
