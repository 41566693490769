import ChoiceGroupField, { IOption } from 'components/inputs/ChoiceGroupField';
import { useTranslation } from 'react-i18next';
import { ChoiceFieldFormat, IFieldChoiceProps, IFieldLinkProps, RouteFieldVisibility } from 'types';
import { getFluentIconNameByFieldType } from 'utils/helpers';

export interface IDynamicChoiceGroupFieldProps {
  disabled?: boolean;
  fieldLink: IFieldLinkProps;
  onChange: (
    selectedOptions:
      | {
          id: string;
          value: string;
        }[]
      | null
  ) => void;
}

function DynamicChoiceGroupField({ fieldLink, disabled, onChange }: IDynamicChoiceGroupFieldProps) {
  const { t } = useTranslation();

  function convertChoiceIntoOption(choice: IFieldChoiceProps): { key: string; text: string } {
    return { key: choice.id, text: choice.value };
  }

  function onChoiceGroupChange(selectedOptions: IOption[] | []): void {
    if (Array.isArray(selectedOptions) && selectedOptions.length) {
      // convert options to choices
      const selectedChoices = selectedOptions.map((choice: IOption) => ({
        id: choice.key,
        value: choice.text
      }));

      onChange(selectedChoices);
    } else {
      onChange(null);
    }
  }

  if (!fieldLink.field) return null;

  const { field, visibility } = fieldLink;
  const { name, description, descriptionPlacement, id, allowFillIn, choiceFormat, choices } = field;
  const defaultValue = fieldLink.value as IFieldChoiceProps[];

  const displayDescriptionTooltip = descriptionPlacement === 1;

  const readOnly = visibility === RouteFieldVisibility.ReadOnly;
  const required = visibility === RouteFieldVisibility.Required;

  const context = disabled ? 'disabled' : undefined;

  const options = choices?.map(convertChoiceIntoOption);

  return (
    <ChoiceGroupField
      allowFillIn={allowFillIn}
      allowMultipleSelections={choiceFormat === ChoiceFieldFormat.MultiSelect}
      defaultValue={defaultValue?.map(convertChoiceIntoOption)}
      description={displayDescriptionTooltip ? description : undefined}
      disabled={disabled || readOnly}
      id={id}
      label={name}
      labelIconName={getFluentIconNameByFieldType(fieldLink.field)}
      onChange={onChoiceGroupChange}
      options={options || []}
      placeholder={t('dynamicField.choiceGroup.placeholder', { context })}
      required={required}
    />
  );
}

export default DynamicChoiceGroupField;
