import './i18n';

import { AuthenticationContainer, MsalProvider, msalInstance } from 'features/Authentication';
import { AppInsightsContextProvider, ErrorBoundary } from 'features/ErrorHandling';
import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { ThemeProvider } from '@fluentui/react';
import { FluentProvider, teamsLightTheme } from '@fluentui/react-components';

// initialize fabric icons
initializeIcons();

const container = document.querySelector('#evocom-tasks');

const root = createRoot(container);

root.render(
  <ErrorBoundary>
    <AppInsightsContextProvider>
      <FluentProvider style={{ height: '100%', background: 'transparent' }} theme={teamsLightTheme}>
        <ThemeProvider style={{ height: '100%', background: 'transparent' }}>
          <Suspense fallback={<div />}>
            <MsalProvider instance={msalInstance}>
              <AuthenticationContainer />
            </MsalProvider>
          </Suspense>
        </ThemeProvider>
      </FluentProvider>
    </AppInsightsContextProvider>
  </ErrorBoundary>
);
