import { t } from 'i18next';
import { Slider, Toggle } from '@fluentui/react';
import { IFormProps } from './FieldForm';

function NumberSettings({ field, setField, disabled }: IFormProps) {
  function updateNumber(value: number) {
    setField((prevState) => ({ ...prevState, numDecimals: value }));
  }

  return (
    <div>
      <Slider
        key={`numDecimals-${field?.fieldType}-${field?.numDecimals}`}
        label={t('createField.dialog.numbersfield.label.decimals')}
        min={0}
        max={10}
        step={1}
        defaultValue={field.numDecimals || 0}
        showValue
        snapToStep
        onChanged={(_, value) => updateNumber(value)}
        disabled={disabled}
        styles={{ root: { marginTop: '7px' }, slideBox: { paddingRight: 8, paddingLeft: 0 } }}
      />
      <Toggle
        checked={field.useThousandsSeparator}
        label={t('createField.dialog.numbersfield.label.thousandsSeparator')}
        onChange={(_, checked) =>
          setField((prevState) => ({ ...prevState, useThousandsSeparator: checked }))
        }
      />
    </div>
  );
}

export default NumberSettings;
