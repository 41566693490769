import styled, { DefaultTheme } from 'styled-components';
import { DeepPartial } from '@fluentui/merge-styles';
import { ITextFieldStyleProps, ITextFieldStyles } from '@fluentui/react';

export function getTextFieldStyles(
  theme: DefaultTheme
): (props: ITextFieldStyleProps) => DeepPartial<ITextFieldStyles> {
  return function map({ disabled }: ITextFieldStyleProps): DeepPartial<ITextFieldStyles> {
    return {
      fieldGroup: [
        {
          border: `1px solid rgb(${theme.dynamicField.outline})`,
          borderRadius: theme.dynamicField.cornerRadius,
          ':after': { borderRadius: theme.dynamicField.cornerRadius }
        }
      ],
      wrapper: disabled
        ? { border: '1px solid #a19f9d', borderRadius: theme.dynamicField.cornerRadius }
        : undefined,
      field: { color: `rgb(${theme.dynamicField.fieldForeground})` }
    };
  };
}

export const SectionStyled = styled.div`
  margin-top: 12px;

  > .c-field-edit-container {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 0.5em;

    > .c-field-edit-button-container {
      place-self: start center;
      margin-top: 2em;

      > .c-field-edit-button.is-loading {
        animation: field-edit-button-loading 1s infinite;
      }
    }
  }

  @keyframes field-edit-button-loading {
    50% {
      opacity: 0.5;
    }
  }

  > .c-field-changes-banner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: end;
    gap: 0.5em;
    margin-top: 0.5em;
    border-radius: ${({ theme }) => theme.dynamicField.cornerRadius};
    font-size: smaller;

    &.has-later-changes {
      padding: 0.2em 0 0.2em 0.4em;
      background: rgb(${({ theme }) => theme.dynamicField.globalFieldChangeBannerBackground});
    }

    > * {
      flex-shrink: 0;
    }
    > .c-label {
      flex: 1;
    }

    > .c-view-history {
      height: auto;
      font-size: inherit;

      .c-view-history-flex {
        display: flex;
        align-items: center;
        gap: 0.2em;

        .c-view-history-icon {
          font-size: 0.86em;
        }
      }
    }

    &:not(.has-later-changes) > .c-view-history {
      opacity: 0.7;

      &:hover {
        opacity: 1;
      }
    }
  }

  > .c-field-edit-container + .c-field-changes-banner {
    margin-right: calc(0.5em + 32px);
  }

  .c-field-required-error {
    .ms-TextField-fieldGroup,
    .rte-base,
    .c-boolean-field-checkbox-wrapper,
    .c-boolean-field-buttons-wrapper,
    .c-choicegroup__wrapper,
    .ms-BasePicker-text,
    .ms-Rating-star,
    .c-document-field-button,
    .c-signature-pad,
    .c-add-media-button {
      border: 1px solid #a80000 !important;
      background-color: #a8000029 !important;
    }

    .c-boolean-field-buttons-wrapper {
      padding: 3px;
      border-radius: 4px;
    }

    .ms-ComboBox {
      background-color: #a8000029;
    }

    .ms-ComboBox::after {
      border: 1px solid #a80000;
    }

    .ms-ChoiceField-field::before,
    .ms-ComboBox input {
      background-color: transparent;
    }
  }
`;

export const DynamicFieldSectionStyled = styled.div<{ $hasLabel: boolean }>`
  width: 100%;

  .c-dynamic-form-fields-container {
    margin-left: ${(props) => (props.$hasLabel ? '27px' : '0px')};
    margin-bottom: 18px;
  }
`;

export const FieldHistoryStyled = styled.div`
  width: calc(100vw - 1em);
  max-width: 600px;
  container: field-history / inline-size;

  > .c-loading {
    padding: 0.5em;
  }

  > .c-title {
    font-weight: bold;
    display: flex;
    font-size: 1.2em;
    justify-content: space-between;
    padding: 1em 1em 0;
    gap: 0.5em;
  }

  > .c-entries {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .c-field-history-entry {
    padding: 1em;

    + .c-field-history-entry {
      border-top: 1px solid rgb(${({ theme }) => theme.dynamicField.fieldHistoryItemDivider});
    }

    > .c-current-value {
      display: flex;
      gap: 0.5em;
      align-items: center;
      margin-bottom: 0.5em;
      font-weight: 600;
      font-size: 0.9em;
      text-transform: uppercase;
      opacity: 0.7;

      &::before,
      &::after {
        content: '';
        display: block;
        height: 1px;
        flex: 1;
        background: currentColor;
        opacity: 0.7;
      }
    }

    > .c-details {
      display: grid;
      gap: 0.5em;
      grid-template-columns: auto auto;
      grid-template-rows: auto auto;

      > .c-editor-persona {
        grid-column: 1 / 3;
      }

      > .c-edit-type {
        font-weight: 500;
        place-self: center start;

        &.is-global-edit {
          display: inline-flex;
          align-items: center;
          gap: 0.2em;
          font-weight: bold;
          padding: 0.2em;
          background: rgb(${({ theme }) => theme.dynamicField.globalFieldChangeBannerBackground});
          border-radius: ${({ theme }) => theme.dynamicField.cornerRadius};
        }
      }

      > .c-edit-date {
        place-self: center end;
      }
    }

    > .c-field-container {
      > * {
        margin: 0;
      }
    }
  }

  @container field-history (min-width: 500px) {
    .c-field-history-entry {
      .c-details {
        grid-template-columns: auto 1fr auto;
        grid-template-rows: 1fr;

        > .c-editor-persona {
          grid-column: 1 / 2;
        }

        > .c-edit-type {
          justify-self: end;
        }
      }
    }
  }
`;
