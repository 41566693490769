import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { DefaultButton, Dropdown, Icon, Label, TooltipHost } from '@fluentui/react';
import RuleCondition from './RuleCondition';

const RuleStyled = styled.div`
  padding: 10px;
  background-color: #f4f9fd;
  border: 1px solid #e5e5e5;
  margin-bottom: 10px;

  .field-setting {
    min-width: 200px;
    max-width: 200px;
    margin: 0 5px 5px 5px;
  }

  .delete-rule-icon {
    color: red;
    font-size: 16px;
  }
`;

const InfoIcon = styled(Icon)`
  margin-left: 15px;

  &:hover {
    cursor: help;
  }
`;

function Rule({ ruleGroup, groupFields, updateRuleGroup, deleteRuleGroup }) {
  const { t } = useTranslation();

  const fieldSettingOptions = [
    {
      key: false,
      text: t('formbuilder.field.setting.optional')
    },
    {
      key: true,
      text: t('formbuilder.field.setting.required')
    }
  ];

  function handleFieldSettingsChange(value) {
    const updatedRuleGroup = { ...ruleGroup, makeRequired: value };

    updateRuleGroup(updatedRuleGroup);
  }

  function updateRules(updatedConditions) {
    const updatedRuleGroup = { ...ruleGroup, rules: updatedConditions };

    updateRuleGroup(updatedRuleGroup);
  }

  function onUpdateCondition(condition, conditionIndex) {
    const updatedConditions = [...ruleGroup.rules];
    updatedConditions[conditionIndex] = condition;

    updateRules(updatedConditions);
  }

  function onUpdateLogicOperator(type) {
    const updatedConditions = ruleGroup.rules.map((ruleCondition, index) => ({
      ...ruleCondition,
      linkType: index > 0 ? type : 0
    }));

    updateRules(updatedConditions);
  }

  function onAddNewCondition(prevConditionIndex) {
    const newCondition = {
      isFocused: true,
      key: uuidv4(),
      linkType: ruleGroup?.rules?.[1]?.linkType || 1
    };

    const updatedConditions = [...ruleGroup.rules];
    updatedConditions.splice(prevConditionIndex + 1, 0, newCondition);

    updateRules(updatedConditions);
  }

  function onDeleteCondition(conditionIndex) {
    const updatedConditions = [...ruleGroup.rules];
    updatedConditions.splice(conditionIndex, 1);

    updateRules(updatedConditions);
  }

  function getConditions() {
    if (ruleGroup?.rules?.length) {
      let counter = 0;

      const conditions = ruleGroup.rules.map((condition, index) => {
        const ruleCondition = (
          <RuleCondition
            key={`condition-${counter}-${condition?.key}`}
            condition={condition}
            conditionIndex={index}
            groupFields={groupFields}
            onUpdateCondition={onUpdateCondition}
            onUpdateLogicOperator={onUpdateLogicOperator}
            onAddNewCondition={onAddNewCondition}
            onDeleteCondition={onDeleteCondition}
            disableDelete={ruleGroup.rules.length < 2}
          />
        );

        counter += 1;

        return ruleCondition;
      });

      return <div key={`conditions-${ruleGroup?.rules?.length || 0}`}>{conditions}</div>;
    }

    return null;
  }

  return (
    <RuleStyled>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          <Label>{t('formbuilder.header.list.fieldSetting')}</Label>
          <Dropdown
            className="field-setting"
            options={fieldSettingOptions}
            disabled={false}
            defaultSelectedKey={ruleGroup?.makeRequired}
            onChange={(_, value) => {
              handleFieldSettingsChange(value.key);
            }}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <DefaultButton
            text={t('rule.button.delete')}
            className="delete-rule-icon-button"
            iconProps={{ iconName: 'Delete', className: 'delete-rule-icon' }}
            onClick={deleteRuleGroup}
          />
          <TooltipHost
            tooltipProps={{
              onRenderContent: () => (
                // eslint-disable-next-line react/no-danger
                <div dangerouslySetInnerHTML={{ __html: t('rule.info.general') }} />
              )
            }}
            calloutProps={{ gapSpace: 5, target: '#rule-info-icon' }}
          >
            <InfoIcon iconName="Info" id="rule-info-icon" />
          </TooltipHost>
        </div>
      </div>
      {getConditions()}
    </RuleStyled>
  );
}

Rule.propTypes = {
  ruleGroup: PropTypes.object,
  groupFields: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateRuleGroup: PropTypes.func.isRequired,
  deleteRuleGroup: PropTypes.func.isRequired
};

Rule.defaultProps = {
  ruleGroup: null
};

export default Rule;
