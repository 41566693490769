import { DateTime, Description, NumbersField, TagPicker } from 'components';
import CombinedPicker from 'components/inputs/CombinedPickerJS/CombinedPickerJS';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { tagsSearch } from 'services/fetchRequests';
import styled from 'styled-components';
import { projectPropType, teamPropType } from 'types';
import breakpoints from 'utils/breakpoints';
import { Dropdown, Label, TextField } from '@fluentui/react';
import { getProjectTypeOptions } from '../ProjectConstants';

const ProjectTabStyled = styled.div`
  margin: 1rem;
  margin-bottom: 3rem;

  .form-fields-width {
    width: 100%;
  }

  .team-picker-error {
    .ms-BasePicker-text {
      border: 1px solid rgb(164, 38, 44);
    }
  }

  .date-error {
    .ms-TextField-fieldGroup {
      border: 1px solid rgb(164, 38, 44);
    }
  }
`;

const DevidedRow = styled.div`
  @media (min-width: ${breakpoints.smallMin}px) {
    display: flex;
    justify-content: space-between;

    .left-half {
      max-width: 50%;
      padding-right: 0.25rem;
    }

    .right-half {
      padding-left: 0.25rem;
      max-width: 50%;
    }
  }
`;

function ProjectPivotItem({
  project,
  isTemplate,
  team,
  handleProjectState,
  handleTeamState,
  errorInField,
  disabled
}) {
  const { t } = useTranslation();

  const projectTypes = getProjectTypeOptions(t);

  function handleCurrentInput(name, value) {
    const newCurrentProject = { ...project, [name]: value };
    handleProjectState(newCurrentProject);
  }

  function handleName(value) {
    handleCurrentInput('name', value);
  }

  function handleDescription(value) {
    handleCurrentInput('description', value);
  }

  function handleType(key) {
    handleCurrentInput('type', key);
  }

  function handleStartDate(value) {
    handleCurrentInput('startDate', value);
  }

  function handlePlannedEndDate(value) {
    handleCurrentInput('plannedEndDate', value);
  }

  function handleSprintDuration(value) {
    handleCurrentInput('sprintDuration', value);
  }

  function handleNumberOfSprints(value) {
    handleCurrentInput('numberOfSprints', value);
  }

  function handleTags(value) {
    handleCurrentInput('tags', value);
  }

  function getEndDateOrDurationField() {
    if (isTemplate) return null;

    if (project?.type === 1 || !!project?.id) {
      return (
        <div id="project-panel-endDate-field" className="right-half half-width">
          <DateTime
            label={t('projectPivotItem.label.plannedEndDate')}
            onSelectDate={handlePlannedEndDate}
            value={project.plannedEndDate}
            minDate={project.startDate}
            disabled={(!!project?.id && project?.type === 2) || disabled}
            className={errorInField?.fieldName === 'plannedEndDate' ? 'date-error' : null}
            required
          />
          {errorInField?.fieldName === 'plannedEndDate' && getErrorMessage(errorInField?.message)}
        </div>
      );
    }

    if (project?.type === 2) {
      return (
        <div id="project-panel-sprintDuration-field" className="right-half half-width">
          <NumbersField
            label={t('projectPivotItem.label.sprintDuration')}
            maxDecimals={0}
            required
            defaultValue={project?.sprintDuration}
            onChange={handleSprintDuration}
            disabled={disabled}
          />
          {errorInField?.fieldName === 'sprintDuration' && getErrorMessage(errorInField?.message)}
        </div>
      );
    }

    return null;
  }

  function getErrorMessage(message) {
    return (
      <p className="ms-TextField-errorMessage">
        <span style={{ color: 'rgb(164, 38, 44)', fontSize: '12px', paddingTop: '5px' }}>
          {message}
        </span>
      </p>
    );
  }

  function getStartDateDisabledState() {
    if (disabled) return disabled;

    if (project?.id && project.type === 1) {
      return project.status === 2 || project.status === 10;
    }

    return !!project?.id;
  }

  return (
    <ProjectTabStyled>
      <div style={{ position: 'relative' }}>
        <div
          style={{ position: 'absolute', top: 70, width: '100%', right: 10 }}
          id="project-panel-name-description-fields"
        />
      </div>
      <TextField
        autoFocus={!errorInField || errorInField?.fieldName === 'name'}
        disabled={disabled}
        label={t('projectPivotItem.label.name')}
        onChange={(ev, value) => handleName(value)}
        required
        defaultValue={project?.name || ''}
        errorMessage={errorInField?.fieldName === 'name' ? errorInField?.message : null}
      />
      {disabled ? (
        <Label disabled style={{ marginTop: '5px' }}>
          {t('projectPivotItem.label.description')}
        </Label>
      ) : null}
      <Description
        defaultValue={project?.description}
        disabled={disabled}
        label={!disabled ? t('projectPivotItem.label.description') : null}
        displayCmdBar={!disabled}
        styles={{
          editorWrapper: {
            minHeight: disabled ? '36px' : '200px',
            backgroundColor: disabled ? 'rgb(243, 242, 241)' : null,
            color: disabled ? 'rgb(161, 159, 157)' : null
          }
        }}
        onChange={handleDescription}
      />

      <DevidedRow className="mt-1">
        <div className="left-half half-width">
          <Label required disabled={disabled}>
            {t('projectPivotItem.label.team')}
          </Label>
          <div id="project-panel-team-field" key={`team-${team?.id}`}>
            <CombinedPicker
              disabled={disabled}
              className={errorInField?.fieldName === 'team' ? 'team-picker-error' : null}
              placeholder={t('projectPivotItem.placeholder.team')}
              searchUser={false}
              searchTeams
              selectedItems={team}
              onChange={handleTeamState}
              createNewTeamAllowed
            />
          </div>
          {errorInField?.fieldName === 'team' && getErrorMessage(errorInField?.message)}
        </div>
        <div id="project-panel-type-field" className="right-half half-width">
          <Dropdown
            disabled={isTemplate || !!project.id}
            placeholder={t('projectPivotItem.placeholder.type')}
            label={t('projectPivotItem.label.type')}
            defaultSelectedKey={project?.type}
            onChange={(_, value) => {
              if (value.key === 2 && project.startDate && moment(project.startDate).isBefore()) {
                handleProjectState({ ...project, type: value.key, startDate: new Date() });
              } else {
                handleType(value.key);
              }
            }}
            options={projectTypes}
            required
            errorMessage={errorInField?.fieldName === 'type' ? errorInField?.message : null}
          />
        </div>
      </DevidedRow>
      <DevidedRow className="mt-1">
        <div id="project-panel-startDate-field" className="left-half half-width">
          <DateTime
            label={t('projectPivotItem.label.startDate')}
            onSelectDate={handleStartDate}
            value={project.startDate}
            minDate={project?.type === 1 ? new Date('01-01-1900') : new Date()}
            maxDate={project.plannedEndDate}
            disabled={getStartDateDisabledState()}
            className={errorInField?.fieldName === 'startDate' ? 'date-error' : null}
            required
          />
          {errorInField?.fieldName === 'startDate' && getErrorMessage(errorInField?.message)}
        </div>
        {getEndDateOrDurationField()}
      </DevidedRow>
      {project?.type === 2 && !project?.id ? (
        <DevidedRow className="mt-1">
          <div id="project-panel-numberOfSprints-field" className="left-half half-width">
            <NumbersField
              label={t('projectPivotItem.label.numberOfSprints')}
              numDecimals={0}
              required
              defaultValue={project?.numberOfSprints}
              onChange={handleNumberOfSprints}
              disabled={disabled}
            />
            {errorInField?.fieldName === 'numberOfSprints' &&
              getErrorMessage(errorInField?.message)}
          </div>
        </DevidedRow>
      ) : null}
      <div id="project-panel-teams-view-permission" style={{ marginTop: 10 }}>
        <Label>{t('projectPivotItem.label.assignedTeams')}</Label>
        <CombinedPicker
          searchUser={false}
          placeholder={t('create.route.template.settings.combinedPicker.placeholder')}
          searchTeams
          disabled={disabled}
          selectedItems={project?.assignedTeams}
          itemLimit={500}
          userToFilter={project?.assignedTeams}
          onChange={(teams) => {
            handleCurrentInput('assignedTeams', teams);
          }}
        />
      </div>
      <div style={{ marginTop: 15 }}>
        <TagPicker
          onChange={handleTags}
          disabled={disabled}
          onTagsSearch={tagsSearch}
          selectedTags={project.tags}
          defaultValue={project?.tags || []}
        />
      </div>
    </ProjectTabStyled>
  );
}

ProjectPivotItem.propTypes = {
  project: projectPropType,
  isTemplate: PropTypes.bool.isRequired,
  team: teamPropType,
  disabled: PropTypes.bool,
  handleProjectState: PropTypes.func.isRequired,
  handleTeamState: PropTypes.func.isRequired,
  errorInField: PropTypes.object
};

ProjectPivotItem.defaultProps = {
  project: null,
  team: null,
  errorInField: null
};

export default ProjectPivotItem;
