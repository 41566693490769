import PropTypes from 'prop-types';
import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from '@fluentui/react';

function DialogCustom({
  content,
  defaultButtonFunc,
  defaultButtonLabel,
  hidden,
  modalProps,
  onDismiss,
  primaryButtonFunc,
  primaryButtonLabel,
  subText,
  title
}) {
  function getDialogCustomStyles() {
    return {
      root: {},

      main: [
        {
          selectors: {
            '@media (min-width: 650px)': {
              width: '100%',
              borderRadius: '4px',
              minWidth: '400px'
            },
            '@media (max-width: 480px)': [
              {
                position: 'absolute',
                top: '10px',
                width: '100%',
                selectors: {
                  '.ms-Dialog-header': [
                    {
                      selectors: {
                        '.ms-Dialog-title': {
                          padding: '11px',
                          fontSize: '16px',
                          paddingRight: '40px'
                        },
                        'div[class^="topButton-"]': {
                          padding: '5px'
                        }
                      }
                    }
                  ]
                }
              }
            ]
          }
        }
      ]
    };
  }
  const buttonStyles = { root: { borderRadius: '3px' } };

  return (
    <Dialog
      hidden={hidden}
      onDismiss={() => onDismiss()}
      dialogContentProps={{
        type: DialogType.normal,
        title,
        subText
      }}
      styles={getDialogCustomStyles}
      modalProps={modalProps.isBlocking}
    >
      {content}
      <DialogFooter>
        {primaryButtonFunc ? (
          <PrimaryButton
            styles={buttonStyles}
            onClick={primaryButtonFunc}
            text={primaryButtonLabel}
          />
        ) : null}
        <DefaultButton
          styles={buttonStyles}
          onClick={() => defaultButtonFunc()}
          text={defaultButtonLabel}
        />
      </DialogFooter>
    </Dialog>
  );
}

DialogCustom.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
  defaultButtonFunc: PropTypes.func.isRequired,
  defaultButtonLabel: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  hidden: PropTypes.bool,
  modalProps: PropTypes.shape({ isBlocking: PropTypes.bool }),
  onDismiss: PropTypes.func,
  primaryButtonFunc: PropTypes.func,
  primaryButtonLabel: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  subText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired
};

DialogCustom.defaultProps = {
  content: null,
  defaultButtonLabel: 'Cancel',
  hidden: true,
  modalProps: { isBlocking: true },
  onDismiss: () => {},
  primaryButtonLabel: 'Confirm',
  primaryButtonFunc: null
};

export default DialogCustom;
