import DateTime, { IDateTimeProps } from 'components/inputs/DateTime';
import { useTranslation } from 'react-i18next';
import { DateTimeFieldFormat, IFieldLinkProps, RouteFieldVisibility } from 'types';
import { getFluentIconNameByFieldType } from 'utils/helpers';

export interface IDynamicDateTimeFieldProps {
  disabled?: boolean;
  fieldLink: IFieldLinkProps;
  onChange: (value?: Date | null) => void;
}

function DynamicDateTimeField({ fieldLink, disabled, onChange }: IDynamicDateTimeFieldProps) {
  const { t } = useTranslation();

  if (!fieldLink.field) return null;

  const { field, visibility } = fieldLink;
  const { name, description, descriptionPlacement, id, dateFormat } = field;
  const currentValue = fieldLink.value as string | undefined;

  const displayDescriptionTooltip = descriptionPlacement === 1;

  const readOnly = visibility === RouteFieldVisibility.ReadOnly;
  const context = disabled ? 'disabled' : undefined;

  let dateTimeStyles: IDateTimeProps['styles'] | undefined;

  if (disabled) {
    dateTimeStyles = {
      textFieldStyles: {
        wrapper: { border: '1px solid #a19f9d', borderRadius: 3 },
        field: { color: '#323130' }
      },
      timePickerStyles: { inputDisabled: { color: '#323130' }, root: { height: 34 } }
    };
  }

  return (
    <DateTime
      id={id}
      allowPastDate
      value={currentValue}
      description={displayDescriptionTooltip ? description : undefined}
      disabled={disabled || readOnly}
      displayTime={dateFormat === DateTimeFieldFormat.DateAndTime}
      label={name}
      labelIconName={getFluentIconNameByFieldType(fieldLink.field)}
      onSelectDate={onChange}
      required={visibility === RouteFieldVisibility.Required}
      timeLabel="Zeit"
      styles={dateTimeStyles}
      placeholder={t('dynamicField.dateTime.placeholder', { context })}
    />
  );
}

export default DynamicDateTimeField;
