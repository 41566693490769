import Label from 'components/inputs/Label';
import { IFieldLinkProps, RouteFieldType } from 'types';
import { getFluentIconNameByFieldType } from 'utils/helpers';
import ChoiceResultContent from './Components/ChoiceResultContent';
import DefaultResultContent, {
  IDefaultResultContentProps
} from './Components/DefaultResultContent';
import NumberResultContent from './Components/NumberResultContent';
import RatingResultContent from './Components/RatingResultContent';
import DynamicSurveyResultFieldStyled from './DynamicSurveyResultField.styles';

export interface IDynamicSurveyResultFieldProps {
  fieldLink: IFieldLinkProps;
  tenantId: string;
  onOpenWopi?: (fileId: string) => void;
  getStreamUrl: IDefaultResultContentProps['getStreamUrl'];
  getFileContents?: ({ id }: { id: string }) => Promise<Response>;
}

function DynamicSurveyResultField({
  fieldLink,
  tenantId,
  getStreamUrl,
  getFileContents,
  onOpenWopi
}: IDynamicSurveyResultFieldProps): JSX.Element | null {
  const fieldType = fieldLink.field?.fieldType || RouteFieldType.SmallText;

  let iconName;

  if (fieldLink.field) {
    iconName = getFluentIconNameByFieldType(fieldLink.field);
  }

  function displayAsDefaultResult(): boolean {
    return (
      fieldType === RouteFieldType.SmallText ||
      fieldType === RouteFieldType.LongText ||
      fieldType === RouteFieldType.Scanner ||
      fieldType === RouteFieldType.DateTime ||
      fieldType === RouteFieldType.Boolean ||
      fieldType === RouteFieldType.Person ||
      fieldType === RouteFieldType.Hyperlink ||
      fieldType === RouteFieldType.Document ||
      fieldType === RouteFieldType.Signature ||
      fieldType === RouteFieldType.AI ||
      fieldType === RouteFieldType.Multimedia
    );
  }

  function renderSurveyResultContent() {
    if (displayAsDefaultResult()) {
      return (
        <DefaultResultContent
          tenantId={tenantId}
          onOpenWopi={onOpenWopi}
          getStreamUrl={getStreamUrl}
          getFileContents={getFileContents}
          fieldLink={fieldLink}
        />
      );
    }

    if (fieldType === RouteFieldType.Number) {
      return <NumberResultContent fieldLink={fieldLink} />;
    }

    if (fieldType === RouteFieldType.Choice) {
      return <ChoiceResultContent fieldLink={fieldLink} />;
    }

    if (fieldType === RouteFieldType.Rating) {
      return <RatingResultContent fieldLink={fieldLink} />;
    }

    return '';
  }

  return (
    <DynamicSurveyResultFieldStyled>
      <Label
        label={fieldLink.field?.name}
        iconName={iconName}
        description={fieldLink.field?.description}
      />
      <div className="c-survey-result-content-wrapper">{renderSurveyResultContent()}</div>
    </DynamicSurveyResultFieldStyled>
  );
}

export default DynamicSurveyResultField;
