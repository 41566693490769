import config from '../config';
import { getGlobalTenantId, getGlobalUserId } from '../features/App/context/AppContext';
import { acquireAccessToken } from '../features/Authentication';
import { ai } from '../features/ErrorHandling/components/AppInsights';
import { isInIframe } from '../utils/helpers';

declare global {
  interface Window {
    /** Causes the next N API requests to fail (counts down) */
    DEBUG_FAIL_NEXT_N_API_REQUESTS?: number;
    /** Causes a random portion (0..1) of API requests to fail */
    DEBUG_FAIL_API_REQUESTS_CHANCE?: number;
  }
}

export async function debugFailApiRequest() {
  let shouldFail = false;
  if (window.DEBUG_FAIL_NEXT_N_API_REQUESTS > 0) {
    window.DEBUG_FAIL_NEXT_N_API_REQUESTS -= 1;
    shouldFail = true;
  } else if (
    window.DEBUG_FAIL_API_REQUESTS_CHANCE > 0 &&
    Math.random() < window.DEBUG_FAIL_API_REQUESTS_CHANCE
  ) {
    shouldFail = true;
  }

  if (shouldFail) {
    await new Promise((_, reject) => {
      setTimeout(
        () =>
          reject(
            new ApiError(ApiErrorType.Unknown, 599, 'debug fake error', {
              type: 'about:blank',
              title: 'debug fake error',
              status: 599,
              traceId: ai?.getTraceCtx()?.getTraceId()
            })
          ),
        1000
      );
    });
  }
}

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

/** A Problem Details object (RFC7807) */
export interface IProblemDetails {
  type: string;
  title: string;
  status: number;
  traceId?: string;
  detail?: string;
  instance?: string;

  /** Additional information about each error on Bad Request responses */
  errors?: Record<string, string[]>;
}

export interface IFetchOptions {
  addUserId?: boolean;
}

async function addAuth(headers: Headers) {
  let token: { accessToken: string };
  try {
    token = await acquireAccessToken();
  } catch (error) {
    return;
  }

  headers.append('authorization', `Bearer ${token.accessToken}`);

  // set tenant id if available
  const tenantId = window.localStorage.getItem('tenantId');
  if (tenantId) {
    headers.append('x-ep-tenant', tenantId);
  }
}

export enum ApiErrorType {
  Unknown,
  Authentication,
  BadRequest,
  Permissions,
  NotFound,
  Server,
  TooEarly,
  ClientSchema
}

export class ApiError extends Error {
  type: ApiErrorType;

  /** HTTP status */
  status: number;

  /** Problem details */
  details: IProblemDetails;

  /** Underlying Javascript error, if any */
  underlyingError?: Error;

  /** AppInsights trace ID */
  get traceId(): string | undefined {
    return this.details.traceId;
  }

  constructor(
    type: ApiErrorType,
    status: number,
    message: string,
    details?: IProblemDetails,
    underlyingError?: Error
  ) {
    super(message);
    this.type = type;
    this.status = status;
    this.details = details ?? { type: 'about:blank', status, title: message };
    this.underlyingError = underlyingError;
  }
}

async function handleErrorResponse(res: Response): Promise<never> {
  let text: string;
  try {
    text = await res.text();
  } catch (err) {
    throw new ApiError(ApiErrorType.Unknown, res.status, err.toString(), err);
  }

  let jsonData: object | string;
  try {
    jsonData = JSON.parse(text);
  } catch {
    // oh well!
  }

  const isJsonDataProbablyAProblemDetailsObject =
    jsonData &&
    typeof jsonData === 'object' &&
    (jsonData as { type: string }).type?.match?.(/^https?:\/\//i);

  const details = isJsonDataProbablyAProblemDetailsObject
    ? (jsonData as IProblemDetails)
    : ({
        type: 'about:blank',
        status: res.status,
        // eslint-disable-next-line no-nested-ternary
        title: typeof jsonData === 'string' ? jsonData : jsonData ? 'unknown error' : text
      } as IProblemDetails);

  const isJsonDataProbablyAnExceptionObject =
    jsonData && typeof jsonData === 'object' && 'ClassName' in jsonData && 'Message' in jsonData;

  if (isJsonDataProbablyAnExceptionObject) {
    const data = jsonData as {
      ClassName: string;
      Message: string;
      Data: unknown;
      InnerException: unknown;
      HelpURL: unknown;
      StackTraceString: unknown;
      RemoteStackTraceString: unknown;
      RemoteStackIndex: number;
      ExceptionMethod: unknown;
      HResult: number;
      Source: unknown;
      WatsonBuckets: unknown;
      ParamName: string;
      ActualValue: unknown;
    };
    details.title = data.Message;
    details.detail = data.ClassName;
    details.instance = data.ParamName;
  }

  if (!('traceId' in details)) {
    details.traceId = res.headers.get('traceid');
  }

  const wwwAuthenticateHeader = res.headers.get('www-authenticate');
  if (wwwAuthenticateHeader) {
    const consentUri = wwwAuthenticateHeader.match(/consentUri="([^"]+)"/);

    if (!isInIframe() && consentUri) {
      // localStorage used in RedirectContainer
      localStorage.setItem('redirectUri', window.location.href);
      window.open(consentUri[1], '_self');
    }
  }

  let errType = ApiErrorType.Unknown;

  switch (res.status) {
    case 400:
      errType = ApiErrorType.BadRequest;
      break;
    case 401:
      errType = ApiErrorType.Authentication;
      break;
    case 403:
      errType = ApiErrorType.Permissions;
      break;
    case 404:
      errType = ApiErrorType.NotFound;
      break;
    default:
      if (res.status >= 500 && res.status <= 599) {
        errType = ApiErrorType.Server;
      }
  }

  throw new ApiError(errType, res.status, text, details);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FetchRequestReturnType = any;

export interface IFetchProgress {
  lengthComputable: boolean;
  loaded: number;
  total: number;
}

export interface IFetchParams {
  method?: HttpMethod;
  url: string;
  body?: unknown;
  abort?: AbortSignal;
  retryCount?: number;
  onUploadProgress?: (progress: IFetchProgress) => void;
}

/** Performs a "fetch" with upload progress. Actually an XMLHttpRequest */
async function emulatedFetchWithXhr({
  method,
  url,
  headers,
  body,
  signal,
  onUploadProgress
}: {
  method: HttpMethod;
  url: string;
  headers: Headers;
  body: FormData;
  signal?: AbortSignal;
  onUploadProgress: (progress: IFetchProgress) => void;
}): Promise<Response> {
  const xhr = new XMLHttpRequest();
  xhr.responseType = 'blob';
  xhr.withCredentials = false;
  xhr.open(method ?? 'GET', url, true);

  for (const [k, v] of headers.entries()) {
    xhr.setRequestHeader(k, v);
  }

  if (signal) {
    const onAbort = () => {
      xhr.abort();
      signal.removeEventListener('abort', onAbort);
    };
    signal.addEventListener('abort', onAbort);
  }

  return new Promise<Response>((resolve, reject) => {
    const sendResponse = () => {
      if (xhr.status < 200) {
        reject(new TypeError('NetworkError'));
        return;
      }

      resolve(
        new Response(xhr.response, {
          status: xhr.status,
          statusText: xhr.statusText,
          headers: xhr
            .getAllResponseHeaders()
            ?.split('\r\n')
            ?.map((line) => line.split(/:\s*/, 2))
            ?.filter((line) => line.length === 2) as [string, string][] | undefined
        })
      );
    };

    xhr.addEventListener('abort', () => {
      reject(new Error('Request aborted'));
    });
    xhr.addEventListener('error', () => {
      sendResponse();
    });
    xhr.addEventListener('readystatechange', () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        sendResponse();
      }
    });

    xhr.upload.addEventListener('progress', (progress) => {
      onUploadProgress({
        lengthComputable: progress.lengthComputable,
        loaded: progress.loaded,
        total: progress.total
      });
    });

    xhr.send(body);
  });
}

function fetchOrXhr(
  url: string,
  {
    method,
    headers,
    body,
    signal,
    onUploadProgress
  }: {
    method: HttpMethod;
    headers: Headers;
    body: FormData | string | undefined;
    signal: AbortSignal;
    onUploadProgress?: IFetchParams['onUploadProgress'];
  }
) {
  if (onUploadProgress) {
    if (!(body instanceof FormData)) {
      throw new Error(
        'unsupported fetch call: if you use onUploadProgress, you must use a FormData body'
      );
    }

    return emulatedFetchWithXhr({
      method,
      url,
      headers,
      body,
      signal,
      onUploadProgress
    });
  }

  return fetch(url, {
    method,
    headers,
    body,
    signal,
    // If we don't omit credentials, we might rarely, randomly, unpredictably, get a mystery 500 Internal Server Error
    // response from *somewhere* in the network for all requests with a body.
    // This mystery response has content-length 0, and absolutely no other details, so we have no idea where it comes
    // from. This sure does fix it, though!
    credentials: 'omit'
  });
}

async function fetchBaseImpl<T>(
  apiUrlBase: string,
  { method = 'GET', url, body, abort, retryCount = 3, onUploadProgress }: IFetchParams,
  mapRes: (res: Response) => Promise<T>
): Promise<T> {
  const headers = new Headers();
  if (body !== undefined && !(body instanceof FormData)) {
    headers.append('content-type', 'application/json');
  }

  await addAuth(headers);
  await debugFailApiRequest();

  headers.append('x-ep-utcoffset', new Date().getTimezoneOffset().toString());

  for (let tryNumber = 0; tryNumber < retryCount; tryNumber += 1) {
    let fetchBody: string | FormData | undefined;
    if (body !== undefined && body instanceof FormData) fetchBody = body;
    else if (body !== undefined) fetchBody = JSON.stringify(body);

    // eslint-disable-next-line no-await-in-loop
    const res = await fetchOrXhr(`${apiUrlBase}/${url}`, {
      method,
      headers,
      body: fetchBody,
      signal: abort,
      onUploadProgress
    });

    if (!res.ok) {
      if (res.status === 425) {
        // Too Early: wait a bit and try again

        // eslint-disable-next-line no-await-in-loop
        await new Promise((resolve) => {
          setTimeout(resolve, 5000);
        });

        // eslint-disable-next-line no-continue
        continue;
      } else {
        return handleErrorResponse(res);
      }
    }

    return mapRes(res);
  }

  throw new ApiError(ApiErrorType.TooEarly, 425, 'Too early', {
    type: 'about:blank',
    status: 425,
    title: 'Too early',
    traceId: ai?.getTraceCtx()?.getTraceId()
  });
}

/**
 * Adds tenant ID to the URL if it is available.
 */
function addTenantIdToParamsUrl(params: IFetchParams): IFetchParams {
  const tenantId = getGlobalTenantId();

  const url = tenantId ? `tenants/${tenantId}/${params.url}` : params.url;

  return { ...params, url };
}

/**
 * Adds tenant ID to the URL if it is available.
 */
function addUserIdToParamsUrl(params: IFetchParams): IFetchParams {
  const userId = getGlobalUserId();

  const url = userId ? `members/${userId}/${params.url}` : params.url;

  return { ...params, url };
}

/**
 * Adds tenant ID and user ID to the URL if they are available.
 */
function addTenantIdAndUserIdToParamsUrl(params: IFetchParams, addUserId = false): IFetchParams {
  return addUserId
    ? addTenantIdToParamsUrl(addUserIdToParamsUrl(params))
    : addTenantIdToParamsUrl(params);
}

/**
 * Performs a JSON-based request (both request and response are JSON). You must handle errors yourself.
 */
export function fetchJson(params: IFetchParams): Promise<FetchRequestReturnType> {
  return fetchBaseImpl(config.API_URL, params, (res) => res.json());
}

/**
 * Performs a JSON request that expects nothing in return. You must handle errors yourself.
 */
export function fetchVoid(params: IFetchParams): Promise<void> {
  return fetchBaseImpl(config.API_URL, params, () => undefined);
}

/**
 * Performs a Blob request. You must handle errors yourself.
 */
export function fetchBlob(params: IFetchParams): Promise<Blob> {
  return fetchBaseImpl(config.API_URL, params, (res) => res.blob());
}

/**
 * Performs a JSON request to the AI endpoint. You must handle errors yourself.
 */
export function fetchAIJson(
  params: IFetchParams,
  { addUserId = false }: IFetchOptions = {}
): Promise<FetchRequestReturnType> {
  return fetchBaseImpl(config.AI_URL, addTenantIdAndUserIdToParamsUrl(params, addUserId), (res) => {
    return res.json();
  });
}

/**
 * Performs a JSON request to the AI endpoint that expects nothing in return. You must handle errors yourself.
 */
export function fetchAIVoid(
  params: IFetchParams,
  { addUserId = false }: IFetchOptions = {}
): Promise<void> {
  return fetchBaseImpl(
    config.AI_URL,
    addTenantIdAndUserIdToParamsUrl(params, addUserId),
    () => undefined
  );
}

/**
 * Performs a Blob request to the AI endpoint. You must handle errors yourself.
 */
export function fetchAIBlob(
  params: IFetchParams,
  { addUserId = false }: IFetchOptions = {}
): Promise<Blob> {
  return fetchBaseImpl(
    config.AI_URL,
    addTenantIdAndUserIdToParamsUrl(params, addUserId),
    async (res) => {
      return res.blob();
    }
  );
}
