import CodeEditor from 'components/inputs/CodeEditor';
import { IIntegrationFieldProps } from 'components/inputs/IntegrationField';
import { IMultiMediaFieldProps } from 'components/inputs/MultiMediaField';
import RichTextEditor from 'components/inputs/RichTextEditor';
import DynamicSurveyResultField from 'components/surfaces/DynamicSurveyResultField';
import { CSSProperties, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Transition } from 'react-transition-group';
import Dialog from 'components/surfaces/Dialog';
import {
  IFieldLinkProps,
  IFieldLinkValueProps,
  IFormGroupProps,
  ITeamProps,
  IUserProps,
  RouteFieldType,
  RouteFieldVisibility
} from 'types';
import {
  ActionButton,
  CommandBarButton,
  DirectionalHint,
  Icon,
  IconButton,
  Persona,
  PersonaSize,
  Spinner,
  SpinnerSize,
  TooltipHost
} from '@fluentui/react';
import { isEqual } from 'lodash';
import { t } from 'i18next';
import DynamicAIField from './DynamicAIField';
import DynamicBooleanField from './DynamicBooleanField';
import DynamicChoiceGroupField from './DynamicChoiceGroupField';
import DynamicCodeScannerField from './DynamicCodeScannerField';
import DynamicDateTimeField from './DynamicDateTimeField';
import DynamicDocumentField from './DynamicDocumentField';
import {
  DynamicFieldSectionStyled,
  FieldHistoryStyled,
  SectionStyled
} from './DynamicField.styles';
import DynamicHyperLinkField from './DynamicHyperLinkField';
import DynamicIntegrationField from './DynamicIntegrationField';
import DynamicLocationField from './DynamicLocationField';
import DynamicLongTextField from './DynamicLongTextField';
import DynamicLookupField from './DynamicLookupField';
import DynamicMultiMediaField from './DynamicMultiMediaField';
import DynamicNumberField from './DynamicNumberField';
import DynamicPersonField from './DynamicPersonField';
import DynamicRatingField from './DynamicRatingField';
import DynamicSignatureField from './DynamicSignatureField';
import DynamicSmallTextField from './DynamicSmallTextField';
import {
  FieldValueEditType,
  IFieldValueChangePermissions,
  IFieldValueHistoryItem,
  IInstanceFieldValuesHistoryParams,
  PROCESS_INSTANCE_FIELD_VALUES_HISTORY,
  useApiObject
} from '../../../hooks/api2';
import { useNotificationContext } from '../../../features/App';
import { ShowError } from '../../ShowError';
import { formatDate, UUID_NULL } from '../../../utils/helpers';
import Callout2 from '../../surfaces/Callout2';

interface ICombinedPickerSearchProps {
  filterText?: string;
  searchUser?: boolean;
  userId?: string;
  searchAAD?: boolean;
  searchTeams?: boolean;
  type?: number;
}

interface IcombinedPickerSearchResult {
  consentNeeded: boolean;
  teams: ITeamProps[];
  users: IUserProps[];
}

export interface IDynamicFieldProps {
  disabled?: boolean;
  fetchRequest?: ({ url, origin }: { url: string; origin?: string }) => Promise<unknown>;
  fieldLink: IFieldLinkProps;
  getDefinition: IIntegrationFieldProps['getDefinition'];
  getDefinitionExternalData: IIntegrationFieldProps['getDefinitionExternalData'];
  getDefinitionExternalDataList: IIntegrationFieldProps['onSearch'];
  getFileContents: ({ id }: { id: string }) => Promise<Response>;
  getStreamUrl: IMultiMediaFieldProps['getStreamUrl'];
  onChange?: (props: IDynamicFieldOnChangeProps) => void;
  onCombinedPickerSearch: (
    combinedPickerSearchProps: ICombinedPickerSearchProps
  ) => Promise<IcombinedPickerSearchResult>;
  style?: CSSProperties;
  tenantId: string;
  instanceId?: string;
  onOpenWopi?: (fileId: string) => void;
  /**
   * If passed, fields are disabled by default with a separate button to edit them.
   * If permission is granted (promise resolve), then users can click a button to confirm or cancel.
   */
  requestEdit?: (field: IFieldLinkProps) => Promise<IFieldEditSession>;
  historyCtx?: Omit<IInstanceFieldValuesHistoryParams, 'fieldId'>;
  /** If true, history will always be shown, instead of only when there are manual changes. */
  alwaysShowHistory?: boolean;
}

export interface IFieldEditSession {
  permissions: IFieldValueChangePermissions;
  /** Submits the change. */
  submit: (newValue: unknown) => Promise<void>;
}

export enum SectionType {
  default = 0,
  lookUp = 1,
  integration = 2,
  fieldGroup = 3
}

export interface IDynamicFieldOnChangeProps {
  sectionType?: SectionType;
  nestedSectionId?: string;
  fieldId: string;
  value?: IFieldLinkValueProps;
}

export interface IDynamicFieldGroupProps {
  disabled?: boolean;
  label: string;
  fields: IFieldLinkProps[];
}

export interface IDynamicFieldSectionOnChangeProps {
  sectionId: string;
  nestedSectionId?: string;
  fieldId: string;
  sectionType?: SectionType;
  value?: IFieldLinkValueProps;
}

export type SubmitHandle = {
  onBeforeSubmit?: () => void;
};

export interface IDynamicFieldSectionProps {
  currentUser?: IUserProps;
  disabled?: boolean;
  fetchRequest?: ({ url, origin }: { url: string; origin?: string }) => Promise<unknown>;
  getDefinition: IIntegrationFieldProps['getDefinition'];
  getDefinitionExternalData: IIntegrationFieldProps['getDefinitionExternalData'];
  getDefinitionExternalDataList: IIntegrationFieldProps['onSearch'];
  getFileContents: ({ id }: { id: string }) => Promise<Response>;
  getStreamUrl: IMultiMediaFieldProps['getStreamUrl'];
  onChange?: (props: IDynamicFieldSectionOnChangeProps) => void;
  onCombinedPickerSearch: IDynamicFieldProps['onCombinedPickerSearch'];
  onRenderLabel?: (label: string) => JSX.Element;
  section?: IFormGroupProps;
  sectionRef?: React.RefObject<SubmitHandle>;
  sectionType?: SectionType;
  tenantId: string;
  instanceId?: string;
  onOpenWopi?: (fileId: string) => void;
  requestEdit?: IDynamicFieldProps['requestEdit'];
  fieldHistoryCtx?: IDynamicFieldProps['historyCtx'];
  alwaysShowHistory?: boolean;
}

export function DynamicFieldSection({
  section,
  disabled,
  instanceId,
  currentUser,
  onCombinedPickerSearch,
  getDefinitionExternalData,
  getDefinition,
  getStreamUrl,
  fetchRequest,
  sectionRef,
  tenantId,
  onOpenWopi,
  getDefinitionExternalDataList,
  getFileContents,
  onChange,
  onRenderLabel,
  sectionType = SectionType.default,
  requestEdit,
  fieldHistoryCtx,
  alwaysShowHistory
}: IDynamicFieldSectionProps) {
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const [fieldsHeight, setFieldsHeight] = useState<number>(0);
  const fieldsRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  useLayoutEffect(() => {
    if (fieldsRef.current) {
      setFieldsHeight(fieldsRef.current.clientHeight);
    }
  }, [fieldsRef]);

  if (!section) return null;

  const { fields, id, name, customCode } = section;

  function onDynamicFieldChange(props: IDynamicFieldOnChangeProps) {
    const { fieldId, value, nestedSectionId } = props;

    if (onChange) {
      onChange({
        sectionId: id,
        fieldId,
        sectionType: props.sectionType || sectionType,
        value,
        nestedSectionId
      });
    }
  }

  function onRenderDynamicFormField(fieldLink: IFieldLinkProps) {
    return (
      <DynamicField
        instanceId={instanceId}
        fetchRequest={fetchRequest}
        getStreamUrl={getStreamUrl}
        getDefinition={getDefinition}
        getDefinitionExternalData={getDefinitionExternalData}
        getDefinitionExternalDataList={getDefinitionExternalDataList}
        getFileContents={getFileContents}
        tenantId={tenantId}
        disabled={disabled}
        onCombinedPickerSearch={onCombinedPickerSearch}
        fieldLink={fieldLink}
        key={
          // sometimes they have no ID, but fields are probably unique enough to serve as a substitute in that case
          fieldLink.id === UUID_NULL
            ? fieldLink.field?.id ?? fieldLink.fieldGroup?.id
            : fieldLink.id
        }
        onChange={onDynamicFieldChange}
        onOpenWopi={onOpenWopi}
        requestEdit={requestEdit}
        historyCtx={fieldHistoryCtx}
        alwaysShowHistory={alwaysShowHistory}
      />
    );
  }

  function onLabelClick() {
    setCollapsed((prevState) => !prevState);
  }

  function getLabel() {
    if (!name) {
      return null;
    }

    if (onRenderLabel) {
      return onRenderLabel(name);
    }

    return (
      <CommandBarButton
        className="c-dynamic-form-section-label"
        iconProps={{
          iconName: 'ChevronDown',
          styles: {
            root: {
              transition: 'transform 0.15s linear 0s',
              transform: collapsed ? 'rotate(-90deg)' : 'rotate(0)'
            }
          }
        }}
        onClick={onLabelClick}
        text={name}
        styles={{
          root: { padding: '7px 0px 7px 0px', width: '100%', borderBottom: '1px solid #d2d2d7' },
          label: { textAlign: 'left' },
          textContainer: {
            fontSize: 20
          }
        }}
      />
    );
  }

  const transitionStyles = {
    unmounted: { height: fieldsHeight, opacity: 1 },
    entering: { height: fieldsHeight, opacity: 1 },
    entered: { height: 'auto', opacity: 1 },
    exiting: { height: fieldsHeight, opacity: 0 },
    exited: { height: 0, opacity: 0 }
  };

  const duration = 200;
  const sectionLabel = getLabel();

  function renderFields() {
    if (customCode) {
      const scopes = {
        currentUser,
        disabled,
        fetchRequest,
        getStreamUrl,
        getDefinition,
        getDefinitionExternalData,
        getDefinitionExternalDataList,
        getFileContents,
        onCombinedPickerSearch,
        onDynamicFieldChange,
        sectionRef,
        tenantId
      };

      return (
        <CodeEditor
          defaultCode={customCode}
          scopes={scopes}
          displayEditor={false}
          contextProps={{ fieldLinks: fields }}
          styles={{ previewContainerStyles: { width: '100%' } }}
        />
      );
    }

    return fields.map(onRenderDynamicFormField);
  }

  return (
    <DynamicFieldSectionStyled $hasLabel={!!sectionLabel} key={id} id={id}>
      {sectionLabel}
      <Transition in={!collapsed} timeout={{ appear: 0, enter: duration, exit: 0 }}>
        {(state) => {
          return (
            <div
              style={{
                overflow: 'hidden',
                paddingRight: 3,
                paddingBottom: 3,
                transition: `height ${duration}ms ease-out, opacity ${duration}ms ease-out`,
                ...transitionStyles[state]
              }}
            >
              <div ref={fieldsRef} className="c-dynamic-form-fields-container">
                {renderFields()}
              </div>
            </div>
          );
        }}
      </Transition>
    </DynamicFieldSectionStyled>
  );
}

function DynamicField({
  onCombinedPickerSearch,
  disabled,
  fieldLink,
  fetchRequest,
  getDefinitionExternalData,
  getDefinitionExternalDataList,
  getDefinition,
  getStreamUrl,
  getFileContents,
  tenantId,
  instanceId,
  style,
  onChange,
  onOpenWopi,
  requestEdit,
  historyCtx,
  alwaysShowHistory
}: IDynamicFieldProps) {
  const fieldType = fieldLink.fieldGroup ? 0 : fieldLink.field?.fieldType;

  const [editSession, setEditSession] = useState<IFieldEditSession | null>(null);

  const isDisabled = disabled || !!requestEdit;

  function onDynamicFieldChange(value?: IFieldLinkValueProps) {
    if (onChange) onChange({ fieldId: fieldLink.id, value });
  }

  function onFieldGroupChange({
    fieldId,
    sectionId,
    value,
    sectionType
  }: IDynamicFieldSectionOnChangeProps) {
    // in this case, the sectionId is the nestedSection id

    if (onChange) {
      onChange({ fieldId, nestedSectionId: sectionId, value, sectionType });
    }
  }

  function getDynamicField() {
    if (fieldLink.values?.length) {
      // because values are passed, this is a survey result
      return (
        <DynamicSurveyResultField
          onOpenWopi={onOpenWopi}
          getStreamUrl={getStreamUrl}
          tenantId={tenantId}
          getFileContents={getFileContents}
          fieldLink={fieldLink}
        />
      );
    }

    if (fieldType === RouteFieldType.FieldGroup) {
      return (
        <DynamicFieldSection
          instanceId={instanceId}
          disabled={isDisabled || fieldLink.visibility === RouteFieldVisibility.ReadOnly}
          fetchRequest={fetchRequest}
          getDefinition={getDefinition}
          getDefinitionExternalData={getDefinitionExternalData}
          getDefinitionExternalDataList={getDefinitionExternalDataList}
          getFileContents={getFileContents}
          getStreamUrl={getStreamUrl}
          onChange={onFieldGroupChange}
          onCombinedPickerSearch={onCombinedPickerSearch}
          onOpenWopi={onOpenWopi}
          section={{
            fields: fieldLink.fieldGroup?.fields || [],
            id: fieldLink.id,
            name: fieldLink.fieldGroup?.name || ''
          }}
          sectionType={SectionType.fieldGroup}
          tenantId={tenantId}
          fieldHistoryCtx={historyCtx}
          alwaysShowHistory={alwaysShowHistory}
        />
      );
    }

    if (fieldType === RouteFieldType.SmallText) {
      return (
        <DynamicSmallTextField
          fieldLink={fieldLink}
          disabled={isDisabled}
          onChange={onDynamicFieldChange}
        />
      );
    }

    if (fieldType === RouteFieldType.LongText) {
      return (
        <DynamicLongTextField
          fieldLink={fieldLink}
          disabled={isDisabled}
          onChange={onDynamicFieldChange}
        />
      );
    }

    if (fieldType === RouteFieldType.Number) {
      return (
        <DynamicNumberField
          fieldLink={fieldLink}
          disabled={isDisabled}
          onChange={onDynamicFieldChange}
        />
      );
    }

    if (fieldType === RouteFieldType.Boolean) {
      return (
        <DynamicBooleanField
          fieldLink={fieldLink}
          disabled={isDisabled}
          onChange={onDynamicFieldChange}
        />
      );
    }

    if (fieldType === RouteFieldType.DateTime) {
      return (
        <DynamicDateTimeField
          fieldLink={fieldLink}
          disabled={isDisabled}
          onChange={onDynamicFieldChange}
        />
      );
    }

    if (fieldType === RouteFieldType.Choice) {
      return (
        <DynamicChoiceGroupField
          fieldLink={fieldLink}
          disabled={isDisabled}
          onChange={onDynamicFieldChange}
        />
      );
    }

    if (fieldType === RouteFieldType.Person) {
      return (
        <DynamicPersonField
          fieldLink={fieldLink}
          onCombinedPickerSearch={onCombinedPickerSearch}
          disabled={isDisabled}
          onChange={onDynamicFieldChange}
        />
      );
    }

    if (fieldType === RouteFieldType.Rating) {
      return (
        <DynamicRatingField
          fieldLink={fieldLink}
          disabled={isDisabled}
          onChange={onDynamicFieldChange}
        />
      );
    }

    if (fieldType === RouteFieldType.Hyperlink) {
      return (
        <DynamicHyperLinkField
          fieldLink={fieldLink}
          disabled={isDisabled}
          onChange={onDynamicFieldChange}
        />
      );
    }

    if (fieldType === RouteFieldType.Document) {
      return (
        <DynamicDocumentField
          fieldLink={fieldLink}
          disabled={isDisabled}
          onOpenWopi={onOpenWopi}
          getFileContents={getFileContents}
          onChange={onDynamicFieldChange}
        />
      );
    }

    if (fieldType === RouteFieldType.ExternalData) {
      return (
        <DynamicIntegrationField
          fieldLink={fieldLink}
          disabled={isDisabled}
          onChange={onDynamicFieldChange}
          getDefinition={getDefinition}
          getDefinitionExternalDataList={getDefinitionExternalDataList}
          getDefinitionExternalData={getDefinitionExternalData}
        />
      );
    }

    if (fieldType === RouteFieldType.Lookup) {
      return (
        <DynamicLookupField
          fieldLink={fieldLink}
          getFileContents={getFileContents}
          disabled={isDisabled}
          onChange={onDynamicFieldChange}
        />
      );
    }

    if (fieldType === RouteFieldType.Location) {
      return (
        <DynamicLocationField
          fieldLink={fieldLink}
          disabled={isDisabled}
          onChange={onDynamicFieldChange}
        />
      );
    }

    if (fieldType === RouteFieldType.Signature) {
      return (
        <DynamicSignatureField
          fieldLink={fieldLink}
          disabled={isDisabled}
          onChange={onDynamicFieldChange}
        />
      );
    }

    if (fieldType === RouteFieldType.Scanner) {
      return (
        <DynamicCodeScannerField
          fieldLink={fieldLink}
          disabled={isDisabled}
          onChange={onDynamicFieldChange}
        />
      );
    }

    if (fieldType === RouteFieldType.Multimedia) {
      return (
        <DynamicMultiMediaField
          tenantId={tenantId}
          fieldLink={fieldLink}
          disabled={isDisabled}
          getFileContents={getFileContents}
          getStreamUrl={getStreamUrl}
          onChange={onDynamicFieldChange}
        />
      );
    }

    if (fieldType === RouteFieldType.AI) {
      return (
        <DynamicAIField
          instanceId={instanceId}
          fieldLink={fieldLink}
          disabled={isDisabled}
          onChange={onDynamicFieldChange}
        />
      );
    }

    return null;
  }

  function renderDescription() {
    if (!fieldLink?.field) return null;

    if (fieldLink.field.descriptionPlacement !== 2) return null;

    if (fieldLink.field.fieldType === RouteFieldType.LongText) return null;

    return (
      <RichTextEditor
        disabled
        styles={{ editor: { margin: '3px 6px 0px 6px', fontSize: '12px', color: '#605e5c' } }}
        defaultValue={fieldLink.field.description}
      />
    );
  }

  const field = getDynamicField();

  const onBeginEdit = (session: IFieldEditSession) => {
    setEditSession(session);
  };

  const innerHistoryCtx = useMemo(() => {
    if (historyCtx && fieldLink.field) {
      return { ...historyCtx, fieldId: fieldLink.field.id };
    }
    return null;
  }, [historyCtx, fieldLink?.field]);

  // we need to recreate the field component when the value changes externally,
  // because it uses defaultValue instead of value for some reason.
  // for now, we'll only do this with requestEdit, because it might break things otherwise...
  const [fieldChangeKey, setFieldChangeKey] = useState(0);
  useEffect(() => {
    setFieldChangeKey((x) => x + 1);
  }, [fieldLink.value]);

  return (
    <SectionStyled style={style}>
      {requestEdit ? (
        <div className="c-field-edit-container">
          <div className="c-field-container" key={fieldChangeKey}>
            {field}
          </div>
          <RequestEditButton
            field={fieldLink}
            requestEdit={requestEdit}
            onBeginEdit={onBeginEdit}
          />
        </div>
      ) : (
        field
      )}
      {(fieldLink.hasLaterChanges || alwaysShowHistory) && innerHistoryCtx ? (
        <FieldChanges
          onCombinedPickerSearch={onCombinedPickerSearch}
          fieldLink={fieldLink}
          fetchRequest={fetchRequest}
          getDefinitionExternalData={getDefinitionExternalData}
          getDefinitionExternalDataList={getDefinitionExternalDataList}
          getDefinition={getDefinition}
          getStreamUrl={getStreamUrl}
          getFileContents={getFileContents}
          tenantId={tenantId}
          instanceId={instanceId}
          historyCtx={innerHistoryCtx}
          hasLaterChanges={fieldLink.hasLaterChanges}
        />
      ) : null}
      {!!requestEdit && (
        <EditSession
          open={!!editSession}
          onClose={() => setEditSession(null)}
          onSave={(value) => editSession.submit(value).then(() => setEditSession(null))}
          onCombinedPickerSearch={onCombinedPickerSearch}
          fieldLink={fieldLink}
          fetchRequest={fetchRequest}
          getDefinitionExternalData={getDefinitionExternalData}
          getDefinitionExternalDataList={getDefinitionExternalDataList}
          getDefinition={getDefinition}
          getStreamUrl={getStreamUrl}
          getFileContents={getFileContents}
          tenantId={tenantId}
          instanceId={instanceId}
        />
      )}
      {renderDescription()}
    </SectionStyled>
  );
}

export default DynamicField;

function getPermissionDeniedMessage(permissions: IFieldValueChangePermissions) {
  if (permissions.isIntegratedProcess && !permissions.isEditableInIntegration) {
    return t('dynamicField.editSession.permissionDenied.notEditableInIntegration');
  }
  if (permissions.isCreator && permissions.isCreatorAllowed && permissions.creatorStepPassed) {
    return t('dynamicField.editSession.permissionDenied.stepPassed', {
      stepName: permissions.creatorStepName
    });
  }
  if (permissions.isInvolved && permissions.isInvolvedAllowed && permissions.involvedStepPassed) {
    return t('dynamicField.editSession.permissionDenied.stepPassed', {
      stepName: permissions.involvedStepName
    });
  }
  return t('dynamicField.editSession.permissionDenied.generic');
}

export class RequestEditPermissionDeniedError extends Error {
  permissions: IFieldValueChangePermissions;

  constructor(permissions: IFieldValueChangePermissions) {
    super(getPermissionDeniedMessage(permissions));
    this.permissions = permissions;
  }
}

export function RequestEditButton({
  field,
  requestEdit,
  onBeginEdit
}: {
  field: IFieldLinkProps;
  requestEdit: Required<IDynamicFieldProps>['requestEdit'];
  onBeginEdit: (session: IFieldEditSession) => void;
}) {
  const [shouldCheck, setShouldCheck] = useState(false);
  const [permissions, setPermissions] = useState<IFieldValueChangePermissions | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  // check permission in background when user mouses over the button
  useEffect(() => {
    if (!shouldCheck) return;

    setIsLoading(true);
    requestEdit(field)
      .then((data) => setPermissions(data.permissions))
      .catch((err) => {
        if (err instanceof RequestEditPermissionDeniedError) {
          setPermissions(err.permissions);
        } else {
          // something went wrong... reset for next time
          setShouldCheck(false);
        }
      })
      .finally(() => setIsLoading(false));
  }, [field, requestEdit, shouldCheck]);

  const [isRequesting, setIsRequesting] = useState(false);
  const { showError } = useNotificationContext();

  let tooltipContent = null;

  const isDisallowed = permissions && !permissions.allowChanges;
  if (isDisallowed) {
    tooltipContent = getPermissionDeniedMessage(permissions);
  }

  return (
    <TooltipHost hostClassName="c-field-edit-button-container" content={tooltipContent}>
      <IconButton
        className={`c-field-edit-button ${isLoading || isRequesting ? 'is-loading' : ''}`}
        iconProps={{ iconName: 'Edit' }}
        disabled={isRequesting || isDisallowed}
        styles={{
          rootDisabled: {
            background: 'transparent',
            opacity: isDisallowed ? '0.5' : undefined
          }
        }}
        onPointerOver={() => setShouldCheck(true)}
        onClick={() => {
          setIsRequesting(true);
          requestEdit(field)
            .then(onBeginEdit)
            .catch((err) => showError(err))
            .finally(() => setIsRequesting(false));
        }}
      />
    </TooltipHost>
  );
}

type EmbeddedDynFieldProps = Omit<
  IDynamicFieldProps,
  'disabled' | 'style' | 'onChange' | 'requestEdit' | 'historyCtx'
>;

export function FieldChanges({
  hasLaterChanges,
  historyCtx,
  ...props
}: {
  hasLaterChanges: boolean;
  historyCtx: IInstanceFieldValuesHistoryParams;
} & EmbeddedDynFieldProps) {
  const { t } = useTranslation();
  const [historyOpen, setHistoryOpen] = useState(false);
  const viewHistoryButton = useRef<HTMLElement>();

  return (
    <div className={`c-field-changes-banner ${hasLaterChanges ? 'has-later-changes' : ''}`}>
      {hasLaterChanges ? (
        <>
          <Icon iconName="FieldChanged" />
          <div className="c-label">{t('dynamicField.fieldChanges.hasChanges')}</div>
        </>
      ) : (
        <div className="c-label" />
      )}
      <ActionButton
        elementRef={viewHistoryButton}
        className="c-view-history"
        text={t('dynamicField.fieldChanges.viewHistory')}
        onRenderText={(props, defaultRender) => (
          <span className="c-view-history-flex">
            {defaultRender(props)} <Icon className="c-view-history-icon" iconName="ChevronRight" />
          </span>
        )}
        onClick={() => setHistoryOpen(true)}
      />
      <Callout2
        directionalHint={DirectionalHint.bottomRightEdge}
        target={viewHistoryButton}
        open={historyOpen}
        onDismiss={() => setHistoryOpen(false)}
      >
        {historyOpen && <FieldHistory fieldProps={props} historyCtx={historyCtx} />}
      </Callout2>
    </div>
  );
}

export function FieldHistory({
  historyCtx,
  fieldProps
}: {
  historyCtx: IInstanceFieldValuesHistoryParams;
  fieldProps: EmbeddedDynFieldProps;
}) {
  const { t } = useTranslation();

  const { data, isLoading, isValidating, error } = useApiObject(
    PROCESS_INSTANCE_FIELD_VALUES_HISTORY,
    historyCtx
  );

  if (isLoading) {
    return (
      <FieldHistoryStyled>
        <div className="c-loading">
          <Spinner />
        </div>
      </FieldHistoryStyled>
    );
  }

  if (error) {
    return <ShowError error={error} />;
  }

  if (!data) return null;

  return (
    <FieldHistoryStyled>
      <div className="c-title">
        {t('dynamicField.fieldChanges.historyTitle')}
        {isValidating ? <Spinner size={SpinnerSize.small} /> : null}
      </div>
      <ul className="c-entries">
        {data.history.map((item, index) => (
          <FieldHistoryItem
            item={item}
            key={item.editDate}
            fieldProps={fieldProps}
            isCurrent={index === 0 && isEqual(fieldProps.fieldLink.value, item.value)}
          />
        ))}
      </ul>
    </FieldHistoryStyled>
  );
}

function FieldHistoryItem({
  item,
  fieldProps,
  isCurrent
}: {
  item: IFieldValueHistoryItem;
  fieldProps: EmbeddedDynFieldProps;
  isCurrent?: boolean;
}) {
  const { t } = useTranslation();

  const innerFieldLink = useMemo(
    () => ({
      ...fieldProps.fieldLink,
      value: item.value,
      field: fieldProps.fieldLink.field
        ? {
            ...fieldProps.fieldLink.field,
            // hide description because it's redundant and possibly quite large
            description: null
          }
        : null
    }),
    [fieldProps.fieldLink, item.value]
  );

  const editTypeLabel = (() => {
    switch (item.editType) {
      case FieldValueEditType.FromTask:
        return t('dynamicField.fieldChanges.editType.fromTask', item);
      case FieldValueEditType.Later:
        return t('dynamicField.fieldChanges.editType.later', item);
      default:
        throw new Error(`unknown edit type ${item.editType}`);
    }
  })();

  return (
    <li className="c-field-history-entry">
      {isCurrent ? (
        <div className="c-current-value">{t('dynamicField.fieldChanges.currentValue')}</div>
      ) : null}
      <div className="c-details">
        <Persona
          className="c-editor-persona"
          text={item.editor?.name}
          size={PersonaSize.size24}
          imageUrl={item.editor?.pictureUrl}
        />
        <div
          className={`c-edit-type ${
            item.editType === FieldValueEditType.Later ? 'is-global-edit' : ''
          }`}
        >
          {item.editType === FieldValueEditType.Later ? <Icon iconName="FieldChanged" /> : null}
          {editTypeLabel}
        </div>
        <div className="c-edit-date">{formatDate(new Date(item.editDate), 'L')}</div>
      </div>
      <div className="c-field-container">
        <DynamicField {...fieldProps} disabled fieldLink={innerFieldLink} />
      </div>
    </li>
  );
}

/** A retroactive field change session. Shows a dialog. */
export function EditSession({
  onCombinedPickerSearch,
  fieldLink,
  fetchRequest,
  getDefinitionExternalData,
  getDefinitionExternalDataList,
  getDefinition,
  getStreamUrl,
  getFileContents,
  tenantId,
  instanceId,
  onOpenWopi,
  open,
  onClose,
  onSave
}: {
  open: boolean;
  onClose: () => void;
  onSave: (value: unknown) => Promise<void>;
} & EmbeddedDynFieldProps) {
  const { t } = useTranslation();

  const [value, setValue] = useState(fieldLink.value);
  const [isSaving, setIsSaving] = useState(false);
  const innerFieldLink = useMemo(() => ({ ...fieldLink, value }), [fieldLink, value]);
  const isSameValue = useMemo(() => isEqual(fieldLink.value, value), [fieldLink.value, value]);

  const { showError } = useNotificationContext();

  const onChange = ({ value }: IDynamicFieldOnChangeProps) => setValue(value);

  return (
    <Dialog
      hidden={!open}
      styles={{
        root: {},
        main: {
          width: '100vw',
          '@media (min-width: 480px)': {
            width: '100%',
            maxWidth: '600px'
          }
        }
      }}
      dialogContentProps={{ title: t('dynamicField.editSession.title'), showCloseButton: true }}
      onDismiss={!isSaving && onClose}
      primaryButtonProps={{
        text: t('dynamicField.editSession.save'),
        disabled: isSameValue || isSaving,
        onClick: () => {
          setIsSaving(true);
          onSave(value)
            .catch(showError)
            .finally(() => setIsSaving(false));
        }
      }}
      content={
        <div>
          <div style={{ marginTop: '1em' }}>{t('dynamicField.editSession.description')}</div>
          <DynamicField
            disabled={isSaving}
            onCombinedPickerSearch={onCombinedPickerSearch}
            fieldLink={innerFieldLink}
            fetchRequest={fetchRequest}
            getDefinitionExternalData={getDefinitionExternalData}
            getDefinitionExternalDataList={getDefinitionExternalDataList}
            getDefinition={getDefinition}
            getStreamUrl={getStreamUrl}
            getFileContents={getFileContents}
            tenantId={tenantId}
            instanceId={instanceId}
            onOpenWopi={onOpenWopi}
            onChange={onChange}
          />
        </div>
      }
    />
  );
}
