import { useMessageDraft } from 'hooks';
import uniqBy from 'lodash/uniqBy';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ICommentProps, IUserProps } from 'types';
import { checkScreenWidth } from 'utils/helpers';
import { ActionButton, Persona, PersonaSize } from '@fluentui/react';
import { useTheme } from 'styled-components';
import Comment from './Comment';
import { IEditorValueProps } from './Conversation.types';
import Editor, { IEditorProps } from './Editor';

interface IConversationProps {
  /**
   * Text for the label
   */
  currentUser: IUserProps;
  /**
   * Function to search for users
   */
  getPersons: IEditorProps['getPersons'];
  /**
   * Function to search for tags
   */
  getTags?: IEditorProps['getTags'];
  /**
   * Text for the label
   */
  mainComment: ICommentProps;
  /**
   * Text for the label
   */
  onDeleteComment: (id: string) => void;
  /**
   * Text for the label
   */
  onSaveComment: (comment: IEditorValueProps) => void;
  /**
   * Callback issued when a tag is clicked.
   */
  onTagClick?: IEditorProps['onTagClick'];
  /**
   * Text for the label
   */
  replies: ICommentProps[];
}

enum displayRepliesType {
  all = 0,
  onlyLast = 1,
  none = 2
}

function Discussion({
  currentUser,
  getPersons,
  getTags,
  mainComment,
  onDeleteComment,
  onSaveComment,
  onTagClick,
  replies
}: IConversationProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  const [currentTextFieldValue, setCurrentTextFieldValue] = useState<string | undefined | null>(
    null
  );

  const [messageDraft, setSaveOnUnmount, deleteDraft] = useMessageDraft({
    message: currentTextFieldValue,
    id: mainComment.discussionId || mainComment.id
  });

  const [displayEditor, setDisplayEditor] = useState(false);
  const [displayReplies, setDisplayReplies] = useState<displayRepliesType>(1);

  function renderMainCommentPersonaCoin() {
    const imageUrl = mainComment.creator.pictureUrl || undefined;
    const text = mainComment.creator.name || undefined;

    return (
      <Persona
        styles={{ root: { marginRight: checkScreenWidth(['extraSmall']) ? 5 : 10 } }}
        imageUrl={imageUrl}
        size={checkScreenWidth(['extraSmall']) ? PersonaSize.size32 : PersonaSize.size40}
        hidePersonaDetails
        text={text}
      />
    );
  }

  function renderMainComment() {
    return (
      <Comment
        comment={mainComment}
        currentUser={currentUser}
        getPersons={getPersons}
        getTags={getTags}
        isReply={false}
        onDeleteComment={onDeleteComment}
        onSaveComment={onSaveComment}
        onTagClick={onTagClick}
      />
    );
  }

  function renderReplies() {
    if (!replies || replies.length === 0 || displayReplies === displayRepliesType.none) {
      return null;
    }

    // display the last reply (default)
    let repliesToDisplay = [replies[replies.length - 1]];

    if (displayReplies === displayRepliesType.all) {
      // display all replies
      repliesToDisplay = [...replies];
    }

    return (
      <>
        {repliesToDisplay.map((reply) => (
          <Comment
            comment={reply}
            currentUser={currentUser}
            getPersons={getPersons}
            getTags={getTags}
            isReply
            key={reply.id}
            onDeleteComment={onDeleteComment}
            onSaveComment={onSaveComment}
            onTagClick={onTagClick}
          />
        ))}
      </>
    );
  }

  function renderReplyActionButton() {
    if (!displayEditor && !messageDraft) {
      return (
        <div style={{ backgroundColor: `rgb(${theme.conversation.actionButtonBackground})` }}>
          <ActionButton
            className="c-conversation_reply-action-button"
            iconProps={{ iconName: 'Reply', className: 'c-conversation_reply-action-button-icon' }}
            styles={{
              root: {
                transition: 'background-color 300ms',
                height: 30,
                fontSize: 12,
                width: '100%'
              },
              rootHovered: {
                backgroundColor: `rgb(${theme.conversation.actionButtonHoverBackground})`,
                transition: 'background-color 300ms'
              },
              label: { color: `rgb(${theme.conversation.actionButtonForeground})` }
            }}
            onClick={() => setDisplayEditor(true)}
          >
            {t('conversation.discussion.replyButton.label')}
          </ActionButton>
        </div>
      );
    }

    return null;
  }

  function handleSaveDiscussionReply(newReplyEditorValue: IEditorValueProps) {
    setCurrentTextFieldValue(null);
    setDisplayEditor(false);

    deleteDraft();

    onSaveComment({ ...newReplyEditorValue, discussionId: mainComment.discussionId });
  }

  function onEditorChange({ message }: IEditorValueProps) {
    setCurrentTextFieldValue(message);
    setSaveOnUnmount(true);
  }

  function renderEditor() {
    if (displayEditor || messageDraft) {
      return (
        <Editor
          disabled={false}
          onChange={onEditorChange}
          defaultTextValue={messageDraft}
          getPersons={getPersons}
          getTags={getTags}
          onSaveComment={handleSaveDiscussionReply}
          onTagClick={onTagClick}
          placeholder={t('conversation.discussion.replyButton.label')}
          styles={{
            editorWrapper: { borderTop: 'none' },
            editor: { backgroundColor: `rgb(${theme.conversation.editorBackground})` }
          }}
        />
      );
    }

    return null;
  }

  function onSeeMoreButtonClick() {
    if (displayReplies === displayRepliesType.all) {
      setDisplayReplies(displayRepliesType.none);
    } else {
      setDisplayReplies(displayRepliesType.all);
    }
  }

  function getDistinctUserNamesFromReplies(repliesToUse: ICommentProps[]) {
    const distinctRepliesByUserNames = uniqBy(repliesToUse, 'creator.name');

    const distinctUserNames = distinctRepliesByUserNames.map((reply) => reply.creator.name);

    const hasCurrentUserReply = repliesToUse.some(
      (reply) => reply.creator.userId === currentUser.userId
    );

    if (hasCurrentUserReply) {
      const currentUserNameIndex = distinctUserNames.findIndex((name) => name === currentUser.name);

      // remove current user name
      distinctUserNames.splice(currentUserNameIndex, 1);
      // replace with "you"
      distinctUserNames.unshift(t('conversation.discussion.seeMoreButton.you'));
    }

    return distinctUserNames;
  }

  function getRepliesToHide() {
    const repliesClone = [...replies];

    // by default the last reply is displayed
    repliesClone.pop();

    let repliesToHide = [...repliesClone];

    if (displayReplies === displayRepliesType.none) {
      // all replies are hidden
      repliesToHide = [...replies];
    }

    return repliesToHide;
  }

  function getSeeMoreButtonLabel(): string {
    if (displayReplies === displayRepliesType.all) {
      return t('conversation.discussion.reduceAll');
    }

    // all replies that are not shown
    const repliesToHide = getRepliesToHide();

    const repliesCount = repliesToHide.length;

    const distinctUserNames = getDistinctUserNamesFromReplies(repliesToHide);

    let translationContext;

    if (distinctUserNames.length === 1) {
      translationContext = 'singlePersonReply';
    } else if (distinctUserNames.length === 2) {
      translationContext = 'dualPersonReply';
    } else {
      translationContext = 'multiPersonReply';
    }

    return t('conversation.discussion.seeMoreButton.label', {
      repliesCount,
      count: distinctUserNames.length,
      firstUserName: distinctUserNames[0],
      secondUserName: distinctUserNames[1],
      thirdUserName: distinctUserNames[2],
      othersCount: distinctUserNames.length - 2,
      context: translationContext
    });
  }

  function renderSeeMoreButton() {
    if (replies && replies.length > 1) {
      const buttonLabel = getSeeMoreButtonLabel();

      const iconName =
        displayReplies === displayRepliesType.all ? 'CaretDownSolid8' : 'CaretRightSolid8';

      const iconStyles =
        displayReplies === displayRepliesType.all
          ? { root: { opacity: '0.8 !important', visibility: 'visible !important' } }
          : undefined;

      return (
        <ActionButton
          className="c-conversation_seeMore-action-button"
          iconProps={{
            iconName,
            className: 'c-conversation_seeMore-action-button-icon',
            styles: iconStyles
          }}
          styles={getSeeMoreButtonStyles()}
          onClick={onSeeMoreButtonClick}
        >
          {buttonLabel}
        </ActionButton>
      );
    }

    return null;
  }

  function getSeeMoreButtonStyles() {
    return {
      root: {
        width: '100%',
        backgroundColor: `rgb(${theme.conversation.actionButtonBackground})`,
        height: 25,
        fontSize: 12,
        padding: 0,
        paddingLeft: 1
      },
      rootHovered: {
        textDecoration: 'underline',
        backgroundColor: `rgb(${theme.conversation.actionButtonHoverBackground})`,
        transition: 'background-color 300ms'
      },
      label: { color: `rgb(${theme.conversation.actionButtonForeground})`, margin: 0 }
    };
  }

  return (
    <div className="c-conversation_discussion-wrapper">
      {renderMainCommentPersonaCoin()}
      <div
        style={{
          border: `1px solid rgb(${theme.conversation.discussionOutline})`,
          boxShadow: '0 0.2rem 0.4rem -0.075rem rgb(0 0 0 / 10%)',
          borderRadius: '.3rem',
          overflow: 'hidden',
          width: '100%'
        }}
      >
        {renderMainComment()}
        {renderSeeMoreButton()}
        {renderReplies()}
        {renderReplyActionButton()}
        {renderEditor()}
      </div>
    </div>
  );
}

export default Discussion;
