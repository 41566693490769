import { LoadingSpinner, MainCommandBar, SettingsContainer } from 'components';
import { AppContext, useNotificationContext } from 'features/App';
import NoAccessContainer from 'pages/NoAccess';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { PrimaryButton, Spinner, Stack, Toggle } from '@fluentui/react';
import { ShowError } from '../../../../components/ShowError';
import { useApiObject } from '../../../../hooks/api2';
import { FE_SETTINGS, IFESettingsItem } from '../../../../hooks/api2/types/administration';
import { fetchBlob, fetchVoid } from '../../../../services/api2';
import AppearanceSettings from './AppearanceSettings';
import DWHSettings from './DWHSettings';
import ExchangeSettings from './ExchangeSettings';

const SettingsAdministrationStyled = styled.div`
  background-color: rgb(${({ theme }) => theme.administration.settingsBackground});
  padding: 30px;
  overflow-y: auto;
  height: 100%;
`;

export default function SettingsAdministration() {
  // global app state
  const { globalAppState, dispatch } = useContext(AppContext);
  const { currentUser } = globalAppState;

  const { t } = useTranslation();

  const { showError } = useNotificationContext();

  const {
    data: originalSettings,
    isLoading,
    isValidating,
    isMutating,
    error,
    mutate
  } = useApiObject(FE_SETTINGS, {});
  const [editedSettings, setEditedSettings] = useState<IFESettingsItem | null>(null);

  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => setEditedSettings(originalSettings), [originalSettings]);
  const settings = editedSettings ?? originalSettings;

  const onSettingsChange = useCallback((changes: Partial<IFESettingsItem>) => {
    setEditedSettings((settings) => ({ ...settings, ...changes }));
  }, []);

  function saveSettings() {
    const mutation = mutate(
      fetchVoid({
        url: 'FESettings',
        method: 'PUT',
        body: editedSettings
      }).then(() => ({
        ...editedSettings,
        dwhConnectionError: null
      })),
      {},
      {
        // we have to revalidate, because settings may be saved even with an error (e.g. due to DWH failure)
        revalidateError: true
      }
    );
    mutation.promise.catch(showError);

    dispatch({ type: 'setTheme', data: settings.palette as unknown as string });
  }

  const isSaveButtonDisabled = isMutating || editedSettings === originalSettings;

  async function handleDownloadUserList() {
    try {
      setIsDownloading(true);

      const blob = await fetchBlob({ url: 'User/Excel' });
      const blobUrl = URL.createObjectURL(blob);

      const downloadLink = document.createElement('a');
      downloadLink.href = blobUrl;
      downloadLink.download = t('settingsAdministration.userListfileName');
      downloadLink.style.display = 'none';

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);

      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      showError(error);
    } finally {
      setIsDownloading(false);
    }
  }

  if (!currentUser?.isAdmin) {
    return <NoAccessContainer />;
  }

  if (isLoading) {
    return <LoadingSpinner label={t('loading.settings.text')} />;
  }

  if (error) {
    return <ShowError error={error} />;
  }

  return (
    <>
      <MainCommandBar
        items={[
          {
            key: 'saveSettings',
            className: 'command-bar-item',
            text: t('settingsAdministration.saveSettings'),
            iconProps: { iconName: 'save' },
            disabled: isSaveButtonDisabled,
            onClick: saveSettings
          }
        ]}
        farItems={
          isValidating
            ? [
                {
                  key: 'validating',
                  onRender: () => <Spinner />
                }
              ]
            : []
        }
      />
      <SettingsAdministrationStyled>
        <Stack tokens={{ childrenGap: 15 }}>
          <AppearanceSettings settings={settings} onSettingsChange={onSettingsChange} />
          <DWHSettings
            settings={settings}
            originalSettings={originalSettings}
            onSettingsChange={onSettingsChange}
          />
          <ExchangeSettings settings={settings} onSettingsChange={onSettingsChange} />
          <SettingsContainer
            defaultExpanded
            label={t('settingsAdministration.form.label.newUser')}
            iconName="People"
            description={t('settingsAdministration.form.summary.newUser')}
          >
            <Toggle
              onChange={(_, checked) => onSettingsChange({ useAllUsers: checked })}
              defaultChecked={settings.useAllUsers}
              onText={t('settingsAdministration.form.label.newUserOn')}
              offText={t('settingsAdministration.form.label.newUserOff')}
              styles={{ root: { marginTop: 20 }, text: { fontSize: 13 } }}
            />
            <div style={{ fontSize: 'smaller' }}>
              {t('settingsAdministration.form.description.newUser')}
            </div>
          </SettingsContainer>
          <SettingsContainer
            defaultExpanded
            isCollapsible={false}
            label={t('settingsAdministration.form.label.userList')}
            iconName="List"
            description={t('settingsAdministration.form.description.userList')}
          >
            <PrimaryButton
              iconProps={{ iconName: 'Download' }}
              onRenderIcon={isDownloading ? () => <Spinner /> : undefined}
              disabled={isDownloading}
              styles={{ root: { borderRadius: 4, marginTop: 10, paddingLeft: 7, paddingRight: 7 } }}
              onClick={handleDownloadUserList}
            >
              {t('settingsAdministration.form.button.downloadUserList')}
            </PrimaryButton>
          </SettingsContainer>
        </Stack>
      </SettingsAdministrationStyled>
    </>
  );
}
