import chroma from 'chroma-js';
import { useEffect, useRef, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { IFieldLinkProps, IFieldSurveyResultChoiceProps } from 'types';
import { getTheme } from '@fluentui/react';
import DefaultResultContent from './DefaultResultContent';
import SurveyResultOverflowButton from './SurveyResultOverflowButton';

interface IChoiceResultContentProps {
  fieldLink: IFieldLinkProps;
}

function ChoiceResultContent({ fieldLink }: IChoiceResultContentProps): JSX.Element | null {
  const [showDetails, setShowDetails] = useState(false);
  const [showAggregation, setShowAggregation] = useState(false);
  const [displayOverflowButton, setDisplayOverflowButton] = useState(false);

  const chartRef = useRef();

  const { values } = fieldLink;

  useEffect(() => {
    if (values) {
      const shouldShowOverflowButton = !!values.find((value) => value.aggregation === 1);
      const shouldShowDetails = !values.find((value) => value.aggregation === 8);
      const shouldShowAggregation = !!values.find((value) => value.aggregation !== 1);
      const hasAggregatedValues = !!values.find((value) => value.aggregation === 8);

      setShowDetails(shouldShowDetails);
      setShowAggregation(shouldShowAggregation);
      setDisplayOverflowButton(shouldShowOverflowButton && hasAggregatedValues);
    }
  }, [values]);

  function getDoughnutData() {
    const labels: string[] = [];
    let colors: string[] = [];
    const data: number[] = [];

    const resultValues = values?.filter((value) => value.aggregation === 8);

    resultValues?.forEach((resultValue) => {
      if (resultValue) {
        const result = resultValue.value as IFieldSurveyResultChoiceProps;

        labels.push(result.choice);
        data.push(result.count);
      }
    });

    colors = chroma
      .scale([getTheme().palette.themePrimary, '#5c2e91', '#0b6a0b', '#69797e'])
      .mode('lch')
      .colors(resultValues?.length);

    return { labels, data, colors };
  }

  function renderAggregatedField() {
    const { labels, data, colors } = getDoughnutData();

    const doughnutlabel = labels.map((i, index) => `${i} (${data[index]})`);

    return (
      <div>
        <Doughnut
          ref={chartRef}
          options={{ plugins: { legend: { position: 'left' } }, maintainAspectRatio: false }}
          height={200}
          data={{
            labels: doughnutlabel,
            datasets: [
              {
                data,
                backgroundColor: colors,
                hoverOffset: 4
              }
            ]
          }}
        />
      </div>
    );
  }

  function renderDetails() {
    return (
      <div style={{ marginTop: showAggregation ? 10 : 0 }}>
        <DefaultResultContent fieldLink={fieldLink} />
      </div>
    );
  }

  return (
    <div>
      {displayOverflowButton && (
        <SurveyResultOverflowButton showDetails={showDetails} setShowDetails={setShowDetails} />
      )}
      {showAggregation && renderAggregatedField()}
      {showDetails && renderDetails()}
    </div>
  );
}

export default ChoiceResultContent;
