import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import breakpoints from 'utils/breakpoints';
import { checkScreenWidth, getPivotStyles } from 'utils/helpers';
import { Dropdown, Icon, Pivot, PivotItem, getTheme } from '@fluentui/react';
import { getProjectStatusOptions } from '../ProjectConstants';

const ProjectPanelHeaderStyled = styled.div`
  background-color: ${(props) => props.theme.themePrimary};
  color: white;
  height: 110px;

  .new-edit-project-title {
    font-size: 28px;
    padding-left: 16px;
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
  }

  .new-edit-project-subtitle {
    font-size: 14px;
    padding-left: 16px;
  }

  @media (max-width: ${breakpoints.smallMax}px) {
    height: auto;

    .new-edit-project-title {
      font-size: 18px;
    }

    .new-edit-project-subtitle {
      font-size: 12px;
    }
  }
`;

const ProjectStatusStyled = styled.div`
  padding-right: 0.75rem;
  margin-left: 0.5rem;
  @media (max-width: ${breakpoints.smallMax}px) {
    padding-right: 0.5rem;
  }

  .project-panel-status {
    display: flex;
    flex-direction: row;
    margin: auto;
  }

  .checkbox-container {
    background-color: ${() => getTheme().palette.themePrimary};
    border-radius: 50%;
    border: 1px solid #e5e5e5;
    height: 31px;
    width: 31px;
    margin-top: 4px;
    @media (max-width: ${breakpoints.smallMax}px) {
      height: 24px;
      width: 24px;
      margin-top: 9px;
    }
  }

  .checkbox-icon {
    color: ${() => getTheme().palette.themePrimary};
    font-size: 15px;
    display: block;
    margin-left: 7px;
    padding-top: 7px;
    @media (max-width: ${breakpoints.smallMax}px) {
      margin-left: 4px;
      padding-top: 4px;
    }
  }

  .project-panel-status-label {
    font-size: 10px;
    padding-left: 8px;
    @media (max-width: ${breakpoints.smallMax}px) {
      z-index: 10;
      position: relative;
      top: 5px;
    }
  }

  .project-panel-status-dropdown * {
    &:hover {
      color: white;
    }
    background-color: ${() => getTheme().palette.themePrimary};
    border: 0;
    color: white !important;
    font-size: 13px;
  }
`;
function ProjectPanelHeader({
  newStatus,
  project,
  isTemplate,
  onPivotLinkClick,
  tab,
  pages,
  onStatusChange
}) {
  const { t } = useTranslation();

  const projectStatuses = getProjectStatusOptions(t);
  const isEdit = project?.id;

  const pivotItems = useMemo(() => {
    const pivotArray = [];

    function getSprintsHeaderText() {
      if (checkScreenWidth(['extraSmall'])) {
        return undefined;
      }

      return t('projectPanelHeader.pivotItemLabel.sprints');
    }

    pivotArray.push(
      <PivotItem
        itemIcon={checkScreenWidth(['extraSmall']) ? 'Taskboard' : undefined}
        className="pivot-item-height"
        itemKey="project"
        key="project"
        headerText={
          checkScreenWidth(['extraSmall'])
            ? undefined
            : t('projectPanelHeader.pivotItemLabel.project')
        }
      />
    );

    if (project?.id && project.type === 2) {
      pivotArray.splice(
        1,
        0,
        <PivotItem
          itemIcon={checkScreenWidth(['extraSmall']) ? 'Sprint' : undefined}
          className="pivot-item-height"
          itemKey="sprints"
          key="sprints"
          headerText={getSprintsHeaderText()}
        />
      );
    }

    if (project?.id) {
      pivotArray.push(
        <PivotItem
          itemIcon={checkScreenWidth(['extraSmall']) ? 'Message' : undefined}
          className="pivot-item-height"
          itemKey="conversation"
          key="conversation"
          headerText={
            checkScreenWidth(['extraSmall'])
              ? undefined
              : t('projectPanelHeader.pivotItemLabel.conversation')
          }
        />
      );
    }

    // Enabled only for existing project until the component is refactored
    if (project?.id) {
      pivotArray.push(
        <PivotItem
          itemIcon={checkScreenWidth(['extraSmall']) ? 'DocumentSet' : undefined}
          className="pivot-item-height"
          itemKey="files"
          key="files"
          headerText={
            checkScreenWidth(['extraSmall'])
              ? undefined
              : t('projectPanelHeader.pivotItemLabel.files')
          }
        />
      );
    }

    pivotArray.push(
      <PivotItem
        itemIcon={checkScreenWidth(['extraSmall']) ? 'Settings' : undefined}
        className="pivot-item-height"
        itemKey="settings"
        key="settings"
        headerText={
          checkScreenWidth(['extraSmall'])
            ? undefined
            : t('projectPanelHeader.pivotItemLabel.settings')
        }
      />
    );

    if (!isTemplate && project?.id && project?.processAssociations?.length > 0) {
      pivotArray.push(
        <PivotItem
          itemIcon={checkScreenWidth(['extraSmall']) ? 'Play' : undefined}
          className="pivot-item-height"
          itemKey="processes"
          key="processes"
          headerText={
            checkScreenWidth(['extraSmall'])
              ? undefined
              : t('projectPanelHeader.pivotItemLabel.processes')
          }
        />
      );
    }

    if (pages?.length > 0) {
      pages.forEach((page) => {
        if (project.includedPages?.includes(page.id)) {
          pivotArray.push(
            <PivotItem
              itemIcon={checkScreenWidth(['extraSmall']) ? 'Page' : undefined}
              className="pivot-item-height"
              itemKey={page.id}
              key={page.id}
              headerText={checkScreenWidth(['extraSmall']) ? undefined : page.name}
            />
          );
        }
      });
    }

    return pivotArray;
  }, [
    t,
    project?.id,
    project.type,
    project?.processAssociations?.length,
    project?.includedPages,
    isTemplate,
    pages
  ]);

  const statusContainerStyle =
    project.status === 10 ? { backgroundColor: 'white' } : { display: 'inline' };

  const statusIconStyle = project.status === 10 ? { display: 'block' } : { display: 'none' };

  const statusDropdown = (
    <ProjectStatusStyled>
      <div className="project-panel-status">
        <div style={statusContainerStyle} className="checkbox-container">
          <Icon style={statusIconStyle} className="checkbox-icon" iconName="Accept" />
        </div>
        <div>
          <div className="project-panel-status-label">Status</div>
          <Dropdown
            className="project-panel-status-dropdown"
            selectedKey={newStatus || project.status}
            dropdownWidth={200}
            options={projectStatuses}
            onChange={(_, option) => onStatusChange(option?.key)}
            disabled={project.permissions ? !project.permissions.update : false}
          />
        </div>
      </div>
    </ProjectStatusStyled>
  );

  return (
    <ProjectPanelHeaderStyled>
      <div className="new-edit-project-title">
        {isEdit
          ? t('projectPanelHeader.title.editProject')
          : t('projectPanelHeader.title.newProject')}
        {isEdit && !checkScreenWidth(['extraSmall', 'small']) ? statusDropdown : null}
      </div>
      <div className="new-edit-project-subtitle">
        {project && isEdit ? project.name : t('projectPanelHeader.subTitle.create')}
      </div>
      <Pivot
        linkSize="normal"
        overflowBehavior="menu"
        getTabId={(itemKey) => `project-panel-${itemKey}-tab`}
        selectedKey={`${tab}`}
        onLinkClick={(item) => onPivotLinkClick(item.props.itemKey)}
        styles={getPivotStyles}
      >
        {pivotItems}
      </Pivot>
    </ProjectPanelHeaderStyled>
  );
}

ProjectPanelHeader.propTypes = {
  project: PropTypes.object,
  onPivotLinkClick: PropTypes.func.isRequired,
  isTemplate: PropTypes.bool.isRequired,
  tab: PropTypes.string,
  newStatus: PropTypes.number,
  pages: PropTypes.array,
  onStatusChange: PropTypes.func.isRequired
};

ProjectPanelHeader.defaultProps = {
  project: null,
  tab: null
};

export default ProjectPanelHeader;
