import { IApiListFetchResult, IApiListPage, IApiListType } from '../list';
import {
  createApiObjectDataFromPlainObject,
  IApiObjectCache,
  IApiObjectData,
  IApiObjectType,
  makeApiObjectVariantKey
} from '../object';
import { DateTime, Ref, Uuid } from './base-types';
import { IUser, USER } from './users';
import { simpleIdObject } from './utils';
import { fetchJson } from '../../../services/api2';

export type ActivityType = number;

export interface IActivity extends IApiObjectData {
  id: Uuid;
  type: ActivityType;
  message: string | null;
  link: string | null;
  creationDate: DateTime;
  creator: Ref<IUser> | null;
  notificationId: Uuid;
  isNew: boolean;
  notificationRecipient: Ref<IUser> | null;
  activityContextId: Uuid | null;
}

export const ACTIVITY: IApiObjectType<string, IActivity> = simpleIdObject({
  id: 'Activity',
  url: (id) => `Activity/${id}`,
  fields: [
    { path: 'creator', type: USER, params: (data) => data.creator?.userId },
    {
      path: 'notificationRecipient',
      type: USER,
      params: (data) => data.notificationRecipient?.userId
    }
  ]
});

export interface IGetActivitiesParams {
  /** If specified, only activities related to this team will be returned. */
  teamId?: string;
  /** If specified, only activities related to this task will be returned. */
  taskId?: string;
  /** If specified, only activities initiated by this user will be returned. */
  creatorId?: string;
  /**
   * If specified, only activities relevant to this user will be returned.
   * Relevant means all activities the user gets a notification for.
   */
  currentUserId?: string;
  /** If specified, only activities after this date will be returned. */
  startDate?: string;
  /** If specified, only activities before this date will be returned. */
  endDate?: string;
}

export const ACTIVITIES: IApiListType<IGetActivitiesParams, typeof ACTIVITY> = {
  id: 'Activities',
  async fetchPage(
    cache: IApiObjectCache,
    params: IGetActivitiesParams,
    pageIndex: number,
    _?: IApiListPage<typeof ACTIVITY>,
    abort?: AbortSignal
  ): Promise<IApiListFetchResult<typeof ACTIVITY>> {
    const searchParams = new URLSearchParams(
      Object.entries(params).filter(([, v]) => v !== null && v !== undefined)
    );
    searchParams.set('pageIndex', pageIndex.toString());

    const { items, searchProps } = await fetchJson({
      method: 'GET',
      url: `Activities?${searchParams}`,
      abort
    });
    return {
      items: items.map((item: object) => {
        const id = makeApiObjectVariantKey(ACTIVITY, (item as IActivity).id);
        cache.insertObjectData(createApiObjectDataFromPlainObject(cache, id, item));
        return id;
      }),
      searchProps
    };
  }
};
