import styled from 'styled-components';

export const WrapperStyled = styled.div`
  min-width: 200px;
  max-width: 220px;
  border-bottom: 1px solid #eaeaea;
`;

export const EmptyResultStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
`;
