import { CHATS, IApiObjectArray, IChat, IDataOverlay, mutate, useApiObject } from 'hooks/api2';
import { createContext, ReactNode, useCallback, useContext, useMemo } from 'react';
import { fetchAIVoid } from 'services/api2';

const ChatsContext = createContext({
  chats: [] as IChat[],
  isLoading: false
} as {
  chats: IChat[];
  isLoading: boolean;
  deleteChat: (id: string) => void;
  replaceOrAddChat: (chat: IChat) => void;
});

export const useChatsContext = () => {
  const { chats, replaceOrAddChat, deleteChat } = useContext(ChatsContext);

  return { chats, replaceOrAddChat, deleteChat };
};

export function replaceOrAddChat(
  chats: IApiObjectArray<IChat>,
  promise: Promise<unknown>,
  mutatedChat: IChat
): Promise<unknown> {
  const mutation: IDataOverlay<IApiObjectArray<IChat>> = ({ items }) => ({
    items: items.filter((c) => c.id !== mutatedChat.id).concat(mutatedChat)
  });

  return mutate(
    chats,
    promise.then(() => mutation),
    mutation,
    { revalidate: true }
  ).promise;
}

export function deleteChat(chats: IApiObjectArray<IChat>, id: string): Promise<unknown> {
  const promise = fetchAIVoid(
    {
      url: `Chats/${id}`,
      method: 'DELETE'
    },
    { addUserId: true }
  ).then(() => {
    return ({ items }) => ({ items: items.filter((chat) => chat.id !== id) });
  });

  return mutate<IApiObjectArray<IChat>>(
    chats,
    promise,
    ({ items }) => ({
      items: items.filter((chat) => chat.id !== id)
    }),
    { revalidate: true }
  ).promise;
}

export function ChatsProvider({ children }: { children: ReactNode }) {
  const { data: chatsData, isLoading } = useApiObject(CHATS, {}, { includes: [] });

  const deleteChat2 = useCallback((id: string) => deleteChat(chatsData, id), [chatsData]);

  const replaceOrAddChat2 = useCallback(
    (chat: IChat) => replaceOrAddChat(chatsData, Promise.resolve(null), chat),
    [chatsData]
  );

  const value = useMemo(() => {
    return {
      isLoading,
      chats: chatsData?.items || [],
      deleteChat: deleteChat2,
      replaceOrAddChat: replaceOrAddChat2
    };
  }, [chatsData?.items, isLoading, deleteChat2, replaceOrAddChat2]);

  return <ChatsContext.Provider value={value}>{children}</ChatsContext.Provider>;
}
