import NumbersField from 'components/inputs/NumbersField';
import { useTranslation } from 'react-i18next';
import { IFieldLinkProps, RouteFieldVisibility } from 'types';
import { getFluentIconNameByFieldType } from 'utils/helpers';
import { useTheme } from 'styled-components';
import { getTextFieldStyles } from './DynamicField.styles';

export interface IDynamicNumberFieldProps {
  disabled?: boolean;
  fieldLink: IFieldLinkProps;
  onChange: (value?: number | null) => void;
}

function DynamicNumberField({ fieldLink, disabled, onChange }: IDynamicNumberFieldProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  if (!fieldLink.field) return null;

  const { field, visibility } = fieldLink;
  const {
    name,
    description,
    descriptionPlacement,
    id,
    useThousandsSeparator,
    numDecimals = 0
  } = field;
  const defaultValue = fieldLink.value as string | number | undefined;

  const displayDescriptionTooltip = descriptionPlacement === 1;

  const readOnly = visibility === RouteFieldVisibility.ReadOnly;
  const required = visibility === RouteFieldVisibility.Required;

  const context = disabled ? 'disabled' : undefined;

  return (
    <NumbersField
      id={id}
      defaultValue={defaultValue}
      useThousandsSeparator={useThousandsSeparator}
      description={displayDescriptionTooltip ? description : undefined}
      disabled={disabled || readOnly}
      label={name}
      labelIconName={getFluentIconNameByFieldType(fieldLink.field)}
      maxDecimals={numDecimals}
      onChange={onChange}
      required={required}
      placeholder={t('dynamicField.numbersField.placeholder', { context })}
      styles={getTextFieldStyles(theme)}
    />
  );
}

export default DynamicNumberField;
