import { checkScreenWidth } from 'utils/helpers';
import {
  Panel as FluentPanel,
  IPanelProps as IFluentPanelProps,
  concatStyleSets
} from '@fluentui/react';
import { useTheme } from 'styled-components';

export interface IPanelProps extends IFluentPanelProps {
  hideCloseButton?: boolean;
}

function Panel(props: IPanelProps): JSX.Element | null {
  const { hideCloseButton, onRenderBody, styles } = props;
  const theme = useTheme();

  function renderBody() {
    if (!onRenderBody) return undefined;

    return () => (
      <div
        data-is-scrollable="true"
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          overflowY: 'auto'
        }}
      >
        {onRenderBody()}
      </div>
    );
  }

  function getPanelStyles() {
    let defaultStyles: IFluentPanelProps['styles'] = {
      main: {
        transition: 'all 0.3s ease-in-out',
        overflow: 'hidden',
        margin: checkScreenWidth(['extraSmall']) ? 0 : 10,
        borderRadius: checkScreenWidth(['extraSmall']) ? 0 : 8,
        height: 'auto !important'
      },
      scrollableContent: {
        overflow: 'hidden',
        height: '100%',
        display: 'contents'
      },
      navigation: {
        flexDirection: 'column-reverse',
        backgroundColor: `rgb(${theme.panelHeader.background})`,
        color: `rgb(${theme.panelHeader.foreground})`
      },
      commands: { paddingTop: 0, zIndex: '400 !important' },
      subComponentStyles: {
        closeButton: hideCloseButton
          ? { root: { display: 'none' } }
          : {
              root: {
                margin: checkScreenWidth(['extraSmall']) ? '5px 5px 5px auto' : '10px 5px 5px auto'
              },
              icon: {
                color: `rgb(${theme.panelHeader.foreground})`
              }
            }
      }
    };

    if (styles) {
      defaultStyles = concatStyleSets(defaultStyles, styles) as IFluentPanelProps['styles'];
    }

    return defaultStyles;
  }

  return (
    <FluentPanel
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      layerProps={{ eventBubblingEnabled: true }}
      onDismiss={(event) => {
        if (!event || (event && event.type !== 'mousedown')) {
          props?.onDismiss?.(event);
        }
      }}
      onRenderBody={onRenderBody ? renderBody() : undefined}
      styles={getPanelStyles()}
    />
  );
}

export default Panel;
