import styled from 'styled-components';
import ReactMarkdown, { Options } from 'react-markdown';
import { HTMLAttributes, useCallback, useContext } from 'react';
import { IMessageFragmentText } from '../../../services/ChatConnection/model';
import { REMARK_PLUGINS, REMARK_PLUGINS_WHILE_GENERATING, toHastHandlers } from './markdown';
import { ChatItemReferenceContext } from './ReferenceContext';

export const ChatMessageTextStyled = styled.div`
  font-size: 1rem;
  line-height: 1.5;
  overflow-wrap: break-word;

  &:not(.is-assistant-text) {
    white-space: pre-wrap;
  }

  ul,
  ol {
    padding-left: 2rem;

    :is(ul, ol) :is(ul, ol) {
      padding-left: 1rem;

      :is(ul, ol) :is(ul, ol) {
        padding-left: 0.5rem;
      }
    }
  }

  li {
    padding-left: 0.375rem;
    margin: 0.5rem 0;

    > p:has(+ :is(ul, ol)) {
      margin-bottom: 0;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 1em;
    margin-bottom: 0.25rem;
  }

  p {
    margin-bottom: 0.5rem;
  }

  p:last-child {
    margin-bottom: 0;
  }

  pre {
    white-space: pre-wrap;
  }
`;

const MessageFragmentTextStyled = styled(ChatMessageTextStyled)`
  .generating-caret {
    display: inline-block;
    width: 0.5ch;
    height: 1.2em;
    vertical-align: text-bottom;
    background: rgb(${({ theme }) => theme.aiChat.typingIndicator});
    border-radius: 0.25em;
    animation: chat-message-generating-caret-blink 1s infinite;
  }

  &.is-assistant-text *:not(.generating-caret) {
    animation: chat-message-fade-in 0.2s;
  }

  @keyframes chat-message-generating-caret-blink {
    50%,
    80% {
      opacity: 0;
    }
    40%,
    90% {
      opacity: 1;
    }
  }

  @keyframes chat-message-fade-in {
    from {
      opacity: 0;
    }
  }
`;

export default function ChatMessageFragmentText({
  isAssistant,
  fragment,
  appendCaret
}: {
  /** Marks this as originating from an assistant. */
  isAssistant: boolean;
  fragment: IMessageFragmentText;
  /** Appends a blinking caret at the end to indicate that the message is still being written. */
  appendCaret: boolean;
}) {
  if (isAssistant && !fragment.text && !appendCaret) return null;

  return (
    <MessageFragmentTextStyled
      className={`${isAssistant ? 'is-assistant-text' : ''} ${appendCaret ? 'has-caret' : ''}`}
    >
      {isAssistant ? (
        <ReactMarkdown
          className="chat-message-fragment-text"
          remarkPlugins={appendCaret ? REMARK_PLUGINS_WHILE_GENERATING : REMARK_PLUGINS}
          remarkRehypeOptions={REMARK_REHYPE_OPTIONS}
          components={REHYPE_COMPONENTS}
        >
          {fragment.text}
        </ReactMarkdown>
      ) : (
        fragment.text
      )}
    </MessageFragmentTextStyled>
  );
}

export const REMARK_REHYPE_OPTIONS: Options['remarkRehypeOptions'] = {
  handlers: toHastHandlers
};

export const REHYPE_COMPONENTS = {
  span: Span
};

function Span(props: HTMLAttributes<HTMLSpanElement>) {
  if (props['data-ec-reference'] && typeof props['data-url'] === 'string') {
    return <EcReference url={props['data-url']} isIncomplete={!!props['data-is-incomplete']} />;
  }

  return <span {...props} />;
}

const EcReferenceStyled = styled.span`
  display: inline-block;
  background: rgb(${({ theme }) => theme.aiChatTextReference.background});
  color: rgb(${({ theme }) => theme.aiChatTextReference.foreground});
  cursor: default;
  min-width: 1.2rem;
  text-align: center;
  border-radius: 0.5rem;
  font-size: smaller;
  font-weight: 600;
  vertical-align: super;
  transition: all 0.2s;

  &:hover {
    background: color-mix(
      in lch,
      rgb(${({ theme }) => theme.aiChatTextReference.foreground}) 30%,
      rgb(${({ theme }) => theme.aiChatTextReference.background}) 70%
    );
  }

  &.is-unexpected {
    color: rgb(${({ theme }) => theme.aiChatTextReference.unexpectedForeground});
  }
`;

function EcReference({ url, isIncomplete }: { url: string; isIncomplete: boolean }) {
  const { orderedSources, unexpectedSources, setHighlighted, clearHighlighted } =
    useContext(ChatItemReferenceContext);

  const index = orderedSources.findIndex((item) => item.url === url);
  const item = orderedSources[index];
  const isUnexpected = unexpectedSources.includes(item);

  const onPointerOver = useCallback(() => {
    setHighlighted(item);
  }, [setHighlighted, item]);
  const onPointerOut = useCallback(() => {
    clearHighlighted(item);
  }, [clearHighlighted, item]);

  if (isIncomplete) return null;

  return (
    <EcReferenceStyled
      className={isUnexpected ? 'is-unexpected' : ''}
      onPointerOver={onPointerOver}
      onPointerOut={onPointerOut}
    >
      {index + 1}
    </EcReferenceStyled>
  );
}
