import { createContext } from 'react';

export interface INavigationContext {
  /** Whether navigation is always visible or only visible as an overlay */
  isOverlay: boolean;
  /** If overlay: whether it's open */
  isOpen: boolean;
  /** If overlay: open navigation sidebar */
  open(): void;
  /** If overlay: close navigation sidebar */
  close(): void;
}

export const NavigationContext = createContext<INavigationContext>({
  isOverlay: false,
  isOpen: false,
  open() {
    throw new Error('not connected');
  },
  close() {
    throw new Error('not connected');
  }
});
