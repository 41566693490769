import { EmptyListPlaceHolder, InfoIconTooltip, ListCustom, RichTextEditor } from 'components';
import { useHeaderStylesOnScroll } from 'hooks';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { checkScreenWidth, formatDate, formatToLocalDateString } from 'utils/helpers';
import { ColumnActionsMode, IconButton, ProgressIndicator, SelectionMode } from '@fluentui/react';
import { getProjectStatusOptions, getProjectTypeOptions } from './ProjectConstants';

const NameStyled = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: pre-line;

  color: rgb(0, 120, 212);

  &:hover {
    color: rgb(0, 69, 120);
    cursor: pointer;

    .info-icon {
      visibility: visible;
    }
  }

  .info-icon {
    visibility: hidden;
    color: #605e5c;
    margin-left: 5px;
    padding-top: 3px;
    font-size: 12px;

    &:hover {
      visibility: visible;
    }
  }

  .nameAndInfo {
    display: flex;
    justify-content: space-between;
    white-space: pre-line;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

function ProjectsList({
  searchTerm,
  projects,
  listRef,
  getNameCellUrl,
  onOpenProjectPanel,
  onDeleteProject
}) {
  const { t } = useTranslation();
  const theme = useTheme();

  const projectTypes = getProjectTypeOptions(t);
  const projectStatuses = getProjectStatusOptions(t);

  const [filteredProjects, setFilteredProjects] = useState([]);

  // List columns
  const cols = [
    {
      key: 'intId',
      isMultiline: false,
      isFiltered: false,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      columnActionsMode: ColumnActionsMode.hasDropdown,
      maxWidth: 60,
      minWidth: 40,
      name: 'ID',
      onRender: (project) => `#${project.intId}`
    },
    {
      key: 'name',
      fieldName: 'name',
      isMultiline: true,
      isFiltered: false,
      isResizable: true,
      isSorted: false,
      isSortedDescending: null,
      columnActionsMode: ColumnActionsMode.hasDropdown,
      minWidth: checkScreenWidth(['extraSmall']) ? window.innerWidth - 35 : 200,
      maxWidth: checkScreenWidth(['extraSmall']) ? window.innerWidth - 35 : 350,
      name: t('projects.column.name'),
      onRender: (project) => {
        const infoDescriptionButton = (
          <InfoIconTooltip
            iconName="Info"
            iconClassName="info-icon"
            content={<RichTextEditor disabled defaultValue={project.description} />}
          />
        );
        return (
          <NameStyled>
            <Link className="nameAndInfo" to={getNameCellUrl(project)}>
              {project.name}
              {project.description ? infoDescriptionButton : null}
            </Link>
            <IconButton
              styles={{
                rootHovered: {
                  backgroundColor: `rgb(${theme.detailsList.moreButtonHoverBackground})`
                },
                root: {
                  height: '20px',
                  float: 'right'
                },
                menuIcon: {
                  fontSize: '14px',
                  fontWeight: '600'
                }
              }}
              className="c-details-list__more-button"
              menuIconProps={{ iconName: 'More' }}
              menuProps={{
                items: [
                  {
                    key: 'projectPanel',
                    text: t('projects.detailList.edit'),
                    iconProps: { iconName: 'SidePanelMirrored' },
                    className: 'checklist-more-subitem',
                    onClick: () => onOpenProjectPanel(project)
                  },
                  {
                    key: 'deleteProject',
                    disabled: !project.permissions?.delete,
                    text: t('projects.detailList.delete'),
                    iconProps: { iconName: 'Delete' },
                    className: 'checklist-more-subitem',
                    onClick: () => onDeleteProject(project)
                  }
                ]
              }}
            />
          </NameStyled>
        );
      }
    },
    {
      key: 'progress',
      isMultiline: false,
      isFiltered: false,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      columnActionsMode: ColumnActionsMode.hasDropdown,
      maxWidth: 100,
      minWidth: 100,
      name: t('projects.column.progress'),
      onRender: (project) => {
        return <ProgressIndicator barHeight={4} percentComplete={project.percentComplete || 0} />;
      }
    },
    {
      key: 'createdBy',
      isMultiline: false,
      isFiltered: false,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      columnActionsMode: ColumnActionsMode.hasDropdown,
      maxWidth: 150,
      minWidth: 120,
      name: t('routeInstances.column.createdBy'),
      onRender: (item) => item.creator?.name
    },
    {
      key: 'currentPhase',
      isFiltered: false,
      isResizable: true,
      isSorted: false,
      isSortedDescending: null,
      columnActionsMode: ColumnActionsMode.disabled,
      maxWidth: 200,
      minWidth: 100,
      name: t('projects.column.currentPhase.title'),
      onRender: (project) => {
        if (projectStatuses?.length > 0) {
          const status = projectStatuses.find((status) => status.key === project.status);

          if (project.plannedEndDate && status.key !== 1 && status.key !== 10) {
            const today = new Date(formatDate(new Date(), 'yyyy-MM-DD'));
            const endDate = new Date(formatDate(project.plannedEndDate, 'yyyy-MM-DD'));

            if (endDate < today) {
              return project.type === 2
                ? t('projects.column.currentPhase.finishedLastSprint')
                : t('projects.column.currentPhase.overdue');
            }
          }

          if (project.type === 2 && project.currentSprint) {
            return project.currentSprint.name;
          }

          if (status) {
            return status.text;
          }

          return '';
        }

        return '';
      }
    },
    {
      fieldName: 'team',
      key: 'team',
      isFiltered: false,
      isResizable: true,
      isSorted: false,
      isSortedDescending: null,
      columnActionsMode: ColumnActionsMode.hasDropdown,
      maxWidth: 250,
      minWidth: 100,
      name: t('projects.column.team'),
      onRender: (project) => (project.team ? project.team.displayTitle : '')
    },
    {
      fieldName: 'startDate',
      key: 'startDate',
      isPadded: true,
      isFiltered: false,
      isResizable: true,
      isSorted: false,
      isSortedDescending: null,
      columnActionsMode: ColumnActionsMode.hasDropdown,
      maxWidth: 80,
      minWidth: 80,
      name: t('projects.column.startDate'),
      onRender: (project) => (project.startDate ? formatToLocalDateString(project.startDate) : null)
    },
    {
      fieldName: 'plannedEndDate',
      key: 'plannedEndDate',
      isFiltered: false,
      isResizable: true,
      isSorted: false,
      isSortedDescending: null,
      columnActionsMode: ColumnActionsMode.hasDropdown,
      maxWidth: 80,
      minWidth: 80,
      name: t('projects.column.plannedEndDate'),
      onRender: (project) =>
        project.plannedEndDate ? formatToLocalDateString(project.plannedEndDate) : null
    },
    {
      key: 'type',
      fieldName: 'type',
      isMultiline: false,
      isFiltered: false,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      columnActionsMode: ColumnActionsMode.hasDropdown,
      maxWidth: 65,
      minWidth: 65,
      name: t('projects.column.type'),
      onRender: (project) => {
        if (projectTypes?.length > 0) {
          const type = projectTypes.find((type) => type.key === project.type);
          if (type) {
            return type.text;
          }

          return '';
        }

        return '';
      }
    }
  ];

  const [columns, setColumns] = useState(cols);

  useHeaderStylesOnScroll(listRef);

  const getFilterOptions = (_, column) => {
    if (projects && column) {
      const itemsToFilter = [...projects];
      // Set filter options for selected column
      const newFilterOptions = [];

      // filter by type options
      const setTypeFilterOption = (project) => {
        if (!newFilterOptions.find((filter) => filter.key === project.type)) {
          newFilterOptions.push({
            key: project.type,
            text: projectTypes.find((type) => type.key === project.type)?.text,
            checked: false
          });
        }

        return null;
      };

      // filter by team options
      const setTeamFilterOption = (project) => {
        if (!newFilterOptions.find((filter) => filter.key === project.team?.id)) {
          newFilterOptions.push({
            key: project.team?.id,
            text: project.team?.displayTitle,
            checked: false
          });
        }

        return null;
      };

      // filter by start date options
      const setStartDateFilterOption = (project) => {
        if (project.startDate) {
          const datePartOfString = project.startDate.substring(0, 10);

          if (!newFilterOptions.find((filter) => filter.key === datePartOfString)) {
            newFilterOptions.push({
              key: datePartOfString,
              text: formatToLocalDateString(project.startDate),
              checked: false
            });
          }
        }

        return null;
      };

      // filter by end date options
      const setPlannedEndDateFilterOption = (project) => {
        if (project.plannedEndDate) {
          const datePartOfString = project.plannedEndDate.substring(0, 10);

          if (!newFilterOptions.find((filter) => filter.key === datePartOfString)) {
            newFilterOptions.push({
              key: datePartOfString,
              text: formatToLocalDateString(project.plannedEndDate),
              checked: false
            });
          }
        }

        return null;
      };

      if (column?.key === 'type') {
        itemsToFilter.map(setTypeFilterOption);
      } else if (column?.key === 'team') {
        itemsToFilter.map(setTeamFilterOption);
      } else if (column?.key === 'startDate') {
        itemsToFilter.map(setStartDateFilterOption);
      } else if (column?.key === 'plannedEndDate') {
        itemsToFilter.map(setPlannedEndDateFilterOption);
      }

      return newFilterOptions;
    }

    return null;
  };

  function sortItems(itemsToSort, sortBy, sortDesc) {
    const copyAndSort = (items, columnKey, isSortedDescending) => {
      const sortedItems = items.slice(0).sort((a, b) => {
        if (columnKey === 'team') {
          const typeA = a[columnKey].displayTitle;
          const typeB = b[columnKey].displayTitle;

          return (
            isSortedDescending
              ? typeA.toLowerCase() < typeB.toLowerCase()
              : typeA.toLowerCase() > typeB.toLowerCase()
          )
            ? 1
            : -1;
        }

        if (columnKey === 'createdBy') {
          const typeA = a.creator?.name;
          const typeB = b.creator?.name;

          return (
            isSortedDescending
              ? typeA?.toLowerCase() < typeB?.toLowerCase()
              : typeA?.toLowerCase() > typeB?.toLowerCase()
          )
            ? 1
            : -1;
        }

        if (
          (columnKey === 'startDate' || columnKey === 'plannedEndDate') &&
          a[columnKey] &&
          b[columnKey]
        ) {
          const typeA = new Date(a[columnKey].substring(0, 10));
          const typeB = new Date(b[columnKey].substring(0, 10));

          return (isSortedDescending ? typeA < typeB : typeA > typeB) ? 1 : -1;
        }

        let valueA = a[columnKey];
        let valueB = b[columnKey];

        if (typeof a[columnKey] === 'string') {
          valueA = a[columnKey].toLowerCase();
        }

        if (typeof b[columnKey] === 'string') {
          valueB = b[columnKey].toLowerCase();
        }

        if (valueA && valueB) {
          return (isSortedDescending ? valueA < valueB : valueA > valueB) ? 1 : -1;
        }

        if (valueA && !valueB) {
          return isSortedDescending ? -1 : 1;
        }

        return isSortedDescending ? 1 : -1;
      });

      return sortedItems;
    };

    const sortedItems = sortBy ? copyAndSort(itemsToSort, sortBy, sortDesc) : itemsToSort;

    return sortedItems;
  }

  function filterItems(column, items) {
    let foundItems = [...items];

    const selectedFilters = column?.filterOptionsState;

    if (selectedFilters) {
      const filters = [];

      Object.keys(selectedFilters).map((key) => {
        if (selectedFilters[key] === true) {
          filters.push(key);
        }

        return null;
      });

      if (filters?.length) {
        let filteredProjects = [];

        filters.map((filterValue) => {
          const partOfFilteredProjects = foundItems.filter((project) => {
            if (column.fieldName === 'type') {
              return project.type.toString() === filterValue;
            }

            if (column.fieldName === 'team') {
              return project.team?.id === filterValue;
            }

            if (column.fieldName === 'startDate' && project.startDate) {
              return project.startDate.substring(0, 10) === filterValue;
            }

            if (column.fieldName === 'plannedEndDate' && project.plannedEndDate) {
              return project.plannedEndDate.substring(0, 10) === filterValue;
            }

            if (column.fieldName === 'status') {
              return project.status.toString() === filterValue;
            }

            return null;
          });

          filteredProjects = filteredProjects.concat(partOfFilteredProjects);

          return null;
        });

        foundItems = filteredProjects;
      }
    }

    const sortedColumn = columns.find((col) => col.isSorted);

    if (sortedColumn) {
      const sortedItems = sortItems(
        foundItems,
        sortedColumn.key,
        sortedColumn.isSortedDescending,
        true
      );

      return sortedItems;
    }

    return foundItems;
  }

  function onFilterItems(column, columns) {
    setColumns(columns);

    const filteredAndSortedItems = filterItems(column, projects);
    setFilteredProjects(filteredAndSortedItems);
  }

  function onSortItems(column, columns) {
    const sortedItems = sortItems(filteredProjects, column.key, column.isSortedDescending);

    setColumns(columns);
    setFilteredProjects(sortedItems);
  }

  function getProjects() {
    const selColumn = columns.find((column) => column.isFiltered);

    const newProjects = filterItems(selColumn, projects);

    return newProjects;
  }

  const filteredAndSortedProjects = getProjects();

  if (!filteredAndSortedProjects?.length) {
    return (
      <EmptyListPlaceHolder
        hidden={!!projects?.length}
        onCreateNew={!searchTerm ? onOpenProjectPanel : null}
        createNewText={t('projects.newProject')}
        noItemsText={searchTerm ? t('projects.emptySearchResults') : t('projects.placeHolder')}
        listIconName={searchTerm ? 'SearchIssue' : 'ViewDashboard'}
      />
    );
  }

  return (
    <ListCustom
      className="projects-list"
      items={filteredAndSortedProjects}
      getFilterOptions={getFilterOptions}
      selectionMode={SelectionMode.none}
      selectionPreservedOnEmptyClick
      enterModalSelectionOnTouch
      columns={columns}
      onSortItems={onSortItems}
      onFilterItems={onFilterItems}
    />
  );
}

ProjectsList.propTypes = {
  searchTerm: PropTypes.string,
  projects: PropTypes.arrayOf(PropTypes.object),
  listRef: PropTypes.object,
  getNameCellUrl: PropTypes.func.isRequired,
  onOpenProjectPanel: PropTypes.func.isRequired,
  onDeleteProject: PropTypes.func.isRequired
};

ProjectsList.defaultProps = {
  searchTerm: null,
  projects: null,
  listRef: null
};

export default ProjectsList;
