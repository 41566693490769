import stableHash from 'stable-hash';

/**
 * Some API objects, such as tasks, have multiple variants:
 * a small variant with limited data, and a full variant with all data.
 *
 * A higher-numbered variant is always a superset of any lower-numbered variant.
 */
export enum ApiObjectVariant {
  Small = 0,
  Full = 1
}

export type IApiObjectTypeId = string;

/** Identifies an API object. */
export interface IApiObjectKey<P> {
  /** Object type */
  type: IApiObjectType<P, IApiObjectData>;
  /** Object parameters as specified by the object type */
  params: P;
}

/** Identifies a variant of an API object. */
export interface IApiObjectVariantKey<P> extends IApiObjectKey<P> {
  /** The variant that we need. */
  variant: ApiObjectVariant;
  includes: string[];
}

export interface IApiObjectVariantKeyFull<P> extends IApiObjectVariantKey<P> {
  variant: ApiObjectVariant.Full;
  includes: never[];
}

export interface IApiObjectVariantKeySmall<P> extends IApiObjectVariantKey<P> {
  variant: ApiObjectVariant.Small;
  includes: string[];
}

/** Uniquely identifies an API object. derived from the IApiObjectKey */
export type IApiObjectId = string;
export type IApiObjectVariantId = string;

/** Helper function to construct an object key with working type inference */
export function makeApiObjectKey<T extends IApiObjectType<unknown, IApiObjectData>>(
  type: T,
  params: InferApiObjectTypeParams<T>
): IApiObjectKey<InferApiObjectTypeParams<T>> {
  return { type, params };
}

/** Helper function to construct an object variant key for a full object */
export function makeApiObjectVariantKey<T extends IApiObjectType<unknown, IApiObjectData>>(
  type: T,
  params: InferApiObjectTypeParams<T>,
  variant?: ApiObjectVariant.Full
): IApiObjectVariantKeyFull<InferApiObjectTypeParams<T>>;

export function makeApiObjectVariantKey<T extends IApiObjectType<unknown, IApiObjectData>>(
  type: T,
  params: InferApiObjectTypeParams<T>,
  variant: ApiObjectVariant.Small,
  includes?: string[]
): IApiObjectVariantKeySmall<InferApiObjectTypeParams<T>>;

export function makeApiObjectVariantKey<T extends IApiObjectType<unknown, IApiObjectData>>(
  type: T,
  params: InferApiObjectTypeParams<T>,
  variant: ApiObjectVariant,
  includes?: string[]
): IApiObjectVariantKey<InferApiObjectTypeParams<T>>;

/** Helper function to construct an object variant key with working type inference */
export function makeApiObjectVariantKey<T extends IApiObjectType<unknown, IApiObjectData>>(
  type: T,
  params: InferApiObjectTypeParams<T>,
  variant = ApiObjectVariant.Full,
  includes: string[] = []
): IApiObjectVariantKey<InferApiObjectTypeParams<T>> {
  return { type, params, variant, includes };
}

export function deriveApiObjectId(key: IApiObjectKey<unknown>) {
  return stableHash([key.type.id, key.params]);
}

export function deriveApiObjectVariantId(key: IApiObjectVariantKey<unknown>) {
  return stableHash([key.type.id, key.params, key.variant]);
}

/**
 * Encodes the path to a particular field inside an object.
 * For `{ a: { b: 2 } }`, a possible path might be `['a', 'b']`.
 *
 * To index an array, just use a `<number>.toString()`.
 */
export type ObjFieldPath = string[];
/** An ObjFieldPath encoded to a string. We can probably safely assume that no one would use slashes in field names */
export type EncObjFieldPath = string;
export type AnyObjFieldPath = ObjFieldPath | EncObjFieldPath;

export function encodeObjFieldPath(path: AnyObjFieldPath): EncObjFieldPath {
  if (typeof path === 'string') return path;
  return path.join('/');
}
export function decodeObjFieldPath(path: AnyObjFieldPath): ObjFieldPath {
  if (Array.isArray(path)) return path;
  return path.split('/');
}

/**
 * Symbol field on API objects that holds a reference to the cache it belongs to.
 * @see IApiObjectData
 */
export const CACHE = Symbol('cache');
/**
 * Symbol field on API objects that contains its key
 * @see IApiObjectData
 */
export const KEY = Symbol('key');
/**
 * Symbol field on API objects that contains its ID (must be equal to `deriveApiObjectId(obj[KEY])`)
 * @see IApiObjectData
 */
export const ID = Symbol('id');
/**
 * Symbol field on API objects that contains field refs
 * @see IApiObjectDataRefs
 * @see IApiObjectData
 */
export const REFS = Symbol('refs');

/**
 * Type of `REFS` on API objects.
 *
 * Maps an object path to the object at that path.
 */
export type IApiObjectDataRefs = Map<EncObjFieldPath, IApiObjectVariantKey<unknown>>;

/**
 * General shape of loaded API object data.
 *
 * These are special objects that can interact with the API data cache.
 */
export interface IApiObjectDataBase {
  /** The cache this object belongs to. */
  [CACHE]: IApiObjectCache;
  /** The ID key of this object. */
  [KEY]: IApiObjectVariantKey<unknown>;
  /** The ID of this object. Derived from the key */
  [ID]: IApiObjectVariantId;
  /** References to other objects in fields of this object. */
  [REFS]: IApiObjectDataRefs;
}

/** @see {IApiObjectDataBase} */
export interface IApiObjectData extends IApiObjectDataBase {
  [KEY]: IApiObjectVariantKeyFull<unknown>;
}

/** @see {IApiObjectDataBase} */
export interface IApiObjectDataSmall extends IApiObjectDataBase {
  /** This is *not* IApiObjectVariantKeySmall so that Full can still be assigned to Small */
  [KEY]: IApiObjectVariantKey<unknown>;
}

/** A derivative of an IApiObjectData type without the cache refs */
export type LocalApiObject<T extends IApiObjectDataBase> = Omit<
  T,
  typeof CACHE | typeof KEY | typeof ID | typeof REFS
>;

/** Removes all API object magic */
export function toLocalApiObject<T extends IApiObjectDataBase>(
  data: T,
  convertRefValue: (value: IApiObjectDataBase, path: ObjFieldPath) => unknown = (data) => data
): LocalApiObject<T> {
  // Object.entries uses only string keys, so this will remove all symbols
  const result = Object.fromEntries(Object.entries(data)) as LocalApiObject<T>;

  // resolve all refs
  for (const [pathStr, id] of data[REFS]) {
    const path = decodeObjFieldPath(pathStr);
    const parent = getFieldPathParent(result, path);

    const value = data[CACHE].getObject(id).get(id.variant);

    Object.defineProperty(parent, path.at(-1), {
      configurable: true,
      enumerable: true,
      writable: true,
      value: convertRefValue(value, path)
    });
  }

  return result;
}

/**
 * Creates an API object data value from a regular JSON value.
 *
 * This will
 * - make the result conform to the IApiObjectData interface with all symbol fields
 * - automatically create field refs according to object type
 * - *not* add it to the cache. Do it yourself if you need to
 */
export function createApiObjectDataFromPlainObject<T extends AnyApiObjectType>(
  cache: IApiObjectCache,
  key: IApiObjectVariantKey<InferApiObjectTypeParams<T>>,
  data: object
): InferApiObjectTypeDataFull<T> {
  const obj = {
    ...data,
    [CACHE]: cache,
    [KEY]: key,
    [ID]: deriveApiObjectVariantId(key),
    [REFS]: new Map()
  } as InferApiObjectTypeDataFull<T>;

  key.type.createRefs(cache, obj, key);

  return obj;
}

/** Similar to createApiObjectDataFromPlainObject, but uses includes. */
export function createSmallApiObjectDataFromPlainObject<T extends AnyApiObjectType>(
  cache: IApiObjectCache,
  key: IApiObjectVariantKeySmall<InferApiObjectTypeParams<T>>,
  data: object
): InferApiObjectTypeDataSmall<T> {
  const obj = {
    ...data,
    [CACHE]: cache,
    [KEY]: key as IApiObjectVariantKey<InferApiObjectTypeParams<T>>,
    [ID]: deriveApiObjectVariantId(key),
    [REFS]: new Map()
  } as InferApiObjectTypeDataSmall<T>;

  key.type.createRefs(cache, obj, key);

  return obj;
}

/**
 * Returns the parent object of a field path.
 *
 * For example, for `{ a: { b: 2 } }` and field path `['a', 'b']`, the parent object is `{ b: 2 }`.
 */
export function getFieldPathParent(obj: object, path: ObjFieldPath) {
  if (path.length === 1) {
    return obj;
  }
  return getFieldPathParent(obj[path[0]], path.slice(1));
}

export interface IApiObjectCache {
  /** The tenant we're loading data from. */
  tenantId: string;

  /** The member we're loading for. */
  memberId: string;

  /**
   * If a new subscription is added, we will not reload the data if this interval has not yet passed since the
   * last load.
   */
  objectLoadDedupIntervalSecs: number;

  /** Inserts object data */
  insertObjectData(data: IApiObjectDataBase): void;

  /** Returns an object */
  getObject<T extends AnyApiObjectType>(
    key: IApiObjectKey<InferApiObjectTypeParams<T>>
  ): ApiObject<T>;

  /**
   * Adds a dependency for an object.
   *
   * @param obj the object we are adding a dependency to
   * @param dep the dependency object we want data updates to be forwarded from
   *
   * The object itself does not need to be in the cache, but all dependencies do need to be in the cache for this
   * to make sense.
   */
  addObjectDependency(obj: AnyApiObject, dep: IApiObjectVariantId): void;
  removeObjectDependency(obj: AnyApiObject, dep: IApiObjectVariantId): void;

  /**
   * Returns all objects that depend on this variant.
   *
   * @see addObjectDependency
   */
  getVariantDependents(variant: IApiObjectVariantId): Set<AnyApiObject>;
}

/**
 * Replaces a plain value in the given API object with a field reference.
 * Returns the existing value as API object data, which you should probably insert into the cache.
 *
 * @param obj the API object
 * @param fieldPathParam path to the value we're replacing with a reference to another API object
 * @param id object ID this will be replaced with
 */
export function createApiObjectField<T extends IApiObjectType<unknown, IApiObjectData>>(
  obj: { [REFS]: IApiObjectDataRefs; [CACHE]: IApiObjectCache },
  fieldPathParam: AnyObjFieldPath,
  id: IApiObjectVariantKey<InferApiObjectTypeParams<T>>
): IApiObjectDataBase {
  const fieldPath = decodeObjFieldPath(fieldPathParam);
  const encFieldPath = encodeObjFieldPath(fieldPathParam);
  obj[REFS].set(encFieldPath, id);

  const parent = getFieldPathParent(obj, fieldPath);
  const existingValue = parent[fieldPath[fieldPath.length - 1]];

  Object.defineProperty(parent, fieldPath[fieldPath.length - 1], {
    configurable: true,
    enumerable: true,
    get() {
      const id = obj[REFS].get(encFieldPath) as IApiObjectVariantKey<InferApiObjectTypeParams<T>>;
      return obj[CACHE].getObject<T>(id).get(id.variant);
    },
    set(value: IApiObjectDataBase) {
      if (!value || value[CACHE] !== obj[CACHE] || value[KEY].type !== id.type) {
        throw new Error(
          `invalid value of different type (expected ${id.type.id}, got ${value?.[KEY]?.type})`
        );
      }
      obj[REFS].set(encFieldPath, value[KEY]);
    }
  });

  return createApiObjectDataFromPlainObject(obj[CACHE], id, existingValue);
}

/** Standard conversion from IApiObjectData to IApiObjectDataSmall */
export type AsSmallApiObjectData<T> = T extends IApiObjectData
  ? Partial<Omit<T, keyof IApiObjectData>> & IApiObjectDataSmall
  : never;

export interface ILoadObjectOptions {
  includes: string[];
}

/**
 * Defines how to deal with an API object type.
 */
export interface IApiObjectType<
  Params,
  Data extends IApiObjectData,
  // SmallData is used elsewhere
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  SmallData extends AsSmallApiObjectData<Data> = AsSmallApiObjectData<Data>
> {
  /** Object type ID. Must be unique across all object types */
  id: IApiObjectTypeId;
  /**
   * If set: overrides the global load deduplication interval
   *
   * @see IApiObjectCache.objectLoadDedupIntervalSecs
   */
  loadDedupIntervalSecs?: number;
  /** (re-)creates all refs in the data. */
  createRefs(
    cache: IApiObjectCache,
    data: Data | SmallData,
    key: IApiObjectVariantKey<Params>
  ): void;
  /** Fetches object data. Dependencies will be inserted into the cache. */
  load(
    cache: IApiObjectCache,
    params: Params,
    abort: AbortSignal,
    options: ILoadObjectOptions
  ): Promise<Data>;
}
export type AnyApiObjectType = IApiObjectType<
  unknown,
  IApiObjectData,
  AsSmallApiObjectData<IApiObjectData>
>;

export type InferApiObjectTypeParams<T> = T extends IApiObjectType<infer P, IApiObjectData>
  ? P
  : never;
export type InferApiObjectTypeDataFull<T> = T extends IApiObjectType<
  unknown,
  infer D,
  // we can't use `unknown` here...
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  infer S
>
  ? D
  : never;

export type InferApiObjectTypeDataSmall<T> = T extends IApiObjectType<
  unknown,
  IApiObjectData,
  infer S
>
  ? S
  : never;
export type InferApiObjectTypeData<T> =
  | InferApiObjectTypeDataFull<T>
  | InferApiObjectTypeDataSmall<T>;

/**
 * A data overlay applies a client-side mutation to existing data.
 *
 * The resulting partial will be merged into the existing object.
 */
export type IDataOverlay<T> = Partial<T> | ((data: T) => Partial<T>);

/** Represents a request to the API to mutate something on this object. */
export interface IPendingMutation<T> {
  /** Data overlay(s) that will be applied while the mutation is pending. */
  overlay: IDataOverlay<T>;
  /** A promise that will resolve when the mutation succeeds on the API side. It returns permanent mutations. */
  promise: Promise<IDataOverlay<T>>;
}

function assignCloneDeep(a: unknown, b: unknown) {
  if (b?.[KEY]) {
    // this is a ref!
    return b;
  }

  if (Array.isArray(a)) {
    return b;
  }

  if (typeof a === 'object' && a) {
    if (typeof b !== 'object' || !b) return b;

    const a2 = { ...a };
    for (const k of Object.keys(b)) {
      a2[k] = k in a ? assignCloneDeep(a[k], b[k]) : b[k];
    }
    return a2;
  }

  return b;
}

/** Merges a data overlay into base data and returns the result. */
export function applyDataOverlay<T extends IApiObjectDataBase>(
  obj: T,
  overlay: IDataOverlay<T>
): T {
  const partial = typeof overlay === 'function' ? overlay(obj) : overlay;

  if (!Object.keys(partial).length) return obj;

  const newObj = { ...obj };

  if (partial[KEY]) {
    const objId = deriveApiObjectVariantId(obj[KEY]);
    const partialId = deriveApiObjectVariantId(partial[KEY]);
    if (objId !== partialId) {
      throw new Error(`cannot apply data overlay because overlay has different ID`);
    }
  }

  if (partial[REFS]) {
    newObj[REFS] = new Map(newObj[REFS]);
    for (const [path, id] of partial[REFS]) {
      newObj[REFS].set(path, id);
    }
  }

  for (const field in partial) {
    if (typeof field === 'string') {
      newObj[field] = assignCloneDeep(newObj[field], partial[field]) as T[Extract<keyof T, string>];
    }
  }

  return newObj;
}

export enum ApiObjectEvent {
  /** Data was updated for any reason. */
  DataUpdated,
  /** Data will be loaded. */
  LoadStarted,
  /** Data loading was canceled. */
  LoadCanceled,
  /** Data was loaded. */
  LoadSucceeded,
  /** An error occurred while loading data. */
  LoadFailed,
  /** A mutation was started. */
  MutationStarted,
  /** A mutation request succeeded. */
  MutationSucceeded,
  /** A mutation request failed. */
  MutationFailed,
  /** A dependency object was changed. */
  DependencyUpdate
}

/** A subscriber will receive updates when an API object changes. */
export interface IApiObjectSubscriber<T extends AnyApiObjectType> {
  /** The variant that this subscriber needs. It may receive a greater variant, but not a lesser one. */
  variant: ApiObjectVariant;
  /** The includes that this subscriber needs. Implies Variant.Small! */
  includes: string[] | null;
  handle(object: ApiObject<T>, type: ApiObjectEvent): void;
}

export interface IPendingLoad {
  abort(): void;
}

export interface IObjectMutationOptions {
  /** If true, will reload fresh data after mutation succeeds. */
  revalidate?: boolean;
  /** If true, will reload fresh data after mutation fails. */
  revalidateError?: boolean;
}

export type AnyApiObject = ApiObject<AnyApiObjectType>;

interface VariantMap<F, S> extends Map<ApiObjectVariant, F | S> {
  get(k: ApiObjectVariant.Small): S;
  get(k: ApiObjectVariant.Full): F;
  get(k: ApiObjectVariant): F | S;
  set(k: ApiObjectVariant.Small, data: S): this;
  set(k: ApiObjectVariant.Full, data: F): this;
}

interface UnsafeVariantMap<F, S> extends VariantMap<F, S> {
  set(k: ApiObjectVariant, data: F | S): this;
}

type AsUnsafeVariantMap<T> = T extends VariantMap<infer F, infer S> ? UnsafeVariantMap<F, S> : T;

/**
 * Handles loading, caching, mutations, etc. for an API object.
 *
 * **NOTE**: this object requires manual memory management. You must call `.drop()` before it goes out of scope.
 */
export class ApiObject<T extends AnyApiObjectType> {
  key: IApiObjectKey<InferApiObjectTypeParams<T>>;

  id: IApiObjectId;

  /** The cache this object belongs to. This does not necessarily mean that this object is in the cache, however. */
  cache: IApiObjectCache;

  /** Base data loaded from the API. */
  private loadedData = new Map() as VariantMap<
    { value: InferApiObjectTypeDataFull<T>; time: number },
    { value: InferApiObjectTypeDataSmall<T>; includes: string[]; time: number }
  >;

  /** Data with overlays. */
  private currentData = new Map() as VariantMap<
    InferApiObjectTypeDataFull<T>,
    InferApiObjectTypeDataSmall<T>
  >;

  private pendingLoad?: IPendingLoad;

  lastReadTime: number;

  lastLoadError?: Error;

  /** Mutations that affect this API object but haven't been resolved yet. */
  private pendingMutations = new Set<IPendingMutation<InferApiObjectTypeData<T>>>();

  private subscribers = new Set<IApiObjectSubscriber<T>>();

  /** If true, data will be loaded automatically when a subscriber is added, with deduplication. */
  autoLoad = true;

  /** include key -> requesters */
  requestedIncludes = new Map<string, Set<object>>();

  constructor(cache: IApiObjectCache, key: IApiObjectKey<InferApiObjectTypeParams<T>>) {
    this.cache = cache;
    this.key = key;
    this.id = deriveApiObjectId(key);
    this.lastReadTime = Date.now();
  }

  get(variant: ApiObjectVariant.Full): InferApiObjectTypeDataFull<T> | undefined;

  get(
    variant: ApiObjectVariant,
    requiredIncludes?: string[]
  ): InferApiObjectTypeData<T> | undefined;

  /** Returns data for a (possibly greater) variant */
  get(variant: ApiObjectVariant, requiredIncludes?: string[]) {
    this.lastReadTime = Date.now();
    if (variant === ApiObjectVariant.Full) {
      return this.currentData.get(ApiObjectVariant.Full);
    }

    if (requiredIncludes) {
      const loaded = this.loadedData.get(ApiObjectVariant.Small);
      const isIncomplete = requiredIncludes.some((i) => !loaded?.includes?.includes(i));
      if (isIncomplete) return undefined;
    }

    return (
      this.currentData.get(ApiObjectVariant.Full) ?? this.currentData.get(ApiObjectVariant.Small)
    );
  }

  /** Returns data for the best loaded variant */
  getAny() {
    this.lastReadTime = Date.now();
    return (
      this.currentData.get(ApiObjectVariant.Full) ?? this.currentData.get(ApiObjectVariant.Small)
    );
  }

  get isLoading() {
    return !!this.pendingLoad;
  }

  get isMutating() {
    return !!this.pendingMutations.size;
  }

  /** Returns true if this object has no dependents and can be safely deleted. */
  get hasNoDependents() {
    return !this.subscribers.size && !this.pendingMutations.size;
  }

  /** Returns true if loaded data for the given variant (or any greater) is stale. */
  isLoadedDataStale(variant: ApiObjectVariant, includes: string[] | null, maxAgeSecs: number) {
    const now = Date.now();
    const loaded = this.loadedData.get(variant) || this.loadedData.get(ApiObjectVariant.Full);
    if (!loaded) return true;

    if (variant === ApiObjectVariant.Small && includes) {
      const loadedIncludes = (loaded as { includes: string[] | null }).includes ?? [];
      const isIncomplete = includes.some((i) => !loadedIncludes.includes(i));
      if (isIncomplete) return true;
    }

    return loaded.time < now - maxAgeSecs * 1000;
  }

  updateCurrentData() {
    this.currentData.clear();

    for (const [variant, { value: loadedData }] of this.loadedData) {
      let data = loadedData;

      for (const mut of this.pendingMutations) {
        if (!mut.overlay[KEY] || mut.overlay[KEY].variant === variant) {
          data = applyDataOverlay(data, mut.overlay);
        }
      }

      // we are setting the same variant we loaded data from, so this is safe
      (this.currentData as AsUnsafeVariantMap<typeof this.currentData>).set(variant, data);
    }

    this.updateDependencies();
  }

  /** Dispatches an event to all subscribers. */
  dispatchEvent(type: ApiObjectEvent) {
    for (const sub of this.subscribers) {
      sub.handle(this, type);
    }

    const shouldNotifyDependents =
      type === ApiObjectEvent.LoadSucceeded ||
      type === ApiObjectEvent.MutationStarted ||
      type === ApiObjectEvent.MutationSucceeded ||
      type === ApiObjectEvent.MutationFailed ||
      type === ApiObjectEvent.DependencyUpdate;

    if (shouldNotifyDependents) {
      for (const variant of this.currentData.keys()) {
        const variantId = deriveApiObjectVariantId(
          makeApiObjectVariantKey(this.key.type, this.key.params, variant)
        );
        for (const dep of this.cache.getVariantDependents(variantId)) {
          dep.updateCurrentData();
          dep.dispatchEvent(ApiObjectEvent.DependencyUpdate);
        }
      }
    }
  }

  cancelLoad() {
    if (this.pendingLoad) {
      this.pendingLoad.abort();
      this.dispatchEvent(ApiObjectEvent.LoadCanceled);
    }
  }

  beginLoad() {
    this.cancelLoad();

    const abortController = new AbortController();
    const pendingLoad = {
      abort() {
        if (this.pendingLoad === pendingLoad) this.pendingLoad = undefined;
        abortController.abort();
      }
    };
    this.pendingLoad = pendingLoad;
    this.lastLoadError = undefined;

    this.dispatchEvent(ApiObjectEvent.LoadStarted);

    this.key.type
      .load(this.cache, this.key.params, abortController.signal, {
        includes: [...this.requestedIncludes.keys()]
      })
      .then((result: InferApiObjectTypeDataFull<T>) => {
        if (this.pendingLoad !== pendingLoad) return;
        this.acceptLoaded(result);

        // load fn should have inserted data through the cache
        this.pendingLoad = undefined;
        this.lastLoadError = undefined;
        this.dispatchEvent(ApiObjectEvent.LoadSucceeded);
      })
      .catch((error) => {
        if (this.pendingLoad !== pendingLoad) return;
        // eslint-disable-next-line no-console
        console.error('object fetch error', error);
        this.pendingLoad = undefined;
        this.lastLoadError = error;
        this.dispatchEvent(ApiObjectEvent.LoadFailed);
      });
  }

  acceptLoaded(data: InferApiObjectTypeData<T>) {
    const { variant, includes } = data[KEY];

    // delete all lesser variants
    for (const k of this.loadedData.keys()) {
      if (k < variant) {
        this.loadedData.delete(k);
      }
    }

    if (variant === ApiObjectVariant.Small) {
      this.loadedData.set(variant, {
        value: data as InferApiObjectTypeDataSmall<T>,
        includes,
        time: Date.now()
      });
    } else {
      this.loadedData.set(variant, {
        value: data as InferApiObjectTypeDataFull<T>,
        time: Date.now()
      });
    }

    this.updateCurrentData();
    this.dispatchEvent(ApiObjectEvent.DataUpdated);
  }

  private scheduledAbort: ReturnType<typeof setTimeout> | null = null;

  update() {
    if (this.subscribers.size && !this.pendingLoad && this.loadedData === undefined) {
      // we have no data, but we have subscribers. load something
      if (this.autoLoad) {
        this.beginLoad();
      }
    }

    if (this.scheduledAbort) clearTimeout(this.scheduledAbort);
    if (!this.subscribers.size) {
      // no subscribers. cancel all requests
      this.scheduledAbort = setTimeout(() => {
        this.pendingLoad?.abort();
        this.pendingLoad = undefined;
        this.lastLoadError = undefined;
      }, 30);
    }
  }

  get loadDedupIntervalSecs() {
    return this.key.type.loadDedupIntervalSecs ?? this.cache.objectLoadDedupIntervalSecs;
  }

  addSubscriber(
    subscriber: IApiObjectSubscriber<T>,
    loadType: 'always' | 'revalidateStale' | 'initialLoadOnly' | 'none' = this.autoLoad
      ? 'revalidateStale'
      : 'none'
  ) {
    if (subscriber.includes && subscriber.variant !== ApiObjectVariant.Small) {
      throw new Error('invalid subscriber: Full with includes');
    }

    this.subscribers.add(subscriber);
    this.update();

    for (const key of subscriber.includes ?? []) {
      if (!this.requestedIncludes.has(key)) this.requestedIncludes.set(key, new Set());
      this.requestedIncludes.get(key).add(subscriber);
    }

    let shouldLoad = loadType === 'always';
    if (loadType === 'revalidateStale') {
      shouldLoad = this.isLoadedDataStale(
        subscriber.variant,
        subscriber.includes,
        this.loadDedupIntervalSecs
      );
    } else if (loadType === 'initialLoadOnly') {
      shouldLoad = this.get(subscriber.variant, subscriber.includes) === undefined;
    }

    if (shouldLoad) {
      this.beginLoad();
    }
  }

  removeSubscriber(subscriber: IApiObjectSubscriber<T>) {
    this.subscribers.delete(subscriber);

    for (const key of subscriber.includes ?? []) {
      this.requestedIncludes.get(key)?.delete(subscriber);
      if (!this.requestedIncludes.get(key)?.size) this.requestedIncludes.delete(key);
    }

    this.update();
  }

  mutate(
    promise: Promise<IDataOverlay<InferApiObjectTypeData<T>>>,
    overlay: IDataOverlay<InferApiObjectTypeData<T>>,
    options?: IObjectMutationOptions
  ): IPendingMutation<InferApiObjectTypeData<T>> {
    const pendingMutation: IPendingMutation<InferApiObjectTypeData<T>> = { overlay, promise };
    this.pendingMutations.add(pendingMutation);
    this.updateCurrentData();
    this.dispatchEvent(ApiObjectEvent.MutationStarted);

    promise
      .then((overlay) => {
        if (overlay[KEY]) {
          // apply change to only this specific variant
          const { variant } = overlay[KEY];
          if (this.loadedData.has(variant)) {
            // this is safe because we're assigning to the same data we loaded
            (this.loadedData.get(variant) as { value: InferApiObjectTypeData<T> }).value =
              applyDataOverlay<InferApiObjectTypeData<T>>(
                this.loadedData.get(variant).value,
                overlay
              );
          }
        } else {
          // apply changes to all variants
          for (const variant of this.loadedData.keys()) {
            this.loadedData.get(variant).value = applyDataOverlay<InferApiObjectTypeData<T>>(
              this.loadedData.get(variant).value,
              overlay
            );
          }
        }

        this.pendingMutations.delete(pendingMutation);
        this.updateCurrentData();
        this.dispatchEvent(ApiObjectEvent.MutationSucceeded);

        if (options?.revalidate) {
          this.beginLoad();
        }
      })
      .catch((err) => {
        this.pendingMutations.delete(pendingMutation);
        this.updateCurrentData();
        this.dispatchEvent(ApiObjectEvent.MutationFailed);

        if (options?.revalidateError) {
          this.beginLoad();
        }
        throw err; // we're only inspecting, not handling
      });

    return pendingMutation;
  }

  previousDependencies = new Set<IApiObjectVariantId>();

  updateDependencies() {
    const dependencies = new Set<IApiObjectVariantId>();
    for (const variant of this.currentData.values()) {
      for (const refKey of variant[REFS].values()) {
        const id = deriveApiObjectVariantId(refKey);
        dependencies.add(id);
      }
    }

    for (const dep of this.previousDependencies) {
      if (!dependencies.has(dep)) {
        this.cache.removeObjectDependency(this, dep);
      }
    }

    for (const dep of dependencies) {
      if (!this.previousDependencies.has(dep)) {
        this.cache.addObjectDependency(this, dep);
      }
    }

    this.previousDependencies = dependencies;
  }

  /** Drops this object. Must be called when this object is about to go out of scope. */
  drop() {
    this.currentData.clear();
    this.updateDependencies();
  }
}

/**
 * Mutates an object.
 *
 * More specifically, this will mutate the version of the object in the cache, which is probably, but not necessarily,
 * related to the object parameter here.
 *
 * @param object an object that represents the object in the cache that we want to mutate
 * @param promise a promise that resolves when the mutation completes with new data
 * @param overlay an overlay shown while the promise is pending
 */
export function mutate<T extends IApiObjectDataBase>(
  object: T,
  promise: Promise<IDataOverlay<T>>,
  overlay: IDataOverlay<T>,
  options?: IObjectMutationOptions
) {
  const obj = object[CACHE].getObject(object[KEY]);
  return obj.mutate(promise, overlay, options);
}

/**
 * Force-reloads the object in the cache.
 *
 * Works similarly to `mutate()` above.
 */
export function forceLoad<T extends IApiObjectDataBase>(object: T) {
  const obj = object[CACHE].getObject(object[KEY]);
  obj.beginLoad();
}
