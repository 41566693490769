import RichTextEditor, { IRichTextEditorProps } from 'components/inputs/RichTextEditor';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export interface IDescriptionProps {
  label?: string;
  disabled?: boolean;
  displayCmdBar?: boolean;
  borderless?: boolean;
  defaultValue?: IRichTextEditorProps['defaultValue'];
  placeholder?: string;
  onChange: (value: string | null) => void;
}

export const RteWrapper = styled.div<{ $disabled?: boolean; $displayCmdBar?: boolean }>`
  margin-top: 8px;

  .rte-focused,
  .rte-base {
    position: relative;
    min-height: 32px;
  }

  .c-rte-cmd-bar {
    visibility: ${(props) => (props.$displayCmdBar ? 'visible' : 'hidden')};
  }

  .public-DraftEditor-content {
    min-height: ${(props) => (props.$disabled ? '0px' : '60px')};
  }

  .rte-focused {
    background-color: none;

    &:after {
      border: ${(props) => `2px solid ${props.theme.themePrimary}`};
      border-radius: 3px;
      bottom: -2px;
      content: '';
      left: -1px;
      pointer-events: none;
      position: absolute;
      right: -1px;
      top: -1px;
    }
  }

  .rte-disabled {
    position: relative;
    min-height: 32px;
  }
`;

function Description({
  onChange,
  label,
  placeholder,
  borderless = false,
  defaultValue,
  displayCmdBar = true,
  disabled
}: IDescriptionProps): JSX.Element | null {
  const { t } = useTranslation();
  const [focused, setFocused] = useState<boolean>(false);

  function onFocus() {
    setFocused(true);
  }

  function onBlur() {
    setFocused(false);
  }

  function shouldDisplayCmdBar() {
    if (disabled) return false;

    return displayCmdBar;
  }

  function getBorderStyles() {
    if (borderless || disabled) {
      return undefined;
    }

    return '1px solid #605e5c';
  }

  if (disabled && defaultValue === '<p><br></p>') {
    return null;
  }

  return (
    <RteWrapper $disabled={disabled} $displayCmdBar={displayCmdBar || focused}>
      <RichTextEditor
        defaultValue={defaultValue}
        disabled={disabled}
        displayCmdBar={shouldDisplayCmdBar()}
        label={label}
        placeholder={placeholder || t('taskDetailsBody.description.placeholder')}
        styles={{
          editorWrapper: {
            border: getBorderStyles(),
            borderRadius: '3px',
            padding: 1
          }
        }}
        onBlur={onBlur}
        onChange={({ value }) => {
          let valueToProvide = value;

          if (valueToProvide === '<p><br></p>') {
            valueToProvide = null;
          }

          if (!valueToProvide) {
            valueToProvide = null;
          }

          if (onChange) {
            onChange(valueToProvide);
          }
        }}
        onFocus={onFocus}
        required={false}
      />
    </RteWrapper>
  );
}

export default Description;
