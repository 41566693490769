import styled from 'styled-components';
import breakpoints from 'utils/breakpoints';

const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: sticky;
  width: 100%;

  @media (min-width: ${breakpoints.extraSmallMax}px) {
    overflow-x: auto;
  }
`;

export default ContainerWrapper;
