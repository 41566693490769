import CmdBarOverflowMenu from 'components/inputs/CmdBarOverflowMenu/CmdBarOverflowMenu';
import LanguagePicker from 'components/inputs/LanguagePicker/LanguagePicker';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import fetchRequest, { apiErrorHandler } from 'services/api';
import styled from 'styled-components';
import { checkScreenWidth } from 'utils/helpers';
import {
  createApiObjectDataFromPlainObject,
  makeApiObjectVariantKey,
  PROCESS_FIELD_GROUP,
  useApiDataCache
} from 'hooks/api2';
import {
  CommandBar,
  DefaultButton,
  IconButton,
  Label,
  PrimaryButton,
  TextField,
  TooltipHost
} from '@fluentui/react';
import FieldLinksList from '../FieldLinksList';

// styles
const ViewPanelBodyContainer = styled.div`
  .view-form {
    display: flex;
    padding: 1rem;
  }

  .add-field-button {
    padding: 0;
    margin-top: 5px;
    background-color: white;

    .ms-Button-label {
      color: #5c5c5c;
      font-weight: 400;
    }
  }

  .add-field-button:hover {
    background-color: #f4f9fd;
  }

  .add-field-button-icon {
    color: #106ebe;
  }
`;

const ViewPanelBodyContent = styled.div`
  width: 100%;
`;

const TranslationInput = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 5px;
`;

const CommandBarStyled = styled(CommandBar)`
  top: 0px;
  position: ${!checkScreenWidth(['extraSmall']) ? 'sticky' : null};
  z-index: 100;

  .ms-FocusZone.ms-CommandBar {
    background-color: #f4f9fd;
  }

  .ms-Button {
    background-color: #f4f9fd;
  }

  .ms-Button:hover {
    background-color: #e5eff8;
  }
`;

const VerticalDivider = styled.div`
  width: 50px;
`;

function ViewPanelBody({
  view,
  onClosePanel,
  onDeleteView,
  onViewUpdate,
  changePanelSize,
  displayCmdBar = true
}) {
  const { t } = useTranslation();
  const cache = useApiDataCache();

  const isEdit = !!(view?.id && view?.id !== '00000000-0000-0000-0000-000000000000');

  const [newView, setNewView] = useState(() => (view ? { ...view } : null));
  const [newViewTranslated, setNewViewTranslated] = useState(null);
  const [newFields, setNewFields] = useState(null);

  const [newViewChanged, setNewViewChanged] = useState({});

  const [isSaving, setIsSaving] = useState(false);
  const [isCalloutVisible, setIsCalloutVisible] = useState(false);

  const [showLanguages, setShowLanguages] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(null);

  const translateView = useCallback((view, language) => {
    // translate content to display it in other language than the browser's
    let translatedView = {
      name: '',
      description: '',
      lang: language.key
    };

    if (view) {
      const translatedName =
        view?.allNames?.find((x) => x.language === language.key)?.text || view.name;
      const translatedDescription =
        view?.allDescriptions?.find((x) => x.language === language.key)?.text || view.description;

      translatedView = {
        ...view,
        name: translatedName,
        description: translatedDescription,
        lang: language.key
      };
    }

    return translatedView;
  }, []);

  useEffect(() => {
    changePanelSize(showLanguages ? '1650px' : '800px');
  }, [showLanguages, changePanelSize]);

  useEffect(() => {
    if (selectedLanguage && newViewTranslated?.lang !== selectedLanguage.key) {
      const translatedView = translateView(newView, selectedLanguage);

      setNewViewTranslated({ ...translatedView, lang: selectedLanguage.key });
    }
  }, [newView, selectedLanguage, newViewTranslated, translateView]);

  useEffect(() => {
    setNewFields(view?.fields?.length ? [...view.fields] : [{ field: {} }, { field: {} }]);
  }, [view]);

  function clearEmptyRuleConditions(fields) {
    if (fields?.length) {
      const newFields = fields.map((fieldLink) => {
        const newFieldLink = { ...fieldLink };

        if (fieldLink.ruleGroups?.length) {
          const newRuleGroups = [];

          fieldLink.ruleGroups.map((ruleGroup) => {
            const newRuleGroup = { ...ruleGroup };

            if (ruleGroup.rules?.length) {
              const newRules = [];

              ruleGroup.rules.map((ruleCondition) => {
                if (
                  ruleCondition.fieldId &&
                  (ruleCondition.compareValueTeam?.id ||
                    ruleCondition.compareValueUser?.userId ||
                    ruleCondition.compareValue ||
                    ruleCondition.compareValueId ||
                    ruleCondition.condition === 11 ||
                    ruleCondition.condition === 12) &&
                  ruleCondition.condition
                ) {
                  newRules.push(ruleCondition);
                }

                return null;
              });

              newRuleGroup.rules = newRules;
            }

            if (newRuleGroup?.rules?.length) {
              newRuleGroups.push(newRuleGroup);
            }

            return null;
          });

          newFieldLink.ruleGroups = newRuleGroups;
        }

        return newFieldLink;
      });

      setNewFields(newFields);
      return newFields;
    }

    return fields;
  }

  function saveView(closePanel) {
    const fieldsToSave = clearEmptyRuleConditions(newFields);

    const viewToPost = { ...newView, fields: fieldsToSave };

    const body = JSON.stringify(viewToPost);

    fetchRequest({ url: 'Route/FieldGroup', method: 'POST', body })
      .then((responseView) => {
        const obj = createApiObjectDataFromPlainObject(
          cache,
          makeApiObjectVariantKey(PROCESS_FIELD_GROUP, responseView.id),
          responseView
        );
        cache.insertObjectData(obj);

        onViewUpdate(obj);

        if (closePanel) {
          onClosePanel();
        } else {
          setIsSaving(false);
          setNewViewChanged({});
        }
      })
      .catch((error) => {
        setIsSaving(false);
        apiErrorHandler(error);
      });
  }

  function onCancelEdit() {
    onClosePanel();
  }

  function updateView(closePanel) {
    const fields = newFields.filter((fieldLink) => !!fieldLink.field?.id);

    if (fields?.length > 1) {
      const fieldsToUpdate = clearEmptyRuleConditions(fields);

      const viewToUpdate = { ...newView, fields: fieldsToUpdate };

      const body = JSON.stringify(viewToUpdate);

      fetchRequest({
        url: `Route/FieldGroup/${viewToUpdate.id}`,
        method: 'PUT',
        body
      })
        .then((responseView) => {
          cache.insertObjectData(
            createApiObjectDataFromPlainObject(
              cache,
              makeApiObjectVariantKey(PROCESS_FIELD_GROUP, responseView.id),
              responseView
            )
          );

          onViewUpdate(responseView);

          if (closePanel) {
            onClosePanel();
          } else {
            setIsSaving(false);
            setNewViewChanged({});
          }
        })
        .catch((error) => {
          setIsSaving(false);
          apiErrorHandler(error);
        });
    }
  }

  function handleName(value, translation) {
    const oldName = translation
      ? view?.allNames?.find((x) => x?.language === selectedLanguage?.key)?.text
      : view?.name;

    if (oldName !== value) {
      if (!newViewChanged?.name) {
        setNewViewChanged((prevState) => ({ ...prevState, name: true }));
      }
    } else if (newViewChanged?.name) {
      setNewViewChanged((prevState) => ({ ...prevState, name: false }));
    }

    if (translation) {
      const lang = selectedLanguage?.key;

      const translatedName = {
        language: lang,
        text: value
      };

      let newLang = true;

      let translatedNames = [];

      if (newView?.allNames?.length) {
        translatedNames = newView.allNames.map((name) => {
          if (name.language === lang) {
            newLang = false;

            return translatedName;
          }

          return name;
        });
      }

      if (newLang) {
        translatedNames.push(translatedName);
      }

      setNewView({ ...newView, allNames: translatedNames });
      setNewViewTranslated({ ...newViewTranslated, name: value });
    } else {
      setNewView({ ...newView, name: value });
    }
  }

  function handleDescription(value, translation) {
    const oldDescription = translation
      ? view?.allDescriptions?.find((x) => x?.language === selectedLanguage?.key)?.text
      : view?.description;

    if (oldDescription !== value) {
      if (!newViewChanged?.description) {
        setNewViewChanged((prevState) => ({ ...prevState, description: true }));
      }
    } else if (newViewChanged?.description) {
      setNewViewChanged((prevState) => ({ ...prevState, description: false }));
    }

    if (translation) {
      const lang = selectedLanguage.key;

      const translatedDescription = {
        language: lang,
        text: value
      };

      let newLang = true;

      let translatedDescriptions = [];

      if (newView?.allDescriptions?.length) {
        translatedDescriptions = newView.allDescriptions.map((description) => {
          if (description.language === lang) {
            newLang = false;

            return translatedDescription;
          }

          return description;
        });
      }

      if (newLang) {
        translatedDescriptions.push(translatedDescription);
      }

      setNewView({ ...newView, allDescriptions: translatedDescriptions });
      setNewViewTranslated({ ...newViewTranslated, description: value });
    } else {
      setNewView({ ...newView, description: value });
    }
  }

  function updateFields(fieldLinks, rulesChanged) {
    let changed = rulesChanged;

    if (!changed) {
      let changedProperty = false;

      let firstArray = view?.fields;
      let secondArray = fieldLinks;

      if (view?.fields?.length < fieldLinks?.length) {
        firstArray = fieldLinks;
        secondArray = view.fields;
      }

      for (let i = 0; i < firstArray?.length; i += 1) {
        let found = false;

        for (let j = 0; j < secondArray?.length; j += 1) {
          if (firstArray[i]?.field?.id === secondArray[j]?.field?.id) {
            found = true;

            if (i !== j) {
              changedProperty = true;
            }

            if (firstArray[i]?.visibility !== secondArray[j]?.visibility) {
              changedProperty = true;
            }

            break;
          }
        }

        if (!!firstArray[i]?.field?.id && (changedProperty || !found)) {
          changed = true;
          break;
        }
      }
    }

    if (changed) {
      if (!newViewChanged?.fields) {
        setNewViewChanged((prevState) => ({ ...prevState, fields: true }));
      }
    } else if (newViewChanged?.fields) {
      setNewViewChanged((prevState) => ({ ...prevState, fields: false }));
    }

    setNewFields(fieldLinks);
  }

  function isSaveButtonDisabled() {
    if (!newView?.name || !newFields?.length) {
      return true;
    }

    if (isSaving) {
      return true;
    }

    const fields = newFields.filter((fieldLink) => !!fieldLink?.field?.id);

    return fields?.length < 2;
  }

  function onSaveButtonClick(closePanel) {
    setIsSaving(true);

    if (isEdit) {
      updateView(closePanel);
    } else {
      saveView(closePanel);
    }
  }

  function getCmdBarCalloutMenuItems() {
    const cmdBarItems = [
      {
        index: 0,
        disabled: isSaveButtonDisabled(),
        key: 'saveAndClose',
        text: t('viewPanelBody.commandBar.button.saveAndClose'),
        iconProps: { iconName: 'SaveAndClose' },
        onClick: () => onSaveButtonClick(true)
      },
      {
        index: 1,
        disabled: isSaveButtonDisabled(),
        key: 'save',
        text: t('viewPanelBody.commandBar.button.save'),
        iconProps: { iconName: 'save' },
        onClick: () => onSaveButtonClick()
      }
    ];

    return cmdBarItems;
  }

  function showCmdMenuForMobiles() {
    if (!isCalloutVisible) {
      setTimeout(() => {
        setIsCalloutVisible(true);
      }, 60);
    } else {
      setIsCalloutVisible(false);
    }
  }

  function getCmdBarButtons() {
    let cmdBarItems = [];
    if (checkScreenWidth(['extraSmall'])) {
      cmdBarItems = [
        {
          key: 'more',
          ariaLabel: 'More',
          className: 'cmdMoreMenu',
          iconOnly: true,
          iconProps: { iconName: 'More' },
          onClick: (ev) => showCmdMenuForMobiles(ev)
        }
      ];
    } else {
      cmdBarItems = [
        {
          disabled: isSaveButtonDisabled(),
          key: 'saveAndClose',
          onClick: () => onSaveButtonClick(true),
          text: t('viewPanelBody.commandBar.button.saveAndClose'),
          iconProps: { iconName: 'SaveAndClose' },
          className: 'command-bar-item',
          split: true,
          subMenuProps: {
            items: [
              {
                key: 'save',
                className: 'command-bar-item',
                text: t('viewPanelBody.commandBar.button.save'),
                iconProps: {
                  iconName: 'save'
                },
                disabled: isSaveButtonDisabled(),
                onClick: () => onSaveButtonClick()
              }
            ]
          }
        }
      ];
    }

    return cmdBarItems;
  }

  const cmdBarItems = getCmdBarButtons();

  const cmdBarFarItems = [
    {
      key: 'delete',
      text: t('viewPanelBody.commandBar.button.delete'),
      iconProps: { iconName: 'delete' },
      className: !isEdit ? 'hideItem' : 'command-bar-item',
      onClick: () => {
        onDeleteView(newView);
      }
    },
    {
      key: 'cancel',
      className: 'command-bar-item',
      text: t('viewPanelBody.commandBar.button.cancel'),
      iconProps: { iconName: 'cancel' },
      onClick: onCancelEdit
    }
  ];

  const commandBar = (
    <CommandBarStyled
      className={`viewPanelBody-commandBar ${checkScreenWidth(['extraSmall']) ? null : 'sticky'}`}
      items={cmdBarItems}
      farItems={cmdBarFarItems}
    />
  );

  const translatedLanguages = (passedView) => {
    if (passedView?.allNames?.length) {
      const transLang = passedView.allNames.map((name) => {
        return name.language;
      });

      return transLang;
    }

    return null;
  };

  function getTranslationInput(translation) {
    return (
      <TranslationInput>
        {translation ? (
          <div style={{ display: 'flex' }}>
            <Label style={{ marginRight: '5px' }}>{t('viewPanelBody.label.language')}</Label>
            <LanguagePicker
              key={`lang-${newView?.allNames?.length || 0}`}
              disabled={!showLanguages}
              placeholder={t('viewPanelBody.placeholder.language')}
              onChange={setSelectedLanguage}
              styles={{
                root: {
                  width: '200px'
                }
              }}
              translatedLanguages={translatedLanguages(newView)}
              defaultLanguageKey={selectedLanguage?.key}
            />
          </div>
        ) : (
          <TooltipHost
            content={t('viewPanelBody.tooltip.translate')}
            calloutProps={{ gapSpace: 5, target: '#translate-button' }}
          >
            <IconButton
              id="translate-button"
              iconProps={{ iconName: 'DoubleChevronRight12' }}
              onClick={() => setShowLanguages(true)}
              disabled={showLanguages}
              ariaLabel="Translate"
            />
          </TooltipHost>
        )}
      </TranslationInput>
    );
  }

  function getPanelBodyContent(translation) {
    const getDefaultDescription = (translation, language) => {
      if (translation && language) {
        return newViewTranslated?.description;
      }

      if (!translation) {
        return newView?.description;
      }

      return '';
    };

    return (
      <>
        {translation ? <VerticalDivider /> : null}
        <ViewPanelBodyContent>
          {getTranslationInput(translation)}
          <TextField
            key={
              translation && selectedLanguage && newViewTranslated?.lang === selectedLanguage?.key
                ? `translated-name-${selectedLanguage?.key}`
                : 'name'
            }
            label={t('viewPanelBody.label.name')}
            defaultValue={translation ? newViewTranslated?.name : newView?.name}
            required
            onChange={(_, value) => handleName(value, translation)}
            autoFocus
            disabled={translation && !selectedLanguage}
          />
          <TextField
            key={
              translation && selectedLanguage && newViewTranslated?.lang === selectedLanguage?.key
                ? `translated-description-${selectedLanguage?.key}`
                : 'description'
            }
            label={t('viewPanelBody.label.description')}
            defaultValue={getDefaultDescription(translation, selectedLanguage)}
            multiline
            rows={checkScreenWidth(['extraSmall']) ? 2 : 4}
            onChange={(_, value) => handleDescription(value, translation)}
            disabled={translation && !selectedLanguage}
          />
          {newFields?.length && (!translation || selectedLanguage) ? (
            <FieldLinksList
              fieldLinks={newFields}
              updateFields={updateFields}
              disabled={translation}
              selectedLanguage={translation && selectedLanguage ? selectedLanguage : null}
            />
          ) : null}
        </ViewPanelBodyContent>
      </>
    );
  }

  return (
    <ViewPanelBodyContainer>
      {displayCmdBar && commandBar}
      {isCalloutVisible ? (
        <CmdBarOverflowMenu
          isCalloutVisible={isCalloutVisible}
          items={getCmdBarCalloutMenuItems()}
          onDismiss={() => setIsCalloutVisible(false)}
          disabled={isSaveButtonDisabled()}
        />
      ) : null}
      <div className="view-form">
        {getPanelBodyContent(false)}
        {showLanguages ? getPanelBodyContent(true) : null}
      </div>
      {!displayCmdBar && (
        <div style={{ display: 'flex' }}>
          <PrimaryButton
            styles={{ root: { marginLeft: 'auto', marginRight: '10px' } }}
            disabled={isSaveButtonDisabled()}
            onClick={onSaveButtonClick}
            text={t('createTag.dialog.save.button.primary')}
          />
          <DefaultButton
            onClick={onCancelEdit}
            text={t('createTag.dialog.cancel.button.default')}
          />
        </div>
      )}
    </ViewPanelBodyContainer>
  );
}

ViewPanelBody.propTypes = {
  view: PropTypes.object,
  onClosePanel: PropTypes.func.isRequired,
  onDeleteView: PropTypes.func.isRequired,
  onViewUpdate: PropTypes.func.isRequired,
  changePanelSize: PropTypes.func.isRequired,
  displayCmdBar: PropTypes.bool
};

ViewPanelBody.defaultProps = {
  displayCmdBar: true,
  view: null
};

export default ViewPanelBody;
