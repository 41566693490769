import { useContext, useMemo } from 'react';
import {
  IProcessField,
  IProcessFieldGroup,
  ISmallProcessTemplateForUser,
  PROCESS_FIELD_GROUP_VALIDATE_EDIT,
  PROCESS_FIELD_VALIDATE_EDIT,
  useApiObject
} from 'hooks/api2';
import { Dialog, Spinner, SpinnerSize } from '@fluentui/react';
import CustomDialog from 'components/surfaces/Dialog';
import { useTranslation } from 'react-i18next';
import FieldForm from '../../surfaces/FieldForm';
import FieldGroupDialog from '../../surfaces/FieldGroupDialog/FieldGroupDialog';
import FieldOwnerAndDefinitions from '../../surfaces/FieldForm/components/FieldOwnerAndDefinitions';
import { AppContext } from '../../../features/App';

export default function FieldEditDialog({
  open,
  onClose,
  isGroup,
  value,
  onChange
}: {
  open: boolean;
  onClose: () => void;
  /** Whether this is a field or field group */
  isGroup: boolean;
  /** Field or field group value */
  value: Partial<IProcessField> | Partial<IProcessFieldGroup>;
  onChange: (value: IProcessField | IProcessFieldGroup) => void;
}) {
  const {
    globalAppState: { currentUser }
  } = useContext(AppContext);

  const { data: editingCharacteristics, isLoading } = useApiObject(
    isGroup ? PROCESS_FIELD_GROUP_VALIDATE_EDIT : PROCESS_FIELD_VALIDATE_EDIT,
    // only check this when we actually need it
    open && value?.id ? value.id : null
  );

  const canEdit = useMemo(() => {
    if (!editingCharacteristics) return false;

    if ('owner' in editingCharacteristics) {
      // field owner can always edit
      if (editingCharacteristics.owner.userId === currentUser.userId) return true;
    }

    return editingCharacteristics.involvedDefinitions.every((definition) => definition.userCanEdit);
  }, [currentUser, editingCharacteristics]);

  if (!open) return null;

  if (isLoading) {
    return (
      <Dialog
        hidden={false}
        onDismiss={onClose}
        dialogContentProps={{
          title: 'a',
          styles: {
            header: { display: 'none' },
            inner: { paddingTop: '24px' }
          }
        }}
      >
        <Spinner size={SpinnerSize.large} />
      </Dialog>
    );
  }
  if (value?.id && !editingCharacteristics) {
    return null;
  }

  if (value?.id && !canEdit) {
    return <FieldNoPermissionsDialog isGroup={isGroup} id={value.id} onClose={onClose} />;
  }
  if (isGroup) {
    return (
      <FieldGroupDialog fieldGroup={value} hidden={!open} onCancel={onClose} onConfirm={onChange} />
    );
  }

  return (
    <FieldForm
      isOpen={open}
      onClose={onClose}
      defaultValue={value}
      surfaceType="modal"
      onSave={onChange}
    />
  );
}

const FILTER_PROCESS_DEF_UNEDITABLE_ONLY = (def: ISmallProcessTemplateForUser) => !def.userCanEdit;

function FieldNoPermissionsDialog({
  isGroup,
  id,
  onClose
}: {
  isGroup: boolean;
  id: string;
  onClose: () => void;
}) {
  const { t } = useTranslation();

  return (
    <CustomDialog
      hidden={false}
      defaultButtonProps={{
        text: t('formbuilder.fieldPicker.noEditPermission.close'),
        onClick: onClose
      }}
      content={
        <div style={{ marginTop: 10 }}>
          {t('formbuilder.fieldPicker.noEditPermission.content')}
          <FieldOwnerAndDefinitions
            isGroup={isGroup}
            id={id}
            processDefFilter={FILTER_PROCESS_DEF_UNEDITABLE_ONLY}
            expanded
          />
        </div>
      }
      styles={{
        root: {},
        main: {
          '@media (min-width: 850px)': {
            width: '100%',
            minWidth: 600,
            minHeight: 200
          }
        }
      }}
      title={t('formbuilder.fieldPicker.noEditPermission.label')}
      onDismiss={onClose}
    />
  );
}
