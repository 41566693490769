import Label, { ILabelProps } from 'components/inputs/Label';
import RichTextEditor from 'components/inputs/RichTextEditor';
import { useState } from 'react';
import { checkScreenWidth } from 'utils/helpers';
import { Checkbox, DefaultButton, IButtonStyles, concatStyleSets } from '@fluentui/react';
import { BooleanFieldContainer } from './BooleanField.styles';

export interface IBooleanFieldProps {
  id?: string;
  /**
   *
   * @defaultvalue 1
   */
  confirmationFormat?: ConfirmationFormatType;
  /**
   * Defaultvalue for the smalltextfield
   * @defaultvalue null
   */
  defaultValue?: IBooleanFieldValue['value'];
  /**
   * Description for the booleanField
   */
  description?: string;
  /**
   * A label for the booleanField
   */
  label?: string;
  /**
   * The icon that will be displayed to the left of the label
   */
  labelIconName?: string;
  /**
   * Callback issued when the state changes.
   */
  onChange?: (value?: IBooleanFieldValue['value']) => void;
  /**
   * Optional flag to display only the result
   * @defaultvalue false
   */
  displayResultOnly?: boolean;
  /**
   * Optional flag to display only the checkbox
   * @defaultvalue false
   */
  displayCheckBoxOnly?: boolean;
  /**
   * Optional flag to mark booleanField as readOnly / disabled
   * @defaultvalue false
   */
  disabled?: boolean;
  /**
   * Whether the associated form field is required or not
   * @defaultvalue false
   */
  required?: boolean;
  /**
   * The Label for the positive button
   * @defaultvalue "OK"
   */
  textOk?: string;
  /**
   * The Label for the negative button
   * @defaultvalue "NOK"
   */
  textNOk?: string;
  /**
   * Call to provide customized styling that will layer on top of the variant rules.
   */
  styles?: IBooleanFieldStyles;
}

interface IBooleanFieldStyles {
  label?: ILabelProps['styles'];
  checkboxWrapper?: React.CSSProperties;
  positiveButton?: IButtonStyles;
  negativeButton?: IButtonStyles;
}

enum ConfirmationFormatType {
  twoButtons = 1,
  checkbox = 2
}

export interface IBooleanFieldValue {
  value: boolean | null | undefined;
}

function BooleanField({
  id,
  confirmationFormat = 1,
  defaultValue = null,
  description,
  disabled = false,
  displayResultOnly = false,
  displayCheckBoxOnly = false,
  label,
  labelIconName,
  onChange,
  required = false,
  textNOk = 'NOK',
  textOk = 'OK',
  styles
}: IBooleanFieldProps) {
  const [checked, setChecked] = useState<IBooleanFieldValue['value']>(defaultValue);
  const displayPositiveButton =
    !displayResultOnly || (displayResultOnly && disabled && checked === true);

  const displayNegativeButton =
    !displayResultOnly || (displayResultOnly && disabled && checked === false);
  const positiveGreenColors = {
    color: '#2e7f48',
    borderColor: '#2e7f48 !important',
    backgroundColor: '#a7cab4'
  };

  const negativeRedColors = {
    color: '#a4262c',
    borderColor: '#a4262c',
    backgroundColor: '#f4abb1'
  };

  let positiveStyles: IButtonStyles = {
    root: {
      overflow: 'hidden',
      transition: 'background-color 0.3s ease-out',
      marginRight: displayNegativeButton ? 10 : 0,
      borderRadius: 4,
      width: '100%'
    },
    rootChecked: {
      ...positiveGreenColors,
      marginRight: displayNegativeButton ? 10 : 0,
      borderRadius: 4
    },
    rootDisabled: { borderColor: '#a19f9d', borderRadius: 4 },
    rootCheckedDisabled: positiveGreenColors,
    rootCheckedHovered: checkScreenWidth(['extraSmall'])
      ? {
          ...positiveGreenColors,
          marginRight: displayNegativeButton ? 10 : 0,
          borderRadius: 4
        }
      : { color: positiveGreenColors.color },
    rootHovered: positiveGreenColors,
    textContainer: {
      overflow: 'hidden'
    },
    label: {
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  };

  let negativeStyles: IButtonStyles = {
    root: {
      overflow: 'hidden',
      transition: 'background-color 0.3s ease-out',
      borderRadius: 4,
      width: '100%'
    },
    rootChecked: { ...negativeRedColors, borderRadius: 4 },
    rootDisabled: { borderColor: '#a19f9d', borderRadius: 4 },
    rootCheckedDisabled: negativeRedColors,
    rootCheckedHovered: checkScreenWidth(['extraSmall'])
      ? { ...negativeRedColors, borderRadius: 4 }
      : { color: negativeRedColors.color },
    rootHovered: negativeRedColors,
    textContainer: {
      overflow: 'hidden'
    },
    label: {
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  };

  if (styles?.positiveButton) {
    positiveStyles = concatStyleSets(positiveStyles, styles.positiveButton);
  }

  if (styles?.negativeButton) {
    negativeStyles = concatStyleSets(negativeStyles, styles.negativeButton);
  }

  function onButtonClick(buttonType: boolean) {
    let newCheckedValue: IBooleanFieldValue['value'] = buttonType;

    if (checked === buttonType) {
      newCheckedValue = null;
    }

    setChecked(newCheckedValue);

    if (onChange) {
      onChange(newCheckedValue);
    }
  }

  if (confirmationFormat === ConfirmationFormatType.checkbox) {
    return (
      <BooleanFieldContainer id={id}>
        <Label
          description={description}
          iconName={labelIconName}
          label={label}
          required={required}
          styles={styles?.label}
        />
        <div style={styles?.checkboxWrapper} className="c-boolean-field-checkbox-wrapper">
          <Checkbox
            label={textOk}
            checked={checked === true}
            disabled={disabled}
            onRenderLabel={() => {
              if (displayCheckBoxOnly) return <div />;

              return (
                <div style={{ maxWidth: '100%', marginLeft: 5, minWidth: 0 }}>
                  <RichTextEditor defaultValue={textOk} disabled />
                </div>
              );
            }}
            styles={{
              label: { maxWidth: '100%' },
              root: { marginTop: 3 },
              text: { overflow: 'hidden' },
              checkbox: { borderRadius: 3 }
            }}
            onChange={(_, checked) => {
              setChecked(checked);

              if (onChange) {
                onChange(checked ? true : null);
              }
            }}
          />
        </div>
      </BooleanFieldContainer>
    );
  }

  return (
    <BooleanFieldContainer id={id}>
      <Label
        description={description}
        iconName={labelIconName}
        label={label}
        required={required}
        styles={styles?.label}
      />
      <div className="c-boolean-field-buttons-wrapper" style={{ display: 'flex', width: '100%' }}>
        {displayPositiveButton && (
          <DefaultButton
            toggle
            allowDisabledFocus
            checked={checked === true}
            disabled={disabled}
            onClick={() => onButtonClick(true)}
            styles={positiveStyles}
            text={textOk}
            title={textOk}
          />
        )}
        {displayNegativeButton && (
          <DefaultButton
            toggle
            allowDisabledFocus
            checked={checked === false}
            disabled={disabled}
            onClick={() => onButtonClick(false)}
            styles={negativeStyles}
            text={textNOk}
            title={textNOk}
          />
        )}
      </div>
    </BooleanFieldContainer>
  );
}

export default BooleanField;
