import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { apiErrorHandler } from 'services/api';
import { getExternalDataAPI } from 'services/fetchRequests';
import {
  Checkbox,
  Dropdown,
  IComboBoxOption,
  IDropdownOption,
  MessageBar,
  MessageBarType,
  Shimmer,
  ShimmerElementType,
  Stack
} from '@fluentui/react';
import { IFormProps } from './FieldForm';

function ExternalsServiceSettings({ field, setField, disabled }: IFormProps) {
  const { t } = useTranslation();

  const [externalServices, setExternalServices] = useState<IComboBoxOption[]>();
  const [externalDataInitialized, setExternalDataInitialized] = useState(false);

  function onDataSelectModeChange(value: IDropdownOption<unknown> | undefined) {
    setField((prevState) => ({ ...prevState, dataSelectMode: Number(value?.key) }));
  }

  const getExternalData = useCallback(() => {
    getExternalDataAPI({ pageIndex: 0, itemsPerPage: 200, status: 1, type: 5, onlyEditable: false })
      .then((services) => {
        const flatServices: IComboBoxOption[] = services.items.map((service) => ({
          key: service.id || '',
          text: service.name || ''
        }));

        setExternalServices(flatServices);
      })
      .catch(apiErrorHandler);
  }, []);

  useEffect(() => {
    if (!externalDataInitialized) {
      setExternalDataInitialized(true);

      getExternalData();
    }
  }, [field, externalDataInitialized, getExternalData]);

  function handleExternalServiceId(option: IDropdownOption<unknown> | undefined) {
    if (!option) return;

    setField((prevState) => ({ ...prevState, externalServiceId: option?.key.toString() }));
  }

  function getExternalDataDropDown() {
    if (!externalServices) {
      return (
        <div style={{ marginTop: '29px' }}>
          <Shimmer shimmerElements={[{ type: ShimmerElementType.line, height: 32 }]} />
        </div>
      );
    }

    if (externalServices?.length) {
      return (
        <Dropdown
          key={`extServId-${field?.fieldType}-${field?.externalServiceId}`}
          required
          placeholder={t('createField.dialog.externalData.dropdown.placeholder')}
          label={t('createField.dialog.externalData.dropdown.label')}
          defaultSelectedKey={field?.externalServiceId || null}
          onChange={(_, value) => handleExternalServiceId(value)}
          options={externalServices}
          disabled={disabled}
        />
      );
    }

    return (
      <MessageBar
        styles={{ root: { marginTop: '10px' } }}
        messageBarType={MessageBarType.warning}
        isMultiline={false}
      >
        {t('createField.dialog.externalData.warning')}
      </MessageBar>
    );
  }

  function allowScanOption() {
    setField({ ...field, allowScan: !field.allowScan });
  }

  return (
    <div>
      {getExternalDataDropDown()}
      <Stack horizontal tokens={{ childrenGap: 10 }} styles={{ root: { alignItems: 'end' } }}>
        <Dropdown
          label={t('createField.dialog.dataSelectMode')}
          selectedKey={field?.dataSelectMode || 1}
          styles={{ root: { width: '100%' } }}
          onChange={(_, value) => onDataSelectModeChange(value)}
          options={[
            { key: 1, text: t('createField.dialog.externalData.select.list') },
            { key: 3, text: t('createField.dialog.externalData.select.new') }
          ]}
          disabled={disabled}
        />
        <Checkbox
          label={t('createField.dialog.allowScanOption.checkbox.label')}
          checked={field?.allowScan}
          styles={{ root: { minWidth: '205px', marginBottom: '6px' } }}
          onChange={allowScanOption}
          disabled={disabled}
        />
      </Stack>
    </div>
  );
}

export default ExternalsServiceSettings;
