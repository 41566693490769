import styled from 'styled-components';

const ReminderFormContainerStyled = styled.div`
  .c-reminder-form_text-row {
    display: flex;
    align-items: center;
    margin: 5px;
    font-size: 15px;
  }

  .c-reminder-form_header {
    margin-bottom: 3px;
    margin-top: 15px;
  }

  .c-reminder-form_section,
  .c-reminder-form_section-disabled,
  .c-reminder-form_section-active {
    padding: 10px;
    border-radius: 5px;
    position: relative;
    transition-property: background, border, border-color;
    transition-duration: 200ms;
  }

  .c-reminder-form_section {
    background: rgb(
      ${({ theme }) => theme.processDesignerComponents.reminderFormSectionBackground}
    );
    border: 1px solid
      rgb(${({ theme }) => theme.processDesignerComponents.reminderFormSectionOutline});
  }

  .c-reminder-form_section-disabled {
    background: rgb(
      ${({ theme }) => theme.processDesignerComponents.reminderFormSectionDisabledBackground}
    );
    border: 1px solid
      rgb(${({ theme }) => theme.processDesignerComponents.reminderFormSectionDisabledOutline});
  }

  .c-reminder-form_section-active {
    background: rgb(
      ${({ theme }) => theme.processDesignerComponents.reminderFormSectionActiveBackground}
    );
    border: 1px solid
      rgb(${({ theme }) => theme.processDesignerComponents.reminderFormSectionActiveOutline});
  }
`;

export default ReminderFormContainerStyled;
