import styled from 'styled-components';
import ActivitiesListInfiniteScroll from '../../../lists/ActivitiesList/ActivitiesListInfiniteScroll';

// we need the infinite scroll element to be the scrollable element
const ActivitiesStyled = styled.div`
  display: grid;
  height: 100%;

  > * {
    grid-area: 1 / 1;
  }
`;

export default function TeamActivities({ teamId }: { teamId: string }) {
  return (
    <ActivitiesStyled>
      <ActivitiesListInfiniteScroll containerContext="team" params={{ teamId }} />
    </ActivitiesStyled>
  );
}
