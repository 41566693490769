import { DialogCustom } from 'components/surfaces/Dialog';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { filePropType } from 'types';

function ConfirmReplaceFileDialog({ duplicateFiles, onCancel, onConfirm }) {
  const { t } = useTranslation();

  if (duplicateFiles.length > 0) {
    const fileNames = duplicateFiles.map((file) => ` ${file.name}`);
    return (
      <DialogCustom
        title={t('dialog.attachments.duplicate.title')}
        subText={
          fileNames.length > 1
            ? t('dialog.attachments.duplicate.1.subText', { fileName: fileNames })
            : t('dialog.attachments.duplicate.multiple.subText', { fileName: fileNames })
        }
        defaultButtonLabel={t('dialog.attachments.duplicate.button.default')}
        primaryButtonLabel={t('dialog.attachments.duplicate.button.primary')}
        primaryButtonFunc={onConfirm}
        defaultButtonFunc={onCancel}
        onDismiss={onCancel}
        hidden={false}
      />
    );
  }
  return null;
}

ConfirmReplaceFileDialog.propTypes = {
  duplicateFiles: PropTypes.arrayOf(filePropType),
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

ConfirmReplaceFileDialog.defaultProps = {
  duplicateFiles: []
};

export default ConfirmReplaceFileDialog;
