import Label from 'components/inputs/Label';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { checkScreenWidth } from 'utils/helpers';
import {
  ComboBox,
  DatePicker,
  IComboBox,
  IComboBoxOption,
  IComboBoxProps,
  IDatePicker,
  IDatePickerProps,
  ILabelProps,
  ITextFieldProps,
  concatStyleSets,
  IDatePickerStrings,
  ICalendarProps,
  compareDates
} from '@fluentui/react';
import DateTimeStyled from './DateTime.styles';

export interface IDateTimeProps {
  id?: string;
  /**
   * Allow the selection of a date in the past. Is ignored if minDate is set.
   * @defaultvalue false
   */
  allowPastDate?: boolean;
  /**
   * Selected date
   * @defaultvalue false
   */
  defaultValue?: Date | string;
  /**
   * Description of the DatePicker.
   */
  description?: string;
  /**
   * Disabled state of the DatePicker.
   * @defaultvalue false
   */
  disabled?: IDatePickerProps['disabled'];
  /**
   * Disabled state of the DatePicker.
   * @defaultvalue false
   */
  displayTime?: boolean;
  /**
   * Whether the datePicker is a required field or not
   * @defaultvalue false
   */
  required?: IDatePickerProps['isRequired'];
  /**
   * Label for the datePicker
   */
  label?: string;
  /**
   * The icon that will be displayed to the left of the label
   */
  labelIconName?: string;
  /**
   * The icon that will be displayed to the left of the time label
   */
  timeLabelIconName?: string;
  /**
   * The maximum allowable date.
   */
  maxDate?: IDatePickerProps['maxDate'];
  /**
   * The minimum allowable date.
   */
  minDate?: IDatePickerProps['minDate'];
  /**
   * Callback issued when a date is selected
   */
  onSelectDate?: IDatePickerProps['onSelectDate'];
  /**
   * Placeholder text for the DatePicker
   */
  placeholder?: IDatePickerProps['placeholder'];
  /**
   * Call to provide customized styling that will layer on top of the variant rules.
   */
  styles?: DateTimeStyles;
  /**
   * Label for the time field
   */
  timeLabel?: IComboBoxProps['label'];
  /**
   * Value to be used as a controlled component
   */
  value?: Date | string;
  /** If true, will disallow selecting weekends */
  weekdaysOnly?: boolean;
  /** If true, will show a visual indicator when the value is changed while the field is not focused. */
  highlightNonUserChanges?: boolean;
}

interface DateTimeStyles {
  datePickerStyles?: IDatePickerProps['styles'];
  timePickerStyles?: IComboBoxProps['styles'];
  containerStyles?: React.CSSProperties;
  labelStyles?: ILabelProps['styles'];
  textFieldStyles?: ITextFieldProps['styles'];
}

interface DateTimeModelProps {
  selectedDate?: Date;
  selectedTime: string;
  timeOptions: IComboBoxOption[];
}

function DateTime({
  id,
  allowPastDate = false,
  defaultValue,
  description,
  disabled = false,
  displayTime = false,
  label,
  labelIconName,
  maxDate,
  minDate,
  onSelectDate,
  placeholder,
  required = false,
  styles,
  timeLabel,
  timeLabelIconName,
  value,
  weekdaysOnly,
  highlightNonUserChanges
}: IDateTimeProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  const defaultTimeOptions = getTimeOptions();

  const datePickerRef = useRef<IDatePicker>(null);

  const [initialized, setInitialized] = useState(false);

  const [dateTimeModel, setDateTimeModel] = useState<DateTimeModelProps>({
    selectedDate: undefined,
    selectedTime: '00:00',
    timeOptions: [...defaultTimeOptions]
  });

  /**
   * Compares time values. Used to sort time options
   */
  const compareTimeValues = useCallback((a: string | number, b: string | number): number => {
    if (a < b) return -1;
    if (a > b) return 1;

    return 0;
  }, []);

  /**
   * If a string does not match any of the above formats and is not able to be parsed with Date.parse, moment.isValid will return false.
   */
  const validateDate = useCallback((dateStr: string | Date): dateStr is Date => {
    if (dateStr instanceof Date) return true;

    return moment(dateStr, 'L').isValid() || moment(dateStr).isValid();
  }, []);

  /**
   * Creates a new time option out of the given string value. Then adds it to the existing options and sorts them.
   */
  const getNewAndSortedTimeOptions = useCallback(
    (newOptionValue: string): IComboBoxOption[] => {
      const newOptions: IComboBoxOption[] = [...defaultTimeOptions];
      const newOption = { key: newOptionValue, text: newOptionValue };

      // add the custom valid time
      newOptions.push(newOption);

      // sort all options
      newOptions.sort((a, b) => compareTimeValues(a.key, b.key));

      return newOptions;
    },
    [compareTimeValues, defaultTimeOptions]
  );

  const setInitialDateTimeModel = useCallback(
    ({ selectedTime, selectedDate }: { selectedTime: string; selectedDate?: Date }): void => {
      const compareTimeOptionToSelectedTime = (option: IComboBoxOption) =>
        option.key === selectedTime;

      setDateTimeModel((prevState) => {
        const { timeOptions } = prevState;

        // check if the given time does already exists in the time options array
        const doesSelectedTimeOptionExist = timeOptions.find(compareTimeOptionToSelectedTime);

        if (selectedTime && !doesSelectedTimeOptionExist) {
          // add new time options to the default time options and sort them
          const newTimeOptions = getNewAndSortedTimeOptions(selectedTime);

          return { ...prevState, timeOptions: newTimeOptions, selectedTime, selectedDate };
        }

        return { ...prevState, selectedTime, selectedDate };
      });
    },
    [getNewAndSortedTimeOptions]
  );

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);

      // useEffect to set inital values if default value is given
      let selectedDate: Date | undefined;
      let selectedTime: string | undefined = '00:00';

      if (defaultValue && validateDate(defaultValue.toString())) {
        selectedDate = new Date(defaultValue);
        selectedTime = moment(selectedDate).format('HH:mm');
      }
      if (value && validateDate(value.toString())) {
        selectedDate = new Date(value);
        selectedTime = moment(value).format('HH:mm');
      }

      setInitialDateTimeModel({ selectedTime, selectedDate });
    }
  }, [defaultValue, value, initialized, setInitialDateTimeModel, validateDate]);

  /**
   * Formats a date value in the "DD-MM-YYYY" format
   */
  const formatDate = useCallback((date?: Date) => {
    if (!date) {
      return '';
    }

    return moment(date).format('L');
  }, []);

  /**
   * Converts a string to a date value
   */
  function convertStringToDate(string: string, format: string): Date {
    return moment(string, format).toDate();
  }

  /**
   * Validates and converts a string to a date value
   */
  const parseDateFromString = useCallback(
    (dateStr: string) => {
      if (!validateDate(dateStr)) {
        return null;
      }

      return convertStringToDate(dateStr, 'L');
    },
    [validateDate]
  );

  /**
   * Determines the minimum selectable date value.
   * Default is set to today or if past dates are allowed to 01.01.1900
   */
  function getMinimumAllowableDate(): Date {
    if (minDate) {
      return new Date(minDate);
    }

    if (allowPastDate) {
      return new Date('January 1, 1900');
    }

    return new Date();
  }

  /**
   * Determines the maximum selectable date value.
   * Default is set to 100 years from now
   */
  function getMaximumAllowableDate(): Date {
    if (maxDate) {
      return new Date(maxDate);
    }

    const today: Date = new Date();

    return new Date(today.setFullYear(today.getFullYear() + 100));
  }

  /**
   * Creates options for all times of the day every half hour
   */
  function getTimeOptions(): IComboBoxOption[] {
    const options: IComboBoxOption[] = [];

    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < 24; index++) {
      const isTwoNumberDigit: boolean = index > 9;
      const hour: string | number = isTwoNumberDigit ? index : `0${index}`;

      options.push({ key: `${hour}:00`, text: `${hour}:00` });
      options.push({ key: `${hour}:30`, text: `${hour}:30` });
    }

    return options;
  }

  /**
   * Test given string with regex
   */
  function validateTime(timeString: string): boolean {
    return /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(timeString);
  }

  function validateAndSetNewTimeOption(value: string): void {
    const isValidTime = validateTime(value);

    if (isValidTime) {
      const newTimeOptions = getNewAndSortedTimeOptions(value);

      setDateTimeModel((prevState) => ({
        ...prevState,
        timeOptions: newTimeOptions,
        selectedTime: value
      }));

      let fullDateTime;

      if (dateTimeModel.selectedDate) {
        // create date + time
        fullDateTime = getFullDateTime(dateTimeModel.selectedDate, value.toString());
      }

      if (onSelectDate) {
        onSelectDate(fullDateTime || null);
      }
    }
  }

  /**
   * Callback issued when the user changes the pending value in ComboBox.
   * This will be called any time the component is updated and there is a current
   * pending value. Option, index, and value will all be undefined if no change
   * has taken place and the previously entered pending value is still valid.
   */
  function onPendingValueChanged(option?: IComboBoxOption, index?: number, value?: string): void {
    if (!option && !index && value) {
      validateAndSetNewTimeOption(value);
    }
  }

  /**
   * Callback issued when either:
   * 1) the selected option changes
   * 2) a manually edited value is submitted. In this case there may not be a matched option if allowFreeform
   *    is also true (and hence only value would be true, the other parameter would be null in this case)
   */
  function onComboBoxChange(_: React.FormEvent<IComboBox>, option?: IComboBoxOption): void {
    if (option && option.key) {
      setDateTimeModel((prevState) => ({
        ...prevState,
        selectedTime: option.key.toString()
      }));

      let fullDateTime;

      if (dateTimeModel.selectedDate) {
        // create date + time
        fullDateTime = getFullDateTime(dateTimeModel.selectedDate, option.key.toString());
      }

      if (onSelectDate) {
        onSelectDate(fullDateTime || null);
      }
    }
  }

  function onLabelClick() {
    if (!disabled && datePickerRef?.current) {
      datePickerRef?.current.showDatePickerPopup();
    }
  }

  /**
   * Creates date with current selected time
   */
  function getFullDateTime(givenDate: Date, givenTime: string): Date {
    const hour = Number(givenTime.split(':')[0]);
    const minute = Number(givenTime.split(':')[1]);

    const fullDate = moment(givenDate).set({ hour, minute });

    return fullDate.toDate();
  }

  const ignoreNextValueChangeForChangeDetection = useRef(false);

  const onDatePickerChange = useCallback(
    (date: Date | null | undefined) => {
      ignoreNextValueChangeForChangeDetection.current = true;

      if (date !== undefined) {
        setDateTimeModel((prevState) => ({
          ...prevState,
          selectedDate: date || undefined
        }));

        let fullDateTime;

        if (date && validateDate(date)) {
          // create date + time
          fullDateTime = getFullDateTime(date, dateTimeModel.selectedTime);
        }

        if (onSelectDate) {
          onSelectDate(fullDateTime || null);
        }
      }
    },
    [dateTimeModel.selectedTime, setDateTimeModel, onSelectDate, validateDate]
  );

  /**
   * Combines given date-picker styles with current styles for the date-picker
   */
  function getDatePickerStyles(): DateTimeStyles['datePickerStyles'] {
    let datePickerStyles: DateTimeStyles['datePickerStyles'] = {
      root: { width: '100%' }
    };

    if (styles?.datePickerStyles) {
      datePickerStyles = concatStyleSets(datePickerStyles, styles.datePickerStyles);
    }

    return datePickerStyles;
  }

  /**
   * Combines given text-field styles with current styles for the text-field of the date-picker
   */
  function getTextFieldStyles(): DateTimeStyles['datePickerStyles'] {
    let textFieldStyles: DateTimeStyles['textFieldStyles'] = {
      fieldGroup: {
        border: `1px solid rgb(${theme.datePicker.outline})`,
        borderRadius: 3,
        ':after': { borderRadius: 3 }
      }
    };

    if (styles?.textFieldStyles) {
      textFieldStyles = concatStyleSets(textFieldStyles, styles.textFieldStyles);
    }

    return textFieldStyles;
  }

  /**
   * Combines given time-picker styles with current styles for the time-picker
   */
  function getTimePickerStyles(): DateTimeStyles['timePickerStyles'] {
    let timePickerStyles: DateTimeStyles['timePickerStyles'] = {
      optionsContainer: { maxHeight: 400 },
      container: { width: '100%' },
      input: { ':after': { border: `1px solid rgb(${theme.datePicker.outline})` } },
      root: {
        marginLeft: 10,
        ':after': { border: `1px solid rgb(${theme.datePicker.outline})`, borderRadius: 3 }
      }
    };

    if (styles?.timePickerStyles) {
      timePickerStyles = concatStyleSets(timePickerStyles, styles.timePickerStyles);
    }

    return timePickerStyles;
  }

  const minimumDate = getMinimumAllowableDate();
  const maximumDate = getMaximumAllowableDate();

  const { selectedDate, selectedTime, timeOptions } = dateTimeModel;

  function renderTimePicker(): JSX.Element | null {
    if (!displayTime) {
      return null;
    }

    const timePickerStyles = getTimePickerStyles();

    return (
      <div>
        <Label
          label={timeLabel}
          required={required}
          description={description}
          iconName={timeLabelIconName}
          styles={{ container: { paddingLeft: 8 } }}
        />
        <ComboBox
          allowFreeform
          autoComplete="off"
          comboBoxOptionStyles={{ optionText: { minWidth: '50px' } }}
          disabled={!dateTimeModel.selectedTime || disabled}
          multiSelect={false}
          calloutProps={{ preventDismissOnResize: true }}
          onChange={onComboBoxChange}
          onPendingValueChanged={onPendingValueChanged}
          options={timeOptions}
          selectedKey={selectedTime}
          styles={timePickerStyles}
        />
      </div>
    );
  }

  const datePickerStyles = getDatePickerStyles();
  const datePickerDescription = !displayTime ? description : undefined;

  const textFieldStyles = useMemo(getTextFieldStyles, [styles?.textFieldStyles, theme.datePicker]);

  // NOTE: we have to memoize all date picker props, because *any* update to the date picker will
  // delete current text input
  const datePickerStrings: IDatePickerStrings = useMemo(
    () => ({
      goToToday: t(`dateTime.datePicker.goToToday`),
      months: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((m) =>
        t(`dateTime.datePicker.month`, { context: m.toString() })
      ),
      shortMonths: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((m) =>
        t(`dateTime.datePicker.month`, { context: m.toString() }).substring(0, 3)
      ),
      days: [0, 1, 2, 3, 4, 5, 6].map((d) =>
        t(`dateTime.datePicker.day`, { context: d.toString() })
      ),
      shortDays: [0, 1, 2, 3, 4, 5, 6].map((d) => {
        return t(`dateTime.datePicker.day`, { context: d.toString() }).substring(0, 2);
      })
    }),
    [t]
  );
  const datePickerTextField: ITextFieldProps = useMemo(
    () => ({
      id: id ? `input-${id}` : null,
      inputClassName: 'c-date-picker__text',
      styles: textFieldStyles
    }),
    [id, textFieldStyles]
  );
  const datePickerValue = useMemo(
    () => (value ? new Date(value) : selectedDate),
    [value, selectedDate]
  );

  const calendarProps: ICalendarProps = useMemo(() => {
    if (weekdaysOnly) {
      return {
        calendarDayProps: {
          customDayCellRef: (element, date, classNames) => {
            if (element && (date.getDay() === 0 || date.getDay() === 6)) {
              // weekend! disable it
              if (classNames.dayOutsideBounds) {
                element.classList.add(classNames.dayOutsideBounds);
              }
              // eslint-disable-next-line no-param-reassign
              (element.children[0] as HTMLButtonElement).disabled = true;
            }
          }
        }
      };
    }
    return {};
  }, [weekdaysOnly]);

  const datePickerNodeRef = useRef<HTMLDivElement>(null);
  const highlightNonUserChangesRef = useRef(highlightNonUserChanges);
  highlightNonUserChangesRef.current = highlightNonUserChanges;

  const changeDetectionPreviousValue = useRef(value);

  useEffect(() => {
    if (!highlightNonUserChangesRef.current) return;

    const prevValue = new Date(changeDetectionPreviousValue.current);
    changeDetectionPreviousValue.current = value;

    if (ignoreNextValueChangeForChangeDetection.current) {
      ignoreNextValueChangeForChangeDetection.current = false;
      return;
    }

    // highlight non-user changes
    const datePicker = datePickerNodeRef.current;
    if (!datePicker) return;
    const isOpen = datePicker.classList.contains('is-open');
    const inputNode = datePicker.querySelector('.c-date-picker__text') as HTMLInputElement;
    const isInputFocused = inputNode === document.activeElement;

    if (isOpen || isInputFocused) return; // probably a user change
    if (compareDates(prevValue, new Date(value))) return; // no change

    const sign = prevValue < new Date(value) ? 1 : -1;

    // bounce
    inputNode.animate(
      [
        { offset: 0, transform: 'translateX(0)', easing: 'cubic-bezier(.14, .18, .33, 1)' },
        {
          offset: 0.2,
          transform: `translateX(${sign * 12}px)`,
          easing: 'cubic-bezier(.5, 0, 1, 1)'
        },
        {
          offset: 0.4,
          transform: `translateX(0px)`,
          easing: 'cubic-bezier(0, 0, .5, 1)'
        },
        {
          offset: 0.6,
          transform: `translateX(${sign * 3}px)`,
          easing: 'cubic-bezier(.5, 0, 1, 1)'
        },
        {
          offset: 0.8,
          transform: `translateX(0px)`,
          easing: 'cubic-bezier(0, 0, .5, 1)'
        }
      ],
      {
        duration: 800,
        easing: 'ease-out',
        composite: 'add'
      }
    );

    // flash
    inputNode.animate(
      [{ offset: 0 }, { offset: 0.1, color: `rgb(${theme.datePicker.changeHighlight})` }],
      {
        duration: 1000,
        easing: 'ease-out'
      }
    );
  }, [theme.datePicker.changeHighlight, value]);

  if (!initialized) {
    return null;
  }

  return (
    <DateTimeStyled id={id} style={styles?.containerStyles}>
      <div style={{ width: '100%' }}>
        <Label
          onClick={onLabelClick}
          label={label}
          required={required}
          iconName={labelIconName}
          description={datePickerDescription}
          styles={{ fluentLabel: styles?.labelStyles }}
        />
        <DatePicker
          ref={datePickerNodeRef}
          className="c-date-picker"
          allowTextInput={!checkScreenWidth(['extraSmall', 'small'])}
          componentRef={datePickerRef}
          disabled={disabled}
          firstWeekOfYear={1}
          firstDayOfWeek={1}
          formatDate={formatDate}
          maxDate={maximumDate}
          strings={datePickerStrings}
          textField={datePickerTextField}
          minDate={minimumDate}
          onSelectDate={onDatePickerChange}
          parseDateFromString={parseDateFromString}
          placeholder={placeholder || t('dateTime.datePicker.placeholder')}
          showWeekNumbers
          styles={datePickerStyles}
          value={datePickerValue}
          calendarProps={calendarProps}
        />
      </div>
      {renderTimePicker()}
    </DateTimeStyled>
  );
}

export default DateTime;
