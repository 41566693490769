import 'moment/dist/locale/de';

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import moment from 'moment';
import { initReactI18next } from 'react-i18next';
import config from './config';

let initOptions = {
  debug: false,
  fallbackLng: ['en'],
  whitelist: ['en', 'en-US', 'de', 'de-DE', 'dev'],
  ns: ['translation', 'components', 'globals'],
  nonExplicitWhitelist: true,
  interpolation: {
    escapeValue: false,
    format: function onFormatInterpolation(value, format) {
      if (moment(value, moment.ISO_8601, true).isValid()) {
        return moment(value).format(format);
      }

      return value?.toString();
    }
  },
  react: {
    bindI18n: 'languageChanged loaded',
    bindStore: 'added removed',
    nsMode: 'default'
  },
  detection: {
    order: ['querystring', 'navigator', 'htmlTag', 'path', 'subdomain']
  }
};

// in production mode we need to prefix the URL with
if (process.env.NODE_ENV === 'production') {
  initOptions = {
    ...initOptions,
    backend: { loadPath: `${config.I18N_LOAD_PATH}?v=${config.VERSION}` }
  };
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(initOptions)
  .then(() => {
    document.documentElement.lang = i18n.language;
  });

i18n.on('languageChanged', (lng) => {
  moment.locale(lng);
});

export default i18n;
