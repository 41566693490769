/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  List,
  PrimaryButton
} from '@fluentui/react';

const DialogStyled = styled(Dialog)`
  .ms-Dialog-subText {
    margin-bottom: 8px;
  }
`;

function DeleteFieldInRulesDialog({
  defaultButtonFunc,
  hidden,
  fieldsWithRules,
  onDismiss,
  primaryButtonFunc
}) {
  const { t } = useTranslation();

  const title = t('deleteFieldInRules.dialog.title');
  const subTitle = t('deleteFieldInRules.dialog.subTitle');

  function getFieldWithRule(fieldName) {
    return <div style={{ padding: '5px 0 5px 5px' }}>-&nbsp;{fieldName}</div>;
  }

  return (
    <DialogStyled
      hidden={hidden}
      onDismiss={onDismiss}
      styles={{
        main: [
          {
            selectors: {
              '@media (max-width: 480px)': {
                width: '95%'
              },
              '@media (min-width: 650px)': {
                width: '100%',
                minWidth: '550px',
                minHeight: '250px'
              }
            }
          }
        ]
      }}
      dialogContentProps={{
        styles: { innerContent: { maxHeight: '500px', overflowY: 'auto' } },
        type: DialogType.close,
        title,
        subText: subTitle
      }}
    >
      <List
        items={fieldsWithRules}
        onRenderCell={(fieldName) => {
          return getFieldWithRule(fieldName);
        }}
      />

      <DialogFooter>
        <PrimaryButton
          onClick={() => primaryButtonFunc()}
          text={t('deleteFieldInRules.dialog.button.primary')}
        />
        <DefaultButton
          onClick={() => defaultButtonFunc()}
          text={t('deleteFieldInRules.dialog.button.cancel')}
        />
      </DialogFooter>
    </DialogStyled>
  );
}

DeleteFieldInRulesDialog.propTypes = {
  primaryButtonFunc: PropTypes.func.isRequired,
  defaultButtonFunc: PropTypes.func.isRequired,
  hidden: PropTypes.bool,
  fieldsWithRules: PropTypes.arrayOf(PropTypes.string).isRequired,
  onDismiss: PropTypes.func.isRequired
};

DeleteFieldInRulesDialog.defaultProps = {
  hidden: true
};

export default DeleteFieldInRulesDialog;
