import { IApiObjectData, IApiObjectDataBase } from '../object';

export const DEFAULT_ITEMS_PER_PAGE = 20;

/** IApiObjectData does not support arrays, so this is a wrapper to make arrays work. */
export interface IApiObjectArray<T> extends IApiObjectData {
  items: T[];
}

/** `string($uuid)` */
export type Uuid = string;
/** `string($date-time)` */
export type DateTime = string;
/** Denotes a reference field */
export type Ref<T> = T extends IApiObjectDataBase ? T : never;

// params can't be actual void because they have a non-null check
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface VoidParams {}

export interface ITextItem {
  language: string | null;
  text: string | null;
  editDate: DateTime | null;
}

export interface IIdAndName {
  id: Uuid;
  name: string | null;
}

export interface IIdStringPair {
  id: Uuid;
  value: string | null;
  allValues: ITextItem[] | null;
}
