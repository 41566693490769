import { AppContext } from 'features/App';
import { BackgroundJobsMenu } from 'features/BackgroundJobs';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import ContrastingThemeProvider from 'utils/ContrastingThemeProvider';
import { Icon } from '@fluentui/react';
import { evocomBeeLogo } from '../Icons';
import { HeaderStyled, LogoWrapper } from './Header.styled';
import HeaderButtonsRow from './HeaderButtonsRow';
import SearchBox from './SearchBox';

function Header({ logout, onToggleNotifications, onToggleTrainer, onToggleReleaseNotes }) {
  const navigate = useNavigate();

  // global app state
  const { globalAppState, dispatch } = useContext(AppContext);
  const { theme, currentUser, tenants, unreadNotifications } = globalAppState;
  const styleTheme = useTheme();

  return (
    <HeaderStyled id="header">
      <ContrastingThemeProvider
        className="inner-theme-provider"
        backgroundColor={(theme) => theme.header.background}
      >
        <Icon
          key="globalNavButton"
          className="global-navigation-button"
          iconName="GlobalNavButton"
          onClick={() => dispatch({ type: 'toggleNavigation' })}
        />
        <LogoWrapper onClick={() => navigate(`/tasks/my-open`)}>
          {evocomBeeLogo(30, `rgb(${styleTheme.header.foreground})`)}
        </LogoWrapper>
        <div style={{ minWidth: 0 }} className="flex full-width">
          <a href="/tasks/my-open" className="c-header__title">
            {theme.title}
          </a>
        </div>
        <BackgroundJobsMenu />
        <SearchBox />
        <HeaderButtonsRow
          logout={logout}
          currentUser={currentUser}
          tenants={tenants}
          unreadNotifications={unreadNotifications}
          onToggleNotifications={onToggleNotifications}
          onToggleReleaseNotes={onToggleReleaseNotes}
          onToggleTrainer={onToggleTrainer}
        />
      </ContrastingThemeProvider>
    </HeaderStyled>
  );
}

Header.propTypes = {
  logout: PropTypes.func.isRequired,
  onToggleNotifications: PropTypes.func.isRequired,
  onToggleReleaseNotes: PropTypes.func.isRequired,
  onToggleTrainer: PropTypes.func.isRequired
};

export default Header;
