import { useMessageDraft } from 'hooks';
import isNumber from 'lodash/isNumber';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ICommentProps, IUserProps } from 'types';
import { Icon, PrimaryButton, Separator } from '@fluentui/react';
import { useTheme } from 'styled-components';
import ConversationContainerStyled from './Conversation.styles';
import { IEditorValueProps } from './Conversation.types';
import Discussion from './Discussion';
import Editor, { IEditorProps } from './Editor';

export interface IConversationProps {
  /**
   * Function to get comments to display
   */
  getComments: () => Promise<ICommentProps[]>;
  /**
   * Function to search for users
   */
  getPersons: IEditorProps['getPersons'];
  /**
   * Function to search for tags
   */
  getTags?: IEditorProps['getTags'];
  /**
   * Callback issued when a comment is created.
   */
  onCreateComment: (comment: IEditorValueProps) => Promise<ICommentProps>;
  /**
   * Callback issued when a comment is updated.
   */
  onUpdateComment: (comment: IEditorValueProps) => Promise<ICommentProps>;
  /**
   * Callback issued when a tag is clicked.
   */
  onTagClick?: IEditorProps['onTagClick'];
  /**
   * Callback issued when a comment is deleted.
   */
  onDeleteComment: (id: string) => Promise<ICommentProps>;
  /**
   * CurrentUser
   */
  currentUser: IUserProps;
  /**
   * Call to provide customized styling that will layer on top of the variant rules.
   */
  styles?: { container?: React.CSSProperties };
  grouped?: boolean;
  id: string;
  disabled?: boolean;
}

export interface IDiscussionProps {
  mainComment: ICommentProps;
  replies: ICommentProps[];
}

function Conversation({
  currentUser,
  getComments,
  getPersons,
  getTags,
  onCreateComment,
  onDeleteComment,
  grouped = false,
  id,
  onTagClick,
  onUpdateComment,
  styles,
  disabled = false
}: IConversationProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  const [currentTextFieldValue, setCurrentTextFieldValue] = useState<string | undefined | null>(
    null
  );

  const [messageDraft, setSaveOnUnmount, deleteDraft] = useMessageDraft({
    message: currentTextFieldValue,
    id
  });

  const [discussions, setDiscussions] = useState<IDiscussionProps[]>([]);
  const [displayRte, setDisplayRte] = useState(!!messageDraft);
  const [isAtBottom, setIsAtBottom] = useState(false);
  const [openGroups, setOpenGroups] = useState<string[]>(id ? [id] : []);
  const [initialized, setInitialized] = useState(false);

  const discussionsWrapperRef = useRef<HTMLDivElement>(null);

  const isBottom = useCallback((element?: HTMLDivElement | null) => {
    if (!element) return false;

    return element.scrollHeight - element.scrollTop === element.clientHeight;
  }, []);

  const trackScrolling = useCallback(() => {
    // track scrolling to add shadow to NewDiscussionContainer
    const newIsAtBottomValue = isBottom(discussionsWrapperRef?.current);

    setIsAtBottom(newIsAtBottomValue);
  }, [isBottom]);

  useLayoutEffect(() => {
    // track scrolling to add shadow to NewDiscussionContainer
    discussionsWrapperRef?.current?.addEventListener('scroll', trackScrolling);

    return () => document.removeEventListener('scroll', trackScrolling);
  }, [discussionsWrapperRef, trackScrolling]);

  /**
   * Create the discussions objects
   */
  const createDiscussions = useCallback(() => {
    const temporarilyDiscussions: IDiscussionProps[] = [];

    getComments().then((comments) => {
      comments.map((comment) => {
        // check if discussion already exists -> in this case the comment is a reply
        const foundDiscussionIndex = temporarilyDiscussions.findIndex(
          (discussionToCheck) => discussionToCheck.mainComment.discussionId === comment.discussionId
        );

        if (foundDiscussionIndex > -1) {
          // add as reply to existing discussion
          temporarilyDiscussions[foundDiscussionIndex].replies.push(comment);
        } else {
          // create new discussion
          temporarilyDiscussions.push({ mainComment: comment, replies: [] });
        }

        return null;
      });

      setDiscussions(temporarilyDiscussions);
    });
  }, [getComments]);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      createDiscussions();
    }
  }, [initialized, createDiscussions]);

  useEffect(() => {
    if (initialized) {
      // scroll to the discussion if id is provided
      setTimeout(() => {
        if (id) {
          const group = document.getElementById(`group-${id}`);

          if (group) {
            group.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }, 400);
    }
  }, [id, initialized]);

  function handleDeleteComment(commentId: string): void {
    onDeleteComment(commentId).then(handleUpdatedComment);
  }

  function renderDiscussion(discussion: IDiscussionProps, grouped: boolean): JSX.Element {
    const { replies, mainComment } = discussion;

    return (
      <div
        className="ms-motion-fadeIn"
        key={mainComment.id}
        style={{ margin: grouped ? '0px 0px 15px 0px' : '15px 0px' }}
      >
        <Discussion
          currentUser={currentUser}
          getPersons={getPersons}
          getTags={getTags}
          key={mainComment.id}
          mainComment={mainComment}
          onDeleteComment={handleDeleteComment}
          onSaveComment={onSaveComment}
          onTagClick={onTagClick}
          replies={replies}
        />
      </div>
    );
  }

  function onSeparatorClick(id: string): void {
    setOpenGroups((prevState) => {
      if (prevState.includes(id)) {
        return prevState.filter((groupId) => groupId !== id);
      }

      return [...prevState, id];
    });
  }

  function groupDiscussions(groupByProp: 'taskId' | 'originalTaskId') {
    return discussions.reduce((acc, discussion) => {
      const id = discussion.mainComment[groupByProp];

      if (!id) return acc;

      if (!acc[id]) acc[id] = [];

      acc[id].push(discussion);

      return acc;
    }, {} as { [key: string]: IDiscussionProps[] });
  }

  function getGroupName(mainComment: ICommentProps): string {
    const { stepName, taskName, originalTaskName } = mainComment;

    return originalTaskName || stepName || taskName || '';
  }

  function renderContent(): JSX.Element {
    if (discussions && discussions.length > 0) {
      // check if there are different discussion taskids

      if (grouped) {
        const hasOriginalTaskId = discussions.some(
          ({ mainComment }) => !!mainComment.originalTaskId
        );

        // group discussions by taskId
        const groupByProp = hasOriginalTaskId ? 'originalTaskId' : 'taskId';

        const groupedDiscussions: { [key: string]: IDiscussionProps[] } =
          groupDiscussions(groupByProp);

        // render grouped discussions
        return (
          <div ref={discussionsWrapperRef} className="c-conversation-content-scroll-container">
            {Object.keys(groupedDiscussions).map((groupId) => {
              const stepDiscussions = groupedDiscussions[groupId];
              const open = openGroups.includes(groupId);

              return (
                <div id={`group-${groupId}`} key={groupId}>
                  <div aria-hidden="true" onClick={() => onSeparatorClick(groupId)}>
                    <Separator
                      styles={{
                        content: { backgroundColor: `rgb(${theme.conversation.background})` },
                        root: {
                          ':hover': { cursor: 'pointer' },
                          padding: '7px 0px',
                          ':before': {
                            backgroundColor: `rgb(${theme.conversation.separatorLine})`,
                            marginLeft: 66,
                            marginRight: 45
                          }
                        }
                      }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Icon
                          styles={{
                            root: {
                              marginRight: 5,
                              fontSize: 12,
                              transform: open ? 'rotate(0deg)' : 'rotate(-90deg)',
                              transition: 'transform 0.2s ease-in-out'
                            }
                          }}
                          iconName="ChevronDown"
                        />
                        {getGroupName(stepDiscussions[0].mainComment)}
                      </div>
                    </Separator>
                  </div>
                  {open && stepDiscussions.map((discussion) => renderDiscussion(discussion, true))}
                </div>
              );
            })}
          </div>
        );
      }

      // render discussions without grouping
      return renderDiscussions();
    }

    return <div />;
  }

  function renderDiscussions(): JSX.Element {
    return (
      <div ref={discussionsWrapperRef} className="c-conversation-content-scroll-container">
        {discussions.map((discussion) => renderDiscussion(discussion, false))}
      </div>
    );
  }

  /**
   * Add a new message in replies in an existing discussion
   */
  function addCommentToDiscussion(comment: ICommentProps, discussionIndex: number): void {
    setDiscussions((prevState) => {
      const prevStateClone = [...prevState];

      let discussionClone = { ...prevStateClone[discussionIndex] };

      discussionClone = { ...discussionClone, replies: [...discussionClone.replies, comment] };

      prevStateClone[discussionIndex] = discussionClone;

      return prevStateClone;
    });
  }

  function findDiscussionIndexById(discussionId?: string): number {
    return discussions.findIndex(
      (discussion) => discussion.mainComment.discussionId === discussionId
    );
  }

  function findReplyIndexById(discussionIndex: number, replyId: string): number {
    return discussions[discussionIndex].replies.findIndex((reply) => reply.id === replyId);
  }

  function handleCreatedComment(newComment: ICommentProps): void {
    const existingDiscussionIndex = findDiscussionIndexById(newComment.discussionId);

    if (existingDiscussionIndex > -1) {
      addCommentToDiscussion(newComment, existingDiscussionIndex);
    } else {
      const newDiscussion = { mainComment: newComment, replies: [] };

      setDiscussions((prevState) => [...prevState, newDiscussion]);
    }
  }

  function replaceComment(
    comment: ICommentProps,
    discussionIndex: number,
    replyIndex?: number
  ): void {
    setDiscussions((prevState) => {
      const prevStateClone = [...prevState];

      if (discussionIndex > -1 && !isNumber(replyIndex)) {
        // replace main comment
        prevStateClone[discussionIndex].mainComment = comment;

        return prevStateClone;
      }

      if (isNumber(replyIndex) && replyIndex > -1) {
        // replace reply comment
        prevStateClone[discussionIndex].replies[replyIndex] = comment;

        return prevStateClone;
      }

      // fallback return unchanged prevState
      return prevStateClone;
    });
  }

  function handleUpdatedComment(updatedComment: ICommentProps): void {
    const discussionIndex = findDiscussionIndexById(updatedComment.discussionId);

    if (discussionIndex > -1) {
      const isMainComment = updatedComment.id === discussions[discussionIndex].mainComment.id;

      if (isMainComment) {
        replaceComment(updatedComment, discussionIndex);
      } else {
        const replyIndex = findReplyIndexById(discussionIndex, updatedComment.id);

        replaceComment(updatedComment, discussionIndex, replyIndex);
      }
    }
  }

  function onSaveComment(newComment: IEditorValueProps): void {
    setDisplayRte(false);
    setCurrentTextFieldValue(null);

    deleteDraft();

    if (newComment.id) {
      onUpdateComment(newComment).then(handleUpdatedComment);
    } else {
      onCreateComment(newComment).then(handleCreatedComment);
    }
  }

  function onEditorChange({ message }: IEditorValueProps) {
    setCurrentTextFieldValue(message);
    setSaveOnUnmount(true);
  }

  function renderNewDiscussionContainer() {
    let boxShadow;

    if (!isAtBottom) {
      boxShadow = theme.conversation.readMoreShadow;
    }

    const styles = {
      boxShadow
    };

    if (displayRte) {
      return (
        <div
          style={styles}
          className="c-conversation_new-discussion-container c-conversation_open-discussion"
        >
          <Editor
            onSaveComment={onSaveComment}
            placeholder={t('conversation.newConversation.editorPlaceholder')}
            disabled={false}
            onChange={onEditorChange}
            defaultTextValue={messageDraft}
            getPersons={getPersons}
            getTags={getTags}
            onTagClick={onTagClick}
          />
        </div>
      );
    }

    return (
      <div style={styles} className="c-conversation_new-discussion-container">
        <PrimaryButton
          disabled={disabled}
          onClick={() => onCreateNewDiscussion()}
          text={t('conversation.newConversation.newButton')}
          iconProps={{ iconName: 'FieldNotChanged' }}
          styles={{ root: { borderRadius: 4 } }}
        />
      </div>
    );
  }

  function onCreateNewDiscussion() {
    setDisplayRte(true);
  }

  return (
    <ConversationContainerStyled style={styles?.container}>
      <div className="c-conversation-content-wrapper">
        {renderContent()}
        {renderNewDiscussionContainer()}
      </div>
    </ConversationContainerStyled>
  );
}

export default Conversation;
