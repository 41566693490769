import { createContext, useContext } from 'react';
import { IFieldLinkValueProps, ITagProps, RouteFieldType } from 'types';

interface IFilter {
  fieldId: string;
  fieldType: RouteFieldType;
  value: IFieldLinkValueProps;
}

interface ISelectOption {
  id: string;
  name: string;
}

interface IProcessOption extends ISelectOption {
  filter: IFilter;
}

interface IConfig {
  id: string;
  selectedProcess?: IProcessOption;
  selectedProject?: ISelectOption;
  selectedTags?: ITagProps[];
  selectedTeam?: ISelectOption;
  selectedTenant?: string;
  selectedView?: string;
  showProcesses: boolean;
  showProjects: boolean;
  showTagList: boolean;
  showTasks: boolean;
  tabName: string;
}

const MsTeamsConfigContext = createContext<IConfig | null>(null);

export const useMsTeamsConfig = () => useContext(MsTeamsConfigContext);

export function MsTeamsConfigProvider({
  children,
  config
}: {
  children: React.ReactNode;
  config: IConfig | null;
}) {
  return <MsTeamsConfigContext.Provider value={config}>{children}</MsTeamsConfigContext.Provider>;
}
