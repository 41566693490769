import { useNotificationContext } from 'features/App';
import { useCallback, useEffect, useState } from 'react';
import { fetchAIBlob } from 'services/api2';

interface CachedFile {
  imageUrl: string;
}

interface IUseFileCacheProps {
  chatId?: string;
  fileId?: string;
}

const useFileCache = ({ chatId, fileId }: IUseFileCacheProps) => {
  const { showError } = useNotificationContext();
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const cacheKey = `file_${chatId}_${fileId}`;

  const fetchFile = useCallback(async () => {
    if (!chatId || !fileId) return;

    setIsLoading(true);
    setError(null);

    try {
      const blob = await fetchAIBlob(
        {
          url: `chats/${chatId}/data-sources/files/${fileId}`,
          method: 'GET'
        },
        { addUserId: true }
      );

      const url: string = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(blob);
      });

      localStorage.setItem(cacheKey, JSON.stringify({ imageUrl: url, timestamp: Date.now() }));

      setImageUrl(url);
    } catch (err) {
      setError(err);
      showError(err);
    } finally {
      setIsLoading(false);
    }
  }, [chatId, fileId, showError, cacheKey]);

  useEffect(() => {
    const cachedData = localStorage.getItem(cacheKey);
    if (cachedData) {
      const { imageUrl }: CachedFile = JSON.parse(cachedData);
      if (imageUrl) {
        setImageUrl(imageUrl);
        return;
      }
    }

    if (chatId && fileId) {
      fetchFile();
    }
  }, [cacheKey, chatId, fetchFile, fileId]);

  return { imageUrl, isLoading, error, refetch: fetchFile };
};

export default useFileCache;
