import styled from 'styled-components';

const DocumentFieldStyled = styled.div`
  .document-image-preview {
    height: 50px;
    max-width: 150px;
    object-fit: cover;

    :hover {
      cursor: pointer;
    }
  }
`;

export default DocumentFieldStyled;
