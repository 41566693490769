import ChoiceGroupField from 'components/inputs/ChoiceGroupField';
import CombinedPicker from 'components/inputs/CombinedPicker';
import DynamicChoiceGroupField from 'components/inputs/DynamicField/DynamicChoiceGroupField';
import DynamicDateTimeField from 'components/inputs/DynamicField/DynamicDateTimeField';
import DynamicIntegrationField from 'components/inputs/DynamicField/DynamicIntegrationField';
import epLabel from 'components/inputs/Label';
import SmallTextField from 'components/inputs/SmallTextField';
import Dialog, { IDialogProps } from 'components/surfaces/Dialog';
import TeamPanel from 'components/surfaces/TeamPanel/TeamPanel.container';
import i18n from 'i18next';
import debounce from 'lodash/debounce';
import moment from 'moment';
import business from 'moment-business';
import { createContext, useEffect, useRef, useState } from 'react';
import { LiveError, LivePreview, LiveProvider, LiveProviderProps } from 'react-live';
import styled from 'styled-components';
import * as helpers from 'utils/helpers';
import {
  ActionButton,
  Calendar,
  Checkbox,
  ChoiceGroup,
  ComboBox,
  CommandBarButton,
  DatePicker,
  DateRangeType,
  DayOfWeek,
  Dropdown,
  IconButton,
  Label,
  PeoplePickerItem,
  Position,
  Shimmer,
  Slider,
  SpinButton,
  Spinner,
  SpinnerSize,
  TextField
} from '@fluentui/react';
// import TestCode from './CustomCode/ConstantiaPowerAppsIframeDateTIme';

export interface ICodeEditorProps {
  /**
   * Code to transpile
   */
  defaultCode?: LiveProviderProps['code'];
  /**
   * Dialog props to display the Editor within a Dialog
   */
  dialogProps?: CodeEditorDialogProps;
  /**
   * Specifies whether the editor should be displayed
   */
  displayEditor?: boolean;
  /**
   * Callback issued when the code changes.
   */
  onChange?: (code: LiveProviderProps['code']) => void;
  /**
   * The scope prop on the LiveProvider accepts additional globals.
   */
  scopes?: LiveProviderProps['scope'];
  /**
   * Props to pass to the container context.
   */
  contextProps?: IContextProps;
  /**
   * Call to provide customized styling that will layer on top of the variant rules.
   */
  styles?: ICodeEditorStyleProps;
  onConfirm?: (code?: string) => void;
}

interface CodeEditorDialogProps extends IDialogProps {
  primaryButtonFunc?: (_: React.MouseEvent<HTMLButtonElement, MouseEvent>, code?: string) => void;
}

interface IContextProps {
  [key: string]: unknown;
}

interface ICodeEditorStyleProps {
  containerStyles?: React.CSSProperties;
  providerContentStyles?: React.CSSProperties;
  editorContainerStyles?: React.CSSProperties;
  previewContainerStyles?: React.CSSProperties;
}

const placeHolderCode = `function Test() {
  return (
    <PreviewContext.Consumer>
      {(contextProps) => {
        return <strong>Hello World</strong>;
      }}
    </PreviewContext.Consumer>
  );
}

render(Test);`;

const PreviewContext = createContext<IContextProps | undefined>({});

function CodeEditor({
  contextProps,
  defaultCode,
  dialogProps,
  onConfirm,
  displayEditor = true,
  onChange,
  scopes = {},
  styles
}: ICodeEditorProps): JSX.Element | null {
  const [code, setCode] = useState<LiveProviderProps['code']>(defaultCode || placeHolderCode);
  const utils = { ...helpers };

  const scopesRef = useRef({
    PreviewContext,
    styled,
    i18n,
    moment,
    utils,
    helpers,
    business,
    sharedEpComponents: {
      TeamPanel,
      Label: epLabel,
      ChoiceGroupField,
      DynamicChoiceGroupField,
      DynamicIntegrationField,
      DynamicDateTimeField,
      SmallTextField,
      CombinedPicker
    },
    fluentui: {
      ActionButton,
      Calendar,
      Checkbox,
      ChoiceGroup,
      ComboBox,
      CommandBarButton,
      DatePicker,
      DateRangeType,
      DayOfWeek,
      Dropdown,
      IconButton,
      Label,
      PeoplePickerItem,
      Position,
      Shimmer,
      Slider,
      SpinButton,
      Spinner,
      SpinnerSize,
      TextField
    },
    ...scopes
  });

  useEffect(() => {
    if (dialogProps?.hidden) {
      // hook to reset default code when the dialog is opened/closed
      setCode(defaultCode || placeHolderCode);
    }
  }, [defaultCode, dialogProps?.hidden]);

  const onLiveEditorChange = debounce((newCode: string) => {
    setCode(newCode);

    if (onChange) {
      onChange(newCode);
    }
  }, 550);

  function renderEditor() {
    if (displayEditor) {
      return (
        <div style={{ display: 'grid', overflowY: 'auto', height: '70vh', width: '100%' }}>
          <textarea
            style={{
              gridArea: '1 / 1',
              resize: 'none',
              padding: '0.3em',
              fontFamily: 'monospace'
            }}
            ref={(node) => {
              // eslint-disable-next-line no-param-reassign
              if (node) node.value = code;
            }}
            spellCheck={false}
            onChange={(event) => onLiveEditorChange(event.currentTarget.value)}
          />
        </div>
      );
    }

    return null;
  }

  function renderLiveProvider(): JSX.Element {
    let providerContentStyles = {};

    if (styles?.providerContentStyles) {
      ({ providerContentStyles } = styles);
    }

    // to test a custom code component, uncomment the following line
    // return <TestCode PreviewContext={PreviewContext} />;

    return (
      <div style={styles?.containerStyles}>
        <LiveProvider noInline scope={scopesRef.current} code={code}>
          <div style={{ display: 'flex', ...providerContentStyles }}>
            {renderEditor()}
            <div style={styles?.previewContainerStyles}>
              <LiveError />
              <LivePreview />
            </div>
          </div>
        </LiveProvider>
      </div>
    );
  }

  const content = (
    <PreviewContext.Provider value={contextProps}>{renderLiveProvider()}</PreviewContext.Provider>
  );

  if (dialogProps) {
    const {
      primaryButtonProps,
      defaultButtonProps,
      hidden,
      modalProps,
      onDismiss,
      subText,
      title
    } = dialogProps;

    return (
      <Dialog
        content={content}
        primaryButtonProps={{
          ...primaryButtonProps,
          onClick: onConfirm ? () => onConfirm(code) : undefined
        }}
        defaultButtonProps={defaultButtonProps}
        hidden={hidden}
        modalProps={modalProps}
        onDismiss={onDismiss}
        subText={subText}
        title={title}
      />
    );
  }

  return content;
}

export default CodeEditor;
