import CodeScannerField, { ICodeScannerFieldProps } from 'components/inputs/CodeScannerField';
import { useTranslation } from 'react-i18next';
import { IFieldLinkProps, RouteFieldVisibility } from 'types';
import { getFluentIconNameByFieldType } from 'utils/helpers';

export interface IDynamicCodeScannerFieldProps {
  disabled?: boolean;
  fieldLink: IFieldLinkProps;
  onChange: ICodeScannerFieldProps['onChange'];
}

function DynamicCodeScannerField({ fieldLink, disabled, onChange }: IDynamicCodeScannerFieldProps) {
  const { t } = useTranslation();

  if (!fieldLink.field) return null;

  const { field, visibility } = fieldLink;
  const { name, description, descriptionPlacement, id } = field;
  const defaultValue = fieldLink.value as string | undefined;

  const displayDescriptionTooltip = descriptionPlacement === 1;

  const readOnly = visibility === RouteFieldVisibility.ReadOnly;
  const required = visibility === RouteFieldVisibility.Required;

  const context = disabled ? 'disabled' : undefined;

  return (
    <CodeScannerField
      id={id}
      label={name}
      description={displayDescriptionTooltip ? description : undefined}
      labelIconName={getFluentIconNameByFieldType(fieldLink.field)}
      defaultValue={defaultValue}
      onChange={onChange}
      disabled={disabled || readOnly}
      required={required}
      placeholder={t('dynamicField.codeScannerField.placeholder', {
        context
      })}
    />
  );
}

export default DynamicCodeScannerField;
