import { Label } from 'components';
import TagPicker from 'components/inputs/TagPicker';
import { useTranslation } from 'react-i18next';
import { tagsSearch } from 'services/fetchRequests';
import { ITagProps } from 'types';
import { Checkbox } from '@fluentui/react';

export default function MsTeamsTagsConfig({ config, setConfig }) {
  const { t } = useTranslation();

  function handleTagChange(tags: ITagProps[]) {
    if (tags.length > 0) {
      setConfig((prevState) => ({
        ...prevState,
        showTasks: false,
        showProcesses: false,
        showProjects: false,
        selectedProcess: null,
        selectedTeam: null,
        selectedProject: null,
        selectedTags: tags
      }));
    } else {
      setConfig((prevState) => ({ ...prevState, selectedTags: null }));
    }
  }

  return (
    <>
      <Checkbox
        // @ts-expect-error: text prop allows JSX
        label={
          <div>
            <span style={{ fontWeight: 600 }}>{t('msTeams.config.checkBox.label.tags')}</span>
            <div style={{ maxWidth: 500 }}>{t('msTeams.config.checkBox.subtext.tags')}</div>
          </div>
        }
        checked={config.showTagList}
        key={config.showTagList}
        styles={{ root: { marginTop: '15px' } }}
        disabled={config.selectedProcess || config.selectedProject || config.selectedTeam}
        onChange={(_, checked) => {
          setConfig((prevState) => ({
            ...prevState,
            selectedProcess: null,
            selectedProject: null,
            selectedTags: null,
            selectedTeam: null,
            selectedView: null,
            showProjects: false,
            showProcesses: false,
            showTagList: checked,
            showTasks: false
          }));
        }}
      />
      <Label
        styles={{ container: { marginTop: 5, marginLeft: 23, maxWidth: 500 } }}
        label={t('msTeams.config.tagPicker.label')}
        description={t('msTeams.config.tagPicker.description')}
      />
      <div style={{ maxWidth: 480, marginLeft: 28, height: 40 }}>
        <TagPicker
          disabled={!config.showTagList}
          onChange={handleTagChange}
          onTagsSearch={tagsSearch}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onTagClick={() => {}}
          placeHolder={t('taskDetailsBody.tagPicker.placeholder')}
          selectedTags={config.selectedTags}
          defaultValue={config.tags || []}
          itemLimit={1}
        />
      </div>
    </>
  );
}
