import { LoadingSpinner } from 'components';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { cloneObject } from 'utils/helpers';
import { DirectionalHint, Icon, IconButton, TooltipHost } from '@fluentui/react';

// styles
const ListContainerStyled = styled.div`
  display: inline-block;
  width: 100%;
  margin: 5px 0;
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 600;
  height: 45px;
  width: 100%;
  border-bottom: 1px solid rgb(245, 245, 245);
`;

const GroupHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 38px;
  width: 100%;

  &:hover {
    .editButton {
      visibility: visible;
    }

    .deleteButton {
      visibility: visible;
    }

    .groupAddButton {
      visibility: visible;
    }

    .moreButton {
      visibility: visible;
    }
  }
`;

const collapseButtonWidth = 30;

const Group = styled.div`
  margin-left: ${collapseButtonWidth}px;
`;

const Row = styled.div`
  display: flex;
  height: 38px;
  width: 100%;
  border-bottom: 1px solid rgb(245, 245, 245);

  &:hover {
    .editButton {
      visibility: visible;
    }

    .deleteButton {
      visibility: visible;
    }

    .moreButton {
      visibility: visible;
    }
  }
`;

const CollapseButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: ${(props) => (props?.$width ? `${props.$width}px` : 'auto')};

  button {
    &:hover {
      background-color: transparent;
    }
  }
`;

const Column = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props?.$centered ? 'center' : 'start')};
  height: 100%;
  width: ${(props) => (props?.$maxWidth ? `${props?.$maxWidth}px` : 'auto')};
  padding: ${(props) => (props?.$padding && !props?.$centered ? props?.$padding : '0 5px')};
`;

const ColumnNameAndInfo = styled.div`
  display: flex;
  position: relative;

  .info-icon {
    font-size: 11px;
    position: absolute;
    top: 3px;
    color: rgb(0, 120, 212);
  }
`;

const InfoIcon = styled(Icon)`
  margin: 0 10px 0 5px;

  &:hover {
    cursor: help;
  }
`;

function BodyPropertiesList({
  body,
  columns,
  newEditColumns,
  onChangeNewProp,
  onCancelNewProp,
  onSaveNewProp,
  onDeleteProp,
  newProp,
  showNewRow,
  disabledNewRow
}) {
  const { t } = useTranslation();

  const [collapseStates, setCollapseStates] = useState({});

  const [isLoading, setIsLoading] = useState(true);

  const [showNewPropForm, setShowNewPropForm] = useState(null);

  const [indexedProperties, setIndexedProperties] = useState({});

  const [deepestLevel, setDeepestLevel] = useState(1);

  useEffect(() => {
    if (body) {
      const indexedProperties = getIndexedProperties(body, setDeepestLevel);

      if (indexedProperties) {
        setIndexedProperties(indexedProperties);
      }
    }
  }, [body]);

  useEffect(() => {
    if (isLoading) {
      setIsLoading(false);
    }

    if (showNewRow) {
      setShowNewPropForm({ 0: true });
    }
  }, [isLoading, showNewRow]);

  function toggleCollapseGroup(index) {
    const group = document.getElementById(`group${index}`);

    const collapsed = collapseStates[index];

    if (group) {
      group.style.display = collapsed ? 'block' : 'none';
    }

    setCollapseStates({ ...collapseStates, [index]: !collapsed });
  }

  function handleNewProp(name, value, index, parentProperties, groupId) {
    if (name && typeof name === 'string') {
      onChangeNewProp(indexedProperties, name, value, index, parentProperties, groupId);
    } else {
      setShowNewPropForm({ [groupId]: false });

      onCancelNewProp(groupId);
    }
  }

  function handleSaveNewProp(ev, groupId) {
    setShowNewPropForm({ [groupId]: false });

    onSaveNewProp(indexedProperties);
  }

  function onAddNewProp(groupId) {
    if (groupId) {
      setShowNewPropForm({ [groupId]: true });
    }
  }

  function handleDeleteProp(prop) {
    setIsLoading(true);

    onDeleteProp(indexedProperties, prop);
  }

  function getNewPropForm(groupId, newPropIndex, parentProperties) {
    const index = newPropIndex || '1';

    const property = {
      ...newProp,
      index,
      parent: { type: parentProperties?.type, index: parentProperties?.index },
      groupId
    };

    return (
      <div key={`${groupId}-edit-container`}>
        <Row key={`${groupId}-edit-row`}>
          {newEditColumns.map((column) => (
            <Column
              key={`${groupId}-${column.key}`}
              $minWidth={column.minWidth}
              $maxWidth={column.maxWidth}
              $centered={column.centered}
              $grow={column.grow}
              $shrink={column.shrink}
            >
              {column?.onRender
                ? column.onRender(
                    property,
                    (name, value) => handleNewProp(name, value, index, parentProperties, groupId),
                    (ev) => handleSaveNewProp(ev, groupId)
                  )
                : ''}
            </Column>
          ))}
        </Row>
      </div>
    );
  }

  let hasGroups = false;

  function getPropList(bodyProps) {
    const getPropertyRow = (passedProp, propKey) => {
      const prop = { [propKey]: { ...passedProp } };
      const { index } = passedProp;

      const isGroupRow = passedProp.type === 'array' || passedProp.type === 'object';
      const RowStyled = isGroupRow ? GroupHeaderRow : Row;

      // calculate width of first column (in case of multiple levels) to keep other columns in same line
      const calculateColumnWidth = (column, passedProp, type) => {
        let columnWidth = type === 'min' ? column.minWidth : column.maxWidth;

        if (column.fieldName === 'internalName') {
          if (isGroupRow) {
            columnWidth += (deepestLevel - 1 - passedProp.level) * collapseButtonWidth;
          } else {
            columnWidth += (deepestLevel - passedProp.level) * collapseButtonWidth;
          }
        }

        return columnWidth;
      };

      return (
        <RowStyled key={`${index}-row`}>
          {isGroupRow ? (
            <CollapseButton key={`${index}-collapseBtn`} $width={collapseButtonWidth}>
              <IconButton
                key={`${index}-collapseIcon`}
                id={`${index}-collapseIcon`}
                className="toggleCollapseButton"
                iconProps={{
                  iconName: collapseStates[index] ? 'ChevronRight' : 'ChevronDown'
                }}
                onClick={() => toggleCollapseGroup(index)}
                styles={{
                  icon: {
                    fontSize: '10px',
                    fontWeight: '600'
                  }
                }}
              />
            </CollapseButton>
          ) : null}
          {columns.map((column) => (
            <Column
              key={`${index}-${column.key}`}
              $minWidth={calculateColumnWidth(column, passedProp, 'min')}
              $maxWidth={calculateColumnWidth(column, passedProp, 'max')}
              $centered={column.centered}
              $grow={column.grow}
              $shrink={column.shrink}
            >
              {column?.onRender
                ? column.onRender(
                    indexedProperties,
                    prop,
                    () => onAddNewProp(passedProp.group),
                    () => handleDeleteProp(prop)
                  )
                : prop[propKey][column.fieldName]}
            </Column>
          ))}
        </RowStyled>
      );
    };

    const getPropertiesRows = (passedProps) => {
      const mainGroupItems = Object.keys(passedProps).map((key) => {
        const property = passedProps[key];

        const groupItems = [];

        let currentRow;

        if (property.type === 'array') {
          hasGroups = true;

          currentRow = getPropertyRow(property, key);

          const newChildIndex = property.items?.properties
            ? `${property.index}-${Object.keys(property.items.properties).length + 1}`
            : `${property.index}-1`;

          const editor =
            newEditColumns?.length && showNewPropForm?.[property.group]
              ? getNewPropForm(property.group, newChildIndex, property)
              : null;

          const group = (
            <div key={`${property.group}-group-container`}>
              {currentRow}
              <Group
                key={`group${property.group || 0}`}
                id={`group${property.group || 0}`}
                className={`group${property.group || 0}`}
                level={property.level}
              >
                {property.items?.properties
                  ? getPropertiesRows(property.items.properties, property.level, property.parent)
                  : null}
                {editor}
              </Group>
            </div>
          );

          groupItems.push(group);
        } else if (property.type === 'object') {
          hasGroups = true;

          currentRow = getPropertyRow(property, key);

          const newChildIndex = property.properties
            ? `${property.index}-${Object.keys(property.properties).length + 1}`
            : `${property.index}-1`;

          const editor =
            newEditColumns?.length && showNewPropForm?.[property.group]
              ? getNewPropForm(property.group, newChildIndex, property)
              : null;

          const group = (
            <div key={`${property.group}-group-container`}>
              {currentRow}
              <Group
                key={`group${property.group || 0}`}
                id={`group${property.group || 0}`}
                className={`group${property.group || 0}`}
                level={property.level}
              >
                {property.properties
                  ? getPropertiesRows(property.properties, property.level, property.parent)
                  : null}
                {editor}
              </Group>
            </div>
          );

          groupItems.push(group);
        } else {
          currentRow = getPropertyRow(property, key);

          return currentRow;
        }

        return groupItems;
      });

      return mainGroupItems;
    };

    let groups = null;
    if (bodyProps) {
      groups = getPropertiesRows(bodyProps);
    }

    const headerRow = (
      <HeaderRow key="header">
        {columns.map((column) => (
          <Column
            key={column.key}
            $minWidth={
              column.fieldName === 'internalName'
                ? (deepestLevel - 1) * collapseButtonWidth + column.minWidth
                : column.minWidth
            }
            $maxWidth={
              column.fieldName === 'internalName'
                ? (deepestLevel - 1) * collapseButtonWidth + column.maxWidth
                : column.maxWidth
            }
            $centered={column.centered}
            $grow={column.grow}
            $shrink={column.shrink}
          >
            {column.infoText ? (
              <ColumnNameAndInfo>
                <span style={{ fontWeight: 600 }}>{column.name}</span>
                <TooltipHost
                  tooltipProps={{
                    onRenderContent: () => <div>{column.infoText}</div>
                  }}
                  directionalHint={DirectionalHint.topCenter}
                  calloutProps={{ gapSpace: 5 }}
                  styles={{
                    root: { display: 'inline-block', maxWidth: 200 }
                  }}
                >
                  <InfoIcon iconName="Info" className="info-icon" />
                </TooltipHost>
              </ColumnNameAndInfo>
            ) : (
              column.name
            )}
          </Column>
        ))}
      </HeaderRow>
    );

    let propRows = [headerRow];

    if (groups) {
      propRows = propRows.concat(groups);
    }

    const newChildIndex = `${Object.keys(indexedProperties).length + 1}`;
    const editor =
      newEditColumns?.length && (!hasGroups || showNewPropForm?.['0'])
        ? getNewPropForm('0', newChildIndex)
        : null;

    return (
      <div>
        {propRows}
        {!disabledNewRow && editor}
      </div>
    );
  }

  if (isLoading) {
    return <LoadingSpinner label={t('loading.bodyPropertiesList.text')} />;
  }

  return columns?.length ? (
    <ListContainerStyled className="bodyPropertiesList">
      {getPropList(indexedProperties)}
    </ListContainerStyled>
  ) : null;
}

BodyPropertiesList.propTypes = {
  body: PropTypes.object,
  columns: PropTypes.arrayOf(PropTypes.any).isRequired,
  newEditColumns: PropTypes.arrayOf(PropTypes.any),
  onChangeNewProp: PropTypes.func,
  onCancelNewProp: PropTypes.func,
  onSaveNewProp: PropTypes.func,
  onDeleteProp: PropTypes.func,
  newProp: PropTypes.object,
  showNewRow: PropTypes.number,
  disabledNewRow: PropTypes.bool
};

BodyPropertiesList.defaultProps = {
  body: null,
  newEditColumns: null,
  onChangeNewProp: null,
  onCancelNewProp: null,
  onSaveNewProp: null,
  onDeleteProp: null,
  newProp: null,
  showNewRow: null,
  disabledNewRow: null
};

export default BodyPropertiesList;

export const getIndexedProperties = (passedBody, updateDeepestLevel) => {
  let indexedProperties = {};

  let deepestLevel = 1;

  if (passedBody) {
    let newProperties = null;
    let required = null;

    if (passedBody.type === 'object') {
      newProperties = cloneObject(passedBody.properties);
      required = passedBody.required;
    } else if (passedBody.type === 'array') {
      newProperties = cloneObject(passedBody.items?.properties);
      required = passedBody.items?.required;
    }

    if (newProperties) {
      const setIndexes = (
        passedProperties,
        passedRequired,
        passedParent,
        passedIndexCount,
        passedGroupCount,
        passedLevelCount
      ) => {
        if (passedProperties) {
          let propsCounter = 0;

          let mainGroupProps = {};

          Object.keys(passedProperties).map((key) => {
            const subGroupProps = {};

            propsCounter += 1;

            let localIndexCounter = `${propsCounter}`;

            if (passedIndexCount) {
              localIndexCounter = `${passedIndexCount}-${propsCounter}`;
            }

            let localGroupCounter = passedGroupCount || '0';
            let localLevelCounter = passedLevelCount || 1;

            const property = passedProperties[key];

            let propType = property.type;

            let newProps = {};

            if (Array.isArray(property.type)) {
              propType = property.type.find((x) => x !== null);
            }

            if (propType === 'array') {
              localGroupCounter = localIndexCounter ? `${localIndexCounter}` : `${propsCounter}`;

              deepestLevel = deepestLevel < localLevelCounter ? localLevelCounter : deepestLevel;

              newProps = {
                ...property,
                type: propType,
                index: localIndexCounter,
                level: localLevelCounter,
                group: localGroupCounter,
                parent: passedParent
              };

              const isRequired = passedRequired?.find((x) => x === key);

              if (isRequired) {
                newProps.isRequired = isRequired;
              }

              if (property?.items?.properties) {
                const parent = { type: property.type, index: localIndexCounter };

                localLevelCounter += 1;

                deepestLevel = deepestLevel < localLevelCounter ? localLevelCounter : deepestLevel;

                localIndexCounter =
                  localLevelCounter > 1 ? `${localIndexCounter}` : `${propsCounter}`;

                const arrayProps = setIndexes(
                  property.items.properties,
                  property.items.required,
                  parent,
                  localIndexCounter,
                  localGroupCounter,
                  localLevelCounter
                );

                newProps.items = { properties: arrayProps, type: 'object' };
              }

              subGroupProps[key] = newProps;
            } else if (propType === 'object') {
              localGroupCounter = localIndexCounter ? `${localIndexCounter}` : `${propsCounter}`;

              deepestLevel = deepestLevel < localLevelCounter ? localLevelCounter : deepestLevel;

              newProps = {
                ...property,
                type: propType,
                index: localIndexCounter,
                level: localLevelCounter,
                group: localGroupCounter,
                parent: passedParent
              };

              const isRequired = passedRequired?.find((x) => x === key);

              if (isRequired) {
                newProps.isRequired = isRequired;
              }

              if (property?.properties) {
                const parent = { type: property.type, index: localIndexCounter };

                localLevelCounter += 1;

                deepestLevel = deepestLevel < localLevelCounter ? localLevelCounter : deepestLevel;

                localIndexCounter =
                  localLevelCounter > 1 ? `${localIndexCounter}` : `${propsCounter}`;

                newProps.properties = setIndexes(
                  property.properties,
                  property.required,
                  parent,
                  localIndexCounter,
                  localGroupCounter,
                  localLevelCounter
                );
              }

              subGroupProps[key] = newProps;
            } else {
              deepestLevel = deepestLevel < localLevelCounter ? localLevelCounter : deepestLevel;

              newProps = {
                ...property,
                type: propType,
                index: localIndexCounter,
                level: localLevelCounter,
                group: localGroupCounter,
                parent: passedParent
              };

              const isRequired = passedRequired?.find((x) => x === key);

              if (isRequired) {
                newProps.isRequired = isRequired;
              }

              mainGroupProps[key] = newProps;
            }

            mainGroupProps = { ...mainGroupProps, ...subGroupProps };

            return null;
          });

          return mainGroupProps;
        }

        return null;
      };

      indexedProperties = setIndexes(newProperties, required);
    }
  }

  if (updateDeepestLevel) {
    updateDeepestLevel(deepestLevel);
  }

  return indexedProperties;
};
