import { OpenAIClient } from '@azure/openai';
import config from '../config';
import { acquireAccessToken } from '../features/Authentication';
import { ApiDataCache } from '../hooks/api2/cache';

const DEPLOYMENT_NAME = 'dall-e-3';

export interface IGenImageOptions {
  textPrompt: string;
  quality: 'standard' | 'hd';
  style: 'natural' | 'vivid';
  generateAsSize: '1024x1024' | '1792x1024' | '1024x1792';
  outputSize: [number, number];
}

export async function generateImage(cache: ApiDataCache, options: IGenImageOptions): Promise<Blob> {
  const client = new OpenAIClient(new URL(config.AI_DIRECT_URL, window.location.href).href, {
    async getToken() {
      const result = await acquireAccessToken();
      return { token: result.accessToken, expiresOnTimestamp: result.expiresOn.getTime() };
    }
  });

  const result = await client.getImages(DEPLOYMENT_NAME, options.textPrompt, {
    n: 1,
    size: options.generateAsSize,
    quality: options.quality,
    style: options.style,
    responseFormat: 'b64_json',
    user: cache.memberId
  });

  const image = result.data[0];
  if (!image) throw new Error('no result image');
  if (!image.base64Data) throw new Error('no result');

  const imageData = new Image();

  await new Promise((resolve, reject) => {
    imageData.addEventListener('load', resolve);
    imageData.addEventListener('error', reject);
    imageData.src = `data:image/png;base64,${image.base64Data}`;
  });

  const canvas = document.createElement('canvas');
  [canvas.width, canvas.height] = options.outputSize;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(imageData, 0, 0, canvas.width, canvas.height);

  return new Promise((resolve) => {
    canvas.toBlob(resolve, 'image/png');
  });
}
