/* eslint-disable no-param-reassign */

import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { checkScreenWidth, cloneObject } from 'utils/helpers';
import {
  CommandBarButton,
  DirectionalHint,
  Dropdown,
  Icon,
  Label,
  Stack,
  TextField,
  TooltipHost
} from '@fluentui/react';
import RequestFieldsList from './RequestFieldsList';
import ResponseBodyList from './ResponseBodyList';
import { authTypeOptions } from './ServiceConnectionPivotItem';

// styles
const ListConnectionTabStyled = styled.div`
  padding: 1rem;
  padding-bottom: 3rem;

  .form-fields-width {
    width: 100%;
  }
`;

const CustomLabelStyled = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
  margin-bottom: 5px;
  padding-top: 0;

  .info-icon {
    font-size: 11px;
    position: absolute;
    top: 3px;
    color: rgb(0, 120, 212);
  }
`;

const InfoIcon = styled(Icon)`
  margin: 0 10px 0 5px;

  &:hover {
    cursor: help;
  }
`;

const TabDescriptionStyled = styled.div`
  margin-bottom: 10px;
  padding-bottom: 10px;
  font-size: 0.9em;
  color: #808080;
  font-style: italic;
  border-bottom: 1px solid rgb(235, 235, 235);
`;

function ServiceListConnectionPivotItem({
  service,
  handleServiceState,
  fieldToFocus,
  handleErrors,
  inputErrors
}) {
  const { t } = useTranslation();

  const [showNewEditQueryFieldDialog, setShowNewEditQueryFieldDialog] = useState(false);
  const [showNewEditHeaderFieldDialog, setShowNewEditHeaderFieldDialog] = useState(false);

  const maxStringLength = checkScreenWidth(['small']) ? 35 : 100;

  function handleListConnection(name, value) {
    const newService = cloneObject(service);
    const listConnection = newService?.serviceData?.listConnection
      ? { ...newService?.serviceData?.listConnection }
      : {};

    let newValue = value;

    if (name === 'queryField' && Array.isArray(value)) {
      [newValue] = value;
    }

    listConnection[name] = newValue;
    newService.serviceData.listConnection = listConnection;

    handleServiceState(newService);
  }

  const handleUrl = debounce((value) => {
    handleListConnection('url', value);
  }, 400);

  const handleAllItemsUrl = debounce((value) => {
    handleListConnection('allItemsUrl', value);
  }, 400);

  function handleContentType(value) {
    handleListConnection('contentType', value.key);
  }

  function handleAuthType(value) {
    handleListConnection('auth', value.key);
  }

  function handleScopes(value) {
    const scopes = value.split(',');

    handleListConnection('scopes', scopes);
  }

  function handleAuthority(value) {
    let appAuthConfig = { authority: value };

    if (service?.serviceData?.listConnection?.appAuthConfig) {
      appAuthConfig = { ...service.serviceData.listConnection.appAuthConfig, authority: value };
    }

    handleListConnection('appAuthConfig', appAuthConfig);
  }

  function handleUsername(username) {
    let basicAuthConfig = { username };

    if (service?.serviceData?.listConnection?.basicAuthConfig) {
      basicAuthConfig = { ...service.serviceData.listConnection.basicAuthConfig, username };
    }

    handleListConnection('basicAuthConfig', basicAuthConfig);
  }

  function handlePassword(password) {
    let basicAuthConfig = { password };

    if (service?.serviceData?.listConnection?.basicAuthConfig) {
      basicAuthConfig = { ...service.serviceData.listConnection.basicAuthConfig, password };
    }

    handleListConnection('basicAuthConfig', basicAuthConfig);
  }

  function handleClientId(value) {
    let appAuthConfig = { clientId: value };

    if (service?.serviceData?.listConnection?.appAuthConfig) {
      appAuthConfig = { ...service.serviceData.listConnection.appAuthConfig, clientId: value };
    }

    handleListConnection('appAuthConfig', appAuthConfig);
  }

  function handleClientSecret(value) {
    let appAuthConfig = { clientSecret: value };

    if (service?.serviceData?.listConnection?.appAuthConfig) {
      appAuthConfig = { ...service.serviceData.listConnection.appAuthConfig, clientSecret: value };
    }

    handleListConnection('appAuthConfig', appAuthConfig);
  }

  function onRenderLabelWithInfo(props, infoText) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    const fieldProps = { ...props };

    return (
      <CustomLabelStyled>
        <span style={{ fontWeight: 600 }}>{fieldProps.label}</span>
        <TooltipHost
          content={infoText}
          directionalHint={DirectionalHint.bottomLeftEdge}
          calloutProps={{ gapSpace: 5, target: `#${fieldProps.id}` }}
          styles={{
            root: { display: 'inline-block', maxWidth: 200 }
          }}
        >
          <InfoIcon iconName="Info" className="info-icon" id="scopes-info-icon" />
        </TooltipHost>
      </CustomLabelStyled>
    );
  }

  function getAuthenticationFields() {
    if (service?.serviceData?.listConnection?.auth === 1) {
      return (
        <TextField
          id="scopes-field"
          disabled={false}
          label={t('serviceListConnectionPivotItem.label.scopes')}
          placeholder={t('serviceListConnectionPivotItem.placeholder.scopes')}
          onChange={(ev, value) => handleScopes(value)}
          required={false}
          value={service?.serviceData?.listConnection?.scopes || ''}
          multiline={service?.serviceData?.listConnection?.scopes?.length > maxStringLength}
          autoAdjustHeight
          onRenderLabel={(props) =>
            onRenderLabelWithInfo(props, t('serviceListConnectionPivotItem.info.scopes'))
          }
        />
      );
    }

    if (service?.serviceData?.listConnection?.auth === 2) {
      return (
        <>
          <TextField
            disabled={false}
            label={t('serviceListConnectionPivotItem.label.appAuthConfig.authority')}
            onChange={(ev, value) => handleAuthority(value)}
            required={false}
            defaultValue={service?.serviceData?.listConnection?.appAuthConfig?.authority}
            multiline={
              service?.serviceData?.listConnection?.appAuthConfig?.authority?.length >
              maxStringLength
            }
            autoAdjustHeight
          />
          <TextField
            disabled={false}
            label={t('serviceListConnectionPivotItem.label.appAuthConfig.clientId')}
            onChange={(ev, value) => handleClientId(value)}
            required={false}
            defaultValue={service?.serviceData?.listConnection?.appAuthConfig?.clientId}
            multiline={
              service?.serviceData?.listConnection?.appAuthConfig?.clientId?.length >
              maxStringLength
            }
            autoAdjustHeight
          />
          <TextField
            disabled={false}
            label={t('serviceListConnectionPivotItem.label.appAuthConfig.clientSecret')}
            onChange={(ev, value) => handleClientSecret(value)}
            required={false}
            defaultValue={service?.serviceData?.listConnection?.appAuthConfig?.clientSecret}
            multiline={
              service?.serviceData?.listConnection?.appAuthConfig?.clientSecret?.length >
              maxStringLength
            }
            autoAdjustHeight
          />
        </>
      );
    }

    if (service?.serviceData?.listConnection?.auth === 3) {
      return (
        <>
          <TextField
            disabled={false}
            label={t('serviceConnectionPivotItem.label.basicAuthConfig.username')}
            onChange={(ev, value) => handleUsername(value)}
            required
            defaultValue={service?.serviceData?.listConnection?.basicAuthConfig?.username}
            autoAdjustHeight
          />
          <TextField
            disabled={false}
            label={t('serviceConnectionPivotItem.label.basicAuthConfig.password')}
            onChange={(ev, value) => handlePassword(value)}
            required
            type="password"
            canRevealPassword
            revealPasswordAriaLabel={t(
              'serviceConnectionPivotItem.label.basicAuthConfig.passwordReveal'
            )}
            defaultValue={service?.serviceData?.listConnection?.basicAuthConfig?.password}
          />
        </>
      );
    }

    return null;
  }

  function onDismissQueryFieldDialog() {
    setShowNewEditQueryFieldDialog(false);
  }

  function onDismissHeaderFieldDialog() {
    setShowNewEditHeaderFieldDialog(false);
  }

  function handleResponseBody(type, value) {
    const newService = cloneObject(service);

    let responseBody = { ...newService?.serviceData?.listConnection?.responseBody };

    if (type === 'responseType' && value !== responseBody?.type) {
      let newBodyProps = null;

      if (value === 'object') {
        newBodyProps = cloneObject(responseBody?.items.properties);
        responseBody = { properties: newBodyProps, type: value };
      } else if (value === 'array') {
        newBodyProps = cloneObject(responseBody?.properties);
        responseBody = { items: { properties: newBodyProps, type: 'object' }, type: value };
      }
    } else if (type === 'properties' && value) {
      const properties = {};

      const setProperties = (newProperties, passedProps) => {
        if (passedProps) {
          Object.keys(passedProps).map((propKey) => {
            newProperties[propKey] = {
              'x-ep-mappedField': passedProps[propKey]['x-ep-mappedField'],
              type: passedProps[propKey].type,
              'x-ep-field': passedProps[propKey]['x-ep-field'],
              'x-ep-key': passedProps[propKey]['x-ep-key']
            };

            if (passedProps[propKey].type === 'object') {
              const objProperties = {};

              setProperties(objProperties, passedProps[propKey].properties);

              newProperties[propKey].properties = objProperties;
            } else if (passedProps[propKey].type === 'array') {
              const arrayProperties = {};

              setProperties(arrayProperties, passedProps[propKey].items?.properties);

              newProperties[propKey].items = { properties: arrayProperties, type: 'object' };
            }

            return null;
          });
        }
      };

      setProperties(properties, value);

      if (newService?.serviceData?.listConnection?.responseBody?.type === 'array') {
        responseBody = {
          items: { properties, type: 'object' },
          type: newService.serviceData?.listConnection?.responseBody?.type
        };
      } else {
        responseBody = {
          properties,
          type: newService?.serviceData?.listConnection?.responseBody?.type || 'object'
        };
      }
    }

    if (!newService?.serviceData.listConnection) {
      newService.serviceData.listConnection = {};
    }

    newService.serviceData.listConnection.responseBody = responseBody;

    handleServiceState(newService);
  }

  function handleErrorsListConnection(errors) {
    handleErrors('list-connection', errors);
  }

  function onlyDefaultValuesAllowed() {
    const header = service?.serviceData?.listConnection?.headerFields;
    const query = service?.serviceData?.listConnection?.queryField;

    let numberOfRequestFields = 0;

    if (header) {
      header.map((prop) => {
        if (!prop.defaultValue) {
          numberOfRequestFields += 1;
        }

        return null;
      });
    }

    if (!numberOfRequestFields && query && !query.defaultValue) {
      numberOfRequestFields += 1;
    }

    return numberOfRequestFields > 0;
  }

  let errorsInHeaderList = false;
  let errorsInQueryList = false;

  if (inputErrors?.length) {
    inputErrors.map((err) => {
      if (err.inputId.endsWith('-internalName-txt') && err.field && err.message) {
        if (err.inputId.startsWith('header-')) {
          errorsInHeaderList = true;
        }
        if (err.inputId.startsWith('query-')) {
          errorsInQueryList = true;
        }
      }

      return null;
    });
  }

  return (
    <ListConnectionTabStyled>
      <TabDescriptionStyled>{t('serviceListConnectionPivotItem.description')}</TabDescriptionStyled>
      <TextField
        id="listConnection-url"
        autoFocus
        disabled={false}
        label={t('serviceListConnectionPivotItem.label.url')}
        onChange={(ev, value) => handleUrl(value)}
        required
        defaultValue={service?.serviceData?.listConnection?.url}
        multiline={service?.serviceData?.listConnection?.url?.length > maxStringLength}
        autoAdjustHeight
        errorMessage={fieldToFocus?.name === 'listConnection-url' ? fieldToFocus?.message : null}
      />
      <TextField
        id="listConnection-allItemsUrl"
        disabled={false}
        label={t('serviceListConnectionPivotItem.label.allItemsUrl')}
        onChange={(ev, value) => handleAllItemsUrl(value)}
        required={false}
        defaultValue={service?.serviceData?.listConnection?.allItemsUrl}
        multiline={service?.serviceData?.listConnection?.allItemsUrl?.length > maxStringLength}
        autoAdjustHeight
      />
      <Dropdown
        label="Content-Type"
        styles={{ root: { width: '50%', marginLeft: 'auto' } }}
        defaultSelectedKey={service?.serviceData?.listConnection?.contentType}
        onChange={(ev, value) => handleContentType(value)}
        options={[
          {
            key: 1,
            text: 'JSON'
          },
          {
            key: 2,
            text: 'XML'
          }
        ]}
      />
      <div style={{ marginTop: '25px', marginBottom: '25px' }}>
        <Label style={{ borderBottom: '1px solid #D3D3D3', width: '100%', marginBottom: '5px' }}>
          {t('serviceListConnectionPivotItem.label.request')}
        </Label>
        <Label>{t('serviceListConnectionPivotItem.label.headerField')}</Label>
        <Stack horizontal styles={{ root: { height: 44 } }}>
          <CommandBarButton
            id="add-new-header-field-btn"
            iconProps={{ iconName: 'Add' }}
            text={t('serviceListConnectionPivotItem.label.newHeaderField')}
            disabled={!service?.serviceData?.listConnection?.url || errorsInHeaderList}
            onClick={() => setShowNewEditHeaderFieldDialog(true)}
            style={{ borderBottom: '1px solid #D3D3D3' }}
          />
        </Stack>
        <RequestFieldsList
          hidden={
            (onlyDefaultValuesAllowed() || !service?.serviceData?.listConnection?.url) &&
            !service?.serviceData?.listConnection?.headerFields?.length
          }
          fields={service?.serviceData?.listConnection?.headerFields}
          saveFields={(props) => handleListConnection('headerFields', props)}
          showCreateDialog={showNewEditHeaderFieldDialog}
          hideCreateDialog={onDismissHeaderFieldDialog}
          disabledNewRow={
            onlyDefaultValuesAllowed() ||
            !service?.serviceData?.listConnection?.url ||
            errorsInHeaderList
          }
          url={service?.serviceData?.listConnection?.url}
          propType="header"
          forAutocomplete
          handleErrors={handleErrorsListConnection}
          queryFieldsErrors={inputErrors}
        />
      </div>
      <div style={{ marginTop: '25px', marginBottom: '25px' }}>
        <Label>{t('serviceListConnectionPivotItem.label.queryField')}</Label>
        <Stack horizontal styles={{ root: { height: 44 } }}>
          <CommandBarButton
            id="add-new-query-field-btn"
            iconProps={{ iconName: 'Add' }}
            text={t('serviceListConnectionPivotItem.label.newQueryField')}
            disabled={!service?.serviceData?.listConnection?.url || errorsInQueryList}
            onClick={() => setShowNewEditQueryFieldDialog(true)}
            style={{ borderBottom: '1px solid #D3D3D3' }}
          />
        </Stack>
        <RequestFieldsList
          hidden={
            (onlyDefaultValuesAllowed() || !service?.serviceData?.listConnection?.url) &&
            !service?.serviceData?.listConnection?.queryField?.internalName
          }
          fields={service?.serviceData?.listConnection?.queryField}
          saveFields={(props) => handleListConnection('queryField', props)}
          showCreateDialog={showNewEditQueryFieldDialog}
          hideCreateDialog={onDismissQueryFieldDialog}
          disabledNewRow={
            onlyDefaultValuesAllowed() ||
            !service?.serviceData?.listConnection?.url ||
            errorsInQueryList
          }
          url={service?.serviceData?.listConnection?.url}
          propType="query"
          forAutocomplete
          handleErrors={handleErrorsListConnection}
          queryFieldsErrors={inputErrors}
        />
      </div>
      <div style={{ marginTop: '30px', marginBottom: '30px' }}>
        <Label style={{ borderBottom: '1px solid #D3D3D3', width: '100%' }}>
          {t('serviceListConnectionPivotItem.label.response')}
        </Label>
        <ResponseBodyList
          responseBody={service?.serviceData?.listConnection?.responseBody}
          handleResponseBody={handleResponseBody}
          propType="body"
          disabledNewRow={!service?.serviceData?.listConnection?.url}
          forListConnection
          handleErrors={handleErrorsListConnection}
          bodyPropertiesErrors={inputErrors}
        />
      </div>
      <Dropdown
        label={t('serviceListConnectionPivotItem.label.authType')}
        placeholder={t('serviceListConnectionPivotItem.placeholder.authType')}
        options={authTypeOptions(t)}
        errorMessage={fieldToFocus?.name === 'auth' ? fieldToFocus?.message : null}
        defaultSelectedKey={
          service?.serviceData?.listConnection?.auth ||
          service?.serviceData?.listConnection?.auth === 0
            ? service?.serviceData?.listConnection?.auth
            : null
        }
        onChange={(_, value) => handleAuthType(value)}
      />
      {getAuthenticationFields()}
    </ListConnectionTabStyled>
  );
}

ServiceListConnectionPivotItem.propTypes = {
  service: PropTypes.any,
  handleServiceState: PropTypes.func.isRequired,
  fieldToFocus: PropTypes.object,
  handleErrors: PropTypes.func,
  inputErrors: PropTypes.arrayOf(PropTypes.object)
};

ServiceListConnectionPivotItem.defaultProps = {
  service: null,
  fieldToFocus: null,
  handleErrors: null,
  inputErrors: null
};

export default ServiceListConnectionPivotItem;
