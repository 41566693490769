import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from '@fluentui/react';

const DialogContent = styled.div`
  margin-top: 16px;
  font-weight: 300;
  font-size: 14px;
`;

function ExpiredTestPeriodDialog({ currentTenantId, tenants }) {
  const { t } = useTranslation();

  let changeTenantButton = null;

  if (!currentTenantId || !tenants) {
    return null;
  }

  const isDialogHidden = !tenants.find(
    (tenant) => tenant.tenantId === currentTenantId && tenant.status === 5
  );

  function onSendRequest() {
    window.location = t('expiredTestPeriodDialog.email', { title: 'Evocom' });
  }

  function setCurrentTenant(tenantId) {
    window.localStorage.setItem('tenantId', tenantId);
    window.location.href = '/tasks/my-open';
  }

  const dialogContentProps = {
    type: DialogType.normal,
    title: t('expiredTestPeriodDialog.title')
  };

  const styles = {
    main: {
      selectors: {
        '@media (min-width: 650px)': {
          width: '100%',
          minWidth: '500px',
          minHeight: '200px'
        },
        '@media (max-width: 480px)': {
          minHeight: '100%',
          minWidth: '100%'
        }
      }
    }
  };

  if (tenants?.length > 1) {
    const items = tenants
      .filter((tenant) => tenant.tenantId !== currentTenantId)
      .map((tenant) => ({
        key: tenant.tenantId,
        text: tenant.name,
        onClick: () => setCurrentTenant(tenant.tenantId)
      }));
    const menuProps = { items, directionalHintFixed: true };

    changeTenantButton = (
      <DefaultButton
        text={t('expiredTestPeriodDialog.changeTenant')}
        iconProps="Sync"
        menuProps={menuProps}
        allowDisabledFocus
      />
    );
  }

  return (
    <Dialog
      modalProps={{ styles: { root: { minWidth: 500 }, main: { minWidth: 500 } } }}
      dialogContentProps={dialogContentProps}
      hidden={isDialogHidden}
      dialogDefaultMaxWidth={500}
      styles={styles}
    >
      <DialogContent>
        {t('expiredTestPeriodDialog.content-1')}
        <div style={{ marginTop: '5px' }}>
          {t('expiredTestPeriodDialog.content-2', {
            title: 'Evocom'
          })}
        </div>
      </DialogContent>
      <DialogFooter>
        {changeTenantButton}
        <PrimaryButton onClick={onSendRequest} text={t('expiredTestPeriodDialog.send')} />
      </DialogFooter>
    </Dialog>
  );
}

ExpiredTestPeriodDialog.propTypes = {
  currentTenantId: PropTypes.string.isRequired,
  tenants: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, tenantId: PropTypes.string })
  ).isRequired
};

export default ExpiredTestPeriodDialog;
