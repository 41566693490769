import { TasksViewType } from 'features/Tasks/model';
import { t } from 'i18next';
import styled, { useTheme } from 'styled-components';
import { DefaultButton } from '@fluentui/react';

const ViewContainer = styled.div`
  padding: 4px;
  margin: 3px;
  display: flex;
  justify-content: space-around;
  border-radius: ${({ theme }) => theme.tasks.depictionSettingsCornerRadius};
  background-color: rgb(${({ theme }) => theme.tasks.depictionSettingsBackground});
  overflow: hidden;
  gap: 2px;
`;

const ItemLinkStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 6px;
`;

interface IOption {
  value: TasksViewType;
  selected: boolean;
}
export interface ITasksViewSettingsProps {
  options: IOption[];
  onChange: (options: IOption[]) => void;
}

export default function TasksViewSettings({ options, onChange }: ITasksViewSettingsProps) {
  function getIconNameByKey(key: string) {
    switch (key) {
      case TasksViewType.Dashboard:
        return 'BarChartVerticalFill';
      case TasksViewType.List:
        return 'BulletedList';
      case TasksViewType.Kanban:
        return 'Tiles';
      case TasksViewType.Gantt:
        return 'TimelineMatrixView';
      case TasksViewType.Grid:
        return 'BulletedTreeList';
      default:
        return 'Tiles';
    }
  }

  function getLabelByKey(key: string) {
    switch (key) {
      case TasksViewType.Dashboard:
        return t('subheader.tasks.commandbar.dashboardView');
      case TasksViewType.List:
        return t('subheader.tasks.commandbar.listView');
      case TasksViewType.Kanban:
        return t('subheader.tasks.commandbar.cardView');
      case TasksViewType.Gantt:
        return t('subheader.tasks.commandbar.ganttView');
      case TasksViewType.Grid:
        return t('subheader.tasks.commandbar.gridView');
      default:
        return t('subheader.tasks.commandbar.cardView');
    }
  }

  function onItemLinkClick(key: string) {
    const newOptions = options.map(({ value, selected }) => {
      if (value === key) {
        return { value, selected: !selected };
      }

      return { value, selected: false };
    });

    onChange(newOptions);
  }

  return (
    <ViewContainer>
      {options.map((option) => (
        <ItemLink
          onChange={onItemLinkClick}
          key={option.value}
          itemKey={option.value}
          iconName={getIconNameByKey(option.value)}
          checked={option.selected}
          label={getLabelByKey(option.value)}
        />
      ))}
    </ViewContainer>
  );
}

interface IItemLinkProps {
  iconName: string;
  itemKey?: string;
  checked?: boolean;
  label?: string;
  onChange: (key: string) => void;
}

function ItemLink({ itemKey, iconName, checked, label, onChange }: IItemLinkProps) {
  const theme = useTheme();

  return (
    <ItemLinkStyled>
      <DefaultButton
        onClick={() => {
          onChange(itemKey);
        }}
        text={label}
        checked={checked}
        styles={{
          root: {
            height: 'auto',
            paddingTop: 13,
            paddingBottom: 10,
            border: 'none',
            background: 'none',
            borderRadius: 8
          },
          label: { fontWeight: checked ? '500' : 'normal' },
          rootHovered: { background: `rgb(${theme.tasks.depictionSettingSelectedBackground})` },
          rootChecked: { background: `rgb(${theme.tasks.depictionSettingSelectedBackground})` },
          flexContainer: { flexDirection: 'column', gap: 7, justifyContent: 'center' }
        }}
        iconProps={{
          iconName,
          styles: {
            root: {
              fontSize: 20,
              color: checked ? `rgb(${theme.tasks.depictionSettingSelectedIconColor})` : undefined
            }
          }
        }}
      />
    </ItemLinkStyled>
  );
}
