import {
  getPersonaInitialsColor,
  IconButton,
  Persona,
  PersonaSize,
  Spinner
} from '@fluentui/react';
import { createContext, CSSProperties, ReactNode, useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import breakpoints from 'utils/breakpoints';
import { useTranslation } from 'react-i18next';
import AppContext from '../../../features/App/context/AppContext';
import ChatFile from './ChatFile';
import ChatItemContents from './ChatItemContents';
import { IToolRenderers } from './ChatMessageFragmentToolCall';
import {
  ChatItemType,
  IChatItem,
  IChatItemAssistant,
  IChatItemUser
} from '../../../services/ChatConnection/model';
import ChatItemAuxiliaryContents from './ChatItemAuxiliaryContents';
import { ChatItemReferenceContext, useChatItemReferences } from './ReferenceContext';
import { useNotificationContext } from '../../../features/App';
import { IAgentVersion, IMessageAI, LocalApiObject } from '../../../hooks/api2';
import { IPendingMessage } from './Chat';
import { useAgentThumbnail } from '../../../pages/AI/components/AgentEditor/AgentThumbnailSettings';
import { useSampledPalette } from './palette';
import { Rgb } from '../../../utils/themeUtils';

export default function ChatItem({
  item,
  isSending,
  onRetry,
  toolRenderers
}: {
  item: IChatItem;
  isSending?: boolean;
  onRetry?: () => Promise<void>;
  /** must be memoized! */
  toolRenderers?: IToolRenderers;
}) {
  if (item.type === ChatItemType.User) {
    return <ChatItemUser item={item} isSending={isSending} onRetry={onRetry} />;
  }

  if (
    item.type === ChatItemType.AssistantGenerating ||
    item.type === ChatItemType.AssistantCompleted
  ) {
    return <ChatItemAssistant item={item} toolRenderers={toolRenderers} onRetry={onRetry} />;
  }

  return null;
}

const scrollbarsHaveWidth = (() => {
  // determine if scrollbars are zero-width (e.g. on mobile)
  const div = document.createElement('div');
  div.style.position = 'fixed';
  div.style.zIndex = '-9999';
  div.style.width = '40px';
  div.style.height = '40px';
  div.style.overflow = 'scroll';

  const div2 = document.createElement('div');
  div2.style.height = '50px';
  div.append(div2);

  document.body.append(div);
  const hasWidth = div2.offsetWidth < 40;
  div.remove();

  return hasWidth;
})();

const ChatItemLayoutContainerStyled = styled.div`
  display: grid;
  container: chat-item-layout / inline-size;
  justify-content: center;

  &.with-scrollbar {
    margin-right: ${scrollbarsHaveWidth ? '14px' : '0'};
  }

  > .c-item-grid {
    display: grid;
    grid-template-columns: var(--wide-grid);
    width: 100cqw;
    max-width: 1400px;
    padding: 0.75rem 1.25rem;
    gap: 1.25rem;

    --wide-grid: 1fr 80ch 1fr;
    --chat-column: 2;
    --chat-column-span: 1;
    --aux-column: 3;
    --aux-column-span: 1;

    > .c-chat {
      grid-column: var(--chat-column) / span var(--chat-column-span);
      min-width: 0;
    }
    > .c-auxiliary {
      grid-column: var(--aux-column) / span var(--aux-column-span);
      min-width: 0;
    }
  }

  &[data-layout-type='wide'] > .c-item-grid {
    --wide-grid: 1fr 40ch 40ch 40ch 1fr;
    --chat-column: 2;
    --chat-column-span: 3;
    --aux-column: 5;
  }

  &[data-layout-type='wide'] {
    &[data-variant='agent'] > .c-item-grid {
      --chat-column: 2;
      --chat-column-span: 2;
      --aux-column: 4;
    }

    &[data-variant='self'] > .c-item-grid {
      --chat-column: 3;
      --chat-column-span: 2;
    }

    &[data-variant='input'] > .c-item-grid > .c-chat {
      margin: 0 auto;
      width: 100%;
      max-width: 80ch;
    }
  }

  &.without-top-padding > .c-item-grid {
    padding-top: 0;
  }

  @container chat-item-layout (max-width: 1100px) {
    > .c-item-grid {
      grid-template-columns: 1fr 80ch 4fr;

      > .c-chat {
        grid-column: 2 / span 1;
      }
      > .c-auxiliary {
        grid-column: 3 / span 1;
      }

      &.hide-auxiliary {
        grid-template-columns: 1fr 80ch 1fr;
      }
    }
  }

  @container chat-item-layout (max-width: 900px) {
    > .c-item-grid {
      grid-template-columns: 2fr 1fr;

      > .c-chat {
        grid-column: 1 / 2;
      }

      > .c-auxiliary {
        grid-column: 2 / 3;
      }

      &.hide-auxiliary {
        grid-template-columns: 1fr;

        > .c-chat {
          width: 100%;
          max-width: 80ch;
          margin: 0 auto;
        }
      }
    }
  }

  @container chat-item-layout (max-width: 600px) {
    > .c-item-grid {
      grid-template-columns: 1fr;
      grid-template-rows: auto;

      > .c-auxiliary {
        grid-column: 1 / 2;

        &:empty,
        &:not(:has(> .chat-item-transitive-empty:not(:empty))) {
          display: none;
        }
      }
    }
  }
`;

export const ChatItemLayoutContainerContext = createContext({
  layoutType: 'normal' as 'wide' | 'normal',
  hideAuxiliary: false
});

/**
 * Layout element for splitting the chat into a chat column and an auxiliary column
 */
export function ChatItemLayoutContainer({
  children,
  auxiliary,
  withoutTopPadding,
  withScrollbarSpacing,
  className,
  variant
}: {
  children?: ReactNode;
  auxiliary?: ReactNode;
  withoutTopPadding?: boolean;
  withScrollbarSpacing?: boolean;
  className?: string;
  variant?: 'agent' | 'self' | 'input';
}) {
  const { layoutType, hideAuxiliary } = useContext(ChatItemLayoutContainerContext);

  return (
    <ChatItemLayoutContainerStyled
      className={`${className ?? ''} ${withoutTopPadding ? 'without-top-padding' : ''} ${
        withScrollbarSpacing ? 'with-scrollbar' : ''
      }`}
      data-layout-type={layoutType}
      data-variant={variant}
    >
      <div className={`c-item-grid ${hideAuxiliary ? 'hide-auxiliary' : ''}`}>
        <div className="c-chat">{children}</div>
        {hideAuxiliary ? null : <div className="c-auxiliary">{auxiliary}</div>}
      </div>
    </ChatItemLayoutContainerStyled>
  );
}

const UserChatMessageStyled = styled.div`
  font-size: 1rem;
  position: relative;
  color: rgb(${({ theme }) => theme.aiChat.messageForeground});
  display: flex;
  flex-direction: column;
  contain: layout;

  .message {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .message-sending {
    margin-right: 0.5rem;
    animation: user-chat-message-sending-delayed-appearance 0.5s 0.5s backwards;
  }

  @keyframes user-chat-message-sending-delayed-appearance {
    from {
      opacity: 0;
    }
  }

  .retry-button {
    margin: 0.5rem;
  }

  .message-bubble {
    max-width: 85%;
    margin-right: 10px;
    background-color: rgb(${({ theme }) => theme.aiChat.userMessageBackground});
    color: rgb(${({ theme }) => theme.aiChat.userMessageForeground});
    border-radius: ${({ theme }) => theme.aiChat.userMessageCornerRadius};
    padding: 0.625rem 1.5rem;

    @media (max-width: ${breakpoints.extraSmallMax}px) {
      max-width: 90%;
      margin-right: 5px;
    }
  }

  &.is-failed .message-bubble {
    background-color: rgb(${({ theme }) => theme.aiChat.messageFailedBackground});
    color: rgb(${({ theme }) => theme.aiChat.messageFailedForeground});
  }

  .persona {
    align-self: baseline;
    margin-top: 10px;
    margin-bottom: 10px;

    .ms-Image-image {
      animation: none !important;
      transition: none !important;
    }
  }

  .files-container {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    align-self: flex-end;
    gap: 5px;
    margin: 5px 0;

    &:empty {
      display: none;
    }
  }

  .chat-file {
    max-width: 600px;
  }

  .file-icon {
    font-size: 25px;
    width: 30px;
  }
`;

function ChatItemUser({
  item,
  isSending,
  onRetry
}: {
  item: IChatItemUser;
  isSending?: boolean;
  onRetry?: () => void;
}) {
  const {
    globalAppState: { currentUser }
  } = useContext(AppContext);

  const { files } = item.message;

  function renderFiles() {
    return files?.map((file) => {
      return <ChatFile key={file?.id} file={file} />;
    });
  }

  const isFailed = (item.message as IPendingMessage).isFailed ?? false;

  return (
    <ChatItemLayoutContainer variant="self">
      <UserChatMessageStyled className={isFailed ? 'is-failed' : ''}>
        <div className="files-container">{renderFiles()}</div>
        <div className="message">
          {isSending ? <Spinner className="message-sending" /> : null}
          {isFailed && onRetry ? (
            <IconButton
              className="retry-button"
              iconProps={{ iconName: 'Sync' }}
              onClick={onRetry}
            />
          ) : null}
          <div className="message-bubble">
            <ChatItemContents fragments={item.fragments} />
          </div>
          <Persona
            className="persona"
            hidePersonaDetails
            imageShouldStartVisible
            imageUrl={currentUser.pictureUrl}
            size={PersonaSize.size32}
          />
        </div>
      </UserChatMessageStyled>
    </ChatItemLayoutContainer>
  );
}

const AssistantMessageStyled = styled.div`
  font-size: 1rem;
  position: relative;
  contain: layout;

  > .c-failed-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  > .c-failed-container > .c-failed {
    background-color: rgb(${({ theme }) => theme.aiChat.messageFailedBackground});
    color: rgb(${({ theme }) => theme.aiChat.messageFailedForeground});
    border-radius: 1.5rem;
    padding: 0.625rem 1.5rem;
    width: fit-content;
  }

  > .c-token-info-button {
    position: absolute;
    top: -30px;
    opacity: 0;
    transition: opacity 0.5s;
  }

  &:hover > .c-token-info-button {
    opacity: 1;
  }
`;

export const AssistantMessageEditContext = createContext<{
  edit?: (item: IChatItemAssistant) => void;
  onEdit: (listener: (item: IMessageAI) => void) => void;
  removeEditListener: (listener: (item: IMessageAI) => void) => void;
}>({
  onEdit: () => null,
  removeEditListener: () => null
});

function ChatItemAssistant({
  item,
  toolRenderers,
  onRetry
}: {
  item: IChatItemAssistant;
  toolRenderers?: IToolRenderers;
  onRetry?: () => Promise<void>;
}) {
  const references = useChatItemReferences(item);
  const { edit } = useContext(AssistantMessageEditContext);

  const { agent } = item.message;

  const content = (
    <AssistantMessageStyled>
      <MessageTokenInfoButton messageId={item.id} />
      <ChatItemContents
        fragments={item.fragments}
        isAssistant
        isGenerating={item.type === ChatItemType.AssistantGenerating}
        toolRenderers={toolRenderers}
      />
      {item.message.isFailed ? <AssistantError onRetry={onRetry} /> : null}
    </AssistantMessageStyled>
  );

  return (
    <ChatItemReferenceContext.Provider value={references}>
      <ChatItemLayoutContainer
        variant="agent"
        auxiliary={<ChatItemAuxiliaryContents fragments={item.fragments} />}
      >
        {agent ? (
          <AgentMessage
            agent={agent}
            onEdit={
              edit && item.type !== ChatItemType.AssistantGenerating ? () => edit(item) : undefined
            }
          >
            {content}
          </AgentMessage>
        ) : (
          content
        )}
      </ChatItemLayoutContainer>
    </ChatItemReferenceContext.Provider>
  );
}

const AgentMessageStyled = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    'sidebar sender'
    'sidebar message';
  gap: 0.5rem 0.625rem;

  > .c-sidebar {
    grid-area: sidebar;
    position: sticky;
    top: 0.5rem;
    align-self: start;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;

    > .c-dot {
      margin: 0.25rem 0;
      width: 4px;
      height: 4px;
      border-radius: 2px;
      background: rgb(${({ theme }) => theme.aiChat.messageForeground} / 0.25);
    }

    > .c-button {
      border-radius: 50%;

      &:not(:hover):not(:active) {
        color: rgb(${({ theme }) => theme.aiChat.messageForeground} / 0.5);
      }
    }
  }

  > .c-sidebar > .c-persona {
    transition: opacity 0.3s;

    .ms-Persona-image {
      box-shadow: 0 0 0 1px rgb(${({ theme }) => theme.aiChat.messageForeground} / 0.1);
    }
  }
  > .c-sender {
    grid-area: sender;
    display: flex;
    gap: 0.5em;
    align-items: baseline;
    font-size: 1.1rem;
    line-height: 2rem;
    font-weight: 600;
    color: rgb(${({ theme }) => theme.aiChat.messageForeground} / 0.75);
  }
  > .c-message {
    grid-area: message;
    align-self: start;
    position: relative;
    overflow: hidden;
    background: rgb(${({ theme }) => theme.aiChat.agentMessageBackground});
    color: rgb(${({ theme }) => theme.aiChat.agentMessageForeground});
    border-radius: ${({ theme }) => theme.aiChat.agentMessageCornerRadius};
    padding: 0.625rem 1.5rem;
    margin-top: -0.5rem;

    --color: 0 0 0;
    --color-opacity: 0;

    > .c-visual-effects {
      position: absolute;
      inset: 0;
      opacity: var(--color-opacity);
      transition: opacity 0.5s;
      background: linear-gradient(
        to bottom right,
        rgb(var(--color) / 0.5),
        rgb(${({ theme }) => theme.aiChat.agentMessageBackground})
      );
      border-radius: inherit;

      &::before,
      &::after {
        content: '';
        position: absolute;
        background: rgb(${({ theme }) => theme.aiChat.agentMessageBackground});
        inset: 2px;
        border-radius: calc(${({ theme }) => theme.aiChat.agentMessageCornerRadius} - 2px);
      }
      &::after {
        background: linear-gradient(to top right, rgb(var(--color) / 0), rgb(var(--color) / 0.1));
      }
    }

    > .c-content {
      position: relative;
      z-index: 2;
    }
  }
`;

function AgentMessage({
  agent,
  onEdit,
  children
}: {
  agent: LocalApiObject<IAgentVersion>;
  onEdit?: () => void;
  children: ReactNode;
}) {
  const { t } = useTranslation();
  const thumbnail = useAgentThumbnail({ agentId: agent.id, version: agent.version });
  const palette = useSampledPalette(`${agent.id} ${agent.version}`, thumbnail.url);

  const paletteOpacity = palette ? 1 : 0;
  const willUsePalette = thumbnail.isLoading || thumbnail.hasThumbnail;

  const personaInitialsColor = useMemo(() => {
    const div = document.createElement('div');
    div.style.color = getPersonaInitialsColor({ text: agent.name });
    document.body.append(div);
    const { color } = getComputedStyle(div);
    div.remove();
    return Rgb.fromArray(color.match(/\d+/g)?.map((n) => +n) ?? [0, 0, 0]);
  }, [agent]);

  return (
    <AgentMessageStyled>
      <div className="c-sidebar">
        <Persona
          className="c-persona"
          hidePersonaDetails
          imageUrl={thumbnail.url}
          size={PersonaSize.size32}
          text={agent.name}
          styles={{ root: { opacity: willUsePalette ? paletteOpacity : 1 } }}
        />

        {onEdit ? (
          <>
            <div className="c-dot" />
            <IconButton
              className="c-button"
              iconProps={{ iconName: 'Edit' }}
              aria-label={t('globals.edit')}
              onClick={onEdit}
            />
          </>
        ) : null}
      </div>
      <div className="c-sender">{agent.name}</div>
      <div
        className="c-message"
        style={
          {
            '--color': palette?.averageColor ?? personaInitialsColor,
            '--color-opacity': willUsePalette ? paletteOpacity : 1
          } as CSSProperties
        }
      >
        <div className="c-visual-effects" />
        <div className="c-content">{children}</div>
      </div>
    </AgentMessageStyled>
  );
}

function AssistantError({ onRetry }: { onRetry?: () => Promise<void> }) {
  const { t } = useTranslation();
  const [isRetrying, setIsRetrying] = useState(false);
  const { showError } = useNotificationContext();

  return (
    <div className="c-failed-container">
      <div className="c-failed">{t('ai.chat.errorMessage')}</div>

      {onRetry ? (
        <IconButton
          iconProps={{ iconName: 'Sync' }}
          aria-label={t('ai.chat.retryAssistantMessage')}
          disabled={isRetrying}
          onClick={() => {
            setIsRetrying(true);
            onRetry()
              .catch(showError)
              .finally(() => setIsRetrying(false));
          }}
        />
      ) : null}
    </div>
  );
}

export interface IMessageTokenInfoContext {
  /** If token info is available: opens token info for a message */
  onOpen?: (messageId: string) => void;
}

export const MessageTokenInfoContext = createContext<IMessageTokenInfoContext>({});

function MessageTokenInfoButton({ messageId }: { messageId: string }) {
  const infoContext = useContext(MessageTokenInfoContext);

  if (!infoContext.onOpen) return null;

  return (
    <IconButton
      title="Show token info"
      onClick={() => infoContext.onOpen(messageId)}
      className="c-token-info-button"
      iconProps={{ iconName: 'Info' }}
    />
  );
}
