import Label from 'components/inputs/Label';
import { CSSProperties, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ILinkProps } from 'types';
import {
  ILinkStyles,
  ITextField,
  ITextFieldProps,
  Link,
  TextField,
  concatStyleSets
} from '@fluentui/react';

export interface IHyperLinkFieldStyles {
  rootStyles?: CSSProperties;
  linkStyles?: ILinkStyles;
  linkWrapperStyles?: CSSProperties;
  textFieldStyles?: ITextFieldProps['styles'];
  urlFieldStyles?: ITextFieldProps['styles'];
}

export interface IHyperLinkFieldProps {
  id?: string;
  /**
   * Description of the hyperlink field.
   */
  description?: string;
  /**
   * Defaultvalue for the locationfield
   */
  defaultValue?: ILinkProps;
  /**
   * A label for the locationfield
   */
  label?: string;
  /**
   * The icon that will be displayed to the left of the label
   */
  labelIconName?: string;
  /**
   * Callback issued when the location changes.
   */
  onChange?: (coords?: ILinkProps) => void;
  /**
   * Optional flag to mark location as readOnly / disabled
   * @defaultvalue false
   */
  disabled: boolean;
  /**
   * Whether the associated form field is required or not
   * @defaultvalue false
   */
  required?: boolean;
  /**
   * Whether the associated form field is required or not
   * @defaultvalue false
   */
  styles?: IHyperLinkFieldStyles;
}

function HyperLinkField({
  id,
  defaultValue,
  description,
  disabled,
  label,
  labelIconName,
  onChange,
  required,
  styles
}: IHyperLinkFieldProps): JSX.Element {
  const { t } = useTranslation();

  const [link, setLink] = useState<ILinkProps>(
    () => defaultValue || { text: undefined, url: undefined }
  );
  const urlTextFieldRef = useRef<ITextField>(null);

  /**
   * Focus/select next field on enter
   */
  function onTextFieldKeyPress(
    event?: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const enterPressed = event && event.key === 'Enter';

    if (enterPressed && urlTextFieldRef?.current) {
      urlTextFieldRef.current.select();
    }
  }

  /**
   * Set new state and call on change if available
   */
  function onTextFieldChange(textFieldType: 'text' | 'url', value?: string) {
    setLink((prevLink: ILinkProps) => {
      const newLink = { ...prevLink, [textFieldType]: value };

      if (onChange) {
        onChange(newLink.url ? newLink : undefined);
      }

      return newLink;
    });
  }

  /**
   * Combines given styles with current styles for the container / text fields / link
   */
  function getStyles(): IHyperLinkFieldStyles {
    // add default styles here
    const completeStyles: IHyperLinkFieldStyles = {
      linkStyles: undefined,
      textFieldStyles: {
        fieldGroup: { border: '1px solid #a19f9d', borderRadius: 3, ':after': { borderRadius: 3 } }
      },
      urlFieldStyles: {
        root: { marginTop: '10px' },
        fieldGroup: { border: '1px solid #a19f9d', borderRadius: 3, ':after': { borderRadius: 3 } }
      }
    };

    if (styles?.textFieldStyles) {
      completeStyles.textFieldStyles = concatStyleSets(
        completeStyles.textFieldStyles,
        styles.textFieldStyles
      );
    }

    if (styles?.urlFieldStyles) {
      completeStyles.urlFieldStyles = concatStyleSets(
        completeStyles.urlFieldStyles,
        styles.urlFieldStyles
      );
    }

    if (styles?.linkStyles) {
      completeStyles.linkStyles = concatStyleSets(completeStyles.linkStyles, styles.linkStyles);
    }

    return completeStyles;
  }

  const { textFieldStyles, urlFieldStyles, linkStyles } = getStyles();

  if (disabled) {
    if (!link.url) {
      return (
        <div>
          <Label
            label={label}
            required={required}
            iconName={labelIconName}
            description={description}
          />
          <TextField
            disabled
            placeholder={t('createLink.placeholder.emptyValue')}
            styles={{
              wrapper: {
                border: '1px solid #a19f9d',
                borderRadius: '3px'
              }
            }}
          />
        </div>
      );
    }

    // on read only mode return a link
    let linkTextWithProtocol = link.url;

    const hasProtocol = /https?:\/\//;
    // add https if its missing
    if (!hasProtocol.test(link.url)) {
      linkTextWithProtocol = `http://${link.url}`;
    }

    let linkStyle: CSSProperties = {
      padding: 7,
      border: '1px solid #a19f9d',
      borderRadius: 3,
      backgroundColor: '#f3f2f1',
      width: '100%',
      color: '#a19f9d'
    };

    if (styles?.linkWrapperStyles) {
      linkStyle = { ...linkStyle, ...styles.linkWrapperStyles };
    }

    return (
      <div>
        <Label
          label={label}
          required={required}
          iconName={labelIconName}
          description={description}
        />
        <div style={linkStyle}>
          <Link styles={linkStyles} target="_blank" href={linkTextWithProtocol}>
            {link.text}
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div id={id} style={styles?.rootStyles}>
      <Label label={label} required={required} iconName={labelIconName} description={description} />
      <TextField
        id={`input-${id}`}
        onChange={(_, value) => onTextFieldChange('text', value)}
        onKeyPress={(ev) => onTextFieldKeyPress(ev)}
        placeholder={t('createLink.placeholder.text')}
        styles={textFieldStyles}
        value={link.text}
      />
      <TextField
        componentRef={urlTextFieldRef}
        onChange={(_, value) => onTextFieldChange('url', value)}
        placeholder={t('createLink.placeholder.url')}
        styles={urlFieldStyles}
        value={link.url}
      />
    </div>
  );
}

export default HyperLinkField;
