import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import fetchRequest, { apiErrorHandler } from 'services/api';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import {
  ChoiceGroup,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  IconButton,
  Label,
  List,
  PrimaryButton,
  TextField
} from '@fluentui/react';

const DialogContentStyled = styled.div`
  margin-top: 1rem;
  margin-bottom: 2rem;

  .subtag-list {
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .add-subtag-container {
    margin-top: 10px;
  }

  .add-subtag-textfield {
    width: 100%;
    margin-right: 8px;
  }

  .toggleDeleteSubTagButton:not(:hover) {
    background-color: transparent;
  }

  .titleDisabled {
    color: #bbbbbb;
  }
`;

const ChoiceGroupStyled = styled(ChoiceGroup)`
  margin-top: 1rem;
  margin-bottom: 1rem;

  .ms-ChoiceFieldGroup-flexContainer {
    display: flex;
  }

  .ms-ChoiceField-wrapper {
    margin-right: 2rem;
  }
`;

function CreateTagDialog({ hidden, onCancel, onConfirm, toggleLoading, tag }) {
  const { t } = useTranslation();

  const [newTag, setNewTag] = useState(null);
  const [newChildrenTags, setNewChildrenTags] = useState(null);
  const [newSubtagText, setNewSubtagText] = useState(''); // controlled TextField cannot contain null
  const [tagPendingDelete, setTagPendingDelete] = useState(null);

  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(true);

  const tagTypes = [
    { key: 0, text: t('createTag.dialog.type.simple') },
    { key: 1, text: t('createTag.dialog.type.grouped') }
  ];

  useEffect(() => {
    if (tag) {
      setNewTag({ ...tag });
    }

    if (tag?.children) {
      setNewChildrenTags([...tag.children]);
    }
  }, [tag]);

  const checkIfTagPropertyIsSame = useCallback((propertyToCompare, firstTag, secondTag) => {
    return firstTag[propertyToCompare] === secondTag[propertyToCompare];
  }, []);

  const detectChildChanges = useCallback(() => {
    const tagChildren = tag ? tag.children : [];

    for (let tagIndex = 0; tagIndex < tagChildren.length; tagIndex += 1) {
      let found = false;
      for (let newTagIndex = 0; newTagIndex < newChildrenTags.length; newTagIndex += 1) {
        if (checkIfTagPropertyIsSame('id', tagChildren[tagIndex], newChildrenTags[newTagIndex])) {
          found = true;

          if (
            !(
              checkIfTagPropertyIsSame(
                'text',
                tagChildren[tagIndex],
                newChildrenTags[newTagIndex]
              ) &&
              checkIfTagPropertyIsSame(
                'isDeleted',
                tagChildren[tagIndex],
                newChildrenTags[newTagIndex]
              )
            )
          ) {
            return true;
          }
        }
      }

      if (!found) {
        return true;
      }
    }

    return false;
  }, [checkIfTagPropertyIsSame, tag, newChildrenTags]);

  useEffect(() => {
    let changed = false;

    if (tag?.text && newTag?.text) {
      changed = !checkIfTagPropertyIsSame('text', tag, newTag);

      if (!changed && tag?.children.length && newChildrenTags) {
        if (tag.children.length !== newChildrenTags.length) {
          changed = true;
        } else {
          changed = detectChildChanges();
        }
      }
    } else if (newTag?.text) {
      if (newTag.type === 0 || newChildrenTags?.length) {
        changed = true;
      } else {
        changed = false;
      }
    }
    setIsPrimaryButtonDisabled(!changed);
  }, [newTag, newChildrenTags, tag, detectChildChanges, checkIfTagPropertyIsSame]);

  function close() {
    onCancel();
  }

  function onConfirmSaveTag() {
    setIsPrimaryButtonDisabled(true);

    const setChildIdAndText = (child, parentTag) => {
      const childClone = { ...child };

      childClone.parentId = parentTag.id;
      childClone.fullText = `${parentTag.text}.${child.text}`;

      return childClone;
    };

    const mainTagClone = { ...newTag };

    if (!mainTagClone.id) {
      mainTagClone.id = uuidv4();
    }

    if (!mainTagClone.parentId) {
      if (mainTagClone.type === 0) {
        mainTagClone.children = [];
      } else if (newChildrenTags) {
        const childrenTagsClone = [...newChildrenTags];
        childrenTagsClone.map((childTag) => setChildIdAndText(childTag, mainTagClone));
        mainTagClone.children = childrenTagsClone;
      }
    } else {
      mainTagClone.children = [];
      mainTagClone.fullText = `${tag.parentText}.${mainTagClone.text}`;
    }
    fetchTag(mainTagClone, newTag.id ? 'PUT' : 'POST');
  }

  function fetchTag(tag, method) {
    const body = JSON.stringify(tag);
    toggleLoading();

    fetchRequest({ url: `Tag`, method, body })
      .then((tag) => {
        onConfirm(tag);
        close();
      })
      .catch((err) => {
        toggleLoading();
        apiErrorHandler(err);
      });
  }

  function getDialogStyles() {
    return {
      main: [
        {
          selectors: {
            '@media (min-width: 650px)': {
              width: '100%',
              minWidth: '550px',
              minHeight: '200px'
            },
            '@media (max-width: 480px)': {
              minHeight: '100%',
              minWidth: '100%'
            }
          }
        }
      ]
    };
  }

  function onChangeText(text) {
    const validText = getValidTagText(text);
    if (!text) {
      setNewTag({ ...newTag, text: '' }); // controlled TextField cannot contain null
    } else if (validText) {
      setNewTag({ ...newTag, text: validText });
    }
  }

  function onChangeType(e, option) {
    setNewTag({ ...newTag, type: option.key });
  }

  function onToggleDeleteSubtagItem(index) {
    const childrenClone = [...newChildrenTags];
    const childClone = { ...childrenClone[index] };

    if (tagPendingDelete) {
      childClone.text = tagPendingDelete.text;
      setTagPendingDelete(null);
    }

    childClone.isDeleted = !childClone.isDeleted;

    childrenClone[index] = childClone;

    setNewChildrenTags(childrenClone);
  }

  function getValidTagText(textToCheck) {
    // allow only alphanumeric
    const regex = /[0-9a-zA-ZZäöüÄÖÜß]*/gm;

    // find regex matches in text
    const matchedRegex = textToCheck.match(regex);

    if (matchedRegex) {
      const matchedRegexText = matchedRegex[0];

      return matchedRegexText;
    }
    return '';
  }

  function onSubtagTextChange(id, text, index) {
    const childrenClone = [...newChildrenTags];

    if (!tagPendingDelete) {
      setTagPendingDelete({ ...childrenClone[index] });
    }

    if (!text) {
      // allow user to delete all the text in order to change it
      // if the field is left empty, it will be deleted onBlur
      childrenClone[index].text = text;
    } else {
      const validText = getValidTagText(text);

      if (validText) {
        const parentText = document.getElementById('mainTagText');

        const childTag = {
          id,
          parentId: newTag.id,
          fullText: `${parentText.value}.${validText}`,
          text: validText
        };

        childrenClone[index] = childTag;
      }
    }

    setNewChildrenTags(childrenClone);
  }

  function addSubtagItem(e) {
    e.preventDefault();

    const newSubtag = {
      id: uuidv4(),
      parentId: newTag.id,
      fullText: `${newTag.text}.${newSubtagText}`,
      text: newSubtagText
    };

    if (newChildrenTags) {
      setNewChildrenTags([...newChildrenTags, newSubtag]);
    } else {
      setNewChildrenTags([newSubtag]);
    }

    setNewSubtagText(''); // controlled TextField cannot contain null
  }

  function checkFocus(e, tag) {
    e.preventDefault();

    if (tag.isDeleted) {
      e.target.blur();
    }
  }

  function onRenderSubtag(subtag, index) {
    const { length } = newChildrenTags;

    return (
      <TextField
        key={subtag.id}
        className={subtag.isDeleted ? 'titleDisabled' : ''}
        onRenderSuffix={() => (
          <IconButton
            className="toggleDeleteSubTagButton"
            onClick={() => onToggleDeleteSubtagItem(index)}
            iconProps={{
              iconName: subtag.isDeleted ? 'CheckMark' : 'Clear',
              styles: { root: { fontSize: '12px' } }
            }}
          />
        )}
        onChange={(_, value) => !subtag.isDeleted && onSubtagTextChange(subtag.id, value, index)}
        styles={{ suffix: { backgroundColor: 'transparent' } }}
        borderless={index === length - 1}
        underlined={!(index === length - 1)}
        value={subtag.text}
        onFocus={(event) => checkFocus(event, subtag)}
        onBlur={() => !subtag.text && onToggleDeleteSubtagItem(index)}
      />
    );
  }

  function getSubtagsContainer() {
    return (
      <div>
        <Label required>{t('createTag.dialog.field.choices.list.label')}</Label>
        <List className="subtag-list" items={newChildrenTags || []} onRenderCell={onRenderSubtag} />
        <form onSubmit={addSubtagItem}>
          <div className={`flex ${newChildrenTags?.length ? 'add-subtag-container' : ''}`}>
            <TextField
              className="add-subtag-textfield"
              autoFocus
              placeholder={t('createTag.dialog.add.textfield.placeholder')}
              onChange={(_, value) => setNewSubtagText(getValidTagText(value))}
              autoComplete="off"
              value={newSubtagText}
            />
            <DefaultButton
              styles={{ root: { minWidth: '108px' } }}
              text={t('createTag.dialog.add.button.text')}
              type="submit"
              disabled={!newSubtagText}
            />
          </div>
        </form>
      </div>
    );
  }

  return (
    <Dialog
      hidden={hidden}
      onDismiss={close}
      styles={getDialogStyles}
      dialogContentProps={{
        type: DialogType.close,
        title: tag?.id ? t('createTag.dialog.titleEdit') : t('createTag.dialog.title')
      }}
      modalProps={{ isBlocking: true }}
    >
      <DialogContentStyled>
        <TextField
          id="mainTagText"
          label={t('createTag.dialog.text.label')}
          required
          autoFocus
          value={newTag?.text || ''}
          onChange={(_, value) => onChangeText(value)}
        />
        {!newTag?.parentId ? (
          <ChoiceGroupStyled
            defaultSelectedKey={tag?.id ? tag.type : 0}
            options={tagTypes}
            onChange={onChangeType}
            label={t('createTag.dialog.type.label')}
            required
            disabled={tag?.id}
          />
        ) : null}
        {newTag?.type === 1 ? getSubtagsContainer() : null}
      </DialogContentStyled>
      <DialogFooter>
        <PrimaryButton
          disabled={isPrimaryButtonDisabled}
          onClick={onConfirmSaveTag}
          text={t('createTag.dialog.save.button.primary')}
        />
        <DefaultButton onClick={close} text={t('createTag.dialog.cancel.button.default')} />
      </DialogFooter>
    </Dialog>
  );
}

CreateTagDialog.propTypes = {
  hidden: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  toggleLoading: PropTypes.func.isRequired,
  tag: PropTypes.shape({
    id: PropTypes.string,
    parentId: PropTypes.string,
    parentText: PropTypes.string,
    text: PropTypes.string,
    type: PropTypes.number,
    children: PropTypes.arrayOf(PropTypes.any),
    isDeleted: PropTypes.bool
  })
};

CreateTagDialog.defaultProps = {
  hidden: true,
  tag: null
};

export default CreateTagDialog;
