import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Breadcrumb } from '@fluentui/react';

const BreadcrumbWrappper = styled.div`
  width: 100%;
  background: rgb(${(props) => props.theme.breadcrumbs.background});
`;

function BreadcrumbCustom({ crumbs, clickableCrumbs, onCrumbClick }) {
  return (
    <BreadcrumbWrappper>
      <Breadcrumb
        id="bread-crumb"
        styles={{
          root: {
            margin: 5,
            selectors: { '@media (max-width: 450px)': { margin: 0, fontSize: '14px' } }
          },
          itemLink: {
            border: 'none !important',
            fontSize: '21px',
            fontWeight: '100 !important',
            selectors: {
              '@media (max-width: 450px)': {
                fontSize: '14px !important'
              }
            }
          },
          item: {
            fontSize: '21px !important',
            fontWeight: '100 !important',
            selectors: {
              '@media (max-width: 450px)': {
                fontSize: '14px !important'
              }
            }
          },
          chevron: { paddingTop: '3px' },
          listItem: {
            selectors: {
              ':last-child .ms-Breadcrumb-item': {
                fontWeight: 400
              }
            }
          }
        }}
        items={crumbs.map((crumb) => ({
          ...crumb,
          onClick:
            clickableCrumbs && clickableCrumbs.includes(crumb.key) && onCrumbClick
              ? (event, clickedCrumb) => onCrumbClick(clickedCrumb.key)
              : null
        }))}
      />
    </BreadcrumbWrappper>
  );
}

BreadcrumbCustom.propTypes = {
  crumbs: PropTypes.arrayOf(PropTypes.any).isRequired,
  clickableCrumbs: PropTypes.arrayOf(PropTypes.string),
  onCrumbClick: PropTypes.func
};

BreadcrumbCustom.defaultProps = {
  onCrumbClick: null,
  clickableCrumbs: []
};

export default BreadcrumbCustom;
