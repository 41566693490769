/* eslint-disable no-param-reassign */

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { cloneObject } from 'utils/helpers';
import ResponseBodyList from './ResponseBodyList';

// styles
const ResponseTabStyled = styled.div`
  padding: 1rem;
  padding-bottom: 3rem;

  .form-fields-width {
    width: 100%;
  }

  .deleteButton {
    visibility: hidden;
    background-color: transparent;
  }

  .addButton {
    background-color: transparent;
  }

  .groupAddButton {
    background-color: transparent;
  }

  .cancelButton {
    background-color: transparent;
  }
`;

const TabDescriptionStyled = styled.div`
  margin-bottom: 10px;
  padding-bottom: 10px;
  font-size: 0.9em;
  color: #808080;
  font-style: italic;
  border-bottom: 1px solid rgb(235, 235, 235);
`;

function ServiceResponsePivotItem({ service, handleServiceState, handleErrors, inputErrors }) {
  const { t } = useTranslation();

  function handleResponseBody(type, value) {
    const newService = cloneObject(service);

    let responseBody = { ...newService.serviceData?.connection?.responseBody };

    if (type === 'responseType' && value !== responseBody?.type) {
      let newBodyProps = null;

      if (value === 'object') {
        newBodyProps = cloneObject(responseBody?.items.properties);
        responseBody = { properties: newBodyProps, type: value };
      } else if (value === 'array') {
        newBodyProps = cloneObject(responseBody?.properties);
        responseBody = { items: { properties: newBodyProps, type: 'object' }, type: value };
      }
    } else if (type === 'properties' && value) {
      const properties = {};

      const setProperties = (newProperties, passedProps) => {
        if (passedProps) {
          Object.keys(passedProps).map((propKey) => {
            newProperties[propKey] = {
              'x-ep-mappedField': passedProps[propKey]['x-ep-mappedField'],
              type: passedProps[propKey].type,
              'x-ep-field': passedProps[propKey]['x-ep-field']
            };

            if (passedProps[propKey].type === 'object') {
              const objProperties = {};

              setProperties(objProperties, passedProps[propKey].properties);

              newProperties[propKey].properties = objProperties;
            } else if (passedProps[propKey].type === 'array') {
              const arrayProperties = {};

              setProperties(arrayProperties, passedProps[propKey].items?.properties);

              newProperties[propKey].items = { properties: arrayProperties, type: 'object' };
            }

            return null;
          });
        }
      };

      setProperties(properties, value);

      if (newService.serviceData?.connection?.responseBody?.type === 'array') {
        responseBody = {
          items: { properties, type: 'object' },
          type: newService.serviceData?.connection?.responseBody?.type
        };
      } else {
        responseBody = {
          properties,
          type: newService.serviceData?.connection?.responseBody?.type || 'object'
        };
      }
    }

    newService.serviceData.connection.responseBody = responseBody;

    handleServiceState(newService);
  }

  function getDescriptionText() {
    const tabDescription = t('serviceResponsePivotItem.description').split('<br/>');
    const formatedDescription = [];

    let lineCounter = 0;

    tabDescription.map((line, index) => {
      formatedDescription.push(<span key={`${lineCounter}-line`}>{line}</span>);
      if (tabDescription.length > 1 && index < tabDescription.length - 1) {
        formatedDescription.push(
          <span key={`${lineCounter}-break`}>
            <br />
          </span>
        );
      }

      lineCounter += 1;

      return null;
    });

    return formatedDescription;
  }

  function handleErrorsFromResponseList(errors) {
    handleErrors('response', errors);
  }

  return (
    <ResponseTabStyled>
      <TabDescriptionStyled>{getDescriptionText()}</TabDescriptionStyled>
      <ResponseBodyList
        responseBody={service?.serviceData?.connection?.responseBody}
        handleResponseBody={handleResponseBody}
        handleErrors={handleErrorsFromResponseList}
        bodyPropertiesErrors={inputErrors}
      />
    </ResponseTabStyled>
  );
}

ServiceResponsePivotItem.propTypes = {
  service: PropTypes.object,
  handleServiceState: PropTypes.func.isRequired,
  handleErrors: PropTypes.func,
  inputErrors: PropTypes.arrayOf(PropTypes.object)
};

ServiceResponsePivotItem.defaultProps = {
  service: null,
  handleErrors: null,
  inputErrors: null
};

export default ServiceResponsePivotItem;
