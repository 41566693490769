import { COMMAND_PRIORITY_LOW } from 'lexical';
import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { DRAG_DROP_PASTE } from '@lexical/rich-text';
import { isMimeType, mediaFileReader } from '@lexical/utils';
import { INSERT_IMAGE_COMMAND } from './ImagesPlugin';

const ACCEPTABLE_IMAGE_TYPES = ['image/', 'image/heic', 'image/heif', 'image/gif', 'image/webp'];
const MAX_IMAGE_SIZE = 400;

export default function DragDropPaste(): null {
  const [editor] = useLexicalComposerContext();

  function resizeImage(originalWidth, originalHeight) {
    let newWidth = originalWidth;
    let newHeight = originalHeight;

    if (originalWidth > MAX_IMAGE_SIZE) {
      newHeight *= MAX_IMAGE_SIZE / originalWidth;
      newWidth = MAX_IMAGE_SIZE;
    }

    if (newHeight > MAX_IMAGE_SIZE) {
      newWidth *= MAX_IMAGE_SIZE / newHeight;
      newHeight = MAX_IMAGE_SIZE;
    }

    return { width: newWidth, height: newHeight };
  }

  useEffect(() => {
    return editor.registerCommand(
      DRAG_DROP_PASTE,
      (files: File[]) => {
        (async () => {
          const filesResult = await mediaFileReader(
            files,
            [ACCEPTABLE_IMAGE_TYPES].flatMap((x) => x)
          );

          filesResult?.forEach(({ file, result }) => {
            const objectURL = URL.createObjectURL(file);
            const img = new Image();

            img.onload = () => {
              if (isMimeType(file, ACCEPTABLE_IMAGE_TYPES)) {
                const { width, height } = resizeImage(img.width, img.height);
                editor.dispatchCommand(INSERT_IMAGE_COMMAND, {
                  altText: file.name,
                  src: result,
                  file,
                  width,
                  height
                });
              }

              URL.revokeObjectURL(objectURL);
            };

            img.src = objectURL;
          });
        })();

        return true;
      },
      COMMAND_PRIORITY_LOW
    );
  }, [editor]);

  return null;
}
