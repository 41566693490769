import { ActivitiesList, EmptyListPlaceHolder, LoadingSpinner, Panel } from 'components';
import { AppContext } from 'features/App';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import fetchRequest, { apiErrorHandler } from 'services/api';
import styled from 'styled-components';
import { checkScreenWidth, getActivityRedirectUrl } from 'utils/helpers';
import { ActionButton, PanelType } from '@fluentui/react';

const headerHeight = checkScreenWidth(['extraSmall']) ? 0 : 48;

const NotificationsContainer = styled.div`
  padding: 0 5px 0 5px;
  overflow-y: auto;
  height: 100%;
`;

function NotificationsPanel({ showNotificationsPanel, onDismiss }) {
  const { t } = useTranslation();

  // List items
  const [notifications, setNotifications] = useState([]);

  // global app state
  const { globalAppState, dispatch } = useContext(AppContext);
  const { currentUser, msTeamId, unreadNotifications } = globalAppState;

  const [searchProps, setSearchProps] = useState({
    itemsPerPage: 150,
    pageIndex: 0,
    totalItems: 0,
    useContinuationToken: true
  });

  const { userId } = currentUser;

  const navigate = useNavigate();

  // Loading states
  const [isLoading, setIsLoading] = useState(true);

  const addIdToNotificationGroup = useCallback(
    (notificationGroup) => ({ ...notificationGroup, id: notificationGroup.objectId }),
    []
  );

  const fetchNotifications = useCallback(
    ({ itemsPerPage, pageIndex, useContinuationToken, continuationToken }) => {
      let url = `Notifications/Grouped?pageIndex=${pageIndex}&itemsPerPage=${itemsPerPage}&recipientId=${userId}&onlyNew=true`;

      if (useContinuationToken) {
        url = `Notifications/Grouped?itemsPerPage=${itemsPerPage}&recipientId=${userId}&onlyNew=true`;
      }

      if (continuationToken) url += `&continuationToken=${encodeURIComponent(continuationToken)}`;

      if (msTeamId) url += `&teamId=${msTeamId}`;

      return fetchRequest({ url })
        .then((fetchedNotifications) => {
          const { items } = fetchedNotifications;
          const fetchedNotificationsWithGroupId = items.map(addIdToNotificationGroup);

          setSearchProps((prevState) => ({
            ...prevState,
            totalItems: fetchedNotifications.searchProps.totalItems,
            continuationToken: fetchedNotifications.searchProps.continuationToken,
            useContinuationToken: fetchedNotifications.searchProps.useContinuationToken
          }));

          return fetchedNotificationsWithGroupId;
        })
        .catch(apiErrorHandler);
    },
    [addIdToNotificationGroup, msTeamId, userId]
  );

  const getNotifications = useCallback(() => {
    return fetchNotifications(searchProps).then((fetchedNotifications) => {
      setNotifications((prevState) => [...prevState, ...fetchedNotifications]);
    });
  }, [fetchNotifications, searchProps]);

  useEffect(() => {
    if (isLoading) getNotifications().then(() => setIsLoading(false));
  }, [getNotifications, isLoading]);

  function updateNotificationsCount(reset, numberOfReadNotifications) {
    let newNotificationNumber = unreadNotifications;

    if (reset) {
      // set to zero
      newNotificationNumber = 0;
    } else if (numberOfReadNotifications) {
      // subtract multiple notifications
      newNotificationNumber = unreadNotifications - numberOfReadNotifications;
    } else {
      // subtract a single notification
      newNotificationNumber = unreadNotifications - 1;
    }

    dispatch({
      type: 'updateNotificationsCount',
      data: newNotificationNumber
    });
  }

  function fetchReadNotification(id) {
    fetchRequest({
      url: `Notification/Read?notificationId=${id}`,
      method: 'PUT'
    }).catch(apiErrorHandler);
  }

  function redirect(notification) {
    const url = getActivityRedirectUrl(notification);

    if (url) {
      navigate(url);
    }
  }

  function findNotification(idToSearch, notificationsArray) {
    return notificationsArray.find((notification) => notification.id === idToSearch);
  }

  function filterNotificationsFromNotificationArray(givenNotificationArray, notificationsToFilter) {
    return givenNotificationArray.filter(
      (notification) => !findNotification(notification.id, notificationsToFilter)
    );
  }

  function removeReadNotifications(notificationsToRemove) {
    let unreadNotifications = [...notifications];

    unreadNotifications = unreadNotifications.map((notificationGroup) => {
      return {
        ...notificationGroup,
        notifications: filterNotificationsFromNotificationArray(
          notificationGroup.notifications,
          notificationsToRemove
        )
      };
    });

    setNotifications(unreadNotifications);
  }

  function markSingleNotificationAsRead(notification, followLink = false) {
    if (userId) {
      updateNotificationsCount();

      fetchReadNotification(notification.notificationId);

      if (followLink) {
        redirect(notification);
      }

      removeReadNotifications([notification]);
    }
  }

  function markNotificationGroupAsRead(groupedNotification, followLink = false) {
    if (userId) {
      const groupLength = groupedNotification.notifications.length;

      updateNotificationsCount(false, groupLength);

      groupedNotification.notifications.map((notification) =>
        fetchReadNotification(notification.notificationId)
      );

      if (followLink) {
        redirect(groupedNotification.notifications[groupLength - 1]);
      }

      removeReadNotifications(groupedNotification.notifications);
    }
  }

  function markAllNotificationsAsRead() {
    fetchRequest({ url: `Notification/Read`, method: 'PUT' })
      .then(() => {
        setNotifications([]);
        updateNotificationsCount(true);
      })
      .catch(apiErrorHandler);
  }

  function onRenderFooterContent() {
    if (!isLoading && notifications?.length > 4) {
      return (
        <ActionButton onClick={markAllNotificationsAsRead}>
          {t('notificationsPanel.footer.markAllAsRead')}
        </ActionButton>
      );
    }

    return null;
  }

  function getPanelContent() {
    if (isLoading) {
      return (
        <LoadingSpinner
          styles={{ container: { margin: 'auto', width: '50%' } }}
          label={t('loading.myNotifications.text')}
        />
      );
    }

    if (notifications?.length) {
      return (
        <NotificationsContainer data-is-scrollable="true" id="notifications-scrollable-container">
          <ActivitiesList
            groupedActivities={notifications}
            handleOnActivityClick={(notification) =>
              markSingleNotificationAsRead(notification, true)
            }
            handleOnReadIconClick={markSingleNotificationAsRead}
            handleOnGroupedReadIconClick={markNotificationGroupAsRead}
            handleOnGroupedActivityClick={(groupedNotification) =>
              markNotificationGroupAsRead(groupedNotification, true)
            }
          />
          <InfiniteScroll
            dataLength={notifications.length}
            next={getNotifications}
            hasMore={searchProps.continuationToken}
            scrollableTarget="notifications-scrollable-container"
            loader={
              <LoadingSpinner
                styles={{ container: { marginTop: 20, marginBottom: 20 } }}
                label={t('loading.myNotifications.text')}
              />
            }
          />
        </NotificationsContainer>
      );
    }

    return (
      <EmptyListPlaceHolder
        hidden={!!notifications?.length}
        noItemsText={t('notificationsPanel.placeHolder')}
        listIconName="Ringer"
      />
    );
  }

  const panelType = checkScreenWidth(['extraSmall', 'small'])
    ? PanelType.smallFluid
    : PanelType.small;

  return (
    <Panel
      headerText={t('notificationsPanel.title')}
      isBlocking={false}
      styles={{
        footer: { height: '40px', textAlign: 'center' },
        footerInner: { padding: 'auto', paddingTop: 0 },
        headerText: { color: 'white', padding: '10px 0px' },
        navigation: {
          color: 'white',
          flexDirection: 'row',
          boxShadow: 'rgb(21 27 38 / 15%) 0px 1px 2px'
        },
        closeButton: { display: 'block', marginTop: 8 },
        root: { marginTop: `${headerHeight}px` }
      }}
      onRenderBody={() => getPanelContent()}
      isOpen={showNotificationsPanel}
      onDismiss={onDismiss}
      type={panelType}
      onRenderFooterContent={onRenderFooterContent}
    />
  );
}

export default NotificationsPanel;

NotificationsPanel.propTypes = {
  showNotificationsPanel: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired
};
