import { createRef, HTMLAttributes, PureComponent, ReactNode } from 'react';

/** A div that animates height between two states. Will only render one child at once */
export default class AnimateExpanded extends PureComponent<
  {
    collapsed: ReactNode;
    expanded: ReactNode;
    isExpanded: boolean;
    animationDuration?: number;
  } & HTMLAttributes<HTMLDivElement>
> {
  node = createRef<HTMLDivElement>();

  getSnapshotBeforeUpdate(prevProps: Readonly<{ isExpanded: boolean }>): number | null {
    if (this.props.isExpanded !== prevProps.isExpanded) {
      const node = this.node.current;
      if (!node) return null;
      return node.offsetHeight;
    }

    return null;
  }

  componentDidUpdate(_1: unknown, _2: unknown, snapshot?: number | null) {
    if (snapshot !== null) {
      const node = this.node.current;
      const newHeight = node.offsetHeight;
      if (!node) return;
      node.animate(
        [
          { height: `${snapshot}px`, overflow: 'hidden' },
          { height: `${newHeight}px`, overflow: 'hidden' }
        ],
        {
          duration: this.props.animationDuration ?? 200,
          easing: 'cubic-bezier(0, 0, 0, 1)'
        }
      );
    }
  }

  render() {
    // we need these to be omitted from the div
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { collapsed, expanded, isExpanded, animationDuration, ...props } = this.props;

    return (
      <div ref={this.node} {...props}>
        {isExpanded ? expanded : collapsed}
      </div>
    );
  }
}
