export default function LogoEvocom() {
  return (
    <svg viewBox="0 0 210 40" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg">
      <g fill="#145aa9">
        <path
          className="st0"
          d="m2.445 26.587 8.36 1.526L18.787 17.4l.806 1.404-8.158 10.934L1 27.824v-9.71l18.104-8.636-1.304 2.258-15.476 7.311zM47.57 18.826l-15.14-7.063-1.318-2.285 17.97 8.434v9.697L38.456 29.53 30.46 18.617l.807-1.39 7.835 10.67 8.535-1.525zM16.953 25.364h16.27v2.97h-16.27zM22.591 35.027h5v2.97h-5zM19.265 20.532h11.659v2.97h-11.66zM19.265 30.196h11.659v2.97h-11.66zM31.77 3.578l-2.56.874-1.23 1.76h-1.807l2.097-3.01 3.5-1.183zM22.141 6.212l-1.236-1.76-2.588-.88V1.998l3.528 1.203 2.11 3.01zM27.725 18.133h-5.268l-2.634-4.556 2.634-4.556h5.268l2.628 4.556z"
        />
        <g className="st0">
          <path
            className="st1"
            d="M59.553 21.134c-.2 5.7 3.7 9.4 8.8 9.4 3.5 0 6.2-2.1 7.8-5l1.8 1.1c-2 3.8-5.6 6-9.9 6-6.5-.1-10.7-5.4-10.7-11.7 0-6.2 4.3-11.5 10.8-11.5 6.7 0 10.7 5.4 10.7 11.8h-19.3zm17-2c-.5-4.1-3.9-7.7-8.4-7.7s-8.3 3.6-8.6 7.7zM90.752 28.134l7.9-18.2h2.2l-10.1 23.3-10.2-23.3h2.2zM125.752 20.934c0 6.6-5.1 11.6-11.7 11.6-6.4 0-11.5-5.2-11.5-11.5 0-6.4 5.1-11.6 11.5-11.6 6.6 0 11.7 5 11.7 11.5zm-21 .1c0 5 4 9.5 9.2 9.5 5.4 0 9.6-4.1 9.6-9.5 0-5.5-4.2-9.6-9.6-9.6-5.2 0-9.2 4.5-9.2 9.6zM146.851 16.034c-1.7-2-3.8-3-6.4-3-4.2 0-7.5 3.7-7.5 7.9 0 4.6 3.2 8 7.8 8 2.5 0 4.5-1.2 6.1-3v5.5c-2 1.1-3.9 1.5-6.2 1.5-6.6 0-12-5.1-12-11.8 0-7 5.4-12.1 12.3-12.1 2 0 4.2.5 6 1.5v5.5zM173.45 20.934c0 6.8-5.3 12-12 12s-12-5.2-12-12 5.3-12 12-12 12 5.2 12 12zm-19.7 0c0 4.2 3.4 7.8 7.7 7.8s7.7-3.6 7.7-7.8c0-4.3-3.4-7.8-7.7-7.8-4.3-.1-7.7 3.5-7.7 7.8zM181.55 12.434c1.3-2 3.6-3.5 6-3.5 2.9 0 5 1.5 6.5 4 1.4-2.4 4.1-4 6.9-4 5.9 0 7.4 4.6 7.4 9.7v13.4h-4.3v-12.7c0-2.9-.4-6.6-4.2-6.6-4.6 0-4.9 4.8-4.9 8.3v11h-4.3v-11.9c0-2.9-.1-7.4-4.1-7.4-4.6 0-5 4.8-5 8.3v11h-4.3v-22.4h4.3z"
          />
        </g>
      </g>
      <style>
        {".st1{font-family:'FuturaStd-Light'}.st0{enable-background:new}.st0,.st1{fill:#145aa9}"}
      </style>
    </svg>
  );
}
