import { BarChart, GroupBarChart, PieChart } from 'components/charts';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const RouteDashboardStyled = styled.div`
  .chartTitle {
    margin: 1em 0 2em 1em;
    font-weight: 500;
    font-size: 2em;
  }
`;

function RouteDashboard({ dashboardData, type, onSelectedChartGroup, templateTitle }) {
  const { t } = useTranslation();
  const [title, setTitle] = useState(null);
  let chartValues = [];

  useEffect(() => {
    if (type) {
      let titleByType = t('subheader.routes.dashboard.option.active');
      if (type === 'dash-duration-routes') {
        titleByType = t('subheader.routes.dashboard.option.duration');
      }
      if (type === 'dash-routes-byStep') {
        titleByType = t('subheader.routes.dashboard.title.byStep');
        if (templateTitle) {
          titleByType += `: ${templateTitle}`;
        }
      }
      if (titleByType) {
        setTitle(titleByType);
      }
    }
  }, [t, type, templateTitle]);

  if (dashboardData) {
    if (type === 'dash-active-routes') {
      dashboardData.map((result) => {
        chartValues.push({
          key: result.id,
          title: result.name,
          number: result.numberOfInstances,
          colorIndex: result.colorIndex
        });

        return null;
      });
    } else if (type === 'dash-duration-routes') {
      const negGroups = dashboardData.filter((x) => x.groupId < 0 && x.numbers.length > 0).length;
      const posGroups = dashboardData.filter((x) => x.groupId > 0 && x.numbers.length > 0).length;

      dashboardData.map((result) => {
        const newResult = { ...result };
        if (
          newResult.numbers.length > 0 ||
          (negGroups > 0 && posGroups > 0 && newResult.groupId === 0)
        ) {
          if (result.groupId === -5) {
            newResult.xValue = t('routes.groupedChart.group.-5');
          } else if (result.groupId === -4) {
            newResult.xValue = t('routes.groupedChart.group.-4');
          } else if (result.groupId === -3) {
            newResult.xValue = t('routes.groupedChart.group.-3');
          } else if (result.groupId === -2) {
            newResult.xValue = t('routes.groupedChart.group.-2');
          } else if (result.groupId === -1) {
            newResult.xValue = t('routes.groupedChart.group.-1');
          } else if (result.groupId === 0) {
            newResult.xValue = '0%';
          } else if (result.groupId === 1) {
            newResult.xValue = t('routes.groupedChart.group.+1');
          } else if (result.groupId === 2) {
            newResult.xValue = t('routes.groupedChart.group.+2');
          } else if (result.groupId === 3) {
            newResult.xValue = t('routes.groupedChart.group.+3');
          } else if (result.groupId === 4) {
            newResult.xValue = t('routes.groupedChart.group.+4');
          } else if (result.groupId === 5) {
            newResult.xValue = t('routes.groupedChart.group.+5');
          }
          chartValues.push(newResult);
        }

        return dashboardData;
      });
    } else if (type === 'dash-routes-byStep') {
      dashboardData.map((result, index) => {
        chartValues.push({
          key: result.id,
          title: result.title,
          number: result.number,
          colorIndex: index + 1
        });

        return null;
      });
    } else {
      chartValues = [...dashboardData];
    }
  }

  if (!chartValues || chartValues.length === 0) {
    return null;
  }

  function selectedChartGroup(value) {
    onSelectedChartGroup(value, 'dash-duration-routes');
  }

  function getChart() {
    if (type !== 'dash-duration-routes') {
      if (chartValues.length < 11) {
        return (
          <PieChart
            showText
            showLegend
            showTooltip
            chartValues={chartValues}
            onSelectedChartGroup={(value) => onSelectedChartGroup(value, type)}
          />
        );
      }
      return (
        <BarChart
          showText
          showTooltip
          chartValues={chartValues}
          onSelectedChartGroup={(value) => onSelectedChartGroup(value, type)}
          yAxisTitle={t('routeDashboard.barChart.yAxisTitle')}
        />
      );
    }
    return (
      <GroupBarChart
        showText
        showTooltip
        showLegend
        chartValues={chartValues}
        onSelectedChartGroup={selectedChartGroup}
        yAxisTitle={t('routeDashboard.routeLatency.yAxisTitle')}
      />
    );
  }

  return (
    <RouteDashboardStyled>
      <div className="chartTitle">{title}</div>
      {getChart()}
    </RouteDashboardStyled>
  );
}

RouteDashboard.propTypes = {
  dashboardData: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.string,
      number: PropTypes.number
    })
  ).isRequired,
  type: PropTypes.string,
  onSelectedChartGroup: PropTypes.func.isRequired,
  templateTitle: PropTypes.string
};

RouteDashboard.defaultProps = {
  type: null,
  templateTitle: null
};

export default RouteDashboard;
