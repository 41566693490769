import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ChoiceGroup } from '@fluentui/react';

function SupportChoiceGroup({ resetMessageBars }) {
  const { t } = useTranslation();

  function createEmailTemplate(contactEvocom) {
    if (contactEvocom) {
      window.location = t('welcomePage.email.contactEvocom');
    } else {
      window.location = t('welcomePage.email.userContactIt');
    }
  }

  function removeCookie() {
    document.cookie = 'epMsalAuthStarted=;max-age=0';
  }

  const options = [
    {
      key: 'userContactIt',
      text: (
        <div style={{ marginBottom: '5px' }}>
          <div>{t('welcomePage.supportChoiceGroup.userContactIt')}</div>
        </div>
      )
    },
    {
      key: 'contactEvocom',
      text: (
        <div style={{ marginBottom: '8px' }}>
          {t('welcomePage.supportChoiceGroup.contactEvocom')}
        </div>
      )
    }
  ];

  return (
    <div>
      <div style={{ fontSize: '14px' }}>{t('welcomePage.supportChoiceGroup.title')}</div>
      <br />
      <div>
        <ChoiceGroup
          options={options}
          onChange={(_, value) => {
            createEmailTemplate(value.key === 'contactEvocom');
            removeCookie();
            resetMessageBars();
          }}
        />
      </div>
    </div>
  );
}

SupportChoiceGroup.propTypes = {
  resetMessageBars: PropTypes.func.isRequired
};

export default SupportChoiceGroup;
