import throttle from 'lodash/throttle';
import { useEffect, useRef, useState } from 'react';
import { checkScreenWidth } from 'utils/helpers';

export default function useHeaderStylesOnScroll(scrollContainerRefOrNode) {
  const [listNode, setListNode] = useState(null);
  const [, setScrollPosition] = useState(0);

  const handleScroll = useRef(
    throttle(
      (event) => {
        const { target } = event;
        const getDifference = (a, b) => Math.abs(a - b);

        const nodes = {
          header: document.getElementById('header'),
          breadCrumb: document.getElementById('bread-crumb'),
          commandBar: document.getElementById('command-bar')
        };

        const setStyles = (node, height) => {
          const transformation = 'scaleY(0)';
          const transformationFull = 'scaleY(1)';
          const transition = 'all 200ms ease-in';

          if (nodes[node]) {
            nodes[node].style.height = `${height}px`;
            nodes[node].style.overflow = height === 0 ? 'hidden' : 'visible';
            nodes[node].style.transform = height === 0 ? transformation : transformationFull;
            nodes[node].style.transition = transition;
            nodes[node].style.transformOrigin = 'top';
          }
        };

        setScrollPosition((prevState) => {
          const scrollUp = target.scrollTop < 30 || prevState > target.scrollTop;
          const difference = getDifference(prevState, target.scrollTop);

          if ((difference > 120 && !scrollUp) || (scrollUp && difference > 70)) {
            setStyles('header', scrollUp ? 48 : 0);
            setStyles('breadCrumb', scrollUp ? 33 : 0);
            setStyles('commandBar', scrollUp ? 45 : 0);
          }

          return target.scrollTop;
        });
      },
      500,
      { trailing: false }
    )
  ).current;

  useEffect(() => {
    if (scrollContainerRefOrNode) {
      if (scrollContainerRefOrNode.current) {
        // ref
        setListNode(scrollContainerRefOrNode.current);
      } else if (scrollContainerRefOrNode.addEventListener) {
        // node
        setListNode(scrollContainerRefOrNode);
      }
    }
  }, [scrollContainerRefOrNode]);

  useEffect(() => {
    if (listNode && checkScreenWidth(['extraSmall'])) {
      // listNode.addEventListener('scroll', (event) => handleScroll(event));
    }

    return () => listNode?.removeEventListener('scroll', handleScroll);
  }, [listNode, handleScroll]);
}
