import RichTextEditor from 'components/inputs/RichTextEditor';
import InfoIconTooltip from 'components/surfaces/InfoIconTooltip';
import { useTheme } from 'styled-components';
import {
  Label as FluentLabel,
  ILabelProps as IFluentLabelProps,
  Icon,
  concatStyleSets
} from '@fluentui/react';
import LabelContainerStyled from './Label.styles';

export interface ILabelProps {
  /**
   * Text for the label
   */
  label?: string;
  /**
   * Whether the associated form field is required or not
   * @defaultvalue false
   */
  required?: boolean;
  /**
   * The icon that will be displayed to the left / far right of the label
   */
  iconName?: string;
  /**
   * The description of the associated form field
   */
  description?: string;
  /**
   * Callback for when the label is clicked
   */
  onClick?: () => void;
  /**
   * Call to provide customized styling that will layer on top of the variant rules.
   */
  styles?: {
    fluentLabel?: IFluentLabelProps['styles'];
    container?: React.CSSProperties;
  };
  /**
   * Additional class name for the root element.
   */
  className?: string;
  /** Label htmlFor ID */
  htmlFor?: string;
}

function Label({
  description,
  iconName,
  label,
  onClick,
  required = false,
  styles,
  className,
  htmlFor
}: ILabelProps) {
  const theme = useTheme();

  if (!label) {
    return null;
  }

  const labelStyles = concatStyleSets(
    { root: { marginLeft: iconName ? 5 : 0 } },
    styles?.fluentLabel
  );

  return (
    <LabelContainerStyled className={className} onClick={onClick} style={styles?.container}>
      <div className="icon-label-container">
        {iconName && <Icon iconName={iconName} styles={{ root: { marginTop: 9 } }} />}
        <FluentLabel htmlFor={htmlFor} styles={labelStyles} required={required}>
          {label}
        </FluentLabel>
      </div>
      {description && (
        <InfoIconTooltip
          iconName="Info"
          styles={{ iconStyles: { root: { marginTop: 9 } } }}
          content={
            <RichTextEditor
              disabled
              styles={{
                editor: {
                  fontSize: '12px',
                  color: `rgb(${theme.dynamicField.labelDescriptionForeground})`
                }
              }}
              defaultValue={description}
            />
          }
        />
      )}
    </LabelContainerStyled>
  );
}

export default Label;
