import { Dialog, Label, LanguagePicker, Panel } from 'components';
import { t } from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { createField, updateField } from 'services/fetchRequests';
import styled from 'styled-components';
import { IFieldProps, ITranslationItemProps, RouteFieldType } from 'types';
import { CommandBar, IconButton, PanelType, Separator } from '@fluentui/react';
import CreateFieldForm from './components/FieldForm';
import { CreateFormDivContainer, TranslationDivContainer, getDialogStyles } from './utils';
import FieldOwnerAndDefinitions from './components/FieldOwnerAndDefinitions';
import { useCurrentProcessDesignerTemplate } from '../../../features/ProcessDesigner/ProcessDesignerTemplateContext';
import {
  createApiObjectDataFromPlainObject,
  IProcessField,
  makeApiObjectVariantKey,
  PROCESS_FIELD,
  useApiDataCache
} from '../../../hooks/api2';

export type ISurfaceTypeType = 'panel' | 'modal';
export interface IFieldFormProps {
  isOpen: boolean;
  onClose: () => void;
  defaultValue?: IFieldProps;
  surfaceType?: ISurfaceTypeType;
  onSave: (props: IFieldProps) => void;
}

const HeaderText = styled.div`
  background-color: ${(props) => props.theme.themePrimary};
  color: white;
  font-size: 25px;
  font-weight: 600;
  min-height: 20;
  margin-left: 20px;
  margin-bottom: 35px;
`;

const CommandBarStyled = styled(CommandBar)`
  box-shadow: 0 1.6px 3.6px 0 rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%);

  .ms-OverflowSet-overflowButton {
    margin-left: auto;
  }
`;

function CreateFieldFormContainer({
  isOpen,
  onClose,
  defaultValue,
  surfaceType = 'modal',
  onSave
}: IFieldFormProps) {
  const cache = useApiDataCache();

  const [isSavedDisabled, setIsSaveDisabled] = useState(true);
  const [translation, setTranslation] = useState(false);

  // enabled field after selected language
  const [languageDisabled] = useState(true);

  // selection of language field: en, de ...
  const [selectedLanguage, setSelectedLanguage] = useState<string | undefined>();

  const [status, setStatus] = useState<'loading' | 'ready'>('loading');

  const [field, setField] = React.useState<IFieldProps>({
    fieldType: RouteFieldType.SmallText,
    maxLength: 150,
    allNames: [],
    descriptionPlacement: 1
  });

  const isSaveButtonDisabled = useCallback(() => {
    const {
      name,
      fieldType,
      textNOk,
      textOk,
      choices,
      lookupDefinitionId,
      externalServiceId,
      prompt,
      lookupDisplayFields
    } = field;

    const basicFieldInfo = !name || !fieldType;

    if (fieldType === RouteFieldType.Boolean) {
      return basicFieldInfo || !textNOk || !textOk;
    }

    if (fieldType === RouteFieldType.Choice) {
      return basicFieldInfo || !choices || choices.length < 2;
    }

    if (fieldType === RouteFieldType.Lookup) {
      return (
        basicFieldInfo ||
        !lookupDefinitionId ||
        !lookupDisplayFields ||
        lookupDisplayFields.length === 0
      );
    }

    if (fieldType === RouteFieldType.ExternalData) {
      return basicFieldInfo || !externalServiceId;
    }

    if (fieldType === RouteFieldType.AI) {
      return basicFieldInfo || !prompt;
    }

    return basicFieldInfo;
  }, [field]);

  useEffect(() => {
    if (defaultValue) {
      setField((prevState) => ({ ...prevState, ...defaultValue }));
    }

    setStatus('ready');
  }, [defaultValue]);

  useEffect(() => {
    if (field.fieldType) {
      setIsSaveDisabled(isSaveButtonDisabled());
    }
  }, [field.fieldType, isSaveButtonDisabled]);

  function handleFieldResponse(response: IFieldProps) {
    const obj = createApiObjectDataFromPlainObject(
      cache,
      makeApiObjectVariantKey(PROCESS_FIELD, response.id),
      response
    ) as IProcessField;
    cache.insertObjectData(obj);

    onSave(obj);
    setField((prevState) => ({ ...prevState, ...response }));
  }

  function onDismiss() {
    setField({ fieldType: RouteFieldType.SmallText, maxLength: 150, allNames: [] });

    setStatus('loading');
    onClose();
  }

  function handleSave(isClose: boolean) {
    if (isClose) onDismiss();

    if (field.id) updateField(field).then(handleFieldResponse);

    if (!field.id) createField(field).then(handleFieldResponse);
  }

  function addLanguageTranslationField(
    language: string,
    translations: ITranslationItemProps[],
    defaultValue?: string
  ): ITranslationItemProps[] {
    const translation = translations?.find((name) => name.language === language);
    // return existence translation Field
    if (translation) return translations;
    // Add new translation
    return [...translations, { language, text: defaultValue || '' }];
  }

  function onLanguagePickerChange(language: string) {
    setSelectedLanguage(language);

    const allNames = addLanguageTranslationField(language, field.allNames || [], field.name);
    const allDescriptions = addLanguageTranslationField(
      language,
      field.allDescriptions || [],
      field.description
    );

    let allTextsOk: ITranslationItemProps[] | undefined;
    let allTextsNOk: ITranslationItemProps[] | undefined;
    let allPrompts: ITranslationItemProps[] | undefined;

    if (field.fieldType === RouteFieldType.Boolean) {
      allTextsOk = addLanguageTranslationField(language, field.allTextsOk || [], field.textOk);
      allTextsNOk = addLanguageTranslationField(language, field.allTextsNOk || [], field.textNOk);
    }

    if (field.fieldType === RouteFieldType.AI) {
      allPrompts = addLanguageTranslationField(language, field.allPrompts || [], field.prompt);
    }

    setField((prevState) => {
      return { ...prevState, allNames, allDescriptions, allTextsOk, allTextsNOk, allPrompts };
    });
  }

  // return a flat array of language list
  function getTranslatedLanguages() {
    return field.allNames?.map(({ language }) => language) || [];
  }

  function onRenderHeader() {
    const title = t(`createField.dialog.${!field.id ? 'title' : 'titleEdit'}`);

    return (
      <>
        <CommandBarStyled
          farItems={[
            {
              iconProps: { iconName: 'Cancel' },
              key: 'Cancel',
              text: t('createField.dialog.cancel.button'),
              onClick: onDismiss
            }
          ]}
          items={[
            {
              key: isSavedDisabled?.toString(),
              text: t('createField.dialog.saveAndClose.button'),
              split: true,
              onClick: () => handleSave(true),
              iconProps: { iconName: 'SaveAndClose' },
              disabled: isSavedDisabled,
              subMenuProps: {
                items: [
                  {
                    iconProps: { iconName: 'Save' },
                    key: 'saveAndClose',
                    text: t('createField.dialog.save.button'),
                    onClick: () => handleSave(false)
                  }
                ]
              }
            }
          ]}
        />
        <HeaderText>{title}</HeaderText>
      </>
    );
  }

  const currentProcessTemplate = useCurrentProcessDesignerTemplate();

  function renderForm() {
    if (status === 'loading') return null;
    return (
      <>
        {field.id ? (
          <>
            <FieldOwnerAndDefinitions
              isGroup={false}
              id={field.id}
              whileEditingProcessTemplate={currentProcessTemplate?.id}
            />
            <Separator />
          </>
        ) : null}

        <TranslationDivContainer $gridItem={translation}>
          <div>
            <IconButton
              id="translate-button"
              iconProps={{ iconName: 'DoubleChevronRight12' }}
              onClick={() => setTranslation(true)}
              disabled={translation}
              ariaLabel="Translate"
            />
          </div>
          {translation && (
            <div style={{ display: 'flex' }}>
              <Label
                label={t('createField.dialog.language.label')}
                styles={{ container: { marginRight: '5px' } }}
              />
              <LanguagePicker
                key={field.allNames?.length || 0}
                placeholder={t('createField.dialog.language.placeholder')}
                onChange={({ key }) => onLanguagePickerChange(key)}
                styles={{ root: { width: '200px' } }}
                translatedLanguages={getTranslatedLanguages()}
                defaultLanguageKey={selectedLanguage}
              />
            </div>
          )}
        </TranslationDivContainer>
        <CreateFormDivContainer $gridItem={translation}>
          <CreateFieldForm field={field} setField={setField} />
          {translation && (
            <CreateFieldForm
              field={field}
              setField={setField}
              disabled={languageDisabled}
              language={selectedLanguage}
            />
          )}
        </CreateFormDivContainer>
      </>
    );
  }

  switch (surfaceType) {
    case 'modal':
      return (
        <Dialog
          hidden={!isOpen}
          onDismiss={onDismiss}
          content={renderForm()}
          styles={getDialogStyles(translation)}
          primaryButtonProps={{
            text: t('createField.dialog.saveAndClose.button'),
            split: true,
            menuProps: {
              items: [
                {
                  key: 'saveAndClose',
                  text: t('createField.dialog.save.button'),
                  onClick: () => handleSave(false)
                }
              ]
            },
            onClick: () => handleSave(true),
            disabled: isSavedDisabled
          }}
          defaultButtonProps={{ text: t('createField.dialog.cancel.button'), onClick: onDismiss }}
          modalProps={{ isBlocking: true }}
          title={field?.id ? t('createField.dialog.titleEdit') : t('createField.dialog.title')}
        />
      );

    case 'panel':
      return (
        <Panel
          isOpen={isOpen}
          onDismiss={onDismiss}
          onRenderBody={() => <div style={{ padding: 20 }}>{renderForm()}</div>}
          onRenderHeader={onRenderHeader}
          type={translation ? PanelType.large : PanelType.medium}
          closeButtonAriaLabel="Close"
        />
      );

    default:
      return null;
  }
}

export default CreateFieldFormContainer;
