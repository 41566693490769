/* eslint-disable no-param-reassign */

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { cloneObject } from 'utils/helpers';
import {
  Checkbox,
  Dropdown,
  IconButton,
  MessageBar,
  MessageBarType,
  TextField,
  TooltipHost,
  getTheme
} from '@fluentui/react';
import FieldPicker from '../FieldPicker';
import BodyPropertiesList, { getIndexedProperties } from './BodyPropertiesList';
import CreateRequestPropDialog from './CreateRequestPropDialog';
import PropertyTypes from './PropertyTypes';

const PropertyListContainer = styled.div`
  position: relative;
`;

const PropertyList = styled.div`
  overflow-x: auto;
  white-space: nowrap;

  .deleteButton {
    visibility: hidden;
    background-color: transparent;
  }

  .moreButton {
    visibility: hidden;
    background-color: transparent;
  }

  .addButton {
    background-color: transparent;
  }

  .groupAddButton {
    background-color: transparent;
  }

  .cancelButton {
    background-color: transparent;
  }
`;

const MessageBarStyled = styled(MessageBar)`
  position: absolute;
  width: 100%;
  margin: 5px 30px 5px 0;
  top: -45px;
  z-index: 10;
`;

const FieldPickerContainer = styled.div`
  width: 100%;
`;

function RequestBodyPropertiesList({
  hidden,
  service,
  saveBodyProperties,
  showCreateDialog,
  hideCreateDialog,
  disabledNewRow,
  handleErrors,
  bodyPropertiesErrors
}) {
  const { t } = useTranslation();

  const [newProp, setNewProp] = useState();
  const propertyTypeOptions = PropertyTypes(t);

  const [inputErrors, setInputErrors] = useState(null);

  const [newEditDialog, setNewEditDialog] = useState(null);

  useEffect(() => {
    if (showCreateDialog) {
      const getProps = () => {
        const requestBody = cloneObject(service.serviceData?.connection?.requestBody);

        const indexedProps = getIndexedProperties(requestBody);

        return indexedProps;
      };

      const properties = getProps();

      setNewProp(null);

      setNewEditDialog({
        show: true,
        groupProperties: properties,
        properties,
        forBodyProp: true,
        onlyDefault: disabledNewRow
      });
    }
  }, [showCreateDialog, service, disabledNewRow]);

  useEffect(() => {
    setInputErrors(bodyPropertiesErrors);
  }, [bodyPropertiesErrors]);

  function onDismissNewEditDialog() {
    setNewEditDialog(null);
    hideCreateDialog();
  }

  function resetErrorMessages(inputIds, onlyClose) {
    if (inputErrors?.length && inputIds?.length) {
      const errors = [];

      inputErrors.map((err) => {
        let errorAdded = false;

        inputIds.map((id) => {
          if (err.inputId === id) {
            if (onlyClose) {
              errors.push({ ...err, showMessage: false });
              errorAdded = true;
            } else {
              errors.push({ ...err, message: null, showMessage: false });
              errorAdded = true;
            }
          }

          return null;
        });

        if (!errorAdded) {
          errors.push(err);
        }

        return null;
      });

      handleErrors(errors);
    }
  }

  function addError(fieldId, message, propertyWithDoubleName) {
    let isNewError = true;

    const newInputErrors = inputErrors?.length
      ? inputErrors.map((err) => {
          const newErr = err;
          if (newErr.inputId === fieldId) {
            isNewError = false;

            newErr.message = message;
            newErr.showMessage = true;
            newErr.property = { ...propertyWithDoubleName };
            newErr.created = Date.now();
          }

          return newErr;
        })
      : [];

    if (isNewError) {
      const newError = {
        inputId: fieldId,
        message,
        showMessage: true,
        list: 'body',
        property: { ...propertyWithDoubleName },
        created: Date.now()
      };

      newInputErrors.push(newError);
    }

    handleErrors(newInputErrors);
  }

  function handleDoublePropKeyError(prop, value) {
    const propKey = Object.keys(prop)[0];

    const doubleProp = { [value]: prop[propKey] };

    const inputId = prop[propKey]?.index
      ? `${prop[propKey]?.index}-txt-internalName`
      : `${prop?.group || 0}-txt-newInternalName`;

    addError(
      inputId,
      t('requestBodyPropertiesList.errorMessage.doublePropKey', { key: value }),
      doubleProp
    );
  }

  // check each existing property in group if there is a double property key (internal name)
  function handlePropKeys(passedProps, prop, value) {
    const propKey = Object.keys(prop)[0];

    const newRequestProperties = {};
    let existingKey = false;
    let doubleNamesCounter = 1;

    if (passedProps) {
      // check if there is an existing property name
      Object.keys(passedProps).map((key) => {
        if (key === value && passedProps[key].index !== prop?.index) {
          existingKey = true;
          // increase the counter in the case of multiple same names
          if (key.startsWith(`${value}(`)) {
            doubleNamesCounter += 1;
          }
        }

        return null;
      });

      Object.keys(passedProps).map((key) => {
        if (key !== value) {
          if (key === propKey) {
            if (existingKey) {
              // change property name if it is duplicate
              newRequestProperties[`${value}(${doubleNamesCounter})`] = prop[propKey];
            } else {
              // assign new property name
              newRequestProperties[value] = prop[propKey];
            }
          } else {
            // pass property values without change
            newRequestProperties[key] = passedProps[key];
          }
        } else {
          // pass property values for existing name without change
          newRequestProperties[key] = passedProps[key];
        }

        return null;
      });
    }

    if (existingKey) {
      handleDoublePropKeyError(prop, value);

      return { props: newRequestProperties, doubleNamesCounter };
    }

    return { props: newRequestProperties };
  }

  // method goes through child properties and handles them
  // passedProperties - group of properties to check
  // propKey - key (name) of the property to add, edit or delete
  // passedProp - property to add, edit or delete
  // newPropKey - new neme of the it is beeing edited
  // fieldValue - value of the formField
  // toDeleteProp - boolean indicator should the property be deleted
  function handleChildProperties(
    passedProperties,
    propKey,
    passedProp,
    newPropKey,
    toDeleteProp,
    doubleNameProperty
  ) {
    const parentIndex = passedProp && propKey ? passedProp[propKey]?.parent?.index : null;

    let requestProperties = {};
    let doubleNamesCounter = 0;

    const replaceDoubleName = (properties) => {
      const newProperties = {};

      Object.keys(properties).map((key) => {
        if (properties[key].index === doubleNameProperty[propKey].index) {
          newProperties[propKey] = { ...properties[key] };
        } else if (key !== propKey) {
          newProperties[key] = { ...properties[key] };
        }

        return null;
      });

      return newProperties;
    };

    Object.keys(passedProperties).map((key) => {
      if (!(key === propKey && passedProperties[key].level === passedProp[propKey].level)) {
        const propType = passedProperties[key].type;

        const propIndex = passedProperties[key].index;

        if (parentIndex === propIndex) {
          let parentProperties = cloneObject(passedProperties[key]);

          if (propType === 'array') {
            if (parentProperties.items?.properties) {
              if (toDeleteProp) {
                if (doubleNameProperty) {
                  const newProps = replaceDoubleName(parentProperties.items?.properties);

                  parentProperties = {
                    ...parentProperties,
                    items: { properties: { ...newProps } }
                  };
                } else {
                  delete parentProperties.items?.properties[propKey];
                }
              } else if (newPropKey) {
                const result = handlePropKeys(
                  parentProperties.items?.properties,
                  passedProp,
                  newPropKey
                );

                if (result?.props && Object.keys(result.props)?.length) {
                  parentProperties.items.properties = result.props;
                }

                doubleNamesCounter = result?.doubleNamesCounter;

                if (doubleNameProperty) {
                  const newProps = replaceDoubleName(parentProperties.items?.properties);

                  parentProperties = {
                    ...parentProperties,
                    items: { properties: { ...newProps } }
                  };
                }
              } else {
                parentProperties.items.properties[propKey] = passedProp[propKey];
              }
            } else {
              parentProperties = {
                ...parentProperties,
                items: { properties: { [propKey]: passedProp[propKey] } }
              };
            }
          } else if (propType === 'object') {
            if (parentProperties.properties) {
              if (toDeleteProp) {
                if (doubleNameProperty) {
                  const newProps = replaceDoubleName(parentProperties.properties);

                  parentProperties = {
                    ...parentProperties,
                    properties: { ...newProps }
                  };
                } else {
                  delete parentProperties.properties[propKey];
                }
              } else if (newPropKey) {
                const result = handlePropKeys(parentProperties.properties, passedProp, newPropKey);

                if (result?.props && Object.keys(result.props)?.length) {
                  parentProperties.properties = result.props;
                }

                doubleNamesCounter = result?.doubleNamesCounter;

                if (doubleNameProperty) {
                  const newProps = replaceDoubleName(parentProperties.properties);

                  parentProperties = {
                    ...parentProperties,
                    properties: { ...newProps }
                  };
                }
              } else {
                parentProperties.properties[propKey] = passedProp[propKey];
              }
            } else {
              parentProperties = {
                ...parentProperties,
                properties: { [propKey]: passedProp[propKey] }
              };
            }
          }

          passedProperties[key] = { ...parentProperties };
        } else if (propType === 'array' && passedProperties[key]?.items?.properties) {
          handleChildProperties(
            passedProperties[key].items.properties,
            propKey,
            passedProp,
            newPropKey,
            toDeleteProp,
            doubleNameProperty
          );
        } else if (propType === 'object' && passedProperties[key]?.properties) {
          handleChildProperties(
            passedProperties[key].properties,
            propKey,
            passedProp,
            newPropKey,
            toDeleteProp,
            doubleNameProperty
          );
        }

        requestProperties = cloneObject(passedProperties);
      }

      doubleNamesCounter = 0;

      return null;
    });

    if (doubleNamesCounter) {
      return null;
    }

    return requestProperties;
  }

  function handleCurrentInput(type, value) {
    let requestBody = { ...service.serviceData?.connection?.requestBody };

    if (type === 'properties' && value) {
      const properties = {};
      const required = [];

      const setProperties = (newProperties, passedProps, newRequired) => {
        if (passedProps) {
          Object.keys(passedProps).map((propKey) => {
            newProperties[propKey] = {
              default: passedProps[propKey].default,
              type: passedProps[propKey].type,
              'x-ep-mappedField': passedProps[propKey]['x-ep-mappedField'],
              description: passedProps[propKey].description
            };

            if (passedProps[propKey].type === 'object') {
              const objProperties = {};
              const objRequired = [];

              setProperties(objProperties, passedProps[propKey].properties, objRequired);

              newProperties[propKey].properties = objProperties;
              newProperties[propKey].required = objRequired;
              newProperties[propKey].type = 'object';
            } else if (passedProps[propKey].type === 'array') {
              const arrayProperties = {};
              const arrayRequired = [];

              setProperties(arrayProperties, passedProps[propKey].items?.properties, arrayRequired);
              newProperties[propKey].items = { properties: arrayProperties, type: 'object' };
              newProperties[propKey].items = {
                ...newProperties[propKey].items,
                required: arrayRequired
              };
            }

            if (passedProps[propKey].isRequired) {
              newRequired.push(propKey);
            } else if (!Array.isArray(passedProps[propKey].type)) {
              newProperties[propKey].type = [passedProps[propKey].type, null];
            }

            return null;
          });
        }
      };

      setProperties(properties, value, required);

      requestBody = {
        properties,
        required,
        type: requestBody?.type || 'object'
      };

      saveBodyProperties(requestBody);
    }
  }

  function onDeleteProp(indexedProps, property) {
    if (property) {
      const propKey = Object.keys(property)[0];

      resetErrorMessages([`${property?.[propKey]?.index}-txt-internalName`]);

      let requestProperties = cloneObject(indexedProps);

      let doubleNameProperty = null;

      const doubleNameError = inputErrors?.find(
        (err) =>
          err.inputId === `${err.property?.[propKey]?.index}-txt-internalName` &&
          err.property?.[propKey]?.index !== property[propKey].index &&
          Object.keys(err.property)?.[0] === propKey &&
          err.message
      );

      if (doubleNameError) {
        resetErrorMessages([`${doubleNameError.property[propKey]?.index}-txt-internalName`]);

        doubleNameProperty = doubleNameError.property;
      }

      if (property[propKey].parent) {
        handleChildProperties(requestProperties, propKey, property, null, true, doubleNameProperty);
      } else if (requestProperties) {
        const newProperties = {};

        Object.keys(requestProperties).map((key) => {
          if (requestProperties[key].index === doubleNameProperty?.[propKey]?.index) {
            newProperties[propKey] = { ...requestProperties[key] };
          } else if (key !== propKey) {
            newProperties[key] = { ...requestProperties[key] };
          }

          return null;
        });

        requestProperties = newProperties;
      }

      handleCurrentInput('properties', requestProperties);
    }
  }

  function handleBodyProperties(indexedProps, prop, newInternalName) {
    if (prop) {
      const propKey = Object.keys(prop)[0];

      const requestProperties = cloneObject(indexedProps);

      let finalRequestProps = {};

      // for exisiting properties
      if (requestProperties) {
        let newProp;
        let newRequestProperties = {};
        let doubleNameProperty = null;

        // handle property name changes
        if (newInternalName) {
          // check if there is a pending error with double property name
          const doubleNameError = inputErrors?.find(
            (err) =>
              err.inputId === `${err.property?.[propKey]?.index}-txt-internalName` &&
              err.property?.[propKey]?.index !== prop[propKey].index &&
              Object.keys(err.property)?.[0] === propKey &&
              err.message
          );

          if (doubleNameError) {
            // reset error message about double property name if there is one
            resetErrorMessages([`${doubleNameError.property[propKey]?.index}-txt-internalName`]);

            doubleNameProperty = doubleNameError.property;
          } else {
            resetErrorMessages([`${prop[propKey]?.index}-txt-internalName`]);
          }

          if (prop[propKey].parent) {
            // handle child properties for complex (multi-level) property model and assign values
            newRequestProperties = handleChildProperties(
              requestProperties,
              propKey,
              prop,
              newInternalName,
              null,
              doubleNameProperty
            );
          } else {
            // assign values for changes in first level of a complex (multi-level) property model or in simple property model
            const result = handlePropKeys(requestProperties, prop, newInternalName);

            newRequestProperties = result?.props;

            if (!result?.doubleNamesCounter) {
              newProp = { [newInternalName]: { ...prop[propKey] } };
            }
          }
        } else {
          // assign values in case of property changes other then property name changes
          newProp = { ...prop };

          newRequestProperties = requestProperties;
        }

        // handle all changes in in a simple property model (first level of a complex model) or
        // changes other then property name in a complex (multi-level) property model
        if (newProp) {
          const newPropKey = Object.keys(newProp)[0];

          const handleProps = (passedProp) => {
            if (!passedProp[newPropKey].parent) {
              // handle changes in simple (one level) property model
              if (
                doubleNameProperty &&
                newRequestProperties &&
                Object.keys(newRequestProperties)?.length
              ) {
                Object.keys(newRequestProperties).map((key) => {
                  if (newRequestProperties[key].index === doubleNameProperty[propKey].index) {
                    newRequestProperties[propKey] = { ...newRequestProperties[key] };

                    delete newRequestProperties[key];
                  }

                  return null;
                });
              }

              finalRequestProps = { ...newRequestProperties, [newPropKey]: passedProp[newPropKey] };
            } else {
              // handle changes (except property name changes) in complex (multi-level) property model
              finalRequestProps = handleChildProperties(
                newRequestProperties,
                newPropKey,
                passedProp
              );
            }
          };

          handleProps(newProp);
        } else {
          finalRequestProps = newRequestProperties;
        }
      } else {
        // for new (first) property
        finalRequestProps = { ...newProp };
      }

      if (newEditDialog?.show) {
        setNewEditDialog(null);
      }

      handleCurrentInput('properties', finalRequestProps);
    }
  }

  function onEditProp(indexedProps, property) {
    if (property) {
      const propKey = Object.keys(property)[0];

      let groupProperties = indexedProps;

      if (indexedProps && property[propKey].parent) {
        let parentProperties = null;

        const setParentProperties = (passedProperties) => {
          if (passedProperties) {
            // find parent properties by index
            Object.keys(passedProperties).map((key) => {
              if (passedProperties[key].index === property[propKey].index) {
                parentProperties = passedProperties[key];
              } else if (passedProperties[key].type === 'array') {
                setParentProperties(passedProperties[key].items?.properties);
              } else if (passedProperties[key].type === 'object') {
                setParentProperties(passedProperties[key].properties);
              }
              return null;
            });
          }
        };

        setParentProperties(indexedProps);

        if (parentProperties) {
          groupProperties = parentProperties;
        }
      }

      const selectedProp = {
        ...property[propKey],
        internalName: propKey
      };

      setNewEditDialog({
        show: true,
        selectedProp,
        groupProperties,
        properties: indexedProps,
        onlyDefault: !!property[propKey]?.default
      });
    }
  }

  // Save the new property
  function onAddNewProperty(indexedProps, propFromDialog) {
    if (newProp && !newProp?.internalName) {
      const inputId = `${newProp.index}-txt-internalName`;

      addError(inputId, t('requestBodyPropertiesList.errorMessage.noInternalName'));
    } else {
      let property = null;

      if (propFromDialog) {
        property = { ...propFromDialog };
      } else if (newProp) {
        property = {
          [newProp.internalName]: {
            default: newProp.default,
            'x-ep-mappedField': newProp['x-ep-mappedField'],
            type: newProp.type,
            isRequired: newProp.isRequired,
            description: newProp.description,
            index: newProp.index,
            parent: newProp.parent,
            group: newProp.group,
            level: newProp.level
          }
        };
      }

      handleBodyProperties(indexedProps, property);

      if (propFromDialog) {
        onDismissNewEditDialog();
      } else {
        setNewProp(null);
      }
    }
  }

  // Set the current state of the new property
  function handleNewProperty(indexedProperties, name, value, index, parentProperties, group) {
    if (name) {
      let property;

      if (name === 'type') {
        property = newProp
          ? { ...newProp, [name]: value.key }
          : {
              [name]: value.key,
              index,
              parent: parentProperties
                ? { type: parentProperties.type, index: parentProperties.index }
                : null,
              group
            };
      } else if (name === 'x-ep-mappedField') {
        if (newProp) {
          resetErrorMessages([`${group}-picker-newXEpMappedField`]);

          if (newProp.isRequired && !value?.[0]?.id) {
            addError(
              `${group}-picker-newXEpMappedField`,
              t('requestBodyPropertiesList.errorMessage.requiredMappedField')
            );
          }

          property = { ...newProp, [name]: value?.[0] };
        } else {
          property = {
            [name]: value?.[0],
            index,
            parent: parentProperties
              ? { type: parentProperties.type, index: parentProperties.index }
              : null,
            group
          };
        }
      } else {
        property = newProp
          ? { ...newProp, [name]: value }
          : {
              [name]: value,
              index,
              parent: parentProperties
                ? { type: parentProperties.type, index: parentProperties.index }
                : null,
              group
            };

        if (name === 'internalName') {
          resetErrorMessages([`${group || 0}-txt-newInternalName`]);

          let properties = indexedProperties;

          if (parentProperties) {
            if (parentProperties.type === 'array' && parentProperties.items?.properties) {
              properties = parentProperties.items.properties;
            } else if (parentProperties.type === 'object' && parentProperties.properties) {
              properties = parentProperties.properties;
            }
          }

          handlePropKeys(properties, property, value);
        } else if (name === 'isRequired') {
          resetErrorMessages([`${group}-picker-newXEpMappedField`]);

          if (value && !newProp?.['x-ep-mappedField']?.id) {
            addError(
              `${group || 0}-picker-newXEpMappedField`,
              t('requestBodyPropertiesList.errorMessage.requiredMappedField')
            );
          }
        }
      }

      setNewProp(property);
    }
  }

  // Cancel changes to the new property (reset the state of the new property)
  function cancelNewProperty(group) {
    resetErrorMessages([
      `${group || 0}-txt-newInternalNameField`,
      `${group || 0}-picker-newXEpMappedField`
    ]);

    setNewProp(null);
  }

  function handleInternalNameChange(indexedProps, prop, value) {
    if (prop) {
      const newProp = { ...prop };
      const propKey = Object.keys(newProp)[0];

      if (!value) {
        const inputId = `${newProp[propKey].index}-txt-internalName`;

        addError(inputId, t('requestBodyPropertiesList.errorMessage.noInternalName'));
      } else {
        resetErrorMessages([`${newProp[propKey]?.index}-txt-internalName`]);

        if (propKey !== value) {
          handleBodyProperties(indexedProps, prop, value);
        }
      }
    }
  }

  function handleMappedFieldChange(indexedProps, prop, value) {
    if (prop) {
      const newProp = { ...prop };
      const propKey = Object.keys(newProp)[0];

      resetErrorMessages([`${newProp[propKey]?.index}-picker-xEpMappedField`]);

      if (newProp[propKey]?.isRequired && !value?.[0]?.id) {
        addError(
          `${newProp[propKey]?.index}-picker-xEpMappedField`,
          t('requestBodyPropertiesList.errorMessage.requiredMappedField')
        );
      }

      newProp[propKey]['x-ep-mappedField'] = value?.[0];

      handleBodyProperties(indexedProps, newProp);
    }
  }

  function handlePropTypeChange(indexedProps, prop, value) {
    const propKey = Object.keys(prop)[0];

    if (prop[propKey].type !== value.key) {
      const newProp = cloneObject(prop);

      if (prop[propKey].type === 'array') {
        if (value.key === 'object' && prop[propKey].items?.properties) {
          newProp[propKey].properties = { ...prop[propKey].items.properties };
        }

        delete newProp[propKey].items;
      } else if (prop[propKey].type === 'object') {
        if (value.key === 'array' && prop[propKey].properties) {
          newProp[propKey].items = { properties: prop[propKey].properties };
        }

        delete newProp[propKey].properties;
      }

      newProp[propKey].type = value.key;

      handleBodyProperties(indexedProps, newProp);
    }
  }

  function handleRequiredChange(indexedProps, prop, checked) {
    const propKey = Object.keys(prop)[0];

    const newProp = { ...prop };

    resetErrorMessages([`${newProp[propKey]?.index}-picker-xEpMappedField`]);

    if (checked && !newProp[propKey]?.['x-ep-mappedField']?.id) {
      addError(
        `${newProp[propKey]?.index}-picker-xEpMappedField`,
        t('requestBodyPropertiesList.errorMessage.requiredMappedField')
      );
    }

    newProp[propKey].isRequired = checked;

    handleBodyProperties(indexedProps, newProp);
  }

  function getActionColumn(indexedProps, property, addNewProp, deleteProp, inputErrors) {
    const propKey = Object.keys(property)[0];
    let propType = property[propKey]?.type;

    if (property[propKey]?.type && Array.isArray(property[propKey]?.type)) {
      propType = property[propKey].type.find((x) => x !== null);
    }

    let disableNewChildForm = false;

    if (inputErrors?.length) {
      inputErrors.map((err) => {
        if (err.list === 'body' && err.message && err.property) {
          if (err.property[Object.keys(err.property)[0]]?.group === property[propKey]?.group) {
            disableNewChildForm = true;
          }
        }

        return null;
      });
    }

    const actionItems = [
      {
        key: 'editProperty',
        text: t('requestBodyPropertiesList.column.action.editProp'),
        iconProps: { iconName: 'Edit' },
        onClick: () => onEditProp(indexedProps, property)
      },
      {
        key: 'deleteProperty',
        text: t('requestBodyPropertiesList.column.action.deleteProp'),
        iconProps: { iconName: 'Delete' },
        onClick: deleteProp
      }
    ];

    if (propType === 'array' || propType === 'object') {
      const parent = { type: property[propKey].type, index: property[propKey].index };
      let groupProperties = null;

      let index = `${property[propKey].index}-1`;
      if (propType === 'array' && property[propKey].items?.properties) {
        groupProperties = property[propKey].items.properties;
        index = `${property[propKey].index}-${
          Object.keys(property[propKey].items.properties).length
        }`;
      } else if (property[propKey].properties) {
        groupProperties = property[propKey].properties;
        index = `${property[propKey].index}-${
          Object.keys(property[propKey].properties).length + 1
        }`;
      }

      const selectedProp = {
        index,
        parent,
        group: property[propKey].group,
        level: property[propKey].level + 1
      };

      actionItems.unshift({
        key: 'addSubProperty',
        text: t('requestBodyPropertiesList.column.action.addProp'),
        iconProps: { iconName: 'Add' },
        disabled: disableNewChildForm,
        onClick: () => {
          setNewProp(null);
          setNewEditDialog({ show: true, selectedProp, groupProperties, properties: indexedProps });
          return null;
        }
      });
    }

    const actionColumn = (
      <IconButton
        className="moreButton"
        menuIconProps={{ iconName: 'More' }}
        menuProps={{
          items: actionItems
        }}
        styles={{
          rootHovered: { backgroundColor: '#E1E1E1' },
          root: {
            height: '20px'
          },
          menuIcon: {
            fontSize: '14px',
            fontWeight: '600'
          }
        }}
      />
    );

    return actionColumn;
  }

  function getErrorMessage() {
    if (inputErrors?.length) {
      let lastError = null;

      inputErrors.sort((a, b) => {
        return new Date(a.created) - new Date(b.created);
      });

      for (let i = inputErrors.length - 1; i >= 0; i -= 1) {
        if (
          inputErrors[i].showMessage &&
          inputErrors[i].message &&
          inputErrors[i].list === 'body'
        ) {
          lastError = inputErrors[i];
          break;
        }
      }

      if (lastError?.inputId) {
        return (
          <MessageBarStyled
            messageBarType={MessageBarType.error}
            isMultiline
            onDismiss={() => resetErrorMessages([lastError.inputId], true)}
            dismissButtonAriaLabel="Close"
          >
            {lastError?.message}
          </MessageBarStyled>
        );
      }
    }

    return null;
  }

  function thereIsAnErrorMessage(inputId) {
    if (inputErrors?.length) {
      const error = inputErrors.find((err) => err.inputId === inputId);

      return !!error?.message;
    }

    return false;
  }

  // Column widths
  const internalNameColumnWidth = 150;
  const mappedFieldColumnWidth = 300;
  const typeColumnWidth = 105;
  const isRequiredColumnWidth = 80;

  // Columns for displaying data in the list
  const columns = [
    {
      key: 'internalName',
      name: t('requestBodyPropertiesList.column.internalName'),
      fieldName: 'internalName',
      minWidth: internalNameColumnWidth,
      maxWidth: internalNameColumnWidth,
      onRender: (indexedProps, property) => {
        const propKey = Object.keys(property)[0];

        const errorInField = inputErrors?.find(
          (err) => err.inputId === `${property[propKey]?.index}-txt-internalName` && err.message
        );

        const errorPropKey = errorInField?.property ? Object.keys(errorInField.property)[0] : null;

        return (
          <TextField
            id={`${property[propKey]?.index}-txt-internalName`}
            onChange={(ev, value) => handleInternalNameChange(indexedProps, property, value)}
            defaultValue={errorPropKey || propKey}
            borderless
            styles={{
              root: {
                width: '100%'
              },
              field: {
                borderRadius: 0,
                borderBottom: errorInField ? '2px solid rgb(164, 38, 44)' : 'none',
                selectors: {
                  '&:hover': {
                    borderRadius: 0,
                    borderBottom: errorInField
                      ? '2px solid rgb(164, 38, 44)'
                      : '1px solid rgb(220, 220, 220)'
                  },
                  '&:hover:focus': {
                    borderRadius: 0,
                    borderBottom: errorInField
                      ? '2px solid rgb(164, 38, 44)'
                      : `2px solid ${getTheme().palette.themePrimary}`
                  },
                  ':focus': {
                    borderRadius: 0,
                    borderBottom: errorInField
                      ? '2px solid rgb(164, 38, 44)'
                      : `2px solid ${getTheme().palette.themePrimary}`
                  }
                }
              }
            }}
          />
        );
      }
    },
    {
      key: 'xEpMappedField',
      name: t('requestBodyPropertiesList.column.mappedField.name'),
      fieldName: 'xEpMappedField',
      minWidth: mappedFieldColumnWidth,
      maxWidth: mappedFieldColumnWidth,
      infoText: t('requestBodyPropertiesList.column.mappedField.infoText'),
      onRender: (indexedProps, property) => {
        const propKey = Object.keys(property)[0];

        const errorInField = thereIsAnErrorMessage(
          `${property[propKey]?.index}-picker-xEpMappedField`
        );

        const borderBottomColorOnHover = () => {
          if (errorInField) {
            return '2px solid rgb(164, 38, 44)';
          }

          if (!property[propKey]?.default) {
            return '1px solid rgb(220, 220, 220)';
          }

          return null;
        };

        const fieldPickerStyles = {
          input: {
            minWidth: mappedFieldColumnWidth - 10,
            maxWidth: mappedFieldColumnWidth - 10,
            borderBottom: errorInField ? '2px solid rgb(164, 38, 44)' : 'none',
            marginBottom: '-1px',
            selectors: {
              '&:hover': {
                borderRadius: 0,
                borderBottom: borderBottomColorOnHover()
              },
              ':focus': {
                borderRadius: 0,
                borderBottom: errorInField
                  ? '2px solid rgb(164, 38, 44)'
                  : `2px solid ${getTheme().palette.themePrimary}`
              }
            }
          },
          text: {
            border: 'none',
            selectors: {
              '::after': {
                border: 'none'
              }
            }
          }
        };

        return (
          <FieldPickerContainer id={`${property[propKey]?.index}-picker-xEpMappedField`}>
            <FieldPicker
              onChange={(value) => handleMappedFieldChange(indexedProps, property, value)}
              selectedField={property[propKey]?.['x-ep-mappedField'] || null}
              styles={fieldPickerStyles}
              disabled={!!property[propKey]?.default}
            />
          </FieldPickerContainer>
        );
      }
    },
    {
      key: 'type',
      name: t('requestBodyPropertiesList.column.propType'),
      fieldName: 'type',
      minWidth: typeColumnWidth,
      maxWidth: typeColumnWidth,
      onRender: (indexedProps, property) => {
        const propKey = Object.keys(property)[0];

        return (
          <Dropdown
            key={`${property[propKey]?.index}-type-${property[propKey]?.type}`}
            disabled={false}
            required={false}
            options={propertyTypeOptions}
            defaultSelectedKey={property[propKey]?.type}
            onChange={(_, value) => handlePropTypeChange(indexedProps, property, value)}
            styles={{
              title: { border: 'none' },
              root: {
                width: '100%',
                border: 'none',
                selectors: {
                  '&:hover': {
                    borderRadius: 0,
                    borderBottom: '1px solid rgb(220, 220, 220)'
                  }
                }
              }
            }}
          />
        );
      }
    },
    {
      key: 'isRequired',
      name: t('requestBodyPropertiesList.column.isRequired'),
      fieldName: 'isRequired',
      minWidth: isRequiredColumnWidth,
      maxWidth: isRequiredColumnWidth,
      centered: true,
      onRender: (indexedProps, property) => {
        const propKey = Object.keys(property)[0];

        return (
          <Checkbox
            key={`${property[propKey]?.index}-required-${property[propKey]?.isRequired}`}
            checked={property[propKey]?.isRequired}
            onChange={(ev, checked) => handleRequiredChange(indexedProps, property, checked)}
            disabled={!!property[propKey]?.default}
          />
        );
      }
    },
    {
      key: 'actions',
      fieldName: 'actions',
      centered: true,
      onRender: (indexedProps, prop, addNewProp, deleteProp) => {
        return getActionColumn(indexedProps, prop, addNewProp, deleteProp, inputErrors);
      }
    }
  ];

  // Columns for new property form
  const newEditColumns = [
    {
      key: 'newInternalName',
      minWidth: internalNameColumnWidth,
      maxWidth: internalNameColumnWidth,
      onRender: (property, changeNewProp) => {
        const errorInField = thereIsAnErrorMessage(`${property?.group || 0}-txt-newInternalName`);

        return (
          <TextField
            id={`${property?.group || 0}-txt-newInternalName`}
            onChange={(ev, value) => changeNewProp('internalName', value)}
            value={property?.internalName || ''}
            borderless
            styles={{
              root: { width: '100%' },
              field: {
                borderRadius: 0,
                borderBottom: errorInField
                  ? '2px solid rgb(164, 38, 44)'
                  : '1px solid rgb(96, 94, 92)',
                selectors: {
                  '&:hover': {
                    borderRadius: 0,
                    borderBottom: errorInField
                      ? '2px solid rgb(164, 38, 44)'
                      : '1px solid rgb(96, 94, 92)',
                    marginBottom: '2px'
                  },
                  '&:hover:focus': {
                    borderRadius: 0,
                    borderBottom: errorInField
                      ? '2px solid rgb(164, 38, 44)'
                      : `2px solid ${getTheme().palette.themePrimary}`,
                    marginBottom: 0
                  },
                  ':focus': {
                    borderRadius: 0,
                    borderBottom: errorInField
                      ? '2px solid rgb(164, 38, 44)'
                      : `2px solid ${getTheme().palette.themePrimary}`
                  }
                }
              }
            }}
          />
        );
      }
    },
    {
      key: 'newXEpMappedField',
      minWidth: mappedFieldColumnWidth,
      maxWidth: mappedFieldColumnWidth,
      onRender: (property, changeNewProp) => {
        const errorInField = thereIsAnErrorMessage(
          `${property?.group || 0}-picker-newXEpMappedField`
        );

        const borderBottomColorOnHover = () => {
          if (errorInField) {
            return '2px solid rgb(164, 38, 44)';
          }

          if (!property?.default) {
            return '1px solid rgb(96, 94, 92)';
          }

          return null;
        };

        const fieldPickerStyles = {
          input: {
            minWidth: mappedFieldColumnWidth - 10,
            maxWidth: mappedFieldColumnWidth - 10,
            borderRadius: 0,
            borderBottom: errorInField ? '2px solid rgb(164, 38, 44)' : '1px solid rgb(96, 94, 92)',
            marginBottom: '-1px',
            selectors: {
              '&:hover': {
                borderRadius: 0,
                borderBottom: borderBottomColorOnHover(),
                marginBottom: '1px'
              },
              '&:hover:focus': {
                marginBottom: '-1px'
              },
              ':focus': {
                borderRadius: 0,
                borderBottom: errorInField
                  ? '2px solid rgb(164, 38, 44)'
                  : `2px solid ${getTheme().palette.themePrimary}`
              }
            }
          },
          text: {
            border: 'none',
            selectors: {
              '::after': {
                border: 'none'
              }
            }
          }
        };

        return (
          <FieldPickerContainer id={`${property?.group || 0}-picker-newXEpMappedField`}>
            <FieldPicker
              key={`${property?.group || 0}-picker-newXEpMappedField`}
              onChange={(value) => changeNewProp('x-ep-mappedField', value)}
              selectedField={property?.['x-ep-mappedField'] || null}
              styles={fieldPickerStyles}
            />
          </FieldPickerContainer>
        );
      }
    },
    {
      key: 'newType',
      fieldName: 'newType',
      minWidth: typeColumnWidth,
      maxWidth: typeColumnWidth,
      onRender: (property, changeNewProp) => {
        return (
          <Dropdown
            options={propertyTypeOptions}
            defaultSelectedKey={property?.type || null}
            onChange={(ev, value) => changeNewProp('type', value)}
            styles={{
              title: { border: 'none' },
              root: {
                borderRadius: 0,
                borderBottom: '1px solid rgb(96, 94, 92)',
                width: '100%'
              }
            }}
          />
        );
      }
    },
    {
      key: 'isRequired',
      fieldName: 'isRequired',
      minWidth: isRequiredColumnWidth,
      maxWidth: isRequiredColumnWidth,
      centered: true,
      onRender: (property, changeNewProp) => {
        return (
          <Checkbox
            checked={property?.isRequired || false}
            onChange={(ev, checked) => changeNewProp('isRequired', checked)}
          />
        );
      }
    },
    {
      key: 'newActions',
      fieldName: 'newActions',
      centered: true,
      visibleOnlyOnFocus: true,
      onRender: (property, cancelNewProp, saveNewProp) => {
        return (
          <div>
            <TooltipHost
              content={t('requestBodyPropertiesList.column.action.saveNewProp')}
              calloutProps={{
                gapSpace: 0,
                target: `#btn-add-new-body-prop-${property.index}`
              }}
            >
              <IconButton
                id={`btn-add-new-body-prop-${property.index}`}
                className="addButton"
                iconProps={{ iconName: 'Accept' }}
                onClick={(ev) => saveNewProp(ev)}
                disabled={
                  !(property?.internalName && property?.type) &&
                  inputErrors?.find((err) => !!err.message)?.length
                }
                styles={{
                  root: {
                    marginRight: '2px',
                    display:
                      property?.internalName &&
                      property?.type &&
                      !inputErrors?.find((err) => !!err.message)?.length
                        ? 'inline-block'
                        : 'none',
                    height: '30px',
                    width: '30px'
                  }
                }}
              />
            </TooltipHost>
            <TooltipHost
              content={t('requestBodyPropertiesList.column.action.cancelNewProp')}
              calloutProps={{
                gapSpace: 0,
                target: `#btn-cancel-new-body-prop-${property.internalName}`
              }}
            >
              <IconButton
                id={`btn-cancel-new-body-prop-${property.internalName}`}
                className="cancelButton"
                iconProps={{ iconName: 'Cancel' }}
                onClick={() => cancelNewProp()}
                styles={{
                  root: {
                    display:
                      property?.internalName || property?.type || property?.isRequired
                        ? 'inline-block'
                        : 'none',
                    width: '30px',
                    height: '30px'
                  }
                }}
              />
            </TooltipHost>
          </div>
        );
      }
    }
  ];

  const propertiesKey = newEditDialog?.show ? 'changedList' : null;

  return (
    <PropertyListContainer>
      <PropertyList>
        {!hidden ? (
          <>
            {getErrorMessage()}
            <div key={propertiesKey}>
              <BodyPropertiesList
                body={service?.serviceData?.connection?.requestBody}
                columns={columns}
                newEditColumns={newEditColumns}
                onChangeNewProp={handleNewProperty}
                onCancelNewProp={cancelNewProperty}
                onSaveNewProp={onAddNewProperty}
                onDeleteProp={onDeleteProp}
                newProp={newProp}
                disabledNewRow={disabledNewRow}
              />
            </div>
          </>
        ) : null}
        {newEditDialog?.show ? (
          <CreateRequestPropDialog
            requestProperty={newEditDialog?.selectedProp || null}
            groupRequestProps={newEditDialog?.groupProperties || null}
            onSaveChanges={newEditDialog?.selectedProp ? handleBodyProperties : onAddNewProperty}
            onDismiss={onDismissNewEditDialog}
            hidden={!newEditDialog?.show}
            propType="body"
            requestProperties={newEditDialog?.properties || null}
            onlyDefault={newEditDialog.onlyDefault}
            inputErrors={inputErrors}
          />
        ) : null}
      </PropertyList>
    </PropertyListContainer>
  );
}

RequestBodyPropertiesList.propTypes = {
  hidden: PropTypes.bool,
  service: PropTypes.object,
  saveBodyProperties: PropTypes.func.isRequired,
  showCreateDialog: PropTypes.bool,
  hideCreateDialog: PropTypes.func.isRequired,
  disabledNewRow: PropTypes.bool,
  handleErrors: PropTypes.func,
  bodyPropertiesErrors: PropTypes.arrayOf(PropTypes.object)
};

RequestBodyPropertiesList.defaultProps = {
  hidden: null,
  service: null,
  showCreateDialog: false,
  disabledNewRow: null,
  handleErrors: null,
  bodyPropertiesErrors: null
};

export default RequestBodyPropertiesList;
