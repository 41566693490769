import cloneDeep from 'lodash/cloneDeep';
import fetchRequest from 'services/api';
import { ViewStatus } from 'types';
import { IBubble, ITrainer, ITraining } from 'types/interfaces';
import { create } from 'zustand';

interface IBubbleGroup {
  id: string;
  trainerId: string;
  bubbles: IBubble[];
}

interface IBubblesState {
  activeBubbleGroups: IBubbleGroup[];
  addActiveBubbleGroups: (bubbleGroups: IBubbleGroup[]) => void;
  updateActiveBubbleGroup: (bubbleGroup: IBubbleGroup) => void;
  deleteActiveBubbleGroup: (groupId: string) => void;
  setBubbleStatus: (bubbleId: string, viewStatus: ViewStatus) => void;
  trainers: ITrainer[];
  getTraining: (trainingId: string) => ITraining | undefined;
  setTrainers: (trainer: ITrainer[]) => void;
  setTrainer: (trainer: ITrainer) => void;
  setTraining: (training: ITraining) => void;
  setTrainingStatus: (
    trainingId: string,
    viewStatus: ViewStatus,
    userId: string,
    updateBubbles?: boolean
  ) => void;
}

const { crossApiOrigin = 'https://befa945a.azurewebsites.net' } = window.config;

function fetchStatus({
  trainerId,
  trainingId,
  trainingBubbleId,
  status,
  userId,
  updateChildren
}: {
  trainerId: string;
  trainingId?: string;
  trainingBubbleId?: string;
  status: ViewStatus;
  userId: string;
  updateChildren?: boolean;
}) {
  return fetchRequest({
    method: 'POST',
    ignoreAlert: true,
    body: JSON.stringify({
      userId,
      trainerId,
      trainingId,
      trainingBubbleId,
      viewStatus: status,
      updateChildren
    }),
    url: 'help/Trainer/Status',
    origin: `${crossApiOrigin}/api`
  });
}

const useBubblesStore = create<IBubblesState>()((set, get) => ({
  activeBubbleGroups: [],
  trainers: [],
  addActiveBubbleGroups: (bubbleGroups: IBubbleGroup[]) =>
    set((state) => ({
      ...state,
      activeBubbleGroups: [...state.activeBubbleGroups, ...bubbleGroups]
    })),
  updateActiveBubbleGroup: (bubbleGroup: IBubbleGroup) =>
    set((state) => ({
      ...state,
      activeBubbleGroups: state.activeBubbleGroups.map((bg) =>
        bg.id === bubbleGroup.id ? bubbleGroup : bg
      )
    })),
  deleteActiveBubbleGroup: (groupId: string) => {
    set((state) => ({
      ...state,
      activeBubbleGroups: state.activeBubbleGroups.filter((bg) => bg.id !== groupId)
    }));
  },
  setBubbleStatus: (bubbleId: string, viewStatus: ViewStatus) => {
    set((state: IBubblesState) => {
      const activeBubbleGroups = [...state.activeBubbleGroups]
        // set viewStatus for bubble
        .map((bg) => ({
          ...bg,
          bubbles: bg.bubbles.map((b) => (b.id === bubbleId ? { ...b, viewStatus } : b))
        }));

      // set viewStatus for bubble in trainers
      const trainers = state.trainers.map((t) => {
        const trainings = t.trainings.map((tr) => {
          // set viewStatus for bubble
          const bubbles = tr.bubbles.map((bubble) => {
            if (bubble.id === bubbleId) {
              return { ...bubble, viewStatus };
            }

            return bubble;
          });

          // set viewStatus for training if all bubbles are read
          const trainingViewStatus = bubbles.every((bubble) => {
            const { viewStatus } = bubble;
            return viewStatus === ViewStatus.read;
          })
            ? ViewStatus.read
            : tr.viewStatus;

          return { ...tr, viewStatus: trainingViewStatus, bubbles };
        });

        const trainer = { ...t, trainings };

        return trainer;
      });

      return {
        ...state,
        activeBubbleGroups,
        trainers: cloneDeep(trainers)
      };
    });
  },
  setTrainers: (trainers: ITrainer[]) => set({ trainers }),
  setTrainer: (trainer: ITrainer) =>
    set((state) => ({
      ...state,
      trainers: state.trainers.map((t) => (t.id === trainer.id ? trainer : t))
    })),
  getTraining: (trainingId: string) => {
    const { trainers } = get();
    const training = trainers.reduce(
      (acc, trainer) => trainer.trainings.find((t) => t.id === trainingId) || acc,
      {} as ITraining
    );

    return training;
  },

  setTrainingStatus: (
    trainingId: string,
    status: ViewStatus,
    userId: string,
    updateBubbles = false
  ) => {
    return set((state: IBubblesState) => {
      const stateClone = { ...state };
      const training = stateClone.trainers.reduce(
        (acc, trainer) => trainer.trainings.find((t) => t.id === trainingId) || acc,
        {} as ITraining
      );

      training.viewStatus = status;

      const updateChildren =
        updateBubbles ||
        (status === ViewStatus.running &&
          training.bubbles.every((b) => b.viewStatus === ViewStatus.read));

      fetchStatus({
        trainerId: training.trainerId as string,
        trainingId: training.id,
        status,
        userId,
        updateChildren
      });

      if (updateChildren) {
        training.bubbles = training.bubbles.map((bubble) => {
          return { ...bubble, viewStatus: status };
        });
      }

      stateClone.trainers.forEach((trainer, index) => {
        const trainingIndex = trainer.trainings.findIndex((t) => t.id === trainingId);
        if (trainingIndex !== -1) {
          stateClone.trainers[index].trainings[trainingIndex] = training;
        }
      });

      return { ...state, trainers: [...stateClone.trainers] };
    });
  },
  setTraining: (training: ITraining) =>
    set((state) => {
      const trainer = state.trainers.find((t) => t.trainings.find((tr) => tr.id === training.id));

      if (!trainer) return state;

      return {
        ...state,
        trainers: state.trainers.map((t) => {
          if (t.id === trainer.id) {
            return {
              ...t,
              trainings: t.trainings.map((tr) => (tr.id === training.id ? training : tr))
            };
          }

          return t;
        })
      };
    })
}));

export { useBubblesStore };
export type { IBubblesState };
