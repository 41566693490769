import { LoadingSpinner } from 'components/progress';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { adjustColor, colorsForDashboards } from 'utils/helpers';

const DiagramContainerStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const LegendStyled = styled.ul`
  max-width: 500px;
  height: 580px;
  margin: 1em 2em;
  list-style-type: none;
  overflow: auto;

  .chartItem {
    display: flex;
    margin-bottom: 0.8em;

    &:hover {
      cursor: pointer;
      .chartItemColor {
        min-width: 22px;
        max-width: 22px;
        min-height: 22px;
        max-height: 22px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
      }
      .chartItemTitle {
        text-shadow: 0 0.5px 0.5px rgba(0, 0, 0, 0.3);
      }
    }
  }

  .chartItemColor {
    min-width: 20px;
    max-width: 20px;
    min-height: 20px;
    max-height: 20px;
    margin-right: 5px;
  }

  .chartItemTitle {
    margin: auto 5px;
  }
`;

const BarPartStyled = styled.g`
  transition: all 0.3s ease-in-out;

  &:hover {
    opacity: 1 !important;
    cursor: pointer;
  }

  &:hover .barPath {
    fill: ${(props) => adjustColor(props.$barFill, -40)};
    x: ${(props) => props.$xTick - (props.$barWidth * 0.1) / 2}px;
    width: ${(props) => props.$barWidth * 1.1}px;
  }

  &:hover .barPartText {
    opacity: 1;
    font-size: 1.2em;
    font-weight: bold;
  }

  &:hover ~ .oneGroupBarText {
    font-size: 1.2em;
    font-weight: bold;
  }

  .focusedGroup {
    opacity: 1 !important;
  }

  .focusedTmpl {
    fill: ${(props) => adjustColor(props.$barFill, -40)};
    x: ${(props) => props.$xTick - (props.$barWidth * 0.1) / 2}px;
    width: ${(props) => props.$barWidth * 1.1}px;
  }

  .focusedTmpl ~ .barPartText {
    opacity: 1;
    font-size: 1.2em;
    font-weight: bold;
  }

  .focusedTmpl ~ .oneGroupBarText {
    font-size: 1.2em;
    font-weight: bold;
  }
`;

const DiagramStyled = styled.div`
  margin: 0 2em;

  .yAxisText {
    font-size: 0.8rem;
  }

  .xAxisText {
    font-size: 0.8rem;
  }

  .xAxisTitle {
    font-size: 1.1rem;
  }

  .axis path,
  .axis line {
    fill: none;
    stroke: #000;
    shape-rendering: crispEdges;
  }

  .barPath {
    transition: all 0.3s ease-in-out;
  }

  .barText {
    font-size: 1em;
    transition: all 0.3s ease-in-out;
  }

  .barPartText {
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }

  .groupBar:hover .barPart {
    opacity: 0.6;
  }

  .focusedBar g:not(.focusedGroup) {
    opacity: 0.6;
  }
`;

function GroupBarChart({
  showText,
  showTooltip,
  showLegend,
  chartValues,
  onSelectedChartGroup,
  yAxisTitle
}) {
  const { t } = useTranslation();
  const chartStartX = 0;
  const chartStartY = 0;
  const maxChartWidth = 950;
  const maxChartHeight = 650;
  const yAxisLength = maxChartHeight - 150;
  const xAxisLength = maxChartWidth - 50;
  const [barColors, setBarColors] = useState([]);

  const assignBarColors = useCallback(() => {
    const colors = colorsForDashboards();
    const distinctTmpl = [];
    chartValues.map((group) => {
      group.numbers.map((template) => {
        const existingElement = distinctTmpl.find((x) => x.key === template.key);
        if (!existingElement) {
          distinctTmpl.push({
            key: template.key,
            title: template.title,
            color: colors[template.colorIndex]
          });
        }
        return template;
      });
      return group;
    });
    setBarColors(distinctTmpl.reverse());
  }, [chartValues]);

  useEffect(() => {
    if (barColors.length === 0 && chartValues && chartValues.length > 0) {
      assignBarColors();
    }
  }, [barColors.length, chartValues, assignBarColors]);

  function getXAxisValues() {
    const numberOfBars = chartValues.length;
    const tickDistance = xAxisLength / (numberOfBars + 1);

    const negativeNumberOfSteps = chartValues.filter((value) => value.groupId < 0).length;
    const positiveNumberOfSteps = chartValues.filter((value) => value.groupId > 0).length;
    const negativeXAxisLength =
      negativeNumberOfSteps > 0 ? (1 + negativeNumberOfSteps) * tickDistance : 0;
    const positiveXAxisLength = xAxisLength - negativeXAxisLength;
    return {
      numberOfBars,
      tickDistance,
      negativeNumberOfSteps,
      positiveNumberOfSteps,
      negativeXAxisLength,
      positiveXAxisLength
    };
  }

  function getXAxis() {
    const x = getXAxisValues();
    const xAxis = [];
    for (let i = 0; i < x.numberOfBars; i += 1) {
      const xTranslation = (i + 1) * x.tickDistance;
      const formatedName = chartValues[i].xValue;
      xAxis.push(
        <g className="tick" transform={`translate(${xTranslation},0)`} key={`x-${i + 1}`}>
          <line y2="6" x2="0" />
          <text className="xAxisText" y="20" x="0" style={{ textAnchor: 'middle' }}>
            {formatedName}
          </text>
        </g>
      );
    }

    return (
      <g className="x axis" transform={`translate(0,${yAxisLength})`} key="x-axis">
        {xAxis}
        <path className="domain" d={`M0,0V0H${xAxisLength}V0`} />
        {x.negativeNumberOfSteps > 0 ? (
          <text
            className="xAxisTitle"
            y="60"
            x={x.negativeXAxisLength / 2}
            style={{ textAnchor: 'middle' }}
          >
            {t('groupBarChart.negativeXAxis.title')}
          </text>
        ) : null}
        {x.positiveNumberOfSteps > 0 ? (
          <text
            className="xAxisTitle"
            y="60"
            x={x.negativeXAxisLength + x.positiveXAxisLength / 2}
            style={{ textAnchor: 'middle' }}
          >
            {t('groupBarChart.positiveXAxis.title')}
          </text>
        ) : null}
      </g>
    );
  }

  function getYAxisValues() {
    const values = [];
    chartValues.map((latencyGroup) => {
      let total = 0;
      latencyGroup.numbers.map((item) => {
        total += item.number;
        return total;
      });
      values.push(total);
      return values;
    });
    const maxValue = Math.max(...values.map((value) => value), 0);

    let stepValue = 1;
    if (maxValue > 10000) {
      stepValue = 5000;
    } else if (maxValue > 5000) {
      stepValue = 1000;
    } else if (maxValue > 2000) {
      stepValue = 500;
    } else if (maxValue > 1000) {
      stepValue = 200;
    } else if (maxValue > 500) {
      stepValue = 100;
    } else if (maxValue > 200) {
      stepValue = 50;
    } else if (maxValue > 100) {
      stepValue = 20;
    } else if (maxValue > 50) {
      stepValue = 10;
    } else if (maxValue > 20) {
      stepValue = 5;
    } else if (maxValue > 10) {
      stepValue = 2;
    }
    const maxTopRoundedValue = Math.ceil(maxValue / stepValue) * stepValue;
    const numberOfSteps = maxTopRoundedValue / stepValue;
    const stepHeight = (yAxisLength - 20) / numberOfSteps;
    return {
      stepValue,
      maxTopRoundedValue,
      stepHeight,
      numberOfSteps
    };
  }

  function getYAxis() {
    const y = getYAxisValues();
    const yAxis = [];
    for (let i = 0; i < y.numberOfSteps + 1; i += 1) {
      const yTranslation = yAxisLength - i * y.stepHeight;
      yAxis.push(
        <g
          className="tick"
          transform={`translate(0,${yTranslation})`}
          style={{ opacity: 1 }}
          key={`y-${y.stepValue * i}`}
        >
          <line x1={xAxisLength} x2="-4" y2="0" style={{ stroke: '#DCDCDC', strokeWidth: '1' }} />
          <text dy=".32em" x="-9" y="0" style={{ textAnchor: 'end' }}>
            {y.stepValue * i}
          </text>
        </g>
      );
    }
    return (
      <g className="y axis" key="y-axis">
        {yAxis}
        <text x="-6" y="-15" dy=".71em" style={{ textAnchor: 'start' }}>
          {yAxisTitle}
        </text>
      </g>
    );
  }

  function getGroupBar(group, barWidth, stepHeight, stepValue, xTick) {
    const groupBars = [];
    let totalBarHeight = 0;
    group.numbers.map((template) => {
      const currentTemplate = { ...template };
      currentTemplate.groupId = group.groupId;
      const barPartHeight = template.number * (stepHeight / stepValue);
      totalBarHeight += barPartHeight;
      const tooltip = `${template.title}: ${template.number}`;
      const barColor = barColors.find((x) => x.key === template.key).color;
      groupBars.push(
        <BarPartStyled
          className="barPart"
          key={`barPart-${template.key}`}
          $barWidth={barWidth}
          $barHeight={barPartHeight}
          $xTick={xTick}
          $barFill={barColor}
        >
          <rect
            id={`tmpl-${template.key}`}
            className="barPath"
            x={xTick}
            width={barWidth}
            y={yAxisLength - totalBarHeight}
            height={barPartHeight}
            onClick={() => onSelectedChartGroup(currentTemplate)}
            fill={barColor}
          />
          {showTooltip ? <title className="pieSliceTooltip">{tooltip}</title> : null}
          {showText && group.numbers.length > 1 ? (
            <text
              className="barPartText"
              textAnchor="end"
              x={xTick - 10}
              y={yAxisLength - (totalBarHeight - barPartHeight / 2) + 5}
            >
              {template.number}
            </text>
          ) : null}
        </BarPartStyled>
      );
      return groupBars;
    });
    return groupBars;
  }

  function getBars() {
    const numberOfBars = chartValues.length;
    const tickDistance = xAxisLength / (numberOfBars + 1);
    let barWidth = tickDistance - tickDistance * 0.2;
    barWidth = barWidth > 80 ? 80 : barWidth;

    const y = getYAxisValues();
    const bars = [];
    for (let i = 0; i < numberOfBars; i += 1) {
      const xTick = (i + 1) * tickDistance - barWidth / 2;
      const group = chartValues[i];
      let groupTotal = 0;
      group.numbers.map((item) => {
        groupTotal += item.number;
        return groupTotal;
      });
      const barHeight = groupTotal * (y.stepHeight / y.stepValue);
      bars.push(
        <g key={`bar-${chartValues[i].groupId}`} className="groupBar">
          {getGroupBar(group, barWidth, y.stepHeight, y.stepValue, xTick, groupTotal)}
          {showText ? (
            <text
              className={`barText ${group.numbers.length === 1 ? 'oneGroupBarText' : ''}`}
              textAnchor="middle"
              x={xTick + barWidth / 2}
              y={yAxisLength - barHeight - 10}
            >
              {groupTotal !== 0 ? groupTotal : ''}
            </text>
          ) : null}
        </g>
      );
    }
    return bars;
  }

  function getBarChart() {
    return (
      <g transform="translate(40,20)">
        {getXAxis()}
        {getYAxis()}
        {getBars()}
      </g>
    );
  }

  function getSvgChart() {
    return (
      <svg
        width={maxChartWidth}
        height={maxChartHeight}
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        preserveAspectRatio="xMinYMin meet"
        viewBox={`${chartStartX * 1.5} ${chartStartY * 1.5} ${maxChartWidth} ${maxChartHeight}`}
        className="chart"
      >
        {getBarChart()}
      </svg>
    );
  }

  function focusBarGroup(barGroupkey) {
    const elements = document.getElementsByClassName('barPath');
    const tmplRectElements = [];
    const tmplGElements = [];
    if (elements && elements.length > 0) {
      for (let i = 0; i < elements.length; i += 1) {
        if (elements[i].id === `tmpl-${barGroupkey}`) {
          tmplRectElements.push(elements[i]);
          tmplGElements.push(elements[i].parentElement);
        }
      }
    }
    const bars = [];
    if (tmplGElements.length > 0) {
      tmplGElements.map((el) => bars.push(el.parentElement));
    }
    if (bars.length > 0) {
      tmplRectElements.map((el) => el.classList.add('focusedTmpl'));
      tmplGElements.map((el) => el.classList.add('focusedGroup'));
      bars.map((el) => el.classList.add('focusedBar'));
    }
  }

  function blurBarGroup(barGroupkey) {
    const elements = document.getElementsByClassName('barPath');
    const tmplRectElements = [];
    const tmplGElements = [];
    if (elements && elements.length > 0) {
      for (let i = 0; i < elements.length; i += 1) {
        if (elements[i].id === `tmpl-${barGroupkey}`) {
          tmplRectElements.push(elements[i]);
          tmplGElements.push(elements[i].parentElement);
        }
      }
    }
    const bars = [];
    if (tmplGElements.length > 0) {
      tmplGElements.map((el) => bars.push(el.parentElement));
    }
    if (bars.length > 0) {
      tmplRectElements.map((el) => el.classList.remove('focusedTmpl'));
      tmplGElements.map((el) => el.classList.remove('focusedGroup'));
      bars.map((el) => el.classList.remove('focusedBar'));
    }
  }

  function getLegend() {
    return (
      <LegendStyled>
        {barColors.map((item) => {
          return (
            <li key={`legend-${item.key}`}>
              <div
                className="chartItem"
                onFocus={() => focusBarGroup(item.key)}
                onMouseOver={() => focusBarGroup(item.key)}
                onMouseOut={() => blurBarGroup(item.key)}
                onBlur={() => blurBarGroup(item.key)}
              >
                <div className="chartItemColor" style={{ backgroundColor: item.color }} />
                <div className="chartItemTitle">{item.title}</div>
              </div>
            </li>
          );
        })}
      </LegendStyled>
    );
  }

  if (barColors.length > 0) {
    return (
      <DiagramContainerStyled>
        <DiagramStyled>{getSvgChart()}</DiagramStyled>
        {showLegend && barColors.length > 1 ? getLegend() : null}
      </DiagramContainerStyled>
    );
  }
  return <LoadingSpinner className="loading-spinner" label={t('loading.routes.diagram')} />;
}

GroupBarChart.propTypes = {
  showText: PropTypes.bool,
  showTooltip: PropTypes.bool,
  showLegend: PropTypes.bool,
  onSelectedChartGroup: PropTypes.func.isRequired,
  chartValues: PropTypes.arrayOf(
    PropTypes.shape({
      groupId: PropTypes.number,
      numbers: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string,
          title: PropTypes.string,
          number: PropTypes.number,
          colorIndex: PropTypes.number
        })
      ),
      xValue: PropTypes.string
    })
  ).isRequired,
  yAxisTitle: PropTypes.string
};

GroupBarChart.defaultProps = {
  showText: false,
  showTooltip: false,
  showLegend: false,
  yAxisTitle: null
};

export default GroupBarChart;
