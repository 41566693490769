import { createContext, CSSProperties, useContext, useMemo } from 'react';
import { IPalette, PartialTheme } from '@fluentui/react';
import { DefaultTheme } from 'styled-components';
import { AI_DARK, AI_LIGHT, DARK, DEFAULT } from './themeDomains';
import { GlobalRgb, Rgb } from './themeUtils';

export const DEVICE_THEME_PREFERENCE_KEY = 'themePreference';
export enum DeviceThemePreference {
  System = 'system',
  Light = 'light',
  Dark = 'dark'
}
export const DEFAULT_THEME_PREFERENCE = DeviceThemePreference.System;

export type ThemeConfig = {
  fluentPalette: IPalette;
  dark: boolean;
  isAI?: boolean;
};

export const ThemeConfigContext = createContext<
  ThemeConfig & {
    /**
     * Sets a temporary theme override, intended for previewing purposes.
     * If `set` is false, then it will be removed if it is the current one.
     */
    setOverride?: (override: Partial<ThemeConfig>, set: boolean) => void;
  }
>({
  fluentPalette: {} as unknown as IPalette,
  dark: false,
  setOverride: () => undefined
});

export function getResolvedTheme(config: ThemeConfig) {
  let domainsFn: typeof DEFAULT;
  if (config.isAI) {
    domainsFn = config.dark ? AI_DARK : AI_LIGHT;
  } else {
    domainsFn = config.dark ? DARK : DEFAULT;
  }

  return domainsFn(config);
}

function getCssVariableName(domain: string, key: string): string {
  return `--${domain}-${key}`;
}

/** Creates the set of CSS variables for a particular theme. */
export function getThemeStyles(config: ThemeConfig) {
  const globalVariables: Record<string, string> = {};
  const variables: Record<string, string> = {};

  const domains = getResolvedTheme(config);

  for (const [id, resolved] of Object.entries(domains)) {
    if (typeof resolved === 'object') {
      for (const [k, v] of Object.entries(resolved as Record<string, string>)) {
        const rgb = Rgb.parse(v);
        if (rgb instanceof GlobalRgb) {
          if (!(rgb.id in globalVariables)) globalVariables[`--${rgb.id}`] = rgb.toString();
          variables[getCssVariableName(id, k)] = `var(--${rgb.id})`;
        } else if (rgb) {
          variables[getCssVariableName(id, k)] = rgb.toString();
        } else {
          // probably not a color, then
          variables[getCssVariableName(id, k)] = v.toString();
        }
      }
    }
  }

  return { ...globalVariables, ...variables };
}

export function useThemeStyles(): CSSProperties {
  const config = useContext(ThemeConfigContext);
  return useMemo(() => getThemeStyles(config), [config]);
}

/** Creates the set of CSS variables for a particular theme, and outputs it as a CSS string. */
export function getThemeStylesAsCss(config: ThemeConfig): string {
  return Object.entries(getThemeStyles(config))
    .map(([name, value]) => `${name}:${value};`)
    .join('');
}

function proxyToCssVariables(
  domain: string,
  colors: Record<string, unknown>
): Record<string, string> {
  const out: Record<string, string> = {};
  for (const k of Object.keys(colors)) {
    out[k] = `var(${getCssVariableName(domain, k)})`;
  }
  return out;
}

/**
 * Creates a styled-components theme for a theme config.
 * This is only really useful at the application root.
 *
 * If you want to locally override some theme variables, you probably want getThemeStylesAsCss instead.
 */
export function createStyledTheme(config: ThemeConfig): DefaultTheme {
  const theme = DEFAULT(config);

  return {
    ...config.fluentPalette,
    ...Object.fromEntries(
      Object.entries(theme)
        .filter(([, resolved]) => typeof resolved === 'object')
        .map(([domain, resolved]) => [
          domain,
          // technically, we don't really need to resolve, but we have no other way of getting all the keys
          proxyToCssVariables(domain, resolved)
        ])
    )
  } as DefaultTheme;
}

const SMUGGLED_THEME_CONFIG = 'epInheritedThemeConfig';

export function createFluentTheme(config: ThemeConfig): PartialTheme {
  const styledTheme = createStyledTheme(config);
  const extraStyles = styledTheme.extraFluentComponentStyles;

  const semanticColors = {
    menuHeader: `rgb(${extraStyles.menuHeaderColor})`,
    menuIcon: `rgb(${extraStyles.menuIconColor})`,

    primaryButtonBackground: `rgb(${extraStyles.primaryButtonBackground})`,
    primaryButtonBackgroundHovered: `rgb(${extraStyles.primaryButtonBackgroundHovered})`,
    primaryButtonBackgroundPressed: `rgb(${extraStyles.primaryButtonBackgroundPressed})`,
    primaryButtonText: `rgb(${extraStyles.primaryButtonText})`,
    primaryButtonTextHovered: `rgb(${extraStyles.primaryButtonText})`,
    primaryButtonTextPressed: `rgb(${extraStyles.primaryButtonText})`,

    inputBorder: `rgb(${extraStyles.inputBorder})`,
    inputBorderHovered: `rgb(${extraStyles.inputBorderHovered})`,
    inputFocusBorderAlt: `rgb(${extraStyles.inputFocusBorderAlt})`,

    link: `rgb(${extraStyles.linkForeground})`,
    linkHovered: `rgb(${extraStyles.linkHoverForeground})`
  };

  const baseComponentStyles: PartialTheme['components'] = {
    [SMUGGLED_THEME_CONFIG]: { styles: config },
    ActionButton: {
      styles: {
        root: { color: `rgb(${extraStyles.actionButtonForeground})` },
        rootHovered: { color: `rgb(${extraStyles.actionButtonHoveredForeground})` },
        iconHovered: { color: `rgb(${extraStyles.actionButtonHoveredForeground})` },
        rootPressed: { color: `rgb(${extraStyles.actionButtonPressedForeground})` },
        rootExpanded: { color: `rgb(${extraStyles.actionButtonHoveredForeground})` },
        iconPressed: { color: `rgb(${extraStyles.actionButtonIconPressedColor})` },
        rootChecked: { color: `rgb(${extraStyles.actionButtonPressedForeground})` },
        iconChecked: { color: `rgb(${extraStyles.actionButtonIconPressedColor})` },
        icon: { color: `rgb(${extraStyles.actionButtonIconColor})` }
      }
    },
    CommandBarButton: {
      styles: {
        icon: { color: `rgb(${extraStyles.buttonIconColor})` },
        iconHovered: { color: `rgb(${extraStyles.buttonIconHoveredColor})` },
        iconPressed: { color: `rgb(${extraStyles.buttonIconPressedColor})` },
        iconExpanded: { color: `rgb(${extraStyles.buttonIconPressedColor})` },
        iconExpandedHovered: { color: `rgb(${extraStyles.buttonIconPressedColor})` },
        iconChecked: { color: `rgb(${extraStyles.buttonIconPressedColor})` }
      }
    },
    ContextualMenuItem: {
      styles: {
        rootPressed: {
          '.ms-ContextualMenu-icon': { color: `rgb(${extraStyles.buttonIconPressedColor})` }
        }
      }
    },
    IconButton: {
      styles: {
        root: { color: `rgb(${extraStyles.buttonIconColor})` },
        rootHovered: { color: `rgb(${extraStyles.buttonIconHoveredColor})` },
        rootPressed: { color: `rgb(${extraStyles.buttonIconPressedColor})` },
        rootExpanded: { color: `rgb(${extraStyles.buttonIconPressedColor})` },
        rootChecked: { color: `rgb(${extraStyles.buttonIconPressedColor})` },
        rootCheckedHovered: { color: `rgb(${extraStyles.buttonIconPressedColor})` }
      }
    },
    ProgressIndicator: {
      styles: (props) => {
        const progressTrackColor = `rgb(${extraStyles.progressTrack})`;
        const themePrimary = `rgb(${extraStyles.progressBar})`;

        return {
          progressBar: props.indeterminate
            ? {
                background: `linear-gradient(to right, ${progressTrackColor} 0%, ${themePrimary} 50%, ${progressTrackColor} 100%)`
              }
            : {
                backgroundColor: themePrimary
              }
        };
      }
    },
    Spinner: {
      styles: {
        circle: {
          borderTopColor: `rgb(${extraStyles.spinnerCircleA})`,
          borderRightColor: `rgb(${extraStyles.spinnerCircleB})`,
          borderBottomColor: `rgb(${extraStyles.spinnerCircleB})`,
          borderLeftColor: `rgb(${extraStyles.spinnerCircleB})`
        },
        label: {
          color: `rgb(${extraStyles.spinnerLabel})`
        }
      }
    },
    Suggestions: {
      styles: {
        title: {
          color: `rgb(${extraStyles.suggestionsTitleForeground})`
        }
      }
    }
  };

  if (config.dark) {
    return {
      palette: {
        themePrimary: config.fluentPalette.themePrimary,
        themeLighterAlt: config.fluentPalette.themeDarkAlt,
        themeLighter: config.fluentPalette.themeDarker,
        themeLight: config.fluentPalette.themeDark,
        themeTertiary: config.fluentPalette.themeTertiary,
        themeSecondary: config.fluentPalette.themeSecondary,
        themeDarkAlt: config.fluentPalette.themeLighterAlt,
        themeDark: config.fluentPalette.themeLight,
        themeDarker: config.fluentPalette.themeLighter,
        neutralLighterAlt: '#3c3b39',
        neutralLighter: '#444241',
        neutralLight: '#514f4e',
        neutralQuaternaryAlt: '#595756',
        neutralQuaternary: '#5f5e5c',
        neutralTertiaryAlt: '#7a7977',
        neutralTertiary: '#c8c8c8',
        neutralSecondary: '#d0d0d0',
        neutralSecondaryAlt: '#d0d0d0',
        neutralPrimaryAlt: '#dadada',
        neutralPrimary: '#ffffff',
        neutralDark: '#f4f4f4',
        black: '#f8f8f8',
        whiteTranslucent40: '#32313066',
        white: '#323130'
      },
      semanticColors,
      components: {
        ...baseComponentStyles,
        Layer: {
          styles: (props) => {
            // layers need to inherit the CSS theme from their virtual parent,
            // because otherwise our CSS is out of sync with the fluent-ui ThemeProvider
            const themeConfig = props.theme.components[SMUGGLED_THEME_CONFIG]?.styles;
            if (!themeConfig) return {};
            return { root: getThemeStyles(themeConfig) };
          }
        },
        PersonaCoin: {
          styles: {
            // these are always white
            initials: { color: 'white' }
          }
        },
        Panel: {
          styles: {
            // for some reason these are hardcoded colors in fluent-ui
            overlay: { backgroundColor: 'rgba(0, 0, 0, 0.4)' }
          }
        }
      }
    };
  }

  return {
    palette: config.fluentPalette,
    semanticColors,
    components: baseComponentStyles
  };
}
