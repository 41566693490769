import {
  ApiObjectVariant,
  createApiObjectDataFromPlainObject,
  IApiObjectCache,
  IApiObjectData,
  IApiObjectType,
  LocalApiObject,
  makeApiObjectVariantKey
} from '../object';
import { DateTime, IIdStringPair, ITextItem, Ref, Uuid } from './base-types';
import {
  ChoiceFieldFormat,
  DateTimeFieldFormat,
  FieldAggregationType,
  PersonFieldFormat,
  ProcessLookupType,
  ProcessOutcome,
  RouteFieldType,
  RouteFieldVisibility,
  RuleCondition,
  RuleConditionLink
} from '../../../types';
import { ITeamRef, IUserRef, SPARSE_TEAM_REF, SPARSE_USER_REF, TEAM, USER } from './users';
import { createFieldRefs, FieldRefSpec, simpleIdObject } from './utils';
import fetchRequest from '../../../services/api';
import { UUID_NULL } from '../../../utils/helpers';
import { IApiListFetchResult, IApiListPage, IApiListType } from '../list';
import { fetchJson } from '../../../services/api2';

export type IProcessField = IApiObjectData & IProcessFieldBaseFields & IProcessFieldSuperFields;

export type IProcessFieldRef = Pick<IProcessField, 'id'>;
export const SPARSE_PROCESS_FIELD_REF = (field: IProcessField) => ({
  id: field.id === UUID_NULL ? `data:${field.name}` : field.id
});

export interface IProcessFieldBaseFields {
  id: Uuid;
  name: string | null;
  allNames: ITextItem[] | null;
  description: string | null;
  allDescriptions: ITextItem[] | null;
  fieldType: RouteFieldType;
  epType: EPFieldType;
  /** @see FieldDescriptionPlacement */
  descriptionPlacement: number;
  creationDate: DateTime | null;
  creator: IUserRef | null;
  editDate: DateTime | null;
  editor: IUserRef | null;
}

export const FieldDescriptionPlacement = {
  NotSet: 0,
  InfoIcon: 1,
  BeneathInput: 1 << 1
};

export enum EPFieldType {
  Deleted = -1,

  Other = 0,
  Title = 1,
  ForeignCurrency = 100,
  OwnCurrency = 101,
  ExchangeRate = 102,
  City = 103,
  WeatherCondition = 104,
  Temperature = 105,
  AmountInput = 106,
  AmountReturn = 107,

  SapMaterialNo = 500,
  SapMaterialType = 501,
  SapMaterialGroup = 502,
  SapIndustrySector = 503,
  SapBaseUnit = 504,
  SapWeightUnit = 505,
  SapGrossWeight = 506,
  SapNetWeight = 507,
  SapTransportationGroup = 508,
  SapDivision = 509,
  SapSupplierNo = 510,
  SapSupplierName = 511,
  SapCity = 512,
  SapZip = 513,
  SapStreet = 514,
  SapCountry = 515,
  SapTelephone = 516
}

function getProcessFieldBaseFieldsFieldRefsSparse(
  basePath: string,
  obj: IProcessFieldBaseFields
): FieldRefSpec<unknown>[] {
  return [
    {
      type: USER,
      sparseRef: SPARSE_USER_REF,
      path: `${basePath}creator`,
      params: () => obj.creator?.userId
    },
    {
      type: USER,
      sparseRef: SPARSE_USER_REF,
      path: `${basePath}editor`,
      params: () => obj.editor?.userId
    }
  ];
}

export type IProcessFieldSuperFields = Partial<IProcessFieldAIFields> &
  Partial<IProcessFieldBooleanFields> &
  Partial<IProcessFieldChoiceFields> &
  Partial<IProcessFieldDateTimeFields> &
  Partial<IProcessFieldExternalDataFields> &
  Partial<IProcessFieldLongTextFields> &
  Partial<IProcessFieldLookupFields> &
  Partial<IProcessFieldNumberFields> &
  Partial<IProcessFieldPersonFields> &
  Partial<IProcessFieldRatingFields> &
  Partial<IProcessFieldSmallTextFields>;

/** no refs */
export interface IProcessFieldAIFields {
  prompt: string | null;
  allPrompts: ITextItem[] | null;
  autoPrompt: boolean;
  allowRefining: boolean;
  aiTemperature: number;
  maxTokens: number;
  additionalPromptFieldIds: Uuid[];
}

/** no refs */
export interface IProcessFieldBooleanFields {
  textOk: string | null;
  allTextsOk: ITextItem[] | null;
  textNOk: string | null;
  allTextsNOk: ITextItem[] | null;
  confirmationFormat: ConfirmationFieldFormat;
}

export enum ConfirmationFieldFormat {
  TwoButtons = 1,
  Checkbox = 2
}

/** no refs */
export interface IProcessFieldChoiceFields {
  choiceFormat: ChoiceFieldFormat;
  allowFillIn: boolean | null;
  choices: IIdStringPair[] | null;
}

/** no refs */
export interface IProcessFieldDateTimeFields {
  dateFormat: DateTimeFieldFormat;
}

/** no refs */
export interface IProcessFieldExternalDataFields {
  externalServiceId: Uuid;
  allowScan: boolean;
  dataSelectMode: ExternalDataFieldSelectMode;
}

export enum ExternalDataFieldSelectMode {
  SelectFromList = 1,
  OnlyNewValue = 3
}

/** no refs */
export interface IProcessFieldLongTextFields {
  appendChanges: boolean;
}

/** no refs */
export interface IProcessFieldLookupFields {
  lookupDefinitionId: Uuid;
  lookupType: ProcessLookupType;
  lookupOnlyOutcome: ProcessOutcome;
  lookupDisplayFieldIds: Uuid[];
  lookupDisplayFields: IProcessFieldLookupDisplayField[];
}

/** no refs */
export interface IProcessFieldLookupDisplayField {
  fieldId: Uuid | null;
  fieldName: string | null;
  showInList: boolean;
  showInResult: boolean;
}

/** no refs */
export interface IProcessFieldNumberFields {
  numDecimals: number;
  useThousandsSeparator: boolean;
}

/** no refs */
export interface IProcessFieldPersonFields {
  searchAAD: boolean | null;
  personFieldFormat: PersonFieldFormat;
  connectUserAndTeam: boolean;
}

/** no refs */
export interface IProcessFieldRatingFields {
  numStars: number;
}

/** no refs */
export interface IProcessFieldSmallTextFields {
  maxLength: number;
}

export const PROCESS_FIELD: IApiObjectType<Uuid, IProcessField> = {
  id: 'Route/Field',
  createRefs(cache: IApiObjectCache, data: IProcessField) {
    createFieldRefs(cache, data, getProcessFieldBaseFieldsFieldRefsSparse('', data));
  },
  async load(cache: IApiObjectCache, id: Uuid, abort: AbortSignal): Promise<IProcessField> {
    let result: object;
    if (id.startsWith('data:')) {
      // synthetic data field (used in process template dataFields)
      result = {
        allDescriptions: [],
        allNames: [],
        description: null,
        descriptionPlacement: 1,
        epType: 0,
        fieldType: 0,
        id: UUID_NULL,
        name: id.substring(5)
      } as IProcessFieldBaseFields;
    } else {
      result = await fetchRequest({ url: `Route/Field/${id}`, signal: abort });
    }

    return createApiObjectDataFromPlainObject(
      cache,
      makeApiObjectVariantKey(PROCESS_FIELD, id),
      result
    ) as IProcessField;
  }
};

export interface IProcessFieldGroup extends IApiObjectData {
  id: Uuid;
  name: string | null;
  allNames: ITextItem[] | null;
  description: string | null;
  allDescriptions: ITextItem[] | null;
  fields: IProcessFieldGroupItem[] | null;
}

/** has refs */
export interface IProcessFieldGroupItem {
  /** Unstable UUID (random on every request) */
  id: Uuid | null;
  field: Ref<IProcessField>;
  sortOrder: number;
  visibility: RouteFieldVisibility;
  /** never really used here. If this field *is* used, please add a note why. */
  aggregation: FieldAggregationType;
  /** has refs */
  ruleGroups: IProcessFieldGroupRuleGroup[] | null;
}

export function getProcessFieldGroupItemFieldRefs(
  basePath: string,
  obj: IProcessFieldGroupItem
): FieldRefSpec<unknown>[] {
  return [
    {
      path: `${basePath}field`,
      type: PROCESS_FIELD,
      params: () => obj.field?.id
    },
    ...(obj.ruleGroups?.flatMap((group, index) =>
      getProcessFieldGroupRuleGroupFieldRefsSparse(`${basePath}ruleGroups/${index}/`, group)
    ) ?? [])
  ];
}

/** has refs */
export interface IProcessFieldGroupRuleGroup {
  makeRequired: boolean;
  /** has refs */
  rules: IProcessStepLinkRule[] | null;
}

export function getProcessFieldGroupRuleGroupFieldRefsSparse(
  basePath: string,
  obj: IProcessFieldGroupRuleGroup
): FieldRefSpec<unknown>[] {
  return (
    obj.rules?.flatMap((rule, index) =>
      getProcessStepLinkRuleFieldRefsSparse(`${basePath}rules/${index}/`, rule)
    ) ?? []
  );
}

/** has refs */
export interface IProcessStepLinkRule {
  fieldId: Uuid;
  condition: RuleCondition | 0;
  compareValue: string | null;
  compareValueUser: IUserRef | null;
  compareValueTeam: ITeamRef | null;
  compareValueId: Uuid | null;
  linkType: RuleConditionLink;
  /** has refs */
  subRules: IProcessStepLinkRule[] | null;
}

export function getProcessStepLinkRuleFieldRefsSparse(
  basePath: string,
  obj: IProcessStepLinkRule
): FieldRefSpec<unknown>[] {
  return [
    {
      type: USER,
      variant: ApiObjectVariant.Small,
      sparseRef: SPARSE_USER_REF,
      path: `${basePath}compareValueUser`,
      params: () => obj.compareValueUser?.userId
    },
    {
      type: TEAM,
      variant: ApiObjectVariant.Small,
      sparseRef: SPARSE_TEAM_REF,
      path: `${basePath}compareValueTeam`,
      params: () => obj.compareValueTeam?.id
    },
    ...(obj.subRules?.flatMap((rule, index) =>
      getProcessStepLinkRuleFieldRefsSparse(`${basePath}subRules/${index}/`, rule)
    ) ?? [])
  ];
}

export const PROCESS_FIELD_GROUP: IApiObjectType<Uuid, IProcessFieldGroup> = simpleIdObject({
  id: 'Route/FieldGroup',
  url: (id) => `Route/FieldGroup/${id}`,
  dynFields: (group) =>
    group.fields?.flatMap((field, index) =>
      getProcessFieldGroupItemFieldRefs(`fields/${index}/`, field)
    ) ?? []
});

export interface IProcessFieldsParams {
  routeDefId?: Uuid;
  /** @default 100 */
  itemsPerPage?: number;
  searchTerms?: string;
  fieldType?: RouteFieldType[];
}
export const PROCESS_FIELDS: IApiListType<IProcessFieldsParams, typeof PROCESS_FIELD> = {
  id: 'Route/Fields',
  async fetchPage(
    cache: IApiObjectCache,
    params: IProcessFieldsParams,
    pageIndex: number,
    _?: IApiListPage<typeof PROCESS_FIELD>,
    abort?: AbortSignal
  ): Promise<IApiListFetchResult<typeof PROCESS_FIELD>> {
    const url = `Route/Fields?${new URLSearchParams(
      Object.entries({
        ...params,
        pageIndex
      }).map(([k, v]) => [k, v.toString()])
    )}`;

    const { items, searchProps } = await fetchJson({ url, abort });
    return {
      items: items.map((item: LocalApiObject<IProcessField>) => {
        const id = makeApiObjectVariantKey(PROCESS_FIELD, item.id);
        cache.insertObjectData(createApiObjectDataFromPlainObject(cache, id, item));
        return id;
      }),
      searchProps
    };
  }
};

export interface IProcessFieldGroupsParams {
  /** @default 100 */
  itemsPerPage: number;
  searchTerms?: string;
}
export const PROCESS_FIELD_GROUPS: IApiListType<
  IProcessFieldGroupsParams,
  typeof PROCESS_FIELD_GROUP
> = {
  id: 'Route/Fields',
  async fetchPage(
    cache: IApiObjectCache,
    params: IProcessFieldGroupsParams,
    pageIndex: number,
    _?: IApiListPage<typeof PROCESS_FIELD_GROUP>,
    abort?: AbortSignal
  ): Promise<IApiListFetchResult<typeof PROCESS_FIELD_GROUP>> {
    const url = `Route/FieldGroups?${new URLSearchParams(
      Object.entries({
        ...params,
        pageIndex
      }).map(([k, v]) => [k, v.toString()])
    )}`;

    const { items, searchProps } = await fetchJson({ url, abort });
    return {
      items: items.map((item: LocalApiObject<IProcessFieldGroup>) => {
        const id = makeApiObjectVariantKey(PROCESS_FIELD_GROUP, item.id);
        cache.insertObjectData(createApiObjectDataFromPlainObject(cache, id, item));
        return id;
      }),
      searchProps
    };
  }
};

export const PROCESS_FIELD_BY_EP_TYPE: IApiObjectType<{ epType: EPFieldType }, IProcessField> = {
  id: 'Route/Field/ByEpType',
  createRefs() {
    // nothing to do; there's no data for this object type
  },
  async load(
    cache: IApiObjectCache,
    { epType }: { epType: EPFieldType },
    abort: AbortSignal
  ): Promise<IProcessField> {
    const result = await fetchJson({ url: `Route/Field/ByEpType?epType=${epType}`, abort });

    return createApiObjectDataFromPlainObject(
      cache,
      makeApiObjectVariantKey(PROCESS_FIELD, result.id),
      result
    ) as IProcessField;
  }
};
