import { z } from 'zod';

export interface IStartSettings {
  version: number;
}

export enum StartFieldType {
  Text = 'text',
  Choice = 'choice',
  Number = 'number'
}

const StartFieldBase = z.object({
  type: z.nativeEnum(StartFieldType),
  label: z.string().describe('shown above the field'),
  description: z.string().describe('a short description below the field')
});

export type IStartFieldBase = z.infer<typeof StartFieldBase>;

const StartFieldText = StartFieldBase.extend({
  type: z.literal(StartFieldType.Text)
});
const StartFieldNumber = StartFieldBase.extend({
  type: z.literal(StartFieldType.Number)
});
const StartFieldChoice = StartFieldBase.extend({
  type: z.literal(StartFieldType.Choice),
  multiple: z.boolean().describe('enable to allow selecting multiple items instead of just one'),
  choices: z.string().array().describe('list of all options')
});

export type IStartFieldText = z.infer<typeof StartFieldText>;
export type IStartFieldNumber = z.infer<typeof StartFieldNumber>;
export type IStartFieldChoice = z.infer<typeof StartFieldChoice>;

export const StartField = z.discriminatedUnion('type', [
  StartFieldText,
  StartFieldNumber,
  StartFieldChoice
]);

export type IStartField = z.infer<typeof StartField>;

export const StartSettingsV1 = z.object({
  version: z.literal(1),
  fields: StartField.array()
});

export type IStartSettingsV1 = z.infer<typeof StartSettingsV1>;

export const EMPTY_START_SETTINGS: IStartSettingsV1 = {
  version: 1,
  fields: []
};

export function createNewField(type: StartFieldType): IStartField {
  const baseField: Omit<IStartFieldBase, 'type'> = { label: '', description: '' };

  switch (type) {
    case StartFieldType.Text:
      return { ...baseField, type: StartFieldType.Text };
    case StartFieldType.Choice:
      return {
        ...baseField,
        type: StartFieldType.Choice,
        multiple: false,
        choices: ['choice 1', 'choice 2']
      };
    case StartFieldType.Number:
      return { ...baseField, type: StartFieldType.Number };
    default:
      throw new Error('unknown type');
  }
}

export function isFieldValid(field: IStartField): boolean {
  if (!field.label) return false;

  if (field.type === StartFieldType.Choice) {
    if (field.choices.length < 2) return false;
  }

  return true;
}
