import styled from 'styled-components';

function hexToRgb(hexColor: string): { r: number; g: number; b: number } | null {
  const colorToConvert = hexColor || '#708090';

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(colorToConvert);

  const parsedResult = result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;

  return parsedResult;
}

const ActivitiesListStyled = styled.div<{ $isScrollable?: boolean }>`
  ${(props) =>
    props.$isScrollable
      ? `
    overflow: auto;
    padding: 1rem;
    `
      : ''}

  .activity-list-item {
    position: relative;
    padding: 7px 7px 10px 10px;
    border-bottom: 1px solid #dddddd;
    display: flex;
    justify-content: space-between;
    border-radius: 3px;

    &:hover {
      .read-notification-button {
        display: block;
      }

      .new-notification-indicator {
        display: none;
      }
    }
  }

  .activity-item {
    width: 100%;
  }

  .activity-creator {
    font-weight: 700;
    padding-right: 3px;
  }

  .activity-description {
    font-weight: 500;
  }

  .unread-notification {
    background-color: ${(props) => {
      const rgbColor = hexToRgb(props.theme.themePrimary);
      return `rgba(${rgbColor?.r}, ${rgbColor?.g}, ${rgbColor?.b}, ${0.1})`;
    }};

    box-shadow: 0px 1px 2px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;

    &:hover {
      background-color: ${(props) => {
        const rgbColor = hexToRgb(props.theme.themePrimary);
        return `rgba(${rgbColor?.r}, ${rgbColor?.g}, ${rgbColor?.b}, ${0.2})`;
      }};
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    }
  }

  .activity-item.active:hover {
    cursor: pointer;
  }

  .route-approval-icon {
    margin-left: 3px;
    margin-right: 3px;
    font-size: 12px;
    transform: rotate(45deg);
  }

  .new-notification-container {
    height: 16px;
    width: 20px;

    .read-notification-button {
      .ms-Button-icon {
        color: ${(props) => props.theme.themePrimary};
        font-size: 16px;
      }

      width: 16px;
      height: 16px;
      margin: auto;
      display: none;
      background-color: transparent;
    }

    .new-notification-indicator {
      margin: 5px 5px 0 auto;
      width: 8px;
      height: 8px;
      background-color: ${(props) => props.theme.themePrimary || 'green'};
      border-radius: 8px;
      display: block;
    }
  }
`;

export default ActivitiesListStyled;
