import { useTranslation } from 'react-i18next';
import { Stack } from '@fluentui/react';
import {
  IFESettingsItem,
  ITenantExchangeSettings
} from '../../../../hooks/api2/types/administration';
import { SettingsContainer, SettingsTextField } from '../../../../components';

export default function ExchangeSettings({
  settings,
  onSettingsChange
}: {
  settings: IFESettingsItem;
  onSettingsChange: (changes: Partial<IFESettingsItem>) => void;
}) {
  const { t } = useTranslation();

  const onExchangeSettingsChange = (changes: Partial<ITenantExchangeSettings>) =>
    onSettingsChange({ exchangeSettings: { ...settings.exchangeSettings, ...changes } });

  return (
    <SettingsContainer
      defaultExpanded
      label={t('settingsAdministration.form.label.mailDelivery')}
      iconName="Mail"
      description={t('settingsAdministration.form.summary.mailDelivery')}
    >
      <div style={{ fontSize: 'smaller', margin: '1em 0' }}>
        {t('settingsAdministration.form.description.mailDelivery')}
      </div>
      <Stack tokens={{ childrenGap: 8 }}>
        <SettingsTextField
          type="email"
          label={t('settingsAdministration.form.label.mailSenderAddress')}
          description={t('settingsAdministration.form.label.mailSenderAddressDescription')}
          onChange={(value) => onExchangeSettingsChange({ senderEmail: value })}
          defaultValue={settings.exchangeSettings.senderEmail}
        />
        <SettingsTextField
          label={t('settingsAdministration.form.label.mailClientId')}
          onChange={(value) => onExchangeSettingsChange({ clientId: value })}
          defaultValue={settings.exchangeSettings.clientId}
        />
        <SettingsTextField
          label={t('settingsAdministration.form.label.mailClientSecret')}
          onChange={(value) => onExchangeSettingsChange({ clientSecret: value })}
          defaultValue={settings.exchangeSettings.clientSecret}
        />
      </Stack>
    </SettingsContainer>
  );
}
