import remarkGfm from 'remark-gfm';
import type { Node } from 'mdast';
import type { Element } from 'hast';
import type { Handlers } from 'mdast-util-to-hast';
import { handleReferenceNode, REF_NODE_ID, remarkEcReference } from './chatMessageReferenceSyntax';

type CARET_NODE_ID = 'generating-caret';
const CARET_NODE_ID: CARET_NODE_ID = 'generating-caret';

declare module 'mdast' {
  interface RootContentMap {
    [CARET_NODE_ID]: Node;
  }
}

const remarkAddGeneratingCaret = () => (tree: import('mdast').Root) => {
  // while generating, add a caret node at the very end of the last parent

  const addToNode = (node: import('mdast').Parent) => {
    if (!('children' in node)) return node;
    const parent = node as import('mdast').Parent;
    const lastChild = parent.children.at(-1);

    if (lastChild && 'children' in lastChild) {
      const newLastChild = addToNode(lastChild);
      return {
        ...node,
        children: [...node.children.filter((item) => item !== lastChild), newLastChild]
      };
    }

    return {
      ...node,
      children: [...node.children, { type: CARET_NODE_ID }]
    };
  };

  return addToNode(tree);
};

export const REMARK_PLUGINS = [remarkEcReference, remarkGfm];
export const REMARK_PLUGINS_WHILE_GENERATING = [
  remarkEcReference,
  remarkGfm,
  remarkAddGeneratingCaret
];

export const toHastHandlers: Handlers = {
  [CARET_NODE_ID]: (): Element => {
    return {
      type: 'element',
      tagName: 'span',
      properties: { class: 'generating-caret' },
      children: []
    };
  },
  [REF_NODE_ID]: (_, node) => handleReferenceNode(node)
};
