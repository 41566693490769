import { useMessageDraft } from 'hooks';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ICommentProps, IUserProps } from 'types';
import { checkScreenWidth } from 'utils/helpers';
import { ActionButton, DefaultButton, Persona, PersonaSize, Text } from '@fluentui/react';
import { useTheme } from 'styled-components';
import { IEditorValueProps } from './Conversation.types';
import Editor, { IEditorProps } from './Editor';

interface IConversationProps {
  /**
   * Text for the label
   */
  comment: ICommentProps;
  /**
   * Text for the label
   */
  currentUser: IUserProps;
  /**
   * Function to search for users
   */
  getPersons: IEditorProps['getPersons'];
  /**
   * Function to search for tags
   */
  getTags?: IEditorProps['getTags'];
  /**
   * Text for the label
   */
  isReply: boolean;
  /**
   * Text for the label
   */
  onDeleteComment: (id: string) => void;
  /**
   * Text for the label
   */
  onSaveComment: (comment: IEditorValueProps) => void;
  /**
   * Callback issued when a tag is clicked.
   */
  onTagClick?: IEditorProps['onTagClick'];
}

function Comment({
  comment,
  currentUser,
  getPersons,
  getTags,
  isReply,
  onDeleteComment,
  onSaveComment,
  onTagClick
}: IConversationProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  const [displayCompleteMessage, setDisplayCompleteMessage] = useState({
    showMoreButton: false,
    fullMessage: false
  });

  const [currentTextFieldValue, setCurrentTextFieldValue] = useState<string | undefined | null>(
    null
  );

  const [messageDraft, setSaveOnUnmount, deleteDraft] = useMessageDraft({
    message: currentTextFieldValue,
    id: comment.id
  });

  // whenever text can be edited
  const [editModeActive, setEditModeActive] = useState(!!messageDraft);

  // ref to determine message height
  const rteWrapperRef = useRef<HTMLDivElement>(null);

  // maximum height should be around 5 lines
  const maxMessageHeight = editModeActive ? undefined : 88;

  useEffect(() => {
    if (!messageDraft && rteWrapperRef.current) {
      const element = rteWrapperRef.current;
      if (element) {
        const observer = new MutationObserver(() => {
          const { scrollHeight, offsetHeight } = element as HTMLDivElement;

          setDisplayCompleteMessage((prevState) => ({
            ...prevState,
            showMoreButton: scrollHeight > offsetHeight
          }));
        });

        observer.observe(element, { attributes: true, childList: true, subtree: true });
      }
    }
  }, [rteWrapperRef, messageDraft]);

  /**
   * Get format string. if the comment was written in current year, the year does not have to be displayed
   */
  function getDateFormat(): string {
    let dateFormatToDisplay = 'DD.MM.YYYY HH:mm';

    const currentYear = new Date().getFullYear();
    const commentCreationYear = new Date(comment.creationDate).getFullYear();

    if (currentYear === commentCreationYear) {
      dateFormatToDisplay = 'DD.MM HH:mm';
    }

    return dateFormatToDisplay;
  }

  /**
   * Render Header. (Name / EditDate / edited )
   */
  function renderCommentHeader() {
    const dateFormat = getDateFormat();

    if (editModeActive) {
      return null;
    }

    const { editDate, creationDate } = comment;
    const formattedEditDate = moment(creationDate).format(dateFormat);
    const formattedCreationDate = moment(editDate).format(dateFormat);

    const isEdited = formattedEditDate !== formattedCreationDate;

    return (
      <div>
        <Text
          as="span"
          styles={{
            root: {
              fontSize: 12,
              marginRight: 10,
              fontWeight: 600,
              color: `rgb(${theme.conversation.commentCreatorForeground})`
            }
          }}
        >
          {comment.creator.name}
        </Text>
        <Text
          as="span"
          styles={{
            root: {
              fontSize: 12,
              marginRight: 10,
              color: `rgb(${theme.conversation.commentDateEditForeground})`
            }
          }}
        >
          {moment(editDate).format(dateFormat)}
        </Text>
        {isEdited && (
          <Text
            as="span"
            styles={{
              root: { fontSize: 12, color: `rgb(${theme.conversation.commentDateEditForeground})` }
            }}
          >
            {t('conversation.comment.header.edited')}
          </Text>
        )}
      </div>
    );
  }

  function handleSaveComment(editorValue: IEditorValueProps) {
    setCurrentTextFieldValue(null);

    setEditModeActive(false);
    deleteDraft();
    onSaveComment({ ...comment, ...editorValue });
  }

  function onEditorChange({ message }: IEditorValueProps) {
    setCurrentTextFieldValue(message);
    setSaveOnUnmount(true);
  }

  function renderCommentMessage() {
    const { fullMessage } = displayCompleteMessage;

    return (
      <div
        ref={rteWrapperRef}
        key={comment.id}
        style={{
          maxHeight: fullMessage ? 'max-content' : maxMessageHeight,
          overflow: 'hidden'
        }}
      >
        <Editor
          onSaveComment={handleSaveComment}
          getPersons={getPersons}
          getTags={getTags}
          onTagClick={onTagClick}
          comment={comment}
          onChange={onEditorChange}
          defaultTextValue={messageDraft}
          disabled={!editModeActive}
          displayCmdBar={editModeActive}
          styles={{
            editorWrapper: !editModeActive
              ? { padding: 0, borderTop: 'none', backgroundColor: 'transparent' }
              : undefined
          }}
          editMode={editModeActive}
          onCancelEditMode={() => {
            deleteDraft();
            setEditModeActive(false);
          }}
          placeholder={t('conversation.comment.editor.placeholder')}
        />
      </div>
    );
  }

  function getPersonaCoinSize() {
    if (checkScreenWidth(['extraSmall'])) {
      return PersonaSize.size24;
    }

    return isReply ? PersonaSize.size32 : PersonaSize.size40;
  }

  function renderPersonaCoin() {
    const imageUrl = comment.creator.pictureUrl || undefined;
    const text = comment.creator.name || undefined;

    if (editModeActive) {
      return null;
    }

    return (
      <div style={{ marginRight: 10 }}>
        <Persona imageUrl={imageUrl} size={getPersonaCoinSize()} hidePersonaDetails text={text} />
      </div>
    );
  }

  function onShowMoreButtonClick() {
    setDisplayCompleteMessage((prevState) => ({
      fullMessage: !prevState.fullMessage,
      showMoreButton: true
    }));
  }

  function renderShowMoreButton() {
    const { fullMessage, showMoreButton } = displayCompleteMessage;

    if (showMoreButton) {
      return (
        <ActionButton
          styles={{
            root: {
              borderTop: !fullMessage
                ? `1px solid rgba(${theme.conversation.readMoreBorder} / ${theme.conversation.readMoreBorderOpacity})`
                : undefined,
              boxShadow: !fullMessage ? theme.conversation.readMoreShadow : undefined,
              zIndex: 10,
              width: '100%',
              height: 25,
              fontSize: 12,
              padding: 0
            },
            rootHovered: { textDecoration: 'underline' },
            label: { color: `rgb(${theme.conversation.actionButtonForeground})`, margin: 0 }
          }}
          onClick={onShowMoreButtonClick}
        >
          {fullMessage
            ? t('conversation.comment.showMoreButton.showLess')
            : t('conversation.comment.showMoreButton.showMore')}
        </ActionButton>
      );
    }

    return null;
  }

  function onEditButtonClick() {
    setEditModeActive(true);

    if (!displayCompleteMessage.fullMessage) {
      setDisplayCompleteMessage({
        fullMessage: true,
        showMoreButton: false
      });
    }
  }

  function renderMenuButton() {
    const { permissions } = comment;

    if (!editModeActive && (permissions?.delete || permissions?.update)) {
      return (
        <DefaultButton
          menuIconProps={{ iconName: 'More' }}
          className="c-conversation__more-menu-button"
          styles={{
            root: {
              position: 'absolute',
              fontWeight: '600',
              minWidth: '40px',
              right: '-11px',
              top: '-5px',
              width: '30px',
              height: '22px',
              boxShadow: '0 0.2rem 0.4rem -0.075rem rgb(0 0 0 / 10%)',
              border: `1px solid rgb(${theme.conversation.commentMenuButtonOutline})`
            },
            menuIcon: { fontWeight: '600' },
            menuIconExpanded: { visibility: 'visible' }
          }}
          menuProps={{
            items: [
              {
                disabled: !permissions.update,
                iconProps: { iconName: 'Edit' },
                key: 'edit',
                onClick: onEditButtonClick,
                text: t('conversation.comment.menuButton.edit')
              },
              {
                disabled: !permissions.delete,
                iconProps: { iconName: 'Delete' },
                key: 'delete',
                onClick: () => onDeleteComment(comment.id),
                text: t('conversation.comment.menuButton.delete')
              }
            ]
          }}
        />
      );
    }

    return null;
  }

  function renderMessageIndicatorColor(): JSX.Element | null {
    if (editModeActive) {
      return null;
    }

    let backgroundColor = 'transparent';

    const isMentioned = !!comment.mentions?.find(
      (mentionedUser) => mentionedUser.userId === currentUser.userId
    );

    if (isMentioned) {
      backgroundColor = `rgb(${theme.conversation.commentIndicatorMentioned})`;
    }

    if (currentUser.userId === comment.creator.userId) {
      backgroundColor = `rgb(${theme.conversation.commentIndicatorSelf})`;
    }

    return (
      <div style={{ position: 'relative' }}>
        <div style={{ position: 'absolute', backgroundColor, height: '100%', width: '3px' }} />
      </div>
    );
  }

  return (
    <div className="c-conversation__comment-wrapper" style={{ display: 'flex' }}>
      {renderMessageIndicatorColor()}
      <div
        style={{
          width: '100%',
          borderBottom: isReply ? 'none' : `1px solid rgb(${theme.conversation.commentDivider})`,
          backgroundColor: isReply
            ? `rgb(${theme.conversation.commentReplyBackground})`
            : `rgb(${theme.conversation.commentBackground})`,
          display: 'flex',
          padding: editModeActive
            ? 0
            : `10px 16px ${displayCompleteMessage.showMoreButton ? '0px' : '10px'} 16px`
        }}
      >
        {isReply && renderPersonaCoin()}
        <div style={{ width: '100%', position: 'relative' }}>
          {renderCommentHeader()}
          {renderCommentMessage()}
          {renderShowMoreButton()}
          {renderMenuButton()}
        </div>
      </div>
    </div>
  );
}

export default Comment;
