import { LoadingSpinner } from 'components/progress';
import FileManagement, { IFileManagementProps } from 'components/surfaces/FileManagement';
import { useNotificationContext } from 'features/App';
import { ACCEPTED_FILE_TYPES, Author, IChatInputFile, IMessageUser } from 'hooks/api2';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Icon, Spinner } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { IChatFileProps } from './ChatFile';
import ChatInput, { IChatInputFileStatus } from './ChatInput';
import ChatMessage, {
  ChatItemLayoutContainer,
  ChatItemLayoutContainerContext
} from './ChatMessage';
import ChatScrollView from './ChatScrollView';
import { IToolRenderers } from './ChatMessageFragmentToolCall';
import {
  ChatConnectionStatus,
  ChatItemType,
  IChatItem,
  IChatItemUser,
  MessageFragmentType
} from '../../../services/ChatConnection/model';

export type IPendingMessage = Pick<IMessageUser, 'author' | 'text' | 'files'> & {
  isFailed?: boolean;
  shouldSend?: boolean;
  isSending?: boolean;
  error?: Error;
};

export interface IChatProps {
  id?: string;
  onSubmit: (message: string) => Promise<void>;
  /** Retries sending the message. */
  onRetryPending?: (message: IPendingMessage) => void;
  getRetryAssistantMessage?: (item: IChatItem) => null | (() => Promise<void>);
  items: Readonly<IChatItem[]>;
  pendingMessages: IPendingMessage[];
  disabled?: boolean;
  waitingForResponse?: boolean;
  inputFiles?: IChatInputFile[];
  inputFileStatuses?: IChatInputFileStatus[];
  onDeleteFile?: IChatFileProps['onDelete'];
  isGenerating: boolean;
  loading?: boolean;
  inputPosition: 'center' | 'bottom';
  stopGenerating: () => void;
  onFilesChange: IFileManagementProps['onChange'];

  /** Will render tool calls as GUI. Must be memoized! */
  toolRenderers?: IToolRenderers;
  /** Will display and respect connection status */
  connectionStatus?: ChatConnectionStatus;

  /** Adds a view transition name to the chat input */
  chatInputTransitionName?: string;
  /** Overrides the placeholder text */
  chatInputPlaceholder?: string;
  layoutType?: 'wide' | 'normal';
  /** Hides any auxiliary chat objects. Useful for narrow chats */
  hideAuxiliary?: boolean;
  /** Called when the user presses the escape key in the chat input. */
  onCancelInput?: () => void;

  temperature?: number;
  onTemperatureChange?: (value: number) => Promise<void>;
}

const ChatContainer = styled.div<{ $inputPosition?: string; $layoutType: string }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  --chat-overlap-height: ${({ $layoutType }) => ($layoutType === 'wide' ? '3rem' : '0')};

  .chat-input-layout-container {
    position: relative;
    bottom: ${({ $inputPosition }) => ($inputPosition === 'bottom' ? '0' : '50%')};
    transition: bottom 0.3s;
    flex-shrink: 0;
    z-index: 10;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: calc(var(--chat-overlap-height) + 2px);
      background: linear-gradient(
        to top,
        ${({ theme }) => {
          // quadratic gradient
          const gradient: string[] = [];
          for (let t = 0; t <= 1; t += 1 / 16) {
            gradient.push(`rgb(${theme.aiPage.background} / ${1 - t ** 2})`);
          }
          return gradient.join(',');
        }}
      );
      transition: opacity 0.3s 0.3s;
      opacity: ${({ $inputPosition }) => ($inputPosition === 'bottom' ? '1' : '0')};
    }

    min-height: var(--chat-overlap-height);
  }

  .chat-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    position: relative;
    width: 100%;
    margin: 0 auto;
  }

  .files-wrapper,
  .files-dropzone {
    /* overflow: hidden; */
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .messages {
    flex: 1 1 0;
    min-height: var(--chat-overlap-height);
    margin-bottom: calc(var(--chat-overlap-height) * -1);
  }

  .logo-suggestions {
    margin: auto;
  }

  .logo-wrapper {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    max-width: 30%;
    min-width: 210px;
  }

  .suggestions {
    gap: 10px;
    display: flex;
    padding: 20px 0;
    justify-content: center;
    flex-wrap: wrap;
  }

  .suggestion-button {
    border-radius: 12px;
    border: 1px solid #d5d5d5;
    max-width: 22%;
    min-width: 150px;

    .ms-Button-label {
      line-height: 18px;
    }
  }

  .dnd-overlay {
    display: none;
  }

  .files-dropzone-active {
    .dnd-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.4);
      z-index: 10;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 20px;
    }

    .dnd-content {
      padding: 20px;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      backdrop-filter: blur(10px);
    }

    .dnd-icon {
      font-size: 70px;
      margin-right: 10px;
      height: 70px;
      margin-top: 40px;
    }

    .dnd-text {
      margin-bottom: 10px;
    }

    .dnd-subtext {
      margin-bottom: 10px;
    }

    .accepts {
      font-size: 14px;
    }
  }

  .typing-indicator {
    display: inline-flex;
    align-items: center;
  }

  .typing-indicator span {
    height: 6px;
    width: 6px;
    background-color: rgb(${({ theme }) => theme.aiChat.typingIndicator});
    border-radius: 50%;
    display: inline-block;
    margin: 0 2px;
    opacity: 0.4;
    animation: blink 1.4s infinite both;
  }

  .typing-indicator span:nth-child(2) {
    animation-delay: 0.2s;
  }

  .typing-indicator span:nth-child(3) {
    animation-delay: 0.4s;
  }

  @keyframes blink {
    0% {
      opacity: 0.2;
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }

  .chat-end-spacer {
    height: var(--chat-overlap-height);

    &.is-solo {
      height: calc(var(--chat-overlap-height) + 1rem);
    }
  }
`;

export default function Chat({
  id: chatId,
  onSubmit,
  onRetryPending,
  getRetryAssistantMessage,
  waitingForResponse,
  items = [],
  pendingMessages = [],
  isGenerating,
  loading = false,
  disabled = false,
  inputPosition = 'center',
  stopGenerating,
  onFilesChange,
  inputFiles,
  inputFileStatuses,
  onDeleteFile,
  toolRenderers,
  connectionStatus,
  chatInputTransitionName,
  chatInputPlaceholder,
  layoutType,
  hideAuxiliary,
  onCancelInput,
  temperature,
  onTemperatureChange
}: IChatProps) {
  const { t } = useTranslation();
  const id = useId(chatId);

  const { showError } = useNotificationContext();

  function onUploadFilesError(error) {
    showError(error.message);
  }

  type ScrollViewItem =
    | { type: 'item'; id: string; item: IChatItem }
    | { type: 'pending-message'; id: string; item: IChatItemUser; message: IPendingMessage }
    | { type: 'status'; id: string };

  const scrollViewItems: ScrollViewItem[] = useMemo(() => {
    if (loading) return [];
    let pendingIndex = 0;

    return [
      ...(items
        .filter((item) => item.type !== ChatItemType.System)
        .map((item): ScrollViewItem => ({ type: 'item', id: item.id, item })) ?? []),

      ...pendingMessages.map((message): ScrollViewItem => {
        const id = `pending-${pendingIndex}`;
        pendingIndex += 1;

        const item: IChatItemUser = {
          id,
          type: ChatItemType.User,
          message,
          fragments: [{ id, type: MessageFragmentType.Text, text: message.text }]
        };

        return { type: 'pending-message', id, message, item };
      }),

      { type: 'status', id: 'status' } as ScrollViewItem
    ];
  }, [loading, items, pendingMessages]);

  const layoutContainerContext = useMemo(
    () => ({ layoutType: layoutType ?? 'normal', hideAuxiliary: !!hideAuxiliary }),
    [layoutType, hideAuxiliary]
  );

  return (
    <ChatItemLayoutContainerContext.Provider value={layoutContainerContext}>
      <ChatContainer
        $inputPosition={inputPosition}
        $layoutType={layoutContainerContext.layoutType}
        id={id ? `${id}-chat-container` : 'chat-container'}
      >
        <FileManagement
          id={`${id}-file-input`}
          disabled={loading || disabled}
          clickable={false}
          multiple
          maxFiles={5}
          onError={onUploadFilesError}
          onChange={onFilesChange}
          accepts={ACCEPTED_FILE_TYPES}
          maxFileSize={5242880}
        >
          <div className="dnd-overlay">
            <div className="dnd-content">
              <Icon className="dnd-icon" iconName="BulkUpload" />
              <div className="dnd-text">{t('ai.chat.attach')}</div>
              <div className="dnd-subtext">{t('ai.chat.dndContent')}</div>
              <div className="accepts">PDF, Word, PowerPoint, Excel, Text, JPG, PNG</div>
            </div>
          </div>
          <div className="chat-content">
            {!loading && (
              <ChatScrollView<ScrollViewItem>
                className="messages"
                items={scrollViewItems}
                renderItem={(item) => {
                  if (item.type === 'item') {
                    const chatItem = item.item;

                    const onRetry =
                      'message' in chatItem &&
                      chatItem.message.author === Author.AI &&
                      chatItem.message.isFailed
                        ? getRetryAssistantMessage?.(chatItem)
                        : null;

                    return (
                      <ChatMessage
                        item={chatItem}
                        toolRenderers={toolRenderers}
                        onRetry={onRetry}
                      />
                    );
                  }

                  if (item.type === 'pending-message') {
                    return (
                      <ChatMessage
                        item={item.item}
                        isSending={item.message.isSending}
                        onRetry={async () => onRetryPending(item.message)}
                      />
                    );
                  }

                  if (item.type === 'status') {
                    return (
                      <ChatStatus
                        waitingForResponse={waitingForResponse}
                        connectionStatus={connectionStatus}
                      />
                    );
                  }

                  return null;
                }}
                estimateItemHeight={(item) => {
                  if (item.type === 'item') {
                    const chatItem = item.item;
                    if (chatItem.type === ChatItemType.System) {
                      return 0;
                    }
                    return (
                      chatItem.fragments
                        .map((frag) => {
                          if (frag.type === MessageFragmentType.Text) {
                            return frag.text.split('\n').length * 24;
                          }
                          return 24;
                        })
                        .reduce((a, b) => a + b, 0) + 40
                    );
                  }
                  return 64;
                }}
              />
            )}
            {loading && <LoadingSpinner />}
            <ChatItemLayoutContainer
              className="chat-input-layout-container"
              withoutTopPadding
              withScrollbarSpacing
              variant="input"
            >
              <ChatInput
                disabled={loading || disabled}
                files={inputFiles}
                fileStatuses={inputFileStatuses}
                onDeleteFile={onDeleteFile}
                id={id}
                isGenerating={isGenerating}
                stopGenerating={stopGenerating}
                onSubmit={onSubmit}
                transitionName={chatInputTransitionName}
                placeholder={chatInputPlaceholder}
                onCancel={onCancelInput}
                temperature={temperature}
                onTemperatureChange={onTemperatureChange}
              />
            </ChatItemLayoutContainer>
          </div>
        </FileManagement>
      </ChatContainer>
    </ChatItemLayoutContainerContext.Provider>
  );
}

function ChatStatus({
  waitingForResponse,
  connectionStatus
}: {
  waitingForResponse: boolean;
  connectionStatus?: ChatConnectionStatus;
}) {
  const isConnectionStatusNoteworthy =
    connectionStatus &&
    connectionStatus !== ChatConnectionStatus.Connected &&
    connectionStatus !== ChatConnectionStatus.Stateless;

  const [showConnectionStatus, setShowConnectionStatus] = useState(false);
  useEffect(() => {
    if (isConnectionStatusNoteworthy) {
      // show connection status with a delay, because every chat starts off as 'not connected',
      // which is just distracting
      const timeout = setTimeout(() => {
        setShowConnectionStatus(true);
      }, 1000);
      return () => {
        clearTimeout(timeout);
      };
    }

    setShowConnectionStatus(false);
    return () => null;
  }, [isConnectionStatusNoteworthy]);

  if (showConnectionStatus && isConnectionStatusNoteworthy) {
    return (
      <ChatItemLayoutContainer>
        <ChatConnectionStatusDisplay connectionStatus={connectionStatus} />
        <div className="chat-end-spacer" />
      </ChatItemLayoutContainer>
    );
  }

  if (waitingForResponse) {
    return (
      <ChatItemLayoutContainer>
        <TypingIndicator />
        <div className="chat-end-spacer" />
      </ChatItemLayoutContainer>
    );
  }

  return <div className="chat-end-spacer is-solo" />;
}

function TypingIndicator() {
  return (
    <div className="typing-indicator">
      <span />
      <span />
      <span />
    </div>
  );
}

const ConnectionStatusStyled = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  gap: 0.5rem;
  align-items: center;
  font-size: 1rem;
  padding: 0.5rem;
`;

function ChatConnectionStatusDisplay({
  connectionStatus
}: {
  connectionStatus: ChatConnectionStatus;
}) {
  const { t } = useTranslation();

  let icon;
  if (connectionStatus === ChatConnectionStatus.Connecting) {
    icon = <Spinner />;
  } else if (
    connectionStatus === ChatConnectionStatus.Disconnecting ||
    connectionStatus === ChatConnectionStatus.NotConnected
  ) {
    icon = <Icon iconName="Error" />;
  }

  return (
    <ConnectionStatusStyled>
      {icon}
      {t(`ai.chat.connectionStatus.${connectionStatus}`)}
    </ConnectionStatusStyled>
  );
}
