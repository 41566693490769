import styled from 'styled-components';
import breakpoints from 'utils/breakpoints';

export const WrapperStyled = styled.div`
  background-color: #fafafa;
  overflow: hidden;
  height: 100%;
  display: flex;

  @media (max-width: ${breakpoints.extraSmallMax}px) {
    padding-bottom: 50px;
  }
`;

export const ListWrapperStyled = styled.div`
  margin: 15px;
  padding: 5px;
  flex-basis: 1100px;
  overflow: hidden;
  display: flex;
`;

export const FieldRowStyled = styled.div`
  &:hover {
    .deleteButton {
      visibility: visible;
    }
  }
`;

export const NameStyled = styled.span<{ $disabled?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: pre-line;

  color: ${(props) =>
    props.$disabled
      ? `rgb(${props.theme.detailsList.linkNameDisabledForeground})`
      : `rgb(${props.theme.detailsList.linkNameForeground})`};

  &:hover {
    color: ${(props) =>
      props.$disabled
        ? `rgb(${props.theme.detailsList.linkNameDisabledForeground})`
        : `rgb(${props.theme.detailsList.linkNameHoverForeground})`};
    cursor: ${(props) => (props.$disabled ? 'default' : 'pointer')};

    .info-icon {
      visibility: visible;
    }
  }

  .info-icon {
    visibility: hidden;
    color: rgb(${({ theme }) => theme.infoIconTooltip.iconColor});
    margin-left: 5px;
    padding-top: 3px;
    font-size: 12px;
  }

  .nameAndInfo {
    display: flex;
    justify-content: space-between;
    white-space: pre-line;
  }
`;

export const UserCellStyled = styled.div`
  display: flex;
  flex-direction: column;

  .user-email {
    font-size: 10px;
    font-style: oblique;
  }
`;
