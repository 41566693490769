/* eslint-disable react/jsx-props-no-spreading */
import { Label } from 'components/inputs';
import { LookupOptionsContainer, TemplateFieldLink } from 'components/surfaces/FieldForm/styles';
import InfoIconTooltip from 'components/surfaces/InfoIconTooltip/InfoIconTooltip';
import { t } from 'i18next';
import { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import { apiErrorHandler } from 'services/api';
import { getLookupOptionsField } from 'services/fetchRequests';
import styled from 'styled-components';
import { IFieldProps, ILookupColumnField } from 'types';
import {
  Checkbox,
  ComboBox,
  Dropdown,
  IComboBoxOption,
  IContextualMenuItem,
  IDropdownOption,
  ISelectableOption,
  Icon,
  IconButton
} from '@fluentui/react';
import { IFormProps } from './FieldForm';

type DisabledrenderItem = {
  $disabled?: boolean;
};

const SelectedFieldContainer = styled.div<DisabledrenderItem>`
  color: ${(props) => (props.$disabled ? 'rgb(161, 159, 157)' : null)};
`;

function LookupSettings({ field, setField, disabled }: IFormProps) {
  const [definitionsOptions, setDefinitionsOptions] = useState<IComboBoxOption[]>([]);

  const [definitionsInitialized, setDefinitionsInitialized] = useState(false);
  const [definitionFields, setDefinitionFields] = useState<IFieldProps[] | undefined>([]);

  const getDefinitions = useCallback(async () => {
    return getLookupOptionsField({
      pageIndex: 0,
      itemsPerPage: 200,
      status: 1,
      type: 6,
      onlyEditable: false
    })
      .then((definitions) => {
        const flatDefinitions: IComboBoxOption[] = definitions.items.map((service) => {
          return {
            key: service.id || '',
            text: service.name || '',
            data: service.dataFields
              ?.filter((dataField) => {
                if (dataField?.field?.id) {
                  return dataField.field.id !== '00000000-0000-0000-0000-000000000000';
                }

                return false;
              })
              .map((dataField) => dataField.field)
          };
        });

        setDefinitionsOptions(flatDefinitions);

        return flatDefinitions;
      })
      .catch(apiErrorHandler);
  }, []);

  useEffect(() => {
    if (!definitionsInitialized && field?.fieldType === 12) {
      setDefinitionsInitialized(true);

      getDefinitions().then((flatDefinitions) => {
        if (Array.isArray(flatDefinitions)) {
          const selectedOption = flatDefinitions.find(
            (definition) => definition.key === field.lookupDefinitionId
          );

          setDefinitionFields(selectedOption?.data);
        }
      });
    }
  }, [field, getDefinitions, definitionsInitialized]);

  function onRenderLabel(label: string, infoText: string, required = false) {
    return (
      <div style={{ display: 'flex', marginTop: 5, gap: !required ? 8 : 0 }}>
        <Label label={label} required={required} />
        <InfoIconTooltip styles={{ iconStyles: { root: { marginTop: 6 } } }} content={infoText} />
      </div>
    );
  }

  function handleLookupType(value: IDropdownOption | undefined) {
    setField((prevState) => ({
      ...prevState,
      lookupOnlyOutcome: 0,
      lookupType: Number(value?.key)
    }));
  }

  function handleLookupDefinitionId(option: ISelectableOption<IFieldProps[]> | undefined) {
    if (!option) return;

    setDefinitionFields(option.data);

    setField((prevState) => ({
      ...prevState,
      lookupDefinitionId: option?.key?.toString(),
      lookupDisplayFields: [
        {
          fieldId: null,
          fieldName: 'intId',
          showInList: true,
          showInResult: true
        },
        {
          fieldId: null,
          fieldName: 'name',
          showInList: true,
          showInResult: true
        },
        {
          fieldId: null,
          fieldName: 'creator',
          showInList: true,
          showInResult: false
        },
        {
          fieldId: null,
          fieldName: 'creationDate',
          showInList: true,
          showInResult: false
        }
      ]
    }));
  }

  function addDefinitionField(option: ISelectableOption<IFieldProps[]> | undefined) {
    if (!option) return;

    setField((prevState) => {
      const id = option?.key?.toString();

      const lookupDisplayFields = prevState.lookupDisplayFields
        ? [...prevState.lookupDisplayFields]
        : [];

      if (id === 'creationDate' || id === 'creator') {
        lookupDisplayFields.push({
          fieldName: id,
          fieldId: null,
          showInList: true,
          showInResult: true
        });

        return { ...prevState, lookupDisplayFields };
      }

      const definitionFIeld = definitionFields?.find(
        (definitionField) => definitionField.id === id
      );

      if (definitionFIeld) {
        lookupDisplayFields.push({
          fieldId: definitionFIeld.id,
          fieldName: definitionFIeld.name,
          showInList: false,
          showInResult: true
        });
      }

      return { ...prevState, lookupDisplayFields };
    });
  }

  function getDefinitionFieldOption() {
    if (!definitionFields) return [];

    const options = definitionFields
      .filter((definitionField) => {
        if (!definitionField.id) return false;

        return !field.lookupDisplayFields?.find(
          (lookupField) => lookupField.fieldId === definitionField.id
        );
      })
      .map((definitionField) => ({
        key: definitionField.id || '',
        text: definitionField.name || ''
      }));

    // check if creationDate and creator is in the lookupDisplayFields
    // if not add them to the options
    if (!field.lookupDisplayFields?.find(({ fieldName }) => fieldName === 'creationDate')) {
      options.push({ key: 'creationDate', text: t(`lookupField.instanceList.creationDate`) });
    }

    if (!field.lookupDisplayFields?.find(({ fieldName }) => fieldName === 'creator')) {
      options.push({ key: 'creator', text: t(`lookupField.instanceList.creator`) });
    }

    return options;
  }

  function onDeleteLookupItem(fieldToDelete: {
    fieldId?: string | null;
    fieldName?: string | null;
  }) {
    setField((prevState) => {
      const newLookupDisplayFields = prevState.lookupDisplayFields?.filter((lookupField) => {
        if (fieldToDelete.fieldId) return lookupField.fieldId !== fieldToDelete.fieldId;
        if (fieldToDelete.fieldName) return lookupField.fieldName !== fieldToDelete.fieldName;
        return false;
      });

      return { ...prevState, lookupDisplayFields: newLookupDisplayFields };
    });
  }

  function onLookupOnlyOutcomeChange() {
    setField((prevState) => ({
      ...prevState,
      lookupOnlyOutcome: prevState.lookupOnlyOutcome === 1 ? 0 : 1
    }));
  }

  function reorder(list: ILookupColumnField[], sourceIndex: number, destinationIndex: number) {
    const result = Array.from(list);
    const [removed] = result.splice(sourceIndex, 1);

    result.splice(destinationIndex, 0, removed);

    return result;
  }

  function onDragEnd(result: DropResult) {
    if (!result.destination) return;

    if (result.destination.index < 4) return;

    const lookupDisplayFields = reorder(
      field.lookupDisplayFields || [],
      result.source.index,
      result.destination.index
    );

    setField((prevState) => ({ ...prevState, lookupDisplayFields }));
  }

  function getItemStyle(isDragging: boolean, index: number) {
    const styles: React.CSSProperties = {
      background: 'white',
      display: 'flex',
      width: '100%',
      alignItems: 'center'
    };

    const length = field.choices?.length || 0;
    const isLastItem = index === length - 1;

    if (!isLastItem && !isDragging) {
      styles.borderBottom = '1px solid #ababab';
    }

    if (isDragging) {
      styles.border = '1px solid #ababab';
      styles.borderRadius = 4;
    }

    return styles;
  }

  function onToggleLookupFieldSetting(
    fieldToToggle: ILookupColumnField,
    propToToggle: 'showInList' | 'showInResult'
  ) {
    setField((prevState) => {
      return {
        ...prevState,
        lookupDisplayFields: prevState.lookupDisplayFields?.map((lookupDisplayField) => {
          if (
            (fieldToToggle.fieldId && lookupDisplayField.fieldId === fieldToToggle.fieldId) ||
            (fieldToToggle.fieldName && lookupDisplayField.fieldName === fieldToToggle.fieldName)
          ) {
            return { ...lookupDisplayField, [propToToggle]: !lookupDisplayField[propToToggle] };
          }

          return lookupDisplayField;
        })
      };
    });
  }

  function getDefaultFieldName(fieldName: string) {
    if (fieldName === 'intId') return 'ID';

    if (fieldName === 'name') return 'Name';

    return t(`lookupField.instanceList.${fieldName}`);
  }

  function renderSelectedField(fieldToRender: ILookupColumnField, index: number) {
    const menuItems: IContextualMenuItem[] = [
      {
        key: 'displayInSearch',
        disabled,
        canCheck: true,
        checked: fieldToRender.showInList,
        text: t('createField.dialog.lookupField.displayInSearch'),
        iconProps: { iconName: 'BulletedList' },
        onClick: () => onToggleLookupFieldSetting(fieldToRender, 'showInList')
      },
      {
        key: 'displayInResult',
        disabled:
          disabled || fieldToRender.fieldName === 'intId' || fieldToRender.fieldName === 'name',
        canCheck: true,
        checked: fieldToRender.showInResult,
        text: t('createField.dialog.lookupField.displayInResult'),
        iconProps: { iconName: 'PageData' },
        onClick: () => onToggleLookupFieldSetting(fieldToRender, 'showInResult')
      }
    ];

    if (fieldToRender.fieldName !== 'intId' && fieldToRender.fieldName !== 'name') {
      menuItems.push({
        key: 'delete',
        disabled,
        text: t('globals.delete'),
        iconProps: { iconName: 'Cancel' },
        onClick: () =>
          onDeleteLookupItem({
            fieldId: fieldToRender.fieldId,
            fieldName: fieldToRender.fieldName
          })
      });
    }

    return (
      <Draggable
        key={fieldToRender.fieldId || fieldToRender.fieldName}
        draggableId={(fieldToRender.fieldId || fieldToRender.fieldName) as string}
        index={index}
        isDragDisabled={!fieldToRender.fieldId}
      >
        {({ innerRef, dragHandleProps, draggableProps }, { isDragging }) => (
          <SelectedFieldContainer
            $disabled={disabled}
            ref={innerRef}
            {...dragHandleProps}
            {...draggableProps}
            style={draggableProps.style}
          >
            <TemplateFieldLink style={getItemStyle(isDragging, index)}>
              <div style={{ display: 'flex' }}>
                {!fieldToRender.fieldId ? (
                  <div style={{ marginRight: 22 }} />
                ) : (
                  <Icon
                    style={{ fontSize: '20px', paddingRight: '2px', color: '#b4b4b4' }}
                    iconName="GripperDotsVertical"
                  />
                )}
                <div>
                  {fieldToRender.fieldId
                    ? fieldToRender.fieldName
                    : getDefaultFieldName(fieldToRender.fieldName as string)}
                </div>
              </div>

              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Icon
                  iconName={fieldToRender.showInList ? 'BulletedList' : undefined}
                  title={t('createField.dialog.lookupField.displayInSearch')}
                  className="display-settings-icon"
                />
                <Icon
                  iconName={fieldToRender.showInResult ? 'PageData' : undefined}
                  title={t('createField.dialog.lookupField.displayInResult')}
                  className="display-settings-icon"
                />
                <IconButton
                  menuIconProps={{ iconName: 'More' }}
                  title={t('globals.more')}
                  styles={{
                    root: {
                      height: 30,
                      marginRight: 10,
                      backgroundColor: '#eeeeee',
                      borderRadius: 4
                    },
                    rootExpanded: { visibility: 'visible !important', opacity: '1 !important' },
                    rootHovered: { backgroundColor: '#e2e2e2' }
                  }}
                  menuProps={{ items: menuItems }}
                />
              </div>
            </TemplateFieldLink>
          </SelectedFieldContainer>
        )}
      </Draggable>
    );
  }

  function renderSelectionField() {
    let fieldsToRender = field.lookupDisplayFields || [];

    fieldsToRender = fieldsToRender.map((fieldToRender) => {
      const definitionField = definitionFields?.find(
        (definitionField) => definitionField.id === fieldToRender.fieldId
      );

      return {
        ...fieldToRender,
        fieldName: definitionField?.name || fieldToRender.fieldName
      };
    });

    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable isDropDisabled={false} droppableId="fields-droppable">
          {(droppableProvided) => (
            <div
              style={{ overflowY: 'auto', height: '100%', maxHeight: 250 }}
              ref={droppableProvided.innerRef}
            >
              {fieldsToRender.map(renderSelectedField)}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  return (
    <LookupOptionsContainer>
      <ComboBox
        selectedKey={field.lookupDefinitionId}
        placeholder={t('createField.dialog.lookupDefinition.combobox.placeholder')}
        label={t('createField.dialog.lookupDefinition.combobox.label')}
        onChange={(_, value) => handleLookupDefinitionId(value)}
        autoComplete="on"
        styles={{ root: { width: '100%' } }}
        allowFreeform
        onRenderLabel={() =>
          onRenderLabel(
            t('createField.dialog.lookupDefinition.combobox.label'),
            t('createField.dialog.lookupDefinition.combobox.info'),
            true
          )
        }
        required
        options={definitionsOptions}
        useComboBoxAsMenuWidth
        disabled={disabled}
      />

      <div style={{ display: 'flex' }}>
        <Dropdown
          styles={{ root: { width: 250 } }}
          selectedKey={field?.lookupType || 0}
          onChange={(_, value) => handleLookupType(value)}
          onRenderLabel={() =>
            onRenderLabel(
              t('createField.dialog.lookupType.choice.label'),
              t('createField.dialog.lookupType.choice.info')
            )
          }
          options={[
            { key: 0, text: t('createField.dialog.lookupType.choice.all') },
            { key: 2, text: t('createField.dialog.lookupType.choice.onlyRunning') },
            { key: 1, text: t('createField.dialog.lookupType.choice.completed') }
          ]}
          disabled={disabled}
        />
        {field.lookupType === 1 && (
          <Checkbox
            styles={{ root: { margin: 'auto 0px 6px 10px' } }}
            disabled={disabled}
            checked={field.lookupOnlyOutcome === 1}
            label={t('createField.dialog.lookupOnlyOutcome.positive.label')}
            onChange={onLookupOnlyOutcomeChange}
          />
        )}
      </div>
      {onRenderLabel(
        t('createField.dialog.definitionFields.label'),
        t('createField.dialog.definitionFields.info'),
        true
      )}
      {renderSelectionField()}
      <ComboBox
        key={field.lookupDisplayFields?.length}
        placeholder={t('createField.dialog.definitionFields.placeholder')}
        onChange={(_, value) => addDefinitionField(value)}
        autoComplete="on"
        allowFreeform
        options={getDefinitionFieldOption()}
        styles={{ optionsContainerWrapper: { maxHeight: '250px' } }}
        useComboBoxAsMenuWidth
        autofill={undefined}
        disabled={disabled || getDefinitionFieldOption().length === 0}
      />
    </LookupOptionsContainer>
  );
}

export default LookupSettings;
