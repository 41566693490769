export enum TaskType {
  Task = 1,
  Request = 2,
  ApprovalParent = 3,
  Approval = 4,
  SurveyParent = 5,
  Survey = 6,
  RouteTaskParent = 7,
  RouteTask = 8,
  RouteApprovalParent = 9,
  RouteApproval = 10,
  RouteDataParent = 11,
  RouteData = 12,
  RouteSurveyParent = 13,
  RouteSurvey = 14,
  RouteDataSub = 20,
  AgileProjectBacklog = 50,
  AgileProjectTask = 51,
  ClassicProjectMilestone = 60,
  ClassicProjectTask = 61,
  PlannerTask = 100,
  ProjectTemplateMilestone = 120,
  ProjectTemplateTask = 121
}

export enum TaskStatus {
  NotStarted = 1,
  Deferred = 2,
  InProgress = 3,
  Waiting = 4,
  Completed = 5,
  Aborted = 6
}

export enum DateType {
  NotSpecified = 0,
  ThisMonth = 1,
  ThisWeek = 2,
  Today = 3,
  DueAndOverdue = 4,
  Upcoming = 5,
  FinishedInTime = 6,
  FinishedJustInTime = 7,
  FinishedLate = 8
}

export enum ApprovalTaskStatus {
  Unset = 0,
  Approved = 1,
  Rejected = 2
}

export enum RequestStatus {
  Waiting = 1,
  Accepted = 2,
  Rejected = 3
}

export enum ConvertChecklistitemType {
  ToTask = 1,
  ToRequest = 2,
  ToDataSub = 3,
  toClassicProjectSub = 4
}

export enum TeamType {
  Internal = 1,
  External = 2,
  Department = 3,
  Board = 4, // Gremium
  TenantAdmin = 5,
  AllUsers = 6
}

export enum DepartmentType {
  PatentsAndIPR = 2,
  ProductManagement = 3,
  Marketing = 4,
  Assembly = 5,
  ProductionPreparation = 6,
  QualityManagement = 7,
  Finance = 8,
  Logistics = 10,
  Subsidiary = 11,
  SupplyChain = 12,
  Production = 14,
  Administration = 16,
  ManagingDirectors = 17,
  HumanResources = 18,
  Accounting = 19,
  Accountancy = 20,
  Legal = 21,
  ResearchAndDevelopment = 22,
  Manufacturing = 23,
  SafetyAtWork = 25,
  PublicRelations = 26,
  InformationTechnology = 27,
  Sales = 29,
  PostOffice = 30,
  FacilityManagement = 32,
  CustomerService = 33,
  Maintenance = 35,
  Controlling = 36
}

export enum MemberRole {
  TeamLeader = 1,
  TeamMember = 2
}

export enum RouteStepType {
  Start = 1,
  End = 2,
  Approval = 3,
  Task = 4,
  DataSwitch = 5,
  Data = 6,
  Survey = 7,
  Integration = 8
}

export enum RouteConnectionType {
  Positive = 0,
  Negative = 1,
  Undecided = 2
}

export enum RouteFieldType {
  FieldGroup = 0,
  SmallText = 1,
  LongText = 2,
  Number = 3,
  Boolean = 4,
  DateTime = 5,
  Choice = 6,
  Person = 7,
  Rating = 8,
  Hyperlink = 9,
  Document = 10,
  ExternalData = 11,
  Lookup = 12,
  Location = 13,
  Signature = 14,
  Multimedia = 15,
  Scanner = 16,
  AI = 17
}

export enum DateTimeFieldFormat {
  DateOnly = 1,
  DateAndTime = 2
}

export enum ChoiceFieldFormat {
  SingleSelect = 1,
  MultiSelect = 2
}

export enum RouteFieldVisibility {
  Optional = 1,
  Required = 2,
  ReadOnly = 3
}

export enum RouteStepAssignment {
  Direct = 0,
  ToCreator = 1
}

export enum RouteDefinitionStatus {
  Draft = 0,
  Published = 1
}

export enum RouteDefinitionUsage {
  /**
   * 0000
   */
  Deactivated = 0,
  /**
   * 0001
   */
  AsEpProcess = 1 << 0,
  /**
   * 0010
   */
  InIntegration = 1 << 1,
  /**
   * 0100
   */
  InFields = 1 << 2
}

export enum RouteDefinitionAuth {
  None = 0,
  AsUser = 1,
  AsApp = 2
}

export enum RouteDefinitionContentType {
  Json = 1,
  Xml = 2
}

export enum QueryFieldUsage {
  /**
   * normaler query-Parameter ? key = value
   */
  KeyValue = 0,
  /**
   * value wird durch string.Format eingesetzt. In stringFormat muß dann der entsprechende string stehen (z.B. "field -eq {ß}"
   */
  StringFormat = 1
}

export enum RouteInstanceStatus {
  NotStarted = 1,
  Started = 2,
  InProgress = 3,
  Completed = 4,
  AbortedBySubProcess = 5,
  AbortedByUser = 6,
  Deleted = 7
}

export enum RouteInstanceStepStatus {
  Current = 1,
  Upcoming = 2,
  Done = 3,
  Bypassed = 4,
  Skipped = 5,
  ManuallySkipped = 6,
  Aborted = 7
}

export enum RouteInstanceStepServiceStatus {
  Running = 1,
  Upcoming = 2,
  Finished = 3,
  /** Bypassed because rules didn't match. */
  Bypassed = 4,
  Error = 5,
  /** Manually skipped by user */
  Skipped = 6,
  /** Manually aborted by user */
  Aborted = 7,
  /** This is for processes running in test mode where no sub-processes are started. will be set to Finished when done. */
  Testing = 8
}

export enum ProcessOutcome {
  Positive = 1,
  Negative = 2,
  Undecided = 3
}

export enum RuleCondition {
  Equal = 1,
  NotEqual = 2,
  GreaterThan = 3,
  LessThan = 4,
  GreaterOrEqual = 5,
  LessOrEqual = 6,
  BeginsWith = 7,
  Contains = 8,
  EndsWith = 9,
  NotContains = 10,
  IsEmpty = 11,
  IsNotEmpty = 12
}

export enum RuleConditionLink {
  And = 1,
  Or = 2
}

export enum OutcomeBehavior {
  /**
   * Die erste Entscheidung ist bestimmend, möglicher Ausgang ist Ja oder Nein
   */
  FirstApplies = 1,
  /**
   * Die Mehrheit entscheidet, es müssen nicht alle abstimmen. 2 von 3 Stimmen für Ja dann wird der 3. Task gelöscht und die Entscheidung steht auf Ja.
   * Möglicher Ausgang ist Ja, Nein, Unentschieden
   */
  MajorityApplies = 2,
  /**
   * Einstimmige Entscheidung, sobald es eine abweichende Stimme gibt, ergibt sich der Ausgang Unentschieden, ansonsten müssen alle "Ja" oder alle "Nein" abstimmen.
   * Möglicher Ausgang ist Ja, Nein, Unentschieden
   */
  UnanimityNecessary = 3,
  /**
   * Die Mehrheit entscheidet, es müssen alle abstimmen
   * Möglicher Ausgang ist Ja, Nein, Unentschieden
   */
  AllDecideWithMajority = 4,
  /**
   * Es müssen alle abstimmen und dann muß Einigkeit bestehen (wie bei 3)
   * Möglicher Ausgang ist Ja, Nein, Unentschieden
   */
  AllDecideUnanimous = 5
}

export enum FieldAggregationType {
  /**
   * `0000` kann ignoriert werden, z.B. kein Survey
   */
  NotSet = 0,
  /**
   * `0001` keine Aggregation (d.h. die Einzelwerte sollen angezeigt werden), weil nicht erwünscht oder wie bei Text nicht möglich
   */
  NoAggregation = 1 << 0,
  /**
   * `0010` Summe - nur bei Zahlen
   */
  Sum = 1 << 1,
  /**
   * `0100` Durchschnitt - nur bei Zahlen
   */
  Average = 1 << 2,
  /**
   * `1000` Werte zählen - nur bei Choice-Feldern
   */
  CountValues = 1 << 3
}

export enum FileType {
  /**
   * `0000`
   */
  NotSpecified = 0,
  /**
   * `0001`
   */
  MainInstanceImage = 1 << 0
}

export enum TenantStatus {
  /**
   * ein alter Mandant, bei dem das nie gesetzt wurde
   */
  Unknown = 0,
  /**
   * Testphase 1
   */
  FirstTest = 1,
  /**
   * erweiterte Testphase 2
   */
  ExtendedTest = 2,
  /**
   * Kunde
   */
  Customer = 3,
  /**
   * ehemaliger Kunde
   */
  FormerCustomer = 4,
  /**
   * gesperrt (Testphase abgelaufen, Lizenz überschritten, etc.)
   */
  Locked = 5
}

export enum NumEmployees {
  /**
   * Unknown
   */
  Unknown = 0,
  /**
   * 1 to 10
   */
  xSmall = 1,
  /**
   * 11 to 50
   */
  small = 2,
  /**
   * 51 to 200
   */
  smallPlus = 3,
  /**
   * 201 to 500
   */
  medium = 4,
  /**
   * 501 to 1000
   */
  mediumPlus = 5,
  /**
   * 1001 to 5000
   */

  large = 6,
  /**
   * 5001 to 10000
   */
  xLarge = 7,
  /**
   * 10000 +
   */
  xxLarge = 8
}

export enum TextType {
  RouteDefinitionName = 1,
  RouteDefinitionDescription = 2,
  RouteDefinitionStepName = 3,
  RouteDefinitionStepDescription = 4,
  RouteDefinitionStepOutcomes = 5,
  RouteDefinitionStepGroupName = 6,
  RouteDefinitionStepGroupDescription = 7,
  RouteFieldName = 8,
  RouteFieldDescription = 9,
  RouteFieldChoiceValue = 10,
  TeamTitle = 11,
  RoleName = 12,
  CountryName = 13,
  LocationName = 14,
  ProcessTypeName = 15,
  ProcessTypeDescription = 16,
  EndToEndTypeName = 17,
  EndToEndTypeDescription = 18
}

export enum NotificationGroupType {
  Task = 1,
  Team = 2,
  RouteDefinition = 3,
  RouteInstance = 4
}

export enum ProcessLookupType {
  All = 0,
  OnlyComplted = 1,
  OnlyRunning = 2
}

export enum ProjectStatus {
  new = 1,
  InProgress = 2,
  Completed = 10
}

export enum ProjectType {
  Classic = 1,
  Agile = 2
}

export enum SprintId {
  ProductBacklog = '11111111-1111-1111-1111-111111111111',
  Removed = 'ffffffff-ffff-ffff-ffff-ffffffffffff',
  NoColumn = '11111111-1111-1111-1111-111111111111'
}

export enum PersonFieldFormat {
  singlePerson = 1,
  multiplePersons = 2,
  singleTeam = 3,
  multipleTeams = 4,
  personsAndTeams = 5
}

export enum ViewStatus {
  new = 0,
  read = 1,
  deferred = 2,
  running = 3
}

export enum PageLocation {
  pages = 1,
  projectLists = 2,
  project = 3,
  projectDetails = 4,
  projectTask = 5,
  processLists = 6,
  processList = 7,
  processDetails = 8,
  processTask = 9,
  tasksLists = 10
}

export enum ChatRole {
  System = 'system',
  Assistant = 'assistant',
  User = 'user',
  Function = 'function',
  Tool = 'tool'
}

export enum ChatAppAccessScope {
  Public = 1,
  Organization = 2,
  Team = 3
}

export enum StepType {}
