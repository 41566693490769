import Label from 'components/inputs/Label';
import i18n from 'i18next';
import {
  $createTextNode,
  $getSelection,
  $isRangeSelection,
  ElementNode,
  GridSelection,
  LexicalEditor,
  NodeSelection,
  RangeSelection,
  TextNode
} from 'lexical';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import { TextField } from '@fluentui/react';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { getSelectedNode } from '../utils';

const LinkFormWrapper = styled.div`
  padding: 6px 12px 12px 12px;
  width: 100%;
  max-width: 500px;

  .confirm-button-wrapper {
    margin-top: 8px;
    display: flex;
    justify-content: flex-end;
  }
`;

interface ILink {
  text: string;
  url: string;
}
interface ILinkFormProps {
  onDismiss?: () => void;
  editor: LexicalEditor;
}

function LinkForm({ onDismiss, editor }: ILinkFormProps) {
  const [lastSelection, setLastSelection] = useState<
    RangeSelection | NodeSelection | GridSelection | null
  >(null);

  const [node, setNode] = useState<null | undefined | TextNode | ElementNode>(null);

  const [link, setLink] = useState<ILink>({
    text: '',
    url: ''
  });

  const [initialized, setInitialized] = useState(false);

  const getCurrentLinkInformations = useCallback(() => {
    let text = window.getSelection()?.toString() || '';
    let url = '';
    let node;

    editor.update(() => {
      const selection = $getSelection();
      setLastSelection(selection);

      if ($isRangeSelection(selection)) {
        const selectedNode = getSelectedNode(selection);
        const parent = selectedNode.getParent();

        if ($isLinkNode(parent)) {
          url = parent.getURL();
          text = parent.getTextContent();
          node = selectedNode;
        }
      }
    });

    return { text, url, node };
  }, [editor]);

  useLayoutEffect(() => {
    if (!initialized) {
      const { url, text, node } = getCurrentLinkInformations();
      setLink({ text, url });
      setNode(node);

      setInitialized(true);
    }
  }, [initialized, getCurrentLinkInformations]);

  useEffect(() => {
    return () => {
      if (onDismiss) {
        onDismiss();
      }
    };
  }, [onDismiss]);

  function insertLink() {
    if (lastSelection !== null) {
      if (link.url !== '') {
        if (node) {
          editor.update(() => {
            node.setTextContent(link.text);
          });

          editor.dispatchCommand(TOGGLE_LINK_COMMAND, link.url);
        } else {
          editor.update(() => {
            // add text node
            const textNode = $createTextNode(link.text);

            lastSelection.insertNodes([textNode]);
            textNode.select();
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, link.url);
          });
        }
      }
    }
  }

  function onTextFieldKeyPress(
    ev: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void {
    if (ev.which === 13) {
      if (link.url && link.text) {
        insertLink();
      }
    }
  }

  if (!initialized) {
    return null;
  }

  return (
    <LinkFormWrapper>
      <Label label={i18n.t('components:richTextEditor.toolbar.linkForm.mainLabel')} />
      <TextField
        id="link-text"
        label={i18n.t('components:richTextEditor.toolbar.linkForm.textLabel')}
        required
        defaultValue={link.text || undefined}
        onKeyPress={(ev: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) =>
          onTextFieldKeyPress(ev)
        }
        onChange={(_, value) => setLink((prevState) => ({ ...prevState, text: value || '' }))}
        autoFocus
      />
      <TextField
        label={i18n.t('components:richTextEditor.toolbar.linkForm.adress')}
        required
        defaultValue={link.url || undefined}
        onKeyPress={(ev: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) =>
          onTextFieldKeyPress(ev)
        }
        onChange={(_, value) => setLink((prevState) => ({ ...prevState, url: value || '' }))}
      />
      <div className="confirm-button-wrapper">
        <PrimaryButton
          disabled={link.url === '' || link.text === ''}
          onClick={() => {
            insertLink();
          }}
          text={i18n.t('globals:confirm')}
        />
      </div>
    </LinkFormWrapper>
  );
}

export default LinkForm;
