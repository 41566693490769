import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { IActivityProps } from 'types';
import { ActivityItem, IconButton } from '@fluentui/react';

interface ActivityComponentProps {
  activity: IActivityProps;
  callInterpolationFunction: (functionName: string, activity: IActivityProps) => JSX.Element;
  containerContext?: string;
  getActivityIcon: (type: number) => JSX.Element;
  handleOnActivityClick?: (activity: IActivityProps) => void;
  handleOnReadIconClick?: (activity: IActivityProps) => void;
  timeAgo: (value: string, format?: string) => string;
}

export default function Activity({
  activity,
  callInterpolationFunction,
  containerContext,
  getActivityIcon,
  handleOnActivityClick,
  handleOnReadIconClick,
  timeAgo
}: ActivityComponentProps): JSX.Element {
  const { t } = useTranslation();

  function getActivityComments(activity: IActivityProps): JSX.Element[] | null {
    const commentArray = t(`activitiesList.activity.${activity.type}.comment`, {
      activity,
      context: containerContext,
      returnObjects: true,
      interpolation: {
        format: onFormatInterpolation
      }
    });

    if (Array.isArray(commentArray) && commentArray.length > 0) {
      return commentArray.map((text) => {
        if (text.indexOf('fx.') === 0) {
          const interpolationFunctionName: string = text.split('.')[1];

          return callInterpolationFunction(interpolationFunctionName, activity);
        }

        return (
          <span key={activity.id + text} className="activity-comment">
            {text}
          </span>
        );
      });
    }

    return null;
  }

  function onFormatInterpolation(value: string | number | Date, format?: string): string {
    if (value instanceof Date) {
      return moment(value).format(format);
    }

    if (format === 'checkListItemStatus') {
      return value ? '2' : '1';
    }

    if (format === 'typeOfTeam') {
      if (value === 1) return 'internal';
      if (value === 2) return 'external';
      if (value === 3) return 'department';
      if (value === 4) return 'board';
    }

    return value.toString();
  }

  function getDescriptionSpan(text: string): JSX.Element {
    return (
      <span key={activity.id + text} className="activity-description">
        {text}
      </span>
    );
  }

  function getActivityDescription(activity: IActivityProps): JSX.Element[] {
    const descriptionArray = t(`activitiesList.activity.${activity.type}.description`, {
      activity,
      context: containerContext,
      returnObjects: true,
      interpolation: {
        format: onFormatInterpolation
      }
    });

    if (Array.isArray(descriptionArray) && descriptionArray.length > 1) {
      return descriptionArray.map((text) => {
        if (text.indexOf('fx.') === 0) {
          const interpolationFunctionName: string = text.split('.')[1];

          return callInterpolationFunction(interpolationFunctionName, activity);
        }

        return getDescriptionSpan(text);
      });
    }

    if (Array.isArray(descriptionArray) && descriptionArray.length === 1) {
      return [getDescriptionSpan(descriptionArray[0])];
    }

    return [];
  }

  function onReadIconClick(activity: IActivityProps): void {
    if (handleOnReadIconClick) {
      // check for handleOnReadIconClick
      handleOnReadIconClick(activity);
    }
  }

  function getNewActivityIcons(activity: IActivityProps): JSX.Element {
    return (
      <div className="new-notification-container">
        <IconButton
          iconProps={{ iconName: 'RedEye' }}
          onClick={() => onReadIconClick(activity)}
          className="read-notification-button"
          title={t('activitiesList.mark-as-read')}
        />
        <div className="new-notification-indicator" />
      </div>
    );
  }

  function onActivityClick(activity: IActivityProps): void {
    if (handleOnActivityClick) {
      handleOnActivityClick(activity);
    }
  }

  return (
    <div style={{ paddingBottom: '7px' }}>
      <div className={`activity-list-item ${activity.isNew ? 'unread-notification' : ''}`}>
        <div
          aria-hidden="true"
          onClick={() => onActivityClick(activity)}
          className={`activity-item ${handleOnActivityClick ? 'active' : ''}`}
        >
          <ActivityItem
            key={activity.id}
            timeStamp={timeAgo(activity?.creationDate || '', 'DD.MM.YYYY HH:mm')}
            activityDescription={getActivityDescription(activity)}
            comments={getActivityComments(activity)}
            activityIcon={getActivityIcon(activity.type)}
          />
        </div>
        {activity.isNew && getNewActivityIcons(activity)}
      </div>
    </div>
  );
}
