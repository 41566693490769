import Label from 'components/inputs/Label';
import FileManagement, { IFileManagement } from 'components/surfaces/FileManagement';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IBlobFile, IFileProps } from 'types';
import {
  DefaultButton,
  IButtonStyles,
  IContextualMenuProps,
  IImageProps,
  ILabelProps,
  IconButton,
  Image,
  Modal,
  concatStyleSets
} from '@fluentui/react';
import DocumentFieldStyled from './DocumentField.styles';

export interface DocumentFieldStyles {
  actionButtonStyles?: IButtonStyles;
  imagePreviewStyles?: IImageProps['styles'];
  labelStyles?: ILabelProps['styles'];
}

export interface IDocumentFieldProps {
  id?: string;
  /**
   * Description of the document field.
   */
  description?: string;
  /**
   * A label for the document field
   */
  label?: string;
  /**
   * The icon that will be displayed to the left of the label
   */
  labelIconName?: string;
  /**
   * Callback issued when the document changes.
   */
  onChange?: (files: IFileProps | null) => void;
  /**
   * Optional flag to mark rating control as readOnly
   * @defaultvalue false
   */
  disabled: boolean;
  /**
   * Whether the associated form field is required or not
   * @defaultvalue false
   */
  required?: boolean;
  /**
   * Whether the associated form field is required or not
   * @defaultvalue false
   */
  displayImagePreview?: boolean;
  /**
   * Whether the associated form field is required or not
   * @defaultvalue false
   */
  getFileContents?: ({ id }: { id: string }) => Promise<Response>;
  /**
   * Whether the associated form field is required or not
   * @defaultvalue false
   */
  styles?: DocumentFieldStyles;
  /**
   * Whether the associated form field is required or not
   * @defaultvalue false
   */
  defaultValue?: IFileProps;
  onOpenWopi?: (fileId: string) => void;
}

function DocumentField({
  id,
  defaultValue,
  description,
  disabled = false,
  displayImagePreview = false,
  getFileContents,
  label,
  labelIconName,
  onChange,
  required = false,
  styles,
  onOpenWopi
}: IDocumentFieldProps): JSX.Element {
  const { t } = useTranslation();

  const fileManagementRef = useRef<IFileManagement>(null);
  const [initialized, setInitialized] = useState(false);

  const [currentDocument, setCurrentDocument] = useState<IBlobFile | null>(null);
  const [isImageModalOpen, setIsImageModalOpen] = useState<boolean>(false);

  const [initialDefaultValue, setInitialDefaultValue] = useState<
    IDocumentFieldProps['defaultValue'] | null
  >(defaultValue);

  const [fileImageBlobUrl, setFileImageBlobUrl] = useState<string | null>(null);
  const [fileModalImageBlobUrl, setFileModalImageBlobUrl] = useState<string | null>(null);

  const isImage = useCallback((text?: string) => {
    if (!text) return false;

    return /.png|.jpg|.jpeg|.gif|.tiff|.tif/i.test(text);
  }, []);

  useEffect(() => {
    return () => {
      if (fileImageBlobUrl) {
        window.URL.revokeObjectURL(fileImageBlobUrl);
      }
    };
  }, [fileImageBlobUrl]);

  useEffect(() => {
    const setImageBlobUrl = async () => {
      if (getFileContents && initialDefaultValue && initialDefaultValue.id) {
        const fileContents = await getFileContents({ id: initialDefaultValue.id });

        const blob = await fileContents.blob();
        const blobUrl = URL.createObjectURL(blob);

        setFileImageBlobUrl(blobUrl);
      }
    };

    if (initialDefaultValue && !initialized) {
      if (isImage(initialDefaultValue?.text)) {
        setImageBlobUrl();
      }

      if (initialDefaultValue.blobFile) {
        setCurrentDocument(initialDefaultValue.blobFile);
      }

      setInitialized(true);
    }
  }, [initialDefaultValue, getFileContents, initialized, isImage]);

  function openFileChooser() {
    if (fileManagementRef.current?.openFileChooser) {
      fileManagementRef.current.openFileChooser();
    }
  }

  function onActionButtonClick() {
    if (disabled) {
      if (initialDefaultValue?.id && initialDefaultValue?.wopiUrl && onOpenWopi) {
        onOpenWopi(initialDefaultValue.id as string);
      } else {
        downloadDocument();
      }
    } else {
      openFileChooser();
    }
  }

  function onFileSelection(files: IBlobFile[]) {
    const newFile = files[0] || null;

    setFileImageBlobUrl(null);

    // state
    setCurrentDocument(newFile);

    // onChange
    if (onChange) onChange({ blobFile: newFile });
  }

  async function toggleImageModal() {
    if (!isImageModalOpen && !currentDocument && getFileContents && initialDefaultValue?.id) {
      const fileContents = await getFileContents({ id: initialDefaultValue.id });

      const blob = await fileContents.blob();
      const blobUrl = URL.createObjectURL(blob);

      setFileModalImageBlobUrl(blobUrl);
    }

    setIsImageModalOpen((prevState) => !prevState);
  }

  function getDownloadUrl() {
    if (currentDocument?.preview?.url) {
      return currentDocument.preview.url;
    }

    if (currentDocument) {
      return window.URL.createObjectURL(currentDocument);
    }

    return null;
  }

  function downloadDocument() {
    if (currentDocument) {
      // for files that have just been selected/added
      const url = getDownloadUrl();

      if (url) {
        const element = document.createElement('a');

        element.setAttribute('href', url);
        element.setAttribute('download', currentDocument.name);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
      }
    } else if (initialDefaultValue?.id && getFileContents) {
      // default file need to be fetched first

      const downloadLink = document.createElement('a');

      getFileContents({ id: initialDefaultValue.id })
        .then((response) => response.blob())
        .then((blob) => URL.createObjectURL(blob))
        .then((blobUrl) => {
          downloadLink.href = blobUrl;
          if (initialDefaultValue.text) downloadLink.download = initialDefaultValue.text;

          document.body.appendChild(downloadLink);
          downloadLink.click();

          URL.revokeObjectURL(blobUrl);
        });
    }
  }

  function getButtonMenuProps(): IContextualMenuProps | undefined {
    if (currentDocument || initialDefaultValue) {
      const items: IContextualMenuProps['items'] = [
        {
          key: 'download',
          text: t('documentField.button.menu.download'),
          onClick: () => downloadDocument(),
          iconProps: { iconName: 'Download' }
        }
      ];

      if (initialDefaultValue?.id && initialDefaultValue?.wopiUrl && onOpenWopi) {
        items.push({
          key: 'open',
          iconProps: { iconName: 'OpenInNewTab' },
          onClick: () => onOpenWopi?.(initialDefaultValue.id as string),
          ariaLabel: t('globals.openFile'),
          text: t('globals.openFile')
        });
      }

      items.push(
        {
          key: 'replace',
          disabled,
          text: t('documentField.button.menu.replace'),
          onClick: openFileChooser,
          iconProps: { iconName: 'Upload' }
        },
        {
          key: 'delete',
          disabled,
          text: t('documentField.button.menu.delete'),
          onClick: () => {
            setCurrentDocument(null);
            setFileImageBlobUrl(null);
            setInitialDefaultValue(null);

            if (onChange) onChange(null);
          },
          iconProps: { iconName: 'Delete' }
        }
      );

      return { items };
    }

    return undefined;
  }

  function Button(): JSX.Element {
    const customSplitButtonStyles: IButtonStyles = {
      flexContainer: {
        width: '100%'
      },
      rootHasMenu: {
        width: '100%'
      },
      splitButtonContainer: {
        width: '100%',
        'span:first-child': {
          width: '100%'
        }
      },
      root: {
        width: '100%',
        paddingLeft: '5px',
        paddingRight: '5px',
        border: '1px solid #a19f9d',
        borderRadius: 3,
        ':hover': {
          border: '1px solid black'
        }
      },
      textContainer: {
        display: 'inline',
        flexGrow: 0
      },
      splitButtonMenuButton: {
        border: '1px solid #a19f9d',
        borderRadius: 3,
        ':hover': {
          border: '1px solid black'
        }
      }
    };

    const actionButtonStyles = concatStyleSets(customSplitButtonStyles, styles?.actionButtonStyles);

    const menuProps: IContextualMenuProps | undefined = getButtonMenuProps();

    const buttonText =
      currentDocument || initialDefaultValue
        ? currentDocument?.name || initialDefaultValue?.text
        : t('documentField.button.text.upload');

    const title = currentDocument
      ? t('documentField.button.title.download')
      : t('documentField.button.title.upload');

    return (
      <div
        style={{
          width: '100%',
          paddingLeft: currentDocument?.preview?.url || fileImageBlobUrl ? 10 : 0
        }}
      >
        <DefaultButton
          className="c-document-field-button"
          split
          menuProps={menuProps}
          disabled={disabled && !initialDefaultValue}
          onClick={onActionButtonClick}
          text={buttonText}
          title={title}
          styles={actionButtonStyles}
          iconProps={{
            iconName: currentDocument ? 'Download' : 'Attach',
            styles: {
              root: { fontSize: currentDocument ? '14px' : '16px' }
            }
          }}
        />
      </div>
    );
  }

  function ImagePreview(): JSX.Element | null {
    if ((currentDocument?.preview?.url || fileImageBlobUrl) && displayImagePreview) {
      return (
        <img
          aria-hidden="true"
          alt="img"
          className="document-image-preview"
          src={currentDocument?.preview?.url || fileImageBlobUrl || undefined}
          onClick={toggleImageModal}
        />
      );
    }

    return null;
  }

  function ImagePreviewCloseButton(): JSX.Element {
    const ImagePreviewCloseButtonStyles: IButtonStyles = {
      root: {
        backgroundColor: 'rgb(207, 207, 207, 0.4)',
        color: 'rgb(50, 49, 48);',
        marginRight: 15,
        marginTop: 10,
        position: 'absolute',
        right: 0
      },
      rootHovered: { backgroundColor: 'rgb(207, 207, 207, 0.6)', color: 'rgb(32, 31, 30)' },
      rootPressed: { backgroundColor: 'rgb(189, 189, 189, 0.6)', color: 'rgb(32, 31, 30)' }
    };

    return (
      <IconButton
        iconProps={{ iconName: 'Cancel' }}
        onClick={toggleImageModal}
        styles={ImagePreviewCloseButtonStyles}
      />
    );
  }

  function ModalImage(): JSX.Element | null {
    if (currentDocument?.preview?.url || fileModalImageBlobUrl) {
      return (
        <div style={{ overflow: 'auto', maxHeight: '80vh', maxWidth: '80vh' }}>
          <ImagePreviewCloseButton />
          <Image
            shouldFadeIn={false}
            src={currentDocument?.preview?.url || fileModalImageBlobUrl || undefined}
            styles={{ image: { width: '100%', height: '100%', objectFit: 'cover' } }}
          />
        </div>
      );
    }

    return null;
  }

  return (
    <DocumentFieldStyled id={id}>
      <FileManagement
        ref={fileManagementRef}
        clickable={false}
        multiple={false}
        onChange={onFileSelection}
      >
        <Label
          label={label}
          required={required}
          iconName={labelIconName}
          description={description}
        />
        <div style={{ display: 'flex', width: '100%' }}>
          <ImagePreview />
          <Button />
        </div>
      </FileManagement>
      <Modal
        isOpen={isImageModalOpen}
        onDismiss={toggleImageModal}
        isBlocking={false}
        styles={{ scrollableContent: { overflow: 'hidden' } }}
      >
        <ModalImage />
      </Modal>
    </DocumentFieldStyled>
  );
}

export default DocumentField;
