import {
  Attachments,
  Conversation,
  Description,
  DynamicFieldForm,
  IFramePage,
  LoadingSpinner,
  TagPicker
} from 'components';
import { AppContext } from 'features/App';
import { usePages } from 'features/App/context/PagesContext';
import PivotDetailBlock from 'features/ProcessInstances/components/InstancePanel/PivotDetailBlock';
import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import fetchRequest from 'services/api';
import {
  createComment,
  deleteComment,
  evaluateFieldGroup,
  fileContents,
  getComments,
  getDefinition,
  getDefinitionExternalData,
  getDefinitionExternalDataList,
  getFile,
  getFiles,
  getStreamUrl,
  onDeleteFile,
  tagsSearch,
  updateComment,
  updateFile,
  uploadFile,
  uploadFile2,
  userSearch
} from 'services/fetchRequests';
import styled from 'styled-components';
import { PageLocation, RouteInstanceStatus } from 'types';
import {
  checkScreenWidth,
  findFilesToUploadInTaskGroups,
  formatDate,
  getTeamImageInitials,
  isUuid,
  onCombinedPickerSearch,
  onOpenWopi,
  UUID_NULL
} from 'utils/helpers';
import { Persona, PersonaSize } from '@fluentui/react';
import {
  IProcessInstance,
  mutate,
  PROCESS_INSTANCE_FIELD_VALUES_BY_INSTANCE,
  PROCESS_INSTANCE_FIELD_VALUES_HISTORY,
  useApiDataCache,
  useApiObject
} from 'hooks/api2';
import { RequestEditPermissionDeniedError } from 'components/inputs/DynamicField/DynamicField';
import { fetchJson } from 'services/api2';
import { ShowError } from 'components/ShowError';
import { useFileUploadJobs } from '../../../../components/lists/Attachments/Attachments';

// styles
const InstancePivotItemStyled = styled.div`
  padding: 1.5rem;

  .start-field-groups {
    padding-top: 1.5rem;
  }

  .description-block {
    padding-bottom: 1rem;
  }

  .persona-block {
    padding-top: 1rem;
  }

  .detail-block-margin-top {
    margin-top: 1.75rem;
  }
`;

export interface IInstancePanelBodyProps {
  panelTab: string;
  instance: IProcessInstance;
}

export default function InstancePanelBody({ instance, panelTab }: IInstancePanelBodyProps) {
  const { t } = useTranslation();

  // global app state
  const { globalAppState } = useContext(AppContext);
  const { currentTenantId, currentUser } = globalAppState;

  const cache = useApiDataCache();

  const { pages } = usePages([PageLocation.processDetails]);

  const navigate = useNavigate();

  const canReadSummary = instance.permissions.readSummary;
  const {
    isLoading: isLoadingFields,
    error: fieldsError,
    data: instanceFields,
    forceLoad: getInstanceFields
  } = useApiObject(PROCESS_INSTANCE_FIELD_VALUES_BY_INSTANCE, canReadSummary ? instance.id : null);

  const fieldGroups = useMemo(() => {
    if (instanceFields) {
      return [{ id: UUID_NULL, name: '', fields: instanceFields.items }];
    }
    return null;
  }, [instanceFields]);

  const { fileUploadJobs, addFileUploadJob, removeFileUploadJob } = useFileUploadJobs(true);

  function getPersons(searchText) {
    return userSearch({ searchTerm: searchText }).then((searchResult) => searchResult.items);
  }

  function onTagClick(tagId) {
    if (tagId) {
      navigate(`/tags/${tagId}`);
    }
  }

  function getTags(searchText, tagSuggestionParentId) {
    return tagsSearch({
      searchTerm: searchText,
      parentId: tagSuggestionParentId,
      onlyParents: false,
      showDeleted: false
    });
  }

  function getAdditionalDocumentCardMenuItems(file, setFiles) {
    let iconName = null;
    if (file.type === 1) iconName = 'CheckMark';

    return [
      {
        key: 'editItem',
        text: t('attachmentsPivotItem.detailList.menu.processImage'),
        iconProps: { iconName },
        onClick: () => {
          const newFile = { ...file, type: file.type === 1 ? 0 : 1 };

          setInstanceImage(newFile).then(() => {
            setFiles((prevStateFiles) => {
              const prevStateClone = [...prevStateFiles];
              const index = prevStateClone.findIndex((f) => f.id === newFile.id);

              prevStateClone[index] = newFile;
              return prevStateClone;
            });
          });
        }
      }
    ];
  }

  function setInstanceImage(file) {
    const body = JSON.stringify(file);

    return fetchRequest({ url: 'File', method: 'PUT', body });
  }

  function onUploadFile(file, onProgress) {
    return uploadFile2({ file, instanceId: instance.id, onUploadProgress: onProgress });
  }

  function findFile(fileToFind, currentFiles) {
    if (!currentFiles || currentFiles.length === 0) {
      return undefined;
    }

    const { name } = fileToFind;

    return currentFiles.find((stateFile) => {
      return stateFile.name === name;
    });
  }

  function getUniqueAndDuplicateFiles(filesToSeparate, currentFiles) {
    const uniqueFiles = [];
    const duplicateFiles = [];

    if (!currentFiles || currentFiles.length === 0) {
      // no duplicate files possible. return given files
      return { uniqueFiles: filesToSeparate, duplicateFiles };
    }

    filesToSeparate.forEach((file) => {
      const isDuplicateFile = !!findFile(file, currentFiles);

      if (isDuplicateFile) {
        duplicateFiles.push(file);
      } else {
        uniqueFiles.push(file);
      }
    });

    return { uniqueFiles, duplicateFiles };
  }

  function deleteFile(fileToDelete) {
    onDeleteFile({ id: fileToDelete.id });
  }

  const isTestMode = instance?.visibility === 4;
  const isAborted =
    instance?.status === RouteInstanceStatus.AbortedBySubProcess ||
    instance?.status === RouteInstanceStatus.AbortedByUser;
  const canRequestEdit = !isTestMode && !isAborted;
  const requestEdit = useCallback(
    async (fieldLink) => {
      if (!instance) throw new Error('no instance');

      const history = await cache.getData(
        PROCESS_INSTANCE_FIELD_VALUES_HISTORY,
        {
          instanceId: instance.id,
          fieldId: fieldLink.field.id
        },
        { alwaysLoad: true }
      );

      if (history.permissions.allowChanges) {
        return {
          async submit(value) {
            const uploadedDocuments = [];

            // documents could have been uploaded in a document field
            const documentFields = findFilesToUploadInTaskGroups([
              {
                id: UUID_NULL,
                name: '',
                fields: [{ ...fieldLink, value }]
              }
            ]);

            // upload files first
            await documentFields.reduce((accumulatorPromise, documentField) => {
              return accumulatorPromise.then(() => {
                return uploadFile({
                  instanceId: instance.id,
                  file: documentField.file,
                  fieldId: documentField.fieldId
                }).then((uploadedFile) => {
                  const { fieldIndex, groupIndex, fieldId, fieldGroupIndex, mediaValueIndex } =
                    documentField;
                  const { name, url, downloadUrl, id } = uploadedFile;

                  const file = {
                    downloadUrl,
                    fieldGroupIndex,
                    fieldId,
                    fieldIndex,
                    groupIndex,
                    id,
                    mediaValueIndex,
                    text: name,
                    url
                  };

                  uploadedDocuments.push(file);

                  return file;
                });
              });
            }, Promise.resolve());

            // the documents have been uploaded
            // now we have to enter the values in the fields
            let value2 = value;

            for (const { url, text, downloadUrl, mediaValueIndex } of uploadedDocuments) {
              if (mediaValueIndex > -1 && Array.isArray(value2)) {
                value2[mediaValueIndex] = { url, text, downloadUrl };
              } else {
                value2 = { url, text, downloadUrl };
              }
            }

            const promise = fetchJson({
              method: 'POST',
              url: `Route/InstanceFieldValues/Update`,
              body: {
                instanceId: instance.id,
                fieldId: fieldLink.field.id,
                value: value2
              }
            });

            mutate(
              instance,
              promise.then(({ value }: { value: unknown }) => {
                if (history.isNamingField) {
                  return { name: value?.toString() };
                }
                return {};
              }),
              {}
            );

            await promise;

            getInstanceFields();
          }
        };
      }

      throw new RequestEditPermissionDeniedError(history.permissions);
    },
    [cache, instance, getInstanceFields]
  );

  const fieldHistoryCtx = useMemo(
    () => (isTestMode ? null : { instanceId: instance?.id }),
    [isTestMode, instance?.id]
  );

  if (isLoadingFields || !instance) {
    return <LoadingSpinner label={t('loading.instance.panel.text')} />;
  }

  if (canReadSummary && panelTab === 'conversation') {
    return (
      <Conversation
        currentUser={currentUser}
        getComments={() => getComments({ routeInstanceId: instance.id })}
        getPersons={getPersons}
        getTags={getTags}
        id={instance.id}
        grouped
        onCreateComment={(props) => createComment({ ...props, routeInstanceId: instance.id })}
        onDeleteComment={deleteComment}
        onTagClick={onTagClick}
        onUpdateComment={updateComment}
      />
    );
  }

  if (canReadSummary && panelTab === 'attachments') {
    return (
      <Attachments
        disabled={false}
        id={instance.id}
        grouped
        updateFile={updateFile}
        additionalDocumentCardMenuItems={getAdditionalDocumentCardMenuItems}
        onTagsSearch={tagsSearch}
        onTagClick={onTagClick}
        getUniqueAndDuplicateFiles={getUniqueAndDuplicateFiles}
        getFiles={() => getFiles({ routeInstanceId: instance.id })}
        getFileContents={fileContents}
        onDeleteFile={deleteFile}
        uploadFile={onUploadFile}
        onOpenWopi={onOpenWopi}
        fileUploadJobs={fileUploadJobs}
        onAddFileUploadJob={addFileUploadJob}
        onRemoveFileUploadJob={removeFileUploadJob}
      />
    );
  }

  if (canReadSummary && panelTab && isUuid(panelTab)) {
    const page = pages.find(({ id }) => id === panelTab);

    if (page) {
      return (
        <IFramePage
          src={page.url}
          processId={instance.id}
          teamId={instance.team?.id}
          processTemplateId={instance.routeDefinitionId}
        />
      );
    }
  }

  return (
    <InstancePivotItemStyled>
      <div className="description-block">
        <div className="ms-font-m">
          {instance.tags && <TagPicker disabled defaultValue={instance.tags} />}
          {instance.description === '' || undefined || null ? null : (
            <Description
              label={t('instancePivotItem.content.label.description')}
              defaultValue={instance.description ? instance.description : ''}
              disabled
            />
          )}
        </div>
      </div>
      <PivotDetailBlock
        label={t('instancePivotItem.content.label.createdAt')}
        content={formatDate(new Date(instance.creationDate), 'DD.MM.YYYY')}
      />

      <div className={`${checkScreenWidth(['extraSmall']) ? '' : 'flex flex-wrap'}`}>
        <div className="detail-block-margin-top half-width">
          <PivotDetailBlock
            label={t('instancePivotItem.content.label.createdBy')}
            content={
              <Persona
                className="mt-2"
                text={instance.creator.name}
                size={PersonaSize.size32}
                imageUrl={instance.creator.pictureUrl}
                hidePersonaDetails={false}
              />
            }
          />
        </div>
        {instance.team ? (
          <div className="detail-block-margin-top half-width">
            <PivotDetailBlock
              label="Team"
              content={
                <Persona
                  className="mt-2"
                  text={instance.team.displayTitle}
                  imageInitials={getTeamImageInitials(instance.team.title)}
                  size={PersonaSize.size32}
                  hidePersonaDetails={false}
                />
              }
            />
          </div>
        ) : null}
      </div>
      <div className="start-field-groups">
        {fieldsError ? <ShowError error={fieldsError} /> : null}
        {fieldGroups ? (
          <DynamicFieldForm
            instanceId={instance.id}
            currentUser={currentUser}
            fetchRequest={fetchRequest}
            getStreamUrl={getStreamUrl}
            getDefinition={getDefinition}
            tenantId={currentTenantId}
            onCombinedPickerSearch={onCombinedPickerSearch}
            getDefinitionExternalData={getDefinitionExternalData}
            getDefinitionExternalDataList={getDefinitionExternalDataList}
            evaluateFieldGroup={evaluateFieldGroup}
            getFileContents={fileContents}
            getFile={getFile}
            sections={fieldGroups}
            onSectionsUpdate={() => undefined}
            disabled={!canRequestEdit}
            requestEdit={canRequestEdit && requestEdit}
            fieldHistoryCtx={fieldHistoryCtx}
            alwaysShowHistory
          />
        ) : null}
      </div>
    </InstancePivotItemStyled>
  );
}
