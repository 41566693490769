import PropTypes from 'prop-types';
import styled from 'styled-components';
import { adjustColor, colorsForDashboards } from 'utils/helpers';

const DiagramContainerStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const PieSliceStyled = styled.g`
  transition: all 0.3s ease-in-out;

  --translate-method: ${(props) =>
    props.$fullCircle
      ? '0,0'
      : `${props.$xText / (props.$pieRadius < 250 ? 20 : 10)}px,${
          props.$yText / (props.$pieRadius < 250 ? 20 : 10)
        }px`};

  &:hover {
    cursor: pointer;
    transform: translate(var(--translate-method));
  }

  &:hover .slicePath {
    fill: ${(props) => adjustColor(props.$pathFill, -30)};
  }

  &:hover .sliceText {
    opacity: 1 !important;
    font-size: ${(props) => (props.$pieRadius < 250 ? '0.7em' : '1.2em')};
    font-weight: bold;
  }

  .focusedSlice {
    transform: translate(var(--translate-method));
    fill: ${(props) => adjustColor(props.$pathFill, -30)};
  }

  .focusedSlice ~ .sliceText {
    opacity: 1 !important;
    transform: translate(var(--translate-method));
    font-size: ${(props) => (props.$pieRadius < 250 ? '0.7em' : '1.2em')};
    font-weight: bold;
  }
`;

const DiagramStyled = styled.div`
  margin: -1em 1em 1em 0;

  .slicePath {
    transition: all 0.3s ease-in;
  }

  .sliceText {
    font-size: ${(props) => (props.$pieRadius < 250 ? '0.7em' : '0.8em')};
    transition: all 0.3s ease-in;
  }

  .sliceTextFull {
    font-size: ${(props) => (props.$pieRadius < 250 ? '1.1em' : '1.5em')};
    font-weight: bold;
    transition: all 0.3s ease-in;
  }
`;

const LegendStyled = styled.ul`
  max-width: ${(props) => (props.$pieRadius < 250 ? '200px' : '500px')};
  height: ${(props) => (props.$pieRadius < 250 ? '250px' : '510px')};
  margin: ${(props) => (props.$pieRadius < 250 ? '1em' : '2em')};
  padding: 0.5em;
  list-style-type: none;
  overflow: auto;
  font-size: ${(props) => (props.$pieRadius < 250 ? '10px' : '14px')};

  .chartItem {
    display: flex;
    margin-bottom: ${(props) => (props.$pieRadius < 250 ? '0.4em' : '0.8em')};

    &:hover {
      cursor: pointer;

      .chartItemColor {
        min-width: ${(props) => (props.$indicatorSize ? `${props.$indicatorSize + 2}px` : '22px')};
        max-width: ${(props) => (props.$indicatorSize ? `${props.$indicatorSize + 2}px` : '22px')};
        min-height: ${(props) => (props.$indicatorSize ? `${props.$indicatorSize + 2}px` : '22px')};
        max-height: ${(props) => (props.$indicatorSize ? `${props.$indicatorSize + 2}px` : '22px')};
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
      }

      .chartItemTitle {
        text-shadow: 0 0.5px 0.5px rgba(0, 0, 0, 0.3);
      }
    }
  }

  .chartItemColor {
    min-width: ${(props) => (props.indicatorSize ? `${props.indicatorSize}px` : '20px')};
    max-width: ${(props) => (props.indicatorSize ? `${props.indicatorSize}px` : '20px')};
    min-height: ${(props) => (props.indicatorSize ? `${props.indicatorSize}px` : '20px')};
    max-height: ${(props) => (props.indicatorSize ? `${props.indicatorSize}px` : '20px')};
    margin-right: ${(props) => (props.indicatorSize ? '3px' : '5px')};
  }

  .chartItemTitle {
    margin: auto 5px;
  }
`;

function PieChart({
  showText,
  showLegend,
  showTooltip,
  chartValues,
  onSelectedChartGroup,
  chartSize,
  legendItemIndicatorSize,
  chartId
}) {
  const pieRadius = chartSize || 250;
  const chartStartX = -pieRadius * 0.84;
  const chartStartY = -pieRadius * 0.8;
  const maxChartWidth = pieRadius * (pieRadius < 250 ? 2.5 : 2.6);
  const maxChartHeight = pieRadius * 2.42;
  const pieColors = colorsForDashboards();

  let totalAmount = 0;
  chartValues.map((value) => {
    totalAmount += value.number;
    return totalAmount;
  });

  let pieChartValues = [];
  if (chartValues && chartValues.length > 0) {
    pieChartValues = chartValues.sort((a, b) => {
      return b.number - a.number;
    });
  }

  const legend = [];

  function getPieSlices() {
    if (pieChartValues && pieChartValues.length > 0) {
      let prevAmount = 0;
      let startX = 0;
      let startY = -pieRadius;

      return pieChartValues.map((value, index) => {
        const a = 2 * Math.PI * ((prevAmount + value.number) / totalAmount);
        const endX = Math.sin(a) * pieRadius;
        const endY = -Math.cos(a) * pieRadius;

        const largArcFlag = totalAmount / 2 > value.number ? 0 : 1;
        const randomColor = pieColors[value.colorIndex];

        const aText = 2 * Math.PI * ((prevAmount + value.number / 2) / totalAmount);
        const xText = Math.sin(aText) * (pieRadius / (pieRadius < 250 ? 0.9 : 0.92));
        const yText =
          -Math.cos(aText) * (pieRadius / (pieRadius < 250 ? 0.91 : 0.94)) +
          (pieRadius < 250 ? 5 : 6);

        const percent = Math.round((value.number / totalAmount) * 100);
        let pieText = `${percent}%`;
        const tooltip = `${value.title}: ${value.number} (${pieText})`;

        let curve = `M0,0 L${startX},${startY} A${pieRadius},${pieRadius} 0 ${largArcFlag},1 ${endX},${endY} Z`;
        if (pieChartValues.length === 1) {
          curve = `M${-pieRadius},0 a ${pieRadius},${pieRadius} 0 1, 0 ${
            pieRadius * 2
          }, 0 a ${pieRadius},${pieRadius} 0 1, 0 ${-pieRadius * 2}, 0 Z`;
          pieText = `${value.title}: ${value.number}`;
        }

        const sliceKey = `g-${value.key}-${index}-${chartId}`;
        const slicePathKey = `path-${value.key}-${index + 1}-${chartId}`;

        const slice = (
          <PieSliceStyled
            className="pieSlice"
            id={`slice-${value.key}-${index}`}
            key={sliceKey}
            $xText={xText}
            $yText={yText}
            $pathFill={randomColor}
            $fullCircle={pieChartValues.length === 1}
            $onClick={() => onSelectedChartGroup(value)}
            $pieRadius={pieRadius}
          >
            <path
              d={curve}
              fill={randomColor}
              className="slicePath"
              id={`path-${index + 1}-${chartId}`}
              key={slicePathKey}
            />
            {showTooltip && pieChartValues.length > 1 ? (
              <title className="pieSliceTooltip" key={`title-${value.title}-${chartId}`}>
                {tooltip}
              </title>
            ) : null}
            {showText || pieChartValues.length === 1 ? (
              <text
                textAnchor="middle"
                x={pieChartValues.length === 1 ? 0 : xText}
                y={pieChartValues.length === 1 ? 0 : yText}
                className={pieChartValues.length === 1 ? 'sliceTextFull' : 'sliceText'}
                key={`text-${value.key}-${chartId}`}
                style={{ opacity: percent > 1 ? 1 : 0 }}
              >
                {pieText}
              </text>
            ) : null}
          </PieSliceStyled>
        );

        legend.push({
          key: value.key,
          title: `${value.title}: ${value.number} (${percent}%)`,
          color: randomColor
        });

        prevAmount += value.number;
        startX = endX;
        startY = endY;

        return slice;
      });
    }
    return null;
  }

  function getPieChart() {
    return (
      <svg
        width={maxChartWidth}
        height={maxChartHeight}
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        preserveAspectRatio="xMinYMin meet"
        viewBox={`${chartStartX * 1.5} ${chartStartY * 1.5} ${maxChartWidth} ${maxChartHeight}`}
        className="pie-chart"
      >
        {getPieSlices()}
      </svg>
    );
  }

  function focusSlice(sliceKey) {
    const sliceElement = document.getElementById(`path-${sliceKey}-${chartId}`);

    if (sliceElement) {
      sliceElement.classList.add('focusedSlice');
    }
  }

  function blurSlice(sliceKey) {
    const sliceElement = document.getElementById(`path-${sliceKey}-${chartId}`);

    if (sliceElement) {
      sliceElement.classList.remove('focusedSlice');
    }
  }

  function getLegend() {
    return (
      <LegendStyled $pieRadius={pieRadius} $indicatorSize={legendItemIndicatorSize}>
        {legend.map((item, index) => {
          return (
            <li key={`legend-${index + 1}-${chartId}`}>
              <div
                className="chartItem"
                onFocus={() => focusSlice(index + 1)}
                onMouseOver={() => focusSlice(index + 1)}
                onMouseOut={() => blurSlice(index + 1)}
                onBlur={() => blurSlice(index + 1)}
              >
                <div className="chartItemColor" style={{ backgroundColor: item.color }} />
                <div className="chartItemTitle">{item.title}</div>
              </div>
            </li>
          );
        })}
      </LegendStyled>
    );
  }

  return (
    <DiagramContainerStyled>
      <DiagramStyled $pieRadius={pieRadius}>{getPieChart()}</DiagramStyled>
      {showLegend && pieChartValues.length > 1 ? getLegend() : null}
    </DiagramContainerStyled>
  );
}

PieChart.propTypes = {
  showText: PropTypes.bool,
  showLegend: PropTypes.bool,
  showTooltip: PropTypes.bool,
  onSelectedChartGroup: PropTypes.func,
  chartValues: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      title: PropTypes.string,
      number: PropTypes.number,
      colorIndex: PropTypes.number
    })
  ).isRequired,
  chartSize: PropTypes.number,
  legendItemIndicatorSize: PropTypes.number,
  chartId: PropTypes.string
};

PieChart.defaultProps = {
  showText: false,
  showLegend: false,
  showTooltip: false,
  onSelectedChartGroup: null,
  chartSize: null,
  legendItemIndicatorSize: null,
  chartId: ''
};

export default PieChart;
