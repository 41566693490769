import Label from 'components/inputs/Label';
import RichTextEditor from 'components/inputs/RichTextEditor';
import { useTranslation } from 'react-i18next';
import fetchRequest, { apiErrorHandler } from 'services/api';
import styled from 'styled-components';
import { IFieldProps, RouteFieldType } from 'types';
import { IPickerItemProps, ITag, IconButton, TagPicker, TooltipHost } from '@fluentui/react';

const SuggestionItemStyled = styled.div`
  padding: 7px;
  text-align: left;

  .create-tag {
    padding-right: 5px;
    padding-left: 2px;
    color: ${(props) => props.theme.themePrimary};
  }

  .field-type-text {
    color: rgb(133, 133, 133);
    font-weight: 400;
    font-size: 12px;
    text-align: left;
  }
`;

const CalloutContentWrapper = styled.div`
  margin: 7px;
  color: #323130;

  .c-callout-content_field-name {
    font-size: 21px;
    font-weight: 300;
  }

  .c-callout-content_info-wrapper {
    margin-top: 6px;
  }

  .c-callout-content_info-header-2 {
    font-weight: 400;
    font-size: 14px;
  }

  .c-callout-content_info-text {
    font-weight: 300;
    font-size: 13px;
  }
`;

const SelectedFieldStyled = styled.div`
  display: flex;
  padding-left: 6px;
  margin: 3px;

  align-items: center;
  max-width: fit-content;
  max-height: 24;
  border-radius: 4px;
  background-color: #e8e8e8;
  width: 100%;

  .ms-TooltipHost {
    display: inline-block;
    width: calc(100% - 24px);
  }

  .field-name {
    cursor: default;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 3px;
  }
`;

const TagPickerStyled = styled(TagPicker)``;

interface IFieldPickerTagProps extends IFieldProps, ITag {
  name: string;
  key: string | number;
  createField?: boolean;
}

interface IFieldPickerProps {
  onChange: (field: IFieldProps[]) => void;
  selectedFields: IFieldProps[];
  label?: string;
  placeholder?: string;
  description?: string;
  itemLimit?: number;
  required?: boolean;
  disabled?: boolean;
  definitionId?: string;
  onCreateNewField?: (field: IFieldProps) => void;
}

function FieldPicker({
  onChange,
  selectedFields,
  label,
  itemLimit = 1,
  placeholder,
  description,
  required,
  disabled,
  onCreateNewField,
  definitionId
}: IFieldPickerProps) {
  const { t } = useTranslation();

  const resultsMaximumNumber = 50;

  const fetchFields = async (filterText?: string): Promise<IFieldPickerTagProps[]> => {
    let url = `Route/Fields?pageIndex=0&itemsPerPage=${resultsMaximumNumber}`;

    if (definitionId) {
      url += `&routeDefId=${definitionId}`;
    }

    if (filterText && typeof filterText !== 'object') {
      url += `&searchTerms=${encodeURIComponent(filterText)}`;
    }

    const fields = await fetchRequest({ url }).catch(apiErrorHandler);

    return fields?.items;
  };

  function filterAlreadySelectedItems(fieldsToFilter: IFieldPickerTagProps[]) {
    return fieldsToFilter.filter(
      (field) => !selectedFields?.find((selectedField) => selectedField.id === field.id)
    );
  }

  const onEmptyResolveSuggestions = async () => {
    const dataFields = await fetchFields();

    return filterAlreadySelectedItems(dataFields) || [];
  };

  function getSelectedItems(): IFieldPickerTagProps[] {
    if (selectedFields?.length > 0) {
      return selectedFields.map((field) => ({
        ...field,
        key: field.id as string,
        name: field.name as string
      }));
    }

    return [];
  }

  const onResolveSuggestions = async (text: string): Promise<IFieldPickerTagProps[]> => {
    const results = await fetchFields(text);

    const filteredResults = filterAlreadySelectedItems(results);

    const isFilterTextInResults =
      !filteredResults ||
      !!filteredResults.find((result) => result.name?.toLowerCase() === text.toLowerCase());

    const showCreateFieldOption = onCreateNewField && !isFilterTextInResults;

    if (showCreateFieldOption) {
      return [
        ...filteredResults.slice(0, resultsMaximumNumber - 1),
        { createField: true, name: text, key: text, fieldType: 1 }
      ];
    }

    return filteredResults;
  };

  const getFieldTypeText = (fieldType: RouteFieldType) => {
    switch (fieldType) {
      case 1:
        return t('formbuilder.fieldPicker.smallText');
      case 2:
        return t('formbuilder.fieldPicker.longText');
      case 3:
        return t('formbuilder.fieldPicker.number');
      case 4:
        return t('formbuilder.fieldPicker.boolean');
      case 5:
        return t('formbuilder.fieldPicker.dateTime');
      case 6:
        return t('formbuilder.fieldPicker.choice');
      case 7:
        return t('formbuilder.fieldPicker.person');
      case 8:
        return t('formbuilder.fieldPicker.rating');
      case 9:
        return t('formbuilder.fieldPicker.link');
      case 10:
        return t('formbuilder.fieldPicker.document');
      case 11:
        return t('formbuilder.fieldPicker.externalData');
      case 12:
        return t('createField.dialog.field.types.lookup');
      case 13:
        return t('createField.dialog.field.types.location');
      case 14:
        return t('createField.dialog.field.types.signature');
      case 15:
        return t('createField.dialog.field.types.multimedia');
      case 16:
        return t('createField.dialog.field.types.scanner');
      case 17:
        return t('createField.dialog.field.types.ai');
      default:
        return '';
    }
  };

  const getToolTipContent = (field: IFieldProps) => {
    const {
      name,
      description,
      maxLength,
      fieldType,
      numStars,
      choices,
      choiceFormat,
      allowFillIn,
      appendChanges,
      numDecimals,
      dateFormat
    } = field;

    let maxLengthInfo;
    let descriptionInfo;
    let numStarsInfo;
    let choiceInfo;
    let appendTeChangesInfo;
    let numDecimalsInfo;
    let dateTimeInfo;

    if (description) {
      descriptionInfo = (
        <div className="c-callout-content_info-text" style={{ maxWidth: '300px' }}>
          <RichTextEditor
            disabled
            styles={{ editor: { fontSize: '12px', color: '#605e5c' } }}
            defaultValue={description}
          />
        </div>
      );
    }

    const getInfoRow = (header: string, text?: string | number | JSX.Element) => {
      return (
        <div className="c-callout-content_info-wrapper">
          <div className="c-callout-content_info-header-2">{header}</div>
          <div className="c-callout-content_info-text">{text}</div>
        </div>
      );
    };

    const fieldTypeInfo = getInfoRow(
      t('formbuilder.fieldPicker.info.type'),
      getFieldTypeText(fieldType)
    );

    if (maxLength) {
      maxLengthInfo = getInfoRow(t('formbuilder.fieldPicker.info.maxChars'), maxLength);
    }

    if (numStars) {
      numStarsInfo = getInfoRow(t('formbuilder.fieldPicker.info.numStars'), numStars);
    }

    if (fieldType === 2) {
      appendTeChangesInfo = getInfoRow(
        t('formbuilder.fieldPicker.info.appendChanges.header'),
        appendChanges
          ? t('formbuilder.fieldPicker.info.appendChanges.yes')
          : t('formbuilder.fieldPicker.info.appendChanges.no')
      );
    }

    if (fieldType === 3) {
      numDecimalsInfo = getInfoRow(t('formbuilder.fieldPicker.info.numDecimal'), numDecimals);
    }

    if (fieldType === 5) {
      dateTimeInfo = getInfoRow(
        t('formbuilder.fieldPicker.info.dateTime.header'),
        dateFormat === 2
          ? t('formbuilder.fieldPicker.info.dateTime.yes')
          : t('formbuilder.fieldPicker.info.dateTime.no')
      );
    }

    if (choiceFormat && choices?.length) {
      choiceInfo = (
        <>
          {getInfoRow(
            t('formbuilder.fieldPicker.info.choice.format'),
            choiceFormat === 1
              ? t('formbuilder.fieldPicker.info.choice.single')
              : t('formbuilder.fieldPicker.info.choice.multiple')
          )}
          {getInfoRow(
            t('formbuilder.fieldPicker.info.choice.allowFillIn.header'),
            allowFillIn
              ? t('formbuilder.fieldPicker.info.choice.allowFillIn.yes')
              : t('formbuilder.fieldPicker.info.choice.allowFillIn.no')
          )}
          {getInfoRow(
            t('formbuilder.fieldPicker.info.choice.choices'),
            <div style={{ maxHeight: '600px' }}>
              {choices.map((choice) => (
                <div key={choice.value}> - {choice.value}</div>
              ))}
            </div>
          )}
        </>
      );
    }

    return (
      <CalloutContentWrapper>
        <span className="c-callout-content_field-name">{name}</span>
        {descriptionInfo}
        {fieldTypeInfo}
        {maxLengthInfo}
        {numStarsInfo}
        {choiceInfo}
        {appendTeChangesInfo}
        {numDecimalsInfo}
        {dateTimeInfo}
      </CalloutContentWrapper>
    );
  };

  const onRenderSuggestionsItem = (suggestionsProps: IFieldPickerTagProps) => {
    if (suggestionsProps.createField && onCreateNewField) {
      return (
        <SuggestionItemStyled>
          <div className="flex">
            <div className="create-tag">{t('formbuilder.fieldPicker.create')}</div>
            <div>{suggestionsProps.name}</div>
          </div>
        </SuggestionItemStyled>
      );
    }

    return (
      <TooltipHost
        delay={2}
        directionalHint={9}
        tooltipProps={{ onRenderContent: () => getToolTipContent(suggestionsProps) }}
        id={`${suggestionsProps.id}-suggestion-tooltip`}
        key={`${suggestionsProps.id}-suggestion-tooltip`}
        calloutProps={{ gapSpace: 10 }}
      >
        <SuggestionItemStyled>
          <div>{suggestionsProps.name}</div>
          <div className="field-type-text">{getFieldTypeText(suggestionsProps.fieldType)}</div>
        </SuggestionItemStyled>
      </TooltipHost>
    );
  };

  const onRenderItem = (itemProps: IPickerItemProps<IFieldPickerTagProps>): JSX.Element => {
    return (
      <SelectedFieldStyled key={`item-key-${itemProps?.item?.id}`}>
        <TooltipHost
          delay={2}
          directionalHint={9}
          tooltipProps={{ onRenderContent: () => getToolTipContent(itemProps.item) }}
          id={`${itemProps.id}-tooltip`}
          calloutProps={{ gapSpace: 10 }}
        >
          <div className="field-name">{itemProps.item.name}</div>
        </TooltipHost>
        <IconButton
          iconProps={{
            iconName: 'Cancel',
            styles: { root: { color: 'black', fontSize: '12px', margin: 'auto' } }
          }}
          styles={{
            root: { height: '24px', width: '24px', padding: 0 },
            rootHovered: { height: '24px', width: '24px', backgroundColor: '#e6e6e6' }
          }}
          onClick={itemProps.onRemoveItem}
        />
      </SelectedFieldStyled>
    );
  };

  const onItemSelected = (item: IFieldPickerTagProps) => {
    if (item.createField && onCreateNewField) {
      onCreateNewField(item);
    }

    return item;
  };

  return (
    <>
      {label && <Label required={required} label={label} description={description} />}
      <TagPickerStyled
        selectedItems={getSelectedItems() || []}
        onResolveSuggestions={onResolveSuggestions}
        onEmptyResolveSuggestions={onEmptyResolveSuggestions}
        inputProps={{
          placeholder
        }}
        pickerSuggestionsProps={{
          resultsMaximumNumber,
          noResultsFoundText: t('fieldPicker.noResultsFoundText')
        }}
        onRenderSuggestionsItem={(props) => onRenderSuggestionsItem(props as IFieldPickerTagProps)}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onRenderItem={(props) => onRenderItem(props)}
        onItemSelected={(selectedItem) => onItemSelected(selectedItem as IFieldPickerTagProps)}
        itemLimit={itemLimit}
        resolveDelay={250}
        disabled={disabled}
        onChange={(value) => {
          onChange(value as IFieldPickerTagProps[]);
        }}
      />
    </>
  );
}

export default FieldPicker;
