import MultiMediaField, { IMultiMediaFieldProps } from 'components/inputs/MultiMediaField';
import { IFieldLinkProps, IFileProps, RouteFieldVisibility } from 'types';
import { getFluentIconNameByFieldType } from 'utils/helpers';

export interface IDynamicMultiMediaFieldProps {
  disabled?: boolean;
  fieldLink: IFieldLinkProps;
  tenantId: string;
  getFileContents: IMultiMediaFieldProps['getFileContents'];
  getStreamUrl: IMultiMediaFieldProps['getStreamUrl'];
  onChange: IMultiMediaFieldProps['onChange'];
}

function DynamicMultiMediaField({
  fieldLink,
  getFileContents,
  tenantId,
  disabled,
  onChange,
  getStreamUrl
}: IDynamicMultiMediaFieldProps) {
  if (!fieldLink.field) return null;

  const { field, visibility } = fieldLink;
  const { name, description, descriptionPlacement, id } = field;

  const displayDescriptionTooltip = descriptionPlacement === 1;

  const readOnly = visibility === RouteFieldVisibility.ReadOnly;
  const required = visibility === RouteFieldVisibility.Required;

  const fieldLinkValue = fieldLink.value as IFileProps[];

  const defaultValue = fieldLinkValue?.map((fileValue) => {
    if (
      fileValue?.url &&
      fileValue?.url !== 'https://tempuri.org' &&
      !fileValue?.url?.includes('microsoftstream.com')
    ) {
      // if default value comes from BE the file does not exists
      const fileIdIndex = fileValue.url.indexOf('id=') + 3;
      const id = fileValue.url.substr(fileIdIndex);

      return { ...fileValue, id };
    }

    // default value comes from state
    return { ...fileValue };
  });

  return (
    <MultiMediaField
      id={id}
      defaultValue={defaultValue}
      label={name}
      description={displayDescriptionTooltip ? description : undefined}
      tenantId={tenantId}
      getStreamUrl={getStreamUrl}
      getFileContents={getFileContents}
      labelIconName={getFluentIconNameByFieldType(fieldLink.field)}
      onChange={onChange}
      disabled={disabled || readOnly}
      required={required}
    />
  );
}

export default DynamicMultiMediaField;
