import styled from 'styled-components';

const PriorityButtonContainer = styled.div`
  .cmd-bar-priority-button {
    color: #666;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: rgb(${({ theme }) => theme.priorityButton.hoverBackground});
      cursor: pointer;
    }
  }

  .cmd-bar-priority-button-circle {
    border: 1px solid transparent;
    height: 19px;
    width: 19px;
    border-radius: 50%;
    display: inline-block;
  }

  .cmd-bar-priority-button-count {
    font-size: 14px;
    color: inherit;
  }
`;

export default PriorityButtonContainer;
